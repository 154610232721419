<h2 mat-dialog-title *ngIf="!formGroup">Selecionar {{ participanteTipoDescricao }}</h2>
<h2 mat-dialog-title *ngIf="formGroup">Criar novo {{ participanteTipoDescricao }}</h2>

<mat-dialog-content>
  <mat-drawer-container autosize>


    <mat-drawer-content>
      <div class="content">
        <div *ngIf="!formGroup">
          <form (submit)="searchSubmit()" novalidate autocomplete="off">
            <mat-form-field floatLabel="always">
              <input matInput (input)="filter()" placeholder="PROCURAR" [(ngModel)]="searchTerm" [ngModelOptions]="{ standalone: true }" [disabled]="loading" #focusInitial cdkFocusInitial>
            </mat-form-field>
          </form>

          <mat-table [dataSource]="matTableDataSource" matSort matSortActive="codigo" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="codigo">
              <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">CÓDIGO</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.codigo }}</mat-cell>
            </ng-container>



            <ng-container matColumnDef="nome">
              <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">NOME</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.nome }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef>CPF / CNPJ</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span *ngIf="row.cnpj">{{ row.cnpj | cnpj }}</span>
                <span *ngIf="row.cpf">{{ row.cpfPiped }}</span>
              </mat-cell>
            </ng-container>



            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns;" [class.selected-row]="row.id === participante?.id"
                     (click)="selectRow(row)" (dblclick)="selectParticipante(row)"></mat-row>
          </mat-table>
        </div>

        <form *ngIf="formGroup" [formGroup]="formGroup" novalidate autocomplete="off">
          <mat-form-field floatPlaceholder="always" class="third left">
            <input matInput formControlName="cnpj" type="text" maxlength="14"
                   placeholder="{{ isCpf ? 'CPF' : (isCnpj ? 'CNPJ' : 'CNPJ ou CPF') }}"
                   (input)="search($event.target.value)"
                   numbersOnly #focusInitial cdkFocusInitial>
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="third-2x right">
            <input matInput formControlName="fantasia" type="text" maxlength="60" placeholder="NOME FANTASIA" #focusSearch>
          </mat-form-field>

          <mat-form-field floatPlaceholder="always">
            <input matInput formControlName="nome" type="text" maxlength="60"
                   placeholder="{{ isCpf ? 'NOME' : (isCnpj ? 'RAZÃO SOCIAL' : 'RAZÃO SOCIAL ou NOME') }}">
          </mat-form-field>


          <mat-divider></mat-divider>
          <h3>ENDEREÇO</h3>

          <mat-form-field floatPlaceholder="always" class="quarter-3x left">
            <input matInput formControlName="logradouro" type="text" maxlength="60" placeholder="LOGRADOURO">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="quarter right">
            <input matInput formControlName="numero" type="text" maxlength="10" placeholder="NÚMERO">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half left">
            <input matInput formControlName="complemento" type="text" maxlength="60" placeholder="COMPLEMENTO">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half right">
            <input matInput formControlName="bairro" type="text" maxlength="60" placeholder="BAIRRO">
          </mat-form-field>

          <mat-form-field floatLabel="always" class="quarter left">
            <mat-select placeholder="PAÍS" formControlName="pais" (selectionChange)="onChangePais($event.value)">
              <mat-option disabled>Selecione</mat-option>
              <mat-option *ngFor="let pais of paises" [value]="pais.id">{{ pais.nome }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field floatLabel="always" class="quarter left">
            <mat-select placeholder="UF" formControlName="uf" (selectionChange)="onChangeUf($event.value)">
              <mat-option disabled>Selecione</mat-option>
              <mat-option *ngFor="let uf of ufs" [value]="uf.id">{{ uf.uf }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field floatLabel="always" class="half right">
            <mat-select placeholder="Município" formControlName="municipioIbge">
              <mat-option disabled>Selecione</mat-option>
              <mat-option *ngFor="let municipio of municipios" [value]="municipio.id">{{ municipio.nome }}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-divider></mat-divider>
          <h3>CONTATO</h3>

          <mat-form-field floatPlaceholder="always">
            <input matInput formControlName="contato" type="text" maxlength="30" placeholder="NOME">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half left">
            <input matInput formControlName="email" type="text" maxlength="60" placeholder="E-MAIL">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half right">
            <input matInput formControlName="fone" type="text" maxlength="15" placeholder="TELEFONE">
          </mat-form-field>
        </form>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-dialog-content>

<mat-dialog-actions>


  <div class="right">
<!--       <button mat-raised-button class="adicionarNovo" (click)='novo()'
      style="color: #8b95a5;border: 1px solid #d7d7d7 !important;border-radius: 3px;background: #f9fafb !important;width: 137px;">NOVO</button>
 -->
      <button mat-button (click)="novo()">NOVO</button>
    <button mat-button (click)="cancel()">CANCELAR</button>
    <button *ngIf="!canSave && isRequiredType" mat-button (click)="selectParticipante()" [disabled]="!canSelect">SELECIONAR</button>
    <button *ngIf="canSave" mat-button (click)="saveParticipante()" [disabled]="formGroup.invalid">SALVAR{{ action }}</button>
  </div>
</mat-dialog-actions>
