<div class="ui-g" style="position:fixed; right: 25px; bottom: 30px; z-index: 10;">
  <div class="ui-g-12">

  </div>
</div>

<!--PADDING INICIAL DE TODA A TELA-->
<div style="padding: 1.5rem" class="pt-3">
  <div class="row align-items-center">
    <div class="col">
      <h4 class="font-weight-bold mt-2 mb-2">
        Dashboard Estoque
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal"
            [innerHTML]="formatarDataExtenso()"></small></div>
      </h4>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <ul class="list-inline filter-menu mb-2">
        <li class="list-inline-item" [ngClass]="{active: statusTela1}"><a (click)="showTelaDashboard('TELA_1')">Posição
            de Estoque</a></li>
        <li class="list-inline-item" [ngClass]="{active: statusTela2}"><a
            (click)="showTelaDashboard('TELA_2')">Movimentações</a></li>
        <li class="list-inline-item" [ngClass]="{active: statusTela3}"><a (click)="showTelaDashboard('TELA_3')">Evolução
            Saldo Estoque</a></li>
        <li class="list-inline-item" [ngClass]="{active: statusTela4}"><a (click)="showTelaDashboard('TELA_4')">Análise
            de Custos</a></li>
      </ul>
    </div>
  </div>

  <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela1'}">
    <!--INICIO DO POSIÇÃO ESTOQUE-->
    <div class="row">
      <div class="col-sm-5 col-xxl-4 pb-3">
        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullTable ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <ul class="list-inline filter-menu filter-card-header mt-0">
                    <ul class="list-inline filter-menu filter-card-header mt-0">
                      <li class="list-inline-item" [ngClass]="{active: statusAlmoxarifado}"><a
                          (click)="showTelaCard2('TELA_1')">Almoxarifado</a></li>
                      <li class="list-inline-item" [ngClass]="{active: statusGrupoDeEstoque}"><a
                          (click)="showTelaCard2('TELA_2')">Grupo de Estoque</a></li>
                    </ul>
                  </ul>

                  <div class="actions-card">
                    <button [ngStyle]="statusAlmoxarifado ? {'display': 'inline'} : {'display': 'none'}" type="button"
                      class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.sdoAlmox, 'Almoxarifado', this.orderListAlmo)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                    </button>
                    <button [ngStyle]="statusGrupoDeEstoque ? {'display': 'inline'} : {'display': 'none'}" type="button"
                      class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.sdoGrpEstoq, 'GrupoEstoque', this.orderListGrupoEstoque)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardTable2()"
                      (click)="changeStateTable2()">
                      <span *ngIf="isCardTable2" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardTable2" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>

              <!--Inicio tabela-->
              <div class="box-content" [@changeDivSize]=currentStateTable2>
                <div style="min-height: 260px;">
                  <div [ngStyle]="statusAlmoxarifado ? {'display': 'block'} : {'display': 'none'}"
                    [ngClass]="{'fade-in': 'statusAlmoxarifado'}">

                    <p-table class="table-system" #dt1 [value]="sdoAlmox" [rows]="rows" [paginator]="false"
                      (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                      selectionMode="single" emptyMessage="Nenhum Registro Encontrado" [style]="{'width':'100%'}"
                      [scrollable]="true" [columns]="columnsModelAdendosPosicaoEstoque" scrollHeight="203px">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-center" style="width: 140px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                              <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                                </p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 78px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtd_fin'">
                              <span class="text-overflow-dynamic-ellipsis">Qtd Final <p-sortIcon [field]="'qtd_fin'">
                                </p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 78px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_fin'">
                              <span class="text-overflow-dynamic-ellipsis">Saldo Final <p-sortIcon
                                  [field]="'saldo_fin'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 80px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'gt'">
                              <span class="text-overflow-dynamic-ellipsis">%GT <p-sortIcon [field]="'gt'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="body" let-rowValue>
                        <tr>
                          <td
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'140px', 'text-align': 'left', 'text-overflow':'ellipsis'}"
                            header="Descrição" field="descricao" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.descricao}}">
                              <span style="user-select: all; cursor: text">{{rowValue.descricao}}</span>
                            </span>
                          </td>

                          <td header="Qtd Final"
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'78px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                            field="qtd_fin" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.qtd_fin | number : '1.2-2' : 'pt-BR'}}">
                              <span style="user-select: all; cursor: text">{{rowValue.qtd_fin | number : '1.2-2' :
                                'pt-BR'}}</span>
                            </span>
                          </td>


                          <td header="Saldo Final"
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'78px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                            field="saldo_fin" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.saldo_fin | number : '1.2-2' : 'pt-BR'}}">
                              <span style="user-select: all; cursor: text">{{rowValue.saldo_fin | number : '1.2-2' :
                                'pt-BR'}}</span>
                            </span>
                          </td>

                          <td
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'80px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                            header="%GT" field="gt" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.gt * 100 | number : '1.2-2' : 'pt-BR'}}%">
                              <span style="user-select: all; cursor: text">{{rowValue.gt * 100 | number : '1.2-2' :
                                'pt-BR'}}%</span>
                            </span>
                          </td>
                        </tr>

                      </ng-template>

                      <ng-template pTemplate="footer">
                        <tr>
                          <td [style]="{'width':'140px', 'text-align': 'right'}">Total:</td>
                          <td [style]="{'width':'78px', 'text-align': 'right'}">{{totalQtdeSdoAlmox | number : '1.2-2' :
                            'pt-BR'}}</td>
                          <td [style]="{'width':'78px', 'text-align': 'right'}">{{totalValorSdoAlmox | number : '1.2-2'
                            : 'pt-BR'}}</td>
                          <td [style]="{'width':'80px', 'text-align': 'right'}">100%</td>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="emptymessage" let-rowData>
                        <tr>
                          <td [attr.colspan]="4" class="spaced-alert">
                            <div class="alert alert-xs alert-primary d-inline" role="alert">
                              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                            </div>
                          </td>
                        </tr>
                      </ng-template>

                    </p-table>
                  </div>

                  <div [ngStyle]="statusGrupoDeEstoque ? {'display': 'block'} : {'display': 'none'}"
                    [ngClass]="{'fade-in': 'statusGrupoDeEstoque'}">
                    <p-table class="table-system" #dt2 [value]="sdoGrpEstoq" [rows]="rows" [paginator]="false"
                      (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                      selectionMode="single" emptyMessage="Nenhum Registro Encontrado" [style]="{'width':'100%'}"
                      [scrollable]="true" [columns]="columnsModelAdendosPosicaoEstoque" scrollHeight="203px">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-center" style="width: 140px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                              <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                                </p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 78px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtd_fin'">
                              <span class="text-overflow-dynamic-ellipsis">Qtd Final <p-sortIcon [field]="'qtd_fin'">
                                </p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 78px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_fin'">
                              <span class="text-overflow-dynamic-ellipsis">Saldo Final <p-sortIcon
                                  [field]="'saldo_fin'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 80px;">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'gt'">
                              <span class="text-overflow-dynamic-ellipsis">%GT <p-sortIcon [field]="'gt'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="body" let-rowValue>
                        <tr>
                          <td
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'140px', 'text-align': 'left', 'text-overflow':'ellipsis'}"
                            header="Descrição" field="descricao" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.descricao ? rowValue.descricao : 'Não informado'}}">
                              <span style="user-select: all; cursor: text">{{rowValue.descricao ? rowValue.descricao :
                                "Não informado"}}</span>
                            </span>
                          </td>

                          <td
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'78px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                            header="Qtd Final" field="qtd_fin" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.qtd_fin | number : '1.2-2' : 'pt-BR'}}">
                              <span style="user-select: all; cursor: text">{{rowValue.qtd_fin | number : '1.2-2' :
                                'pt-BR'}}</span>
                            </span>
                          </td>


                          <td
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'78px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                            header="Saldo Final" field="saldo_fin" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.saldo_fin | number : '1.2-2' : 'pt-BR'}}">
                              <span style="user-select: all; cursor: text">{{rowValue.saldo_fin | number : '1.2-2' :
                                'pt-BR'}}</span>
                            </span>
                          </td>

                          <td
                            [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'80px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                            header="%GT" field="gt" filterMatchMode="contains">
                            <span matTooltip="{{rowValue.gt * 100 | number : '1.2-2' : 'pt-BR'}}%">
                              <span style="user-select: all; cursor: text">{{rowValue.gt * 100 | number : '1.2-2' :
                                'pt-BR'}}%</span>
                            </span>
                          </td>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="footer">
                        <tr>
                          <td [style]="{'width':'140px', 'text-align': 'right'}">Total:</td>
                          <td [style]="{'width':'78px', 'text-align': 'right'}">{{totalQtdeSdoAlmox | number : '1.2-2' :
                            'pt-BR'}}</td>
                          <td [style]="{'width':'78px', 'text-align': 'right'}">{{totalValorSdoAlmox | number : '1.2-2'
                            : 'pt-BR'}}</td>
                          <td [style]="{'width':'80px', 'text-align': 'right'}">100%</td>
                        </tr>
                      </ng-template>

                      <ng-template pTemplate="emptymessage" let-rowData>
                        <tr>
                          <td [attr.colspan]="4" class="spaced-alert">
                            <div class="alert alert-xs alert-primary d-inline" role="alert">
                              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                            </div>
                          </td>
                        </tr>
                      </ng-template>

                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
              [ngClass]="fullTable ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <ul class="list-inline filter-menu filter-card-header mt-0">
                    <li class="list-inline-item" [ngClass]="{active: statusTipoDeItem}"><a
                        (click)="showTelaCard3('TELA_1')">Tipo Item</a></li>
                    <li class="list-inline-item" [ngClass]="{active: statusEstoqueNegativo}"><a
                        (click)="showTelaCard3('TELA_2')">Itens c/ Estoque Negativo</a></li>
                  </ul>
                  <div class="actions-card">
                    <button [ngStyle]="statusTipoDeItem ? {'display': 'inline'} : {'display': 'none'}" type="button"
                      class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.sdoTipoItem, 'TipoItem', this.orderListTipoItem)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                    </button>
                    <button [ngStyle]="statusEstoqueNegativo ? {'display': 'inline'} : {'display': 'none'}"
                      type="button" class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.sdoNeg, 'ItensNegativo', this.orderListItemNegativo)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardItensNegativo()"
                      (click)="changeStateItensNegativo()">
                      <span *ngIf="isCardItensNegativo" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardItensNegativo" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
                <div class="box-content" [@changeDivSize]=currentStateItensNegativo>
                  <div style="min-height: 281px;">
                    <div [ngStyle]="statusTipoDeItem ? {'display': 'block'} : {'display': 'none'}"
                      [ngClass]="{'fade-in': 'statusTipoDeItem'}">

                      <p-table class="table-system" #dt3 [value]="sdoTipoItem" [rows]="rows" [paginator]="false"
                        (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                        emptyMessage="Nenhum Registro Encontrado" selectionMode="single" [style]="{'width':'100%'}"
                        [scrollable]="true" [columns]="columnsModelAdendosPosicaoEstoque" scrollHeight="203px">

                        <ng-template pTemplate="header">
                          <tr>
                            <th class="text-center" style="width: 140px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                                <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon
                                    [field]="'descricao'"></p-sortIcon>
                                </span>
                              </span>
                            </th>
                            <th class="text-center" style="width: 78px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtd_fin'">
                                <span class="text-overflow-dynamic-ellipsis">Qtd Final <p-sortIcon [field]="'qtd_fin'">
                                  </p-sortIcon>
                                </span>
                              </span>
                            </th>
                            <th class="text-center" style="width: 78px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_fin'">
                                <span class="text-overflow-dynamic-ellipsis">Saldo Final <p-sortIcon
                                    [field]="'saldo_fin'"></p-sortIcon>
                                </span>
                              </span>
                            </th>
                            <th class="text-center" style="width: 80px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'gt'">
                                <span class="text-overflow-dynamic-ellipsis">%GT <p-sortIcon [field]="'gt'">
                                  </p-sortIcon>
                                </span>
                              </span>
                            </th>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowValue>
                          <tr>
                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'140px', 'text-align': 'left', 'text-overflow':'ellipsis'}"
                              header="Descrição" field="descricao" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.descricao}}">
                                <span style="user-select: all; cursor: text">{{rowValue.descricao}}</span>
                              </span>
                            </td>

                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'78px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                              header="Qtd Final" field="qtd_fin" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.qtd_fin | number : '1.2-2' : 'pt-BR'}}">
                                <span style="user-select: all; cursor: text">{{rowValue.qtd_fin | number : '1.2-2' :
                                  'pt-BR'}}</span>
                              </span>
                            </td>


                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'78px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                              header="Saldo Final" field="saldo_fin" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.saldo_fin | number : '1.2-2' : 'pt-BR'}}">
                                <span style="user-select: all; cursor: text">{{rowValue.saldo_fin | number : '1.2-2' :
                                  'pt-BR'}}</span>
                              </span>
                            </td>

                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'80px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                              header="%GT" field="gt" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.gt * 100 | number : '1.2-2' : 'pt-BR'}}%">
                                <span style="user-select: all; cursor: text">{{rowValue.gt * 100 | number : '1.2-2' :
                                  'pt-BR'}}%</span>
                              </span>
                            </td>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="footer">
                          <tr>
                            <td [style]="{'width':'140px', 'text-align': 'right'}">Total:</td>
                            <td [style]="{'width':'78px', 'text-align': 'right'}">{{totalQtdeSdoAlmox | number : '1.2-2'
                              : 'pt-BR'}}</td>
                            <td [style]="{'width':'78px', 'text-align': 'right'}">{{totalValorSdoAlmox | number :
                              '1.2-2' : 'pt-BR'}}</td>
                            <td [style]="{'width':'80px', 'text-align': 'right'}">100%</td>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" let-rowData>
                          <tr>
                            <td [attr.colspan]="4" class="spaced-alert">
                              <div class="alert alert-xs alert-primary d-inline" role="alert">
                                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                              </div>
                            </td>
                          </tr>
                        </ng-template>

                      </p-table>
                    </div>
                    <div [ngStyle]="statusEstoqueNegativo ? {'display': 'block'} : {'display': 'none'}"
                      [ngClass]="{'fade-in': 'statusEstoqueNegativo'}">

                      <p-table class="table-system" #dt4 [value]="sdoNeg" [rows]="rows" [paginator]="false"
                        (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                        selectionMode="single" emptyMessage="Nenhum Registro Encontrado" [style]="{'width':'100%'}"
                        [scrollable]="true" [columns]="columnsModelEstoqueNegativo" scrollHeight="203px">

                        <ng-template pTemplate="header">
                          <tr>
                            <th class="text-center" style="width: 120px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_item'"
                                matTooltip="Código do Item">
                                <span class="text-overflow-dynamic-ellipsis">Cód. Item <p-sortIcon [field]="'cod_item'">
                                  </p-sortIcon>
                                </span>
                              </span>
                            </th>

                            <th class="text-center" style="width: 60px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'almox'"
                                matTooltip="Almox">
                                <span class="text-overflow-dynamic-ellipsis">Almox <p-sortIcon [field]="'almox'">
                                  </p-sortIcon>
                                </span>
                              </span>
                            </th>

                            <th class="text-center" style="width: 90px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtd_fin'"
                                matTooltip="Quantidade Final">
                                <span class="text-overflow-dynamic-ellipsis">Qtd Final <p-sortIcon [field]="'qtd_fin'">
                                  </p-sortIcon>
                                </span>
                              </span>
                            </th>

                            <th class="text-center" style="width: 90px;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_fin'"
                                matTooltip="Quantidade Final(R$)">
                                <span class="text-overflow-dynamic-ellipsis">Saldo Fin(R$) <p-sortIcon
                                    [field]="'saldo_fin'"></p-sortIcon>
                                </span>
                              </span>
                            </th>

                          </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowValue>
                          <tr>
                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'120px', 'text-align': 'left', 'text-overflow':'ellipsis'}"
                              header="Cód Item" field="cod_item" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.cod_item}}">
                                <span style="user-select: all; cursor: text">{{rowValue.cod_item}}</span>
                              </span>
                            </td>

                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'60px', 'text-align': 'left', 'text-overflow':'ellipsis'}"
                              header="Cód Item" field="cod_item" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.almox}}">
                                <span style="user-select: all; cursor: text">{{rowValue.almox}}</span>
                              </span>
                            </td>

                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'90px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                              header="Qtd Final" field="qtd_fin" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.qtd_fin}}">
                                <span style="user-select: all; cursor: text">{{rowValue.qtd_fin | number : '1.2-2' :
                                  'pt-BR'}}</span>
                              </span>
                            </td>

                            <td
                              [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'90px', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                              header="Saldo Fin" field="saldo_fin" filterMatchMode="contains">
                              <span matTooltip="{{rowValue.saldo_fin}}">
                                <span style="user-select: all; cursor: text">{{rowValue.saldo_fin | number : '1.2-2' :
                                  'pt-BR'}}</span>
                              </span>
                            </td>
                          </tr>

                        </ng-template>

                        <ng-template pTemplate="footer">
                          <tr>
                            <td [style]="{'width':'120px', 'text-align': 'left'}">Total:</td>
                            <td [style]="{'width':'150px', 'text-align': 'right'}" colspan="2">
                              {{totalQtdeItensNeg | number : '1.2-2' : 'pt-BR'}}</td>
                            <td [style]="{'width':'90px', 'text-align': 'right'}">
                              {{totalValorItensNeg | number :'1.2-2' : 'pt-BR'}}</td>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" let-rowData>
                          <tr>
                            <td [attr.colspan]="4" class="spaced-alert">
                              <div class="alert alert-xs alert-primary d-inline" role="alert">
                                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                              </div>
                            </td>
                          </tr>
                        </ng-template>

                      </p-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-7 col-xxl-8 pl-1 pb-3">
        <div class="row">
          <div class="col pb-3">
            <div class="row">
              <div class="col-sm-4 pr-1 text-center">
                <div class="div-card-total card-total-radius" style="min-width: auto; height: auto;">
                  <p class="titulo-card-total">QTDE. SALDO FINAL</p>
                  <p class="valor-card-total">{{totalQtdeSdoAlmox | number : '1.2-2' : 'pt-BR'}}</p>
                </div>
              </div>


              <div class="col-sm-4 text-center">
                <div class="div-card-total card-total-radius">
                  <p class="titulo-card-total">VALOR SALDO FINAL</p>
                  <p class="valor-card-total">{{totalValorSdoAlmox | number : '1.2-2' : 'pt-BR'}}</p>
                </div>
              </div>


              <div class="col-sm-4 pl-1 text-center">
                <div class="div-card-total card-total-radius">
                  <p class="titulo-card-total">VARIAÇÃO VALOR ESTOQUE</p>
                  <p class="valor-card-total">{{totalValorSdoAlmox - totalValorSdoInit | number : '1.2-2' : 'pt-BR'}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
          [ngClass]="fullTable ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card d-inline-block">Posição de Estoque</h3>
              <p-multiSelect class="type-p-mult" [options]="listAlmoxarifado" [filter]="true"
                [(ngModel)]="liAlmoxarifadoId2" (onChange)="getPosicaoEstoque()"
                defaultLabel="Selecione um almoxarifado">
              </p-multiSelect>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportPosicaoEstoque()"
                  matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenTable()">
                  <span *ngIf="!fullTable" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullTable" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardPosicaoEstoque()"
                  (click)="changeStatePosicaoEstoque()">
                  <span *ngIf="isCardPosicaoEstoque" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardPosicaoEstoque" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <!--Inicio tabela-->
          <div class="box-content" [@changeDivSize]=currentStatePosicaoEstoque>
            <div style="min-height: 492px;">
              <p-table class="table-system" #dt5 [value]="posEstoque" [rows]="rows" [paginator]="true"
                (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                selectionMode="single" emptyMessage="Nenhum Registro Encontrado" [style]="{'width':'100%'}"
                [columns]="columnsModelPosicaoEstoque">
                <ng-template pTemplate="header">
                  <tr>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'25%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="descricao" filterMatchMode="contains" matTooltip="Descricao">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'descricao'">
                        Descricao <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'12%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="almox" filterMatchMode="contains" matTooltip="Almoxarifado">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'almox'">
                        Almoxarifado <p-sortIcon [field]="'almox'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'almox', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="qtde_ini" filterMatchMode="contains" matTooltip="Quantidade Saldos Iniciais">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde_ini'">
                        Qtd Saldos Ini <p-sortIcon [field]="'qtde_ini'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'qtde_ini', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="valor_ini" filterMatchMode="contains" matTooltip="Valor Saldo Inicial">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'valor_ini'">
                        Vl. Saldo Ini <p-sortIcon [field]="'valor_ini'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'valor_ini', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="qtde_entrada" filterMatchMode="contains" matTooltip="Quantidade Entrada">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde_entrada'">
                        Qtd Entrada <p-sortIcon [field]="'qtde_entrada'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'qtde_entrada', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="valor_entrada" matTooltip="Valor Entrada">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'valor_entrada'">
                        Vl. Entrada <p-sortIcon [field]="'valor_entrada'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'valor_entrada', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="qtde_saida" matTooltip="Quantidade Saída">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde_saida'">
                        Qtd Saída <p-sortIcon [field]="'qtde_saida'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'qtde_saida', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="valor_saida" matTooltip="Valor Saída">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde_saida'">
                        Vl. Saída <p-sortIcon [field]="'valor_saida'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'valor_saida', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="qtde_fin" matTooltip="Quantidade Saldo Final">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde_fin'">
                        Qtd Saldo Fin <p-sortIcon [field]="'qtde_fin'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'qtde_fin', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="valor_fin" matTooltip="Valor Saldo Final">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'valor_fin'">
                        Vl. Saldo Fin <p-sortIcon [field]="'valor_fin'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'valor_fin', 'contains')">
                    </th>
                    <th
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      field="cmv_valor" matTooltip="Custo Méd.">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'cm'">
                        Custo Méd. <p-sortIcon [field]="'cm'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="number"
                        (input)="dt5.filter($event.target.value, 'cm', 'contains')">
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowValue>
                  <tr>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'25%', 'text-align': 'left', 'text-overflow':'ellipsis'}"
                      header="Descrição" field="descricao" filterMatchMode="contains">
                      <span matTooltip="{{rowValue.descricao}}">
                        <span style="user-select: all; cursor: text">{{rowValue.descricao}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'12%', 'text-align': 'center', 'text-overflow':'ellipsis'}"
                      header="Almoxarifado" field="almox" filterMatchMode="contains">
                      <span matTooltip="{{rowValue.almox}}">
                        <span style="user-select: all; cursor: text">{{rowValue.almox}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Qtd Saldos Ini" field="qtde_ini" filterMatchMode="contains">
                      <span matTooltip="{{rowValue.qtde_ini | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.qtde_ini | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Vl. Saldo Ini" field="valor_ini" filterMatchMode="contains">
                      <span matTooltip="{{rowValue.valor_ini | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.valor_ini | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Qtd Entrada" field="qtde_entrada" filterMatchMode="contains">
                      <span matTooltip="{{rowValue.qtde_entrada | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.qtde_entrada | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="V. Entrada" field="valor_entrada">
                      <span matTooltip="{{rowValue.valor_entrada | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.valor_entrada | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Qtd Saída" field="qtde_saida">
                      <span matTooltip="{{rowValue.qtde_saida | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.qtde_saida | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Vl. Saída" field="valor_saida">
                      <span matTooltip="{{rowValue.valor_saida | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.valor_saida | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Qtd Saldo Fin" field="qtde_fin">
                      <span matTooltip="{{rowValue.qtde_fin | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.qtde_fin | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Vl. Saldo Fin" field="valor_fin">
                      <span matTooltip="{{rowValue.valor_fin | number : '1.2-2' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.valor_fin | number : '1.2-2' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                    <td
                      [style]="{'white-space':'nowrap', 'overflow': 'hidden', 'width':'7%', 'text-align': 'right', 'text-overflow':'ellipsis'}"
                      header="Custo Méd." field="cm">
                      <span matTooltip="{{rowValue.cm | number : '1.4-4' : 'pt-BR'}}">
                        <span style="user-select: all; cursor: text">{{rowValue.cm | number : '1.4-4' :
                          'pt-BR'}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [style]="{'width':'37%', 'text-align': 'right'}" colspan="2">Total:</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'qtde_ini') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'valor_ini') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'qtde_entrada') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'valor_entrada') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'qtde_saida') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'valor_saida') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'qtde_fin') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooter(dt5,'valor_fin') | number :
                      '1.2-2' :
                      'pt-BR'}}</td>
                    <td [style]="{'width':'7%', 'text-align': 'right'}">{{calcFooterCusto(dt5, 'qtde_fin', 'valor_fin')
                      | number : '1.4-4' : 'pt-BR'}}</td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="11" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM POSIÇÃO DO ESTOQUE-->

    </div>

  </div>

  <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela2'}">

    <!--INICIO DO MOVIMENTAÇÃO DE ESTOQUE-->
    <div class="row">
      <div class="col-sm-5 col-xxl-3 pb-3">
        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Resumo por Movimento</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.movEs, 'ResumoMovimento', this.orderListResumoMov)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardResumoMovimento()"
                      (click)="changeStateResumoMovimento()">
                      <span *ngIf="isCardResumoMovimento" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardResumoMovimento" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>

              <!--Inicio tabela-->
              <div class="box-content" [@changeDivSize]=currentStateResumoMovimento>
                <div style="min-height: 140px;">

                  <p-table class="table-system" #dt6 [value]="movEs" (onFilter)="onFilter($event)"
                    selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="80px"
                    [columns]="columnsModelResumoPorMovimento">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="text-center">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'mov'">
                            <span class="text-overflow-dynamic-ellipsis">
                              mov <p-sortIcon [field]="'mov'"></p-sortIcon>
                            </span>
                          </span>
                        </th>
                        <th class="text-center">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                            <span class="text-overflow-dynamic-ellipsis">
                              qtde <p-sortIcon [field]="'qtde'"></p-sortIcon>
                            </span>
                          </span>
                        </th>
                        <th class="text-center">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                            <span class="text-overflow-dynamic-ellipsis">
                              valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                            </span>
                          </span>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td>
                          <span class="overflow-ellipse text-center" matTooltip="{{ rowData.mov}}">
                            {{rowData.mov }}
                          </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{ rowData.qtde| number : '1.2-2' : 'pt-BR' }}">
                            {{rowData.qtde | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{ rowData.valor| number : '1.2-2' : 'pt-BR' }}">
                            {{rowData.valor | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" let-rowData>
                      <tr>
                        <td>
                          <span class="overflow-ellipse text-right" matTooltip="Total">
                            Total:
                          </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{calcFooter(dt6,'qtde') | number : '1.2-2' : 'pt-BR'}}">
                            {{calcFooter(dt6,'qtde') | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{calcFooter(dt6,'valor') | number : '1.2-2' : 'pt-BR'}}">
                            {{calcFooter(dt6,'valor') | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td [attr.colspan]="3" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Resumo por Tipo do Item</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.movTipoItem, 'ResumoTipo', this.orderListResumoTipoItem)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardResumoTipoItem()"
                      (click)="changeStateResumoTipoItem()">
                      <span *ngIf="isCardResumoTipoItem" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardResumoTipoItem" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
                <div class="box-content" [@changeDivSize]=currentStateResumoTipoItem>
                  <div style="min-height: 140px;">
                    <p-table class="table-system" #dt7 [value]="movTipoItem" (onFilter)="onFilter($event)"
                      selectionMode="single" [scrollable]="true" scrollHeight="80px" [columns]="columnsModelTipoDoItem">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-center">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                              <span class="text-overflow-dynamic-ellipsis">
                                descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                              <span class="text-overflow-dynamic-ellipsis">
                                qtde <p-sortIcon [field]="'qtde'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                          <th class="text-center">
                            <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                              <span class="text-overflow-dynamic-ellipsis">
                                valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                              </span>
                            </span>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData">
                          <td>
                            <span class="overflow-ellipse text-left" matTooltip="{{ rowData.descricao}}">
                              {{rowData.descricao }}
                            </span>
                          </td>
                          <td>
                            <span class="overflow-ellipse text-right"
                              matTooltip="{{ rowData.qtde| number : '1.2-2' : 'pt-BR' }}">
                              {{rowData.qtde | number : '1.2-2' : 'pt-BR'}}
                            </span>
                          </td>
                          <td>
                            <span class="overflow-ellipse text-right"
                              matTooltip="{{ rowData.valor| number : '1.2-2' : 'pt-BR' }}">
                              {{rowData.valor | number : '1.2-2' : 'pt-BR'}}
                            </span>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="footer" let-rowData>
                        <tr>
                          <td>
                            <span class="overflow-ellipse text-right" matTooltip="Total">
                              Total:
                            </span>
                          </td>
                          <td>
                            <span class="overflow-ellipse text-right"
                              matTooltip="{{calcFooter(dt7,'qtde') | number : '1.2-2' : 'pt-BR'}}">
                              {{calcFooter(dt7,'qtde') | number : '1.2-2' : 'pt-BR'}}
                            </span>
                          </td>
                          <td>
                            <span class="overflow-ellipse text-right"
                              matTooltip="{{calcFooter(dt7,'valor') | number : '1.2-2' : 'pt-BR'}}">
                              {{calcFooter(dt7,'valor') | number : '1.2-2' : 'pt-BR'}}
                            </span>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td [attr.colspan]="3" class="spaced-alert">
                            <div class="alert alert-xs alert-primary d-inline" role="alert">
                              <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-7 col-xxl-9 pl-1 pb-3">
        <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
          [ngClass]="fullTableMovEstoque ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card d-inline-block">Movimentação de Estoque</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link"
                  (click)="exportExcelAll(this.movEstoque, 'MovimentacaoEstoque', this.orderListMovEstoque)"
                  matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"
                    style="margin-right: 0px;"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenTableEstoque()">
                  <span *ngIf="!fullTable" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullTable" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardMovimentacaoEstoque()"
                  (click)="changeStateMovimentacaoEstoque()">
                  <span *ngIf="isCardMovimentacaoEstoque" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardMovimentacaoEstoque" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <!--Inicio tabela-->
          <div class="box-content" [@changeDivSize]=currentStateMovimentacaoEstoque>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt8 [value]="movEstoque" (onFilter)="onFilter($event)"
                scrollDirection="both" responsiveLayout="scroll" selectionMode="single" [rows]="rows" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                [columns]="columnsModelMovimentacaoDeEstoque">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'data'">
                        data <p-sortIcon [field]="'timestamp'"></p-sortIcon>
                      </span>
                      <input pInputText type="text" (input)="dt8.filter($event.target.value, 'data', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'mov'">
                        mov <p-sortIcon [field]="'mov'"></p-sortIcon>
                      </span>
                      <input pInputText type="text" (input)="dt8.filter($event.target.value, 'mov', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'item'">
                        item <p-sortIcon [field]="'item'"></p-sortIcon>
                      </span>
                      <input pInputText type="text" (input)="dt8.filter($event.target.value, 'item', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'almox'">
                        almox <p-sortIcon [field]="'almox'"></p-sortIcon>
                      </span>
                      <input pInputText type="text" (input)="dt8.filter($event.target.value, 'almox', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'nf'">
                        nf <p-sortIcon [field]="'nf'"></p-sortIcon>
                      </span>
                      <input pInputText type="text" (input)="dt8.filter($event.target.value, 'nf', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'historico'">
                        historico <p-sortIcon [field]="'historico'"></p-sortIcon>
                      </span>
                      <input pInputText type="text" (input)="dt8.filter($event.target.value, 'historico', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'qtde_entrada'">
                        qtde entrada <p-sortIcon [field]="'qtde_entrada'"></p-sortIcon>
                      </span>
                      <input pInputText type="number"
                        (input)="dt8.filter($event.target.value, 'qtde_entrada', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'valor_entrada'">
                        valor entrada <p-sortIcon [field]="'valor_entrada'"></p-sortIcon>
                      </span>
                      <input pInputText type="number"
                        (input)="dt8.filter($event.target.value, 'valor_entrada', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'qtde_saida'">
                        qtde saida <p-sortIcon [field]="'qtde_saida'"></p-sortIcon>
                      </span>
                      <input pInputText type="number"
                        (input)="dt8.filter($event.target.value, 'qtde_saida', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'valor_saida'">
                        valor saida <p-sortIcon [field]="'valor_saida'"></p-sortIcon>
                      </span>
                      <input pInputText type="number"
                        (input)="dt8.filter($event.target.value, 'valor_saida', 'contains')"
                        class="form-control form-control-sm" />
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td>
                      <span class="overflow-ellipse text-center" matTooltip="{{ rowData.data}}">
                        {{rowData.data}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center" matTooltip="{{ rowData.mov }}">
                        {{rowData.mov }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-left" matTooltip="{{ rowData.item }}">
                        {{rowData.item }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-center" matTooltip="{{ rowData.almox }}">
                        {{rowData.almox }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-left" matTooltip="{{ rowData.nf }}">
                        {{rowData.nf }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-left" matTooltip="{{ rowData.historico }}">
                        {{rowData.historico }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{ rowData.qtde_entrada | number : '1.2-2' : 'pt-BR'}}">
                        {{rowData.qtde_entrada | number : '1.2-2' : 'pt-BR' }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{ rowData.valor_entrada | number : '1.2-2' : 'pt-BR' }}">
                        {{rowData.valor_entrada | number : '1.2-2' : 'pt-BR' }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{ rowData.qtde_saida | number : '1.2-2' : 'pt-BR' }}">
                        {{rowData.qtde_saida | number : '1.2-2' : 'pt-BR' }}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{ rowData.valor_saida | number : '1.2-2' : 'pt-BR' }}">
                        {{rowData.valor_saida | number : '1.2-2' : 'pt-BR' }}
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td [attr.colspan]="6">
                      <span class="overflow-ellipse text-right" matTooltip="Total">
                        Total:
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{calcFooter(dt8,'qtde_entrada')  | number : '1.2-2' : 'pt-BR'}}">
                        {{calcFooter(dt8,'qtde_entrada') | number : '1.2-2' : 'pt-BR'}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{calcFooter(dt8,'valor_entrada') | number : '1.2-2' : 'pt-BR'}}">
                        {{calcFooter(dt8,'valor_entrada') | number : '1.2-2' : 'pt-BR'}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{calcFooter(dt8,'qtde_saida') | number : '1.2-2' : 'pt-BR'}}">
                        {{calcFooter(dt8,'qtde_saida') | number : '1.2-2' : 'pt-BR'}}
                      </span>
                    </td>
                    <td>
                      <span class="overflow-ellipse text-right"
                        matTooltip="{{calcFooter(dt8,'valor_saida') | number : '1.2-2' : 'pt-BR'}}">
                        {{calcFooter(dt8,'valor_saida') | number : '1.2-2' : 'pt-BR'}}
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xxl-5 pr-1">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card d-inline-block">Resumo por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link"
                  (click)="exportExcelAll(this.movItem, 'ResumoItem', this.orderListResumoItem)"
                  matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardResumoItem()"
                  (click)="changeStateResumoItem()">
                  <span *ngIf="isCardResumoItem" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardResumoItem" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
            <div class="box-content" [@changeDivSize]=currentStateResumoItem>
              <div style="min-height: 260px;">
                <p-table class="table-system" #dt9 [value]="movItem" [rows]="rows" [paginator]="true"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [columns]="columnsModelResumoPorItem">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" [style]="{'width':'380px'}">
                        <span class="overflow-ellipse text-left" [pSortableColumn]="'descricao'">
                          Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt9.filter($event.target.value, 'descricao', 'contains')">
                      </th>
                      <th class="text-left">
                        <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde'">
                          Qtde <p-sortIcon [field]="'qtde'"></p-sortIcon>
                        </span>
                        <input class="form-control form-control-sm" type="number"
                          (input)="dt9.filter($event.target.value, 'qtde', 'contains')">
                      </th>
                      <th class="text-left">
                        <span class="overflow-ellipse text-left" [pSortableColumn]="'valor'">
                          Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                        <input class="form-control form-control-sm" type="number"
                          (input)="dt9.filter($event.target.value, 'valor', 'contains')">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td [style]="{'width':'380px'}">
                        <span class="overflow-ellipse text-left" matTooltip="{{ rowData.descricao}}">
                          {{rowData.descricao}}
                        </span>
                      </td>
                      <td>
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{ rowData.qtde| number : '1.2-2' : 'pt-BR' }}">
                          {{rowData.qtde | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                      <td>
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{ rowData.valor| number : '1.2-2' : 'pt-BR' }}">
                          {{rowData.valor | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer" let-rowData>
                    <tr>
                      <td [style]="{'width':'380px'}">
                        <span class="overflow-ellipse text-right" matTooltip="Total">
                          Total:
                        </span>
                      </td>
                      <td>
                        <span class="overflow-ellipse text-right"
                          matTooltip=" {{calcFooter(dt9,'qtde')  | number : '1.2-2' : 'pt-BR'}}">
                          {{calcFooter(dt9,'qtde') | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                      <td>
                        <span class="overflow-ellipse text-right"
                          matTooltip=" {{calcFooter(dt9,'valor')  | number : '1.2-2' : 'pt-BR'}}">
                          {{calcFooter(dt9,'valor')| number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="3" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xxl-7">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px; display: table">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card d-inline-block">Resumo por NF / Participante</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link"
                  (click)="exportExcelAll(this.nfPart, 'ResumoNF', this.orderListNF)" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardResumoNfPart()"
                  (click)="changeStateResumoNfPart()">
                  <span *ngIf="isCardResumoNfPart" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardResumoNfPart" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
            <div class="box-content" [@changeDivSize]=currentStateResumoNfPart>
              <div style="min-height: 260px;">
                <p-table class="table-system" #dt10 [value]="nfPart" [rows]="rows" [paginator]="true"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [columns]="columnsModelResumoPorNF">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'data'">
                          data <p-sortIcon [field]="'timestamp'"></p-sortIcon>
                        </span>
                        <input pInputText type="text" (input)="dt10.filter($event.target.value, 'data', 'contains')"
                          class="form-control form-control-sm" />
                      </th>
                      <th class="text-left" [style]="{'width':'85px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'nf'">
                          nf <p-sortIcon [field]="'nf'"></p-sortIcon>
                        </span>
                        <input pInputText type="text" (input)="dt10.filter($event.target.value, 'nf', 'contains')"
                          class="form-control form-control-sm" />
                      </th>
                      <th class="text-left" [style]="{'width':'185px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'participante'">
                          participante <p-sortIcon [field]="'participante'"></p-sortIcon>
                        </span>
                        <input pInputText type="text"
                          (input)="dt10.filter($event.target.value, 'participante', 'contains')"
                          class="form-control form-control-sm" />
                      </th>
                      <th class="text-left" [style]="{'width':'80px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'almox'">
                          almox <p-sortIcon [field]="'almox'"></p-sortIcon>
                        </span>
                        <input pInputText type="text" (input)="dt10.filter($event.target.value, 'almox', 'contains')"
                          class="form-control form-control-sm" />
                      </th>
                      <th class="text-left" [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'qtde_entrada'">
                          qtde entrada <p-sortIcon [field]="'qtde_entrada'"></p-sortIcon>
                        </span>
                        <input pInputText type="number"
                          (input)="dt10.filter($event.target.value, 'qtde_entrada', 'contains')"
                          class="form-control form-control-sm" />
                      </th>
                      <th class="text-left" [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'valor_entrada'">
                          valor entrada <p-sortIcon [field]="'valor_entrada'"></p-sortIcon>
                        </span>
                        <input pInputText type="number"
                          (input)="dt10.filter($event.target.value, 'valor_entrada', 'contains')"
                          class="form-control form-control-sm" />

                      </th>
                      <th class="text-left" [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'qtde_saida'">
                          qtde saída <p-sortIcon [field]="'qtde_saida'"></p-sortIcon>
                        </span>
                        <input pInputText type="number"
                          (input)="dt10.filter($event.target.value, 'qtde_saida', 'contains')"
                          class="form-control form-control-sm" />

                      </th>
                      <th class="text-left" [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-center" [pSortableColumn]="'valor_saida'">
                          valor saída <p-sortIcon [field]="'valor_saida'"></p-sortIcon>
                        </span>
                        <input pInputText type="number"
                          (input)="dt10.filter($event.target.value, 'valor_saida', 'contains')"
                          class="form-control form-control-sm" />
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-center" matTooltip="{{ rowData.data}}">
                          {{rowData.data }}
                        </span>
                      </td>
                      <td [style]="{'width':'85px'}">
                        <span class="overflow-ellipse text-left" matTooltip="{{ rowData.nf }}">
                          {{rowData.nf }}
                        </span>
                      </td>
                      <td [style]="{'width':'185px'}">
                        <span class="overflow-ellipse text-left" matTooltip="{{ rowData.participante }}">
                          {{rowData.participante }}
                        </span>
                      </td>
                      <td [style]="{'width':'80px'}">
                        <span class="overflow-ellipse text-center" matTooltip="{{ rowData.almox }}">
                          {{rowData.almox }}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{ rowData.qtde_entrada | number : '1.2-2' : 'pt-BR' }}">
                          {{rowData.qtde_entrada | number : '1.2-2' : 'pt-BR' }}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{ rowData.valor_entrada | number : '1.2-2' : 'pt-BR' }}">
                          {{rowData.valor_entrada | number : '1.2-2' : 'pt-BR' }}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{ rowData.qtde_saida | number : '1.2-2' : 'pt-BR' }}">
                          {{rowData.qtde_saida | number : '1.2-2' : 'pt-BR' }}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{ rowData.valor_saida | number : '1.2-2' : 'pt-BR' }}">
                          {{rowData.valor_saida | number : '1.2-2' : 'pt-BR' }}
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" [style]="{'width':'450px'}">
                        <span class="overflow-ellipse text-right" matTooltip="Total">
                          Total:
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{calcFooter(dt10,'qtde_entrada') | number : '1.2-2' : 'pt-BR'}}">
                          {{calcFooter(dt10,'qtde_entrada') | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{calcFooter(dt10,'valor_entrada') | number : '1.2-2' : 'pt-BR'}}">
                          {{calcFooter(dt10,'valor_entrada') | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{calcFooter(dt10,'qtde_saida') | number : '1.2-2' : 'pt-BR'}}">
                          {{calcFooter(dt10,'qtde_saida') | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                      <td [style]="{'width':'100px'}">
                        <span class="overflow-ellipse text-right"
                          matTooltip="{{calcFooter(dt10,'valor_saida') | number : '1.2-2' : 'pt-BR'}}">
                          {{calcFooter(dt10,'valor_saida') | number : '1.2-2' : 'pt-BR'}}
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td [attr.colspan]="8" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--FIM DO MOVIMENTAÇÃO DE ESTOQUE-->

  </div>
  <div [ngStyle]="statusTela3 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela3'}">
    <div class="row">
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullEvolucaoEstoqueQtde ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Evolução Saldo de Estoque em Quantidade</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenEvolucaoEstoqueQtde()">
                      <span *ngIf="!fullEvolucaoEstoqueQtde" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullEvolucaoEstoqueQtde" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardEvolucaoEstoqueQtde()"
                      (click)="changeStateSaldoEstoqueQtde()">
                      <span *ngIf="isCardEvolucaoEstoqueQtde" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardEvolucaoEstoqueQtde" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateSaldoEstoqueQtde>
                <div style="min-height: 260px;">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_estoque_qtde" style="width: 100%; height: 260px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullEvolucaoEstoqueReais ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Evolução Saldo de Estoque em R$</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenEvolucaoEstoqueReais()">
                      <span *ngIf="!fullEvolucaoEstoqueReais" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullEvolucaoEstoqueReais" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardEvolucaoEstoqueReais()"
                      (click)="changeStateSaldoEstoqueReais()">
                      <span *ngIf="isCardEvolucaoEstoqueReais" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardEvolucaoEstoqueReais" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateSaldoEstoqueReais>
                <div style="min-height: 260px;">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_estoque_reais" style="width: 100%; height: 260px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-7 pl-1">
        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullEvolucaoEstoqueTipoItem ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Evolução Saldo de Estoque por Tipo do Item</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenEvolucaoEstoqueTipoItem()">
                      <span *ngIf="!fullEvolucaoEstoqueTipoItem" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullEvolucaoEstoqueTipoItem" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardEvolucaoEstoqueTipoItem()"
                      (click)="changeStateSaldoEstoqueTipoItem()">
                      <span *ngIf="isCardEvolucaoEstoqueTipoItem" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardEvolucaoEstoqueTipoItem" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateSaldoEstoqueTipoItem>
                <div style="min-height: 260px;">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_estoque_tipo_item" style="width: 100%; height: 260px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullEstoqueTopFive ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">TOP 5 Itens em R$</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenEstoqueTopFive()">
                      <span *ngIf="!fullEstoqueTopFive" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullEstoqueTopFive" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardEstoqueTopFive()"
                      (click)="changeStateEstoqueTopFive()">
                      <span *ngIf="isCardEstoqueTopFive" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardEstoqueTopFive" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateEstoqueTopFive>
                <div style="min-height: 260px;">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_estoque_top_five" style="width: 100%; height: 260px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngStyle]="statusTela4 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela4'}">
    <div class="row">
      <div class="col">
        <div class="form-row">
          <div class="form-group col-sm-6 col-xxl-5 pr-2 pr-xxl-3 mb-2">
            <label>Almoxarifado</label>
            <p-multiSelect class="type-p-mult" [options]="listAlmoxarifado" [filter]="true"
              [(ngModel)]="liAlmoxarifadoId" (onChange)="getEvolucaoCusto(); getEvolucaoCustoItem()"
              defaultLabel="Selecione um almoxarifado">
            </p-multiSelect>
          </div>
          <div class="form-group col-sm-6 col-xxl-7 pl-2 pl-xxl-1 mb-0">
            <label>Código Item</label>
            <p-multiSelect class="type-p-mult p-mult" [options]="listItem" [filter]="true" [(ngModel)]="liItemId"
              (onChange)="getEvolucaoCustoItem()" defaultLabel="Selecione um item">
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-xxl-5">
        <div class="row">
          <div class="col-sm-6 col-xxl-12 pb-3 pr-2 pr-xxl-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullEvolucaoCustoMedio ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Evolução do Custo Médio</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenEvolucaoCustoMedio()">
                      <span *ngIf="!fullEvolucaoCustoMedio" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullEvolucaoCustoMedio" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardEvolucaoCustoMedio()"
                      (click)="changeStateEvolucaoCustoMedio()">
                      <span *ngIf="isCardEvolucaoCustoMedio" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardEvolucaoCustoMedio" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateEvolucaoCustoMedio>
                <div style="min-height: 25vh;">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_custo_medio" style="width: 100%; height: 220px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-xxl-12 pb-3 pl-2 pl-xxl-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullEstoqueTopFive2 ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">TOP 5 Itens em R$</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenEstoqueTopFive2()">
                      <span *ngIf="!fullEstoqueTopFive2" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullEstoqueTopFive2" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardEstoqueTopFive2()"
                      (click)="changeStateEstoqueTopFive2()">
                      <span *ngIf="isCardEstoqueTopFive2" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardEstoqueTopFive2" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateEstoqueTopFive2>
                <div style="min-height: 25vh;">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_estoque_top_five_2" style="width: 100%; height: 290px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-xxl-7 pl-xxl-1">
        <div class="row">
          <div class="col-sm-12 col-xxl-12 pb-3">
            <div class="shadow-box" style="border-radius: 13px; padding: 3px;"
              [ngClass]="fullRelacaoItens ? 'box-fullscreen' : ''">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card d-inline-block">Relação de Itens | Custo Médio (R$)</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link"
                      (click)="exportExcelAll(this.relacItemCusto, 'Custo', this.orderListRelacaoItem)"
                      matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"
                        style="margin-right: 0px;"></i> </button>
                    <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                      matTooltipPosition="above" (click)="fullScreenRelacaoItens()">
                      <span *ngIf="!fullRelacaoItens" class="material-icons full-icon">fullscreen</span>
                      <span *ngIf="fullRelacaoItens" class="material-icons full-icon">fullscreen_exit</span>
                    </button>
                    <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardRelacaoItens()"
                      (click)="changeStateRelacaoItens()">
                      <span *ngIf="isCardRelacaoItens" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">expand_less</span>
                      <span *ngIf="!isCardRelacaoItens" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">expand_more</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]=currentStateRelacaoItens>
                <div style="min-height: 61.5vh;">
                  <p-table class="table-system" #dt11 [value]="relacItemCusto" [columns]="colsCustoMedio"
                    selectionMode="single" [rows]="20" [paginator]="true" [pageLinks]="pageLinks"
                    [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" [scrollable]="false"
                    [columns]="columnsModelRelacaoDeItens">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="text-center" style="width: 200px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'item'">
                            item <p-sortIcon [field]="'item'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt11.filter($event.target.value, 'item', 'contains')">
                        </th>
                        <th class="text-center" style="width: 100px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'periodo'">
                            periodo <p-sortIcon [field]="'periodo'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt11.filter($event.target.value, 'periodo', 'contains')">
                        </th>
                        <th class="text-center" style="width: 80px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'almox'">
                            almox <p-sortIcon [field]="'almox'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt11.filter($event.target.value, 'almox', 'contains')">
                        </th>
                        <th class="text-center" style="width: 100px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'qtde'">
                            qtd sdo final <p-sortIcon [field]="'qtde'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="number"
                            (input)="dt11.filter($event.target.value, 'qtde', 'contains')">
                        </th>
                        <th class="text-center" style="width: 100px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'valor'">
                            vl sdo final <p-sortIcon [field]="'valor'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="number"
                            (input)="dt11.filter($event.target.value, 'valor', 'contains')">
                        </th>
                        <th class="text-center" style="width: 100px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'custo'">
                            vl custo médio <p-sortIcon [field]="'custo'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="number"
                            (input)="dt11.filter($event.target.value, 'custo', 'contains')">
                        </th>
                        <th class="text-center" style="width: 100px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'mom'">
                            mom cm <p-sortIcon [field]="'mom'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="number"
                            (input)="dt11.filter($event.target.value, 'mom', 'contains')">
                        </th>
                        <th class="text-center" style="width: 100px;">
                          <span class="overflow-ellipse text-left" [pSortableColumn]="'cmv'">
                            VL CMV <p-sortIcon [field]="'cmv'"></p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="number"
                            (input)="dt11.filter($event.target.value, 'cmv', 'contains')">
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td class="text-left" style="width: 200px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.item }}">{{ rowData.item
                              }}</span>
                          </span>
                        </td>
                        <td class="text-center" style="width: 100px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.periodo}}">{{rowData.periodo }}</span>
                          </span>
                        </td>
                        <td class="text-center" style="width: 80px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.almox}}">{{rowData.almox
                              }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 100px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.qtde | number : '1.2-2' : 'pt-BR'}}">{{rowData.qtde | number :
                              '1.2-2' : 'pt-BR' }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 100px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number :
                              '1.2-2' : 'pt-BR'}}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 100px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.custo | number : '1.2-2' : 'pt-BR'}}">{{rowData.custo | number :
                              '1.2-2' : 'pt-BR' }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 100px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.mom | number : '1.2-2' : 'pt-BR'}}%">{{rowData.mom| number :
                              '1.2-2' : 'pt-BR'}}%</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 100px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.cmv | number : '1.2-2' : 'pt-BR'}}%">{{rowData.cmv | number :
                              '1.2-2' : 'pt-BR'}}</span>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" let-rowData>
                      <tr>
                        <td [attr.colspan]="3" [style]="{'width':'380'}">
                          <span class="overflow-ellipse text-right" matTooltip="Total">
                            Total:
                          </span>
                        </td>
                        <td [style]="{'width':'100px'}">
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{calcFooter(dt11,'qtde') | number : '1.2-2' : 'pt-BR'}}">
                            {{calcFooter(dt11,'qtde') | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                        <td [style]="{'width':'100px'}">
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{calcFooter(dt11,'valor') | number : '1.2-2' : 'pt-BR'}}">
                            {{calcFooter(dt11,'valor') | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                        <td [style]="{'width':'100px'}">
                          <span class="overflow-ellipse text-right"
                            matTooltip="{{calcFooter(dt11,'custo') | number : '1.2-2' : 'pt-BR'}}">
                            {{calcFooter(dt11,'valor') / calcFooter(dt11,'qtde') | number : '1.2-2' : 'pt-BR'}}
                          </span>
                        </td>
                        <td [style]="{'width':'100px'}">
                        </td>
                        <td [style]="{'width':'100px'}">
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="8" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--FIM PADDING INICIAL DE TODA A TELA-->