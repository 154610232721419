<mat-card class="card-linnks margin-linnks">
    <mat-card-title>
      {{title}}
      <mat-card-subtitle>
        {{subtitle}}
      </mat-card-subtitle>
    </mat-card-title>
  
    <mat-card-content>
      <div class="clearfix"></div>
      <div class="row">
        <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
          <div class="box-content">
            <div class="header-box">
              <div class="header-box-content" style="padding: 1rem !important; margin-top: -25px;">
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item"></li>
                  <li class="list-inline-item"></li>
                </ul>
                <div class="actions-card">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
                </div>
              </div>
            </div>
  
  
            <div *ngIf="!novaConta && !editarConta">
  
              <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroRel" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                immutable="false" (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)" [(selection)]="selectRel">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 40px;">
                    </th>
                    <th class="text-left" style="width: 200px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                        <span class="text-overflow-dynamic-ellipsis">
                          Código <p-sortIcon [field]="'cod'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($any($event.target).value, 'cod', 'contains')">
                    </th>
  
                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">
                          Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($any($event.target).value, 'descricao', 'contains')">
                    </th>
  
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 40px;">
                      <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData">
                      </p-tableRadioButton>
                    </td>
  
                    <td class="text-left" style="width: 50px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.cod}}">{{rowData.cod}}</span>
                      </span>
                    </td>
  
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="3" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row form-projeto" *ngIf="novaConta || editarConta">
        <div style="min-width: 818px;">
          <div class="box-content">
            <div class="form-size">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Código<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" minlength="3" pInputText [(ngModel)]="codCodrel">
              </div>
            </div>
  
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricaoCodrel">
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  
    </mat-card-content>
  </mat-card>
  
  