<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>

    <mat-tab-group (selectedTabChange)="tabChange($event)">

      <mat-tab label="Agendadas ({{ obrigacoesVencer.length }})">
        <div class="box-content">
          <p-table class="table-system" #dt2 [value]="obrigacoesVencer" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'responsavel'">
                    <span class="text-overflow-dynamic-ellipsis">Responsável <p-sortIcon [field]="'responsavel'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'responsavel', 'contains')">
                </th>
                <th class="text-center" style="width: 30%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'obrig'">
                    <span class="text-overflow-dynamic-ellipsis">Obrigação <p-sortIcon [field]="'obrig'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'obrig', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoArquivoDesc'">
                    <span class="text-overflow-dynamic-ellipsis">Tipo Arquivo <p-sortIcon [field]="'tipoArquivoDesc'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'tipoArquivoDesc', 'contains')">
                </th>
                <th class="text-center" style="width: 30%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresa'">
                    <span class="text-overflow-dynamic-ellipsis">Empresa <p-sortIcon [field]="'empresa'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'empresa', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vencimento'">
                    <span class="text-overflow-dynamic-ellipsis">Vencimento <p-sortIcon [field]="'vencimento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'vencimento', 'contains')">
                </th>
                <th class="text-center" style="width: 10%; vertical-align: sub;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Enviar Obrigação</span>
                  </span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.responsavel}}">{{rowData.responsavel}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 30%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.obrig}}">{{rowData.obrig}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.tipoArquivoDesc}}">{{rowData.tipoArquivoDesc}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 30%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.empresa}}">{{rowData.empresa}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vencimento}}">{{rowData.vencimento}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%; vertical-align: sub;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      <span class="default" matTooltip="Enviar Obrigação" (click)="alertObrig(rowData)">
                        <i class="fas fa-envelope" style="font-size: 18px;"></i>
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="6" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>

      <mat-tab label="Vencidas ({{ vencidas.length }})">
        <div class="box-content">
          <p-table class="table-system" selectionMode="single" #dt3 [value]="vencidas" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 30%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'obrigacao'">
                    <span class="text-overflow-dynamic-ellipsis">Obrigação <p-sortIcon [field]="'obrigacao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'obrigacao', 'contains')">
                </th>
                <th class="text-center" style="width: 30%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresa'">
                    <span class="text-overflow-dynamic-ellipsis">Empresa <p-sortIcon [field]="'empresa'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'empresa', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vencimento'">
                    <span class="text-overflow-dynamic-ellipsis">Vencimento <p-sortIcon [field]="'vencimento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'vencimento', 'contains')">
                </th>
                <th class="text-center" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoArquivo'">
                    <span class="text-overflow-dynamic-ellipsis">Tipo Arquivo <p-sortIcon [field]="'tipoArquivo'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'tipoArquivo', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'responsavel'">
                    <span class="text-overflow-dynamic-ellipsis">Responsável <p-sortIcon [field]="'responsavel'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'responsavel', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-left" style="width: 30%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.obrigacao}}">{{rowData.obrigacao}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 30%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.empresa}}">{{rowData.empresa}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vencimento}}">{{rowData.vencimento}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.tipoArquivo}}">{{rowData.tipoArquivo}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.responsavel}}">{{rowData.responsavel}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>

      <mat-tab label="Enviadas ({{ enviadas.length }})">
        <div class="box-content">

          <p-table class="table-system" #dt4 [value]="enviadas" [rows]="rows" [paginator]="true"
            (onRowSelect)="onRowSelectEnvio($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            selectionMode="single">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'obrigacao'">
                    <span class="text-overflow-dynamic-ellipsis">Obrigação <p-sortIcon [field]="'obrigacao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'obrigacao', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEnvio'">
                    <span class="text-overflow-dynamic-ellipsis">Envio <p-sortIcon [field]="'dtEnvio'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'dtEnvio', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtCompetencia'">
                    <span class="text-overflow-dynamic-ellipsis">Competência <p-sortIcon [field]="'dtCompetencia'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'dtCompetencia', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtVencimento'">
                    <span class="text-overflow-dynamic-ellipsis">Vencimento <p-sortIcon [field]="'dtVencimento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'dtVencimento', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'retificado'">
                    <span class="text-overflow-dynamic-ellipsis">Retificado <p-sortIcon [field]="'retificado'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'retificado', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                    <span class="text-overflow-dynamic-ellipsis">Responsável <p-sortIcon [field]="'usuario'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'usuario', 'contains')">
                </th>
                <th class="text-center" style="width: 80px;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlSaldoRecolher'">
                    <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'vlSaldoRecolher'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt4.filter($event.target.value, 'vlSaldoRecolher', 'contains')">
                </th>
                <th class="text-center" style="width: 10%; vertical-align: sub;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Confirm. </span>
                  </span>
                </th>
                <th class="text-center" style="width: 10%; vertical-align: sub;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Download </span>
                  </span>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-left" style="width: 25%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.obrigacao}}">{{rowData.obrigacao}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dtEnvio}}">{{rowData.dtEnvio}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dtCompetencia | date:'dd/MM/yyyy'}}">{{rowData.dtCompetencia |
                      date:'dd/MM/yyyy'}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dtVencimento}}">{{rowData.dtVencimento}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.retificado}}">{{rowData.retificado}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
                  </span>
                </td>
                <td class="text-right" style="width: 80px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vlSaldoRecolher | money}}">{{rowData.vlSaldoRecolher | money}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span
                    *ngIf="(rowData.nconf > 0 && rowData.conf === 0) && (rowData.nconf !== 0 || rowData.conf !== 0)">
                    <i class="material-icons-done">done</i>
                  </span>
                  <span *ngIf="rowData.gera > 0 && rowData.comprovante === 0">
                    <i class="material-icons-done-all-concluido-dolar">attach_money</i>
                  </span>
                  <span *ngIf="rowData.gera > 0 && rowData.comprovante > 0">
                    <i class="material-icons-done-all-concluido-dolar-concluido">attach_money</i>
                  </span>
                  <span *ngIf="rowData.nconf > 0 && rowData.conf > 0 && (rowData.nconf !== 0 || rowData.conf !== 0)">
                    <i class="material-icons-done-all">done_all</i>
                  </span>
                  <span *ngIf="rowData.gera > 0 && rowData.comprovante === 0">
                    <i class="material-icons-done-all-concluido-dolar">attach_money</i>
                  </span>
                  <span *ngIf="rowData.gera > 0 && rowData.comprovante > 0">
                    <i class="material-icons-done-all-concluido-dolar-concluido">attach_money</i>
                  </span>
                  <span *ngIf="rowData.nconf === 0 && (rowData.nconf !== 0 || rowData.conf !== 0)">
                    <span>
                      <i class="material-icons-done-all-concluido">done_all</i>
                    </span>
                    <span *ngIf="rowData.gera > 0 && rowData.comprovante === 0">
                      <i class="material-icons-done-all-concluido-dolar">attach_money</i>
                    </span>
                    <span *ngIf="rowData.gera > 0 && rowData.comprovante > 0">
                      <i class="material-icons-done-all-concluido-dolar-concluido">attach_money</i>
                    </span>
                  </span>
                  <span *ngIf="rowData.nconf === 0 && rowData.conf === 0">
                    <i class="material-icons-done">done</i>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="">
                    <i class="fas fa-download" (click)="export(rowData)"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="9" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>

          </p-table>


        </div>
      </mat-tab>

      <mat-tab label="Calendário ">
        <div class="box-content">
          <div>
            <br>
            <table class="calendar-table-ok">
              <thead>
                <tr>
                  <th class="center-cabecalho">
                    <b>D</b>
                  </th>
                  <th class="center-cabecalho">
                    <b>S</b>
                  </th>
                  <th class="center-cabecalho">
                    <b>T</b>
                  </th>
                  <th class="center-cabecalho">
                    <b>Q</b>
                  </th>
                  <th class="center-cabecalho">
                    <b>Q</b>
                  </th>
                  <th class="center-cabecalho">
                    <b>S</b>
                  </th>
                  <th class="center-cabecalho">
                    <b>S</b>
                  </th>
                </tr>
              </thead>

              <tr *ngFor="let c of calendar1">
                <td *ngIf="c.dom" [class.hojetrue]="c.dom[0].hoje" [class.hojefalse]="!c.dom[0].hoje">
              <tr *ngFor="let c of c.dom">
                <td *ngIf="c.ind===0">{{c.dia}}</td>

              </tr>
              <tr *ngFor="let c of c.dom">
                <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                  {{c.obrig.substr(0,45)}}</td>
                <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                  {{c.obrig.substr(0,45)}}</td>
                <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                  {{c.obrig.substr(0,45)}}</td>
              </tr>
              </td>
              <td *ngIf="!c.dom" class="nulo">
              </td>


              <td *ngIf="c.seg" [class.hojetrue]="c.seg[0].hoje" [class.hojefalse]="!c.seg[0].hoje">
                <tr *ngFor="let c of c.seg">
                  <td *ngIf="c.ind===0">{{c.dia}}</td>

                </tr>
                <tr *ngFor="let c of c.seg">
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>


                </tr>
              </td>
              <td *ngIf="!c.seg" class="nulo">
              </td>

              <td *ngIf="c.ter" [class.hojetrue]="c.ter[0].hoje" [class.hojefalse]="!c.ter[0].hoje">
                <tr *ngFor="let c of c.ter">
                  <td *ngIf="c.ind===0">{{c.dia}}</td>

                </tr>
                <tr *ngFor="let c of c.ter">
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>

                </tr>
              </td>
              <td *ngIf="!c.ter" class="nulo">
              </td>

              <td *ngIf="c.qua" [class.hojetrue]="c.qua[0].hoje" [class.hojefalse]="!c.qua[0].hoje">
                <tr *ngFor="let c of c.qua">
                  <td *ngIf="c.ind===0">{{c.dia}}</td>

                </tr>
                <tr *ngFor="let c of c.qua">
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>

                </tr>
              </td>
              <td *ngIf="!c.qua" class="nulo">
              </td>

              <td *ngIf="c.qui" [class.hojetrue]="c.qui[0].hoje" [class.hojefalse]="!c.qui[0].hoje">
                <tr *ngFor="let c of c.qui">
                  <td *ngIf="c.ind===0">{{c.dia}}</td>
                </tr>

                <tr *ngFor="let c of c.qui">
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>

                </tr>
              </td>
              <td *ngIf="!c.qui" class="nulo">
              </td>

              <td *ngIf="c.sex" [class.hojetrue]="c.sex[0].hoje" [class.hojefalse]="!c.sex[0].hoje">
                <tr *ngFor="let c of c.sex">
                  <td *ngIf="c.ind===0">{{c.dia}}</td>

                </tr>
                <tr *ngFor="let c of c.sex">
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>

                </tr>
              </td>
              <td *ngIf="!c.sex" class="nulo">
              </td>


              <td *ngIf="c.sab" [class.hojetrue]="c.sab[0].hoje" [class.hojefalse]="!c.sab[0].hoje">
                <tr *ngFor="let c of c.sab">
                  <td *ngIf="c.ind===0">{{c.dia}}</td>

                </tr>
                <tr *ngFor="let c of c.sab">
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencido'" class="vencido" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'vencer'" class="vencer" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>
                  <td *ngIf="c.obrigId !== 0 && c.status === 'enviado'" class="enviado" (click)="alertObrig(c)">
                    {{c.obrig.substr(0,45)}}</td>

                </tr>
              </td>
              <td *ngIf="!c.sab" class="nulo">
              </td>


              </tr>
            </table>

            <!-- Enviar Obrigação-->
            <div *ngIf="exibirModalEnvio">
              <p-dialog [(visible)]="exibirModalEnvio" [style]="{width: '900px'}" [modal]="true"
                styleClass="modal-scroll" appendTo="body" [closable]="true" (onHide)="cancelarEnvio();">
                <p-header class="title-modal">
                  Enviar Obrigação
                  <div class="row">
                    <div class="col">
                      <span class="empresa"><i class="fas fa-building"></i> {{obrigSelecionada.empresa}}</span> - <span
                        class="obrigacao">{{obrigSelecionada.obrig}}</span>
                    </div>
                  </div>
                </p-header>
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-envelope-open-text"></i> Mensagem</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <textarea class="form-control"
                      placeholder="Essa mensagem estará no corpo do e-mail junto com a obrigação" [rows]="5"
                      [(ngModel)]="mensagem"></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-sticky-note"></i> Justificativa (Controle Interno)</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a justificativa"
                      [ngClass]="obrigSelecionada.status != 'vencido' && obrigSelecionada.status != 'enviado' ? 'blocked-item' : ''"
                      [disabled]="obrigSelecionada.status != 'vencido' && obrigSelecionada.status != 'enviado'"
                      [options]="opcoesJustificativa" [(ngModel)]="justificativa">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <textarea class="form-control" placeholder="Informe maiores detalhes"
                      [disabled]="obrigSelecionada.status != 'vencido' && obrigSelecionada.status != 'enviado'"
                      [ngStyle]="{cursor: obrigSelecionada.status != 'vencido' && obrigSelecionada.status != 'enviado' ? 'not-allowed' : 'text'}"
                      [rows]="5" [cols]="60" pInputTextarea autoResize="false"
                      [(ngModel)]="detalhesJustificativa"></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Valores</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <p-dropdown class="type-p-dropdown" [options]="tipoDocumento"
                      [(ngModel)]="tipoDocumentoSelecionado"></p-dropdown>
                  </div>
                </div>
                <div *ngIf="tipoDocumentoSelecionado.tipoArquivo">
                  <div class="form-row" *ngIf="tipoDocumentoSelecionado.cod === 'DARF/GARE/GUIA'">
                    <div class="form-group col-sm-2">
                      <label>Competência</label>
                      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="competencia"
                        [defaultDate]="defaultDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                        [style]="{'width':'100%'}" appendTo="body">
                      </p-calendar>
                    </div>
                    <div class="form-group col-sm-2">
                      <label>Vl. Principal</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlPrincipal"
                        (keyup)="calcSaldoRecolher()" />
                    </div>
                    <div class="form-group col-sm-2">
                      <label>Vl. Multa</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlMulta"
                        (keyup)="calcSaldoRecolher()" />
                    </div>
                    <div class="form-group col-sm-2">
                      <label>Vl. Juros</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlJuros"
                        (keyup)="calcSaldoRecolher()" />
                    </div>
                    <div class="form-group col-sm-2">
                      <label>Vl.Recolher</label>
                      <input class="form-control text-right" type="text" [disabled]="true"
                        [(ngModel)]="vlSaldoRecolher" />
                    </div>
                  </div>

                  <div class="form-row" *ngIf="tipoDocumentoSelecionado.cod === 'SALDO CREDOR'">
                    <div class="form-group col-sm-2">
                      <label>Competência</label>
                      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="competencia"
                        [defaultDate]="defaultDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                        [style]="{'width':'100%'}" appendTo="body">
                      </p-calendar>
                    </div>
                    <div class="form-group col-sm-2">
                      <label>Saldo Credor</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlSaldoCredor" />
                    </div>
                  </div>

                  <div class="form-row" *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA'">
                    <div class="form-group col-sm-2">
                      <label>Competência</label>
                      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="competencia"
                        [defaultDate]="defaultDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                        [style]="{'width':'100%'}" appendTo="body">
                      </p-calendar>
                    </div>
                  </div>


                  <div class="form-row"
                    *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('SPED -EFD'))">
                    <div class="form-group col-sm-2"
                      *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('SPED -EFD ICMS'))">
                      <label>ICMS</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto1" money />
                    </div>
                    <div class="form-group col-sm-2"
                      *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('SPED -EFD ICMS'))">
                      <label>IPI</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto2" money />
                    </div>
                    <div class="form-group col-sm-2"
                      *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('SPED -EFD ICMS'))">
                      <label>ICMS-ST</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto3" money />
                    </div>
                    <div class="form-group col-sm-2"
                      *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('SPED -EFD Contr'))">
                      <label>PIS</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto1" money />
                    </div>
                    <div class="form-group col-sm-2"
                      *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('SPED -EFD Contr'))">
                      <label>COFINS</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto2" money />
                    </div>
                  </div>

                  <div class="form-row"
                    *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigSelecionada.obrig.startsWith('GIA'))">
                    <div class="form-group col-sm-2">
                      <label>ICMS</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto1" money />
                    </div>
                    <div class="form-group col-sm-2">
                      <label>ICMS-ST</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlImposto3" money />
                    </div>
                  </div>


                  <div class="form-row"
                    *ngIf="tipoDocumentoSelecionado.cod !== 'MEMORANDO' && tipoDocumentoSelecionado.cod !== 'SALDO CREDOR'
                      && tipoDocumentoSelecionado.cod !== 'DARF/GARE/GUIA' && tipoDocumentoSelecionado.cod !== 'OBRIGAÇÃO ACESSÓRIA'">
                    <div class="form-group col-sm-2">
                      <label>Competência</label>
                      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="competencia"
                        [defaultDate]="defaultDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                        appendTo="body">
                      </p-calendar>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-paperclip"></i> Anexo</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <!-- drag and drop anexo -->
                    <div class="u-clearfix buug-sc">
                      <div fxLayout="row" id="div2"
                        style="border:2px dashed #ededed;height:150px;border-radius:9px;margin-top:6px;font-size:14px;width:100%;"
                        [dndDropzone]="[]" [dndAllowExternal]="true" (dndDrop)="onDrop($event);fileChange();importar()">
                        <div fxLayout="column" fxLayoutAlign="space-between" class="layout-padding">
                        </div>

                        <div fxFlex (click)="openAnexoSelect();" fxLayout="column" fxLayoutAlign="center center"
                          class="scrollable">
                          <span style="color: #b5b5b5;">
                            <i style="margin:0px 0px 0px 43px;" class="lnr d-block lnr-paperclip"></i>
                            Solte os arquivos<br>ou, clique aqui!
                          </span>
                        </div>
                      </div>

                      <div *ngIf="enableInput" style="display:none" class="input-12" input-vertical="middle">
                        <input id="file-upload" type="file" multiple
                          (change)="onSelectfiles($event); fileChange();importar()" />
                      </div>
                    </div>
                    <!-- drag and drop anexo -->
                  </div>
                </div>
                <div class="row" *ngIf="files.length > 0">
                  <div class="col-sm-12 mt-3">
                    <table class="table table-system-boot">
                      <tr>
                        <th style="width: 90%">ARQUIVO</th>
                        <th style="width: 10%" class="text-center">AÇÃO</th>
                      </tr>
                      <tr *ngFor="let file of files">
                        <td>
                          {{file.nome}}
                        </td>
                        <td class="text-center">
                          <span class="delete" matTooltip='Excluir Arquivo' (click)="excluirAnexo(file)">
                            <i class="fas fa-trash-alt"></i>
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 20px;">
                  <div class="col">
                    <h3 class="sub-title"><i class="fas fa-users"></i> Destinatários</h3>
                    <div class="conteudo">
                      <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Selecionadas"
                        [source]="responsaveis" [target]="responsaveisSelecionados" [dragdrop]="true"
                        [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
                        <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                      </p-pickList>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h3 class="sub-title"><i class="fas fa-envelope"></i> E-mail</h3>
                    <label>
                      Informar e-mail caso queira adicionar, para mais de um separar por <strong
                        class="separar">';'</strong>
                    </label>
                    <input class="form-control" type="text" [(ngModel)]="outrosDestinatarios" />
                  </div>
                  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
                    <div class="ui-g-12" style="text-transform: initial;">

                    </div>
                  </div>
                </div>
                <p-footer>
                  <button type="button" class="btn btn-secondary" (click)='cancelarEnvio()'><i class="fas fa-undo"></i>
                    Fechar</button>
                  <button type="button" class="btn btn-primary" (click)="enviarObrigacao()"
                    [disabled]="obrigSelecionada.tipoArquivo != 11 && (((((obrigSelecionada.status == 'vencido' || obrigSelecionada.status == 'enviado') && !justificativa)
                                          || (!tipoDocumentoSelecionado.id && (obrigSelecionada.tipoArquivo !== 1 && obrigSelecionada.tipoArquivo !== 3 && obrigSelecionada.tipoArquivo !== 6 && obrigSelecionada.tipoArquivo !== 7))
                                          || (responsaveisSelecionados.length === 0 && !outrosDestinatarios)
                                          || (justificativa.id === 3 && !detalhesJustificativa)) || files.length === 0 && tipoDocumentoSelecionado.cod != 'SEM MOVIMENTO' ))"><i
                      class="fas fa-paper-plane"></i> Enviar</button>
                </p-footer>
              </p-dialog>
            </div>

            <!-- Obrigação não cadasteada -->
            <div *ngIf="modalObrigNaoCadastrada">
              <p-dialog header="Enviar obrigação não cadastrada" [(visible)]="modalObrigNaoCadastrada"
                [style]="{width: '900px'}" appendTo="body" styleClass="modal-scroll" [closable]="true" [modal]="true">

                <!-- Obrigação-->
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-file-alt"></i> Obrigação</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Tipo de Arquivo</label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tiposArquivo"
                      [(ngModel)]="obrigacaoNaoCadastrada.tipoArquivo" (ngModelChange)="carregarObrigacao()">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Obrigação</label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="codigosObrigacao"
                      [(ngModel)]="obrigacaoNaoCadastrada.codigosObrigacoes" (ngModelChange)="carregarTipoDocumentos()">
                    </p-dropdown>
                  </div>
                </div>

                <!-- Mensagem-->
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-file-alt"></i> Mensagem</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <textarea class="form-control" [rows]="5" placeholder="Mensagem visível para o cliente"
                      [(ngModel)]="mensagem"></textarea>
                  </div>
                </div>

                <!-- Anexo-->
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-paperclip"></i> Anexo</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <!-- drag and drop anexo -->
                    <div class="u-clearfix buug-sc">
                      <div fxLayout="row" id="div2"
                        style="cursor: pointer;border:2px dashed #ededed;height:150px;border-radius:9px;margin-top:6px;font-size:14px;width:100%;"
                        [dndDropzone]="[]" [dndAllowExternal]="true"
                        (dndDrop)="onDrop($event);fileChange();importarNaoCadastrada()">
                        <div fxLayout="column" fxLayoutAlign="space-between" class="layout-padding">
                        </div>

                        <div fxFlex (click)="openAnexoSelect();" fxLayout="column" fxLayoutAlign="center center"
                          class="scrollable">
                          <span style="color: #b5b5b5;">
                            <i style="margin:0px 0px 0px 43px;" class="lnr d-block lnr-paperclip"></i>
                            Solte os arquivos<br>ou, clique aqui!
                          </span>
                        </div>
                      </div>

                      <div *ngIf="enableInput" style="display:none" class="input-12" input-vertical="middle">
                        <input id="file-upload" type="file" multiple
                          (change)="onSelectfiles($event); fileChange();importarNaoCadastrada()" />
                      </div>
                    </div>
                    <!-- drag and drop anexo -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div *ngIf="filesNaoCadastrada.length > 0">
                      <table class="table table-system-boot mt-3">
                        <tr>
                          <th style="width: 90%;">Nome</th>
                          <th style="width: 10%;" class="text-center">Ação</th>
                        </tr>
                        <tr *ngFor="let file of filesNaoCadastrada">
                          <td>{{file.nome.substring(0, file.nome.lastIndexOf("."))}}{{file.extensao}}</td>
                          <td class="text-center">
                            <span class="delete" matTooltip='Excluir Arquivo' (click)="excluirAnexo(file)">
                              <i class="fas fa-trash-alt"></i>
                            </span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <!-- Valores-->
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Valores</h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Tipo Documento</label>
                    <p-dropdown class="type-p-dropdown" [options]="tipoDocumento"
                      [(ngModel)]="tipoDocumentoSelecionado"></p-dropdown>
                  </div>
                </div>
                <div *ngIf="tipoDocumentoSelecionado.tipoArquivo">
                  <div class="form-row" *ngIf="tipoDocumentoSelecionado.cod === 'DARF/GARE/GUIA'">
                    <div class="form-group col-sm-3">
                      <label>Valor Principal</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlPrincipal" money
                        (keyup)="calcSaldoRecolher()" />
                    </div>
                    <div class="form-group col-sm-3">
                      <label>Valor Multa</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlMulta" money
                        (keyup)="calcSaldoRecolher()" />
                    </div>
                    <div class="form-group col-sm-3">
                      <label>Valor Juros</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="vlJuros" money
                        (keyup)="calcSaldoRecolher()" />
                    </div>
                    <div class="form-group col-sm-3">
                      <label>Valor Saldo Recolher</label>
                      <input class="form-control text-right" type="text" [disabled]="true"
                        [(ngModel)]="vlSaldoRecolher" />
                    </div>
                  </div>
                </div>

                <div class="form-row" *ngIf="tipoDocumentoSelecionado.cod === 'SALDO CREDOR'">
                  <div class="form-group col-sm-3">
                    <label>Saldo Credor</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlSaldoCredor" money />
                  </div>
                </div>

                <div class="form-row"
                  *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigacaoNaoCadastrada.codigosObrigacoes.cod.startsWith('SPED -EFD ICMS'))">
                  <div class="form-group col-sm-3">
                    <label>ICMS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto1" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>IPI</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto2" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>ICMS-ST</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto3" money />
                  </div>
                </div>

                <div class="form-row"
                  *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigacaoNaoCadastrada.codigosObrigacoes.cod.startsWith('SPED -Contr'))">
                  aaaa
                  <div class="form-group col-sm-3">
                    <label>PIS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto1" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>COFINS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto2" money />
                  </div>
                </div>

                <div class="form-row"
                  *ngIf="tipoDocumentoSelecionado.cod === 'OBRIGAÇÃO ACESSÓRIA' && (obrigacaoNaoCadastrada.codigosObrigacoes.cod.startsWith('GIA'))">
                  <div class="form-group col-sm-3">
                    <label>ICMS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto1" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>ICMS-ST</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="vlImposto3" money />
                  </div>
                </div>
                <div class="row" style="margin-bottom: 20px;">
                  <div class="col">
                    <h3 class="sub-title"><i class="fas fa-users"></i> Destinatários</h3>
                    <div class="conteudo">
                      <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Selecionadas"
                        [source]="responsaveis" [target]="responsaveisSelecionados" [dragdrop]="true"
                        [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
                        <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                      </p-pickList>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h3 class="sub-title"><i class="fas fa-envelope"></i> E-mail</h3>
                    <label>
                      Informar e-mail caso queira adicionar, para mais de um separar por <strong
                        class="separar">';'</strong>
                    </label>
                    <input class="form-control" type="text" [(ngModel)]="outrosDestinatarios" />
                  </div>
                </div>
                <p-footer>
                  <button type="button" (click)='cancelarEnvioObrigacaoNaoSelecionada()' class="btn btn-secondary"><i
                      class="fas fa-undo"></i> Fechar</button>
                  <button type="button" (click)="enviarObrigacaoNaoSelecionada()"
                    [disabled]="(!obrigacaoNaoCadastrada.codigosObrigacoes || filesNaoCadastrada.length === 0 && tipoDocumentoSelecionado.cod != 'SEM MOVIMENTO' ||
                                                            !tipoDocumentoSelecionado || (responsaveisSelecionados.length === 0 && !outrosDestinatarios))" class="btn btn-primary"><i
                      class="fas fa-paper-plane"></i> Enviar</button>
                </p-footer>
              </p-dialog>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <!-- Histórico Envio (Anexo não funciona) -->
    <div *ngIf="exibirModalHistoricoEnvio">
      <p-dialog header="Histórico de Envio" [(visible)]="exibirModalHistoricoEnvio" [style]="{width: '900px'}"
        [modal]="true" [responsive]="true" appendTo="body" styleClass="modal-scroll" [closable]="true">

        <!-- Informações Gerais -->
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-info-circle"></i> Informações</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>Tipo de Documento</label>
            <p>{{envioExibir.tipoDocumento.cod}}</p>
          </div>
          <div class="form-group col">
            <label>Enviado por</label>
            <p>{{envioExibir.usuario.nome}} {{envioExibir.usuario.sobrenome}} em:
              <!-- <i class="fas fa-calendar-alt" style="color: #0091ea;"></i> -->
              {{ envioExibir.dtEnvio | date:'dd/MM/yyyy HH:mm' }}
            </p>
          </div>
        </div>
        <div class="form-row" *ngIf="envioExibir.mensagem">
          <div class="form-group col-sm-12">
            <label>Mensagem enviada ao cliente</label>
            <p>{{envioExibir.mensagem}}</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Enviado para</label>
            <textarea class="form-control" [rows]="3" [disabled]="true">{{envioExibir.outrosDestinatarios }}</textarea>
          </div>
        </div>
        <div class="form-row" *ngIf="envioExibir.justificativa">
          <div class="form-group col-sm-12">
            <label>Justificativa</label>
            <p>{{envioExibir.justificativa.descricao}}</p>
          </div>
        </div>
        <div class="form-row" *ngIf="envioExibir.detalhesJustificativa">
          <div class="form-group col-sm-12">
            <label>Detalhes Justificativa</label>
            <p>{{envioExibir.detalhesJustificativa}}</p>
          </div>
        </div>


        <!--Valores (dúvida If)-->
        <div *ngIf="envioExibir.vlPrincipal !== 0.00 ||
          envioExibir.vlMulta !== 0.00 || envioExibir.vlJuros !== 0.00 || envioExibir.vlSaldoRecolher !== 0.00
          || envioExibir.vlImposto1 !== 0.00 || envioExibir.vlImposto2 !== 0.00 || envioExibir.vlImposto3 !== 0.00
          || envioExibir.vlImposto4 !== 0.00">
          <div
            *ngIf="(envioExibir.obrigacao.codigosObrigacoes.cod !== 'SPED Fiscal' && envioExibir.obrigacao.codigosObrigacoes.cod !== 'EFD Contribuições'
            && envioExibir.obrigacao.codigosObrigacoes.cod !== 'SISCOSERV') && (envioExibir.obrigacao.tipoArquivo.id !== 5 && envioExibir.obrigacao.tipoArquivo.id !== 7)">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Valores</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Valor Principal</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlPrincipal" money />
                <!-- <p class="text-right">{{envioExibir.vlPrincipal | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Multa</label>
                <input class="form-control text-right" type="text" [disabled]="true" [(ngModel)]="envioExibir.vlMulta"
                  money />
                <!-- <p class="text-right">{{envioExibir.vlMulta | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Juros</label>
                <input class="form-control text-right" type="text" [disabled]="true" [(ngModel)]="envioExibir.vlJuros"
                  money />
                <!-- <p class="text-right">{{envioExibir.vlJuros | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>Saldo Recolher</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlSaldoRecolher" money />
                <!-- <p class="text-right">{{envioExibir.vlSaldoRecolher | money }}</p> -->
              </div>
            </div>
          </div>
          <div
            *ngIf="envioExibir.obrigacao.codigosObrigacoes.cod === 'SPED Fiscal' && envioExibir.tipoDocumento.id === 5">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Valores</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Valor ICMS</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlImposto1" money />
                <!-- <p class="text-right">{{envioExibir.vlImposto1 | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>Valor IPI</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlImposto2" money />
                <!-- <p class="text-right">{{envioExibir.vlImposto2 | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>Valor ICMS-ST</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlImposto3" money />
                <!-- <p class="text-right">{{envioExibir.vlImposto3 | money }}</p> -->
              </div>
            </div>
          </div>
          <div *ngIf="envioExibir.obrigacao.codigosObrigacoes.cod === 'GIA' && envioExibir.tipoDocumento.id === 5">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Valores</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Valor ICMS</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlImposto1" money />
                <!-- <p class="text-right">{{envioExibir.vlImposto1 | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>Valor ICMS-ST</label>
                <p class="text-right">{{envioExibir.vlImposto2 | money }}</p>
              </div>
            </div>
          </div>
          <div
            *ngIf="envioExibir.obrigacao.codigosObrigacoes.cod === 'EFD Contribuições' && envioExibir.tipoDocumento.id === 5">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Valores</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>PIS</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlImposto1" money />
                <!-- <p class="text-right">{{envioExibir.vlImposto1 | money }}</p> -->
              </div>
              <div class="form-group col-sm-3">
                <label>COFINS</label>
                <input class="form-control text-right" type="text" [disabled]="true"
                  [(ngModel)]="envioExibir.vlImposto2" money />
                <!-- <p class="text-right">{{envioExibir.vlImposto2 | money }}</p> -->
              </div>
            </div>
          </div>
        </div>

        <!--Confirmação Recebimento-->
        <div *ngIf="envioDestinatariosList.length > 0">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-check-double"></i> Confirmação de Recebimento</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <table class="table table-system-boot">
                <thead>
                  <th width="80%">
                    E-mail
                  </th>
                  <th width="20%" class="text-center">
                    Confirmação
                  </th>
                </thead>
                <tbody>
                  <tr *ngFor="let e of envioDestinatariosList">
                    <td>
                      {{e.mail}}
                    </td>
                    <td class="text-center">
                      <!-- <span *ngIf="!dtHrConfirmacao.length === 0" class="shared"><i style="cursor: default;" class="fas fa-calendar-alt"></i></span> -->
                      {{e.dtHrConfirmacao | date:'dd/MM/yyyy HH:mm'}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--Confirmação Pagamento-->
        <div *ngIf="!verificaPagamento">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-hand-holding-usd"></i> Confirmação de Pagamento</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-2">
              <label>Data Pgto</label>
              <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dtComprovante"
                [defaultDate]="defaultDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [style]="{'width':'100%'}" appendTo="body">
              </p-calendar>
            </div>
            <div class="form-group col-sm-2">
              <label>Principal</label>
              <input class="form-control text-right" type="text" [(ngModel)]="vlPrincipal" money
                (keyup)="calcSaldoRecolher()" />
            </div>
            <div class="form-group col-sm-2">
              <label>Multa</label>
              <input class="form-control text-right" type="text" [(ngModel)]="vlMulta" money
                (keyup)="calcSaldoRecolher()" />
            </div>
            <div class="form-group col-sm-2">
              <label>Juros</label>
              <input class="form-control text-right" type="text" [(ngModel)]="vlJuros" money
                (keyup)="calcSaldoRecolher()" />
            </div>
            <div class="form-group col-sm-3">
              <label>Saldo Recolher</label>
              <input class="form-control text-right" type="text" [disabled]="true" [(ngModel)]="vlSaldoRecolher" />
            </div>
          </div>

          <!-- Anexo -->
          <div class="row">
            <div class="col-sm-12">
              <!--<div class="col-sm-12">
                  <div *ngIf="enableInput" style="display:none" class="input-12" input-vertical="middle">
                      <input id="file-upload" type="file" multiple (change)="onSelectfiles($event); fileChange();importar()"/>

                    </div>
                  <div fxLayout="row" id="div2" style="cursor: pointer; border:2px dashed #ededed;height:150px;border-radius:9px;margin-top:6px;font-size:14px;width:100%;"
                  dndDropzone [dndAllowExternal]="true" (dndDrop)="onSelectfiles($event);fileChange();importar()">

                    <div fxLayout="column" fxLayoutAlign="space-between" class="layout-padding">
                    </div>

                    <div fxFlex (click)="openAnexoSelect();" fxLayout="column" fxLayoutAlign="center center"
                          class="scrollable">
                          <span style="color: #b5b5b5;">
                            <i style="margin:0px 0px 0px 43px;" class="lnr d-block lnr-paperclip"></i>
                            Solte os arquivos<br>ou, clique aqui!
                          </span>
                    </div>
                  </div>-->

              <div class="d-block mt-2">
                <button *ngIf="comprovantePagamento !== undefined" type="button" class="btn btn-xs btn-danger"
                  (click)="excluirComprovantePagamento()"><i class="fas fa-times"></i> Excluir</button>
                <button *ngIf="comprovantePagamento === undefined" type="button" class="btn btn-xs btn-primary"
                  (click)="salvarPagamento()" [disabled]="vlSaldoRecolher === 0"><i class="fas fa-check"></i>
                  Salvar</button>
                <button *ngIf="comprovantePagamento !== undefined" type="button" class="btn btn-xs btn-primary"
                  (click)="atualizarPagamento()" [disabled]="vlSaldoRecolher === 0"><i class="fas fa-sync"></i>
                  Atualizar</button>
              </div>
            </div>
            <!--</div>-->
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div *ngIf="files.length > 0">
                <table class="table table-system-boot mt-3">
                  <tr>
                    <th style="width: 90%;">Anexos</th>
                    <th style="width: 10%;" class="text-center">Ação</th>
                  </tr>
                  <tr *ngFor="let file of files">
                    <td>{{file.nome}}</td>
                    <td class="text-center">
                      <span class="delete" matTooltip='Excluir Arquivo'>
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--Reenvio Informação-->
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-paper-plane"></i> Reenvio de Obrigação</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Informar e-mail para reenvio. Para mais de um, separar por ; (ponto e vírgula).</label>
            <input class="form-control" type="text" [(ngModel)]="outrosDestinatarios" />
          </div>
        </div>

        <!--Desconsiderar Obrigação-->
        <div *ngIf="(envioExibir.usuario.id === dblinkedSessionService.usuario.id)">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-check"></i> Desconsiderar</h3>
            </div>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox class="mr-1" [(ngModel)]="desconsideraObrigacao" (click)="desconsiderarObrigacao()"
              [binary]="true" inputId="desObrg"></p-checkbox>
            <label for="desObrg">Desconsiderar obrigação</label>
          </div>
        </div>

        <!--Footer-->
        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='exibirModalHistoricoEnvio=false'><i
              class="fas fa-undo"></i> Fechar</button>
          <button type="button" class="btn btn-primary" (click)="reEnviarObrigacao()"
            [disabled]="(!outrosDestinatarios)"><i class="fas fa-paper-plane"></i> Reenviar</button>
        </p-footer>
      </p-dialog>
    </div>

  </mat-card-content>
</mat-card>
