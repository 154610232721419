<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <mat-card-content>

    <div class="box-content">

      <div class="header-box" style="height: 10px">
        <div class="header-box-content p-3" style="margin-top: -25px">
          <ul class="list-inline filter-menu filter-card-header mt-0">
            <li class="list-inline-item"></li>
            <li class="list-inline-item"></li>
          </ul>
          <div class="actions-card">
            <div style="display: flex;">
              <button type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu" class="btnCustom-default"
                matTooltip="Mais opções" *ngIf="!condicaoEditarRegraContabil && !condicaoCriarRegraContabil">
                <span class="material-icons" style="margin-top: -7px;">more_vert</span>
              </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                    <p-checkbox class="mr-1" [(ngModel)]="showInativos" [binary]="true"
                    (onChange)="empresaChanged()">
                    </p-checkbox>
                    <span>Exibir Inativos</span>
                  </div>
                  <div class="menuOptions" mat-menu-item (click)="exportarExcel();">
                    <span>Exportar</span>
                  </div>
                </mat-menu>
                <app-toolbar [value]="toolbarRegraContabil"></app-toolbar>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!novaRegra" style="margin-top: 40px;">
        <p-table class="table-system" #dt1 [value]="regras" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" [(selection)]="regrasContabeisSelecionadas"
          (onRowSelect)="onRowSelectRegra($event)" (onRowUnselect)="onUnRowSelectRegra($event)" (onHeaderCheckboxToggle)="selecionarTudo()">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox"style="width: 40px;">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                  <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'id', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                  <span class="text-overflow-dynamic-ellipsis">TÍTULO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoRegraContabil.descricao'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO REGRA<p-sortIcon [field]="'tipoRegraContabil.descricao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'tipoRegraContabil.descricao', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoOperacao.descricao'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO OPERAÇÃO<p-sortIcon
                      [field]="'tipoOperacao.descricao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'tipoOperacao.descricao', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS REGRA<p-sortIcon [field]="'ativo'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]=" showInativos == true ? opcoesStatusRegraContabilShowInativos : opcoesStatusRegraContabil"
                  defaultLabel="Selecione" [filter]="false" (onChange)="dt1.filter($event.value, 'ativo', 'in')">
                </p-multiSelect>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData" [ngClass]="{ inativo: !rowData.ativo }">
              <td class="text-center" style="width: 40px;">
                <p-tableCheckbox [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.id}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.descricao}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.tipoRegraContabil.descricao}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.tipoEntradaSaida.descricao}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.ativo? 'ATIVO' : 'INATIVO'}}">{{rowData.ativo? 'ATIVO' : 'INATIVO'
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>
    </div>
    <div class="box-content" *ngIf="novaRegra">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label for="inputDescricao">TÍTULO</label>
          <input class="form-control" [(ngModel)]="operacaoRegraContabil.descricao"
            value="{{operacaoRegraContabil.descricao}}" type="text" name="inputDescricao">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>TIPO DE ENTRADA</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTipoEntradaSaida"
            optionLabel="descricao" [(ngModel)]="operacaoRegraContabil.tipoEntradaSaida"></p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>TIPO DE REGRA</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTipoRegraContabil"
            optionLabel="descricao" [(ngModel)]="operacaoRegraContabil.tipoRegraContabil"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>TIPO DE OPERAÇÃO</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTipoOperacao"
            optionLabel="descricao" [(ngModel)]="operacaoRegraContabil.tipoOperacao"></p-dropdown>
        </div>
      </div>


      <div *ngIf="((novaRegra == true) || (editarRegraBoolean == true))">
        <p-table class="table-system" [value]="regraDetalhe" [rows]="rows" selectionMode="false" [paginator]="false"
          [pageLinks]="pageLinks" [style]="{'width':'100%'}">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">CAMPO</span>
                </span>
              </th>
              <th class="text-center" style="width: 30%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL</span>
                </span>
              </th>
              <th class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">D/C</span>
                </span>
              </th>
              <th class="text-center" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">HISTÓRICO</span>
                </span>
              </th>
              <th class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">DFC</span>
                </span>
              </th>
              <th class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    <span class="d-block" (click)="modalCampo(rowData)" *ngIf="rowData.formula"
                      style="cursor:pointer">{{rowData.formula}}</span>
                    <span class="d-block" (click)="modalCampo(rowData)" *ngIf="!rowData.formula"
                      style="cursor:pointer">Selecione a fórmula</span>
                  </span>
                </span>
              </td>
              <td class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container">
                  <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContas"
                    placeholder="Selecione o Plano de Contas" [(ngModel)]="rowData.planoContas.id"></p-dropdown>
                </span>
              </td>
              <td class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesDebitoCredito"
                    [(ngModel)]="rowData.indDc"></p-dropdown>
                </span>
              </td>
              <td class="text-center" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCadHist"
                    [(ngModel)]="rowData.cadHistPadrao.id">
                  </p-dropdown>
                </span>
              </td>
              <td class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <div class="mt-0">
                    <p-checkbox [(ngModel)]="rowData.dfc" [binary]="true"></p-checkbox>
                  </div>
                </span>
              </td>
              <td class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    <span class="delete d-block text-center" matTooltip="Excluir Linha!"
                      (click)="removerLinha(rowData)"><i class="fas fa-trash-alt"></i></span>
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="6" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class="form-column" style="background-color: #fff; padding: 10px;">
          <div class="form-group">
            <div class="p-field-checkbox mb-3 ml-1">
              <p-checkbox class="mr-1" [(ngModel)]="operacaoRegraContabil.ativo"
                (change)="operacaoRegraContabil.ativo = !operacaoRegraContabil.ativo" [binary]="true"
              inputId="operacaoRegraContabil.ativo"></p-checkbox>
              <label for="operacaoRegraContabil.ativo" class="ml-1" style="position: relative;top: 3px">Regra
              ativa</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col mt-3">
            <button type="button" class="btn btn-sm btn-info" (click)="addItem()"><i class="fas fa-plus"></i>
              Adicionar
              Linha</button>
          </div>
        </div>
      </div>

    </div>


    <!--MODAL ALTERAR FÓRMULA FEITO -->
    <p-dialog header="Alterar Fórmula" [(visible)]="edit" [modal]="true" appendTo="body" [closable]="true">
      <div class="form-row" [style]="{ width: '500px' }">
        <div class="form-group col-sm-6">
          <p-dropdown class="type-p-dropdown" [options]="opcoesCampos" appendTo="body"
            (onChange)="onChangeCampoFormula($event)"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <table class="table-formula" [style]="{'padding':'10px', 'font-size': '12px', 'width':'100%'}">
            <tr>
              <td>

              </td>
              <td>
                <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                  (click)="abreParenteses()">(</button>
              </td>
              <td>
                <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                  (click)="fechaParenteses()">)</button>
              </td>
              <td>
                <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                  (click)="incluirSoma()">+</button>
              </td>
              <td>
                <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                  (click)="incluirSub()">-</button>
              </td>
              <td>
                <button class="btn btn-sm btn-info" type="button" style="padding: 8px 12px 0px; font-weight: bold;"
                  (click)="incluirMult()">*</button>
              </td>
              <td>
                <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                  (click)="incluirDiv()">/</button>
              </td>
              <td>
                <button style="font-weight: bold;" class="btn btn-sm btn-danger" type="button"
                  (click)="limparCampoFormula()"><i class="fas fa-trash-alt" style="
                  font-size: 13px;
                  position: relative;
                  top: -1px;
              "></i></button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <textarea class="form-control" rows="5" value="{{detalheAlterar.formula}}" [readonly]="true"></textarea>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="cancelarEdicao()"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarFormula()"><i class="fas fa-check"></i>
          Salvar</button>
      </p-footer>
    </p-dialog>

    <!--MODAL EDITAR PROJETO FEITO -->
    <p-dialog header="Editar Projeto" [(visible)]="editProjeto" [modal]="true" appendTo="body" [closable]="true"
      [style]="{ width: '500px' }">
      <div class="form-row">
        <div class="form-group col-sm-7">
          <label>Projeto</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesProjetos"
            [(ngModel)]="proj" placeholder="Selecionar" optionLabel="label"></p-dropdown>
        </div>
        <div class="form-group col-sm-2">
          <label>Porcentagem</label>
          <input class="form-control text-right" value="{{porcentagem}}" [(ngModel)]="porcentagem" money />
        </div>
        <div class="form-group col">
          <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
          <button type="button" class="btn btn-sm btn-info" [disabled]="verifSaldoRateio()" (click)="incluirRateio()"><i
              class="fas fa-plus"></i> Adicionar</button>
        </div>
      </div>

      <p-table class="table-system" [value]="porcentagem">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 70%">PROJETO</th>
            <th style="width: 20%" class="text-right">PORCENTAGEM</th>
            <th style="width: 10%" class="text-center">AÇÃO</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td>
              {{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}
            </td>
            <td class="text-right">
              {{ rowData.porcentagem }}
            </td>
            <td class="text-center">
              <span class="delete" matTooltip="Excluir Projeto!" (click)="removerRateioCadProj(rowData)">
                <i class="fas fa-trash-alt"></i>
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-footer>
        <div *ngIf="rateioProj !== 0 && rateioProj !== 100"><strong style="color: #555555;">Rateio
            Disponível:</strong>
          {{rateioProj}}%</div>
        <button *ngIf="rateioProj === 0 || rateioProj === 100" type="button" class="btn btn-secondary"
          (click)="fecharRateioCadProj()"><i class="fas fa-undo"></i> Fechar</button>
      </p-footer>
    </p-dialog>

    <!--MODAL EDITAR PLANO CONTAS FEITO -->
    <p-dialog header="Editar Plano de Contas" [(visible)]="editarPlanoContas" styleClass="contas-altura-inicial"
      [style]="{ width: '600px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Plano Contas</label>
          <p-dropdown *ngIf="operacaoRegraContabilDetalheEditar.planoContas" class="type-p-dropdown" [filter]="true"
            [options]="opcoesPlanoContas" placeholder="Selecione o Plano de Contas"
            [(ngModel)]="operacaoRegraContabilDetalheEditar.planoContas.id"></p-dropdown>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="cancelarEdicao()"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarAlteracaoPlanoContas()"><i
            class="fas fa-check"></i> Salvar</button>
      </p-footer>
    </p-dialog>

    <!--MODAL EDITAR HISTÓRICO FEITO -->
    <p-dialog header="Editar Histórico Padrão" [(visible)]="editarCadHist" [modal]="true" [responsive]="true"
      appendTo="body" [closable]="true" [style]="{ width: '600px' }">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>HistÃ³rico PadrÃ£o</label>
          <p-dropdown *ngIf="operacaoRegraContabilDetalheEditar.cadHistPadrao" appendTo="body" class="type-p-dropdown"
            [filter]="true" [options]="opcoesCadHist" [(ngModel)]="operacaoRegraContabilDetalheEditar.cadHistPadrao.id">
          </p-dropdown>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="cancelarEdicao()"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarAlteracaoCadHist()"><i class="fas fa-check"></i>
          Salvar</button>
      </p-footer>
    </p-dialog>

    <!--MODAL EDITAR INDICADOR DC FEITO -->
    <p-dialog header="Editar Indicador D/C" [(visible)]="editarIndDc" [modal]="true" [responsive]="true" appendTo="body"
      [closable]="true" [style]="{ width: '600px' }">

      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>Indicador D/C</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesDebitoCredito"
            [(ngModel)]="operacaoRegraContabilDetalheEditar.indDc"></p-dropdown>
        </div>
      </div>

      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="cancelarEdicao()"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarAlteracaoIndDc()"><i class="fas fa-check"></i>
          Salvar</button>
      </p-footer>
    </p-dialog>

    <!--MODAL COPIAR REGRA FEITO -->
    <p-dialog header="Copiar Regra" [(visible)]="copiarRegra" [modal]="true" [responsive]="true" appendTo="body"
      [closable]="false" [style]="{ width: '500px' }">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome da Regra</label>
          <input class="form-control" [(ngModel)]="nomeNovaRegra" placeholder="Digite o nome da regra" />
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)='copiarRegra=false'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" [disabled]="!nomeNovaRegra" (click)="gravarNovaRegra()"><i
            class="fas fa-check"></i> Salvar</button>
      </p-footer>
    </p-dialog>

  </mat-card-content>

  <p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll" [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

    <label *ngIf="clonagem && clonagemUnica">Nome da nova regra contábil</label>
    <input *ngIf="clonagem && clonagemUnica" class="form-control" [(ngModel)]="nomeNovaRegraClonagemGrupoEmpresa" placeholder="Digite o Nome da nova regra contábil" />

    <label style="margin-top: 100px;">{{ descricaoModalGrupoEmpresas }}</label>
    <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
    </p-multiSelect>

    <p-footer class="d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="criarRegraContabil && !editarRegraBoolean ? salvarRegraContabilGrupoEmpresa() : editarRegraContabilGrupoEmpresa()" *ngIf="!clonagem">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>

      <button class="btnCustom-green" (click)="clonarRegraGrupoEmpresa()" *ngIf="clonagem">
        <span class="material-icons">save</span>
        <div>Clonar</div>
      </button>
    </p-footer>
  </p-dialog>
</mat-card>