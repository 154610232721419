<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content p-3" style="margin-top: -25px">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarCadCcus"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta" class="size">

            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroCadCcus" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="onRowSelect($event)" [(selection)]="selectCad"
              (onRowUnselect)="onRowUnselect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCcus'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CÓDIGO <p-sortIcon [field]="'codCcus'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'codCcus', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCcus'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Descrição <p-sortIcon [field]="'descrCcus'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'descrCcus', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtInicial'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Data Inicial <p-sortIcon [field]="'dtInicial'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtInicialFormatoBrasil', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtFinal'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Data Final <p-sortIcon [field]="'dtFinal'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtFinalFormatoBrasil', 'contains')">
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoCcus.descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Tipo Centro de Custo <p-sortIcon [field]="'tipoCcus.descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesCentroCusto" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'tipoCcus.descricao', 'contains')"></p-multiSelect>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Ativo <p-sortIcon [field]="'ativo'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'projetosRelacionados'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Projetos Relacionados <p-sortIcon [field]="'projetosRelacionados'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesProjeto" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'projetosRelacionados', 'contains')"></p-multiSelect>
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" >
                    <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData" >
                    </p-tableRadioButton >
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codCcus}}">{{rowData.codCcus}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descrCcus}}">{{rowData.descrCcus}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtInicial}}">
                        {{rowData.dtInicial | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtFinal}}">
                        {{rowData.dtFinal | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrCcus}}">{{rowData.tipoCcus
                        != null? rowData.tipoCcus.descricao: ''}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo" [binary]="true" inputId="ativo"
                      [disabled]="true"></p-checkbox>
                  </td>

                  <td class="text-center" style="width: 10%;">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.projetosRelacionados" [binary]="true"
                      inputId="projetoRelac" [disabled]="true"></p-checkbox>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
         <div class="form-size">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Código<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="codigo">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Descrição<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricao">
            </div>
          </div>
          <div class="form-row">

            <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
              <label>DATA INICIAL</label>
              <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2010:2030" [(ngModel)]="dataInicial">
              </p-calendar>
            </div>

            <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
              <label>DATA FINAL</label>
              <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
               yearRange="2010:2030" [(ngModel)]="dataFinal">
              </p-calendar>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="ativo">Ativo</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="projetoRelac">Projetos relacionados</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Tipo</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="opcoesTipo" (click)="true" [(ngModel)]="tipo">
              </p-dropdown>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>