<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div>
    <mat-tab-group *ngIf="empresa" [(selectedIndex)]="tabIndex" (selectedTabChange)="tabChange()" dynamicHeight="true">
      <mat-tab label="Destinadas">
        <div class="container-formulario">
          <br>
          <app-period-select *ngIf="tabIndex === 0" [(ngModel)]="period" (change)="periodChange()" [months]="12"
            displayMonths="true" prompt="" filter="contains">
          </app-period-select>


        </div>
      </mat-tab>
      <mat-tab label="Emitidas">
        <div class="container-formulario">
          <br>
          <app-period-select *ngIf="tabIndex === 1" [(ngModel)]="period" (change)="periodChange()" [months]="12"
            displayMonths="true" prompt="" filter="contains">
          </app-period-select>


        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>