<div style="padding: 1.5rem !important;">
  <h4 class="font-weight-bold py-3 mb-4">
    Dashboard Vendas
    <div class="text-muted text-tiny mt-1"><small class="font-weight-normal"
        [innerHTML]="formatarDataExtenso()"></small></div>
  </h4>
  <div class="row">
    <div class="d-flex col-xl-6 align-items-stretch">

      <div class="card d-flex w-100 mb-4">
        <div class="row no-gutters row-bordered row-border-light h-100">
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark">
              <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualNegocia | money}} </span> ({{
                  pedidosNegociacao.length }})</span><br>
                <small class="text-muted">Em Negociação</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark">
              <i class="lnr lnr-hourglass display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualCompra | money}} </span> ({{
                  pedidosCompra.length }})</span><br>
                <small class="text-muted">Vendas</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark">
              <i class="lnr lnr-checkmark-circle display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualFinaliza | money}} </span> ({{
                  pedidosFinalizados.length }})</span><br>
                <small class="text-muted">Finalizado</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark">
              <i class="lnr lnr-cross-circle display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualRecusa| money}}</span> ({{
                  pedidosRecusados.length }})</span><br>
                <small class="text-muted">Recusado</small>
              </span>
            </span>

          </div>
        </div>
      </div>

    </div>
    <div class="d-flex col-xl-6 align-items-stretch">

      <div class="card w-100 mb-4">
        <div class="card-body" style="padding:1.5rem 1rem 0 1.5rem;">
          <div class="card-header-title text-muted" style="font-size: 13px;">Gráfico de Vendas</div>
        </div>
        <div class="px-2 mt-4">
          <figure class="highcharts-figure">
            <highcharts-chart id="chart_3" style="width: 100%; height: 240px; display: block">
            </highcharts-chart>
          </figure>
        </div>
      </div>

    </div>
  </div>


  <div class="row" style="border: none;">

    <div class="col-md-7 col-lg-12 col-xl-3">

      <h6 class="card-header with-elements" style="background-color:#fff;">
        <div class="card-header-title" style="color: #a3a4a6 !important;">Vendas Produtos no Mês</div>
      </h6>
      <div class="py-4 px-3" style="background-color:#fff;height:300px;">
        <figure class="highcharts-figure">
          <highcharts-chart id="chart_1" style="width: 100%; height: 240px; display: block">
          </highcharts-chart>
        </figure>
      </div>
    </div>
    <div class="col-md-7 col-lg-12 col-xl-3">

      <h6 class="card-header with-elements" style="background-color:#fff;">
        <div class="card-header-title" style="color: #a3a4a6 !important;">Venda Serviços no Mês</div>

      </h6>
      <div class="py-4 px-3" style="background-color:#fff;height:300px;">
        <figure class="highcharts-figure">
          <highcharts-chart id="chart_2" style="width: 100%; height: 240px; display: block">
          </highcharts-chart>
        </figure>
      </div>
    </div>

    <div class="col-md-5 col-lg-12 col-xl-6">



      <mat-tab-group class="overflow-tabs">
        <mat-tab label="Itens">
          <div class="card mb-4" style="border: none;">
            <table class="table card-table table-striped">
              <thead>
                <tr>
                  <th><strong>Itens + vendidos</strong></th>
                  <th class="text-right"><strong>Qtde.</strong></th>
                  <th class="text-right"><strong>Vlr. Vendido</strong></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let it of itensTopFive">
                  <tr>
                    <td>{{ it.descrItem }}</td>
                    <td class="text-right">{{ it.quantidade }}</td>
                    <td class="text-right">{{ it.valorTotal | money }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <span style="cursor: pointer; text-transform: capitalize;"
              class="card-footer d-block text-center text-dark small font-weight-semibold"
              (click)="detalharTopFiveItem()">Exibir +</span>
          </div>
        </mat-tab>

        <mat-tab label="Clientes">
          <div class="card mb-4" style="border: none;">
            <table class="table card-table table-striped">
              <thead>
                <tr>
                  <th><strong>Principais Clientes</strong></th>
                  <th class="text-right"><strong>Qtde.</strong></th>
                  <th class="text-right"><strong>Valor</strong></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let emp of empresasTopFive">
                  <tr>
                    <td>{{ emp.nomeEmpresa }}</td>
                    <td class="text-right">{{ emp.quantidade }}</td>
                    <td class="text-right">{{ emp.valorTotal | money }}</td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
            <span style="cursor: pointer; text-transform: capitalize;"
              class="card-footer d-block text-center text-dark small font-weight-semibold"
              (click)="detalharTopFiveEmp()">Exibir +</span>
          </div>
        </mat-tab>
      </mat-tab-group>




    </div>

  </div>

</div>
<p-dialog header="{{ tituloModal }}" [(visible)]="modalMaisInfo" [style]="{width: '1000px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true"
  (onHide)="resetModal()">

  <div class="pull-right align-down-table">
    <button type="button" class="btn btn-link" (click)="dt1.exportCSV()"><i class="fas fa-file-excel excel"></i>
      Exportar para Excel</button>
  </div>
  <div class="clearfix"></div>
  <p-table class="table-system" #dt1 [columns]="colsitensTopFiveDetal" [value]="itensTopFiveDetal" [rows]="rows"
    [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
    immutable="false" selectionMode="single" (onFilter)="onFilter($event)" (onFilter)="onFilter($event)"
    [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''" [scrollable]="itensTopFiveDetal.length >= 15 ? true : false"
    scrollHeight="320px">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codItem'">
            <span class="text-overflow-dynamic-ellipsis">CÓD. ITEM <p-sortIcon [field]="'codItem'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt1.filter($event.target.value, 'codItem', 'contains')">
        </th>
        <th class="text-left" style="width: 45%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrItem'">
            <span class="text-overflow-dynamic-ellipsis">DESC. ITEM <p-sortIcon [field]="'descrItem'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt1.filter($event.target.value, 'descrItem', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
            <span class="text-overflow-dynamic-ellipsis">QUANTIDADE <p-sortIcon [field]="'quantidade'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt1.filter($event.target.value, 'quantidade', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
            <span class="text-overflow-dynamic-ellipsis">VALOR TOTAL <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt1.filter($event.target.value, 'valorTotal', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'porcentagem'">
            <span class="text-overflow-dynamic-ellipsis">PORC. (%) <p-sortIcon [field]="'porcentagem'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt1.filter($event.target.value, 'porcentagem', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codItem }}">{{ rowData.codItem }}</span>
          </span>
        </td>
        <td class="text-left" style="width: 45%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrItem}}">{{rowData.descrItem
              }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.quantidade}}">{{rowData.quantidade
              }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valorTotal | money}}">{{rowData.valorTotal | money }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.porcentagem}}">{{rowData.porcentagem}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="5" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

</p-dialog>

<p-dialog header="{{ tituloModalEmp }}" [(visible)]="modalMaisInfoEmp" [style]="{width: '1000px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true"
  (onHide)="resetModal()">

  <div class="pull-right align-down-table">
    <button type="button" class="btn btn-link" (click)="dt2.exportCSV()"><i class="fas fa-file-excel excel"></i>
      Exportar para Excel</button>
  </div>
  <div class="clearfix"></div>
  <p-table class="table-system" #dt2 [columns]="colsempTopFiveDetal" [value]="empTopFiveDetal" [rows]="rows"
    [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
    immutable="false" selectionMode="single" (onFilter)="onFilter($event)" (onFilter)="onFilter($event)"
    [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''" [scrollable]="empTopFiveDetal.length >= 15 ? true : false"
    scrollHeight="320px">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codEmpresa'">
            <span class="text-overflow-dynamic-ellipsis">CÓD. CLIENTE <p-sortIcon [field]="'codEmpresa'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'codEmpresa', 'contains')">
        </th>
        <th class="text-left" style="width: 45%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeEmpresa'">
            <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nomeEmpresa'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'nomeEmpresa', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
            <span class="text-overflow-dynamic-ellipsis">QUANTIDADE <p-sortIcon [field]="'quantidade'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'quantidade', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
            <span class="text-overflow-dynamic-ellipsis">VALOR TOTAL <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'valorTotal', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'porcentagem'">
            <span class="text-overflow-dynamic-ellipsis">PORC. (%) <p-sortIcon [field]="'porcentagem'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'porcentagem', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codEmpresa }}">{{ rowData.codEmpresa
              }}</span>
          </span>
        </td>
        <td class="text-left" style="width: 45%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeEmpresa}}">{{rowData.nomeEmpresa
              }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.quantidade}}">{{rowData.quantidade
              }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valorTotal | money}}">{{rowData.valorTotal | money }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.porcentagem}}">{{rowData.porcentagem}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="5" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>