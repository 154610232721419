<app-data-table mainTitle="Empresa Parametro Baixa"
                singleTitle="Empresa Parametro Baixa"
                routerPath="/empresa-parametro-baixa"
                entityPath="/empresaparametrobaixa"
                [entity]="empresaParametroBaixa"
                [entityFilter]="entityFilter"
                keyField="id"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
