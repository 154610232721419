<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{active: statusUsuario}"><a
                    (click)="showTelaUsuario('TELA_1')">APROVADORES</a></li>
                <li class="list-inline-item" [ngClass]="{active: statusUsuarioAprovador}"><a
                    (click)="showTelaUsuario('TELA_2')">USUÁRIOS POR APROVADORES</a></li>
              </ul>


              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardUsuario()"
                  (click)="changeStateUsuario()">
                  <span *ngIf="isCardUsuario" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardUsuario" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStateUsuario>

            <!--Table Cadastro Menu-->

            <div [ngStyle]="statusUsuario ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusUsuario'}">
              <div class="row mt-2 mb-3">
                <div class="col-sm-6">
                  <div class="conteudo m-0">
                    <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Aprovadores" [source]="listaUsuarios"
                      [target]="listaAprovadores" [dragdrop]="true" [responsive]="true" filterBy="nome"
                      [showSourceControls]="false" [showTargetControls]="false">
                      <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>
            </div>


            <div [ngStyle]="statusUsuarioAprovador ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusUsuarioAprovador'}">
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title" style="border-bottom: none;"><i class="fas fa-user"></i> Aprovadores Técnicos
                  </h3>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-3">
                  <p-dropdown class="type-p-dropdown" [filter]="false" [options]="aprovadoresTecnicos"
                    placeholder="Selecione o Aprovador" [(ngModel)]="aprovId" (onChange)="buscarUsuariosAprovador()">
                  </p-dropdown>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title" style="border-bottom: none;"><i class="fas fa-users"></i> Usuários</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="conteudo m-0">
                    <p-pickList *ngIf="picklistState2" sourceHeader="Disponíveis" targetHeader="Usuários Relacionados"
                      [source]="listaUsuariosRelacionar" [target]="listaUsuariosRelacionados" [dragdrop]="true"
                      [responsive]="true" filterBy="nome" [showSourceControls]="false" [showTargetControls]="false">
                      <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
