<mat-card class="card-linnks margin-linnks">
    <mat-card-title>
      {{title}}
      <mat-card-subtitle>
        {{subtitle}}
      </mat-card-subtitle>
    </mat-card-title>



  <mat-card-content>
    <div class="box-content pt-2">
      <div class="form-row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-ticket-alt"> </i> Tipo Ingresso</h3>
        </div>
        <div class="form-group col-sm-6">
            <!-- <label>Tipo Ingresso:</label> -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="tpingresso"
              [(ngModel)]="tpingressoValue" (onChange)="onChangeIngresso()"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-bars"> </i> DESCRIÇÃO</h3>
        </div>
        <div class="form-group col-sm-12">
            <!-- <label>Tipo Ingresso:</label> -->
            <div class="input-12" input-vertical="middle">
              <textarea [froalaEditor]="optionsDescFroola" class="field card-back-description" id="textAreaTwoSize"
                [(ngModel)]="descricao" autofocus></textarea>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-bars"> </i> Descrição Complementar</h3>
        </div>
        <div class="form-group col-sm-12">
            <!-- <label>Tipo Ingresso:</label> -->
            <textarea [froalaEditor]="optionsDescFroola" class="field card-back-description" id="textAreaTwoSize"
              autofocus [(ngModel)]="descricaoComplementar"></textarea>
        </div>
      </div>
    </div>
  </mat-card-content>

  <div class="ui-g">
      <div class="ui-g-12">

      </div>
    </div>

  </mat-card>
