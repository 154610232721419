<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content d-flex align-items-center justify-content-between">
              <h3 class="title-card" *ngIf="editar || criar">
                Cadastros Participantes
              </h3>
              <ul class="list-inline filter-menu filter-card-header mt-0" *ngIf="!editar && !criar">
                <li class="list-inline-item" [ngClass]="{active: currentScreen === 'c'}"><a
                    (click)="showTela('c')">CLIENTES</a></li>
                <li class="list-inline-item" [ngClass]="{active: currentScreen === 'f'}"><a
                    (click)="showTela('f')">FORNECEDORES</a></li>
                <li class="list-inline-item" [ngClass]="{active: currentScreen === 't'}"><a
                    (click)="showTela('t')">TRANSPORTADORES</a></li>
                <li class="list-inline-item" [ngClass]="{active: currentScreen === 'a'}"><a
                    (click)="showTela('a')">AGÊNCIAS</a></li>
              </ul>
              <div style="display: flex;">
                <button type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu" class="btnCustom-default"
                  matTooltip="Mais opções" *ngIf="!editar && !criar">
                  <span class="material-icons" style="margin-top: -7px;">more_vert</span>
                </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                    <p-checkbox class="mr-1" [(ngModel)]="showPf" [binary]="true"
                      (onChange)="changeMenuOption()"></p-checkbox>
                    <span>Pessoa física</span>
                  </div>
                  <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                    <p-checkbox class="mr-1" [(ngModel)]="showPj" [binary]="true"
                      (onChange)="changeMenuOption()"></p-checkbox>
                    <span>Pessoa jurídica</span>
                  </div>
                  <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                    <p-checkbox class="mr-1" [(ngModel)]="showExterior" [binary]="true" (onChange)="changeMenuOption()">
                    </p-checkbox>
                    <span>Exterior</span>
                  </div>
                  <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                    <p-checkbox class="mr-1" [(ngModel)]="showInativos" [binary]="true" (onChange)="changeMenuOption()">
                    </p-checkbox>
                    <span>Exibir inativos</span>
                  </div>
                </mat-menu>
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <p-dialog [(visible)]="modalUploadParticipante" styleClass="modal-scroll" [style]="{ width: '700px' }"
            [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
            <p-header>
              <div class="cabecalho">
                <label>Importar participantes</label>
                <div class="botoes-cabecalho">
                  <a href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LEGENDAS_UPLOAD_PARTICIPANTE.pdf"
                    matTooltip="Baixar Legendas" matTooltipPosition="above" target="_blank">
                    <span class="material-icons"
                      style="font-size: 24px; margin-left: 5px; margin-top: -5px; color: #666666;">file_download</span>
                  </a>
                  <a href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_UPLOAD_PARTICIPANTE.csv"
                    matTooltip="Baixar Layout" matTooltipPosition="above">
                    <span class="material-icons"
                      style="font-size: 24px; margin-left: 5px; margin-top: -5px; color: #666666;">cloud_download</span>
                  </a>
                </div>
              </div>
            </p-header>
            <div class="form-row">
              <div class="form-group col-sm-9 mb-1 mt-1">
                <label class="d-block">Selecione um arquivo</label>
                <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                  <span class="input-group-append">
                    <span class="input-group-text btn-file">
                      <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                        Arquivo</span>
                      <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                        Arquivo</span>
                      <input type="file" #file (change)="fileChange()" />
                    </span>
                  </span>
                  <div class="form-control" data-trigger="fileinput">
                    <span class="fileinput-filename" id="file-name"></span>
                  </div>
                </div>
              </div>
              <div class="form-group col-sm-3 mb-0">
                <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                <button type="button" class="btn btn-primary" (click)="importar()"><i class="fas fa-upload"></i>
                  Importar</button>
              </div>
            </div>
            <p-footer>
              <button type="button" class="btn btn-secondary" (click)="modalUploadParticipante = false;"><i
                  class="fas fa-undo"></i> Cancelar</button>
            </p-footer>
          </p-dialog>

          <div class="box-content pt-2" [@changeDivSize]=currentStateCad>
            <!-- inicio tabela em p-table -->
            <app-skeleton [config]="skeletonConfig" *ngIf="!editar && !criar">
              <p-table class="table-system" #dt2 [value]="participanteList" [rows]="rows" [paginator]="true"
                (onFilter)="onFilterParticipante($event)" (onRowSelect)="selectCheckbox($event)"
                (onRowUnselect)="unselectCheckbox($event)" [pageLinks]="pageLinks"
                emptyMessage="Nenhum Registro Encontrado" [rowsPerPageOptions]="rowsPerPage"
                (onHeaderCheckboxToggle)="selectTodos($event)" [style]="{'width':'100%'}" [lazy]="true"
                (onLazyLoad)="participante_lazy_on($event)" [totalRecords]="totalRecords">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 40px;">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>

                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CODIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'nome', 'contains')">
                    </th>
                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                        <span class="text-overflow-dynamic-ellipsis">FANTASIA <p-sortIcon [field]="'fantasia'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'fantasia', 'contains')">
                    </th>

                    <th class="text-left" style="width: 13%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                        <span class="text-overflow-dynamic-ellipsis">CNPJ/CPF <p-sortIcon [field]="'cnpj'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'cnpj', 'contains')">
                    </th>

                    <th class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ie'">
                        <span class="text-overflow-dynamic-ellipsis">INSC. ESTADUAL <p-sortIcon [field]="'ie'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'ie', 'contains')">
                    </th>

                    <th class="text-left" style="width: 13%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'regime_trib'">
                        <span class="text-overflow-dynamic-ellipsis">REG. TRIBUTÁRIO <p-sortIcon
                            [field]="'regime_trib'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'regime_trib', 'contains')">
                    </th>

                    <th class="text-left" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                        <span class="text-overflow-dynamic-ellipsis">SITUAÇÃO CNPJ <p-sortIcon [field]="'status'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'status', 'contains')">
                    </th>

                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'atualizado'">
                        <span class="text-overflow-dynamic-ellipsis">ATUALIZADO EM <p-sortIcon [field]="'atualizado'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'atualizadoFormatado', 'contains')">
                    </th>

                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                        <span class="text-overflow-dynamic-ellipsis">UF<p-sortIcon
                            [field]="'uf'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'uf', 'contains')">
                    </th>

                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                        <span class="text-overflow-dynamic-ellipsis">STATUS PARTICIPANTE<p-sortIcon [field]="'ativo'">
                          </p-sortIcon></span>
                      </span>
                      <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                        [options]="opcoesStatusParticipante" defaultLabel="Selecione" [filter]="false"
                        (onChange)="dt2.filter($event.value, 'ativo', 'in')">
                      </p-multiSelect>
                    </th>

                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuarioCadastro'">
                        <span class="text-overflow-dynamic-ellipsis">CADASTRO POR<p-sortIcon
                            [field]="'usuarioCadastro'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'usuarioCadastro', 'contains')">
                    </th>

                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'horarioCadastro'">
                        <span class="text-overflow-dynamic-ellipsis">CADASTRADO EM <p-sortIcon
                            [field]="'horarioCadastro'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'horarioCadastro', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData" [ngClass]="{ inativo: !rowData.ativo }">
                    <td class="text-center" style="width: 40px;">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>
                    <td class="text-left" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">
                          {{rowData.codigo | uppercase }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nome | uppercase}}">{{rowData.nome | uppercase }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.fantasia | uppercase}}">{{rowData.fantasia | uppercase}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj | cnpj}}">{{rowData.cnpj
                          |
                          cnpj}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ie | uppercase}}">{{rowData.ie
                          | uppercase }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.regime_trib | uppercase}}">{{rowData.regime_trib | uppercase }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.status | uppercase}}">{{rowData.status | uppercase }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.atualizado | date:'dd/MM/yyyy' : '+000'}}">{{rowData.atualizado |
                          date:'dd/MM/yyyy' : '+000' }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.uf | uppercase}}">{{rowData.uf | uppercase }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ativo? 'ATIVO' : 'INATIVO'}}">{{rowData.ativo? 'ATIVO' : 'INATIVO'
                          }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.usuarioCadastro}}">{{rowData.usuarioCadastro }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.horarioCadastro}}">{{rowData.horarioCadastro }}</span>
                      </span>
                    </td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="12" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </app-skeleton>

            <div class="col-sm-6 p-0"
              style="overflow-x: hidden; overflow-y: auto; background-color: #fff; min-width: 811px;"
              *ngIf="criar || editar">
              <mat-tab-group class="custom-header-group">

                <mat-tab label="DADOS PARTICIPANTE" appendTo="body">
                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div class="form-row">
                      <div class="col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-bars"></i> Informações participante</h3>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-3">
                        <div class="p-field-checkbox mb-3 ml-1">
                          <p-checkbox class="mr-1" [(ngModel)]="pesquisaApi" [binary]="true" inputId="pesquisaApi"
                            (click)="mudarTipoBusca($event, 'BUSCA AUTOMÁTICA')"></p-checkbox>
                          <label for="pesquisaApi" class="ml-1" style="position: relative;top: 3px">Busca
                            Automática</label>
                        </div>
                      </div>
                      <div class="form-group col-sm-9">
                        <div class="p-field-checkbox mb-3 ml-1">
                          <p-checkbox class="mr-1" [(ngModel)]="manual" [binary]="true" inputId="manual"
                            (click)="mudarTipoBusca($event, 'INCLUSÃO MANUAL')"></p-checkbox>
                          <label for="manual" class="ml-1" style="position: relative;top: 3px">Inclusão Manual</label>
                          <span class="icon-info"><i class="fas fa-question-circle" style="margin-left: 40px"
                              matTooltip="Flegar somente quando a busca automática pelo CNPJ não ocorrer!"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-7 mb-3">
                        <label>CNPJ/CPF A VERIFICAR<span class="obrigatorio">*</span></label>
                        <div class="input-group">
                          <input type="text" class="form-control" placeholder="Pesquisar" [(ngModel)]="cnpjVerificar"
                            [disabled]="editar" value="{{cnpjVerificar}}" (keyup)="verificarCnpjCpf()"
                            (keyup.enter)="pesquisar()">
                          <div class="input-group-append">
                            <button *ngIf="pesquisaApi" class="btn btn-sm btn-primary" type="button"
                              (click)="pesquisar();"><i class="fas fa-search"></i></button>
                          </div>
                        </div>

                      </div>
                      <div class="form-group col-sm-5 mb-3">
                        <label class="d-block" style="visibility: hidden; margin-top: 10px;">DO NOT REMOVE</label>
                        <div class="p-field-checkbox mb-3 ml-1">
                          <p-checkbox class="mr-1" [(ngModel)]="exterior" [binary]="true" inputId="exterior">
                          </p-checkbox>
                          <label for="exterior" class="ml-1" style="position: relative;top: 3px">Participante
                            Domiciliado no Exterior</label>
                        </div>
                      </div>
                    </div>
                    <!-- NOVO CAMPO -->

                    <div class="form-row" *ngIf="!isCnpj">
                      <div class="form-group col-sm-4 mb-3">
                        <label>DATA DE NASCIMENTO</label>
                        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                          [(ngModel)]="participante.dtNascimento" [yearNavigator]="true" [defaultDate]="defaultDate"
                          yearRange="1900:2030" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"></p-calendar>
                      </div>
                      <div class="form-group col-sm-4 mb-3">
                        <label>CÓDIGO DE IDENTIFICAÇÃO ÚNICA</label>
                        <input class="form-control" type="text" [(ngModel)]="participante.rg">
                      </div>
                      <div class="form-group col-sm-4 mb-3">
                        <label>ESTADO CIVIL</label>
                        <input class="form-control" type="text" [(ngModel)]="participante.estadoCivil">
                      </div>
                    </div>
                    <!-- FIM NOVO CAMPO -->
                    <div class="form-row">
                      <div class="form-group col-sm-12 mb-3"
                        *ngIf="(!editar && (isCnpj || isCpf || exterior || manual))">
                        <label>TIPO PARTICIPANTE<span class="obrigatorio">*</span></label>
                        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tipos"
                          [(ngModel)]="participanteEmpresa.participanteTipo.id" appendTo="body"
                          (onChange)="verificarCnpjCpf()">
                        </p-dropdown>
                      </div>
                    </div>
                    <div class="form-row" *ngIf="isCnpj || exterior || manual || isCpf">
                      <div class="form-group col-sm-12">
                        <label for="inputFantasia">NOME FANTASIA<span class="obrigatorio">*</span></label>
                        <input class="form-control" type="text" [(ngModel)]="participanteEmpresa.fantasia"
                          name="inputFantasia">
                      </div>
                    </div>
                    <div class="form-row" *ngIf="isCnpj">
                      <div class="form-group col-sm-12">
                        <label>RAZÃO SOCIAL</label>
                        <input type="text" class="form-control" class="form-control" [disabled]="pesquisaApi"
                          [(ngModel)]="participante.nome" name="inputNome" />
                      </div>
                    </div>
                    <div class="form-row" *ngIf="isCpf">
                      <div class="form-group col-sm-12">
                        <label>NOME COMPLETO<span class="obrigatorio">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="participante.nome" />
                      </div>
                    </div>
                    <div class="form-row" *ngIf="(isCpf || isCnpj || manual || exterior) && !criar">
                      <div class="form-group col-sm-12">
                        <label>CONTA CONTÁBIL</label>
                        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                          [options]="opcoesPlanoContas" [disabled]="editar || exterior"
                          [(ngModel)]="participanteEmpresa.planoContas.id"></p-dropdown>
                        <!--<p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                          [options]="opcoesPlanoContas" (click)="buscarPc()"
                          [(ngModel)]="participanteEmpresa.planoContas.id"></p-dropdown>-->
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-sm-8">
                        <label>SEGMENTO</label>
                        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                          [options]="segmentos" [(ngModel)]="segmentoSelecionado"></p-dropdown>
                      </div>
                      <div class="form-group col-sm-4 mb-3">
                        <label>FONE
                          <span class="obrigatorio" *ngIf="parametroFoneObrigatorio">*</span>
                        </label>

                        <!-- dropdown de paises (bandeira e ddi) -->
                        <div class="d-flex form-row">

                          <!--<p-dropdown [options]="countries" [(ngModel)]="selectedCountry" [style]="{'width':'150px'}"
                              class="col-3" filter="true">
                              <ng-template let-country pTemplate="selectedItem">
                                <img class="flagImg"
                                  src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/{{country.value.toLowerCase()}}.svg" />
                                <span>
                                  {{country.label}}
                                </span>
                              </ng-template>
                              <ng-template let-country pTemplate="item">
                                <div class="ui-helper-clearfix">
                                  <img class="flagImg"
                                    src="https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/{{country.value.toLowerCase()}}.svg" />
                                  <span>
                                    {{country.label}}
                                  </span>
                                </div>
                              </ng-template>
                            </p-dropdown>-->

                          <input class="form-control col-9" type="text" [(ngModel)]="telefone"
                            placeholder="+55 (00)00000-0000" />
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col mb-3" *ngIf="isCnpj">
                        <label>CNAE</label>
                        <input type="text" class="form-control" [(ngModel)]="participante.cnae" />
                      </div>
                    </div>

                    <!-- NOVOS CAMPOS -->
                    <div class="form-row">
                      <div class="form-group col-sm-4 mb-3" *ngIf="!isCnpj">
                        <label>PROFISSÃO</label>
                        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tiposProfissao"
                          [(ngModel)]="participante.profissao" appendTo="body">
                        </p-dropdown>
                      </div>
                      <div class="form-group col-sm-4 mb-3" *ngIf="!isCnpj">
                        <label>GÊNERO</label>
                        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tiposGenero"
                          [(ngModel)]="participante.genero" appendTo="body">
                        </p-dropdown>
                      </div>

                      <div class="form-group col-sm-4 mb-3" *ngIf="!isCnpj">
                        <label>FORMA DE CONHECIMENTO</label>
                        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tiposFormaConhecimento"
                          [(ngModel)]="participante.formaConhecimento" appendTo="body">
                        </p-dropdown>
                      </div>


                      <!-- FIM NOVO CAMPO -->
                      <!-- <div class="form-row" *ngIf="isCpf">
                        <div class="form-group col-sm-12">
                          <label>NOME COMPLETO<span class="obrigatorio">*</span></label>
                          <input type="text" class="form-control" [(ngModel)]="participante.nome" />
                        </div>
                      </div> -->
                      <!-- NOVOS CAMPOS -->


                      <div class="form-row col-sm-12 mb-3">

                        <div class="form-group col-sm-6 "
                          *ngIf="participanteEmpresa.participanteTipo && (((participanteEmpresa.participanteTipo.id === 1 || participanteEmpresa.participanteTipo.id === 3) || (this.existeCliente === false && this.existeFornecedor === true)))">
                          <label>LIMITE DE CRÉDITO</label>
                          <input type="number" class="form-control text-left"
                            [(ngModel)]="participanteEmpresa.limiteCredito" />
                        </div>
                        <div class="form-group col-sm-6 "
                          *ngIf="participanteEmpresa.participanteTipo && (((participanteEmpresa.participanteTipo.id === 2 || participanteEmpresa.participanteTipo.id === 3) || (this.existeFornecedor === false && this.existeCliente === true)))">
                          <label>LIMITE DE COMPRA</label>
                          <input type="number" class="form-control text-left"
                            [(ngModel)]="participanteEmpresa.limiteCompra" />
                        </div>

                      </div>


                      <!-- FIM NOVO CAMPO -->

                    </div>
                    <div class="form-row">
                      <div class="form-group col-sm-12 mb-0">
                        <label>E-MAIL DO PARTICIPANTE <span style="text-transform:initial;">(Para mais de um separar por
                            ';')</span></label>
                        <input type="text" class="form-control text-left"
                          [(ngModel)]="participanteEmpresa.destinatarios" />
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-12">
                        <label>OBSERVAÇÕES</label>
                        <textarea [(ngModel)]="participante.observacoes" class="form-control text-left"
                          maxlength="255"></textarea>
                      </div>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab label="FISCAL">
                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div class="form-row">
                      <div class="col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-bars"></i> Informações Fiscais</h3>
                      </div>
                    </div>
                    <div *ngIf="!exterior">


                      <div class="form-row">
                        <div class="form-group col-sm-12 text-left">
                          <label>REGIME TRIBUTÁRIO</label>
                          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                            [options]="regimeTributario" [(ngModel)]="participante.regimeTributario.id"></p-dropdown>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12 text-left">
                          <label>INSCRIÇÃO ESTADUAL</label>
                          <input type="text" class="form-control text-left" [(ngModel)]="participante.ie"
                            name="inputIe" />
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-4 text-left">
                          <mat-slide-toggle [(ngModel)]="participanteEmpresa.contribuinte">
                            CONTRIBUINTE ICMS
                          </mat-slide-toggle>
                        </div>

                        <div class="form-group col-sm-4 text-left">
                          <mat-slide-toggle [(ngModel)]="participanteEmpresa.contribuinteIpi">
                            IPI
                          </mat-slide-toggle>
                        </div>

                        <div class="form-group col-sm-4 text-left">
                          <mat-slide-toggle [(ngModel)]="participanteEmpresa.orgaoPublico">
                            Orgão Público
                          </mat-slide-toggle>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-12 text-left">
                          <label>Tipo de Contribuinte</label>
                          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                            [options]="opcoesContribuinte" [(ngModel)]="participanteEmpresa.tipoContribuinte.id">
                          </p-dropdown>
                        </div>
                      </div>
                      <!-- <div class="div-sub-title"><span class="text-sub-title">CONTA CONTÁBIL</span></div> -->
                      <div class="form-row"
                        *ngIf="(participanteEmpresa.tipoContribuinte.id === 3 || participanteEmpresa.tipoContribuinte.id === 4)">
                        <div class="form-group col">
                          <label>Suframa</label>
                          <input type="text" class="form-control text-left" [(ngModel)]="participante.suframa" />
                        </div>
                        <div class="form-group col-sm-2" *ngIf="participante.suframa">
                          <label>Data Validade</label>
                          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                            [yearNavigator]="true" [(ngModel)]="participante.dtSuframa" [defaultDate]="defaultDate"
                            yearRange="2010:2030">
                          </p-calendar>
                        </div>
                        <div class="col-sm-2" *ngIf="participante.suframa">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <button type="button" class="btn btn-sm btn-info"
                            (click)="modalBeneficios=true;">Benefícios</button>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12 text-left">
                          <label>INSCRIÇÃO MUNICIPAL</label>
                          <input type="text" class="form-control text-left" [(ngModel)]="participante.im" />
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <mat-slide-toggle [(ngModel)]="participanteEmpresa.inscricaoCene">
                            Inscrito Município Tomador ISS
                          </mat-slide-toggle>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12 text-left">
                          <label>Tipo Assinante (PARA SERVIÇOS DE COMUNICAÇÃO)</label>
                          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" appendTo="body"
                            [options]="opcoesTipoAssinante" [(ngModel)]="participanteEmpresa.tipoAssinante.id">
                          </p-dropdown>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12 text-left">
                          <label>Tipo de Cliente (PARA SERVIÇOS DE COMUNICAÇÃO) </label>
                          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" appendTo="body"
                            [options]="opcoesTipoClienteServicosComunicacao"
                            [(ngModel)]="participanteEmpresa.tipoClienteServicosComunicacao.id">
                          </p-dropdown>
                        </div>
                      </div>

                    </div>
                    <!--FINAL DADOS FISCAIS-->
                    <div class="form-row" *ngIf="exterior">
                      <div class="form-group col-sm-12 text-left">
                        <label>TAX ID <span class="obrigatorio">*</span></label>
                        <input type="text" class="form-control text-left" [(ngModel)]="participanteEmpresa.nif" />
                      </div>
                    </div>

                    <!--(!editar && (isCnpj || isCpf)) || exterior || manual-->
                    <!-- <div *ngIf="manual">
                                <div class="form-row">
                                  <div class="form-group col-sm-12 text-left">
                                    <label> CONTA CONTÁBIL</label>
                                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                                      [options]="opcoesPlanoContas" [(ngModel)]="participanteEmpresa.planoContas.id"></p-dropdown>
                                  </div>
                                </div>
                              </div> -->
                  </div>
                </mat-tab>

                <mat-tab label="ENDEREÇO">

                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div *ngIf="isCnpj || isCpf || exterior || manual">
                      <div class="form-row">
                        <div class="col-sm-12">
                          <h3 class="sub-title"><i class="fas fa-map-marker-alt"></i> Endereço <button
                              class="btn btn-sm btn-info pull-right" type="button" style="margin-top: -8px;"
                              (click)="novoEndereco();"><i class="fas fa-plus"></i> Adicionar</button></h3>
                        </div>
                      </div>
                      <div class="alert alert-xs alert-primary d-block" role="alert"
                        *ngIf="participante.participanteEnderecos.length==0">
                        <i class="fas fa-info-circle"></i> Adicione um endereço!
                      </div>

                      <div *ngIf="participante.participanteEnderecos.length>0">

                        <ng-container *ngFor="let end of participante.participanteEnderecos">

                          <mat-card class="form-control" style="box-shadow: none; margin-bottom: 10px; height: auto">

                            <div class="d-flex justify-content-between alig-items-center" style="margin-left: 9px;">

                              <mat-card-title class="custom-mat-card-title">
                                ENDEREÇO {{end.enderecoTipo.descricao}}
                              </mat-card-title>

                              <div>
                                <span class="card-actions" (click)="enderecoEditar(end)" matTooltip="Editar Endereço">
                                  <i class="material-icons">edit</i>
                                </span>
                                <span class="card-actions" (click)="enderecoExcluir(end)" matTooltip="Excluir Endereço">
                                  <i class="material-icons">delete</i>
                                </span>
                              </div>

                            </div>
                            <mat-card-content class="custom-mat-card-content d-flex flex-column">
                              <span *ngIf="exterior">{{end.endereco.pais.nome}}</span>
                              <span *ngIf="end.endereco.cidade"> {{end.endereco.cidade}}</span>
                              <span>
                                {{end.endereco.logradouro}}
                                {{end.endereco.numero ?' , '+end.endereco.numero :''}}
                              </span>

                              <span *ngIf="end.endereco.complemento"> - {{end.endereco.complemento }}</span>
                              <span *ngIf="end.endereco.bairro">{{end.endereco.bairro}}</span>
                              <span *ngIf="end.endereco.cep">CEP: {{end.endereco.cep | cep}}</span>
                              <span>
                                {{end.endereco.municipioIbge.nome}}
                                {{end.endereco.uf.uf?'- '+end.endereco.uf.uf:''}}
                              </span>

                              <span>Principal: {{((end.principal)?'Sim':'Não')}}</span>
                            </mat-card-content>
                          </mat-card>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab label="CONTATO">
                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div class="form-row">
                      <div class="col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-user"></i> Contato <button
                            class="btn btn-sm btn-info pull-right" type="button" style="margin-top: -8px;"
                            (click)="contatoNovo(); informaContatoPrincipalMsg();"><i class="fas fa-plus"></i>
                            Adicionar</button></h3>
                      </div>
                    </div>
                    <div class="alert alert-xs alert-primary d-block" role="alert"
                      *ngIf="participante.participanteContatos.length==0">
                      <i class="fas fa-info-circle"></i> Adicione um contato!
                    </div>
                    <div *ngIf="participante.participanteContatos.length>0">
                      <ng-container *ngFor="let contact of participante.participanteContatos">
                        <mat-card class="form-control" style="box-shadow: none; margin-bottom: 10px;">
                          <mat-card-header style="display:block;">
                            <div style="float: right;margin-top:-25px;">
                              <span class="edit" (click)="contatoEditar(contact); informaContatoPrincipalMsg();"
                                matTooltip="Editar Contato"><i class="fas fa-edit"></i></span>
                              <span class="delete" (click)="contatoExcluir(contact);" matTooltip="Excluir Contato"><i
                                  class="fas fa-trash-alt"></i></span>
                            </div>
                            <mat-card-title class="custom-mat-card-title">
                              CONTATO - {{contact.tipoContato}}
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content class="custom-mat-card-content">
                            <span *ngIf="contact.contato">Contato: {{contact.contato}} <br> </span>
                            <span *ngIf="contact.fone">Fone: {{contact.fone}}<br /></span>
                            <span *ngIf="contact.email">Email: {{contact.email}}<br /></span>
                            <span>Principal: {{((contact.principal)?'Sim':'Não')}}</span>
                          </mat-card-content>
                        </mat-card>
                      </ng-container>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab label="DADOS BANCÁRIOS">
                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div class="form-row">
                      <div class="col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Dados Bancários
                          <button type="button" class="btn btn-sm btn-info pull-right" style="margin-top: -8px;"
                            (click)="dadoBancarioNovo(); informaContaPrincipalMsg();"><i class="fas fa-plus"></i>
                            Adicionar</button>
                        </h3>
                      </div>
                    </div>
                    <div class="alert alert-xs alert-primary d-block" role="alert"
                      *ngIf="participante.participanteDadosBancarios.length==0">
                      <i class="fas fa-info-circle"></i> Adicione os dados bancários!
                    </div>
                    <div *ngIf="participante.participanteDadosBancarios.length>0">
                      <ng-container *ngFor="let conta of participante.participanteDadosBancarios">
                        <mat-card class="form-control" style="box-shadow: none; margin-bottom: 10px;">

                          <mat-card-header style="display:block;">
                            <div style="float: right;margin-top:-25px;">
                              <span class="edit" (click)="dadoBancarioEditar(conta); informaContaPrincipalMsg();"
                                matTooltip="Editar Conta"><i class="fas fa-edit"></i></span>
                              <span class="delete" (click)="dadoBancarioExcluir(conta);" matTooltip="Excluir Conta"><i
                                  class="fas fa-trash-alt"></i></span>
                            </div>
                            <mat-card-title class="custom-mat-card-title">
                              CONTA - {{conta.tipoConta}}
                            </mat-card-title>
                          </mat-card-header>
                          <mat-card-content class="custom-mat-card-content">
                            <span *ngIf="conta.banco">Banco: {{conta.banco.codigo}} - {{conta.banco.descricao}} <br>
                            </span>
                            <span *ngIf="conta.agencia">Agência: {{conta.agencia}}<br /></span>
                            <span *ngIf="conta.contaNumero">Conta: {{conta.contaNumero}}</span>
                            <span *ngIf="conta.contaDv">-{{conta.contaDv}}</span>
                            <span>Principal: {{((conta.contaPrincipal)?'Sim':'Não')}}</span>
                          </mat-card-content>
                        </mat-card>
                      </ng-container>
                    </div>
                  </div>
                  <!-- DADOS DO PIX -->
                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div class="form-row">
                      <div class="col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Chaves PIX
                          <button type="button" class="btn btn-sm btn-info pull-right" style="margin-top: -8px;"
                          (click)="ativaModalDadosPix();"><i class="fas fa-plus"></i>
                          Adicionar</button>
                        </h3>
                      </div>
                    </div>
                    <div class="alert alert-xs alert-primary d-block" role="alert" *ngIf="chavesPix.length==0">
                      <i class="fas fa-info-circle"></i> Adicione as chaves PIX!
                    </div>
                    <div *ngIf="chavesPix.length>0">
                      <ng-container *ngFor="let chavePix of chavesPix">
                        <mat-card class="form-control" style="box-shadow: none; margin-bottom: 10px;">

                          <mat-card-header style="display:block;">
                            <div style="float: right;">
                              <span class="edit" (click)="dadosPixEditar(chavePix);"
                                matTooltip="Editar Chave PIX"><i class="fas fa-edit"></i></span>
                              <span class="delete" (click)="dadosPixExcluir(chavePix);" matTooltip="Excluir Chave PIX"><i
                                  class="fas fa-trash-alt"></i></span>
                            </div>
                          </mat-card-header>
                          <mat-card-title class="custom-mat-card-title">
                            TIPO: {{chavePix.descricaoChavePix}}
                          </mat-card-title>
                          <mat-card-content class="custom-mat-card-content">
                            <span>Chave: {{chavePix.chavePix}} <br>
                            </span>
                          </mat-card-content>
                        </mat-card>
                      </ng-container>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab label="COMISSÃO" *ngIf="participanteEmpresa.participanteTipo.id === 5">
                  <div class="box-content p-3" style="padding-bottom: 0 !important;">
                    <div class="form-row">
                      <div class="col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-money-check-alt"></i> Comissão
                        </h3>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-2">
                        <label>% de Comissão</label>
                        <input (blur)="formataPorcentagemComissao()" class="form-control text-right" type="text"
                          [(ngModel)]="participanteEmpresa.porcentagemComissao" />
                      </div>

                      <div class="form-group col-sm-8 text-left">
                        <mat-slide-toggle [(ngModel)]="participanteEmpresa.permiteAlterarComissao">
                          Permite Alterar Comissão Pedido
                        </mat-slide-toggle>
                      </div>

                    </div>

                    <!--<div class="form-row">
                        <div class="form-group col">
                          <label>% de Comissão</label>
                          <input class="form-control text-right" type="text"/>
                        </div>
                        <div class="form-group col-sm-2">
                          <p-checkbox class="mr-2" [binary]="true"
                          inputId="participanteEmpresa.alteraComissaoPedido"></p-checkbox>
                        <label for="participanteEmpresa.alteraComissaoPedido" class="ml-1" style="position: relative;top: 3px">Participante
                          Permite Alterar Comissão Pedido</label>
                        </div>

                      </div>-->


                    <!--<div class="form-group col-sm-2">
                        <label>% de Comissão</label>
                        <input class="form-control text-right" type="text"
                          (blur)="calculaValorUnitImport(); calculaValorTotalItem()" [disabled]="!pedidoItem.item" pInputText
                          [(ngModel)]="pedidoItem.valorUnit" />
                          <input class="form-control text-right" type="text"/>
                      </div>

                        <p-checkbox class="mr-1" [(ngModel)]="participanteEmpresa.ativo"
                          (change)="participanteEmpresa.ativo = !participanteEmpresa.ativo" [binary]="true"
                          inputId="participanteEmpresa.ativo"></p-checkbox>
                        <label for="participanteEmpresa.ativo" class="ml-1" style="position: relative;top: 3px">Participante
                          ativo</label>
                          <p-checkbox class="mr-2" [binary]="true"
                          inputId="participanteEmpresa.alteraComissaoPedido"></p-checkbox>
                        <label for="participanteEmpresa.alteraComissaoPedido" class="ml-1" style="position: relative;top: 3px">Participante
                          Permite Alterar Comissão Pedido</label>-->



                  </div>
                </mat-tab>

              </mat-tab-group>

              <!-- *ngIf="!hideButtons"-->
              <div class="form-column" style="background-color: #fff; padding: 17px;">
                <div class="form-group">
                  <div class="p-field-checkbox mb-3 ml-1">
                    <p-checkbox class="mr-1" [(ngModel)]="participanteEmpresa.ativo"
                      (change)="participanteEmpresa.ativo = !participanteEmpresa.ativo" [binary]="true"
                      inputId="participanteEmpresa.ativo"></p-checkbox>
                    <label for="participanteEmpresa.ativo" class="ml-1" style="position: relative;top: 3px">Participante
                      ativo</label>
                  </div>
                </div>

                <div class="form-group">
                  <!--   exterior: {{exterior}} - criar: {{(criar)}} - isCpf: {{isCpf}} = manual: {{manual}}
                tipo: {{participanteEmpresa.participanteTipo}} - nome: {{participanteEmpresa.nome}} - ou: {{(!participanteEmpresa.participanteTipo || !participanteEmpresa.nome )}}-->
                  <button type="button" class="btn btn-secondary" (click)="voltar()"><i class="fas fa-undo"></i>
                    Cancelar</button>

                  <button type="button" *ngIf="editar" class="btn btn-danger"
                    (click)="cadastroGrupoEmpresas ? excluirParticipanteGrupoEmpresa() : excluirParticipante()"><i
                      class="fas fa-times"></i> Excluir</button>

                  <button type="button" class="btn btn-primary" *ngIf="criar && isCnpj && !manual && !exterior"
                    [disabled]="participanteEmpresa.participanteTipo.id === 0 || !participanteEmpresa.fantasia"
                    (click)="determinarMetodoSalvamentoParticipanteGrupoEmpresa(1)"> <!--salvarParticipante()-->
                    <i class="fas fa-check"></i> Salvar
                  </button>

                  <button type="button" class="btn btn-primary" *ngIf="criar && isCpf && !manual && !exterior"
                    [disabled]="((criar && isCpf) && (participanteEmpresa.participanteTipo.id === 0 || !participanteEmpresa.fantasia  ))
                              || ((participanteEmpresa.participanteTipo.id === 0 || !participanteEmpresa.fantasia  ))"
                    (click)="determinarMetodoSalvamentoParticipanteGrupoEmpresa(2)"> <!--salvarCpfParticipante()-->
                    <i class="fas fa-check"></i> Salvar
                  </button>

                  <button type="button" class="btn btn-primary" *ngIf="(criar && manual && !exterior)"
                    [disabled]="(participanteEmpresa.participanteTipo.id === 0 || !participanteEmpresa.fantasia )"
                    (click)="determinarMetodoSalvamentoParticipanteGrupoEmpresa(3)"> <!--salvarCpfParticipante()-->
                    <i class="fas fa-check"></i> Salvar
                  </button>

                  <button type="button" class="btn btn-primary" *ngIf="(criar && exterior)"
                    [disabled]="(participanteEmpresa.participanteTipo.id === 0 || !participanteEmpresa.fantasia )"
                    (click)="determinarMetodoSalvamentoParticipanteGrupoEmpresa(4)">
                    <!--salvarParticipanteManualExterior()-->
                    <i class="fas fa-check"></i> Salvar
                  </button>

                  <button type="button" class="btn btn-primary" *ngIf="editar"
                    [disabled]="!participanteEmpresa.fantasia"
                    (click)="determinarMetodoSalvamentoParticipanteGrupoEmpresa(5)">
                    <!--salvarAlteracaoParticipante()-->
                    <i class="fas fa-check"></i> Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="box-content col-sm-6 mb-4"
      style="overflow-x: hidden; overflow-y: auto; background-color: transparent; padding: 0; margin-top: -10px; min-width: 811px;"
      *ngIf="criar">
      <div class="custom-header">

        <div class="form-row">
          <div class="form-group col-sm-12 mb-4">
            <label>CNPJ/CPF A VERIFICAR</label>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Pesquisar" [(ngModel)]="cnpjVerificar"
                [disabled]="exterior || editar || manual" value="{{cnpjVerificar}}" (keyup.enter)="pesquisar();">
              <div class="input-group-append">
                <button class="btn btn-sm btn-primary" type="button" (click)="pesquisar();"><i
                    class="fas fa-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->



  </mat-card-content>


  <!---Modal Beneficios-->
  <p-dialog header="Controle beneficios fiscais" [(visible)]="modalBeneficios" [style]="{ width: '600px' }"
    [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalBeneficios">

      <div class="form-row">
        <div class="col-sm-3" input-vertical="middle">
          <mat-slide-toggle [(ngModel)]="participanteEmpresa.beneficioIcms">ICMS</mat-slide-toggle>
        </div>

        <!-- <div class="input-2" input-vertical="middle"><label>Agência</label></div> -->
        <div class="col-sm-3" input-vertical="middle">
          <mat-slide-toggle [(ngModel)]="participanteEmpresa.beneficioPis">PIS</mat-slide-toggle>
        </div>

        <!-- <div class="input-2" input-vertical="middle"><label>Conta</label></div> -->
        <div class="col-sm-3" input-vertical="middle">
          <mat-slide-toggle [(ngModel)]="participanteEmpresa.beneficioCofins">COFINS</mat-slide-toggle>
        </div>

        <!-- <div class="input-3" input-vertical="middle" input-text="right"><label>Conta Principal</label></div> -->
        <div class="col-sm-3" input-vertical="middle">
          <mat-slide-toggle [(ngModel)]="participanteEmpresa.beneficioIpi">IPI</mat-slide-toggle>
        </div>
      </div>

      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">
        </div>
      </div>

    </div>
    <p-footer>
      <!-- <button mat-raised-button class="button-default" (click)='cancelarModalBeneficio();'>Cancelar</button> -->
      <button type="button" class="btn btn-primary" (click)="modalBeneficios=false"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>

  <!---Modal Dados Bancários-->
  <p-dialog header="Controle de Contas" [(visible)]="modalDadosBancarios" [style]="{ width: '500px' }" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalDadosBancarios">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Instituição</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="bancosTipos"
            [(ngModel)]="participanteDadoBancario.banco.id"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-3 text-center">
          <label>Descrição</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteDadoBancario.tipoConta" />
        </div>

        <div class="form-group col-sm-3 text-center">
          <label>Agência</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteDadoBancario.agencia"
            maxlength="4" />
        </div>

        <div class="form-group col-sm-3 text-center">
          <label>Conta</label>
          <input class="form-control text-right" type="text" [(ngModel)]="participanteDadoBancario.contaNumero" />
        </div>

        <div class="form-group col-sm-3 text-center">
          <label>DV</label>
          <input class="form-control text-right" type="text" [(ngModel)]="participanteDadoBancario.contaDv" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <mat-slide-toggle [(ngModel)]="participanteDadoBancario.contaPrincipal"
            [disabled]="(contaPrincipal && !participanteDadoBancario.contaPrincipal)"
            (change)="contaPrincipalChange($event)"><span>Conta Principal</span></mat-slide-toggle>
        </div>
      </div>

      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalDadosBancarios=false; '><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary"
        (click)="dadoBancarioAdicionar(); atualizaVariavelBancarioContaPrincipal(); "><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>

  <!---Modal contatos-->
  <p-dialog header="Controle de Contatos" [(visible)]="modalContato" [style]="{ width: '500px' }" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalContato">
      <div class="form-row">
        <div class="form-group col-sm-6 text-left">
          <label>TIPO</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteContato.tipoContato" />
        </div>
        <div class="form-group col-sm-6 text-left">
          <label>NOME</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteContato.contato" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6 text-left">
          <label>E-MAIL</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteContato.email" />
        </div>
        <div class="form-group col-sm-6 text-left">
          <label>TELEFONE</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteContato.fone"
            placeholder="+55 (00)00000-0000" />
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <mat-slide-toggle [(ngModel)]="participanteContato.principal"
              [disabled]="principal && !participanteContato.principal" (change)="principalChange($event)"><span>Contato
                Principal</span></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalContato=false; '><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary"
        (click)="contatoAdicionar(); atualizaVariavelContatoPrincipal(); "><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>

  <!---Modal endereços-->
  <p-dialog header="Controle de Endereços" styleClass="endereco-altura-inicial" [(visible)]="modalEndereco"
    [style]="{ width: '800px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalEndereco">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Tipo<span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="enderecoTipos"
            [(ngModel)]="participanteEndereco.enderecoTipo.id">
          </p-dropdown>
        </div>
      </div>


      <!-- VERIFICAR FERNANDO -->
      <div class="form-row" *ngIf="!exterior">
        <!-- <div class="form-group col-sm-4 text-left">
          <label>CEP</label>
          <input class="form-control" type="text" *ngIf="exterior" input-text="left"
            [(ngModel)]="participanteEndereco.endereco.cep" />
          <input class="form-control" type="text" *ngIf="!exterior" input-text="left"
            [(ngModel)]="participanteEndereco.endereco.cep" maxlength="8" (keyup.enter)="pesquisarCep();"
            (keypress)="numberOnly($event)" />
          <button type="button" *ngIf="!exterior" class="btn btn-sm btn-info" (click)="pesquisarCep();"><i
            class="fas fa-search"></i> Pesquisar</button>
        </div> -->

        <div class="form-group col-sm-4">
          <label>CEP<span class="obrigatorio">*</span></label>
          <div class="input-group">
            <p-inputMask class="type-p-mask custom-cep" mask="99999-999" [(ngModel)]="participanteEndereco.endereco.cep"
              *ngIf="exterior" placeholder="99999-999">
            </p-inputMask>
            <p-inputMask class="type-p-mask custom-cep" mask="99999-999" [(ngModel)]="participanteEndereco.endereco.cep"
              *ngIf="!exterior" placeholder="99999-999" (keyup.enter)="pesquisarCep();" (keypress)="numberOnly($event)">
            </p-inputMask>
            <div *ngIf="!exterior" class="input-group-append">
              <button type="button" class="btn btn-sm btn-primary" (click)="pesquisarCep();">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="form-group col-sm-2 text-left">
          <label>UF<span class="obrigatorio">*</span></label>
          <!-- <input type="text" input-text="left" [(ngModel)]="participanteEndereco.endereco.uf.id"/>  -->
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="ufs"
            (onChange)="loadMunicipioIbge($event)" [(ngModel)]="participanteEndereco.endereco.uf.id">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-6 text-left" *ngIf="exterior">
          <label>CIDADE<span class="obrigatorio">*</span></label>
          <!--exterior digita cidade-->
          <input class="form-control text-left" type="text" [(ngModel)]="participanteEndereco.endereco.cidade" />
        </div>
        <!--se não exterior escolhe cidade -->
        <div class="form-group col" *ngIf="!exterior">
          <label>MUNICÍPIO<span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
            [options]="cidades" [(ngModel)]="participanteEndereco.endereco.municipioIbge.id"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col text-left" *ngIf="exterior">
          <label>PAÍS <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="paises"
            [(ngModel)]="participanteEndereco.endereco.pais.id" [disabled]="!exterior">
          </p-dropdown>
        </div>
        <div class="form-group col text-left" *ngIf="exterior">
          <label>CIDADE<span class="obrigatorio">*</span></label>
          <!--exterior digita cidade-->
          <input class="form-control text-left" type="text" [(ngModel)]="participanteEndereco.endereco.cidade" />
        </div>
        <div class="form-group col">
          <label>BAIRRO<span class="obrigatorio">*</span></label>
          <input class="form-control" type="text" input-text="left"
            [(ngModel)]="participanteEndereco.endereco.bairro" />
        </div>
        <div class="form-group col text-left">
          <label>LOGRADOURO<span class="obrigatorio">*</span></label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteEndereco.endereco.logradouro" />
        </div>
        <div class="form-group col">
          <label>NÚMERO<span class="obrigatorio">*</span></label>
          <input class="form-control text-left" type="number" [(ngModel)]="participanteEndereco.endereco.numero" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12 text-left">
          <label>COMPLEMENTO</label>
          <input class="form-control text-left" type="text" [(ngModel)]="participanteEndereco.endereco.complemento" />
        </div>
      </div>
      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <mat-slide-toggle [(ngModel)]="participanteEndereco.principal">Principal</mat-slide-toggle>
        </div>
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalEndereco=false'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="adicionarEndereco()"><i class="fas fa-check"></i>
        Salvar</button>

    </p-footer>
  </p-dialog>

  <p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll"
    [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

    <label>{{ descricaoModalGrupoEmpresas }}</label>

    <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true"
      [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
    </p-multiSelect>

    <p-footer class="d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="funcaoModalGrupoEmpresas()">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>
    </p-footer>
  </p-dialog>

  <!---Modal Dados do PIX-->
  <p-dialog header="Chaves PIX" [(visible)]="modalDadosPix" [style]="{ width: '700px' }" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalDadosPix">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Tipo</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesTiposPix"
            [(ngModel)]="chavePixModal.tipoChavePix"></p-dropdown>
        </div>
        <div class="form-group col-sm-8">
          <label>Chave</label>
          <input class="form-control text-left" type="text" [(ngModel)]="chavePixModal.chavePix" />
        </div>
      </div>

      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='cancelaModalDadosPix();'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary"
        (click)="validaDadosPix();"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>

</mat-card>