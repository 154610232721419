<!-- [onlyTable]="false" Quando quer a tabela juntamente com o card -->
<div class="row" *ngIf="!onlyTable">
  <div class="col-sm-12">
    <div class="shadow-box">
      <div class="header-box">
        <div class="header-box-content">
          <ul class="list-inline filter-menu filter-card-header mt-0">
            <li class="list-inline-item" [ngClass]="{active: statusMovimentacao}">{{titulo}}</li>
          </ul>
          <div class="actions-card">
            <button *ngIf="exportable" type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()"
              matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeMovVenc()"
              (click)="changeStateMovVenc()">
              <span *ngIf="isCardMovVenc" class="material-icons" matTooltip="Ocultar Card"
                matTooltipPosition="above">expand_more</span>
              <span *ngIf="!isCardMovVenc" class="material-icons" matTooltip="Ver Card"
                matTooltipPosition="above">expand_less</span>
            </button>
          </div>
        </div>
      </div>
      <div class="box-content" [@changeDivSize]=currentStateMovVenc>
        <div [ngStyle]="statusMovimentacao ? {'display': 'block'} : {'display': 'none'}"
          [ngClass]="{'fade-in': 'statusMovimentacao'}">

          <input class="form-control" type="text" pInputText placeholder="Digite a informação para que seja filtrada..."
            (input)="dt.filterGlobal($event.target.value, 'contains')" *ngIf="globalFilter">
          <br>

          <p-table #dt [columns]="cols" [value]="value"
            [scrollable]="!paginator && (value.length >= 20 || contador >= 20)" scrollHeight="55vh"
            [paginator]="paginator" [rows]="rows" (onFilter)="onFilter($event)" appendTo="body"
            [style]="{'width':'100%'}">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" [pSortableColumn]="col.field"
                  [ngStyle]="getMyStyles(col.style)">{{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns">
                  <input *ngIf="col.filter === true && col.filterType === 'text'" class="form-control" pInputText
                    type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
                  <p-multiSelect appendTo="body" *ngIf="col.filter === true && col.filterType === 'multi'"
                    [options]="getOptions(col.field, col.style)" defaultLabel="Todos"
                    (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns" [ngStyle]="getMyStyles(col.style)">
                  <span matTooltip="{{rowData[col.field]}}"
                    *ngIf="!col.style.startsWith('data')">{{rowData[col.field]}}</span>
                  <span matTooltip="{{rowData[col.field] | date: 'dd/MM/yyyy' }}"
                    *ngIf="col.style.startsWith('data')">{{rowData[col.field] | date: 'dd/MM/yyyy' }}</span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr style="height:25px">
                <td [attr.colspan]="columns.length" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Resultado Encontrado
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- [onlyTable]="true" Quando quer somente a tabela -->
<div class="row" *ngIf="onlyTable">
  <div class="col-sm-12">
    <div class="shadow-box">

      <p-table #dt [columns]="cols" [value]="value" [scrollable]="!paginator && (value.length >= 20 || contador >= 20)"
        scrollHeight="55vh" [paginator]="paginator" [rows]="rows" (onFilter)="onFilter($event)" appendTo="body"
        [style]="{'width':'100%'}">
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix">
            <div class="form-row">
              <div class="form-group col-sm-8">
                <input class="form-control" type="text" pInputText
                  placeholder="Digite a informação para que seja filtrada..."
                  (input)="dt.filterGlobal($event.target.value, 'contains')" *ngIf="globalFilter">
              </div>
              <div class="form-group col-sm-4">
                <button type="button" pButton icon="fa-file-o" iconPos="left" label="Exportar" (click)="dt.exportCSV()"
                  style="float:left" *ngIf="exportable"></button>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field" [pSortableColumn]="col.field"
              [ngStyle]="getMyStyles(col.style)">{{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
          </tr>
          <tr>
            <th *ngFor="let col of columns">
              <input *ngIf="col.filter === true && col.filterType === 'text'" class="form-control" pInputText
                type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
              <p-multiSelect appendTo="body" *ngIf="col.filter === true && col.filterType === 'multi'"
                [options]="getOptions(col.field, col.style)" defaultLabel="Todos"
                (onChange)="dt.filter($event.value, col.field, 'in')"></p-multiSelect>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td *ngFor="let col of columns" [ngStyle]="getMyStyles(col.style)">
              <span matTooltip="{{rowData[col.field]}}"
                *ngIf="!col.style.startsWith('data')">{{rowData[col.field]}}</span>
              <span matTooltip="{{rowData[col.field] | date: 'dd/MM/yyyy' }}"
                *ngIf="col.style.startsWith('data')">{{rowData[col.field] | date: 'dd/MM/yyyy' }}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr style="height:25px">
            <td [attr.colspan]="columns.length" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Resultado Encontrado
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</div>