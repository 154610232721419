<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsFichas" [value]="fichas" [paginator]="true" [rows]="rows"
        [totalRecords]="fichas.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
        [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataEvento'">
                <span class="text-overflow-dynamic-ellipsis">Data Evento <p-sortIcon [field]="'dataEvento'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dataEvento', 'contains')">
            </th>
            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoEvento'">
                <span class="text-overflow-dynamic-ellipsis">Evento <p-sortIcon [field]="'descricaoEvento'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'descricaoEvento', 'contains')">
            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cidadeEvento'">
                <span class="text-overflow-dynamic-ellipsis">Cidade <p-sortIcon [field]="'cidadeEvento'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cidadeEvento', 'contains')">
            </th>

            <th class="text-center" style="width: 6%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'ufEvento'">
                <span class="text-overflow-dynamic-ellipsis">UF <p-sortIcon [field]="'ufEvento'"></p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboUf" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'ufEvento', 'in')"></p-multiSelect>
            </th>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nome', 'contains')">
            </th>


            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>


            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'fone'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'fone', 'contains')">
            </th>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
            </th>


            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataConfLeitura'">
                <span class="text-overflow-dynamic-ellipsis">Confirmação Leitura <p-sortIcon
                    [field]="'dataConfLeitura'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dataConfLeitura', 'contains')">
            </th>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataConfClick'">
                <span class="text-overflow-dynamic-ellipsis">Confirmação Li e Aceito <p-sortIcon
                    [field]="'dataConfClick'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dataConfClick', 'contains')">
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>



            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataEvento}}">{{rowData.dataEvento
                  }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descricaoEvento}}">{{rowData.descricaoEvento }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cidadeEvento}}  ">
                  {{rowData.cidadeEvento}} </span>
              </span>
            </td>
            <td class="text-center" style="width: 6%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.ufEvento}}  ">{{rowData.ufEvento}}
                </span>
              </span>
            </td>

            <td class="text-left" style="width: 12.5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}   ">{{rowData.nome}} </span>
              </span>
            </td>

            <td class="text-center" style="width: 15.5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.email}} "> {{rowData.email}} </span>
              </span>
            </td>


            <td class="text-center" style="width: 15.5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.fone}} "> {{rowData.fone}} </span>
              </span>
            </td>

            <td class="text-center" style="width: 15.5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.cpf}} "> {{rowData.cpf}} </span>
              </span>
            </td>


            <td class="text-center" style="width: 15.5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.dataConfLeitura}} ">
                  {{rowData.dataConfLeitura}} </span>
              </span>
            </td>

            <td class="text-center" style="width: 15.5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.dataConfClick}} ">
                  {{rowData.dataConfClick}} </span>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="10" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


    </div>
  </mat-card-content>
</mat-card>