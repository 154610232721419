<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    <!--Start Btn Back-->
    <button *ngIf="processadoDetalhe && !gerarApuracao && !gerarApuracaoIcmsIpi" class="btn btn-xs btn-link"
      style="padding: 0 !important;" type="button" (click)="backCards()" matTooltip="Voltar" matTooltipPosition="above">
      <span class="material-icons" style="line-height: 1;">west</span>
    </button>
    <button *ngIf="gerarApuracao" class="btn btn-xs btn-link" style="padding: 0 !important;" type="button"
      (click)="showGerarApuracao()" matTooltipPosition="above" matTooltip="Voltar">
      <span class="material-icons" style="line-height: 1;">west</span>
    </button>
    <button *ngIf="gerarApuracaoIcmsIpi" class="btn btn-xs btn-link" style="padding: 0 !important;" type="button"
      (click)="showGerarApuracaoICMSIPI()" matTooltipPosition="above" matTooltip="Voltar">
      <span class="material-icons" style="line-height: 1;">west</span>
    </button>
    <!--End Btn Back-->
    {{title}}
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>

  <div class="row">
    <div class="col">
      <ul class="list-inline filter-menu mb-2 mt-0 d-inline-block">

        <li class="list-inline-item active"><a> Projeto Sped</a></li>
      </ul>
      <div class="pull-right" *ngIf="processadoDetalhe">
        <button *ngIf="(!gerarApuracaoIcmsIpi && !gerarApuracao) || (!gerarApuracaoIcmsIpi && !gerarApuracao)"
          type="button" class="btn btn-sm btn-info" style="padding: 0.25rem 0.8rem !important;"
          (click)="showGerarApuracao()">
          <i class="fas fa-sync-alt"></i> Gerar Apuração
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <!--Arquivos Processados-->
      <div [ngStyle]="!processadoDetalhe && !gerarApuracao ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': '!processadoDetalhe'}">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Arquivos Processados <span>(Período: {{this.dataIniSearch}} à
                  {{this.dataFinSearch}})</span></h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardProcessados()"
                  (click)="changeStateProcessados()">
                  <span *ngIf="isCardProcessados" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardProcessados" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStateProcessados>
            <div class="form-row mt-2">
              <!--Filter Tipo Arquivo-->
              <div class="form-group col-sm-3 col-xxl-2">
                <label>Tipo</label>
                <p-multiSelect #cmp class="type-p-mult" [options]="tipoArquivo" [(ngModel)]="filtroTipo"
                  defaultLabel="Filtre por tipo" (onChange)="filtrarDados()"></p-multiSelect>
              </div>

              <!--Filter Tipo Status-->
              <div class="form-group col-sm-3 col-xxl-2">
                <label>Status</label>
                <p-multiSelect #cmp class="type-p-mult" [options]="statusArquivo" [(ngModel)]="filtroStatus"
                  defaultLabel="Filtre por status" (onChange)="filtrarDados()"></p-multiSelect>
              </div>

              <div class="form-group col-sm-2 col-xxl-1 mb-0">
                <div class="dropdown">
                  <label class="d-block label-hide" style="visibility: hidden; margin-top: 6px;">DO NOT REMOVE</label>
                  <a (click)="DropdownDateEvent()" class="btn btn-outline-secondary dropdown-toggle pl-2 pr-2"
                    role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="fas fa-calendar"></i>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink"
                    [ngStyle]="dropdate ? {'display': 'block'} : {'display': 'none'}">
                    <div class="form-group col-sm-12 mb-1">
                      <!--style="min-width: 130px;"-->
                      <label>DATA INICIAL</label>
                      <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030"
                        [(ngModel)]="dtInicial">
                      </p-calendar>
                    </div>
                    <div class="form-group col-sm-12 mb-2">
                      <!--style="min-width: 130px;"-->
                      <label>DATA FINAL</label>
                      <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                        [yearNavigator]="true" [defaultDate]="DefFinalDate" yearRange="2000:2030" [(ngModel)]="dtFinal">
                      </p-calendar>
                    </div>
                    <div class="form-group col-sm-12 mb-2">
                      <!-- <label class="d-block" style="visibility: hidden; margin-top: 6px;">DO NOT REMOVE</label> -->
                      <button type="button" class="btn btn-info w-100" (click)='atualizarTudo();'><i
                          class="fas fa-refresh"></i> Atualizar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="mt-0">

            <!--ESTRUTURA PARA TABELA ARQUIVOS PROCESSADOS-->

            <p-table class="table-system" #dt1 [columns]="colsFilteredProcess" [value]="tFilteredProcess" [rows]="rows"
              [paginator]="true" [totalRecords]="tFilteredProcess.length" (onFilter)="onFilter($event)"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>

                  <th class="text-center" style="width: 90PX;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                    </span>
                  </th>

                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtIni'">
                      <span class="text-overflow-dynamic-ellipsis">DATA INÍCIO <p-sortIcon [field]="'dtIni'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dtIni', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtFim'">
                      <span class="text-overflow-dynamic-ellipsis">DATA FIM <p-sortIcon [field]="'dtFim'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dtFim', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'cod', 'contains')">
                  </th>

                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao_social'">
                      <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL <p-sortIcon [field]="'razao_social'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'razao_social', 'contains')">
                  </th>

                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                      <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'cnpj', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'tipo', 'contains')">
                  </th>

                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'status', 'contains')">
                  </th>


                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 90px;">
                    <span class="edit" matTooltip="Analisar" (click)="showProcessadoDetalhe(rowData)">
                      <i class="fas fa-search"></i>
                    </span>
                    <span class="shared" matTooltip="Download">
                      <i class="fas fa-download"></i>
                    </span>
                    <span class="delete" matTooltip="Excluir" (click)="removerRegistro(rowData)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtIni}}">{{rowData.dtIni
                        }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtFim}}">{{rowData.dtFim
                        }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cod}}">{{rowData.cod}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.razao_social}}">{{rowData.razao_social}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj}}">{{rowData.cnpj
                        }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo
                        }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                        }}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>




          </div>
        </div>
      </div>

      <!--Item Selecionado-->
      <div class="row" value="tFilteredProcess"
        [ngStyle]="processadoDetalhe || gerarApuracao? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'processadoDetalhe'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card">{{this.tCurrentRazaoSocial}} - <span>(Período: {{this.tCurrentDataInicio}} à
                    {{this.tCurrentDataFim}})</span></h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardItemSelecionado()"
                    (click)="changeStateItemSelecionado()">
                    <span *ngIf="isCardItemSelecionado" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardItemSelecionado" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateItemSelecionado>
              <table class="table table-soft table-bordered table-striped mb-0">
                <thead>
                  <tr>
                    <th class="text-center" style="width: 10%;">Data Início</th>
                    <th class="text-center" style="width: 10%;">Data Fim</th>
                    <th class="text-center" style="width: 10%;">Cód.</th>
                    <th class="text-left" style="width: 20%;">Razão Social</th>
                    <th style="width: 15%;">CNPJ</th>
                    <th class="text-center" style="width: 15%;">Tipo</th>
                    <th style="width: 20%;">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center" style="width: 10%;">
                      <span matTooltip="{{tCurrentDataInicio}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentDataInicio}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 10%;">
                      <span matTooltip="{{tCurrentDataFim}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentDataFim}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 10%;">
                      <span matTooltip="{{tCurrentCodigo}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentCodigo}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span matTooltip="{{tCurrentRazaoSocial}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentRazaoSocial}}</span>
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span matTooltip="{{tCurrentCnpj}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentCnpj}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span matTooltip="{{tCurrentTipo}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentTipo}}</span>
                      </span>
                    </td>
                    <td style="width: 20%;">
                      <span matTooltip="{{tCurrentStatus}}" class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text;">{{tCurrentStatus}}</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!--Arquivo Detalhado (Regras / Relatórios)-->
      <div class="row"
        [ngStyle]="processadoDetalhe && !gerarApuracao && !gerarApuracaoIcmsIpi ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'processadoDetalhe'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: statusRegras}"><a
                      (click)="showTypeLink('LINK_1')">Regras</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusRelatorios}"><a
                      (click)="showTypeLink('LINK_2')">Relatórios</a></li>
                </ul>
                <div class="actions-card">
                  <button *ngIf="statusRegras" type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="statusRelatorios" type="button" class="btn btn-xs btn-link" (click)="dt3.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardProcessadosDetalhe()"
                    (click)="changeStateProcessadosDetalhe()">
                    <span *ngIf="isCardProcessadosDetalhe" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardProcessadosDetalhe" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateProcessadosDetalhe>
              <div [ngStyle]="statusRegras ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusRegras'}">

                <!--ESTRUTURA PARA TABELA ARQUIVOS REGRAS-->

                <p-table class="table-system" #dt2 [columns]="colstRegra" [value]="tRegras" [rows]="rows"
                  [paginator]="true" [totalRecords]="tRegras.length" (onFilter)="onFilterRegra($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="  250px "
                  [ngClass]="contadorLinha < 15 ? 'yes-overflow-x' : ''">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>
                      </th>
                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'cod', 'contains')">
                      </th>
                      <th class="text-left" style="width: 400px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'descricao', 'contains')">
                      </th>
                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ocorrencia'">
                          <span class="text-overflow-dynamic-ellipsis">OCORRÊNCIAS <p-sortIcon [field]="'ocorrencia'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'ocorrencia', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'risco'">
                          <span class="text-overflow-dynamic-ellipsis">GRAU DE RISCO <p-sortIcon [field]="'risco'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'risco', 'contains')">
                      </th>

                      <th class="text-left" style="width: 400px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'justificativa'">
                          <span class="text-overflow-dynamic-ellipsis">JUSTIFICATIVA <p-sortIcon
                              [field]="'justificativa'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'justificativa', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="openCardRegra(rowData)">
                          <i class="fas fa-search"></i>
                        </span>

                      </td>
                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cod}}">{{rowData.cod
                            }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 400px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ocorrencia}}">{{rowData.ocorrencia}}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.risco}}">{{rowData.risco}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 400px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.justificativa}}">{{rowData.justificativa }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="3" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>




              </div>
              <div [ngStyle]="statusRelatorios ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusRelatorios'}">
                <!--ESTRUTURA PARA TABELA ARQUIVOS RELATÓRIOS-->

                <p-table class="table-system" #dt3 [columns]="colstRelatorios" [value]="tRelatorios" [rows]="rows"
                  [paginator]="true" [totalRecords]="tRelatorios.length" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt3.filter($event.target.value, 'cod', 'contains')">
                      </th>
                      <th class="text-left" style="width: 80%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt3.filter($event.target.value, 'descricao', 'contains')">
                      </th>



                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="openCardRelatorio(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cod}}">{{rowData.cod
                            }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 80%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="3" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Card com os dados de regras e relatórios-->
      <div class="row"
        [ngStyle]="processadoDetalhe && !gerarApuracao && !gerarApuracaoIcmsIpi ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'processadoDetalhe'}">
        <div class="col">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: true}"><a>Detalhes Regra/Relatório</a></li>
                </ul>
                <div class="actions-card">
                  <button *ngIf="statusRegras" type="button" class="btn btn-xs btn-link"
                    (click)="gerarRelatorioExcelModalPisCofins('regra')" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusRelatorios" type="button" class="btn btn-xs btn-link"
                    (click)="gerarRelatorioExcelModalPisCofins('relatorio')" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button type="button" class="btn btn-xs btn-link"
                    (click)="ShowHIdeCardProcessadosDetalheRegraRelatorio()"
                    (click)="changeStateProcessadosDetalheRegrasRelatorios()">
                    <span *ngIf="isCardProcessadosDetalheRegraRelatorio" class="material-icons"
                      matTooltip="Ocultar Card" matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardProcessadosDetalheRegraRelatorio" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- Detalhes de regra -->
            <div class="box-content" style="overflow: initial;"
              [@changeDivSize]=currentStateProcessadosDetalheRegraRelatorio>
              <div [ngStyle]="statusRegras ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusRegras'}">

                <mat-card-subtitle>
                  {{tCurrentRegraModalCodigo}} - {{tCurrentRegraModalDescricao}}
                </mat-card-subtitle>

                <p-table class="table-system" #dtRegrasDetalhe [value]="tRegrasDetalhe" [rows]="rows" [paginator]="true"
                  [totalRecords]="tRegrasDetalhe.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="280px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 150px;" *ngFor="let col of tCampos">
                        <span class="text-overflow-dynamic-container" pSortableColumn={{col}}>
                          <span class="text-overflow-dynamic-ellipsis"> {{col}} <p-sortIcon field={{col}}></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtRegrasDetalhe.filter($event.target.value, col, 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-center" style="width: 150px;" *ngFor="let col of tCampos">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData[col]}}">{{rowData[col]
                            }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="tRegrasDetalhe?.length" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>



              </div>

              <!-- Detalhes de relatório -->
              <div [ngStyle]="statusRelatorios ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusRelatorios'}">
                <mat-card-subtitle>
                  {{tCurrentRelatorioModalCodigo}} - {{tCurrentRelatorioModalDescricao}}
                </mat-card-subtitle>



                <p-table class="table-system" #dtRelatoriosDetalhe [value]="tRelatoriosDetalhe" [rows]="rows"
                  [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="true" scrollHeight="280px">
                  <ng-template pTemplate="header" let-rowData>
                    <tr>

                      <th class="text-center" style="width: 150px;" *ngFor="let col of tCampos">
                        <span class="text-overflow-dynamic-container" pSortableColumn={{col}}>
                          <span class="text-overflow-dynamic-ellipsis"> {{col}} </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtRelatoriosDetalhe.filter($event.target.value, col, 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 150px;" *ngFor="let col of tCampos">
                        <span class="text-overflow-dynamic-container">

                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData[col]}}">{{rowData[col]
                            }}</span>

                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
            </div>
          </div>
        </div>
      </div>


      <!--Gerar Apuração-->
      <div class="row" [ngStyle]="gerarApuracao ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'gerarApuracao'}">
        <div class="col-sm-12">
          <div class="tabui">
            <ul id="tabs" class="list-inline filter-menu mt-0 mb-2 tabs">
              <!--
                <li class="list-inline-item back-card">
                  <button class="btn btn-xs btn-link" type="button" (click)="showGerarApuracao()" matTooltip="Voltar">
                    <span class="material-icons">west</span>
                  </button>
                </li> -->
              <li class="list-inline-item"><span class="arrow arrow-left material-icons">chevron_left</span></li>
              <li class="list-inline-item" [ngClass]="{active: statusPis}"><a
                  (click)="showTypeApuracao('LINK_1')">PIS</a></li>
              <li class="list-inline-item" [ngClass]="{active: statusCofins}"><a
                  (click)="showTypeApuracao('LINK_2')">COFINS</a></li>
              <li class="list-inline-item" [ngClass]="{active: statusContribuicao}"><a
                  (click)="showTypeApuracao('LINK_3')">Contribuição Retida na Fonte</a></li>
              <li class="list-inline-item" [ngClass]="{active: statusF100}"><a
                  (click)="showTypeApuracao('LINK_4')">F100: Demais Documentos</a></li>
              <li class="list-inline-item" [ngClass]="{active: statusF120}"><a
                  (click)="showTypeApuracao('LINK_5')">F120: Bens Incorporados ao Ativo - Depreciação/Amortização</a>
              </li>
              <li class="list-inline-item" [ngClass]="{active: statusF130}"><a
                  (click)="showTypeApuracao('LINK_6')">F130: Bens Incorporados ao Ativo - Aquisição/Contribuição</a>
              </li>
              <li class="list-inline-item"><span class="arrow arrow-right material-icons">chevron_right</span></li>
            </ul>
          </div>

        </div>
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content pb-0">
                <div class="tabui" [ngStyle]="statusPis ? {'display': 'block'} : {'display': 'none'}"
                  style="max-width: 90%;">
                  <ul id="tabs2" class="list-inline filter-menu filter-card-header mt-0 tabs">
                    <li class="list-inline-item"><span class="arrow2 arrow-left material-icons">chevron_left</span></li>
                    <li class="list-inline-item" [ngClass]="{active: creditoPisPasep}"><a
                        (click)="showTypeSubApuracao('LINK_1')">Crédito de PIS/PASEP</a></li>
                    <li class="list-inline-item" [ngClass]="{active: contribuicaoPisPasep}"><a
                        (click)="showTypeSubApuracao('LINK_2')">Contribuição de PIS/PASEP</a></li>
                    <li class="list-inline-item" [ngClass]="{active: receitasPisPasep}"><a
                        (click)="showTypeSubApuracao('LINK_3')">Receitas Isentas de PIS/PASEP</a></li>
                    <li class="list-inline-item" [ngClass]="{active: creditosPisPasep}"><a
                        (click)="showTypeSubApuracao('LINK_4')">Controle de Créditos Fiscais - PIS/PASEP</a></li>
                    <li class="list-inline-item" [ngClass]="{active: retidosPisPasep}"><a
                        (click)="showTypeSubApuracao('LINK_5')">Controle dos valores Retidos Na Fonte - PIS/PASEP</a>
                    </li>
                    <li class="list-inline-item"><span class="arrow2 arrow-right material-icons">chevron_right</span>
                    </li>
                  </ul>
                </div>
                <div class="tabui" [ngStyle]="statusCofins ? {'display': 'block'} : {'display': 'none'}"
                  style="max-width: 90%;">
                  <ul id="tabs3" class="list-inline filter-menu filter-card-header mt-0 tabs">
                    <li class="list-inline-item"><span class="arrow3 arrow-left material-icons">chevron_left</span></li>
                    <li class="list-inline-item" [ngClass]="{active: creditoCofins}"><a
                        (click)="showTypeSubApuracaoCofins('LINK_1')">Crédito de COFINS</a></li>
                    <li class="list-inline-item" [ngClass]="{active: contribuicaoCofins}"><a
                        (click)="showTypeSubApuracaoCofins('LINK_2')">Contribuição de COFINS</a></li>
                    <li class="list-inline-item" [ngClass]="{active: receitasCofins}"><a
                        (click)="showTypeSubApuracaoCofins('LINK_3')">Receitas Isentas de COFINS</a></li>
                    <li class="list-inline-item" [ngClass]="{active: creditosCofins}"><a
                        (click)="showTypeSubApuracaoCofins('LINK_4')">Controle de Créditos Fiscais - COFINS</a></li>
                    <li class="list-inline-item" [ngClass]="{active: retidosCofins}"><a
                        (click)="showTypeSubApuracaoCofins('LINK_5')">Controle dos valores Retidos Na Fonte - COFINS</a>
                    </li>
                    <li class="list-inline-item"><span class="arrow3 arrow-right material-icons">chevron_right</span>
                    </li>
                  </ul>
                </div>
                <button *ngIf="statusContribuicao" class="btn btn-sm btn-info mb-2" type="button"
                  (click)="addRetencao()" matTooltip="Adicionar Retenção"><i class="fas fa-plus"></i> Adicionar
                  Retenção</button>
                <button *ngIf="statusF100" class="btn btn-sm btn-info mb-2" type="button" (click)="addF100()"
                  matTooltip="Adicionar F100"><i class="fas fa-plus"></i> Adicionar F100</button>
                <button *ngIf="statusF120" class="btn btn-sm btn-info mb-2" type="button" (click)="addF120()"
                  matTooltip="Adicionar F120"><i class="fas fa-plus"></i> Adicionar F120</button>
                <button *ngIf="statusF130" class="btn btn-sm btn-info mb-2" type="button" (click)="addF130()"
                  matTooltip="Adicionar F130"><i class="fas fa-plus"></i> Adicionar F130</button>

                <div class="actions-card">
                  <button *ngIf="statusPis && creditoPisPasep" type="button" class="btn btn-xs btn-link"
                    (click)="dt12.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusPis && contribuicaoPisPasep" type="button" class="btn btn-xs btn-link"
                    (click)="dt13.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusPis && receitasPisPasep" type="button" class="btn btn-xs btn-link"
                    (click)="dt14.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusPis && creditosPisPasep" type="button" class="btn btn-xs btn-link"
                    (click)="dt15.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusCofins && creditoCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dt16.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusCofins && contribuicaoCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dtContribuicaoCofins.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusCofins && receitasCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dtReceitasCofins.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusCofins && creditosCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dtControleCreditoCofins.exportCSV()" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusCofins && retidosCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dtControleRetidosCofins.exportCSV()" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusF100" type="button" class="btn btn-xs btn-link" (click)="dtF100.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="statusF120" type="button" class="btn btn-xs btn-link" (click)="dtF120.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="statusF130" type="button" class="btn btn-xs btn-link" (click)="dtF130.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="statusPis && retidosPisPasep" type="button" class="btn btn-xs btn-link"
                    (click)="dt24.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="statusContribuicao" type="button" class="btn btn-xs btn-link"
                    (click)="dtContribuicaoRetidaFonte.exportCSV()" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>

                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeGerarApuracao()"
                    (click)="changeStateGerarApuracao()">
                    <span *ngIf="isCardGerarApuracao" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardGerarApuracao" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateGerarApuracao>
              <!--ESTRUTURA Crédito de PIS/PASEP -->
              <div [ngStyle]="statusPis && creditoPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusPis && creditoPisPasep'}">

                <p-table class="table-system" #dt12 [columns]="colsTCreditoPis" [value]="tCreditoPis" [rows]="rows"
                  [paginator]="true" [totalRecords]="tCreditoPis.length" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>
                      </th>

                      <th class="text-left" style="width: 240px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo de Crédito <p-sortIcon
                              [field]="'tipo_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'tipo_credito', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC PIS <p-sortIcon [field]="'vl_bc_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_bc_pis', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'aliq_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'vl_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Crédito <p-sortIcon
                              [field]="'vl_total_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_total_credito', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_ajuste_acrescimo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Ajuste Acréscimo <p-sortIcon
                              [field]="'vl_total_ajuste_acrescimo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_total_ajuste_acrescimo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_ajuste_reducao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Ajuste Redução <p-sortIcon
                              [field]="'vl_total_ajuste_reducao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_total_ajuste_reducao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_credito_diferido'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Crédito Diferido <p-sortIcon
                              [field]="'vl_total_credito_diferido'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_total_credito_diferido', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_disponivel_perido'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Crédito Disponível no Período
                            <p-sortIcon [field]="'vl_total_disponivel_perido'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_total_disponivel_perido', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_total_disponivel_descontado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total de Credito Disponivel Descontado
                            Contribuicao Apurada <p-sortIcon [field]="'vl_total_disponivel_descontado'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'vl_total_disponivel_descontado', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_periodo_futuro'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo a Utilizar em Periodos Futuros <p-sortIcon
                              [field]="'saldo_periodo_futuro'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt12.filter($event.target.value, 'saldo_periodo_futuro', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="showPisDetalhe(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </td>

                      <td class="text-left" style="width: 240px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.tipo_credito}}">{{rowData.tipo_credito }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_pis | money}}">{{rowData.vl_pis | money}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_credito | money}}">{{rowData.vl_total_credito | money
                            }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_ajuste_acrescimo | money}}">{{rowData.vl_total_ajuste_acrescimo
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_ajuste_reducao | money}}">{{rowData.vl_total_ajuste_reducao |
                            money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_credito_diferido | money}}">{{rowData.vl_total_credito_diferido
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_disponivel_perido | money}}">{{rowData.vl_total_disponivel_perido
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_disponivel_descontado | money}}">{{rowData.vl_total_disponivel_descontado
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_periodo_futuro | money}}">{{rowData.saldo_periodo_futuro |
                            money}}</span>
                        </span>
                      </td>



                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="13" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <div [ngStyle]="statusPis && contribuicaoPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusPis && contribuicaoPisPasep'}">
                <!--ESTRUTURA Contribuição de PIS/PASEP -->

                <p-table class="table-system" #dt13 [value]="tContribuicaoPis" [columns]="colsTContribuicaoPis"
                  [rows]="rows" [paginator]="true" [totalRecords]="tContribuicaoPis.length"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'contr_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Contribuição Não Cumulativa do Período
                            <p-sortIcon [field]="'contr_periodo'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'contr_periodo', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cred_descontado'">
                          <span class="text-overflow-dynamic-ellipsis">Crédito Descontado Apurado no Próprio Periodo
                            <p-sortIcon [field]="'cred_descontado'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'cred_descontado', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cred_descontado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. de Credito Descontado Apurado em Periodo de
                            Apuração Anterior <p-sortIcon [field]="'valor_cred_descontado'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'valor_cred_descontado', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_contribuicao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total da Contribuicao Não Cumulativa
                            <p-sortIcon [field]="'vl_total_contribuicao'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_total_contribuicao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retido_fonte'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Retido Fonte (Não Cumulativo) <p-sortIcon
                              [field]="'vl_retido_fonte'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_retido_fonte', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'outras_deducoes'">
                          <span class="text-overflow-dynamic-ellipsis">Outras Deduções Não Cumulativo <p-sortIcon
                              [field]="'outras_deducoes'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'outras_deducoes', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_contribuicao_pagar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Contribuição Não Cumulativa a Pagar
                            <p-sortIcon [field]="'vl_contribuicao_pagar'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_contribuicao_pagar', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_total_contribuicao_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Contribuição Cumulativa do Período
                            <p-sortIcon [field]="'vl_total_contribuicao_periodo'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_total_contribuicao_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retido_fonte_cumulativo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Retido na Fonte (Cumulativo) <p-sortIcon
                              [field]="'vl_retido_fonte_cumulativo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_retido_fonte_cumulativo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'outras_deducoes_cumulativo'">
                          <span class="text-overflow-dynamic-ellipsis">Outras Deduções Cumulativo <p-sortIcon
                              [field]="'outras_deducoes_cumulativo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'outras_deducoes_cumulativo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_contribuicao_cumulativa_pagar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Contribuição Cumulativa a Pagar <p-sortIcon
                              [field]="'vl_contribuicao_cumulativa_pagar'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_contribuicao_cumulativa_pagar', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_contribuicao_total_pagar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total da Contribuição a Pagar <p-sortIcon
                              [field]="'vl_contribuicao_total_pagar'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt13.filter($event.target.value, 'vl_contribuicao_total_pagar', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="showPisContribuicaoDetalhe(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.contr_periodo | money}}">{{rowData.contr_periodo | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cred_descontado | money}}">{{rowData.cred_descontado | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.valor_cred_descontado | money}}">{{rowData.valor_cred_descontado |
                            money}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_contribuicao | money}}">{{rowData.vl_total_contribuicao |
                            money}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retido_fonte | money}}">{{rowData.vl_retido_fonte | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.outras_deducoes | money}}">{{rowData.outras_deducoes | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contribuicao_pagar | money}}">{{rowData.vl_contribuicao_pagar |
                            money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_contribuicao_periodo | money}}">{{rowData.vl_total_contribuicao_periodo
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retido_fonte_cumulativo | money}}">{{rowData.vl_retido_fonte_cumulativo
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.outras_deducoes_cumulativo | money}}">{{rowData.outras_deducoes_cumulativo
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contribuicao_cumulativa_pagar | money}}">{{rowData.vl_contribuicao_cumulativa_pagar
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contribuicao_total_pagar | money}}">{{rowData.vl_contribuicao_total_pagar
                            | money }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <div [ngStyle]="statusPis && receitasPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusPis && receitasPisPasep'}">
                <!--ESTRUTURA Receitas Isentas de PIS/PASEP -->

                <p-table class="table-system" #dt14 [value]="tReceitasPis" [columns]="colsTReceitasPis" [rows]="rows"
                  [paginator]="true" [totalRecords]="tReceitasPis.length" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-left" style="width: 55%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_pis'">
                          <span class="text-overflow-dynamic-ellipsis">CST PIS <p-sortIcon [field]="'cst_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt14.filter($event.target.value, 'cst_pis', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_item'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Item <p-sortIcon [field]="'vl_item'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt14.filter($event.target.value, 'vl_item', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC PIS <p-sortIcon [field]="'vl_bc_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt14.filter($event.target.value, 'vl_bc_pis', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt14.filter($event.target.value, 'aliq_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'vl_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt14.filter($event.target.value, 'vl_pis', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-left" style="width: 55%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_pis}}">{{rowData.cst_pis }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_item | money}}">{{rowData.vl_item | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_pis | money}}">{{rowData.vl_pis | money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

              </div>
              <div [ngStyle]="statusPis && creditosPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusPis && creditosPisPasep'}">
                <!--ESTRUTURA Controle de Créditos Fiscais PIS/PASEP -->

                <p-table class="table-system" #dt15 [value]="tControleCreditoPis" [columns]="colsTControleCreditoPis"
                  [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo_apuracao'">
                          <span class="text-overflow-dynamic-ellipsis">Periodo Ap. <p-sortIcon
                              [field]="'periodo_apuracao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'periodo_apuracao', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Origem do Crédito <p-sortIcon
                              [field]="'origem_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'origem_credito', 'contains')">
                      </th>
                      <th class="text-right" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj_cedente_credito'">
                          <span class="text-overflow-dynamic-ellipsis">CNPJ do Cedente do Crédito <p-sortIcon
                              [field]="'cnpj_cedente_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'cnpj_cedente_credito', 'contains')">
                      </th>

                      <th class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo do Crédito <p-sortIcon
                              [field]="'tipo_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'tipo_credito', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Apurado no Período <p-sortIcon
                              [field]="'vl_credito_periodo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_extemporaneo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Extemporâneo <p-sortIcon
                              [field]="'vl_credito_extemporaneo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_extemporaneo', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_apurado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total do Crédito Apurado <p-sortIcon
                              [field]="'vl_credito_apurado'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_apurado', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_desconto'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Utilizado Mediante Desconto
                            <p-sortIcon [field]="'vl_credito_desconto'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_desconto', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_ressarcimento'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Utilizado Mediante Pedido de
                            Ressarcimento <p-sortIcon [field]="'vl_credito_ressarcimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_ressarcimento', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_intermediaria'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Utilizado Mediante Declaração de
                            Compensação Intermediária <p-sortIcon [field]="'vl_credito_intermediaria'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_intermediaria', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_credito_escrituracao'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo do Crédito Disponível para Utilização neste
                            Período de Escrituração <p-sortIcon [field]="'saldo_credito_escrituracao'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'saldo_credito_escrituracao', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_descontado_escrituracao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito descontado neste período de
                            escrituração <p-sortIcon [field]="'vl_descontado_escrituracao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_descontado_escrituracao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_descontado_ressarcimento'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito objeto de Pedido de Ressarcimento
                            neste Período de Escrituração <p-sortIcon [field]="'vl_descontado_ressarcimento'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_descontado_ressarcimento', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_compensacao_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito utilizado mediante Declaração de
                            Compensação Intermediária neste Período <p-sortIcon [field]="'vl_compensacao_periodo'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_compensacao_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_incorporacao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito transferido em evento de cisão,
                            fusão ou incorporação <p-sortIcon [field]="'vl_credito_incorporacao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_incorporacao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_outras'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do crédito utilizado por outras formas
                            <p-sortIcon [field]="'vl_credito_outras'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'vl_credito_outras', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_apuracao_futuro'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo de Créditos a Utilizar em Periodo de
                            Apuração Futuro <p-sortIcon [field]="'saldo_apuracao_futuro'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt15.filter($event.target.value, 'saldo_apuracao_futuro', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDados1100(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.periodo_apuracao}}">{{rowData.periodo_apuracao }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.origem_credito}}">{{rowData.origem_credito }}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cnpj_cedente_credito}}">{{rowData.cnpj_cedente_credito}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.tipo_credito}}">{{rowData.tipo_credito}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_periodo | money}}">{{rowData.vl_credito_periodo |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_extemporaneo | money}}">{{rowData.vl_credito_extemporaneo |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_apurado | money}}">{{rowData.vl_credito_apurado |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_desconto | money}}">{{rowData.vl_credito_desconto |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_ressarcimento | money}}">{{rowData.vl_credito_ressarcimento
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_intermediaria | money}}">{{rowData.vl_credito_intermediaria
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_credito_escrituracao | money}}">{{rowData.saldo_credito_escrituracao
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_descontado_escrituracao | money}}">{{rowData.vl_descontado_escrituracao
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_descontado_ressarcimento | money}}">{{rowData.vl_descontado_ressarcimento
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_compensacao_periodo | money}}">{{rowData.vl_compensacao_periodo |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_incorporacao | money}}">{{rowData.vl_credito_incorporacao |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_outras | money}}">{{rowData.vl_credito_outras |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_apuracao_futuro | money}}">{{rowData.saldo_apuracao_futuro |
                            money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-sm btn-info mt-2" type="button" (click)="openModalControleCredito('pis')"><i
                        class="fas fa-plus"></i> Adicionar Crédito</button>
                  </div>
                </div>
              </div>
              <div [ngStyle]="statusPis && retidosPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusPis && retidosPisPasep'}">
                <!--ESTRUTURA Controle dos Valores Retidos PIS/PASEP -->

                <p-table class="table-system" #dt24 [value]="tControleRetidosPis" [rows]="rows"
                  [columns]="colsTControleRetidosPis" [paginator]="true" [totalRecords]="tControleRetidosPis.length"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 55%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'natureza_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Natureza da Retenção na Fonte <p-sortIcon
                              [field]="'natureza_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'natureza_retencao', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo_recebimento'">
                          <span class="text-overflow-dynamic-ellipsis">Período do Recebimento e da Retenção <p-sortIcon
                              [field]="'periodo_recebimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'periodo_recebimento', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total da Retenção <p-sortIcon
                              [field]="'vl_total_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'vl_total_retencao', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retencao_devida'">
                          <span class="text-overflow-dynamic-ellipsis">Vl da Retenção deduzida da Contribuição Devida
                            <p-sortIcon [field]="'vl_retencao_devida'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'vl_retencao_devida', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retencao_ressarcimento'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. da Retenção Utilizada Mediante Pedido de
                            Ressarcimento <p-sortIcon [field]="'vl_retencao_ressarcimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'vl_retencao_ressarcimento', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retencao_compensacao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. da Retenção Utilizada Mediante Declaração de
                            Compensação <p-sortIcon [field]="'vl_retencao_compensacao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'vl_retencao_compensacao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_retencao_futuros'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo de Retenção a Utilizar em Períodos de
                            Apuração Futuros <p-sortIcon [field]="'saldo_retencao_futuros'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt24.filter($event.target.value, 'saldo_retencao_futuros', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDados1300(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 55%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.natureza_retencao}}">{{rowData.natureza_retencao }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.periodo_recebimento}}">{{rowData.periodo_recebimento }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_retencao | money}}">{{rowData.vl_total_retencao | money
                            }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retencao_devida | money}}">{{rowData.vl_retencao_devida |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retencao_ressarcimento | money}}">{{rowData.vl_retencao_ressarcimento
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retencao_compensacao | money}}">{{rowData.vl_retencao_compensacao |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_retencao_futuros | money}}">{{rowData.saldo_retencao_futuros |
                            money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-sm btn-info mt-2" type="button"
                      (click)="openModalControleRetidoFonte('pis')"><i class="fas fa-plus"></i> Adicionar Controle
                      Retido Fonte</button>
                  </div>
                </div>
              </div>
              <!-- End PisPasep-->

              <!-- Start Cofins-->
              <div [ngStyle]="statusCofins && creditoCofins ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusCofins && creditoCofins'}">
                <!--ESTRUTURA Créditos de COFINS -->
                <h3 class="sub-title">Crédito de Cofins <i class="fas fa-search" style="cursor: pointer;"
                    matTooltip="Analisar"></i></h3>

                <p-table class="table-system" #dt16 [value]="tCreditoCofins" [columns]="colsTCreditoCofins"
                  [rows]="rows" [paginator]="true" [totalRecords]="tCreditoCofins.length" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 240px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo de Crédito <p-sortIcon
                              [field]="'tipo_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'tipo_credito', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC COFINS <p-sortIcon
                              [field]="'vl_bc_cofins'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_bc_cofins', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon [field]="'aliq_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'aliq_cofins', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'vl_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Crédito <p-sortIcon
                              [field]="'vl_total_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_total_credito', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_ajuste_acrescimo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Ajuste Acréscimo <p-sortIcon
                              [field]="'vl_total_ajuste_acrescimo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_total_ajuste_acrescimo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_ajuste_reducao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Ajuste Redução <p-sortIcon
                              [field]="'vl_total_ajuste_reducao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_total_ajuste_reducao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_credito_diferido'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Crédito Diferido <p-sortIcon
                              [field]="'vl_total_credito_diferido'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_total_credito_diferido', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_disponivel_perido'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Crédito Disponível no Período
                            <p-sortIcon [field]="'vl_total_disponivel_perido'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_total_disponivel_perido', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_total_disponivel_descontado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total de Credito Disponivel Descontado
                            Contribuicao Apurada <p-sortIcon [field]="'vl_total_disponivel_descontado'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'vl_total_disponivel_descontado', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_periodo_futuro'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo a Utilizar em Periodos Futuros <p-sortIcon
                              [field]="'saldo_periodo_futuro'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt16.filter($event.target.value, 'saldo_periodo_futuro', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="showCofinsDetalhe(rowData)">
                          <i class="fas fa-search"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 240px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.tipo_credito}}">{{rowData.tipo_credito }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_cofins | money}}">{{rowData.vl_cofins | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_credito | money}}">{{rowData.vl_total_credito |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_ajuste_acrescimo | money}}">{{rowData.vl_total_ajuste_acrescimo
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_ajuste_reducao | money}}">{{rowData.vl_total_ajuste_reducao |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_credito_diferido | money}}">{{rowData.vl_total_credito_diferido
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_disponivel_perido | money}}">{{rowData.vl_total_disponivel_perido
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_disponivel_descontado | money}}">{{rowData.vl_total_disponivel_descontado
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_periodo_futuro | money}}">{{rowData.saldo_periodo_futuro |
                            money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>

              <!--ESTRUTURA Contribuição de COFINS -->
              <div [ngStyle]="statusCofins && contribuicaoCofins ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusCofins && contribuicaoCofins'}">
                <h3 class="sub-title">Contribuição de Cofins <i class="fas fa-search" style="cursor: pointer;"
                    matTooltip="Analisar"></i></h3>


                <p-table class="table-system" #dtContribuicaoCofins [value]="tContribuicaoCofins"
                  [columns]="colsTContribuicaoCofins" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'contr_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Contribuição Não Cumulativa do Período
                            <p-sortIcon [field]="'contr_periodo'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'contr_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cred_descontado'">
                          <span class="text-overflow-dynamic-ellipsis">Crédito Descontado Apurado no Próprio Periodo
                            <p-sortIcon [field]="'cred_descontado'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'cred_descontado', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cred_descontado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. de Credito Descontado Apurado em Periodo de
                            Apuração Anterior <p-sortIcon [field]="'valor_cred_descontado'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'valor_cred_descontado', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_contribuicao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total da Contribuicao Não Cumulativa
                            <p-sortIcon [field]="'vl_total_contribuicao'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_total_contribuicao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retido_fonte'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Retido Fonte (Não Cumulativo) <p-sortIcon
                              [field]="'vl_retido_fonte'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_retido_fonte', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'outras_deducoes'">
                          <span class="text-overflow-dynamic-ellipsis">Outras Deduções Não Cumulativo <p-sortIcon
                              [field]="'outras_deducoes'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'outras_deducoes', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_contribuicao_pagar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Contribuição Não Cumulativa a Pagar
                            <p-sortIcon [field]="'vl_contribuicao_pagar'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_contribuicao_pagar', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_total_contribuicao_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total Contribuição Cumulativa do Período
                            <p-sortIcon [field]="'vl_total_contribuicao_periodo'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_total_contribuicao_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retido_fonte_cumulativo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Retido na Fonte (Cumulativo) <p-sortIcon
                              [field]="'vl_retido_fonte_cumulativo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_retido_fonte_cumulativo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'outras_deducoes_cumulativo'">
                          <span class="text-overflow-dynamic-ellipsis">Outras Deduções Cumulativo <p-sortIcon
                              [field]="'outras_deducoes_cumulativo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'outras_deducoes_cumulativo', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_contribuicao_cumulativa_pagar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Contribuição Cumulativa a Pagar <p-sortIcon
                              [field]="'vl_contribuicao_cumulativa_pagar'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_contribuicao_cumulativa_pagar', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_contribuicao_total_pagar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total da Contribuição a Pagar <p-sortIcon
                              [field]="'vl_contribuicao_total_pagar'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoCofins.filter($event.target.value, 'vl_contribuicao_total_pagar', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="showCofinsContribuicaoDetalhe(rowData)">
                          <i class="fas fa-search"></i>
                        </span>

                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.contr_periodo | money}}">{{rowData.contr_periodo | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cred_descontado | money}}">{{rowData.cred_descontado | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.valor_cred_descontado | money}}">{{rowData.valor_cred_descontado |
                            money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_contribuicao | money}}">{{rowData.vl_total_contribuicao |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retido_fonte | money}}">{{rowData.vl_retido_fonte | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.outras_deducoes | money}}">{{rowData.outras_deducoes | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contribuicao_pagar | money}}">{{rowData.vl_contribuicao_pagar |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_contribuicao_periodo | money}}">{{rowData.vl_total_contribuicao_periodo
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retido_fonte_cumulativo | money}}">{{rowData.vl_retido_fonte_cumulativo
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.outras_deducoes_cumulativo | money}}">{{rowData.outras_deducoes_cumulativo
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contribuicao_cumulativa_pagar | money}}">{{rowData.vl_contribuicao_cumulativa_pagar
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contribuicao_total_pagar | money}}">{{rowData.vl_contribuicao_total_pagar
                            | money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>

              <!--ESTRUTURA Receitas Isentas de COFINS -->
              <div [ngStyle]="statusCofins && receitasCofins ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusCofins && receitasCofins'}">
                <h3 class="sub-title"><i class="fas fa-bars"></i> Receitas Cofins</h3>


                <p-table class="table-system" #dtReceitasCofins [value]="tReceitasCofins" [rows]="rows"
                  [columns]="colsTReceitasCofins" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-left" style="width: 55%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">CST COFINS <p-sortIcon [field]="'cst_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtReceitasCofins.filter($event.target.value, 'cst_cofins', 'contains')">
                      </th>
                      <th class="text-left" style="width: 15%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_item'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Item <p-sortIcon [field]="'vl_item'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtReceitasCofins.filter($event.target.value, 'vl_item', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC COFINS <p-sortIcon
                              [field]="'vl_bc_cofins'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtReceitasCofins.filter($event.target.value, 'vl_bc_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon [field]="'aliq_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtReceitasCofins.filter($event.target.value, 'aliq_cofins', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'vl_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtReceitasCofins.filter($event.target.value, 'vl_cofins', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-left" style="width: 55%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_cofins}}">{{rowData.cst_cofins }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_item | money}}">{{rowData.vl_item | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_cofins | money}}">{{rowData.vl_cofins | money }}</span>
                        </span>
                      </td>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="13" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>

              <!--ESTRUTURA Controle de Créditos Fiscais COFINS -->
              <div [ngStyle]="statusCofins && creditosCofins ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusCofins && creditosCofins'}">
                <h3 class="sub-title"><i class="fas fa-bars"></i> Créditos Cofins</h3>

                <p-table class="table-system" #dtControleCreditoCofins [value]="tControleCreditoCofins"
                  [columns]="colsTControleCreditoCofins" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo_apuracao'">
                          <span class="text-overflow-dynamic-ellipsis">Periodo Ap. <p-sortIcon
                              [field]="'periodo_apuracao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'periodo_apuracao', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Origem do Crédito <p-sortIcon
                              [field]="'origem_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'origem_credito', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj_cedente_credito'">
                          <span class="text-overflow-dynamic-ellipsis">CNPJ do Cedente do Crédito <p-sortIcon
                              [field]="'cnpj_cedente_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'cnpj_cedente_credito', 'contains')">
                      </th>


                      <th class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_credito'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo do Crédito <p-sortIcon
                              [field]="'tipo_credito'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'tipo_credito', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Apurado no Período <p-sortIcon
                              [field]="'vl_credito_periodo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_extemporaneo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Extemporâneo <p-sortIcon
                              [field]="'vl_credito_extemporaneo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_extemporaneo', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_apurado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Total do Crédito Apurado <p-sortIcon
                              [field]="'vl_credito_apurado'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_apurado', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_desconto'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Utilizado Mediante Desconto
                            <p-sortIcon [field]="'vl_credito_desconto'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_desconto', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_ressarcimento'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Utilizado Mediante Pedido de
                            Ressarcimento <p-sortIcon [field]="'vl_credito_ressarcimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_ressarcimento', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_intermediaria'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito Utilizado Mediante Declaração de
                            Compensação Intermediária <p-sortIcon [field]="'vl_credito_intermediaria'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_intermediaria', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_credito_escrituracao'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo do Crédito Disponível para Utilização neste
                            Período de Escrituração <p-sortIcon [field]="'saldo_credito_escrituracao'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'saldo_credito_escrituracao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_descontado_escrituracao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito descontado neste período de
                            escrituração <p-sortIcon [field]="'vl_descontado_escrituracao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_descontado_escrituracao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_descontado_ressarcimento'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito objeto de Pedido de Ressarcimento
                            neste Período de Escrituração <p-sortIcon [field]="'vl_descontado_ressarcimento'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_descontado_ressarcimento', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_compensacao_periodo'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito utilizado mediante Declaração de
                            Compensação Intermediária neste Período <p-sortIcon [field]="'vl_compensacao_periodo'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_compensacao_periodo', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_incorporacao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do Crédito transferido em evento de cisão,
                            fusão ou incorporação <p-sortIcon [field]="'vl_credito_incorporacao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_incorporacao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_credito_outras'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do crédito utilizado por outras formas
                            <p-sortIcon [field]="'vl_credito_outras'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'vl_credito_outras', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_apuracao_futuro'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo de Créditos a Utilizar em Periodo de
                            Apuração Futuro <p-sortIcon [field]="'saldo_apuracao_futuro'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleCreditoCofins.filter($event.target.value, 'saldo_apuracao_futuro', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDados1500(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.periodo_apuracao}}">{{rowData.periodo_apuracao }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.origem_credito}}">{{rowData.origem_credito }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cnpj_cedente_credito}}">{{rowData.cnpj_cedente_credito }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.tipo_credito}}">{{rowData.tipo_credito}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_periodo | money}}">{{rowData.vl_credito_periodo |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_extemporaneo | money}}">{{rowData.vl_credito_extemporaneo |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_apurado | money}}">{{rowData.vl_credito_apurado |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_desconto | money}}">{{rowData.vl_credito_desconto |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_ressarcimento | money}}">{{rowData.vl_credito_ressarcimento
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_intermediaria | money}}">{{rowData.vl_credito_intermediaria
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_credito_escrituracao | money}}">{{rowData.saldo_credito_escrituracao
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_descontado_escrituracao | money}}">{{rowData.vl_descontado_escrituracao
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_descontado_ressarcimento | money}}">{{rowData.vl_descontado_ressarcimento
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_compensacao_periodo | money}}">{{rowData.vl_compensacao_periodo |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_incorporacao | money}}">{{rowData.vl_credito_incorporacao |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_credito_outras | money}}">{{rowData.vl_credito_outras |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_apuracao_futuro | money}}">{{rowData.saldo_apuracao_futuro |
                            money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="18" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-sm btn-info mt-2" type="button"
                      (click)="openModalControleCredito('cofins')"><i class="fas fa-plus"></i> Adicionar
                      Crédito</button>
                  </div>
                </div>
              </div>

              <!--ESTRUTURA Controle dos Valores Retidos COFINS -->
              <div [ngStyle]="statusCofins && retidosCofins ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusCofins && retidosCofins'}">
                <h3 class="sub-title"><i class="fas fa-bars"></i> Retidos Cofins</h3>

                <p-table class="table-system" #dtControleRetidosCofins [value]="tControleRetidosCofins"
                  [columns]="colsTControleRetidosCofins" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-center" style="width: 50%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'natureza_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Natureza da Retenção na Fonte <p-sortIcon
                              [field]="'natureza_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'natureza_retencao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo_recebimento'">
                          <span class="text-overflow-dynamic-ellipsis">Período do Recebimento e da Retenção <p-sortIcon
                              [field]="'periodo_recebimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'periodo_recebimento', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total da Retenção <p-sortIcon
                              [field]="'vl_total_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'vl_total_retencao', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retencao_devida'">
                          <span class="text-overflow-dynamic-ellipsis">Vl da Retenção deduzida da Contribuição Devida
                            <p-sortIcon [field]="'vl_retencao_devida'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'vl_retencao_devida', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retencao_ressarcimento'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. da Retenção Utilizada Mediante Pedido de
                            Ressarcimento <p-sortIcon [field]="'vl_retencao_ressarcimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'vl_retencao_ressarcimento', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_retencao_compensacao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. da Retenção Utilizada Mediante Declaração de
                            Compensação <p-sortIcon [field]="'vl_retencao_compensacao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'vl_retencao_compensacao', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_retencao_futuros'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo de Retenção a Utilizar em Períodos de
                            Apuração Futuros <p-sortIcon [field]="'saldo_retencao_futuros'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtControleRetidosCofins.filter($event.target.value, 'saldo_retencao_futuros', 'contains')">
                      </th>




                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDados1700(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 50%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.natureza_retencao}}">{{rowData.natureza_retencao }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.periodo_recebimento}}">{{rowData.periodo_recebimento }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_retencao | money}}">{{rowData.vl_total_retencao | money
                            }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retencao_devida | money}}">{{rowData.vl_retencao_devida |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retencao_ressarcimento | money}}">{{rowData.vl_retencao_ressarcimento
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_retencao_compensacao | money}}">{{rowData.vl_retencao_compensacao |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_retencao_futuros | money}}">{{rowData.saldo_retencao_futuros |
                            money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-sm btn-info mt-2" type="button"
                      (click)="openModalControleRetidoFonte('cofins')"><i class="fas fa-plus"></i> Adicionar Controle
                      Retido Fonte</button>
                  </div>
                </div>
              </div>
              <!-- End Cofins-->

              <!-- Start Demais Itens Menu-->
              <div [ngStyle]="statusContribuicao ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusContribuicao'}">
                <!--ESTRUTURA Contribuição Retida Na Fonte -->

                <p-table class="table-system" #dtContribuicaoRetidaFonte [value]="tContribuicaoRetidaFonte"
                  [columns]="colsTContribuicaoRetidaFonte" [rows]="rows" [paginator]="true"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-right" style="width: 250px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'natureza_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Natureza da Retenção na Fonte <p-sortIcon
                              [field]="'natureza_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'natureza_retencao', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Data Retenção <p-sortIcon
                              [field]="'data_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'data_retencao', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_base_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Base Retenção <p-sortIcon
                              [field]="'vl_base_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'vl_base_retencao', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_retencao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl Total Retenção <p-sortIcon
                              [field]="'vl_total_retencao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'vl_total_retencao', 'contains')">
                      </th>

                      <th class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_receita'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. Receita <p-sortIcon [field]="'cod_receita'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'cod_receita', 'contains')">
                      </th>

                      <th class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'natureza_receita'">
                          <span class="text-overflow-dynamic-ellipsis">Natureza Receita <p-sortIcon
                              [field]="'natureza_receita'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'natureza_receita', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj_pagadora'">
                          <span class="text-overflow-dynamic-ellipsis">CNPJ Pagadora <p-sortIcon
                              [field]="'cnpj_pagadora'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'cnpj_pagadora', 'contains')">
                      </th>

                      <th class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cond_pesso'">
                          <span class="text-overflow-dynamic-ellipsis">Condição Pesso <p-sortIcon
                              [field]="'cond_pesso'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'cond_pesso', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Parcela PIS <p-sortIcon [field]="'parcela_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'parcela_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Parcela COFINS <p-sortIcon
                              [field]="'parcela_cofins'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtContribuicaoRetidaFonte.filter($event.target.value, 'parcela_cofins', 'contains')">
                      </th>



                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDadosF600(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.natureza_retencao}}">{{rowData.natureza_retencao }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.data_retencao}}">{{rowData.data_retencao }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_base_retencao | money}}">{{rowData.vl_base_retencao | money
                            }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_retencao | money}}">{{rowData.vl_total_retencao |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_receita}}">{{rowData.cod_receita}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.natureza_receita}}">{{rowData.natureza_receita}}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cnpj_pagadora}}">{{rowData.cnpj_pagadora}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 250px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cond_pesso}}">{{rowData.cond_pesso}}</span>
                        </span>
                      </td>


                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.parcela_pis | money}}">{{rowData.parcela_pis | money}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.parcela_cofins | money}}">{{rowData.parcela_cofins | money}}</span>
                        </span>
                      </td>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="11" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

              </div>

              <div [ngStyle]="statusF100 ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusF100'}">
                <!--ESTRUTURA F100: Demais Documentos -->

                <p-table class="table-system" #dtF100 [value]="tF100" [columns]="colsTF100" [rows]="rows"
                  [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 350px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_oper'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Oper. <p-sortIcon [field]="'ind_oper'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'ind_oper', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_part'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. Part. <p-sortIcon [field]="'cod_part'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'cod_part', 'contains')">
                      </th>


                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_item'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. Item <p-sortIcon [field]="'cod_item'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'cod_item', 'contains')">
                      </th>


                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_oper'">
                          <span class="text-overflow-dynamic-ellipsis">Data Oper. <p-sortIcon [field]="'data_oper'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'data_oper', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_oper'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Oper. <p-sortIcon [field]="'vl_oper'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'vl_oper', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_pis'">
                          <span class="text-overflow-dynamic-ellipsis">CST PIS <p-sortIcon [field]="'cst_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'cst_pis', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC PIS <p-sortIcon [field]="'vl_bc_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'vl_bc_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'aliq_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'vl_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'vl_pis', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">CST COFINS <p-sortIcon [field]="'cst_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'cst_cofins', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC COFINS <p-sortIcon
                              [field]="'vl_bc_cofins'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'vl_bc_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon [field]="'aliq_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'aliq_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'vl_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'vl_cofins', 'contains')">
                      </th>


                      <th class="text-left" style="width: 350px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'nat_bc_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Nat. BC Cred. <p-sortIcon
                              [field]="'nat_bc_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'nat_bc_cred', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_orig_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Orig. Cred. <p-sortIcon
                              [field]="'ind_orig_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'ind_orig_cred', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. CTA. <p-sortIcon [field]="'cod_cta'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'cod_cta', 'contains')">
                      </th>


                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cc'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. CC. <p-sortIcon [field]="'cod_cc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'cod_cc', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_doc_oper'">
                          <span class="text-overflow-dynamic-ellipsis">Desc. Doc. Oper. <p-sortIcon
                              [field]="'desc_doc_oper'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF100.filter($event.target.value, 'desc_doc_oper', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDadosF100(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 350px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_oper}}">{{rowData.ind_oper }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_part}}">{{rowData.cod_part }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_item}}">{{rowData.cod_item }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.data_oper}}">{{rowData.data_oper}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_oper | money}}">{{rowData.vl_oper | money}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_pis}}">{{rowData.cst_pis}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_pis | money}}">{{rowData.vl_pis | money}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_cofins}}">{{rowData.cst_cofins}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_cofins | money}}">{{rowData.vl_cofins | money}}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 350px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.nat_bc_cred}}">{{rowData.nat_bc_cred}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_orig_cred}}">{{rowData.ind_orig_cred}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_cta}}">{{rowData.cod_cta}}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_cc}}">{{rowData.cod_cc}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.desc_doc_oper}}">{{rowData.desc_doc_oper}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="18" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>

              <div [ngStyle]="statusF120 ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusF120'}">
                <!--ESTRUTURA F120: Bens Incorporados ao Ativo - Depreciação/Amortização -->

                <p-table class="table-system" #dtF120 [value]="tF120" [columns]="colsTF120" [rows]="rows"
                  [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'nat_bc_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Nat. BC Cred. <p-sortIcon
                              [field]="'nat_bc_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'nat_bc_cred', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'indent_bem_imob'">
                          <span class="text-overflow-dynamic-ellipsis">Indent. Bem Imob. <p-sortIcon
                              [field]="'indent_bem_imob'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'indent_bem_imob', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_orig_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Orig. Cred. <p-sortIcon
                              [field]="'ind_orig_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'ind_orig_cred', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_util_bem_imob'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Util. Bem Imob. <p-sortIcon
                              [field]="'ind_util_bem_imob'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'ind_util_bem_imob', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_oper_dep'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Oper. Dep. <p-sortIcon
                              [field]="'vl_oper_dep'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'vl_oper_dep', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'parc_oper_nao_bc_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Parc. Oper. Não Bc Cred. <p-sortIcon
                              [field]="'parc_oper_nao_bc_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'parc_oper_nao_bc_cred', 'contains')">
                      </th>


                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_pis'">
                          <span class="text-overflow-dynamic-ellipsis">CST PIS <p-sortIcon [field]="'cst_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'cst_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC PIS <p-sortIcon [field]="'vl_bc_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'vl_bc_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'aliq_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'vl_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'vl_pis', 'contains')">
                      </th>


                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">CST COFINS <p-sortIcon [field]="'cst_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'cst_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC COFINS <p-sortIcon
                              [field]="'vl_bc_cofins'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'vl_bc_cofins', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon [field]="'aliq_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'aliq_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'vl_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'vl_cofins', 'contains')">
                      </th>


                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. CTA. <p-sortIcon [field]="'cod_cta'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'cod_cta', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cc'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. CC. <p-sortIcon [field]="'cod_cc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'cod_cc', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_bem_imob'">
                          <span class="text-overflow-dynamic-ellipsis">Desc. Bem Imob. <p-sortIcon
                              [field]="'desc_bem_imob'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF120.filter($event.target.value, 'desc_bem_imob', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDadosF120(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.nat_bc_cred}}">{{rowData.nat_bc_cred }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.indent_bem_imob}}">{{rowData.indent_bem_imob }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_orig_cred}}">{{rowData.ind_orig_cred }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_util_bem_imob}}">{{rowData.ind_util_bem_imob}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_oper_dep | money}}">{{rowData.vl_oper_dep | money}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.parc_oper_nao_bc_cred}}">{{rowData.parc_oper_nao_bc_cred}}</span>
                        </span>
                      </td>


                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_pis}}">{{rowData.cst_pis}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_pis | money}}">{{rowData.vl_pis | money}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_cofins}}">{{rowData.cst_cofins}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_cofins | money}}">{{rowData.vl_cofins | money}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_cta}}">{{rowData.cod_cta}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_cc}}">{{rowData.cod_cc}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.desc_bem_imob}}">{{rowData.desc_bem_imob}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>

              <div [ngStyle]="statusF130 ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusF130'}">
                <!--ESTRUTURA F130: Bens Incorporados ao Ativo - Aquisição/Contribuição -->

                <p-table class="table-system" #dtF130 [value]="tF130" [columns]="colsTF130" [rows]="rows"
                  [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'nat_bc_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Nat. BC Cred. <p-sortIcon
                              [field]="'nat_bc_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'nat_bc_cred', 'contains')">
                      </th>

                      <th class="text-right" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'indent_bem_imob'">
                          <span class="text-overflow-dynamic-ellipsis">Indent. Bem Imob. <p-sortIcon
                              [field]="'indent_bem_imob'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'indent_bem_imob', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_orig_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Orig. Cred. <p-sortIcon
                              [field]="'ind_orig_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'ind_orig_cred', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_util_bem_imob'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Util. Bem Imob. <p-sortIcon
                              [field]="'ind_util_bem_imob'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'ind_util_bem_imob', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'mes_oper_aquis'">
                          <span class="text-overflow-dynamic-ellipsis">Mês Oper. Aquis. <p-sortIcon
                              [field]="'mes_oper_aquis'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'mes_oper_aquis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_oper_aquis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Oper. Aquis. <p-sortIcon
                              [field]="'vl_oper_aquis'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'vl_oper_aquis', 'contains')">
                      </th>


                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'parc_oper_nao_bc_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Parc. Oper. Não Bc Cred. <p-sortIcon
                              [field]="'parc_oper_nao_bc_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'parc_oper_nao_bc_cred', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC. Cred. <p-sortIcon [field]="'vl_bc_cred'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'vl_bc_cred', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ind_num_parc'">
                          <span class="text-overflow-dynamic-ellipsis">Ind. Nr. Parc. <p-sortIcon
                              [field]="'ind_num_parc'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'ind_num_parc', 'contains')">
                      </th>

                      <th class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_pis'">
                          <span class="text-overflow-dynamic-ellipsis">CST PIS <p-sortIcon [field]="'cst_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'cst_pis', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC PIS <p-sortIcon [field]="'vl_bc_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'vl_bc_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'aliq_pis', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_pis'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'vl_pis'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'vl_pis', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">CST COFINS <p-sortIcon [field]="'cst_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'cst_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. BC COFINS <p-sortIcon
                              [field]="'vl_bc_cofins'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'vl_bc_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon [field]="'aliq_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'aliq_cofins', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_cofins'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'vl_cofins'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'vl_cofins', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. CTA. <p-sortIcon [field]="'cod_cta'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'cod_cta', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cc'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. CC. <p-sortIcon [field]="'cod_cc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'cod_cc', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_bem_imob'">
                          <span class="text-overflow-dynamic-ellipsis">Desc. Bem Imob. <p-sortIcon
                              [field]="'desc_bem_imob'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtF130.filter($event.target.value, 'desc_bem_imob', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDadosF130(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.nat_bc_cred}}">{{rowData.nat_bc_cred }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.indent_bem_imob}}">{{rowData.indent_bem_imob }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_orig_cred}}">{{rowData.ind_orig_cred }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_util_bem_imob}}">{{rowData.ind_util_bem_imob}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.mes_oper_aquis}}">{{rowData.mes_oper_aquis}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_oper_aquis | money}}">{{rowData.vl_oper_aquis | money}}</span>
                        </span>
                      </td>


                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.parc_oper_nao_bc_cred}}">{{rowData.parc_oper_nao_bc_cred}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_cred | money}}">{{rowData.vl_bc_cred | money}}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ind_num_parc}}">{{rowData.ind_num_parc}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_pis}}">{{rowData.cst_pis}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_pis | money}}">{{rowData.vl_pis | money}}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_cofins}}">{{rowData.cst_cofins}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_cofins | money}}">{{rowData.vl_cofins | money}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_cta}}">{{rowData.cod_cta}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_cc}}">{{rowData.cod_cc}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.desc_bem_imob}}">{{rowData.desc_bem_imob}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="21" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <!-- End Demais Itens Menu-->
            </div>
          </div>
        </div>
      </div>

      <!--Gerar Apuração ICMS/IPI (Primeira linha)-->
      <div class="row" [ngStyle]="gerarApuracaoIcmsIpi ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'gerarApuracaoIcmsIpi'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: apuracaoICMS}"><a
                      (click)="showTypeApuracaoICMSIPI('LINK_1')">Apuração ICMS</a></li>
                  <li class="list-inline-item" [ngClass]="{active: apuracaoICMSST}"><a
                      (click)="showTypeApuracaoICMSIPI('LINK_2')">Apuração ICMS-ST</a></li>
                  <li class="list-inline-item" [ngClass]="{active: apuracaoIPI}"><a
                      (click)="showTypeApuracaoICMSIPI('LINK_3')">Apuração IPI</a></li>
                </ul>
                <div class="actions-card">

                  <button *ngIf="!apuracaoIPI && !apuracaoICMSST" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoICMS.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="!apuracaoICMS && !apuracaoIPI" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoICMSST.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="!apuracaoICMS && !apuracaoICMSST" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoIPI.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>

                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeGerarApuracao()"
                    (click)="changeStateGerarApuracao()">
                    <span *ngIf="isCardGerarApuracao" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardGerarApuracao" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateGerarApuracao>
              <!-- START APURAÇÃO ICMS-->
              <!--ESTRUTURA ICMS -->
              <div [ngStyle]="!apuracaoIPI && !apuracaoICMSST ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': '!apuracaoIPI && !apuracaoICMSST'}">

                <p-table class="table-system" #dtApuracaoICMS [columns]="colstApuracaoICMS" [value]="tApuracaoICMS"
                  [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_tot_debitos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total dos débitos do Imposto <p-sortIcon
                              [field]="'vl_tot_debitos'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_tot_debitos', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_aj_debitos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total ajustes a débito (doc fiscal)
                            <p-sortIcon [field]="'vl_aj_debitos'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_aj_debitos', 'contains')">
                      </th>
                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_tot_aj_debitos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total dos ajustes a débito <p-sortIcon
                              [field]="'vl_tot_aj_debitos'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_tot_aj_debitos', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_estornos_cred'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total dos estornos de créditos <p-sortIcon
                              [field]="'vl_estornos_cred'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_estornos_cred', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_tot_creditos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total dos créditos do imposto <p-sortIcon
                              [field]="'vl_tot_creditos'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_tot_creditos', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_aj_creditos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total ajustes a crédito (doc fiscal)
                            <p-sortIcon [field]="'vl_aj_creditos'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_aj_creditos', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_tot_aj_creditos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total dos ajustes a crédito <p-sortIcon
                              [field]="'vl_tot_aj_creditos'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_tot_aj_creditos', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_estornos_deb'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total dos estornos de créditos <p-sortIcon
                              [field]="'vl_estornos_deb'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_estornos_deb', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_sld_credor_ant'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo credor do período anterior <p-sortIcon
                              [field]="'vl_sld_credor_ant'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_sld_credor_ant', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_sld_apurado'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do saldo devedor <p-sortIcon
                              [field]="'vl_sld_apurado'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_sld_apurado', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_tot_ded'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total das deduções <p-sortIcon
                              [field]="'vl_tot_ded'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_tot_ded', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_icms_recolher'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total do ICMS a recolher <p-sortIcon
                              [field]="'vl_icms_recolher'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_icms_recolher', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_sld_credor_transportar'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do saldo credor do ICMS <p-sortIcon
                              [field]="'vl_sld_credor_transportar'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'vl_sld_credor_transportar', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'deb_exp'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. recolhidos ou a recolher, extra-apuração
                            <p-sortIcon [field]="'deb_exp'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMS.filter($event.target.value, 'deb_exp', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_tot_debitos | money}}">{{rowData.vl_tot_debitos | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_aj_debitos | money}}">{{rowData.vl_aj_debitos | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_tot_aj_debitos | money}}">{{rowData.vl_tot_aj_debitos |
                            money}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_estornos_cred | money}}">{{rowData.vl_estornos_cred |
                            money}}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_tot_creditos | money}}">{{rowData.vl_tot_creditos | money }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_aj_creditos | money}}">{{rowData.vl_aj_creditos | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_tot_aj_creditos | money}}">{{rowData.vl_tot_aj_creditos | money
                            }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_estornos_deb | money}}">{{rowData.vl_estornos_deb | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_sld_credor_ant | money}}">{{rowData.vl_sld_credor_ant | money
                            }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_sld_apurado | money}}">{{rowData.vl_sld_apurado | money }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_tot_ded | money}}">{{rowData.vl_tot_ded | money }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_icms_recolher | money}}">{{rowData.vl_icms_recolher | money
                            }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_sld_credor_transportar | money}}">{{rowData.vl_sld_credor_transportar
                            | money }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.deb_exp}}">{{rowData.deb_exp }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="13" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <!-- End APURAÇÃO ICMS-->

              <!-- START APURAÇÃO ICMS-ST-->
              <!--ESTRUTURA apuracaoICMSST -->

              <div [ngStyle]="!apuracaoICMS && !apuracaoIPI ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': '!apuracaoICMS && !apuracaoIPI '}">


                <p-table class="table-system" #dtApuracaoICMSST [value]="tApuracaoICMSST"
                  [columns]="colsTApuracaoICMSST" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-center" style="width: 33%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                          <span class="text-overflow-dynamic-ellipsis">&nbsp;&nbsp;&nbsp;&nbsp;UF <p-sortIcon
                              [field]="'uf'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSST.filter($event.target.value, 'uf', 'contains')">
                      </th>

                      <th class="text-center" style="width: 33%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_inicial'">
                          <span class="text-overflow-dynamic-ellipsis">&nbsp;&nbsp;DATA INICIAL <p-sortIcon
                              [field]="'dt_inicial'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSST.filter($event.target.value, 'dt_inicial', 'contains')">
                      </th>


                      <th class="text-center" style="width: 33%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_final'">
                          <span class="text-overflow-dynamic-ellipsis">&nbsp;&nbsp;DATA FINAL <p-sortIcon
                              [field]="'dt_final'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSST.filter($event.target.value, 'dt_final', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="edit" matTooltip="Analisar" (click)="showIcmsstDetalhe(rowData)">
                          <i class="fas fa-search"></i>
                        </span>

                      </td>

                      <td class="text-center" style="width: 33%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}">{{rowData.uf
                            }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 33%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.dt_inicial}}">{{rowData.dt_inicial }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 33%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.dt_final}}">{{rowData.dt_final }}</span>
                        </span>
                      </td>



                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <!-- END APURAÇÃO ICMS-ST-->

              <!-- START APURAÇÃO IPI-->
              <!--ESTRUTURA valoresConsolidados && ipiAjuste && ipiSaldoCredor IPI (FALTA CONTEUDO) -->
              <div [ngStyle]="!apuracaoICMS && !apuracaoICMSST ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': '!apuracaoICMS && !apuracaoICMSST'}">


                <p-table class="table-system" #dtApuracaoIPI [value]="tApuracaoIPI" [columns]="colsTApuracaoIPI"
                  [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_cred_peri_ant'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo credor do período anterior <p-sortIcon
                              [field]="'saldo_cred_peri_ant'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'saldo_cred_peri_ant', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_debitos_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. dos débitos do IPI <p-sortIcon
                              [field]="'vl_debitos_ipi'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'vl_debitos_ipi', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_creditos_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. dos créditos do IPI <p-sortIcon
                              [field]="'vl_creditos_ipi'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'vl_creditos_ipi', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_outros_debitos_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. outros débitos do IPI <p-sortIcon
                              [field]="'vl_outros_debitos_ipi'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'vl_outros_debitos_ipi', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_outros_creditos_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. outros créditos do IPI <p-sortIcon
                              [field]="'vl_outros_creditos_ipi'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'vl_outros_creditos_ipi', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_saldo_cred_per'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. saldo credor no período <p-sortIcon
                              [field]="'vl_saldo_cred_per'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'vl_saldo_cred_per', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_saldo_devedor_recolher'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. saldo devedor a recolher <p-sortIcon
                              [field]="'vl_saldo_devedor_recolher'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPI.filter($event.target.value, 'vl_saldo_devedor_recolher', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_cred_peri_ant}}">{{rowData.saldo_cred_peri_ant }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_debitos_ipi | money}}">{{rowData.vl_debitos_ipi | money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_creditos_ipi | money}}">{{rowData.vl_creditos_ipi | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_outros_debitos_ipi | money}}">{{rowData.vl_outros_debitos_ipi |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_outros_creditos_ipi | money}}">{{rowData.vl_outros_creditos_ipi |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_saldo_cred_per | money}}">{{rowData.vl_saldo_cred_per |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_saldo_devedor_recolher | money}}">{{rowData.vl_saldo_devedor_recolher
                            | money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="7" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <!-- End APURAÇÃO IPI-->
            </div>
          </div>
        </div>
      </div>

      <div class="row" [ngStyle]="gerarApuracaoIcmsIpi && !apuracaoICMSST ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'gerarApuracaoIcmsIpi && !apuracaoICMSST'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <div class="tabui" [ngStyle]="apuracaoICMS ? {'display': 'block'} : {'display': 'none'}"
                  style="max-width: 90%;">
                  <ul class="list-inline filter-menu filter-card-header mt-0">
                    <li class="list-inline-item" [ngClass]="{active: ajusteBeneficioIncentivo}"><a
                        (click)="showTypeSubApuracaoICMS('LINK_1')">Ajuste/Benefício/Incentivo</a></li>
                    <li class="list-inline-item" [ngClass]="{active: saldoCredor}"><a
                        (click)="showTypeSubApuracaoICMS('LINK_2')">Saldo Credor</a></li>
                  </ul>
                </div>
                <div class="tabui" [ngStyle]="apuracaoIPI ? {'display': 'block'} : {'display': 'none'}"
                  style="max-width: 90%;">
                  <ul class="list-inline filter-menu filter-card-header mt-0">
                    <li class="list-inline-item" [ngClass]="{active: valoresConsolidados}"><a
                        (click)="showTypeSubApuracaoIPI('LINK_1')">Valores Consolidados</a></li>
                    <li class="list-inline-item" [ngClass]="{active: ipiAjuste}"><a
                        (click)="showTypeSubApuracaoIPI('LINK_2')">Ajuste</a></li>
                    <li class="list-inline-item" [ngClass]="{active: ipiSaldoCredor}"><a
                        (click)="showTypeSubApuracaoIPI('LINK_3')">Saldo Credor</a></li>
                  </ul>
                </div>
                <div class="actions-card">
                  <button *ngIf="apuracaoICMS && ajusteBeneficioIncentivo" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoBeneficioIncentivo.exportCSV()" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="apuracaoIPI && valoresConsolidados" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoIPIConsolidados.exportCSV()" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="apuracaoIPI && ipiAjuste" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoIPIAjuste.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeGerarApuracaoSub()"
                    (click)="changeStateGerarApuracaoSub()">
                    <span *ngIf="isCardGerarApuracaoSub" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardGerarApuracaoSub" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateGerarApuracaoSub>
              <!-- START APURAÇÃO ICMS-->
              <!--ESTRUTURA ajusteBeneficioIncentivo ICMS -->
              <div [ngStyle]="apuracaoICMS && ajusteBeneficioIncentivo ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'apuracaoICMS && ajusteBeneficioIncentivo'}">


                <p-table class="table-system" #dtApuracaoBeneficioIncentivo [value]="tApuracaoBeneficioIncentivo"
                  [columns]="colsTApuracaoBeneficioIncentivo" [rows]="rows" [paginator]="true"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-left" style="width: 15%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_apuracao_deducao'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. ajuste da apuração/dedução <p-sortIcon
                              [field]="'cod_apuracao_deducao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoBeneficioIncentivo.filter($event.target.value, 'cod_apuracao_deducao', 'contains')">
                      </th>

                      <th class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_complementar'">
                          <span class="text-overflow-dynamic-ellipsis">Descrição Complementar <p-sortIcon
                              [field]="'desc_complementar'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoBeneficioIncentivo.filter($event.target.value, 'desc_complementar', 'contains')">
                      </th>


                      <th class="text-right" style="width: 15%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste_apuracao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do ajuste da apuração <p-sortIcon
                              [field]="'vl_ajuste_apuracao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoBeneficioIncentivo.filter($event.target.value, 'vl_ajuste_apuracao', 'contains')">
                      </th>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDadosE111(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 15%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_apuracao_deducao}}">{{rowData.cod_apuracao_deducao }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.desc_complementar}}">{{rowData.desc_complementar }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 15%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_ajuste_apuracao | money}}">{{rowData.vl_ajuste_apuracao | money
                            }}</span>
                        </span>
                      </td>


                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-sm btn-info mt-2" type="button" (click)="openModalAjusteBeneficio()"><i
                        class="fas fa-plus"></i> Adicionar Ajuste</button>
                  </div>
                </div>
              </div>

              <!--ESTRUTURA saldoCredor ICMS -->
              <div [ngStyle]="apuracaoICMS && saldoCredor ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'apuracaoICMS && saldoCredor'}">
                <div class="form-row">
                  <div class="form-group pull-left pr-3">
                    <label>VL. do Saldo Credor do Período Anterior</label>
                    <input type="text" class="form-control text-right" money [(ngModel)]="valorSaldoCredorIcms" />
                  </div>
                  <div class="form-group pull-left">
                    <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
                    <button class="btn btn-sm btn-info" style="margin-top: 6px;" type="button"
                      (click)="atualizarSaldoCredorICMS()"><i class="fas fa-check"></i> Atualizar Saldo</button>
                  </div>
                </div>
              </div>
              <!-- End APURAÇÃO ICMS-->

              <!-- START APURAÇÃO IPI-->
              <!--ESTRUTURA valoresConsolidados -->
              <div [ngStyle]="apuracaoIPI && valoresConsolidados ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'apuracaoIPI && valoresConsolidados'}">

                <p-table class="table-system" #dtApuracaoIPIConsolidados [value]="tApuracaoIPIConsolidados"
                  [columns]="colsTApuracaoIPIConsolidados" [rows]="rows" [paginator]="true"
                  (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
                          <span class="text-overflow-dynamic-ellipsis">CFOP <p-sortIcon [field]="'cfop'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIConsolidados.filter($event.target.value, 'cfop', 'contains')">
                      </th>

                      <th class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">CST IPI <p-sortIcon [field]="'cst_ipi'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIConsolidados.filter($event.target.value, 'cst_ipi', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_contabil'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Contábil <p-sortIcon [field]="'vl_contabil'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIConsolidados.filter($event.target.value, 'vl_contabil', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">BC IPI <p-sortIcon [field]="'bc_ipi'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIConsolidados.filter($event.target.value, 'bc_ipi', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ipi'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. IPI <p-sortIcon [field]="'vl_ipi'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIConsolidados.filter($event.target.value, 'vl_ipi', 'contains')">
                      </th>



                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}">{{rowData.cfop
                            }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cst_ipi}}">{{rowData.cst_ipi }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_contabil | money}}">{{rowData.vl_contabil | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.bc_ipi}}">{{rowData.bc_ipi}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_ipi | money}}">{{rowData.vl_ipi | money}}</span>
                        </span>
                      </td>




                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>

              <!--ESTRUTURA apuracaoIPI && ipiAjuste IPI -->
              <div [ngStyle]="apuracaoIPI && ipiAjuste ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'apuracaoIPI && ipiAjuste'}">


                <p-table class="table-system" #dtApuracaoIPIAjuste [value]="tApuracaoIPIAjuste"
                  [columns]="colsTApuracaoIPIAjuste" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center" style="width: 90px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                        </span>

                      </th>

                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ajuste'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo de Ajuste <p-sortIcon
                              [field]="'tipo_ajuste'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIAjuste.filter($event.target.value, 'tipo_ajuste', 'contains')">
                      </th>

                      <th class="text-right" style="width: 30%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_ajuste'">
                          <span class="text-overflow-dynamic-ellipsis">Cód. Ajuste <p-sortIcon [field]="'cod_ajuste'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIAjuste.filter($event.target.value, 'cod_ajuste', 'contains')">
                      </th>


                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem_doc'">
                          <span class="text-overflow-dynamic-ellipsis">Origem Documento <p-sortIcon
                              [field]="'origem_doc'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIAjuste.filter($event.target.value, 'origem_doc', 'contains')">
                      </th>


                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_doc'">
                          <span class="text-overflow-dynamic-ellipsis">Nº Documento <p-sortIcon [field]="'num_doc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIAjuste.filter($event.target.value, 'num_doc', 'contains')">
                      </th>

                      <th class="text-right" style="width: 20%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                          <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIAjuste.filter($event.target.value, 'descricao', 'contains')">
                      </th>

                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. Ajuste <p-sortIcon [field]="'vl_ajuste'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoIPIAjuste.filter($event.target.value, 'vl_ajuste', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-center" style="width: 90px;">
                        <span class="delete" matTooltip="Excluir" (click)="removerDadosE530(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>

                      </td>

                      <td class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.tipo_ajuste}}">{{rowData.tipo_ajuste }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 30%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.cod_ajuste}}">{{rowData.cod_ajuste }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.origem_doc}}">{{rowData.origem_doc }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.num_doc}}">{{rowData.num_doc}}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_ajuste | money}}">{{rowData.vl_ajuste | money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="7" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


                <div class="row">
                  <div class="col-sm-12">
                    <button class="btn btn-sm btn-info mt-2" type="button" [disabled]="!tCurrentApuracaoIPI"
                      (click)="openModalAjusteApuracaoIPI()"><i class="fas fa-plus"></i> Adicionar Ajuste</button>
                  </div>
                </div>
              </div>

              <!--ESTRUTURA saldoCredor IPI -->
              <div [ngStyle]="apuracaoIPI && ipiSaldoCredor ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'apuracaoIPI && ipiSaldoCredor'}">
                <div class="form-row">
                  <div class="form-group pull-left pr-3">
                    <label>VL. do Saldo Credor do Período Anterior</label>
                    <input type="text" class="form-control text-right" money [(ngModel)]="valorSaldoCredorIpi" />
                  </div>
                  <div class="form-group pull-left">
                    <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
                    <button class="btn btn-sm btn-info" style="margin-top: 6px;" type="button"
                      (click)="atualizarSaldoCredorIPI()"><i class="fas fa-check"></i> Atualizar Saldo</button>
                  </div>
                </div>
              </div>
              <!-- End APURAÇÃO IPI-->
            </div>
          </div>
        </div>
      </div>

      <!--Spaced PIS -->
      <div class="row" [ngStyle]="pisDetalhe ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'pisDetalhe'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <!--Start PIS-->
                <ul class="list-inline filter-menu filter-card-header mt-0" *ngIf="creditoPisPasep">
                  <li class="list-inline-item" [ngClass]="{active: statusBaseCalculoPis}"><a
                      (click)="showTypeDetalhePis('LINK_1')">Detalhamento da Base de Cálculo do Crédito</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusAjusteCreditoPis}"><a
                      (click)="showTypeDetalhePis('LINK_2')">Ajustes do Crédito de PIS/PASEP</a></li>
                </ul>
                <ul class="list-inline filter-menu filter-card-header mt-0" *ngIf="contribuicaoPisPasep">
                  <li class="list-inline-item" [ngClass]="{active: statusDetalheContribuicaoPis}"><a
                      (click)="showTypeDetalheContribuicaoPis('LINK_1')">Detalhamento da Contribuição</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusAjusteContribuicaoPis}"><a
                      (click)="showTypeDetalheContribuicaoPis('LINK_2')">Ajustes da Contribuição de PIS/PASEP</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusRetidoFontePis}"><a
                      (click)="showTypeDetalheContribuicaoPis('LINK_3')">Valor Retido na Fonte</a></li>
                </ul>
                <!--End PIS-->

                <div class="actions-card">

                  <button *ngIf="creditoPisPasep && statusBaseCalculoPis" type="button" class="btn btn-xs btn-link"
                    (click)="dtDetalhePis.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="creditoPisPasep && statusAjusteCreditoPis" type="button" class="btn btn-xs btn-link"
                    (click)="dttAjustePis.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="contribuicaoPisPasep && statusDetalheContribuicaoPis && tDetalheContribuicaoPis"
                    type="button" class="btn btn-xs btn-link" (click)="dtDetalheContribuicaoPis.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="contribuicaoPisPasep && statusAjusteContribuicaoPis" type="button"
                    class="btn btn-xs btn-link" (click)="dttAjusteContribuicaoPis.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>

                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardPisDetalhe()"
                    (click)="changeStateDetalhePis()">
                    <span *ngIf="isCardDetalhePis" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardDetalhePis" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateDetalhePis>
              <!--Start Crédito de PIS/PASEP-->
              <div [ngStyle]="creditoPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'creditoPisPasep'}">
                <div [ngStyle]="statusBaseCalculoPis ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusBaseCalculoPis'}">
                  <!--ESTRUTURA Detalhamento da Base de Cálculo do Crédito -->

                  <p-table class="table-system" #dtDetalhePis [columns]="colstDetalhePis" [value]="tDetalhePis"
                    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                    [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>

                        </th>

                        <th class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'natureza_base_credito'">
                            <span class="text-overflow-dynamic-ellipsis">Natureza de Base de Crédito <p-sortIcon
                                [field]="'natureza_base_credito'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalhePis.filter($event.target.value, 'natureza_base_credito', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst'">
                            <span class="text-overflow-dynamic-ellipsis">CST <p-sortIcon [field]="'cst'"></p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalhePis.filter($event.target.value, 'cst', 'contains')">
                        </th>


                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_pis'">
                            <span class="text-overflow-dynamic-ellipsis">BC PIS <p-sortIcon [field]="'bc_pis'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalhePis.filter($event.target.value, 'bc_pis', 'contains')">
                        </th>


                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_pis_prop'">
                            <span class="text-overflow-dynamic-ellipsis">BC PIS Prop <p-sortIcon
                                [field]="'bc_pis_prop'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalhePis.filter($event.target.value, 'bc_pis_prop', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                            <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalhePis.filter($event.target.value, 'aliq_pis', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_pis'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'valor_pis'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalhePis.filter($event.target.value, 'valor_pis', 'contains')">
                        </th>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">


                        <td class="text-center" style="width: 90px;">
                          <span class="edit" matTooltip="Analisar" (click)="openModalBaseCreditoPis(rowData)">
                            <i class="fas fa-search"></i>
                          </span>

                        </td>

                        <td class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.natureza_base_credito}}">{{rowData.natureza_base_credito }}</span>
                          </span>
                        </td>

                        <td class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cst}}">{{rowData.cst
                              }}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.bc_pis| money}}">{{rowData.bc_pis| money }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.bc_pis_prop| money}}">{{rowData.bc_pis_prop| money}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.valor_pis | money}}">{{rowData.valor_pis | money}}</span>
                          </span>
                        </td>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="5" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
                <div [ngStyle]="statusAjusteCreditoPis ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusAjusteCreditoPis'}">
                  <!--ESTRUTURA Ajuste de Crédito de PIS/PASEP -->

                  <p-table class="table-system" [columns]="colsdttAjustePis" #dttAjustePis [value]="tAjustePis"
                    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                    [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>

                        </th>

                        <th class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                            <span class="text-overflow-dynamic-ellipsis">Data Ref. <p-sortIcon [field]="'data'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjustePis.filter($event.target.value, 'data', 'contains')">
                        </th>

                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Tipo Ajuste <p-sortIcon
                                [field]="'tipo_ajuste'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjustePis.filter($event.target.value, 'tipo_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Cód. Ajuste <p-sortIcon [field]="'cod_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjustePis.filter($event.target.value, 'cod_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjustePis.filter($event.target.value, 'descricao', 'contains')">
                        </th>

                        <th class="text-left" style="width: 15%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_doc'">
                            <span class="text-overflow-dynamic-ellipsis">num_doc <p-sortIcon [field]="'num_doc'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjustePis.filter($event.target.value, 'num_doc', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. Ajuste <p-sortIcon [field]="'vl_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjustePis.filter($event.target.value, 'vl_ajuste', 'contains')">
                        </th>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">


                        <td class="text-center" style="width: 90px;">
                          <span class="delete" matTooltip="Excluir" (click)="removerAjustePis(rowData)">
                            <i class="fas fa-trash-alt"></i>
                          </span>

                        </td>

                        <td class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data
                              }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.tipo_ajuste}}">{{rowData.tipo_ajuste }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.cod_ajuste}}">{{rowData.cod_ajuste }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 15%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.num_doc}}">{{rowData.num_doc}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.vl_ajuste | money}}">{{rowData.vl_ajuste | money}}</span>
                          </span>
                        </td>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="7" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>



                  <div class="row">
                    <div class="col-sm-12">
                      <button class="btn btn-sm btn-info mt-2" type="button" (click)="openModalAjustePIS()"><i
                          class="fas fa-plus"></i> Adicionar Ajuste</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--End Crédito de PIS/PASEP-->

              <!--Start Contribuição de PIS/PASEP-->
              <div [ngStyle]="contribuicaoPisPasep ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'contribuicaoPisPasep'}">
                <div [ngStyle]="statusDetalheContribuicaoPis ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDetalheContribuicaoPis'}">
                  <!--ESTRUTURA Detalhamento da Base de Cálculo do Crédito -->

                  <p-table class="table-system" #dtDetalheContribuicaoPis [value]="tDetalheContribuicaoPis"
                    [columns]="colstDetalheContribuicaoPis" [rows]="rows" [paginator]="true"
                    (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                    selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>

                        </th>

                        <th class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_contribuicao'">
                            <span class="text-overflow-dynamic-ellipsis">Cód. Contribuição <p-sortIcon
                                [field]="'cod_contribuicao'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoPis.filter($event.target.value, 'cod_contribuicao', 'contains')">
                        </th>


                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_receita'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. Receita <p-sortIcon [field]="'vl_receita'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoPis.filter($event.target.value, 'vl_receita', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_pis'">
                            <span class="text-overflow-dynamic-ellipsis">BC PIS <p-sortIcon [field]="'bc_pis'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoPis.filter($event.target.value, 'bc_pis', 'contains')">
                        </th>


                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
                            <span class="text-overflow-dynamic-ellipsis">Aliq. PIS <p-sortIcon [field]="'aliq_pis'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoPis.filter($event.target.value, 'aliq_pis', 'contains')">
                        </th>


                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_pis'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'valor_pis'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoPis.filter($event.target.value, 'valor_pis', 'contains')">
                        </th>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">


                        <td class="text-center" style="width: 90px;">
                          <span class="edit" matTooltip="Analisar" (click)="openModalContribuicaoPis(rowData)">
                            <i class="fas fa-search"></i>
                          </span>

                        </td>

                        <td class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM210(rowData)"
                              matTooltip="{{rowData.cod_contribuicao}}">{{rowData.cod_contribuicao }}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM210(rowData)"
                              matTooltip="{{rowData.vl_receita | money}}">{{rowData.vl_receita | money }}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <!-- <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM210(rowData)" matTooltip="{{rowData.bc_pis}}" >{{rowData.bc_pis }}</span> -->
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM210(rowData)"
                              matTooltip="{{rowData.bc_pis| money}}">{{rowData.bc_pis| money }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM210(rowData)"
                              matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM210(rowData)"
                              matTooltip="{{rowData.valor_pis | money}}">{{rowData.valor_pis | money}}</span>
                          </span>
                        </td>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="6" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
                <div [ngStyle]="statusAjusteContribuicaoPis ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusAjusteContribuicaoPis'}">
                  <!--ESTRUTURA Ajustes de Contribuição de PIS/PASEP -->

                  <p-table class="table-system" #dttAjusteContribuicaoPis [columns]="colstAjusteContribuicaoPis"
                    [value]="tAjusteContribuicaoPis" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                    [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>

                        </th>

                        <th class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                            <span class="text-overflow-dynamic-ellipsis">Data Ref. <p-sortIcon [field]="'data'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoPis.filter($event.target.value, 'data', 'contains')">
                        </th>

                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Tipo Ajuste <p-sortIcon
                                [field]="'tipo_ajuste'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoPis.filter($event.target.value, 'tipo_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Cód. Ajuste <p-sortIcon [field]="'cod_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoPis.filter($event.target.value, 'cod_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoPis.filter($event.target.value, 'descricao', 'contains')">
                        </th>

                        <th class="text-left" style="width: 15%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_doc'">
                            <span class="text-overflow-dynamic-ellipsis">num_doc <p-sortIcon [field]="'num_doc'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoPis.filter($event.target.value, 'num_doc', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. Ajuste <p-sortIcon [field]="'vl_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoPis.filter($event.target.value, 'vl_ajuste', 'contains')">
                        </th>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">


                        <td class="text-center" style="width: 90px;">
                          <span class="delete" matTooltip="Excluir" (click)="removerAjusteContribuicaoPis(rowData)">
                            <i class="fas fa-trash-alt"></i>
                          </span>

                        </td>

                        <td class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data
                              }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.tipo_ajuste}}">{{rowData.tipo_ajuste }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.cod_ajuste}}">{{rowData.cod_ajuste }}</span>
                          </span>
                        </td>
                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 15%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.num_doc}}">{{rowData.num_doc}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.vl_ajuste | money}}">{{rowData.vl_ajuste | money}}</span>
                          </span>
                        </td>





                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="5" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>



                  <div class="row">
                    <div class="col-sm-12">
                      <button class="btn btn-sm btn-info mt-2" type="button" [disabled]="!tDetalheContribuicaoPis"
                        (click)="openModalAjusteContribuicaoPIS()"><i class="fas fa-plus"></i> Adicionar Ajuste</button>
                    </div>
                  </div>
                </div>
                <div [ngStyle]="statusRetidoFontePis ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusRetidoFontePis'}">
                  <div class="form-row">
                    <div class="form-group pull-left pl-1 pr-3">
                      <label>Vl. Retido Não Cumulativo PIS</label>
                      <input type="text" class="form-control text-right" money [(ngModel)]="vlRetidoNaoCumulativoPis" />
                    </div>
                    <div class="form-group pull-left pr-3">
                      <label>Vl. Retido Cumulativo PIS</label>
                      <input type="text" class="form-control text-right" money [(ngModel)]="vlRetidoCumulativoPis" />
                    </div>
                    <div class="form-group pull-left">
                      <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
                      <button class="btn btn-sm btn-info" style="margin-top: 6px;" type="button"
                        (click)="atualizarValorRetidoPis()"><i class="fas fa-check"></i> Salvar Valores</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--End Contribuição de PIS/PASEP-->
            </div>
          </div>
        </div>
      </div>

      <!--Spaced COFINS -->
      <div class="row" [ngStyle]="cofinsDetalhe ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'cofinsDetalhe'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">

                <!--Start COFINS-->
                <ul class="list-inline filter-menu filter-card-header mt-0" *ngIf="creditoCofins">
                  <li class="list-inline-item" [ngClass]="{active: statusBaseCalculoCofins}"><a
                      (click)="showTypeDetalheCofins('LINK_1')">Detalhamento da Base de Cálculo do Crédito COFINS</a>
                  </li>
                  <li class="list-inline-item" [ngClass]="{active: statusAjusteCreditoCofins}"><a
                      (click)="showTypeDetalheCofins('LINK_2')">Ajustes do Crédito de COFINS</a></li>
                </ul>
                <ul class="list-inline filter-menu filter-card-header mt-0" *ngIf="contribuicaoCofins">
                  <li class="list-inline-item" [ngClass]="{active: statusDetalheContribuicaoCofins}"><a
                      (click)="showTypeDetalheContribuicaoCofins('LINK_1')">Detalhamento da Contribuição COFINS</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusAjusteContribuicaoCofins}"><a
                      (click)="showTypeDetalheContribuicaoCofins('LINK_2')">Ajustes da Contribuição de COFINS</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusRetidoFonteCofins}"><a
                      (click)="showTypeDetalheContribuicaoCofins('LINK_3')">Valor Retido na Fonte COFINS</a></li>
                </ul>
                <!--End COFINS-->

                <div class="actions-card">
                  <button *ngIf="creditoCofins && statusBaseCalculoCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dtDetalheCofins.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="creditoCofins && statusAjusteCreditoCofins" type="button" class="btn btn-xs btn-link"
                    (click)="dttAjusteCofins.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above"><i
                      class="far fa-file-excel excel"></i> </button>
                  <button *ngIf="contribuicaoCofins && statusDetalheContribuicaoCofins && tDetalheContribuicaoCofins"
                    type="button" class="btn btn-xs btn-link" (click)="dtDetalheContribuicaoCofins.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="contribuicaoCofins && statusAjusteContribuicaoCofins" type="button"
                    class="btn btn-xs btn-link" (click)="dttAjusteContribuicaoCofins.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button *ngIf="icmsstDetalhe" type="button" class="btn btn-xs btn-link"
                    (click)="dtApuracaoICMSSTDetalhe.exportCSV()" matTooltip="Exportar Excel"
                    matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardCofinsDetalhe()"
                    (click)="changeStateDetalheCofins()">
                    <span *ngIf="isCardDetalheCofins" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardDetalheCofins" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateDetalheCofins>
              <!--Start Crédito de COFINS-->
              <div [ngStyle]="creditoCofins? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'creditoCofins'}">
                <div [ngStyle]="statusBaseCalculoCofins ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusBaseCalculoCofins'}">
                  <h3 class="sub-title"><i class="fas fa-bars"></i> Detalhamento da Base de Cálculo do Crédito COFINS
                  </h3>

                  <p-table class="table-system" #dtDetalheCofins [columns]="colstDetalheCofins" [value]="tDetalheCofins"
                    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                    [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>
                        </th>

                        <th class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'natureza_base_credito'">
                            <span class="text-overflow-dynamic-ellipsis">Natureza de Base de Crédito <p-sortIcon
                                [field]="'natureza_base_credito'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheCofins.filter($event.target.value, 'natureza_base_credito', 'contains')">
                        </th>
                        <th class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst'">
                            <span class="text-overflow-dynamic-ellipsis">cst <p-sortIcon [field]="'cst'"></p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheCofins.filter($event.target.value, 'cst', 'contains')">
                        </th>
                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_cofins'">
                            <span class="text-overflow-dynamic-ellipsis">BC COFINS <p-sortIcon [field]="'bc_cofins'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheCofins.filter($event.target.value, 'bc_cofins', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_cofins_prop'">
                            <span class="text-overflow-dynamic-ellipsis">BC COFINS Prop <p-sortIcon
                                [field]="'bc_cofins_prop'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheCofins.filter($event.target.value, 'bc_cofins_prop', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                            <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon
                                [field]="'aliq_cofins'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheCofins.filter($event.target.value, 'aliq_cofins', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cofins'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon
                                [field]="'valor_cofins'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheCofins.filter($event.target.value, 'valor_cofins', 'contains')">
                        </th>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">

                        <td class="text-center" style="width: 90px;">
                          <span class="edit" matTooltip="Analisar" (click)="openModalBaseCreditoCofins(rowData)">
                            <i class="fas fa-search"></i>
                          </span>
                        </td>

                        <td class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.natureza_base_credito}}">{{rowData.natureza_base_credito }}</span>
                          </span>
                        </td>
                        <td class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cst}}">{{rowData.cst
                              }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.bc_cofins}}">{{rowData.bc_cofins}}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.bc_cofins_prop}}">{{rowData.bc_cofins_prop}}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins }}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.valor_cofins | money}}">{{rowData.valor_cofins | money }}</span>
                          </span>
                        </td>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="13" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
                <div [ngStyle]="statusAjusteCreditoCofins ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusAjusteCreditoCofins'}">
                  <h3 class="sub-title"><i class="fas fa-bars"></i> Ajustes do Crédito de COFINS</h3>

                  <p-table class="table-system" #dttAjusteCofins [columns]="colstAjusteCofins" [value]="tAjusteCofins"
                    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                    [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>

                        </th>

                        <th class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                            <span class="text-overflow-dynamic-ellipsis">Data Ref. <p-sortIcon [field]="'data'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteCofins.filter($event.target.value, 'data', 'contains')">
                        </th>

                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Tipo Ajuste <p-sortIcon
                                [field]="'tipo_ajuste'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteCofins.filter($event.target.value, 'tipo_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Cód. Ajuste <p-sortIcon [field]="'cod_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteCofins.filter($event.target.value, 'cod_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteCofins.filter($event.target.value, 'descricao', 'contains')">
                        </th>

                        <th class="text-left" style="width: 15%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_doc'">
                            <span class="text-overflow-dynamic-ellipsis">num_doc <p-sortIcon [field]="'num_doc'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteCofins.filter($event.target.value, 'num_doc', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. Ajuste <p-sortIcon [field]="'vl_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteCofins.filter($event.target.value, 'vl_ajuste', 'contains')">
                        </th>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">


                        <td class="text-center" style="width: 90px;">
                          <span class="delete" matTooltip="Excluir" (click)="removerAjusteCofins(rowData)">
                            <i class="fas fa-trash-alt"></i>
                          </span>

                        </td>

                        <td class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data
                              }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.tipo_ajuste}}">{{rowData.tipo_ajuste }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.cod_ajuste}}">{{rowData.cod_ajuste }}</span>
                          </span>
                        </td>
                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 15%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.num_doc}}">{{rowData.num_doc}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.vl_ajuste | money}}">{{rowData.vl_ajuste | money}}</span>
                          </span>
                        </td>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="7" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>

                  <div class="row">
                    <div class="col-sm-12">
                      <button class="btn btn-sm btn-info mt-2" type="button" (click)="openModalAjusteCofins()"><i
                          class="fas fa-plus"></i> Adicionar Ajuste</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--End Crédito de COFINS-->

              <!--Start Contribuição de COFINS-->
              <div [ngStyle]="contribuicaoCofins ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'contribuicaoCofins'}">
                <div [ngStyle]="statusDetalheContribuicaoCofins ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDetalheContribuicaoCofins'}">
                  <h3 class="sub-title"><i class="fas fa-bars"></i> Detalhamento da Contribuição COFINS</h3>


                  <p-table class="table-system" #dtDetalheContribuicaoCofins [value]="tDetalheContribuicaoCofins"
                    [columns]="colsTDetalheContribuicaoCofins" [rows]="rows" [paginator]="true"
                    (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                    selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>
                        </th>

                        <th class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_contribuicao'">
                            <span class="text-overflow-dynamic-ellipsis">Cód. Contribuição <p-sortIcon
                                [field]="'cod_contribuicao'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoCofins.filter($event.target.value, 'cod_contribuicao', 'contains')">
                        </th>
                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_receita'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. Receita <p-sortIcon [field]="'vl_receita'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoCofins.filter($event.target.value, 'vl_receita', 'contains')">
                        </th>
                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'bc_cofins'">
                            <span class="text-overflow-dynamic-ellipsis">BC COFINS <p-sortIcon [field]="'bc_cofins'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoCofins.filter($event.target.value, 'bc_cofins', 'contains')">
                        </th>

                        <th class="text-left" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
                            <span class="text-overflow-dynamic-ellipsis">Aliq. COFINS <p-sortIcon
                                [field]="'aliq_cofins'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoCofins.filter($event.target.value, 'aliq_cofins', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cofins'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon
                                [field]="'valor_cofins'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dtDetalheContribuicaoCofins.filter($event.target.value, 'valor_cofins', 'contains')">
                        </th>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td class="text-center" style="width: 90px;">
                          <span class="edit" matTooltip="Analisar" (click)="openModalContribuicaoCofins(rowData)">
                            <i class="fas fa-search"></i>
                          </span>
                        </td>
                        <td class="text-left" style="width: 55%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM610(rowData)"
                              matTooltip="{{rowData.cod_contribuicao}}">{{rowData.cod_contribuicao }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM610(rowData)"
                              matTooltip="{{rowData.vl_receita | money}}">{{rowData.vl_receita | money }}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM610(rowData)"
                              matTooltip="{{rowData.bc_cofins}}">{{rowData.bc_cofins}}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM610(rowData)"
                              matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
                          </span>
                        </td>
                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="obterValoresM610(rowData)"
                              matTooltip="{{rowData.valor_cofins | money}}">{{rowData.valor_cofins | money }}</span>
                          </span>
                        </td>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="13" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
                <div [ngStyle]="statusAjusteContribuicaoCofins ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusAjusteContribuicaoCofins'}">
                  <h3 class="sub-title"><i class="fas fa-bars"></i> Ajustes da Contribuição de COFINS</h3>

                  <p-table class="table-system" #dttAjusteContribuicaoCofins [columns]="colstAjusteContribuicaoCofins"
                    [value]="tAjusteContribuicaoCofins" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
                    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                    [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                    <ng-template pTemplate="header">
                      <tr>

                        <th class="text-center" style="width: 90px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                          </span>

                        </th>

                        <th class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                            <span class="text-overflow-dynamic-ellipsis">Data Ref. <p-sortIcon [field]="'data'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoCofins.filter($event.target.value, 'data', 'contains')">
                        </th>

                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Tipo Ajuste <p-sortIcon
                                [field]="'tipo_ajuste'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoCofins.filter($event.target.value, 'tipo_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Cód. Ajuste <p-sortIcon [field]="'cod_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoCofins.filter($event.target.value, 'cod_ajuste', 'contains')">
                        </th>


                        <th class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoCofins.filter($event.target.value, 'descricao', 'contains')">
                        </th>

                        <th class="text-right" style="width: 15%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_doc'">
                            <span class="text-overflow-dynamic-ellipsis">num_doc <p-sortIcon [field]="'num_doc'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoCofins.filter($event.target.value, 'num_doc', 'contains')">
                        </th>

                        <th class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste'">
                            <span class="text-overflow-dynamic-ellipsis">Vl. Ajuste <p-sortIcon [field]="'vl_ajuste'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dttAjusteContribuicaoCofins.filter($event.target.value, 'vl_ajuste', 'contains')">
                        </th>


                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">


                        <td class="text-center" style="width: 90px;">
                          <span class="delete" matTooltip="Excluir" (click)="removerAjusteContribuicaoCofins(rowData)">
                            <i class="fas fa-trash-alt"></i>
                          </span>

                        </td>

                        <td class="text-center" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data
                              }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.tipo_ajuste}}">{{rowData.tipo_ajuste }}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.cod_ajuste}}">{{rowData.cod_ajuste }}</span>
                          </span>
                        </td>
                        <td class="text-left" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                          </span>
                        </td>

                        <td class="text-left" style="width: 15%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.num_doc}}">{{rowData.num_doc}}</span>
                          </span>
                        </td>

                        <td class="text-right" style="width: 120px;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.vl_ajuste | money}}">{{rowData.vl_ajuste | money}}</span>
                          </span>
                        </td>



                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="5" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                  <div class="row">
                    <div class="col-sm-12">
                      <button class="btn btn-sm btn-info mt-2" type="button" [disabled]="!tDetalheContribuicaoCofins"
                        (click)="openModalAjusteContribuicaoCofins()"><i class="fas fa-plus"></i> Adicionar
                        Ajuste</button>
                    </div>
                  </div>
                </div>
                <div [ngStyle]="statusRetidoFonteCofins ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusRetidoFonteCofins'}">
                  <h3 class="sub-title"><i class="fas fa-bars"></i> Valor Retido na Fonte COFINS</h3>
                  <div class="form-row">
                    <div class="form-group pull-left pl-1 pr-3">
                      <label>Vl. Retido Não Cumulativo COFINS</label>
                      <input type="text" class="form-control text-right" money
                        [(ngModel)]="vlRetidoNaoCumulativoCofins" />
                    </div>
                    <div class="form-group pull-left pr-3">
                      <label>Vl. Retido Cumulativo COFINS</label>
                      <input type="text" class="form-control text-right" money [(ngModel)]="vlRetidoCumulativoCofins" />
                    </div>
                    <div class="form-group pull-left">
                      <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
                      <button class="btn btn-sm btn-info" style="margin-top: 6px;" type="button"
                        (click)="atualizarValorRetidoCofins()"><i class="fas fa-check"></i> Salvar Valores</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--End Contribuição de COFINS-->
            </div>
          </div>
        </div>
      </div>

      <!--Spaced ICMS-ST Detalhe -->
      <div class="row" [ngStyle]="icmsstDetalhe ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'slide-table': 'icmsstDetalhe'}">
        <div class="col pb-3">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content">
                <!--Start ICMS-ST-->
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: icmsstDetalhe}"><a>ICMS-ST</a></li>
                </ul>
                <!--End ICMS-ST-->

                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dtApuracaoICMSSTDetalhe.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeIcmsStsDetalhe()"
                    (click)="changeStateDetalheIcmsst()">
                    <span *ngIf="isCardIcmsSt" class="material-icons" matTooltip="Ocultar Card"
                      matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardIcmsSt" class="material-icons" matTooltip="Ver Card"
                      matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="box-content" style="overflow: initial;" [@changeDivSize]=currentStateDetalheIcmsst>
              <!--Start ICMS-ST DETALHE -->
              <div [ngStyle]="icmsstDetalhe ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'icmsstDetalhe'}">

                <p-table class="table-system" #dtApuracaoICMSSTDetalhe [value]="tApuracaoICMSSTDetalhe" [rows]="rows"
                  [columns]="colsTApuracaoICMSSTDetalhe" [paginator]="true" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="250px">
                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'indicador_movimento'">
                          <span class="text-overflow-dynamic-ellipsis">Indicador de movimento <p-sortIcon
                              [field]="'indicador_movimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'indicador_movimento', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo_cred_peri_ant_st'">
                          <span class="text-overflow-dynamic-ellipsis">Saldo credor do período anterior - ST <p-sortIcon
                              [field]="'saldo_cred_peri_ant_st'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'saldo_cred_peri_ant_st', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_icms_st_devolucao'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total ICMS ST devol. mercadorias <p-sortIcon
                              [field]="'vl_total_icms_st_devolucao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_icms_st_devolucao', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_total_icms_st_ressarcimentos'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total ICMS ST - ressarcimentos <p-sortIcon
                              [field]="'vl_total_icms_st_ressarcimentos'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_icms_st_ressarcimentos', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_outros_creditos_st'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total - outros créditos ST <p-sortIcon
                              [field]="'vl_total_outros_creditos_st'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_outros_creditos_st', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_ajuste_icms_st_doc_fiscal'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. ajustes a crédito ICMS ST (doc fiscal)
                            <p-sortIcon [field]="'vl_ajuste_icms_st_doc_fiscal'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_ajuste_icms_st_doc_fiscal', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_retido_icms_st'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total do ICMS retido ST <p-sortIcon
                              [field]="'vl_total_retido_icms_st'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_retido_icms_st', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_outros_st'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total - outros débitos ST <p-sortIcon
                              [field]="'vl_total_outros_st'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_outros_st', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_ajuste_debito_icms_st'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. ajustes a débito ICMS ST (doc fiscal)
                            <p-sortIcon [field]="'vl_ajuste_debito_icms_st'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_ajuste_debito_icms_st', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_saldo_devedor'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do saldo devedor antes das deduções
                            <p-sortIcon [field]="'vl_saldo_devedor'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_saldo_devedor', 'contains')">
                      </th>


                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_deducoes_st'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total das deduções ST <p-sortIcon
                              [field]="'vl_total_deducoes_st'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_deducoes_st', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_total_icms'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. total do ICMS a recolher <p-sortIcon
                              [field]="'vl_total_icms'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_total_icms', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_saldo_credor_icms_st'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. do saldo credor do ICMS <p-sortIcon
                              [field]="'vl_saldo_credor_icms_st'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_saldo_credor_icms_st', 'contains')">
                      </th>

                      <th class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container"
                          [pSortableColumn]="'vl_recolhidos_recolher_extra'">
                          <span class="text-overflow-dynamic-ellipsis">Vl. recolhidos ou a recolher, extra-apuração
                            <p-sortIcon [field]="'vl_recolhidos_recolher_extra'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dtApuracaoICMSSTDetalhe.filter($event.target.value, 'vl_recolhidos_recolher_extra', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.indicador_movimento}}">{{rowData.indicador_movimento }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.saldo_cred_peri_ant_st | money}}">{{rowData.saldo_cred_peri_ant_st |
                            money }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_icms_st_devolucao | money}}">{{rowData.vl_total_icms_st_devolucao
                            | money }}</span>
                        </span>
                      </td>
                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_icms_st_ressarcimentos | money}}">{{rowData.vl_total_icms_st_ressarcimentos
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_outros_creditos_st | money}}">{{rowData.vl_total_outros_creditos_st
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_ajuste_icms_st_doc_fiscal | money}}">{{rowData.vl_ajuste_icms_st_doc_fiscal
                            | money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_retido_icms_st | money}}">{{rowData.vl_total_retido_icms_st |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_outros_st | money}}">{{rowData.vl_total_outros_st |
                            money}}</span>
                        </span>
                      </td>


                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_ajuste_debito_icms_st | money}}">{{rowData.vl_ajuste_debito_icms_st
                            | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_saldo_devedor | money}}">{{rowData.vl_saldo_devedor |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_deducoes_st | money}}">{{rowData.vl_total_deducoes_st |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_total_icms | money}}">{{rowData.vl_total_icms | money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_saldo_credor_icms_st | money}}">{{rowData.vl_saldo_credor_icms_st |
                            money}}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 120px;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.vl_recolhidos_recolher_extra | money}}">{{rowData.vl_recolhidos_recolher_extra
                            | money}}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>


              </div>
              <!--Start ICMS-ST DETALHE-->
            </div>
          </div>
        </div>
      </div>

      <!--Spaced Ações Final da Página -->
      <div class="row" *ngIf="gerarApuracao || gerarApuracaoIcmsIpi"
        [ngClass]="{'slide-table': 'gerarApuracao && gerarApuracaoIcmsIpi'}">
        <div class="col-sm-12 mb-3">
          <button type="button" class="btn btn-secondary"><i class="fas fa-undo"></i> Desfazer</button>
          <button type="button" class="btn btn-primary" (click)="gravarDados();"><i class="fas fa-save"></i>
            Gravar</button>
          <button type="button" class="btn btn-primary" (click)="homologarDados();"><i class="fas fa-check"></i>
            Homologar</button>
          <button type="button" class="btn btn-primary" (click)="openModalCentralEdicao();"><i
              class="fas fa-pencil-alt"></i> Central de Edição</button>
        </div>
      </div>
    </div>
  </div>
</mat-card>


<!--Modal Base de Crédito PIS-->
<p-dialog [(visible)]="modalBasePis" [style]="{width: '1250px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <p-header>
    Composição de Crédito
    <div class="actions-modal">
      <button type="button" class="btn btn-xs btn-link" (click)="dtBaseCreditoPis.exportCSV()"
        matTooltip="Exportar Excel" matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
    </div>
  </p-header>
  <h3 class="sub-title mt-2" style="font-size: 12px;"><i class="fas fa-bars"></i> Natureza de Base de Crédito:
    {{modalBasePisNatBcCred}}, Alíquota: {{modalBasePisAliquota}}% e Tipo de Crédito: {{modalBasePisTipoCredito}}</h3>

  <p-table class="table-system" #dtBaseCreditoPis [columns]="colstBaseCreditoPis" [value]="tBaseCreditoPis"
    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true"
    scrollHeight="250px">
    <ng-template pTemplate="header">
      <tr>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'reg'">
            <span class="text-overflow-dynamic-ellipsis">reg <p-sortIcon [field]="'reg'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'reg', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
            <span class="text-overflow-dynamic-ellipsis">data <p-sortIcon [field]="'data'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'data', 'contains')">
        </th>


        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_nf'">
            <span class="text-overflow-dynamic-ellipsis">Num NF <p-sortIcon [field]="'num_nf'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'num_nf', 'contains')">
        </th>


        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
            <span class="text-overflow-dynamic-ellipsis">cfop <p-sortIcon [field]="'cfop'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'cfop', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'participante'">
            <span class="text-overflow-dynamic-ellipsis">participante <p-sortIcon [field]="'participante'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'participante', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
            <span class="text-overflow-dynamic-ellipsis">descr_item <p-sortIcon [field]="'descr_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'descr_item', 'contains')">
        </th>


        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
            <span class="text-overflow-dynamic-ellipsis">tipo <p-sortIcon [field]="'tipo'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'tipo', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_pis'">
            <span class="text-overflow-dynamic-ellipsis">CST PIS <p-sortIcon [field]="'cst_pis'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'cst_pis', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
            <span class="text-overflow-dynamic-ellipsis">Aliq PIS <p-sortIcon [field]="'aliq_pis'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'aliq_pis', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nat_bc_cred'">
            <span class="text-overflow-dynamic-ellipsis">Nat Bc Cred <p-sortIcon [field]="'nat_bc_cred'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'nat_bc_cred', 'contains')">
        </th>


        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_item'">
            <span class="text-overflow-dynamic-ellipsis">Vl. Item <p-sortIcon [field]="'valor_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'valor_item', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
            <span class="text-overflow-dynamic-ellipsis">VL BC PIS <p-sortIcon [field]="'vl_bc_pis'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'vl_bc_pis', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_pis'">
            <span class="text-overflow-dynamic-ellipsis">Vl. PIS <p-sortIcon [field]="'valor_pis'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoPis.filter($event.target.value, 'valor_pis', 'contains')">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">


        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reg}}">{{rowData.reg }}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data }}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.num_nf}}">{{rowData.num_nf }}</span>
          </span>
        </td>
        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}">{{rowData.cfop}}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.participante}}">{{rowData.participante}}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.descr_item}}">{{rowData.descr_item}}</span>
          </span>
        </td>


        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cst_pis}}">{{rowData.cst_pis}}</span>
          </span>
        </td>


        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.nat_bc_cred}}">{{rowData.nat_bc_cred}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valor_item | money}}">{{rowData.valor_item | money}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis
              | money}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor_pis | money}}">{{rowData.valor_pis
              | money}}</span>
          </span>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="5" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalBasePis = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>

<!--Modal Ajuste PIS-->
<p-dialog header="Adicionar Ajuste PIS/PASEP" [(visible)]="modalAjustePis" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Tipo de crédito</label>
      <input readonly type="text" class="form-control" [(ngModel)]="tipoCreditoPisM100" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código Ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="codAjustePis" [(ngModel)]="codAjustePisEscolhido">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Tipo de ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="tipoAjustePis"
        [(ngModel)]="tipoAjustePisEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>Alíq. Ajuste</label>
      <input readonly type="text" class="form-control" [(ngModel)]="aliqAjustePisM100" />
    </div>
    <div class="form-group col-sm-2">
      <label>Valor Ajuste <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="valorAjustePis" />
    </div>
    <div class="form-group col-sm-3">
      <label>Nº Documento</label>
      <input type="text" class="form-control" [(ngModel)]="numeroDocumentoAjustePis" />
    </div>
    <div class="form-group col-sm-2">
      <label>Data Ref.</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtAjustePis">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input type="text" class="form-control" [(ngModel)]="descricaoAjustePis" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAjustePis = false;"><i class="fas fa-undo"></i>
      Fechar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarAjustePis()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Ajuste Contribuição PIS-->
<p-dialog header="Adicionar Ajuste Contribuição PIS/PASEP" [(visible)]="modalAjusteContribuicaoPis"
  [style]="{width: '1000px'}" [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Cód. Ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="codAjustePis"
        [(ngModel)]="codAjusteContribuicaoPisEscolhido"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Cód. Contribuição Social</label>
      <input readonly type="text" class="form-control" [(ngModel)]="codContribuicaoPisM210" />
    </div>
    <div class="form-group col-sm-3">
      <label>Tipo de ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="tipoAjustePis"
        [(ngModel)]="tipoAjusteContribuicaoPisEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>Valor Ajuste <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="valorAjusteContribuicaoPis" />
    </div>
    <div class="form-group col-sm-2">
      <label>Nº Documento</label>
      <input type="text" class="form-control" [(ngModel)]="numeroDocumentoAjusteContribuicaoPis" />
    </div>
    <div class="form-group col-sm-2">
      <label>Data Ref.</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtAjusteContribuicaoPis">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Alíq. Ajuste</label>
      <input readonly type="text" class="form-control" [(ngModel)]="aliqAjustePisM210" />
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input type="text" class="form-control" [(ngModel)]="descricaoAjusteContribuicaoPis" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAjusteContribuicaoPis = false;"><i
        class="fas fa-undo"></i> Fechar</button>
    <button type="button" class="btn btn-primary"><i (click)="adicionarAjusteContribuicaoPis()"
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Detalhamento da Contribuicao PIS-->
<p-dialog [(visible)]="modalContribuicaoPis" [style]="{width: '1250px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <p-header>
    Composição de Contribuição
    <div class="actions-modal">
      <button type="button" class="btn btn-xs btn-link" (click)="dt9.exportCSV()" matTooltip="Exportar Excel"
        matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
    </div>
  </p-header>

  <p-table class="table-system" #dt9 [columns]="colstBaseContribuicaoPis" [value]="tBaseContribuicaoPis" [rows]="rows"
    [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
    selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="280px">
    <ng-template pTemplate="header">
      <tr>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'reg'">
            <span class="text-overflow-dynamic-ellipsis">regra <p-sortIcon [field]="'reg'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'reg', 'contains')">
        </th>
        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
            <span class="text-overflow-dynamic-ellipsis">data <p-sortIcon [field]="'data'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'data', 'contains')">
        </th>
        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_nf'">
            <span class="text-overflow-dynamic-ellipsis">Num NF <p-sortIcon [field]="'num_nf'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'num_nf', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
            <span class="text-overflow-dynamic-ellipsis">CFOP <p-sortIcon [field]="'cfop'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'cfop', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'participante'">
            <span class="text-overflow-dynamic-ellipsis">participante <p-sortIcon [field]="'participante'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'participante', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
            <span class="text-overflow-dynamic-ellipsis">descr_item <p-sortIcon [field]="'descr_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'descr_item', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
            <span class="text-overflow-dynamic-ellipsis">tipo <p-sortIcon [field]="'tipo'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'tipo', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_pis'">
            <span class="text-overflow-dynamic-ellipsis">CST PIS <p-sortIcon [field]="'cst_pis'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'cst_pis', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_pis'">
            <span class="text-overflow-dynamic-ellipsis">Aliq PIS <p-sortIcon [field]="'aliq_pis'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'aliq_pis', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_item'">
            <span class="text-overflow-dynamic-ellipsis">Vl. Item <p-sortIcon [field]="'valor_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'valor_item', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_pis'">
            <span class="text-overflow-dynamic-ellipsis">vl_bc_pis <p-sortIcon [field]="'vl_bc_pis'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'vl_bc_pis', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_pis'">
            <span class="text-overflow-dynamic-ellipsis">valor_pis <p-sortIcon [field]="'valor_pis'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt9.filter($event.target.value, 'valor_pis', 'contains')">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reg}}">{{rowData.reg }}</span>
          </span>
        </td>
        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data }}</span>
          </span>
        </td>
        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.num_nf}}">{{rowData.num_nf}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}">{{rowData.cfop}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.participante}}">{{rowData.participante
              }}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descr_item}}">{{rowData.descr_item
              }}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo }}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cst_pis}}">{{rowData.cst_pis }}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliq_pis}}">{{rowData.aliq_pis }}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valor_item | money}}">{{rowData.valor_item | money }}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vl_bc_pis | money}}">{{rowData.vl_bc_pis
              | money }}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor_pis | money}}">{{rowData.valor_pis
              | money }}</span>
          </span>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="13" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalContribuicaoPis = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>

<!--Modal Contribuição Retida na Fonte-->
<p-dialog header="Contribuição Retida na Fonte" [(visible)]="modalContribuicaoRetidaFonte" [style]="{width: '1000px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Natureza da Retenção na Fonte <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="naturezaRetFonte"
        [(ngModel)]="naturezaRetFonteEscolhido"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Vl. Base Cálc. Ret. <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlBaseCalcCredF600" />
    </div>
    <div class="form-group col-sm-2">
      <label>Vl. Total Ret. <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlTotalRetF600" />
    </div>
    <div class="form-group col-sm-2">
      <label>Data Ret. <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtF600">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Cód. Receita</label>
      <input class="type-text" class="form-control" [(ngModel)]="codReceitaF600" />
    </div>
    <div class="form-group col-sm-4">
      <label>Natureza da Receita </label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="naturezaReceita"
        [(ngModel)]="naturezaReceitaEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>CNPJ da Fonte Pagadora <span class="obrigatorio">*</span></label>
      <p-inputMask class="type-p-mask" placeholder="99.999.999/9999-99" mask="99.999.999/9999-99"
        [(ngModel)]="cnpjFontePagadora"> </p-inputMask>
    </div>
    <div class="form-group col-sm-4">
      <label>Indicador de Condição da Pessoa Jurídica <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indicadorCondJuridica"
        [(ngModel)]="indicadorCondJuridicaEscolhido"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Parc. PIS/PASEP Ret. <span class="obrigatorio">*</span></label>
      <input class="form-control" money [(ngModel)]="parcPis" />
    </div>
    <div class="form-group col-sm-2">
      <label>Parc. COFINS Ret. <span class="obrigatorio">*</span></label>
      <input class="form-control" money [(ngModel)]="parcCofins" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalContribuicaoRetidaFonte = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarContribuicaoRetidaFonte()"> <i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Adicionar F100-->
<p-dialog header="F100: Demais Documentos" [(visible)]="modalF100" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Indicador do Tipo de Operação <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indTipoOper" [(ngModel)]="tipoOperacaoF100">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Cód. Participante</label>
      <input type="text" class="form-control" [(ngModel)]="codParticipanteF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Cód. Item</label>
      <input class="type-text" class="form-control" [(ngModel)]="codItemF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. Operação <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlOperacaoF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Data Operação <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtOperacaoF100">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Cód. Natureza Base Crédito</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="codBaseNatureza"
        [(ngModel)]="codNaturezaBaseCreditoF100"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>CST PIS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="cstPIS" [(ngModel)]="cstPisF100"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. BC PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlBcPisF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Aliq. PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="aliqPisF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlPisF100" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>CST COFINS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="cstCOFINS" [(ngModel)]="cstCofinsF100">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. BC COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlBcCofinsF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Aliq. COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="aliqCofinsF100" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlCofinsF100" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Ind. Origem Crédito</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indOrigemCredito" [(ngModel)]="indOrigCredF100">
      </p-dropdown>
    </div>
    <div class="form-group col">
      <label>Cód. cta. contábil deb. / cred.</label>
      <input type="text" class="form-control" [(ngModel)]="codContaContabilF100" />
    </div>
    <div class="form-group col">
      <label>Cód. Centro de Custos</label>
      <input type="text" class="form-control" [(ngModel)]="codCentroCustosF100" />
    </div>
    <div class="form-group col">
      <label>Desc. do Documento / Operação</label>
      <input type="text" class="form-control" [(ngModel)]="descDocF100" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalF100 = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarDadosF100()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Adicionar F120-->
<p-dialog header="F120: Bens Incorporados ao Ativo - Depreciação/Amortização" [(visible)]="modalF120"
  [style]="{width: '1000px'}" [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body"
  [closable]="true">
  <!--media anterior 1200px-->
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Natureza da Base de Crédito <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="naturezaBaseCredito"
        [(ngModel)]="codNaturezaBaseCreditoF120"> </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Identificação dos Bens/Grupo <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="identBensGrupo" [(ngModel)]="identBensGrupoF120">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Indicador da origem do bem</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indOrigemBem" [(ngModel)]="indOrigBemF120">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Indicador da Utilização dos Bens <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indUtiliBens" [(ngModel)]="indUtilBemF120">
      </p-dropdown>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Vl. do Encargo <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlEncargoF120"
        (ngModelChange)="alterarValoresF120()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Parc. Vl. do Encargo </label>
      <input type="text" class="form-control" [(ngModel)]="parcelasEncargoF120"
        (ngModelChange)="alterarValoresF120()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Cód. cta. contábil deb. / cred.</label>
      <input type="text" class="form-control" [(ngModel)]="codContaContabilF120" />
    </div>
    <div class="form-group col-sm-3">
      <label>Cód. Centro de Custos</label>
      <input type="text" class="form-control" [(ngModel)]="codCentroCustosF120" />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>CST PIS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="cstPIS" [(ngModel)]="cstPisF120"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. BC PIS</label>
      <input readonly type="text" class="form-control text-right" money [(ngModel)]="vlBcPisF120" />
    </div>
    <div class="form-group col-sm-3">
      <label>Aliq. PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="aliqPisF120" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlPisF120" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>CST COFINS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="cstCOFINS" [(ngModel)]="cstCofinsF120">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. BC COFINS</label>
      <input readonly type="text" class="form-control text-right" money [(ngModel)]="vlBcCofinsF120" />
    </div>
    <div class="form-group col-sm-3">
      <label>Aliq. COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="aliqCofinsF120" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlCofinsF120" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Desc. do Documento / Operação</label>
      <input type="text" class="form-control" [(ngModel)]="descDocF120" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalF120 = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarDadosF120()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Adicionar F130-->
<p-dialog header="F130: Bens Incorporados ao Ativo - Aquisição/Contribuição" [(visible)]="modalF130"
  [style]="{width: '1000px'}" [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body"
  [closable]="true">
  <!--media anterior 1200px-->
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Natureza da Base de Crédito <span class="obrigatorio">*</span></label>
      <input readonly type="text" class="form-control" [(ngModel)]="codNaturezaBaseCreditoF130" />
    </div>
    <div class="form-group col-sm-6">
      <label>Identificação dos Bens/Grupo <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="identBensGrupo" [(ngModel)]="identBensGrupoF130">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Indicador da Origem do Bem</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indOrigemBem" [(ngModel)]="indOrigBemF130">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Indicador da Utilização dos Bens <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indUtiliBens" [(ngModel)]="indUtilBemF130">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Mês/Ano de Aquisição dos Bens</label>
      <input type="text" class="form-control" [(ngModel)]="mesAnoAquisicaoF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. de Aquisição dos Bens <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlAquisicaoF130"
        (ngModelChange)="alterarValoresF130()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Parc. Vl. de Aquisição a excluir</label>
      <input type="text" class="form-control" [(ngModel)]="parcelasAquisicaoF130"
        (ngModelChange)="alterarValoresF130()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Ind. do Nº de Parc. <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="indNumParc" [(ngModel)]="indNumParcF130">
      </p-dropdown>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Vl. Base Cálculo Crédito <span class="obrigatorio">*</span></label>
      <input readonly type="text" class="form-control text-right" money [(ngModel)]="vlBaseCalcCredF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Cód. Cta. Contábil Deb. / Cred.</label>
      <input type="text" class="form-control" [(ngModel)]="codContaContabilF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Cód. Centro de Custos</label>
      <input type="text" class="form-control" [(ngModel)]="codCentroCustosF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Desc. do Documento / Operação</label>
      <input type="text" class="form-control" [(ngModel)]="descDocF130" />
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>CST PIS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="cstPIS" [(ngModel)]="cstPisF130"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. BC PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlBcPisF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Aliq. PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="aliqPisF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. PIS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlPisF130" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>CST COFINS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="cstCOFINS" [(ngModel)]="cstCofinsF130">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. BC COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlBcCofinsF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Aliq. COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="aliqCofinsF130" />
    </div>
    <div class="form-group col-sm-3">
      <label>Vl. COFINS</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlCofinsF130" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalF130 = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarDadosF130()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal ICMS Ajuste Benefício/Incentivo-->
<p-dialog header="Ajuste Benefício/Incentivo" [(visible)]="modalBeneficioIncentivo" [style]="{width: '1000px'}"
  [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <div class="row">
    <div class="col-sm-12">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Cód. Ajuste Apuração/Dedução <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [options]="AjusteApurDedu" [(ngModel)]="ajusteApurDeduEscolhido">
          </p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-10">
          <label>Descrição Complementar</label>
          <input type="text" class="form-control" [(ngModel)]="descricaoComplementarE111" />
        </div>
        <div class="form-group col-sm-2">
          <label>Vl ajuste da apuração</label>
          <input type="text" class="form-control text-right" money [(ngModel)]="vlAjusteApuracaoE111" />
        </div>
      </div>
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalBeneficioIncentivo = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarDadosRegE111()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Ajuste Apuração IPI-->
<p-dialog header="Ajuste Apuração IPI" [(visible)]="modalAjusteApuracaoIPI" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <div class="row">
    <div class="col-sm-12">
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>Tipo de Ajuste <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [options]="TipoAjusteIPI" [(ngModel)]="tipoAjusteIPIEscolhido">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>Origem Documento <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [options]="OrigemDocIPI" [(ngModel)]="origemDocIPIEscolhido"></p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>Nº do Documento <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="numeroDocumentoE530" />
        </div>
        <div class="form-group col-sm-3">
          <label>Valor do ajuste <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control text-right" money [(ngModel)]="vlAjusteE530" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Cód. do ajuste da apuração <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [options]="CodAjusteApuracaoIPI"
            [(ngModel)]="codAjusteApuracaoIPIEscolhido"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição Resumida</label>
          <input type="text" class="form-control" [(ngModel)]="descricaoResumidaE530" />
        </div>
      </div>
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAjusteApuracaoIPI = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarDadosRegE530()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Controle de Créditos Fiscais -->
<p-dialog header="Adicionar Crédito REF. Período Anterior" [(visible)]="modalControleCredito"
  [style]="{width: '1000px'}" [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body"
  [closable]="true">
  <!--media anterior 1200px-->
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Período de Apuração do Crédito <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030"
        [(ngModel)]="dtApuracaoCreditosFiscais">
      </p-calendar>
    </div>
    <div class="form-group col-sm-6">
      <label>Origem do Crédito <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [options]="origemCredito" [(ngModel)]="origemCreditosFiscaisEscolhido">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>CNPJ do Cedente do Crédito</label>
      <p-inputMask class="type-p-mask" placeholder="99.999.999/9999-99" mask="99.999.999/9999-99"
        [(ngModel)]="cnpjCreditosFiscais"></p-inputMask>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Tipo de Crédito <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [options]="tipoCredito" [(ngModel)]="tipoCreditosFiscaisEscolhido">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Apurado no Período <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="apuradoPeriodoCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Extemporâneo Apurado</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="extemporaneoCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Total do Crédito Apurado <span class="obrigatorio">*</span></label>
      <input readonly type="text" class="form-control text-right" money [(ngModel)]="vlCredApuCreditosFiscais" />
    </div>
    <div class="form-group col-sm-3">
      <label>Utilizado Mediante Desconto <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="utilDescCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Util. Mediante Pedido Ressarcimento</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="utilRessarcCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Util. Mediante Dec. de Comp. Inter. Períodos Anteriores</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="utilCompCreditosFiscaisAnteriores"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Disp. para Utilização neste Período <span class="obrigatorio">*</span></label>
      <input readonly type="text" class="form-control text-right" money [(ngModel)]="dispUtilizacaoCreditosFiscais" />
    </div>
    <div class="form-group col-sm-3">
      <label>Desc. neste período de escrituração</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="descEscrituracaoCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Objeto de Pedido de Ressarcimento</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="objPedidoRessarcCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Util. Mediante Compensação Inter. Período Atual</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="utilCompCreditosFiscaisAtual"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Transf. cisão, fusão ou incorp.</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="opcoesCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Utilizado por Outras Formas</label>
      <input type="text" class="form-control text-right" money [(ngModel)]="utilOutrosCreditosFiscais"
        (ngModelChange)="alterarValoresCreditosFiscais()" />
    </div>
    <div class="form-group col-sm-3">
      <label>Utilizar Período Apuração Futura</label>
      <input readonly type="text" class="form-control text-right" money [(ngModel)]="utilPeriodoApurCreditosFiscais" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalControleCredito = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarControleCreditosFiscais()"><i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Controle Retido Fonte-->
<p-dialog header="Adicionar Controle Retido Fonte" [(visible)]="modalControleRetidoFonte" [style]="{width: '1000px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Natureza da Retenção na Fonte <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="naturezaRetFonte"
        [(ngModel)]="natControleRetFonteEscolhido"></p-dropdown>
    </div>
  </div>
  <div class="form-row align-items-end">
    <div class="form-group col-sm-4">
      <label>Período do Recebimento e da Retenção <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030"
        [(ngModel)]="dtOperacaoControleRetFonte">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Vl. Total da Retenção <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlTotalControleRetFonte"
        (ngModelChange)="alterarValoresControleRetidoFonte()" />
    </div>
    <div class="form-group col-sm-4">
      <label>Vl. Ret. deduzida da Contribuição Devida <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlDeduzidoContribuicaoControleRetFonte"
        (ngModelChange)="alterarValoresControleRetidoFonte()" />
    </div>
  </div>
  <div class="form-row align-items-end">
    <div class="form-group col-sm-4">
      <label>Vl. Ret. Utilizada Mediante Pedido de Ressarc. <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlPedRessarcControleRetFonte"
        (ngModelChange)="alterarValoresControleRetidoFonte()" />
    </div>
    <div class="form-group col-sm-4">
      <label>Vl. Ret. Utilizada Mediante Declaração Comp. <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="vlUtilizadoDeclaracaoControleRetFonte"
        (ngModelChange)="alterarValoresControleRetidoFonte()" />
    </div>
    <div class="form-group col-sm-4">
      <label>Saldo Ret. a Utilizar em Períodos Apur. Futuros <span class="obrigatorio">*</span></label>
      <input readonly type="text" class="form-control text-right" money
        [(ngModel)]="saldoUtilizarFuturoControleRetFonte" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalControleRetidoFonte = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarControleRetidoFonte()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Detalhamento da Contribuicao COFINS-->
<p-dialog [(visible)]="modalContribuicaoCofins" [style]="{width: '1250px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <p-header>
    Composição de Contribuição
    <div class="actions-modal">
      <button type="button" class="btn btn-xs btn-link" (click)="dtBaseContribuicaoCofins.exportCSV()"
        matTooltip="Exportar Excel" matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
    </div>
  </p-header>

  <p-table class="table-system" #dtBaseContribuicaoCofins [columns]="colstBaseContribuicaoCofins"
    [value]="tBaseContribuicaoCofins" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)"
    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
    [scrollable]="true" scrollHeight="250px">
    <ng-template pTemplate="header">
      <tr>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'reg'">
            <span class="text-overflow-dynamic-ellipsis">regra <p-sortIcon [field]="'reg'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'reg', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
            <span class="text-overflow-dynamic-ellipsis">data <p-sortIcon [field]="'data'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'data', 'contains')">
        </th>


        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_nf'">
            <span class="text-overflow-dynamic-ellipsis">Num NF <p-sortIcon [field]="'num_nf'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'num_nf', 'contains')">
        </th>


        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
            <span class="text-overflow-dynamic-ellipsis">cfop <p-sortIcon [field]="'cfop'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'cfop', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'participante'">
            <span class="text-overflow-dynamic-ellipsis">participante <p-sortIcon [field]="'participante'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'participante', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
            <span class="text-overflow-dynamic-ellipsis">descr_item <p-sortIcon [field]="'descr_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'descr_item', 'contains')">
        </th>


        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
            <span class="text-overflow-dynamic-ellipsis">tipo <p-sortIcon [field]="'tipo'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'tipo', 'contains')">
        </th>

        <th class="text-center" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_cofins'">
            <span class="text-overflow-dynamic-ellipsis">CST COFINS <p-sortIcon [field]="'cst_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'cst_cofins', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
            <span class="text-overflow-dynamic-ellipsis">Aliq COFINS <p-sortIcon [field]="'aliq_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'aliq_cofins', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_item'">
            <span class="text-overflow-dynamic-ellipsis">Vl. Item <p-sortIcon [field]="'valor_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'valor_item', 'contains')">
        </th>


        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
            <span class="text-overflow-dynamic-ellipsis">vl_bc_cofins <p-sortIcon [field]="'vl_bc_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'vl_bc_cofins', 'contains')">
        </th>

        <th class="text-right" style="width: 120px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cofins'">
            <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'valor_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseContribuicaoCofins.filter($event.target.value, 'valor_cofins', 'contains')">
        </th>


      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">


        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reg}}">{{rowData.reg }}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data }}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.num_nf}}">{{rowData.num_nf }}</span>
          </span>
        </td>
        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}">{{rowData.cfop}}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.participante}}">{{rowData.participante}}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.descr_item}}">{{rowData.descr_item}}</span>
          </span>
        </td>


        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.cst_cofins}}">{{rowData.cst_cofins}}</span>
          </span>
        </td>


        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valor_item | money}}">{{rowData.valor_item | money}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valor_cofins | money}}">{{rowData.valor_cofins | money}}</span>
          </span>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="12" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalContribuicaoCofins = false;"><i
        class="fas fa-undo"></i> Fechar</button>
  </p-footer>
</p-dialog>

<!--Modal Base de Crédito COFINS-->
<p-dialog [(visible)]="modalBaseCofins" [style]="{width: '1250px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" appendTo="body" [closable]="true">
  <!--media anterior 1200px-->
  <p-header>
    Composição de Crédito
    <div class="actions-modal">
      <button type="button" class="btn btn-xs btn-link" (click)="dtBaseCreditoCofins.exportCSV()"
        matTooltip="Exportar Excel" matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
    </div>
  </p-header>
  <h3 class="sub-title mt-2" style="font-size: 12px;"><i class="fas fa-bars"></i> Natureza de Base de Crédito:
    {{modalBaseCofinsNatBcCred}}, Alíquota: {{modalBaseCofinsAliquota}}% e Tipo de Crédito:
    {{modalBaseCofinsTipoCredito}}</h3>

  <p-table class="table-system" #dtBaseCreditoCofins [columns]="colstBaseCreditoCofins" [value]="tBaseCreditoCofins"
    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true"
    scrollHeight="250px">
    <ng-template pTemplate="header">
      <tr>


        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'reg'">
            <span class="text-overflow-dynamic-ellipsis">reg <p-sortIcon [field]="'reg'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'reg', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
            <span class="text-overflow-dynamic-ellipsis">data <p-sortIcon [field]="'data'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'data', 'contains')">
        </th>


        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_nf'">
            <span class="text-overflow-dynamic-ellipsis">Num NF <p-sortIcon [field]="'num_nf'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'num_nf', 'contains')">
        </th>


        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
            <span class="text-overflow-dynamic-ellipsis">CFOP <p-sortIcon [field]="'cfop'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'cfop', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'participante'">
            <span class="text-overflow-dynamic-ellipsis">participante <p-sortIcon [field]="'participante'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'participante', 'contains')">
        </th>

        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
            <span class="text-overflow-dynamic-ellipsis">descr_item <p-sortIcon [field]="'descr_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'descr_item', 'contains')">
        </th>


        <th class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
            <span class="text-overflow-dynamic-ellipsis">tipo <p-sortIcon [field]="'tipo'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'tipo', 'contains')">
        </th>

        <th class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst_cofins'">
            <span class="text-overflow-dynamic-ellipsis">CST COFINS <p-sortIcon [field]="'cst_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'cst_cofins', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliq_cofins'">
            <span class="text-overflow-dynamic-ellipsis">Aliq COFINS <p-sortIcon [field]="'aliq_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'aliq_cofins', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nat_bc_cred'">
            <span class="text-overflow-dynamic-ellipsis">Nat Bc Cred <p-sortIcon [field]="'nat_bc_cred'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'nat_bc_cred', 'contains')">
        </th>


        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_item'">
            <span class="text-overflow-dynamic-ellipsis">Vl. Item <p-sortIcon [field]="'valor_item'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'valor_item', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_bc_cofins'">
            <span class="text-overflow-dynamic-ellipsis">VL BC COFINS <p-sortIcon [field]="'vl_bc_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'vl_bc_cofins', 'contains')">
        </th>

        <th class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cofins'">
            <span class="text-overflow-dynamic-ellipsis">Vl. COFINS <p-sortIcon [field]="'valor_cofins'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtBaseCreditoCofins.filter($event.target.value, 'valor_cofins', 'contains')">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">


        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reg}}">{{rowData.reg }}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data }}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.num_nf}}">{{rowData.num_nf }}</span>
          </span>
        </td>
        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}">{{rowData.cfop}}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.participante}}">{{rowData.participante}}</span>
          </span>
        </td>

        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.descr_item}}">{{rowData.descr_item}}</span>
          </span>
        </td>


        <td class="text-left" style="width: 200px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
          </span>
        </td>

        <td class="text-center" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.cst_cofins}}">{{rowData.cst_cofins}}</span>
          </span>
        </td>


        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.aliq_cofins}}">{{rowData.aliq_cofins}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.nat_bc_cred}}">{{rowData.nat_bc_cred}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valor_item | money}}">{{rowData.valor_item | money}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.vl_bc_cofins | money}}">{{rowData.vl_bc_cofins | money}}</span>
          </span>
        </td>

        <td class="text-right" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.valor_cofins | money}}">{{rowData.valor_cofins | money}}</span>
          </span>
        </td>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="13" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalBaseCofins = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>

<!--Modal Ajuste COFINS-->
<p-dialog header="Adicionar Ajuste COFINS" [(visible)]="modalAjusteCofins" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Tipo de crédito</label>
      <input readonly type="text" class="form-control" [(ngModel)]="tipoCreditoCofinsM500" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código Ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="codAjustePis"
        [(ngModel)]="codAjusteCofinsEscolhido"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Tipo de ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="tipoAjustePis"
        [(ngModel)]="tipoAjusteCofinsEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>Alíq. Ajuste</label>
      <input readonly type="text" class="form-control" [(ngModel)]="aliqAjusteCofinsM500" />
    </div>
    <div class="form-group col-sm-2">
      <label>Valor Ajuste <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="valorAjusteCofins" />
    </div>
    <div class="form-group col-sm-3">
      <label>Nº Documento</label>
      <input type="text" class="form-control" [(ngModel)]="numeroDocumentoAjusteCofins" />
    </div>
    <div class="form-group col-sm-2">
      <label>Data Ref.</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtAjusteCofins">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input type="text" class="form-control" [(ngModel)]="descricaoAjusteCofins" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAjusteCofins = false;"><i class="fas fa-undo"></i>
      Fechar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarAjusteCofins()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!--Modal Ajuste Contribuição COFINS-->
<p-dialog header="Adicionar Ajuste Contribuição COFINS" [(visible)]="modalAjusteContribuicaoCofins"
  [style]="{width: '1000px'}" [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Cód. Ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="codAjustePis"
        [(ngModel)]="codAjusteContribuicaoCofinsEscolhido"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Cód. Contribuição Social</label>
      <input readonly type="text" class="form-control" [(ngModel)]="codContribuicaoCofinsM610" />
    </div>
    <div class="form-group col-sm-3">
      <label>Tipo de ajuste <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="tipoAjustePis"
        [(ngModel)]="tipoAjusteContribuicaoCofinsEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>Valor Ajuste <span class="obrigatorio">*</span></label>
      <input type="text" class="form-control text-right" money [(ngModel)]="valorAjusteContribuicaoCofins" />
    </div>
    <div class="form-group col-sm-2">
      <label>Nº Documento</label>
      <input type="text" class="form-control" [(ngModel)]="numeroDocumentoAjusteContribuicaoCofins" />
    </div>
    <div class="form-group col-sm-2">
      <label>Data Ref.</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030"
        [(ngModel)]="dtAjusteContribuicaoCofins">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Alíq. Ajuste</label>
      <input readonly type="text" class="form-control" [(ngModel)]="aliqAjusteCofinsM610" />
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input type="text" class="form-control" [(ngModel)]="descricaoAjusteContribuicaoCofins" />
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAjusteContribuicaoCofins = false;"><i
        class="fas fa-undo"></i> Fechar</button>
    <button type="button" class="btn btn-primary"><i (click)="adicionarAjusteContribuicaoCofins()"
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>


<!--Modal Central de Edição-->
<p-dialog header="Central de Edição" [(visible)]="modalCentralEdicao" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Registro para Edição </label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="centralEdicaoOpcoes"
        [(ngModel)]="centralEdicaoEscolhido" (onChange)="buscarDadosCentralEdicao()"></p-dropdown>
    </div>
  </div>


  <p-table class="table-system" #dtDadosCentralEdicao [value]="tDadosCentralEdicao" [rows]="rows" [paginator]="true"
    (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
    [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="280px" dataKey="id">
    <ng-template pTemplate="header" let-rowData>
      <tr>

        <th class="text-center" style="width: 90px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
          </span>

        </th>

        <th class="text-center" style="width: 150px;" *ngFor="let col of colunasCentralEdicao">
          <span class="text-overflow-dynamic-container" pSortableColumn={{col}}>
            <span class="text-overflow-dynamic-ellipsis"> {{col}} </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtDadosCentralEdicao.filter($event.target.value, col, 'contains')">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">


        <td class="text-center" style="width: 90px;">
          <span *ngIf="tabelaPaiCentralEdicao" class="edit" matTooltip="Analisar"
            (click)="buscarDadosTabelaFilhaCentralEdicao(rowData)">
            <i class="fas fa-search"></i>
          </span>
          <span class="shared" matTooltip="Salvar" (click)="salvarAlteracoesCentralEdicao(rowData, 'principal')">
            <i class="fas fa-save"></i>
          </span>
          <span class="delete" matTooltip="Excluir" (click)="removerRegistroCentralEdicao(rowData, 'principal')">
            <i class="fas fa-trash-alt"></i>
          </span>

        </td>

        <td class="text-center" style="width: 150px;" *ngFor="let col of colunasCentralEdicao">
          <span class="text-overflow-dynamic-container">
            <div (click)="abrirModalCentralEdicao(rowData, col, 'principal')">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData[col]}}">{{rowData[col] }}</span>
            </div>
          </span>
        </td>




      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="5" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>




  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalCentralEdicao = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>


<!--Modal Alteração de Valor em Campo da Central de Edição-->
<p-dialog header="Editar" [(visible)]="modalEditarDadoCentralEdicao" [style]="{width: '250px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Insira o novo valor</label>
      <input type="text" class="form-control" [(ngModel)]="novoValorCentralEdicao" />
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalEditarDadoCentralEdicao = false;"><i
        class="fas fa-undo"></i> Fechar</button>
    <button type="button" class="btn btn-primary" (click)="atualizarDadoCentralEdicao();"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>


<!--Modal Central de Edição para a tabela-filha de uma selecionada pelo usuário-->
<p-dialog header="Central de Edição" [(visible)]="modalTabelaFilha" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

  <p-table class="table-system" #dtDadosTabelaFilha [value]="dadosTabelaFilha" [rows]="rows" [paginator]="true"
    (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
    [style]="{'width':'100%'}" [scrollable]="true" scrollHeight="280px">
    <ng-template pTemplate="header" let-rowData>
      <tr>

        <th class="text-center" style="width: 90px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
          </span>

        </th>

        <th class="text-center" style="width: 150px;" *ngFor="let col of colunasTabelaFilha">
          <span class="text-overflow-dynamic-container" pSortableColumn={{col}}>
            <span class="text-overflow-dynamic-ellipsis"> {{col}} </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtDadosTabelaFilha.filter($event.target.value, col, 'contains')">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">


        <td class="text-center" style="width: 90px;">
          <span class="shared" matTooltip="Salvar" (click)="salvarAlteracoesCentralEdicao(rowData, 'filha')">
            <i class="fas fa-save"></i>
          </span>
          <span class="delete" matTooltip="Excluir" (click)="removerRegistroCentralEdicao(rowData, 'filha')">
            <i class="fas fa-trash-alt"></i>
          </span>

        </td>

        <td class="text-center" style="width: 150px;" *ngFor="let col of colunasTabelaFilha">
          <span class="text-overflow-dynamic-container">
            <div (click)="abrirModalCentralEdicao(rowData, col, 'filha')">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData[col]}}">{{rowData[col] }}</span>
            </div>
          </span>
        </td>


      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="5" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-table *ngIf="containTabelaFilha2" class="table-system" #dtDadosTabelaFilha2 [value]="dadosTabelaFilha2"
    [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true"
    scrollHeight="280px">
    <ng-template pTemplate="header" let-rowData>
      <tr>

        <th class="text-center" style="width: 90px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
          </span>

        </th>

        <th class="text-center" style="width: 150px;" *ngFor="let col of colunasTabelaFilha2">
          <span class="text-overflow-dynamic-container" pSortableColumn={{col}}>
            <span class="text-overflow-dynamic-ellipsis"> {{col}} </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dtDadosTabelaFilha2.filter($event.target.value, col, 'contains')">
        </th>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">


        <td class="text-center" style="width: 90px;">
          <span class="shared" matTooltip="Salvar" (click)="salvarAlteracoesCentralEdicao(rowData, 'filha2')">
            <i class="fas fa-save"></i>
          </span>
          <span class="delete" matTooltip="Excluir" (click)="removerRegistroCentralEdicao(rowData, 'filha2')">
            <i class="fas fa-trash-alt"></i>
          </span>

        </td>

        <td class="text-center" style="width: 150px;" *ngFor="let col of colunasTabelaFilha2">
          <span class="text-overflow-dynamic-container">
            <div (click)="abrirModalCentralEdicao(rowData, col, 'filha2')">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData[col]}}">{{rowData[col] }}</span>
            </div>
          </span>
        </td>


      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="5" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>



  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalTabelaFilha = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>