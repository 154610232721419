<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="row">
    <div class="col">

    </div>
  </div>

  <mat-card-content class="mat-card-linnks">
    <div class="box-content">
      <p-table class="table-system" #dt [value]="arquivos" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage">

        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 50%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'arquivo'">
                <span class="text-overflow-dynamic-ellipsis">Arquivo <p-sortIcon [field]="'arquivo'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'arquivo', 'contains')">
            </th>
            <th class="text-center" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                <span class="text-overflow-dynamic-ellipsis">Usuário <p-sortIcon [field]="'usuario'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'usuario', 'contains')">
            </th>
            <th class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                <span class="text-overflow-dynamic-ellipsis">Data Inclusão <p-sortIcon [field]="'data'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'data', 'contains')">
            </th>
            <th class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Deletar</span>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.arquivo}}">{{rowData.arquivo}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="delete" matTooltip='Excluir Arquivo' (click)="excluirArquivo(rowData)"><i
                    class="fas fa-trash-alt"></i></span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>