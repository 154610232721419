<h3 *ngIf="!loading && !displayForm && !dataSource">Para prosseguir, selecione uma empresa no menu superior</h3>

<form *ngIf="displayForm" [formGroup]="formGroup" novalidate autocomplete="off" class="form-margin">
  <mat-form-field>
    <input matInput placeholder="Senha do certificado digital" type="password" formControlName="pass" minlength="5" maxlength="50">
    <mat-hint *ngIf="company?.certificateExpiration && isPristinePassword" align="end">Preencha apenas para alterar a senha atual</mat-hint>
  </mat-form-field>

  <mat-form-field *ngIf="!company?.certificateExpiration || displayPasswordConfirm" [class.invalid]="!isEqualPasswords">
    <input matInput placeholder="Confirmar senha" type="password" formControlName="passConfirm" minlength="5" maxlength="50">
    <mat-hint *ngIf="!isEqualPasswords" align="end">Repita a senha utilizada no campo anterior</mat-hint>
  </mat-form-field>

  <label>Certificado</label>
  <input type="file" #fileInput formControlName="file" (ngModelChange)="fileChange($event)">
</form>

<form *ngIf="displayForm" novalidate autocomplete="off" class="form-margin">
  <mat-form-field *ngIf="company?.certificateExpiration">
    <input matInput placeholder="Data de expiração" type="text" readonly [class.error]="company?.certificateExpired" value="{{ company?.certificateExpiration | timezone | date:'dd/MM/yyyy' }}">
    <mat-hint align="end" *ngIf="!company?.certificateToExpire && !company?.certificateExpired">Data de expiração do certificado</mat-hint>
    <mat-hint align="end" *ngIf="company?.certificateToExpire || company?.certificateExpired" [class.toExpire]="company?.certificateToExpire" [class.expired]="company?.certificateExpired">Atenção: Certificado expira{{ company?.certificateExpired ? '' : 'n' }}do</mat-hint>
  </mat-form-field>

  <div *ngIf="company?.certificateFound && !company?.certificateExpiration" class="error message">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;&nbsp;Senha do certificado digital incorreta ou certificado inválido!<br>
  </div>

  <mat-form-field *ngIf="company?.lastNsu">
    <input matInput placeholder="Último NSU* obtido" type="text" readonly [value]="company?.lastNsu">
    <mat-hint align="end">* Número Sequencial Único de NF-e</mat-hint>
  </mat-form-field>

  <mat-form-field *ngIf="company?.lastNsu && company?.changeTimestamp">
    <input matInput placeholder="Último registro" type="text" readonly value="{{ company?.changeTimestamp | timezone | date:'dd/MM/yyyy HH:mm' }}">
    <mat-hint align="end">Data de obtenção do último registro</mat-hint>
  </mat-form-field>
</form>

<div [hidden]="!dataSource || displayForm"><!-- *ngIf="dataSource && !displayForm" // TODO: Fix filter and sort -->
  <mat-form-field floatLabel="never">
    <input matInput #filter placeholder="Digite aqui para buscar">
  </mat-form-field>

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container cdkColumnDef="name">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Razão Social</mat-header-cell>
      <mat-cell *cdkCellDef="let row">{{ row.name }}</mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="cnpjOrCpf">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>CNPJ</mat-header-cell>
      <mat-cell *cdkCellDef="let row">{{ row.cnpjOrCpf | cnpj }}</mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="certificateExpiration">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header>Expiração</mat-header-cell>
      <mat-cell *cdkCellDef="let row" [class.toExpire]="row.certificateToExpire" [class.expired]="row.certificateExpired || !row.certificateExpiration">
        <div *ngIf="row.certificateExpiration">{{ row.certificateExpiration | date:'dd/MM/yyyy' }}</div>
        <div *ngIf="row.certificateFound && !row.certificateExpiration">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;&nbsp;ERRO&nbsp;&nbsp;<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container cdkColumnDef="action">
      <mat-header-cell *cdkHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <a (click)="edit(row)" class="action"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;&nbsp;Editar</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
