<mat-card class="card-linnks margin-linnks">
	<mat-card-title>
		{{titlePrincipal}}
		<mat-card-subtitle>
			{{subtitle}}
		</mat-card-subtitle>
	</mat-card-title>
	<div class="container">
		<div class="fixed-th-table-wrapper">
			<table>

				<tr>
					<th class="th-cab" colspan=2>DESCRIÇÃO</th>
					<td class="td-cab text-right"><span>JAN</span></td>
					<td class="td-cab text-right"><span>FEV</span></td>
					<td class="td-cab text-right"><span>MAR</span></td>
					<td class="td-cab text-right"><span>ABR</span></td>
					<td class="td-cab text-right"><span>MAI</span></td>
					<td class="td-cab text-right"><span>JUN</span></td>
					<td class="td-cab text-right"><span>JUL</span></td>
					<td class="td-cab text-right"><span>AGO</span></td>
					<td class="td-cab text-right"><span>SET</span></td>
					<td class="td-cab text-right"><span>OUT</span></td>
					<td class="td-cab text-right"><span>NOV</span></td>
					<td class="td-cab text-right"><span>DEZ</span></td>
				</tr>
				<tr *ngFor="let somaResultado of listaListSomaResultado">
					<th colspan="2"><span matTooltip="O valor é a soma dos saldos das contas contábeis de natureza 4">Lucro
							(Prejuízo) Acumulado</span></th>
					<td class="currency"><span>{{ somaResultado.jan | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.fev | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.mar | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.abr | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.mai | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.jun | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.jul | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.ago | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.set | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.out | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.nov | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.dez | money }}</span></td>
				</tr>
				<tr *ngIf="listSomaIrpj?.length === 0">
					<th align="left" colspan="2"><span
							matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ
							e CSLL</span></th>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
				</tr>
				<tr *ngFor="let somaIrpj of listSomaIrpj">
					<th align="left" colspan="2"><span
							matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ
							e CSLL</span></th>
					<td class="currency"><span>{{ somaIrpj.jan | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.fev | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.mar | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.abr | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.mai | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.jun | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.jul | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.ago | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.set | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.out | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.nov | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let somaResultadoAntes of listaSomaResultadoAntesIrpj">
					<th align="left" colspan=2><span matTooltip="Valor do Lucro(Prejuízo)Acumulado + IRPJ e CSLL">Lucro (Prejuízo)
							Acumulado antes do IRPJ e CSLL</span></th>
					<td class="currency"><span>{{ somaResultadoAntes.jan | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.fev | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.mar | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.abr | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.mai | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.jun | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.jul | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.ago | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.set | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.out | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.nov | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.dez | money }}</span></td>
				</tr>

				<tr class="dtc" *ngIf="listaListSoma?.length === 0">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaAdicao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">ADIÇÕES</th>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				</tr>

				<tr class="dtc" *ngFor="let tbIrpj of listaListSoma">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaAdicao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">ADIÇÕES</th>
					<td class="td-subtotal"><span>{{ tbIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbIrpj of listaList">
					<th align="left"><button *ngIf="tbIrpj.count===0" type="button" class="btn btn-xs btn-outline-danger"
							style="border-color: transparent;" matTooltip="Excluir Conta" [disabled]="hasApurado"
							(click)="excMovParteA(tbIrpj.id)"><i class="fas fa-trash-alt"></i></button></th>
					<th><span matTooltip="{{tbIrpj.descricao}}">{{tbIrpj.cod}} - {{tbIrpj.desc_mini}}</span> </th>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,1)"><span>{{ tbIrpj.jan | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,2)"><span>{{ tbIrpj.fev | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,3)"><span>{{ tbIrpj.mar | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,4)"><span>{{tbIrpj.abr | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,5)"><span>{{tbIrpj.mai | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,6)"><span>{{tbIrpj.jun | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,7)"><span>{{tbIrpj.jul | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,8)"><span>{{tbIrpj.ago | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,9)"><span>{{tbIrpj.set | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,10)"><span>{{tbIrpj.out | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,11)"><span>{{tbIrpj.nov | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,12)"><span>{{tbIrpj.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngIf="listaListSomaExc?.length === 0">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaExclusao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">EXCLUSÕES</th>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbIrpj of listaListSomaExc">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaExclusao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">EXCLUSÕES</th>
					<td class="td-subtotal"><span>{{ tbIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbExc of listaListExc">
					<th align="left"><button *ngIf="tbExc.count===0" type="button" class="btn btn-xs btn-outline-danger"
							style="border-color: transparent;" title="Excluir Conta" [disabled]="hasApurado"
							(click)="excMovParteA(tbExc.id)"><i class="fas fa-trash-alt"></i></button></th>

					<th><span matTooltip="{{tbExc.descricao}}">{{tbExc.cod}} - {{tbExc.desc_mini}}</span> </th>
					<td class="currency" (click)="onContaSelect(tbExc.id,1)"><span>{{ tbExc.jan | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,2)"><span>{{ tbExc.fev | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,3)"><span>{{ tbExc.mar | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,4)"><span>{{tbExc.abr | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,5)"><span>{{tbExc.mai | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,6)"><span>{{tbExc.jun | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,7)"><span>{{tbExc.jul | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,8)"><span>{{tbExc.ago | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,9)"><span>{{tbExc.set | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,10)"><span>{{tbExc.out | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,11)"><span>{{tbExc.nov | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,12)"><span>{{tbExc.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let somaLucroCompensacao of listaSomaLucroAntesCompensacao">
					<th class="th-subtotal" colspan="2"><span matTooltip="Lucro(Prejuízo) Acumulado + ADIÇÕES - EXCLUSÕES">LUCRO
							ANTES DA COMPENSAÇÃO PREJ. FISCAL</span></th>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.dez | money }}</span></td>
				</tr>
				<tr>
					<th class="left"><button type="button" matTooltip="Adicionar Prejuízo Acumulado" class="btn btn-xs btn-info"
							[disabled]="hasApurado" (click)="adicionarPrejuizoAcumulado()"><i class="fas fa-plus"></i></button></th>
					<th>PREJUÍZO FISCAL A COMPENSAR</th>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				</tr>
				<tr *ngFor="let prejFiscalCompensado of listaPrejFiscalCompensado">
					<th class="left" colspan="2"><span matTooltip="30% do Lucro Antes da Compensação">PREJUÍZO FISCAL
							COMPENSADO</span></th>
					<td class="currency"><span>{{ prejFiscalCompensado.jan | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.fev | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.mar | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.abr | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.mai | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.jun | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.jul | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.ago | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.set | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.out | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.nov | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let compensacaoPrejPeriodoAnterior of listaCompensacaoPrejPeriodoAnterior">
					<th class="left" colspan="2">COMPENSAÇÃO PREJUÍZOS PERÍODOS ANTERIORES</th>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jan | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.fev | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mar | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.abr | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mai | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jun | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jul | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.ago | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.set | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.out | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.nov | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let saldoPrejFiscalCompensar of listaSaldoPrejFiscalCompensar">
					<th class="left" colspan="2"><span matTooltip="Prejuizo Fiscal a Compensar - Prejuizo Fiscal Compensado">SALDO
							DE PREJUÍZO FISCAL A COMPENSAR</span></th>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.jan | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.fev | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.mar | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.abr | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.mai | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.jun | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.jul | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.ago | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.set | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.out | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.nov | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let lucroAjustado of listaLucroAjustado">
					<th class="th-subtotal" colspan="2"><span
							matTooltip="Lucro antes da Compensação - Compensação Prejuízo de Períodos Anteriores">LUCRO
							AJUSTADO</span></th>
					<td class="td-subtotal"><span>{{ lucroAjustado.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let impRenda of listaImpRenda">
					<th class="left" colspan="2"><span matTooltip="15% do LUCRO AJUSTADO">Imposto de Renda (15%)</span></th>
					<td class="currency"><span>{{ impRenda.jan | money }}</span></td>
					<td class="currency"><span>{{ impRenda.fev | money }}</span></td>
					<td class="currency"><span>{{ impRenda.mar | money }}</span></td>
					<td class="currency"><span>{{ impRenda.abr | money }}</span></td>
					<td class="currency"><span>{{ impRenda.mai | money }}</span></td>
					<td class="currency"><span>{{ impRenda.jun | money }}</span></td>
					<td class="currency"><span>{{ impRenda.jul | money }}</span></td>
					<td class="currency"><span>{{ impRenda.ago | money }}</span></td>
					<td class="currency"><span>{{ impRenda.set | money }}</span></td>
					<td class="currency"><span>{{ impRenda.out | money }}</span></td>
					<td class="currency"><span>{{ impRenda.nov | money }}</span></td>
					<td class="currency"><span>{{ impRenda.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let adicImpRenda of listaAdicImpRenda">
					<th class="left" colspan="2"><span
							matTooltip="10% do LUCRO AJUSTADO caso o LUCRO AJUSTADO seja maior do que o valor de 20.000 para cada mês">Adicional
							de Imposto de Renda (10%)</span></th>
					<td class="currency"><span>{{ adicImpRenda.jan | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.fev | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.mar | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.abr | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.mai | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.jun | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.jul | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.ago | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.set | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.out | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.nov | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let totalIrpj of listaTotalIrpj">
					<th class="th-subtotal" colspan="2"><span matTooltip="Imposto de Renda + Adicional do Imposto de Renda">TOTAL
							IRPJ A RECOLHER</span></th>
					<td class="td-subtotal"><span>{{ totalIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let totalIrpj of listaTotalAntecIrpj">
					<th class="th-subtotal" colspan="2"><span matTooltip="I">ANTECIPAÇÕES DE IMPOSTO DE RENDA</span></th>
					<td class="td-subtotal"><span>{{ totalIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.dez | money }}</span></td>
				</tr>


				<tr class="dtc" *ngFor="let tbSomaDeducoes of listaListSomaDeducoes">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaDeducao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">DEDUÇÕES</th>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbDed of listaListDed">
					<th align="left"><button type="button" class="btn btn-xs btn-outline-danger"
							style="border-color: transparent;" matTooltip="Excluir Conta" [disabled]="hasApurado"
							(click)="excMovParteADeducoes(tbDed.id)"><i class="fas fa-trash-alt"></i></button></th>
					<th><span matTooltip="{{tbDed.descricao}}">{{tbDed.cod}} - {{tbDed.desc_mini}}</span> </th>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,1)"><span>{{ tbDed.jan | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,2)"><span>{{ tbDed.fev | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,3)"><span>{{ tbDed.mar | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,4)"><span>{{tbDed.abr | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,5)"><span>{{tbDed.mai | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,6)"><span>{{tbDed.jun | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,7)"><span>{{tbDed.jul | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,8)"><span>{{tbDed.ago | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,9)"><span>{{tbDed.set | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,10)"><span>{{tbDed.out | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,11)"><span>{{tbDed.nov | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,12)"><span>{{tbDed.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbSomaValorLiq of listaValorLiqRecolher">
					<th class="th-subtotal" colspan="2">VALOR LÍQUIDO A RECOLHER</th>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.dez | money }}</span></td>
				</tr>
			</table>
		</div>

		<div class="scrolled-td-table-wrapper">
			<table>

				<tr>
					<th class="th-cab" colspan=2>DESCRIÇÃO</th>
					<td class="td-cab text-right"><span>JAN</span></td>
					<td class="td-cab text-right"><span>FEV</span></td>
					<td class="td-cab text-right"><span>MAR</span></td>
					<td class="td-cab text-right"><span>ABR</span></td>
					<td class="td-cab text-right"><span>MAI</span></td>
					<td class="td-cab text-right"><span>JUN</span></td>
					<td class="td-cab text-right"><span>JUL</span></td>
					<td class="td-cab text-right"><span>AGO</span></td>
					<td class="td-cab text-right"><span>SET</span></td>
					<td class="td-cab text-right"><span>OUT</span></td>
					<td class="td-cab text-right"><span>NOV</span></td>
					<td class="td-cab text-right"><span>DEZ</span></td>
				</tr>
				<tr *ngFor="let somaResultado of listaListSomaResultado">
					<th colspan="2"><span matTooltip="O valor é a soma dos saldos das contas contábeis de natureza 4">Lucro
							(Prejuízo) Acumulado</span></th>
					<td class="currency"><span>{{ somaResultado.jan | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.fev | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.mar | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.abr | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.mai | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.jun | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.jul | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.ago | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.set | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.out | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.nov | money }}</span></td>
					<td class="currency"><span>{{ somaResultado.dez | money }}</span></td>
				</tr>
				<tr *ngIf="listSomaIrpj?.length === 0">
					<th align="left" colspan="2"><span
							matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ
							e CSLL</span></th>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
					<td class="currency"><span>{{ 0 | money }}</span></td>
				</tr>
				<tr *ngFor="let somaIrpj of listSomaIrpj">
					<th align="left" colspan="2"><span
							matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ
							e CSLL</span></th>
					<td class="currency"><span>{{ somaIrpj.jan | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.fev | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.mar | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.abr | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.mai | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.jun | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.jul | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.ago | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.set | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.out | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.nov | money }}</span></td>
					<td class="currency"><span>{{ somaIrpj.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let somaResultadoAntes of listaSomaResultadoAntesIrpj">
					<th align="left" colspan=2><span matTooltip="Valor do Lucro(Prejuízo)Acumulado + IRPJ e CSLL">Lucro (Prejuízo)
							Acumulado antes do IRPJ e CSLL</span></th>
					<td class="currency"><span>{{ somaResultadoAntes.jan | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.fev | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.mar | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.abr | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.mai | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.jun | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.jul | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.ago | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.set | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.out | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.nov | money }}</span></td>
					<td class="currency"><span>{{ somaResultadoAntes.dez | money }}</span></td>
				</tr>

				<tr class="dtc" *ngIf="listaListSoma?.length === 0">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaAdicao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">ADIÇÕES</th>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				</tr>

				<tr class="dtc" *ngFor="let tbIrpj of listaListSoma">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaAdicao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">ADIÇÕES</th>
					<td class="td-subtotal"><span>{{ tbIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbIrpj of listaList">
					<th align="left"><button *ngIf="tbIrpj.count===0" type="button" class="btn btn-xs btn-outline-danger"
							style="border-color: transparent;" matTooltip="Excluir Conta" [disabled]="hasApurado"
							(click)="excMovParteA(tbIrpj.id)"><i class="fas fa-trash-alt"></i></button></th>
					<th><span matTooltip="{{tbIrpj.descricao}}">{{tbIrpj.cod}} - {{tbIrpj.desc_mini}}</span> </th>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,1)"><span>{{ tbIrpj.jan | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,2)"><span>{{ tbIrpj.fev | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,3)"><span>{{ tbIrpj.mar | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,4)"><span>{{tbIrpj.abr | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,5)"><span>{{tbIrpj.mai | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,6)"><span>{{tbIrpj.jun | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,7)"><span>{{tbIrpj.jul | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,8)"><span>{{tbIrpj.ago | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,9)"><span>{{tbIrpj.set | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,10)"><span>{{tbIrpj.out | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,11)"><span>{{tbIrpj.nov | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbIrpj.id,12)"><span>{{tbIrpj.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngIf="listaListSomaExc?.length === 0">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaExclusao()"><i class="fa fa-plus"></i></button></th>
					<th class="th-subtotal">EXCLUSÕES</th>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
					<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbIrpj of listaListSomaExc">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaExclusao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">EXCLUSÕES</th>
					<td class="td-subtotal"><span>{{ tbIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbIrpj.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbExc of listaListExc">
					<th align="left"><button *ngIf="tbExc.count===0" type="button" class="btn btn-xs btn-outline-danger"
							style="border-color: transparent;" matTooltip="Excluir Conta" [disabled]="hasApurado"
							(click)="excMovParteA(tbExc.id)"><i class="fas fa-trash-alt"></i></button></th>

					<th><span matTooltip="{{tbExc.descricao}}">{{tbExc.cod}} - {{tbExc.desc_mini}}</span> </th>
					<td class="currency" (click)="onContaSelect(tbExc.id,1)"><span>{{ tbExc.jan | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,2)"><span>{{ tbExc.fev | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,3)"><span>{{ tbExc.mar | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,4)"><span>{{tbExc.abr | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,5)"><span>{{tbExc.mai | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,6)"><span>{{tbExc.jun | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,7)"><span>{{tbExc.jul | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,8)"><span>{{tbExc.ago | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,9)"><span>{{tbExc.set | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,10)"><span>{{tbExc.out | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,11)"><span>{{tbExc.nov | money }}</span></td>
					<td class="currency" (click)="onContaSelect(tbExc.id,12)"><span>{{tbExc.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let somaLucroCompensacao of listaSomaLucroAntesCompensacao">
					<th class="th-subtotal" colspan="2"><span matTooltip="Lucro(Prejuízo) Acumulado + ADIÇÕES - EXCLUSÕES">LUCRO
							ANTES DA COMPENSAÇÃO PREJ. FISCAL</span></th>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ somaLucroCompensacao.dez | money }}</span></td>
				</tr>
				<tr>
					<th class="left"><button type="button" matTooltip="Adicionar Prejuízo Acumulado" class="btn btn-xs btn-info"
							[disabled]="hasApurado" (click)="adicionarPrejuizoAcumulado()"><i class="fas fa-plus"></i></button></th>
					<th>PREJUÍZO FISCAL A COMPENSAR</th>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
					<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				</tr>
				<tr *ngFor="let prejFiscalCompensado of listaPrejFiscalCompensado">
					<th class="left" colspan="2"><span matTooltip="30% do Lucro Antes da Compensação">PREJUÍZO FISCAL
							COMPENSADO</span></th>
					<td class="currency"><span>{{ prejFiscalCompensado.jan | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.fev | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.mar | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.abr | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.mai | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.jun | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.jul | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.ago | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.set | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.out | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.nov | money }}</span></td>
					<td class="currency"><span>{{ prejFiscalCompensado.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let compensacaoPrejPeriodoAnterior of listaCompensacaoPrejPeriodoAnterior">
					<th class="left" colspan="2">COMPENSAÇÃO PREJUÍZOS PERÍODOS ANTERIORES</th>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jan | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.fev | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mar | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.abr | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mai | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jun | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jul | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.ago | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.set | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.out | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.nov | money }}</span></td>
					<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let saldoPrejFiscalCompensar of listaSaldoPrejFiscalCompensar">
					<th class="left" colspan="2"><span matTooltip="Prejuizo Fiscal a Compensar - Prejuizo Fiscal Compensado">SALDO
							DE PREJUÍZO FISCAL A COMPENSAR</span></th>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.jan | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.fev | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.mar | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.abr | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.mai | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.jun | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.jul | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.ago | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.set | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.out | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.nov | money }}</span></td>
					<td class="currency"><span>{{ saldoPrejFiscalCompensar.dez | money }}</span></td>
				</tr>

				<tr *ngFor="let lucroAjustado of listaLucroAjustado">
					<th class="th-subtotal" colspan="2"><span
							matTooltip="Lucro antes da Compensação - Compensação Prejuízo de Períodos Anteriores">LUCRO
							AJUSTADO</span></th>
					<td class="td-subtotal"><span>{{ lucroAjustado.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ lucroAjustado.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let impRenda of listaImpRenda">
					<th class="left" colspan="2"><span matTooltip="15% do LUCRO AJUSTADO">Imposto de Renda (15%)</span></th>
					<td class="currency"><span>{{ impRenda.jan | money }}</span></td>
					<td class="currency"><span>{{ impRenda.fev | money }}</span></td>
					<td class="currency"><span>{{ impRenda.mar | money }}</span></td>
					<td class="currency"><span>{{ impRenda.abr | money }}</span></td>
					<td class="currency"><span>{{ impRenda.mai | money }}</span></td>
					<td class="currency"><span>{{ impRenda.jun | money }}</span></td>
					<td class="currency"><span>{{ impRenda.jul | money }}</span></td>
					<td class="currency"><span>{{ impRenda.ago | money }}</span></td>
					<td class="currency"><span>{{ impRenda.set | money }}</span></td>
					<td class="currency"><span>{{ impRenda.out | money }}</span></td>
					<td class="currency"><span>{{ impRenda.nov | money }}</span></td>
					<td class="currency"><span>{{ impRenda.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let adicImpRenda of listaAdicImpRenda">
					<th class="left" colspan="2"><span
							matTooltip="10% do LUCRO AJUSTADO caso o LUCRO AJUSTADO seja maior do que o valor de 20.000 para cada mês">Adicional
							de Imposto de Renda (10%)</span></th>
					<td class="currency"><span>{{ adicImpRenda.jan | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.fev | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.mar | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.abr | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.mai | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.jun | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.jul | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.ago | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.set | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.out | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.nov | money }}</span></td>
					<td class="currency"><span>{{ adicImpRenda.dez | money }}</span></td>
				</tr>
				<tr *ngFor="let totalIrpj of listaTotalIrpj">
					<th class="th-subtotal" colspan="2"><span matTooltip="Imposto de Renda + Adicional do Imposto de Renda">TOTAL
							IRPJ A RECOLHER</span></th>
					<td class="td-subtotal"><span>{{ totalIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.dez | money }}</span></td>
				</tr>


				<tr *ngFor="let totalIrpj of listaTotalAntecIrpj">
					<th class="th-subtotal" colspan="2"><span matTooltip="I">ANTECIPAÇÕES DE IMPOSTO DE RENDA</span></th>
					<td class="td-subtotal"><span>{{ totalIrpj.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ totalIrpj.dez | money }}</span></td>
				</tr>


				<tr class="dtc" *ngFor="let tbSomaDeducoes of listaListSomaDeducoes">
					<th class="th-subtotal"><button type="button" matTooltip="Adicionar Conta" class="btn btn-xs btn-info"
							[disabled]="allApurado" (click)="vincularContaDeducao()"><i class="fas fa-plus"></i></button></th>
					<th class="th-subtotal">DEDUÇÕES</th>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaDeducoes.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbDed of listaListDed">
					<th align="left"><button type="button" class="btn btn-xs btn-outline-danger"
							style="border-color: transparent;" matTooltip="Excluir Conta" [disabled]="hasApurado"
							(click)="excMovParteADeducoes(tbDed.id)"><i class="fas fa-trash-alt"></i></button></th>
					<th><span matTooltip="{{tbDed.descricao}}">{{tbDed.cod}} - {{tbDed.desc_mini}}</span> </th>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,1)"><span>{{ tbDed.jan | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,2)"><span>{{ tbDed.fev | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,3)"><span>{{ tbDed.mar | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,4)"><span>{{tbDed.abr | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,5)"><span>{{tbDed.mai | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,6)"><span>{{tbDed.jun | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,7)"><span>{{tbDed.jul | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,8)"><span>{{tbDed.ago | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,9)"><span>{{tbDed.set | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,10)"><span>{{tbDed.out | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,11)"><span>{{tbDed.nov | money }}</span></td>
					<td class="currency" (click)="onContaSelectDeducoes(tbDed.id,12)"><span>{{tbDed.dez | money }}</span></td>
				</tr>
				<tr class="dtc" *ngFor="let tbSomaValorLiq of listaValorLiqRecolher">
					<th class="th-subtotal" colspan="2">VALOR LÍQUIDO A RECOLHER</th>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.jan | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.fev | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.mar | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.abr | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.mai | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.jun | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.jul | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.ago | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.set | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.out | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.nov | money }}</span></td>
					<td class="td-subtotal"><span>{{ tbSomaValorLiq.dez | money }}</span></td>
				</tr>
			</table>
		</div>
	</div>

	<!--Modal Selecionar Contas para Mapeamento (FEITO)-->
	<p-dialog header="Selecionar Contas para Mapeamento" [(visible)]="modalSelecao" styleClass="modal-initial"
		[style]="{width: '900px'}" [modal]="true" appendTo="body" [closable]="true">

		<p-table class="table-system" #dt4 [value]="balancete" [(selection)]="planoSelecionados" [rows]="10"
			[paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10]" [style]="{'width':'100%'}" immutable="false">
			<ng-template pTemplate="header">
				<tr>
					<th class="text-center" style="width: 40px;">
					</th>
					<th class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'planoContas.codCta'">
							<span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'planoContas.codCta'">
								</p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'planoContas.codCta', 'contains')">
					</th>
					<th class="text-left" style="width: 25%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'planoContas.descrCta'">
							<span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'planoContas.descrCta'">
								</p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'planoContas.descrCta', 'contains')">
					</th>
					<th class="text-left" style="width: 15%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'cadCcus.codCcus'">
							<span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO <p-sortIcon [field]="'cadCcus.codCcus'">
								</p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'cadCcus.codCcus', 'contains')">
					</th>
					<th class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'vlSldIni'">
							<span class="text-overflow-dynamic-ellipsis">SALDO INICIAL <p-sortIcon [field]="'vlSldIni'"></p-sortIcon>
							</span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'vlSldIni', 'contains')">
					</th>
					<th class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcIni'">
							<span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcIni'"></p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'indDcIni', 'contains')">
					</th>
					<th class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'vlSldFin'">
							<span class="text-overflow-dynamic-ellipsis">SALDO FINAL <p-sortIcon [field]="'vlSldFin'"></p-sortIcon>
							</span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'vlSldFin', 'contains')">
					</th>
					<th class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcFin'">
							<span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcFin'"></p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt4.filter($event.target.value, 'indDcFin', 'contains')">
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td class="text-center" style="width: 40px;">
						<p-checkbox (change)="selectTbRelac(rowData)"></p-checkbox>
					</td>
					<td class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.planoContas?.codCta }}">{{
								rowData.planoContas?.codCta }}</span>
						</span>
					</td>
					<td class="text-left" style="width: 25%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis"
								matTooltip="{{rowData.planoContas?.descrCta}}">{{rowData.planoContas?.descrCta }}</span>
						</span>
					</td>
					<td class="text-left" style="width: 15%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis"
								matTooltip="{{rowData.cadCcus?.codCcus}}">{{rowData.cadCcus?.codCcus }}</span>
						</span>
					</td>
					<td class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlSldIni | money }}">{{rowData.vlSldIni
								| money }}</span>
						</span>
					</td>
					<td class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indDcIni}}">{{rowData.indDcIni
								}}</span>
						</span>
					</td>
					<td class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlSldFin | money }}">{{rowData.vlSldFin
								| money }}</span>
						</span>
					</td>
					<td class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indDcFin}}">{{rowData.indDcFin}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-rowData>
				<tr>
					<td [attr.colspan]="8" class="spaced-alert">
						<div class="alert alert-xs alert-primary d-inline" role="alert">
							<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>



		<p-footer>
			<button type="text" class="btn btn-secondary" (click)="modalSelecao=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="text" class="btn btn-primary" (click)="adicionarMapeamento()"><i class="fas fa-check"></i>
				Adicionar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Mapeamento (FEITO)-->
	<p-dialog header="Mapeamento" [(visible)]="modalInexistente" [resizable]="false" styleClass="modal-initial"
		[style]="{width: '600px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="alert alert-danger" role="alert">
			<i class="fas fa-exclamation-triangle"></i> Mapeamento <strong>inexistente</strong> para este mês, contate o
			<strong>suporte!</strong>
		</div>
		<p-footer>
			<button type="button" class="btn btn-secondary" (click)="modalInexistente=false"><i class="fas fa-undo"></i>
				Fechar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Conta IRPJ M300 (FEITO)-->
	<p-dialog header="Adicionar Conta IRPJ M300" [(visible)]="modalMapeamento" styleClass="modal-initial"
		[style]="{width: '800px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="row">
			<div class="col-sm-12">
				<h3 class="sub-title"><i class="fas fa-check"> </i> Selecione uma conta referencial do IRPJ</h3>
			</div>
		</div>

		<p-table class="table-system" #dt1 [value]="tbIrpjM300ListAll" [style]="{'width':'100%'}" immutable="false"
			(onFilter)="onFilter($event)" [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''"
			[scrollable]="tbIrpjM300ListAll.length >= 15 ? true : false" scrollHeight="320px">
			<ng-template pTemplate="header">
				<tr>
					<th class="text-center" style="width: 40px;">
					</th>
					<th class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
							<span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt1.filter($event.target.value, 'cod', 'contains')">
					</th>
					<th class="text-left" style="width: 80%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
							<span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
							</span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt1.filter($event.target.value, 'descricao', 'contains')">
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td class="text-center" style="width: 40px;">
						<!-- <mat-checkbox [disabled]="irpjDisabled(rowData)" (change)="selectIrpj(rowData)"></mat-checkbox> -->
						<p-tableCheckbox [disabled]="irpjDisabled(rowData)" (click)="selectIrpj(rowData)" [value]="rowData">
						</p-tableCheckbox>
					</td>
					<td class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cod }}">{{ rowData.cod }}</span>
						</span>
					</td>
					<td class="text-left" style="width: 80%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
								}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-rowData>
				<tr>
					<td [attr.colspan]="3" class="spaced-alert">
						<div class="alert alert-xs alert-primary d-inline" role="alert">
							<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>


		<p-footer>
			<button type="button" class="btn btn-secondary" (click)="modalMapeamento=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="button" [disabled]="!tipoConta" class="btn btn-primary" (click)="adicionarConta()"><i
					class="fas fa-check"></i> Adicionar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Conta IRPJ N620 (FEITO)-->
	<p-dialog header="Adicionar Conta IRPJ N620" [(visible)]="modalMapeamentoDeducoes" styleClass="modal-initial"
		[style]="{width: '800px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="row">
			<div class="col-sm-12">
				<h3 class="sub-title"><i class="fas fa-check"> </i> Selecione uma conta referencial do IRPJ</h3>
			</div>
		</div>

		<p-table class="table-system" #dt2 [value]="tbIrpjN620ListAll" [style]="{'width':'100%'}" immutable="false"
			(onFilter)="onFilterN620($event)" [ngClass]="contadorLinhaN620 < 15 ? 'no-overflow-y' : ''"
			[scrollable]="tbIrpjN620ListAll.length >= 15 ? true : false" scrollHeight="320px">
			<ng-template pTemplate="header">
				<tr>
					<th class="text-center" style="width: 40px;">
					</th>
					<th class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
							<span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt2.filter($event.target.value, 'cod', 'contains')">
					</th>
					<th class="text-left" style="width: 80%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
							<span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
							</span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt2.filter($event.target.value, 'descricao', 'contains')">
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td class="text-center" style="width: 40px;">
						<p-tableCheckbox (click)="selectDeducao(rowData)" [value]="rowData">
						</p-tableCheckbox>
					</td>
					<td class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cod }}">{{ rowData.cod }}</span>
						</span>
					</td>
					<td class="text-left" style="width: 80%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
								}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-rowData>
				<tr>
					<td [attr.colspan]="3" class="spaced-alert">
						<div class="alert alert-xs alert-primary d-inline" role="alert">
							<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>



		<p-footer>
			<button type="button" class="btn btn-secondary" (click)="modalMapeamentoDeducoes=false"><i
					class="fas fa-undo"></i> Cancelar</button>
			<button type="button" class="btn btn-primary" (click)="adicionarContaDeducao()"><i class="fas fa-check"></i>
				Adicionar</button>
		</p-footer>

	</p-dialog>

	<!--Modal Prejuízo Fiscal Acumulado (FEITO)-->
	<p-dialog header="Prejuízo Fiscal Acumulado" [(visible)]="modalPrejuizoAcumulado" styleClass="modal-initial"
		[style]="{width: '500px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="form-row" *ngIf="tbPrejAcumulado">
			<div class="form-group col">
				<label>Informe o Valor do Prejuízo Fiscal Acumulado</label>
				<input class="form-control text-right" id="valorPrejuizo" type="text"
					[(ngModel)]="tbPrejAcumulado.valorPrejuizo" money />
			</div>
		</div>
		<p-footer>
			<button type="button" class="btn btn-primary" (click)="salvarValorPrejuizo()"><i class="fa fa-check"></i>
				Salvar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Visualizar Mapeamento (FEITO)-->
	<p-dialog header="Visualizar Mapeamento" *ngIf="movParteA" [(visible)]="modalConta" styleClass="modal-scroll see-map"
		[style]="{width: '1200px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="form-row">
			<div class="form-group col-sm-2">
				<label>Valor Parte A</label>
				<input [disabled]="desabilitarLancamentos" type="text" class="form-control text-right"
					[(ngModel)]="movParteA.valor" money />
			</div>
			<div class="form-group col">
				<label>Histórico</label>
				<input [disabled]="desabilitarLancamentos" type="text" class="form-control" [(ngModel)]="movParteA.hist" />
			</div>
		</div>

		<p-accordion>
			<p-accordionTab header="Contas Contábeis">

				<p-table class="table-system" [scrollable]="true" scrollHeight="200px" [value]="saldosRelacionados"
					(onEditComplete)="onEditCompleteSaldosRelacionados($event)" (onEdit)="onEditSaldosRelacionados($event)"
					emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%' }">
					<ng-template pTemplate="header">
						<tr>

							<th class="text-left" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Código</span>
								</span>
							</th>
							<th class="text-left" style="width: 20%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Descrição </span>
								</span>

							</th>
							<th class="text-left" style="width: 15%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Centro de Custo </span>
								</span>
							</th>

							<th class="text-right" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Saldo Ini </span>
								</span>
							</th>

							<th class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Ind Ini </span>
								</span>
							</th>

							<th class="text-right" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Saldo Fin </span>
								</span>
							</th>

							<th class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Ind Fin </span>
								</span>
							</th>

							<th class="text-right" style="width: 15%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Saldo Utilizado </span>
								</span>
							</th>

							<th class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Ação </span>
								</span>

							</th>


						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr>


							<td class="text-left" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.planoContas?.codCta }}">
										{{rowData?.movSaldosContabeis?.planoContas?.codCta }} </span>
								</span>


							</td>

							<td class="text-left" style="width: 20%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.planoContas?.descrCta }}">
										{{rowData?.movSaldosContabeis?.planoContas?.descrCta }} </span>
								</span>
							</td>
							<td class="text-left" style="width: 15%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.cadCcus?.codCcus}}">
										{{rowData?.movSaldosContabeis?.cadCcus?.codCcus}} </span>
								</span>
							</td>
							<td class="text-right" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.vlSldIni | money}}">{{rowData?.movSaldosContabeis?.vlSldIni
										| money}}</span>
								</span>


							</td>
							<td class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.movSaldosContabeis?.indDcIni}}">
										{{rowData?.movSaldosContabeis?.indDcIni}}</span>
								</span>
							</td>

							<td class="text-right" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.vlSldFin | money}}">{{rowData?.movSaldosContabeis?.vlSldFin
										| money }}</span>
								</span>


							</td>

							<td class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.movSaldosContabeis?.indDcFin}}">
										{{rowData?.movSaldosContabeis?.indDcFin}}</span>
								</span>
							</td>

							<td class="text-right" style="width: 15%;" [pEditableColumn]="rowData"
								[pEditableColumnField]="'rowData.saldoRelacionado'">
								<p-cellEditor>
									<ng-template pTemplate="input">
										<input class="form-control form-control-sm" type="text" [(ngModel)]="rowData.saldoRelacionado">
									</ng-template>
									<ng-template pTemplate="output">
										<span class="text-overflow-dynamic-container">
											<span class="text-overflow-dynamic-ellipsis"
												matTooltip="{{rowData.saldoRelacionado | money}}">{{rowData.saldoRelacionado | money }}</span>
										</span>
									</ng-template>
								</p-cellEditor>



							</td>

							<td class="text-center" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="delete" matTooltip="Excluir" (click)="excluirMapeamento(rowData)"><i
											class="far fa-trash-alt"></i>
									</span>
								</span>


							</td>


						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage" let-rowData>
						<tr>
							<td [attr.colspan]="9" class="spaced-alert">
								<div class="alert alert-xs alert-primary d-inline" role="alert">
									<i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>


				<div class="row">
					<div class="col mt-2">
						<button type="text" class="btn btn-sm btn-info" (click)="exibirModalSelecao()"
							matTooltip="Adicionar Conta Contábil" matTooltipPosition="right"><i class="fas fa-plus"></i>
							Adicionar</button>
					</div>
				</div>
			</p-accordionTab>
			<p-accordionTab header="Parte B">

				<p-table class="table-system" [value]="movParteBRelacionados" [scrollable]="true" scrollHeight="250px"
					[style]="{'width':'100%'}" immutable="false"
					(onEditComplete)="atualizarSaldoUtilizadoParteB(movParteBRelacionados)">
					<ng-template pTemplate="header">
						<tr>
							<th class="text-left" style="width: 10%;">
								CÓDIGO
							</th>
							<th class="text-left" style="width: 60%;">
								DESCRIÇÃO
							</th>
							<th class="text-right" style="width: 10%;">
								VALOR
							</th>
							<th class="text-center" style="width: 10%;">
								IND LCTO
							</th>
							<th class="text-center" style="width: 90px; vertical-align: sub;">
								AÇÃO
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr>
							<td class="text-left" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.movParteB.cod }}">{{
										rowData.movParteB.cod }}</span>
								</span>
							</td>
							<td class="text-left" style="width: 60%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData.movParteB.descricao}}">{{rowData.movParteB.descricao }}</span>
								</span>
							</td>
							<td pEditableColumn class="text-right" style="width:10%" [pEditableColumn]="rowData"
								[pEditableColumnField]="'rowData.valor'">
								<p-cellEditor>
									<ng-template pTemplate="input">
										<div class="form-row m-0">
											<div class="form-group col-sm-12 mb-0 p-0">
												<input class="form-control form-control-sm text-right" type="text" [(ngModel)]="rowData.valor">
											</div>
										</div>
									</ng-template>

									<ng-template pTemplate="output">
										<span class="text-overflow-dynamic-container">
											<span class="text-overflow-dynamic-ellipsis"
												matTooltip="{{rowData.valor | money}}">{{rowData.valor | money}}</span>
										</span>
									</ng-template>
								</p-cellEditor>
							</td>
							<td pEditableColumn class="text-center" style="width:10%" [pEditableColumn]="rowData"
								[pEditableColumnField]="'rowData.indLcto'">
								<p-cellEditor>
									<ng-template pTemplate="input">
										<div class="form-row m-0">
											<div class="form-group col-sm-12 mb-0 p-0">
												<input class="form-control form-control-sm" type="text" [(ngModel)]="rowData.indLcto">
											</div>
										</div>
									</ng-template>

									<ng-template pTemplate="output">
										<span class="text-overflow-dynamic-container">
											<span class="text-overflow-dynamic-ellipsis"
												matTooltip="{{rowData.indLcto}}">{{rowData.indLcto}}</span>
										</span>
									</ng-template>
								</p-cellEditor>
							</td>
							<td class="text-center" style="width: 90px;">
								<span class="delete" matTooltip="Excluir" (click)="excluirRelacParteB(rowData)"><i
										class="far fa-trash-alt"></i></span>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage" let-rowData>
						<tr>
							<td [attr.colspan]="5" class="spaced-alert">
								<div class="alert alert-xs alert-primary d-inline" role="alert">
									<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>


				<div class="row">
					<div class="col mt-2">
						<button type="text" class="btn btn-sm btn-info" (click)="adicionarParteB()" matTooltip="Adicionar Parte B"
							matTooltipPosition="right"><i class="fas fa-plus"></i> Adicionar</button>
					</div>
				</div>
			</p-accordionTab>
		</p-accordion>
		<p-footer>
			<button type="text" class="btn btn-secondary" (click)="modalConta=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="text" [disabled]="buttonDisabled" class="btn btn-primary"
				(click)="atualizarMovParteAMes(movParteA)"><i class="fas fa-refresh"></i> Atualizar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Visualizar Mapeamento (FEITO)-->
	<p-dialog header="Selecionar Contas para Mapeamento" [(visible)]="modalSelecaoDeducoes" styleClass="modal-initial"
		[style]="{width: '800px'}" [modal]="true" appendTo="body" [closable]="true">

		<p-table class="table-system" #dt5 [value]="balancete" [(selection)]="planoSelecionados" [rows]="10"
			[paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10]" [style]="{'width':'100%'}" immutable="false">
			<ng-template pTemplate="header">
				<tr>
					<th class="text-center" style="width: 40px;">
					</th>
					<th class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'planoContas.codCta'">
							<span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'planoContas.codCta'">
								</p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'planoContas.codCta', 'contains')">
					</th>
					<th class="text-left" style="width: 25%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'planoContas.descrCta'">
							<span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'planoContas.descrCta'">
								</p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'planoContas.descrCta', 'contains')">
					</th>
					<th class="text-left" style="width: 15%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'cadCcus.codCcus'">
							<span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO <p-sortIcon [field]="'cadCcus.codCcus'">
								</p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'cadCcus.codCcus', 'contains')">
					</th>
					<th class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'vlSldIni'">
							<span class="text-overflow-dynamic-ellipsis">SALDO INICIAL <p-sortIcon [field]="'vlSldIni'"></p-sortIcon>
							</span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'vlSldIni', 'contains')">
					</th>
					<th class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcIni'">
							<span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcIni'"></p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'indDcIni', 'contains')">
					</th>
					<th class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'vlSldFin'">
							<span class="text-overflow-dynamic-ellipsis">SALDO FINAL <p-sortIcon [field]="'vlSldFin'"></p-sortIcon>
							</span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'vlSldFin', 'contains')">
					</th>
					<th class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcFin'">
							<span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcFin'"></p-sortIcon></span>
						</span>
						<input class="form-control form-control-sm" type="text"
							(input)="dt5.filter($event.target.value, 'indDcFin', 'contains')">
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData>
				<tr>
					<td class="text-center" style="width: 40px;">
						<p-checkbox (change)="selectTbRelac(rowData)"></p-checkbox>
					</td>
					<td class="text-left" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.planoContas?.codCta }}">{{
								rowData.planoContas?.codCta }}</span>
						</span>
					</td>
					<td class="text-left" style="width: 25%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis"
								matTooltip="{{rowData.planoContas?.descrCta}}">{{rowData.planoContas?.descrCta }}</span>
						</span>
					</td>
					<td class="text-left" style="width: 15%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis"
								matTooltip="{{rowData.cadCcus?.codCcus}}">{{rowData.cadCcus?.codCcus }}</span>
						</span>
					</td>
					<td class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlSldIni | money }}">{{rowData.vlSldIni
								| money }}</span>
						</span>
					</td>
					<td class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indDcIni}}">{{rowData.indDcIni
								}}</span>
						</span>
					</td>
					<td class="text-right" style="width: 15%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlSldFin | money }}">{{rowData.vlSldFin
								| money }}</span>
						</span>
					</td>
					<td class="text-center" style="width: 10%;">
						<span class="text-overflow-dynamic-container">
							<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indDcFin}}">{{rowData.indDcFin}}</span>
						</span>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-rowData>
				<tr>
					<td [attr.colspan]="8" class="spaced-alert">
						<div class="alert alert-xs alert-primary d-inline" role="alert">
							<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
						</div>
					</td>
				</tr>
			</ng-template>
		</p-table>



		<p-footer>
			<button type="text" class="btn btn-secondary" (click)="modalSelecaoDeducoes=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="text" class="btn btn-primary" (click)="adicionarMapeamentoDeducoes()"><i class="fas fa-check"></i>
				Adicionar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Visualizar Mapeamento (FEITO)-->
	<p-dialog header="Visualizar Mapeamento" *ngIf="movParteADeducoes" [(visible)]="modalContaDeducoes"
		styleClass="modal-scroll see-map" [style]="{width: '1200px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="form-row">
			<div class="form-group col-sm-2">
				<label>Valor Parte A</label>
				<input [disabled]="desabilitarLancamentosDeducoes" type="text" class="form-control text-right"
					[(ngModel)]="movParteADeducoes.valor" money />
			</div>
			<div class="form-group col">
				<label>Histórico</label>
				<input [disabled]="desabilitarLancamentosDeducoes" type="text" class="form-control"
					[(ngModel)]="movParteADeducoes.hist" />
			</div>
		</div>

		<p-accordion>
			<p-accordionTab header="Contas Contábeis">

				<p-table class="table-system" [scrollable]="true" scrollHeight="200px" [value]="saldosRelacionadosDeducoes"
					(onEditComplete)="atualizarMapeamentoListDeducoes(saldosRelacionadosDeducoes)"
					emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%' }">
					<ng-template pTemplate="header">
						<tr>

							<th class="text-left" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Código</span>
								</span>
							</th>
							<th class="text-left" style="width: 20%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Descrição </span>
								</span>

							</th>


							<th class="text-right" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Saldo Ini </span>
								</span>
							</th>

							<th class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Ind Ini </span>
								</span>
							</th>

							<th class="text-right" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Saldo Fin </span>
								</span>
							</th>

							<th class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Ind Fin </span>
								</span>
							</th>

							<th class="text-right" style="width: 15%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Saldo Utilizado </span>
								</span>
							</th>

							<th class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis">Ação </span>
								</span>

							</th>


						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-rowData>
						<tr>


							<td class="text-left" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.planoContas?.codCta }}">
										{{rowData?.movSaldosContabeis?.planoContas?.codCta }} </span>
								</span>


							</td>

							<td class="text-left" style="width: 20%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.planoContas?.descrCta }}">
										{{rowData?.movSaldosContabeis?.planoContas?.descrCta }} </span>
								</span>
							</td>

							<td class="text-right" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.vlSldIni | money}}">{{rowData?.movSaldosContabeis?.vlSldIni
										| money}}</span>
								</span>


							</td>
							<td class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.movSaldosContabeis?.indDcIni}}">
										{{rowData?.movSaldosContabeis?.indDcIni}}</span>
								</span>
							</td>

							<td class="text-right" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis"
										matTooltip="{{rowData?.movSaldosContabeis?.vlSldFin | money}}">{{rowData?.movSaldosContabeis?.vlSldFin
										| money }}</span>
								</span>

							</td>

							<td class="text-center" style="width: 10%;">
								<span class="text-overflow-dynamic-container">
									<span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.movSaldosContabeis?.indDcFin}}">
										{{rowData?.movSaldosContabeis?.indDcFin}}</span>
								</span>
							</td>

							<td class="text-right" style="width: 15%;" pEditableColumn [pEditableColumn]="rowData"
								[pEditableColumnField]="'rowData.saldoRelacionado'">
								<p-cellEditor>
									<ng-template pTemplate="input">
										<input class="form-control form-control-sm" type="number" [(ngModel)]="rowData.saldoRelacionado">
									</ng-template>
									<ng-template pTemplate="output">
										<span class="text-overflow-dynamic-container">
											<span class="text-overflow-dynamic-ellipsis"
												matTooltip="{{rowData.saldoRelacionado | money}}">{{rowData.saldoRelacionado | money }}</span>
										</span>
									</ng-template>
								</p-cellEditor>



							</td>

							<td class="text-center" style="width: 10%;">

								<span class="text-overflow-dynamic-container">
									<span class="delete" matTooltip="Excluir" (click)="excluirMapeamentoDeducoes(rowData)"><i
											class="far fa-trash-alt"></i>
									</span>
								</span>


							</td>


						</tr>
					</ng-template>
					<ng-template pTemplate="emptymessage" let-rowData>
						<tr>
							<td [attr.colspan]="8" class="spaced-alert">
								<div class="alert alert-xs alert-primary d-inline" role="alert">
									<i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
								</div>
							</td>
						</tr>
					</ng-template>
				</p-table>


				<div class="row">
					<div class="col mt-2">
						<button type="text" class="btn btn-sm btn-info" (click)="exibirModalSelecaoDeducoes()"
							matTooltip="Adicionar Conta Contábil" matTooltipPosition="right"><i class="fas fa-plus"></i>
							Adicionar</button>
					</div>
				</div>
			</p-accordionTab>
		</p-accordion>
		<p-footer>
			<button type="text" class="btn btn-secondary" (click)="modalContaDeducoes=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="text" [disabled]="buttonDisabledDeducoes" class="btn btn-primary"
				(click)="atualizarMovParteADeducoesMes(movParteADeducoes)"><i class="fas fa-refresh"></i> Atualizar</button>
		</p-footer>
	</p-dialog>

	<!--Modal Selecionar Contas para Mapeamento (FEITO)-->
	<p-dialog header="Selecionar Contas para Mapeamento" *ngIf="movParteBLcto" [(visible)]="addParteB"
		styleClass="modal-initial" [style]="{width: '800px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="form-row">
			<div class="form-group col">
				<label>Conta Parte B</label>
				<p-dropdown class="type-p-dropdown" [options]="opcoesMovParteB" [(ngModel)]="movParteBLcto.movParteB"
					(ngModelChange)="carregarDemonstrativoParteB()"></p-dropdown>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col">
				<label>Saldo Inicial</label>
				<input type="text" class="form-control text-right" disabled="true"
					[(ngModel)]="movParteBDemonstrativo.saldo_inicial + movParteBDemonstrativo.natureza" money />
				<!-- <p>{{movParteBDemonstrativo.saldo_inicial | money }} {{movParteBDemonstrativo.natureza}}</p> -->
			</div>
			<div class="form-group col">
				<label>Lçtos efetuados Part A</label>
				<input type="text" class="form-control text-right" disabled="true"
					[(ngModel)]="movParteBDemonstrativo.valor_parte_a + movParteBDemonstrativo.natureza_parte_a" money />
				<!-- <p>{{movParteBDemonstrativo.valor_parte_a | money }} {{movParteBDemonstrativo.natureza_parte_a}}</p> -->
			</div>
			<div class="form-group col">
				<label>Lçtos efetuados Sem Part A</label>
				<input type="text" class="form-control text-right" disabled="true"
					[(ngModel)]="movParteBDemonstrativo.valor_sem_parte_a + movParteBDemonstrativo.natureza_sem_parte_a" money />
				<!-- <p>{{movParteBDemonstrativo.valor_sem_parte_a | money }} {{movParteBDemonstrativo.natureza_sem_parte_a}}</p> -->
			</div>
			<div class="form-group col">
				<label>Saldo Disponível</label>
				<input type="text" class="form-control text-right" disabled="true"
					[(ngModel)]="movParteBDemonstrativo.saldo_final + movParteBDemonstrativo.natureza_saldo_final" money />
				<!-- <p>{{movParteBDemonstrativo.saldo_final | money }} {{movParteBDemonstrativo.natureza_saldo_final}}</p> -->
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col">
				<label>Histórico</label>
				<input type="text" class="form-control" [(ngModel)]="movParteBLcto.descricao" />
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-sm-6">
				<label>Indicador do Lançamento</label>
				<p-dropdown class="type-p-dropdown" [options]="indicadorLcto" [(ngModel)]="movParteBLcto.indLcto"></p-dropdown>
			</div>
			<div class="form-group col-sm-3">
				<label>Valor do Lançamento</label>
				<input type="text" class="form-control text-right" [(ngModel)]="movParteBLcto.valor" money />
			</div>
		</div>
		<p-footer>
			<button type="button" class="btn btn-secondary" (click)="addParteB=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="button" class="btn btn-primary" (click)="adicionarMapeamentoParteB()"><i class="fas fa-check"></i>
				Adicionar</button>
		</p-footer>
	</p-dialog>
</mat-card>