<mat-card class="card-linnks margin-linnks">

  <mat-card-title class="mb-2">
    {{titlePrincipal}}
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-8 col-xxl-6">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">Cadastro de Regras OFX</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateOfxRegras>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title mt-0"><i class="fas fa-hand-holding-usd"></i> Operação Financeiro</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="operacoesRegrasContabeis"
                  placeholder="Selecione a Operação" [(ngModel)]="operacaoEscolhida" (onChange)="buscarListaOFXMemo()">
                </p-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title mt-1"><i class="fas fa-bars"></i> Descrições OFX</h3>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="conteudo m-0">
                  <p-pickList sourceHeader="Descrições Disponíveis" targetHeader="Descrições Relacionadas"
                    [source]="listaOFXMemoRelacionar" [target]="listaOFXMemoRelacionados" [dragdrop]="true"
                    [responsive]="true" filterBy="memo" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-resp pTemplate="item">{{resp.memo}}</ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mt-3 mb-0">
                <button class="btn btn-primary" (click)="atualizarRegrasOFX();"><i class="fas fa-check"></i>
                  Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
