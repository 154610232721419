<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>


  <mat-card-content>
    <div class="box-content">
      <div>

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsListaPalestraCliente" [value]="listaPalestraCliente"
          [paginator]="true" [rows]="rows" [totalRecords]="listaPalestraCliente.length" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 24%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'palestraDescricao'">
                  <span class="text-overflow-dynamic-ellipsis">WORKSHOP <p-sortIcon [field]="'palestraDescricao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'palestraDescricao', 'contains')">
              </th>
              <th class="text-left" style="width: 12%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCadastro'">
                  <span class="text-overflow-dynamic-ellipsis">DATA CADASTRO <p-sortIcon [field]="'dataCadastro'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'dataCadastro', 'contains')">
              </th>
              <th class="text-left" style="width: 26%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeCompleto'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nomeCompleto'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nomeCompleto', 'contains')">
              </th>

              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                  <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'email', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'telefone'">
                  <span class="text-overflow-dynamic-ellipsis">TELEFONE <p-sortIcon [field]="'telefone'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'telefone', 'contains')">
              </th>

              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeCarteira'">
                  <span class="text-overflow-dynamic-ellipsis">NOME CARTEIRA <p-sortIcon [field]="'nomeCarteira'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nomeCarteira', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoStatus'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'descricaoStatus'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatusLista" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'descricaoStatus', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>

              <td class="text-left" style="width: 24%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.palestraDescricao}}">{{rowData.palestraDescricao }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 12%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.dataCadastro}}">{{rowData.dataCadastro }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 29.5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeCompleto}}  ">
                    {{rowData.nomeCompleto}} </span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.email}}  ">{{rowData.email}}
                  </span>
                </span>
              </td>

              <td class="text-left" style="width: 12.5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.telefone}}   ">{{rowData.telefone}}
                  </span>
                </span>
              </td>

              <td class="text-left" style="width: 14.5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.nomeCarteira}} ">
                    {{rowData.nomeCarteira}} </span>
                </span>
              </td>

              <td class="text-center" style="width: 15.5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.descricaoStatus}} ">
                    {{rowData.descricaoStatus}} </span>
                </span>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="8" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
  </mat-card-content>


  <!-- Inicio Modal Cadastra Palestra Cliente -->
  <p-dialog header="Cadastrar Palestra Cliente" [(visible)]="modalPalestraCliente" [style]="{width: '800px'}"
    [modal]="true" styleClass="lks-modal" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="true">


    <!-- modalFormulario -->
    <div *ngIf="modalPalestraCliente">

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>INFORMAÇÕES WORKSHOP</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesPalestras"
            [(ngModel)]="palestraCliente.palestra.id">
          </p-dropdown>
        </div>
      </div>



      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>CARTEIRA</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesCarteira"
            [(ngModel)]="carteiraId">
          </p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>NOME COMPLETO</label>
          <input class="form-control" [(ngModel)]="palestraCliente.nomeCompleto" required="true" type="text"
            minlength="3" maxlength="255" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>EMAIL</label>
          <input class="form-control" [(ngModel)]="palestraCliente.email" required="true" type="email" minlength="3"
            maxlength="255" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DDD+TELEFONE</label>
          <p-inputMask class="type-p-mask" mask="(99)99999999?9" [(ngModel)]="palestraCliente.telefone"
            placeholder="(99)999999999"></p-inputMask>
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>EMPRESA</label>
          <input class="form-control" [(ngModel)]="palestraCliente.empresaNome" required="true" type="text"
            minlength="3" maxlength="255" />
        </div>
      </div>



      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">

        </div>
      </div>


    </div>


    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalPalestraCliente=false; "><i class="fas fa-undo"></i>
        Cancelar</button>
      <button *ngIf="editarPalestraCliente" type="button" class="btn btn-primary" (click)="atualizar();"><i
          class="fas fa-pencil-alt"></i> Alterar</button>
      <button *ngIf="!editarPalestraCliente" type="button" class="btn btn-primary" (click)="adicionar();"><i
          class="fas fa-pencil-alt"></i> Novo</button>
    </p-footer>
  </p-dialog>
  <!-- Final Modal Cadastra Palestra Cliente -->


  <!-- Inicio Modal Informacoes Observacoes -->
  <p-dialog header="Informações " [(visible)]="modalObservacoes" [style]="{width: '1000px'}" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true" styleClass="modal-scroll palestra-cliente-altura">

    <mat-tab-group id="tabEventoInformacoes" [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange($event)">

      <mat-tab label="ADD HISTÓRICO">
        <div class="box-content pt-2">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>STATUS</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="listaMotivosRecusa"
                [(ngModel)]="palestraClienteObservacoes.palestraClienteStatus.id">
              </p-dropdown>
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>PESSOA RESPONSÁVEL</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesResponsavel"
                [(ngModel)]="palestraClienteObservacoes.usuarioResponsavelId">
              </p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Data do Contato</label>
              <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [(ngModel)]="palestraClienteObservacoes.dataRecusa">
              </p-calendar>
            </div>

            <div class="form-group col-sm-6">
              <label>Data e Hora para retorno</label>
              <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [showTime]="true" hourFormat="12"
                [(ngModel)]="this.palestraClienteObservacoes.dataRetorno">
              </p-calendar>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-sm-12 mt-2">
              <label>OBSERVAÇÃO</label>
              <textarea class="form-control text-left" rows="7"
                [(ngModel)]="palestraClienteObservacoes.observacao"></textarea>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>LINK LEAD</label>
              <input class="form-control" [(ngModel)]="linkLead" type="text" readonly />
            </div>
          </div>



        </div>
      </mat-tab>
      <mat-tab label="Histórico">
        <div class="box-content pt-2">
          <!-- Inicio tabela de informacoes de troca de participantes -->

          <p-table class="table-system" #dt1 [value]="listaHistorioObservacoes" [paginator]="false" [rows]="rows"
            [totalRecords]="listaHistorioObservacoes.length" selectionMode="single" [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">DATA </span>
                  </span>

                </th>
                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">STATUS </span>
                  </span>

                </th>
                <th class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Observação </span>
                  </span>

                </th>

                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">USUÁRIO CAD. </span>
                  </span>

                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">USUÁRIO RESP. </span>
                  </span>

                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">DT RETORNO </span>
                  </span>

                </th>



              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>

                <td class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dataRecusa}}">{{rowData.dataRecusa }}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
                      }}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.observacaoSimplificada}}  ">
                      {{rowData.observacaoSimplificada}} </span>
                  </span>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip=" {{rowData.usuarioNome}}  ">{{rowData.usuarioNome}} </span>
                  </span>
                </td>

                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.nomeUsuarioResponsavel}}   ">{{rowData.nomeUsuarioResponsavel}} </span>
                  </span>
                </td>

                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.dataRetorno}} ">
                      {{rowData.dataRetorno}} </span>
                  </span>
                </td>



              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="8" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>




          <!-- Final da tabela de informacoes de troca de participantes -->


        </div>
      </mat-tab>




    </mat-tab-group>

    <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer class="mb-0">
      <button type="button" class="btn btn-secondary" (click)='modalObservacoes = false;'><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary" (click)='salvarObservacoes(); '><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>


  </p-dialog>
  <!-- Final Modal Informacoes Observacoes -->


  <!-- INICIO MODAL VISUALIZACAO INFORMACAO -->
  <p-dialog header="Informações" [(visible)]="modalVisualizacaoInf" [style]="{width: '800px'}" [modal]="true"
    styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">



    <!-- modalFormulario -->
    <div *ngIf="modalVisualizacaoInf">


      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>STATUS</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="listaMotivosRecusa"
            [disabled]="true" [(ngModel)]="palestraClienteObservacoesVisualizacao.palestraClienteStatus.id">
          </p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
            [defaultDate]="defaultDate" yearRange="2010:2030"
            [(ngModel)]="palestraClienteObservacoesVisualizacao.dataRecusa" [disabled]="true">
          </p-calendar>

        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12 mt-2">
          <label>OBSERVAÇÃO</label>
          <textarea class="form-control text-left" [(ngModel)]="palestraClienteObservacoesVisualizacao.observacao"
            [disabled]="true"></textarea>
        </div>
      </div>


      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">

        </div>
      </div>


    </div>


    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalVisualizacaoInf=false;"><i class="fas fa-undo"></i>
        Cancelar</button>
    </p-footer>
  </p-dialog>
  <!-- FINAL MODAL VISUALIZACAO INFORMACAO -->



</mat-card>