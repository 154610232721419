<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>

  <div class="input-12">

  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <mat-tab label="R-2010">
        <div class="box-content pt-2">

          <!-- <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dt2.exportCSV()"><i class="fas fa-file-excel excel"></i>
              Exportar para Excel</button>
          </div> -->
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <p-table class="table-system" #dt2 [columns]="colsLista" [value]="lista" [rows]="rows" [paginator]="true"
            [totalRecords]="lista.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            (onHeaderCheckboxToggle)="checkAll()" (onRowSelect)="selectCheckbox($event)"  (onRowUnselect)="unselectCheckbox($event)"
            selectionMode="false" [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 45px;">
                  <span class="check-all p-0">
                    <!-- <mat-checkbox (click)="checkAll()"></mat-checkbox> -->
                    <p-tableHeaderCheckbox ></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo'">
                    <span class="text-overflow-dynamic-ellipsis">PERÍODO <p-sortIcon [field]="'periodo'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'periodo', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'status', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'ambiente'">
                    <span class="text-overflow-dynamic-ellipsis">AMBIENTE <p-sortIcon [field]="'ambiente'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'ambiente', 'contains')">
                </th>

                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'prestadorNome'">
                    <span class="text-overflow-dynamic-ellipsis">PRESTADOR NOME <p-sortIcon [field]="'prestadorNome'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'prestadorNome', 'contains')">
                </th>

                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'prestadorCnpj'">
                    <span class="text-overflow-dynamic-ellipsis">PRESTADOR CNPJ <p-sortIcon [field]="'prestadorCnpj'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'prestadorCnpj', 'contains')">
                </th>

                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'contratante'">
                    <span class="text-overflow-dynamic-ellipsis">CONTRATANTE <p-sortIcon [field]="'contratante'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'contratante', 'contains')">
                </th>

                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'servico'">
                    <span class="text-overflow-dynamic-ellipsis">SERVIÇO <p-sortIcon [field]="'servico'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'servico', 'contains')">
                </th>

                <th class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrTotalBruto'">
                    <span class="text-overflow-dynamic-ellipsis">VLR TOTAL BRUTO <p-sortIcon [field]="'vlrTotalBruto'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'vlrTotalBruto', 'contains')">
                </th>

                <th class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrTotalBaseRet'">
                    <span class="text-overflow-dynamic-ellipsis">VLR TOTAL BASE RET <p-sortIcon
                        [field]="'vlrTotalBaseRet'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'vlrTotalBaseRet', 'contains')">
                </th>

                <th class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrTotalRetPrinc'">
                    <span class="text-overflow-dynamic-ellipsis">VLR TOTAL RET PRINC <p-sortIcon
                        [field]="'vlrTotalRetPrinc'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'vlrTotalRetPrinc', 'contains')">
                </th>




              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center" style="width: 45px;">
                  <!-- <mat-checkbox [disabled]="rowData.desabilitaSelecao" (click)="check(rowData.id, rowData)"
                    [(ngModel)]="rowData.selected"></mat-checkbox> -->
                    <p-tableCheckbox [value]="rowData" [(ngModel)]="rowData.selected"></p-tableCheckbox>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.periodo}}">{{rowData.periodo
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.ambiente}}">{{rowData.ambiente}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.prestadorNome}}">{{rowData.prestadorNome}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.prestadorCnpj}}">{{rowData.prestadorCnpj }}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.contratante}}">{{rowData.contratante }}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.servico}}">{{rowData.servico
                      }}</span>
                  </span>
                </td>

                <td class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vlrTotalBruto}}">{{rowData.vlrTotalBruto }}</span>
                  </span>
                </td>

                <td class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vlrTotalBaseRet}}">{{rowData.vlrTotalBaseRet }}</span>
                  </span>
                </td>

                <td class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vlrTotalRetPrinc}}">{{rowData.vlrTotalRetPrinc }}</span>
                  </span>
                </td>




              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="13" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>



        </div>
      </mat-tab>
      <mat-tab label="R-4020">
        <div class="box-content pt-2">
          </div>
          </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>

<!--Modal R-2010 -->
<p-dialog header="R-2010 - Retenção Contribuição Previdenciária - Serviços Tomados" [(visible)]="modalR2010"
  appendTo="body" [closable]="true" [modal]="true" [responsive]="true" styleClass="modal-initial"
  [style]="{width: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block pb-1">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12 mb-1 mt-1">
      <label class="d-block">Selecione o arquivo para importação</label>
      <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
        <span class="input-group-append">
          <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                    Remover Arquivo
                  </span> -->
          <span class="input-group-text btn-file">
            <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span>
            <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span> <!-- Antes era Trocar Arquivo-->
            <input type="file" #file (change)="fileChange()" />
          </span>
        </span>
        <div class="form-control" data-trigger="fileinput">
          <span class="fileinput-filename"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="upload()">
      <i class="fas fa-check"></i> Enviar
    </button>
  </p-footer>
</p-dialog>

<!--Modal R-2098 -->
<p-dialog header="R-2098 - Reabertura dos Eventos Periódicos" [(visible)]="modalR2098" appendTo="body" [closable]="true"
  [modal]="true" [responsive]="true" styleClass="modal-initial" [style]="{width: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block pb-1">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="evtR2098()">
      <i class="fas fa-check"></i> Enviar
    </button>
  </p-footer>
</p-dialog>

<!--Modal R-2099 -->
<p-dialog header="R-2099 - Fechamento dos Eventos Periódicos" [(visible)]="modalR2099" appendTo="body" [closable]="true"
  [modal]="true" [responsive]="true" styleClass="modal-scroll" [style]="{width: '850px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">Contratou serviços sujeitos à retenção de contribuição previdenciária?</label>
      <mat-radio-group [(ngModel)]="rbEvtServTm">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">Prestou serviços sujeitos à retenção de contribuição previdenciária?</label>
      <mat-radio-group [(ngModel)]="rbEvtServPr">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">A associação desportiva que mantém equipe de futebol profissional,
        possui informações sobre recursos recebidos?</label>
      <mat-radio-group [(ngModel)]="rbEvtAssDespRec">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">Possui informações sobre repasses efetuados à associação desportiva
        que mantém equipe de futebol profissional?</label>
      <mat-radio-group [(ngModel)]="rbEvtAssDespRep">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">O produtor rural PJ/Agroindústria possui informações de
        comercialização de produção?</label>
      <mat-radio-group [(ngModel)]="rbEvtComProd">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">Possui informações sobre a apuração da Contribuição Previdenciária
        sobre a Receita Bruta?</label>
      <mat-radio-group [(ngModel)]="rbEvtCPRB">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block">Possui informações sobre aquisição de produção rural?</label>
      <mat-radio-group [(ngModel)]="rbEvtAquis">
        <mat-radio-button value="S" class="mr-3"> Sim </mat-radio-button>
        <mat-radio-button value="N"> Não </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="evtR2099()">
      <i class="fas fa-check"></i> Enviar
    </button>
  </p-footer>
</p-dialog>

<!--Modal Consulta de fechamento -->
<p-dialog header="Consulta de fechamento" [(visible)]="modalConsultaFechamento" appendTo="body" [closable]="true"
  [modal]="true" [responsive]="true" styleClass="modal-initial" [style]="{width: '700px'}">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block pb-1">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="evtConsultaFechamento()">
      <i class="fas fa-search"></i> Consultar
    </button>
  </p-footer>
</p-dialog>

<!--Modal Exportar -->
<p-dialog header="Exportar {{ modalExportLabel }}" [(visible)]="modalExport" appendTo="body" [closable]="true"
  [modal]="true" [responsive]="true" styleClass="modal-initial" [style]="{width: '700px'}">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block pb-1">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="download()">
      <i class="fas fa-download"></i> Baixar
    </button>
  </p-footer>
</p-dialog>

<!--Modal R-1000 -->
<p-dialog header="R-1000 - ENVIO DE DADOS CADASTRAIS" [(visible)]="modalR1000" appendTo="body" [closable]="true"
  [modal]="true" [responsive]="true" styleClass="modal-initial" [style]="{width: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block pb-1">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="evtR1000()">
      <i class="fas fa-check"></i> Enviar
    </button>
  </p-footer>
</p-dialog>


<!--Modal R-2010 -->
<p-dialog header="R-4020 - Pagamentos/créditos a beneficiário pessoa jurídica" [(visible)]="modalR4020"
  appendTo="body" [closable]="true" [modal]="true" [responsive]="true" styleClass="modal-initial"
  [style]="{width: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block pb-1">Ambiente</label>
      <mat-radio-group [(ngModel)]="rbAmbiente">
        <mat-radio-button value="1" class="mr-3"> Produção </mat-radio-button>
        <mat-radio-button value="2"> Homologação </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12 mb-1 mt-1">
      <label class="d-block">Selecione o arquivo para importação</label>
      <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
        <span class="input-group-append">
          <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                    Remover Arquivo
                  </span> -->
          <span class="input-group-text btn-file">
            <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span>
            <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span> <!-- Antes era Trocar Arquivo-->
            <input type="file" #file (change)="fileChange()" />
          </span>
        </span>
        <div class="form-control" data-trigger="fileinput">
          <span class="fileinput-filename"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="upload()">
      <i class="fas fa-check"></i> Enviar
    </button>
  </p-footer>
</p-dialog>
