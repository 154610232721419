<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>


      <p-table class="table-system" #dt2 [columns]="colslistaCarteira" [value]="listaCarteira" [rows]="rows"
        [totalRecords]="listaCarteira.length" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox">
              <span class="check-all p-0">
                <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
              </span>
            </th>
            <th class="text-left">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">CARTEIRA <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nome', 'contains')">
            </th>

            <th class="text-left">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'consultorNome'">
                <span class="text-overflow-dynamic-ellipsis">CONSULTOR <p-sortIcon [field]="'consultorNome'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'consultorNome', 'contains')">
            </th>

            <th class="text-left">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>

            <th class="text-center">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'recebeAutomaticoPt'">
                <span class="text-overflow-dynamic-ellipsis">BAL. AUTOM. <p-sortIcon [field]="'recebeAutomaticoPt'">
                  </p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesStatus" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'recebeAutomaticoPt', 'in')"></p-multiSelect>
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center tableCheckbox">
              <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.carteiraNome}}"><span
                    style="user-select: all; cursor: text"> {{rowData.carteiraNome }} </span></span>
              </span>
            </td>

            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.consultorNome}}"><span
                    style="user-select: all; cursor: text"> {{rowData.consultorNome }} </span></span>
              </span>
            </td>

            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}"><span
                    style="user-select: all; cursor: text"> {{rowData.email }} </span></span>
              </span>
            </td>

            <td class="text-center">
              <p-checkbox class="mr-1" [(ngModel)]="rowData.recebeAutomatico" [binary]="true"
                inputId="recebeAutomatico" [disabled]="true"></p-checkbox>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>



    </div>

    <div class="ui-g">
      <div class="ui-g-12">

      </div>
    </div>


    <p-dialog header="CARTEIRA" [(visible)]="modalCarteira" [style]="{width: '800px'}" [modal]="true"
      styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">



      <!-- modalConsultor -->
      <div *ngIf="modalCarteira">

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>CARTEIRA </label>

            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesConsultores"
              placeholder="Selecione o consultor" [(ngModel)]="carteira.consultor"
              (onChange)="onChangeConsultor($event)"></p-dropdown>

          </div>
        </div>


        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>NOME </label>
            <input [(ngModel)]="carteira.nome" class="form-control text-left" type="text" maxlength="255" />
          </div>
        </div>

        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>BACKOFFICE </label>

            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCarteiras"
              placeholder="Selecione o consultor" [(ngModel)]="carteira.backoffice"
              (onChange)="onChangeBackOffice($event)"></p-dropdown>

          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-4">
            <!-- <mat-slide-toggle class="mr-2" [(ngModel)]="carteira.recebeAutomatico;" > BALANCEAMENTO AUTOMÁTICO </mat-slide-toggle> -->
            <mat-slide-toggle class="mr-2" [(ngModel)]="carteira.recebeAutomatico"> BALANCEAMENTO AUTOMÁTICO
            </mat-slide-toggle>
          </div>
        </div>


        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>




      </div>


      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="modalCarteira=false;"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button *ngIf="!editCarteira" type="button" class="btn btn-primary" (click)="salvarCarteira();"
          [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>
        <button *ngIf="editCarteira" type="button" class="btn btn-primary" (click)="editarCarteira();"
          [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>
      </p-footer>
    </p-dialog>
    <!--Final Modal cadastro de Palestras-->

  </mat-card-content>

</mat-card>