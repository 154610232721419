<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>


  <mat-card-content>

    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <mat-tab label="Cadastrar">
        <div class="box-content">
          <!--  <mat-form-field class="texto">
            <label for="inputTexto">EMAIL</label>
            <input matInput value="" type="text" name="inputTexto">
          </mat-form-field> -->
          <div class="form-row">
            <div class="form-group col">
              <label>TIPO DE ARQUIVO</label>
              <p-dropdown class="type-p-dropdown" (onChange)="carregarObrigacao($event)" [filter]="true"
                [options]="tiposArquivo" [(ngModel)]="obrigacao.tipoArquivo.id">
              </p-dropdown>
              <!-- <mat-form-field>
                <mat-select class="example-full-width" (change)="carregarObrigacao($event)" [(ngModel)]="obrigacao.tipoArquivo" ng-class="mat-elevation-z0">
                  <mat-option *ngFor="let tipo of tiposArquivo" [value]="tipo">
                    {{ tipo.nome }}
                  </mat-option>
                </mat-select>
              </mat-form-field>   -->
            </div>
            <div class="form-group col">
              <label>Obrigação</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCodigos"
                [(ngModel)]="obrigacao.codigosObrigacoes.id">
              </p-dropdown>
            </div>
            <div class="form-group col">
              <label>Departamento</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" (onChange)="carregarUsuarios($event)"
                [options]="departamentos" [(ngModel)]="obrigacao.departamento.id">
              </p-dropdown>
              <!-- <mat-form-field>
                  <mat-select class="example-full-width" [(ngModel)]="obrigacao.departamento" (change)="carregarUsuarios($event)">
                    <mat-option *ngFor="let depto of departamentos" [value]="depto">
                      {{ depto.cod }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
            </div>
            <div class="form-group col">
              <label>Responsável</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesusuarios"
                [(ngModel)]="obrigacao.usuario.id">
              </p-dropdown>
              <!-- <mat-form-field>
                  <mat-select class="example-full-width" [(ngModel)]="obrigacao.usuario.id">
                    <mat-option *ngFor="let user of usuarios" [value]="user.id">
                      {{ user.nome }} - {{ user.mail }}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
            </div>
            <div class="form-group col">
              <label>Avisos</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="gatilhos"
                [(ngModel)]="obrigacao.gatilhoObrigacao.id">
              </p-dropdown>
              <!-- <mat-form-field>
                    <mat-select class="example-full-width" [(ngModel)]="obrigacao.gatilhoObrigacao">
                      <mat-option *ngFor="let gatilho of gatilhos" [value]="gatilho">
                        {{ gatilho.descricao }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
            </div>
          </div>



          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-building"></i> Selecionar Empresas</h3>
            </div>
            <div class="col-sm-6">
              <p-pickList sourceHeader="Disponíveis" targetHeader="Selecionadas" [source]="empresas"
                [target]="empresasSelecionadas" [dragdrop]="true" [responsive]="true" [showSourceControls]="false"
                [showTargetControls]="false">
                <ng-template let-emp pTemplate="item">{{emp.cnpj | cnpj}} - {{emp.nomeFantasia}}</ng-template>
              </p-pickList>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title mt-4"><i class="fas fa-calendar-alt"></i> Vencimento</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <!--<div *ngFor="let rec of recorrencias">
                  <p-radioButton name="group1" value="{{rec.value}}" label="{{rec.label}}"  formControlName="myRadio" [(ngModel)]="obrigacao.recorrencia" (click)="modalRecorrencia()"></p-radioButton>
            </div>-->
              <!--<p-selectButton [options]="recorrencias" [(ngModel)]="obrigacao.recorrencia" (click)="modalRecorrencia()"></p-selectButton>-->
              <mat-radio-group [(ngModel)]="obrigacao.recorrencia" (ngModelChange)="modalRecorrencia()">
                <mat-radio-button class="mr-3" *ngFor="let rec of recorrencias" [value]="rec">
                  {{rec.descricao}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-primary" (click)="criarObrigacao()"
                [disabled]="dtFin < dtIni && obrigacao.recorrencia.id !== 1"><i class="fas fa-check"></i>
                Salvar</button>
            </div>
            <!--  <button mat-raised-button class="mat-elevation-z0">Voltar</button>
            <button class="botao-excluir" type="button" pButton label="Excluir"></button> -->
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Editar">
        <div class="box-content">
          <p-table class="table-system" #dt1 [value]="obrigacoes" [rows]="rows" [paginator]="true"
            (onRowSelect)="onRowSelect($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            selectionMode="single">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'vencimento'">
                    <span class="text-overflow-dynamic-ellipsis">Vencimento <p-sortIcon [field]="'vencimento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'vencimento', 'contains')">
                </th>
                <th class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'obrigacao'">
                    <span class="text-overflow-dynamic-ellipsis">Obrigação <p-sortIcon [field]="'obrigacao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'obrigacao', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'recorrencia'">
                    <span class="text-overflow-dynamic-ellipsis">Recorrência <p-sortIcon [field]="'recorrencia'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'recorrencia', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'gatilho'">
                    <span class="text-overflow-dynamic-ellipsis">Avisos <p-sortIcon [field]="'gatilho'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'gatilho', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.vencimento}}">{{rowData.vencimento}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.obrigacao}}">{{rowData.obrigacao}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.recorrencia}}">{{rowData.recorrencia}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.gatilho}}">{{rowData.gatilho}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="4" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

      </mat-tab>
    </mat-tab-group>

    <p-dialog [(visible)]="exbirModalRecorrencia" [style]="{width: '700px'}" [responsive]="true" [modal]="true"
      showEffect="fade" appendTo="body" styleClass="modal-initial" [closable]="false">
      <p-header>
        <div class="modal-title" *ngIf="obrigacao.recorrencia.id === 1">Vencimento Único</div>
        <div class="modal-title" *ngIf="obrigacao.recorrencia.id === 2">Vencimento Semanal</div>
        <div class="modal-title" *ngIf="obrigacao.recorrencia.id === 3">Vencimento Mensal</div>
        <div class="modal-title" *ngIf="obrigacao.recorrencia.id === 4">Vencimento Anual</div>
      </p-header>


      <div *ngIf="obrigacao.recorrencia.id === 1">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Vencimento</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
          <label for="antDNU">Antecipa Dia Não Útil</label>
        </div>
      </div>

      <div *ngIf="obrigacao.recorrencia.id === 2">
        <div class="form-row">
          <div class="form-group col-sm-2">
            <label>Repete a cada</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
            <label>(semanas)</label>
          </div>
          <div class="ml-3 col">
            <label class="d-block" style="margin-top: 5px;">Repete</label>
            <div class="p-field-checkbox">
              <p-checkbox class="mr-1" [(ngModel)]="dom" [binary]="true" inputId="dom"></p-checkbox>
              <label for="dom" class="mr-3">D</label>
              <p-checkbox class="mr-1" [(ngModel)]="seg" [binary]="true" inputId="seg"></p-checkbox>
              <label for="seg" class="mr-3">S</label>
              <p-checkbox class="mr-1" [(ngModel)]="ter" [binary]="true" inputId="ter"></p-checkbox>
              <label for="ter" class="mr-3">T</label>
              <p-checkbox class="mr-1" [(ngModel)]="qua" [binary]="true" inputId="qua"></p-checkbox>
              <label for="qua" class="mr-3">Q</label>
              <p-checkbox class="mr-1" [(ngModel)]="qui" [binary]="true" inputId="qui"></p-checkbox>
              <label for="qui" class="mr-3">Q</label>
              <p-checkbox class="mr-1" [(ngModel)]="sex" [binary]="true" inputId="sex"></p-checkbox>
              <label for="sex" class="mr-3">S</label>
              <p-checkbox class="mr-1" [(ngModel)]="sab" [binary]="true" inputId="sab"></p-checkbox>
              <label for="sab" class="mr-3">S</label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Início Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body">
            </p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Após</label>
            <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
            <label>ocorrências</label>
          </div>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
          <label for="antDNU">Antecipa Dia Não Útil</label>
        </div>
      </div>

      <div *ngIf="obrigacao.recorrencia.id === 3">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Dia do mês</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="dias" [(ngModel)]="diaMes"></p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Repete a cada meses</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Início Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Termina Após</label>
            <input type="text" class="form-control" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
            <label>ocorrências</label>
          </div>
          <div class="col ml-2">
            <label class="d-block" style="margin-top: 6px;">Período</label>
            <div class="p-field-checkbox">
              <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
              <label for="antDNU">Antecipa Dia Não Útil</label>
              <p-checkbox class="ml-3 mr-1" [(ngModel)]="diaUtil" [binary]="true" inputId="diaU"
                [disabled]="diaMes > 20"></p-checkbox>
              <label for="diaU">Dia Útil</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="obrigacao.recorrencia.id === 4">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Repete a cada</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
            <label>anos</label>
          </div>
          <div class="form-group col-sm-3">
            <label>Início Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Após</label>
            <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
            <label>ocorrências</label>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Gatilho de aviso</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="gatilhos"
              [(ngModel)]="obrigacao.gatilhoObrigacao">></p-dropdown>
          </div>
          <div class="col ml-2">
            <div class="p-field-checkbox">
              <label class="d-block" style="visibility: hidden; margin-top: 6px;">DO NOT REMOVE</label>
              <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
              <label for="antDNU">Antecipa Dia Não Útil</label>
            </div>
          </div>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)='exbirModalRecorrencia=false'><i class="fa fa-undo"></i>
          Fechar</button>
      </p-footer>
    </p-dialog>

    <p-dialog header="Editar" [(visible)]="exbirModalObrigacao" [style]="{width: '800px'}" [responsive]="true"
      [modal]="true" showEffect="fade" styleClass="modal-scroll custom" appendTo="body" [closable]="true">
      <div *ngIf="obrigacaoEditar.id">
        <mat-tab-group (selectedTabChange)="onTabChanged($event)">
          <mat-tab label="Editar Vencimento">
            <div class="form-row mt-3">
              <div class="form-group col-sm-3">
                <label>Data</label>
                <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
              <div class="form-group col-sm-4">
                <label>Gatilho de Aviso</label>
                <p-dropdown class="type-p-dropdown" [options]="gatilhos" [(ngModel)]="obrigacao.gatilhoObrigacao">>
                </p-dropdown>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Editar Recorrencia">
            <div class="form-row mt-4">
              <div class="form-group col-sm-12" id="btn-edit-recrrnc">
                <mat-radio-group [(ngModel)]="obrigacaoEditar.recorrencia">

                  <mat-radio-button class="mr-3" *ngFor="let rec of recorrenciasEditar" [value]="rec">
                    {{rec.descricao}}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div *ngIf="obrigacaoEditar.recorrencia.id === 2">
              <div class="form-row">
                <div class="form-group col-sm-2">
                  <label>Repete a cada</label>
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada">
                  </p-dropdown>
                  <label>(semanas)</label>
                </div>
                <div class="ml-3 col">
                  <label class="d-block" style="margin-top: 5px;">Repete</label>
                  <div class="p-field-checkbox">
                    <p-checkbox class="mr-1" [(ngModel)]="dom" [binary]="true" inputId="dom"></p-checkbox>
                    <label for="dom" class="mr-3">D</label>
                    <p-checkbox class="mr-1" [(ngModel)]="seg" [binary]="true" inputId="seg"></p-checkbox>
                    <label for="seg" class="mr-3">S</label>
                    <p-checkbox class="mr-1" [(ngModel)]="ter" [binary]="true" inputId="ter"></p-checkbox>
                    <label for="ter" class="mr-3">T</label>
                    <p-checkbox class="mr-1" [(ngModel)]="qua" [binary]="true" inputId="qua"></p-checkbox>
                    <label for="qua" class="mr-3">Q</label>
                    <p-checkbox class="mr-1" [(ngModel)]="qui" [binary]="true" inputId="qui"></p-checkbox>
                    <label for="qui" class="mr-3">Q</label>
                    <p-checkbox class="mr-1" [(ngModel)]="sex" [binary]="true" inputId="sex"></p-checkbox>
                    <label for="sex" class="mr-3">S</label>
                    <p-checkbox class="mr-1" [(ngModel)]="sab" [binary]="true" inputId="sab"></p-checkbox>
                    <label for="sab" class="mr-3">S</label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>Início Em</label>
                  <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body">
                  </p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>Termina Em</label>
                  <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>Termina Após</label>
                  <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
                  <label>ocorrências</label>
                </div>
                <div class="form-group col-sm-3">
                  <label>Gatilho de aviso</label>
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="gatilhos"
                    [(ngModel)]="obrigacao.gatilhoObrigacao">></p-dropdown>
                </div>
              </div>
            </div>

            <div *ngIf="obrigacaoEditar.recorrencia.id === 3">
              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>Dia do mês</label>
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="dias" [(ngModel)]="diaMes">
                  </p-dropdown>
                </div>
                <div class="form-group col-sm-3">
                  <label>Repete a cada meses</label>
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada">
                  </p-dropdown>
                </div>
                <div class="form-group col-sm-3">
                  <label>Início Em</label>
                  <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>Termina Em</label>
                  <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>Termina Após</label>
                  <input type="text" class="form-control" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
                  <label>ocorrências</label>
                </div>
                <div class="form-group col-sm-3">
                  <label>Gatilho de aviso</label>
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="gatilhos"
                    [(ngModel)]="obrigacao.gatilhoObrigacao"></p-dropdown>
                </div>
                <div class="col">
                  <label class="d-block" style="margin-top: 6px;">Período</label>
                  <div class="p-field-checkbox">
                    <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU">
                    </p-checkbox>
                    <label for="antDNU">Antecipa Dia Não Útil</label>
                    <p-checkbox class="ml-3 mr-1" [(ngModel)]="diaUtil" [binary]="true" inputId="diaU"
                      [disabled]="diaMes > 20"></p-checkbox>
                    <label for="diaU">Dia Útil</label>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="obrigacaoEditar.recorrencia.id === 4">
              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>Repete a cada</label>
                  <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada">
                  </p-dropdown>
                  <label>anos</label>
                </div>
                <div class="form-group col-sm-3">
                  <label>Início Em</label>
                  <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>Termina Em</label>
                  <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>Termina Após</label>
                  <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
                  <label>ocorrências</label>
                </div>
              </div>
              <div class="p-field-checkbox">
                <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
                <label for="antDNU">Antecipa Dia Não Útil</label>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Excluir" *ngIf="dblinkedSessionService.usuario.nivel >= 3">
            <div class="row mt-2">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-trash-alt"></i> Exclusão de obrigação</h3>
              </div>
            </div>
            <div class="form-row mt-1">
              <div class="form-group col-sm-12">
                <mat-radio-group aria-label="Select an option">
                  <mat-radio-button class="mr-3" (click)="deletarObrigacao(obrigacaoEditar.id)" [value]="1">
                    Somente esta obrigação será excluída
                  </mat-radio-button>
                  <mat-radio-button class="mr-3" (click)="deletarObrigacoes(obrigacaoEditar.id)" [value]="2">
                    Esta obrigação e as obrigações seguintes serão excluídas
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

          </mat-tab>
        </mat-tab-group>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" *ngIf="isCancelarDisabled"
          (click)='exbirModalObrigacao=false'><i class="fas fa-undo"></i> Cancelar</button>
        <button type="button" class="btn btn-primary" *ngIf="isVencimentoDisabled" (click)="atualizarObrigacao()"><i
            class="fas fa-check"></i> Editar</button>
        <button type="button" class="btn btn-primary" *ngIf="isRecorrenciaDisabled" (click)="atualizarRecorrencia()"><i
            class="fas fa-check"></i> Editar</button>
      </p-footer>
    </p-dialog>
  </mat-card-content>
</mat-card>