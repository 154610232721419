<div class="mat-form-field mat-form-field mat-primary mat-form-field-type-mat-select mat-form-field-can-float mat-form-field-hide-placeholder mat-form-field-should-float">
  <div class="mat-input-flex mat-form-field-flex">
    <div class="mat-input-infix mat-form-field-infix">
      <span class="mat-form-field-label-wrapper mat-input-placeholder-wrapper mat-form-field-placeholder-wrapper">
        <label class="mat-form-field-label mat-input-placeholder mat-form-field-placeholder">{{ label }}<span *ngIf="required" class="mat-placeholder-required mat-form-field-required-marker">&nbsp;*</span></label>
      </span>
    </div>
  </div>
</div>

<div #uploadContainer>
  <div class="upload-container">
    <div class="left">
      <button matTooltip="{{ file ? anotherTip : chooseTip }}" mat-accent mat-fab (click)="fileSelect(fileInput)"
              *ngIf="!isReadOnly && (isAutoUpload || !file)" class="select">
        <mat-icon>{{ preview ? 'add_a_photo' : 'cloud_upload' }}</mat-icon>
      </button>

      <button [matTooltip]="clearTip" mat-accent mat-fab (click)="fileClear(fileInput)"
              *ngIf="!isReadOnly && !isAutoUpload && file" class="clear">
        <mat-icon>undo</mat-icon>
      </button>

      <button [matTooltip]="sendTip" mat-accent mat-fab (click)="fileUpload(fileInput)"
              *ngIf="!isReadOnly && !isAutoUpload && file" class="send">
        <mat-icon>file_upload</mat-icon><!-- cloud_done -->
      </button>
    </div>

    <div class="left">
      <img *ngIf="displayPreview" #filePreview [src]="fileValue" (click)="zoomIn()" [class.preview]="true" alt="" />
    </div>

    <input #fileInput type="file" (change)="fileChange($event)" class="input" />
  </div>

  <div *ngIf="zoom" class="zoom-container" (click)="zoomOut()" [ngStyle]="{ 'top.px': top, 'left.px': left }">
    <img [src]="fileValue" alt="" class="zoom" [ngStyle]="{ 'margin-left.px': imgLeft, 'margin-top.px': imgTop }" />
  </div>
</div>
