<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content">
      <div class="pull-right align-down-table">
        <button type="button" class="btn btn-link" (click)="dt.exportCSV()"><i class="fas fa-file-excel excel"></i>
          Exportar para Excel</button>
      </div>
      <div class="clearfix"></div>
      <p-table class="table-system" #dt [columns]="colslista" [value]="lista" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false"
        selectionMode="false">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'data'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'data', 'contains')">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'doc'">
                <span class="text-overflow-dynamic-ellipsis">DOC <p-sortIcon [field]="'doc'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'doc', 'contains')">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'operacao', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vu'">
                <span class="text-overflow-dynamic-ellipsis">V.U. <p-sortIcon [field]="'vu'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'vu', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtdEntrada'">
                <span class="text-overflow-dynamic-ellipsis">QTDE. ENT. <p-sortIcon [field]="'qtdEntrada'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'qtdEntrada', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrEntrada'">
                <span class="text-overflow-dynamic-ellipsis">VL. ENT. <p-sortIcon [field]="'vlrEntrada'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'vlrEntrada', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtdSaida'">
                <span class="text-overflow-dynamic-ellipsis">QTDE. SAÍDA <p-sortIcon [field]="'qtdSaida'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'qtdSaida', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrSaida'">
                <span class="text-overflow-dynamic-ellipsis">VL. SAÍDA <p-sortIcon [field]="'vlrSaida'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'vlrSaida', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtdTotal'">
                <span class="text-overflow-dynamic-ellipsis">QTDE. TOTAL <p-sortIcon [field]="'qtdTotal'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'qtdTotal', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrTotal'">
                <span class="text-overflow-dynamic-ellipsis">VL. TOTAL <p-sortIcon [field]="'vlrTotal'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'vlrTotal', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstMedioDetail'">
                <span class="text-overflow-dynamic-ellipsis">CTO. MÉDIO <p-sortIcon [field]="'cstMedioDetail'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'cstMedioDetail', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.data }}">{{ rowData.data }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.doc}}">{{rowData.doc }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.operacao}}">{{rowData.operacao
                  }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vu}}">{{rowData.vu }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.qtdEntrada}}">{{rowData.qtdEntrada}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrEntrada}}">{{rowData.vlrEntrada}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.qtdSaida}}">{{rowData.qtdSaida}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrSaida}}">{{rowData.vlrSaida}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.qtdTotal}}">{{rowData.qtdTotal}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrTotal}}">{{rowData.vlrTotal}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.cstMedioDetail}}">{{rowData.cstMedioDetail}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="11" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>