<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <div [ngStyle]="{'display': 'block'}" [ngClass]="{'fade-in': true}">
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Certificados</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardTable()"
                  (click)="changeStateTable()">
                  <span *ngIf="isCardTable" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardTable" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateTable>
            <p-table class="table-system" #dt1 [columns]="colslistaCertificados" [value]="listaCertificados"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 55%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeFantasia'">
                      <span class="text-overflow-dynamic-ellipsis">NOME FANTASIA <p-sortIcon [field]="'nomeFantasia'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'nomeFantasia', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataExpiracao'">
                      <span class="text-overflow-dynamic-ellipsis">DATA EXPIRAÇÃO <p-sortIcon [field]="'dataExpiracao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dataExpiracao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataModificacao'">
                      <span class="text-overflow-dynamic-ellipsis">DATA ÚLTIMA MODIFICAÇÃO <p-sortIcon
                          [field]="'dataModificacao'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dataModificacao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">DISPARO AVISO</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">

                  <!-- NOME FANTASIA -->
                  <td class="text-left" style="width: 55%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onCertificadoRowSelect(rowData)'
                        matTooltip="{{rowData.nomeFantasia}}">{{rowData.nomeFantasia}}</span>
                    </span>
                  </td>

                  <!-- DATA EXPIRAÇÃO -->
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onCertificadoRowSelect(rowData)'
                        matTooltip="{{rowData.dataExpiracao | date:'dd/MM/yyyy'}}">{{rowData.dataExpiracao |
                        date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>

                  <!-- DATA ÚLTIMA MODIFICAÇÃO -->
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onCertificadoRowSelect(rowData)'
                        matTooltip="{{rowData.dataModificacao | date:'dd/MM/yyyy'}}">{{rowData.dataModificacao |
                        date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>

                  <!-- BOTÃO PARA DISPARAR E-MAIL DE AVISO -->
                  <td class="text-center" style="width: 15%">
                    <span matTooltip="Enviar e-mail com aviso sobre vencimento" class="edit"
                      (click)="dispararEmailAviso(rowData)">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum certificado encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 pb-4">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> Configurações do certificado </h3>
            <div class="actions-card">
              <button type="button" (click)="salvarVinculacaoUsuarios()" class="btn btn-xs btn-link btn-full"
                matTooltip="Salvar Alterações" matTooltipPosition="above">
                <span class="material-icons full-icon" style="font-size: 22px;">save</span>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardVinculacao()"
                (click)="changeStateVinculacao()">
                <span *ngIf="isCardVinculacao" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardVinculacao" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateVinculacao>
          <div class="row">
            <div class="col-sm-8">
              <div class="conteudo m-0">

                <div class="form-row">
                  <div class="form-group col-sm-12 mb-1 mt-1">
                    <label class="d-block">Importar certificado (extensão PFX)</label>
                    <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                      <span class="input-group-append">
                        <span class="input-group-text btn-file">
                          <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i>
                            Selecionar Arquivo</span>
                          <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i>
                            Selecionar Arquivo</span>
                          <input type="file" #file (change)="fileChange()" />
                        </span>
                      </span>
                      <div class="form-control" data-trigger="fileinput">
                        <span class="fileinput-filename"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Senha</label>
                    <input class="form-control" id="input" type="password" [(ngModel)]="password">
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Data Validade</label>
                    <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dataExpiracao">
                    </p-calendar>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Dias para disparo aviso</label>
                    <input class="form-control" id="input" type="text" [(ngModel)]="diasAviso">
                  </div>
                </div>

                <p-pickList sourceHeader="Usuários Disponíveis" targetHeader="Usuários Vinculados"
                  [source]="listaUsuariosDisponiveis" [target]="listaUsuariosVinculados" [dragdrop]="true"
                  [responsive]="true" filterBy="nome" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-usuario pTemplate="item">{{ usuario.nome }} {{ usuario.sobrenome }}
                  </ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>