<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div #formContainer>
    <div class=" box-content col-sm-6">
      <form *ngIf="formGroup" [formGroup]="formGroup" novalidate autocomplete="off">

        <div class="form-row" *ngIf="verif">
          <div class="form-group col-sm-12">
            <label> Usuário a ser editado</label>
            <p-dropdown class="mat-elevation-z0" [options]="usersAll" [ngModel]="usuario" >
            </p-dropdown>
  
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Nome</label>
            <input class="form-control" type="text" formControlName="displayName" minlength="3" maxlength="25">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Nome completo</label>
            <input class="form-control" type="text" formControlName="fullName" minlength="10" maxlength="60">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>CPF</label>
            <p-inputMask class="type-p-mask" formControlName="cpf" placeholder="999.999.999-99" mask="999.999.999-99">
            </p-inputMask>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Telefone</label>
            <p-inputMask class="type-p-mask" type="phone" formControlName="phone" placeholder="(99) 99999-9999"
              mask="(99) 99999-9999"></p-inputMask>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>E-mail</label>
            <input class="form-control" type="email" formControlName="mail" minlength="10" maxlength="60">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Departamento</label>
            <p-dropdown formControlName="departamento" class="mat-elevation-z0" [options]="departamentos" >
            </p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Gestor</label>
            <p-dropdown formControlName="gestor" class="mat-elevation-z0" [options]="gestores" >
            </p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Nível</label>
            <p-dropdown formControlName="nivel" class="mat-elevation-z0" [options]="niveis">
            </p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <mat-slide-toggle formControlName="visualizaLog">Visualiza Log do sistema</mat-slide-toggle>
          </div>
        </div>

        <div class="form-row">
          <div class="form-gourp col-sm-12">
            <label>Senha</label>
            <input class="form-control" type="password" formControlName="pass" minlength="6" maxlength="50">
            <mat-hint>Preencha apenas caso queira alterar a senha atual</mat-hint>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label *ngIf="displayConfirm">Confirmar senha</label>
            <input class="form-control" *ngIf="displayConfirm" [class.invalid]="!equalPasswords" type="password"
              formControlName="passConfirm" minlength="6" maxlength="50">
            <mat-hint *ngIf="!equalPasswords">Repita a senha utilizada no campo anterior</mat-hint>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <!-- <button mat-raised-button class="mat-elevation-z0" (click)="formGroup.reset();" >Cancelar</button>
                     <button class="botao-excluir" type="button" pButton label="Excluir"></button> -->
            <button class="btn btn-primary" type="button" (click)="save()" [disabled]="!formGroup.valid"><i
                class="fas fa-check"></i> Salvar</button>
          </div>
        </div>

      </form>
    </div>
  </div>
</mat-card>