<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>

    <div class="form-row">


      <div class="form-group col-sm-2"><label>TERMO PESQUISA</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="false" [(ngModel)]="termoPesquisa"
          [options]="opcoesTermo"></p-dropdown>
      </div>
      <div class="form-group col-sm-3"><label>PESQUISAR</label><input class="form-control" maxlength="50" type="text"
          [(ngModel)]="buscaNfe" /></div>
      <div class="d-flex align-items-center">
        <button class="btn btn-sm btn-info" (click)="pesquisarNfe()" [disabled]="isReadOnly">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div class="row" *ngIf="!exibirNf && !escritNf">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">

            <div class="header-box-content p-3">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: statusPendente }">
                  <a (click)="showTelaNotasFiscais('TELA_1')"> Pendente ({{ xmlPendente.length }}) </a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusEscriturada }">
                  <a (click)="showTelaNotasFiscais('TELA_2')"> Escriturada ({{ xmlEscriturado.length }}) </a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusCancelada }">
                  <a (click)="showTelaNotasFiscais('TELA_3')"> Cancelada ({{ xmlCancelado.length }}) </a>
                </li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentStateNotasFiscais">
            <div [ngStyle]="statusPendente ? { display: 'block' } : { display: 'none' }"
              [ngClass]="{ 'fade-in': 'statusPendente' }">
              <p-table class="table-system" #dt20 (onFilter)="onFilter($event)" [value]="xmlPendente"
                [totalRecords]="xmlPendente.length" [rows]="rows" [pageLinks]="pageLinks" appendTo="body"
                [paginator]="true" selectionMode="single" [style]="{ width: '100%' }" [rows]="rows"
                [pageLinks]="pageLinks" (onRowSelect)="onTableRowSelect($event)" [(selection)]="xmlSelecionados"
                (onRowUnselect)="onTableRowUnselect($event)" (onHeaderCheckboxToggle)="onTableselectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA <p-sortIcon field="emissao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueData"
                        (input)="dt20.filter(filterValueData, 'emissao', 'contains')" />
                    </th>
                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emitente">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME <p-sortIcon field="emitente"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNome"
                        (input)="dt20.filter(filterValueNome, 'emitente', 'contains')" />
                    </th>
                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="cnpj">
                        <span class="text-overflow-dynamic-ellipsis">
                          CNPJ <p-sortIcon field="cnpj"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpj"
                        (input)="dt20.filter(filterValueCnpj, 'cnpj', 'contains')" />
                    </th>
                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="numNf">
                        <span class="text-overflow-dynamic-ellipsis">
                          NÚMERO DOC <p-sortIcon field="numNf"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumeroDoc"
                        (input)="dt20.filter(filterValueNumeroDoc, 'numNf', 'contains')" />
                    </th>
                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="modelo">
                        <span class="text-overflow-dynamic-ellipsis">
                          Modelo <p-sortIcon field="modelo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueModelo"
                        (input)="dt20.filter(filterValueModelo, 'modelo', 'contains')" />
                    </th>
                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR <p-sortIcon field="valor"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor"
                        (input)="dt20.filter(filterValueValor, 'valor', 'contains')" />
                    </th>
                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="status">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS <p-sortIcon field="status"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueStatus"
                        (input)="dt20.filter(filterValueStatus, 'status', 'contains')" />
                    </th>
                    <th class="text-center" style="width: 5%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="vinculado">
                        <span class="text-overflow-dynamic-ellipsis">
                          VINCULADO <p-sortIcon field="vinculado"></p-sortIcon>
                        </span>
                      </span>
                      <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                        [options]="vinculadoOpt" defaultLabel="Selecione" [filter]="false"
                        (onChange)="dt20.filter($event.value, 'vinculado', 'equals')">
                      </p-multiSelect>
                    </th>
                    <th class="text-center" style="width: 5%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pronto">
                        <span class="text-overflow-dynamic-ellipsis">
                          PRONTO <p-sortIcon field="pronto"></p-sortIcon>
                        </span>
                      </span>
                      <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                        [options]="vinculadoOpt" defaultLabel="Selecione" [filter]="false"
                        (onChange)="dt20.filter($event.value, 'pronto', 'equals')">
                      </p-multiSelect>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.emissao }}">
                          {{ rowData.emissao }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.emitente }}">
                          {{ rowData.emitente }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.cnpj }}">
                          {{ rowData.cnpj }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.numNf }}">
                          {{ rowData.numNf }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.modelo }}">
                          {{ rowData.modelo }}
                        </span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.valor }}">
                          R$ {{ rowData.valor }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.status }}">
                          {{ rowData.status }}
                        </span>
                      </span>
                      <span class="action-fade" matTooltip="Vizualizar Pedidos" (click)="visualizarPedidos(rowData)">
                        <i class="fas fa-search"></i>
                      </span>
                    </td>
                    <td class="text-center" style="width: 5%">
                      <i *ngIf="!rowData.vinculado" class="fas fa-circle" style="color: yellow;"></i>
                      <i *ngIf="rowData.vinculado" class="fas fa-circle" style="color: green;"></i>
                    </td>
                    <td class="text-center" style="width: 5%">
                      <i *ngIf="!rowData.pronto" class="fas fa-circle" style="color: yellow;"></i>
                      <i *ngIf="rowData.pronto" class="fas fa-circle" style="color: green;"></i>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="10" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusEscriturada ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusEscriturada' }">
              <p-table class="table-system" #dt21 (onFilter)="onFilter($event)" [value]="xmlEscriturado" [rows]="rows"
                [pageLinks]="pageLinks" appendTo="body" [paginator]="true" [totalRecords]="xmlEscriturado.length"
                [style]=" { width: '100%' }" (onRowSelect)="onTableRowSelect($event)"
                (onRowUnselect)="onTableRowUnselect($event)" (onHeaderCheckboxToggle)="onTableselectAll($event)"
                selectionMode="single">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                        <span class="text-overflow-dynamic-ellipsis">
                          EMISSÃO <p-sortIcon field="emissao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueEmissao" (input)="
                          dt21.filter(filterValueEmissao, 'emissao', 'contains')
                        " />
                    </th>

                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="entrada">
                        <span class="text-overflow-dynamic-ellipsis">
                          ENTRADA <p-sortIcon field="entrada"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueEntrada" (input)="
                          dt21.filter(filterValueEntrada, 'entrada', 'contains')
                        " />
                    </th>

                    <th class="text-left" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emitente">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME <p-sortIcon field="emitente"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNome2" (input)="
                          dt21.filter(filterValueNome2, 'emitente', 'contains')
                        " />
                    </th>

                    <th class="text-left" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="cnpj">
                        <span class="text-overflow-dynamic-ellipsis">
                          CNPJ <p-sortIcon field="cnpj"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpj2" (input)="
                          dt21.filter(filterValueCnpj2, 'cnpj', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          COD CONTROLE
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCodigoControle"
                        (input)="
                          dt21.filter(
                            filterValueCodigoControle,
                            'codigoControle',
                            'contains'
                          )
                        " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoPedido">
                        <span class="text-overflow-dynamic-ellipsis">
                          PEDIDO
                          <p-sortIcon field="codigoPedido"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValuePedido" (input)="
                          dt21.filter(
                            filterValuePedido,
                            'codigoPedido',
                            'contains'
                          )
                        " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="numNf">
                        <span class="text-overflow-dynamic-ellipsis">
                          NÚMERO NF <p-sortIcon field="numNf"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumeroNf" (input)="
                          dt21.filter(filterValueNumeroNf, 'numNf', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 13%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="modelo">
                        <span class="text-overflow-dynamic-ellipsis">
                          Modelo <p-sortIcon field="modelo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueModelo2" (input)="
                          dt21.filter(filterValueModelo2, 'modelo', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR <p-sortIcon field="valor"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor2" (input)="
                          dt21.filter(filterValueValor2, 'valor', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="status">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS <p-sortIcon field="status"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueStatus2" (input)="
                          dt21.filter(filterValueStatus2, 'status', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 4%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'contQtde'">
                        <span class="text-overflow-dynamic-ellipsis">Cont <p-sortIcon
                            [field]="'contQtde'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt21.filter($event.target.value, 'contQtde', 'contains')">
                    </th>

                    <th class="text-center" style="width: 4%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'estQtde'">
                        <span class="text-overflow-dynamic-ellipsis">Estoque <p-sortIcon
                            [field]="'estQtde'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt21.filter($event.target.value, 'estQtde', 'contains')">
                    </th>

                    <th class="text-center" style="width: 4%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'finQtde'">
                        <span class="text-overflow-dynamic-ellipsis">Financeiro <p-sortIcon
                            [field]="'finQtde'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt21.filter($event.target.value, 'finQtde', 'contains')">
                    </th>


                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.emissao }}">
                          {{ rowData.emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.entrada }}">
                          {{ rowData.entrada }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.emitente }}">
                          {{ rowData.emitente }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.cnpj }}">
                          {{ rowData.cnpj }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip=" {{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip=" {{ rowData.codigoPedido }}">
                          {{ rowData.codigoPedido }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.numNf }}">
                          {{ rowData.numNf }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.modelo }}">
                          {{ rowData.modelo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.valor }}">
                          R$ {{ rowData.valor }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="escriturarNota(rowData)"
                          matTooltip="{{ rowData.status }}">
                          {{ rowData.status }}
                        </span>
                      </span>
                    </td>

                    <td style="width: 5%;" class="text-center">

                      <i matTooltip="NF está lançada na Contabilidade" *ngIf="rowData.contQtde === 'SIM'"
                        class="fas fa-circle" style="color: green;"></i>
                      <i matTooltip="NF não está lançada na Contabilidade" *ngIf="rowData.contQtde !== 'SIM'"
                        class="fas fa-circle" style="color: red;"></i>

                    </td>

                    <td style="width: 5%;" class="text-center">

                      <i matTooltip="NF está lançada no Estoque" *ngIf="rowData.estQtde === 'SIM'" class="fas fa-circle"
                        style="color: green;"></i>
                      <i matTooltip="NF não está lançada no Estoque" *ngIf="rowData.estQtde !== 'SIM'"
                        class="fas fa-circle" style="color: red;"></i>

                    </td>

                    <td style="width: 5%;" class="text-center">

                      <i matTooltip="NF está lançada no Financeiro" *ngIf="rowData.finQtde === 'SIM'"
                        class="fas fa-circle" style="color: green;"></i>
                      <i matTooltip="NF não está lançada no Financeiro" *ngIf="rowData.finQtde !== 'SIM'"
                        class="fas fa-circle" style="color: red;"></i>

                    </td>

                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="11" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusCancelada ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusCancelada' }">
              <p-table class="table-system" #dt22 [value]="xmlCancelado" [rows]="rows" [pageLinks]="pageLinks"
                appendTo="body" [paginator]="true" [totalRecords]="xmlCancelado.length" selectionMode="single"
                [style]="{ width: '100%' }" appendTo="body" [paginator]="true" (onRowSelect)="onTableRowSelect($event)"
                (onRowUnselect)="onTableRowUnselect($event)" (onHeaderCheckboxToggle)="onTableselectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA <p-sortIcon field="emissao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueData3" (input)="
                          dt22.filter(filterValueData3, 'emissao', 'contains')
                        " />
                    </th>

                    <th class="text-left" style="width: 30%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emitente">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME <p-sortIcon field="emitente"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNome3" (input)="
                          dt22.filter(filterValueNome3, 'emitente', 'contains')
                        " />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="cnpj">
                        <span class="text-overflow-dynamic-ellipsis">
                          CNPJ <p-sortIcon field="cnpj"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpj3" (input)="
                          dt22.filter(filterValueCnpj3, 'cnpj', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="numNf">
                        <span class="text-overflow-dynamic-ellipsis">
                          NÚMERO NF <p-sortIcon field="numNf"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumeroNf3"
                        (input)="
                          dt22.filter(filterValueNumeroNf3, 'numNf', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR <p-sortIcon field="valor"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor3" (input)="
                          dt22.filter(filterValueValor3, 'valor', 'contains')
                        " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="status">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS <p-sortIcon field="status"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueStatus3" (input)="
                          dt22.filter(filterValueStatus3, 'status', 'contains')
                        " />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.emissao }}">
                          {{ rowData.emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 30%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.emitente }}">
                          {{ rowData.emitente }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cnpj }}">
                          {{ rowData.cnpj }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.numNf }}">
                          {{ rowData.numNf }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 15%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valor }}">
                          R$ {{ rowData.valor }}</span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="Cancelada">Cancelada</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="7" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="exibirNf && !escritNf">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: false }">
                  <a (click)="showTelaNotasFiscais('TELA_1')">ESCRITURAÇÃO DE NF</a>
                </li>
              </ul>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="realizarImpressao(pedido.id)"
                  matTooltip="Impressão Pedido" matTooltipPosition="above">
                  <span class="material-icons print-icon">print</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="visualizarDanfeId(nf.nfeId)"
                  matTooltip="Impressão DANFE" matTooltipPosition="above">
                  <i class="far fa-file-pdf pdf"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeNotasFiscais()"
                  (click)="changeStateNotasFiscais()">
                  <span *ngIf="isCardNotasFiscais" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_more</span>
                  <span *ngIf="!isCardNotasFiscais" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_less</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]="currentStateNotasFiscais">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="title-page mt-0">
                  {{ nf.emitente }} - {{ nf.cnpj }}
                </h4>
                <h5 class="sub-title-page mb-3">
                  <span class="info-title-page">{{ nf.endereco }}</span>
                </h5>
              </div>
            </div>

            <div class="form-row align-items-end">
              <div class="form-group col-sm-1" *ngIf="pedido.operacaoRegra">
                <label>PEDIDO</label>
                <input class="form-control" type="text" input-text="left" [(ngModel)]="pedido.codigo" pInputText
                  readOnly />
              </div>
              <div class="form-group col-sm-1" *ngIf="pedido.codigoControle">
                <label class="texto-bold">COD CONTROLE</label>
                <input class="form-control" type="text" input-text="left" [(ngModel)]="pedido.codigoControle" pInputText
                  readOnly />
              </div>

              <div class="form-group col-sm-2" *ngIf="pedido.pedidoStatus">
                <label class="texto-bold">STATUS</label>
                <input class="form-control" type="text" input-text="left" [(ngModel)]="pedido.pedidoStatus.description"
                  pInputText readOnly />
              </div>
              <div class="form-group col-sm-4">
                <label>MODELO</label>
                <input class="form-control" type="text" input-text="left" [(ngModel)]="nf.modelo" pInputText readOnly />
              </div>
              <div class="form-group col-sm-1">
                <label>SÉRIE</label>
                <input class="form-control" type="text" input-text="left" [(ngModel)]="nf.series" pInputText readOnly />
              </div>
              <div class="form-group col-sm-1">
                <div class="input-12" input-text="left" input-vertical="middle">
                  <label>NOTA FISCAL</label>
                  <input class="form-control" type="text" input-text="left" [(ngModel)]="nf.numNf" pInputText
                    readOnly />
                </div>
              </div>
              <div class="form-group col-sm-1">
                <label>DATA EMISSÃO</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="nf.emissao" [disabled]="true"></p-calendar>
              </div>
              <div class="form-group col-sm-1">
                <label>DATA ENTRADA</label>
                <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                  [(ngModel)]="dtEntrada" dateFormat="dd/mm/yy"></p-calendar>
              </div>
            </div>

            <div class="" *ngIf="escrituracaoFiscal.length > 0">
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title">
                    <i class="fas fa-bars"></i> ITENS DA ESCRITURAÇÃO
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <p-table class="table-system" [value]="escrituracaoFiscal" selectionMode="single"
                    (onRowSelect)="onRowSelect($event)">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 25%">ITEM</th>
                        <th style="width: 25%">OPERAÇÃO</th>
                        <th style="width: 5%">CFOP</th>
                        <th class="text-right" style="width: 5%">QTD</th>
                        <th class="text-right" style="width: 10%">
                          VALOR UNIT
                        </th>
                        <th class="text-right" style="width: 10%">
                          VALOR PRODUTO
                        </th>
                        <th class="text-right" style="width: 10%">
                          VALOR DESC
                        </th>
                        <th class="text-right" style="width: 10%">
                          VALOR TOTAL
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData" style="height: 25px">
                        <td>
                          <span class="overflow-ellipse" matTooltip="{{ rowData.descrItem }}">
                            <span style="user-select: all; cursor: text">{{
                              rowData.codItem
                              }}</span></span>
                        </td>
                        <td>
                          <span class="overflow-ellipse" matTooltip="{{ rowData.codOper }}">
                            <span style="user-select: all; cursor: text">{{
                              rowData.codOper
                              }}</span></span>
                        </td>
                        <td>
                          <span style="user-select: all; cursor: text">{{
                            rowData.cfop
                            }}</span>
                        </td>
                        <td class="text-right">
                          <span style="user-select: all; cursor: text">{{
                            rowData.qtde
                            }}</span>
                        </td>
                        <td class="text-right">
                          <span style="user-select: all; cursor: text">{{
                            rowData.vlUnit | money
                            }}</span>
                        </td>
                        <td class="text-right">
                          <span style="user-select: all; cursor: text">{{
                            rowData.vlProd | money
                            }}</span>
                        </td>
                        <td class="text-right">
                          <span style="user-select: all; cursor: text">{{
                            rowData.vlDesc | money
                            }}</span>
                        </td>
                        <td class="text-right">
                          <span style="user-select: all; cursor: text">{{
                            rowData.vlTot | money
                            }}</span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title">
                  <i class="fas fa-info-circle"></i> INFORMAÇÕES GERAIS
                </h3>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-row align-items-end" *ngIf="
                    pedido.pedidoTipo.id === 3 || pedido.pedidoTipo.id === 1
                  ">
                  <div class="form-group col">
                    <label>BASE CALC ICMS</label>
                    <input class="form-control" type="text" input-text="right" #baseCalcIcms name="baseCalcIcms"
                      [(ngModel)]="vlBcIcms" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. ICMS</label>
                    <input class="form-control" type="text" input-text="right" #valorIcms name="valorIcms"
                      [(ngModel)]="vlIcms" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>BASE CALC ICMS ST</label>
                    <input class="form-control" type="text" input-text="right" #valorIcmsSt name="valorIcmsSt"
                      [(ngModel)]="vlBcIcmsSt" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. ICMS SUBST</label>
                    <input class="form-control" type="text" input-text="right" #valorIcmsSubs name="valorIcmsSubs"
                      [(ngModel)]="vlIcmsSt" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>ICMS UF REMETENTE</label>
                    <input class="form-control" type="text" input-text="right" #valorIcmsUfRemt name="valorIcmsUfRemt"
                      [(ngModel)]="vlIcmsUfRemetente" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>ICMS UF DESTINO</label>
                    <input class="form-control" type="text" input-text="right" #valorIcmsDest name="valorIcmsDest"
                      [(ngModel)]="vlIcmsUfDestino" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. FCP</label>
                    <input class="form-control" type="text" input-text="right" #valorFcp name="valorFcp"
                      [(ngModel)]="vlFcp" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. IPI</label>
                    <input class="form-control" type="text" input-text="right" #valorIpi name="valorIpi"
                      [(ngModel)]="vlIpi" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. PRODUTOS</label>
                    <input class="form-control" type="text" input-text="right" [(ngModel)]="vlProd" money
                      disabled="disabled" />
                  </div>
                </div>
                <div class="form-row align-items-end" *ngIf="
                    pedido.pedidoTipo.id === 3 || pedido.pedidoTipo.id === 1
                  ">
                  <div class="form-group col">
                    <label>VL. FRETE</label>
                    <input class="form-control" type="text" input-text="right" #valorFrete name="valorFrete"
                      [(ngModel)]="vlFrete" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. SEGURO</label>
                    <input class="form-control" type="text" input-text="right" #valorSeguro name="valorSeguro"
                      [(ngModel)]="vlSeguro" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. DESCONTO</label>
                    <input class="form-control" type="text" input-text="right" #desconto name="desconto"
                      [(ngModel)]="vlDesc" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>OUTRAS DESPESAS</label>
                    <input class="form-control" type="text" input-text="right" #valorOutrasDespesas
                      name="valorOutrasDespesas" [(ngModel)]="vlOutrasDesp" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>IMPOSTO IMPORTAÇÃO</label>
                    <input class="form-control" type="text" input-text="right" #valorImpostoImportacao
                      name="valorImpostoImportacao" [(ngModel)]="vlImpostoImportacao" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. TRIBUTOS</label>
                    <input class="form-control" type="text" input-text="right" #valorTotalTributos
                      name="valorTotalTributos" [(ngModel)]="vlTributos" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. COFINS</label>
                    <input class="form-control" type="text" input-text="right" #valorCofins name="valorCofins"
                      [(ngModel)]="vlCofins" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. PIS</label>
                    <input class="form-control" type="text" input-text="right" #valorPis name="valorPis"
                      [(ngModel)]="vlPis" money disabled="disabled" />
                  </div>
                  <div class="form-group col">
                    <label>VL. TOTAL</label>
                    <input class="form-control" type="text" input-text="right" name="vlTotal" [(ngModel)]="vlTotal"
                      money disabled="disabled" />
                  </div>
                </div>

                <!-- 1a. linhas das Gerais -->
                <div class="form-row align-items-end" *ngIf="
                    pedido.pedidoTipo.id === 4 || pedido.pedidoTipo.id === 5
                  ">
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR INSS (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR INSS ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR INSS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorInss" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR IRRF (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR IRRF ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR IRRF</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIr" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR CSLL (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR CSLL ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR CSLL</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCsll" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR PIS (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR PIS ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR PIS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorPis" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR COFINS (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR COFINS ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR COFINS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCofins" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR ISS (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR ISS ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR ISS</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIssRetido" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">OUTRAS RETENÇÕES (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">OUTRAS RETENÇÕES ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">OUTRAS RETENÇÕES</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorOutrasRetencoes" money
                      (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
                  </div>
                </div>

                <!-- 2a. linhas das Gerais -->
                <div class="form-row align-items-end" *ngIf="
                    pedido.pedidoTipo.id === 4 || pedido.pedidoTipo.id === 5
                  ">
                  <div class="form-group col"></div>
                  <div class="form-group col"></div>
                  <div class="form-group col"></div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">SUBTOTAL (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">SUBTOTAL ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">SUBTOTAL</label>
                    <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
                      (ngModelChange)="atualizarSubtotal()" [(ngModel)]="subTotalPedido" money />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR DESCONTO (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR DESCONTO ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR DESCONTO</label>
                    <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
                      (ngModelChange)="atualizarSubtotal()" [(ngModel)]="valorDescontoItens" money />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">IMPOSTOS RET. (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">IMPOSTOS RET. ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">IMPOSTOS RET.</label>
                    <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
                      (ngModelChange)="atualizarSubtotal()" [(ngModel)]="valorImpostosRetidos" money />
                  </div>
                  <div class="form-group col">
                    <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR TOTAL (BRL)</label>
                    <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR TOTAL ({{ codigoMoeda }})</label>
                    <label *ngIf="!pedidoExterior">VALOR TOTAL</label>
                    <input class="form-control text-right" type="text" [(ngModel)]="valorTotalPedido" money />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="exibirNf">
      <div class="col-sm-12 pt-2">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: aba1 }">
                  <a (click)="showTela('1')">FINANCEIRO</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: aba2 }">
                  <a (click)="showTela('2')">GRADE CONTÁBIL</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: aba3 }">
                  <a (click)="showTela('3')">GRADE FISCAL</a>
                </li>
                <li class="list-inline-item" *ngIf="!this.isModeloNFS" [ngClass]="{ active: aba4 }">
                  <a (click)="showTela('4')">ITENS FORNECEDOR</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: aba5 }">
                  <a (click)="showTela('5')">DADOS ADICIONAIS</a>
                </li>
              </ul>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeInfoFiscais()"
                  (click)="changeStateInfoFiscais()">
                  <span *ngIf="isCardInfoFiscais" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_more</span>
                  <span *ngIf="!isCardInfoFiscais" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_less</span>
                </button>
              </div>
            </div>
          </div>

          <div [ngStyle]="aba1 ? { display: 'block' } : { display: 'none' }" [ngClass]="{ 'fade-in': 'aba1' }">
            <div class="box-content pt-2" [@changeDivSize]="currentStateInfoFiscais">


              <div class="form-row" *ngIf="!visualizarEscrituracao">
                <div class="form-group col-sm-3">
                  <label>TIPO DE PAGAMENTO <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.tipoPagamento"
                    [options]="opcoesTipoPagamento" (onChange)="pagamentoTipo()" appendTo="body"></p-dropdown>
                </div>
                <div class="form-group col-sm-2" *ngIf="pedido.tipoPagamento === 1">
                  <label>FORMA DE PAGAMENTO <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.formaPagamento.id"
                    [options]="opcoesFormaPagamento" (onChange)="infosFormaPagto(pedido.formaPagamento.id)"
                    appendTo="body">
                  </p-dropdown>
                </div>
                <div class="form-group col-sm-3" *ngIf="pedido.tipoPagamento === 1 && pedido.formaPagamento.id">
                  <label>CONTA <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.empresaConta.id"
                    [options]="opcoesContas" (onChange)="pagamentoForma()" appendTo="body"></p-dropdown>
                </div>
                <div class="form-group col-sm-4"
                  *ngIf="pedido.tipoPagamento === 1 && pedido.formaPagamento.id && pedido.empresaConta.id && !isReadOnly">
                  <label class="d-block" style="visibility: hidden">DO NOT REMOVE</label>
                  <button type="button" class="btn btn-sm btn-info" (click)="modalFormaPgto = true">
                    <i class="fas fa-plus"></i> Adicionar
                  </button>
                  <button type="button" class="btn btn-sm btn-info" (click)="pagamentoForma(true)">
                    <i class="fas fa-refresh"></i> Atualizar
                  </button>
                </div>
              </div>

              <!-- 2a. linha pagamento -->
              <div class="row">
                <div class="col-sm-12 mb-3" *ngIf="pedido.pedidoParcela">
                  <div *ngIf="pedido.pedidoParcela.length > 0">
                    <p-table class="table-system" [value]="pedido.pedidoParcela" selectionMode="single"
                      (onRowSelect)="modalFormaPgto = true" [scrollable]="true" scrollHeight="330px">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-center" style="width: 10%">Parcela</th>
                          <th class="text-center" style="width: 12%">Vencimento</th>
                          <th class="text-right" style="width: 12%" *ngIf="pedido.converterTaxa && pedidoExterior">
                            Valor (BRL)
                          </th>
                          <th class="text-right" style="width: 12%" *ngIf="!pedido.converterTaxa && pedidoExterior">
                            Valor ({{ codigoMoeda }})
                          </th>
                          <th class="text-right" style="width: 12%" *ngIf="!pedidoExterior">
                            Valor
                          </th>
                          <th class="text-center" style="width: 12%">Conta</th>
                          <th class="text-left" style="width: 12%">Tipo baixa</th>
                          <th class="text-left" style="width: 12%">Tipo Pagamento</th>
                          <th class="text-left" style="width: 30%">Histórico</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                        <tr *ngIf="!isReadOnly" [pSelectableRow]="rowData">
                          <td class="text-center" style="width: 10%">
                            {{ rowData.parcela }}
                          </td>
                          <td class="text-center" style="width: 12%">
                            {{ rowData.dtVencimento | date: "dd/MM/yyyy" }}
                          </td>
                          <td class="text-right" style="width: 12%">
                            {{ rowData.valor | money }}
                          </td>
                          <td class="text-center" style="width: 12%">
                            {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                          </td>
                          <td class="text-left" style="width: 12%">
                            <span class="overflow-ellipse"
                              matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                              {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                            </span>
                          </td>
                          <td class="text-left" style="width: 12%">
                            <span class="overflow-ellipse"
                              matTooltip="{{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}">
                              {{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}
                            </span>
                          </td>
                          <td class="text-left" style="width: 30%">
                            <span class="overflow-ellipse" matTooltip="{{ rowData.historico }}">{{ rowData.historico
                              }}</span>
                          </td>
                        </tr>
                        <tr *ngIf="isReadOnly">
                          <td class="text-center" style="width: 10%">
                            {{ rowData.parcela }}
                          </td>
                          <td class="text-center" style="width: 12%">
                            {{ rowData.dtVencimento | timezone | date: "dd/MM/yyyy" }}
                          </td>
                          <td class="text-right" style="width: 12%">
                            {{ rowData.valor | money }}
                          </td>
                          <td class="text-center" style="width: 12%">
                            {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                          </td>
                          <td class="text-left" style="width: 12%">
                            <span class="overflow-ellipse"
                              matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                              {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                            </span>
                          </td>
                          <td class="text-left" style="width: 12%">
                            <span class="overflow-ellipse"
                              matTooltip="{{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}">
                              {{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}
                            </span>
                          </td>
                          <td class="text-left" style="width: 30%">
                            <span class="overflow-ellipse" matTooltip="{{ rowData.historico }}">{{ rowData.historico
                              }}</span>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-columns>
                        <tr style="height: 25px">
                          <td [attr.colspan]="7" class="spaced-alert">
                            <div class="alert alert-xs alert-primary d-inline">
                              <i class="fas fa-info-circle"></i> Sem forma pagamento selecionada
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="pedido.tipoPagamento === 2">
                <div class="col-sm-12 mb-3" *ngIf="adtosRelacionadosPedido">
                  <div *ngIf="adtosRelacionadosPedido.length">
                    <p-table class="table-system" [value]="adtosRelacionadosPedido" selectionMode="single"
                      (onRowSelect)="adicionarCompensacao()" [scrollable]="true" scrollHeight="320px">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-center" style="width: 10%">Data</th>
                          <th class="text-left" style="width: 30%">Participante</th>
                          <th class="text-left" style="width: 30%">Histórico</th>
                          <th class="text-right" style="width: 10%">Valor</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData">
                          <td class="text-center" style="width: 10%">
                            {{ rowData.data }}
                          </td>
                          <td class="text-left" style="width: 30%">
                            {{ rowData.nomePart }}
                          </td>
                          <td class="text-left" style="width: 30%">
                            {{ rowData.descricao }}
                          </td>
                          <td class="text-right" style="width: 10%">
                            {{ rowData.compensar | money }}
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-columns>
                        <tr style="height: 25px">
                          <td [attr.colspan]="3">Sem forma pagamento selecionada</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
              </div>



              <!--<div class="row">
                <div class="col-sm-12">
                  <div *ngIf="valorTotalPedido > 0 && !visualizarEscrituracao">
                    <div class="form-row" *ngIf="pedido.formaPagamento">
                      <div class="form-group col-sm-6">
                        <div class="lks-form-grid">
                          <div class="input-12" input-text="left" input-vertical="middle">
                            <label class="texto-bold">FORMA DE PAGAMENTO</label>
                            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesFormaPagamento"
                              placeholder="Selecione a Forma de Pagamento" [(ngModel)]="pedido.formaPagamento.id"
                              [disabled]="
                                pedido.pedidoStatus.id === 2 ||
                                pedido.pedidoStatus.id === 3
                              "></p-dropdown>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-sm-6" *ngIf="pedido.empresaConta">
                        <div class="lks-form-grid">
                          <div class="input-12" input-text="left" input-vertical="middle">
                            <label class="texto-bold">CONTA</label>
                            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
                              placeholder="Selecione a Conta" [(ngModel)]="pedido.empresaConta.id" #empresaConta
                              name="empresaConta"></p-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="pedido.pedidoParcela">
                    <div *ngIf="pedido.pedidoParcela.length > 0">
                      <div class="pull-left separar-parcela" *ngFor="let pp of pedido.pedidoParcela">
                        <table class="table table-pay">
                          <tbody>
                            <tr>
                              <td><strong>Conta:</strong></td>
                              <td class="text-right">
                                {{ contaSelecionada }}
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Parcela:</strong></td>
                              <td class="text-right">
                                {{ pp.parcela }}
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Vencimento:</strong></td>
                              <td class="edit-date text-right" (click)="editarVencimento(pp)">
                                <span matTooltip="Editar Data de Vencimento">{{
                                  pp.dtVencimento
                                  | date: "dd/MM/yyyy"
                                  }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Valor:</strong></td>
                              <td class="text-right">
                                {{ pp.valor | money }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
            </div>
          </div>

          <div [ngStyle]="aba2 ? { display: 'block' } : { display: 'none' }" class="pull-left pr-2">
            <div class="box-content pt-2" [@changeDivSize]="currentStateInfoFiscais">
              <div class="row">
                <div class="col-sm-12">
                  <p-table class="table-system" [value]="escrituracaoContabil" selectionMode="single"
                    [paginator]="false" [scrollable]="true" scrollHeight="300px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 10%" class="text-center" *ngIf="visualizarEscrituracao">
                          DATA
                        </th>
                        <th style="width: 10%" *ngIf="visualizarEscrituracao">
                          NÚMERO
                        </th>
                        <th style="width: 10%" *ngIf="visualizarEscrituracao">
                          LOTE
                        </th>
                        <th style="width: 20%">CONTA</th>
                        <th style="width: 5%" class="text-center">D/C</th>
                        <th style="width: 10%" class="text-right">VALOR</th>
                        <th style="width: 20%">CENTRO CUSTO</th>
                        <th style="width: 15%">PROJETO</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td style="width: 10%" class="text-center" *ngIf="visualizarEscrituracao">
                          {{ rowData.data | timezone | date: "dd/MM/yyyy" }}
                        </td>
                        <td style="width: 10%" *ngIf="visualizarEscrituracao">
                          {{ rowData.numero }}
                        </td>
                        <td style="width: 10%" *ngIf="visualizarEscrituracao">
                          <span matTooltip="{{ rowData.lote }}" class="overflow-ellipse">{{ rowData.lote }}</span>
                        </td>
                        <td style="width: 20%">
                          <span matTooltip="{{ rowData.codCta }} - {{
                              rowData.descrCta
                            }}" class="overflow-ellipse">{{ rowData.codCta }} - {{ rowData.descrCta }}</span>
                        </td>
                        <td style="width: 5%" class="text-center">
                          {{ rowData.indDc }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.valor | money }}
                        </td>
                        <td style="width: 20%">
                          <span matTooltip="{{ rowData.codCcus }} - {{
                              rowData.descrCcus
                            }}" class="overflow-ellipse">{{ rowData.codCcus }} -
                            {{ rowData.descrCcus }}</span>
                        </td>
                        <td style="width: 15%">
                          <span matTooltip="{{ rowData.codProj }} - {{
                              rowData.descrProj
                            }}" class="overflow-ellipse">{{ rowData.codProj }} -
                            {{ rowData.descrProj }}</span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr style="height: 25px">
                        <td [attr.colspan]="8" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Sem Itens!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>

          <div [ngStyle]="aba3 ? { display: 'block' } : { display: 'none' }" class="pull-left pr-2">
            <div class="box-content pt-2" [@changeDivSize]="currentStateInfoFiscais">
              <div class="row" *ngIf="!isModeloNFS">
                <div class="col-sm-12">
                  <p-table class="table-system" [value]="gradeFiscal" selectionMode="single" [paginator]="false"
                    [scrollable]="true" scrollHeight="300px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 10%">CFOP</th>
                        <th style="width: 10%">CST ICMS</th>
                        <th style="width: 10%">VALOR</th>
                        <th style="width: 10%">BASE ICMS</th>
                        <th style="width: 10%">ALIQ. ICMS</th>
                        <th style="width: 10%">VL. ICMS</th>
                        <th style="width: 12%">BASE ICMS ST</th>
                        <th style="width: 12%">ALIQ. ICMS ST</th>
                        <th style="width: 11%">VL. ICMS ST</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td style="width: 10%">
                          {{ rowData.cfop }}
                        </td>
                        <td style="width: 10%">
                          {{ rowData.cstIcms }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.valor | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlBcIcms | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.aliqIcms | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlIcms | money }}
                        </td>
                        <td style="width: 12%" class="text-right">
                          {{ rowData.vlBcIcmsSt | money }}
                        </td>
                        <td style="width: 12%" class="text-right">
                          {{ rowData.aliqIcmsSt | money }}
                        </td>
                        <td style="width: 11%" class="text-right">
                          {{ rowData.vlIcmsSt | money }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr style="height: 25px">
                        <td [attr.colspan]="9" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Sem Itens!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>

              <div class="row" *ngIf="isModeloNFS">
                <div class="col-sm-12">
                  <p-table class="table-system" [value]="gradeFiscal" selectionMode="single" [paginator]="false"
                    [scrollable]="true" scrollHeight="300px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 10%">CFOP</th>
                        <th style="width: 10%">VALOR</th>
                        <th style="width: 10%">VL ISS RET</th>
                        <th style="width: 10%">VL PIS RET</th>
                        <th style="width: 10%">VL COF RET</th>
                        <th style="width: 10%">VL CSLL RET</th>
                        <th style="width: 10%">VL IRRF</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td style="width: 10%">
                          {{ rowData.cfop }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.valor | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlIssRet | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlPis | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlCofins | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlCsll | money }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.vlIrrf | money }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr style="height: 25px">
                        <td [attr.colspan]="9" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Sem Itens!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>

          <div [ngStyle]="aba4 ? { display: 'block' } : { display: 'none' }" class="pull-left pr-2"
            style="margin-top: 7px">
            <div class="box-content" [@changeDivSize]="currentStateNotasFiscais">
              <div class="row">
                <div class="col-sm-12">
                  <p-table class="table-system" [value]="itensForn" selectionMode="single" [paginator]="false"
                    [scrollable]="true" scrollHeight="300px">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 30%">ITEM FORNECEDOR</th>
                        <th style="width: 30%">ITEM ENTRADA</th>
                        <th style="width: 15%">CFOP FORNECEDOR</th>
                        <th style="width: 15%">CFOP ENTRADA</th>
                        <th style="width: 10%" class="text-right">VALOR</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td style="width: 30%">
                          <span matTooltip="{{ rowData.descForn }}" class="overflow-ellipse">{{ rowData.codigoForn }} -
                            {{ rowData.descForn }}</span>
                        </td>
                        <td style="width: 30%">
                          <span matTooltip="{{ rowData.descInt }}" class="overflow-ellipse">{{ rowData.codigoInt }} -
                            {{ rowData.descInt }}</span>
                        </td>
                        <td style="width: 15%">
                          {{ rowData.cfopForn }}
                        </td>
                        <td style="width: 15%">
                          {{ rowData.cfopInt }}
                        </td>
                        <td style="width: 10%" class="text-right">
                          {{ rowData.totalValue | money }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr style="height: 25px">
                        <td [attr.colspan]="5" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Sem Itens!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>

          <div [ngStyle]="aba5 ? { display: 'block' } : { display: 'none' }" class="pull-left pr-2"
            style="margin-top: 7px">
            <div class="box-content" [@changeDivSize]="currentStateNotasFiscais">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>DADOS ADICIONAIS</label>
                  <textarea class="form-control" rows="5" [disabled]="true"
                    value="dadosAdicionais">{{ dadosAdicionais }}</textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <p-dialog [(visible)]="modalLog" [style]="{ width: '800px' }" [responsive]="true">
    <p-header> Log de Advertência </p-header>
    <div class="conteudo">
      <ul>
        <li *ngFor="let log of logAdvertencia">
          {{ log.detail }}
        </li>
      </ul>
    </div>
  </p-dialog>

  <p-dialog header="Verificar Detalhes da Escrituração" [modal]="true" appendTo="body" [style]="{ width: '900px' }"
    styleClass="detalhes-altura-inicial" [(visible)]="exibirDetalhes" [responsive]="true" [closable]="true">
    <mat-tab-group>
      <mat-tab label="Itens Fornecedor">
        <div class="row">
          <div class="col-sm-12 mt-3">
            <h3 class="sub-title">
              <i class="fas fa-bars"></i> Itens Fornecedor
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <p-table class="table-system" [value]="itensForn" selectionMode="single" [paginator]="false"
              [scrollable]="true" scrollHeight="300px">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 30%">ITEM FORNECEDOR</th>
                  <th style="width: 25%">ITEM ENTRADA</th>
                  <th style="width: 20%">CFOP FORNECEDOR</th>
                  <th style="width: 15%">CFOP ENTRADA</th>
                  <th style="width: 10%" class="text-right">VALOR</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 30%">
                    {{ rowData.codigoForn }} - {{ rowData.descForn }}
                  </td>
                  <td style="width: 25%">
                    {{ rowData.codigoInt }} - {{ rowData.descInt }}
                  </td>
                  <td style="width: 20%">
                    {{ rowData.cfopForn }}
                  </td>
                  <td style="width: 15%">
                    {{ rowData.cfopInt }}
                  </td>
                  <td style="width: 10%" class="text-right">
                    {{ rowData.totalValue | money }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr style="height: 25px">
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Sem Itens!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Grade Contábil">
        <div class="row">
          <div class="col-sm-12 mt-3">
            <h3 class="sub-title">
              <i class="fas fa-bars"></i> Grade Contábil
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p-table class="table-system" [value]="escrituracaoContabil" selectionMode="single"
              (onRowSelect)="onRowSelectContabil($event)" [paginator]="false" [scrollable]="true" scrollHeight="300px">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 10%" class="text-center" *ngIf="visualizarEscrituracao">
                    DATA
                  </th>
                  <th style="width: 10%" *ngIf="visualizarEscrituracao">
                    NÚMERO
                  </th>
                  <th style="width: 10%" *ngIf="visualizarEscrituracao">
                    LOTE
                  </th>
                  <th style="width: 20%">CONTA</th>
                  <th style="width: 5%" class="text-center">D/C</th>
                  <th style="width: 10%" class="text-right">VALOR</th>
                  <th style="width: 20%">CENTRO CUSTO</th>
                  <th style="width: 15%">PROJETO</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 10%" class="text-center" *ngIf="visualizarEscrituracao">
                    {{ rowData.data | timezone | date: "dd/MM/yyyy" }}
                  </td>
                  <td style="width: 10%" *ngIf="visualizarEscrituracao">
                    {{ rowData.numero }}
                  </td>
                  <td style="width: 10%" *ngIf="visualizarEscrituracao">
                    <span matTooltip="{{ rowData.lote }}" class="overflow-ellipse">{{ rowData.lote }}</span>
                  </td>
                  <td style="width: 20%">
                    <span matTooltip="{{ rowData.codCta }} - {{ rowData.descrCta }}" class="overflow-ellipse">{{
                      rowData.codCta }} - {{ rowData.descrCta }}</span>
                  </td>
                  <td style="width: 5%" class="text-center">
                    {{ rowData.indDc }}
                  </td>
                  <td style="width: 10%" class="text-right">
                    {{ rowData.valor | money }}
                  </td>
                  <td style="width: 20%">
                    <span matTooltip="{{ rowData.codCcus }} - {{
                        rowData.descrCcus
                      }}" class="overflow-ellipse">{{ rowData.codCcus }} - {{ rowData.descrCcus }}</span>
                  </td>
                  <td style="width: 15%">
                    <span matTooltip="{{ rowData.codProj }} - {{
                        rowData.descrProj
                      }}" class="overflow-ellipse">{{ rowData.codProj }} - {{ rowData.descrProj }}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr style="height: 25px">
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Sem Itens!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-sm btn-info pull-right" (click)="addLinhaLcto()" *ngIf="!visualizarEscrituracao">
              <i class="fas fa-plus"></i> Adicionar Linha
            </button>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Grade Fiscal">
        <div class="row">
          <div class="col-sm-12">
            <p-table class="table-system" [value]="gradeFiscal" selectionMode="single" [paginator]="false"
              [scrollable]="true" scrollHeight="300px">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 10%">CFOP</th>
                  <th style="width: 10%">CST ICMS</th>
                  <th style="width: 10%">VALOR</th>
                  <th style="width: 10%">BASE ICMS</th>
                  <th style="width: 10%">ALIQ. ICMS</th>
                  <th style="width: 10%">VL. ICMS</th>
                  <th style="width: 12%">BASE ICMS ST</th>
                  <th style="width: 12%">ALIQ. ICMS ST</th>
                  <th style="width: 11%">VL. ICMS ST</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 10%">
                    {{ rowData.cfop }}
                  </td>
                  <td style="width: 10%">
                    {{ rowData.cstIcms }}
                  </td>
                  <td style="width: 10%" class="text-right">
                    {{ rowData.valor | money }}
                  </td>
                  <td style="width: 10%" class="text-right">
                    {{ rowData.vlBcIcms | money }}
                  </td>
                  <td style="width: 10%" class="text-right">
                    {{ rowData.aliqIcms | money }}
                  </td>
                  <td style="width: 10%" class="text-right">
                    {{ rowData.vlIcms | money }}
                  </td>
                  <td style="width: 12%" class="text-right">
                    {{ rowData.vlBcIcmsSt | money }}
                  </td>
                  <td style="width: 12%" class="text-right">
                    {{ rowData.aliqIcmsSt | money }}
                  </td>
                  <td style="width: 11%" class="text-right">
                    {{ rowData.vlIcmsSt | money }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr style="height: 25px">
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Sem Itens!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="exibirDetalhes = false">
        <i class="fas fa-undo"></i> Fechar
      </button>
    </p-footer>
  </p-dialog>

  <!--<p-dialog header="Detalhes da Contabilização" [(visible)]="editarEscrituracaoContabil" [style]="{width: '900px'}" [modal]="true" styleClass="contabilizacao-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-bars"></i> Lançamento Contábil</h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
        <label>Conta Contábil</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContas"
        placeholder="Selecione o Plano de Contas" [(ngModel)]="itemEscrituracaoContabil.planoContas.id"
        [disabled]="true"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
      <div class="form-group col-sm-4">
          <label>Indicador Débito/Crédito</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [options]="opcoesDebitoCredito" [(ngModel)]="itemEscrituracaoContabil.indDc"
          [disabled]="true"></p-dropdown>
      </div>
      <div class="form-group col-sm-4">
          <label>Centro de Custo</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"
          placeholder="Selecione o Centro de Custo" [(ngModel)]="itemEscrituracaoContabil.cadCcus.id"
          [disabled]="itemEscrituracaoContabil.planoContas.codNaturezaConta.id != 4"></p-dropdown>
      </div>
      <div class="form-group col-sm-4">
          <label>Projeto</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCadProj"
          placeholder="Selecione o Projeto" [(ngModel)]="itemEscrituracaoContabil.cadProj.id"
          [disabled]="itemEscrituracaoContabil.planoContas.codNaturezaConta.id != 4"></p-dropdown>
      </div>
  </div>
  <div class="form-row">
      <div class="form-group col-sm-8" *ngIf="visualizarEscrituracao">
          <label>Histórico</label>
          <input class="form-control" type="text" [(ngModel)]="itemEscrituracaoContabil.histCompl" />
      </div>
      <div class="form-group col-sm-4">
          <label>Valor Total</label>
          <input class="form-control text-right" type="text" [(ngModel)]="itemEscrituracaoContabil.vlCc" money
          [disabled]="true" />
      </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="cancelarEdicaoLctoContabil()"><i class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-danger" (click)="excluirLctoContabil()"><i class="fas fa-times"></i> Excluir</button>
    <button type="button" class="btn btn-primary" (click)="atualizarLinhaEscrituracaoContabil()"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>-->

  <div *ngIf="partidaLancamentoEditar.id">
    <p-dialog header="Detalhes da Contabilização" [(visible)]="editarLctoContabil" [style]="{ width: '900px' }"
      [modal]="true" styleClass="contabilizacao-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-bars"></i> Lançamento Contábil
          </h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Conta Contábil</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContas"
            placeholder="Selecione o Plano de Contas" [(ngModel)]="partidaLancamentoEditar.planoContas.id">
          </p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Indicador Débito/Crédito</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [options]="opcoesDebitoCredito"
            [(ngModel)]="partidaLancamentoEditar.indDc"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Centro de Custo</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"
            placeholder="Selecione o Centro de Custo" [(ngModel)]="partidaLancamentoEditar.cadCcus.id" [disabled]="
              partidaLancamentoEditar.planoContas.codNaturezaConta.id != 4
            "></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Projeto</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCadProj"
            placeholder="Selecione o Projeto" [(ngModel)]="partidaLancamentoEditar.cadProj.id" [disabled]="
              partidaLancamentoEditar.planoContas.codNaturezaConta.id != 4
            "></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label>Histórico</label>
          <input class="form-control" type="text" [(ngModel)]="partidaLancamentoEditar.histCompl" />
        </div>
        <div class="form-group col-sm-4">
          <label>Valor Total</label>
          <input class="form-control text-right" type="text" [(ngModel)]="partidaLancamentoEditar.valor" {money} />
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="cancelarEdicaoPartidaLctoContabil()">
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button type="button" class="btn btn-danger" (click)="excluirPartidaLctoContabil()">
          <i class="fas fa-times"></i> Excluir
        </button>
        <button type="button" class="btn btn-primary" (click)="atualizarPartidaContabil()">
          <i class="fas fa-check"></i> Salvar
        </button>
      </p-footer>
    </p-dialog>
  </div>

  <!-- Modal Vencimento Parcela -->
  <!-- <div class="modal fade" id="editarVencimentoParcela" [ngStyle]="{'display':editarVencimentoParcela}" tabindex="-1"
  role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xs" role="document">
    <div class="modal-content" style="overflow: initial;">
      <div class="modal-header">
        <h5 class="modal-title">Editar Vencimento Parcela</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" aria-label="Fechar">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="pedido.id">
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>DATA VENCIMENTO</label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                 [(ngModel)]="vencimentoParcela" dateFormat="dd/mm/yy"></p-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="atualizarVencimentoParcela()"><i
            class="fas fa-check"></i> Salvar</button>
      </div>
    </div>
  </div>
</div>
<p-dialog header="Editar Vencimento Parcela" [(visible)]="editarVencimentoParcela"
  [style]="{width: '400px'}"
  [modal]="true"
  styleClass="vencimento-altura-inicial"
  [responsive]="true"
  appendTo="body"
  [closable]="true">
  <div *ngIf="pedido.id">
      <div class="form-row">
        <div class="form-group col-sm-5">
          <label>DATA VENCIMENTO</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
             [(ngModel)]="vencimentoParcela" dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="editarVencimentoParcela=false"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="atualizarVencimentoParcela()"><i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>-->

  <!-- Modal Alterar Período FEITO-->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{ width: '400px' }" [modal]="true"
    styleClass="modal-initial" [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body"
    [closable]="true">
    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px">
      <div class="ui-g-12"></div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)="closeModalPeriodo()">
            <i class="fas fa-undo"></i> Cancelar
          </button>
          <button type="button" class="btn btn-primary" (click)="inicializar()">
            <i class="fas fa-pencil-alt"></i> Alterar
          </button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <!-- Modal Ediçao Escrituracao -->
  <!-- vir -->
  <p-dialog header="Editar linha da Escrituração" [(visible)]="editarEscrituracao" [style]="{ width: '800px' }"
    [modal]="true" styleClass="editar-linha-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="editarEscrituracao" style="height: 80vh;">
      <mat-tab-group>
        <mat-tab label="Dados">
          <h3 class="sub-title font-xs mt-4">
            <i class="fas fa-bars"></i> Dados
          </h3>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Item</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.codItem" readOnly />
            </div>
          </div>

          <div *ngIf="pedido.pedidoTipo && pedido.pedidoTipo.id !== 2">
            <div class="form-row">
              <div class="form-group col-sm-4" *ngIf="pedido.operacaoRegra">
                <label>Operação</label>
                <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.codOper" readOnly />
              </div>
              <div class="form-group col-sm-4">
                <label>NCM</label>
                <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.ncm" disabled="disabled" />
              </div>
              <div class="form-group col-sm-4">
                <label>CFOP</label>
                <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.cfop" />
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Quantidade</label>
              <input class="form-control text-right" type="text" input-text="right" (blur)="calcularValorTotal()"
                pInputText placeholder="Quantidade" [disabled]="!linhaEditada.codItem"
                [(ngModel)]="linhaEditada.qtde" />
            </div>
            <div class="form-group col-sm-6">
              <label>Valor Unitário</label>
              <input class="form-control text-right" type="text" input-text="right" [disabled]="!linhaEditada.codItem"
                pInputText [(ngModel)]="linhaEditada.vlUnit" money />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Desconto</label>
              <input class="form-control text-right" type="text" input-text="right" input-text="right"
                [disabled]="!linhaEditada.codItem" pInputText [(ngModel)]="linhaEditada.vlDesc" money />
            </div>
            <div class="form-group col-sm-6">
              <label>Valor do Desconto</label>
              <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="linhaEditada.vlDesc"
                money pInputText money />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Tot. Frete</label>
              <input class="form-control text-right" type="text" input-text="right" pInputText
                [(ngModel)]="linhaEditada.vlFrete" money />
            </div>
            <div class="form-group col-sm-6">
              <label>Tot. Seguro</label>
              <input class="form-control text-right" type="text" input-text="right" pInputText
                [(ngModel)]="linhaEditada.vlSeguro" money />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Outro</label>
              <input class="form-control text-right" type="text" input-text="right" pInputText
                [(ngModel)]="linhaEditada.vlOutro" money />
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Tributos">
          <h3 class="sub-title font-xs mt-4">
            <i class="fas fa-bars"></i> Tributos
          </h3>
          <mat-tab-group>
            <mat-tab label="IPI">
              <div class="form-row mt-3">
                <div class="form-group col-sm-6">
                  <label>Situação tributária</label>
                  <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.cstIpi" />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor base de calculo</label>
                  <input class="form-control text-right" type="text" input-text="right" (blur)="calculaValorIpi()"
                    pInputText [(ngModel)]="linhaEditada.vlBcIpi" money />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Alíquota</label>
                  <input class="form-control text-right" type="text" input-text="right" (blur)="calculaValorIpi()"
                    pInputText [(ngModel)]="linhaEditada.aliqIpi" money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor do IPI</label>
                  <input class="form-control text-right" type="text" input-text="right" pInputText
                    [(ngModel)]="linhaEditada.vlIpi" disabled="disabled" money />
                </div>
              </div>
            </mat-tab>

            <mat-tab label="ICMS">
              <div class="form-row mt-3">
                <div class="form-group col-sm-6">
                  <label>Situação Tributária</label>
                  <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.cstIcms" />
                </div>
              </div>
              <h3 class="sub-title font-xs mt-3">
                <i class="fas fa-bars"></i> ICMS
              </h3>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Valor da base de cálculo ICMS</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorIcms()" pInputText
                    [(ngModel)]="linhaEditada.vlBcIcms" money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Modalidade de determinação do ICMS</label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesModBcIcms"
                    [(ngModel)]="linhaEditada.modBcIcms" placeholder="Selecione"></p-dropdown>
                </div>
                <div class="form-group col-sm-6">
                  <label>Alíquota ICMS</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorIcms()" pInputText
                    [(ngModel)]="linhaEditada.aliqIcms" money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Redução da BC ICMS</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlRedBcIcms"
                    money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor ICMS</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlIcms"
                    disabled="disabled" money />
                </div>
              </div>

              <h3 class="sub-title font-xs">
                <i class="fas fa-bars"></i> ICMS ST
              </h3>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Valor da base de cálculo ICMS ST</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorIcmsSt()" pInputText
                    [(ngModel)]="linhaEditada.vlBcIcmsSt" money />
                </div>

                <div class="form-group col-sm-6">
                  <label>Alíquota ICMS ST</label>
                  <input class="form-control text-right" (blur)="calculaValorIcmsSt()" pInputText
                    [(ngModel)]="linhaEditada.aliqIcmsSt" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Valor do ICMS ST</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlIcmsSt"
                    disabled="disabled" />
                </div>
              </div>
            </mat-tab>

            <mat-tab label="PIS">
              <div class="form-row mt-3">
                <div class="form-group col-sm-6">
                  <label>Situação tributária</label>
                  <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.cstPis" />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor da base de cálculo</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorPis()" pInputText
                    [(ngModel)]="linhaEditada.vlBcPis" money />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Alíquota (percentual)</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorPis()" pInputText
                    [(ngModel)]="linhaEditada.aliqPis" money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor do PIS</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlPis"
                    disabled="disabled" money />
                </div>
              </div>
            </mat-tab>

            <mat-tab label="COFINS">
              <div class="form-row mt-3">
                <div class="form-group col-sm-6">
                  <label>Situação tributária</label>
                  <input class="form-control" type="text" pInputText [(ngModel)]="linhaEditada.cstCofins" />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor da base de cálculo</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorCofins()" pInputText
                    [(ngModel)]="linhaEditada.vlBcCofins" money />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Alíquota (percentual)</label>
                  <input class="form-control text-right" type="text" (blur)="calculaValorCofins()" pInputText
                    [(ngModel)]="linhaEditada.aliqCofins" money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor do COFINS</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlCofins"
                    disabled="disabled" money />
                </div>
              </div>
            </mat-tab>

            <mat-tab label="Imposto de Importação">
              <div class="form-row mt-3">
                <div class="form-group col-sm-6">
                  <label>Valor base de cálculo</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlBcIi"
                    money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor despesas aduaneiras</label>
                  <input class="form-control text-right" type="text" pInputText
                    [(ngModel)]="linhaEditada.vlDespAduImport" money />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>Valor do IOF</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlIof"
                    money />
                </div>
                <div class="form-group col-sm-6">
                  <label>Valor imposto de importação</label>
                  <input class="form-control text-right" type="text" pInputText [(ngModel)]="linhaEditada.vlIi" money />
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="editarEscrituracao = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="atualizarLinhaEscrituracao()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
  </p-dialog>

  <!---Modal Modal Pedidos-->
  <p-dialog header="Pedidos pendentes de Escrituração" [(visible)]="exibirPedidos" [style]="{ width: '800px'}"
    [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="exibirPedidos">
      <table class="table table-borderless" *ngIf="!escritNf">
        <tbody>
          <tr>
            <td style="width: 105px">
              <strong>EMITENTE: </strong>
            </td>
            <td>
              {{ nf.emitente }}
            </td>
            <td style="width: 60px">
              <strong> CNPJ: </strong>
            </td>
            <td>
              {{ nf.cnpj }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>NÚM DOC: </strong>
            </td>
            <td>
              <div>
                {{ nf.numNf }}
              </div>
            </td>
            <td>
              <strong>EMISSÃO: </strong>
            </td>
            <td>
              <div>
                {{ nf.emissao }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <strong>CHV ELETRÔNICA: </strong>
            </td>
            <td>
              {{ nf.chave }}
            </td>
            <td>
              <strong>VALOR: </strong>
            </td>
            <td>
              {{ nf.valor }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row" *ngIf="!escritNf">
        <div class="col-sm-12">
          <h3 class="sub-title mt-0">
            <i class="fas fa-info-circle"></i> PEDIDOS
          </h3>
        </div>
      </div>

      <p-table class="table-system" #dt23 [value]="listaPedidosPendentes" [rows]="rows"
        [totalRecords]="listaPedidosPendentes.length" [pageLinks]="pageLinks" selectionMode="single"
        [style]="{ width: '100%' }" appendTo="body" scrollHeight="280px">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 15%">
              <span class="text-overflow-dynamic-container" pSortableColumn="dtEmissao">
                <span class="text-overflow-dynamic-ellipsis">DATA EMISSÃO <p-sortIcon field="dtEmissao"> </p-sortIcon>
                </span>
              </span>
            </th>

            <th class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon field="codigo"></p-sortIcon></span>
              </span>
            </th>

            <th class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                <span class="text-overflow-dynamic-ellipsis">CÓD CONTROLE <p-sortIcon field="codigoControle">
                  </p-sortIcon></span>
              </span>
            </th>

            <th class="text-left" style="width: 10%">
              <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon field="valor"></p-sortIcon></span>
              </span>
            </th>


            <th class="text-left" style="width: 10%">
              <span class="text-overflow-dynamic-container" pSortableColumn="valorDisponivel">
                <span class="text-overflow-dynamic-ellipsis">Saldo <p-sortIcon field="valorDisponivel"></p-sortIcon>
                </span>
              </span>
            </th>

            <th class="text-center" style="width: 18%">
              <span class="text-overflow-dynamic-container" pSortableColumn="status">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon field="status"></p-sortIcon></span>
              </span>
            </th>

            <th class="text-center" style="width: 7%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-left" style="width: 15%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="carregarPedido(rowData)"
                  matTooltip="{{ rowData.dtEmissao }}">{{ rowData.dtEmissao }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="carregarPedido(rowData)"
                  matTooltip="{{ rowData.codigo }}">{{ rowData.codigo }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="carregarPedido(rowData)"
                  matTooltip="{{ rowData.codigoControle }}  ">
                  {{ rowData.codigoControle }}
                </span>
              </span>
            </td>

            <td class="text-right" style="width: 10%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="carregarPedido(rowData)"
                  matTooltip="{{ rowData.valor }}  ">
                  {{ rowData.valor }}
                </span>
              </span>
            </td>

            <td class="text-right" style="width: 10%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="carregarPedido(rowData)"
                  matTooltip=" {{ rowData.valorDisponivel }}  ">{{ rowData.valorDisponivel }}
                </span>
              </span>
            </td>

            <td class="text-left" style="width: 18%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="carregarPedido(rowData)"
                  matTooltip=" {{ rowData.status }}  ">{{ rowData.status }}
                </span>
              </span>
            </td>

            <td class="text-center" style="width: 7%">
              <span class="edit" matTooltip="Imprimir Pedido" (click)="realizarImpressao(rowData.pedidoId)">
                <i class="fas fa-print"></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="7" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <p-footer></p-footer>
  </p-dialog>
  <!---Fim Modal Pedidos-->

  <div class="box-content {{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="escritNf">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title">
          <i class="fas fa-info-circle"></i> Informações
        </h3>
      </div>
    </div>
    <table class="table table-borderless">
      <thead>
        <tr>
          <th style="width: 180px">
            <strong>PEDIDO DE COMPRA: </strong>
          </th>
          <th>
            <strong> {{ pedido.codigo }} </strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <strong>Fornecedor </strong>
          </td>
          <td>
            <div *ngIf="!(infoParticipante[0].cnpj === null)">
              {{ infoParticipante[0].nome }} - CNPJ:
              {{ infoParticipante[0].cnpj | cnpj }}
            </div>
            <div *ngIf="!(infoParticipante[0].cpf === null)">
              {{ infoParticipante[0].nome }} - CPF:
              {{ infoParticipante[0].cpf | cpf }}
            </div>
            <div *ngIf="
                infoParticipante[0].cpf === null &&
                infoParticipante[0].cnpj === null
              ">
              {{ infoParticipante[0].nome }} (Exterior)
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <strong>ENDEREÇO: </strong>
          </td>
          <td>
            {{ infoParticipante[0].logradouro }},
            {{ infoParticipante[0].numero }} -
            {{ infoParticipante[0].cidade }}/{{ infoParticipante[0].uf }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>CONTATO: </strong>
          </td>
          <td>
            <!--{{this.ExisteParticipanteContato(this.infoParticipante[0])}}-->
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title">
          <i class="fas fa-info-circle"></i> Informações Iniciais
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <!-- 1a. linha das Iniciais -->
        <div class="form-row align-items-end">
          <div class="form-group col-sm-3">
            <label>MODELO <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="false" [(ngModel)]="pedido.modNf.id" [options]="opcoesModelo">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-1">
            <label>CÓD. CONTROLE</label>
            <input class="form-control" type="text" [(ngModel)]="pedido.codigoControle" />
          </div>
          <div class="form-group col-sm-2">
            <label>NÚMERO <span class="obrigatorio">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="pedido.numNf" maxlength="50"
              [disabled]="pedido.pedidoStatus.id === 4" />
          </div>
          <div class="form-group col-sm-1">
            <label>SÉRIE</label>
            <input class="form-control" type="text" maxlength="3" [(ngModel)]="pedido.serie"
              [disabled]="pedido.pedidoStatus.id === 4" />
          </div>
          <div class="form-group col-sm-1">
            <label>DATA ENTREGA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="pedido.dtEntrega"
              [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030">
            </p-calendar>
          </div>
          <div class="form-group col-sm-1">
            <label>DATA EMISSÃO<span class="obrigatorio">*</span></label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="pedido.dtEmissao"
              [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030">
            </p-calendar>
          </div>
          <div class="form-group col-sm-1">
            <label>DATA ENTRADA<span class="obrigatorio">*</span></label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="pedido.dtFaturamentoAutomatico"
              [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030">
            </p-calendar>
          </div>
          <div class="form-group col-sm-1">
            <button class="btn btn-sm btn-info" (click)="vincularPedidoServico()">
              Vincular Pedido
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title">
          <i class="fas fa-bars"></i> Itens do Pedido
          <span class="obrigatorio">*</span>
        </h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mb-2">
        <p-table class="table-system" [value]="pedido.pedidoItem" selectionMode="single">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 22%">ITEM</th>
              <th style="width: 16%">OPERAÇÃO</th>
              <th class="text-right" style="width: 11%">QTD</th>
              <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                VALOR UNIT ({{ codigoMoeda }})
              </th>
              <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                VALOR UNIT (BRL)
              </th>
              <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                VALOR UNIT
              </th>
              <th class="text-right" style="width: 11%">TIPO DESC</th>
              <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                VALOR DESC ({{ codigoMoeda }})
              </th>
              <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                VALOR DESC (BRL)
              </th>
              <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                VALOR DESC
              </th>
              <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                VALOR TOTAL ({{ codigoMoeda }})
              </th>
              <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                VALOR TOTAL (BRL)
              </th>
              <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                VALOR TOTAL
              </th>
              <th style="width: 7%" class="text-center">Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData" style="height: 25px">
              <td *ngIf="rowData.item.id" (click)="rateioItem(rowData)">
                <span matTooltip="{{ rowData.item.codigo }} - {{
                    rowData.item.descricao
                  }}" class="overflow-ellipse">{{ rowData.item.codigo }} -
                  {{ rowData.item.descricao }}</span>
              </td>
              <td (click)="rateioItem(rowData)">
                <span matTooltip="{{ rowData.operacaoRegra.cod }} - {{
                    rowData.operacaoRegra.descricao
                  }}" class="overflow-ellipse">{{ rowData.operacaoRegra.cod }} -
                  {{ rowData.operacaoRegra.descricao }}</span>
              </td>
              <td class="text-right" (click)="rateioItem(rowData)">
                {{ rowData.quantidade }}
              </td>
              <td class="text-right" (click)="rateioItem(rowData)">
                {{ rowData.valorUnit | money }}
              </td>
              <td class="text-right" (click)="rateioItem(rowData)">
                {{ rowData.tipoDesconto.cod }}
              </td>
              <td class="text-right" (click)="rateioItem(rowData)">
                {{ rowData.valorDesconto | money }}
              </td>
              <td class="text-right" (click)="rateioItem(rowData)">
                {{ rowData.valorTotal | money }}
              </td>
              <td class="text-center">
                <span class="edit" matTooltip="Editar Item Pedido" (click)="itemOnRowSelect(rowData)">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="delete" matTooltip="Excluir Item Pedido" *ngIf="!isReadOnly"
                  (click)="excluirItemLista(rowData.id)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr style="height: 25px">
              <td [attr.colspan]="8" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline">
                  <i class="fas fa-info-circle"></i> Sem Itens Adicionados!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-sm-12">
        <button class="btn btn-sm btn-info" (click)="adicionarItem()" [disabled]="isReadOnly">
          <i class="fas fa-plus"></i> Adicionar Item
        </button>
      </div>
    </div>

    <!-- Início Rateio Pedido-->
    <div class="row">
      <div class="col-sm-12" *ngIf="exibirRateio && pedidoItem.item">
        <h3 class="sub-title mt-4 mb-0">
          <i class="fas fa-bars"> </i> Rateio
          <span class="text-secondary">{{ pedidoItem.item.codigo }}</span>
        </h3>
      </div>
    </div>

    <div class="row" *ngIf="exibirRateio && pedidoItem.pedidoItensRateio">
      <div class="col-sm-12">
        <p-table class="table-system mt-2 d-block" [value]="pedidoItem.pedidoItensRateio" [style]="{ width: '100%' }">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 40%" class="text-left">Centro de Custo</th>
              <th style="width: 33%">Projeto</th>
              <th style="width: 20%">Porcentagem</th>
              <th style="width: 7%" class="text-center">Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr (click)="retornaIdCentroDeCusto(rowData.cadCcus.id, null)">
              <td pEditableColumn style="width: 40%">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="form-row">
                      <div class="form-group col-sm-12 mb-0">
                        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"
                          [(ngModel)]="rowData.cadCcus" placeholder="Selecione um centro de custo" (onChange)="
                            calculaRateioDisponivelCentroCusto();
                            retornaIdCentroDeCusto(rowData.cadCcus.id, rowData)
                          " [disabled]="!permiteAlterarRateio" optionLabel="label"></p-dropdown>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span matTooltip=" {{ rowData.cadCcus.codCcus }} - {{
                        rowData.cadCcus.descrCcus
                      }}" class="overflow-ellipse" style="cursor: pointer" *ngIf="rowData.cadCcus">
                      {{ rowData.cadCcus.codCcus }} -
                      {{ rowData.cadCcus.descrCcus }}
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn style="width: 33%">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="form-row">
                      <div class="form-group col-sm-12 mb-0">
                        <p-dropdown ##dd class="type-p-dropdown" [filter]="true" [options]="opcoesProjeto"
                          [(ngModel)]="rowData.cadProj" placeholder="Selecione um projeto"
                          (onChange)="calculaRateioDisponivelCentroCusto()" optionLabel="label"
                          [disabled]="!permiteAlterarRateio"></p-dropdown>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span matTooltip=" {{ rowData.cadProj.codProj }} - {{
                        rowData.cadProj.descrProj
                      }}" class="overflow-ellipse" style="cursor: pointer" *ngIf="rowData.cadProj">
                      {{ rowData.cadProj.codProj }} -
                      {{ rowData.cadProj.descrProj }}
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn style="width: 20%; text-align: right">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="form-row">
                      <div class="form-group col-sm-12 mb-0">
                        <input class="form-control text-right" [(ngModel)]="rowData.porcentagem"
                          (keyup)="calculaRateioDisponivelCentroCusto()" type="text"
                          [disabled]="!permiteAlterarRateio" />
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span matTooltip="{{ rowData.porcentagem | money }}" class="overflow-ellipse text-right"
                      style="cursor: pointer">{{ rowData.porcentagem | money }}</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="text-center" style="width: 7%">
                <span class="delete" matTooltip="Excluir" (click)="removerRateioCentroCusto(rowData.id)">
                  <i class="far fa-trash-alt"></i></span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col" *ngIf="exibirRateio">
        <button class="btn btn-sm btn-info mt-2" *ngIf="exibirRateio" (click)="adicionarRateio()"
          [disabled]="!permiteAlterarRateio">
          <i class="fas fa-plus"></i> Adicionar Rateio
        </button>

        <button class="btn btn-sm btn-info mt-2" *ngIf="exibirRateio && pedido.pedidoItem.length > 1"
          (click)="replicarRateio()" [disabled]="!permiteAlterarRateio">
          <i class="fas fa-copy"></i> Replicar
        </button>
      </div>
    </div>
    <!-- Final Rateio Pedido-->

    <div class="row">
      <div class="col-sm-12 mt-3">
        <h3 class="sub-title">
          <i class="fas fa-info-circle"></i> Informações Gerais
        </h3>
      </div>
    </div>
    <!-- 1a. linhas das Gerais -->
    <div class="form-row align-items-end">
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR INSS (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR INSS ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR INSS</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorInss" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR IRRF (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR IRRF ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR IRRF</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIr" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR CSLL (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR CSLL ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR CSLL</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCsll" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR PIS (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR PIS ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR PIS</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorPis" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR COFINS (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR COFINS ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR COFINS</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCofins" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR ISS (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR ISS ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR ISS</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIssRetido" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">OUTRAS RETENÇÕES (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">OUTRAS RETENÇÕES ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">OUTRAS RETENÇÕES</label>
        <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorOutrasRetencoes" money
          (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
      </div>
    </div>

    <!-- 2a. linhas das Gerais -->
    <div class="form-row align-items-end">
      <div class="form-group col"></div>
      <div class="form-group col"></div>
      <div class="form-group col"></div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">SUBTOTAL (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">SUBTOTAL ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">SUBTOTAL</label>
        <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
          (ngModelChange)="atualizarSubtotal()" [(ngModel)]="subTotalPedido" money />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR DESCONTO (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR DESCONTO ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR DESCONTO</label>
        <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
          (ngModelChange)="atualizarSubtotal()" [(ngModel)]="valorDescontoItens" money />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">IMPOSTOS RET. (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">IMPOSTOS RET. ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">IMPOSTOS RET.</label>
        <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
          (ngModelChange)="atualizarSubtotal()" [(ngModel)]="valorImpostosRetidos" money />
      </div>
      <div class="form-group col">
        <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR TOTAL (BRL)</label>
        <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR TOTAL ({{ codigoMoeda }})</label>
        <label *ngIf="!pedidoExterior">VALOR TOTAL</label>
        <input class="form-control text-right" type="text" [(ngModel)]="valorTotalPedido" money />
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title mt-0">
          <i class="fas fa-money-check-alt"></i> Informações de Pagamento
        </h3>
      </div>
    </div>

    <!-- 1a. linha pagamento -->
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>TIPO DE PAGAMENTO <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.tipoPagamento"
          [options]="opcoesTipoPagamento"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="pedido.tipoPagamento === 1">
        <label>FORMA DE PAGAMENTO <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.formaPagamento.id"
          [options]="opcoesFormaPagamento" (onChange)="pagamentoForma()"></p-dropdown>
      </div>
      <div class="form-group col-sm-3" *ngIf="pedido.tipoPagamento === 1">
        <label>CONTA <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.empresaConta.id"
          [options]="opcoesContas" (onChange)="pagamentoForma()"></p-dropdown>
      </div>
      <div class="form-group col-sm-4" *ngIf="
          pedido.tipoPagamento === 1 &&
          pedido.empresaConta.id &&
          pedido.formaPagamento.id &&
          !isReadOnly
        ">
        <label class="d-block" style="visibility: hidden">DO NOT REMOVE</label>
        <button type="button" class="btn btn-sm btn-info" (click)="modalFormaPgto = true">
          <i class="fas fa-plus"></i> Adicionar
        </button>
        <button type="button" class="btn btn-sm btn-info" (click)="atualizarSubtotal(); pagamentoForma()">
          <i class="fas fa-refresh"></i> Atualizar
        </button>
      </div>
    </div>

    <!-- 2a. linha pagamento -->
    <div class="row" *ngIf="pedido.tipoPagamento === 1">
      <!-- *ngIf="pedido.pedidoParcela" -->
      <div class="col-sm-12 mb-3" *ngIf="pedido.pedidoParcela">
        <div *ngIf="pedido.pedidoParcela.length > 0">
          <!--*ngIf="pedido.pedidoParcela.length > 0"-->
          <p-table class="table-system" [value]="pedido.pedidoParcela" selectionMode="single"
            (onRowSelect)="modalFormaPgto = true" [scrollable]="true" scrollHeight="330px">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 10%">Parcela</th>
                <th class="text-center" style="width: 15%">Vencimento</th>
                <th class="text-right" style="width: 15%" *ngIf="pedido.converterTaxa && pedidoExterior">
                  Valor (BRL)
                </th>
                <th class="text-right" style="width: 15%" *ngIf="!pedido.converterTaxa && pedidoExterior">
                  Valor ({{ codigoMoeda }})
                </th>
                <th class="text-right" style="width: 15%" *ngIf="!pedidoExterior">
                  Valor
                </th>
                <th class="text-left" style="width: 15%">Tipo</th>
                <th class="text-left" style="width: 45%">Histórico</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr *ngIf="!isReadOnly" [pSelectableRow]="rowData">
                <td class="text-center" style="width: 10%">
                  {{ rowData.parcela }}
                </td>
                <td class="text-center" style="width: 15%">
                  {{ rowData.dtVencimento | timezone | date: "dd/MM/yyyy" }}
                </td>
                <td class="text-right" style="width: 15%">
                  {{ rowData.valor | money }}
                </td>
                <td class="text-left" style="width: 15%">
                  <span class="overflow-ellipse" matTooltip="{{
                      rowData.tipoBaixa
                        ? rowData.tipoBaixa.descricao
                        : rowData.tipoBaixa
                    }}">
                    {{
                    rowData.tipoBaixa
                    ? rowData.tipoBaixa.descricao
                    : rowData.tipoBaixa
                    }}
                  </span>
                </td>
                <td class="text-left" style="width: 45%">
                  {{ rowData.historico }}
                </td>
              </tr>
              <tr *ngIf="isReadOnly">
                <td class="text-center" style="width: 10%">
                  {{ rowData.parcela }}
                </td>
                <td class="text-center" style="width: 15%">
                  {{ rowData.dtVencimento | timezone | date: "dd/MM/yyyy" }}
                </td>
                <td class="text-right" style="width: 15%">
                  {{ rowData.valor | money }}
                </td>
                <td class="text-left" style="width: 15%">
                  <span class="overflow-ellipse" matTooltip="{{
                      rowData.tipoBaixa
                        ? rowData.tipoBaixa.descricao
                        : rowData.tipoBaixa
                    }}">
                    {{
                    rowData.tipoBaixa
                    ? rowData.tipoBaixa.descricao
                    : rowData.tipoBaixa
                    }}
                  </span>
                </td>
                <td class="text-left" style="width: 45%">
                  {{ rowData.historico }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr style="height: 25px">
                <td [attr.colspan]="5">Sem forma pagamento selecionada</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="pedido.tipoPagamento === 2">
      <!-- *ngIf="pedido.pedidoParcela" -->
      <div class="col-sm-12 mb-3" *ngIf="adtosRelacionadosPedido">
        <div *ngIf="adtosRelacionadosPedido.length">
          <p-table class="table-system" [value]="adtosRelacionadosPedido" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 10%">Data</th>
                <th class="text-left" style="width: 30%">Participante</th>
                <th class="text-left" style="width: 30%">Histórico</th>
                <th class="text-right" style="width: 10%">Valor</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center">{{ rowData.data }}</td>
                <td class="text-left">{{ rowData.nomePart }}</td>
                <td class="text-left">{{ rowData.descricao }}</td>
                <td class="text-right">{{ rowData.compensar | money }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr style="height: 25px">
                <td [attr.colspan]="3">Sem forma pagamento selecionada</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 mt-3">
        <h3 class="sub-title"><i class="fas fa-file-alt"></i> OBSERVAÇÕES</h3>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>ENDEREÇO DE COBRANÇA</label>
        <textarea class="form-control" rows="5" type="text" value="pedido.enderecoCobranca"
          [(ngModel)]="pedido.enderecoCobranca"></textarea>
      </div>
      <div class="form-group col-sm-6">
        <label>ENDEREÇO DE ENTREGA</label>
        <textarea class="form-control" rows="5" type="text" value="pedido.enderecoEntrega"
          [(ngModel)]="pedido.enderecoEntrega"></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <!-- 1a. linha dados adicionais -->
        <label>DADOS ADICIONAIS</label>
        <textarea class="form-control" rows="5" pInputTextarea value="pedido.dadosAdicionais"
          [(ngModel)]="pedido.dadosAdicionais"></textarea>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-sm-12">
        <button class="btn btn-secondary" (click)="voltar()">
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button class="btn btn-primary" (click)="salvarPedido(false)">
          <i class="fas fa-check"></i> Salvar
        </button>
        <button class="btn btn-primary" (click)="escriturarNf()">
          <i class="fas fa-check"></i> ESCRITURAR NF
        </button>
      </div>
    </div>

    <!---Modal Item-->
    <p-dialog header="Adicionar Item" [(visible)]="exibirItemPedido" [style]="{ width: '900px' }" [modal]="true"
      styleClass="adicionar-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
      <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="exibirItemPedido">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Operação <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao" [disabled]="pedidoVinculado"
              placeholder="Selecione uma Operacao" [(ngModel)]="pedidoItem.operacaoRegra.id"
              (onChange)="dadosOperacao(pedidoItem.operacaoRegra.id)"></p-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Item <span class="obrigatorio">*</span></label>
            <p-dropdown [disabled]="pedidoVinculado" class="type-p-dropdown" [filter]="true" [options]="opcoesItens"
              placeholder="Selecione o Item" [(ngModel)]="pedidoItem.item.id" (onChange)="dadosItem($event)">
            </p-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Part Number</label>
            <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.itemFornecedor" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Quantidade <span class="obrigatorio">*</span></label>
            <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.quantidade"
              (blur)="calcularValorTotal()" />
          </div>
          <div class="form-group col-sm-3">
            <label>Valor Unitário </label>
            <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.valorUnit"
              (blur)="calcularValorTotal()" />
          </div>
          <div class="form-group col-sm-3">
            <label>Valor Desconto</label>
            <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.valorDesconto"
              (blur)="calcularValorTotal()" />
          </div>
          <div class="form-group col-sm-3">
            <label>Valor Total</label>
            <!-- To do Fernando -->
            <input class="form-control text-right" type="text" input-text="right" [disabled]="true"
              [(ngModel)]="pedidoItem.valorTotal" />
          </div>
        </div>
        <!-- *ngIf="pedido.pedidoTipo.id === 1 || pedido.pedidoTipo.id === 2"-->
        <!-- <div class="form-group col-sm-6">
    <label>Tipo de Desconto</label>
    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesDesconto"
      placeholder="Selecione forma desconto" [(ngModel)]="pedidoItem.tipoDesconto.id"></p-dropdown>
  </div> -->
      </div>
      <!-- Final do form do modal-->
      <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px">
        <div class="ui-g-12"></div>
      </div>

      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="exibirItemPedido = false">
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <div class="d-inline-block {{
            isReadOnly ? 'lks-form-grid-readOnly' : ''
          }}">
          <button type="button" class="btn btn-danger" *ngIf="botaoEditar && !isReadOnly"
            (click)="excluirItemLista(pedidoItem.id)">
            <i class="fas fa-times"></i> Excluir
          </button>
          <button type="button" class="btn btn-primary" *ngIf="!botaoEditar" (click)="salvarItemLista(null)">
            <i class="fas fa-check"></i> Salvar
          </button>
          <button type="button" class="btn btn-primary" *ngIf="botaoEditar && !isReadOnly"
            (click)="alterarItemLista(pedidoItem.id)">
            <i class="fas fa-pencil-alt"></i> Alterar
          </button>
        </div>
      </p-footer>
    </p-dialog>
    <!---final Modal Item-->

    <!---Fim Formas Pgtos-->
  </div>
</mat-card>

<p-dialog header="Cancelar Nota Fiscal" [(visible)]="modalCancelarNfe" width="700" [modal]="true" styleClass="lks-modal"
  [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body" [closable]="true">
  <div class="form-row" *ngIf="modalCancelarNfe">
    <div class="form-group col-sm-12">
      <label>Motivo</label>
      <input class="form-control" type="text" pInputText [(ngModel)]="msgCancelamentoNfe" />
    </div>
  </div>
  <!--<div class="ui-g">
    <div class="ui-g-12">
      <p-growl life="5000" [value]="msgs" (click)="hideMessage()"></p-growl>
    </div>
  </div>-->
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalCancelarNfe = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="cancelarNfe()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Carta Correção Nota Fiscal" [(visible)]="modalCartaCorrecao" width="700" [modal]="true"
  styleClass="lks-modal" [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body" [closable]="true">
  <div class="form-row" *ngIf="modalCartaCorrecao">
    <div class="form-group col-sm-12">
      <label>Motivo</label>
      <input class="form-control" type="text" pInputText [(ngModel)]="msgCartaCorrecaoNfe" />
    </div>
  </div>
  <!--<div class="ui-g">
    <div class="ui-g-12">
      <p-growl life="5000" [value]="msgs" (click)="hideMessage()"></p-growl>
    </div>
  </div>-->
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalCartaCorrecao = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="enviarCartaCorrecao()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>

<!-- MODAL VINCULAR ITEM -->
<p-dialog header="Referenciar NFe" [(visible)]="vincularItens" [modal]="true" [style]="{ width: '1500px' }"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="header-box-content d-flex align-items-center justify-content-between mb-2">
    <div style="display: flex;">
      <app-toolbar [value]="toolbarXml"></app-toolbar>
    </div>
  </div>
  <app-skeleton [config]="skeletonConfigItens">
    <p-table class="table-system" #dt12 [value]="itensNfsParticipantes" [rows]="rows" [paginator]="true"
      [totalRecords]="itensNfsParticipantes.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
      [style]="{ width: '100%' }" [(selection)]="itensXmlSelecionados">
      <ng-template pTemplate="header" let-rowData>
        <tr>
          <th class="text-center tableHeaderCheckbox">
            <span class="check-all p-0">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </span>
          </th>
          <th class="text-left" style="width: 15%">
            <span class="text-overflow-dynamic-container" pSortableColumn="item_f">
              <span class="text-overflow-dynamic-ellipsis">ITEM <p-sortIcon field="item_f"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'item_f', 'contains')" />
          </th>
          <th class="text-left" style="width: 15%">
            <span class="text-overflow-dynamic-container" pSortableColumn="item_relac">
              <span class="text-overflow-dynamic-ellipsis">ITEM RELACIONADO
                <p-sortIcon field="item_relac"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'item_relac', 'contains')" />
          </th>
          <th class="text-left" style="width: 15%">
            <span class="text-overflow-dynamic-container" pSortableColumn="operacao">
              <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon field="operacao"> </p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'operacao', 'contains')" />
          </th>
          <th class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="cfop">
              <span class="text-overflow-dynamic-ellipsis">CFOP <p-sortIcon field="cfop"> </p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'cfop', 'contains')" />
          </th>
          <th class="text-right" style="width: 10%">
            <span class="text-overflow-dynamic-container" pSortableColumn="valor_unit">
              <span class="text-overflow-dynamic-ellipsis">VL UNIT <p-sortIcon field="valor_unit"> </p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'valor_unit', 'contains')" />
          </th>
          <th class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="qtde">
              <span class="text-overflow-dynamic-ellipsis">QUANTIDADE <p-sortIcon field="qtde">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'qtde', 'contains')" />
          </th>
          <th class="text-right" style="width: 10%">
            <span class="text-overflow-dynamic-container" pSortableColumn="valor_tot">
              <span class="text-overflow-dynamic-ellipsis">VL TOTAL <p-sortIcon field="valor_tot">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'valorTotal', 'contains')" />
          </th>
          <th class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="unidade_cod_nf">
              <span class="text-overflow-dynamic-ellipsis">UN. NF <p-sortIcon field="unidade_cod_nf">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'unidade_cod_nf', 'contains')" />
          </th>
          <th class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="unidade_cod_item">
              <span class="text-overflow-dynamic-ellipsis">UN. ITEM <p-sortIcon field="unidade_cod_item">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'unidade_cod_item', 'contains')" />
          </th>
          <th class="text-right" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="fator">
              <span class="text-overflow-dynamic-ellipsis">FATOR <p-sortIcon field="fator">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'fator', 'contains')" />
          </th>
          <th class="text-right" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="qtde_final">
              <span class="text-overflow-dynamic-ellipsis">QTDE FINAL <p-sortIcon field="qtde_final">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt12.filter($event.target.value, 'qtde_final', 'contains')" />
          </th>
          <th class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container" pSortableColumn="relacionado">
              <span class="text-overflow-dynamic-ellipsis">RELACIONADO <p-sortIcon field="relacionado">
                </p-sortIcon></span>
            </span>
            <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="vinculadoOpt"
              defaultLabel="Selecione" [filter]="false" (onChange)="dt12.filter($event.value, 'relacionado', 'equals')">
            </p-multiSelect>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="text-center tableCheckbox">
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td class="text-left" style="width: 15%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.item_f }}">{{ rowData.item_f }}</span>
            </span>
          </td>
          <td class="text-left" style="width: 15%">
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesItensXml" [virtualScroll]="true"
              [itemSize]="30" placeholder="Selecione o item" [(ngModel)]="rowData.item_id"
              (onChange)="buscarMelhorOperacao(rowData);buscarInfoUnidadeMedida(rowData)" appendTo="body">
            </p-dropdown>
          </td>
          <td class="text-left" style="width: 15%">
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoXml" [virtualScroll]="true"
              [itemSize]="30" placeholder="Selecione a operação" [(ngModel)]="rowData.operacao_regra_id"
              (onChange)="replicarOperacaoXml(rowData)" appendTo="body">
            </p-dropdown>
          </td>
          <td class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cfop }}">{{ rowData.cfop }}</span>
            </span>
          </td>
          <td class="text-right" style="width: 10%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valor_unit | money }}">{{
                rowData.valor_unit | money }}</span>
            </span>
          </td>
          <td class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.qtde }}">{{ rowData.qtde }}</span>
            </span>
          </td>
          <td class="text-right" style="width: 10%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valor_tot | money }}">{{
                rowData.valor_tot | money }}</span>
            </span>
          </td>
          <td class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.unidade_cod_nf }}">{{
                rowData.unidade_cod_nf }}</span>
            </span>
          </td>
          <td class="text-center" style="width: 5%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.unidade_cod_item }}">{{
                rowData.unidade_cod_item }}</span>
            </span>
          </td>
          <td pEditableColumn class="text-right" style="width: 5%">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="form-row">
                  <div class="form-group col-sm-12 mb-0">
                    <input class="form-control" [(ngModel)]="rowData.fator" type="number"
                      (keyup.enter)="calcularQtdeFinal(rowData)" (blur)="calcularQtdeFinal(rowData)"
                      (keyup)="calcularQtdeFinal(rowData)" />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="output">
                <span matTooltip="{{ rowData.fator }}" class="overflow-ellipse text-right" style="cursor: pointer">{{
                  rowData.fator }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="text-right" style="width: 5%">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.qtde_final }}">{{ rowData.qtde_final
                }}</span>
            </span>
          </td>
          <td class="text-center" style="width: 5%">
            <i *ngIf="!rowData.relacionado" class="fas fa-circle" style="color: yellow;"></i>
            <i *ngIf="rowData.relacionado" class="fas fa-circle" style="color: green;"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="13" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro
              Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </app-skeleton>
</p-dialog>

<!-- MODAL PAGAMENTO/RATEIO PEDIDO -->
<p-dialog header="Criação pedido lote" [(visible)]="modalPagRateio" [modal]="true" [style]="{ width: '900px' }"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title mt-0">Informações de Pagamento</h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>DATA ENTRADA<span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="informacoesPagamento.dtEntrada"
        [monthNavigator]="true" appendTo="body" [yearNavigator]="true" [defaultDate]="informacoesPagamento.dtEntrada"
        yearRange="2010:2030">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>FORMA DE PAGAMENTO <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="informacoesPagamento.formaPagamentoId"
        [options]="opcoesFormaPagamento" appendTo="body" (onChange)="buscarContaFormaPag()"></p-dropdown>
    </div>
    <div class="form-group col-sm-4">
      <label>CONTA <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="informacoesPagamento.empresaContaId"
        [options]="opcoesContas" appendTo="body"></p-dropdown>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title mt-0">Rateio (válido para os itens da nota sem rateio)</h3>
      <mat-slide-toggle class="mr-2" [(ngModel)]="aplicarRateioTodosItens"> Aplicar esse rateio a <u>todos</u> os itens
        da nota </mat-slide-toggle>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <p-table class="table-system mt-2 d-block" [value]="informacoesRateio" [style]="{ width: '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 40%" class="text-left">Centro de Custo</th>
            <th style="width: 30%" class="text-left">Projeto</th>
            <th style="width: 20%" class="text-right">Porcentagem</th>
            <th style="width: 10%" class="text-center">Ação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td pEditableColumn style="width: 40%">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <div class="form-row">
                    <div class="form-group col-sm-12 mb-0">
                      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"
                        [(ngModel)]="rowData.cadCcus" placeholder="Selecione um centro de custo"
                        (onChange)="buscarProjetoRelacionado(rowData)" appendTo="body"></p-dropdown>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="output">
                  <span matTooltip=" {{ rowData.cadCcus.codCcus }} - {{rowData.cadCcus.descrCcus}}"
                    class="overflow-ellipse text-left" style="cursor: pointer">{{ rowData.cadCcus.codCcus }} - {{
                    rowData.cadCcus.descrCcus }}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn style="width: 30%">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <div class="form-row">
                    <div class="form-group col-sm-12 mb-0">
                      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesProjeto"
                        [(ngModel)]="rowData.cadProj" placeholder="Selecione um projeto" appendTo="body"></p-dropdown>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="output">
                  <span matTooltip=" {{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}"
                    class="overflow-ellipse text-left" style="cursor: pointer">{{ rowData.cadProj.codProj }} - {{
                    rowData.cadProj.descrProj }}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn style="width: 20%">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <div class="form-row">
                    <div class="form-group col-sm-12 mb-0">
                      <input class="form-control" [(ngModel)]="rowData.porcentagem" type="number" />
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="output">
                  <span matTooltip="{{ rowData.porcentagem | money }}" class="overflow-ellipse text-right"
                    style="cursor: pointer">{{ rowData.porcentagem | money }}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td class="text-center" style="width: 10%">
              <span class="delete" matTooltip="Excluir" (click)="removerRateioXml(rowData)">
                <i class="far fa-trash-alt"></i></span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col">
      <button class="btn btn-sm btn-info mt-2" (click)="adicionarRateioXml()">
        <i class="fas fa-plus"></i> Adicionar Rateio
      </button>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalPagRateio = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="salvarInfoPedido()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>
<div *ngIf="isLoadingInProgress" class="loading-layer">
  <h6>Processamento em andamento, favor aguardar... {{loadingProgress}}%</h6>
  <p-progressBar [value]="loadingProgress" style="width: 40% !important"></p-progressBar>
</div>


<!---Modal Formas Pgtos-->
<p-dialog header="Formas Pagamentos" [(visible)]="modalFormaPgto" [style]="{ width: '800px' }" [modal]="true"
  styleClass="pagamento-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="" *ngIf="modalFormaPgto">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-divide"></i> Parcelas</h3>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Qtde Parcelas</label>
        <input class="form-control text-left" type="number" pInputText [(ngModel)]="qtdParcelas" min="0" />
      </div>
      <div class="form-group pull-left">
        <label>Primeiro Venc.</label>
        <p-calendar class="type-p-calendar" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
          yearRange="2010:2030" [(ngModel)]="primeroVencimento" dateFormat="dd/mm/yy" [defaultDate]="defaultDate">
        </p-calendar>
      </div>
      <div class="form-group col-sm-2" *ngIf="pedido.empresaConta.id && pedido.formaPagamento.id">
        <label style="visibility: hidden">DO NOT REMOVE</label>
        <div class="btn btn-sm btn-info" (click)="formaPgtoCalcular(true)">
          <i class="fas fa-calculator"></i> Calcular
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-12">
        <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px">
          <div class="ui-g-12"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="tabela_edit_VCTOS">
    <p-table [value]="pedido.pedidoParcela" class="table-system" [scrollable]="true" scrollHeight="300px">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 9%" class="text-center">Parcela</th>
          <th style="width: 16%" class="text-center">Vencimento</th>
          <th style="width: 25%" class="text-right">Valor</th>
          <th style="width: 25%">Tipo</th>
          <th style="width: 25%">Histórico</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td pEditableColumn style="width: 9%" class="text-center">
            <p-cellEditor>
              <ng-template pTemplate="output">{{
                rowData.parcela
                }}</ng-template>
              <ng-template pTemplate="input">{{
                rowData.parcela
                }}</ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn style="width: 16%" class="text-center">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="col-sm-12 p-0">
                  <p-calendar appendTo="body" class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true"
                    yearRange="2010:2030" [(ngModel)]="rowData.dtVencimento" dateFormat="dd/mm/yy">
                  </p-calendar>
                </div>
              </ng-template>
              <ng-template pTemplate="output">{{
                rowData.dtVencimento | date: "dd/MM/yyyy"
                }}</ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn style="width: 25%" class="text-right">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="col-sm-12 p-0">
                  <input class="form-control text-right" type="text" [(ngModel)]="rowData.valor" money
                    (keyup)="calculaValorParcelas()" />
                </div>
              </ng-template>
              <ng-template pTemplate="output">{{
                rowData.valor | money
                }}</ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn style="width: 25%">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="col-sm-12 p-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposBaixas"
                    placeholder="Selecione" [(ngModel)]="rowData.tipoBaixa.id"
                    (onChange)="setTipoBaixa($event, rowData)"></p-dropdown>
                </div>
              </ng-template>
              <ng-template pTemplate="output" class="overflow-ellipse">
                <span matTooltip="{{
                  rowData.tipoBaixa
                    ? rowData.tipoBaixa.descricao
                    : rowData.tipoBaixa
                }}">
                  {{
                  rowData.tipoBaixa.id
                  ? rowData.tipoBaixa.descricao
                  : "Clique para Editar"
                  }}
                </span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn style="width: 25%">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="col-sm-12 p-0">
                  <input class="form-control" type="text" [(ngModel)]="rowData.historico" />
                </div>
              </ng-template>
              <ng-template pTemplate="output">{{
                rowData.historico
                ? rowData.historico
                : "Clique para Editar"
                }}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td style="width: 9%"></td>
          <td style="width: 16%"></td>
          <td style="
            text-align: right;
            font-weight: bold;
            font-size: 14px;
            width: 25%;
          ">
            TOTAL: {{ valorTotalParcelas | money }}
          </td>
          <td style="width: 25%"></td>
          <td style="width: 25%"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-footer>
    <button class="btn btn-secondary" (click)="
      modalFormaPgto = false; qtdParcelas = null; primeroVencimento = null
    ">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="formaPgtoSalvar()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>


<p-dialog header="Buscar XML" [(visible)]="modalXml" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="row" style="width: 900px;">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-info-circle"></i> Chaves Para Buscar XML</h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <textarea class="form-control" rows="8" value="chavesBuscar"  [(ngModel)]="chavesBuscar"></textarea>
    </div>
  </div>

  <div class="form-row">
 <div class="col-sm-12">
      <div class="alert alert-warning alertcustom" role="alert">
        <i class="fas fa-warning"></i> Para informar mais de uma chave eletrônica, separe por vírgula (,), você receberá um e-mail com o resultado da consulta
      </div>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalXml = false;"><i class="fas fa-undo"></i>
      Fechar
    </button>
    <button class="btn btn-primary" (click)='buscarXmlsRfb()'>
      <i class="fas fa-refresh"></i> BUSCAR
    </button>
  </p-footer>

</p-dialog>
