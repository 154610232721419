<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="far fa-money-bill-alt"
                  style="font-size: 18px; vertical-align: middle; margin-top: -3px;"></i>Orçamento</h3>
              <div class="actions-card">
                <!--<button type="button" class="btn btn-xs btn-link">
                  <span class="material-icons full-icon" style="margin-right: -4px;" matTooltip="Valor Intercompany"
                    matTooltipPosition="above" (click)="valorIntercompany()">info</span>
                </button>-->
                <button type="button" class="btn btn-xs btn-link" (click)="razaoSocial()">
                  <span class="material-icons full-icon" matTooltip="Razão Social"
                    matTooltipPosition="above">text_snippet</span>
                </button>
                <button *ngIf="!disabledEditBtn" [disabled]="disabledEditBtn" type="button" class="btn btn-xs btn-link"
                  (click)="exibirRelacionamento = true">
                  <span class="material-icons full-icon" style="margin-right: -4px;" matTooltip="Edição"
                    matTooltipPosition="above">mode_edit</span>
                </button>
                <button *ngIf="!disabledAjusteBtn" [disabled]="disabledAjusteBtn" type="button"
                  class="btn btn-xs btn-link" (click)="exibirAjustes = true; novoAjuste =false;">
                  <span class="material-icons full-icon" style="margin-right: -4px;" matTooltip="Ajustes"
                    matTooltipPosition="above">mode_edit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="impressao()">
                  <span class="material-icons full-icon" matTooltip="Impressão" matTooltipPosition="above">print</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="alternarCentroCusto()">
                  <span class="material-icons full-icon" matTooltip="Aplicar Filtro"
                    matTooltipPosition="above">search</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideControlOrca()"
                  (click)="changeStateControlOrca()">
                  <span *ngIf="isCardControlOrca" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardControlOrca" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStateControlOrca>
            <div class="row">
              <div class="col-sm-12">

                <p-table class="table-system" [value]="despesas" [scrollable]="true" [style]="{width:'100%'}"
                  [paginator]="true" [pageLinks]="pageLinks" [rows]="rows" [rowsPerPageOptions]="rowsPerPage"
                  (onFilter)="onFilter($event)" (onHeaderCheckboxToggle)="checkAll()" (onRowSelect)="check($event)"
                  (onRowUnselect)="uncheck($event)" #dt1>

                  <ng-template pTemplate="header">
                    <tr>

                      <th class="text-center tableHeaderCheckbox">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </span>
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'classificacaoDesc'">
                          <span class="text-overflow-dynamic-ellipsis">CLASSIFICAÇÃO <p-sortIcon
                              [field]="'classificacaoDesc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'classificacaoDesc', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'areaDesc'">
                          <span class="text-overflow-dynamic-ellipsis">AREA <p-sortIcon [field]="'areaDesc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'areaDesc', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'subAreaDesc'">
                          <span class="text-overflow-dynamic-ellipsis">SUBAREA <p-sortIcon [field]="'subAreaDesc'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'subAreaDesc', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'segmento'">
                          <span class="text-overflow-dynamic-ellipsis">SEGMENTO <p-sortIcon [field]="'segmento'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'segmento', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'pcCodCta'">
                          <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL <p-sortIcon [field]="'pcCodCta'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'pcCodCta', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta2'">
                          <span class="text-overflow-dynamic-ellipsis">DESC CTA 2 <p-sortIcon [field]="'descrCta2'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'descrCta2', 'contains')">
                      </th>



                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ccCodCcus'">
                          <span class="text-overflow-dynamic-ellipsis">CENTRO CUSTO <p-sortIcon [field]="'ccCodCcus'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'ccCodCcus', 'contains')">
                      </th>

                      <th class="text-left" style="width: 100px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataForm'">
                          <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'dataForm'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'dataForm', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem'">
                          <span class="text-overflow-dynamic-ellipsis">ORIGEM <p-sortIcon [field]="'origem'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'origem', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'lote'">
                          <span class="text-overflow-dynamic-ellipsis">LOTE <p-sortIcon [field]="'lote'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'lote', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'documento'">
                          <span class="text-overflow-dynamic-ellipsis">DOCUMENTO <p-sortIcon [field]="'documento'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'documento', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'linha'">
                          <span class="text-overflow-dynamic-ellipsis">LINHA <p-sortIcon [field]="'linha'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'linha', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'itemContabil'">
                          <span class="text-overflow-dynamic-ellipsis">ITEM CONTÁBIL <p-sortIcon
                              [field]="'itemContabil'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'itemContabil', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCliFor'">
                          <span class="text-overflow-dynamic-ellipsis">COD CLI/FOR <p-sortIcon [field]="'codCliFor'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'codCliFor', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'razaoCliFor'">
                          <span class="text-overflow-dynamic-ellipsis">RAZÃO CLI/FOR <p-sortIcon
                              [field]="'razaoCliFor'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'razaoCliFor', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasiaCliFor'">
                          <span class="text-overflow-dynamic-ellipsis">FANTASIA CLI/FOR <p-sortIcon
                              [field]="'fantasiaCliFor'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'fantasiaCliFor', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'notaFiscal'">
                          <span class="text-overflow-dynamic-ellipsis">NOTA FISCAL <p-sortIcon [field]="'notaFiscal'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'notaFiscal', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'serie'">
                          <span class="text-overflow-dynamic-ellipsis">SÉRIE <p-sortIcon [field]="'serie'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'serie', 'contains')">
                      </th>



                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'numPedido'">
                          <span class="text-overflow-dynamic-ellipsis">NUM PEDIDO <p-sortIcon [field]="'numPedido'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'numPedido', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                          <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'historico', 'contains')">
                      </th>


                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                          <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'valor', 'contains')">
                      </th>

                      <th class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'observacoes'">
                          <span class="text-overflow-dynamic-ellipsis">OBSERVAÇÕES <p-sortIcon [field]="'observacoes'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'observacoes', 'contains')">
                      </th>

                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">

                      <td class="text-center tableCheckbox">
                        <p-tableCheckbox [value]="rowData">
                        </p-tableCheckbox>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.classificacaoDesc }}">{{rowData.classificacaoDesc }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.areaDesc }}">{{rowData.areaDesc }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.subAreaDesc }}">{{rowData.subAreaDesc }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.segmento }}">{{rowData.segmento }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.pcCodCta }}">{{rowData.pcCodCta }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.descrCta2 }}">{{rowData.descrCta2 }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.ccCodCcus }}">{{rowData.ccCodCcus }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 100px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{ rowData.data  | date : 'dd/MM/yyyy' }}">{{ rowData.data | date : 'dd/MM/yyyy'
                            }}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.origem }}">{{rowData.origem
                            }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.lote }}">{{rowData.lote
                            }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.documento }}">{{rowData.documento }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.linha }}">{{rowData.linha
                            }}</span>
                        </span>
                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.itemContabil }}">{{rowData.itemContabil }}</span>
                        </span>


                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.codCliFor }}">{{rowData.codCliFor }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.razaoCliFor }}">{{rowData.razaoCliFor }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.fantasiaCliFor }}">{{rowData.fantasiaCliFor }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.notaFiscal }}">{{rowData.notaFiscal }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.serie }}">{{rowData.serie
                            }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.numPedido }}">{{rowData.numPedido }}</span>
                        </span>

                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.historico }}">{{rowData.historico }}</span>
                        </span>
                      </td>


                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor }}">{{rowData.valor
                            }}</span>
                        </span>


                      </td>

                      <td class="text-left" style="width: 200px">

                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.observacoes }}">{{rowData.observacoes }}</span>
                        </span>

                      </td>
                    </tr>
                  </ng-template>

                </p-table>

                <div class="row">
                  <div class="col-sm-12" style="margin-top: 8px;">
                    <div class="alert alertcustom" [ngClass]="'alert-success'" role="alert" style="cursor: text;">
                      <strong>{{ idOrcamentos.length}}</strong> Itens Selecionados.
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<!---Modal RELACIONAMENTO-->
<p-dialog header="EDITAR LINHA" [(visible)]="exibirRelacionamento" styleClass="modal-initial" width="600" [modal]="true"
  [responsive]="true" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="editarClassificacao"> CLASSIFICAÇÃO </mat-slide-toggle>
    </div>
    <div class="col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="editarSegmento"> SEGMENTO </mat-slide-toggle>
    </div>
    <div class="col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="editarArea"> AREA </mat-slide-toggle>
    </div>
    <div class="col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="editarSubArea"> SUBÁREA </mat-slide-toggle>
    </div>
    <div class="col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="editarConta2"> DESCRIÇÃO CONTA 2 </mat-slide-toggle>
    </div>
  </div>

  <br>

  <div class="form-row"
    *ngIf="editarSegmento && !editarClassificacao &&  !editarArea &&  !editarSubArea  &&  !editarConta2">
    <div class="form-group col-sm-12">
      <label>Segmento</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="segmento" [options]="opcoesSegmento" [filter]="true"
        placeholder="Selecione o Segmento"></p-dropdown>
    </div>
  </div>

  <div class="form-row"
    *ngIf="!editarSegmento && !editarClassificacao &&  editarArea &&  !editarSubArea  &&  !editarConta2">
    <div class="form-group col-sm-12">
      <label>Area</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="area" [options]="opcoesArea" [filter]="true"
        placeholder="Selecione a Área"></p-dropdown>
    </div>
  </div>

  <div class="form-row"
    *ngIf="!editarSegmento && !editarClassificacao &&  !editarArea &&  editarSubArea  &&  !editarConta2">
    <div class="form-group col-sm-12">
      <label>SubArea</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="subarea" [options]="opcoesSubArea" [filter]="true"
        placeholder="Selecione a SubArea"></p-dropdown>
    </div>
  </div>

  <div class="form-row"
    *ngIf="!editarSegmento && editarClassificacao &&  !editarArea &&  !editarSubArea  &&  !editarConta2">
    <div class="form-group col-sm-12">
      <label>Classificacao</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="classificacao" [options]="opcoesClassificacao" [filter]="true"
        placeholder="Selecione a Classificação"></p-dropdown>
    </div>
  </div>

  <div class="form-row"
    *ngIf="!editarSegmento && !editarClassificacao &&  !editarArea &&  !editarSubArea  &&  editarConta2">
    <div class="form-group col-sm-12">
      <label>DESCRIÇÃO CONTA 2</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="descrCta2" [options]="opcoesDescrConta2" [filter]="true"
        placeholder="Selecione a Descrição Conta 2"></p-dropdown>
    </div>
  </div>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirRelacionamento = false"><i class="fas fa-undo"></i>
      Cancelar </button>
    <button type="button" class="btn btn-primary" (click)="salvarAlteracoes()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal RELACIONAMENTO-->

<!---Modal OBSERVAÇÃO-->
<p-dialog
  header="AJUSTES REF. AO MÊS {{dblinkedSessionService.periodo.month.value}}/{{dblinkedSessionService.periodo.year.value}}"
  [(visible)]="exibirAjustes" styleClass="modal-initial" width="800" [modal]="true" [responsive]="true" appendTo="body">

  <div class="row">
    <div class="col-sm-12">
      <button class="btn btn-sm btn-info" (click)="novoAjuste = true"><i class="fas fa-plus"></i> Novo Ajuste</button>
    </div>
  </div>

  <div class="row mt-2" *ngIf="!novoAjuste">
    <div class="col-sm-12">
      <p-table class="table-system mt-2 d-block" [value]="ajustesOrcamento" [style]="{width:'100%'}" [scrollable]="true"
        scrollHeight="200px">
        <ng-template pTemplate="header">
          <tr>
            <th style="width:20%">LINHA</th>
            <th style="width:30%">HISTÓRICO</th>
            <th style="width:10%">COD CLI/FOR</th>
            <th style="width:20%">USUÁRIO</th>
            <th style="width:10%">VALOR</th>
            <th style="width:10%" class="text-center">Ação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData" style="height:25px">
            <td>
              <span matTooltip="{{rowData.codCta}} - {{rowData.descCta}}" class="overflow-ellipse">{{rowData.codCta}} -
                {{rowData.descCta}}</span>
            </td>
            <td>
              <span matTooltip="{{rowData.historico}}" class="overflow-ellipse">{{rowData.historico}}</span>
            </td>
            <td>
              <span matTooltip="{{rowData.codCliFor}}" class="overflow-ellipse">{{rowData.codCliFor}}</span>
            </td>
            <td>
              <span matTooltip="{{rowData.usuario}}" class="overflow-ellipse">{{rowData.usuario}}</span>
            </td>
            <td>
              <span matTooltip="{{rowData.valor}}" class="overflow-ellipse">{{rowData.valor}}</span>
            </td>
            <td class="text-center" style="width:10%">
              <span class="delete" matTooltip="Excluir" (click)="excluirAjuste(rowData.id)"><i
                  class="far fa-trash-alt"></i></span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="6" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i>
                Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="form-row" *ngIf="novoAjuste">
    <div class="form-group col-sm-12">
      <label>Gerencial<span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="gerencial" [options]="opcoesGerencial" [filter]="true"
        placeholder="Selecione a Conta Gerencial"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>HISTÓRICO<span class="obrigatorio">*</span></label>
      <input class="form-control" [(ngModel)]="historico" type="text" />
    </div>
  </div>
  <div class="form-row" *ngIf="novoAjuste">

    <div class="form-group col-sm-6">
      <label>Valor<span class="obrigatorio">*</span></label>
      <input class="form-control text-right" [(ngModel)]="vlrAjustes" type="text" input-text="right" />
    </div>
    <div class="form-group col-sm-6">
      <label>COD. CLI/FOR</label>
      <input class="form-control text-right" [(ngModel)]="codCliFor" type="text" input-text="right" />
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirAjustes = false"><i class="fas fa-undo"></i>
      Cancelar </button>
    <button type="button" *ngIf="novoAjuste" class="btn btn-primary" (click)="salvarAjustes()"><i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal OBSERVAÇÃO-->

<!-- Inicio Modal Alternar Centro de custo FEITO-->
<p-dialog header="Aplicar Filtro" [(visible)]="modalAplicarFiltro" width="700" [modal]="true" class="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)">

      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Aplicar Filtro</label>
      <input style="min-height: 34px" class="form-control text-left" type="text" [(ngModel)]="filtro" />
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalAplicarFiltro=false'><i class="fas fa-undo"></i>
      Fechar</button>
    <button type="button" class="btn btn-primary" (click)="buscarInfoReal();"><i class="fas fa-check"></i>
      Aplicar</button>
  </p-footer>
</p-dialog>
<!--  Final Modal Alternar Centro de custo FEITO-->

<!-- Inicio Model Alternar periodo -->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" width="400" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)">

      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary"
      (click)='modalPeriodo=false; dataIni = null; dataFin = null; isProcurarPorData= false'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="isProcurarPorData = true;change();"><i
        class="fas fa-pencil-alt"></i> Alterar</button>
  </p-footer>
</p-dialog>
<!-- Final model Alternar periodo -->

<!-- Inicio Model Alternar periodo -->
<p-dialog header="Valor Intercompany" [(visible)]="exibirIntercompany" width="400" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Valor Intercompany do Mês
        {{dblinkedSessionService.periodo.month.value}}/{{dblinkedSessionService.periodo.year.value}}</label>
      <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="vlrIntercompany" />
    </div>
  </div>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='exibirIntercompany= false'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="salvarIntecompany();"><i class="fas fa-pencil-alt"></i>
      Salvar</button>
  </p-footer>
</p-dialog>
<!-- Final model Alternar periodo -->

<!-- Inicio Impressão -->
<p-dialog header="Impressão" [(visible)]="exibirImpressao" width="400" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">

      <label>Área</label>
      <p-multiSelect class="type-p-mult" [options]="opcoesGerencial" defaultLabel="Selecione as Linhas" [filter]="true"
        [(ngModel)]="linhasSelecionadas" placeholder="{0} itens selecionados"
        selectedItemsLabel="{0} itens selecionados" appendTo="body"></p-multiSelect>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='exibirImpressao= false'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="realizarImpressao();"><i class="fas fa-print"></i>
      Impressão</button>
  </p-footer>
</p-dialog>
<!-- Final model Alternar periodo -->


<!-- Inicio Razão -->
<p-dialog header="Razão Social" [(visible)]="exibirRazao" width="900" [modal]="true" styleClass="modal-initial"
  [responsive]="true" appendTo="body" [closable]="false">

  <!--

[rows]="rows" [paginator]="true"
                [totalRecords]="pedidosNegociacao.length" (onFilter)="onFilterPedido($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}"

  -->

  <div class="col-sm-12">
    <p-table class="table-system" #dt10 [value]="listaRazoes" [rows]="20" [paginator]="true" [pageLinks]="pageLinks"
      [rowsPerPageOptions]="rowsPerPage" selectionMode="true" [style]="{width:'100%'}" [scrollable]="true"
      scrollHeight="400px">
      <ng-template pTemplate="header">
        <tr>


          <th class="text-left" style="width: 30%;">
            <span [pSortableColumn]="'razaoCliFor'">NOME
              <p-sortIcon [field]="'razaoCliFor'"></p-sortIcon>
            </span>

            <input class="form-control form-control-sm" type="text"
              (input)="dt10.filter($event.target.value, 'razaoCliFor', 'contains')">
          </th>

          <th class="text-left" style="width: 30%;">
            <span [pSortableColumn]="'razaoSimplificada'">NOME
              <p-sortIcon [field]="'razaoSimplificada'"></p-sortIcon>
            </span>

            <input class="form-control form-control-sm" type="text"
              (input)="dt10.filter($event.target.value, 'razaoSimplificada', 'contains')">
          </th>

          <th class="text-left" style="width: 15%;">
            <span [pSortableColumn]="'codCta'">COD CTA
              <p-sortIcon [field]="'codCta'"></p-sortIcon>
            </span>

            <input class="form-control form-control-sm" type="text"
              (input)="dt10.filter($event.target.value, 'codCta', 'contains')">
          </th>

          <th class="text-left" style="width: 45%;">
            <span [pSortableColumn]="'historico'">HISTÓRICO
              <p-sortIcon [field]="'historico'"></p-sortIcon>
            </span>

            <input class="form-control form-control-sm" type="text"
              (input)="dt10.filter($event.target.value, 'historico', 'contains')">
          </th>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData" style="height:25px">
          <td>
            <span matTooltip="{{rowData.razaoCliFor}}" (click)="visualizarRazao(rowData)"
              class="overflow-ellipse">{{rowData.razaoCliFor}}</span>
          </td>
          <td>
            <span matTooltip="{{rowData.razaoSimplificada}}" (click)="visualizarRazao(rowData)"
              class="overflow-ellipse">{{rowData.razaoSimplificada}}</span>
          </td>
          <td>
            <span matTooltip="{{rowData.codCta}}" (click)="visualizarRazao(rowData)"
              class="overflow-ellipse">{{rowData.codCta}}</span>
          </td>
          <td>
            <span matTooltip="{{rowData.historico}}" (click)="visualizarRazao(rowData)"
              class="overflow-ellipse">{{rowData.historico}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='exibirRazao= false'><i class="fas fa-undo"></i>
      fechar</button>
    <!--<button type="button" class="btn btn-primary" (click)="realizarImpressao();"><i
        class="fas fa-print"></i> Impressão</button>-->
  </p-footer>
</p-dialog>
<!-- Final model Alternar periodo -->


<p-dialog header="Editar Razão Social" [(visible)]="exibirRazaoEdit" width="900" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="false">

  <!--

[rows]="rows" [paginator]="true"
                [totalRecords]="pedidosNegociacao.length" (onFilter)="onFilterPedido($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}"

  -->
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>RAZÃO SOCIAL</label>
      <input class="form-control" type="text" pInputText [(ngModel)]="razaoSocialEdit" [disabled]=true />
    </div>
    <div class="form-group col-sm-4">
      <label>RAZÃO SIMPLIFICADO</label>
      <input class="form-control" type="text" pInputText [(ngModel)]="razaoSocialSimplificadaEdit" />
    </div>
    <div class="form-group col-sm-4">
      <label>CONTA CONTÁBIL</label>
      <input class="form-control" type="text" pInputText [(ngModel)]="codCtaContabil" [disabled]=true />
    </div>


    <div class="form-group col-sm-4">
    </div>

  </div>

  <div class="form-row">
    <div class="form-group col-sm-4">
      <mat-slide-toggle [(ngModel)]="alterarRazaoOcorrencia">
        Somente Esta
      </mat-slide-toggle>
    </div>
    <div class="form-group col-sm-4">
      <mat-slide-toggle [(ngModel)]="alterarRazaoMes">
        Todos deste mês
      </mat-slide-toggle>
    </div>

    <div class="form-group col-sm-4">
      <mat-slide-toggle [(ngModel)]="alterarRazaoGeral">
        Todo período
      </mat-slide-toggle>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <mat-slide-toggle [(ngModel)]="alterarPelaContaContabil">
          Alterar pela Conta Contábil
        </mat-slide-toggle>
      </div>
      </div>

  </div>




  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='exibirRazaoEdit= false'><i class="fas fa-undo"></i>
      fechar</button>
    <button type="button" class="btn btn-primary" (click)="salvarAlteracao();"><i class="fas fa-save"></i>
      Salvar</button>
  </p-footer>
</p-dialog>
