<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>

    <div *ngIf="!vinculaGrupoOperacao">
      <div class="box-content pt-2">
        <div class="pull-right align-down-table">
          <button type="button" class="btn btn-link" (click)="dt1.exportCSV()"><i class="fas fa-file-excel excel"></i>
            Exportar para Excel</button>
        </div>
        <div class="clearfix"></div>
        <p-table class="table-system" #dt1 [columns]="colslistaCtrl" [value]="listaCtrl" [rows]="rows"
          [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
          immutable="false" selectionMode="single">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 40px;">

              </th>
              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeFantasia'">
                  <span class="text-overflow-dynamic-ellipsis">EMPRESA <p-sortIcon [field]="'nomeFantasia'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'nomeFantasia', 'contains')">
              </th>
              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeFluxo'">
                  <span class="text-overflow-dynamic-ellipsis">NOME FLUXO <p-sortIcon [field]="'nomeFluxo'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'nomeFluxo', 'contains')">
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'createTimestamp'">
                  <span class="text-overflow-dynamic-ellipsis">CRIADO EM <p-sortIcon [field]="'createTimestamp'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'createTimestamp', 'contains')">
              </th>
              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'userNome'">
                  <span class="text-overflow-dynamic-ellipsis">USUÁRIO <p-sortIcon [field]="'userNome'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'userNome', 'contains')">
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'changeTimestamp'">
                  <span class="text-overflow-dynamic-ellipsis">ALTERADO EM <p-sortIcon [field]="'changeTimestamp'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'changeTimestamp', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 40px;">
                <p-checkbox (click)="selectCheckbox(rowData.id,rowData)" [(ngModel)]="rowData.selected">
                </p-checkbox>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                    matTooltip="{{ rowData.nomeFantasia }}">{{ rowData.nomeFantasia }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                    matTooltip="{{rowData.nomeFluxo}}">{{rowData.nomeFluxo }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                    matTooltip="{{rowData.createTimestamp  | timezone | date:'dd/MM/yyyy'}}">{{rowData.createTimestamp |
                    timezone | date:'dd/MM/yyyy'}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                    matTooltip="{{rowData.userNome}}">{{rowData.userNome }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                    matTooltip="{{rowData.changeTimestamp  | timezone | date:'dd/MM/yyyy'}}">{{rowData.changeTimestamp |
                    timezone | date:'dd/MM/yyyy'}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="6" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </mat-card-content>

  <!---Modal Fluxo de caixa FEITO-->
  <p-dialog header="Controle Fluxo de Caixa" [(visible)]="modalFluxoCaixa" [style]="{width: '600px'}" [modal]="true"
    styleClass="controle-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">

    <div *ngIf="modalFluxoCaixa">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome do fluxo</label>
          <input class="form-control" [(ngModel)]="fluxoCaixa.nomeFluxo">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Conta Padrão</label>
          <!--(ngModelChange)="loadAdms()"-->
          <p-multiSelect class="type-p-mult" [(ngModel)]="fluxoCaixa.empresaContas" [options]="opcoesEmpresaConta"
            defaultLabel="Adicionar / remover contas" [filter]="true" placeholder="{0} itens selecionados"
            selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalFluxoCaixa = false;"><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="fluxoCaixaSalvar((fluxoCaixa.id>0))"><i
          class="fas fa-check"></i> Salvar</button>
    </p-footer>
  </p-dialog>
  <!---Fim Modal Fluxo de caixa-->

  <!---Modal grupo Fluxo de caixa FEITO-->
  <p-dialog header="Controle Grupos Fluxo de Caixa" [(visible)]="modalFluxoCaixaGrupos" [style]="{width: '800px'}"
    [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

    <div *ngIf="modalFluxoCaixaGrupos">

      <div class="form-row">
        <div class="form-group col-sm-2">
          <label>Linha</label>
          <input class="form-control" [(ngModel)]="grupoFluxoCaixa.codigoGrupo">
        </div>
        <div class="form-group col-sm-6">
          <label>Grupo</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposFluxoCaixa"
            placeholder="Selecione" [(ngModel)]="grupoFluxoCaixa.tipoGrupoFluxo.id"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição</label>
          <input class="form-control" [(ngModel)]="grupoFluxoCaixa.descricao">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="pull-right">
            <button type="button" class="btn btn-sm btn-secondary" *ngIf="grupoFluxoCaixa.id!==undefined"
              (click)="cancelarEdicaoGrupo()">Cancelar</button>
            <button type="button" class="btn btn-sm btn-info"
              (click)="grupoFluxoCaixaAdd()">{{((grupoFluxoCaixa.id===undefined)?' Adicionar':' Alterar')}}</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mt-3">
          <p-table class="table-system" [value]="fluxoCaixa.grupoFluxoCaixa" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:10%;" class="text-center">Linha</th>
                <th style="width:57%;">Descrição</th>
                <th style="width:17%;">Tipo</th>
                <th style="width:15%;" class="text-center">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td style="width:10%;" class="text-center" (click)="editarGrupoFluxoCaixa(rowData)">
                  {{ rowData.codigoGrupo}}
                </td>
                <td style="width:60%;" (click)="editarGrupoFluxoCaixa(rowData)">
                  {{ rowData.descricao }}
                </td>
                <td style="width:15%;" (click)="editarGrupoFluxoCaixa(rowData)">
                  {{ rowData.tipoGrupoFluxo.descricao }}
                </td>

                <td style="width:15%;" class="text-center">
                  <span style="min-width: 20px;" class="edit d-inline-block" matTooltip="Editar Grupo"
                    (click)="editarGrupoFluxoCaixa(rowData)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span style="min-width: 20px;" *ngIf="rowData.tipoGrupoFluxo.id===1 || rowData.tipoGrupoFluxo.id===4"
                    class="edit d-inline-block" matTooltip="Saldo Inical">
                    <i style="cursor: default;" class="fas fa-dollar-sign"></i>
                  </span>
                  <span style="min-width: 20px;" *ngIf="rowData.tipoGrupoFluxo.id===2" class="shared d-inline-block"
                    matTooltip="Compor conteúdo do grupo" (click)="criarAliasGrupo(rowData)">
                    <i class="fas fa-share-alt"></i>
                  </span>
                  <span style="min-width: 20px;" *ngIf="rowData.tipoGrupoFluxo.id===3" class="formula d-inline-block"
                    matTooltip="Adicionar Formula" (click)="openModalFormulaDetal(rowData)">
                    <i class="fas fa-square-root-alt"></i>
                  </span>
                  <span style="min-width: 20px;" class="delete d-inline-block" matTooltip="Excluir Grupo"
                    (click)="removerGrupoFluxoCaixa(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalFluxoCaixaGrupos = false;"><i
          class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="grupofluxoCaixaSalvar()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
  <!---Fim Modal grupo Fluxo de caixa-->

  <!---Modal Alias linhas Fluxo de caixa FEITO-->
  <p-dialog header="Composição: {{ grupoFluxoCaixa.descricao }} " [(visible)]="modalAliasGrupo"
    [style]="{width: '700px'}" [modal]="true" styleClass="composicao-altura-inicial" [responsive]="true" appendTo="body"
    [closable]="true">

    <div *ngIf="modalAliasGrupo">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome</label>
          <input class="form-control" [(ngModel)]="aliasGrupo.nomeAlias">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-2">
          <label>Codigo</label>
          <input class="form-control" [(ngModel)]="aliasGrupo.codigoAlias" (keypress)="numberOnly($event)" type="text">
        </div>
        <div class="form-group col-sm-4">
          <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
          <button type="button" class="btn btn-sm btn-secondary" *ngIf="aliasGrupo.id!==undefined"
            (click)="cancelarEdicaoAlias()">Cancelar</button>
          <button type="button" class="btn btn-sm btn-info"
            (click)="aliasGruposFluxoCaixaAdd()">{{((aliasGrupo.id===undefined)?'Adicionar':'Alterar')}}</button>
        </div>
      </div>

      <!--    <div class="lks-modal-button" style="text-align: right;">
            <div class="input-12" input-vertical="middle">
                <div class="lks-form-grid">
                <div class="input-10"  input-text="right"><img *ngIf="aliasGrupo.id!==undefined" height="30px" style="filter: grayscale(1) brightness(2);" matTooltip="Cancelar" (click)="cancelarEdicaoAlias()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABhUlEQVR4Ae2agYbDQBRFB1BV9hcKQOHJFQjILxcLoOiiYP8lSlJgVlnDYtjoHA3ueVDAuTQzM29eMsYYY4wxxhhjzP/Qhy4RCSBCl9MhsZwO+lbWFAHoT8q6oRG0101ZuXWEov+sm/ac/lW51BR9M/3+qV/qikQ47n71S3X36Nvod3flP3U97lJr9KmsXInQSr9Ud06tibF7VCK013/EmNL2I/D6bAReH40Qw3p9NsKwTl/zKn0+guYYXtPXXPT5CBWB4SX9IbWBleD10Qi8PhohRkQfiDAC6xdDfTmE9/E6yIbE67MHMl4fjcDroxF4fTSCFi3lN3IdxSLUC+hpAJdzRp8nQhOvz0fg9ekImL4D8H8hf8T8MtonAGwjg48R/GlISz2CD3M+TvMXGl8p+Uv9kHiItgrQFdp+Y8utRb656/Y68EbDPHAA+j34xORHvu0/s27/odujBt2ZuR5WIpzRcZuiH/y4DTnwFPzAk0fONjD0B4xdfr1/7NIYY4wxxhhjjDE/haco+u7W9o4AAAAASUVORK5CYII="></div>
                <div class="botao-adicionar" (click)="aliasGruposFluxoCaixaAdd()">{{((aliasGrupo.id===undefined)?'Adicionar':'Alterar')}}</div></div>
              </div>
          </div> -->

      <div class="row">
        <div class="col-sm-12">
          <p-table class="table-system" [value]="grupoFluxoCaixa.aliasGrupoList" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <!-- <th style="width:50px;">Linha</th> -->
                <th style="width:10%;">Código</th>
                <th style="width:75%;">Nome</th>
                <th style="width:15%;" class="text-center">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <!-- <td style="height: 33px;" (click)="editarAliasGrupo(rowData)" >
                        {{ rowData.posicaoAlias}}
                    </td> -->
                <td style="width:10%;" (click)="editarAliasGrupo(rowData)">
                  {{ rowData.codigoAlias}}
                </td>
                <td style="width:75%;" (click)="editarAliasGrupo(rowData)">
                  {{ rowData.nomeAlias }}
                </td>
                <td style="width:15%;" class="text-center">
                  <span style="min-width: 20px;" class="edit d-inline-block" matTooltip="Editar Grupo"
                    (click)="editarAliasGrupo(rowData)">
                    <i class="fas fa-edit"></i>
                  </span>

                  <span style="min-width: 20px;" class="shared d-inline-block" matTooltip="Relacionar Grupo e Operações"
                    (click)="vincularGrupoOperacao(rowData, true)">
                    <i class="fas fa-share-alt"></i>
                  </span>

                  <span style="min-width: 20px;" class="delete d-inline-block" matTooltip="Excluir Grupo"
                    (click)="removerAliasGrupo(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>


    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalAliasGrupo = false;"><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="salvarAliasGrupo()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
  <!---Modal Alias linhas Fluxo de caixa-->

  <!---inicio formulário composição FEITO -->
  <div id="composicao_linha" *ngIf="vinculaGrupoOperacao">

    <div id="composicao" class="box-content pt-1 pr-3 pl-3 pb-3">
      <div class="form-row">
        <div class="form-group col-sm-3 mb-0">
          <label>Grupo</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesGrupoFluxoCaixa" placeholder="Selecione"
            [(ngModel)]="grupoFluxoCaixa.id" (ngModelChange)="comboAliasFluxoCaixa()"></p-dropdown>
        </div>
        <div class="form-group col-sm-3 mb-0">
          <label>Linha</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesAliasFluxoCaixa" placeholder="Selecione"
            [(ngModel)]="aliasGrupo.id" (ngModelChange)="vincularGrupoOperacao($event, false)"></p-dropdown>
        </div>
        <div class="form-group col mb-0">
          <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
          <button type="button" [disabled]="!(operacaoSelecionada.length > 0)"
            class="{{(operacaoSelecionada.length > 0)?'btn btn-sm btn-primary':'btn btn-sm btn-default'}}"
            (click)="adicionarOperacaoLista()"><i class="fas fa-check"></i> Adicionar</button>
          <button type="button" [disabled]="!(operacaoAdicionada.length > 0)"
            class="{{(operacaoAdicionada.length > 0)? 'btn btn-sm btn-danger' : 'btn btn-sm btn-default'}}"
            (click)="removerOperacaoLista()"><i class="fas fa-times"></i> Remover</button>
        </div>
      </div>
    </div>

    <!-- <div id="fundo_cinza" class='fundo-cinza'></div> -->
    <div class="box-content pt-3 pr-3 pl-3 pb-2 mt-2">
      <p-accordion [multiple]="true">
        <p-accordionTab header="Operações" [selected]="true">
          <p-table class="table-system" #dt2 [value]="listaOperacaoRegras" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false"
            selectionMode="single" (onFilter)="onFilterOrigem($event)">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 40px;">
                  <span class="check-all p-0">
                    <p-checkbox [disabled]="(filterOrigem.length === 0)" [ngModel]="selectAllOrigem"
                      (change)="onSelectAllOrigem($event)"></p-checkbox>
                  </span>
                </th>
                <th class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoRegra'">
                    <span class="text-overflow-dynamic-ellipsis">REGRA <p-sortIcon [field]="'descricaoRegra'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'descricaoRegra', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoRegra'">
                    <span class="text-overflow-dynamic-ellipsis">TIPO REGRA <p-sortIcon [field]="'tipoRegra'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'tipoRegra', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoES'">
                    <span class="text-overflow-dynamic-ellipsis">ENT/SAÍDA <p-sortIcon [field]="'tipoES'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'tipoES', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoOperacao'">
                    <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'tipoOperacao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'tipoOperacao', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 40px;">
                  <p-checkbox (click)="selectOperacao(rowData.id,rowData)" [(ngModel)]="rowData.selected">
                  </p-checkbox>
                </td>
                <td class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricaoRegra }}">{{
                      rowData.descricaoRegra }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.tipoRegra }}">{{
                      rowData.tipoRegra }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                      matTooltip="{{ rowData.tipoES }}">{{ rowData.tipoES }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.tipoOperacao}}">{{rowData.tipoOperacao }}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado, selecione um grupo!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>

        <p-accordionTab header="Composição">
          <p-table class="table-system" #dt3 [value]="listaOperacaoRegrasSelecionadas" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false"
            selectionMode="single" (onFilter)="onFilterDestino($event)">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 40px;">
                  <span class="check-all p-0">
                    <p-checkbox [ngModel]="selectAllDest" (change)="onSelectAllDestino($event)"></p-checkbox>
                  </span>
                </th>
                <th class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoRegra'">
                    <span class="text-overflow-dynamic-ellipsis">REGRA <p-sortIcon [field]="'descricaoRegra'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'descricaoRegra', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoRegra'">
                    <span class="text-overflow-dynamic-ellipsis">TIPO REGRA <p-sortIcon [field]="'tipoRegra'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'tipoRegra', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoES'">
                    <span class="text-overflow-dynamic-ellipsis">ENT/SAÍDA <p-sortIcon [field]="'tipoES'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'tipoES', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoOperacao'">
                    <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'tipoOperacao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'tipoOperacao', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 40px;">
                  <p-checkbox (click)="selectOperacaoRemove(rowData.id,rowData)" [(ngModel)]="rowData.selected">
                  </p-checkbox>
                </td>
                <td class="text-left" style="width: 60%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricaoRegra }}">{{
                      rowData.descricaoRegra }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.tipoRegra }}">{{
                      rowData.tipoRegra }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                      matTooltip="{{ rowData.tipoES }}">{{ rowData.tipoES }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.tipoOperacao}}">{{rowData.tipoOperacao }}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado, selecione uma linha!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </p-accordionTab>
      </p-accordion>
    </div>

  </div>
  <!---Fim formulário composição -->

  <!---Modal Formula Detalhes Fluxo de caixa FEITO-->
  <p-dialog header="Detalhar Fórmula: {{grupoFluxoCaixa.descricao}}" [(visible)]="modalFormulaDetal"
    [style]="{width: '700px'}" [modal]="true" styleClass="formula-altura-inicial" [responsive]="true" appendTo="body"
    [closable]="true">

    <div *ngIf="modalFormulaDetal">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Grupo</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesGrupoFluxoCaixa" placeholder="Selecione"
            [(ngModel)]="formulaDetalhe.grupoFluxoCaixa.id"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-2">
          <label>Operador</label>
          <input class="form-control" [(ngModel)]="formulaDetalhe.operador" maxlength="1">
        </div>
        <div class="form-group col">
          <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
          <button type="button" class="btn btn-sm btn-secondary" *ngIf="formulaDetalhe.id!==undefined"
            (click)="cancelarFormulaDetalhe()">Cancelar</button>
          <button type="button" class="btn btn-sm btn-info"
            (click)="formulaDetalheAdd()">{{((formulaDetalhe.id===undefined)?'Adicionar':'Alterar')}}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <p-table class="table-system" [value]="formulaDetalList" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:45%">Grupo</th>
                <th style="width:45%">Operador</th>
                <th style="width:10%;" class="text-center">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td style="width:45%" (click)="editarFormulaDetalhe(rowData)">
                  {{ rowData.grupoFluxoCaixa.descricao }}
                </td>
                <td style="width:45%" (click)="editarFormulaDetalhe(rowData)">
                  {{ ((rowData.operador === null || rowData.operador === undefined) ? '' :
                  (rowData.operador.indexOf('-') !== -1)?'Subtração(-)':(rowData.operador.indexOf('+') !==
                  -1)?'Adição(+)':'') }}
                </td>
                <td style="width:10%;" class="text-center">
                  <span class="edit" matTooltip="Editar" (click)="editarFormulaDetalhe(rowData)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="delete" matTooltip="Excluir" (click)="removerFormulaDetalhe(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="salvarFormulaDetalhe();"><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="salvarFormulaDetalhe();"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
  <!---Modal Formula Detalhes Fluxo de caixa-->

</mat-card>