<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content tableSpace">
              <ul class="list-inline filter-menu filter-card-header">
                <li class="list-inline-item" [ngClass]="{active: statusTela1}"><a
                    (click)="showTelaVinculacao('TELA_1')">Benefícios</a></li>
                <li class="list-inline-item" [ngClass]="{active: statusTela2}"><a
                    (click)="showTelaVinculacao('TELA_2')">Encargos</a></li>
                <li class="list-inline-item" [ngClass]="{active: statusTela3}"><a
                    (click)="showTelaVinculacao('TELA_3')">Eventos FP</a>
                </li>
                <li class="list-inline-item" [ngClass]="{active: statusTela4}"><a
                    (click)="showTelaVinculacao('TELA_4')">Salário/Rescisão</a>
                </li>
              </ul>
              <div class="actions-card">
                <div *ngIf="statusTela1">
                  <app-toolbar [value]="toolbarBenef"></app-toolbar>
                </div>
                <div *ngIf="statusTela2">
                  <app-toolbar [value]="toolbarEncar"></app-toolbar>
                </div>
                <div *ngIf="statusTela3">
                  <app-toolbar [value]="toolbarEvent"></app-toolbar>
                </div>
                <div *ngIf="statusTela4">
                  <app-toolbar [value]="toolbarSald"></app-toolbar>
                </div>
                <!-- <button *ngIf="statusTela1" type="button" class="btn btn-xs btn-link" matTooltip="Exportar Excel"
                  matTooltipPosition="above" (click)="dt1.exportCSV()"><i class="far fa-file-excel excel"></i> </button>
                <button *ngIf="statusTela2" type="button" class="btn btn-xs btn-link" matTooltip="Exportar Excel"
                  matTooltipPosition="above" (click)="dt2.exportCSV()"><i class="far fa-file-excel excel"></i> </button>
                <button *ngIf="statusTela3" type="button" class="btn btn-xs btn-link" matTooltip="Exportar Excel"
                  matTooltipPosition="above" (click)="dt3.exportCSV()"><i class="far fa-file-excel excel"></i> </button>
                <button *ngIf="statusTela4" type="button" class="btn btn-xs btn-link" matTooltip="Exportar Excel"
                  matTooltipPosition="above" (click)="dt4.exportCSV()"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardBenef()"
                  (click)="changeStateBenef()">
                  <span *ngIf="isCardBenef" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardBenef" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span> -->
                <!-- </button> -->
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateBenef>
            <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela1'}">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt1 [columns]="colsBenefLista" [value]="benefLista" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" (onRowUnselect)="onBenefUnselect($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">BENEFÍCIO <p-sortIcon [field]="'nome'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'nome', 'contains')">
                    </th>
                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ccus'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO CENTRO DE CUSTO <p-sortIcon
                            [field]="'tipo_ccus'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'tipo_ccus', 'contains')">
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD. CTA <p-sortIcon [field]="'cod_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'cod_cta', 'contains')">
                    </th>
                    <th class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_cta'">
                        <span class="text-overflow-dynamic-ellipsis">DESCR. CTA <p-sortIcon [field]="'descr_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descr_cta', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onBenefSelect(rowData)"
                          matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onBenefSelect(rowData)"
                          matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onBenefSelect(rowData)"
                          matTooltip="{{rowData.tipo_ccus}}">{{rowData.tipo_ccus}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onBenefSelect(rowData)"
                          matTooltip="{{rowData.cod_cta}}">{{rowData.cod_cta}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onBenefSelect(rowData)"
                          matTooltip="{{rowData.descr_cta}}">{{rowData.descr_cta}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <button type="button" style="padding: 0 !important;" (click)="onBenefSelect(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 22px">edit</span>
                      </button>
                      <button type="button" style="padding: 0 !important;" (click)="excluirBenef(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 18px">delete</span>
                      </button>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="6" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhuma vinculação encontrada!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>


            <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela2'}">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt2 [columns]="colsEncargoLista" [value]="encargoLista" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" (onRowUnselect)="onEncargoUnselect($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'encargo'">
                        <span class="text-overflow-dynamic-ellipsis">ENCARGO <p-sortIcon [field]="'encargo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'encargo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ccus'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO CENTRO DE CUSTO <p-sortIcon
                            [field]="'tipo_ccus'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'tipo_ccus', 'contains')">
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD. CTA <p-sortIcon [field]="'cod_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'cod_cta', 'contains')">
                    </th>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_cta'">
                        <span class="text-overflow-dynamic-ellipsis">DESCR. CTA <p-sortIcon [field]="'descr_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'descr_cta', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEncargoSelect(rowData)"
                          matTooltip="{{rowData.encargo}}">{{rowData.encargo}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEncargoSelect(rowData)"
                          matTooltip="{{rowData.tipo_ccus}}">{{rowData.tipo_ccus}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEncargoSelect(rowData)"
                          matTooltip="{{rowData.cod_cta}}">{{rowData.cod_cta}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEncargoSelect(rowData)"
                          matTooltip="{{rowData.descr_cta}}">{{rowData.descr_cta}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <button type="button" style="padding: 0 !important;" (click)="onEncargoSelect(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 22px">edit</span>
                      </button>
                      <button type="button" style="padding: 0 !important;" (click)="excluirEncargo(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 18px">delete</span>
                      </button>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhuma vinculação encontrada!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="statusTela3 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela3'}">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt3 [columns]="colsEventoLista" [value]="eventoLista" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" (onRowUnselect)="onEventoUnselect($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_evento'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod_evento'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'cod_evento', 'contains')">
                    </th>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_evento'">
                        <span class="text-overflow-dynamic-ellipsis">EVENTO <p-sortIcon [field]="'descr_evento'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'descr_evento', 'contains')">
                    </th>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_evento'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipo_evento'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'tipo_evento', 'contains')">
                    </th>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ccus'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO CENTRO DE CUSTO <p-sortIcon
                            [field]="'tipo_ccus'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'tipo_ccus', 'contains')">
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD. CTA <p-sortIcon [field]="'cod_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'cod_cta', 'contains')">
                    </th>
                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_cta'">
                        <span class="text-overflow-dynamic-ellipsis">DESCR. CTA <p-sortIcon [field]="'descr_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'descr_cta', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEventoSelect(rowData)"
                          matTooltip="{{rowData.cod_evento}}">{{rowData.cod_evento}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEventoSelect(rowData)"
                          matTooltip="{{rowData.descr_evento}}">{{rowData.descr_evento}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEventoSelect(rowData)"
                          matTooltip="{{rowData.tipo_evento}}">{{rowData.tipo_evento}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEventoSelect(rowData)"
                          matTooltip="{{rowData.tipo_ccus}}">{{rowData.tipo_ccus}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEventoSelect(rowData)"
                          matTooltip="{{rowData.cod_cta}}">{{rowData.cod_cta}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onEventoSelect(rowData)"
                          matTooltip="{{rowData.descr_cta}}">{{rowData.descr_cta}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <button type="button" style="padding: 0 !important;" (click)="onEventoSelect(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 22px">edit</span>
                      </button>
                      <button type="button" style="padding: 0 !important;" (click)="excluirEvento(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 18px">delete</span>
                      </button>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="7" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhuma vinculação encontrada!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="statusTela4 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela4'}">
              <p-table class="table-system" #dt4 [columns]="colsSalRecLista" [value]="salRecLista" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}" emptyMessage="Nenhuma vinculação encontrada" sortField="codigo"
                [sortOrder]="1" (onRowUnselect)="onSalarioUnselect($event)">

                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'nome'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'nome', 'contains')">
                    </th>
                    <th>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ccus'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO CENTRO DE CUSTOS <p-sortIcon
                            [field]="'tipo_ccus'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'tipo_ccus', 'contains')">
                    </th>
                    <th>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_cta'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD. CTA<p-sortIcon [field]="'cod_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'cod_cta', 'contains')">
                    </th>
                    <th>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_cta'">
                        <span class="text-overflow-dynamic-ellipsis">DESCR. CTA<p-sortIcon [field]="'descr_cta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'descr_cta', 'contains')">
                    </th>
                    <!-- <th class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowValue>
                  <tr [pSelectableRow]="rowValue">
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onSalarioSelect(rowValue)"
                          matTooltip="{{rowValue.nome}}">{{rowValue.nome}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onSalarioSelect(rowValue)"
                          matTooltip="{{rowValue.tipo_ccus}}">{{rowValue.tipo_ccus}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onSalarioSelect(rowValue)"
                          matTooltip="{{rowValue.cod_cta}}">{{rowValue.cod_cta}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"(click)="onSalarioSelect(rowValue)"
                          matTooltip="{{rowValue.descr_cta}}">{{rowValue.descr_cta}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhuma vinculação encontrada!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVA VINCULAÇÃO -->
<p-dialog header="Adicionar nova vinculação" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div *ngIf="isEvento" class="form-group col-sm-12">
      <label>Tipo Evento</label>
      <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione o tipo de evento"
        [options]="tipoEventoList" [(ngModel)]="tipoEventoSelecionado" (onChange)="getEventos()"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <div *ngIf="isBenef">
        <label>Benefício</label>
        <p-dropdown class="type-p-mult" [options]="benefList" [(ngModel)]="benefSelecionado"
          defaultLabel="Selecionar benefício(s)" [filter]="true" placeholder="{0} benefício(s) selecionado(s)"
          selectedItemsLabel="{0} benefício(s) selecionado(s)" [resetFilterOnHide]="true" appendTo="body">
        </p-dropdown>
      </div>
      <div *ngIf="isEncargo">
        <label>Encargo</label>
        <p-dropdown class="type-p-mult" [options]="encargoList" [(ngModel)]="encargoSelecionado"
          defaultLabel="Selecionar encargo(s)" [filter]="true" placeholder="{0} encargo(s) selecionado(s)"
          selectedItemsLabel="{0} encargo(s) selecionado(s)" [resetFilterOnHide]="true" appendTo="body"></p-dropdown>
      </div>
      <div *ngIf="isEvento">
        <label>Evento</label>
        <p-dropdown class="type-p-mult" [options]="eventoList" [(ngModel)]="eventoSelecionado"
          defaultLabel="Selecionar evento(s)" [filter]="true" placeholder="{0} evento(s) selecionado(s)"
          selectedItemsLabel="{0} evento(s) selecionado(s)" [resetFilterOnHide]="true" appendTo="body"></p-dropdown>
      </div>
      <div *ngIf="isSalario">
        <label>Descrição</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="descrList"
          [(ngModel)]="descrSelecionada" appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="form-group col-sm-12">
      <label>Conta contábil Administrativo</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a conta" [options]="contasList"
        [(ngModel)]="contaSelecionadaAdm" appendTo="body"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Conta contábil Produção</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a conta" [options]="contasList"
        [(ngModel)]="contaSelecionadaProd" appendTo="body"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button *ngIf="isBenef" type="button" class="btn btn-primary" (click)="addBenefVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="isEncargo" type="button" class="btn btn-primary" (click)="addEncargoVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="isEvento" type="button" class="btn btn-primary" (click)="addEventoVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="isSalario" type="button" class="btn btn-primary" (click)="addSalarioVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDITAR VINCULAÇÃO -->
<p-dialog header="Editar vinculação" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div *ngIf="contaSelecionada === null || contaSelecionadaAdm != null" class="form-group col-sm-12">
      <label>Conta contábil Administrativo</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a conta" [options]="contasList"
        [(ngModel)]="contaSelecionadaAdm" appendTo="body"></p-dropdown>
    </div>
    <div *ngIf="contaSelecionada === null || contaSelecionadaProd != null" class="form-group col-sm-12">
      <label>Conta contábil Produção</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a conta" [options]="contasList"
        [(ngModel)]="contaSelecionadaProd" appendTo="body"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button *ngIf="isBenef" type="button" class="btn btn-primary" (click)="editBenefVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="isEncargo" type="button" class="btn btn-primary" (click)="editEncargoVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="isEvento" type="button" class="btn btn-primary" (click)="editEventoVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="isSalario" type="button" class="btn btn-primary" (click)="editSalarioVinc();"><i
            class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>