<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        {{title}}
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box" [class.conciliadora]="currentScreen === 'CON'">
        <div class="header-box" [class.conciliadora]="currentScreen === 'CON'">
          <div class="header-box-content p-3">
            <ul class="list-inline filter-menu filter-card-header mt-0">
              <li class="list-inline-item" [ngClass]="{active: currentScreen === 'MAT'}">
                <a (click)="initializeMatera()">MATERA</a>
              </li>
              <li class="list-inline-item" [ngClass]="{active: currentScreen === 'CON'}">
                <a (click)="initializeConciliadora()">CONCILIADORA</a>
              </li>
              <li class="list-inline-item" [ngClass]="{active: currentScreen === 'EYE'}">
                <a (click)="initializeEyeMobile()">EYEMOBILE</a>
              </li>
              <li class="list-inline-item" [ngClass]="{active: currentScreen === 'BS2'}">
                <a (click)="initializeBS2()">BS2</a>
              </li>
              <li class="list-inline-item" [ngClass]="{active: currentScreen === 'PAY'}">
                <a (click)="initializePayLivre()">PAYLIVRE</a>
              </li>
            </ul>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <!-- Matera: -->
        <div class="box-content" *ngIf="currentScreen == 'MAT'">
          <app-skeleton [config]="skeletonConfig">
            <div class="card-timer" *ngFor="let item of materaTimersList; let i = index;">
              <div class="content">
                <div class="title">
                  <h3>{{ item.name }}</h3>
                  <div class="infos">
                    <small>{{ item.cod }}</small>
                    <small>{{item.description}}</small>
                  </div>
                </div>
                <div class="status" [style]="{color: item.isRunning ? '#00BFA5' : 'hsl(340, 90%, 38%)'}">
                  <h3>{{ item.isRunning ? "Rodando" : "Parado" }}</h3>
                </div>
                <div class="btn-actions">
                  <button (click)="changeTimer(i)" class='{{item.isRunning ? "btnCustom-red" : "btnCustom-green"}}'
                    matTooltip='{{item.isRunning ? "Pausar" : "Iniciar"}}'>
                    <span class="material-icons">{{item.isRunning ? 'pause' : 'play_arrow'}}</span>
                  </button>
                </div>
              </div>
            </div>
            <p-table #dt class="table-system" [value]="tableData" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              (onRowSelect)="changeToolbar()" (onRowUnselect)="changeToolbar()" [(selection)]="mediationSelected">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox"></th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'cnpj', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao_social'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Razão Social <p-sortIcon [field]="'razao_social'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'razao_social', 'contains')">
                  </th>
                  <th class="text-left" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fornecedor_padrao_id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Fornecedor <p-sortIcon [field]="'fornecedor_padrao_id'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="optParticipantProvidersTable" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'fornecedor_padrao_id', 'in')"
                      emptyMessage="Nenhum resultado encontrado!"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container"
                      [pSortableColumn]="'operacao_regra_contabil_saida_id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Operação débito <p-sortIcon [field]="'operacao_regra_contabil_saida_id'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="optDebitOperationsTable" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'operacao_regra_contabil_saida_id', 'in')"
                      emptyMessage="Nenhum resultado encontrado!"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width:12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cliente_padrao_id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Cliente <p-sortIcon [field]="'cliente_padrao_id'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="optParticipantClientTable" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'cliente_padrao_id', 'in')"
                      emptyMessage="Nenhum resultado encontrado!"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container"
                      [pSortableColumn]="'operacao_regra_contabil_entrada_id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Operação crédito <p-sortIcon [field]="'operacao_regra_contabil_entrada_id'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="optCreditOperationsTable" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'operacao_regra_contabil_entrada_id', 'in')"
                      emptyMessage="Nenhum resultado encontrado!"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_cobranca_id_qrcode'">
                      <span class="text-overflow-dynamic-ellipsis">
                        QR Code <p-sortIcon [field]="'tipo_cobranca_id_qrcode'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="optLevy"
                      defaultLabel="Selecione" [filter]="false"
                      (onChange)="dt.filter($event.value, 'tipo_cobranca_id_qrcode', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-right" style="width: 6%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_qrcode'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Valor <p-sortIcon [field]="'valor_qrcode'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor_qrcode', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_cobranca_id_cashout'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Cashout <p-sortIcon [field]="'tipo_cobranca_id_cashout'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="optLevy"
                      defaultLabel="Selecione" [filter]="false"
                      (onChange)="dt.filter($event.value, 'tipo_cobranca_id_cashout', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-right" style="width: 6%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_cashout'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Valor <p-sortIcon [field]="'valor_cashout'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor_cashout', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj | cnpj}}">
                        {{rowData.cnpj | cnpj}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao_social}}">
                        {{rowData.razao_social}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setProviderLabel(rowData.fornecedor_padrao_id, 2)}}">
                        {{setProviderLabel(rowData.fornecedor_padrao_id, 2)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setOperationLabel(rowData.operacao_regra_contabil_saida_id, 2)}}">
                        {{setOperationLabel(rowData.operacao_regra_contabil_saida_id, 2)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setProviderLabel(rowData.cliente_padrao_id, 1)}}">
                        {{setProviderLabel(rowData.cliente_padrao_id, 1)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setOperationLabel(rowData.operacao_regra_contabil_entrada_id, 1)}}">
                        {{setOperationLabel(rowData.operacao_regra_contabil_entrada_id, 1)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setOptLevyLabel(rowData.tipo_cobranca_id_qrcode)}}">
                        {{setOptLevyLabel(rowData.tipo_cobranca_id_qrcode)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setValueLevyLabel(rowData.tipo_cobranca_id_qrcode, rowData.valor_qrcode)}}">
                        {{setValueLevyLabel(rowData.tipo_cobranca_id_qrcode, rowData.valor_qrcode)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setOptLevyLabel(rowData.tipo_cobranca_id_cashout)}}">
                        {{setOptLevyLabel(rowData.tipo_cobranca_id_cashout)}}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{setValueLevyLabel(rowData.tipo_cobranca_id_cashout, rowData.valor_cashout)}}">
                        {{setValueLevyLabel(rowData.tipo_cobranca_id_cashout, rowData.valor_cashout)}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="11" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>

        <!-- Conciliadora: -->
        <div class="col-sm-12" *ngIf="currentScreen == 'CON'">
          <div class="card-timer" *ngFor="let item of conciliadoraTimersList; let i = index;">
            <div class="content">
              <div class="title">
                <h3>{{ item.name }}</h3>
                <div class="infos">
                  <small>{{item.description}}</small>
                </div>
              </div>
              <div class="status" [style]="{color: item.isRunning ? '#00BFA5' : 'hsl(340, 90%, 38%)'}">
                <h3>{{ item.isRunning ? "Rodando" : "Parado" }}</h3>
              </div>
              <div class="btn-actions">
                <button (click)="changeTimerConciliadora(i)"
                  class='{{item.isRunning ? "btnCustom-red" : "btnCustom-green"}}'
                  matTooltip='{{item.isRunning ? "Pausar" : "Iniciar"}}'>
                  <span class="material-icons">{{item.isRunning ? 'pause' : 'play_arrow'}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- EyeMobile: -->
        <div class="col-sm-12" *ngIf="currentScreen == 'EYE'">
          <div class="card-timer" *ngFor="let item of eyeMobileTimersList; let i = index;">
            <div class="content">
              <div class="title">
                <h3>{{ item.name }}</h3>
                <div class="infos">
                  <small>{{ item.cod }}</small>
                  <small>{{item.description}}</small>
                </div>
              </div>
              <div class="status" [style]="{color: item.isRunning ? '#00BFA5' : 'hsl(340, 90%, 38%)'}">
                <h3>{{ item.isRunning ? "Rodando" : "Parado" }}</h3>
              </div>
              <div class="btn-actions">
                <button (click)="changeTimer(i)" class='{{item.isRunning ? "btnCustom-red" : "btnCustom-green"}}'
                  matTooltip='{{item.isRunning ? "Pausar" : "Iniciar"}}'>
                  <span class="material-icons">{{item.isRunning ? 'pause' : 'play_arrow'}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- BS2: -->
        <div class="col-sm-12" *ngIf="currentScreen == 'BS2'">
          <div class="card-timer" *ngFor="let item of bs2TimersList; let i = index;">
            <div class="content">
              <div class="title">
                <h3>{{ item.name }}</h3>
                <div class="infos">
                  <small>{{item.description}}</small>
                </div>
              </div>
              <div class="status" [style]="{color: item.isRunning ? '#00BFA5' : 'hsl(340, 90%, 38%)'}">
                <h3>{{ item.isRunning ? "Rodando" : "Parado" }}</h3>
              </div>
              <div class="btn-actions">
                <button (click)="changeTimer(i)" class='{{item.isRunning ? "btnCustom-red" : "btnCustom-green"}}'
                  matTooltip='{{item.isRunning ? "Pausar" : "Iniciar"}}'>
                  <span class="material-icons">{{item.isRunning ? 'pause' : 'play_arrow'}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Pay Libre: -->
        <div class="col-sm-12" *ngIf="currentScreen == 'PAY'">
          <div class="card-timer" *ngFor="let item of payLivreTimersList; let i = index;">
            <div class="content">
              <div class="title">
                <h3>{{ item.name }}</h3>
                <div class="infos">
                  <small>{{item.description}}</small>
                </div>
              </div>
              <div class="status" [style]="{color: item.isRunning ? '#00BFA5' : 'hsl(340, 90%, 38%)'}">
                <h3>{{ item.isRunning ? "Rodando" : "Parado" }}</h3>
              </div>
              <div class="btn-actions">
                <button (click)="changeTimer(i)" class='{{item.isRunning ? "btnCustom-red" : "btnCustom-green"}}'
                  matTooltip='{{item.isRunning ? "Pausar" : "Iniciar"}}'>
                  <span class="material-icons">{{item.isRunning ? 'pause' : 'play_arrow'}}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Matera -->
<p-dialog header="Nova integração" [(visible)]="showModal" [style]="{width: '900px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Empresa <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [placeholder]="labelCompany"
        [options]="optCompany" [(ngModel)]="mediation.empresa_id" emptyMessage="Nenhum resultado encontrado!"
        [disabled]="optCompanyLoading || isCompanySelected"
        [dropdownIcon]="optIconCompanyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Cobrança QR CODE <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optLevy"
        [(ngModel)]="mediation.tipo_cobranca_id_qrcode" emptyMessage="Nenhum resultado encontrado!"
        [disabled]="optLevyLoading"
        [dropdownIcon]="optIconLevyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>
        Valor QR CODE
        <span *ngIf="mediation.tipo_cobranca_id_qrcode !== null"
          style="line-height: 0;">{{setIsPercentualValue(mediation.tipo_cobranca_id_qrcode) ? '(%)' : '(R$)'}} </span>
        <span *ngIf="mediation.tipo_cobranca_id_qrcode !== null" class="obrigatorio" style="line-height: 0;">*</span>
      </label>
      <input class="form-control" [(ngModel)]="mediation.valor_qrcode" type="number" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Cobrança Cashout <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optLevy"
        [(ngModel)]="mediation.tipo_cobranca_id_cashout" emptyMessage="Nenhum resultado encontrado!"
        [disabled]="optLevyLoading"
        [dropdownIcon]="optIconLevyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>
        Valor cashout
        <span *ngIf="mediation.tipo_cobranca_id_cashout !== null"
          style="line-height: 0;">{{setIsPercentualValue(mediation.tipo_cobranca_id_cashout) ? '(%)' : '(R$)'}} </span>
        <span *ngIf="mediation.tipo_cobranca_id_cashout !== null" class="obrigatorio" style="line-height: 0;">*</span>
      </label>
      <input class="form-control" [(ngModel)]="mediation.valor_cashout" type="number" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Fornecedor <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" panelStyleClass="panel-participants" [filter]="true"
        placeholder="Selecione" [(ngModel)]="mediation.fornecedor_padrao_id" [virtualScroll]="true" [itemSize]="30"
        [options]="optParticipantProviders" emptyMessage="Nenhum resultado encontrado!"
        [disabled]="optParticipantLoading"
        [dropdownIcon]="optIconParticipantLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Operação débito <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
        [(ngModel)]="mediation.operacao_regra_contabil_saida_id" [virtualScroll]="true" [itemSize]="30"
        [options]="optDebitOperations" emptyMessage="Nenhum resultado encontrado!" [disabled]="optOperationLoading"
        [dropdownIcon]="optIconOperationLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Cliente <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" panelStyleClass="panel-participants" [filter]="true"
        placeholder="Selecione" [(ngModel)]="mediation.cliente_padrao_id" [virtualScroll]="true" [itemSize]="30"
        [options]="optParticipantClient" emptyMessage="Nenhum resultado encontrado!" [disabled]="optParticipantLoading"
        [dropdownIcon]="optIconParticipantLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Operação crédito <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
        [(ngModel)]="mediation.operacao_regra_contabil_entrada_id" [virtualScroll]="true" [itemSize]="30"
        [options]="optCreditOperations" emptyMessage="Nenhum resultado encontrado!" [disabled]="optOperationLoading"
        [dropdownIcon]="optIconOperationLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div class="w-100 d-flex justify-content-end">
      <button *ngIf="!isSaveLoading" class="btnCustom-red" matTooltip="Cancelar" (click)="openingModal(false)">
        <span class="material-icons">close</span>
        <div>Cancelar</div>
      </button>
      <button *ngIf="!isSaveLoading" class="btnCustom-green" matTooltip="Salvar" (click)="saveIntegration()">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>
      <button *ngIf="isSaveLoading" class="btnCustom-default" [disabled]="true">
        <span class="material-icons rotate">hourglass_full</span>
        <div>Carregando...</div>
      </button>
    </div>
  </p-footer>

</p-dialog>