<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Central de Aprovação
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
          <br>
          <small *ngIf="hasTimeCourse" class="font-weight-normal">Período selecionado: {{convertTimestamp(dateIni)}} à {{convertTimestamp(dateFin)}}</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="headerBox">
          <ul class="list-inline filter-menu mb-3">
            <li class="list-inline-item" [ngClass]="{ active: showPending }">
              <a (click)="changeScreen(0)">Em andamento ({{tableDataPending.length}})</a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: showRefused }">
              <a (click)="changeScreen(2)">Recusados ({{tableDataRefused.length}})</a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: showConcluded }">
              <a (click)="changeScreen(1)">Finalizados ({{tableDataApproved.length}})</a>
            </li>
          </ul>
          <div class="d-flex align-items-center justify-content-end">
            <div class="d-flex align-items-center mr-3">
              <p-checkbox inputId="myReq" class="mr-1" (onChange)="onFilter(); saveOptions()" [binary]="true" [value]="showReq" [(ngModel)]="showReq"></p-checkbox>
              <label for="myReq" class="label-header-table">Minhas requisições</label>
            </div>
            <div class="d-flex align-items-center mr-3">
              <p-checkbox inputId="myApr" class="mr-1" (onChange)="onFilter(); saveOptions()" [binary]="true" [value]="showApr" [(ngModel)]="showApr"></p-checkbox>
              <label for="myApr" class="label-header-table">Minhas aprovações</label>
            </div>
            <app-toolbar [value]="toolbarMain"></app-toolbar>
          </div>
        </div>

        <div class="box-content" [@changeDivSize]=currentState>
          <p-table #dt class="table-system" [value]="tableData" [rows]="rows" [paginator]="true" *ngIf="hasTimeCourse"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" [(selection)]="clearArr"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="onSelectAll($event)"
          [loading]="tableLoading" [showLoader]="false">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-center">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCriacaoLabel'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Data Criação<p-sortIcon [field]="'dataCriacaoLabel'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="dataFilterTable" (input)="dt.filter($event.target.value, 'dataCriacaoLabel', 'contains')">
                </th>
                <th class="text-center">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataEntregaLabel'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Data Entrega<p-sortIcon [field]="'dataEntregaLabel'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="dataDeliveryFilterTable" (input)="dt.filter($event.target.value, 'dataEntregaLabel', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Código <p-sortIcon [field]="'codigo'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="codeFilterTable" (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Título <p-sortIcon [field]="'titulo'"></p-sortIcon>
                    </span>
                  </span>
                  <input pInputText class="form-control form-control-sm" type="text" [(ngModel)]="titleFilterTable" (input)="dt.filter($event.target.value, 'titulo', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Tipo de requisição <p-sortIcon [field]="'tipo'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="typesTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="typesFilterTable" (onChange)="dt.filter($event.value, 'tipo', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'destinoRms'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Destino <p-sortIcon [field]="'destinoRms'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="destinysTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="destinysFilterTable" (onChange)="dt.filter($event.value, 'destinoRms', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'objeto'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Objeto <p-sortIcon [field]="'objeto'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="objectsTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="objectsFilterTable" (onChange)="dt.filter($event.value, 'objeto', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'prioridade'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Prioridade <p-sortIcon [field]="'prioridade'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="prioritiesTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="prioritiesFilterTable" (onChange)="dt.filter($event.value, 'prioridade', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'requisitante'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Requisitante <p-sortIcon [field]="'requisitante'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="applicantsTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="applicantsFilterTable" (onChange)="dt.filter($event.value, 'requisitante', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'labelAprovador'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Responsável <p-sortIcon [field]="'labelAprovador'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="responsiblesTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="responsiblesFilterTable" (onChange)="dt.filter($event.value, 'labelAprovador', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'currentAprov'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Aprovação atual <p-sortIcon [field]="'currentAprov'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="currentApprovalsTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="currentApprovalsFilterTable" (onChange)="dt.filter($event.value, 'currentAprov', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda.codigo'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Moeda <p-sortIcon [field]="'moeda.codigo'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="coinsTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="coinsFilterTable" (onChange)="dt.filter($event.value, 'moeda.codigo', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Valor (R$) <p-sortIcon [field]="'valor'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="valueFilterTable" (input)="dt.filter($event.target.value, 'valor', 'contains')">
                </th>
                <th class="text-center" style="width: 75px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      AÇÃO
                    </span>
                  </span>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataCriacaoLabel}}">{{rowData.dataCriacaoLabel}}</span>
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataEntregaLabel}}">{{rowData.dataEntregaLabel}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.titulo}}">{{rowData.titulo}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.destinoRms}}">{{rowData.destinoRms}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.objeto}}">{{rowData.objeto}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.prioridade}}">{{rowData.prioridade}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.requisitante}}">{{rowData.requisitante}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{listApprovers(rowData.aprovadores)}}">{{rowData.labelAprovador}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.currentAprov}}">{{rowData.currentAprov}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.moeda.codigo}}">{{rowData.moeda.codigo}}</span>
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor | money}}">{{rowData.valor | money}}</span>
                  </span>
                </td>
                <td class="text-center" style="vertical-align: top;">
                  <span class="check-all p-0">
                    <span  matTooltip="Abrir sidebar" class="material-icons" style="font-size: 18px; line-height: 0; cursor: pointer;" (click)="rowSidebar(rowData)">view_sidebar</span>

                    <a matTooltip="Gerar PDF" target="_blank" class="pdf-generate" (click)="downloadPdf(rowData.rmsId)">
                      <span class="material-icons">file_download</span>
                    </a>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr *ngFor="let item of tableDataLoading">
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td><td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer">
              <tr>
                <td colspan="13" class="text-right">Valor total:</td>
                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="R$ {{totalValue | money}}">R$ {{totalValue | money}}</span>
                  </span>
                </td>
                <td colspan="1" ></td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="15" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div *ngIf="!hasTimeCourse">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Sem período selecionado!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Filtrar Período" [(visible)]="showFilterModal" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [(ngModel)]="dateIni" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-3">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [(ngModel)]="dateFin" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-6">
      <label>Data para filtro</label>
      <div class="radio-date-container">
        <p-radioButton value="deliveryDay" label="Data de Entrega" [(ngModel)]="selectedDate"></p-radioButton>
        <p-radioButton value="creationDay" label="Data de Criação" [(ngModel)]="selectedDate"></p-radioButton>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Prioridade</label>
      <p-multiSelect appendTo="body" [filter]="true" placeholder="Selecione a(s) prioridade(s)" [options]="priorities" [(ngModel)]="prioritySelected"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Tipo de requisição</label>
      <p-multiSelect appendTo="body" [filter]="true" placeholder="Selecione o(s) tipo(s) de requisição" [options]="requestTypes" [(ngModel)]="requestTypeSelected"></p-multiSelect>
    </div>
  </div>

  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='showFilterModal=false'>
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button type="button" class="btn btn-primary" [disabled]='!dateIni || !dateFin || dateIni > dateFin' (click)='filterTable()'>
          <i class="fas fa-filter"></i> Filtrar
        </button>
      </div>
    </div>
  </p-footer>

</p-dialog>