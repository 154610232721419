<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>
  <mat-card-content>
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <div class="pull-right align-down-table" style="right: -5px;">

          </div>
          <div [ngStyle]="statusTelaposicaoEstoque ? {'display': 'inline'} : {'display': 'none'}">
            <div class="pull-right align-down-table">
              <button type="button" class="btn btn-link" (click)="dttposicaoEstoque.exportCSV()"><i
                  class="fas fa-file-excel excel"></i> Exportar para Excel</button>
            </div>
            <div class="clearfix"></div>

            <p-table class="table-system" #dttposicaoEstoque [columns]="colsPosicaoEstoque" [(value)]="posicaoEstoque"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              selectionMode="single" [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>

                  <th class="text-left" style="width: 9%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tp'">
                      <span class="text-overflow-dynamic-ellipsis">TP <p-sortIcon [field]="'tp'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'tp', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'grupo'">
                      <span class="text-overflow-dynamic-ellipsis">GRUPO <p-sortIcon [field]="'grupo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'grupo', 'contains')">
                  </th>

                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'descricao', 'contains')">
                  </th>

                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'um'">
                      <span class="text-overflow-dynamic-ellipsis">UM <p-sortIcon [field]="'um'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'um', 'contains')">
                  </th>

                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fl'">
                      <span class="text-overflow-dynamic-ellipsis">fl <p-sortIcon [field]="'fl'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'fl', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'armazem'">
                      <span class="text-overflow-dynamic-ellipsis">ARMAZEM <p-sortIcon [field]="'armazem'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'armazem', 'contains')">
                  </th>

                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoArmazem'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO ARMAZEM <p-sortIcon
                          [field]="'descricaoArmazem'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'descricaoArmazem', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'classFiscal'">
                      <span class="text-overflow-dynamic-ellipsis">CLASS FISCAL <p-sortIcon [field]="'classFiscal'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'classFiscal', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'sldEstoque'">
                      <span class="text-overflow-dynamic-ellipsis">SALDO ESTOQUE<p-sortIcon [field]="'sldEstoque'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'sldEstoque', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlEstoque'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR ESTOQUE <p-sortIcon [field]="'vlEstoque'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'vlEstoque', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ctaCont'">
                      <span class="text-overflow-dynamic-ellipsis">CTA CONTÁBIL <p-sortIcon [field]="'ctaCont'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoEstoque.filter($event.target.value, 'ctaCont', 'contains')">
                  </th>


                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>

                  <td class="text-left" style="width: 9%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tp}}">{{rowData.tp }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.grupo}}">{{rowData.grupo}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.um}}">{{rowData.um }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fl}}">{{rowData.fl }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.armazem}}">{{rowData.armazem
                        }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricaoArmazem}}">{{rowData.descricaoArmazem }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.classFiscal}}">{{rowData.classFiscal }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.sldEstoque | money}}">{{rowData.sldEstoque | money }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.vlEstoque | money}}">{{rowData.vlEstoque | money }}</span>
                    </span>
                  </td>

                  <td class="text-left " style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ctaCont }}">{{rowData.ctaCont
                        }}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </div>


          <div [ngStyle]="statusTelaposicaoTerceiro ? {'display': 'inline'} : {'display': 'none'}">
            <div class="pull-right align-down-table">
              <button type="button" class="btn btn-link" (click)="dttposicaoTerceiro.exportCSV()"><i
                  class="fas fa-file-excel excel"></i> Exportar para Excel</button>
            </div>
            <div class="clearfix"></div>

            <p-table class="table-system" #dttposicaoTerceiro [columns]="colsPosicaoTerceiro" [value]="posicaoTerceiro"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              selectionMode="single" [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>

                  <th class="text-left" style="width: 9%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'armazem'">
                      <span class="text-overflow-dynamic-ellipsis">ARMAZEM <p-sortIcon [field]="'armazem'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'armazem', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoCliFor'">
                      <span class="text-overflow-dynamic-ellipsis">Tipo Cli/For <p-sortIcon [field]="'tipoCliFor'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'tipoCliFor', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'clienteForn'">
                      <span class="text-overflow-dynamic-ellipsis">Cliente/Forn <p-sortIcon [field]="'clienteForn'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'clienteForn', 'contains')">
                  </th>

                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'razaoSocial'">
                      <span class="text-overflow-dynamic-ellipsis">Razão Social <p-sortIcon [field]="'razaoSocial'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'razaoSocial', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'docOriginal'">
                      <span class="text-overflow-dynamic-ellipsis">Documento <p-sortIcon [field]="'docOriginal'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'docOriginal', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEmissao'">
                      <span class="text-overflow-dynamic-ellipsis">Data Emissão <p-sortIcon [field]="'dtEmissao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'dtEmissao', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo'">
                      <span class="text-overflow-dynamic-ellipsis">SALDO <p-sortIcon [field]="'saldo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'saldo', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'custoProd'">
                      <span class="text-overflow-dynamic-ellipsis">CUSTO PROD<p-sortIcon [field]="'custoProd'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'custoProd', 'contains')">
                  </th>


                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ctaCont'">
                      <span class="text-overflow-dynamic-ellipsis">CTA CONTÁBIL <p-sortIcon [field]="'ctaCont'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dttposicaoTerceiro.filter($event.target.value, 'ctaCont', 'contains')">
                  </th>


                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>

                  <td class="text-left" style="width: 9%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.armazem}}">{{rowData.armazem}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.tipoCliFor}}">{{rowData.tipoCliFor}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.clienteForn}}">{{rowData.clienteForn }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.razaoSocial}}">{{rowData.razaoSocial }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.docOriginal}}">{{rowData.docOriginal }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dtEmissao}}">{{rowData.dtEmissao }}</span>
                    </span>
                  </td>


                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.saldo | money}}">{{rowData.saldo | money }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.custoProd | money}}">{{rowData.custoProd | money }}</span>
                    </span>
                  </td>

                  <td class="text-left " style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ctaCont }}">{{rowData.ctaCont
                        }}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>


          </div>










        </div>
      </div>
    </div>
  </mat-card-content>