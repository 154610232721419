<mat-card class="card-linnks margin-linnks" *ngIf="!relatorioGerado">
  <mat-card-title style="margin-top: 13px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

<div class="clearfix"></div>

  <mat-card-content>
    <div class="box-content p-0 mt-4" style="background-color: transparent;" >
        <div class="row">
          <div class="col-sm-12">

          </div>
        </div>
        <div class="row">
            <div class="col-sm-11">
              <span class="title-list" style="margin-left: 44px;">Nome</span>
            </div>
            <div class="col-sm-1">
              <span class="title-list pull-right">Favorito</span>
            </div>
        </div>
        <div class="row">

          <div class="col-sm-12">
              <div class="panel panel-default panel-custom" *ngFor="let item of relatoriosPbiFav">
                  <div class="panel-body">
                    <div class="col-sm-6">
                      <span class="item-name" matTooltip="{{item.nome}}" (click)="gerarRelatorio(item.id)">{{item.nome}}</span>
                    </div>
                    <div class="col-sm-2">
                      <span *ngIf="item.temView" class="dtAtualizacao" matTooltip="{{item.dtAtualizacao}}" (click)="atualizarViewMaterializada(item.id)">{{item.dtAtualizacao}}</span>
                      <span *ngIf="!item.temView" matTooltip=""></span>
                    </div>
                    <div class="col-sm-1">
                      <span *ngIf="item.temView" class="atualizar" (click)="atualizarViewMaterializada(item)"><i class="fas fa-clock" matTooltip={{item.dtAtualizacao}} matTooltipPosition="left"></i></span>
                      <span *ngIf="!item.temView"></span>
                  </div>
                  <div class="col-sm-1">
                    <span *ngIf="item.permiteAtualizacao" class="atualizar" (click)="atualizarDataSet(item)"><i class="fas fa-refresh" matTooltipPosition="left"></i></span>
                    <span *ngIf="!item.permiteAtualizacao"></span>
                </div>
                <div class="col-sm-1">
                  <span *ngIf="item.permiteAtualizacao" class="verificar atualizacoes" (click)="historicoAtualizacoes(item)"><i class="fas fa-clock" matTooltipPosition="left"></i></span>
                  <span *ngIf="!item.permiteAtualizacao"></span>
              </div>
                    <div class="col-sm-1">
                        <span class="favorite" (click)="infoRelatorioFavorito(item, false)"><i class="fas fa-star" style="color: #edd607;" matTooltip='Remover dos favoritos!' matTooltipPosition="left"></i></span>
                    </div>
                  </div>
              </div>
              <div class="panel panel-default panel-custom" *ngFor="let item of relatoriosPbi">
                  <div class="panel-body">
                    <div class="col-sm-6">
                      <span class="item-name" (click)="gerarRelatorio(item.id)">{{item.nome}}</span>
                    </div>
                    <div class="col-sm-2">
                      <span *ngIf="item.temView" class="dtAtualizacao" matTooltip="{{item.dtAtualizacao}}" (click)="atualizarViewMaterializada(item.id)">{{item.dtAtualizacao}}</span>
                      <span *ngIf="!item.temView" matTooltip=""></span>
                    </div>
                    <div class="col-sm-1">
                      <span *ngIf="item.temView" class="atualizar" (click)="atualizarViewMaterializada(item)"><i class="fas fa-clock" matTooltip={{item.dtAtualizacao}} matTooltipPosition="left"></i></span>
                      <span *ngIf="!item.temView"></span>
                  </div>
                  <div class="col-sm-1">
                    <span *ngIf="item.permiteAtualizacao" class="atualizar" (click)="atualizarDataSet(item)"><i class="fas fa-refresh" matTooltipPosition="left"></i></span>
                    <span *ngIf="!item.permiteAtualizacao"></span>
                </div>
                <div class="col-sm-1">
                  <span *ngIf="item.permiteAtualizacao" class="verificar atualizacoes" (click)="historicoAtualizacoes(item)"><i class="fas fa-clock" matTooltipPosition="left"></i></span>
                  <span *ngIf="!item.permiteAtualizacao"></span>
              </div>
                    <div class="col-sm-1">
                        <span class="favorite" (click)="infoRelatorioFavorito(item, true)"><i class="fas fa-star" style="color: #999999;" matTooltip='Adicionar aos favoritos!' matTooltipPosition="left"></i></span>
                    </div>
                  </div>
              </div>
          </div>
        </div>

    </div>

  </mat-card-content>
</mat-card>

  <div class="bi" id="pbi-report" #powerBIReports></div>
  <div *ngIf="relatorioGerado" class="data">Data da Última Atualização: {{dtAtualizacao}}</div>


  <p-dialog header="ÚLTIMAS 10 ATUALIZAÇÕES BI" [modal]="true" appendTo="body" [style]="{width: '700px'}"
  styleClass="modal-initial" [(visible)]="modalAtualizacoes" [responsive]="true" [closable]="true">
  <div class="form-row">

     <p-table class="table-system" [value]="atualizacoesList" [style]="{'width':'100%'}" immutable="false">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" style="width: 30%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis">STATUS</span>
            </span>
          </th>
          <th class="text-center" style="width: 70%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis">DATA</span>
            </span>
          </th>
       </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="text-center" style="width: 45%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis"
                matTooltip="{{ rowData.status}}">{{ rowData.status }}</span>
            </span>
          </td>

          <td class="text-center" style="width: 45%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis"
                matTooltip="{{ rowData.data }}">{{ rowData.data}}</span>
            </span>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="2" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhuma atualização registrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>




  <p-footer>

  </p-footer>
</p-dialog>
