<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <div>
    <mat-card-content>
      <div class="box-content" *ngIf="exibeTable">

        <div class="header-box space">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="size">
        <p-table class="table-system" #dtrelatorios [columns]="colsrelatorios" [value]="relatorios" [rows]="rows"
          [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
          [style]="{'width':'100%'}" (onRowSelect)="selectRelatorio($event)"
          (onRowUnselect)="unselectRelatorio($event)" [(selection)]="selectCad">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 45px;">
                <span class="check-all p-0">
                  <!-- <mat-checkbox (click)="selectTodos()"></mat-checkbox> -->
                </span>
              </th>
              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'relId'">
                  <span class="text-overflow-dynamic-ellipsis"> ID <p-sortIcon [field]="'relId'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dtrelatorios.filter($event.target.value, 'relId', 'contains')">
              </th>
              <th class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dtrelatorios.filter($event.target.value, 'nome', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tpDescricao'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO RELATÓRIO <p-sortIcon [field]="'tpDescricao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dtrelatorios.filter($event.target.value, 'tpDescricao', 'contains')">
              </th>

              <th class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'reportId'">
                  <span class="text-overflow-dynamic-ellipsis">REPORT ID <p-sortIcon [field]="'reportId'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dtrelatorios.filter($event.target.value, 'reportId', 'contains')">
              </th>

              <th class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'groupId'">
                  <span class="text-overflow-dynamic-ellipsis">GROUP ID <p-sortIcon [field]="'groupId'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dtrelatorios.filter($event.target.value, 'groupId', 'contains')">
              </th>


            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 45px;">
                <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData"></p-tableRadioButton>
              </td>
              <td class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.relId}}">{{rowData.relId }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.tpDescricao}}">{{rowData.tpDescricao}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.reportId}}">{{rowData.reportId}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.groupId}}">{{rowData.groupId
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="6" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      </div>
    </mat-card-content>
  </div>
  <div class="box-content" *ngIf="!exibeTable">
    <div class="header-box">
      <div class="header-box-content space">
        <div class="actions-card">
          <app-toolbar [value]="toolbarMain"></app-toolbar>
        </div>
      </div>
    </div>

    <div class="form-row mt-3">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-file-alt"> </i> INFORMAÇÕES GERAIS</h3>
      </div>

      <div class="form-group col-sm-8">
        <label>NOME <span class="obrigatorio">*</span></label>
        <input class="form-control text-left" type="text" placeholder="Nome do relatório" [(ngModel)]="nome" />
      </div>

      <div class="form-group col-sm-4">
        <label>TIPO DE RELATÓRIO <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesTipoRelatorio"
          [(ngModel)]="tipoRelatorio">
        </p-dropdown>
      </div>


      <div class="form-group col-sm-3">
        <label *ngIf="tipoRelatorio === 0"> SELECIONE O TIPO DE RELATÓRIO</label>
        <label *ngIf="tipoRelatorio === 1"> REPORT ID <span class="obrigatorio">*</span></label>
        <label *ngIf="tipoRelatorio === 2"> DASHBOARD ID <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control text-left" placeholder="Insira o Id" [(ngModel)]="reportId" />
      </div>
      <div class="form-group col-sm-3">
        <label>GROUP ID<span class="obrigatorio">*</span></label>
        <input type="text" class="form-control text-left" placeholder="Insira o Id" [(ngModel)]="groupId" />
      </div>
      <div class="form-group col-sm-3">
        <label>DATASET ID<span class="obrigatorio">*</span></label>
        <input type="text" class="form-control text-left" placeholder="Insira o Id" [(ngModel)]="datasetId" />
      </div>

      <div class="form-group col-sm-3">
        <label>EMPRESA</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesEmpresa"
          [(ngModel)]="empresa">
        </p-dropdown>
      </div>
    </div>

    <div class="form-row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-cog"> </i> PARÂMETROS VISUALIZAÇÃO</h3>
      </div>
      <div class="form-group col-sm-2">
        <label>VISUALIZA FILTRO</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesBoolean"
          [(ngModel)]="habilitaFiltro">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>VISUALIZA TAB</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesBoolean"
          [(ngModel)]="habilitaTab">
        </p-dropdown>
      </div>
    </div>

    <div class="form-row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-cog"> </i> PARÂMETROS FILTRO</h3>
      </div>
      <div class="form-group col-sm-2">
        <label>FILTRA EMPRESA</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesBoolean"
          [(ngModel)]="filtraEmpresa">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>TABELA FILTRO EMPRESA</label>
        <input type="text" class="form-control text-left" placeholder="Tabela filtro da empresa"
          [(ngModel)]="tabelaFiltroEmpresa" />
      </div>
      <div class="form-group col-sm-2">
        <label>COLUNA FILTRO EMPRESA</label>
        <input type="text" class="form-control text-left" placeholder="Coluna filtro da empresa"
          [(ngModel)]="colunaFiltroEmpresa" />
      </div>
      <div class="form-group col-sm-6"></div>
      <div class="form-group col-sm-2">
        <label>FILTRA PERÍODO</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesBoolean"
          [(ngModel)]="filtraPeriodo">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>TABELA FILTRO PERÍODO</label>
        <input type="text" class="form-control text-left" placeholder="Tabela filtro da periodo"
          [(ngModel)]="tabelaFiltroPeriodo" />
      </div>
      <div class="form-group col-sm-2">
        <label>COLUNA FILTRO PERÍODO</label>
        <input type="text" class="form-control text-left" placeholder="Coluna filtro da periodo"
          [(ngModel)]="colunaFiltroPeriodo" />
      </div>
      <div class="form-group col-sm-6"></div>

    </div>

    <div class="form-row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-cog"> </i> VIEWS MATERIALIZADAS</h3>
      </div>
      <div class="form-group col-sm-12">
        <label>VIEW</label>
        <input type="text" class="form-control text-left" placeholder="Views Materializadas" [(ngModel)]="viewMat" />
      </div>


    </div>

  </div>
</mat-card>