<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>





  <mat-card-content>
    <div class="box-content pt-2" [ngStyle]="viewPc ? {'display': 'block'} : {'display': 'none'}">
      <div class="pull-right align-down-table">
        <button type="button" class="btn btn-link" (click)="dt.exportCSV()"><i class="fas fa-file-excel excel"></i>
          Exportar para Excel</button>
      </div>
      <div class="clearfix"></div>
      <p-table class="table-system" #dt [columns]="colsplanosGerenciais" [value]="planosGerenciais" [rows]="rows"
        [paginator]="true" selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
        [style]="{'width':'100%'}" immutable="false">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 40px;">
            </th>
            <th class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'id', 'contains')">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'codigo', 'contains')">
            </th>
            <th class="text-left" style="width: 75%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda'">
                <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'moeda'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'moeda', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-center" style="width: 40px;">
              <p-checkbox (click)="selectCheckbox(rowData.id,rowData)" [(ngModel)]="rowData.selected"></p-checkbox>
            </td>
            <td class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                  matTooltip="{{ rowData.id }}">{{ rowData.id }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                  matTooltip="{{ rowData.codigo }}">{{ rowData.codigo }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 75%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                  matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                  matTooltip="{{rowData.moeda.codigo}}">{{rowData.moeda.codigo }}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="box-content pt-2" [ngStyle]="viewCpg ? {'display': 'block'} : {'display': 'none'}">
      <div class="pull-right align-down-table">
        <button type="button" class="btn btn-link" (click)="dt8.exportCSV()"><i class="fas fa-file-excel excel"></i>
          Exportar para Excel</button>
      </div>
      <div class="clearfix"></div>
      <p-table class="table-system" #dt8 [columns]="colscontasGerenciais" [value]="contasGerenciais" [rows]="rows"
        [paginator]="false" selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
        [style]="{'width':'100%'}" immutable="false" (onFilter)="onFilter($event)"
        [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''"
        [scrollable]="contasGerenciais.length >= 15 ? true : false" scrollHeight="320px">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 40px;">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt8.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-left" style="width: 60%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt8.filter($event.target.value, 'descrCta', 'contains')">
            </th>
            <th class="text-right" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt8.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">INDICADOR <p-sortIcon [field]="'indCta'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt8.filter($event.target.value, 'indCta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-center" style="width: 40px;">
              <p-checkbox (click)="selectCheckboxConta(rowData.id,rowData)" [(ngModel)]="rowData.selected">
              </p-checkbox>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                  matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 60%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                  matTooltip="{{rowData.descrCta}}">{{rowData.descrCta }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                  matTooltip="{{rowData.nivel}}">{{rowData.nivel }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                  matTooltip="{{rowData.indCta}}">{{rowData.indCta }}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="d-block mt-4" *ngIf="viewAbas">
      <mat-tab-group>
        <mat-tab label="Vinculação">
          <div class="box-content">
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Centro de Custo</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesCentroCusto" [(ngModel)]="centroCusto.id"
                  [filter]="true" appendTo="body"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>Projeto</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesProjeto" [(ngModel)]="cadProj.id" [filter]="true"
                  appendTo="body"></p-dropdown>
              </div>
            </div>

            <p-table class="table-system" #dt2 [value]="planoContasList" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false"
              selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'codCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 60%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                      <span class="text-overflow-dynamic-ellipsis">DESRIÇÃO <p-sortIcon [field]="'descrCta'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'descrCta', 'contains')">
                  </th>
                  <th class="text-right" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                      <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'nivel', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'indCta'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'indCta', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" [ngClass]="rowData.indCta === 'S' ? 'bold ' : ''">
                  <td class="text-center" style="width: 40px;">
                    <p-checkbox [ngModel]="rowData.selected" (ngModelChange)="check(rowData, $event)"></p-checkbox>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">{{ rowData.codCta
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 60%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrCta}}">{{rowData.descrCta
                        }}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nivel}}">{{rowData.nivel
                        }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indCta}}">{{rowData.indCta
                        }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="form-row">
            <div class="form-group col mt-3">
              <button type="button" class="btn btn-sm btn-info" (click)="add()" matTooltip="Adicionar Contas"><i
                  class="fas fa-angle-double-down"></i> Adicionar</button>
              <button type="button" class="btn btn-sm btn-info" (click)="remove()" matTooltip="Remover Contas"><i
                  class="fas fa-angle-double-up"></i> Remover</button>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system" #dt3 [value]="planoContasSelectedList" selectionMode="single" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'codCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 55%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'descrCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadCcus.codCcus'">
                      <span class="text-overflow-dynamic-ellipsis">CC <p-sortIcon [field]="'cadCcus.codCcus'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'cadCcus.codCcus', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadCcus.descrCcus'">
                      <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO <p-sortIcon
                          [field]="'cadCcus.descrCcus'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'cadCcus.descrCcus', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadProj.codProj'">
                      <span class="text-overflow-dynamic-ellipsis">PROJ <p-sortIcon [field]="'cadProj.codProj'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'cadProj.codProj', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadCcus.descrProj'">
                      <span class="text-overflow-dynamic-ellipsis">PROJETO <p-sortIcon [field]="'cadCcus.descrProj'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'cadCcus.descrProj', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;">
                    <p-checkbox [(ngModel)]="rowData.selected"></p-checkbox>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">{{ rowData.codCta
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 55%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrCta}}">{{rowData.descrCta
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cadCcus?.codCcus}}">{{rowData.cadCcus?.codCcus }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cadCcus?.descrCcus}}">{{rowData.cadCcus?.descrCcus }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cadProj?.codProj}}">{{rowData.cadProj?.codProj }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cadCcus?.descrProj}}">{{rowData.cadCcus?.descrProj }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>

        <mat-tab label="Internacionalização">
          <div class="box-content">
            <p-table class="table-system" #dt4 [value]="contasInternacionalizacao" selectionMode="single" [rows]="rows"
              [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 75%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">IDIOMA</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectInter(rowData)'
                        matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 75%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectInter(rowData)'
                        matTooltip="{{rowData.descrCta}}">{{rowData.descrCta }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectInter(rowData)'
                        matTooltip="{{rowData.idioma.codigo}}">{{rowData.idioma.codigo }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

  </mat-card-content>
</mat-card>


<!-- Modal Clonar Plano -->
<p-dialog header="Clonar Plano" [(visible)]="cloneModal" [style]="{width: '800px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col">
      <label>Clonar</label>
      <p-dropdown class="type-p-dropdown" [options]="opcoesEmpresa" [(ngModel)]="empresaClone" appendTo="body"
        [filter]="true"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="cloneModal=false"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" *ngIf="!editPc" (click)="clonarPedido()"><i class="fas fa-clone"></i>
      Clonar</button>
  </p-footer>
</p-dialog>

<!-- Modal Plano Gerencial -->
<p-dialog header="Plano Gerencial" [(visible)]="modalPlanoGerencial" [style]="{width: '800px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>CÓDIGO</label>
      <input class="form-control" type="text" [(ngModel)]="planoGerencial.codigo" />
    </div>
    <div class="form-group col">
      <label>NOME</label>
      <input class="form-control" type="text" [(ngModel)]="planoGerencial.nome" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>NÍVEIS</label>
      <p-dropdown class="type-p-dropdown" [options]="opcoesNivel" [(ngModel)]="planoGerencial.niveis" [filter]="true">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>MOEDA</label>
      <p-dropdown class="type-p-dropdown" [options]="opcoesMoeda" [(ngModel)]="planoGerencial.moeda" [filter]="true">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>VIEW</label>
      <p-dropdown class="type-p-dropdown" [options]="opcoesView" [(ngModel)]="planoGerencial.view" [filter]="true">
      </p-dropdown>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="cancelarEdicaoPlanoGerencial()"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" *ngIf="!editPc" (click)="gravarPlanoGerencial()"><i
        class="fas fa-check"></i> Salvar</button>
    <button type="button" class="btn btn-primary" *ngIf="editPc" (click)="atualizarPlanoGerencial()"><i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>

<!-- Modal Conta Gerencial -->
<p-dialog header="Conta Gerencial" [(visible)]="modalContaPlanoGerencial" [style]="{width: '800px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">


  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>CÓDIGO</label>
      <input class="form-control" type="text" [(ngModel)]="planogerencialcontas.codCta" />
    </div>
    <div class="form-group col">
      <label>DESCRIÇÃO</label>
      <input class="form-control" type="text" [(ngModel)]="planogerencialcontas.descrCta" />
    </div>

  </div>
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>NATUREZA</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesNatureza"
        (onChange)="procurarContaSuperior()" placeholder="Selecione a Natureza"
        [(ngModel)]="planogerencialcontas.codNaturezaConta.id"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>NÍVEL</label>
      <p-dropdown class="type-p-dropdown" [options]="opcoesNivel" (onChange)="procurarContaSuperior()"
        [(ngModel)]="planogerencialcontas.nivel" [filter]="true"></p-dropdown>
    </div>
    <div class="form-group col-sm-2">
      <label>INDICADOR</label>
      <p-dropdown id="indCta" name="indCta" class="type-p-dropdown" [filter]="true" [options]="opcoesIndicador"
        placeholder="Selecione o Indicador" [(ngModel)]="planogerencialcontas.indCta"
        (onChange)="procurarContaSuperior()"></p-dropdown>
    </div>
    <div class="form-group col-sm-4">
      <label>CONTA SUPERIOR</label>
      <p-dropdown id="ctaSup" name="ctaSup" class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
        placeholder="Selecione a Conta Superior" [(ngModel)]="planogerencialcontas.ctaSup.id"></p-dropdown>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="afterSaveConta()"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" *ngIf="!editCpg" (click)="save()"><i class="fas fa-check"></i>
      Salvar</button>
    <button type="button" class="btn btn-primary" *ngIf="editCpg" (click)="editarConta()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!-- Modal Internacionalização -->
<p-dialog header="Internacionalização" [(visible)]="modalInternacionalizacao" [style]="{width: '800px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>CÓDIGO</label>
      <input class="form-control" type="text" [(ngModel)]="planoContasGerencialInternacionalizacao.codCta" />
    </div>
    <div class="form-grouo col">
      <label>DESCRIÇÃO</label>
      <input class="form-control" type="text" [(ngModel)]="planoContasGerencialInternacionalizacao.descrCta" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>IDIOMA</label>
      <p>{{planoContasGerencialInternacionalizacao.idioma.codigo}}</p>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="afterSaveConta()"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="salvarInternacionalizacao()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>