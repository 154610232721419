<h2 mat-dialog-title *ngIf="!formGroup">Selecionar {{ participanteTipoDescricao }}</h2>
<h2 mat-dialog-title *ngIf="formGroup">Criar novo {{ participanteTipoDescricao }}</h2>

<mat-dialog-content>
  <mat-drawer-container style="height: auto;">

    <mat-drawer-content style="display: initial;">
      <div class="content">

        <p-table *ngIf="!formGroup" class="table-system table-mov" #dt2 [value]="participantes" [rows]="rows"
          [paginator]="true" [pageLinks]="pageLinks" selectionMode="false"
          [style]="{ width: '100%'}" [lazy]="true" (onLazyLoad)="getOutrosParticipantes($event)"
          [totalRecords]="totalRecords">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th style="width: 40%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeFantasia'">
                  <span class="text-overflow-dynamic-ellipsis">NOME FANTASIA <p-sortIcon [field]="'nomeFantasia'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nomeFantasia', 'contains')">
              </th>
              <th style="width: 40%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'razaoSocial'">
                  <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL <p-sortIcon [field]="'razaoSocial'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'razaoSocial', 'contains')">
              </th>
              <th style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpjCpf'">
                  <span class="text-overflow-dynamic-ellipsis">CNPJ/CPF<p-sortIcon [field]="'cnpjCpf'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'cnpjCpf', 'contains')">
              </th>


            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr (click)="selectParticipante(rowData)" [pSelectableRow]="rowData">
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.codigo}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 40%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.nomeFantasia}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 40%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.razaoSocial}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.cnpjCpf}}">{{rowData.cnpjCpf}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


        <form *ngIf="formGroup" [formGroup]="formGroup" novalidate autocomplete="off">
          <mat-form-field floatPlaceholder="always" class="third left">
            <input matInput formControlName="cnpj" type="text" maxlength="14"
              placeholder="{{ isCpf ? 'CPF' : (isCnpj ? 'CNPJ' : 'CNPJ ou CPF') }}"
              (input)="search($event.target.value)" numbersOnly #focusInitial cdkFocusInitial>
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="third-2x right">
            <input matInput formControlName="fantasia" type="text" maxlength="60" placeholder="NOME FANTASIA"
              #focusSearch>
          </mat-form-field>

          <mat-form-field floatPlaceholder="always">
            <input matInput formControlName="nome" type="text" maxlength="60"
              placeholder="{{ isCpf ? 'NOME' : (isCnpj ? 'RAZÃO SOCIAL' : 'RAZÃO SOCIAL ou NOME') }}">
          </mat-form-field>


          <mat-divider></mat-divider>
          <h3>ENDEREÇO</h3>

          <mat-form-field floatPlaceholder="always" class="quarter-3x left">
            <input matInput formControlName="logradouro" type="text" maxlength="60" placeholder="LOGRADOURO">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="quarter right">
            <input matInput formControlName="numero" type="text" maxlength="10" placeholder="NÚMERO">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half left">
            <input matInput formControlName="complemento" type="text" maxlength="60" placeholder="COMPLEMENTO">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half right">
            <input matInput formControlName="bairro" type="text" maxlength="60" placeholder="BAIRRO">
          </mat-form-field>

          <mat-form-field floatLabel="always" class="quarter left">
            <mat-select placeholder="PAÍS" formControlName="pais" (selectionChange)="onChangePais($event.value)">
              <mat-option disabled>Selecione</mat-option>
              <mat-option *ngFor="let pais of paises" [value]="pais.id">{{ pais.nome }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field floatLabel="always" class="quarter left">
            <mat-select placeholder="UF" formControlName="uf" (selectionChange)="onChangeUf($event.value)">
              <mat-option disabled>Selecione</mat-option>
              <mat-option *ngFor="let uf of ufs" [value]="uf.id">{{ uf.uf }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field floatLabel="always" class="half right">
            <mat-select placeholder="Município" formControlName="municipioIbge">
              <mat-option disabled>Selecione</mat-option>
              <mat-option *ngFor="let municipio of municipios" [value]="municipio.id">{{ municipio.nome }}</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-divider></mat-divider>
          <h3>CONTATO</h3>

          <mat-form-field floatPlaceholder="always">
            <input matInput formControlName="contato" type="text" maxlength="30" placeholder="NOME">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half left">
            <input matInput formControlName="email" type="text" maxlength="60" placeholder="E-MAIL">
          </mat-form-field>

          <mat-form-field floatPlaceholder="always" class="half right">
            <input matInput formControlName="fone" type="text" maxlength="15" placeholder="TELEFONE">
          </mat-form-field>
        </form>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-dialog-content>

<mat-dialog-actions>


  <div class="right">
    <button mat-button (click)="cancel()">CANCELAR</button>
    <button *ngIf="!canSave && isRequiredType" mat-button (click)="selectParticipante()"
      [disabled]="!canSelect">SELECIONAR{{ action }}</button>
    <button *ngIf="!canSave && !isRequiredType" mat-button (click)="selectParticipante()" [disabled]="!canCopy">UTILIZAR
      COMO {{ participanteTipoDescricao | uppercase }}{{ action }}</button>
    <button *ngIf="canSave" mat-button (click)="saveParticipante()" [disabled]="formGroup.invalid">SALVAR{{ action
      }}</button>
  </div>
</mat-dialog-actions>