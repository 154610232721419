<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Versão Orçamento</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentOrcaVers>
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" #dt1 [columns]="colsListaOrcamentoVersao" [value]="listaOrcamentoVersao"
                [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}" (onRowSelect)="handleSelect()"  (onRowUnselect)="handleSelect()" [(selection)]="selectedBudgets">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox (click)="selectTodos()"></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'tipo', 'contains')">
                    </th>
                    <th class="text-center">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ano'">
                        <span class="text-overflow-dynamic-ellipsis">ANO <p-sortIcon [field]="'ano'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'ano', 'contains')">
                    </th>
                    <th class="text-center">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'config_descricao'">
                        <span class="text-overflow-dynamic-ellipsis">CONFIGURAÇÃO <p-sortIcon
                            [field]="'config_descricao'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'config_descricao', 'contains')">
                    </th>
                    <th class="text-center">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                        <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'status', 'contains')">
                    </th>
                    <th class="text-center">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_encerramento'">
                        <span class="text-overflow-dynamic-ellipsis">DATA ENCERRAMENTO <p-sortIcon
                            [field]="'dt_encerramento'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'dt_encerramento', 'contains')">
                    </th>
                    <th class="text-center">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                        <span class="text-overflow-dynamic-ellipsis">USUÁRIO <p-sortIcon [field]="'usuario'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'usuario', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.ano}}">{{rowData.ano}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.config_descricao}}">{{rowData.config_descricao}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.dt_encerramento | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_encerramento
                          | date:'dd/MM/yyyy' : '+000'}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis hover" (click)="onSelectVersao(rowData)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>

      <div class="shadow-box mt-3" *ngIf="versaoSelecionada">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">
              Gestão de Orçamento
            </h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainGestao"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content" [@changeDivSize]=currentStateGestao>
          <div class="row">
            <div class="col-sm-12">
              <p-tree #expandingTree [value]="versionDetails" selectionMode="checkbox"
                [(selection)]="detailSelecionados" (onNodeSelect)="nodeSelect($event)"
                (onNodeUnselect)="nodeUnselect($event)" (onNodeExpand)="handleExpandNode()" (onNodeCollapse)="handleColapseNode()">
              </p-tree>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</mat-card>

<!--Modal Orçamento Versão-->
<!-- <p-dialog [header]="headerModal" [(visible)]="boolCriarStatus" styleClass="modal-custom"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true"  (onHide)="closeDialog()"
  [contentStyle]="{maxHeight: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>ANO <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesAno"
        [(ngModel)]="versao.ano" appendTo="body">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>CONFIGURAÇÃO <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesConfigOrca"
        [(ngModel)]="versao.configVersao.id" (onChange)="instanciarConfig();" appendTo="body">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label for="inputDescricao">DESCRIÇÃO <span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{versao.descricao}}" [(ngModel)]="versao.descricao" name="inputDescricao"
        maxlength="200">
    </div>
    <div class="form-group col-sm-6" *ngIf="boolEditStatus">
      <label>STATUS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesStatusOrcamento"
        [(ngModel)]="versao.statusOrcamento.id" appendTo="body">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row mt-2">
    <div class="form-group col-sm-12">
      <h3 class="sub-title">OPÇÕES MOEDA</h3>
      <div class="form-group col-sm-6" style="margin-left: -14px;">
        <label>MOEDA PADRÃO <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesMoedaPadrao" [(ngModel)]="versao.moeda.id" [filter]="true"
          placeholder="Selecione" appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="form-group col-sm-12">
      <div class="forecast">
        <div class="forecast-item mb-1">
          Forecast / Budget:
        </div>
        <div class="forecast-item" *ngFor="let option of opcoesTaxaVersao">
          <mat-radio-group [(ngModel)]="versao.taxaBudgetId.id">
            <mat-radio-button class="mr-3" [value]="option.id">
              {{ option.descricao }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="forecast mt-1">
        <div class="forecast-item">
          Realizado:
        </div>
        <div class="forecast-item" *ngFor="let option of opcoesTaxaVersao">
          <mat-radio-group [(ngModel)]="versao.taxaRealizadoId.id">
            <mat-radio-button class="mr-3" [value]="option.id">
              {{ option.descricao }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row mt-2"
    *ngIf="versao.configVersao.tipoOrcamento.id === 1 || versao.configVersao.tipoOrcamento.id === 2">
    <div class="form-group col-sm-12 mb-0">
      <h3 class="sub-title">CLONAR</h3>
    </div>
    <div class="form-group col-sm-3"
      *ngIf="versao.configVersao.tipoOrcamento.id === 1 || versao.configVersao.tipoOrcamento.id === 2">
      <label class="d-block" style="margin-top: 7px">CLONAR</label>
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="clonar"
        [disabled]="!boolCriarStatus">
      </mat-slide-toggle>
    </div>
    <div class="form-group col-sm-3" *ngIf="clonar">
      <label class="d-block" style="margin-top: 7px">ORIGEM REALIZADO </label>
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="origemReal"
        [disabled]="!boolCriarStatus">
      </mat-slide-toggle>
    </div>
    <div class="form-group col-sm-4 mr-2" *ngIf="clonar && !origemReal">
      <label class="d-block" style="margin-top: 5px">ORIGEM <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" placeholder="Selecione" [filter]="true"
        [options]="opcoesVersoes" [(ngModel)]="versaoOrigem" [disabled]="!boolCriarStatus"></p-dropdown>
    </div>
    <div class="form-group col-sm-4 mr-2" *ngIf="clonar && origemReal">
      <label class="d-block" style="margin-top: 5px">ORIGEM <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesAnoOrigem"
        [(ngModel)]="anoRealOrigem" appendTo="body" [disabled]="!boolCriarStatus">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3" *ngIf="clonar && !origemReal && versao.configVersao.tipoOrcamento.id === 2">
      <label class="d-block" style="margin-top: 5px">FORECAST </label>
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 3px" [(ngModel)]="usarRealizado"
        [disabled]="!boolCriarStatus">
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row mt-2" *ngIf="usarRealizado && !origemReal && clonar">
    <div class="col-sm-12">
      <h3 class="sub-title">OPÇÕES FORECAST</h3>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>ANO DO REALIZADO <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesAnoOrigem"
            [(ngModel)]="anoRealizado" appendTo="body" [disabled]="!boolCriarStatus">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>MÊS INICIAL <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesMeses"
            [(ngModel)]="mesInitRealizado" appendTo="body" [disabled]="!boolCriarStatus">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>MÊS FINAL <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesMeses"
            [(ngModel)]="mesFinRealizado" appendTo="body" [disabled]="!boolCriarStatus">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="usarRealizado && !origemReal && clonar">
    <div class="form-group col-sm-12 mb-0" style="margin-left: -14px;">
      <h3 class="sub-title">OPÇÕES CÁLCULO FORECAST</h3>
    </div>

    <div>
      <ul style="display: flex; list-style: none; margin-left: -30px;">
        <li>
          <p-checkbox [(ngModel)]="foreConstant" (change)="calculoTipoChange($event.checked, 0)" [binary]="true"  (onChange)="singleConstantProp()">
          </p-checkbox>
          <label style="margin-left: 10px; margin-top: 6px;">Constante</label>
        </li>
        <li>
          <div style="margin-left: 20px;">
            <p-checkbox [(ngModel)]="foreConstantProp" (change)="calculoTipoChange($event.checked, 1)" [binary]="true" (onChange)="singleConstant()">
            </p-checkbox>
            <label style="margin-left: 10px;">Proporcional</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="boolCriarStatus = false; boolEditStatus = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary"
      (click)="criarNovoRegistro()"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">
    </div>
  </div>
</p-dialog> -->

<p-dialog [header]="headerModal" [(visible)]="modalState" styleClass="modal-custom"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true"  (onHide)="closeDialog()"
  [contentStyle]="{maxHeight: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>ANO <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesAno"
        [(ngModel)]="versao.ano" appendTo="body">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>CONFIGURAÇÃO <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesConfigOrca"
        [(ngModel)]="versao.configVersao.id" (onChange)="instanciarConfig();" appendTo="body">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label for="inputDescricao">DESCRIÇÃO <span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{versao.descricao}}" [(ngModel)]="versao.descricao" name="inputDescricao"
        maxlength="200">
    </div>
    <div class="form-group col-sm-6" *ngIf="boolEditStatus">
      <label>STATUS <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesStatusOrcamento"
        [(ngModel)]="versao.statusOrcamento.id" appendTo="body">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row mt-2">
    <div class="form-group col-sm-12">
      <h3 class="sub-title">OPÇÕES MOEDA</h3>
      <div class="form-group col-sm-6" style="margin-left: -14px;">
        <label>MOEDA PADRÃO <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesMoedaPadrao" [(ngModel)]="versao.moeda.id" [filter]="true"
          placeholder="Selecione" appendTo="body"></p-dropdown>
      </div>
    </div>
    <div class="form-group col-sm-12">
      <div class="forecast">
        <div class="forecast-item mb-1">
          Forecast / Budget:
        </div>
        <div class="forecast-item" *ngFor="let option of opcoesTaxaVersao">
          <mat-radio-group [(ngModel)]="versao.taxaBudgetId.id">
            <mat-radio-button class="mr-3" [value]="option.id">
              {{ option.descricao }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="forecast mt-1">
        <div class="forecast-item">
          Realizado:
        </div>
        <div class="forecast-item" *ngFor="let option of opcoesTaxaVersao">
          <mat-radio-group [(ngModel)]="versao.taxaRealizadoId.id">
            <mat-radio-button class="mr-3" [value]="option.id">
              {{ option.descricao }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row mt-2"
    *ngIf="versao.configVersao.tipoOrcamento.id === 1 || versao.configVersao.tipoOrcamento.id === 2">
    <div class="form-group col-sm-12 mb-0">
      <h3 class="sub-title">CLONAR</h3>
    </div>
    <div class="form-group col-sm-3"
      *ngIf="versao.configVersao.tipoOrcamento.id === 1 || versao.configVersao.tipoOrcamento.id === 2">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="clonar"
        [disabled]="!boolCriarStatus">
        CLONAR
      </mat-slide-toggle>
    </div>
    <div class="form-group col-sm-3" *ngIf="clonar">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="origemReal"
        [disabled]="!boolCriarStatus">
        ORIGEM REALIZADO
      </mat-slide-toggle>
    </div>
    <div class="form-group col-sm-4 mr-2" *ngIf="clonar && !origemReal">
      <label class="d-block" style="margin-top: 5px">ORIGEM <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" placeholder="Selecione" [filter]="true"
        [options]="opcoesVersoes" [(ngModel)]="versaoOrigem" [disabled]="!boolCriarStatus"></p-dropdown>
    </div>
    <div class="form-group col-sm-4 mr-2" *ngIf="clonar && origemReal">
      <label class="d-block" style="margin-top: 5px">ORIGEM <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesAnoOrigem"
        [(ngModel)]="anoRealOrigem" appendTo="body" [disabled]="!boolCriarStatus">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3" *ngIf="clonar && !origemReal && versao.configVersao.tipoOrcamento.id === 2">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 3px" [(ngModel)]="usarRealizado"
        [disabled]="!boolCriarStatus">
        FORECAST
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row mt-2" *ngIf="usarRealizado && !origemReal && clonar">
    <div class="col-sm-12">
      <h3 class="sub-title">OPÇÕES FORECAST</h3>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>ANO DO REALIZADO <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesAnoOrigem"
            [(ngModel)]="anoRealizado" appendTo="body" [disabled]="!boolCriarStatus">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>MÊS INICIAL <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesMeses"
            [(ngModel)]="mesInitRealizado" appendTo="body" [disabled]="!boolCriarStatus">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>MÊS FINAL <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesMeses"
            [(ngModel)]="mesFinRealizado" appendTo="body" [disabled]="!boolCriarStatus">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="usarRealizado && !origemReal && clonar">
    <div class="form-group col-sm-12 mb-0" style="margin-left: -14px;">
      <h3 class="sub-title">OPÇÕES CÁLCULO FORECAST</h3>
    </div>

    <div>
      <ul style="display: flex; list-style: none; margin-left: -30px;">
        <li>
          <p-checkbox [(ngModel)]="foreConstant" (change)="calculoTipoChange($event.checked, 0)" [binary]="true" (onChange)="singleConstantProp()" [disabled]="!boolCriarStatus">
          </p-checkbox>
          <label style="margin-left: 10px; margin-top: 6px;">Constante</label>
        </li>
        <li>
          <div style="margin-left: 20px;">
            <p-checkbox [(ngModel)]="foreConstantProp" (change)="calculoTipoChange($event.checked, 1)" [binary]="true" (onChange)="singleConstant()" [disabled]="!boolCriarStatus">
            </p-checkbox>
            <label style="margin-left: 10px;">Proporcional</label>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="cancelar()"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button *ngIf="!boolCriarStatus && boolEditStatus" type="button" class="btn btn-primary"
      (click)="editarRegistro()"><i class="fas fa-check"></i> Salvar</button>
    <button *ngIf="boolCriarStatus && !boolEditStatus" type="button" class="btn btn-primary"
      (click)="criarNovoRegistro()"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">
    </div>
  </div>
</p-dialog>