<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>

  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> RELATÓRIOS TRANSFER PRICING PARA EXPORTAÇÃO EM EXCEL
      </h3>
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col">
        <div class="card-deck card-custom" *ngIf="tpConfigRelat.id != null">
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="showDialogRelatorio()"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="showDialogRelatorio()"
                matTooltip="{{tpConfigRelat.codigo}} - {{tpConfigRelat.nome}}" matTooltipPosition="above"
                style="width: 150px;"><span class="d-block">{{tpConfigRelat.codigo}} - {{tpConfigRelat.nome}}</span>
              </h5>
            </div>
          </div>
        </div>
        <div class="card-deck card-custom" *ngIf="tpConfigRelat.id == null">
          <div class="card">
            <div class="card-body">
              <span class="icon-card"><i class="fas fa-times"></i></span>
              <h5 class="card-title" matTooltip="Não Existe Nenhuma Configuração Cadastrada" matTooltipPosition="above"
                style="width: 150px; font-size: 11px; "><span class="d-block">Não Existe Nenhuma Configuração
                  Cadastrada</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- MODAL -->
  <p-dialog header="{{tpConfigRelat.codigo}} - {{tpConfigRelat.nome}}" [modal]="true" appendTo="body" width="450"
    styleClass="modal-initial" [(visible)]="modalRelatorio" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>MÊS INICIAL</label>
        <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="selecione" [options]="opcoesMeses"
          [(ngModel)]="mesIni"></p-dropdown>
      </div>
      <div class="form-group col-sm-6">
        <label>MÊS FINAL</label>
        <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="selecione" [options]="opcoesMeses"
          [(ngModel)]="mesFin"></p-dropdown>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorio()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>