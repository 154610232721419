<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Periodos</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="openAddPeriodo()">
                  <span class="material-icons" matTooltip="Adicionar" matTooltipPosition="above"
                    style="font-size: 22px;">add_circle_outline</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardPeriodo()"
                  (click)="changeStatePeriodo()">
                  <span *ngIf="isCardPeriodo" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardPeriodo" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStatePeriodo>
            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt [columns]="colsPeriodosLista" [value]="periodosLista" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_ini'">
                      <span class="text-overflow-dynamic-ellipsis">DATA INICIAL <p-sortIcon [field]="'dt_ini'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dt_ini', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_fin'">
                      <span class="text-overflow-dynamic-ellipsis">DATA FINAL <p-sortIcon [field]="'dt_fin'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dt_fin', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'status', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtencerramento'">
                      <span class="text-overflow-dynamic-ellipsis">DATA ENCERRAMENTO <p-sortIcon
                          [field]="'dtencerramento'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dtencerramento', 'contains')">
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                      <span class="text-overflow-dynamic-ellipsis">USUÁRIO <p-sortIcon [field]="'usuario'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'usuario', 'contains')">
                  </th>
                  <th class="text-center" style="width: 90px; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dt_ini | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.dt_ini | date :
                        'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dt_fin | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.dt_fin | date :
                        'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dtencerramento | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.dtencerramento
                        | date : 'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 90px;">
                    <button *ngIf="rowData.status_id === 1" type="button" style="padding: 0 !important;"
                      (click)="mudarStatusPeriodo(rowData)" class="btn btn-xs btn-link btn-full"
                      matTooltip="Encerrar Período" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">check</span>
                    </button>
                    <button type="button" *ngIf="rowData.status_id === 2 || rowData.status_id === 3"
                      style="padding: 0 !important;" (click)="mudarStatusPeriodo(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Abrir Período" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">folder_open</span>
                    </button>
                    <button *ngIf="rowData.status_id === 1" type="button" style="padding: 0 !important;"
                      (click)="openEditPeriodo(rowData)" class="btn btn-xs btn-link btn-full" matTooltip="Editar"
                      matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">edit</span>
                    </button>
                    <button type="button" *ngIf="rowData.status_id === 1" style="padding: 0 !important;"
                      (click)="deletePeriodo(rowData)" class="btn btn-xs btn-link btn-full" matTooltip="Excluir"
                      matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">delete</span>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum período encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVO -->
<p-dialog header="Adicionar novo período" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione a versão do orçamento</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="orcamentoVersaoIdNew" [options]="listVersao" [filter]="true"
        placeholder="Selecionar uma versão" appendTo="body"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInicialNew"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-6">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFimNew"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addPeriodo();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT -->
<p-dialog header="Editar período" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInicialEdit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-6">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFimEdit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editPeriodo();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>