<div style="padding: 1.5rem" class="pt-3">

  <div class="row d-flex align-items-center" style="padding-left: 1rem;">
    <div class="col-6" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        {{title}}
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">{{subtitle}}</small>
          <br>
        </div>
      </h4>
    </div>
    <div *ngIf="isReady" class="col-6 d-flex justify-content-end">
      <button class="btnCustom-default" matTooltip="Configurações da dashboard" (click)="openModal()">
        <span class="material-icons">settings</span>
        <div>Configurações</div>
      </button>
    </div>
  </div>

  <div class="row pb-4" *ngIf="!isReady">
    <div class="col-12">
      <div class="alert alert-xs alert-primary d-inline" role="alert">
        <i class="fas fa-info-circle"></i> {{isNotReady()}}
      </div>
    </div>
  </div>

  <div class="row pb-4" *ngIf="!hideShortcuts && isReady">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">Seus atalhos</h3>
            <div class="actions-card">
              <button class="btnCustom-default" matTooltip="{{isCardShortcuts ? 'Ver mais' : 'Ver menos'}}"
                (click)="changeStateShortcuts()">
                <span class="material-icons">{{isCardShortcuts ? 'expand_more' : 'expand_less'}}</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content shortcutContainer" [@changeDivSize]=currentStateShortcuts>
          <app-toolbar *ngIf="!toolbarLoading && toolbarShortcutsButtons.length > 0" [value]="toolbarShortcuts"></app-toolbar>

          <div *ngIf="!toolbarLoading && toolbarShortcutsButtons.length <= 0" class="alert alert-xs alert-primary d-inline mb-0" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum atalho foi adicionado
          </div>

          <div *ngIf="toolbarLoading" class="w-100 d-flex justify-content-start align-items-center">
            <p-skeleton width="130px" height="40px" class="mr-3"></p-skeleton>
            <p-skeleton width="130px" height="40px" class="mr-3"></p-skeleton>
            <p-skeleton width="130px" height="40px" class="mr-3"></p-skeleton>
            <p-skeleton width="130px" height="40px" class="mr-3"></p-skeleton>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row pb-4" *ngIf="isReady">
    <div class="col-sm-12">
      <div class="w-100">
        <div class="shadow-box">
          <div class="header-box" style="background-color: transparent !important">
            <div class="header-box-content p-3">
              <h3 class="title-card">Sua dashboard: {{dashSelected.name || 'nenhuma selecionada'}}</h3>
              <div class="actions-card d-flex align-items-center justify-content-end">
                <p-dropdown class="mr-3" [filter]="true" placeholder="Selecione a dashboard" [options]="favoriteDashs" [(ngModel)]="selectedDashId" [emptyMessage]="dropdownMessage" appendTo="body" (onChange)="changeDashboard()"></p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="box-content dashboardContainer" style="background-color: transparent !important; padding: 15px 0 !important;">
          <div class="w-100 h-100">
            <app-dash-main [dashId]="dashSelected.id"></app-dash-main>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Configurações" [(visible)]="showSettingsModal" [style]="{width: '1000px'}" [modal]="true"
  styleClass="modal-initial" appendTo="body" [closable]="true">
  <div class="row" style="max-height: max-content; min-height: 500px;">
    <div class="col-2">
      <div class="menu">
        <div class="sub-menu">
          <div class="title">Atalhos</div>
          <ul>
            <li class="item" (click)="changeSettingsTab(0)" [ngClass]="{active: isShortcuts}">Geral</li>
          </ul>
        </div>
        <div class="sub-menu">
          <div class="title">Dashboard</div>
          <ul>
            <li class="item" (click)="changeSettingsTab(1, 'financeiro')" [ngClass]="{active: originDashSelected === 'financeiro'}">Financeiro</li>
            <li class="item" (click)="changeSettingsTab(1, 'compras')" [ngClass]="{active: originDashSelected === 'compras'}">Compras</li>
            <li class="item" (click)="changeSettingsTab(1, 'vendas')" [ngClass]="{active: originDashSelected === 'vendas'}">Vendas</li>
            <li class="item" (click)="changeSettingsTab(1, 'rms')" [ngClass]="{active: originDashSelected === 'rms'}">RMS</li>
            <li class="item" (click)="changeSettingsTab(1, 'estoque')" [ngClass]="{active: originDashSelected === 'estoque'}">Estoque</li>
          </ul>
        </div>
        <div class="sub-menu">
          <div class="title">E-mail</div>
          <ul>
            <li class="item" (click)="changeSettingsTab(2)" [ngClass]="{active: isEmail}">Geral</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-10">
      <div class="tab-content tab-empty" style="border: none;" *ngIf="isNoTab">
        <span class="material-icons">campaign</span>
        <small>Selecione uma aba para começar!</small>
      </div>
      <div class="tab-content" style="border: none;" *ngIf="!isNoTab && isShortcuts">
        <div class="header">
          <div class="title">
            <h2>Organizar atalhos</h2>
            <small>Escolha e organize até 8 atalhos para sua dashboard!</small>
          </div>
          <button *ngIf="!hideShortcuts" [disabled]="btnShortcutsLoading" class="btnCustom-green" matTooltip="Salvar alterações" (click)="saveMyShortcuts()">
            <span class="material-icons" [ngClass]="{ isLoading: btnShortcutsLoading }">{{btnShortcutsLoading ? 'hourglass_empty' : 'save'}}</span>
            <div>{{btnShortcutsLoading ? 'Carregando' : 'Salvar'}}</div>
          </button>
        </div>
        <p-table *ngIf="!hideShortcuts" #dt styleClass="table-system2 my-3" [value]="shortcutsData" [style]="{'width':'100%'}" immutable="false"
        [rows]="shortcutsData.length" dataKey="id" [reorderableColumns]="true" [scrollable]="true" scrollHeight="300px"
        [(selection)]="selectedShortcuts" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
        (onRowReorder)="onRowReorder()" [loading]="tableShortcutsLoading" [showLoader]="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox"> <span class="check-all p-0"></span> </th>
              <th class="text-center tableHeaderCheckbox"> <span class="check-all p-0"></span> </th>
              <th style="width: 70px;"> <div class="text-center"> Ordem </div> </th>
              <th style="width: 50%;"> <div class="text-left"> Atalho </div> </th>
              <th> <div class="text-left"> Módulo </div> </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-index="rowIndex">
            <tr [pReorderableRow]="index" class="line" [pRowToggler]="rowData">
              <td class="text-center tableCheckbox">
                <span class="material-icons" [pReorderableRowHandle]="index">drag_handle</span>
              </td>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox [disabled]="rowData.disabledSelection" [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
              </td>
              <td class="text-center" style="width: 70px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.order}}">{{rowData.order}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 50%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.shortcut}}">{{rowData.shortcut}}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.module}}">{{rowData.module}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr *ngFor="let item of tableShortcutLoading">
              <td class="text-center" style="width: 45px;">
                <p-skeleton width="100%"></p-skeleton>
              </td>
              <td class="text-center" style="width: 45px;">
                <p-skeleton width="100%"></p-skeleton>
              </td>
              <td class="text-center" style="width: 70px;">
                <p-skeleton width="100%"></p-skeleton>
              </td>
              <td class="text-center" style="width: 50%;">
                <p-skeleton width="100%"></p-skeleton>
              </td>
              <td class="text-center">
                <p-skeleton width="100%"></p-skeleton>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="14" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum atalho disponível!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-field-checkbox mt-3">
          <p-checkbox class="mr-1" [(ngModel)]="hideShortcuts" [binary]="true" inputId="hideShortcuts" (onChange)="saveHideShortcuts()"></p-checkbox>
          <label for="hideShortcuts" style="margin: 0">Ocultar atalhos</label>
        </div>
        <div class="hidden" *ngIf="hideShortcuts">
          <span class="material-icons">warning</span>
          <small>Ocultar os atalhos farão com que eles não apareçam em sua tela inicial! Desabilitamos a edição dos seus atalhos.</small>
        </div>
      </div>
      <div class="tab-content" style="border: none;" *ngIf="!isNoTab && isDash">
        <div class="header">
          <div class="title">
            <h2>Dashboard {{originDashSelected}}</h2>
            <small>Defina sua dashboard principal e suas favoritas!</small>
          </div>
        </div>
        <div class="content mt-3">
          <div class="row" *ngIf="!dashboardsLoading">
            <div class="col-6 mb-3" *ngFor="let dash of dashboardData">
              <div class="card-dash">
                <img [src]="dash.link_image" [alt]="dash.name">
                <div class="layer">
                  <div class="text">
                    <h2>{{dash.name}}</h2>
                    <p>{{dash.description}}</p>
                  </div>
                  <div class="actions">
                    <button class="btn-favorite" matTooltip="Favoritar" (click)="setDashUser(dash, false)" [ngClass]="{active: dash.isFavorite}">
                      <span class="material-icons">star</span>
                      <div>Favoritar</div>
                    </button>
                    <button class="btn-main" matTooltip="Definir principal" (click)="setDashUser(dash, true)" [ngClass]="{active: dash.isMain}">
                      <span class="material-icons">push_pin</span>
                      <div>Principal</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="dashboardsLoading">
            <div class="col-6 mb-3">
              <p-skeleton width="100%" height="170px"></p-skeleton>
            </div>
            <div class="col-6 mb-3">
              <p-skeleton width="100%" height="170px"></p-skeleton>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content" style="border: none;" *ngIf="!isNoTab && isEmail">
        <div class="header">
          <div class="title">
            <h2>Envio de e-mail</h2>
            <small>Configure os paramêtros para receber seu e-mail!</small>
          </div>
          <button [disabled]="btnEmailLoading" class="btnCustom-green" matTooltip="Salvar alterações" (click)="setDashboardPermission()">
            <span class="material-icons" [ngClass]="{ isLoading: btnEmailLoading }">{{ btnEmailLoading ? 'hourglass_empty' : 'save' }}</span>
            <div>{{ btnEmailLoading ? 'Carregando' : 'Salvar' }}</div>
          </button>
        </div>
        <div class="form-row col-sm-12 mt-4" style="padding: 0">
          <div class="form-group col-sm-6">
            <label>Frequência de envio do e-mail</label>
            <p-multiSelect appendTo="body" class="type-p-mult" [filter]="true" placeholder="Selecione" [options]="optIntervals" [(ngModel)]="selectedRanges" [disabled]="isLoadingOptIntervals" [dropdownIcon]="isLoadingOptIconIntervals ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-multiSelect>
            <div *ngIf="selectedRanges.length === 0" class="alert alert-xs alert-primary d-inline" role="alert" >
              <i class="fas fa-check-circle"></i> O seu envio de e-mail está desativado!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>