<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Requisição de Material
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
          <br>
          <small class="font-weight-normal">Período selecionado: {{convertTimestamp(dateIni)}} à {{convertTimestamp(dateFin)}}</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <ul *ngIf="!formNewRequisition" class="list-inline filter-menu filter-card-header mt-0">
              <li class="list-inline-item" [ngClass]="{active : tabMovementSelected == 's'}">
                  <a (click)="changeTab('s')" [ngClass]="loadingTable ? 'loading-tabs' : ''">
                    Compras ({{totalSaidas}})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{active : tabMovementSelected == 'e'}">
                  <a (click)="changeTab('e')" [ngClass]="loadingTable ? 'loading-tabs' : ''">
                   Vendas ({{totalEntradas}})
                  </a>
                </li>
            </ul>
            <h3 *ngIf="formNewRequisition" class="title-card">{{isEditRMS ? ('CÓDIGO: ' + requisitionSelected['key']) : '&nbsp;' }}</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div *ngIf="!formNewRequisition" class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="(tabMovementSelected == 's') ? tableDataOutflow : tableDataInflow"  dataKey="id" [style]="{'width':'100%'}"
            [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [(selection)]="selectedRequisitions"
            (onRowSelect)="changeToolbar()" (onHeaderCheckboxToggle)="changeToolbar()" (onRowUnselect)="changeToolbar()" *ngIf="hasTimeCourse">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox [disabled]="isDisabled"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtCriacao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DT. CRIAÇÃO <p-sortIcon [field]="'dtCriacao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" [(ngModel)]="creationDataFilterTable" type="text" (input)="dt.filter($event.target.value, 'dtCriacaoStr', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEntrega'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DT. ENTREGA <p-sortIcon [field]="'dtEntrega'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="deliverynDataFilterTable" (input)="dt.filter($event.target.value, 'dtEntregaStr', 'contains')">
                  </th>
                  <th class="text-center" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'key'">
                      <span class="text-overflow-dynamic-ellipsis">
                        KEY <p-sortIcon [field]="'key'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" [(ngModel)]="keyFilterTable" type="text" (input)="dt.filter($event.target.value, 'key', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_pedido'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DESTINO <p-sortIcon [field]="'tipo_pedido'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [(ngModel)]="destinysFilterTable" [options]="destinysTable" defaultLabel="Selecione" [filter]="true" (onChange)="dt.filter($event.value, 'tipo_pedido', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'objeto_descr'">
                      <span class="text-overflow-dynamic-ellipsis">
                        OBJETO <p-sortIcon [field]="'objeto_descr'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [(ngModel)]="objectFilterTable" [options]="objectsTable" defaultLabel="Selecione" [filter]="true" (onChange)="dt.filter($event.value, 'objeto_descr', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        TÍTULO <p-sortIcon [field]="'titulo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="titleFilterTbale" (input)="dt.filter($event.target.value, 'titulo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'prioridade_nome'">
                      <span class="text-overflow-dynamic-ellipsis">
                        PRIORIDADE <p-sortIcon [field]="'prioridade_nome'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [(ngModel)]="prioritiesFilterTable" [options]="prioritiesTable" defaultLabel="Selecione" [filter]="true" (onChange)="dt.filter($event.value, 'prioridade_nome', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'requisitante_nome'">
                      <span class="text-overflow-dynamic-ellipsis">
                        REQUISITANTE <p-sortIcon [field]="'requisitante_nome'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [(ngModel)]="applicantsFilterTable" [options]="applicantsTable" defaultLabel="Selecione" [filter]="true" (onChange)="dt.filter($event.value, 'requisitante_nome', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'labelAprovador'">
                      <span class="text-overflow-dynamic-ellipsis">
                        APROVADOR <p-sortIcon [field]="'labelAprovador'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [(ngModel)]="approversFilterTable" [options]="approversTable" defaultLabel="Selecione" [filter]="true" (onChange)="dt.filter($event.value, 'labelAprovador', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">
                        VALOR (R$) <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="valueFilterTable" (input)="dt.filter($event.target.value, 'valor', 'contains')">
                  </th>
                  <th class="text-center" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">
                        STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [(ngModel)]="statusFilterTable" [options]="statusTable" defaultLabel="Selecione" [filter]="true" (onChange)="dt.filter($event.value, 'status', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-center" style="width: 45px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        AÇÃO
                      </span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [value]="rowData" [disabled]="isDisabled"></p-tableCheckbox>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtCriacaostr }}">
                        {{rowData.dtCriacaoStr}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtEntregaStr }}">
                        {{rowData.dtEntregaStr}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.key}}">
                        {{rowData.key}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo_pedido}}">
                        {{rowData.tipo_pedido}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.objeto_descr}}">
                        {{rowData.objeto_descr}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.titulo}}">
                        {{rowData.titulo}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.prioridade_nome}}">
                        {{rowData.prioridade_nome}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.requisitante_nome}}">
                        {{rowData.requisitante_nome}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{listApprovers(rowData.aprovadores)}}">
                        {{rowData.labelAprovador}}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor | money}}">
                        {{rowData.valor | money}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">
                        {{rowData.status}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center action-cell">
                    <span class="check-all p-0">
                      <span matTooltip="Abrir sidebar" class="material-icons" (click)="rowSidebar(rowData.todoCardAprovacaoId)">
                        view_sidebar
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="12" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
        <app-form-material-request
          [isRender]="formNewRequisition"
          [isGestor]="isGestor"
          [_empresaId]="empresaId"
          [_matrizId]="matrizId"
          [_userId]="userId"
          [isStartingSave]="startSaveRMS"
          [isEditRMS]="isEditRMS"
          [isStartingEdit]="startEditRMS"
          [selectedRMS]="requisitionSelected"
          (isFinishedSave)="finishedSavingRMS($event)"
          (isFinishedEdit)="finishedEditingRMS($event)"
          (isFormLoading)="disabledToolbar($event)"
        ></app-form-material-request>
        <div class="col-sm-12 my-3 d-flex justify-content-start" *ngIf="formNewRequisition">
          <app-toolbar [value]="toolbarMain"></app-toolbar>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Filtrar Período" [(visible)]="showFilter" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dateIni" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dateFin" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Data para filtro</label>
      <div class="radio-date-container">
        <p-radioButton value="deliveryDay" label="Data de Entrega" [(ngModel)]="selectedDate"></p-radioButton>
        <p-radioButton value="creationDay" label="Data de Criação" [(ngModel)]="selectedDate"></p-radioButton>
      </div>
    </div>
  </div>

  <p-footer>
    <div id="div-botoes">
      <div class="w-100 d-flex align-items-center justify-content-end">
        <button type="button" class="btnCustom-default mr-3" (click)='showFilterModal(false)'>
          <span class="material-icons">close</span>
          <div>Cancelar</div>
        </button>
        <button type="button" class="btnCustom-green" [disabled]='!dateIni || !dateFin || dateIni > dateFin' (click)='saveFilterModal()'>
          <span class="material-icons">filter_alt</span>
          <div>Filtrar</div>
        </button>
      </div>
    </div>
  </p-footer>

</p-dialog>