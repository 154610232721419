<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content" style="padding: 1rem !important; margin-top: -25px">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbar"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta" style="margin-top: -5px;">



            <p-table class="table-system" #dt [value]="registrosGruposEstoque" selectionMode="single" [paginator]="true"
               [rows]="rows" [rowsPerPageOptions]="rowsPerPage" (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th  style="width: 50px;"></th>
                    <th class="text-left" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                          Codigo <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                    </th>
  
                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                          Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData" [id]="'mycheck'+rowData.id" >
                    <td class="text-center" style="width: 40px;" >
                      <p-tableRadioButton  [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                      </p-tableRadioButton >
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" 
                          matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                      </span>
                    </td>
  
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"  
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                   

                    <!-- <td style="width: 10%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="edit" matTooltip="Clonar" (click)="abrirModalLancamentoClone(rowData)">
                          <i class="far fa-clone"></i>
                        </span>
                        <span class="edit" matTooltip="Editar" (click)="editarRegistroLancamento(rowData)">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span class="delete" matTooltip="Excluir" (click)="removerRegistroLancamento(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>
                      </span>
                    </td> -->
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="3" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>

          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div class="form">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Código<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="codigo">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricao">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>