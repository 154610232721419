<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Unidade Medida
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Relacionar unidade medida do sistema com as de NF</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">Selecionar unidade medida </h3>
          </div>
        </div>
        <div class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="lista" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon
                          [field]="'codigo'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon
                          [field]="'descricao'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codigo }}"
                        (click)="onRowClick(rowData)">{{
                        rowData.codigo }}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}"
                        (click)="onRowClick(rowData)">{{
                        rowData.descricao }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="2" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert"><i class="fas fa-info-circle"></i>
                      Nenhum Registro Encontrado!</div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="vincularUnidadeMedida" class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-exchange-alt"></i> Vinculação de unidade medida</h3>
              <div class="conteudo mt-2">
                <p-pickList sourceHeader="Disponíveis" targetHeader="Vinculadas"
                  [source]="listUnidadesDisponiveis" [target]="listUnidadesVinculadas" [dragdrop]="true"
                  [responsive]="true" filterBy="codigo" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-unidade pTemplate="item">{{ unidade.codigo }}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="form-group col-sm-12">
              <button type="button" class="btn btn-primary" (click)="salvarVinculacao()">
                <i class="fas fa-check"></i> Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>