<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">
      </div>
    </div>
  </div>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="PERÍODO ({{dblinkedSessionService.periodo.year.value}})">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" [(value)]="periodoFin">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 22%" class="text-center">MÊS</th>
                    <th style="width: 22%" class="text-center">STATUS</th>
                    <th style="width: 22%" class="text-center">DATA ENCERRAMENTO</th>
                    <th style="width: 22%" class="text-center">USUARIO</th>
                    <th style="width: 12%" class="text-center">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 22%" class="text-center">{{ rowData.mes }}</td>
                    <td style="width: 22%" class="text-center">{{ rowData.status }}</td>
                    <td style="width: 22%" class="text-center">{{ rowData.dt_encerramento | date:'dd/MM/yyyy' : '+000'
                      }}</td>
                    <td style="width: 22%" class="text-center">{{ rowData.usuario }}</td>
                    <td style="width: 12%" class="text-center">
                      <div class="d-flex align-items-center justify-content-around">
                        <span class="edit" matTooltip="Recalcular Saldo" (click)="recalcularSaldo(rowData)"><i
                            class="far fa-clock"></i>
                        </span>
                        <span class="edit" *ngIf="rowData.statusId === 1" matTooltip="Encerrar Período" (click)="mudarStatusPeriodo(rowData)">
                          <i class="fas fa-check"></i>
                        </span>

                        <span class="delete" *ngIf="rowData.statusId === 2" matTooltip="Abrir Período" (click)="mudarStatusPeriodo(rowData)">
                          <i class="far fa-folder-open"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem período!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
