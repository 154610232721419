<mat-card class="card-linnks margin-linnks">

  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-street-view" style="font-size: 18px;"></i>Orçamentos Gerencial
              </h3>
              <div class="actions-card">
                <button type="button" (click)="alternarCentroCusto()" class="btn btn-xs btn-link"
                  matTooltip="Buscar Centro de Custo" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 24px;">search</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideOrcaGer()"
                  (click)="changeStateOrcaGer()">
                  <span *ngIf="isCardOrcaGer" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardOrcaGer" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStateOrcaGer>
            <p-table class="table-system" #dt [columns]="colsDadosOrcamento" [value]="dadosOrcamento" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false" (onFilter)="onFilter($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                      <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL <p-sortIcon [field]="'codCta'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codCta', 'contains')">
                  </th>
                  <th class="text-center" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'linha'">
                      <span class="text-overflow-dynamic-ellipsis">LINHA <p-sortIcon [field]="'linha'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'linha', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoCompleta'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricaoCompleta'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricaoCompleta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 18%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoRazao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO RAZÃO <p-sortIcon
                          [field]="'descricaoRazao'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricaoRazao', 'contains')">
                  </th>
                  <th class="text-right" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorOrcamento'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR ORÇAMENTO <p-sortIcon
                          [field]="'valorOrcamento'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valorOrcamento', 'contains')">
                  </th>
                  <th class="text-right" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorRelacionado'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR RELACIONADO <p-sortIcon
                          [field]="'valorRelacionado'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valorRelacionado', 'contains')">
                  </th>
                  <th class="text-center" style="width: 5%; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">
                        {{ rowData.codCta }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.linha }}">{{ rowData.linha
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.descricaoCompleta }}">{{
                        rowData.descricaoCompleta }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 18%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.descricaoRazao }}">{{
                        rowData.descricaoRazao }}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.valorOrcamento }}">{{
                        rowData.valorOrcamento }}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.valorRelacionado }}">{{
                        rowData.valorRelacionado }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 5%;">
                    <span class="edit" (click)="selecionarComentario(rowData)"
                      matTooltip='Visualzar {{rowData.qtdeCom}} Comentário(s)' *ngIf="rowData.qtdeCom > 0">
                      <i class="fas fa-comment-dots"></i>
                    </span>
                    <span class="edit" (click)="selecionarComentario(rowData)" matTooltip='Inserir Comentários'
                      *ngIf="rowData.qtdeCom === 0">
                      <i class="far fa-comment"></i>
                    </span>
                    <span class="edit" (click)="selecionarProvisao(rowData)"
                      matTooltip="Visualzar {{rowData.qtdeProv}} Provisão(ões).&#013;  Saldo disponível R$ {{rowData.saldoProvisao}}"
                      *ngIf="rowData.qtdeProv  > 0">
                      <i class="fas fa-money-bill-alt"></i>
                    </span>
                    <span class="edit" (click)="selecionarProvisao(rowData)" matTooltip='Inserir Provisão'
                      *ngIf="rowData.qtdeProv === 0">
                      <i class="far fa-money-bill-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!---Modal COMENTÁRIO-->
<p-dialog header="Visualizar Comentários" [(visible)]="exibirComentario" styleClass="modal-scroll"
  [style]="{width: '800px'}" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-comment-dots"></i> CRIAR COMENTÁRIO</h3>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-12 mb-1">

      <!--<textarea class="form-control" rows="5" type="text" value="pedido.enderecoCobranca"
          [(ngModel)]="pedido.enderecoCobranca"></textarea>-->
      <textarea class="form-control" value="comentario" [(ngModel)]="comentario" [disabled]="!podeEditar" rows="3"
        type="text"></textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-sm btn-secondary" (click)="limparComentario()"><i class="fas fa-times"></i>
        Cancelar </button>
      <button [disabled]="!podeEditar" type="button" class="btn btn-sm btn-danger" *ngIf="comentarioId > 0"
        (click)="excluirComentario()"><i class="fas fa-trash"></i> Excluir</button>
      <button [disabled]="!podeEditar" type="button" class="btn btn-sm btn-primary" *ngIf="comentarioId > 0"
        (click)="salvarComentario()"><i class="fas fa-check"></i> Atualizar</button>
      <button [disabled]="!podeEditar" type="button" class="btn btn-sm btn-primary" *ngIf="comentarioId === 0"
        (click)="salvarComentario()"><i class="fas fa-check"></i> Salvar</button>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default panel-xs mt-4">
        <div class="panel-heading">
          Comentários <span class="btn-add" (click)="adicionarComentario()" matTooltip="Adicionar Comentário"><i
              class="fas fa-plus"></i></span>
        </div>
        <div class="panel-body" style="min-height: 150px;">
          <ng-container>
            <ul class="list-group list-hist">
              <li *ngFor="let c of comentariosOrcamento" (click)="editarComentario(c)" class="list-group-item"
                style="cursor: pointer;">
                <strong class="name">{{c.usuario}}</strong>
                <span class="date"><i class="fas fa-calendar-alt"></i> {{c.dtHrEnvio}}</span>
                <p class="description"><strong>Comentário:</strong> {{c.comentario}}</p>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='exibirComentario=false'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal OBSERVAÇÃO-->

<!-- Inicio Modal Alternar Centro de custo FEITO-->
<p-dialog header="Alternar Centro de custo" [(visible)]="modalAlternarCentroCusto" [style]="{width: '700px'}"
  [modal]="true" class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione um Centro de Custo</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="false" [options]="opcoesCentroCusto"
        placeholder="Selecione um Centro de Custo" [(ngModel)]="centroCustoSelecionado"
        (onChange)="alternarCentroCustoSelecionada($event)"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalAlternarCentroCusto=false'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>
<!--  Final Modal Alternar Centro de custo FEITO-->

<!-- Inicio Model Alternar periodo -->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onChange)="changeDataIni($event)">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false; dataIni = null; dataFin = null'><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="isProcurarPorData = true;change();"><i
        class="fas fa-pencil-alt"></i> Alterar</button>
  </p-footer>
</p-dialog>
<!-- Final model Alternar periodo -->

<!---Modal provisao-->
<p-dialog header="Visualizar Provisão" [(visible)]="exibirProvisao" styleClass="modal-scroll" [style]="{width: '800px'}"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-money-bill-alt"></i> CRIAR PROVISÃO</h3>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-2 mb-1">
      <label>VALOR</label>
      <input class="form-control text-right" value="{{valorProvisao}}" #valor [(ngModel)]="valorProvisao" />
    </div>
    <div class="form-group col text-right">
      <label class="d-block">SALDO DISPONÍVEL </label>
      <p>R$ {{valorMaximoProvisao}}</p>
    </div>

  </div>


  <div class="form-row">
    <div class="form-group col-sm-12 mb-1">

      <textarea class="form-control" value="justificativaProvisao" [(ngModel)]="justificativaProvisao" rows="3"
        type="text"></textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-sm btn-secondary" (click)="limparProvisao()"><i class="fas fa-times"></i>
        Cancelar </button>
      <button [disabled]="!podeEditar" type="button" class="btn btn-sm btn-danger" *ngIf="provisaoId > 0"
        (click)="excluirProvisao()"><i class="fas fa-trash"></i> Excluir</button>
      <button [disabled]="!podeEditar" type="button" class="btn btn-sm btn-primary" *ngIf="provisaoId > 0"
        (click)="salvarProvisao()"><i class="fas fa-check"></i> Atualizar</button>
      <button [disabled]="!podeEditar" type="button" class="btn btn-sm btn-primary" *ngIf="provisaoId === 0"
        (click)="salvarProvisao()"><i class="fas fa-check"></i> Salvar</button>
    </div>
  </div>


  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default panel-xs mt-4">
        <div class="panel-heading">
          Provisões <span class="btn-add" (click)="adicionarProvisao()" matTooltip="Adicionar Provisão"><i
              class="fas fa-plus"></i></span>
        </div>
        <div class="panel-body" style="min-height: 150px;">
          <ng-container>
            <ul class="list-group list-hist">
              <li *ngFor="let c of provisoesOrcamento" (click)="editarProvisao(c)" class="list-group-item"
                style="cursor: pointer;">
                <strong class="name">{{c.usuario}}</strong>
                <p class="description"><strong>Valor:</strong> {{c.valor}}</p>
                <span class="date"><i class="fas fa-calendar-alt"></i> {{c.dtHrProvisao}}</span>
                <p class="description"><strong>Justificativa:</strong> {{c.justificativa}}</p>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='exibirProvisao=false'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal provisao-->