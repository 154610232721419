<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
      {{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à
      {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <!--Novo Modelo Tabs -->
    <div *ngIf="!modalEfetuarBaixa && !modalOperacaoPagto && !modalOperacao">
      <div class="row">
        <div class="col-sm-12">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content p-3">
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: statusMovimentacao}"><a
                      (click)="showTelaMovVenc('TELA_1')">Movimentação ({{ totalRecords }})</a></li>
                  <li class="list-inline-item" [ngClass]="{active: statusVencidos}"><a
                      (click)="showTelaMovVenc('TELA_2')">Vencidos ({{ totalVencidos }})</a></li>
                </ul>
                <div class="actions-card">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
                </div>
              </div>
            </div>
            <div class="box-content">
              <div [ngStyle]="statusMovimentacao ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusMovimentacao'}">

                <app-skeleton [config]="skeletonConfig">
                  <p-table class="table-system" #dt1 [value]="recebimentos" [rows]="rows" [paginator]="true"
                    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
                    [style]="{ width: '100%' }" [lazy]="true"
                    (onLazyLoad)="statusMovimentacao && getMoreTransactions($event)" [totalRecords]="totalRecords"
                    [loading]="loading" [columns]="cols" [scrollable]="false" (onRowSelect)="rowSelect($event)"
                    (onRowUnselect)="rowUnselect($event)" (onHeaderCheckboxToggle)="selectAll($event)"
                    [(selection)]="clearArr">

                    <ng-template pTemplate="header">
                      <tr>
                        <th class="text-center tableHeaderCheckbox">
                          <span class="check-all p-0">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                          </span>
                        </th>
                        <th class="text-center" style="width: 10%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'dt_vcto'">VENC/BAIXA
                            <p-sortIcon [field]="'dt_vcto'">
                            </p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'dt_vcto', 'contains')">

                        </th>
                        <th class="text-center" style="width: 8%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'dt_previsao'">PREVISÃO
                            <p-sortIcon [field]="'dt_previsao'">
                            </p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'dt_previsao', 'contains')">

                        </th>
                        <th class="text-center" style="width: 8%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'dt_emissao'">EMISSÃO
                            <p-sortIcon [field]="'dt_emissao'">
                            </p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'dt_emissao', 'contains')">

                        </th>
                        <th style="width: 12%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'nome'">NOME <p-sortIcon
                              [field]="'nome'"></p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'nome', 'contains')">

                        </th>
                        <th style="width: 12%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'razaoSocial'">RAZÃO SOCIAL
                            <p-sortIcon [field]="'razaoSocial'">
                            </p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'razaoSocial', 'contains')">

                        </th>
                        <th style="width: 12%;" *ngIf="relatorioAnalitico">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'operacao'">OPERAÇÃO <p-sortIcon
                              [field]="'operacao'">
                            </p-sortIcon></span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'operacao', 'contains')">

                        </th>
                        <th style="width: 12%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'nomeConta'">
                            CONTA <p-sortIcon [field]="'nomeConta'"></p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'nomeConta', 'contains')">
                        </th>
                        <th style="width: 12%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'historico'">HISTÓRICO
                            <p-sortIcon [field]="'historico'">
                            </p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'historico', 'contains')">

                        </th>
                        <th style="width: 9%;" *ngIf="relatorioAnalitico">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'numDoc'">DOC <p-sortIcon
                              [field]="'numDoc'"></p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'numDoc', 'contains')">

                        </th>
                        <th class="text-center" style="width: 6%;" *ngIf="relatorioAnalitico">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'parcela'">Parc <p-sortIcon
                              [field]="'parcela'"></p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'parcela', 'contains')">

                        </th>
                        <th class="text-right" style="width: 10%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'valor'">Valor <p-sortIcon
                              [field]="'valor'"></p-sortIcon>
                          </span>
                          <input pInputText class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'valor', 'contains')">

                        </th>
                        <th class="text-center" style="width: 10%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'status'">Status <p-sortIcon
                              [field]="'status'"></p-sortIcon>
                          </span>

                          <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                            [options]="statusOptions" defaultLabel="Selecione" [filter]="false"
                            (onChange)="dt1.filter($event.value, 'status', 'in')">
                          </p-multiSelect>
                        </th>

                        <th class="text-center" style="width: 10%;" *ngIf="flowUses">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'statusAprovacao'">
                            Status Aprovação <p-sortIcon [field]="'statusAprovacao'"></p-sortIcon>
                          </span>

                          <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                            [options]="statusAprovOptions" defaultLabel="Selecione" [filter]="false"
                            (onChange)="dt1.filter($event.value, 'statusAprovacao', 'in')">
                          </p-multiSelect>
                        </th>

                        <th class="text-center" style="width: 8%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'statusRemessa'">Remessa
                            <p-sortIcon [field]="'statusRemessa'">
                            </p-sortIcon>
                          </span>

                          <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                            [options]="remessaOptions" defaultLabel="Selecione" [filter]="false"
                            (onChange)="dt1.filter($event.value, 'statusRemessa', 'in')">
                          </p-multiSelect>
                        </th>

                        <th class="text-center" style="width: 8%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'tipoBaixa'">Tipo Baixa
                            <p-sortIcon [field]="'tipoBaixa'">
                            </p-sortIcon>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'tipoBaixa', 'contains')">
                        </th>

                        <th class="text-center" style="width: 10%;">
                          <span class="overflow-ellipse text-center" [pSortableColumn]="'historicoParcela'">Observações
                            <p-sortIcon [field]="'historicoParcela'"></p-sortIcon>
                          </span>

                          <input class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'historicoParcela', 'contains')">
                        </th>

                        <th class="text-center" style="width: 5%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'contabilizado'">
                            <span class="text-overflow-dynamic-ellipsis">Contabilidade <p-sortIcon
                                [field]="'contabilizado'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt1.filter($event.target.value, 'contabilizado', 'contains')">
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [ngClass]="aplyRowStyle(rowData)" style="cursor: pointer">
                        <td class="text-center tableCheckbox">
                          <p-tableCheckbox [value]="rowData">
                          </p-tableCheckbox>
                        </td>
                        <td class="text-center" style="width: 10%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.dt_vcto}}">{{rowData.dt_vcto}}</span>
                          </span>
                        </td>
                        <td class="text-center" style="width: 8%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.dt_previsao}}">{{rowData.dt_previsao}}</span>
                          </span>
                        </td>
                        <td class="text-center" style="width: 8%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.dt_emissao}}">{{rowData.dt_emissao}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.razaoSocial}}">{{rowData.razaoSocial}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;" *ngIf="relatorioAnalitico" (click)="onRowSelect(rowData)">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.nomeConta}}">{{rowData.nomeConta}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.numDoc}}">{{rowData.numDoc}}</span>
                          </span>
                        </td>
                        <td style="width: 9%;" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.parcela && rowData.maxParcela ? rowData.parcela + '/' + rowData.maxParcela : null}}">{{rowData.parcela}}</span>
                          </span>
                        </td>
                        <td style="width: 10%;" class="text-right">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              *ngIf="rowData.tipo === 'CR' || rowData.tipo === 'C'"
                              matTooltip="{{rowData.valor}}">{{rowData.valor | number : '1.2-2' :
                              'pt-BR'}}</span>
                            <span class="text-overflow-dynamic-ellipsis"
                              *ngIf="rowData.tipo === 'CP' || rowData.tipo === 'D'" style="color:darkred;"
                              matTooltip="{{rowData.valor}}">{{rowData.valor
                              | number : '1.2-2' : 'pt-BR'}}</span>
                          </span>
                        </td>
                        <td style="width: 6%;" class="text-center">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.statusLog}}">{{rowData.status}}</span>
                          </span>
                        </td>
                        <td style="width: 6%;" class="text-center" *ngIf="flowUses">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.aprovacaoDescr}}">{{rowData.aprovacaoDescr}}</span>
                          </span>
                        </td>
                        <td style="width: 10%;" class="text-center">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.statusRemessa}}">{{rowData.statusRemessa}}</span>
                          </span>
                        </td>
                        <td style="width: 8%;" class="text-center">
                          <span class="text-overflow-dynamic-container" (click)="onRowSelect(rowData)">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.tipoBaixa}}">{{rowData.tipoBaixa}}</span>
                          </span>
                        </td>
                        <td style="width: 10%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.historicoParcela}}">{{rowData.historicoParcela}}</span>

                            <span class="action-fade" matTooltip="Visualizar detalhes"
                              (click)="previewFinanceiro(rowData, 'mov')">
                              <i class="fas fa-search"></i>
                            </span>

                          </span>
                        </td>

                        <td style="width: 5%;" class="text-center">

                          <i matTooltip="Baixa foi contabilizada" *ngIf="rowData.contabilizado === 'SIM'"
                            class="fas fa-circle" style="color: green;"></i>
                          <i matTooltip="Baixa não foi contabilizada" *ngIf="rowData.contabilizado !== 'SIM'"
                            class="fas fa-circle" style="color: red;"></i>

                        </td>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <tr>
                        <td [attr.colspan]="relatorioAnalitico ? 11 : 8">TOTAL SELEÇÃO:</td>
                        <td class="text-right">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                              matTooltip="R$ {{ totalPeriodo | money}}">R$ {{ totalPeriodo | money}}</span>
                          </span>
                        </td>
                        <td colspan="6" class="text-right">
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="18" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </app-skeleton>


              </div>
              <div [ngStyle]="statusVencidos ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusVencidos'}">
                <!-- Table New P-TABLE -->
                <app-skeleton [config]="skeletonConfig">
                  <p-table class="table-system" #dt2 [columns]="colsVencidos" [value]="vencidos" [rows]="rows"
                    [paginator]="true" mix (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                    (onRowSelect)="rowSelect($event)" (onRowUnselect)="rowUnselect($event)"
                    (onHeaderCheckboxToggle)="selectAll($event)" [(selection)]="clearArr" [lazy]="true"
                    (onLazyLoad)="statusVencidos && getMoreTransactions($event)" [totalRecords]="totalVencidos">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="text-center tableHeaderCheckbox">
                          <span class="check-all p-0">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                          </span>
                        </th>
                        <th class="text-center" style="width: 7%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vcto'">
                            <span class="text-overflow-dynamic-ellipsis">VENCTO <p-sortIcon [field]="'dt_vcto'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'dt_vcto', 'contains')">
                        </th>
                        <th class="text-center" style="width: 7%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataBaixaPrev'">
                            <span class="text-overflow-dynamic-ellipsis">PREVISÃO <p-sortIcon [field]="'dataBaixaPrev'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'dataBaixaPrev', 'contains')">
                        </th>
                        <th style="width: 12%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                            <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'nome', 'contains')">
                        </th>
                        <th style="width: 10%;" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                            <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'operacao', 'contains')">
                        </th>
                        <th style="width: 9%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'contaBancaria'">
                            <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'contaBancaria'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'contaBancaria', 'contains')">
                        </th>
                        <th style="width: 12%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                            <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'historico', 'contains')">
                        </th>
                        <th style="width: 6%;" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nf'">
                            <span class="text-overflow-dynamic-ellipsis">DOC <p-sortIcon [field]="'nf'"></p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'nf', 'contains')">
                        </th>
                        <th class="text-center" style="width: 5%;" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                            <span class="text-overflow-dynamic-ellipsis">PARC <p-sortIcon [field]="'parcela'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'parcela', 'contains')">
                        </th>
                        <th class="text-right" style="width: 10%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                            <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'">
                              </p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'valor', 'contains')">
                        </th>
                        <th class="text-center" style="width: 7.5%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                            <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'status', 'contains')">
                        </th>
                        <th class="text-center" style="width: 7.5%;" *ngIf="flowUses">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'statusAprovacao'">
                            <span class="text-overflow-dynamic-ellipsis">
                              Status Aprovação<p-sortIcon [field]="'statusAprovacao'"></p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'statusAprovacao', 'contains')">
                        </th>
                        <th class="text-center" style="width: 7.5%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'statusRemessa'">
                            <span class="text-overflow-dynamic-ellipsis">Remessa <p-sortIcon [field]="'statusRemessa'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'statusRemessa', 'contains')">
                        </th>

                        <th class="text-center" style="width: 12%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'TipoPag'">
                            <span class="text-overflow-dynamic-ellipsis">TIPO PAGAMENTO <p-sortIcon [field]="'TipoPag'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'TipoPag', 'contains')">
                        </th>
                        <th class="text-center" style="width: 12%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'historicoParcela'">
                            <span class="text-overflow-dynamic-ellipsis">OBSERVAÇÕES <p-sortIcon
                                [field]="'historicoParcela'"></p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt2.filter($event.target.value, 'historicoParcela', 'contains')">
                        </th>
                      </tr>


                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr style="cursor: pointer">
                        <td class="text-center tableCheckbox">
                          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>
                        <td class="text-center" style="width: 7%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.dt_vcto}}">{{rowData.dt_vcto}}</span>
                          </span>
                        </td>
                        <td class="text-center" style="width: 7%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.dataBaixaPrev}}">{{rowData.dataBaixaPrev}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}"
                              (click)="onRowSelect(rowData)">{{rowData.nome}}</span>
                          </span>
                        </td>
                        <td style="width: 9%;" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.operacao}}"
                              (click)="onRowSelect(rowData)">{{rowData.operacao}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.contaBancaria}}"
                              (click)="onRowSelect(rowData)">{{rowData.contaBancaria}}</span>
                          </span>
                        </td>
                        <td style="width: 6%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.historico}}"
                              (click)="onRowSelect(rowData)">{{rowData.historico}}</span>
                          </span>
                        </td>
                        <td style="width: 5%;" class="text-center" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.nf}}">{{rowData.nf}}</span>
                          </span>
                        </td>
                        <td style="width: 7.5%;" class="text-center" *ngIf="relatorioAnalitico">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.parcela}}">{{rowData.parcela}}</span>
                          </span>
                        </td>
                        <td style="width: 10%;" class="text-right">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor |
                              number : '1.2-2' : 'pt-BR'}}</span>
                          </span>
                        </td>
                        <td style="width: 7.5%;" class="text-center">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                          </span>
                        </td>
                        <td style="width: 7.5%;" class="text-center" *ngIf="flowUses">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.aprovacaoDescr}}">{{rowData.aprovacaoDescr}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;" class="text-center">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.statusRemessa}}">{{rowData.statusRemessa}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;" class="text-center">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                              matTooltip="{{rowData.TipoPag}}">{{rowData.TipoPag}}</span>
                          </span>
                        </td>
                        <td style="width: 12%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis"
                              matTooltip="{{rowData.historicoParcela}}">{{rowData.historicoParcela}}</span>

                            <span class="action-fade" matTooltip="Vizualizar Detalhes"
                              (click)="previewFinanceiro(rowData, 'venc')">
                              <i class="fas fa-search"></i>
                            </span>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <tr>
                        <td [attr.colspan]="relatorioAnalitico ? 9 : 6">TOTAL SELEÇÃO:</td>
                        <td class="text-right">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                              matTooltip="R$ {{ totalVencido | money}}">R$ {{ totalVencido | money}}</span>
                          </span>
                        </td>
                        <td [attr.colspan]="flowUses ? 5 : 4" class="text-right">
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="20" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </app-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3" [ngStyle]="statusMovimentacao ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'fade-in': 'statusMovimentacao'}">
        <div class="col-sm-12 clearfix">
          <!--Valor Total -->
          <div class="pull-left mr-3">
            <div class="div-card-total">
              <p class="titulo-card-total">Total do Período</p>
              <p class="valor-card-total">R$ {{ totalPeriodo | money}}</p>
            </div>
          </div>
          <!--Valor Pago / Recebido -->
          <div class="pull-left  mr-3">
            <div class="div-card-total">
              <p class="titulo-card-total" *ngIf="parametro === 'cp'">Total Pago</p>
              <p class="titulo-card-total" *ngIf="parametro === 'cr'">Total Recebido</p>
              <p class="valor-card-total">R$ {{ totalPago | money}}</p>
            </div>
          </div>
          <!--Total Vencido -->
          <div class="pull-left mr-3">
            <div class="div-card-total">
              <p class="titulo-card-total">Total Vencido</p>
              <p class="valor-card-total" style="color:darkred;">R$ {{ totalVencido | money}}</p>
            </div>
          </div>
          <!--Total Vencido -->
          <div class="pull-left" *ngIf="finProv || finPed">
            <div class="div-card-total">
              <p class="titulo-card-total" style="padding-bottom: 5px;">LEGENDA</p>
              <table width="100%" style="padding-bottom: 4px;">
                <tr>
                  <td width="5%">

                  </td>
                  <td width="10%" style="background-color:#ffebcc;">

                  </td>
                  <td width="5%">

                  </td>
                  <td width="80%">
                    PEDIDO
                  </td>
                </tr>
                <tr height="3px">
                </tr>
                <tr style="padding-top: 1px;">
                  <td width="5%">

                  </td>
                  <td width="10%" style="background-color:#98f7ad;">

                  </td>
                  <td width="5%">

                  </td>
                  <td width="80%">
                    PROVISÃO
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>



      <div class="row mt-3" [ngStyle]="statusVencidos ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'fade-in': 'statusVencidos'}" *ngIf="totalTitVencido > 0">
        <div class="col-sm-12 clearfix">
          <!--Total Vencido -->
          <div class="pull-left mr-3">
            <div class="div-card-total">
              <p class="titulo-card-total">Total Vencido</p>
              <p class="valor-card-total" style="color:darkred;">R$ {{ totalTitVencido | money}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="modalEfetuarBaixa && !modalOperacaoPagto && !modalOperacao"
      [ngClass]="{'fade-in': 'modalEfetuarBaixa && !modalOperacaoPagto && !modalOperacao'}">
      <!--Título-->
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mb-2 mt-0">
          <li class="list-inline-item active"><a>Título</a></li>
        </ul>
        <div class="box-content mb-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Item</h3>
            </div>
          </div>
          <table class="table table-soft table-systemed table-striped mb-0">
            <thead>
              <tr>
                <th class="text-center" style="width: 9%;">Vencto/Baixa</th>
                <th class="text-center" style="width: 9%;">Previsão</th>
                <th class="text-center" style="width: 9%;">Emissão</th>
                <th style="width: 15%;">Nome</th>
                <th style="width: 15%;">Operação</th>
                <th style="width: 15%;">Histórico</th>
                <th class="text-center" style="width: 5%;">Doc</th>
                <th class="text-center" style="width: 5%;">Parcela</th>
                <th class="text-right" style="width: 9%;">Valor</th>
                <th class="text-center" style="width: 9%;">Status</th>
                <th class="text-center" style="width: 9%;">Tipo Pagamento</th>
                <th class="text-center" style="width: 9%;">Historico Pagamento</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" style="width: 9%;">
                  {{tDtVcto}}
                </td>
                <td class="text-center" style="width: 9%;">
                  {{tDtPrevisao}}
                </td>
                <td class="text-center" style="width: 9%;">
                  {{tDtEmissao}}
                </td>

                <td style="width: 15%;">
                  <span matTooltip="{{tNome}}" class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      style="user-select: all; cursor: text;">{{tNome}}</span>
                  </span>
                </td>
                <td style="width: 15;">
                  <span matTooltip="{{tOperacao}}" class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      style="user-select: all; cursor: text;">{{tOperacao}}</span>
                  </span>
                </td>
                <td style="width: 15%;">
                  <span matTooltip="{{tHistorico}}" class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      style="user-select: all; cursor: text;">{{tHistorico}}</span>
                  </span>
                </td>
                <td style="width: 5%;" class="text-center">
                  {{tNf}}
                </td>
                <td style="width: 5%;" class="text-center">
                  {{tParcela}}
                </td>
                <td style="width: 9%;" class="text-right">
                  {{tValor}}
                </td>
                <td style="width: 9%;" class="text-center">
                  {{tStatus}}
                </td>
                <td class="text-center" style="width: 9%;">
                  {{ttipopag}}
                </td>
                <td class="text-center" style="width: 9%;">
                  {{thistpag}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-8 col-xxl-9">
        <ul class="list-inline filter-menu mb-2">
          <li class="list-inline-item" [ngClass]="{active: statusLink1}"><a
              (click)="showTypeTabs('LINK_1'); somaValoresCompensados()">Geral</a></li>
          <li class="list-inline-item" [ngClass]="{active: statusLink2}"><a
              (click)="showTypeTabs('LINK_2')">Adiantamento</a></li>
          <!-- <li class="list-inline-item" [ngClass]="{active: statusLink3}"><a (click)="showTypeTabs('LINK_3')">Histórico</a></li> -->
        </ul>
      </div>

      <div class="col-sm-4 col-xxl-3">
        <ul class="list-inline filter-menu mb-2">
          <li class="list-inline-item active"><a>Comentários</a></li>
        </ul>
      </div>
    </div>

    <div class="row" *ngIf="modalEfetuarBaixa && !modalOperacaoPagto && !modalOperacao">

      <!--Info geral / Valores-->
      <div class="col-sm-8 col-xxl-9">
        <!-- Adiantamento -->
        <div *ngIf="statusLink2" [ngClass]="{'fade-in': 'statusLink2'}">
          <div class="box-content pt-2 mb-3">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-bars"></i> Adiantamento</h3>
              </div>
            </div>
            <div class="form-row mt-2">
              <div class="form-group col-sm-4">
                <button type="button" class="btn btn-xs btn-info pull-left" style="margin-top: -5px;"
                  matTooltip="Adicionar Adiantamento!" matTooltipPosition="left"
                  (click)="showModalFiltraAdiantamentos()"><i class="fas fa-plus"></i> Adicionar</button>
              </div>
            </div>

            <p-table class="table-system" [value]="adtosListaFinal" [rows]="10" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" [paginator]="false" [style]="{'width':'100%'}"
              (onEditComplete)="validarValorCompensado(); totalValorCompensado()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center">Data</th>
                  <th class="text-left">Código</th>
                  <th class="text-left">R. Social</th>
                  <th class="text-left">N. Doc</th>
                  <th class="text-left">Operação</th>
                  <th class="text-right">Vl. Orig.</th>
                  <th class="text-right">Vl. Disp.</th>
                  <th class="text-right">Vl. Comp.</th>
                  <th style="width:7%" class="text-center">Ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container" matTooltip="{{convertDate(rowData.dataView)}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{convertDate(rowData.dataView)}}</span>
                    </span>
                  </td>
                  <td>
                    <span class="text-overflow-dynamic-container" matTooltip="{{rowData.cod}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{rowData.cod}}</span>
                    </span>
                  </td>
                  <td>
                    <span class="text-overflow-dynamic-container" matTooltip="{{rowData.razao}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{rowData.razao}}</span>
                    </span>
                  </td>
                  <td>
                    <span class="text-overflow-dynamic-container" matTooltip="{{rowData.n_doc}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{rowData.n_doc}}</span>
                    </span>
                  </td>
                  <td>
                    <span class="text-overflow-dynamic-container" matTooltip="{{rowData.operacao}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{rowData.operacao}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container" matTooltip="{{rowData.vl_original | money}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{rowData.vl_original | money}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container" matTooltip="{{rowData.vl_disponivel | money}}">
                      <span class="text-overflow-dynamic-ellipsis"
                        style="user-select: all; cursor: text;">{{rowData.vl_disponivel | money}}</span>
                    </span>
                  </td>
                  <td pEditableColumn class="text-right">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div class="form-row m-0">
                          <div class="form-group col-sm-12 mb-0 p-0">
                            <input class="form-control form-control-sm text-right" [(ngModel)]="rowData.vl_compensado"
                              type="number" />
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="edit-line" matTooltip="{{rowData.vl_compensado | money}}">
                          <i class="fas fa-pencil-alt"></i> {{ rowData.vl_compensado | money }}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="width:7%" class="text-center">
                    <span class="delete" matTooltip="Excluir adiantamento" (click)="removerAdto(rowData)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template *ngIf="adtosListaFinal.length === 0" pTemplate="emptymessage" let-columns>
                <tr style="height:25px">
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td style="width:10%; border-right-color: transparent;"></td>
                  <td style="width:10%; border-right-color: transparent;"></td>
                  <td style="width:10%; border-right-color: transparent;"></td>
                  <td style="width:10%; border-right-color: transparent;"></td>
                  <td style="width:10%;"></td>
                  <td style="width:10%; font-size: 11px;" class="text-right"><strong>{{somaOriginal | money}}</strong>
                  </td>
                  <td style="width:10%; font-size: 11px" class="text-right"><strong>{{somaDisponivel | money}}</strong>
                  </td>
                  <td style="width:10%; font-size: 11px" class="text-right"><strong>{{totalCompensado | money}}</strong>
                  </td>
                  <td style="width:7%"></td>
                </tr>
              </ng-template>
            </p-table>



            <!-- <br/>
            <button (click)="toggleEdit()">Toggle Edit</button> -->
          </div>
        </div>

        <!-- Infomações / Valores -->
        <div *ngIf="statusLink1" [ngClass]="{'fade-in': 'statusLink1'}">
          <div class="box-content pt-2 mb-3">
            <!--Campos Gerais-->
            <div class="row">
              <div class="col-sm-6">
                <h3 class="sub-title"><i class="fas fa-bars"></i> Informações</h3>
                <div>
                  <!--Seleção-->
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Tipo de Baixa</label>
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTiposBaixa"
                        placeholder="Selecione o tipo de baixa" [(ngModel)]="tipoBaixa.id">
                      </p-dropdown>
                    </div>

                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Conta Bancária</label>
                      <p-dropdown appendTo="body" [disabled]="tipoBaixa.id === 8" class="type-p-dropdown"
                        [filter]="true" [options]="selectContas" placeholder="Selecione a conta Bancária"
                        [(ngModel)]="contaSelecionadaId">
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-4">
                      <label>TX. MOEDA</label>
                      <input class="form-control text-right" type="text" [(ngModel)]="valorTaxa" />
                    </div>
                    <div class="form-group col">
                      <label>MOEDA</label>
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesMoeda"
                        placeholder="Selecione a Moeda" [(ngModel)]="moedaSelecionadaId">
                      </p-dropdown>
                    </div>

                  </div>
                  <!--Datas-->
                  <div class="form-row">

                    <div class="form-group col-sm-4">
                      <label>Data Baixa</label>
                      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dataVencimento"
                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                        (onInput)="onInputDate($event)">
                      </p-calendar>
                    </div>
                    <div class="form-group col-sm-4" *ngIf="valorRecebidoBaixa < valorSaldoBaixar">
                      <!-- Campo Dúvida-->
                      <label>Data Novo Pagto</label>
                      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dataNovoVencimento"
                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                        (onInput)="onInputDate($event)"></p-calendar>
                    </div>
                  </div>
                  <div class="form-row">
                    <!--Campo Novo-->
                    <div class="col-sm-12 mb-0">
                      <div class="p-field-checkbox mr-3">
                        <p-checkbox class="mr-1" [(ngModel)]="rateioJurosMulta" [binary]="true" inputId="rvm">
                        </p-checkbox>
                        <label for="rvm">Rateio / Valor Juros / Multa</label>
                      </div>
                      <div class="p-field-checkbox">
                        <p-checkbox class="mr-1" [(ngModel)]="parcelamento" [binary]="true" inputId="pt"></p-checkbox>
                        <label for="pt">Parcelar Título</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Valores-->
              <div class="col-sm-6">
                <h3 class="sub-title"><i class="fas fa-bars"></i> Valores</h3>
                <div>
                  <div class="form-row">
                    <div class="form-group col-sm-6">
                      <label *ngIf="parametro === 'cr'">Valor Recebido</label>
                      <label *ngIf="parametro === 'cp'">Valor Pago</label>
                      <input class="form-control text-right" type="number" (keyup)="calcularValorBaixa()"
                        [(ngModel)]="valorRecebidoBaixa" />
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Valor Desconto</label>
                      <input class="form-control text-right" type="number" (keyup)="calcularValorBaixa()"
                        [(ngModel)]="valorDescontoBaixa" />
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Valor Multa</label>
                      <input class="form-control text-right" type="number" (keyup)="calcularValorBaixa()"
                        [(ngModel)]="valorMultaBaixa" />
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Valor Juros</label>
                      <input class="form-control text-right" type="number" (keyup)="calcularValorBaixa()"
                        [(ngModel)]="valorJurosBaixa" />
                    </div>

                    <div class="form-group col-sm-6">
                      <!--Campo Novo-->
                      <label>Valor Compensado</label>
                      <input class="form-control text-right" type="number" (keyup)="calcularValorBaixa()"
                        [(ngModel)]="valorCompensadoBaixa" [disabled]="true" />
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Valor Taxa Adm</label>
                      <input class="form-control text-right" type="number" (keyup)="calcularValorBaixa()"
                        [(ngModel)]="valorTaxaAdm" />
                    </div>
                    <div class="form-group col-sm-6">

                    </div>
                    <div class="form-group col-sm-6">
                      <label>Total da Baixa</label>
                      <input class="form-control text-right" type="text" [disabled]="true" [(ngModel)]="valorTotalBaixa"
                        number />
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!--Rateio Detalhes da Baixa-->
            <div *ngIf="rateioJurosMulta" [ngClass]="{'fade-in': 'rateioJurosMulta'}">
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-bars"></i> Rateio</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p-table class="table-system" [value]="movimentacaoRateios">
                    <!--pedidoItem.pedidoItensRateio-->
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width: 35%;">CENTRO CUSTO </th>
                        <th style="width: 35%;">PROJETO</th>
                        <th style="width: 20%;" class="text-right">PORCENTAGEM</th>
                        <th style="width: 10%;" class="text-center">AÇÃO</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr>
                        <td style="width: 35%;">
                          <span class="overflow-ellipse"
                            matTooltip='{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}'>{{
                            rowData.cadCcus.codCcus }}
                            - {{ rowData.cadCcus.descrCcus }} </span>
                        </td>
                        <td style="width: 35%;" *ngIf="rowData.cadProj">
                          <span class="overflow-ellipse"
                            matTooltip='{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}'>{{
                            rowData.cadProj.codProj }}
                            - {{ rowData.cadProj.descrProj }}</span>
                        </td>
                        <td style="width: 35%;" *ngIf="!rowData.cadProj">
                        </td>
                        <td style="width: 20%;" class="text-right">
                          {{ rowData.porcentagem | money }}%
                        </td>
                        <td style="width: 10%;" class="text-center">
                          <span class="edit" matTooltip="Editar centro de custo" (click)="editarCentroCusto()">
                            <i class="fas fa-edit"></i>
                          </span>
                          <span class="delete" matTooltip="Excluir centro de custo"
                            (click)="removerRateioCentroCusto(rowData)"
                            (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                            <i class="fas fa-trash-alt"></i>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                      <tr style="height:25px">
                        <td [attr.colspan]="4" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Sem rateio!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-2">
                  <button type="button" class="btn btn-sm btn-info" (click)="controlCentroCusto()"><i
                      class="fas fa-plus"></i> Adicionar Rateio</button>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mt-3 mb-0" *ngIf="modalEfetuarBaixa">
                <button type="button" class="btn btn-sm btn-secondary" (click)="modalEfetuarBaixa = false"><i
                    class="fas fa-undo"></i> Cancelar</button>

                <button type="button" class="btn btn-sm btn-danger"
                  *ngIf="editarBaixa && !criarBaixaFinanceira && !statusLink2 && !statusLink3" [disabled]="false"
                  (click)="excluirBaixa(0)"><i class="fas fa-times"></i> Excluir</button>

                <!--<button type="button" class="btn btn-sm btn-primary"
                  *ngIf="editarBaixa && !criarBaixaFinanceira && !statusLink2 && !statusLink3" [disabled]="false"
                  (click)="alterarBaixa()"><i class="fas fa-check"></i> Alterar</button>-->

                <button type="button" class="btn btn-sm btn-primary"
                  *ngIf="criarBaixaFinanceira && !statusLink2 && !statusLink3" [disabled]="((!contaSelecionadaId && tipoBaixa.id != 8) || !dataVencimento || valorTotalBaixa < 0 || !tipoBaixa)
                || (valorRecebidoBaixa < valorSaldoBaixar && !dataNovoVencimento)" (click)="baixar()"><i
                    class="fas fa-download"></i>
                  Baixar</button>
                <!--<button *ngIf="editarBaixa" type="button" pButton label="Salvar" (click)="alterarBaixa()"></button>-->


              </div>


            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <ul class="list-inline filter-menu mb-2">
              <li class="list-inline-item active"><a>Histórico de Baixa</a></li>
            </ul>
          </div>
        </div>
        <!-- Histórico Baixa -->
        <div class="box-content pt-2" *ngIf="histBaixa" [ngClass]="{'fade-in': 'LINK_BAIXA'}">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Baixa</h3>
              <div class="alert alert-xs alert-primary d-inline" role="alert" *ngIf="baixas.length===0">
                <i class="fas fa-info-circle"></i> Nenhuma baixa realizada!
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="height-activity">
                    <ng-container>
                      <div *ngFor="let b of baixas; index as i">
                        <div *ngIf="i < exibicoesBaixas">
                          <div class="spaced-activity" *ngIf="b.empresaConta && !this.desabilitarTrazerBaixa">
                            <div class="by-date">
                              <span class="user"><i class="far fa-user"></i> {{b.usuario}}</span><span
                                class="date-time"><i class="far fa-calendar-alt"></i> DT BAIXA: {{b.data_baixa}} DT LOG:
                                {{b.dataLog}}</span>
                              <div class="actions">
                                <span class="edit" matTooltip="Exibir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-eye" (click)="exibirBaixa(b)"></i></span>
                                <span class="delete" matTooltip="Excluir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-trash-alt" (click)="excluirBaixa(b.nfbId)"></i></span>
                              </div>
                            </div>
                            <div class='box-activity' style="min-height: 74px;" (click)="exibirBaixa(b)"
                              matTooltip="Clique para exibir Baixa!">
                              <p class="description"><strong>Valor da Baixa:</strong>
                                {{b.valor + b.multa + b.juros-b.desconto | money}}</p>
                              <p class="description"><strong>Tipo de Baixa:</strong> {{b.tipoBaixa.descricao}}</p>
                              <p class="description"><strong>Conta:</strong> {{b.empresaConta.nome}}</p>
                              <div *ngIf="b.adtosCompensados !== undefined && b.adtosCompensados.length > 0">
                                <!-- <p class="description"><strong>Valor da Conta:</strong> {{b.valorDaConta| money}}</p> -->
                                <p class="description"><strong>Valor Compensado:</strong> {{b.totalCompensado| money}}
                                </p>
                                <div *ngFor="let adto of b.adtosCompensados">
                                  <p class="description">Compensado {{adto.valorCompensado| money}} do Título com ID:
                                    {{adto.nfeFinId}}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="spaced-activity" *ngIf="b.empresaConta && this.desabilitarTrazerBaixa">
                            <div class="by-date">
                              <span class="user"><i class="far fa-user"></i> {{b.usuario}}</span><span
                                class="date-time"><i class="far fa-calendar-alt"></i> {{b.data_baixa}}</span>
                              <div class="actions">
                                <span class="delete" matTooltip="Excluir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-trash-alt" (click)="excluirBaixa(b.nfbId)"></i></span>
                              </div>
                            </div>
                            <div class='box-activity' style="min-height: 74px;">
                              <p class="description"><strong>Valor da Baixa:</strong>
                                {{b.valor + b.multa + b.juros-b.desconto| money}}</p>
                              <p class="description"><strong>Tipo de Baixa:</strong> {{b.tipoBaixa.descricao}}</p>
                              <p class="description"><strong>Conta:</strong> {{b.empresaConta.nome}}</p>
                              <div *ngIf="b.adtosCompensados !== undefined && b.adtosCompensados.length > 0">
                                <!-- <p class="description"><strong>Valor da Conta:</strong> {{b.valorDaConta| money}}</p> -->
                                <p class="description"><strong>Valor Compensado:</strong> {{b.totalCompensado| money}}
                                </p>
                                <div *ngFor="let adto of b.adtosCompensados">
                                  <p class="description">Compensado {{adto.valorCompensado| money}} do Título com ID:
                                    {{adto.nfeFinId}}</p>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="spaced-activity" *ngIf="!b.empresaConta && !this.desabilitarTrazerBaixa">
                            <div class="by-date">
                              <span class="user"><i class="far fa-user"></i> {{b.usuario}}</span><span
                                class="date-time"><i class="far fa-calendar-alt"></i> DT BAIXA: {{b.data_baixa}} DT LOG:
                                {{b.dataLog}}</span>
                              <div class="actions">
                                <span class="edit" matTooltip="Exibir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-eye" (click)="exibirBaixa(b)"></i></span>
                                <span class="delete" matTooltip="Excluir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-trash-alt" (click)="excluirBaixa(b.nfbId)"></i></span>
                              </div>
                            </div>
                            <div class='box-activity' style="min-height: 74px;">
                              <p class="description"><strong>Valor da Baixa:</strong>
                                {{b.valor + b.multa + b.juros-b.desconto| money}}</p>
                              <p class="description"><strong>Tipo de Baixa:</strong> {{b.tipoBaixa.descricao}}</p>
                              <div *ngIf="b.adtosCompensados !== undefined && b.adtosCompensados.length > 0">
                                <!-- <p class="description"><strong>Valor da Conta:</strong> {{b.valorDaConta| money}}</p> -->
                                <p class="description"><strong>Valor Compensado:</strong> {{b.totalCompensado| money}}
                                </p>
                                <div *ngFor="let adto of b.adtosCompensados">
                                  <p class="description">Compensado {{adto.valorCompensado| money}} do Título com ID:
                                    {{adto.nfeFinId}}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="spaced-activity" *ngIf="!b.empresaConta && this.desabilitarTrazerBaixa">
                            <div class="by-date">
                              <span class="user"><i class="far fa-user"></i> {{b.usuario}}</span><span
                                class="date-time"><i class="far fa-calendar-alt"></i> DT BAIXA: {{b.data_baixa}} DT LOG:
                                {{b.dataLog}}</span>
                              <div class="actions">
                                <span class="edit" matTooltip="Exibir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-eye" (click)="exibirBaixa(b)"></i></span>
                                <span class="delete" matTooltip="Excluir Baixa!" matTooltipPosition="above"><i
                                    class="far fa-trash-alt" (click)="excluirBaixa(b.nfbId)"></i></span>
                              </div>
                            </div>
                            <div class='box-activity' style="min-height: 74px;">
                              <p class="description"><strong>Valor da Baixa:</strong>
                                {{b.valor + b.multa + b.juros-b.desconto| money}}</p>
                              <p class="description"><strong>Tipo de Baixa:</strong> {{b.tipoBaixa.descricao}}</p>
                              <div *ngIf="b.adtosCompensados !== undefined && b.adtosCompensados.length > 0">
                                <!-- <p class="description"><strong>Valor da Conta:</strong> {{b.valorDaConta| money}}</p> -->
                                <p class="description"><strong>Valor Compensado:</strong> {{b.totalCompensado| money}}
                                </p>
                                <div *ngFor="let adto of b.adtosCompensados">
                                  <p class="description">Compensado {{adto.valorCompensado| money}} do Título com ID:
                                    {{adto.nfeFinId}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="item-footer">
                        <small *ngIf="exibicoesBaixas !== 3" class="show-more" (click)="mostrarMenosBaixas()">
                          <span class="material-icons">remove_circle_outline</span>
                          Exibir menos
                        </small>
                        <small *ngIf="!(exibicoesBaixas >= baixas.length)" class="show-more"
                          (click)="mostrarMaisBaixas()">
                          <span class="material-icons">add_circle_outline</span>
                          Exibir mais
                        </small>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Histórico Cobrança-->
      <div class="col-sm-4 col-xxl-3">
        <div class="box-content pt-2 pb-2" style="height: 100%">
          <div class="row">
            <div class="col-sm-12" *ngIf="histCobranca" [ngClass]="{'fade-in': 'LINK_COBRANCA'}">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Comentário <button type="button"
                  class="btn btn-xs btn-info pull-right" style="margin-top: -3px;" matTooltip="Adicionar Histórico!"
                  matTooltipPosition="left" (click)="criarHistorico()"><i class="fas fa-plus"></i> Adicionar</button>
              </h3>
              <div class="alert alert-xs alert-primary d-inline" role="alert"
                *ngIf="historicos.length===0 && !criarHistoricoCobranca && !editarHistorico">
                <i class="fas fa-info-circle"></i> Nenhum comentário!
              </div>

              <div *ngIf="criarHistoricoCobranca || editarHistorico">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <textarea [froalaEditor]="optionsDescFroola" [(ngModel)]="historico" id="textAreaTwoSize"
                      autofocus></textarea>
                    <button type="button" class="btn btn-xs btn-primary" *ngIf="criarHistoricoCobranca"
                      (click)="gravarHistorico()"><i class="fas fa-save"></i> Gravar</button>
                    <button type="button" class="btn btn-xs btn-primary" *ngIf="editarHistorico"
                      (click)="alterarHistorico()"><i class="fas fa-check"></i> Salvar</button>
                    <button type="button" class="btn btn-xs btn-link btn-close-times" (click)="criarBaixa()"><i
                        class="fas fa-times"></i></button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <ng-container>
                    <div class="height-activity"
                      [ngClass]="criarHistoricoCobranca || editarHistorico ? 'new-charge' : ''">
                      <div *ngFor="let h of historicos">
                        <div class="spaced-activity">
                          <div class="by-date">
                            <span class="user"><i class="far fa-user"></i> {{h.usuario}}</span><span
                              class="date-time"><i class="far fa-calendar-alt"></i> {{(h.data)}}</span>
                            <!-- <span class="user"><i class="far fa-user"></i> {{h.usuario}}</span> -->
                            <div class="actions">
                              <span class="edit" matTooltip="Editar Histórico!" matTooltipPosition="above"><i
                                  class="fas fa-pencil-alt" (click)="exibirHistorico(h)"></i></span>
                              <span class="delete" matTooltip="Excluir Histórico!" matTooltipPosition="above"><i
                                  class="far fa-trash-alt" (click)="excluirHistorico(h.nfhId)"></i></span>
                            </div>
                          </div>
                          <div class='box-activity' style="min-height: 74px;">
                            <p class="description" [innerHtml]="h.descricao | safeHtml"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Card Novo Pagamento-->
    <div class="row" *ngIf="modalOperacaoPagto" [ngClass]="{'fade-in': 'modalOperacaoPagto'}">
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mb-2 mt-0">
          <li class="list-inline-item active"><a>Pagamento</a></li>
        </ul>
      </div>
      <div class="col-sm-8 col-xxl-6">
        <div class="box-content mb-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title" *ngIf="!tituloNewEdit"><i class="fas fa-bars"></i> Novo pagamento</h3>
              <h3 class="sub-title" *ngIf="tituloNewEdit"><i class="fas fa-bars"></i> Editar pagamento</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Operação <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                placeholder="Selecione a Operação" [(ngModel)]="operacaoRegra.id"
                (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)" [disabled]="isFromPedido">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Data <span class="obrigatorio">*</span></label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="defaultDate" [(ngModel)]="dtOperacao" dateFormat="dd/mm/yy"
                (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-2">
              <mat-slide-toggle [(ngModel)]="isEstorno">
                Estorno
              </mat-slide-toggle>
            </div>
            <div class="form-group col-sm-2" *ngIf="finProv">
              <mat-slide-toggle [(ngModel)]="provisao">Provisão</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12 d-flex flex-column">
              <label>Fornecedor<span class="obrigatorio" *ngIf="participanteObrigatorio">*</span></label>
              <button matTooltip="Alterar Participante" class="btn btn-info btn-participant" type="button"
                (click)="loadParticipantes(2, isEstorno, false)">
                {{ participanteCodigo ? participanteCodigo + '-' + participanteFantasia : 'Não informado' }}
                <i class="fas fa-pen"></i>
              </button>
            </div>
          </div>

          <div class="form-row" *ngIf="permiteMultiMoeda">
            <div class="form-group col-sm-12">
              <label>Conta <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="selectContas"
                placeholder="Selecione a Conta" [(ngModel)]="contaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'select')"></p-dropdown>
            </div>
          </div>

          <div class="form-row" *ngIf="permiteMultiMoeda">
            <div class="form-group col-sm-4">
              <label>MOEDA</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesMoeda"
                placeholder="Selecione a moeda" [(ngModel)]="moedaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'moeda'); convertValorMoeda()"
                [disabled]="!permiteAlterarMoeda">
              </p-dropdown>
            </div>

            <div class="form-group col-sm-4">
              <label>TX. MOEDA</label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorTaxa"
                (onChange)="convertValorMoeda()" (keyup)="convertValorMoeda()" [disabled]="moedaSelecionadaId < 2" />
            </div>


            <div class="form-group col-sm-2">
              <label>Valor Moeda<span class="obrigatorio">*</span></label>
              <input class="form-control text-right" (onChange)="convertValorMoeda()" (keyup)="convertValorMoeda()"
                type="text" [(ngModel)]="valorOperacao" money />
            </div>

            <div class="form-group col-sm-2">
              <label>Valor R$<span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [disabled]="true" [(ngModel)]="valorReais" money />
            </div>

          </div>

          <div class="form-row" *ngIf="!permiteMultiMoeda">
            <div class="form-group col-sm-10">
              <label>Conta <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="selectContas"
                placeholder="Selecione a Conta" [(ngModel)]="contaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'select')"></p-dropdown>
            </div>

            <div class="form-group col-sm-2">
              <label>Valor <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorOperacao" money />
            </div>

          </div>

          <!-- MODIFICAÇÕES MODAL CNAB @octavio -->

          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Tipo de pagamento</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tiposLista" placeholder="Selecione o tipo"
                [(ngModel)]="tipoSelecionadoId" (onChange)="onChangeTipoPagamento($event)"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>N Documento</label>
              <input class="form-control text-right" type="number" [(ngModel)]="nDocumento" />
            </div>
          </div>
          <!-- Para boletos (segmento J do Itaú) -->
          <div class="form-row" *ngIf="cnabFormaSelecionadaId === 1">
            <div class="form-group col-sm-12">
              <label>Código de Barras <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="codBarras" />
            </div>
          </div>
          <!-- fim segmento J -->
          <!-- para impostos do segmento O (com código de barras ou impostos estaduais) -->
          <div class="form-row" *ngIf="[6].includes(cnabFormaSelecionadaId)">
            <div class="form-group col-sm-10">
              <label>Código de Barras <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="codBarras" />
            </div>
            <div class="form-group col-sm-2">
              <label>Apuração <span class="obrigatorio">*</span></label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="defaultDate" [(ngModel)]="dtApuracao" dateFormat="dd/mm/yy"
                (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
          </div>
          <!-- fim segmento O -->
          <!-- para impostos do segmento N + FGTS -->
          <div *ngIf="[2, 3, 4].includes(cnabFormaSelecionadaId)">
            <div class="form-row">
              <!-- para DARF -->
              <div class="form-group col-sm-10" *ngIf="cnabFormaSelecionadaId === 2">
                <label>Cód. Receita <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="codReceitaLista"
                  placeholder="Selecione o tipo" [(ngModel)]="codReceita"></p-dropdown>
              </div>
              <!-- para GPS -->
              <div class="form-group col-sm-10" *ngIf="cnabFormaSelecionadaId === 4">
                <label>CÓD. PAGAMENTO <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [(ngModel)]="codPagamentoGps" />
              </div>
              <!-- para FGTS -->
              <div class="form-group col-sm-10" *ngIf="cnabFormaSelecionadaId === 3">
                <label>CÓD. RECOLHIMENTO <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [(ngModel)]="codRecolhimento" />
              </div>
              <div class="form-group col-sm-2">
                <label>Apuração <span class="obrigatorio">*</span></label>
                <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                  [defaultDate]="defaultDate" [(ngModel)]="dtApuracao" dateFormat="dd/mm/yy"
                  (onInput)="onInputDate($event)">
                </p-calendar>
              </div>
            </div>
            <!-- para FGTS (segmento N) -->
            <div *ngIf="cnabFormaSelecionadaId === 3">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Código de Barras <span class="obrigatorio">*</span></label>
                  <input class="form-control text-right" type="text" [(ngModel)]="codBarras" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-9">
                  <label>ID RECOLHIMENTO <span class="obrigatorio">*</span></label>
                  <input class="form-control text-right" type="text" [(ngModel)]="idRecolhimento" />
                </div>
                <div class="form-group col-sm-3">
                  <label>DÍGITO ID RECOLHIMENTO<span class="obrigatorio">*</span></label>
                  <input class="form-control text-right" type="text" [(ngModel)]="digitoIdRecolhimento" />
                </div>
              </div>
            </div>
          </div>
          <!-- fim segmento  -->
          <!-- Para transferências CC ou TED (segmento A no Itaú) -->
          <div class="form-row" *ngIf="[5].includes(cnabFormaSelecionadaId)">
            <div class="form-group col-sm-12">
              <label>Conta do Fornecedor <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="contasFornecedorLista"
                placeholder="Selecione o tipo" [(ngModel)]="participanteContasId"></p-dropdown>
            </div>
          </div>
          <!-- fim segmento A -->
          <!-- fim @octavio -->
          <div class="form-row" *ngIf="[7].includes(cnabFormaSelecionadaId)">
            <div class="form-group col-sm-12">
              <label>Chaves Pix do Fornecedor <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="chavesPixParticipante"
                placeholder="Selecione a chave" [(ngModel)]="chavePixSelecionada"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Histórico <span class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [(ngModel)]="historicoOperacao" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group {{flowUses ? 'col-sm-9' : 'col-sm-12'}} ">
              <label>Link</label>
              <input class="form-control" type="text" [(ngModel)]="linkFinanceiro" />
            </div>
            <div class="form-group col-sm-3">
              <label>Código de Controle</label>
              <input class="form-control" type="text" [disabled]="refIsAlreadyDefined" [(ngModel)]="refFinanceiro" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Observações</label>
              <input class="form-control" type="text" [(ngModel)]="observacao" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Recorrência</h3>
            </div>
          </div>
          <div class="p-field-checkbox my-3">
            <p-checkbox class="mr-1" [(ngModel)]="recorrencia" [binary]="true" inputId="ir"></p-checkbox>
            <label for="ir">Informar recorrência</label>
          </div>
          <div class="form-row" *ngIf="recorrencia === true">
            <div class="form-group col-sm-2">
              <label>A cada (meses)</label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="meses" [(ngModel)]="ocorrenciaMes">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Data final</label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [(ngModel)]="dtFimOccorencia" dateFormat="dd/mm/yy" (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
              <div class="p-field-checkbox">
                <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="adu"></p-checkbox>
                <label for="adu">Antecipa dia não útil</label>
              </div>
            </div>
            <div class="col-sm-5 d-flex align-items-center">
              <div class="p-field-checkbox" *ngIf="editarPagtoOuReceb">
                <p-checkbox class="mr-1" [(ngModel)]="alterarRecorrencia" [binary]="true" inputId="ar"></p-checkbox>
                <label for="ar">Alterar somente esta recorrência</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Rateio</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" [value]="movimentacaoRateios">
                <!--pedidoItem.pedidoItensRateio-->
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35%;">CENTRO CUSTO </th>
                    <th style="width: 35%;">PROJETO</th>
                    <th style="width: 20%;" class="text-right">PORCENTAGEM</th>
                    <th style="width: 10%;" class="text-center">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 35%;">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}'>{{
                        rowData.cadCcus.codCcus }}
                        - {{ rowData.cadCcus.descrCcus }} </span>
                    </td>
                    <td style="width: 35%;" *ngIf="rowData.cadProj">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}'>{{
                        rowData.cadProj.codProj }}
                        - {{ rowData.cadProj.descrProj }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="!rowData.cadProj">
                    </td>
                    <td style="width: 20%;" class="text-right">
                      {{ rowData.porcentagem | money }}%
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="edit" matTooltip="Editar centro de custo" (click)="editarCentroCusto()">
                        <span class="material-icons"
                          style="color: #cccccc; font-size: 18px; cursor: pointer;">edit</span>
                      </span>
                      <span class="delete" matTooltip="Excluir centro de custo"
                        (click)="removerRateioCentroCusto(rowData)"
                        (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                        <span class="material-icons"
                          style="color: #cccccc; font-size: 18px; cursor: pointer;">delete</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem rateio!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-2">
              <button type="button" class="btn btn-sm btn-info" (click)="controlCentroCusto()"><i
                  class="fas fa-plus"></i> Adicionar Rateio</button>
            </div>
          </div>
          <div class="form-row">
            <div class="col-sm-12 mt-4 d-flex align-items-center">
              <button type="button" class="btn btn-secondary" (click)='changePeriod(true, false);'><i
                  class="fas fa-undo"></i>
                Cancelar
              </button>
              <button type="button" class="btn btn-primary"
                [disabled]="!operacaoRegra.id || !dtOperacao || !historicoOperacao || !valorOperacao"
                (click)="salvarPagamento(alterarRecorrencia, baixarTitulo)"><i class="fas fa-check"></i> Salvar</button>
              <div class="p-field-checkbox ml-3">
                <p-checkbox class="mr-1" [(ngModel)]="baixarTitulo" [binary]="true" inputId="bt"></p-checkbox>
                <label for="bt" style="margin: 0">BAIXAR TITULO</label>
              </div>

              <div class="p-field-checkbox ml-3" *ngIf="showSolicitarAprov">
                <p-checkbox class="mr-1" [(ngModel)]="requestApproval" [binary]="true" inputId="sa"></p-checkbox>
                <label for="sa" style="margin: 0">Solicitar aprovação</label>
              </div>
            </div>
          </div>
          <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
            <div class="ui-g-12">

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Card Novo Recebimento-->
    <div class="row" *ngIf="modalOperacao" [ngClass]="{'fade-in': 'modalOperacao'}">
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mb-2 mt-0">
          <li class="list-inline-item active"><a>Recebimento</a></li>
        </ul>
      </div>
      <div class="col-sm-8 col-xxl-6">
        <div class="box-content mb-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title" *ngIf="!tituloNewEdit"><i class="fas fa-bars"></i> Novo recebimento</h3>
              <h3 class="sub-title" *ngIf="tituloNewEdit"><i class="fas fa-bars"></i> Editar recebimento</h3>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Operação <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                placeholder="Selecione a Operação" [(ngModel)]="operacaoRegra.id"
                (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)" [disabled]="isFromPedido">
              </p-dropdown>
              <!-- (onChange)="verificarDetalheOperacao()"-->
            </div>
            <div class="form-group col-sm-2">
              <label>Data <span class="obrigatorio">*</span></label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="defaultDate" [(ngModel)]="dtOperacao" dateFormat="dd/mm/yy"
                (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-2">
              <mat-slide-toggle [(ngModel)]="isEstorno">
                Estorno
              </mat-slide-toggle>
            </div>
            <div class="form-group col-sm-2" *ngIf="finProv">
              <mat-slide-toggle [(ngModel)]="provisao">Provisão</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12 d-flex flex-column">
              <label>Cliente</label>

              <button matTooltip="Alterar Participante" class="btn btn-info btn-participant" type="button"
                (click)="loadParticipantes(1, isEstorno, false)">
                {{ participanteCodigo ? participanteCodigo + '-' + participanteFantasia : 'Não informado' }}
                <i class="fas fa-pen"></i>
              </button>
            </div>
          </div>
          <div class="form-row" *ngIf="permiteMultiMoeda">
            <div class="form-group col-sm-12">
              <label>Conta <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="selectContas"
                placeholder="Selecione a Conta" [(ngModel)]="contaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'select')"></p-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="permiteMultiMoeda">
            <div class="form-group col-sm-4">
              <label>MOEDA</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesMoeda"
                placeholder="Selecione a moeda" [(ngModel)]="moedaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'moeda'); convertValorMoeda()"
                [disabled]="!permiteAlterarMoeda">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>TX. MOEDA</label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorTaxa"
                (onChange)="convertValorMoeda()" (keyup)="convertValorMoeda()" [disabled]="moedaSelecionadaId < 2" />
            </div>
            <div class="form-group col-sm-2">
              <label>Valor Moeda<span class="obrigatorio">*</span></label>
              <input class="form-control text-right" (onChange)="convertValorMoeda()" (keyup)="convertValorMoeda()"
                type="text" [(ngModel)]="valorOperacao" money />
            </div>
            <div class="form-group col-sm-2">
              <label>Valor R$<span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [disabled]="true" [(ngModel)]="valorReais" money />
            </div>
          </div>
          <div class="form-row" *ngIf="!permiteMultiMoeda">
            <div class="form-group col-sm-10">
              <label>Conta <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="selectContas"
                placeholder="Selecione a Conta" [(ngModel)]="contaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'select')"></p-dropdown>
            </div>

            <div class="form-group col-sm-2">
              <label>Valor <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorOperacao" money />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Tipo de Recebimento</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposLista"
                placeholder="Selecione o tipo de recebimento" [(ngModel)]="tipoSelecionadoId"
                (onChange)="extrairTipoBaixa()">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>N Documento</label>
              <input class="form-control text-right" type="number" [(ngModel)]="nDocumento" />
            </div>
          </div>
          <div class="form-row" *ngIf="usaAsaas && (tipoBaixaId === 1 || tipoBaixaId === 17)">
            <div class="form-group col-sm-12">
              <p-checkbox class="mr-1" [(ngModel)]="usarMultaJuros" [binary]="true" inputId="bt"></p-checkbox>
              <label for="bt" style="margin: 0">Multa/juros/desconto personalizados</label>
            </div>
          </div>
          <div class="form-row" *ngIf="usarMultaJuros">
            <div class="form-group col-sm-3">
              <label>Tipo multa</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tipoMultaJuros"
                placeholder="Selecione o tipo de multa" [(ngModel)]="tipoMulta">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>Valor multa {{tipoMulta === 'PERCENTAGE' ? '(%)' : '(R$)'}}</label>
              <input class="form-control" type="number" [(ngModel)]="valorMulta" />
            </div>
            <div class="form-group col-sm-3">
              <label>Tipo juros</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tipoMultaJuros"
                placeholder="Selecione o tipo de juros" [(ngModel)]="tipoJuros">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>Valor juros {{tipoJuros === 'PERCENTAGE' ? '(%)' : '(R$)'}}</label>
              <input class="form-control" type="number" [(ngModel)]="valorJuros" />
            </div>
          </div>
          <div class="form-row" *ngIf="usarMultaJuros">
            <div class="form-group col-sm-4">
              <label>Tipo desconto</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tipoMultaJuros"
                placeholder="Selecione o tipo de desconto" [(ngModel)]="tipoDesconto">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>Valor desconto {{tipoDesconto === 'PERCENTAGE' ? '(%)' : '(R$)'}}</label>
              <input class="form-control" type="number" [(ngModel)]="valorDesconto" />
            </div>
            <div class="form-group col-sm-4">
              <label>Dias de desconto (antes do vencimento)</label>
              <input class="form-control" type="number" [(ngModel)]="diasDesconto" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-9">
              <label>Histórico <span class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [(ngModel)]="historicoOperacao" />
            </div>
            <div class="form-group col-sm-3">
              <label>Código de Controle</label>
              <input class="form-control" type="text" [disabled]="refIsAlreadyDefined" [(ngModel)]="refFinanceiro" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Observações</label>
              <input class="form-control" type="text" [(ngModel)]="observacao" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-2">
              <label>DT EMISSÃO </label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [(ngModel)]="dataEmissao" dateFormat="dd/mm/yy" (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
            <div class="form-group col-sm-2">
              <label>DT COMPETÊNCIA </label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [(ngModel)]="dataCompetencia" dateFormat="dd/mm/yy" (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Recorrência</h3>
            </div>
          </div>
          <div class="p-field-checkbox my-3">
            <p-checkbox class="mr-1" [(ngModel)]="recorrencia" [binary]="true" inputId="ir"></p-checkbox>
            <label for="ir">Informar recorrência</label>
          </div>
          <div class="form-row" *ngIf="recorrencia === true">
            <div class="form-group col-sm-2">
              <label>A cada (meses)</label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="meses" [(ngModel)]="ocorrenciaMes">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Data final</label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [(ngModel)]="dtFimOccorencia" dateFormat="dd/mm/yy" (onInput)="onInputDate($event)">
              </p-calendar>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
              <div class="p-field-checkbox">
                <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="adu"></p-checkbox>
                <label for="adu">Antecipa dia não útil</label>
              </div>
            </div>
            <div class="col-sm-5 d-flex align-items-center">
              <div class="p-field-checkbox" *ngIf="editarPagtoOuReceb">
                <p-checkbox class="mr-1" [(ngModel)]="alterarRecorrencia" [binary]="true" inputId="ar"></p-checkbox>
                <label for="ar">Alterar somente esta recorrência</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Rateio</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" [value]="movimentacaoRateios">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35%;">CENTRO CUSTO</th>
                    <th style="width: 35%;">PROJETO</th>
                    <th style="width: 20%;" class="text-right">PORCENTAGEM</th>
                    <th style="width: 10%;" class="text-center">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 35%;">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}'>{{
                        rowData.cadCcus.codCcus }}
                        - {{ rowData.cadCcus.descrCcus }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="rowData.cadProj">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}'>{{
                        rowData.cadProj.codProj }}
                        - {{ rowData.cadProj.descrProj }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="!rowData.cadProj">
                    </td>
                    <td style="width: 20%;" class="text-right">
                      {{ rowData.porcentagem | money }}%
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="edit" matTooltip="Editar centro de custo" (click)="editarCentroCusto()">
                        <i class="fas fa-edit"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir centro de custo"
                        (click)="removerRateioCentroCusto(rowData)"
                        (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem rateio!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-2">
              <button type="button" class="btn btn-sm btn-info" (click)="controlCentroCusto()"><i
                  class="fas fa-plus"></i>
                Adicionar Rateio</button>
            </div>
          </div>

          <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
            <div class="ui-g-12">

            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12 mt-4 d-flex align-items-center">

              <button type="button" class="btn btn-secondary" (click)='changePeriod(true, false);'><i
                  class="fas fa-undo"></i>
                Cancelar
              </button>
              <button type="button" class="btn btn-primary"
                [disabled]="!operacaoRegra.id || !dtOperacao || !historicoOperacao || !valorOperacao"
                (click)="salvarRecebimento()"><i class="fas fa-check"></i>
                Salvar
              </button>
              <div class="p-field-checkbox ml-3">
                <p-checkbox class="mr-1" [(ngModel)]="baixarTitulo" [binary]="true" inputId="bt"></p-checkbox>
                <label for="bt" style="margin: 0">BAIXAR TITULO</label>
              </div>

              <div class="p-field-checkbox ml-3" *ngIf="showSolicitarAprov">
                <p-checkbox class="mr-1" [(ngModel)]="requestApproval" [binary]="true" inputId="sa"></p-checkbox>
                <label for="sa" style="margin: 0">Solicitar aprovação</label>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Modal Alterar Período-->
<p-dialog header="Alterar Período" [(visible)]="modalFiltro" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onInput)="onInputDate($event)">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" (onInput)="onInputDate($event)">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione a conta</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="selectContas" placeholder="Selecione a Conta"
        [(ngModel)]="contaSelecionadaFiltroPreSelect"></p-dropdown>
    </div>
  </div>

  <div class="form-row" *ngIf="parametro == 'cr'">
    <div class="d-flex align-items-end">
      <div class="form-group">
        <label>Cliente</label>
        <p>
          {{ participanteCodigo ? participanteCodigo +'-'+ participanteFantasia :
          'Não informado'}}
        </p>
      </div>
      <span class="form-group ml-2" matTooltip="Alterar Participante" (click)="loadParticipantes(1, isEstorno, false)">
        <span class="material-icons" style="color: #666; font-size: 18px;cursor: pointer;">edit</span>
      </span>
    </div>
  </div>

  <div class="form-row" *ngIf="parametro == 'cp'">
    <div class="d-flex align-items-end">
      <div class="form-group">
        <label>Fornecedor<span class="obrigatorio" *ngIf="participanteObrigatorio">*</span></label>
        <p>
          {{ participanteCodigo ? participanteCodigo +'-'+ participanteFantasia :
          'Não informado'}}
        </p>
      </div>
      <span class="form-group ml-2" matTooltip="Alterar Participante" (click)="loadParticipantes(2, isEstorno, false)">
        <span class="material-icons" style="color: #666; font-size: 18px;cursor: pointer;">edit</span>
      </span>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFiltro=false'><i class="fas fa-undo"></i>
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="atualizarVoltar()"><i class="fas fa-pencil-alt"></i>
          Alterar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIÇÃO EM LOTE -->
<p-dialog header="Edição de títulos em lote" [(visible)]="modalEdicaoLote" [style]="{width: '600px'}" [modal]="true"
  class="modal-initial" appendTo="body" [closable]="true">
  <div class="form-row mb-4 d-flex align-items-center justify-content-end">
    <button class="btnCustom-green" (click)="adicionarLinhaEdicaoLote()">
      <span class="material-icons">add</span>
      <div>Adicionar</div>
    </button>
  </div>
  <div class="form-row">
    <p-table class="table-system" [value]="linhasEdicao">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-left" style="width: 45%">Editar</th>
          <th class="text-left" style="width: 45%">Valor</th>
          <th class="text-center" style="width: 10%">Ação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <span class="overflow-ellipse">
              <p-dropdown class="type-p-dropdown" appendTo="body" [options]="itensEditaveis" [(ngModel)]="rowData.item"
                placeholder="Selecione">
              </p-dropdown>
            </span>
          </td>
          <td>
            <div *ngIf="rowData.item">
              <span class="overflow-ellipse">
                <p-dropdown *ngIf="rowData.item == 'banco'" class="type-p-dropdown" appendTo="body"
                  [options]="selectContas" placeholder="Selecione" [filter]="true" [(ngModel)]="rowData.valor">
                </p-dropdown>
                <p-dropdown *ngIf="rowData.item == 'tipopag'" class="type-p-dropdown" appendTo="body"
                  [options]="tiposLista" placeholder="Selecione" [filter]="true" [(ngModel)]="rowData.valor">
                </p-dropdown>
                <p-dropdown *ngIf="rowData.item == 'tipobaixa'" class="type-p-dropdown" appendTo="body"
                  [options]="opcoesTiposBaixa" placeholder="Selecione" [filter]="true" [(ngModel)]="rowData.valor">
                </p-dropdown>
                <p-calendar *ngIf="rowData.item == 'previsao' || rowData.item == 'vencimento'" class="type-p-calendar"
                  appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="rowData.valor" [monthNavigator]="true"
                  [yearNavigator]="true" yearRange="2010:2030" (onInput)="onInputDate($event)">
                </p-calendar>
                <input *ngIf="rowData.item == 'historico'" class="form-control text-left" [(ngModel)]="rowData.valor">
              </span>
            </div>
            <div *ngIf="!rowData.item">
              <span class="overflow-ellipse text-left">Nenhuma edição selecionada</span>
            </div>
          </td>
          <td class="text-center">
            <span class="delete" matTooltip="Excluir linha" (click)="excluirLinhaEdicaoLote(rowData)">
              <i class="fas fa-trash-alt"></i>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-footer class="d-flex align-items-center justify-content-between">
    <div class="p-field-checkbox ml-3">
      <p-checkbox class="mr-1" [(ngModel)]="baixarTitulosLote" [binary]="true" inputId="bt"></p-checkbox>
      <label for="bt" style="margin: 0">BAIXAR</label>
    </div>
    <div>
      <button type="button" class="btn btn-secondary" (click)='modalEdicaoLote=false'><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary" (click)="salvarEdicaoLote()"><i class="fas fa-check"></i>
        Salvar</button>
    </div>
  </p-footer>
</p-dialog>


<!--Modal Definir Rateio FEITO-->
<p-dialog header="Definir Rateio" [(visible)]="modalCentroCusto" [style]="{width: '800px'}" [modal]="true"
  styleClass="rateio-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div *ngIf="modalCentroCusto">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Centro de Custo <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesCentroCusto"
          [(ngModel)]="cc" (onChange)="controlCentroCusto(cc.id); " placeholder="Selecione um centro de custo"
          optionLabel="label">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-4">
        <label>Projeto</label>
        <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesProjetos"
          [(ngModel)]="proj" placeholder="Selecione um projeto" optionLabel="label"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="!modalEfetuarBaixa">
        <label>Porcentagem <span class="obrigatorio">*</span></label>
        <input class="form-control text-right" value="{{porcentagemCc}}" [(ngModel)]="porcentagemCc" money>
      </div>
      <div class="form-group col-sm-2" *ngIf="modalEfetuarBaixa">
        <label>Porcentagem <span class="obrigatorio">*</span></label>
        <input class="form-control text-right" value="{{porcentagemCc}}" [disabled]="modalEfetuarBaixa"
          [(ngModel)]="porcentagemCc" money>
      </div>
      <div class="form-group col-sm-2">
        <div class="locked-element">
          <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
          <button class="btn btn-sm btn-info d-block" style="margin-top: 8px" (click)="incluirRateioCentroCusto()"
            [disabled]="isReadOnly"><i class="fas fa-plus"></i> Adicionar</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="movimentacaoRateios.length > 0">
      <div class="col-sm-12">
        <p-table class="table-system" [value]="movimentacaoRateios">
          <!--pedidoItem.pedidoItensRateio-->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35%;">CENTRO CUSTO</th>
              <th style="width: 35%;">PROJETO</th>
              <th class="text-right" style="width: 20%;">PORCENTAGEM</th>
              <th class="text-center" style="width: 10%;">AÇÃO</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width: 35%;">
                <span class="overflow-ellipse"
                  matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">{{
                  rowData.cadCcus.codCcus }}
                  - {{ rowData.cadCcus.descrCcus }} </span>
              </td>

              <td style="width: 35%;" *ngIf="rowData.cadProj">

                <span class="overflow-ellipse"
                  matTooltip="{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}">{{
                  rowData.cadProj.codProj }}
                  - {{ rowData.cadProj.descrProj }} </span>
              </td>
              <td *ngIf="!rowData.cadProj">
              </td>
              <td class="text-right">
                {{ rowData.porcentagem | money }}%
              </td>
              <td class="text-center">
                <span class="delete" matTooltip="Excluir centro de custo" (click)="removerRateioCentroCusto(rowData)"
                  (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr style="height:25px">
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Sem rateio!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <label class="mr-3" *ngIf="rateioCentroCusto !== 0 && rateioCentroCusto !== 100"><strong>Rateio Disponível:</strong>
      {{rateioCentroCusto}}%</label>
    <button type="button" class="btn btn-secondary" *ngIf="rateioCentroCusto === 0 || rateioCentroCusto === 100"
      (click)="fecharModalRateio()"><i class="fas fa-undo"></i> FECHAR</button>
  </p-footer>
</p-dialog>

<!-- Modal adicionar adiantamento -->
<p-dialog header="Adicionar Adiantamentos" [(visible)]="exibirModalAdicionarAdiantamento" [style]="{width: '1200px'}"
  [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">


  <div class="form-row mt-2">
    <!--Filter Operação-->
    <div class="form-group col-sm-4">
      <p-multiSelect #cmp class="type-p-mult" [options]="operacao" defaultLabel="Filtre por operação"
        (onChange)="filterOperacao($event)"></p-multiSelect>
    </div>
  </div>

  <!-- Table New P-TABLE -->
  <p-table class="table-system" #dt3 [value]="tabelaComFiltro === true ? adtosFiltradosPorOperacaoId : adtos"
    [rows]="10" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [paginator]="false" editable="true"
    [style]="{'width':'100%'}" (onFilter)="onFilterAdiantamento($event)"
    [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''" [scrollable]="adtos.length >= 11 ? true : false"
    scrollHeight="250px" immutable="false" (onHeaderCheckboxToggle)="selectTodosAdiantamentos($event)"
    (onRowSelect)="selectAdiantamento($event)" (onRowUnselect)="unselectAdiantamento($event)">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center tableHeaderCheckbox">
          <span class="check-all p-0">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </span>
        </th>
        <th class="text-center">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataView'">
            <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'dataView'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'dataView', 'contains')">
        </th>
        <th class="text-center">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
            <span class="text-overflow-dynamic-ellipsis">Código <p-sortIcon [field]="'cod'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'cod', 'contains')">
        </th>
        <th>
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
            <span class="text-overflow-dynamic-ellipsis">Razão Social <p-sortIcon [field]="'razao'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'razao', 'contains')">
        </th>
        <th>
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'n_doc'">
            <span class="text-overflow-dynamic-ellipsis">Nº doc. <p-sortIcon [field]="'n_doc'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'n_doc', 'contains')">
        </th>
        <th>
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
            <span class="text-overflow-dynamic-ellipsis">Operação <p-sortIcon [field]="'operacao'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'operacao', 'contains')">
        </th>
        <th class="text-right">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_original'">
            <span class="text-overflow-dynamic-ellipsis">Vl. Original <p-sortIcon [field]="'vl_original'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'vl_original', 'contains')">
        </th>
        <th class="text-right">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'vl_disponivel'">
            <span class="text-overflow-dynamic-ellipsis">Vl. Disponível <p-sortIcon [field]="'vl_disponivel'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'vl_disponivel', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="text-center tableCheckbox">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td class="text-center">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              style="user-select: all; cursor: text;">{{convertDate(rowData.dataView)}}</span>
          </span>
        </td>
        <td class="text-center">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;">{{rowData.cod}}</span>
          </span>
        </td>
        <td>
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.razao}}">{{rowData.razao}}</span>
          </span>
        </td>
        <td>
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.n_doc}}">{{rowData.n_doc}}</span>
          </span>
        </td>
        <td>
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
          </span>
        </td>
        <td class="text-right">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.vl_original | money}}">{{rowData.vl_original | money}}</span>
          </span>
        </td>
        <td class="text-right">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.vl_disponivel | money}}">{{rowData.vl_disponivel | money}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="8" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>


  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="closeModalFiltraAdiantamentos(dt3)"><i
        class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="adicionarAdiantamentosFiltrados(dt3)"><i
        class="fas fa-check"></i>
      Adicionar</button>
  </p-footer>
</p-dialog>

<!-- Modal adicionar adiantamento -->
<p-dialog header="Parcelamento" [(visible)]="parcelamento" [style]="{width: '1200px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Valor Título</label>
      <input class="form-control text-right" type="text" readonly [(ngModel)]="valorRecebidoBaixa" money />
    </div>

    <div class="form-group col-sm-2">
      <label>Qtde Parcelas</label>
      <input class="form-control text-left" type="number" [(ngModel)]="qtdParcelas" pInputText
        class="form-control form-control-sm" min="1" />
    </div>
    <div class="form-group pull-left">
      <label>Primeiro Venc.</label>
      <p-calendar class="type-p-calendar" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
        yearRange="2010:2030" dateFormat="dd/mm/yy" [defaultDate]="defaultDate" [(ngModel)]="primeiroVencimento"
        (onInput)="onInputDate($event)">
      </p-calendar>
    </div>
    <div class="form-group col-sm-1">
      <label style="visibility: hidden;">d</label>
      <div class="btn btn-sm btn-info"
        style="height: 35px; display: flex; justify-content: center; align-items: center;"
        (click)="formaPgtoCalcular()"> <i class="fas fa-calculator mr-1"></i> Calcular</div>
    </div>
  </div>
  <p-table [value]="parcelamentoList" class="table-system" [scrollable]="false">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5%;" class="text-center">Parcela</th>
        <th style="width: 10%;" class="text-center">Vencimento</th>
        <th style="width: 15%;" class="text-center">Conta Bancária</th>
        <th style="width: 15%;" class="text-center">Documento</th>
        <th style="width: 10%;" class="text-right">Valor Principal</th>
        <th style="width: 10%;" class="text-right">Multa</th>
        <th style="width: 10%;" class="text-right">Juros</th>
        <th style="width: 10%;" class="text-right">Desconto</th>
        <th style="width: 10%;" class="text-right">Valor</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td pEditableColumn style="width: 5%;" class="text-center">
          <p-cellEditor>
            <ng-template pTemplate="output">{{rowData.parcela}}</ng-template>
            <ng-template pTemplate="input">{{rowData.parcela}}</ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 10%;" class="text-center">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <p-calendar appendTo="body" class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="rowData.dtVencimento" dateFormat="dd/mm/yy"
                  (onInput)="onInputDate($event)">
                </p-calendar>
              </div>
            </ng-template>
            <ng-template pTemplate="output">{{rowData.dtVencimento | date:'dd/MM/yyyy'}}</ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 15%;">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="selectContas"
                  placeholder="Selecione" [(ngModel)]="rowData.empresaConta.id" (onChange)="setConta($event, rowData)">
                </p-dropdown>
              </div>
            </ng-template>
            <ng-template pTemplate="output" class="overflow-ellipse">
              <span matTooltip="{{(rowData.empresaConta)?rowData.empresaConta.nome:rowData.empresaConta}}">
                {{(rowData.empresaConta.id)?rowData.empresaConta.nome:'Clique para Editar'}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 15%;" class="text-right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control text-right" type="number" [(ngModel)]="rowData.documento"
                  (keydown.enter)="alterarValorParcelamento(rowData, 'documento')"
                  (keydown.tab)="alterarValorParcelamento(rowData, 'documento')"
                  (focusout)="alterarValorParcelamento(rowData, 'documento')" />
              </div>
            </ng-template>
            <ng-template pTemplate="output">{{rowData.documento}}</ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 10%;" class="text-right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control text-right" type="text" [(ngModel)]="rowData.valorPrincipal" money
                  (keydown.enter)="alterarValorParcelamento(rowData, 'principal')"
                  (keydown.tab)="alterarValorParcelamento(rowData, 'principal')"
                  (focusout)="alterarValorParcelamento(rowData, 'principal')" />
              </div>
            </ng-template>
            <ng-template pTemplate="output">{{rowData.valorPrincipal | money}}</ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 10%;" class="text-right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control text-right" type="text" [(ngModel)]="rowData.multa" money
                  (keydown.enter)="alterarValorParcelamento(rowData, 'multa')"
                  (keydown.tab)="alterarValorParcelamento(rowData, 'multa')"
                  (focusout)="alterarValorParcelamento(rowData, 'multa')" />
              </div>
            </ng-template>
            <ng-template pTemplate="output">{{rowData.multa | money}}</ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 10%;" class="text-right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control text-right" type="text" [(ngModel)]="rowData.juros" money
                  (keydown.enter)="alterarValorParcelamento(rowData, 'juros')"
                  (keydown.tab)="alterarValorParcelamento(rowData, 'juros')"
                  (focusout)="alterarValorParcelamento(rowData, 'juros')" />
              </div>
            </ng-template>
            <ng-template pTemplate="output">{{rowData.juros | money}}</ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn style="width: 10%;" class="text-right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control text-right" type="text" [(ngModel)]="rowData.desconto" money
                  (keydown.enter)="alterarValorParcelamento(rowData, 'desconto')"
                  (keydown.tab)="alterarValorParcelamento(rowData, 'desconto')"
                  (focusout)="alterarValorParcelamento(rowData, 'desconto')" />
              </div>
            </ng-template>
            <ng-template pTemplate="output">{{rowData.desconto | money}}</ng-template>
          </p-cellEditor>
        </td>

        <td style="width: 10%;" class="text-right">
          <span matTooltip="{{rowData.valor | money}}" class="text-right">{{rowData.valor | money}}</span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td colspan=4 style="text-align:right; font-weight:bold; font-size: 12px; width: 45%;"> Totais:</td>
        <td style="text-align:right; font-weight:bold; font-size: 12px; width: 10%;">
          {{valorPrincipalParcelamento | money}} </td>
        <td style="text-align:right; font-weight:bold; font-size: 12px; width: 10%;">
          {{valorMultaParcelamento | money}} </td>
        <td style="text-align:right; font-weight:bold; font-size: 12px; width: 10%;">
          {{valorJurosParcelamento | money}} </td>
        <td style="text-align:right; font-weight:bold; font-size: 12px; width: 10%;">
          {{valorDescontoParcelamento | money}} </td>
        <td style="text-align:right; font-weight:bold; font-size: 12px; width: 10%;">
          {{valorTotalParcelamento | money}} </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="closeModalParcelamento()"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="salvarParcelamento()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!-- Modal DETALHE LCTO FINANCEIRO-->
<p-dialog [(visible)]="modalDetalheContFin" [style]="{width: '1000px'}" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">
  <p-header class="title-modal">Informações Título: {{numDoc}}</p-header>
  <div>
    <div class="rowInfo" style="margin-bottom: 1rem;">
      <div style="margin-right: 1rem;"><strong>PARTICIPANTE:</strong> {{codPart}} - {{razaoSocial}}</div>
      <div><strong>CNPJ/CPF:</strong> {{cnpjCpf}}</div>
    </div>
    <div class="rowInfo" style="margin-bottom: 1rem;">
      <div style="margin-right: 1rem;"><strong>PEDIDO:</strong> {{codPedido}}</div>
      <div style="margin-right: 1rem;"><strong>NUM DOC:</strong> {{numDoc}}</div>
      <div style="margin-right: 1rem;"><strong>CÓD CONTROLE: </strong>{{codControle}}</div>
      <div style="margin-right: 1rem;"><strong>VALOR TOTAL: </strong>{{valorTotal}}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-info-circle"></i> FINANCEIRO
      </h3>
    </div>
  </div>
  <mat-tab-group>
    <mat-tab label="Parcelas">
      <p-table class="table-system" [value]="detalheFin" scrollHeight="200px" [scrollable]='true'>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%" class="text-center">DT EXTRATO</th>
            <th style="width: 10%;" class="text-left">CONTA</th>
            <th style="width: 10%;" class="text-left">OPERAÇÃO</th>
            <th style="width: 8%" class="text-center">PARCELA</th>
            <th style="width: 10%" class="text-right">VALOR</th>
            <th style="width: 10%;" class="text-center">STATUS</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.dtExtrato }}'>{{
                rowData.dtExtrato }} </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.contaFinanceira }}'>{{
                rowData.contaFinanceira }} </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.regraContabil }}'>{{
                rowData.regraContabil }} </span>
            </td>
            <td style="width: 8%;">
              <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.parcela }}'>{{
                rowData.parcela }} </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: right" matTooltip='{{ rowData.valor }}'>{{ rowData.valor
                }}
              </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.status }}'>{{
                rowData.status }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr style="height:25px">
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Sem Informações!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </mat-tab>
    <mat-tab label="Adiantamento">
      <p-table class="table-system" [value]="detalheAdto" scrollHeight="200px" [scrollable]='true'>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%" class="text-center">DT EXTRATO</th>
            <th style="width: 10%;" class="text-left">CONTA</th>
            <th style="width: 10%;" class="text-left">OPERAÇÃO</th>
            <th style="width: 8%" class="text-center">PARCELA</th>
            <th style="width: 10%" class="text-right">VALOR</th>
            <th style="width: 10%;" class="text-center">STATUS</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.dtExtrato }}'>{{
                rowData.dtExtrato }} </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.contaFinanceira }}'>{{
                rowData.contaFinanceira }} </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.regraContabil }}'>{{
                rowData.regraContabil }} </span>
            </td>
            <td style="width: 8%;">
              <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.parcela }}'>{{
                rowData.parcela }} </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: right" matTooltip='{{ rowData.valor }}'>{{ rowData.valor
                }}
              </span>
            </td>
            <td style="width: 10%;">
              <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.status }}'>{{
                rowData.status }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr style="height:25px">
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Sem Informações!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </mat-tab>
  </mat-tab-group>
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-info-circle"></i> CONTÁBIL
      </h3>
    </div>
  </div>
  <p-table class="table-system" [value]="detalheCont" scrollHeight="200px" [scrollable]='true'>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 10%" class="text-center">DT LCTO</th>
        <th style="width: 8%" class="text-center">NUMERO</th>
        <th style="width: 10%" class="text-center">LOTE</th>
        <th style="width: 5%;" class="text-left">D/C</th>
        <th style="width: 10%;" class="text-left">CONTA</th>
        <th style="width: 10%;" class="text-center">VALOR</th>
        <th style="width: 30%;" class="text-center">HIST</th>
        <th style="width: 10%;" class="text-left">C.C</th>
        <th style="width: 10%;" class="text-left">PROJ</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td style="width: 10%;" class="text-center">
          <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.dataLcto }}'>{{
            rowData.dataLcto }} </span>
        </td>
        <td style="width: 8%;" class="text-center">
          <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.numero }}'>{{ rowData.numero
            }} </span>
        </td>
        <td style="width: 10%;" class="text-right">
          <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.lote }}'>{{ rowData.lote }}
          </span>
        </td>
        <td style="width: 5%">
          <span class="overflow-ellipse" style="text-align: center" matTooltip='{{ rowData.indDc }}'>{{ rowData.indDc }}
          </span>
        </td>
        <td style="width: 10%;">
          <span class="overflow-ellipse" style="text-align: left"
            matTooltip='{{ rowData.codCta }} - {{ rowData.descCta }}'>{{ rowData.codCta }} - {{ rowData.descCta }}
          </span>
        </td>
        <td style="width: 10%;">
          <span class="overflow-ellipse" style="text-align: right" matTooltip='{{ rowData.valor }}'>{{ rowData.valor }}
          </span>
        </td>
        <td style="width: 30%;">
          <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.histComp }}'>{{
            rowData.histComp }} </span>
        </td>
        <td style="width: 10%;">
          <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.codCcus }}'>{{ rowData.codCcus
            }} </span>
        </td>
        <td style="width: 10%;">
          <span class="overflow-ellipse" style="text-align: left" matTooltip='{{ rowData.codProj }}'>{{ rowData.codProj
            }} </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr style="height:25px">
        <td [attr.colspan]="4" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Sem Informações!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalDetalheContFin=false;'>
          Fechar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Modal Alternar Conta-->
<p-dialog header="Alternar Conta" [(visible)]="modalAlternarConta" [style]="{width: '700px'}" [modal]="true"
  class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione a conta</label>
      <p-dropdown class="type-p-dropdown" [filter]="false" [options]="selectContas" placeholder="Selecione a Conta"
        [(ngModel)]="contaSelecionadaFiltro" (onChange)="changePeriod(true); loadVencidos();"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalAlternarConta=false'><i class="fas fa-undo"></i>
      Cancelar</button>
  </p-footer>
</p-dialog>

<!-- Modal negativacão-->
<p-dialog header="Negativar cobrança" [(visible)]="modalNegativar" [style]="{width: '700px'}" [modal]="true"
  class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>E-mail de contato do cliente</label>
      <input class="form-control" type="text" [(ngModel)]="emailNegativacao" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Telefone de contato do cliente (somente números com DDD)</label>
      <input class="form-control" type="text" [(ngModel)]="telefoneNegativacao" />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <p class="info-negativar-cobranca">Antes de confirmar a negativação, favor conferir se o cliente possui endereço
        completo cadastrado e se o
        CPF/CNPJ e nome são conforme o cadastro da receita federal.</p>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalNegativar=false'><i
        class="fas fa-undo"></i>Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="negativacao(false)"><i
        class="fas fa-check"></i>Solicitar</button>
  </p-footer>
</p-dialog>

<!-- Modal detalhe cobrança-->
<p-dialog header="Cobrança" [(visible)]="modalDetalheCobranca" [style]="{width: '900px'}" [modal]="true"
  class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="row cobranca-detalhe">
    <div class="col-sm-6">
      <p><b>Tipo cobrança</b>: {{cobranca.tipo_cobranca}}</p>
      <p><b>Emissão</b>: {{cobranca.dt_emissao | date : 'dd/MM/yyyy' : '+000'}}</p>
      <p><b>N° fatura</b>: {{cobranca.n_fatura}}</p>
      <p><b>Valor (R$)</b>: {{cobranca.valor | money}}</p>
      <p><b>Valor líquido (R$)</b>: {{cobranca.valor_liquido | money}}</p>
      <p><b>Valor taxas (R$)</b>: {{cobranca.taxas | money}}</p>
      <p><b>Valor split (R$)</b>: {{cobranca.valor_split | money}}</p>
      <p><b>Data previsão</b>: {{cobranca.dt_previsao | date : 'dd/MM/yyyy' : '+000'}}</p>
      <p><b>Data pagamento</b>: {{cobranca.dt_pagamento | date : 'dd/MM/yyyy' : '+000'}}</p>
      <p><b>Data vencimento original</b>: {{cobranca.dt_vencimento_original | date : 'dd/MM/yyyy' : '+000'}}</p>
      <p><b>Valor original (R$)</b>: {{cobranca.valor_original | money}}</p>

    </div>
    <div class="col-sm-6">
      <p><b>Dias de desconto antes do vencimento</b>: {{cobranca.dias_desconto}}</p>
      <p><b>Tipo desconto</b>: {{cobranca.tipo_desconto === 'FIXED' ? 'Valor' : 'Porcentual'}}</p>
      <p><b>Valor desconto {{cobranca.tipo_desconto === 'FIXED' ? '(R$)' : '(%)'}}</b>: {{cobranca.valor_desconto |
        money}}</p>
      <p><b>Tipo multa</b>: {{cobranca.tipo_multa === 'FIXED' ? 'Valor' : 'Porcentual'}}</p>
      <p><b>Valor multa {{cobranca.tipo_multa === 'FIXED' ? '(R$)' : '(%)'}}</b>: {{cobranca.valor_multa | money}}
      </p>
      <p><b>Tipo juros</b>: {{cobranca.tipo_juros === 'FIXED' ? 'Valor' : 'Porcentual'}}</p>
      <p><b>Valor juros {{cobranca.tipo_juros === 'FIXED' ? '(R$)' : '(%)'}}</b>: {{cobranca.valor_juros | money}}
      </p>
      <p><b>Nosso número (boleto somente)</b>: {{cobranca.nosso_numero}}</p>
      <p><b>Transação PIX</b>: {{cobranca.transacao_pix}}</p>
      <p><b>Status</b>: {{cobranca.status}}</p>
      <p><b>Cancelada</b>: {{cobranca.cancelado ? 'Sim': 'Não'}}</p>
    </div>
  </div>
  <div class="row cobranca-detalhe">
    <div class="col-sm-6">
      <!-- <p *ngIf="cobranca.assinatura_id"><b>Status assinatura</b>: {{cobranca.status_assinatura}}</p> -->
      <p *ngIf="cobranca.assinatura_id"><b>Assinatura cancelada</b>: {{cobranca.assinatura_cancelada ? 'Sim': 'Não'}}</p>
      <p><b>Data visualização documento</b>: {{cobranca.data_visualizacao_documento | date : 'dd/MM/yyyy hh:mm' :
        '+000'}}</p>
      <p><b>Data visualização fatura</b>: {{cobranca.data_visualizacao_fatura | date : 'dd/MM/yyyy hh:mm' : '+000'}}
      </p>
      <p><b>Cobrança criada/atualizada</b>: {{cobranca.change_timestamp | date : 'dd/MM/yyyy hh:mm' : '+000'}} por
        {{cobranca.nome}}</p>
    </div>
    <div class="col-sm-6">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-start justify-content-evenly">
          <a target='_blank' href='{{cobranca.link_fatura}}'><button type="button"
              class="btn btn-primary">Fatura</button></a>
          <a *ngIf="cobranca.link_comprovante" target='_blank' href='{{cobranca.link_comprovante}}'><button
              type="button" class="btn btn-primary">Comprovante pagamento</button></a>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-2 d-flex align-items-start justify-content-evenly">
          <a *ngIf="cobranca.tipo_cobranca === 'BOLETO'" target='_blank' href='{{cobranca.link_documento}}'><button
              type="button" class="btn btn-primary">Boleto</button></a>
          <button *ngIf="cobranca.tipo_cobranca === 'BOLETO'" type="button" class="btn btn-primary"
            (click)='copiarLinhaDigitavel()'>Copiar Boleto</button>
          <button *ngIf="cobranca.tipo_cobranca === 'PIX' || cobranca.tipo_cobranca === 'BOLETO'" type="button"
            class="btn btn-primary" (click)='copiarQRCode()'>Copiar QRCode</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-2 d-flex align-items-start justify-content-evenly">
          <button *ngIf="!cobranca.cancelado" type="button" class="btn btn-danger"
            (click)='cancelarCobranca(cobranca.cancelado)'>Cancelar cobrança</button>
          <button *ngIf="cobranca.cancelado" type="button" class="btn btn-primary"
            (click)='cancelarCobranca(cobranca.cancelado)'>Reativar cobrança</button>
          <button *ngIf="cobranca.negativada" type="button" class="btn btn-primary"
            (click)='negativacao(cobranca.negativada)'>Cancelar negativação</button>
          <button *ngIf="!cobranca.negativada" type="button" class="btn btn-danger"
            (click)='abrirModalNegativacao()'>Negativar</button>
        </div>
      </div>
      <div class="row" *ngIf="cobranca.assinatura_id && !cobranca.assinatura_cancelada">
        <div class="col-sm-12 mt-2 d-flex align-items-start justify-content-evenly">
          <button type="button" class="btn btn-danger" (click)='cancelarAssinatura()'>Cancelar assinatura</button>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalDetalheCobranca=false'><i
        class="fas fa-undo"></i>Fechar</button>
  </p-footer>
</p-dialog>

<app-modal-participante [(isVisible)]="modalParticipante" (onSelectParticipant)="estocarSelecaoParticipante($event)"
  [participanteTipoId]="participanteTipoId">
</app-modal-participante>