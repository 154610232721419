<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        {{title}}
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
          <br>
          <small class="font-weight-normal">Período selecionado: {{convertTimestamp(dataIni)}} à
            {{convertTimestamp(dataFin)}}</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">{{ '&nbsp;' }}</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="dadosTabela" [style]="{width:'100%',height:'100%'}" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [totalRecords]="totalLinhas"
              [(selection)]="ordSelecionadas" dataKey="id" [lazy]="true" (onLazyLoad)="receberOrdens($event)"
              [lazyLoadOnInit]="false" paginatorDropdownAppendTo="body" (onRowSelect)="atualizarToolbar()"
              (onHeaderCheckboxToggle)="atualizarToolbar()" (onRowUnselect)="atualizarToolbar()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox [disabled]="checkboxDesativada"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DATA <p-sortIcon [field]="'data'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'data', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'documento'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CÓDIGO <p-sortIcon [field]="'documento'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'documento', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoItem'">
                      <span class="text-overflow-dynamic-ellipsis">
                        ITEM <p-sortIcon [field]="'codigoItem'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigoItem', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                      <span class="text-overflow-dynamic-ellipsis">
                        HISTÓRICO <p-sortIcon [field]="'historico'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'historico', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'almoxarifado'">
                      <span class="text-overflow-dynamic-ellipsis">
                        ALMOXARIFADO <p-sortIcon [field]="'almoxarifado'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'almoxarifado', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoEntradaSaida'">
                      <span class="text-overflow-dynamic-ellipsis">
                        TIPO <p-sortIcon [field]="'tipoEntradaSaida'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="listaES"
                      defaultLabel="" [filter]="false"
                      (onChange)="dt.filter($event.value, 'tipoEntradaSaidaId', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                      <span class="text-overflow-dynamic-ellipsis">
                        QTDE <p-sortIcon [field]="'qtde'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'qtde', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">
                        VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [value]="rowData" [disabled]="checkboxDesativada"></p-tableCheckbox>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data }}">
                        {{rowData.data}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.documento}}">
                        {{rowData.documento}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigoItem}}">
                        {{rowData.codigoItem}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.historico}}">
                        {{rowData.historico}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.almoxarifado}}">
                        {{rowData.almoxarifado}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoEntradaSaida}}">
                        {{rowData.tipoEntradaSaida}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.qtde}}">
                        {{rowData.qtde}}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor | money}}">
                        {{rowData.valor | money}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="12" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header={{tituloModal}} [(visible)]="mostrarModal" [style]="{width: '900px'}" [modal]="true"
  class="modal-scroll" appendTo="body" [closable]="false" *ngIf="mostrarModal">
  <div class="form-row">
    <div class="form-group col-2" *ngIf="ordemProd.data">
      <label>DATA <span class="obrigatorio">*</span> </label>
      <p-calendar class="type-p-calendar" [(ngModel)]="ordemProd.data" appendTo="body" [monthNavigator]="true"
        [yearNavigator]="true" yearRange="2010:2030" dateFormat="dd/mm/yy" *ngIf="!modalVisualizacao">
      </p-calendar>
      <input class="form-control" [ngModel]="convertTimestamp(ordemProd.data)" *ngIf="modalVisualizacao" readonly
        type="text" />
    </div>
    <div class="form-group col-sm-2" *ngIf="mostrarCamposModal.mostrarQtde">
      <label>QTDE. <span class="obrigatorio">*</span> </label>
      <input class="form-control" [(ngModel)]="ordemProd.qtde" type="number" [readonly]="modalVisualizacao" />
    </div>
    <div class="form-group col-sm-8" *ngIf="mostrarCamposModal.mostrarAlmox">
      <label>Almoxarifado <span class="obrigatorio">*</span> </label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaAlmoxarifado" [(ngModel)]="ordemProd.local"
        [filter]="true" [disabled]="requisicaoAlmoxCarregando" *ngIf="!modalVisualizacao"
        [dropdownIcon]="requisicaoAlmoxCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
        placeholder="Selecione" panelStyleClass="custom-width">
      </p-dropdown>
      <input class="form-control" [ngModel]="ordemProd.local.codigo + ' - ' + ordemProd.local.descricao"
        *ngIf="modalVisualizacao" readonly type="text" />
    </div>
  </div>
  <div class="form-row" *ngIf="mostrarCamposModal.mostrarItem">
    <div class="form-group col-sm-12">
      <label>Item <span class="obrigatorio">*</span> </label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaItem" [(ngModel)]="ordemProd.codItem"
        [filter]="true" [disabled]="requisicaoItemCarregando" *ngIf="!modalVisualizacao"
        [dropdownIcon]="requisicaoItemCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
        placeholder="Selecione" panelStyleClass="custom-width">
      </p-dropdown>
      <input class="form-control" [ngModel]="ordemProd.codItem.codigo + ' - ' + ordemProd.codItem.descricao"
        *ngIf="modalVisualizacao" readonly type="text" />
    </div>
  </div>
  <div class="form-row" *ngIf="mostrarCamposModal.mostrarHist">
    <div class="form-group col-sm-12">
      <label>Histórico <span class="obrigatorio">*</span> </label>
      <input class="form-control" [(ngModel)]="ordemProd.historico" [readonly]="modalVisualizacao" type="text" />
    </div>
  </div>

  <div class="pull-right align-down-table">
    <app-toolbar [value]="toolbarItens" *ngIf="modalVisualizacao"></app-toolbar>
  </div>

  <p-table class="table-system" [value]="dadosTabelaItens" [paginator]="false" [rows]="15" [resizableColumns]="true"
    [responsive]="true" [rowHover]="false" derableColumns="true" [scrollable]="true" [style]="{width:'100%'}"
    scrollHeight="280px" *ngIf="modalVisualizacao">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 25%;">Item</th>
        <th style="width: 25%;">Almoxarifado</th>
        <th style="width: 25%;">Histórico</th>
        <th style="width: 5%;">E/S</th>
        <th style="width: 10%;" class="text-right">Qtde.</th>
        <th style="width: 10%;" class="text-right">Valor</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr class="line">
        <td class="text-left" style="width:25%">
          <span matTooltip="{{rowData.item}}" class="overflow-ellipse" style="cursor: pointer;">
            {{rowData.item}}
          </span>
        </td>
        <td style="width:25%">
          <span matTooltip="{{rowData.alm}}" class="overflow-ellipse" style="cursor: pointer;">
            {{rowData.alm}}
          </span>
        </td>
        <td style="width:25%">
          <span matTooltip="{{rowData.hist}}" class="overflow-ellipse" style="cursor: pointer;">
            {{rowData.hist}}
          </span>
        </td>
        <td style="width:5%">
          <span matTooltip="{{rowData.descricao}}" class="overflow-ellipse" style="cursor: pointer;">
            {{rowData.descricao}}
          </span>
        </td>
        <td style="width:10%">
          <span matTooltip="{{rowData.qtde}}" class="overflow-ellipse" style="cursor: pointer;">
            {{rowData.qtde}}
          </span>
        </td>
        <td style="width:10%">
          <span matTooltip="{{rowData.valor}}" class="overflow-ellipse" style="cursor: pointer;">
            {{rowData.valor}}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='this.fecharModal()' *ngIf="!modalVisualizacao"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='this.concluirModal()'><i class="fas fa-check"></i>
      {{tituloBotaoModal}}</button>
  </p-footer>

</p-dialog>

<p-dialog header="Filtrar Período" [(visible)]="mostrarFiltro" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [(ngModel)]="dataIni" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [(ngModel)]="dataFin" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
  </div>

  <p-footer>
    <div id="div-botoes">
      <div class="w-100 d-flex align-items-center justify-content-end">
        <button type="button" class="btnCustom-default mr-3" (click)='mostrarModalFiltro(false)'>
          <span class="material-icons">close</span>
          <div>Cancelar</div>
        </button>
        <button type="button" class="btnCustom-green" [disabled]='!dataIni || !dataFin || dataIni > dataFin'
          (click)='saveFilterModal()'>
          <span class="material-icons">filter_alt</span>
          <div>Filtrar</div>
        </button>
      </div>
    </div>
  </p-footer>

</p-dialog>

