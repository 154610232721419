<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullBenef ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content tableSpace">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateBenef>
            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt [columns]="colsBeneficio" [value]="beneficiosLista" [rows]="10"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" (onRowUnselect)="onRowUnselect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis">BENEFÍCIO <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nome', 'contains')">
                  </th>
                  <th class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum benefício encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pb-4" *ngIf="rowSelected">
        <div class="shadow-box" [ngClass]="fullDetal ? 'box-fullscreen' : ''">
          <div class="header-box" style="min-height: 50px;">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{active: statusTela1}" *ngIf="!idadeEdit">
                  <a (click)="showTelaBeneficios('TELA_1')">Por funcionário</a>
                </li>
                <li class="list-inline-item" [ngClass]="{active: statusTela1}" *ngIf="idadeEdit">
                  <a (click)="showTelaBeneficios('TELA_1')">Por nível</a>
                </li>
                <li class="list-inline-item" [ngClass]="{active: statusTela2}" *ngIf="!idadeEdit">
                  <a (click)="showTelaBeneficios('TELA_2')">Por cargo</a>
                </li>
                <li class="list-inline-item" [ngClass]="{active: statusTela3}" *ngIf="!comissaoEdit">
                  <a (click)="showTelaBeneficios('TELA_3')">Reajustes</a>
                </li>
              </ul>
              <!-- <div class="">
                <p-dropdown class="type-p-dropdown d-inline-block drop-header-card" [filter]="true"
                [options]="opcoesVersoes" placeholder="Selecione uma versão" [(ngModel)]="versaoSelecionada"
                (onChange)="atuTabBenef()"></p-dropdown>
              </div> -->
              <div class="actions-card" style="display: flex; gap: 8px;">
                <p-dropdown class="type-p-dropdown d-inline-block drop-header-card" [filter]="true"
                [options]="opcoesVersoes" placeholder="Selecione uma versão" [(ngModel)]="versaoSelecionada"
                (onChange)="atuTabBenef()"></p-dropdown>
                <div *ngIf="statusTela1 && !idadeEdit">
                  <app-toolbar [value]="toolbarFunc"></app-toolbar>
                </div>
                <div *ngIf="statusTela2" type="button">
                  <app-toolbar [value]="toolbarCargo"></app-toolbar>
                </div>
                <div *ngIf="statusTela3" type="button">
                  <app-toolbar [value]="toolbarReajuste"></app-toolbar>
                </div>
                <div *ngIf="statusTela1 && idadeEdit" type="button">
                  <app-toolbar [value]="toolbarNivel"></app-toolbar>
                </div>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDetal>
            <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela1'}" *ngIf="!comissaoEdit && !idadeEdit">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [value]="funcBenefLista" [rows]="10" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nome', 'contains')">
                    </th>
                    <th class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cargo'">
                        <span class="text-overflow-dynamic-ellipsis">CARGO <p-sortIcon [field]="'cargo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'cargo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_desc'">
                        <span class="text-overflow-dynamic-ellipsis">VL. DESC <p-sortIcon [field]="'valor_desc'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_desc', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_reaj'">
                        <span class="text-overflow-dynamic-ellipsis">VL. REAJ. <p-sortIcon [field]="'valor_reaj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_reaj', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_proj'">
                        <span class="text-overflow-dynamic-ellipsis">VL. PROJ. <p-sortIcon [field]="'valor_proj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_proj', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.cargo}}">{{rowData.cargo}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2'
                          : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_desc | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_desc | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_reaj | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_reaj | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_proj | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_proj | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <span class="edit" style="margin-right: 5px;" matTooltip="Editar"
                        (click)="onFuncRelacSelect(rowData)">
                        <i class="fas fa-pencil-alt"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir" (click)="excluirFuncRelac(rowData)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum funcionário encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela1'}" *ngIf="comissaoEdit && !idadeEdit">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [value]="funcBenefLista" [rows]="10" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nome', 'contains')">
                    </th>
                    <th class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cargo'">
                        <span class="text-overflow-dynamic-ellipsis">CARGO <p-sortIcon [field]="'cargo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'cargo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_mes'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. MÊS <p-sortIcon [field]="'percentual_mes'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_mes', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_mes_proj'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. MÊS PROJ. <p-sortIcon
                            [field]="'percentual_mes_proj'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_mes_proj', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_trim'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. TRIM. <p-sortIcon
                            [field]="'percentual_trim'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_trim', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_trim_proj'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. TRIM. PROJ. <p-sortIcon
                            [field]="'percentual_trim_proj'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_trim_proj', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.cargo}}">{{rowData.cargo}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_mes | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_mes
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_mes_proj | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_mes_proj
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_trim | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_trim
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_trim_proj | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_trim_proj
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <span class="edit" style="margin-right: 5px;" matTooltip="Editar"
                        (click)="onFuncRelacSelect(rowData)">
                        <i class="fas fa-pencil-alt"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir" (click)="excluirFuncRelac(rowData)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum funcionário encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela1'}" *ngIf="!comissaoEdit && idadeEdit">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [value]="idadeNivBenefLista" [rows]="10" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'idade_min'">
                        <span class="text-overflow-dynamic-ellipsis">IDADE MIN <p-sortIcon [field]="'idade_min'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'idade_min', 'contains')">
                    </th>
                    <th class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'idade_max'">
                        <span class="text-overflow-dynamic-ellipsis">IDADE MAX <p-sortIcon [field]="'idade_max'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'idade_max', 'contains')">
                    </th>
                    <th class="text-left" style="width: 36%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                        <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nivel', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_desc'">
                        <span class="text-overflow-dynamic-ellipsis">VL. DESC <p-sortIcon [field]="'valor_desc'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_desc', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_reaj'">
                        <span class="text-overflow-dynamic-ellipsis">VL. REAJ. <p-sortIcon [field]="'valor_reaj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_reaj', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_proj'">
                        <span class="text-overflow-dynamic-ellipsis">VL. PROJ. <p-sortIcon [field]="'valor_proj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_proj', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.idade_min | number}}">{{rowData.idade_min | number}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.idade_max | number}}">{{rowData.idade_max | number}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 36%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2'
                          : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_desc | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_desc | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_reaj | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_reaj | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_proj | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_proj | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <span class="edit" style="margin-right: 5px;" matTooltip="Editar"
                        (click)="onIdadeNivSelect(rowData)">
                        <i class="fas fa-pencil-alt"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir" (click)="excluirIdadeNivRelac(rowData)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum relação encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela2'}" *ngIf="!comissaoEdit">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [value]="cargoBenefLista" [rows]="10" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 27%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">CARGO <p-sortIcon [field]="'descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 27%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                        <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nivel', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_desc'">
                        <span class="text-overflow-dynamic-ellipsis">VL. DESC <p-sortIcon [field]="'valor_desc'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_desc', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_reaj'">
                        <span class="text-overflow-dynamic-ellipsis">VL. REAJ. <p-sortIcon [field]="'valor_reaj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_reaj', 'contains')">
                    </th>
                    <th class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_proj'">
                        <span class="text-overflow-dynamic-ellipsis">VL. PROJ. <p-sortIcon [field]="'valor_proj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'valor_proj', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                          === 'None'? '' : rowData.codigo }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 27%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 27%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2'
                          : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_desc | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_desc | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_reaj | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_reaj | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor_proj | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_proj | number
                          : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <span class="edit" style="margin-right: 5px;" matTooltip="Editar"
                        (click)="onCargoRelacSelect(rowData)">
                        <i class="fas fa-pencil-alt"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir" (click)="excluirCargoRelac(rowData)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum cargo encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela2'}" *ngIf="comissaoEdit">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [value]="cargoBenefLista" [rows]="10" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">CARGO <p-sortIcon [field]="'descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                        <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nivel', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_mes'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. MÊS <p-sortIcon [field]="'percentual_mes'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_mes', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_mes_proj'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. MÊS PROJ. <p-sortIcon
                            [field]="'percentual_mes_proj'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_mes_proj', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_trim'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. TRIM. <p-sortIcon
                            [field]="'percentual_trim'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_trim', 'contains')">
                    </th>
                    <th class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual_trim_proj'">
                        <span class="text-overflow-dynamic-ellipsis">PERC. TRIM. PROJ. <p-sortIcon
                            [field]="'percentual_trim_proj'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual_trim_proj', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 9%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                          === 'None'? '' : rowData.codigo }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 23%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_mes | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_mes
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_mes_proj | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_mes_proj
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_trim | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_trim
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percentual_trim_proj | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual_trim_proj
                          | number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <span class="edit" style="margin-right: 5px;" matTooltip="Editar"
                        (click)="onCargoRelacSelect(rowData)">
                        <i class="fas fa-pencil-alt"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir" (click)="excluirCargoRelac(rowData)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum cargo encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusTela3 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela3'}">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [value]="reajusteLista" [rows]="10" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" sortField="dt_reaj"
                [sortOrder]="-1" [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 50%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_reaj'">
                        <span class="text-overflow-dynamic-ellipsis">DATA REAJUSTE <p-sortIcon [field]="'dt_reaj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'dt_reaj', 'contains')">
                    </th>
                    <th class="text-right" style="width: 50%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percentual'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR REAJUSTE <p-sortIcon [field]="'percentual'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'percentual', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 50%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onReajSelect(rowData)'
                          matTooltip="{{rowData.dt_reaj | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.dt_reaj | date :
                          'dd/MM/yyyy' : '+000'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 50%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onReajSelect(rowData)'
                          matTooltip="{{rowData.percentual | number : '1.2-2' : 'pt-BR'}}%">{{rowData.percentual |
                          number : '1.2-2' : 'pt-BR'}}%</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="2" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum reajuste encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVO -->
<p-dialog header="Adicionar novo benefício" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do benefício" [(ngModel)]="codigoNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Benefício</label>
      <input class="form-control text-left" placeholder="Benefício" [(ngModel)]="beneficioNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição do benefício" [(ngModel)]="descricaoNew">
    </div>
    <div class="form-row">
      <div class="form-group col">
        <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="comissaoNew" (change)="comissaoOuIdade(0)">Percentual
        </mat-slide-toggle>
      </div>
      <div class="form-group col">
        <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="idadeNew" (change)="comissaoOuIdade(1)">Faixa etária
        </mat-slide-toggle>
      </div>
      <div class="form-group col" *ngIf="comissaoNew">
        <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="possuiPisoNew">Valor mínimo
        </mat-slide-toggle>
      </div>
      <div class="form-group col" *ngIf="!comissaoNew">
        <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="possuiDependentesNew">Dependentes
        </mat-slide-toggle>
      </div>
    </div>
    <div class="form-row" *ngIf="possuiPisoNew && comissaoNew">
      <div class="form-group col-sm-12">
        <label>Valor mínimo</label>
        <input class="form-control text-right" placeholder="Valor mínimo" type="number" [(ngModel)]="valorPisoNew"
          [disabled]="!possuiPisoNew">
      </div>
    </div>
    <div class="form-row" *ngIf="possuiDependentesNew && !comissaoNew">
      <div class="form-group col-sm-12">
        <label>Idade máxima</label>
        <input class="form-control text-right" placeholder="Idade máxima" type="number"
          [(ngModel)]="dependentesIdadeMaxNew" [disabled]="!possuiDependentesNew">
      </div>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addBeneficio();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT -->
<p-dialog header="Editar benefício" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do benefício" [(ngModel)]="codigoEdit">
    </div>
    <div class="form-group col-sm-12">
      <label>Benefício</label>
      <input class="form-control text-left" placeholder="Benefício" [(ngModel)]="beneficioEdit">
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição do benefício" [(ngModel)]="descricaoEdit">
    </div>
    <div class="form-row">
      <div class="form-group col" *ngIf="comissaoEdit">
        <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="possuiPisoEdit">Valor mínimo
        </mat-slide-toggle>
      </div>
      <div class="form-group col" *ngIf="!comissaoEdit">
        <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="possuiDependentesEdit">Dependentes
        </mat-slide-toggle>
      </div>
    </div>

    <div class="form-row col-sm-12" *ngIf="possuiPisoEdit && comissaoEdit">
      <label>Valor mínimo</label>
      <input class="form-control text-left" placeholder="Valor mínimo do benefício" type="number"
        [(ngModel)]="valorPisoEdit">
    </div>

    <div class="form-group col-sm-12" *ngIf="possuiDependentesEdit && !comissaoEdit">
      <label>Idade máxima</label>
      <input class="form-control text-right" placeholder="Idade máxima" type="number"
        [(ngModel)]="dependentesIdadeMaxEdit" [disabled]="!possuiDependentesEdit">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editBeneficio();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL NOVO REAJUSTE -->
<p-dialog header="Adicionar novo reajuste" [(visible)]="modalReajNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Percentual (%)</label>
      <input class="form-control text-left" type="number" placeholder="Percentual do reajuste (%)" [(ngModel)]="percentualNew">
    </div>
    <div class="form-group col-sm-6">
      <label>Data do reajuste</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtReajusteNew"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalReajNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addReajuste();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT REAJUSTE-->
<p-dialog header="Editar reajuste" [(visible)]="modalReajEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Percentual (%)</label>
      <input class="form-control text-left" type="number" placeholder="Percentual do reajuste (%)" [(ngModel)]="percentualEdit">
    </div>
    <div class="form-group col-sm-6">
      <label>Data do reajuste</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtReajusteEdit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalReajEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-danger" (click)='deleteReajuste();'><i class="fas fa-times"></i>
          Excluir</button>
        <button type="button" class="btn btn-primary" (click)="editReajuste();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL NOVO FUNCIONARIO -->
<p-dialog header="Adicionar novo funcionário" [(visible)]="modalFuncNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Funcionários</label>
      <p-multiSelect class="type-p-mult" [options]="funcOpcoes" [(ngModel)]="funcSelecionados"
        defaultLabel="Selecionar funcionário(s)" [filter]="true" placeholder="{0} funcionário(s) selecionado(s)"
        selectedItemsLabel="{0} funcionário(s) selecionado(s)"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Valor</label>
      <input class="form-control text-right" placeholder="Valor do benefício" [(ngModel)]="valorNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Valor desconto</label>
      <input class="form-control text-right" placeholder="Valor do desconto" [(ngModel)]="valorDescNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFuncNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addFuncRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL NOVO FUNCIONARIO COMISSÃO -->
<p-dialog header="Adicionar novo funcionário" [(visible)]="modalFuncComNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Funcionários</label>
      <p-multiSelect class="type-p-mult" [options]="funcOpcoes" [(ngModel)]="funcSelecionados"
        defaultLabel="Selecionar funcionário(s)" [filter]="true" placeholder="{0} funcionário(s) selecionado(s)"
        selectedItemsLabel="{0} funcionário(s) selecionado(s)"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual mensal (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal (%)" [(ngModel)]="percentMesNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual trimestral (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral (%)" [(ngModel)]="percentTrimNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual mensal projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal (%)" [(ngModel)]="percentMesProjNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual trimestral projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral (%)" [(ngModel)]="percentTrimProjNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFuncComNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addFuncRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT FUNCIONARIO -->
<p-dialog header="Editar funcionário" [(visible)]="modalFuncEdit" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor</label>
      <input class="form-control text-right" placeholder="Valor do benefício" [(ngModel)]="valorEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncValor">Todos os funcionários</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsVal" [checked]="allPeriodsValor[0]"
        (change)="changePeriodsValor(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsVal" [checked]="allPeriodsValor[1]"
        (change)="changePeriodsValor(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsVal" [checked]="allPeriodsValor[2]"
        (change)="changePeriodsValor(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor desconto</label>
      <input class="form-control text-right" placeholder="Valor do desconto" [(ngModel)]="valorDescEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncValorDesc">Todos os funcionários
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValDesc" [checked]="allPeriodsValorDesc[0]"
        (change)="changePeriodsValorDesc(0)">Esse
        período</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValDesc" [checked]="allPeriodsValorDesc[1]"
        (change)="changePeriodsValorDesc(1)">Esse
        período e os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValDesc" [checked]="allPeriodsValorDesc[2]"
        (change)="changePeriodsValorDesc(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor projetado</label>
      <input class="form-control text-right" placeholder="Valor projetado" [(ngModel)]="valorProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncValorProj">Todos os funcionários
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValProj" [checked]="allPeriodsValorProj[0]"
        (change)="changePeriodsValorProj(0)">Esse
        período</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValProj" [checked]="allPeriodsValorProj[1]"
        (change)="changePeriodsValorProj(1)">Esse
        período e os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValProj" [checked]="allPeriodsValorProj[2]"
        (change)="changePeriodsValorProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFuncEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editFuncRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EXCLUIR FUNCIONARIO -->
<p-dialog header="Excluir funcionário" [(visible)]="modalFuncExcluir" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncExclude" [checked]="allPeriodsExclude[0]"
        (change)="changePeriodsExclude(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncExclude" [checked]="allPeriodsExclude[1]"
        (change)="changePeriodsExclude(1)">Esse período eos próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncExclude" [checked]="allPeriodsExclude[2]"
        (change)="changePeriodsExclude(2)">Todos os períodos
      </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFuncExcluir=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-danger" (click)='deleteFuncRelac();'><i class="fas fa-times"></i>
          Excluir</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EXCLUIR CARGO -->
<p-dialog header="Excluir cargo" [(visible)]="modalCargoExcluir" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsCargoExclude" [checked]="allPeriodsExclude[0]"
        (change)="changePeriodsExclude(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsCargoExclude" [checked]="allPeriodsExclude[1]"
        (change)="changePeriodsExclude(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsCargoExclude" [checked]="allPeriodsExclude[2]"
        (change)="changePeriodsExclude(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalCargoExcluir=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-danger" (click)='deleteCargoRelac();'><i class="fas fa-times"></i>
          Excluir</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT FUNCIONARIO COMISSÃO -->
<p-dialog header="Editar funcionário" [(visible)]="modalFuncComEdit" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual mensal (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal (%)" [(ngModel)]="percentMesEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentMes">Todos os funcionários
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentMes" [checked]="allPeriodsPercentMes[0]"
        (change)="changePeriodsPercentMes(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentMes" [checked]="allPeriodsPercentMes[1]"
        (change)="changePeriodsPercentMes(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentMes" [checked]="allPeriodsPercentMes[2]"
        (change)="changePeriodsPercentMes(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual trimestral (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral (%)" [(ngModel)]="percentTrimEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentTrim">Todos os funcionários
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentTrim"
        [checked]="allPeriodsPercentTrim[0]" (change)="changePeriodsPercentTrim(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentTrim"
        [checked]="allPeriodsPercentTrim[1]" (change)="changePeriodsPercentTrim(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentTrim"
        [checked]="allPeriodsPercentTrim[2]" (change)="changePeriodsPercentTrim(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual mensal projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal projetado (%)"
        [(ngModel)]="percentMesProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentMesProj">Todos os funcionários
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentMesProj"
        [checked]="allPeriodsPercentMesProj[0]" (change)="changePeriodsPercentMesProj(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentMesProj"
        [checked]="allPeriodsPercentMesProj[1]" (change)="changePeriodsPercentMesProj(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentMesProj"
        [checked]="allPeriodsPercentMesProj[2]" (change)="changePeriodsPercentMesProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual trimestral projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral projetado (%)"
        [(ngModel)]="percentTrimProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentTrimProj">Todos os funcionários
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentTrimProj"
        [checked]="allPeriodsPercentTrimProj[0]" (change)="changePeriodsPercentTrimProj(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentTrimProj"
        [checked]="allPeriodsPercentTrimProj[1]" (change)="changePeriodsPercentTrimProj(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsFuncPercentTrimProj"
        [checked]="allPeriodsPercentTrimProj[2]" (change)="changePeriodsPercentTrimProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFuncComEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editFuncRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL NOVO CARGO -->
<p-dialog header="Adicionar novo cargo" [(visible)]="modalCargoNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
        <p-checkbox (click)="aplicarNivel()" [(ngModel)]="aplicNivel"></p-checkbox>
      <label style="padding: 0 5px;">Aplicar nível?</label>
    </div>
    <div *ngIf="aplicNivel" class="form-group col-sm-12">
      <label>Níveis</label>
      <p-multiSelect class="type-p-mult" [options]="niveisOpcoes" [(ngModel)]="niveisSelecionados"
        defaultLabel="Selecionar nívei(s)" [filter]="true" placeholder="{0} níveis selecionado(s)"
        selectedItemsLabel="{0} níveis selecionado(s)" (onChange)="getCargos()"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Cargos</label>
      <p-multiSelect class="type-p-mult" [options]="cargoOpcoes" [(ngModel)]="cargosSelecionados"
        defaultLabel="Selecionar cargo(s)" [filter]="true" placeholder="{0} cargo(s) selecionado(s)"
        selectedItemsLabel="{0} cargo(s) selecionado(s)"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Valor</label>
      <input class="form-control text-right" placeholder="Valor do benefício" [(ngModel)]="valorCargoNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Valor desconto</label>
      <input class="form-control text-right" placeholder="Valor do desconto" [(ngModel)]="valorCargoDescNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalCargoNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addCargoRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL NOVO CARGO COMISSÃO -->
<p-dialog header="Adicionar novo cargo" [(visible)]="modalCargoComNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Aplicar nível?</label>
      <p-checkbox (click)="aplicarNivel()" [(ngModel)]="aplicNivel" style="margin-left: 10px;"></p-checkbox>
    </div>
    <div *ngIf="aplicNivel" class="form-group col-sm-12">
      <label>Níveis</label>
      <p-multiSelect class="type-p-mult" [options]="niveisOpcoes" [(ngModel)]="niveisSelecionados"
        defaultLabel="Selecionar nívei(s)" [filter]="true" placeholder="{0} níveis selecionado(s)"
        selectedItemsLabel="{0} níveis selecionado(s)" (onChange)="getCargos()"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Cargos</label>
      <p-multiSelect class="type-p-mult" [options]="cargoOpcoes" [(ngModel)]="cargosSelecionados"
        defaultLabel="Selecionar cargo(s)" [filter]="true" placeholder="{0} cargo(s) selecionado(s)"
        selectedItemsLabel="{0} cargo(s) selecionado(s)"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual mensal (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal (%)" [(ngModel)]="percentMesNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual trimestral (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral (%)" [(ngModel)]="percentTrimNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual mensal projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal (%)" [(ngModel)]="percentMesProjNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Percentual trimestral projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral (%)" [(ngModel)]="percentTrimProjNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalCargoComNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addCargoRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT CARGO -->
<p-dialog header="Editar cargo" [(visible)]="modalCargoEdit" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor</label>
      <input class="form-control text-right" placeholder="Valor do benefício" [(ngModel)]="valorCargoEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allCargoValor">Todos os cargos</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValor" [checked]="allCargoPeriodsValor[0]"
        (change)="changeCargoPeriodsValor(0)">Esse
        período</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValor" [checked]="allCargoPeriodsValor[1]"
        (change)="changeCargoPeriodsValor(1)">Esse
        período e os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValor" [checked]="allCargoPeriodsValor[2]"
        (change)="changeCargoPeriodsValor(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor desconto</label>
      <input class="form-control text-right" placeholder="Valor do desconto" [(ngModel)]="valorCargoDescEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allCargoValorDesc">Todos os cargos</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValorDesc"
        [checked]="allCargoPeriodsValorDesc[0]" (change)="changeCargoPeriodsValorDesc(0)">Esse
        período</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValorDesc"
        [checked]="allCargoPeriodsValorDesc[1]" (change)="changeCargoPeriodsValorDesc(1)">Esse
        período e os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValorDesc"
        [checked]="allCargoPeriodsValorDesc[2]" (change)="changeCargoPeriodsValorDesc(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor projetado</label>
      <input class="form-control text-right" placeholder="Valor projetado" [(ngModel)]="valorCargoProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allCargoValorProj">Todos os cargos</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValorProj"
        [checked]="allCargoPeriodsValorProj[0]" (change)="changeCargoPeriodsValorProj(0)">Esse
        período</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValorProj"
        [checked]="allCargoPeriodsValorProj[1]" (change)="changeCargoPeriodsValorProj(1)">Esse
        período e os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allCargoPeriodsValorProj"
        [checked]="allCargoPeriodsValorProj[2]" (change)="changeCargoPeriodsValorProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalCargoEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editCargoRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT CARGO COMISSÃO -->
<p-dialog header="Editar cargo" [(visible)]="modalCargoComEdit" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual mensal (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal (%)" [(ngModel)]="percentMesEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentMes">Todos os cargos</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentMes" [checked]="allPeriodsPercentMes[0]"
        (change)="changePeriodsPercentMes(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentMes" [checked]="allPeriodsPercentMes[1]"
        (change)="changePeriodsPercentMes(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentMes" [checked]="allPeriodsPercentMes[2]"
        (change)="changePeriodsPercentMes(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual trimestral (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral (%)" [(ngModel)]="percentTrimEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentTrim">Todos os cargos</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentTrim" [checked]="allPeriodsPercentTrim[0]"
        (change)="changePeriodsPercentTrim(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentTrim" [checked]="allPeriodsPercentTrim[1]"
        (change)="changePeriodsPercentTrim(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentTrim" [checked]="allPeriodsPercentTrim[2]"
        (change)="changePeriodsPercentTrim(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual mensal projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual mensal projetado (%)"
        [(ngModel)]="percentMesProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentMesProj">Todos os cargos
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentMesProj"
        [checked]="allPeriodsPercentMesProj[0]" (change)="changePeriodsPercentMesProj(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentMesProj"
        [checked]="allPeriodsPercentMesProj[1]" (change)="changePeriodsPercentMesProj(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentMesProj"
        [checked]="allPeriodsPercentMesProj[2]" (change)="changePeriodsPercentMesProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Percentual trimestral projetado (%)</label>
      <input class="form-control text-right" placeholder="Percentual trimestral projetado (%)"
        [(ngModel)]="percentTrimProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allFuncPercentTrimProj">Todos os cargos
      </mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentTrimProj"
        [checked]="allPeriodsPercentTrimProj[0]" (change)="changePeriodsPercentTrimProj(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPallPeriodsPercentTrimProjercentTrim"
        [checked]="allPeriodsPercentTrimProj[1]" (change)="changePeriodsPercentTrimProj(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsPercentTrimProj"
        [checked]="allPeriodsPercentTrimProj[2]" (change)="changePeriodsPercentTrimProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalCargoComEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editCargoRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL NOVA RELAÇÃO IDADE/NÍVEL -->
<p-dialog header="Adicionar nova faixa etária por nível" [(visible)]="modalFaixaNew" [style]="{width: '400px'}"
  [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Nível</label>
      <p-multiSelect class="type-p-mult" [options]="niveisOpcoes" [(ngModel)]="niveisSelecionados"
        defaultLabel="Selecionar níveis" [filter]="true" placeholder="{0} níveis selecionado"
        selectedItemsLabel="{0} níveis selecionado"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Idade Min</label>
      <input class="form-control text-right" placeholder="Idade minimum" [(ngModel)]="idadeMinNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Idade Max</label>
      <input class="form-control text-right" placeholder="Idade maximum" [(ngModel)]="idadeMaxNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Valor</label>
      <input class="form-control text-right" placeholder="Valor do benefício" [(ngModel)]="valorNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Valor desconto</label>
      <input class="form-control text-right" placeholder="Valor do desconto" [(ngModel)]="valorDescNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)="modalFaixaNew=false;"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addFaixaRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT RELAÇÃO IDADE/NÍVEL -->
<p-dialog header="Editar relação faixa etária por nível" [(visible)]="modalFaixaEdit" [style]="{width: '600px'}"
  [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor</label>
      <input class="form-control text-right" placeholder="Valor do benefício" [(ngModel)]="valorEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allValor">Todos os níveis</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValor" [checked]="allPeriodsValor[0]"
        (change)="changePeriodsValor(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValor" [checked]="allPeriodsValor[1]"
        (change)="changePeriodsValor(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValor" [checked]="allPeriodsValor[2]"
        (change)="changePeriodsValor(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor desconto</label>
      <input class="form-control text-right" placeholder="Valor do desconto do benefício" [(ngModel)]="valorDescEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allValorDesc">Todos os níveis</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValorDesc" [checked]="allPeriodsValorDesc[0]"
        (change)="changePeriodsValorDesc(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValorDesc" [checked]="allPeriodsValorDesc[1]"
        (change)="changePeriodsValorDesc(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValorDesc" [checked]="allPeriodsValorDesc[2]"
        (change)="changePeriodsValorDesc(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Valor projetado</label>
      <input class="form-control text-right" placeholder="Valor projetado do benefício" [(ngModel)]="valorProjEdit">
    </div>
    <div class="form-group col">
      <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="allValorProj">Todos os níveis</mat-slide-toggle>
    </div>
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValorProj" [checked]="allPeriodsValorProj[0]"
        (change)="changePeriodsValorProj(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValorProj" [checked]="allPeriodsValorProj[1]"
        (change)="changePeriodsValorProj(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsValorProj" [checked]="allPeriodsValorProj[2]"
        (change)="changePeriodsValorProj(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFaixaEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editFaixaRelac();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EXCLUIR FAIXA ETÁRIA -->
<p-dialog header="Excluir faixa etária" [(visible)]="modalFaixaExcluir" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label>Aplicar para:</label>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsIdadeExclude" [checked]="allPeriodsExclude[0]"
        (change)="changePeriodsExclude(0)">Esse período
      </mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsIdadeExclude" [checked]="allPeriodsExclude[1]"
        (change)="changePeriodsExclude(1)">Esse período e
        os próximos</mat-radio-button>
      <mat-radio-button class="d-block radio-normal" name="allPeriodsIdadeExclude" [checked]="allPeriodsExclude[2]"
        (change)="changePeriodsExclude(2)">Todos os
        períodos </mat-radio-button>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFaixaExcluir=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-danger" (click)='deleteFaixaRelac();'><i class="fas fa-times"></i>
          Excluir</button>
      </div>
    </div>
  </p-footer>
</p-dialog>