<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-11">

    </div>
  </div>
  <mat-card-content>
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title mb-4"><i class="fas fa-file-download"> </i> Períodos</h3>
        </div>
      </div>
      <p-table class="table-system" [value]="ecdList" selectionMode="single">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <span class="overflow-ellipse text-center">
                Data Inicial
              </span>
            </th>
            <th>
              <span class="overflow-ellipse text-center">
                Data Final
              </span>
            </th>
            <th *ngIf="ecdList && ecdList.length > 0">
              <span class="overflow-ellipse text-center">
                Apurado
              </span>
            </th>

          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td>
              <span class="overflow-ellipse text-center">
                {{rowData.dtIni | timezone | date:'dd/MM/yyyy'}}
              </span>
            </td>
            <td>
              <span class="overflow-ellipse text-center">
                {{rowData.dtFin | timezone | date:'dd/MM/yyyy'}}
              </span>
            </td>
            <td>
              <span class="overflow-ellipse text-center">
                <p-checkbox [binary]="true" [disabled]="rowData.homologado" class="checkBox"
                  [(ngModel)]="rowData.apurado" (click)="onClick(rowData, 2, rowData.homologado)"
                  (keyup)="onClick(rowData, 2, rowData.homologado, $event)">
                </p-checkbox>
              </span>
            </td>

          </tr>
        </ng-template>

        <ng-template pTemplate=" emptymessage">
          <tr>
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhuma Aprovação Encontrada!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>

  <p-dialog [(visible)]="modalLog" [modal]="true" appendTo="body" width="800" styleClass="modal-initial"
    [responsive]="true" [closable]="true">
    <div class="row">
      <div class="col-sm-12">
        <ul class="list-group list-group-flush list-log">
          <li class="list-group-item" *ngFor="let log of logAdvertencia">
            <i class="fas fa-exclamation"></i> {{log.detail }}
          </li>
        </ul>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalLog=false'><i class="fas fa-undo"></i>
        Fechar</button>
    </p-footer>
  </p-dialog>
</mat-card>
