<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <mat-tab label="Aprovadores">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> <i class="fas fa-users"></i> Aprovadores Fatura</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content">
          <div class="row mt-3">
            <div class="col-sm-6">
              <div class="conteudo m-0">
                <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Aprovadores" [source]="listaUsuarios"
                  [target]="listaAprovadores" [dragdrop]="true" [responsive]="true" filterBy="nome"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Usuários por Aprovador">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> <i class="fas fa-user"></i> Aprovador </h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content">
          <div class="form-row">
            <div class="form-group col-sm-3">
                <p-dropdown class="type-p-dropdown" [filter]="false" [options]="aprovadoresTecnicos"
                placeholder="Selecione o Aprovador" [(ngModel)]="aprovId" (onChange)="buscarUsuariosAprovador()"></p-dropdown>
            </div>
          </div>
            <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-users"></i> Usuários</h3>
            </div>
            </div>
            <div class="row">
            <div class="col-sm-6">
              <div class="conteudo m-0">
                <p-pickList *ngIf="picklistState2" sourceHeader="Disponíveis" targetHeader="Usuários Relacionados" [source]="listaUsuariosRelacionar"
                  [target]="listaUsuariosRelacionados" [dragdrop]="true" [responsive]="true" filterBy="nome"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

</mat-card>
