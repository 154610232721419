<app-data-table
                mainTitle="Planos Gerenciais"
                singleTitle="Plano Gerencial"
                routerPath="/planogerencial"
                entityPath="/planogerencial"
                [entity]="planogerencial"
                [entityFilter]="entityFilter"
                keyField="id"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>