<div class="dialog">
  <h2 mat-dialog-title>
    Editando {{ (this.selectedLength > 1 ? this.mainTitle : this.singleTitle).toLowerCase() }}
  </h2>

  <mat-dialog-content>
    <label *ngIf="!_control">Selecione o campo a alterar</label>
    <mat-select placeholder="Campo a alterar" floatPlaceholder="always" required [(ngModel)]="_control">
      <mat-option *ngFor="let control of controls" [value]="control">
        {{ control.label }}
      </mat-option>
    </mat-select>

    <form [formGroup]="formGroup" novalidate autocomplete="off">
      <div *ngFor="let control of controls">
        <div *ngIf="_control && _control.key === control.key" [ngSwitch]="control.type">
          <mat-select *ngSwitchCase="'select'" placeholder="Valor" floatPlaceholder="always"
            [required]="control.required" [formControlName]="control.key">
            <mat-option [value]="null">(não alterar)</mat-option>
            <mat-option *ngFor="let option of control.options.values" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>

          <div *ngSwitchCase="'checkbox'">
            <p-checkbox #matCheckBox [formControlName]="control.key" indeterminate="true">{{ control.label }}
            </p-checkbox>
            <br><br>
          </div>

          <mat-form-field *ngSwitchDefault floatLabel="always">
            <input matInput #matInput dividerColor="primary" [formControlName]="control.key" placeholder="Valor"
              (ngModelChange)="defaultChange(control, matInput.value)" [type]="control.type"
              [required]="control.required" autocomplete="off" [minlength]="control.minLength"
              [maxlength]="control.maxLength" [step]="control.step" [min]="control.min" [max]="control.max" />
            <!-- TODO: Implement @ angular2-material => [pattern]="control.pattern" -->
            <mat-hint *ngIf="control.hint || (control.minLength + 0) > 0 || (control.maxLength + 0) > 0" align="end">
              {{ control.hint ||
              (matInput.value ? matInput.value.length : 0) + ' / ' +
              (control.minLength + 0 > 0 ? control.minLength + (control.maxLength + 0 > 0 ? ' ..' : '+') : '') +
              (control.maxLength + 0 > 0 ? (control.minLength + 0 > 0 ? ' ' : '') + control.maxLength : '') }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="alert alert-warning p-2" style="font-size: 13px;" role="alert" *ngIf="_control && !changed">
      <i class="fas fa-warning"></i> Alterar o valor do campo acima afetará cada registro selecionado.
    </div>
    <div *ngIf="_control && changed">
      <p style="font-size: 13px;">Cada registro selecionado terá o valor do campo <strong>"{{ _control.label
          }}"</strong> atualizado para <strong>"{{ _control.type === 'checkbox' ? (displayValue ? 'Sim' : 'Não') :
          displayValue }}"</strong></p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button *ngIf="!_control" [mat-dialog-close]="false" type="button" class="btn btn-secondary"><i
        class="fas fa-undo"></i> Fechar</button>
    <button *ngIf="_control" (click)="cancel()" type="button" class="btn btn-secondary"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button *ngIf="_control && changed" [mat-dialog-close]="true" type="button" class="btn btn-primary"><i
        class="fas fa-check"></i> Salvar</button>
  </mat-dialog-actions>
</div>