<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content" style="padding: 1rem !important; margin-top: -25px;">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta">

            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroFeature" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple" [style]="{'width':'100%'}"
              immutable="false" (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>

                  <th class="text-center" style="width: 200px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'authFeature.authApp.id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Aplicação <p-sortIcon [field]="'authFeature.authApp.id'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesFiltrarAplicacao" 
                    (onChange)="dt.filter($event.value, 'authFeature.authApp.id', 'equals')"></p-dropdown>
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'authFeature.id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Funcionalidade <p-sortIcon [field]="'authFeature.id'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesFiltrarFuncionalidades" 
                    (onChange)="dt.filter($event.value, 'authFeature.id', 'equals')"></p-dropdown>
                  </th>

                  <th class="text-left" style="width: 400px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'path'">
                      <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                        URL <p-sortIcon [field]="'path'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'path', 'contains')">
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'allowGet'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Leitura <p-sortIcon [field]="'allowGet'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesLeitura" 
                    (onChange)="dt.filter($event.value, 'allowGet', 'equals')"></p-dropdown>
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'allowPut'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Alteração <p-sortIcon [field]="'allowPut'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesAlteracao" 
                    (onChange)="dt.filter($event.value, 'allowPut', 'equals')"></p-dropdown>
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'allowPost'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Inclusão <p-sortIcon [field]="'allowPost'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesInclusao" 
                    (onChange)="dt.filter($event.value, 'allowPost', 'equals')"></p-dropdown>
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'allowDelete'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Exclusão <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesExclusao" 
                    (onChange)="dt.filter($event.value, 'allowDelete', 'equals')"></p-dropdown>
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'active'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Ativa <p-sortIcon [field]="'active'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesAtiva" 
                    (onChange)="dt.filter($event.value, 'active', 'equals')"></p-dropdown>
                  </th>
                  
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" [id]="'checkboxContainer'+rowData.id">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-left" style="width: 50px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.authFeature.authApp.name}}">{{rowData.authFeature != null? rowData.authFeature.authApp.name: ''}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.authFeature.name}}">{{rowData.authFeature !=null? rowData.authFeature.name: ''}}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 900px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.path}}">{{rowData.path}}</span>
                    </span>
                  </td>

                  <td class="text-center" style="width: 10%;">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.allowGet" [binary]="true"
                        inputId="allowGet" [disabled]="true"></p-checkbox>
                    </td>

                    <td class="text-center" style="width: 10%;">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.allowPut" [binary]="true"
                        inputId="allowPut" [disabled]="true"></p-checkbox>
                    </td>
                    
                    <td class="text-center" style="width: 10%;">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.allowPost" [binary]="true"
                        inputId="allowPost" [disabled]="true"></p-checkbox>
                    </td>

                    <td class="text-center" style="width: 10%;">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.allowDelete" [binary]="true"
                        inputId="allowDelete" [disabled]="true"></p-checkbox>
                    </td>

                    <td class="text-center" style="width: 10%;">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.active" [binary]="true"
                        inputId="active" [disabled]="true"></p-checkbox>
                    </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
          <div class="box-content">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Aplicação</label>
                  <p-dropdown class="type-p-dropdown" [options]="opcoesAplicacao" [(ngModel)]="aplicacao">
                  </p-dropdown>
                </div>
              </div>

              <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Funcionalidade</label>
                    <p-dropdown class="type-p-dropdown" [options]="opcoesFuncionalidades" [(ngModel)]="funcionalidade">
                    </p-dropdown>
                  </div>
                </div>
<!-- 
              <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Funcionalidade (CONCILIAÇÃO CONTÁBIL)</label>
                    <p-dropdown class="type-p-dropdown" [options]="opcoesFuncionalidadesContabil" [(ngModel)]="funcionalidade">
                    </p-dropdown>
                  </div>
              </div>
              
              <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Funcionalidade (AUDITOR DIGITAL)</label>
                    <p-dropdown class="type-p-dropdown" [options]="opcoesFuncionalidadesAuditorDigital" [(ngModel)]="funcionalidade">
                    </p-dropdown>
                  </div>
              </div> -->
    
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>URL<span class="obrigatorio">*</span></label>
                  <input class="form-control" id="input" type="text" pInputText [(ngModel)]="url">
                </div>
              </div>
    
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="leitura">Leitura</mat-slide-toggle>
                </div>
              </div>
    
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="alteracao">Alteração</mat-slide-toggle>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="inclusao">Inclusão</mat-slide-toggle>
                </div>
              </div>
    
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="exclusao">Exclusão</mat-slide-toggle>
                </div>
              </div>
    
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="ativa">Ativa</mat-slide-toggle>
                </div>
              </div>
            </div>      
      </div>
    </div>
  </mat-card-content>
</mat-card>

