<div>
  <div class="col-sm-12" style="padding: 0 !important" *ngIf="!loadingConfigPattern">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Usuário <span class="obrigatorio" style="line-height: 0;">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.usuario" />
      </div>
      <div class="form-group col-sm-6">
        <label>Chave secreta <span class="obrigatorio" style="line-height: 0;">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.senha" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Cliente padrão para venda <span class="obrigatorio">*</span></label>
        <div class="participant-container">
          <p> {{ codigoCliente ? codigoCliente + ' - ' + nomeFantasiaCliente : 'NÃO INFORMADO' }} </p>
          <span class="material-icons" (click)="loadCliente(1)" matTooltip="Alterar Cliente">edit</span>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label>Taxa de serviço <span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
          placeholder="Selecione" [options]="optItens" emptyMessage="Nenhum resultado encontrado!"
          [dropdownIcon]="loadingIconOptItens ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
          [(ngModel)]="config.taxaServicoId"></p-dropdown>
      </div>
    </div>
  </div>
  <div class="col-sm-12 loading-content" style="padding: 0 !important" *ngIf="loadingConfigPattern">
    <p><span class="material-icons rotate">hourglass_empty</span> <b>Carregando informações da conta ...</b></p>
  </div>
</div>

<app-modal-participante [(isVisible)]="modalParticipante" (onSelectParticipant)="salvarCliente($event)"
  [participanteTipoId]="tipoParticipanteId">
</app-modal-participante>