<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Dashboard RH
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatarDataExtenso()"></small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row" *ngIf="showVersionOptions">
    <div class="col">
      <div class="card-deck card-custom">
        <div class="card" *ngFor="let versao of versionsList">
          <div class="card-body" style="cursor: pointer;"
            (click)="getFiltersInformations(versao.versao_id,versao.podeconsolidar,true,false,true)">
            <span class="icon-card"><i class="fas fa-chart-bar"></i></span>
            <span class="info-card">
              <h5 class="card-title" matTooltipPosition="above" style="width: 150px">
                <span class="overflow-ellipse">{{ versao.versao_descr }}</span>
              </h5>
              <p class="card-text overflow-ellipse" matTooltip="{{ versao.tipo }} - {{ versao.descricao }}"
                matTooltipPosition="above" style="max-width: 150px; margin-bottom: 2px; display: inline-block;">
                <small class="text-muted">{{ versao.tipo }} - {{ versao.versao_descr }}</small>
              </p>
              <p class="card-text overflow-ellipse" style="width: 150px">
                <small class="text-muted">{{ versao.status }}</small>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!showVersionOptions">
    <div *ngIf="allowsGroup" class="form-row">
      <div class="form-group col-sm-2">
        <label>Empresas</label>
        <p-multiSelect class="type-p-mult" [options]="companyOptions" [(ngModel)]="toFilterCompany"
          defaultLabel="Selecione empresas" selectedItemsLabel="{0} empresas selecionadas" [maxSelectedLabels]="1"
          (onChange)="onCompanyChange();">
        </p-multiSelect>
      </div>
      <div *ngIf="[1,2,3].includes(levels)" class="form-group col-sm-2">
        <label>{{keysInfo['1'][0]}}</label>
        <p-multiSelect class="type-p-mult" appendTo="body" [filter]="true"
          defaultLabel="Selecione o {{keysInfo['1'][0]}}" [options]="aboveOptions.key1"
          [(ngModel)]="filtersData[keysInfo['1'][1]]"
          (onChange)="[2,3].includes(levels) ? setAboveFilters(keysInfo['2'][1], 1) : null"
          selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div *ngIf="[2,3].includes(levels)" class="form-group col-sm-2">
        <label>{{keysInfo['2'][0]}}</label>
        <p-multiSelect class="type-p-mult" appendTo="body" [filter]="true"
          defaultLabel="Selecione o {{keysInfo['2'][0]}}" [options]="aboveOptions.key2"
          [(ngModel)]="filtersData[keysInfo['2'][1]]"
          (onChange)="levels===3 ? setAboveFilters(keysInfo['3'][1], 2) : null"
          selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div *ngIf="levels===3" class="form-group col-sm-2">
        <label>{{keysInfo['3'][0]}}</label>
        <p-multiSelect class="type-p-mult" appendTo="body" [filter]="true"
          defaultLabel="Selecione o {{keysInfo['3'][0]}}" [options]="aboveOptions.key3"
          [(ngModel)]="filtersData[keysInfo['3'][1]]" selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-group col-sm-2" style="display: flex;align-items: flex-end;">
        <button type="button" class="btn btn-info" matTooltip="Atualizar" matTooltipPosition="above" (click)="update()">
          <span class="material-icons" style="font-size: 13px;">cached</span>
        </button>
      </div>

    </div>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullTable ? 'box-fullscreen' : ''">
          <div class="headerBox">
            <label class="titleCard">Dashboard</label>
            <div class="d-flex">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
              <button type="button" class="btnCustom-default" matTooltip="Fullscreen" matTooltipPosition="above"
                (click)="changeState('screen')">
                <span *ngIf="!fullTable" class="material-icons full-icon">fullscreen</span>
                <span *ngIf="fullTable" class="material-icons full-icon">fullscreen_exit</span>
              </button>
            </div>
          </div>
          <div class="box-content">
            <p-table #dt class="table-system" [value]="tableValues" [rows]="20" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="[10, 15, 20, 50, 100]" [scrollable]="true"
              [style]="{ width: '100%' }" [frozenWidth]="allowsGroup?'550px':'450px'"
              (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
              (onHeaderCheckboxToggle)="onSelectAll($event)" (onFilter)="onFilter($event)">

              <ng-template pTemplate="frozenheader">
                <tr>
                  <th class="text-center" style="width: 40px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th *ngIf="selectedCompany.length>1" class="text-left" style="width: 100px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="'empresa_id'">
                      empresa <p-sortIcon [field]="'empresa_id'"></p-sortIcon>
                    </span>
                    <p-multiSelect class="type-p-mult filter" appendTo="body" panelStyleClass="mult-table"
                      [options]="selectedCompanyOptions" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'empresa_id', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 60px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="'matricula'">
                      matricula <p-sortIcon [field]="'matricula'"></p-sortIcon>
                    </span>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'matricula', 'contains')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-left" style="width: 100px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="'nome'">
                      nome <p-sortIcon [field]="'nome'"></p-sortIcon>
                    </span>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'nome', 'contains')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-left" style="width: 100px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="'cargo_atu_id'">
                      função <p-sortIcon [field]="'cargo_atu_id'"></p-sortIcon>
                    </span>
                    <p-multiSelect class="type-p-mult filter" appendTo="body" panelStyleClass="mult-table"
                      [options]="functionOptions" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'cargo_atu_id', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 100px;border-right:0.2rem solid #ababab">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="'nivel_atu_id'">
                      nível <p-sortIcon [field]="'nivel_atu_id'"></p-sortIcon>
                    </span>
                    <p-multiSelect class="type-p-mult filter" appendTo="body" panelStyleClass="mult-table"
                      [options]="levelOptions" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, 'nivel_atu_id', 'in')"></p-multiSelect>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="frozenbody" let-rowData>
                <tr [pSelectableRow]="rowData" class="line">
                  <td class="text-center" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td *ngIf="selectedCompany.length>1" style="width: 100px;" pEditableColumn>
                    <span class="overflow-ellipse text-left">{{convertId(rowData.empresa_id,'company')}}</span>
                  </td>
                  <td style="width: 60px;">
                    <span class="overflow-ellipse text-left">{{rowData.matricula}}</span>
                  </td>
                  <td style="width: 100px;" pEditableColumn>
                    <p-cellEditor *ngIf="rowData.isEdit">
                      <span class="overflow-ellipse text-left">
                        <ng-template pTemplate="input">
                          <input type="text" [(ngModel)]="rowData.nome">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{rowData.nome}}
                        </ng-template>
                      </span>
                    </p-cellEditor>
                    <span [matTooltip]="rowData.nome" matTooltipPosition="above" class="overflow-ellipse text-left"
                      *ngIf="!rowData.isEdit">
                      {{rowData.nome}}
                    </span>
                  </td>
                  <td style="width: 100px;" pEditableColumn>
                    <span class="overflow-ellipse text-left" matTooltip="{{getTooltip(rowData,'cargo')}}"
                      matTooltipPosition="above"
                      matTooltipClass="my-tooltip">{{convertId(rowData.cargo_atu_id,'cargo')}}</span>
                  </td>
                  <td style="width: 100px;border-right:0.2rem solid #ababab" pEditableColumn>
                    <span class="overflow-ellipse text-left" matTooltip="{{getTooltip(rowData,'nivel')}}"
                      matTooltipPosition="above"
                      matTooltipClass="my-tooltip">{{convertId(rowData.nivel_atu_id,'nivel')}}</span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="frozenfooter">
                <tr>
                  <td class="text-center" [ngStyle]="{'width':selectedCompany.length>1?'500px':'450px'}"
                    style="border-right:0.2rem solid #ababab">TOTAL:</td>
                </tr>
              </ng-template>

              <ng-template pTemplate="header">
                <tr>
                  <th *ngIf="[1,2,3].includes(levels)" class="text-left" style="width: 150px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="keysInfo['1'][1]">
                      {{keysInfo['1'][0]}} <p-sortIcon [field]="keysInfo['1'][1]"></p-sortIcon>
                    </span>
                    <p-multiSelect class="type-p-mult filter" appendTo="body" panelStyleClass="mult-table"
                      [options]="optionsKey1" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, keysInfo['1'][1], 'in')">
                    </p-multiSelect>
                  </th>
                  <th *ngIf="[2,3].includes(levels)" class="text-left" style="width: 150px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="keysInfo['2'][1]">
                      {{keysInfo['2'][0]}} <p-sortIcon [field]="keysInfo['2'][1]"></p-sortIcon>
                    </span>
                    <p-multiSelect class="type-p-mult filter" appendTo="body" panelStyleClass="mult-table"
                      [options]="optionsKey2" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, keysInfo['2'][1], 'in')">
                    </p-multiSelect>
                  </th>
                  <th *ngIf="levels===3" class="text-left" style="width: 150px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="keysInfo['3'][1]">
                      {{keysInfo['3'][0]}} <p-sortIcon [field]="keysInfo['3'][1]"></p-sortIcon>
                    </span>
                    <p-multiSelect class="type-p-mult filter" appendTo="body" panelStyleClass="mult-table"
                      [options]="optionsKey3" defaultLabel="Selecione" [filter]="true"
                      (onChange)="dt.filter($event.value, keysInfo['3'][1], 'in')">
                    </p-multiSelect>
                  </th>
                  <th *ngFor="let month of months" class="text-center" style="width: 100px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="month[0]">
                      {{month[1]}} <p-sortIcon [field]="month[0]"></p-sortIcon>
                    </span>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, month[0], 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                  <th style="width: 150px;">
                    <span class="overflow-ellipse text-center" [pSortableColumn]="'total'">
                      total <p-sortIcon [field]="'total'"></p-sortIcon>
                    </span>
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'total', 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-center" style="width: 60px;">
                    <span class="overflow-ellipse text-center">ação</span>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" class="line">
                  <td *ngIf="[1,2,3].includes(levels)" style="width: 150px;" pEditableColumn>
                    <p-cellEditor *ngIf="rowData.isEdit">
                      <span class="overflow-ellipse text-left">
                        <ng-template pTemplate="input">
                          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                            placeholder="Selecione a area"
                            [options]="getAddOptions(this.keysInfo['1'][1],rowData.empresa_id)"
                            [(ngModel)]="rowData[keysInfo['1'][1]]">
                          </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{convertId(rowData[keysInfo['1'][1]],keysInfo['1'][1])}}
                        </ng-template>
                      </span>
                    </p-cellEditor>
                    <span *ngIf="!rowData.isEdit" [matTooltip]="convertId(rowData[keysInfo['1'][1]],keysInfo['1'][1])"
                      matTooltipPosition="above"
                      class="overflow-ellipse text-left">{{convertId(rowData[keysInfo['1'][1]],keysInfo['1'][1])}}
                    </span>
                  </td>
                  <td *ngIf="[2,3].includes(levels)" style="width: 150px;" pEditableColumn>
                    <p-cellEditor *ngIf="rowData.isEdit">
                      <span class="overflow-ellipse text-left">
                        <ng-template pTemplate="input">
                          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                            placeholder="Selecione o centro de custo"
                            [options]="getAddOptions(this.keysInfo['2'][1],rowData.empresa_id,rowData[keysInfo['1'][1]],this.keysInfo['1'][1])"
                            [(ngModel)]="rowData[keysInfo['2'][1]]">
                          </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{convertId(rowData[keysInfo['2'][1]],keysInfo['2'][1])}}
                        </ng-template>
                      </span>
                    </p-cellEditor>
                    <span *ngIf="!rowData.isEdit" [matTooltip]="convertId(rowData[keysInfo['2'][1]],keysInfo['2'][1])"
                      matTooltipPosition="above"
                      class="overflow-ellipse text-left">{{convertId(rowData[keysInfo['2'][1]],keysInfo['2'][1])}}
                    </span>
                  </td>
                  <td *ngIf="levels===3" style="width: 150px;" pEditableColumn>
                    <p-cellEditor *ngIf="rowData.isEdit">
                      <span class="overflow-ellipse text-left">
                        <ng-template pTemplate="input">
                          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                            placeholder="Selecione o projeto"
                            [options]="getAddOptions(this.keysInfo['3'][1],rowData.empresa_id,rowData[keysInfo['2'][1]],this.keysInfo['2  '][1])"
                            [(ngModel)]="rowData[keysInfo['3'][1]]">
                          </p-dropdown>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{convertId(rowData[keysInfo['3'][1]],keysInfo['3'][1])}}
                        </ng-template>
                      </span>
                    </p-cellEditor>
                    <span *ngIf="!rowData.isEdit" [matTooltip]="convertId(rowData[keysInfo['3'][1]],keysInfo['3'][1])"
                      matTooltipPosition="above"
                      class="overflow-ellipse text-left">{{convertId(rowData[keysInfo['3'][1]],keysInfo['3'][1])}}</span>
                  </td>
                  <td *ngFor="let month of months" style="width: 100px;">
                    <span class="overflow-ellipse text-right" [matTooltip]="rowData[month[0]] | number: '1.0-0':'pt-BR'"
                      matTooltipPosition="above">{{rowData[month[0]] | number: "1.0-0":"pt-BR"}}</span>
                  </td>
                  <td style="width: 150px;">
                    <span class="overflow-ellipse text-right" [matTooltip]="rowData.total | number: '1.0-0':'pt-BR'"
                      matTooltipPosition="above">{{rowData.total | number: "1.0-0":"pt-BR"}}</span>
                  </td>
                  <td style="width: 60px;padding: 0 !important;">
                    <div class="actionButton" *ngIf="!rowData.isEdit">
                      <button (click)="changeEditionState(rowData.id)" type="button" style="padding: 0 !important;"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px">edit</span>
                      </button>
                      <button (click)="removeFromMain(rowData.id)" type="button" style="padding: 0 !important;"
                        class="btn btn-xs btn-link btn-full" matTooltip="Remover" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px">delete</span>
                      </button>
                    </div>
                    <div class="actionButton" *ngIf="rowData.isEdit">
                      <button (click)="changeEditionState(rowData.id)" type="button" style="padding: 0 !important;"
                        class="btn btn-xs btn-link btn-full" matTooltip="Confirmar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px">check</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td *ngIf="levels!=0" [ngStyle]="{'width': levels*150+'px'}">
                  </td>
                  <td *ngFor="let month of months" style="width: 100px;">
                    <span class="overflow-ellipse text-right">{{calcFooterTotal(month[0]) | number:
                      "1.0-0":"pt-BR"}}</span>
                  </td>
                  <td style="width: 150px;">
                    <span class="overflow-ellipse text-right">{{calcFooterTotal('total') | number:
                      "1.0-0":"pt-BR"}}</span>
                  </td>
                  <td style="width: 60px;">
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Inclusão" [(visible)]="modalInclusion" width="1300" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">

  <div class="headerBoxModal">
    <div class="d-flex">
      <button type="button" class="btnCustom-default" (click)="addInclusionLine()" matTooltip="Inclusão"
        matTooltipPosition="above">
        <span class="material-icons">add</span>
        <div>Adicionar</div>
      </button>
    </div>
  </div>

  <div class="col-sm-12">
    <p-table class="table-system" [value]="tableInclusion" selectionMode="single" [style]="{ width: '100%' }">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" style="width: 40px;">
            qtd
          </th>
          <th *ngIf="selectedCompany.length>1" class="text-center" style="width: 100px;">
            <span class="overflow-ellipse text-center">empresa</span>
          </th>
          <th class="text-center" style="width: 100px;">
            <span class="overflow-ellipse text-center">nome</span>
          </th>
          <th class="text-center" style="width: 100px;">
            <span class="overflow-ellipse text-center">função</span>
          </th>
          <th class="text-center" style="width: 100px;">
            <span class="overflow-ellipse text-center">nível</span>
          </th>
          <th *ngIf="[1,2,3].includes(levels)" class="text-left" style="width: 100px;">
            <span class="overflow-ellipse text-center">{{keysInfo['1'][0]}}</span>
          </th>
          <th *ngIf="[2,3].includes(levels)" class="text-left" style="width: 100px;">
            <span class="overflow-ellipse text-center">{{keysInfo['2'][0]}}</span>
          </th>
          <th *ngIf="levels===3" class="text-left" style="width: 100px;">
            <span class="overflow-ellipse text-center">{{keysInfo['3'][0]}}</span>

          </th>
          <th class="text-center" style="width: 100px;">
            <span class="overflow-ellipse text-center"> data ini</span>

          </th>
          <th class="text-center" style="width: 100px;">
            <span class="overflow-ellipse text-center"> data fin</span>

          </th>
          <th class="text-center" style="width: 40px;">
            <span class="overflow-ellipse text-center">ação</span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td style="width: 40px;">
            <span class="overflow-ellipse text-center">
              <input type="number" class="text-center form-control" [(ngModel)]="rowData.qtd" />
            </span>
          </td>
          <td *ngIf="selectedCompany.length>1" style="width: 100px;">
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione a empresa"
              [options]="selectedCompanyOptions" [(ngModel)]="rowData.empresa_id">
            </p-dropdown>
          </td>
          <td style="width: 100px;">
            <span class="overflow-ellipse text-center">
              <input type="text" class="text-left form-control" [(ngModel)]="rowData.nome" />
            </span>
          </td>
          <td style="width: 100px;">
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione a função"
              [options]="getKeyOptions('function',rowData.empresa_id)" [(ngModel)]="rowData.cargo_atu_id">
            </p-dropdown>
          </td>
          <td style="width: 100px;">
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione o nivel"
              [options]="getKeyOptions('level',rowData.empresa_id)" [(ngModel)]="rowData.nivel_atu_id">
            </p-dropdown>
          </td>
          <td *ngIf="[1,2,3].includes(levels)" style="width: 100px;">
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="getAddOptions(this.keysInfo['1'][1],rowData.empresa_id)" [(ngModel)]="rowData.key1">
            </p-dropdown>
          </td>
          <td *ngIf="[2,3].includes(levels)" style="width: 100px;">
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="getAddOptions(this.keysInfo['2'][1],rowData.empresa_id,rowData.key1,this.keysInfo['1'][1])"
              [(ngModel)]="rowData.key2">
            </p-dropdown>
          </td>
          <td *ngIf="levels===3" style="width: 100px;">
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="getAddOptions(this.keysInfo['3'][1],rowData.empresa_id,rowData.key2,this.keysInfo['2'][1])"
              [(ngModel)]="rowData.key3">
            </p-dropdown>
          </td>
          <td style="width: 100px;">
            <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="rowData.data_ini"
              [monthNavigator]="true" [locale]="pt_BR" [yearNavigator]="true" yearRange="2010:2030"
              (onInput)="onInputDate($event)">
            </p-calendar>
          </td>
          <td style="width: 100px;">
            <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="rowData.data_fin"
              [monthNavigator]="true" [locale]="pt_BR" [yearNavigator]="true" yearRange="2010:2030"
              (onInput)="onInputDate($event)">
            </p-calendar>
          </td>
          <td style="width: 40px;">
            <div class="actionButton">
              <button (click)="removeInclusionLine(rowData.lineId)" type="button" style="padding: 0 !important;"
                class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha" matTooltipPosition="above">
                <span class="material-icons" style="font-size: 18px">delete</span>
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="9" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalInclusion=false;">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" (click)="saveInclusion()" class="btn btn-primary">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Edição" [(visible)]="modalEdition" width="900" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">

  <div class="headerBoxModal">
    <div class="d-flex align-items-center">
      <button type="button" class="btnCustom-default" (click)="addEditionLine()" matTooltip="Inclusão"
        matTooltipPosition="above">
        <span class="material-icons">add</span>
        <div>Adicionar</div>
      </button>
    </div>
  </div>

  <div class="col-sm-12">
    <p-table class="table-system" [value]="tableEdition" selectionMode="single" [style]="{ width: '100%' }">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-left">
            Coluna
          </th>
          <th class="text-left">
            Valor
          </th>
          <th class="text-left">
            Data
          </th>
          <th class="text-center" style="width: 50px;">
            Ação
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <span class="overflow-ellipse text-left">
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione a coluna"
                [options]="editionColumn" [(ngModel)]="rowData.column"
                (onChange)="changeColumn(rowData.column, rowData.lineId)">
              </p-dropdown>
            </span>
          </td>
          <td>
            <span class="overflow-ellipse text-left">
              <p-dropdown *ngIf="rowData.columnDropdown" appendTo="body" class="type-p-dropdown" [filter]="true"
                placeholder="Selecione" [options]="getOptions(rowData.column)" [(ngModel)]="rowData.value">
              </p-dropdown>
              <p-calendar *ngIf="rowData.columnCalendar" appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="rowData.value" [monthNavigator]="true" [locale]="pt_BR" [yearNavigator]="true"
                yearRange="2010:2030" (onInput)="onInputDate($event)">
              </p-calendar>
              <input *ngIf="rowData.columnWage" type="number" class="form-control" [(ngModel)]="rowData.value">
              <input *ngIf="rowData.columnText" type="text" class="form-control" [(ngModel)]="rowData.value">
            </span>
          </td>
          <td>
            <span class="overflow-ellipse text-left">
              <p-calendar *ngIf="rowData.startDate" appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="rowData.dt_reajuste" [monthNavigator]="true" [locale]="pt_BR" [yearNavigator]="true"
                yearRange="2010:2030" (onInput)="onInputDate($event)">
              </p-calendar>

              <div *ngIf="rowData.rescisao" class="d-flex">
                <p-checkbox value="rcs" [(ngModel)]="rowData.demissao" [binary]="true" inputId="rcs" label="Rescisão"
                  class="checkBox"></p-checkbox>
                <input *ngIf="rowData.demissao && rowData.rescisao" type="number" class="form-control form-control-sm"
                  [(ngModel)]="rowData.valor_rescisao">
              </div>
            </span>
          </td>
          <td style="width: 50px;">
            <div class="actionButton">
              <button (click)="removeEditionLine(rowData.lineId)" type="button" style="padding: 0 !important;"
                class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha" matTooltipPosition="above">
                <span class="material-icons" style="font-size: 18px">delete</span>
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="4" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalEdition=false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="saveEdition()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Filtro" [(visible)]="modalFilter" width="600" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">

  <div class="form-row" style="flex-direction: column;align-items: center;justify-content: center;">
    <div *ngIf="[1,2,3].includes(levels)" class="form-group col-sm-12">
      <label>{{keysInfo['1'][0]}}</label>
      <p-multiSelect class="type-p-mult" appendTo="body" [filter]="true" defaultLabel="Selecione o {{keysInfo['1'][0]}}"
        [options]="aboveOptions.key1" [(ngModel)]="filtersData[keysInfo['1'][1]]"
        (onChange)="[2,3].includes(levels) ? setAboveFilters(keysInfo['2'][1], 1) : null"
        selectedItemsLabel="{0} itens selecionados">
      </p-multiSelect>
    </div>
    <div *ngIf="[2,3].includes(levels)" class="form-group col-sm-12">
      <label>{{keysInfo['2'][0]}}</label>
      <p-multiSelect class="type-p-mult" appendTo="body" [filter]="true" defaultLabel="Selecione o {{keysInfo['2'][0]}}"
        [options]="aboveOptions.key2" [(ngModel)]="filtersData[keysInfo['2'][1]]"
        (onChange)="levels===3 ? setAboveFilters(keysInfo['3'][1], 2) : null"
        selectedItemsLabel="{0} itens selecionados">
      </p-multiSelect>
    </div>
    <div *ngIf="levels===3" class="form-group col-sm-12">
      <label>{{keysInfo['3'][0]}}</label>
      <p-multiSelect class="type-p-mult" appendTo="body" [filter]="true" defaultLabel="Selecione o {{keysInfo['3'][0]}}"
        [options]="aboveOptions.key3" [(ngModel)]="filtersData[keysInfo['3'][1]]"
        selectedItemsLabel="{0} itens selecionados">
      </p-multiSelect>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalFilter=false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary"
      (click)="getFiltersInformations(selectedVersionId, allowsGroup, false, true, false)">
      <i class="fas fa-check"></i> Filtrar
    </button>
  </p-footer>
</p-dialog>