<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{mainTitle}}
    <mat-card-subtitle>
      {{singleTitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="div-data-table" *ngIf="viewing">
      <form (ngSubmit)="save()" [formGroup]="form" novalidate autocomplete="off">
        <div [class]="control.classNames" *ngFor="let control of controls">
          <div [ngSwitch]="control.type" *ngIf="!control.hidden">

            <div *ngSwitchDefault>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>{{ control.label }} <span class="obrigatorio" *ngIf="control.required">*</span></label>
                  <input matInput #matInput dividerColor="primary" autocomplete="off"
                    [formControl]="control.getFormControl()" [type]="control.type" [required]="control.required"
                    [readonly]="!editing || control.readonly" [minlength]="control.minLength"
                    [maxlength]="control.maxLength" [step]="control.step" [min]="control.min" [max]="control.max" />
                  <!-- TODO: Implement @ angular2-material => [pattern]="control.pattern" -->
                  <mat-hint
                    *ngIf="(alwaysDisplayHint || editing) && control.hint || (control.minLength + 0) > 0 || (control.maxLength + 0) > 0"
                    align="end">
                    {{ control.hint ||
                    (matInput.value ? matInput.value.length : 0) + ' / ' +
                    (control.minLength + 0 > 0 ? control.minLength + (control.maxLength + 0 > 0 ? ' ..' : '+') : '') +
                    (control.maxLength + 0 > 0 ? (control.minLength + 0 > 0 ? ' ' : '') + control.maxLength : '') }}
                  </mat-hint>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'money'">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>{{ control.label }} <span class="obrigatorio" *ngIf="control.required">*</span></label>
                  <input class="form-control" money [formControl]="control.getFormControl()" type="text"
                    [step]="control.step" [min]="control.min" [max]="control.max" [required]="control.required"
                    [readonly]="!editing || control.readonly" />
                  <span class="texto" matPrefix>R$&nbsp;</span>
                  <mat-hint
                    *ngIf="(alwaysDisplayHint || editing) && control.hint || (control.min + 0) > 0 || (control.max + 0) > 0"
                    align="end">
                    {{ control.hint }}
                  </mat-hint>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'textarea'">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>{{ control.label }}</label>
                  <textarea class="form-control" rows="5" [formControl]="control.getFormControl()"
                    [class.read-only]="!editing" [readonly]="!editing"></textarea>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'slider'">
              <div *ngIf="!editing">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <input class="form-control" [formControl]="control.getFormControl()" type="text"
                      [placeholder]="control.label" readonly />
                  </div>
                </div>
              </div>
              <div *ngIf="editing">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label class="d-block">{{ control.label }} <span *ngIf="control.required"
                        class="obrigatorio">*</span></label>
                    <mat-slider style="width: 300px;" [formControl]="control.getFormControl()"
                      [class.read-only]="!editing" thumb-label="true" [step]="control.step" [min]="control.min"
                      [max]="control.max"></mat-slider>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'checkbox'">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <div *ngIf="printing">
                    <!-- TODO: REVIEW -->
                    <label><i class="checkbox fa fa-{{ control.getFormControl().value ? 'check-' : '' }}square-o"></i>
                      {{ control.label }}</label>
                  </div>
                  <mat-slide-toggle *ngIf="!printing" [formControl]="control.getFormControl()"
                    [class.read-only]="!editing" [disableRipple]="!editing" (slideend)="slideEnd(control)"
                    (click)="clickCallback($event, control)">
                    {{ control.label }}
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">{{ control.hint }}
                    </mat-hint>
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'select'">
              <div [hidden]="!editing">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>{{ control.label }}</label>
                    <mat-select [formControl]="control.getFormControl()" [required]="control.required"
                      [compareWith]="compareOptions">
                      <mat-option [value]="undefined" disabled>{{ control.options?.prompt !== undefined ?
                        control.options?.prompt : 'Selecione' }}</mat-option>
                      <mat-option *ngFor="let option of control.options.values" [value]="optionValue(control, option)">
                        {{ label(option, control.options.label) }}
                      </mat-option>
                    </mat-select>
                    <!--<mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">{{ control.hint }}</mat-hint>-->
                  </div>
                </div>
              </div>

              <div *ngIf="!editing">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>{{ control.label }}</label>
                    <input class="form-control" readonly [value]="label(control)" />
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">{{ control.hint }}
                    </mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'list'">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>{{ control.label }} ({{ fieldValue(item, control) !== null ? fieldValue(item, control).length :
                    '0' }})</label>
                  <div *ngFor="let listItem of item[control.key]">
                    <strong>{{ listItem[control.options?.label] }}</strong> - {{ listItem[control.options?.description]
                    }}<br>
                    <div *ngFor="let childItem of listItem[control.options?.child]">
                      {{ childItem[control.options?.childField] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'multiselect'">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>{{ control.label }}</label>
                  <app-multi-select *ngIf="!control.disabled" [options]="control.options" [required]="control.required"
                    [readonly]="!editing || control.readonly" [formControl]="control.getFormControl()">
                  </app-multi-select>
                  <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">{{ control.hint }}
                  </mat-hint>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'date'">
              <div floatPlaceholder="always" *ngIf="!editing || !control.readonly">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>{{ control.label }}</label>
                    <input matInput [formControl]="control.getFormControl()"
                      [class.read-only]="!editing && !control.readonly" [required]="control.required"
                      [readonly]="!editing || control.readonly" type="date" /><!-- TODO: add date directive -->
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">
                      {{ control.hint }}
                    </mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'date-local'">
              <div floatPlaceholder="always" *ngIf="!editing || !control.readonly">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label> {{ control.label }} </label>
                    <input matInput [formControl]="control.getFormControl()"
                      [class.read-only]="!editing && !control.readonly" [required]="control.required"
                      [readonly]="!editing || control.readonly" type="date-local" />
                    <!-- TODO: add datetime directive -->
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">
                      {{ control.hint }}
                    </mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'datetime'">
              <div floatPlaceholder="always" *ngIf="!editing || !control.readonly">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label> {{ control.label }} </label>
                    <input matInput [formControl]="control.getFormControl()"
                      [class.read-only]="!editing && !control.readonly" [required]="control.required"
                      [readonly]="!editing || control.readonly" type="datetime" /><!-- TODO: add datetime directive -->
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">
                      {{ control.hint }}
                    </mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'datetime-local'">
              <div floatPlaceholder="always" *ngIf="!editing || !control.readonly">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>{{ control.label }}</label>
                    <input matInput [formControl]="control.getFormControl()"
                      [class.read-only]="!editing && !control.readonly" [required]="control.required"
                      [readonly]="!editing || control.readonly" type="datetime-local" />
                    <!-- TODO: add datetime directive -->
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">
                      {{ control.hint }}
                    </mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'time'">
              <div floatPlaceholder="always" *ngIf="!editing || !control.readonly">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>{{ control.label }}</label>
                    <input matInput [formControl]="control.getFormControl()"
                      [class.read-only]="!editing && !control.readonly" [required]="control.required"
                      [readonly]="!editing || control.readonly" type="time" /><!-- TODO: add time directive -->
                    <mat-hint *ngIf="(alwaysDisplayHint || editing) && control.hint" align="end">
                      {{ control.hint }}
                    </mat-hint>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div *ngSwitchCase="'image'">
          <app-upload autoupload="true" preview="true" [readonly]="!editing" [formControl]="control.getFormControl()" [label]="control.label" [required]="control.required"></app-upload>
        </div> -->
          </div>
        </div>

        <div *ngIf="editing">
          <button type="button" (click)='cancel()' class="btn btn-secondary"><i class="fas fa-undo"></i> Voltar</button>
          <button type="button" *ngIf="!isNew" [disabled]="form.dirty" (click)="remove()" class="btn btn-danger"><i
              class="fas fa-times"></i> Excluir</button>
          <button type="submit" [disabled]="( form.pristine || form.invalid )" class="btn btn-primary"><i
              class="fas fa-check"></i> Salvar</button>
        </div>
      </form>
    </div>

    <div *ngIf="sessionLoaded && !viewing" class="tabela-data-table">
      <!--<h1>{{ gridTitle }}</h1>-->

      <table [class.list-only]="readonly">
        <!-- TODO: REVIEW   && !select -->
        <thead>
          <tr class="labels">
            <th *ngIf="bulkeditable || bulkdelete" class="selectable"></th>
            <th *ngFor="let c of columns" [class]="c.orderClass + ' datatable-label-' + c.type">
              <a (click)="sort(c.key)">{{ c.label }}<mat-icon *ngIf="c.orderClass !== undefined">{{ c.orderClass ===
                  'asc' ? 'expand_more' : (c.orderClass === 'desc' ? 'expand_less' : '') }}</mat-icon></a>
            </th>
          </tr>
          <tr class="filters" #filters>
            <th *ngIf="bulkeditable || bulkdelete" class="selectable">
              <p-checkbox style="padding-left: 3px;" [matTooltip]="allSelectTooltip" [binary]="true"
                (change)="allSelect()"></p-checkbox>
            </th>
            <th *ngFor="let c of columns; let i = index"
              [class]="(c.classNames ? c.classNames + ' ' : '') + 'datatable-filter-' + c.type">
              <div class="search" [ngSwitch]="c.type">
                <div *ngSwitchCase="'checkbox'">
                  <mat-select [tabindex]="i + 1" #filterInput [(ngModel)]="c.filter" (onOpen)="c.setReady()"
                    (change)="filter(c, $event.value)">
                    <mat-option [value]="-2">{{ c.options?.nullLabel || 'Todos' }}</mat-option>
                    <mat-option value="false">Não</mat-option>
                    <mat-option value="true">Sim</mat-option>
                  </mat-select>
                </div>

                <div *ngSwitchCase="'select'" class="dropdown-table-search">
                  <mat-select [tabindex]="i + 1" #filterInput [(ngModel)]="c.filter" (onOpen)="c.setReady()"
                    (change)="filter(c, $event.value)">
                    <mat-option [value]="-2">{{ c.options?.prompt || 'Todos' }}</mat-option>
                    <mat-option [value]="-1" *ngIf="!c.required && !findControl(c.key)?.required">{{
                      c.options?.nullLabel !== undefined ? c.options?.nullLabel : 'Nenhum(a)' }}</mat-option>
                    <mat-option *ngFor="let option of c.options.values" [value]="option.value">{{ option?.label ||
                      option.value }}</mat-option>
                  </mat-select>
                </div>

                <div *ngSwitchCase="'complex'"></div>

                <input class="form-control" *ngSwitchCase="'date'" type="date-local" autocomplete="off"
                  [tabindex]="i + 1" #filterInput (keyup)="filter(c, filterInput.value)" [value]="filterValue(c)" />

                <input class="form-control" *ngSwitchCase="'datetime'" type="datetime" autocomplete="off"
                  [tabindex]="i + 1" #filterInput (keyup)="filter(c, filterInput.value)" [value]="filterValue(c)" />

                <input class="form-control" *ngSwitchCase="'time'" type="time" autocomplete="off" [tabindex]="i + 1"
                  #filterInput (keyup)="filter(c, filterInput.value)" [value]="filterValue(c)" />

                <input class="form-control" *ngSwitchDefault type="text" autocomplete="off" [tabindex]="i + 1"
                  #filterInput (keyup)="filter(c, filterInput.value)" [value]="filterValue(c)" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let i of items; trackBy: trackByItems" (click)="view(i[keyField], i)" (mouseover)="rowSelect(i)">
            <td *ngIf="bulkeditable || bulkdelete" class="selectable">
              <p-checkbox (click)="$event.stopPropagation()" (change)="singleSelect(i)" [binary]="true">
              </p-checkbox>
            </td>

            <td *ngFor="let c of columns" [class]="(c.classNames ? c.classNames + ' ' : '') + c.type"
              [class.nowrap]="c.nowrap">
              <div [ngSwitch]="c.type">
                <div class="text-overflow-dynamic-container">
                  <p-checkbox *ngSwitchCase="'checkbox'" [binary]="true"></p-checkbox>
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ fieldValue(i, c) ? 'R$ ' + (fieldValue(i, c) | money) : '-' }}"
                    *ngSwitchCase="'money'">{{ fieldValue(i, c) ? 'R$ ' + (fieldValue(i, c) | money) : '-' }}</span>
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ fieldValue(i, c) | timezone | date:'dd/MM/yyyy' }}" *ngSwitchCase="'date'">{{
                    fieldValue(i, c) | timezone | date:'dd/MM/yyyy' }}</span>
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ fieldValue(i, c) | timezone | date:'dd/MM/yyyy HH:mm' + (c.timeWithSeconds ? ':ss' : '') }}"
                    *ngSwitchCase="'datetime'">{{ fieldValue(i, c) | timezone | date:'dd/MM/yyyy HH:mm' +
                    (c.timeWithSeconds ? ':ss' : '') }}</span>
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ fieldValue(i, c) | timezone | date:'dd/MM/yyyy' }}" *ngSwitchCase="'date-local'">{{
                    fieldValue(i, c) | timezone | date:'dd/MM/yyyy' }}</span>
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ fieldValue(i, c) | timezone | date:'dd/MM/yyyy HH:mm' + (c.timeWithSeconds ? ':ss' : '') }}"
                    *ngSwitchCase="'datetime-local'">{{ fieldValue(i, c) | timezone | date:'dd/MM/yyyy HH:mm' +
                    (c.timeWithSeconds ? ':ss' : '') }}</span>
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ fieldValue(i, c) | timezone | date:'HH:mm' + (c.timeWithSeconds ? ':ss' : '') }}"
                    *ngSwitchCase="'time'">{{ fieldValue(i, c) | timezone | date:'HH:mm' + (c.timeWithSeconds ? ':ss' :
                    '') }}</span>
                  <span *ngSwitchCase="'image'" [class.empty-image]="!fieldValue(i, c)"><img *ngIf="fieldValue(i, c)"
                      [src]="fieldValue(i, c)" alt="" /></span>


                  <span *ngSwitchCase="'select'">
                    <span class="text-overflow-dynamic-ellipsis" *ngIf="!c.editable || !isSelected(i)"
                      matTooltip="{{ fieldLabel(c, i, c.options?.nullLabel) }}">{{ fieldLabel(c, i,
                      c.options?.nullLabel) }}</span>

                    <div *ngIf="c.editable && isSelected(i)">
                      <mat-select floatPlaceholder="never" [ngModel]="fieldValue(i, c)"
                        (change)="c.callback(i, $event.value)">
                        <mat-option [value]="undefined" disabled>{{ c.options?.prompt || 'Selecione' }}</mat-option>
                        <mat-option [value]="-1" *ngIf="!c.required && !findControl(c.key)?.required">{{
                          c.options?.nullLabel !== undefined ? c.options?.nullLabel : 'Nenhum(a)' }}</mat-option>
                        <div *ngIf="c.options?.values">
                          <mat-option *ngFor="let o of c.options.values" [value]="o.value">{{ o?.label || o.value }}
                          </mat-option>
                        </div>
                      </mat-select>
                    </div>
                  </span>

                  <span *ngSwitchDefault>
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ fieldValue(i, c, '') | cnpj }}"
                      *ngIf="c.pipe === 'cnpj'">{{ fieldValue(i, c, '') | cnpj }}</span><!-- TODO: fix filter -->
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ fieldValue(i, c, '') }}"
                      *ngIf="c.pipe !== 'cnpj'">{{ fieldValue(i, c, '') }}</span>
                  </span>
                </div>
              </div>
            </td>
          </tr>

          <tr *ngIf="!loading && items.length === 0" class="empty">
            <td [attr.colspan]="columns ? columns.length + (bulkeditable || bulkdelete ? 1 : 0) : 1">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot *ngIf="itemsCount > 0">
          <tr>
            <td [attr.colspan]="columns ? columns.length + (bulkeditable || bulkdelete ? 1 : 0) : 1"
              style="font-size: 12px; padding-top:10px;"><label>Exibindo: {{ items.length }} de {{ itemsCount }}</label>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

  </mat-card-content>
