<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="row">
    <div class="col pb-3">
      <div *ngIf="exibePesquisa">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">PARÂMETROS DE CONSULTA</h3>
              <div class="actions-card d-flex">
                <button class="btnCustom-green" type="button" matTooltip="Novo Lançamento" (click)="criarLcto()">
                  <span class="material-icons" matTooltipPosition="above">add</span>
                  Novo Lançamento
                </button>
                <button type="button" class="btnCustom-default" (click)="showHideCardPesquisaLancamentos()"
                  (click)="changeStatePesquisaLancamentos()">
                  <span *ngIf="isCardPesquisaLancamentos" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardPesquisaLancamentos" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStatePesquisaLancamentos>
            <div class="form-row">
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>De <span class="obrigatorio">*</span></label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataIni"
                  [monthNavigator]="true" [defaultDate]="defaultDate" [yearNavigator]="true" yearRange="2010:2030">
                </p-calendar>
              </div>
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Até <span class="obrigatorio">*</span></label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataFin"
                  [monthNavigator]="true" [defaultDate]="defaultDate" [yearNavigator]="true" yearRange="2010:2030">
                </p-calendar>
              </div>
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Lote</label>
                <input class="form-control" id="input" type="text" [(ngModel)]="lote">
              </div>
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Número LCTO</label>
                <input class="form-control" id="input" type="text" [(ngModel)]="numLcto">
              </div>
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Valor De</label>
                <input class="form-control text-right" id="input" type="text" [(ngModel)]="valorPesquisaDe" money />
              </div>
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Valor Até</label>
                <input class="form-control text-right" id="input" type="text" [(ngModel)]="valorPesquisaAte" money />
              </div>
              <div class="form-group col-sm-2">
                <label>Que o histórico contenha</label>
                <input class="form-control" id="input" type="text" [(ngModel)]="historico">
              </div>
            </div>

            <div class="buttonsWrraper">

              <div class="formGroup">
                <label>Conta contábil</label>
                <button *ngIf="planoContasSelecionados.length" matTooltip="Alterar contas" class="btn btn-info"
                  type="button" (click)="modalContasMultiplas=true">
                  {{ planoContasSelecionados.length }} contas(s) selecionada(s)
                  <i class="fas fa-pen"></i>
                </button>
                <button *ngIf="!planoContasSelecionados.length" matTooltip="Alterar contas" class="btn btn-info"
                  type="button" (click)="modalContasMultiplas=true">
                  Todas as contas
                  <i class="fas fa-pen"></i>
                </button>
              </div>

              <button type="button" class="btn btn-primary" (click)="pesquisar()">
                <i class="fas fa-search"></i>
                Pesquisar
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col pb-3">
      <div *ngIf="lctos.length>0 && exibeResultados">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">RESULTADOS DA CONSULTA</h3>
              <div class="actions-card d-flex">
                <button *ngIf="idLctos.length>0" class="btnCustom-red" matTooltip="Excluir"
                  (click)="removerRegistrosSelecionados()">
                  <span class="material-icons" matTooltipPosition="above">delete</span>
                  Excluir
                </button>
                <button type="button" class="btnCustom-default" (click)="showHideCardResultadosLancamentos()"
                  (click)="changeStateResultadosLancamentos()">
                  <span *ngIf="isCardResultadosLancamentos" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardResultadosLancamentos" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStateResultadosLancamentos>
            <div class="row" style="padding: 14px;">
              <p-table class="table-system" #dt1 [value]="lctos" [paginator]="true" (onFilter)="onFilter($event)"
                [pageLinks]="pageLinks" [rows]="rows" [(selection)]="idLctos" [rowsPerPageOptions]="rowsPerPage">

                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtLcto'">
                        <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'dtLcto'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'dtLcto', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'numLcto'">
                        <span class="text-overflow-dynamic-ellipsis">Número <p-sortIcon [field]="'numLcto'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'numLcto', 'contains')">
                    </th>


                    <th class="text-left" style="width: 47%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'lote'">
                        <span class="text-overflow-dynamic-ellipsis">Lote <p-sortIcon [field]="'lote'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'lote', 'contains')">
                    </th>

                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">Ação</span>
                    </th>

                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td style="width: 7%;">
                      <span class="overflow-ellipse text-center">
                        <p-tableCheckbox [value]="rowData">
                        </p-tableCheckbox>
                      </span>
                    </td>
                    <td style="width: 10%;">
                      <span class="overflow-ellipse text-center" matTooltip="{{rowData.dtLcto}}">
                        {{rowData.dtLcto}}
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.numLcto}}">
                        {{rowData.numLcto}}
                      </span>
                    </td>
                    <td style="width: 47%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.lote}}">
                        {{rowData.lote}}
                      </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <div class="actionColumn">
                        <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Clonar"
                          matTooltipPosition="above" (click)="abrirModalLancamentoClone(rowData)">
                          <span class="material-icons">file_copy</span>
                        </button>
                        <button class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above"
                          (click)="editarRegistroLancamento(rowData)">
                          <span class="material-icons">edit</span>
                        </button>
                        <button class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above"
                          (click)="removerRegistroLancamento(rowData)">
                          <span class="material-icons">delete</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div *ngIf="novoLcto && exibeDetalhesResultado">
    <div class="row">
      <div class="col pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">LANÇAMENTO CONTÁBIL</h3>
              <div class="actions-card d-flex">
                <button type="button" class="btnCustom-default" (click)="voltarPesquisaLancamentos()">
                  <span class="material-icons full-icon" matTooltip="Voltar"
                    matTooltipPosition="above">chevron_left</span>
                  Voltar
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;">
            <div class="row">
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Data</label>
                <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                  [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="lcto.dataLcto"></p-calendar>
              </div>
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Lote</label>
                <input class="form-control" type="text" [(ngModel)]="lcto.lote">
              </div>
              <div class="form-group col-sm-1" *ngIf="editarLcto" style="min-width: 132px;">
                <label>Número</label>
                <input class="form-control" type="text" disabled="true" [(ngModel)]="lcto.numero">
              </div>
              <div class="form-group col-sm-1" style="min-width: 200px;">
                <label>Valor total de débito</label>
                <input class="form-control" readonly type="text" [(ngModel)]="valorLancamentoDebito">
              </div>
              <div class="form-group col-sm-1" style="min-width: 200px;">
                <label>Valor total de crédito</label>
                <input class="form-control" readonly type="text" [(ngModel)]="valorLancamentoCredito">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-1 div-checkbox-lancamento" style="min-width: 132px;">
                <p-checkbox class="mr-1" [(ngModel)]="recorrencia" [binary]="true" inputId="recorrencia"
                  label="Recorrência"></p-checkbox>
              </div>
              <div class="form-group col-sm-2" style="margin-right: 10px; margin-left: 10px;"
                *ngIf="recorrencia === true">
                <label>A cada (meses) </label>
                <p-dropdown class="type-p-dropdown" [filter]="false" [options]="meses" [(ngModel)]="ocorrenciaMes">
                </p-dropdown>
              </div>
              <div class="form-group col-sm-2" *ngIf="recorrencia === true">
                <label>Data final</label>
                <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                  [(ngModel)]="dtFimOcorrencia" dateFormat="dd/mm/yy"></p-calendar>
              </div>
              <div class="form-group col-sm-2 div-checkbox-lancamento" *ngIf="recorrencia === true"
                style="min-width: 192px;">
                <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antecipaDiaNaoUtil"
                  label="Antecipa dia não útil">
                </p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">PARTIDAS DO LANÇAMENTO CONTÁBIL</h3>
              <div class="actions-card d-flex">
                <button type="button" class="btnCustom-default" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above">
                  <span class="material-icons">
                    insert_drive_file
                  </span>
                  Exportar
                </button>

                <button type="button" class="btnCustom-green" (click)="validarLancamentos()">
                  <span class="material-icons full-icon" matTooltip="Salvar Alterações"
                    matTooltipPosition="above">save</span>
                  Salvar
                </button>

                <button type="button" class="btnCustom-default" (click)="showHideCardDetalhesResultadoLancamentos()"
                  (click)="changeStateResultadosDetalhesLancamento()">
                  <span *ngIf="isCardDetalhesResultadoLancamentos" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDetalhesResultadoLancamentos" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>

              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;"
            [@changeDivSize]=currentStateResultadosDetalhesLancamento>
            <div class="row" style="padding: 14px;">
              <p-table class="table-system" #dt2 [value]="lctoPartidas" [rows]="rows" [columns]='colsLancamentos'
                [paginator]="true" (onFilter)="onFilterDetalhesLancamento($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <ng-template pTemplate="header">
                  <tr>

                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'contaContabilDesc'">
                        <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL <p-sortIcon
                            [field]="'contaContabilDesc'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'contaContabilDesc', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'indDc'">
                        <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDc'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'indDc', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'valor', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadHistPadraoDesc'">
                        <span class="text-overflow-dynamic-ellipsis">HIST PADRÃO <p-sortIcon
                            [field]="'cadHistPadraoDesc'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'cadHistPadraoDesc', 'contains')">
                    </th>

                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'histCompl'">
                        <span class="text-overflow-dynamic-ellipsis">HIST COMPLEMENTAR <p-sortIcon
                            [field]="'histCompl'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'histCompl', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadCcusDesc'">
                        <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO <p-sortIcon
                            [field]="'cadCcusDesc'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'cadCcusDesc', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadProjDesc'">
                        <span class="text-overflow-dynamic-ellipsis">PROJETO <p-sortIcon [field]="'cadProjDesc'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'cadProjDesc', 'contains')">
                    </th>

                    <th style="width:5%" class="text-center">AÇÃO</th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td (click)="loadContasUnicas(rowData)" style="cursor: pointer;">
                      <div class="d-flex justify-content-between">
                        <span matTooltip=" {{rowData.contaContabilDesc}}" class="overflow-ellipse"
                          style="max-width: 90%;">
                          {{rowData.planoContas.id?rowData.contaContabilDesc:'Selecionar uma conta'}}
                        </span>
                        <span class="material-icons" style="font-size: 15px;">edit</span>
                      </div>
                    </td>

                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="form-row">
                            <div class="form-group col-sm-12 mb-0">
                              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true"
                                [options]="opcoesDebitoCredito" [(ngModel)]="rowData.indDc"
                                (onChange)="calculaValorLancamento()"></p-dropdown>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span matTooltip=" {{rowData.indDc}}" class="overflow-ellipse" style="cursor: pointer;"
                            *ngIf="rowData.indDc">
                            {{rowData.indDc}}
                          </span>
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td pEditableColumn>

                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input class="form-control text-right" type="text" [(ngModel)]="rowData.valor"
                            (ngModelChange)="calculaValorLancamento()" money />
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span matTooltip=" {{rowData.valor | money}}" class="overflow-ellipse"
                            style="cursor: pointer;" *ngIf="rowData.valor">
                            {{rowData.valor | money}}
                          </span>
                        </ng-template>
                      </p-cellEditor>

                    </td>

                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="form-row">
                            <div class="form-group col-sm-12 mb-0">
                              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true"
                                [options]="opcoesCadHist" [(ngModel)]="opcoesCadHistEscolhido"
                                (onChange)="alteraCadHist(rowData, opcoesCadHistEscolhido)"></p-dropdown>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span matTooltip=" {{obterCadHist(rowData)}}" class="overflow-ellipse"
                            style="cursor: pointer;" *ngIf="rowData.cadHistPadrao.id">
                            {{obterCadHist(rowData)}}
                          </span>
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                      <input class="form-control" type="text" [(ngModel)]="rowData.histCompl">
                    </td>

                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="form-row">
                            <div class="form-group col-sm-12 mb-0">
                              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true"
                                [options]="opcoesCentroCustos" [(ngModel)]="opcoesCentroCustosEscolhido"
                                (onChange)="verificaContaContabil(rowData, opcoesCentroCustosEscolhido, 'centroCusto')">
                              </p-dropdown>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span matTooltip=" {{obterCadCcus(rowData)}}" class="overflow-ellipse"
                            style="cursor: pointer;" *ngIf="rowData.cadCcus.id">
                            {{obterCadCcus(rowData)}}
                          </span>
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="form-row">
                            <div class="form-group col-sm-12 mb-0">
                              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true"
                                [options]="rowData.opcoesProjetos" [(ngModel)]="opcoesProjetosEscolhido"
                                (onChange)="verificaContaContabil(rowData, opcoesProjetosEscolhido, 'projeto')">
                              </p-dropdown>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span matTooltip=" {{obterCadProj(rowData, rowData.opcoesProjetos)}}" class="overflow-ellipse"
                            style="cursor: pointer;" *ngIf="rowData.cadProj.id">
                            {{obterCadProj(rowData, rowData.opcoesProjetos)}}
                          </span>
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td class="text-center">
                      <button type="button" style="padding: 0 !important;" (click)="removerLinha(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Excluir Linha!" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 16px">delete</span>
                      </button>
                    </td>

                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="2" style="width:30%; font-size: 11px;"><b>TOTAL DO PERÍODO:</b></td>
                    <td class="text-right" style="width:10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: text; font-size: 11px;"
                          matTooltip="R$ {{ valorLancamento | money}}"><b>R$: {{ valorLancamento | money}}</b></span>
                      </span>
                    </td>
                    <td colspan="5" style="width:60%;"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="12" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>

            <div class="row">
              <div class="col mt-2">
                <button class="btn btn-sm btn-info" type="button" (click)="addItem()"><i class="fas fa-plus"></i>
                  Adicionar Linha</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

  <!--Modal Clone Lançamento-->
  <p-dialog header="Lote e data do novo lançamento" [(visible)]="modalLancamentoClone" width="600" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Data <span class="obrigatorio">*</span></label>
            <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
              [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dataClone"></p-calendar>
          </div>
          <div class="form-group col-sm-9">
            <label>Lote <span class="obrigatorio">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="loteClone" />
          </div>
        </div>
      </div>
    </div>

    <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
      <div class="ui-g-12">
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalLancamentoClone = false;"><i
          class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="validarLancamentoClone()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
</mat-card>

<!-- Modal contas únicas-->
<!-- <p-dialog [(visible)]="modalContasUnicas" [style]="{width: '60vw'}" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">
  <p-header style="display: flex;justify-content: space-between;width: 100%;">
    <label style="font-size: 1rem;font-weight: 600;">Escolher conta</label>
  </p-header>
  <p-table class="table-system" #dt5 [value]="listaContas" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [lazy]="true"
    (onLazyLoad)="getPlanoContas($event)" [totalRecords]="totalContas" dataKey="id"
    (onRowSelect)="selecionarContasUnica($event)" appendTo="body">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
            <span class="text-overflow-dynamic-ellipsis">Código <p-sortIcon [field]="'codCta'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'codCta', 'contains')">
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descrCta'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'descrCta', 'contains')">
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codNaturezaConta.descricao'">
            <span class="text-overflow-dynamic-ellipsis">Cód. natureza <p-sortIcon
                [field]="'codNaturezaConta.descricao'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'codNaturezaConta.descricao', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
            <span class="text-overflow-dynamic-ellipsis">Nível<p-sortIcon [field]="'nivel'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'nivel', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.codCta}}">{{rowData.codCta}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 6%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 7%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.codNaturezaConta.descricao}}">{{rowData.codNaturezaConta.descricao}}</span>
          </span>
        </td>
        <td class="text-right" style="width: 7%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-primary" (click)="modalContasUnicas=false">
        <i class="fas fa-check"></i> Salvar
      </button>
    </div>
  </p-footer>
</p-dialog> -->

<app-modal-plano-contas [(isVisible)]="modalContasUnicas" (onSelectAccount)="selecionarContasUnica($event)" [contasAutomaticas]="visualizarContaAutomatica">
</app-modal-plano-contas>

<app-modal-plano-contas [(isVisible)]="modalContasMultiplas" [isMultiple]="true" [initialState]="selectedContas"
  (onSelectAccount)="selecionarContasMultiplas($event)" [contasAutomaticas]="visualizarContaAutomatica">
</app-modal-plano-contas>
