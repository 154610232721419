<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="box-content pt-3">
      <p-header>
        <h3 class="sub-title-page">Declarações</h3>
      </p-header>
      <p-table class="table-system" #datatable [columns]="colsDeclaracoes" [value]="declaracoes" [paginator]="false"
        selectionMode="single" [style]="{'width':'100%'}" (onRowCollapse)="unselect($event)"
        (onRowExpand)="select($event)" (onRowClick)="select($event)" [selection]="currentDeclaracao" dataKey="id"
        [responsive]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 50px;">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Referência </span>
              </span>

            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Calendário </span>
              </span>

            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Retificadora </span>
              </span>
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Status </span>
              </span>
            </th>

            <th class="text-left" style="width: 35%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Usuário </span>
              </span>
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Alteração </span>
              </span>
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">
          <tr>
            <td class="text-center" expander="true" styleClass="col-expander" style="width: 30px;">
              <span class="shared" [pRowToggler]="rowData" matTooltip="{{expanded ? 'Ocultar' : 'Expandir'}}">
                <i (click)="select(rowData)"
                  [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"
                  class="expanded"></i>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.anoReferencia}}">{{rowData.anoReferencia }}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.anoCalendario}}">{{rowData.anoCalendario }}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.retificadora ? 'Sim' : 'Não'}}">{{rowData.retificadora ? 'Sim' : 'Não'}}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status }}">{{rowData.status }}
                </span>
              </span>
            </td>

            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.usuario.login}}">{{rowData.usuario.nome}} {{rowData.usuario.sobrenome}}</span>
              </span>
            </td>

            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.changeDatetime | timezone | date:'dd/MM/yyyy HH:mm:ss' }}">{{rowData.changeDatetime
                  | timezone | date:'dd/MM/yyyy HH:mm:ss'}}</span>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr class="expanded">
            <td [attr.colspan]="7" class="pt-0 pb-0" style="border-top: 0px;">
              <div *ngIf="formGroup && declaracao">
                <form [formGroup]="formGroup">
                  <div class="alert alert-sm alert-success mt-3" role="alert" *ngIf="exportedFile">
                    <i class="fas fa-check"></i> Declaração gerada para o arquivo <strong><a target="_blank"
                        href="{{ filePath(exportedFile) }}">{{ exportedFile }}</a></strong>.
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-3 mt-2" style="min-width: 215px; max-width: 215px;">
                      <label>CPF do responsável pelo CNPJ</label>
                      <input #cpfInpuf class="form-control" formControlName="cpf"
                        [(ngModel)]="declaracao.responsavelCpf" [minlength]="11" [maxlength]="11" />
                    </div>
                  </div>
                  <div class="p-field-checkbox mb-3">
                    <p-checkbox #enviada class="mr-1" [(ngModel)]="declaracaoEnviada" inputId="declaracaoEnviada"
                      formControlName="declaracaoEnviada" [binary]="true"></p-checkbox>
                    <label for="declaracaoEnviada">Declaração enviada</label>
                  </div>
                  <div *ngIf="enviada.checked">
                    <div class="form-row">
                      <div class="form-group col-sm-1" style="min-width: 215px; max-width: 215px;">
                        <label class="d-block">Data de envio</label>
                        <!--TO DO ARAN -->
                        <!-- <p-calendar class="type-p-calendar" formControlName="envio" dateFormat="dd/mm/yy" [monthNavigator]="true"
                            [yearNavigator]="true" [(ngModel)]="declaracao.envio" [defaultDate]="defaultDate"
                            yearRange="2010:2030" >
                          </p-calendar> -->
                        <input matInput formControlName="envio" [(ngModel)]="declaracao.envio" type="date"
                          class="envio" />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label>Recibo da declaração</label>
                        <input class="form-control" formControlName="recibo" [(ngModel)]="declaracao.recibo" />
                      </div>
                    </div>
                  </div>
                  <div class="p-field-checkbox mb-3">
                    <p-checkbox #retificadora class="mr-1" [(ngModel)]="declaracaoRetificadora"
                      inputId="declaracaoRetificadora" formControlName="declaracaoRetificadora" [binary]="true">
                    </p-checkbox>
                    <label for="declaracaoRetificadora">Declaração retificadora</label>
                  </div>
                  <div class="form-row" *ngIf="retificadora.checked">
                    <div class="form-group col-sm-6">
                      <label>Recibo da declaração original</label>
                      <input class="form-control" formControlName="reciboOriginal" [(ngModel)]="declaracao.recibo" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <h3 class="sub-title"><i class="fas fa-bars"></i> Natureza jurídica do declarante</h3>
                    </div>
                  </div>
                  <mat-radio-group formControlName="naturezaJuridica" [(ngModel)]="declaracao.naturezaJuridica.id">
                    <mat-radio-button class="d-block mb-2" [value]="0">Pessoa jurídica de direito privado
                    </mat-radio-button>
                    <mat-radio-button class="d-block mb-2" [value]="1">Órgãos, autarquias e fundações da
                      administração pública federal</mat-radio-button>
                    <mat-radio-button class="d-block mb-2" [value]="2">Órgãos, autarquias e fundações da
                      administração pública estadual, municipal ou do Distrito Federal</mat-radio-button>
                    <mat-radio-button class="d-block mb-2" [value]="3">Empresa pública ou sociedade de economia mista
                      federal</mat-radio-button>
                    <mat-radio-button class="d-block mb-2" [value]="4">Empresa pública ou sociedade de economia mista
                      estadual, municipal ou do Distrito federal</mat-radio-button>
                    <mat-radio-button class="d-block mb-2" [value]="8">Entidade com alteração de natureza jurídica
                      (uso restrito)</mat-radio-button>
                  </mat-radio-group>

                  <div class="row">
                    <div class="col-sm-12">
                      <h3 class="sub-title"><i class="fas fa-bars"></i> Outras informações</h3>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.socioOstensivo" inputId="socioOstensivo"
                        formControlName="socioOstensivo" [binary]="true"></p-checkbox>
                      <label for="socioOstensivo">Sócio ostensivo responsável por sociedade em conta de participação
                        - SCP</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.depositoJudicial" inputId="depositoJudicial"
                        formControlName="depositoJudicial" [binary]="true"></p-checkbox>
                      <label for="depositoJudicial">Declarante depositário de crédito decorrente de decisão
                        judicial</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.clubeInvestimento" inputId="clubeInvestimento"
                        formControlName="clubeInvestimento" [binary]="true"></p-checkbox>
                      <label for="clubeInvestimento">Declarante de instituição administradora ou intermediadora de
                        fundo ou clube de investimento</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.rendimentosExterior"
                        inputId="rendimentosExterior" formControlName="rendimentosExterior" [binary]="true">
                      </p-checkbox>
                      <label for="rendimentosExterior">Declarante de rendimentos pagos a residentes ou domiciliados no
                        exterior</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.planoSaude" inputId="planoSaude"
                        formControlName="planoSaude" [binary]="true"></p-checkbox>
                      <label for="planoSaude">Existe pagamento pelo titular/dependente do plano privado de assistência à
                        saude - coletivo empresarial</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.recursoTesouroNacional"
                        inputId="recursoTesouroNacional" formControlName="recursoTesouroNacional" [binary]="true">
                      </p-checkbox>
                      <label for="recursoTesouroNacional">Indicador de entidade em que a União detém maioria do capital
                        social sujeito a voto, recebe recursos do Tesouro Nacional</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.fundacaoPublica" inputId="fundacaoPublica"
                        formControlName="fundacaoPublica" [binary]="true"></p-checkbox>
                      <label for="fundacaoPublica">Indicador de fundação pública de direito privado instituída pela
                        União, Estados, Municípios ou Distrito Federal</label>
                    </div>
                    <div class="p-field-checkbox mb-2">
                      <p-checkbox class="mr-1" [(ngModel)]="declaracao.situacaoEspecial" inputId="situacaoEspecial"
                        formControlName="situacaoEspecial" [binary]="true"></p-checkbox>
                      <label for="situacaoEspecial">Declaração de situação especial</label>
                    </div>
                  </div>
                  <!-- <div class="form-row">
                        <div class="form-group col-sm-12">
                          <mat-checkbox formControlName="olimpiadas2016" [(ngModel)]="declaracao.olimpiadas2016">Existe pagamento relacionado aos Jogos Olímpicos ou Jogos Paraolímpicos de 2016</mat-checkbox>
                        </div>
                    </div>  -->

                  <div class="form-row" *ngIf="declaracao.situacaoEspecial">
                    <div class="form-group col-sm-1" style="min-width: 215px; max-width: 215px;">
                      <label class="d-block">Data do Evento</label>
                      <!--TO DO ARAN -->
                      <!-- <p-calendar class="type-p-calendar" formControlName="situacaoEspecialData" dateFormat="dd/mm/yy" [monthNavigator]="true"
                          [yearNavigator]="true" [(ngModel)]="declaracao.situacaoEspecialData" [defaultDate]="defaultDate"
                          yearRange="2010:2030" >
                        </p-calendar> -->
                      <input matInput formControlName="situacaoEspecialData"
                        [(ngModel)]="declaracao.situacaoEspecialData" type="date" />
                    </div>
                  </div>

                  <div class="alert alert-sm alert-warning" role="alert" *ngIf="formGroup.dirty">
                    <i class="fas fa-exclamation-triangle"></i> Para incluir novos pagamentos, é necessário salvar as
                    alterações da declaração.
                  </div>

                  <div class="row" *ngIf="!formGroup.dirty">
                    <div class="col-sm-12">
                      <h3 class="sub-title"><i class="fas fa-file-upload"> </i> Importar documento contendo informações
                        de pagamentos</h3>
                    </div>
                  </div>
                  <div *ngIf="!formGroup.dirty">
                    <div class="form-row">
                      <div class="form-group col-sm-5 mb-1 mt-1">
                        <label class="d-block">Selecione o documento</label>
                        <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                          <span class="input-group-append">
                            <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                                      Remover Arquivo
                                    </span> -->
                            <span class="input-group-text btn-file">
                              <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i>
                                Selecionar Arquivo</span>
                              <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i>
                                Selecionar Arquivo</span> <!-- Antes era Trocar Arquivo-->
                              <input type="file" #fileInput [disabled]="formGroup.dirty"
                                (change)="fileChange($event)" />
                            </span>
                          </span>
                          <div class="form-control" data-trigger="fileinput">
                            <span class="fileinput-filename"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <button type="button" class="btn btn-sm btn-primary"
                          [disabled]="!file || (formGroup && formGroup.dirty)" (click)="fileUpload(fileInput)"><i
                            class="fas fa-upload"></i> Importar</button>
                      </div>
                    </div>
                    <div *ngIf="message" [class.error]="error">
                      <div class="alert alert-sm alert-danger" role="alert" *ngIf="error">
                        <strong><i class="fas fa-exclamation-triangle"></i> Ocorreram erros ao importar as informações
                          do arquivo.</strong><br>
                        <small>{{ message }}</small>
                      </div>

                      <div class="alert alert-sm alert-success" role="alert" *ngIf="!error">
                        <i class="fas fa-check"></i> {{ message }}
                      </div>
                    </div>
                  </div>

                  <p-header>
                    <h3 class="sub-title-page"><strong>{{historicoCount }}</strong> {{' pagamento' + (historicoCount > 1
                      ? 's' : '') + ' registrado' + (historicoCount > 1 ? 's' : '') }}</h3>
                  </p-header>

                  <p-table class="table-system" #declaracaoDatatable1 [value]="historico"
                    [totalRecords]="historico.length" selectionMode="single" [style]="{'width':'100%'}"
                    [selection]="currentDeclaracao">
                    <p-header>
                      <h3 class="sub-title-page"><strong>{{historicoCount }}</strong> {{' pagamento' + (historicoCount >
                        1 ? 's' : '') + ' registrado' + (historicoCount > 1 ? 's' : '') }}</h3>
                    </p-header>

                    <ng-template let-col pTemplate="header">
          <tr>

            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Código </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'codReceitaRfb', 'contains')">
            </th>
            <th class="text-left" style="width: 15%;">


              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">CNPJ/CPF </span>

              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'dirfParticipante.cnpjOrCpf', 'contains')">

            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Data </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'data | timezone | date:dd/MM/yyyy ', 'contains')">
            </th>

            <th class="text-right" style="width: 13%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">IRRF BC </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'irrfBc', 'contains')">
            </th>

            <th class="text-right" style="width: 12%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">IRRF </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'irrfValue', 'contains')">
            </th>

            <th class="text-right" style="width: 13%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">PCC BC </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'pccBc', 'contains')">
            </th>

            <th class="text-right" style="width: 9%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Cofins </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'cofinsValue', 'contains')">
            </th>

            <th class="text-right" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Pis </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'pisValue', 'contains')">
            </th>

            <th class="text-right" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">CSLL </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="declaracaoDatatable1.filter($event.target.value, 'csllValue', 'contains')">
            </th>







          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData?.codReceitaRfb.descricao}}">{{rowData?.codReceitaRfb.cod }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dirfParticipante.razaoSocialOrNome}}">{{rowData.dirfParticipante.cnpjOrCpf |
                  cnpj }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.data | timezone | date:'dd/MM/yyyy'}}">{{rowData.data | timezone |
                  date:'dd/MM/yyyy'}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.irrfBc | money }}">{{rowData.irrfBc |
                  money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.irrfValue | money}}">{{rowData.irrfValue | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.pccBc | money}}">{{rowData.pccBc |
                  money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.cofinsValue | money}}">{{rowData.cofinsValue | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.pisValue | money}}">{{rowData.pisValue | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.csllValue | money}}">{{rowData.csllValue | money }}</span>
              </span>
            </td>




          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="13" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


      </form>
    </div>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="7" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
    </p-table>





    </div>
  </mat-card-content>
</mat-card>