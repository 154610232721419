<mat-card class="card-linnks margin-linnks">
  <mat-card-content class="mat-card-linnks">

    <div id="div-botoes">
      <div class="margin-botoes">
        <button type="button" (click)="print()" mat-raised-button pButton label="Imprimir"></button>
      </div>
    </div>

    <div id="container-impressao">
      <!--http://localhost:4200/teste/teste-->
      <table cellpadding="0" cellspacing="0" border="0" width="100%">
        <thead>
          <tr>
            <th style="width:60%">
              <table cellpadding="0" cellspacing="10" border="0" width="100%" class="text-left normal uppercase padding15">
                <tr><td colspan="2" class="px20 text-center bold padding-bottom15">REGISTRO DE ENTRADA</td></tr>
                <tr>
                  <td>Empresa: HMY DO BRASIL LTDA</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Inscr. Est: 407240845115</td>
                  <td>CNPJ: 01.476.925/0001-76</td>
                </tr>
                <tr>
                  <td>Folha: </td>
                  <td>Periodo: 01/01/2017 a 31/01/2017</td>
                </tr>
              </table>
            </th>
            <th style="width:40%" class="vertical-top">
              <table cellpadding="0" cellspacing="10" border="0" width="100%" class="padding15">
                <tr>
                  <td class="px10 normal text-left line-height20">
                    <span class="bold">Código de valores fiscais</span><br/>
                    1 - Operações com Crédito do Imposto<br/>
                    2 - Operações sem Crédito do Impoto - Isentas e Não Tributadas<br/>
                    3 - Operações sem Crédito do Imposto - Outras
                  </td>
                </tr>
              </table>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="2">

              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="border-collapse:collapse;">
                <thead>
                  <tr class="px15 bold text-center uppercase">
                    <th class="padding10 border-solid1" colspan="8">DOCUMENTOS FISCAIS</th>
                    <th class="padding10 border-solid1">COD</th>
                    <th class="padding10 border-solid1" colspan="4">VALORES FISCAIS</th>
                    <th class="padding10 border-solid1"></th>
                  </tr>
                  <tr class="px12 text-center">
                    <th class="normal padding5 border-solid1" style="width:70px">DATA ENTRADA</th>
                    <th class="normal padding5 border-solid1" style="width:70px">MODELO</th>
                    <th class="normal padding5 border-solid1" style="width:40px">SERIE</th>
                    <th class="normal padding5 border-solid1" style="width:75px">NUMERO</th>
                    <th class="normal padding5 border-solid1" style="width:70px">DATA EMISSÃO</th>
                    <th class="normal padding5 border-solid1" style="width:70px">COD EMITENTE</th>
                    <th class="normal padding5 border-solid1" style="width:30px">UF</th>
                    <th class="normal padding5 border-solid1" style="width:100px">VALOR CONTÁBIL</th>
                    <th class="normal padding5 border-solid1" style="width:50px">CFOP</th>
                    <th class="normal padding5 border-solid1" style="width:60px">TIPO</th>
                    <th class="normal padding5 border-solid1" style="width:100px">BASE DE CALCULO</th>
                    <th class="normal padding5 border-solid1" style="width:40px">ALIQ</th>
                    <th class="normal padding5 border-solid1" style="width:100px">IMPOSTO CREDITADO</th>
                    <th class="normal padding5 border-solid1" style="width:100px">OBS</th>
                  </tr>
                </thead>

                <tbody>
                  <!-- LINHA COM 0 VALOR FISCAL -->
                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <!-- LINHA COM 1 VALOR FISCAL -->
                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <!-- LINHA COM 2 VALOR FISCAL -->
                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <!-- LINHA COM 3 VALOR FISCAL -->
                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <!-- LINHA COM 4 VALOR FISCAL -->
                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



















                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>


                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>


                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>


                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>


                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>


                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>


                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="padding-top-bottom5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right"></td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top"></td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>



                  <tr class="px12 text-center border-right border-left">
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">55</td>
                    <td class="padding-top-bottom5">001</td>
                    <td class="padding-top-bottom5">000029503</td>
                    <td class="padding-top-bottom5">03/01/17</td>
                    <td class="padding-top-bottom5">00000031</td>
                    <td class="padding-top-bottom5">SP</td>
                    <td class="text-right padding-top-bottom5 padding-right5 border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8">
                      <span>HMY DO BRASIL LTDA</span>
                      <span style="float:right;">01.476.925/0001-76</span>
                    </td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>
                  <tr class="px12 text-center uppercase border-right border-bottom border-left">
                    <td class="text-left padding-top-bottom5 padding-right5 padding-left5 border-right" colspan="8"></td>
                    <td class="padding-top-bottom5 vertical-top border-right">2949</td>
                    <td class="padding-top-bottom5 vertical-top border-right">1-ICMS</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">3.510,40</td>
                    <td class="padding-top-bottom5 vertical-top border-right">7,00</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top border-right">245,73</td>
                    <td class="text-right padding-top-bottom5 padding-right5 vertical-top">1835.88 IPI</td>
                  </tr>

                </tbody>
              </table>

            </td>
          </tr>
        </tbody>
      </table>



      <table cellpadding="0" cellspacing="0" border="0" width="100%" style="page-break-before:always;">
        <thead style="display:table-row-group;"> <!-- if numLinhas < 35 = style="display:table-row-group;" -->
          <tr>
            <th style="width:60%">
              <table cellpadding="0" cellspacing="10" border="0" width="100%" class="text-left normal uppercase padding15">
                <tr><td colspan="2" class="px20 text-center bold padding-bottom15">REGISTRO DE ENTRADA</td></tr>
                <tr>
                  <td>Empresa: HMY DO BRASIL LTDA</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Inscr. Est: 407240845115</td>
                  <td>CNPJ: 01.476.925/0001-76</td>
                </tr>
                <tr>
                  <td>Folha:</td>
                  <td>Periodo: 01/01/2017 a 31/01/2017</td>
                </tr>
              </table>
            </th>
            <th style="width:40%" class="vertical-top">
              <table cellpadding="0" cellspacing="10" border="0" width="100%" class="text-left normal padding15">
                <tr>
                  <td class="px10 line-height20">
                    <span class="bold">Código de valores fiscais</span><br/>
                    1 - Operações com Crédito do Imposto<br/>
                    2 - Operações sem Crédito do Impoto - Isentas e Não Tributadas<br/>
                    3 - Operações sem Crédito do Imposto - Outras
                  </td>
                </tr>
              </table>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td colspan="2">

              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="border-collapse:collapse;">
                <thead class="border-solid1" style="display:table-row-group;"> <!-- if numLinhas < 35 = style="display:table-row-group;" -->
                  <tr>
                    <td class="px14 normal text-left padding5" colspan="7">Resumo Mensal de Operações por Codigo Fiscal</td>
                  </tr>
                  <tr class="px12 bold text-center">
                    <td class="text-left padding5" style="width:100px;" colspan="2">CFOP</td>
                    <td class="padding5" style="width:160px;">VALOR CONTÁBIL</td>
                    <td class="padding5" style="width:160px;">BASE DE CÁLCULO</td>
                    <td class="padding5" style="width:160px;">IMPOSTO</td>
                    <td class="padding5" style="width:160px;">ISENTAS</td>
                    <td class="padding5" style="width:160px;">OUTRAS</td>
                  </tr>
                </thead>

                <tbody>
                  <!--
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  30 -->
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px13 bold text-center">
                    <td colspan="2" class="text-left padding5">SUBTOTAL</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.982.427,35</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.869.071,88</td>
                    <td class="padding5 padding-top10 padding-bottom10">412.792,10</td>
                    <td class="padding5 padding-top10 padding-bottom10">379.870,98</td>
                    <td class="padding5 padding-top10 padding-bottom10">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px13 bold text-center">
                    <td colspan="2" class="text-left padding5">SUBTOTAL</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.982.427,35</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.869.071,88</td>
                    <td class="padding5 padding-top10 padding-bottom10">412.792,10</td>
                    <td class="padding5 padding-top10 padding-bottom10">379.870,98</td>
                    <td class="padding5 padding-top10 padding-bottom10">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px12 text-center">
                    <td class="text-left padding5">1101</td>
                    <td class="padding5">ICMS</td>
                    <td class="padding5">2.982.427,35</td>
                    <td class="padding5">2.869.071,88</td>
                    <td class="padding5">412.792,10</td>
                    <td class="padding5">379.870,98</td>
                    <td class="padding5">(266.515,51)</td>
                  </tr>
                  <tr class="px13 bold text-center">
                    <td colspan="2" class="text-left padding5">SUBTOTAL</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.982.427,35</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.869.071,88</td>
                    <td class="padding5 padding-top10 padding-bottom10">412.792,10</td>
                    <td class="padding5 padding-top10 padding-bottom10">379.870,98</td>
                    <td class="padding5 padding-top10 padding-bottom10">(266.515,51)</td>
                  </tr>
                  <tr class="px13 bold text-center">
                    <td colspan="2" class="text-left padding5">TOTAL</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.982.427,35</td>
                    <td class="padding5 padding-top10 padding-bottom10">2.869.071,88</td>
                    <td class="padding5 padding-top10 padding-bottom10">412.792,10</td>
                    <td class="padding5 padding-top10 padding-bottom10">379.870,98</td>
                    <td class="padding5 padding-top10 padding-bottom10">(266.515,51)</td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>
        </tbody>
      </table>



    </div>

  </mat-card-content>
</mat-card>
