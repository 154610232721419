<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content">
      <div class="pull-right align-down-table">
        <button type="button" class="btn btn-link" (click)="dt.exportCSV()"><i class="fas fa-file-excel excel"></i>
          Exportar para Excel</button>
      </div>
      <div class="clearfix"></div>
      <p-table class="table-system" #dt [columns]="colslista" [value]="lista" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false"
        selectionMode="single">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'codigo', 'contains')">
            </th>
            <th class="text-left" style="width: 60%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'descricao', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                <span class="text-overflow-dynamic-ellipsis">SALDO QTDE <p-sortIcon [field]="'qtde'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'qtde', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                <span class="text-overflow-dynamic-ellipsis">SALDO VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'valor', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstMedio'">
                <span class="text-overflow-dynamic-ellipsis">CUSTO MÉDIO <p-sortIcon [field]="'cstMedio'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'cstMedio', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect()"
                  matTooltip="{{ rowData.codigo }}">{{ rowData.codigo }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 60%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect()"
                  matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect()"
                  matTooltip="{{rowData.qtde}}">{{rowData.qtde }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect()"
                  matTooltip="{{rowData.valor}}">{{rowData.valor }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect()"
                  matTooltip="{{rowData.cstMedio}}">{{rowData.cstMedio}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>