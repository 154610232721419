<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-12 d-flex align-items-center justify-content-between" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Demonstrativos de Vendas
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Informativo sobre as saídas</small>
          <br>
        </div>
      </h4>
      <div class="d-flex align-itens-center justify-content-end">
        <p-multiSelect class="type-p-mult mr-3 mult-header" [filter]="true" [placeholder]="placeholderCompanies" [options]="availableCompanies" appendTo="body" [(ngModel)]="selectedCompanies" [disabled]="loadingOptCompanies" [dropdownIcon]="loadingOptIconCompanies ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="checkCompaniesList()"></p-multiSelect>
        <app-dash-changer [options]="periodOptions" format="chip" (tabSelected)="changePeriod($event)"></app-dash-changer>
      </div>
    </div>
  </div>
  <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3" *ngIf="selectedCompanies.length > 0">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <app-dash-graphics container="pie_chart1" [title]="'Valor por status'" [type]="'pie'" [height]="348" [pieData]="statusData" [isLegend]="false" [isLoading]="isLoading"></app-dash-graphics>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top">
        <div class="shadow-box w-100 p-3 h-100">
          <app-dash-changer class="mt-3" [options]="tabOptionsItens" format="text" (tabSelected)="changeTab($event, 2)"></app-dash-changer>
          <app-dash-graphics container="bar_chart1" [subtitle]="'5 maiores valores'" [type]="'bar'" [height]="280" [isMoney]="true" [barData]="itemData" [baseList]="itemLabel" [isLegend]="true" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (itensTabSelected === 'valuesItens') ? 'block' : 'none'}"></app-dash-graphics>
          <app-dash-graphics container="bar_chart2" [isPercentage]="true" [subtitle]="'5 maiores valores'" [type]="'bar'" [height]="280" [isMoney]="false" [barData]="itensPercentageData" [baseList]="itemLabel" [isLegend]="true" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (itensTabSelected === 'percentageItens') ? 'block' : 'none'}"></app-dash-graphics>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9" *ngIf="selectedCompanies.length > 0">
    <div class="row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="shadow-box w-100 p-3 h-100">
          <app-dash-changer class="mt-3" [options]="tabOptionsProvider" format="text" (tabSelected)="changeTab($event, 1)"></app-dash-changer>
          <app-dash-graphics container="bar1" [type]="'bar'" [height]="300" [isMoney]="true" [barData]="providerData" [isLegend]="true" [baseList]="providerLabel" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (providerTabSelected === 'valuesProvider') ? 'block' : 'none'}"></app-dash-graphics>
          <app-dash-graphics container="provider_percentage_chart" [isPercentage]="true" [type]="'bar'" [height]="300" [isMoney]="false" [barData]="providersPercentageData" [isLegend]="true" [baseList]="providerLabel" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (providerTabSelected === 'percentageProvider') ? 'block' : 'none'}"></app-dash-graphics>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <app-dash-graphics container="bar2" [title]="'Valor por centro de custo'" [type]="'bar'" [height]="350" [isMoney]="true" [barData]="ccusData" [isLegend]="true" [baseList]="ccusLabel" [isLegend]="true" [isLoading]="isLoading"></app-dash-graphics>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <app-dash-graphics container="bar3" [title]="'Valor por projeto'" [type]="'bar'" [height]="350" [isMoney]="true" [barData]="projData" [isLegend]="true" [baseList]="projLabel" [isLegend]="true" [isLoading]="isLoading"></app-dash-graphics>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top">
        <div class="shadow-box w-100 p-3 h-100">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <app-dash-changer class="mt-3" [options]="tabOptions" format="text" (tabSelected)="changeTab($event, 3)"></app-dash-changer>
            <div class="alert alert-xs alert-primary d-inline" role="alert" style="width:max-content;">
              <i class="fas fa-info-circle"></i> Somente vendas finalizadas!
            </div>
          </div>
          <app-dash-graphics container="column_chart1" [type]="'column'" [height]="280" [columnData]="monthlyEvolutionData" [isMoney]="true" [baseList]="monthlyEvolutionBase" [isLegend]="true" [isLoading]="isLoading" [isStack]="false" [showShadowBox]="false" [ngStyle]="{'display': (evolutionTabSelected === 'normal') ? 'block' : 'none'}" [colors]="['rgb(232, 43, 123)', 'rgb(85, 0, 189)', 'rgb(36, 4, 45)', 'rgb(254, 198, 1)', 'rgb(39, 232, 188)']"></app-dash-graphics>
          <app-dash-graphics container="column_chart2" [type]="'column'" [height]="280" [columnData]="monthlyEvolutionDataYTD" [isMoney]="true" [baseList]="monthlyEvolutionBase" [isLegend]="true" [isLoading]="isLoading" [isStack]="false" [showShadowBox]="false" [ngStyle]="{'display': (evolutionTabSelected === 'ytd') ? 'block' : 'none'}" [colors]="['rgb(232, 43, 123)', 'rgb(85, 0, 189)', 'rgb(36, 4, 45)', 'rgb(254, 198, 1)', 'rgb(39, 232, 188)']"></app-dash-graphics>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top" *ngIf="selectedCompanies.length > 0">
    <div class="shadow-box w-100 p-3 h-100">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <app-dash-changer class="mt-3" [options]="tabOptionsTable" format="text" (tabSelected)="changeTab($event, 4)"></app-dash-changer>
        <button class="btnCustom-default" matTooltip="Exportar excel" [disabled]="isTableExportLoading" (click)="exportDetails()">
          <span *ngIf="!isTableExportLoading" class="material-icons">file_download</span>
          <span *ngIf="isTableExportLoading" class="material-icons loading-icon">refresh</span>
        </button>
      </div>
      <app-dash-table [data]="transactionsDetailsSimplified" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="true" [hasFilter]="true" [hasPaginator]="true" [hasFooter]="true" [isScrollable]="false" [hasExport]="false" [showShadowBox]="false" [isScrollable]="true" (appliedFilter)="changeFilterDetails($event)" [ngStyle]="{'display': (movTabSelected === 'simplified') ? 'block' : 'none'}"></app-dash-table>
      <app-dash-table [data]="transactionsDetails" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="true" [hasFilter]="true" [hasPaginator]="true" [hasFooter]="true" [isScrollable]="false" [hasExport]="false" [showShadowBox]="false" [isScrollable]="true"  (appliedFilter)="changeFilterDetails($event)" [ngStyle]="{'display': (movTabSelected === 'normal') ? 'block' : 'none'}"></app-dash-table>
    </div>
  </div>
</div>

<div class="empty" *ngIf="selectedCompanies.length <= 0">
  <div class="empty-content">
      <span class="material-icons">warning</span>
      <h2>Por favor, selecione pelo menos uma empresa</h2>
  </div>
</div>