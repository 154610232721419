<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12"></div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: statusPendente }">
                  <a (click)="showTelaNotasFiscais('TELA_1')">Pendente ({{ xmlPendente.length }})</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusEscriturada }">
                  <a (click)="showTelaNotasFiscais('TELA_2')">Escriturada ({{ xmlEscriturado.length }})</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusCancelada }">
                  <a (click)="showTelaNotasFiscais('TELA_3')">Cancelada ({{ xmlCancelado.length }})</a>
                </li>
              </ul>
              <div class="actions-card">
                <button *ngIf="statusPendente" type="button" class="btn btn-xs btn-link" (click)="exportExcel(xmlPendente, 'Notas pendentes', colPendentes)"
                  matTooltip="Exportar Excel" matTooltipPosition="above">
                  <i class="far fa-file-excel excel"></i>
                </button>
                <button *ngIf="statusEscriturada" type="button" class="btn btn-xs btn-link" (click)="exportExcel(xmlEscriturado, 'Notas escrituradas', colEmitidas)"
                  matTooltip="Exportar Excel" matTooltipPosition="above">
                  <i class="far fa-file-excel excel"></i>
                </button>
                <button *ngIf="statusCancelada" type="button" class="btn btn-xs btn-link" (click)="exportExcel(xmlCancelado, 'Notas canceladas', colCanceladas)"
                  matTooltip="Exportar Excel" matTooltipPosition="above">
                  <i class="far fa-file-excel excel"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeNotasFiscais()"
                  (click)="changeStateNotasFiscais()">
                  <span *ngIf="isCardNotasFiscais" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_more</span>
                  <span *ngIf="!isCardNotasFiscais" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_less</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentStateNotasFiscais">
            <div [ngStyle]="
                statusPendente ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusPendente' }">
              <p-table class="table-system" #dt [value]="xmlPendente" [rows]="rows" [pageLinks]="pageLinks"
                appendTo="body" (onFilter)="onFilter($event)" [paginator]="true" (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                        <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon field="emissao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueDataEmissao"
                        (input)="
                          dt.filter(
                            filterValueDataEmissao,
                            'emissao',
                            'contains'
                          )
                        " />
                    </th>
                    <th class="text-left" style="width: 30%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="nome">
                        <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL
                          <p-sortIcon field="nome"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueRazaoSocial"
                        (input)="
                          dt.filter(filterValueRazaoSocial, 'nome', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="cpfCnpj">
                        <span class="text-overflow-dynamic-ellipsis">CNPJ/CPF
                          <p-sortIcon field="cpfCnpj"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpjCpf" (input)="
                          dt.filter(filterValueCnpjCpf, 'cpfCnpj', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="numNf">
                        <span class="text-overflow-dynamic-ellipsis">NÚMERO DOC
                          <p-sortIcon field="numNf"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumeroDoc"
                        (input)="
                          dt.filter(filterValueNumeroDoc, 'numNf', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="chave">
                        <span class="text-overflow-dynamic-ellipsis">CHAVE <p-sortIcon field="chave"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueChave" (input)="
                          dt.filter(filterValueChave, 'chave', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 17%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="modelo">
                        <span class="text-overflow-dynamic-ellipsis">MODELO <p-sortIcon field="modelo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueModelo" (input)="
                          dt.filter(filterValueModelo, 'modelo', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 6%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon field="valor"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor" (input)="
                          dt.filter(filterValueValor, 'valor', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="status">
                        <span class="text-overflow-dynamic-ellipsis">status <p-sortIcon field="status"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueStatus" (input)="
                          dt.filter(filterValueStatus, 'status', 'contains')
                        " />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.emissao }}">{{ rowData.emissao }}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.nome }}">{{ rowData.nome }}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.cpfCnpj }}">
                          {{ rowData.cpfCnpj }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.numNf }}">
                          {{ rowData.numNf }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.chave }}">
                          {{ rowData.chave }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.modelo }}">
                          {{ rowData.modelo }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.valor }}">
                          {{ rowData.valor }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.status }}">
                          {{ rowData.status }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="9" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro
                        encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusEscriturada ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusEscriturada' }">
              <p-table class="table-system" #dt1 [value]="xmlEscriturado" [rows]="rows" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" (onFilter)="onFilter($event)" [style]="{ width: '100%' }"
                [paginator]="true" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                selectionMode="multiple" (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                        <span class="text-overflow-dynamic-ellipsis">EMISSÃO
                          <p-sortIcon field="emissao"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueEmissao2" (input)="
                          dt1.filter(filterValueEmissao2, 'emissao', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 23%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="nome">
                        <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL
                          <p-sortIcon field="nome"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueRazaoSocial2"
                        (input)="
                          dt1.filter(
                            filterValueRazaoSocial2,
                            'nome',
                            'contains'
                          )
                        " />
                    </th>
                    <th class="text-left" style="width: 12%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="cpfCnpj">
                        <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon field="cpfCnpj"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpj2" (input)="
                          dt1.filter(filterValueCnpj2, 'cpfCnpj', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">COD CONTROLE
                          <p-sortIcon field="codigoControle"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCodigoControle2"
                        (input)="
                          dt1.filter(
                            filterValueCodigoControle2,
                            'codigoControle',
                            'contains'
                          )
                        " />
                    </th>
                    <th class="text-left" style="width: 12%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedido">
                        <span class="text-overflow-dynamic-ellipsis">pedido <p-sortIcon field="pedido"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValuePedido2" (input)="
                          dt1.filter(filterValuePedido2, 'pedido', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="numNf">
                        <span class="text-overflow-dynamic-ellipsis">NÚMERO DOC
                          <p-sortIcon field="numNf"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumeroDoc2"
                        (input)="
                          dt1.filter(filterValueNumeroDoc2, 'numNf', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="modelo">
                        <span class="text-overflow-dynamic-ellipsis">modelo <p-sortIcon field="modelo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueModelo2" (input)="
                          dt1.filter(filterValueModelo2, 'modelo', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 16%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                        <span class="text-overflow-dynamic-ellipsis">valor <p-sortIcon field="valor"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor2" (input)="
                          dt1.filter(filterValueValor2, 'valor', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">usuario
                          <p-sortIcon field="usuario"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueUsuario2" (input)="
                          dt1.filter(filterValueUsuario2, 'usuario', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="status">
                        <span class="text-overflow-dynamic-ellipsis">status <p-sortIcon field="status"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueStatus2" (input)="
                          dt1.filter(filterValueStatus2, 'status', 'contains')
                        " />
                    </th>


                    <th class="text-center" style="width: 4%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'contQtde'">
                        <span class="text-overflow-dynamic-ellipsis">Cont <p-sortIcon [field]="'contQtde'"></p-sortIcon>
                          </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'contQtde', 'contains')">
                    </th>

                    <th class="text-center" style="width: 4%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'estQtde'">
                        <span class="text-overflow-dynamic-ellipsis">Estoque <p-sortIcon [field]="'estQtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'estQtde', 'contains')">
                    </th>

                    <th class="text-center" style="width: 4%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'finQtde'">
                        <span class="text-overflow-dynamic-ellipsis">Financeiro <p-sortIcon [field]="'finQtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'finQtde', 'contains')">
                    </th>

                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.emissao }}">{{ rowData.emissao }}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.nome }}">{{ rowData.nome }}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.cpfCnpj }}">
                          {{ rowData.cpfCnpj }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.pedido }}">
                          {{ rowData.pedido }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.numNf }}">
                          {{ rowData.numNf }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.modelo }}">
                          {{ rowData.modelo }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ 'R$ '+rowData.valor }}">
                          R$ {{ rowData.valor }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.usuario }}">
                          {{ rowData.usuario }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedidos(rowData)"
                          matTooltip="{{ rowData.status }}">
                          {{ rowData.status }}
                        </span>
                      </span>
                    </td>


                    <td style="width: 5%;" class="text-center">

                      <i matTooltip="NF está lançada na Contabilidade" *ngIf="rowData.contQtde === 'SIM'"
                        class="fas fa-circle" style="color: green;"></i>
                      <i matTooltip="NF não está lançada na Contabilidade" *ngIf="rowData.contQtde !== 'SIM'"
                        class="fas fa-circle" style="color: red;"></i>

                    </td>

                    <td style="width: 5%;" class="text-center">

                      <i matTooltip="NF está lançada no Estoque" *ngIf="rowData.estQtde === 'SIM'" class="fas fa-circle"
                        style="color: green;"></i>
                      <i matTooltip="NF não está lançada no Estoque" *ngIf="rowData.estQtde !== 'SIM'"
                        class="fas fa-circle" style="color: red;"></i>

                    </td>

                    <td style="width: 5%;" class="text-center">

                      <i matTooltip="NF está lançada no Financeiro" *ngIf="rowData.finQtde === 'SIM'"
                        class="fas fa-circle" style="color: green;"></i>
                      <i matTooltip="NF não está lançada no Financeiro" *ngIf="rowData.finQtde !== 'SIM'"
                        class="fas fa-circle" style="color: red;"></i>

                    </td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="11" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro
                        encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusCancelada ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusCancelada' }">
              <p-table class="table-system" #dt2 [value]="xmlCancelado" [rows]="rows" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" [paginator]="true" (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                        <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon field="emissao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueData3" (input)="
                          dt2.filter(filterValueData3, 'emissao', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 35%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="nome">
                        <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL
                          <p-sortIcon field="nome"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueRazaoSocial3"
                        (input)="
                          dt2.filter(
                            filterValueRazaoSocial3,
                            'nome',
                            'contains'
                          )
                        " />
                    </th>
                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="cpfCnpj">
                        <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon field="cpfCnpj"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpj3" (input)="
                          dt2.filter(filterValueCnpj3, 'cpfCnpj', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 8%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="numNf">
                        <span class="text-overflow-dynamic-ellipsis">NÚMERO DOC
                          <p-sortIcon field="numNf"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumeroDoc3"
                        (input)="
                          dt2.filter(filterValueNumeroDoc3, 'numNf', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valor">
                        <span class="text-overflow-dynamic-ellipsis">valor <p-sortIcon field="valor"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor3" (input)="
                          dt2.filter(filterValueValor3, 'valor', 'contains')
                        " />
                    </th>
                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="status">
                        <span class="text-overflow-dynamic-ellipsis">status <p-sortIcon field="status"> </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueStatus3" (input)="
                          dt2.filter(filterValueStatus3, 'status', 'contains')
                        " />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.emissao }}">{{
                          rowData.emissao }}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.nome }}">{{ rowData.nome
                          }}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cpfCnpj }}">
                          {{ rowData.cpfCnpj }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.numNf }}">
                          {{ rowData.numNf }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valor }}">
                          {{ rowData.valor }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.status }}">
                          {{ rowData.status }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="7" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro
                        encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!--Valor Total Geral -->
  <div class="row mt-3">
    <div class="col">
      <div class="pull-left mr-3">
        <div class="div-card-total">
          <p class="titulo-card-total">Valor Total</p>
          <p class="valor-card-total">R$ {{ valorAtual | money }}</p>
        </div>
      </div>
      <div class="pull-left">
        <div class="div-card-total">
          <p class="titulo-card-total">Valor Selecionados</p>
          <p class="valor-card-total">R$ {{ valorSelecionados | money }}</p>
        </div>
      </div>
    </div>
  </div>

  <p-dialog header="Cancelar Nota Fiscal" [(visible)]="modalCancelarNfe" [style]="{ width: '700px' }" [modal]="true"
    styleClass="lks-modal" [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body"
    [closable]="true">
    <div class="form-row" *ngIf="modalCancelarNfe">
      <div class="form-group col-sm-12">
        <label>Motivo</label>
        <input class="form-control" type="text" pInputText [(ngModel)]="msgCancelamentoNfe" />
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12"></div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalCancelarNfe = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="cancelarNfe()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
  </p-dialog>

  <!-- Modal Alterar Período FEITO-->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{ width: '400px' }" [modal]="true"
    styleClass="modal-initial" [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body"
    [closable]="true">
    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px">
      <div class="ui-g-12"></div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)="closeModalPeriodo()">
            <i class="fas fa-undo"></i> Cancelar
          </button>
          <button type="button" class="btn btn-primary" (click)="listarNfse()">
            <i class="fas fa-pencil-alt"></i> Alterar
          </button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Carta Correção Nota Fiscal" [(visible)]="modalCartaCorrecao" [style]="{ width: '700px' }"
    [modal]="true" styleClass="lks-modal" [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body"
    [closable]="true">
    <div class="form-row" *ngIf="modalCartaCorrecao">
      <div class="form-group col-sm-12">
        <label>Motivo</label>
        <input class="form-control" type="text" pInputText [(ngModel)]="msgCartaCorrecaoNfe" />
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12"></div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalCartaCorrecao = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="enviarCartaCorrecao()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
  </p-dialog>
</mat-card>