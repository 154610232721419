<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        País
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="box-content" style="border: none;">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registrosPaises" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
                immutable="false"
            >
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-left" style="width: 10%;">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'id'">
                        ID
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'id', 'contains')">
                    </th>

                    <th class="text-left" style="width: 70%;">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'nome'">
                        País
                        <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nome', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="overflow-ellipse text-left" [pSortableColumn]="'codigoBacen'">
                        Código BACEN <p-sortIcon [field]="'codigoBacen'"></p-sortIcon>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigoBacen', 'contains')">
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.id}}">
                          {{rowData.id}}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                          {{rowData.nome}}
                        </span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigoBacen}}">
                          {{rowData.codigoBacen}}
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="3" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>