<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-th-list" style="font-size: 15px;"></i>GRUPO DE EMPRESAS</h3>
              <div class="actions-card">
                <button type="button" (click)="openNovoGrupo()" class="btn btn-xs btn-link"
                  matTooltip="Adicionar Novo Grupo" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 28px;">add</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideGrupo()"
                  (click)="changeStateGrupo()">
                  <span *ngIf="isCardGrupo" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardGrupo" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateGrupo>
            <div class="row">
              <div class="col-sm-12">
                <p-table class="table-system" #dt1 [value]="listaGruposEmpresas" [rows]="rows" [paginator]="true"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
                  immutable="false">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" style="width: 8%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo_grupo'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo_grupo'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'codigo_grupo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 50%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_grupo'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon
                              [field]="'descricao_grupo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'descricao_grupo', 'contains')">
                      </th>
                      <th class="text-center" style="width: 15%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtcriacao_grupo'">
                          <span class="text-overflow-dynamic-ellipsis">DATA DE CRIAÇÃO <p-sortIcon
                              [field]="'dtcriacao_grupo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'dtcriacao_grupo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'responsavel_grupo'">
                          <span class="text-overflow-dynamic-ellipsis">CRIADO POR <p-sortIcon
                              [field]="'responsavel_grupo'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'responsavel_grupo', 'contains')">
                      </th>
                      <th class="text-center" style="width: 80px; vertical-align: sub;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                        </span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            (click)='onRowSelect(rowData)'>{{rowData.cod_grupo}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            (click)='onRowSelect(rowData)'>{{rowData.descricao_grupo}}</span>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            (click)='onRowSelect(rowData)'>{{rowData.create_timestamp | date : 'dd/MM/yyyy' :
                            '+000'}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            (click)='onRowSelect(rowData)'>{{rowData.user_full_name}}</span>
                        </span>
                      </td>
                      <td class="text-center">
                        <div style="display: flex;justify-content: center;">
                          <button
                            type="button" style="padding: 0 !important;"
                            (click)="openEditGrupo(rowData)" class="btn btn-xs btn-link btn-full"
                            matTooltip="Editar" matTooltipPosition="above">
                            <span class="material-icons" style="font-size: 22px">edit</span>
                          </button>
                          <button type="button"
                            style="padding: 0 !important;"
                            (click)="excluirGrupo(rowData)"
                            class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                            <span class="material-icons" style="font-size: 18px">delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="5" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 pb-3" *ngIf="rowSelected">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-object-group" style="font-size: 15px;"></i>RELACIONAR EMPRESAS À
                GRUPOS</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideRelacionar()"
                  (click)="changeStateRelacionar()">
                  <span *ngIf="isCardRelacionar" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardRelacionar" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateRelacionar>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-users"></i> EMPRESAS</h3>
                <div class="conteudo m-0">
                  <p-pickList *ngIf="picklistState" sourceHeader="Empresas Disponíveis" targetHeader="Empresas Vinculadas"
                    [source]="listaEmpresasDisponiveis" [target]="listaEmpresasVinculadas" [dragdrop]="true"
                    [responsive]="true" filterBy="razaoSocial" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-empresa pTemplate="item">{{ empresa.razaoSocial }}
                    </ng-template>
                  </p-pickList>
                </div>
                <button type="button" class="btn btn-primary mt-3" (click)='salvarRelacao();'><i
                    class="fas fa-check"></i> Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <p-dialog header="Novo Grupo de Empresas" [(visible)]="modalNewGroup" [style]="{width: '900px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Código</label>
        <input type="number" class="form-control text-left" placeholder="Insira o Código" [(ngModel)]="codigoGrupoNew">
      </div>
      <div class="form-group col-sm-12">
        <label>Descrição</label>
        <input type="text" class="form-control text-left" placeholder="Insira a Descrição"
          [(ngModel)]="descricaoGrupoNew">
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalNewGroup=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="addNovoGrupo();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Editar Grupo de Empresas" [(visible)]="modalEditGrupo" [style]="{width: '900px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Código</label>
        <input type="number" class="form-control text-left" placeholder="Código" [(ngModel)]="codigoEditGrupo">
      </div>
      <div class="form-group col-sm-12">
        <label>Nome</label>
        <input type="text" class="form-control text-left" placeholder="Nome do Funcionário"
          [(ngModel)]="descricaoEditGrupo">
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalEditGrupo=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="editGrupo();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

</mat-card>