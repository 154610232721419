<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content">
      <div *ngIf="botaoNovo">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Informações Cadastrais</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>NOME</label>
            <input class="form-control" [(ngModel)]="seminarista.nome" type="text" maxlength="500" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>EMAIL</label>
            <input class="form-control" [(ngModel)]="seminarista.email" type="text" maxlength="250" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>FONE</label>
            <p-inputMask class="type-p-mask" [(ngModel)]="seminarista.telefone" placeholder="(99) 99999-999?9"
              mask="(99) 99999-999?9"> </p-inputMask>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>URL OUTLOOK</label>
            <input class="form-control" [(ngModel)]="seminarista.urlOutlookEmail" type="text" />
          </div>
        </div>

      </div>


      <div *ngIf="!botaoNovo">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title mt-0"><i class="fas fa-bars"> </i> Seminarista</h3>
          </div>
        </div>

        <!-- <div class="pull-right align-down-table">
          <button type="button" class="btn btn-link" (click)="dt2.exportCSV()"><i class="fas fa-file-excel excel"></i>
            Exportar para Excel</button>
        </div> -->
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsOrigemRequisicao" [value]="origemRequisicao"
          [paginator]="true" [rows]="rows" [totalRecords]="origemRequisicao.length" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                </span>
              </th>

              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nome', 'contains')">
              </th>

              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                  <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'email', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'telefone'">
                  <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'telefone'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'telefone', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'urlOutlookEmail'">
                  <span class="text-overflow-dynamic-ellipsis">Url OUTLOOK <p-sortIcon [field]="'urlOutlookEmail'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'urlOutlookEmail', 'contains')">
              </th>


            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="onSelect(rowData)" [value]="rowData"></p-tableCheckbox>
              </td>

              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.nome}}">{{rowData?.nome }}</span>
                </span>
              </td>

              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.email}}">{{rowData?.email
                    }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData?.telefone}}  ">
                    {{rowData?.telefone}} </span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip=" {{rowData?.urlOutlookEmail}}  ">{{rowData?.urlOutlookEmail}} </span>
                </span>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="5" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>




      </div>
    </div>
  </mat-card-content>

</mat-card>