<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div  [ngClass]="formscreen ? 'col-sm-6' : 'col-sm-12'">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">
                Alíquota tributo
              </h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
            <div class="box-content" *ngIf="tablescreen">
              <div class="row">
                <div class="col-sm-12">
                  <p-table class="table-system" #dt [value]="dataTable" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" selectionMode="single"
                  [(selection)]="rowSelected" (onRowSelect)="onRowSelect()" (onRowUnselect)="onRowUnselect()">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="text-left">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                            <span class="text-overflow-dynamic-ellipsis">
                              Nome <p-sortIcon [field]="'nome'"></p-sortIcon>
                            </span>
                          </span>
                          <input #nome class="form-control form-control-sm" type="text" (input)="dt.filter(nome.value, 'nome', 'contains')">
                        </th>
                        <th class="text-left">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'impostoRegraFiscal.nome'">
                            <span class="text-overflow-dynamic-ellipsis">
                              Imposto <p-sortIcon [field]="'impostoRegraFiscal.nome'"></p-sortIcon>
                            </span>
                          </span>
                          <input #imposto class="form-control form-control-sm" type="text" (input)="dt.filter(imposto.value, 'impostoRegraFiscal.nome', 'contains')">
                        </th>
                        <th class="text-center">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliquotaImposto'">
                            <span class="text-overflow-dynamic-ellipsis">
                              Alíquota <p-sortIcon [field]="'aliquotaImposto'"></p-sortIcon>
                            </span>
                          </span>
                          <input #aliquota class="form-control form-control-sm" type="text" (input)="dt.filter(aliquota.value, 'aliquotaImposto', 'contains')">
                        </th>
                        <th class="text-center">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'reducaoBc'">
                            <span class="text-overflow-dynamic-ellipsis">
                              Redução bc <p-sortIcon [field]="'reducaoBc'"></p-sortIcon>
                            </span>
                          </span>
                          <input #reducaoBc class="form-control form-control-sm" type="text" (input)="dt.filter(reducaoBc.value, 'reducaoBc', 'contains')">
                        </th>
                        <th class="text-center">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorPauta'">
                            <span class="text-overflow-dynamic-ellipsis">
                              Valor pauta <p-sortIcon [field]="'valorPauta'"></p-sortIcon>
                            </span>
                          </span>
                          <input #valorPauta class="form-control form-control-sm" type="text" (input)="dt.filter(valorPauta.value, 'valorPauta', 'contains')">
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData.id">
                        <td class="text-left">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                          </span>
                        </td>
                        <td class="text-left">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.impostoRegraFiscal.nome}}">{{rowData.impostoRegraFiscal.nome}}</span>
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliquotaImposto}}">{{rowData.aliquotaImposto}}</span>
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reducaoBc}}">{{rowData.reducaoBc}}</span>
                          </span>
                        </td>
                        <td class="text-center">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorPauta}}">R$ {{rowData.valorPauta || '0.00'}}</span>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="5" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
            <div class="box-content" *ngIf="formscreen">
              <div class="col-sm-12">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>Nome<span class="obrigatorio">*</span></label>
                    <input class="form-control" type="text" pInputText [(ngModel)]="nome">
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Imposto<span class="obrigatorio">*</span></label>
                    <p-dropdown placeholder="Selecione o imposto" [options]="impostoRegraFiscal" [filter]="true" appendTo="body" [(ngModel)]="impostoRegraFiscalValue"></p-dropdown>
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Alíquota<span class="obrigatorio">*</span></label>
                    <input class="form-control" type="number" pInputText [(ngModel)]="aliquotaImposto">
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Redução bc<span class="obrigatorio">*</span></label>
                    <input class="form-control" type="number" pInputText [(ngModel)]="reducaoBc">
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Valor pauta (R$)</label>
                    <input class="form-control" type="number" pInputText [(ngModel)]="valorPauta">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>