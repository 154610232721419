<mat-card class="card-linnks margin-linnks" style="margin-top: 15px !important;">
  <mat-card-title>
    PLANO DE CONTAS
    <mat-card-subtitle>
      RELATÓRIO PLANO DE CONTAS
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content">
      <p-table class="table-system" #dt [value]="planoContas" [rows]="rows" [paginator]="true" selectionMode="single"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-left" style="width: 40%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'descrCta', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'parent.codCta'">
                <span class="text-overflow-dynamic-ellipsis">CONTA SUPERIOR <p-sortIcon [field]="'parent.codCta'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'parent.codCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">INDICADOR <p-sortIcon [field]="'indCta'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'indCta', 'contains')">
            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'natConta'">
                <span class="text-overflow-dynamic-ellipsis">NATUREZA CONTA <p-sortIcon [field]="'natConta'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'natConta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData" [ngClass]="rowData.indCta === 'S'  ? 'bold ' : ''">
            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">
                  {{ rowData.codCta }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 40%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrCta}}">{{rowData.descrCta
                  }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nivel}}">{{rowData.nivel }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.parent.codCta}}">{{rowData.parent.codCta }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indCta}}">{{rowData.indCta }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.natConta}}">{{rowData.natConta}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="6" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>
<div *ngIf="isLoadingInProgress" class="loading-layer">
  <h6>Processamento em andamento, favor aguardar... {{loadingProgress}}%</h6>
  <p-progressBar [value]="loadingProgress" style="width: 40% !important"></p-progressBar>
</div>