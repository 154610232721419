<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{title}}
  </mat-card-title>

  <div class="row">
    <div class="col">
        <h3 class="sub-title"><i class="fas fa-chart-bar"></i> ARQUIVOS MAGNÉTICOS PARA EXPORTAR</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">

    </div>
  </div>
  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="empresa?.id">
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('SPEED_ICMS_IPI')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('SPEED_ICMS_IPI')" matTooltip="SPED ICMS/IPI" matTooltipPosition="above" style="width: 150px;"><span class="d-block">SPED ICMS/IPI </span></h5>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('EFD_PIS_COFINS')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('EFD_PIS_COFINS')" matTooltip="EFD CONTRIBUIÇÕES" matTooltipPosition="above" style="width: 150px;"><span class="d-block">EFD CONTRIBUIÇÕES </span></h5>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('NFS_CAMPINAS')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('NFS_CAMPINAS')" matTooltip="NFS CAMPINAS" matTooltipPosition="above" style="width: 150px;"><span class="d-block">NFS CAMPINAS </span></h5>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('NFS_21')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('NFS_21')" matTooltip="NF MODELO 21" matTooltipPosition="above" style="width: 150px;"><span class="d-block">NF MODELO 21 </span></h5>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('DIME_SC')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('DIME_SC')" matTooltip="DIME SC" matTooltipPosition="above" style="width: 150px;"><span class="d-block">DIME SC</span></h5>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('CAT42')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('CAT42')" matTooltip="CAT42" matTooltipPosition="above" style="width: 150px;"><span class="d-block">PORTARIA CAT42</span></h5>
            </div>
          </div>
      </div>
    </div>
  </div>



  <p-dialog header="SPED MENSAL" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial" [(visible)]="modalSpeedIcmsIpi" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione o mês</label>
          <p-dropdown class="type-p-dropdown" [options]="meses" [filter]="true" [(ngModel)]="mesSelecionado" placeholder="Selecione um mês"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>
          <p-checkbox [binary]="true" [(ngModel)]="exportarBlocoH"></p-checkbox>
          <label style="padding: 0 5px;">Exportar Bloco H</label>
        </label>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
        <button type="button" class="btn btn-secondary" (click)='modalSpeedIcmsIpi=false'><i class="fas fa-undo"></i> Cancelar</button>
        <button type="button" class="btn btn-primary" (click)='exportarSpeed();'><i class="fas fa-file-download"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="EFD CONTRIBUIÇÕES" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial" [(visible)]="modalEfd" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione o mês</label>
        <p-dropdown class="type-p-dropdown" [options]="meses" [filter]="true" [(ngModel)]="mesSelecionado" placeholder="Selecione um mês"></p-dropdown>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalEfd=false'><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)='exportarEfd();'><i class="fas fa-file-download"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="NF MOD 21" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial" [(visible)]="modalNf21" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione o mês</label>
        <p-dropdown class="type-p-dropdown" [options]="meses" [filter]="true" [(ngModel)]="mesSelecionado" placeholder="Selecione um mês"></p-dropdown>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalNf21=false'><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)='exportarNfComunic();'><i class="fas fa-file-download"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="DIME SC" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-scroll" [(visible)]="modalDimeSc" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione o mês</label>
          <p-dropdown class="type-p-dropdown" [options]="meses" [filter]="true" [(ngModel)]="mesSelecionado" placeholder="Selecione um mês"></p-dropdown>
      </div>
    </div>

    <div class="form-row">
      <div class="col-sm-12">
        <h3 class="sub-title"> Registro 31 - Débitos Específicos</h3>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Item (Consultar Quadro 10)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="codigo31" maxlength="3"/>
      </div>
      <div class="form-group col-sm-8">
        <label>Valor</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="valorDime" money />
      </div>
    </div>


    <div class="form-row">
      <div class="col-sm-12">
        <h3 class="sub-title"> Registro 33 - Discriminação de débitos específicos</h3>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12"> <label>Inform. 1</label></div>

    </div>

    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Origem (nº 01)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="origem33" maxlength="1"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Cód. Receita (nº 04)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="codigoReceita33" maxlength="4"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Vencimento (nº 05)</label>
        <p-calendar appendTo="body" (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="dtVencimentoReg33" [monthNavigator]="true"
            [(ngModel)]="dtVencimentoReg33" [yearNavigator]="true" [defaultDate]="defaultDate"
            yearRange="2010:2030"></p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Valor (nº 06)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="valorReg33" money />
      </div>
      <div class="form-group col-sm-4">
        <label>Classe Venc. (nº 07)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="classeVencimentoReg33" maxlength="5"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Num Acordo. (nº 08)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="numAcordoReg33" maxlength="15"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12"> <label>Inform. 2</label></div>

    </div>

    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Origem (nº 01)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="origem332" maxlength="1"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Cód. Receita (nº 04)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="codigoReceita332" maxlength="4"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Vencimento (nº 05)</label>
        <p-calendar appendTo="body" (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="dtVencimentoReg332" [monthNavigator]="true"
            [(ngModel)]="dtVencimentoReg332" [yearNavigator]="true" [defaultDate]="defaultDate"
            yearRange="2010:2030"></p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Valor (nº 06)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="valorReg332" money />
      </div>
      <div class="form-group col-sm-4">
        <label>Classe Venc. (nº 07)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="classeVencimentoReg332" maxlength="5"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Num Acordo. (nº 08)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="numAcordoReg332" maxlength="15"/>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12"> <label>Inform. 3</label></div>

    </div>

    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Origem (nº 01)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="origem333" maxlength="1"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Cód. Receita (nº 04)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="codigoReceita333" maxlength="4"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Vencimento (nº 05)</label>
        <p-calendar appendTo="body" (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="dtVencimentoReg333" [monthNavigator]="true"
            [(ngModel)]="dtVencimentoReg333" [yearNavigator]="true" [defaultDate]="defaultDate"
            yearRange="2010:2030"></p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Valor (nº 06)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="valorReg333" money />
      </div>
      <div class="form-group col-sm-4">
        <label>Classe Venc. (nº 07)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="classeVencimentoReg333" maxlength="5"/>
      </div>
      <div class="form-group col-sm-4">
        <label>Num Acordo. (nº 08)</label>
        <input class="form-control text-right" type="text" pInputText
          [(ngModel)]="numAcordoReg333" maxlength="15"/>
      </div>
    </div>


    <div class="form-row">
      <div class="col-sm-12">
        <h3 class="sub-title"> Registro 46 - Créditos apurados por Aut. Especial</h3>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12"> <label>Inform. 1</label></div>

    </div>

    <div class="form-row">
      <div class="form-group col-sm-10">
        <label>Identificação do Regime ou da Autorização (nº 04)</label>
        <input class="form-control text-right" type="text" pInputText
        [(ngModel)]="identificacaoReg46" maxlength="15"/>
      </div>
      <div class="form-group col-sm-2">
        <label>Origem (nº 06)</label>
        <input class="form-control text-right" type="text" pInputText
        [(ngModel)]="origemReg46"/>
      </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12"> <label>Inform. 2</label></div>

      </div>

    <div class="form-row">
      <div class="form-group col-sm-10">
        <label>Identificação do Regime ou da Autorização (nº 04)</label>
        <input class="form-control text-right" type="text" pInputText
        [(ngModel)]="identificacaoReg462" maxlength="15"/>
      </div>
      <div class="form-group col-sm-2">
        <label>Origem (nº 06)</label>
        <input class="form-control text-right" type="text" pInputText
        [(ngModel)]="origemReg462"/>
      </div>
      </div>


    <p-footer>
        <button type="button" class="btn btn-secondary" (click)='modalDimeSc=false'><i class="fas fa-undo"></i> Cancelar</button>
        <button type="button" class="btn btn-primary" (click)='exportarDime();'><i class="fas fa-file-download"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="NFS CAMPINAS" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial" [(visible)]="modalNfs" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA INICIAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="defaultDate" yearRange="2010:2030"  [(ngModel)]="dtInicial">
        </p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>DATA FINAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
        [defaultDate]="defaultDate" yearRange="2010:2030"  [(ngModel)]="dtFinal">
      </p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Tipo de Serviço</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="idRelatorio" [options]="listRelatorio"  [filter]="false"
            placeholder="Selecionar um tipo de serviço"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>
          <p-checkbox [binary]="true" [(ngModel)]="partOutroMunicipio"></p-checkbox>
          <label style="padding: 0 5px;">Participante de outro município</label>
        </label>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalNfs=false'><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)='exportarNfs();' [disabled]="idRelatorio === 0"><i class="fas fa-file-download"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="ARQUIVO NO FORMATO DA PORTARIA CAT42" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial" [(visible)]="modalCat42" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione o mês</label>
          <p-dropdown class="type-p-dropdown" [options]="meses" [filter]="true" [(ngModel)]="mesSelecionado" placeholder="Selecione um mês"></p-dropdown>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">
      </div>
    </div>
    <p-footer>
        <button type="button" class="btn btn-secondary" (click)='modalCat42=false'><i class="fas fa-undo"></i> Cancelar</button>
        <button type="button" class="btn btn-primary" (click)='exportarCat42();'><i class="fas fa-file-download"></i> Exportar</button>
    </p-footer>
  </p-dialog>
</mat-card>
