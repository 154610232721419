<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <div [ngStyle]="TelaSubarea ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'TelaSubarea'}">
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">

          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{active: statusTelaArea}"><a
                    (click)="showTelaAreas('TELA_1')">ÁREA</a></li>
                <li class="list-inline-item" [ngClass]="{active: statusTelaSubarea}"><a
                    (click)="showTelaAreas('TELA_2')">SUBÁREAS</a></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div [ngStyle]="statusTelaArea ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusTelaArea'}">

            <div class="box-content" [@changeDivSize]=currentStateTable>
              <div class="clearfix"></div>

              <p-table
                class="table-system"
                #dt
                [columns]="colslistaAreas"
                [value]="listaAreas"
                [rows]="rows"
                [paginator]="true"
                [paginator]="true"
                [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}"
                [scrollable]="false"
                scrollHeight="20vh"
                (onHeaderCheckboxToggle)="changeToolbar()"
                (onRowSelect)="changeToolbar()"
                (onRowUnselect)="changeToolbar()"
                [(selection)] = "selectedAreas"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 60px;">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 65%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                        <span class="text-overflow-dynamic-ellipsis">ÚLTIMA EDIÇÃO <p-sortIcon [field]="'data'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'data', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 60px;">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}"
                          (click)="onAreaRowSelect(rowData)">{{rowData.codigo
                          }}</span>
                      </span>
                    </td>
                    <td class=" text-left" style="width: 65%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.data | date:'dd/MM/yyyy'}}">{{rowData.data | date:'dd/MM/yyyy'}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>

          <div [ngStyle]="statusTelaSubarea ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusTelaSubArea'}">
            <div class="box-content" [@changeDivSize]=currentStateTable>

              <div class="clearfix"></div>

              <p-table
                class="table-system"
                [columns]="colslistaSubareas"
                #dt1 [value]="listaSubareas"
                [rows]="rows"
                [paginator]="true"
                [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}"
                [scrollable]="false"
                scrollHeight="20vh"
                (onHeaderCheckboxToggle)="changeToolbar()"
                (onRowSelect)="changeToolbar()"
                (onRowUnselect)="changeToolbar()"
                [(selection)]="selectedSubAreas"
              >
                <ng-template pTemplate="header">
                  <tr>

                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox ></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                    </th>

                    <th class="text-left" style="width: 65%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                    </th>

                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                        <span class="text-overflow-dynamic-ellipsis">ÚLTIMA EDIÇÃO <p-sortIcon [field]="'data'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'data', 'contains')">
                    </th>


                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 60px;">
                      <p-tableCheckbox [value]="rowData" [(ngModel)]="rowData.selected">
                      </p-tableCheckbox>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                          }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 65%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.data | date:'dd/MM/yyyy'}}">{{rowData.data | date:'dd/MM/yyyy'}}</span>
                      </span>
                    </td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngStyle]="TelaCond ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'TelaCond'}">
      <div class="row">
        <div class="col-sm-12 pb-4">
          <div class="shadow-box">

            <div class="header-box">
              <div class="header-box-content">

                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: statusTelaSub}" *ngIf="statusTelaArea">
                    <a (click)="showTelaAreas('TELA_SUB')">
                      SUBÁREAS
                    </a>
                  </li>
                </ul>

                <div class="actions-card">
                  <app-toolbar [value]="toolbarMainSub"></app-toolbar>
                </div>

              </div>
            </div>

            <div class="box-content" [@changeDivSize]=currentStateCond>
              <div [ngStyle]="statusTelaArea ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusTelaArea'}" *ngIf="statusTelaArea">
                <div class="row">
                  <div class="col-sm-8">
                    <h3 class="sub-title"><i class="fas fa-exchange-alt"></i> VINCULAÇÃO DE SUBÁREAS À ÁREAS</h3>
                    <div class="conteudo mt-2">
                      <p-pickList sourceHeader="Disponíveis" targetHeader="Vinculadas"
                        [source]="listaAreaSubareaDisponiveis" [target]="listaAreaSubareaVinculados" [dragdrop]="true"
                        [responsive]="true" filterBy="codigo" [showSourceControls]="false" [showTargetControls]="false">
                        <ng-template let-projeto pTemplate="item">{{ projeto.codigo }} - {{ projeto.descricao }}
                        </ng-template>
                      </p-pickList>
                    </div>
                  </div>
                </div>

                <div class="form-row mt-3">
                  <div class="form-group col-sm-12">
                    <button type="button" class="btn btn-primary" (click)="salvarVinculacaoAreaSubarea()">
                      <i class="fas fa-check"></i>
                      Salvar
                    </button>
                  </div>
                </div>

                <div class="form-group col-sm-12">
                  <!--SEM FUNÇÃO DE SALVAR CONDIÇÃO-->
                  <!-- <button type="button" class="btn btn-primary" (click)="salvarCondicao()">
                    <i class="fas fa-check"></i>
                    Salvar
                  </button> -->
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<!-- MODAL NOVA ÁREA/SUBÁREA -->
<p-dialog header="{{statusTelaArea ? 'Adicionar Área': 'Adicionar Subárea'}}" [(visible)]="modalNew"
  [style]="{width: '400px'}" [modal]="true" styleClass="modal-initial" [responsive]="true"
  [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código<span class="obrigatorio">*</span></label>
      <input class="form-control text-left" placeholder="Código" [(ngModel)]="newCodigo">
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição<span class="obrigatorio">*</span></label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="newDescricao">
    </div>
    <div class="form-group col-sm-12" *ngIf="!statusTelaArea">
      <label>Condição</label>
      <textarea class="form-control" rows="5" pInputTextarea value="newCondicao" [(ngModel)]="newCondicao"></textarea>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addAreaSubarea();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>

<!-- MODAL EDIT ÁREA/SUBÁREA -->
<p-dialog header="{{statusTelaArea ? 'Editar Área': 'Editar Subárea'}}" [(visible)]="modalEdit"
  [style]="{width: '400px'}" [modal]="true" styleClass="modal-initial" [responsive]="true"
  [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código<span class="obrigatorio">*</span></label>
      <input class="form-control text-left" style="text-transform: uppercase;" placeholder="Código"
        [(ngModel)]="editCodigo">
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição<span class="obrigatorio">*</span></label>
      <input class="form-control text-left" style="text-transform: uppercase;" placeholder="Descrição"
        [(ngModel)]="editDescricao">
    </div>
    <div class="form-group col-sm-12" *ngIf="!statusTelaArea">
      <label>Condição</label>
      <textarea class="form-control" rows="5" pInputTextarea value="editCondicao" [(ngModel)]="editCondicao"></textarea>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editAreaSubarea();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>


<p-dialog header="Vincular Centro de Custo" (onHide)="onHideModalVinculacaoCentroCusto()" [(visible)]="modalVincularCentroCusto" [style]="{width: '700px'}" [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione os centros de custo a serem relacionados</label>
      <p-pickList sourceHeader="Disponíveis" targetHeader="Vinculados" onClos
        [source]="listaCentrosCustoDisponiveis" [target]="listaCentrosCustoVinculados"
        [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
        <ng-template let-projeto pTemplate="item">{{ projeto.id }} - {{ projeto.descricao }}</ng-template>
      </p-pickList>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-primary" (click)="salvarRelacaoAreaCentroCusto()">
      <i class="fas fa-check"></i>
      Salvar
    </button>
  </p-footer>

</p-dialog>