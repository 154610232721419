<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <mat-card-content>
    <div class="box-content pt-0" *ngIf="showLista">
      <div class="row">

        <div class="col-sm-12">
          <div class="clearfix"></div>

          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card mt-2">
                <app-toolbar [value]="toolbarContaBanc"></app-toolbar>
              </div>
            </div>
          </div>

          <p-table class="table-system" #dt1 [value]="lista" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
            [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{ width: '100%' }">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 5%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                    <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'id', 'contains')">
                </th>
                <th class="text-center" style="width: 13%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                    <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'nome', 'contains')">
                </th>
                <th class="text-center" style="width: 13%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoConta.descricao'">
                    <span class="text-overflow-dynamic-ellipsis">TIPO DA CONTA <p-sortIcon
                        [field]="'tipoConta.descricao'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'tipoConta.descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda.codigo'">
                    <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'moeda.codigo'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'moeda.codigo', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'instituicaoFinanceira.descricao'">
                    <span class="text-overflow-dynamic-ellipsis">INST. FIN. <p-sortIcon
                        [field]="'instituicaoFinanceira.descricao'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'instituicaoFinanceira.descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'agencia'">
                    <span class="text-overflow-dynamic-ellipsis">AGÊNCIA <p-sortIcon [field]="'agencia'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'agencia', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dvAgencia'">
                    <span class="text-overflow-dynamic-ellipsis">DV AG. <p-sortIcon [field]="'dvAgencia'">
                      </p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'dvAgencia', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'conta'">
                    <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'conta'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'conta', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dvConta'">
                    <span class="text-overflow-dynamic-ellipsis">DV CTA. <p-sortIcon [field]="'dvConta'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'dvConta', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldoInicial'">
                    <span class="text-overflow-dynamic-ellipsis">SALDO INI. <p-sortIcon [field]="'saldoInicial'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'saldoInicial', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtInicial'">
                    <span class="text-overflow-dynamic-ellipsis">DATA INI. <p-sortIcon [field]="'dtInicial'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'dtInicial', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtFinal'">
                    <span class="text-overflow-dynamic-ellipsis">DATA FIN. <p-sortIcon [field]="'dtFinal'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'dtFinal', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCtaPlanoContas'">
                    <span class="text-overflow-dynamic-ellipsis">Conta Contábil. <p-sortIcon
                        [field]="'codCtaPlanoContas'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'codCtaPlanoContas', 'contains')">
                </th>
                <th class="text-center" style="width: 12%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                    <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                    </span>
                  </span>
                  <div style="margin-top: -1px;">
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                      (onChange)="dt1.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td style="width: 5%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.id }}"
                      (click)="loadComponent('edit', rowData)">{{rowData.id}}</span>
                  </span>
                </td>
                <td style="width: 13%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.nome }}"
                      (click)="loadComponent('edit', rowData)">{{rowData.nome}}</span>
                  </span>
                </td>
                <td style="width: 13%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.tipoConta.descricao }}"
                      (click)="loadComponent('edit', rowData)">{{rowData.tipoConta.descricao}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{ rowData.moeda.codigo }} - {{ rowData.moeda.descricao }}"
                      (click)="loadComponent('edit', rowData)">
                      {{ rowData.moeda.codigo }} - {{ rowData.moeda.descricao }}
                    </span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{ rowData.instituicaoFinanceira !== null ? rowData.instituicaoFinanceira.descricao : null }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.instituicaoFinanceira !== null ?
                      rowData.instituicaoFinanceira.descricao : null }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      (click)="loadComponent('edit', rowData)">{{rowData.agencia}}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">{{
                      rowData.dvAgencia }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.conta }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.conta }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">{{
                      rowData.dvConta }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">{{
                      rowData.saldoInicial | money }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">{{
                      rowData.dtInicial | date: 'dd/MM/yyyy' }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">
                      {{rowData.dtFinal ? (rowData.dtFinal | date: 'dd/MM/yyyy') : ''}}
                    </span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">{{
                      rowData.codCtaPlanoContas }}</span>
                  </span>
                </td>
                <td style="width: 9%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)">{{
                      rowData.ativo ? 'SIM' : 'NÃO' }}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="14" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- EDIT | NEW -->
  <!-- ---------------------------------------------------------------------------------------- -->
  <div class="row">
    <div class="col-sm-6">
      <div class="box-content" *ngIf="showEdit || showNew">
        <div class="header-box">
          <div class="header-box-content">
            <div class="actions-card">
              <app-toolbar [value]="toolbarContaBanc"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group col-sm-12">
            <label>NOME</label>
            <input class="form-control" type="text" [(ngModel)]="empresaConta.nome" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>TIPO DA CONTA</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="tipContaList"
              [(ngModel)]="tipConta"></p-dropdown>
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>INSTITUIÇÃO FINANCEIRA</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="instFinanceiraList"
              [(ngModel)]="instFinanceira"></p-dropdown>
          </div>
        </div>

        <div class="form-row" *ngIf="instFinanceira === 116">
          <div class="form-group col-sm-12">
            <label>Código de transmissão</label>
            <input minlength="20" maxlength="20" type="text" class="form-control"
              [(ngModel)]="empresaConta.codigoTransmissao" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>MOEDA</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="moedasList"
              [(ngModel)]="moeda"></p-dropdown>
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>AGÊNCIA</label>
            <input type="text" class="form-control" [(ngModel)]="empresaConta.agencia" />
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>DV AGÊNCIA</label>
            <input maxlength="10" type="text" class="form-control" [(ngModel)]="empresaConta.dvAgencia" />
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>CONTA</label>
            <input type="text" class="form-control" [(ngModel)]="empresaConta.conta" />
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>DV CONTA</label>
            <input maxlength="10" type="text" class="form-control" [(ngModel)]="empresaConta.dvConta" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>SALDO INICIAL</label>
            <input type="number" class="form-control" [(ngModel)]="empresaConta.saldoInicial" />
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>NÚMERO DO CONVÊNIO</label>
            <input type="string" class="form-control" [(ngModel)]="convenio" />
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>TIPO CHAVE PIX</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="tipoChavesPix"
              [(ngModel)]="tipoChavePixId"></p-dropdown>
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>CHAVE PIX</label>
            <input type="text" class="form-control" [(ngModel)]="chavePix" />
          </div>
        </div>

        <div class="form-row" *ngIf="tipConta != 10">
          <div class="form-group col-sm-12">
            <label>CONTA CONTÁBIL</label><br>
            <button matTooltip="Alterar contas" (click)="loadContasUnicas()" style="cursor: pointer;"
              class="btn btn-info" type="button">
              {{ctContabil ? ctaSelecionada : 'Selecionar uma conta'}} <i class="fas fa-pen"></i></button>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
            <label>DATA INICIAL</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="defaultDate" yearRange="2010:2030" [(ngModel)]="empresaConta.dtInicial">
            </p-calendar>
          </div>
          <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
            <label>DATA CONCILIAÇÃO</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="defaultDate" yearRange="2010:2030" [(ngModel)]="empresaConta.dtFinal">
            </p-calendar>
          </div>
        </div>
        <div class="row mb-3">
          <div class="form-group col-sm-6 d-flex align-items-center">
            <mat-slide-toggle class="mr-1" [(ngModel)]="ativo" inputId="atv">Ativo</mat-slide-toggle>
          </div>
          <div class="form-group col-sm-6 d-flex align-items-center" *ngIf="tipConta != 10">
            <mat-slide-toggle class="mr-1" [(ngModel)]="pagamentosautomatizados" inputId="pga">Pagamentos Automatizados
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<!-- Modal contas únicas-->
<p-dialog [(visible)]="modalContasUnicas" [style]="{width: '60vw'}" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">
  <p-header style="display: flex;justify-content: space-between;width: 100%;">
    <label style="font-size: 1rem;font-weight: 600;">Escolher conta</label>
  </p-header>
  <p-table class="table-system" #dt5 [value]="listaContas" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
    [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [lazy]="true"
    (onLazyLoad)="getPlanoContas($event)" [totalRecords]="totalContas" dataKey="id"
    (onRowSelect)="selecionarContasUnica($event)" appendTo="body">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
            <span class="text-overflow-dynamic-ellipsis">Código <p-sortIcon [field]="'codCta'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'codCta', 'contains')">
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descrCta'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'descrCta', 'contains')">
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codNaturezaConta.descricao'">
            <span class="text-overflow-dynamic-ellipsis">Cód. natureza <p-sortIcon
                [field]="'codNaturezaConta.descricao'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'codNaturezaConta.descricao', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
            <span class="text-overflow-dynamic-ellipsis">Nível<p-sortIcon [field]="'nivel'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt5.filter($event.target.value, 'nivel', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.codCta}}">{{rowData.codCta}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 6%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 7%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.codNaturezaConta.descricao}}">{{rowData.codNaturezaConta.descricao}}</span>
          </span>
        </td>
        <td class="text-right" style="width: 7%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-primary" (click)="modalContasUnicas=false">
        <i class="fas fa-check"></i> Salvar
      </button>
    </div>
  </p-footer>
</p-dialog>