<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}

    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="row" [ngClass]="{'fade-in': 'isCardMov'}"
      *ngIf="!modalOperacaoPagto && !modalOperacao && !modalTransferenciaBancaria">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">&nbsp;</h3>
              <div class="actions-card d-flex">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateMov>
            <app-skeleton [config]="skeletonConfig">
              <p-table class="table-system table-mov" #dt2 [value]="movimentacao" [rows]="rows" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                [style]="{ width: '100%' }" [lazy]="true" (onLazyLoad)="getMoreTransactions($event)" [lazyLoadOnInit]="false"
                [totalRecords]="totalRecords" [columns]="cols" (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect()">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vcto'">
                        <span class="text-overflow-dynamic-ellipsis">VENCTO/BAIXA <p-sortIcon [field]="'dt_vcto'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'dt_vcto', 'contains')">
                    </th>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_previsao'">
                        <span class="text-overflow-dynamic-ellipsis">PREVISÃO <p-sortIcon [field]="'dt_previsao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'dt_previsao', 'contains')">
                    </th>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_emissao'">
                        <span class="text-overflow-dynamic-ellipsis">EMISSÃO <p-sortIcon [field]="'dt_emissao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'dt_emissao', 'contains')">
                    </th>
                    <th style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeConta'">
                        <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'nomeConta'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'nomeConta', 'contains')">
                    </th>
                    <th style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'nome', 'contains')">
                    </th>
                    <th style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'razaoSocial'">
                        <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL <p-sortIcon [field]="'razaoSocial'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'razaoSocial', 'contains')">
                    </th>
                    <th style="width: 12%;" *ngIf="relatorioAnalitico">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                        <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'operacao', 'contains')">
                    </th>
                    <th style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                        <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'historico', 'contains')">
                    </th>
                    <th style="width: 9%;" *ngIf="relatorioAnalitico">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nf'">
                        <span class="text-overflow-dynamic-ellipsis">DOC <p-sortIcon [field]="'nf'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'nf', 'contains')">
                    </th>
                    <th class="text-center" style="width: 5%;" *ngIf="relatorioAnalitico">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                        <span class="text-overflow-dynamic-ellipsis">Parc <p-sortIcon [field]="'parcela'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'parcela', 'contains')">
                    </th>
                    <th class="text-right" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'valor', 'contains')">
                    </th>
                    <th class="text-right" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldoAtual'">
                        <span class="text-overflow-dynamic-ellipsis">Saldo <p-sortIcon [field]="'saldoAtual'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'saldoAtual', 'contains')">
                    </th>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                        <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'"></p-sortIcon>
                        </span>
                      </span>
                      <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                        [options]="statusOptions" defaultLabel="Selecione" [filter]="false"
                        (onChange)="dt2.filter($event.value, 'status', 'in')"></p-multiSelect>
                    </th>
                  </tr>

                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">{{rowData.dt_vcto}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">{{rowData.dt_previsao}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">{{rowData.dt_emissao}}</span>
                      </span>
                    </td>
                    <td style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nomeConta}}">{{rowData.nomeConta}}</span>
                      </span>
                    </td>
                    <td style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                      </span>
                    </td>
                    <td style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.razaoSocial}}">{{rowData.razaoSocial}}</span>
                      </span>
                    </td>
                    <td style="width: 12%;" *ngIf="relatorioAnalitico">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
                      </span>
                    </td>
                    <td style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                      </span>
                    </td>
                    <td style="width: 9%;" *ngIf="relatorioAnalitico">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nf}}">{{rowData.nf}}</span>
                      </span>
                    </td>
                    <td style="width: 5%;" class="text-center" *ngIf="relatorioAnalitico">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          *ngIf="rowData.parcela > 0 ">{{rowData.parcela}}</span>
                      </span>
                    </td>
                    <td style="width: 9%;" class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.valor >= 0">{{rowData.valor | number : '1.2-2' :'pt-BR'}}</span>
                        <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.valor < 0" style="color:darkred;">{{rowData.valor | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td style="width: 9%;" class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.saldoAtual >= 0">{{rowData.saldoAtual | number : '1.2-2' : 'pt-BR'}} </span>
                        <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.saldoAtual < 0" style="color:darkred;">{{rowData.saldoAtual | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">{{rowData.status}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </app-skeleton>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-3" [ngStyle]="isCardMov ? {'display': 'block'} : {'display': 'none'}"
      [ngClass]="{'fade-in': 'isCardMov'}" *ngIf="!modalOperacaoPagto && !modalOperacao && !modalTransferenciaBancaria">
      <div class="col-sm-12 clearfix">
        <!--Saldo Inicial-->
        <div class="pull-left mr-3">
          <div class="div-card-total">
            <p class="titulo-card-total">Saldo Inicial</p>
            <p class="valor-card-total" *ngIf="saldoInicial >= 0">R$ {{ saldoInicial | money}}</p>
            <p class="valor-card-total" *ngIf="saldoInicial < 0" style="color:darkred;">R$ {{ saldoInicial | money}}</p>
          </div>
        </div>
        <!--Movimentação-->
        <div class="pull-left  mr-3">
          <div class="div-card-total">
            <p class="titulo-card-total">Movimentação</p>
            <p class="valor-card-total" *ngIf="valorFiltro >= 0">R$ {{ valorFiltro | money}}</p>
            <p class="valor-card-total" *ngIf="valorFiltro < 0" style="color:darkred;">R$ {{ valorFiltro | money}}</p>
          </div>
        </div>
        <!--Saldo Atual -->
        <div class="pull-left">
          <div class="div-card-total">
            <p class="titulo-card-total">Saldo Atual</p>
            <p class="valor-card-total" *ngIf="saldoAtual >= 0">R$ {{ saldoAtual | money}}</p>
            <p class="valor-card-total" *ngIf="saldoAtual < 0" style="color:darkred;">R$ {{ saldoAtual | money}}</p>
          </div>
        </div>
      </div>
    </div>

    <!--Card Novo Pagamento-->
    <div class="row" *ngIf="modalOperacaoPagto" [ngClass]="{'fade-in': 'modalOperacaoPagto'}">
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mb-2 mt-0">
          <li class="list-inline-item active"><a>Pagamento</a></li>
        </ul>
      </div>
      <div class="col-sm-8 col-xxl-6">
        <div class="box-content mb-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Novo pagamento</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Operação <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                placeholder="Selecione a Operação" [(ngModel)]="operacaoRegra.id"
                (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)"></p-dropdown>
              <!-- (onChange)="verificarDetalheOperacao()"-->
            </div>
            <div class="form-group col-sm-2">
              <label>Data <span class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtOperacao"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Fornecedor <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="clientes" placeholder="Selecione o Cliente"
                [(ngModel)]="cliente.codigo" (onChange)="onChangeContaFornecedor($event)"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Conta <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
                placeholder="Selecione a Conta" [(ngModel)]="contaSelecionadaId"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Valor <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorOperacao" money />
            </div>
          </div>

          <!-- MODIFICAÇÕES MODAL CNAB @octavio -->

          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Tipo de pagamento</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="tiposLista" placeholder="Selecione o tipo"
                [(ngModel)]="tipoSelecionadoId" (onChange)="onChangeTipoPagamento($event)"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>N Documento</label>
              <input class="form-control text-right" type="text" [(ngModel)]="nDocumento" />
            </div>
          </div>
          <!-- Para boletos (segmento J do Itaú) -->
          <div class="form-row" *ngIf="cnabFormaSelecionadaId === 1">
            <div class="form-group col-sm-12">
              <label>Código de Barras <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="codBarras" />
            </div>
          </div>
          <!-- fim segmento J -->
          <!-- para impostos do segmento O (com código de barras ou impostos estaduais) -->
          <div class="form-row" *ngIf="[6, 7].includes(cnabFormaSelecionadaId)">
            <div class="form-group col-sm-10">
              <label>Código de Barras <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="codBarras" />
            </div>
            <div class="form-group col-sm-2">
              <label>Apuração <span class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtApuracao"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
          </div>
          <!-- fim segmento O -->
          <!-- para impostos do segmento N + FGTS -->
          <div *ngIf="[2, 3, 4].includes(cnabFormaSelecionadaId)">
            <div class="form-row">
              <!-- para DARF -->
              <div class="form-group col-sm-10" *ngIf="cnabFormaSelecionadaId === 2">
                <label>Cód. Receita <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="codReceitaLista"
                  placeholder="Selecione o tipo" [(ngModel)]="codReceita"></p-dropdown>
              </div>
              <!-- para GPS -->
              <div class="form-group col-sm-10" *ngIf="cnabFormaSelecionadaId === 4">
                <label>CÓD. PAGAMENTO <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [(ngModel)]="codPagamentoGps" />
              </div>
              <!-- para FGTS -->
              <div class="form-group col-sm-10" *ngIf="cnabFormaSelecionadaId === 3">
                <label>CÓD. RECOLHIMENTO <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [(ngModel)]="codRecolhimento" />
              </div>
              <div class="form-group col-sm-2">
                <label>Apuração <span class="obrigatorio">*</span></label>
                <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
                  [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtApuracao"
                  dateFormat="dd/mm/yy">
                </p-calendar>
              </div>
            </div>
            <!-- para FGTS (segmento N) -->
            <div *ngIf="cnabFormaSelecionadaId === 3">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Código de Barras <span class="obrigatorio">*</span></label>
                  <input class="form-control text-right" type="text" [(ngModel)]="codBarras" />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-9">
                  <label>ID RECOLHIMENTO <span class="obrigatorio">*</span></label>
                  <input class="form-control text-right" type="text" [(ngModel)]="idRecolhimento" />
                </div>
                <div class="form-group col-sm-3">
                  <label>DÍGITO ID RECOLHIMENTO<span class="obrigatorio">*</span></label>
                  <input class="form-control text-right" type="text" [(ngModel)]="digitoIdRecolhimento" />
                </div>
              </div>
            </div>
          </div>
          <!-- fim segmento  -->
          <!-- Para transferências CC ou TED (segmento A no Itaú) -->
          <div class="form-row" *ngIf="cnabFormaSelecionadaId === 5">
            <div class="form-group col-sm-12">
              <label>Conta do Fornecedor <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="contasFornecedorLista"
                placeholder="Selecione o tipo" [(ngModel)]="participanteContasId"></p-dropdown>
            </div>
          </div>
          <!-- fim segmento A -->
          <!-- fim @octavio -->

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Histórico <span class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [(ngModel)]="historicoOperacao" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Recorrência</h3>
            </div>
          </div>
          <div class="p-field-checkbox mb-3">
            <p-checkbox class="mr-1" [(ngModel)]="recorrencia" [binary]="true" inputId="ir"></p-checkbox>
            <label for="ir">Informar recorrência</label>
          </div>
          <div class="form-row" *ngIf="recorrencia === true">
            <div class="form-group col-sm-2">
              <label>A cada (meses)</label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="meses" [(ngModel)]="ocorrenciaMes">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Data final</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dtFimOccorencia" dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
              <div class="p-field-checkbox">
                <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="adu"></p-checkbox>
                <label for="adu">Antecipa dia não útil</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Rateio</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" [value]="movimentacaoRateios">
                <!--pedidoItem.pedidoItensRateio-->
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35%;">CENTRO CUSTO</th>
                    <th style="width: 35%;">PROJETO</th>
                    <th style="width: 20%;" class="text-right">PORCENTAGEM</th>
                    <th style="width: 10%;" class="text-center">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 35%;">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}'>{{
                        rowData.cadCcus.codCcus }}
                        - {{ rowData.cadCcus.descrCcus }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="rowData.cadProj">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}'>{{
                        rowData.cadProj.codProj }}
                        - {{ rowData.cadProj.descrProj }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="!rowData.cadProj">
                    </td>
                    <td style="width: 20%;" class="text-right">
                      {{ rowData.porcentagem | money }}%
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="edit" matTooltip="Editar centro de custo" (click)="editarCentroCusto()">
                        <i class="fas fa-edit"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir centro de custo"
                        (click)="removerRateioCentroCusto(rowData)"
                        (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem rateio!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-2">
              <button type="button" class="btn btn-sm btn-info" (click)="controlCentroCusto()"><i
                  class="fas fa-plus"></i> Adicionar Rateio</button>
            </div>
          </div>
          <div class="form-row">
            <div class="col-sm-12 mt-4">
              <button type="button" class="btn btn-secondary" (click)='modalOperacaoPagto=false'><i
                  class="fas fa-undo"></i> Cancelar</button>
              <button type="button" class="btn btn-primary"
                [disabled]="!operacaoRegra.id || !dtOperacao || !historicoOperacao || !valorOperacao"
                (click)="salvarPagamento();"><i class="fas fa-check"></i> Salvar</button>
            </div>
          </div>
          <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
            <div class="ui-g-12">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Card Novo Recebimento-->
    <div class="row" *ngIf="modalOperacao" [ngClass]="{'fade-in': 'modalOperacao'}">
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mb-2 mt-0">
          <li class="list-inline-item active"><a>Recebimento</a></li>
        </ul>
      </div>
      <div class="col-sm-8 col-xxl-6">
        <div class="box-content mb-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Novo recebimento</h3>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Operação <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                placeholder="Selecione a Operação" [(ngModel)]="operacaoRegra.id"
                (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)"></p-dropdown>
              <!-- (onChange)="verificarDetalheOperacao()"-->
            </div>
            <div class="form-group col-sm-2">
              <label>Data <span class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtOperacao"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Cliente <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="clientes" placeholder="Selecione o Cliente"
                [(ngModel)]="cliente.codigo"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-10">
              <label>Conta <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
                placeholder="Selecione a Conta" [(ngModel)]="contaSelecionadaId"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Valor <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorOperacao" money />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Histórico <span class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [(ngModel)]="historicoOperacao" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Recorrência</h3>
            </div>
          </div>
          <div class="p-field-checkbox mb-3">
            <p-checkbox class="mr-1" [(ngModel)]="recorrencia" [binary]="true" inputId="ir"></p-checkbox>
            <label for="ir">Informar recorrência</label>
          </div>
          <div class="form-row" *ngIf="recorrencia === true">
            <div class="form-group col-sm-2">
              <label>A cada (meses)</label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="meses" [(ngModel)]="ocorrenciaMes">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Data final</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dtFimOccorencia" dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="col-sm-3 d-flex align-items-center">
              <div class="p-field-checkbox">
                <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="adu"></p-checkbox>
                <label for="adu">Antecipa dia não útil</label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Rateio</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" [value]="movimentacaoRateios">
                <!--pedidoItem.pedidoItensRateio-->
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35%;">CENTRO CUSTO</th>
                    <th style="width: 35%;">PROJETO</th>
                    <th style="width: 20%;" class="text-right">PORCENTAGEM</th>
                    <th style="width: 10%;" class="text-center">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 35%;">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}'>{{
                        rowData.cadCcus.codCcus }}
                        - {{ rowData.cadCcus.descrCcus }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="rowData.cadProj">
                      <span class="overflow-ellipse"
                        matTooltip='{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}'>{{
                        rowData.cadProj.codProj }}
                        - {{ rowData.cadProj.descrProj }}</span>
                    </td>
                    <td style="width: 35%;" *ngIf="!rowData.cadProj">
                    </td>
                    <td style="width: 20%;" class="text-right">
                      {{ rowData.porcentagem | money }}%
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="edit" matTooltip="Editar centro de custo" (click)="editarCentroCusto()">
                        <i class="fas fa-edit"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir centro de custo"
                        (click)="removerRateioCentroCusto(rowData)"
                        (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem rateio!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-2">
              <button type="button" class="btn btn-sm btn-info" (click)="controlCentroCusto()"><i
                  class="fas fa-plus"></i> Adicionar Rateio</button>
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12 mt-4">
              <button type="button" class="btn btn-secondary" (click)='modalOperacao=false'><i class="fas fa-undo"></i>
                Cancelar</button>
              <button type="button" class="btn btn-primary"
                [disabled]="!operacaoRegra.id || !dtOperacao || !historicoOperacao || !valorOperacao"
                (click)="salvarRecebimento();"><i class="fas fa-check"></i> Salvar</button>
            </div>
          </div>

          <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
            <div class="ui-g-12">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Card Transferencia Bancaria-->
    <div class="row" *ngIf="modalTransferenciaBancaria" [ngClass]="{'fade-in': 'modalTransferenciaBancaria'}">
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mb-2 mt-0">
          <li class="list-inline-item active"><a>Transferência</a></li>
        </ul>
      </div>
      <div class="col-sm-8 col-xxl-6">
        <div class="box-content mb-3 pt-2">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Transferência Bancária</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Conta de origem</label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="opcoesContas"
                placeholder="Selecione a Conta Origem" [(ngModel)]="contaOrigem"
                (onChange)="onChangeContaBancaria($event, 'select')"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Conta destino</label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="opcoesContas"
                placeholder="Selecione a Conta Destino" [(ngModel)]="contaDestino">
              </p-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="participanteTipo===1">
            <div class="form-group col-sm-12">
              <label>Cliente</label>
              <p-dropdown class="type-p-dropdown max-width" [filter]="true" [options]="clientes"
                placeholder="Selecione um cliente" [(ngModel)]="cliente.codigo"></p-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="participanteTipo===2">
            <div class="form-group col-sm-12">
              <label>Fornecedor</label>
              <p-dropdown class="type-p-dropdown max-width" [filter]="true" [options]="clientes"
                placeholder="Selecione um Fornecedor" [(ngModel)]="cliente.codigo"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-2">
              <label>Data</label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true"
                [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtTransferencia"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>

            <div class="form-group col-sm-4" *ngIf="permiteMultiMoeda">
              <label>MOEDA</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesMoeda"
                placeholder="Selecione a Moeda" [(ngModel)]="moedaSelecionadaId"
                (onChange)="onChangeContaBancaria($event, 'moeda'); convertValorMoeda()"
                [disabled]="!permiteAlterarMoeda">
              </p-dropdown>
            </div>

            <div class="form-group col-sm-2" *ngIf="permiteMultiMoeda">
              <label>TX. MOEDA</label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorTaxa"
                (onChange)="convertValorMoeda()" (keyup)="convertValorMoeda()" [disabled]="moedaSelecionadaId < 2" />
            </div>


            <div class="form-group col-sm-2" *ngIf="permiteMultiMoeda">
              <label>Valor Moeda<span class="obrigatorio">*</span></label>
              <input class="form-control text-right" (onChange)="convertValorMoeda()" (keyup)="convertValorMoeda()"
                type="text" [(ngModel)]="valorTransferencia" money />
            </div>

            <div class="form-group col-sm-2">
              <label>Valor<span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [disabled]="permiteMultiMoeda" [(ngModel)]="valorTransferenciaReal" money />
            </div>


            <div class="form-group col-sm-12">
              <label>Histórico</label>
              <input class="form-control" type="text" [(ngModel)]="historicoOperacao" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-8 mb-0 mt-2">
              <button type="button" class="btn btn-secondary" (click)='modalTransferenciaBancaria=false; edicaoTransferencia = false; carregarNovamente = false'><i
                  class="fas fa-undo"></i> Cancelar</button>
              <button *ngIf="!edicaoTransferencia" type="button" class="btn btn-primary" (click)="transferencia();"><i class="fas fa-check"></i>
                Salvar</button>
                <button *ngIf="edicaoTransferencia" type="button" class="btn btn-primary" (click)="transferencia();"><i class="fas fa-check"></i>
                  Editar</button>
            </div>
            <div class="p-field-checkbox ml-4" *ngIf="contaOrigemAsaas">
              <p-checkbox class="mr-1" [(ngModel)]="solicitarAutorizacao" [binary]="true"></p-checkbox>
              <label style="margin: 0">Solicitar autorização</label>
            </div>
          </div>

          <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
            <div class="ui-g-12">
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<!-- Modal Alternar Conta FEITO-->
<p-dialog header="Alternar Conta" [(visible)]="modalAlternarConta" width="700" [modal]="true" class="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione a conta</label>
      <p-dropdown class="type-p-dropdown" [filter]="false" [options]="opcoesContas" placeholder="Selecione a Conta"
        [(ngModel)]="contaSelecionada" (onChange)="alternarContaSelecionada()"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalAlternarConta=false'><i class="fas fa-undo"></i>
      Cancelar</button>
  </p-footer>
</p-dialog>

<!-- Modal Alterar Período FEITO-->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div *ngIf="modalPeriodo">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Data Inicial</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
          [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataIni" [(ngModel)]="dataIni"
          dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>Data Final</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
          [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataFin" [(ngModel)]="dataFin"
          dateFormat="dd/mm/yy"></p-calendar>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione a conta</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesContas" placeholder="Selecione a Conta"
          [(ngModel)]="contaSelecionada"></p-dropdown>
      </div>
    </div>

  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="atualizarVoltar();"><i class="fas fa-pencil-alt"></i>
      Alterar</button>
  </p-footer>
</p-dialog>

<!--Modal Definir Rateio FEITO-->
<p-dialog header="Definir Rateio" [(visible)]="modalCentroCusto" [style]="{width: '50vw'}" [modal]="true"
  styleClass="rateio-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div *ngIf="modalCentroCusto" style="height: 40vh;">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Centro de Custo <span class="obrigatorio">*</span></label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto" [(ngModel)]="cc"
          (onChange)="controlCentroCusto(cc.id)" placeholder="Selecione um centro de custo" optionLabel="label">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-4">
        <label>Projeto</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesProjetos" [(ngModel)]="proj"
          placeholder="Selecione um projeto" optionLabel="label"></p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>Porcentagem <span class="obrigatorio">*</span></label>
        <input class="form-control text-right" value="{{porcentagemCc}}" [(ngModel)]="porcentagemCc" money>
      </div>
      <div class="form-group col-sm-2">
        <div class="locked-element">
          <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
          <button class="btn btn-sm btn-info d-block" style="margin-top: 8px" (click)="incluirRateioCentroCusto()">
            <i class="fas fa-plus"></i> Adicionar</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="movimentacaoRateios.length > 0">
      <div class="col-sm-12">
        <p-table class="table-system" [value]="movimentacaoRateios">
          <!--pedidoItem.pedidoItensRateio-->
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 35%;">CENTRO CUSTO</th>
              <th style="width: 35%;">PROJETO</th>
              <th class="text-right" style="width: 20%;">PORCENTAGEM</th>
              <th class="text-center" style="width: 10%;">AÇÃO</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width: 35%;">
                <span class="overflow-ellipse"
                  matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">{{
                  rowData.cadCcus.codCcus }}
                  - {{ rowData.cadCcus.descrCcus }}</span>
              </td>
              <td style="width: 35%;" *ngIf="rowData.cadProj">
                <span class="overflow-ellipse"
                  matTooltip="{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}">{{
                  rowData.cadProj.codProj }}
                  - {{ rowData.cadProj.descrProj }}</span>
              </td>
              <td *ngIf="!rowData.cadProj">
              </td>
              <td class="text-right">
                {{ rowData.porcentagem | money }}%
              </td>
              <td class="text-center">
                <span class="delete" matTooltip="Excluir centro de custo" (click)="removerRateioCentroCusto(rowData)"
                  (onChange)="existeContaResultadoParaOperacaoRegra(operacaoRegra.id)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr style="height:25px">
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Sem rateio!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">
    </div>
  </div>
  <p-footer>
    <label class="mr-3" *ngIf="rateioCentroCusto !== 0 && rateioCentroCusto !== 100"><strong>Rateio Disponível:</strong>
      {{rateioCentroCusto}}%</label>
    <button type="button" class="btn btn-secondary" *ngIf="rateioCentroCusto === 0 || rateioCentroCusto === 100"
      (click)="fecharModalRateio()"><i class="fas fa-undo"></i> FECHAR</button>
  </p-footer>
</p-dialog>
