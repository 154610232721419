<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="col-sm-12 pb-4" style="padding-right: 0; padding-left: 0;">

      <!---Menu incial-->
      <div class="row">
        <div class="col">
          <div class="card-deck card-custom" *ngIf="!exibirFluxoRelatorio">
            <div class="card" *ngFor="let fluxo of listafluxosIni">
              <div class="card-body">
                <span class="icon-card" (click)="showDialogFluxoCaixa(fluxo)"><i class="fas fa-chart-bar"></i></span>
                <span class="info-card">
                  <h5 class="card-title" (click)="showDialogFluxoCaixa(fluxo)" matTooltip="{{fluxo.nome_fluxo}}"
                    matTooltipPosition="above" style="width: 150px;"><span
                      class="overflow-ellipse">{{fluxo.nome_fluxo}}</span></h5>
                  <p class="card-text overflow-ellipse" style="width: 150px;"><small
                      class="text-muted">{{fluxo.usuario}}</small></p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Relatório-->
      <div class="shadow-box" *ngIf="exibirFluxoRelatorio" style="box-shadow: none; border-radius: 10px;">
        <div class="header-box">
          <div class="header-box-content p-3" style="padding-bottom: 16px;">
            <h3 class="title-card">&nbsp; {{ dataAtual }}</h3>
            <div class="actions-card toolbarContainer">
              <mat-slide-toggle class="slideThickLabel mr-3" [(ngModel)]="baixados"
                (change)="fluxoCaixaChange(visaoFluxoCaixa)">
                Somente títulos baixados
              </mat-slide-toggle>
              <mat-slide-toggle class="slideThickLabel mr-3" *ngIf="visaoFluxoCaixa === 3" [(ngModel)]="versionamento"
                (change)="checkVersao()">
                Versionamento
              </mat-slide-toggle>
              <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="fluxoCaixaChange(1)"
                  [ngClass]="{ active: visaoFluxoCaixa === 1 }">
                  Diária
                </button>
                <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="fluxoCaixaChange(2)"
                  [ngClass]="{ active: visaoFluxoCaixa === 2 }">
                  Semanal
                </button>
                <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="fluxoCaixaChange(3)"
                  [ngClass]="{ active: visaoFluxoCaixa === 3 }">
                  Mensal
                </button>
              </div>
              <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>

            </div>
          </div>
        </div>

        <div class="box-content">
          <div class="container-table flow-table" *ngIf="!versionamento">
            <p-treeTable class="table-system" [value]="linhasFluxo" [columns]="columns" [scrollable]="true"
              [style]="{width:'100%'}">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <ng-container *ngFor="let col of columns">
                    <th>
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{col}}
                        </span>
                      </span>
                    </th>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                <tr>
                  <ng-container *ngFor="let col of columns; let i = index">
                    <td class="{{ ((rowData['codigo'].indexOf('.') === -1) ? 'cls_001_02 fixedText' : 'fixedText') }}">
                      <span class="text-overflow-dynamic-container"
                        *ngIf="checkClickableCellFirstCondition(rowData['codigo'], col)"
                        class="{{ setClassRow(rowData[col]) }}">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ setContentRow(rowData[col]) }}">
                          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                          {{ setContentRow(rowData[col]) }}
                        </span>
                      </span>
                      <span class="text-overflow-dynamic-container"
                        *ngIf="checkClickableCellSecondCondition(rowData['codigo'], col)"
                        class="{{ setClassRow(rowData[col]) }}" (click)="showDialogDetal(col, rowData)"
                        [style]="{cursor: 'pointer'}">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ setContentRow(rowData[col]) }}">
                          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                          {{ setContentRow(rowData[col]) }}
                        </span>
                      </span>
                      <span class="text-overflow-dynamic-container"
                        *ngIf="checkClickableCellThirdCondition(rowData['codigo'], col)"
                        class="{{ setClassRow(rowData[col]) }}">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ setContentRow(rowData[col]) }}">
                          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                          {{ setContentRow(rowData[col]) }}
                        </span>
                      </span>
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
            </p-treeTable>
          </div>
          <div class="container-table flow-table" *ngIf="versionamento">
            <p-treeTable class="table-system" [value]="linhasFluxoVersao" [scrollable]="true" [style]="{width:'100%'}" scrollWidth="300px" frozenWidth="300px">
              <ng-template pTemplate="frozenheader">
                <tr>
                  <th style="width: 400px">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        &nbsp;
                      </span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th style="width: 400px">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        Descrição
                      </span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
                <tr>
                  <td style="width: 400px"  class="{{ ((rowData['codigo'].indexOf('.') === -1) ? 'cls_001_02 fixedText' : 'fixedText') }}">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        {{ rowData['Descrição'] }}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th *ngFor="let col of columnsVersUp" colspan="4" style="width: 400px" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        {{col}}
                      </span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th *ngFor="let col of columnsVersDown" style="width: 100px" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        {{col[0]}}
                      </span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData="rowData">
                <tr>
                  <ng-container *ngFor="let col of columnsVersDown; let i = index">
                    <td style="width: 100px" class="{{ ((rowData['codigo'].indexOf('.') === -1) ? 'cls_001_02 fixedText' : 'fixedText') }} text-right">
                      <span class="text-overflow-dynamic-container" *ngIf="checkClickableCellFirstCondition(rowData['codigo'], col[1])" class="{{ setClassRow(rowData[col[1]]) }}">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ setContentRow(rowData[col[1]]) }}">
                          {{ setContentRow(rowData[col[1]]) }}
                        </span>
                      </span>
                      <span class="text-overflow-dynamic-container" *ngIf="checkClickableCellSecondCondition(rowData['codigo'], col[1])" class="{{ setClassRow(rowData[col[1]]) }}" (click)="showDialogDetal(col[1], rowData, true)" [style]="{cursor: 'pointer'}">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ setContentRow(rowData[col[1]]) }}">
                          {{ setContentRow(rowData[col[1]]) }}
                        </span>
                      </span>
                      <span class="text-overflow-dynamic-container" *ngIf="checkClickableCellThirdCondition(rowData['codigo'], col[1])" class="{{ setClassRow(rowData[col[1]]) }}">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ setContentRow(rowData[col[1]]) }}">
                          {{ setContentRow(rowData[col[1]]) }}
                        </span>
                      </span>
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
            </p-treeTable>
          </div>
          <div class="alert alert-xs alert-primary d-inline" role="alert" *ngIf="!linhasFluxo.length">
            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

<!-- MODAL SELEÇÃO FLUXO -->
<p-dialog header="Composição Fluxo de Caixa" [(visible)]="modalFluxoCaixa" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" showEffect="clip" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Visão</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="visaoFluxoCaixa"
        [options]="opcoesVisaoFluxoCaixa" placeholder="Selecione"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Período</label>
      <input class="form-control" value="" disabled *ngIf="!visaoFluxoCaixa">
      <p-dropdown *ngIf="visaoFluxoCaixa === 1 || visaoFluxoCaixa === 2" class="type-p-dropdown" [filter]="true"
        [(ngModel)]="visaoFluxoCaixaMes" [options]="opcoesMeses" placeholder="Selecione"></p-dropdown>
      <p-dropdown *ngIf="visaoFluxoCaixa === 3" class="type-p-dropdown" [filter]="true" [(ngModel)]="visaoFluxoCaixaAno"
        [options]="opcoesAnos" placeholder="Selecione"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Contas</label>
      <p-multiSelect class="type-p-mult" [(ngModel)]="empresaContas" [options]="opcoesEmpresaConta"
        defaultLabel="Adicionar / remover contas" [filter]="true" placeholder="Selecione"
        selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalFluxoCaixa = false"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="atualizarContasUsuario();exportar()"><i
        class="fas fa-file-excel"></i>
      Excel</button>
    <button type="button" class="btn btn-primary" (click)="relatorioFluxoCaixa();atualizarContasUsuario()"><i
        class="fas fa-search"></i>
      Visualizar</button>
  </p-footer>
</p-dialog>

<!-- MODAL DETALHE FLUXO -->
<p-dialog [(visible)]="modalFluxoCaixaDetal" [style]="{width: '1000px'}" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" appendTo="body" showEffect="clip" [closable]="true">
  <p-header>
    Detalhe movimentação: {{ modalTitle }}
    <div class="actions-modal">
      <button type="button" class="btn btn-xs btn-link" (click)="exportarExcelDetalhe()" matTooltip="Exportar Excel"
        matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
    </div>
  </p-header>
  <p-table class="table-system" #dt2 [columns]="colsFluxo" [value]="valoresTabelaDetal" [rows]="rows" [paginator]="true"
    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%','border':'none'}">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_emissao_ts'">
            <span class="text-overflow-dynamic-ellipsis">EMISSÃO <p-sortIcon [field]="'dt_emissao_ts'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'dt_emissao', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vencimento_ts'">
            <span class="text-overflow-dynamic-ellipsis">VENCTO/BAIXA <p-sortIcon [field]="'dt_vencimento_ts'">
              </p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'dt_vencimento', 'contains')">
        </th>
        <th class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
            <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'nome', 'contains')">
        </th>
        <th class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
            <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'operacao', 'contains')">
        </th>
        <th class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
            <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'historico', 'contains')">
        </th>
        <th class="text-left" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'doc'">
            <span class="text-overflow-dynamic-ellipsis">DOCUMENTO <p-sortIcon [field]="'doc'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'doc', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
            <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'status', 'contains')">
        </th>
        <th class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
            <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'valor', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.dt_emissao}}">{{rowData.dt_emissao}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.dt_vencimento}}">{{rowData.dt_vencimento}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.doc}}">{{rowData.doc}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status}}</span>
          </span>
        </td>
        <td class="text-right" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.tipo === 'CR'">{{rowData.valor | money}}</span>
            <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.tipo === 'CP'"
              style="color:darkred;">{{rowData.valor | money}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="7" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalFluxoCaixaDetal = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>

<!-- MODAL SELEÇÃO VERSÃO -->
<p-dialog header="Versionamento do fluxo de caixa" [(visible)]="modalVersao" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" showEffect="clip" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12" *ngIf="tipoMetodoVersao === 'get'">
      <label>Versões salvas</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="descrVersao" [options]="opcoesVersoes"
        placeholder="Selecione a versão"></p-dropdown>
    </div>
    <div class="form-group col-sm-12" *ngIf="tipoMetodoVersao === 'post'">
      <label>Descrição</label>
      <input class="form-control" value="{{descrVersao}}" [(ngModel)]="descrVersao" name="descricaoVersao"
        maxlength="200">
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalVersao = false"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button *ngIf="tipoMetodoVersao === 'get'" type="button" class="btn btn-danger" (click)="excluirVersao()"><i
        class="fas fa-trash"></i> Excluir</button>
    <button *ngIf="tipoMetodoVersao === 'get'" type="button" class="btn btn-primary" (click)="carregarVersao()"><i
        class="fas fa-search"></i> Carregar</button>
    <button *ngIf="tipoMetodoVersao === 'post'" type="button" class="btn btn-primary" (click)="salvarVersao()"><i
        class="fas fa-plus"></i> Salvar</button>
  </p-footer>
</p-dialog>