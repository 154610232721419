import { Injectable } from '@angular/core'
import { ConfigService } from './shared/services/config.service'
import { NavigationMenu } from './shared/model/navigation-menu.model'

@Injectable()
export class MenuConfig {
  menus: Array<NavigationMenu> | NavigationMenu | Array<any> | any = new Array()

  constructor(configService: ConfigService) {
    this.menus = [
      { link: ['/erp'], title: 'Home', icon: 'home_filled' },
      // TODO: arrumar permissões
      { link: null, title: 'API', icon: 'dns', permissions: [{ url: '/api' }],
        menus: [
          // { link: ['/api/espaco-cliente'], title: 'Espaço cliente', permissions: [{ url: '/espaco-cliente' }], checkChildren: false },
          // { link: ['/api/log'], title: 'LOG', permissions: [{ url: '/espaco-cliente' }], checkChildren: false },
          { link: ['/api/monitoramento'], title: 'Monitoramento', permissions: [{ url: '/dashboard-api' }], checkChildren: false },
          { link: null, title: 'Configurações', permissions: [{ url: '/api/configuracao' }],
            menus: [
              { link: ['api/configuracoes/cliente'], title: 'Integração', permissions: [{ url: '/config-api-cliente' }], checkChildren: false },
              // { link: ['api/configuracoes/admin'], title: 'Administrador', permissions: [{ url: '/config-api-adm' }], checkChildren: false },
            ]
          }
        ]
      },
      { link: null, title: 'Cadastros', icon: 'app_registration', permissions: [{ url: '/erp/cadastro' }],
        menus: [
          { link: ['/contabil/empresa'], title: 'Empresa', permissions: [{ url: '/erp/cadastro/empresa' }], checkChildren: false },
          // { link: ['/profile'], title: 'Controle de Usuário', permissions: [{ url: '/erp/cadastro/usuario' }], checkChildren: false },
          // { link: ['/participante-empresa'], title: 'Clientes e Fornecedores',permissions: [{ url: '/erp/cadastro/participante' }], checkChildren: false },
          { link: ['/cadastro-informacao-empresa'], title: 'Cadastros de Informações', permissions: [{ url: '/erp/cadastro/informacoes' }], checkChildren: false },
          { link: ['/cadastro-grupo-empresa'], title: 'Grupos de Empresas', permissions: [{ url: '/erp/cadastro/grupo-empresas' }], checkChildren: false },
          { link: ['/depto-empresa'], title: 'Departamento Empresa', permissions: [{ url: '/depto-empresa' }], checkChildren: false },
          // { link: ['/item-ref'], title: 'Produtos e Serviços', permissions: [{ url: '/erp/cadastro/produto-servico' }], checkChildren: false },
          // { link: ['/item-participante-relac'], title: 'Vinculação Item XML', permissions: [{ url: '/erp/cadastro/vinculacao-item-xml' }] },
          { link: ['/segmento-participante'], title: 'Segmento Participantes', permissions: [{ url: '/erp/cadastro/segmento-participante' }], checkChildren: false },
          { link: null, title: 'Contabilidade', permissions: [{ url: '/ecd/export1' }], checkChildren: false,
            menus: [
              // { link: ['/orcamento/cadastro-area'], title: 'Áreas', permissions: [{ url: '/orcamento/cadastro-area' }] },
              // { link: ['/orcamento/classificacao'], title: 'Classificação', permissions: [{ url: '/orcamento/classificacao' }] },
              // { link: ['/planocontas-new'], title: 'Plano de Contas', permissions: [{ url: '/planocontas-new1' }] },
              // { link: ['/cad-ccus'], title: 'Centro de Custo', permissions: [{ url: '/cad-ccus1' }] },
              { link: ['/cad-hist-padrao'], title: 'Histórico Padrão', permissions: [{ url: '/cad-hist-padrao1' }] },
              { link: ['/cad-lei-do-bem'], title: 'Lei do Bem', permissions: [{ url: '/cad-lei-do-bem1' }] },
              // { link: ['/cad-proj'], title: 'Projeto', permissions: [{ url: '/cad-proj1' }] },
              { link: ['/signatarios'], title: 'Signatários', permissions: [{ url: '/signatarios1' }] },
              { link: ['/mov-termo-ae'], title: 'Termo de Abert/Encerramento', permissions: [{ url: '/mov-termo-ae1' }] },
              // { link: ['/config-cad-ccus-cad-proj'], title: 'Gestão Centro de Custo/Projeto', permissions: [{ url: '/config-cad-ccus-cad-proj1' }] },
            ]
          },
          // { link: null, title: 'Financeiro', permissions: [{ url: '/cad-fin' }], checkChildren: false,
            // menus: [
              // { link: ['/empresa-conta-ref'], title: 'Contas Bancárias', permissions: [{ url: '/empresa-conta-ref' }] },
              // { link: ['/forma-pagamento'], title: 'Formas de Pagamento', permissions: [{ url: '/forma-pagamento' }] },
              // { link: ['/tipo-pagamento'], title: 'Tipos de Pagamento', permissions: [{ url: '/tipo-pagamento' }] },
              // { link: ['/maquina-cartao'], title: 'Máquinas de cartão', permissions: [{ url: '/maquina-cartao' }] },
            // ]
          // },
          // { link: null, title: 'Fiscal', permissions: [{ url: '/cad-fiscal' }], checkChildren: false,
            // menus: [
              // { link: ['/aliquota-tributo'], title: 'Alíquotas por Tributo', permissions: [{ url: '/aliquota-tributo' }] },
              // { link: ['/grupo-tributacao'], title: 'Grupo de Tributação', permissions: [{ url: '/grupo-tributacao' }] },
              // { link: ['/mva'], title: 'Mva', permissions: [{ url: '/mva' }] },
              // { link: ['/empresa-cnae'], title: 'Código Municipal de Serviço', permissions: [{ url: '/empresa-cnae' }] },
            // ]
          // },
          // { link: null, title: 'Regras e Operação', permissions: [{ url: '/cad-regras' }], checkChildren: false,
            // menus: [
              // { link: ['/operacao-regra'], title: 'Regra Operação', permissions: [{ url: '/operacao-regra' }] },
              // { link: ['/operacao-regra-fiscal'], title: 'Regra Fiscal', permissions: [{ url: '/operacao-regra-fiscal' }] },
              // { link: ['/operacao-regra-contabil'], title: 'Regra Contabil', permissions: [{ url: '/operacao-regra-contabil' }] },
              // { link: ['/item-contabil'], title: 'Item Contabil', permissions: [{ url: '/item-contabil' }] },
              // { link: ['/grupo-operacao'], title: 'Grupo Operação', permissions: [{ url: '/grupo-operacao' }] },
              // { link: ['/gestao-grupo-operacao'], title: 'Gestão Grupo/Regra Operação', permissions: [{ url: '/gestao-grupo-operacao' }] },
              // { link: ['/operacao-contabil-externa'], title: 'Regra Contábil Externa', permissions: [{ url: '/operacao-regra-contabil' }] },
              // { link: ['/vinculacao-operacao-contabil-externa'], title: 'Vinculação de Regra Externa', permissions: [{ url: '/operacao-regra-contabil' }] },
            // ]
          // },
        ]
      },
      // { link: null, title: 'CentralWeb', icon: 'public', permissions: [{ url: '/centralweb' }], checkChildren: false,
        // menus: [
          // { link: ['/centralweb/dashboard'], title: 'Dashboard', permissions: [{ url: '/centralweb/dashboard' }], checkChildren: false },
          // { link: ['/centralweb/agenda'], title: 'Agenda de Obrigações', permissions: [{ url: '/centralweb/agenda' }], checkChildren: false },
          // { link: ['/centralweb/check-list'], title: 'Check List', permissions: [{ url: '/centralweb/check-list' }], checkChildren: false },
          // { link: ['/codigos-obrigacoes'], title: 'Códigos Obrigações', permissions: [{ url: '/codigos-obrigacoes' }], checkChildren: false },
          // { link: ['/centralweb/obrigacao'], title: 'Controle de Obrigação', permissions: [{ url: '/centralweb/obrigacao' }], checkChildren: false },
          // { link: ['/destinatarios'], title: 'Destinatários por Empresa', permissions: [{ url: '/destinatarios' }], checkChildren: false },
          // { link: ['/centralweb/gatilho-obrigacao'], title: 'Gatilho Obrigação', permissions: [{ url: '/centralweb/gatilho-obrigacao' }], checkChildren: false },
          // { link: ['/centralweb/tributos-pagamento-relac'], title: 'Gera Financeiro', permissions: [{ url: '/centralweb/tributos-pagamento-relac' }], checkChildren: false },
          // { link: ['/feriados'], title: 'Gerenciar dia não útil', permissions: [{ url: '/feriados' }], checkChildren: false },
          // { link: ['/justificativas'], title: 'Justificativas', permissions: [{ url: '/justificativas' }], checkChildren: false },
          // { link: ['/centralweb/relatorios'], title: 'Relatórios Gerenciais', permissions: [{ url: '/centralweb/relatorios' }], checkChildren: false },
          // { link: ['/tipo-arquivo'], title: 'Tipo Arquivo', permissions: [{ url: '/tipo-arquivo' }], checkChildren: false },
          // { link: ['/tipo-documento'], title: 'Tipo Documento', permissions: [{ url: '/tipo-documento' }], checkChildren: false },
      //   ]
      // },
      { link: null, title: 'Compras', icon: 'shopping_cart', permissions: [{ url: '/compras' }], checkChildren: false,
        menus: [
          // { link: ['compras/dashboard-compra'], title: 'Dashboard', permissions: [{ url: '/dashboard_compra' }], checkChildren: false },
          // { link: ['/financeiro/import-pedidos'], title: 'Importar', permissions: [{ url: '/import-pedidos' }], checkChildren: false },
          { link: ['/notas-pendentes'], title: 'Notas Fiscais', permissions: [{ url: '/notas-pendentes' }], checkChildren: false },
          // { link: ['pedido-produto/pedido-produto/' + 0], title: 'Pedidos - Compra Produto', permissions: [{ url: '/pedido-produto/pedido-produto/0' }], checkChildren: false },
          // { link: ['pedido-servico/pedido-servico/' + 0], title: 'Pedidos - Compra Serviço', permissions: [{ url: '/pedido-servico/pedido-servico/0' }], checkChildren: false },
          // { link: ['pedido-produto-new/pedido-produto-new/' + 0], title: 'Pedidos - Compra Produto', permissions: [{ url: '/pedido-produto/pedido-produto-new/0' }], checkChildren: false },
          // { link: ['pedido-servico-new/pedido-servico-new/' + 0], title: 'Pedidos - Compra Serviço', permissions: [{ url: '/pedido-servico/pedido-servico-new/0' }], checkChildren: false },
          // { link: ['/relatorio-pedidos/' + 0], title: 'Relatórios', permissions: [{ url: '/relatorio-pedidos' }], checkChildren: false },
          // { link: null, title: 'Cadastros', permissions: [{ url: '/compras' }], checkChildren: false,
          //   menus: [
          //     { link: ['/participante-empresa'], title: 'Clientes e Fornecedores', permissions: [{ url: '/participante-empresa' }] },
          //     { link: ['/item-ref'], title: 'Produtos e Serviços', permissions: [{ url: '/cad-prod0' }] },
          //   ]
          // },
        ]
      },
      { link: null, title: 'Configurações', icon: 'settings', permissions: [{ url: '/configuracoes' }], checkChildren: false,
        menus: [
          { link: null, title: 'Empresa', permissions: [{ url: '/empresa' }], checkChildren: false,
            menus: [
              { link: ['/empresa/empresa'], title: 'Paramêtro Empresa', permissions: [{ url: '/company', method: 'POST', application: 'auditor' }, { url: '/company', method: 'PATCH', application: 'auditor' }] },
              // { link: ['/empresa/certificado-a1'], title: 'Gerenciar certificado digital', permissions: [{ url: '/company', method: 'POST', application: 'auditor' }, { url: '/company', method: 'PATCH', application: 'auditor' }] },
              // { link: ['/empresa-parametro-contabil'], title: 'Parâmetro Contábil', permissions: [{ url: '/empresa-parametro-contabil' }] },
              { link: ['/empresa-parametro-financeiro'], title: 'Parâmetro Financeiro', permissions: [{ url: '/empresa-parametro-financeiro' }] },
              // { link: ['/item-participante-relac'], title: 'Item Participante Relac', permissions: [{ url: '/item' }] }
            ]
          },
          { link: null, title: 'Sistema', permissions: [{ url: '/sistema' }], checkChildren: false,
            menus: [
              { link: ['/empresa'], title: 'Empresas', permissions: [{ url: '/linnks' }] },
              { link: ['/profile'], title: 'Controle de Usuário', permissions: [{ url: '/profile' }] },
              // { link: null, title: 'Controle de Acessos', permissions: [{ url: '/controledeAcessos0' }],
                // menus: [
                  // { link: ['/app'], title: 'Gerenciamento de Aplicações', permissions: [{ url: '/authapp', method: 'PUT', application: 'proof' }] },
                  // { link: ['/user'], title: 'Controle de Usuário', permissions: [{ url: '/authuser', method: 'PUT', application: 'proof' }] },
                  // { link: ['/group'], title: ' Controle de Grupos', permissions: [{ url: '/authgroup', method: 'PUT', application: 'proof' }] },
                  // { link: ['/feature'], title: 'Funcionalidades', permissions: [{ url: '/authfeature', method: 'PUT', application: 'proof' }] },
                  // { link: ['/feature-url'], title: 'Permissões', permissions: [{ url: '/authfeatureurl', method: 'PUT', application: 'proof' }] },
                  // { link: ['/crowd'], title: 'Crowds', permissions: [{ url: '/authcrowd', method: 'PUT', application: 'proof' }], active: configService.crowdAuth }
                // ]
              // },
              { link: null, title: 'Implantação', permissions: [{ url: '/implantacao' }],
                menus: [
                  { link: ['implantacao/upload'], title: 'Upload', permissions: [{ url: '/implantacao-upload' }] },
                ]
              },
            ]
          },
          { link: null, title: 'Gerenciamento de Acesso', permissions: [{ url: '/controle-acesso' }], checkChildren: false,
            menus: [
              { link: ['/controle-acesso/cadastro-menu'], title: 'Gerenciamento de Menu', permissions: [{ url: '/controle-acesso/cadastro-menu' }] },
              { link: ['/controle-acesso/controle-grupo'], title: 'Gerenciamento de Grupo', permissions: [{ url: '/controle-acesso/controle-grupo' }] },
              { link: ['/controle-acesso/controle-usuario'], title: 'Gerenciamento de Usuário', permissions: [{ url: '/controle-acesso/controle-usuario' }] },
            ]
          },
          // { link: ['/cadastrar-plataforma'], title: 'Cadastrar Plataforma', permissions: [{ url: '/cadastrar-plataforma' }], checkChildren: false },
          // { link: ['/configuracoes-apis'], title: 'Configurações de APIs', permissions: [{ url: '/configuracoes-apis' }], checkChildren: false },
          // { link: ['/distrib-gp'], title: 'Distribuição de Gateways', permissions: [{ url: '/distrib-gp' }], checkChildren: false }
        ]
      },
      { link: null, title: 'Contabilidade', icon: 'calculate', permissions: [{ url: '/contabilidade' }, { url: '/erp-cont' }, { url: '/conciliacao' }], checkChildren: false,
        menus: [
          //{ link: ['/ecd/export'], title: 'Exportar ECD', permissions: [{ url: '/ecd/export' }], checkChildren: false },
          { link: ['/ecd/exportnew'], title: 'Exportar ECD (new)', permissions: [{ url: '/ecd/exportnew' }], checkChildren: false },
          // { link: ['/ecf/export'], title: 'Exportar ECF', permissions: [{ url: '/ecf/export' }], checkChildren: false },
          // { link: ['/contabil/import-planilha'], title: 'Importar Arquivos', permissions: [{ url: '/contabil/import-planilha' }], checkChildren: false },
          // { link: ['/contabil/import'], title: 'Importar ECD', permissions: [{ url: '/contabil/import' }], checkChildren: false },
          // { link: ['/contabil/lancamento'], title: 'Lançamentos', permissions: [{ url: '/contabil/lancamento' }], checkChildren: false },
          { link: ['/plano-contas-ref-relac-csll'], title: 'Referenciar CSLL', permissions: [{ url: '/plano-contas-ref-relac-csll' }], checkChildren: false },
          { link: ['/plano-contas-ref-relac-irpj'], title: 'Referenciar IRPJ', permissions: [{ url: '/plano-contas-ref-relac-irpj' }], checkChildren: false },
          // { link: ['/plano-contas-ref-relac-new'], title: 'Referenciar Plano de Contas', permissions: [{ url: '/plano-contas-ref-relac-new' }], checkChildren: false },
          //{ link: ['/ecd/report'], title: 'Relatórios', permissions: [{ url: '/ecd/report' }], checkChildren: false },
          // { link: ['/ecd/reportnew'], title: 'Relatórios (new)', permissions: [{ url: '/ecd/reportnew' }], checkChildren: false },
          { link: ['/ecd/lcto-encerramento'], title: 'Rotina de Encerramento', permissions: [{ url: '/ecd/lcto-encerramento' }], checkChildren: false },
          { link: ['/plano-contas-ref-relac'], title: 'Visualizar Mapeamento', permissions: [{ url: '/plano-contas-ref-relac' }], checkChildren: false },


          /*{ link: null, title: 'USGaap', permissions: [{ url: '/usgaap' }], checkChildren: false,
            menus: [
              { link: ['/contabil/us-gaap/' + 0], title: 'Período', permissions: [{ url: '/contabil/us-gaap/0' }] },
              { link: ['/contabil/us-gaap/' + 1], title: 'USGaap', permissions: [{ url: '/contabil/us-gaap/1' }] },
            ]
          },*/
          { link: null, title: 'Configurações', permissions: [{ url: '/ecd' }], checkChildren: false,
            menus: [
              // { link: ['/cad-ccus'], title: 'Centro de Custo', permissions: [{ url: '/cad-ccus' }] },
              { link: ['/cad-hist-padrao'], title: 'Histórico Padrão', permissions: [{ url: '/cad-hist-padrao' }] },
              { link: ['/contabil/empresa'], title: 'Informações da empresa', permissions: [{ url: '/contabil/empresa' }] },
              { link: ['/mov-termo-ae'], title: 'Mov Termo', permissions: [{ url: '/mov-termo-ae' }] },
              // { link: ['/planocontas-new'], title: 'Plano de Contas', permissions: [{ url: '/planocontas-new' }] },
              // { link: ['/cad-proj'], title: 'Projeto', permissions: [{ url: '/cad-proj' }] },
              { link: ['/signatarios'], title: 'Signatários', permissions: [{ url: '/signatarios' }] }
            ]
          },
          { link: null, title: 'Lalur', permissions: [{ url: '/lalur' }], checkChildren: false,
            menus: [
              { link: null,  title: 'IRPJ', permissions: [{ url: '/lalur/parte-a-m300-irpj' }],
                menus: [
                  { link: ['lalur/parte-a-m300-irpj'], title: 'Parte A - IRPJ', permissions: [{ url: '/lalur/parte-a-m300-irpj' }] },
                  { link: ['lalur/parte-a-m300-irpj-gerencial'], title: 'Parte A - IRPJ', permissions: [{ url: '/lalur/parte-a-m300-irpj-gerencial' }] },
                  { link: ['lalur/parte-b'], title: 'Parte B - IRPJ', permissions: [{ url: '/lalur/parte-b' }] },
                ]
              },
              { link: null, title: 'CSLL', permissions: [{ url: '/lalur/parte-a-m300-csll' }],
                menus: [
                  { link: ['lalur/parte-a-m300-csll'], title: 'Parte A - CSLL', permissions: [{ url: '/lalur/parte-a-m300-csll' }] },
                  { link: ['lalur/parte-a-m350-csll-gerencial'], title: 'Parte A - CSLL', permissions: [{ url: '/lalur/parte-a-m350-csll-gerencial' }] },
                  { link: ['lalur/parte-b-csll'], title: 'Parte B - CSLL', permissions: [{ url: '/lalur/parte-b-csll' }] },
                ]
              },
            ]
          },
        ]
      },
      { link: null, title: 'DBL', icon: 'gavel',  permissions: [{ url: '/projetodbl' }], checkChildren: false,
        menus: [
          { link: ['/projetodbl-sped'], title: 'Projeto Sped', permissions: [{ url: '/projetodbl-sped' }], checkChildren: false },
          { link: ['/projetodbl-sped/dbl-config-importacao'], title: 'Configuração Importação', permissions: [{ url: '/projetodbl-sped/dbl-config-importacao' }], checkChildren: false }
        ]
      },

      { link: null, title: 'DIRF', icon: 'gavel', permissions: [{ url: '/dirf' }], checkChildren: false,
        menus: [
          { link: ['/dirf'], title: 'Declaração',permissions: [{ url: '/declaracao' }], checkChildren: false }
        ]
      },

      // { link: null, icon: 'inventory', title: 'Estoque', permissions: [{ url: '/ficha-estoque' }], checkChildren: false,
      //   menus: [
          // { link: ['estoque/dashboard2'], title: 'Dashboard', permissions: [{ url: '/dashboard2' }], checkChildren: false },
          // { link: null,  title: 'Cadastros', permissions: [{ url: '/cadastro-estoque' }], checkChildren: false,
          //   menus: [
              // { link: ['/empresa-almoxarifado'], title: 'Almoxarifado', permissions: [{ url: '/empresa-almoxarifado' }] },
              // { link: ['/item-bom'], title: 'Árvore de Produtos', permissions: [{ url: '/item-bom' }] },
              // { link: ['/mi'], title: 'Cadastro MI', permissions: [{ url: '/mi' }] },
              // { link: ['/grupo-estoque'], title: 'Grupo de Estoque', permissions: [{ url: '/grupo-estoque' }] },
          //   ]
          // },
          // { link: ['estoque/periodo'], title: 'Controle Período', permissions: [{ url: '/controle_estoque' }], checkChildren: false },
          // { link: ['/estoque/import'], title: 'Importar Arquivos', permissions: [{ url: '/estoque-import' }] },
          // { link: ['/movimentacao-interna'], title: 'Movimentação Interna', permissions: [{ url: '/mov_estoque' }], checkChildren: false },
          // { link: ['/ordens'], title: 'Ordens de Produção', permissions: [{ url: '/ordens' }], checkChildren: false },
          // { link: ['/requisicao'], title: 'Requisição de Materiais', permissions: [{ url: '/req_estoque' }], checkChildren: false },
          // { link: ['estoque/relatorios'], title: 'Relatórios', permissions: [{ url: '/rel_estoque' }], checkChildren: false }
        // ]
      // },
      // { link: null, title: 'Eventos', icon: 'confirmation_number', permissions: [{ url: '/eventos' }], checkChildren: false,
      //   menus: [
      //     { link: null, title: 'Eduzz', permissions: [{ url: '/eduzz' }], checkChildren: false,
      //     menus: [
      //         { link: ['/eduzz/eduzz-carga'], title: 'Eduzz Carga', permissions: [{ url: '/eduzz/eduzz-carga' }] },
      //       ]
      //     },
      //     { link: null, title: 'Evento', permissions: [{ url: '/eventos-gestor' }], checkChildren: false,
      //       menus: [
      //         { link: ['/eventos/eventos'], title: 'Cadastrar Eventos', permissions: [{ url: '/eventos/eventos' }] },
      //         { link: ['/eventos/participantes-eventos/' + '0' + '/' + 0], title: 'Eventos Participantes', permissions: [{ url: '/eventos/participantes-eventos/0/0' }] },
      //         { link: ['/eventos/participantes-eventos/' + '1' + '/' + 0], title: 'Eventos Participantes lista', permissions: [{ url: '/eventos/participantes-eventos/1/0' }] },
      //         { link: ['/eventos/eventos-plano/'], title: 'Eventos Plano', permissions: [{ url: '/eventos/eventos-plano/' }] },
      //         { link: ['/eventos/checkin'], title: 'Check-In', permissions: [{ url: '/eventos/checkin' }] },
      //         { link: ['/eventos/motivo-estorno/'], title: 'Pedidos de Estorno', permissions: [{ url: '/eventos/motivo-estorno/' }] },
      //         { link: ['polozi/evento-proj-relac'], title: 'Projetos e Eventos', permissions: [{ url: '/eventos/evento-proj-relac' }] },
      //         { link: ['eventos/evento-pagamento-pagseguro'], title: 'Pagamento Evento PagSeguro', permissions: [{ url: '/eventos/evento-pagamento-pagseguro' }] },
      //         { link: ['eventos/transferir-participante-evento'], title: 'Transf. de Participante Evento', permissions: [{ url: '/eventos/transferir-participante-evento' }] },
      //         { link: ['/eventos/consulta-clientes'], title: 'Consulta Clientes', permissions: [{ url: '/eventos/consulta-clientes' }] },
      //         { link: ['/eventos/importar-arquivos'], title: 'Importar arquivo Ev. Part.', permissions: [{ url: '/eventos/importar-arquivos' }] },
      //       ]
      //     },
      //     { link: ['/eventos/campanha'], title: 'Campanha', permissions: [{ url: '/eventos/campanha' }], checkChildren: false },
      //     { link: ['/eventos/carteira'], title: 'Carteira', permissions: [{ url: '/eventos/carteira' }], checkChildren: false },
      //     { link: ['/eventos/consultor'], title: 'Consultor', permissions: [{ url: '/eventos/consultor' }], checkChildren: false },
      //     { link: ['/eventos/fichas'], title: 'Fichas (SYS)', permissions: [{ url: '/eventos/fichas' }], checkChildren: false },
      //     { link: ['/palestras/palestra/' + 'pal'], title: 'Palestra', permissions: [{ url: '/palestras/palestra/pal' }], checkChildren: false },
      //     { link: ['/palestras/palestra/' + 'work'], title: 'Workshop', permissions: [{ url: '/palestras/palestra/work' }], checkChildren: false },
      //     { link: ['/eventos/relacionar-campanha-carteira'], title: 'Relac. Campanha carteira', permissions: [{ url: '/eventos/relacionar-campanha-carteira' }], checkChildren: false },
      //     { link: ['/eventos/relacionar-carteira-clientes'], title: 'Relac. Carteira por clientes', permissions: [{ url: '/eventos/relacionar-carteira-clientes' }], checkChildren: false },
      //     { link: ['/eventos/seminarista'], title: 'Seminaristas', permissions: [{ url: '/eventos/seminarista' }], checkChildren: false },
      //     { link: ['polozi/tipo-ingresso'], title: 'Tipo Ingresso', permissions: [{ url: '/eventos/tipo-ingresso' }], checkChildren: false },
      //     { link: ['/eventos/vendedor'], title: 'Vendedor', permissions: [{ url: '/eventos/vendedor' }], checkChildren: false },
      //     { link: null, title: 'Formulário', permissions: [{ url: '/formulario' }], checkChildren: false,
      //       menus: [
      //         { link: ['/eventos/formulario'], title: 'Cadastrar formulário', permissions: [{ url: '/eventos/formulario' }] },
      //         { link: ['/eventos/formulario-perguntas-campos'], title: 'Formulário perguntas', permissions: [{ url: '/eventos/formulario-perguntas-campos' }] },
      //         { link: ['/eventos/formulario-visualizacao-respostas'], title: 'Respostas formulário', permissions: [{ url: '/eventos/formulario-visualizacao-respostas' }] },
      //         { link: ['/eventos/formulario-motivo-cancelamento'], title: 'Motivos Cancelamento', permissions: [{ url: '/eventos/formulario-motivo-cancelamento' }] },
      //         { link: ['/eventos/formulario-motivo-reagendamento'], title: 'Motivos Re-agendamento', permissions: [{ url: '/eventos/formulario-motivo-reagendamento' }] },
      //       ]
      //     },
      //     { link: null, title: 'GetNet', permissions: [{ url: '/getnet' }], checkChildren: false, test: true,
      //       menus: [
      //         { link: ['/getnet/getnet-assinatura'], title: 'GetNet Assinatura', permissions: [{ url: '/getnet/getnet-assinatura' }], test: true },
      //         { link: ['/getnet/getnet-planos'], title: 'GetNet Planos', permissions: [{ url: '/getnet/getnet-planos' }], test: true },
      //         { link: ['/getnet/getnet-pagamento-cartao-credito'], title: 'Pagamentos Cartão de crédito', permissions: [{ url: '/getnet/getnet-pagamento-cartao-credito' }], test: true },
      //       ]
      //     },
      //     { link: null, title: 'Leads', permissions: [{ url: '/leads' }], checkChildren: false,
      //       menus: [
      //         { link: ['/palestras/palestra-cliente'], title: 'Leads', permissions: [{ url: '/palestras/palestra-cliente' }] },
      //         { link: ['/palestras/palestra-cliente-status'], title: 'Status Leads', permissions: [{ url: '/palestras/palestra-cliente-status' }] },
      //         { link: ['/eventos/importar-lead'], title: 'Importar Leads', permissions: [{ url: '/eventos/importar-lead' }] },
      //       ]
      //     },
      //     { link: null, title: 'Relatórios', permissions: [{ url: '/relatorios' }], checkChildren: false,
      //       menus: [
      //         { link: ['/eventos/fichas-conf-email'], title: 'Confirmação E-mail', permissions: [{ url: '/eventos/fichas-conf-email' }] },
      //         { link: ['/eventos/fichas-evento'], title: 'Fichas por Evento', permissions: [{ url: '/eventos/fichas-evento' }] },
      //         { link: ['/eventos/inscritos-evento'], title: 'Inscritos por Evento', permissions: [{ url: '/eventos/inscritos-evento' }] },
      //         { link: ['/eventos/staff'], title: 'Relação Staff', permissions: [{ url: '/eventos/staff' }] },
      //       ]
      //     },
      //   ]
      // },

      { link: null, title: 'Financeiro', icon: 'attach_money', permissions: [{ url: '/fin' }], checkChildren: false,
        menus: [
          // { link: ['/calendario-new'], title: 'Calendário', permissions: [{ url: '/fin-calendario' }], checkChildren: false },
          // { link: ['/dashboard-new3'], title: 'Dashboard', permissions: [{ url: '/dashboard-new3' }], checkChildren: false },
          // { link: ['/dashboard-new4'], title: 'Dashboard Gerencial', permissions: [{ url: '/dashboard-new4' }], checkChildren: false },
          // { link: ['/financeiro/fatura/configuracao/importacao-ofx'], title: 'Importação', permissions: [{ url: '/importacao-ofx' }] },
          // { link: ['/extrato-ofx'], title: 'Extrato OFX', permissions: [{ url: '/extrato-ofx' }], checkChildren: false },
          // { link: ['/movimentacao'], title: 'Movimentação Bancária', permissions: [{ url: '/movimentacao' }], checkChildren: false },
          // { link: ['/receb-pagto/' + 'cp'], title: 'Pagamento', permissions: [{ url: '/fin-cp' }], checkChildren: false },
          // { link: ['/receb-pagto/' + 'cr'], title: 'Recebimento', permissions: [{ url: '/fin-cr' }], checkChildren: false },
          // { link: ['/remessas/'], title: 'Remessas/Retornos', permissions: [{ url: '/remessas' }], checkChildren: false },
          // { link: ['/financeiro/fatura'], title: 'Despesas Diretas', permissions: [{ url: '/financeiro/fatura' }], checkChildren: false },
          // { link: ['/financeiro/periodo'], title: 'Controle de Período', permissions: [{ url: '/financeiro/periodo' }], checkChildren: false },
          // { link: null, title: 'Relatórios',permissions: [{ url: '/fin-relatorios' }], checkChildren: false,
            // menus: [
            //   { link: ['/financeiro/fluxo-caixa'], title: 'Fluxo de Caixa',permissions: [{ url: '/fluxo_caixa' }] },
              // { link: ['/financeiro/relatorio'], title: 'Relatórios Gerenciais', permissions: [{ url: '/financeiro/relatorio' }] },
            // ]
          // },
          { link: null, title: 'Cadastros', permissions: [{ url: '/fin-cadastros' }], checkChildren: false,
            menus: [
              // { link: ['/financeiro/fatura/configuracao/cadastro-regra-ofx'], title: 'Cadastro Regra OFX', permissions: [{ url: '/cadastro-regra-ofx' }] },
              // { link: ['/financeiro/fatura/configuracao/cadastro-regra-centralweb'], title: 'Cadastro Regra CentralWeb', permissions: [{ url: '/cadastro-regra-centralweb' }] },
              // { link: ['/empresa-conta-ref'], title: 'Contas Bancárias', permissions: [{ url: '/empresa-conta-ref' }] },
              // { link: ['/forma-pagamento'], title: 'Forma Pagamento', permissions: [{ url: '/forma-pagamento' }] },
              { link: ['/cambio'], title: 'Câmbio', permissions: [{ url: '/cambio' }] },
              { link: ['/indices'], title: 'Índices', permissions: [{ url: '/indices' }] },
              { link: ['/eventos/vendedor'], title: 'Vendedor', permissions: [{ url: '/eventos/vendedor' }] }
            ]
          },
          { link: null, title: 'Comissões', permissions: [{ url: '/fin-cadastros' }], checkChildren: false,
            menus: [
              { link: ['financeiro/cadastro-comissao'], title: 'Grupos de Comissão', permissions: [{ url: '/fin-cadastros' }] }
            ]
          },

          { link: null, title: 'Configurações', permissions: [{ url: '/fin-cadastros' }], checkChildren: false,
            menus: [
              { link: ['/financeiro/fluxo-caixa-ctrl'], title: 'Parâmetros', permissions: [{ url: '/fluxo_caixa' }] },
              // { link: ['/financeiro/fatura/configuracao/aprovador-fatura'], title: 'Aprovador Fatura', permissions: [{ url: '/aprovador-fatura' }] },
              // { link: ['/conf-dashboard'], title: 'Conf. Dashboard', permissions: [{ url: '/conf-dashboard' }] },
            ]
          },
        ]
      },
      // { link: null, title: 'LeadLovers', icon: 'favorite', permissions: [{ url: '/leadLovers' }], checkChildren: false,
      //   menus: [
      //     { link: ['/eventos/leadlovers'], title: 'LeadLovers', permissions: [{ url: '/leadLovers' }], checkChildren: false }
      //   ]
      // },
      { link: null,  title: 'Orçamentos', icon: 'savings', permissions: [{ url: '/orcamento' }], checkChildren: false,
        menus: [
          // { link: ['/orcamento/dashboard-orca-new'], title: 'Dashboard', permissions: [{ url: '/orcamento/dashboard-orca-new' }], checkChildren: false },
          // { link: ['/orcamento/dashboard-bi'], title: 'Dashboard BI', permissions: [{ url: '/orcamento-dashboard-trbr' }], checkChildren: false },
          // { link: ['/orcamento/controle-orcamento'], title: 'Controle Orçamento', permissions: [{ url: '/controle-orcamento' }], checkChildren: false },
          { link: ['/orcamento/controle-orcamento-fini'], title: 'Controle Orçamento (FINI)', permissions: [{ url: '/controle-orcamento-fini' }], checkChildren: false },
          { link: ['/orcamento/ata-orcamento'], title: 'Plano de Ação', hint: '', permissions: [{ url: '/ata-orcamento' }], checkChildren: false },
          // { link: ['/orcamento/gerencial-orcamento'], title: 'Gerencial', permissions: [{ url: '/gerencial-orcamento' }], checkChildren: false },
          { link: ['/orcamento/importar-arquivos-orcamento'], title: 'Importar Arquivos', permissions: [{ url: '/import-orcamento' }], checkChildren: false },
          { link: ['/orcamento/relatorios'], title: 'Relatórios', permissions: [{ url: '/orcamento/relatorios' }], checkChildren: false },
          { link: ['/orcamento/relatorios-gest'], title: 'Relatórios (Gestão)', permissions: [{ url: '/orcamento/relatorios' }], checkChildren: false },
          { link: null, title: 'Configurações', hint: '', permissions: [{ url: '/orcamento/configuracoes' }], checkChildren: false,
            menus: [
              // { link: ['/orcamento/cadastro-area'], title: 'Área', permissions: [{ url: '/orcamento/cadastro-area' }] },
              // { link: ['/orcamento/classificacao'], title: 'Classificação', permissions: [{ url: '/orcamento/classificacao' }] },
              { link: ['/orcamento/periodo'], title: 'Controle de Período', permissions: [{ url: '/orcamento/periodo' }] },
              { link: ['/cadastro/gestor-centro-custo'], title: 'Gestores', permissions: [{ url: '/cad-aprov-centro-custo' }] },
              // { link: ['/orcamento/configuracoes/configuracao-importacao'], title: 'Configurações', permissions: [{ url: '/orcamento/configuracoes/configuracao-importacao' }] },
              { link: ['/orcamento/configuracoes/periodo'], title: 'Período', permissions: [{ url: '/orcamento/configuracoes/periodo' }] },
              // { link: ['/orcamento/configuracoes/taxa-moeda'], title: 'Taxa Moeda', permissions: [{ url: '/orcamento/configuracoes/taxa-moeda' }] },
              // { link: ['/orcamento/configuracoes/tipo-orcamento'], title: 'Tipo Orçamento', permissions: [{ url: '/orcamento/configuracoes/tipo-orcamento' }] },
              { link: ['/orcamento/versao-orcamento'], title: 'Versão Orçamento', permissions: [{ url: '/versao-orcamento' }] }
            ]
          }
        ]
      },
      // { link: null, title: 'PagSeguro', icon: 'credit_card', permissions: [{ url: '/pagseguro' }], checkChildren: false,
      //   menus: [
      //     { link: ['pagseguro/boleto'], title: 'Gerar Boletos', permissions: [{ url: '/pagseguroboleto' }], checkChildren: false },
      //     { link: ['pagseguro/gerenciar-boleto'], title: 'Gerenciar Boletos', permissions: [{ url: '/gerenciarpagseguro' }], checkChildren: false },
      //     { link: ['pagseguro/relatorio-boletos'], title: 'Relatorio Boletos', permissions: [{ url: '/relatorioboletos' }], checkChildren: false }
      //   ]
      // },
      { link: null, title: 'Recursos Humanos', icon: 'groups', permissions: [{ url: '/rh' }], checkChildren: false,
        menus: [
          { link: ['/rh/importar-fp'], title: 'Importar', permissions: [{ url: '/rh/importar-fp' }], checkChildren: false },
          { link: ['/rh/dashboard'], title: 'Dashboard', permissions: [{ url: '/rh' }], checkChildren: false },
          { link: ['/rh/relatorios'], title: 'Relátorios', permissions: [{ url: '/rh/relatorios' }], checkChildren: false },
          { link: ['/cad-benef-encargo-cta-relac'], title: 'Vinculações Contábeis', permissions: [{ url: '/cad-benef-encargo-cta-relac' }], checkChildren: false },
          { link: ['/cad-rateio'], title: 'Rateio', permissions: [{ url: '/cad-rateio' }], checkChildren: false },
          { link: null, title: 'Cadastro', permissions: [{ url: '/rh' }], checkChildren: false,
            menus: [
              { link: ['/cad-beneficio'], title: 'Benefícios', permissions: [{ url: '/cad-beneficio' }] },
              { link: ['/cad-cargo'], title: 'Cargos', permissions: [{ url: '/cad-cargo' }] },
              { link: ['/cad-encargos'], title: 'Encargos e provisões', permissions: [{ url: '/cad-encargos' }] },
              { link: ['/cad-func'], title: 'Funcionários', permissions: [{ url: '/cad-func' }] },
              { link: ['/cad-nivel'], title: 'Níveis', permissions: [{ url: '/cad-nivel' }] },
              { link: ['/cad-periodo'], title: 'Períodos', permissions: [{ url: '/cad-periodo' }] }
            ]
          },
        ]
      },
      // { link: null, title: 'Reinf', icon: 'gavel', permissions: [{ url: '/reinf' }], checkChildren: false,
      //   menus: [
      //     { link: ['/reinf/eventos'], title: 'Eventos', permissions: [{ url: '/reinf/eventos' }], checkChildren: false }
      //   ]
      // },
      { link: null, title: 'Workstats', icon: 'trending_up', permissions: [{ url: '/powerbi' }], checkChildren: false,
        menus: [
          { link: ['/powerbi/acesso'], title: 'Acessos', permissions: [{ url: '/acessospbi' }], checkChildren: false },
          { link: ['/powerbi/cadastro'], title: 'Cadastro', permissions: [{ url: '/cadastropowerbi' }], checkChildren: false },
          // { link: ['/planogerencialnew'], title: 'Plano Gerencial', permissions: [{ url: '/planogerencialnew' }], checkChildren: false },
          { link: ['/powerbi/relatorios'], title: 'Relatórios BI', permissions: [{ url: '/relatoriospowerbi' }], checkChildren: false },
          // { link: ['/workstats/relatorios-gerenciais'], title: 'Relatórios Gerenciais', permissions: [{ url: '/workstats/relatorios-gerenciais' }], checkChildren: false },
          // { link: null, title: 'Configurações', permissions: [{ url: '/powerbi/configuracoes' }],  checkChildren: false,
            // menus: [
            //   { link: ['/view-campos'], title: 'Campos da View', permissions: [{ url: '/view-campos' }] },
            //   { link: ['/config-relat'], title: 'Conf. Relatórios Gerenciais', permissions: [{ url: '/config-relat' }] },
            //   { link: ['/view'], title: 'View do Sistema', permissions: [{ url: '/view' }] },
            // ]
          // }
        ]
      },
      // { link: null, title: 'Requisições', icon: 'request_page', permissions: [{ url: '/erp/rms' }], checkChildren: false,
      //   menus: [
      //     { link: ['rms/requisicao-material'], title: 'Requisição Materiais', permissions: [{ url: '/erp/rms/requisicao-material' }], checkChildren: false },
      //     { link: null, title: 'Configurações', permissions: [{ url: '/erp/rms/configuracao' }], checkChildren: false,
      //       menus: [
      //         { link: ['rms/prioridade'], title: 'Prioridade', permissions: [{ url: '/erp/rms/configuracao/prioridade' }] },
      //         { link: ['rms/tipo'], title: 'Tipo de Requisição', permissions: [{ url: '/erp/rms/configuracao/tipo' }] },
      //         { link: ['rms/nda'], title: 'NDA', permissions: [{ url: '/erp/rms/configuracao/nda' }] },
      //         { link: ['rms/objeto'], title: 'Objeto', permissions: [{ url: '/erp/rms/configuracao/objeto' }] },
      //         { link: ['rms/origem'], title: 'Origem', permissions: [{ url: '/erp/rms/configuracao/origem' }] },
      //         { link: ['rms/categoria'], title: 'Categoria', permissions: [{ url: '/erp/rms/configuracao/categoria' }] },
      //         { link: ['rms/parametros'], title: 'Parâmetros', permissions: [{ url: '/erp/rms/configuracao/parametros' }] },
      //       ],
      //     }
      //   ]
      // },
      // { link: null, title: 'Siscoserv', icon: 'gavel', permissions: [{ url: '/siscoserv' }], checkChildren: false,
      //   menus: [
      //     { link: ['/siscoserv/import'], title: 'Importar Arquivos', permissions: [{ url: '/siscoserv/import' }], checkChildren: false },
      //     { link: ['/siscoserv/export-xml'], title: 'Exportar XML', permissions: [{ url: '/siscoserv/export-xml' }], checkChildren: false },
      //     { link: ['/siscoserv/status'], title: 'Status', permissions: [{ url: '/siscoserv/status' }], checkChildren: false },
      //     { link: ['/siscoserv/editar'], title: 'Editar Arquivos', permissions: [{ url: '/siscoserv/editar' }], checkChildren: false },
      //     { link: null, title: 'Config para Importação', permissions: [{ url: '/siscoserv' }], checkChildren: false,
      //       menus: [
      //         { link: ['/siscoserv/enquadramento'], title: 'Enquadramentos', permissions: [{ url: '/siscoserv/enquadramento' }], active: false },
      //         { link: ['/siscoserv/moeda'], title: 'Moedas', permissions: [{ url: '/siscoserv/moeda' }] },
      //         { link: ['/siscoserv/modo-prestacao'], title: 'Modos de Prestação', permissions: [{ url: '/siscoserv/modo-prestacao' }] },
      //         { link: ['/siscoserv/nao-nif'], title: 'Motivos para dispensa Nif', permissions: [{ url: '/siscoserv/nao-nif' }] },
      //         { link: ['/siscoserv/nbs'], title: 'NBS', permissions: [{ url: '/siscoserv/nbs' }] },
      //         { link: ['/siscoserv/pais'], title: 'Países', permissions: [{ url: '/siscoserv/pais' }] },
      //         { link: ['/siscoserv/tipo-registro'], title: 'Tipos de Registro', permissions: [{ url: '/siscoserv/tipo-registro' }] },
      //         { link: ['/siscoserv/tipo-vinculo'], title: 'Tipos de Vínculo', permissions: [{ url: '/siscoserv/tipo-vinculo' }] }
      //       ]
      //     },
      //   ]
      // },
      { link: null, icon: 'menu_book', title: 'Solução Fiscal', permissions: [{ url: '/fiscal' }], checkChildren: false,
        menus: [
          // { link: ['analise-xml/import'], title: 'Analise XML Faltantes', permissions: [{ url: '/analise-xml/import' }] },
          // { link: ['/fiscal/arquivos-magneticos'], title: 'Exportar', permissions: [{ url: '/fiscal/arquivos-magneticos' }], checkChildren: false },
          // { link: ['/fiscal/import-fiscal'], title: 'Importar', permissions: [{ url: '/import-fiscal' }], checkChildren: false },
          // { link: ['/regra'], title: 'Cadastro de Regras', permissions: [{ url: '/regra' }], checkChildren: false },
          // { link: ['/grupo'], title: 'Grupos', permissions: [{ url: '/grupo' }], checkChildren: false },
          // { link: ['/grupo-relac'], title: 'Manutenção de Regras', permissions: [{ url: '/grupo-relac' }], checkChildren: false },
          // { link: ['/projeto-dbl'], title: 'Projeto DBL', permissions: [{ url: '/projeto-dbl' }], checkChildren: false },
          // { link: ['/fiscal/report'], title: 'Relatórios', permissions: [{ url: '/fiscal/report' }], checkChildren: false },
          { link: ['/fiscal/report-xml'], title: 'Relatórios XML', permissions: [{ url: '/fiscal/report-xml' }], checkChildren: false },
          // { link: ['/fiscal/registro-exportacao'], title: 'Registros de Exportação', permissions: [{ url: '/fiscal/registro-exportacao' }], checkChildren: false },
          // { link: ['/grupo-regra-empresa-relac'], title: 'Relac de Grupos e Regras', permissions: [{ url: '/grupo-regra-empresa-relac' }], checkChildren: false },
          { link: ['/fiscal/import-f600'], title: 'Importar F600', permissions: [{ url: '/fiscal/import-f600' }], checkChildren: false },
          // { link: ['/fiscal/certificados-digitais'], title: 'Certificados Digitais', permissions: [{ url: '/fiscal/certificados-digitais' }], checkChildren: false },
          // { link: ['/fiscal/periodo'], title: 'Controle de Período', permissions: [{ url: '/fiscal/periodo' }], checkChildren: false },
          // { link: ['/fiscal/edit-livro'], title: 'Editar Livro Fiscal', permissions: [{ url: '/fiscal/edit-livro' }], checkChildren: false },
        ]
      },
      { link: null, title: 'Tabela de Códigos', icon: 'table_view', permissions: [{ url: '/util-tabelas' }], checkChildren: false,
        menus: [
          { link: null, title: 'Tabelas Contábeis', permissions: [{ url: '/util-tabelas' }], checkChildren: false,
            menus: [
              { link: ['/cod-natureza-conta'], title: 'Natureza Conta Contábil', permissions: [{ url: '/cod-natureza-conta' }], checkChildren: false },
              { link: ['/cod-rel'], title: 'Relatório', permissions: [{ url: '/cod-rel' }], checkChildren: false },
              { link: ['/forma-escrit-contabil'], title: 'Forma Escrit. Contábil', permissions: [{ url: '/forma-escrit-contabil' }], checkChildren: false },
              { link: ['/ind-fin-esc'], title: 'Ind Fin Esc', permissions: [{ url: '/ind-fin-esc' }], checkChildren: false },
              { link: ['/ind-grande-porte'], title: 'Ind Grande Porte', permissions: [{ url: '/ind-grande-porte' }], checkChildren: false },
              { link: ['/ind-nire'], title: 'Ind Nire', permissions: [{ url: '/ind-nire' }], checkChildren: false },
              { link: ['/ind-sit-esp'], title: 'Ind Sit Esp', permissions: [{ url: '/ind-sit-esp' }], checkChildren: false },
              { link: ['/ind-sit-ini-per'], title: 'Ind Sit Ini Per', permissions: [{ url: '/ind-sit-ini-per' }], checkChildren: false },
              { link: ['/tipo-ecd'], title: 'Tipo ECD', permissions: [{ url: '/tipo-ecd' }], checkChildren: false }
            ]
          },
          { link: null, title: 'Tabelas Fiscais', permissions: [{ url: '/util-tabelas' }], checkChildren: false,
            menus: [
              { link: ['/cfop'], title: 'CFOP', permissions: [{ url: '/cfop' }], checkChildren: false },
              { link: ['/cst-icms'], title: 'CST ICMS', permissions: [{ url: '/cst-icms' }], checkChildren: false },
              { link: ['/cst-piscofins'], title: 'CST PIS COFINS', permissions: [{ url: '/cst-piscofins' }], checkChildren: false },
              { link: ['/tipo-item'], title: 'Tipo Item', permissions: [{ url: '/tipo-item' }], checkChildren: false },
              { link: ['/lst'], title: 'Código de Serviço', permissions: [{ url: '/lst' }], checkChildren: false },
              { link: ['/tipi'], title: 'TIPI', permissions: [{ url: '/tipi' }], checkChildren: false },
              { link: ['/unidade-medida'], title: 'Unidade Medida', permissions: [{ url: '/unidade-medida' }], checkChildren: false }
            ]
          },
          { link: null, title: 'Cadastro de Localidades', permissions: [{ url: '/util-tabelas' }], checkChildren: false,
            menus: [
              { link: ['/municipio-ibge'], title: 'Municípios', permissions: [{ url: '/municipio-ibge' }], checkChildren: false },
              { link: ['/uf'], title: 'Estados', permissions: [{ url: '/uf' }], checkChildren: false },
              { link: ['/pais'], title: 'Países', permissions: [{ url: '/pais' }], checkChildren: false }
            ]
          },
        ]
      },
      // { link: null, title: 'Transfer Pricing', icon: 'compare_arrows', permissions: [{ url: '/transfer' }], checkChildren: false,
      //   menus: [
      //     { link: ['/transfer/dashboard1'], title: 'Dashboard', permissions: [{ url: '/transfer-dashboard1' }], checkChildren: false },
      //     { link: ['/transfer/import'], title: 'Importar', permissions: [{ url: '/transfer-import' }], checkChildren: false },
      //     { link: ['/transfer/periodo'], title: 'Período', permissions: [{ url: '/transfer-periodo' }], checkChildren: false },
      //     { link: ['/transfer/relatorio-transfer'], title: 'Configurações', permissions: [{ url: '/transfer-periodo' }], checkChildren: false },
      //     { link: ['/transfer/relatorios'], title: 'Relatórios', permissions: [{ url: '/transfer-periodo' }], checkChildren: false }
      //   ]
      // },
      { link: null, title: 'Vendas', icon: 'sell', permissions: [{ url: '/vendas' }], checkChildren: false,
        menus: [
          // { link: ['vendas/dashboard-vendas'], title: 'Dashboard', permissions: [{ url: '/dashboard_venda' }], checkChildren: false },
          // { link: ['pedido-produto/pedido-produto/' + 1], title: 'Pedidos - Venda Produto', permissions: [{ url: '/pedido-produto/pedido-produto/1' }], checkChildren: false },
          // { link: ['pedido-servico/pedido-servico/' + 1], title: 'Pedidos - Venda Serviço', permissions: [{ url: '/pedido-servico/pedido-servico/1' }], checkChildren: false },
          // { link: ['pedido-produto-new/pedido-produto-new/' + 1], title: 'Pedidos - Venda Produto', permissions: [{ url: '/pedido-produto/pedido-produto-new/1' }], checkChildren: false },
          // { link: ['pedido-servico-new/pedido-servico-new/' + 1], title: 'Pedidos - Venda Serviço', permissions: [{ url: '/pedido-servico/pedido-servico-new/1' }], checkChildren: false },
          // { link: ['/notas-emitidas'], title: 'Notas Fiscais', permissions: [{ url: '/notas-emitidas' }], checkChildren: false },
          // { link: ['/relatorio-pedidos/' + 1], title: 'Relatórios', permissions: [{ url: '/relatorio-pedidos' }], checkChildren: false },
          { link: null, title: 'Cadastros', permissions: [{ url: '/erp-cad' }], checkChildren: false,
            menus: [
              // { link: ['/participante-empresa'], title: 'Clientes e Fornecedores', permissions: [{ url: '/participante-empresa1' }] },
              // { link: ['/item-ref'], title: 'Produtos e Serviços', permissions: [{ url: '/cad-prod1' }] },
              { link: ['/financeiro/parametros-vendas'], title: 'Parâmetros', permissions: [{ url: '/parametros-vendas' }] },
            ]
          },
        ]
      },
      { link: null,  icon: 'account_tree', title: 'Workflow', permissions: [{ url: '/erp/workflow' }], checkChildren: false,
        menus: [
          // { link: ['centralweb/work-flow-projeto'], title: 'Projetos', permissions: [{ url: '/erp/workflow/projetos' }], checkChildren: false },
          // { link: ['centralweb/work-flow/central-aprovacao'], title: 'Central de Aprovação', permissions: [{ url: '/erp/workflow/central-aprovacao' }], checkChildren: false },
          // { link: ['centralweb/work-flow/autorizacao-debito'], title: 'Autorização de Pagamentos', permissions: [{ url: '/erp/workflow/autorizacao-debito' }], checkChildren: false },
          { link: null, title: 'Configurações', permissions: [{ url: '/erp/workflow/projetos' }], checkChildren: false,
            menus: [
              { link: ['cadastro/aprovacao'], title: 'Cadastro Aprovação', permissions: [{ url: '/erp/workflow/projetos' }] },
            ]
          }
        ]
      },
      // { link: null, title: 'CRM', icon: 'gavel', permissions: [{ url: '/crm' }], checkChildren: false,
      //   menus: [
      //     { link: null, title: 'Configurações', permissions: [{ url: '/crm-configuracao' }], checkChildren: false,
      //       menus: [
      //         { link: ['crm/tabela-crm'], title: 'Tabelas', permissions: [{ url: '/crm/tabela-crm' }] },
      //         { link: ['crm/tabela-crm-detalhe'], title: 'Tabela Crm Detalhe', permissions: [{ url: '/crm/tabela-crm-detalhe' }] },
      //         { link: ['crm/tabela-crm-carga'], title: 'Tabela Crm Carga', permissions: [{ url: '/crm/tabela-crm-carga' }] },
      //       ]
      //     }
      //   ]
      // },
    ]

    this.setId(this.menus)
  }

  private setId(menus: Array<NavigationMenu>, lastId?: number): number {
    menus.forEach(menu => {
      lastId = lastId ? lastId + 1 : 1
      menu.id = lastId

      if (menu.menus && menu.menus.length > 0) {
        lastId++
        lastId = this.setId(menu.menus, lastId)
      }
    })

    return lastId
  }
}
