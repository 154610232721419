<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 pb-4">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Projetos Lei do Bem</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
              <!-- <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardTable()"
                (click)="changeStateTable()">
                <span *ngIf="isCardTable" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardTable" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button> -->
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateTable>
          <p-table class="table-system" #dt [columns]="colsCadLeiDoBem" [value]="ldbProjetosLista" [rows]="rows"
            [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" (onRowUnselect)="onRowUnselect()"
            [style]="{'width':'100%'}" (onFilter)="onFilter($event)"
            [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''"
            >

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                    <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                    <span class="text-overflow-dynamic-ellipsis">ÚLTIMA EDIÇÃO <p-sortIcon [field]="'data'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'data', 'contains')">
                </th>
                <th class="text-center" style="width: 80%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">DESCRICÃO <p-sortIcon [field]="'descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                      matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                      matTooltip="{{rowData.data | date:'dd/MM/yyyy'}}">{{rowData.data | date:'dd/MM/yyyy'}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 80%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                      matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="3" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum projeto Lei do Bem encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>

          </p-table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <ul class="list-inline filter-menu filter-card-header mt-0">
              <li class="list-inline-item" [ngClass]="{active: statusTela1}"><a (click)="showTela('TELA_1')">Vinculação
                  de projetos</a></li>
              <li class="list-inline-item" [ngClass]="{active: statusTela2}"><a (click)="showTela('TELA_2')">Vinculação
                  de segmentos</a></li>
            </ul>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainVin"></app-toolbar>
              <!-- <button type="button" *ngIf="statusTela1" (click)="salvarVinculacaoProjetos()"
                class="btn btn-xs btn-link btn-full" matTooltip="Salvar Alterações" matTooltipPosition="above">
                <span class="material-icons full-icon" style="font-size: 22px;">save</span>
              </button>
              <button type="button" *ngIf="statusTela2" (click)="salvarVinculacaoSegmentos()"
                class="btn btn-xs btn-link btn-full" matTooltip="Salvar Alterações" matTooltipPosition="above">
                <span class="material-icons full-icon" style="font-size: 22px;">save</span>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardVinculacao()"
                (click)="changeStateVinculacao()">
                <span *ngIf="isCardVinculacao" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardVinculacao" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button> -->
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateVinculacao>
          <div style="margin-left: 350px;">
          <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusTela1'}">
            <div class="row">
              <div class="col-sm-8">
                <div class="conteudo m-0"> 
                  <p-pickList sourceHeader="Disponíveis" targetHeader="Projetos vinculados"
                    [source]="listaProjetosDisponiveis" [target]="listaProjetosVinculados" [dragdrop]="true"
                    [responsive]="true" filterBy="codigo" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-projeto pTemplate="item">{{ projeto.codigo }} - {{ projeto.descricao }}
                    </ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
          </div>
          <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusTela2'}">
            <div class="row">
              <div class="col-sm-8">
                <div class="conteudo m-0">
                  <p-pickList sourceHeader="Disponíveis" targetHeader="Segmentos vinculados"
                    [source]="listaSegmentosDisponiveis" [target]="listaSegmentosVinculados" [dragdrop]="true"
                    [responsive]="true" filterBy="codigo" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-segmento pTemplate="item">{{ segmento.codigo }} - {{ segmento.descricao }}
                    </ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<!-- MODAL NOVA LEI DO BEM -->
<p-dialog header="Adicionar nova Lei do Bem" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do projeto Lei do Bem" [(ngModel)]="newCodigo" required>
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="newDescricao" required>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addLeiDoBem();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>

<!-- MODAL EDIT LEI DO BEM -->
<p-dialog header="Editar Lei do Bem" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do projeto Lei do Bem" [(ngModel)]="editCodigo"
        required>
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="editDescricao" required>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-danger" (click)='deleteLeiDoBem();'><i class="fas fa-times"></i>
          Excluir</button>
        <button type="button" class="btn btn-primary" (click)="editLeiDoBem();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>