<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div *ngIf="empresa?.id && periodo?.id">
      <div *ngIf="lancamentoPartida.length === 0" class="box-content">
        <!-- <p>Escolha a conta abaixo para receber o resultado do exercício</p> -->
        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>Selecionar Conta</label>
            <p-dropdown class="type-p-dropdown" [options]="opcoesPlanoContas" [(ngModel)]="contaSelecionada">
            </p-dropdown>
          </div>
        </div>
        <!-- <p>Visualização do Lançamento a ser efetuado, a contra-partida será feita na conta contábil selecionada:</p> -->
        <p-table class="table-system" #dt [value]="previewLcto" [style]="{'width':'100%'}" immutable="false" [rows]="10"
          [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[5,10,15,20]">
          <!--  <p-header>Pré-Visualização Lançamento de Encerramento</p-header> -->
          <ng-template pTemplate="header">
            <tr>
              <th class="text-left" style="width: 80%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                  <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL <p-sortIcon [field]="'codCta'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'codCta', 'contains')">
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'indLcto'">
                  <span class="text-overflow-dynamic-ellipsis">IND LCTO <p-sortIcon [field]="'indLcto'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'indLcto', 'contains')">
              </th>
              <th class="text-right" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'valor', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-left" style="width: 80%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">
                    {{ rowData.codCta }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indLcto}}">{{rowData.indLcto
                    }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor | money}}">{{rowData.valor |
                    money }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="3" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <br>

        <div class="row" *ngIf="contaSelecionada">
          <div class="form-group col-sm-12">
            <div class="form-row align-items-end font-comeco">
              <div class="form-group col-sm-9">

              </div>
              <div class="form-group col-sm-2">
                <label>DATA ENCERRAMENTO<span class="obrigatorio">*</span></label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dtEncerramento"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" ></p-calendar>

              </div>
              <div class="form-group col-sm-1 text-right">
                <label style="color: #1b1d1f;">Resultado:</label> {{resultado | money }}
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="contaSelecionada">
          <div class="form-group col-sm-12">
            <div class="form-row align-items-end font-comeco">
              <div class="form-group col-sm-11">

              </div>
              <div class="form-group col-sm-1 text-right">
                <button type="button" class="btn btn-sm btn-primary" (click)="lancarEncerramento()"><i
                    class="fas fa-sync"></i> Encerrar</button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div *ngIf="lancamentoPartida.length > 0">
      <div class="box-content">
        <p-table class="table-system" #dt1 [value]="lancamentoPartida" [style]="{'width':'100%'}" immutable="false"
          [rows]="10" [paginator]="true" [pageLinks]="5" [rowsPerPageOptions]="[5,10,15,20]">
          <!--  <p-header>Pré-Visualização Lançamento de Encerramento</p-header> -->
          <ng-template pTemplate="header">
            <tr>
              <th class="text-left" style="width: 80%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'planoContas'">
                  <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL <p-sortIcon [field]="'planoContas'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'planoContas', 'contains')">
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'indDc'">
                  <span class="text-overflow-dynamic-ellipsis">IND LCTO <p-sortIcon [field]="'indDc'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'indDc', 'contains')">
              </th>
              <th class="text-right" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'valor', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-left" style="width: 80%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.planoContas }}">
                    {{ rowData.codCta }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indDc}}">{{rowData.indDc }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor | money}}">{{rowData.valor |
                    money }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="3" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="alert alert-warning mt-3" role="alert">
        <strong><i class="fas fa-exclamation-triangle"></i></strong> O lançamento de encerramento poderá ser estornado
        clicando em <button type="button" class="btn btn-xs btn-link"
          style="font-weight: bold; font-size: 12px !important; padding: 0 2px !important; color: #856404;"
          (click)="deletarEncerramento()" [disabled]="disabled"><i class="fas fa-undo"></i> Desfazer
          Encerramento</button>
      </div>
    </div>

  </mat-card-content>

</mat-card>