<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="box-content" style="width: 800px;">
    <p-accordion>

      <p-accordionTab header="Relacionar View com Projeto">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>View</label>
            <p-dropdown class="type-p-dropdown" [options]="opcoesViews" [filter]="true" (onChange)="onChangeView($event)" [(ngModel)]="viewSelecionada"></p-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <div class="conteudo m-0">
              <p-pickList sourceHeader="Projetos Disponíveis" targetHeader="Projetos Selecionados" [source]="projetos" [target]="projetosSelecionados" [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-projeto pTemplate="item">{{projeto.cod}} - {{projeto.descricao}}</ng-template>
              </p-pickList>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!viewSelecionada" (click)="atualizarRelacView()"><i class="fas fa-sync-alt"></i> Atualizar</button>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Relacionar Regra com Grupos">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Regra</label>
            <p-dropdown class="type-p-dropdown" [options]="opcoesRegras" [filter]="true" (onChange)="onChangeRegra($event)" [(ngModel)]="regraSelecionada"></p-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <div class="conteudo m-0">
              <p-pickList sourceHeader="Grupos Disponíveis" targetHeader="Grupos Selecionados" [source]="grupos" [target]="gruposSelecionados" [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-grupo pTemplate="item">{{grupo.descricao}}</ng-template>
              </p-pickList>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!regraSelecionada" (click)="atualizarRegraGrupo()"><i class="fas fa-sync-alt"></i> Atualizar</button>
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Relacionar Grupo com Empresas">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Grupo</label>
            <p-dropdown class="type-p-dropdown" [options]="opcoesGrupos" [filter]="true" (onChange)="onChangeGrupo($event)" [(ngModel)]="grupoSelecionado"></p-dropdown>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <div class="conteudo m-0">
              <p-pickList sourceHeader="Empresas Disponíveis" targetHeader="Empresas Selecionadas" [source]="empresas" [target]="empresasSelecionadas" [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-emp pTemplate="item">{{emp.cnpj | cnpj}} - {{emp.nomeFantasia}}</ng-template>
              </p-pickList>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <button type="button" class="btn btn-sm btn-primary" [disabled]="!grupoSelecionado" (click)="atualizarGrupoEmpresa()"><i class="fas fa-sync-alt"></i> Atualizar</button>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</mat-card>
