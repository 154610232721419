<div>
  <p-steps class="w-100" styleClass="step-form" [model]="stepMenu"  [(activeIndex)]="activeTab" [readonly]="true"></p-steps>

  <div *ngIf="strActiveTab === 'Resumo' || strActiveTab === 'Representante legal'" class="w-100">
    <h6 *ngIf="strActiveTab === 'Resumo'">Representante legal</h6>
    <div *ngIf="isHaveAccount" class="col-sm-12 mt-4" style="padding: 0 !important">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>ID da conta <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="loginAccount.accountId" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-12" *ngIf="strActiveTab === 'Representante legal'">
          <small class="small-link" (click)="changeFormRegister()">Não possui conta? Cadastrar-se!</small>
        </div>
      </div>
    </div>
    <div *ngIf="!isHaveAccount" class="col-sm-12 mt-4" style="padding: 0 !important">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Nome <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="50" [(ngModel)]="representative.nome" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-6">
          <label>E-mail <span class="obrigatorio">*</span></label>
          <input  type="text" class="form-control" [(ngModel)]="representative.email" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-2">
          <label>CPF <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="11" [(ngModel)]="representative.cpf" (keyup)="onlyNumbersFormat('cpf')" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
         <div class="form-group col-sm-2">
          <label>Nascimento <span class="obrigatorio">*</span></label>
          <p-calendar [class]="'type-p-calendar datepicker-form-cfg ' + ((strActiveTab === 'Resumo') ? 'disabled-prime-input' : null)" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'1900:' + actualYear" dateFormat="dd/mm/yy" appendTo="body" [(ngModel)]="baseDate" [disabled]="strActiveTab === 'Resumo'" (onSelect)="formatDateStr()"></p-calendar>
        </div>
        <div class="form-group col-sm-2">
          <label>Telefone <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="11" [(ngModel)]="representative.telefone"  (keyup)="onlyNumbersFormat('telefone')" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-6">
          <label>Nome da mãe <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="50" [(ngModel)]="representative.nome_mae" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-2">
          <label>CEP <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="8" [(ngModel)]="representative.endereco.cep" (keyup.enter)="getCompleteAddress()" (blur)="getCompleteAddress()" (keyup)="onlyNumbersFormat('cep', true)" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-4">
          <label>Logradouro <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="50" [(ngModel)]="representative.endereco.logradouro" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-2">
          <label>Complemento</label>
          <input type="text" class="form-control" maxlength="50" [(ngModel)]="representative.endereco.complemento" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-1">
          <label>Número <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="10" [(ngModel)]="representative.endereco.numero" (keyup)="onlyNumbersFormat('numero', true)" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-1">
          <label>Bairro <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="30" [(ngModel)]="representative.endereco.bairro" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-1">
          <label>Cidade <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="50" [(ngModel)]="representative.endereco.cidade" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-1">
          <label>UF <span class="obrigatorio">*</span></label>
          <input type="text" class="form-control" maxlength="2" [(ngModel)]="representative.endereco.uf" (keyup)="ufFormat()" [disabled]="strActiveTab === 'Resumo'"/>
        </div>
        <div class="form-group col-sm-12" *ngIf="strActiveTab === 'Representante legal'">
          <small class="small-link" (click)="changeFormRegister()">Possui conta? Logar-se!</small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="strActiveTab === 'Resumo'" class="w-100">
    <h6 *ngIf="strActiveTab === 'Resumo'">Chave PIX</h6>
    <div class="col-sm-12 mt-4" style="padding: 0 !important">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <input type="text" class="form-control" value="Sua chave PIX EVP será gerada pela Matera!" [disabled]="true"/>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="strActiveTab === 'Resumo' || strActiveTab === 'Cobrança'" class="w-100">
    <h6 *ngIf="strActiveTab === 'Resumo'">Cobrança</h6>
    <div class="col-sm-12 mt-4" style="padding: 0 !important">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Conta destino</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optCompanyAccounts" emptyMessage="Nenhum resultado encontrado!" [disabled]="strActiveTab === 'Resumo' || loadingOptCompanyAccounts" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="settingsLevy.empresa_conta_id" [styleClass]="(strActiveTab === 'Resumo') ? 'disabled-prime-input' : null"></p-dropdown>
          <div *ngIf="strActiveTab === 'Cobrança'" class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Conta para cash out (retirada de dinheiro)!
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>E-mail destinatário <span class="obrigatorio" style="line-height: 0;">*</span></label>
          <input type="text" class="form-control" [(ngModel)]="settingsLevy.email" [disabled]="strActiveTab === 'Resumo'"/>
          <div *ngIf="strActiveTab === 'Cobrança'" class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> E-mail para aviso de pagamento da fatura
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Fornecedor</label>
          <div class="participant-container">
            <p> {{ providerCode ? providerCode + ' - ' + providerFantasyName : 'NÃO INFORMADO' }} </p>
            <span class="material-icons" (click)="loadParticipant(2)" matTooltip="Alterar Fornecedor">edit</span>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Operação</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" styleClass="panel-form-levy" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optOperations" emptyMessage="Nenhum resultado encontrado!" [disabled]="strActiveTab === 'Resumo' || loadingOptOperation" [dropdownIcon]="loadingIconOptOperation ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="settingsLevy.operacao_regra_contabil_id" [styleClass]="(strActiveTab === 'Resumo') ? 'disabled-prime-input' : null"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Tipo juros</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optTypeLevyFees" [(ngModel)]="settingsLevy.tipo_juros_id" emptyMessage="Nenhum resultado" [disabled]="strActiveTab === 'Resumo' || optTypeLevyLoading" [dropdownIcon]="optIconTypeLevyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeTypeLevy('tipo_juros_id', 'valor_juros')" [styleClass]="(strActiveTab === 'Resumo') ? 'disabled-prime-input' : null"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <label>
            Valor de juros
            <span *ngIf="settingsLevy.tipo_juros_id !== null" style="line-height: 0;">{{isPercentualTypeLevyFees ? '(%)' : '(R$)'}} </span>
            <span *ngIf="settingsLevy.tipo_juros_id !== null" class="obrigatorio" style="line-height: 0;">*</span>
          </label>
          <input class="form-control" type="number" [(ngModel)]="settingsLevy.valor_juros" [disabled]="strActiveTab === 'Resumo' || settingsLevy.tipo_juros_id === null"/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Tipo multa</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optTypeLevyFine" [(ngModel)]="settingsLevy.tipo_multa_id" emptyMessage="Nenhum resultado" [disabled]="strActiveTab === 'Resumo' || optTypeLevyLoading" [dropdownIcon]="optIconTypeLevyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeTypeLevy('tipo_multa_id', 'valor_multa')" [styleClass]="(strActiveTab === 'Resumo') ? 'disabled-prime-input' : null"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <label>
            Valor de multa
            <span *ngIf="settingsLevy.tipo_multa_id !== null" style="line-height: 0;">{{isPercentualTypeLevyFine ? '(%)' : '(R$)'}} </span>
            <span *ngIf="settingsLevy.tipo_multa_id !== null" class="obrigatorio" style="line-height: 0;">*</span>
          </label>
          <input class="form-control" type="number" [(ngModel)]="settingsLevy.valor_multa" [disabled]="strActiveTab === 'Resumo' || settingsLevy.tipo_multa_id === null"/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Tipo desconto</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optTypeLevyDiscount" [(ngModel)]="settingsLevy.tipo_desconto_id" emptyMessage="Nenhum resultado" [disabled]="strActiveTab === 'Resumo' || optTypeLevyLoading" [dropdownIcon]="optIconTypeLevyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeTypeLevy('tipo_desconto_id', 'valor_desconto')" [styleClass]="(strActiveTab === 'Resumo') ? 'disabled-prime-input' : null"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <label>
            Valor de desconto
            <span *ngIf="settingsLevy.tipo_desconto_id !== null" style="line-height: 0;">{{isPercentualTypeLevyDiscount ? '(%)' : '(R$)'}} </span>
            <span *ngIf="settingsLevy.tipo_desconto_id !== null" class="obrigatorio" style="line-height: 0;">*</span>
          </label>
          <input class="form-control" type="number" [(ngModel)]="settingsLevy.valor_desconto" [disabled]="strActiveTab === 'Resumo' || settingsLevy.tipo_desconto_id === null"/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Tipo abatimento</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optTypeLevyReduction" [(ngModel)]="settingsLevy.tipo_abatimento_id" emptyMessage="Nenhum resultado" [disabled]="strActiveTab === 'Resumo' || optTypeLevyLoading" [dropdownIcon]="optIconTypeLevyLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeTypeLevy('tipo_abatimento_id', 'valor_abatimento')" [styleClass]="(strActiveTab === 'Resumo') ? 'disabled-prime-input' : null"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <label>Valor de abatimento
            <span *ngIf="settingsLevy.tipo_abatimento_id !== null" style="line-height: 0;">{{isPercentualTypeLevyReduction ? '(%)' : '(R$)'}} </span>
            <span *ngIf="settingsLevy.tipo_abatimento_id !== null" class="obrigatorio" style="line-height: 0;">*</span>
          </label>
          <input class="form-control" type="number" [(ngModel)]="settingsLevy.valor_abatimento" [disabled]="strActiveTab === 'Resumo' || settingsLevy.tipo_abatimento_id === null"/>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="strActiveTab === 'Resumo'" class="w-100 mt-4">
    <div class="d-flex align-items-center">
      <p-checkbox [(ngModel)]="isAcceptedTerms" (onChange)="emitValidatedSteps()" [binary]="true" inputId="terms" class="mr-2"></p-checkbox>
      <label for="terms">Li e concordo com os <a class="external-link" href="https://aws-website-downloads-e72og.s3.amazonaws.com/TERMOS_DE_USO/Termos+de+Uso+da+Plataforma.pdf" target="_blank">Termos e Condições</a>
      </label>
    </div>
    <div *ngIf="isLocationDisabled" class="w-100 mt-2">
      <div class="alert alert-xs alert-primary d-inline alert-error" role="alert">
        <i class="fas fa-info-circle"></i> A localização está desativada!
      </div>
    </div>
  </div>
  <div *ngIf="strActiveTab === 'Conta' && !isLoadingAccountMatera" class="w-100 mb-4">
    <div class="w-100">
      <h6>Geral</h6>
      <div class="row status-content">
        <div class="col-sm-12">
          <p><b>Estado: </b>{{accountState.status}}</p>
          <p *ngIf="(accountState.status === 'ATIVA')"><b>ID: </b>{{accountState.accountId}}</p>
          <p *ngIf="(accountState.status === 'ATIVA')"><b>Número: </b>{{accountState.accountNumber}} <b>Branch: </b>{{accountState.branch}}</p>

          <div *ngIf="(accountState.status === 'ATIVA')" class="alert alert-xs alert-primary d-inline alert-success" role="alert">
            <i class="fas fa-check-circle"></i> Conta ativa!
          </div>
          <div *ngIf="(accountState.status === 'REJEITADA')" class="alert alert-xs alert-primary d-inline alert-error" role="alert">
            <i class="fas fa-info-circle"></i> A criação da sua conta foi rejeitada! Entre em contato com nosso suporte.
          </div>
          <div *ngIf="isSupportContact" class="alert alert-xs alert-primary d-inline alert-error" role="alert">
            <i class="fas fa-info-circle"></i> Algo errado aconteceu com sua conta! Entre em contato com nosso suporte.
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isSupportContact && !(accountState.status === 'REJEITADA')" class="w-100">
      <h6>Chave PIX</h6>
      <div class="row status-content">
        <div class="col-sm-12" *ngIf="(accountState.status === 'ATIVA')">
          <p *ngIf="accountState.keyStatus === 'ACTIVE'"><b>{{accountState.keyType}}: </b>{{accountState.key}}</p>
          <div *ngIf="accountState.keyStatus === 'ACTIVE'" class="alert alert-xs alert-primary d-inline alert-success" role="alert">
            <i class="fas fa-check-circle"></i> Chave ativa!
          </div>
          <div *ngIf="accountState.keyStatus !== 'ACTIVE'" class="alert alert-xs alert-primary d-inline alert-error" role="alert">
            <i class="fas fa-info-circle"></i> Chave desativada!
          </div>
        </div>
        <div class="col-sm-12" *ngIf="!(accountState.status === 'ATIVA')">
          <div class="alert alert-xs alert-primary d-inline alert-error" role="alert">
            <i class="fas fa-info-circle"></i> A conta precisa estar ativa!
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isSupportContact && (accountState.status === 'ATIVA')" class="w-100">
      <h6>Cash out (retirada do dinheiro)</h6>
      <div class="row status-content">
        <div class="col-sm-12">
          <div class="alert alert-xs alert-primary d-inline alert-success" role="alert">
            <i class="fas fa-check-circle"></i> O cash out acontece diariamente às 23:50 horas (horário de Brasília)!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="strActiveTab === 'Conta' && isLoadingAccountMatera" class="w-100 my-4">
    <div class="row status-content">
      <div class="col-sm-12">
        <p><span class="material-icons rotate">hourglass_empty</span> <b>Carregando informações da conta ...</b></p>
      </div>
    </div>
  </div>
  <div [class]="'w-100 mt-3 d-flex align-items-center ' + ((activeTab > 0) ? 'justify-content-between' : 'justify-content-end')">
    <button *ngIf="activeTab > 0 && (_isConfigExists ? (accountState.status === 'ATIVA' && !isLoadingAccountMatera) : true)" class="btnCustom-default" (click)="backStep()" matTooltip="Voltar">
      <span class="material-icons">west</span>
      <div>Voltar</div>
    </button>
    <button *ngIf="!(strActiveTab === 'Resumo' || strActiveTab === 'Conta')" class="btnCustom-green" (click)="nextStep()" matTooltip="Próximo">
      <div style="margin: 0 0.625rem 0;">Próximo</div>
      <span class="material-icons">east</span>
    </button>
  </div>
</div>

<app-modal-participante [(isVisible)]="modalParticipant" (onSelectParticipant)="saveProvider($event)"
  [participanteTipoId]="participantTypeId">
</app-modal-participante>