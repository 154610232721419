<app-data-table class="produtos-e-servicos"
                mainTitle="Cadastro de Produtos e Serviços"
                singleTitle="PRODUTOS E SERVIÇOS CADASTRADOS PELA EMRPESA SELECIONADA"
                routerPath="/item"
                entityPath="/item"
                [entity]="item"
                entityFilter="empresa/id {{ empresa ? 'eq ' + empresa.id : 'is null' }} and changeUser/id ne 1"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>

