<mat-card class="card-linnks margin-linnks">
  <mat-card-title class="clearfix mb-0">
    {{title}}
    <!-- <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle> -->
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-8 col-xxl-6">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Cadastro de Segmento</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateSeg>
            <p-table class="table-system" #dt [value]="segmentoLista" [rows]="rows" [paginator]="false" [pageLinks]="5"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
              (onFilter)="onFilter($event)" [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''"
              [scrollable]="segmentoLista.length >= 11 ? true : false" scrollHeight="250px">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                      <span class="text-overflow-dynamic-ellipsis">ÚLTIMA EDIÇÃO <p-sortIcon [field]="'data'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'data', 'contains')">
                  </th>
                  <th class="text-center" style="width: 68%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'segmento'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'segmento'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'segmento', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.data | date:'dd/MM/yyyy'}}">{{rowData.data | date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 68%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.segmento}}">{{rowData.segmento}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVO SEGMENTO -->
<p-dialog header="Adicionar novo segmento" [(visible)]="modalNovo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do segmento" [(ngModel)]="newCodigo" required>
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição segmento</label>
      <input class="form-control text-left" placeholder="Descrição do segmento" [(ngModel)]="newDescricao" required>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNovo=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addSegmento();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>

<!-- MODAL EDIT SEGMENTO -->
<p-dialog header="Editar segmento" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do segmento" [(ngModel)]="editCodigo" required>
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição segmento</label>
      <input class="form-control text-left" placeholder="Descrição do segmento" [(ngModel)]="editDescricao" required>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-danger" (click)='deleteSegmento();'><i class="fas fa-times"></i>
          Excluir</button>
        <button type="button" class="btn btn-primary" (click)="editSegmento();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>