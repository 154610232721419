<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>

    <div class="box-content">
      <p-table class="table-system" [value]="relatorios" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" (onRowSelect)="onRowSelect($event)">

        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Data </span>
              </span>
            </th>
            <th class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Relatório </span>
              </span>
            </th>
            <th class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Empresa </span>
              </span>
            </th>
            <th class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Destinatarios </span>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtEnvio}}">{{rowData.dtEnvio}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.relatorio}}">{{rowData.relatorio}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.empresa}}">{{rowData.empresa}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.destinatarios}}">{{rowData.destinatarios}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>

  </mat-card-content>
</mat-card>

<p-dialog header="Editar" [(visible)]="exbirModalObrigacao" [style]="{width: '800px'}" [responsive]="true"
  [modal]="true" showEffect="fade" styleClass="modal-scroll custom" appendTo="body" [closable]="true">
  <div *ngIf="obrigacaoEditar.id">
    <mat-tab-group (selectedTabChange)="onTabChanged($event)" [selectedIndex]="selectedIndex">
      <mat-tab label="Editar Único">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Data</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
          </div>
          <div class="form-group col">
            <label>E-mail</label>
            <input class="form-control" [(ngModel)]="obrigacaoEditar.destinatarios"
              placeholder="Insira os destinatarios, separe cada e-mail com ;" />
          </div>
        </div>
      </mat-tab>

      <!--Editar Recorrencia New-->
      <mat-tab label="Editar Recorrencia">
        <div class="form-row mt-4">
          <div class="form-group col-sm-12">
            <!-- <p-selectButton [options]="recorrenciasEditar" [(ngModel)]="obrigacaoEditar.recorrencia"></p-selectButton> -->
            <mat-radio-group [(ngModel)]="obrigacaoEditar.recorrencia">
              <mat-radio-button class="mr-3" *ngFor="let rec of recorrenciasEditar" [value]="rec">
                {{rec.descricao}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div *ngIf="obrigacaoEditar.recorrencia.id === 2">
          <div class="form-row">
            <div class="form-group col-sm-2">
              <label>Repete a cada</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
              <label>(semanas)</label>
            </div>
            <div class="form-group ml-3 col">
              <label class="d-block" style="margin-top: 5px;">Repete</label>
              <p-checkbox class="mr-3" value=true label="D" [(ngModel)]="dom" [binary]="true">D</p-checkbox>
              <p-checkbox class="mr-3" value=true label="S" [(ngModel)]="seg" [binary]="true">S</p-checkbox>
              <p-checkbox class="mr-3" value=true label="T" [(ngModel)]="ter" [binary]="true">T</p-checkbox>
              <p-checkbox class="mr-3" value=true label="Q" [(ngModel)]="qua" [binary]="true">Q</p-checkbox>
              <p-checkbox class="mr-3" value=true label="Q" [(ngModel)]="qui" [binary]="true">Q</p-checkbox>
              <p-checkbox class="mr-3" value=true label="S" [(ngModel)]="sex" [binary]="true">S</p-checkbox>
              <p-checkbox class="mr-3" value=true label="S" [(ngModel)]="sab" [binary]="true">S</p-checkbox>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>Início Em</label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body">
              </p-calendar>
            </div>
            <div class="form-group col-sm-3">
              <label>Termina Em</label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-3">
              <label>Termina Após</label>
              <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
              <label>ocorrências</label>
            </div>
          </div>
        </div>

        <div *ngIf="obrigacaoEditar.recorrencia.id === 3">
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>Dia do mês</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [options]="dias" [(ngModel)]="diaMes"></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>Repete a cada meses</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>Início Em</label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-3">
              <label>Termina Em</label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>Termina Após</label>
              <input type="text" class="form-control" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
              <label>ocorrências</label>
            </div>
            <div class="form-group col">
              <label class="d-block" style="margin-top: 6px;">Período</label>
              <p-checkbox ng-value="true" [ngModel]="antecipaDiaNaoUtil" [binary]="true">Antecipa Dia Não Útil</p-checkbox>
              <p-checkbox class="ml-3" value=true [(ngModel)]="diaUtil" [binary]="true" [disabled]="diaMes > 20">Dia útil
              </p-checkbox>
            </div>
          </div>
        </div>

        <div *ngIf="obrigacaoEditar.recorrencia.id === 4">
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>Repete a cada</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
              <label>anos</label>
            </div>
            <div class="form-group col-sm-3">
              <label>Início Em</label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-3">
              <label>Termina Em</label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-3">
              <label>Termina Após</label>
              <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
              <label>ocorrências</label>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <p-checkbox ng-value="true" [ngModel]="antecipaDiaNaoUtil" [binary]="true">Antecipa Dia Não Útil</p-checkbox>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label>E-mail</label>
            <input class="form-control" [(ngModel)]="obrigacaoEditar.destinatarios"
              placeholder="Insira os destinatarios, separe cada e-mail com ;" />
          </div>
        </div>
      </mat-tab>

      <!-- Excluir New-->
      <mat-tab label="Excluir">
        <div class="row mt-2">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-trash-alt"></i> Exclusão de agendados</h3>
          </div>
        </div>
        <div class="form-row mt-1">
          <div class="form-group col-sm-12">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button class="mr-3" (click)="deletarObrigacao(obrigacaoEditar.id)" [value]="1">
                Somente este agendamento será excluída
              </mat-radio-button>
              <mat-radio-button class="mr-3" (click)="deletarObrigacoes(obrigacaoEditar.id)" [value]="2">
                Este agendamento e os agendamentos seguintes serão excluídos
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </mat-tab>

    </mat-tab-group>

  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" *ngIf="isCancelarDisabled" (click)='exbirModalObrigacao=false'><i
        class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" *ngIf="isUnicoDisabled" (click)="atualizarObrigacao()"><i
        class="fas fa-check"></i> Editar</button>
    <button type="button" class="btn btn-primary" *ngIf="isRecorrenciaDisabled" (click)="atualizarRecorrencia()"><i
        class="fas fa-check"></i> Editar</button>
  </p-footer>
</p-dialog>