<mat-card class="card-linnks margin-linnks">
  <mat-card-title class="mb-3">
    <span *ngIf="statusTela1">Calendário Financeiro</span>
  </mat-card-title>

  <div class="shadow-box" [ngClass]="fullCalendar ? 'box-fullscreen' : ''">
    <div class="header-box">
      <div class="header-box-content">
        <h3 class="title-card">Agenda</h3>
        <div class="actions-card">


          <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
            (click)="fullScreenCalendar()">
            <span *ngIf="!fullCalendar" class="material-icons full-icon">fullscreen</span>
            <span *ngIf="fullCalendar" class="material-icons full-icon">fullscreen_exit</span>
          </button>
          <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardCalendar()" (click)="changeState()">
            <span *ngIf="isCardCalendar" class="material-icons" matTooltipPosition="above">expand_more</span>
            <span *ngIf="!isCardCalendar" class="material-icons" matTooltipPosition="above">expand_less</span>
          </button>
        </div>
      </div>
    </div>
    <app-calendar></app-calendar>
  </div>
</mat-card>
