<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-8 col-xxl-6">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">Informações de Câmbio</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateCambio>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title mt-0"><i class="fas fa-sliders-h"> </i> PARÂMETROS DE CONSULTA</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-1" style="min-width: 130px;">
                <label>DATA INICIAL</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  [defaultDate]="DefInitDate" yearRange="2000:2030"  [(ngModel)]="dtInicial">
                </p-calendar>
              </div>
              <div class="form-group col-sm-1" style="min-width: 130px;">
                <label>DATA FINAL</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  [defaultDate]="DefFinalDate" yearRange="2000:2030"  [(ngModel)]="dtFinal">
                </p-calendar>
              </div>
              <div class="form-group col-sm-2">
                <label>MOEDA</label>
                <p-dropdown class="type-p-dropdown" class="type-p-dropdown" [(ngModel)]="idMoeda" [options]="listMoeda"
                  [filter]="false" placeholder="Selecione uma moeda"></p-dropdown>
              </div>
              <div class="form-group col-sm-2">
                <label class="d-block" style="visibility: hidden; margin-top: 3px;">DO NOT REMOVE</label>
                <button type="button" class="btn btn-info" (click)='getCambio();'><i class="fas fa-refresh"></i>
                  Atualizar</button>
              </div>
            </div>

            <hr style="margin-top: 5px; border-color: #ccc;">

            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt2 [columns]="colsCambio" [value]="cambioLista" [rows]="15"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_cotacao'">
                      <span class="text-overflow-dynamic-ellipsis">DATA COTAÇÃO <p-sortIcon [field]="'dt_cotacao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'dt_cotacao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO DA MOEDA <p-sortIcon [field]="'codigo'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-right" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cotacao_compra_dia'">
                      <span class="text-overflow-dynamic-ellipsis">COTAÇÃO COMPRA <p-sortIcon
                          [field]="'cotacao_compra_dia'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'cotacao_compra_dia', 'contains')">
                  </th>
                  <th class="text-right" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cotacao_venda_dia'">
                      <span class="text-overflow-dynamic-ellipsis">COTAÇÃO VENDA <p-sortIcon
                          [field]="'cotacao_venda_dia'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'cotacao_venda_dia', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dt_cotacao | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_cotacao |
                        date:'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cotacao_compra | number: '1.4-4':'pt-BR'}}">{{rowData.cotacao_compra |
                        number: '1.4-4':'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cotacao_venda | number: '1.4-4':'pt-BR'}}">{{rowData.cotacao_venda |
                        number: '1.4-4':'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>