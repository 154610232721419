<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="far fa-edit"
                  style="font-size: 18px; vertical-align: middle; margin-top: -3px;"></i>Livro Fiscal</h3>
              <div class="actions-card">


                <button  type="button"
                  class="btn btn-xs btn-link" (click)="modalEdicao();">
                  <span class="material-icons full-icon" style="margin-right: -4px;" matTooltip="Ajustes"
                    matTooltipPosition="above">mode_edit</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content">
            <div class="row">
              <div class="col-sm-12">
        <p-table class="table-system" #dt1 [columns]="colsicmsIpisDetalhe" [value]="icmsIpi" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" [scrollable]="true"
        selectionMode="multiple" [(selection)]="pedidosSelecionados" (onHeaderCheckboxToggle)="selectAll()"  (onRowSelect)="onRowSelect($event)">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                  <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'operacao', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                  <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cnpj', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
                  <span class="text-overflow-dynamic-ellipsis">RAZÃO <p-sortIcon [field]="'razao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'razao', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numPedido'">
                  <span class="text-overflow-dynamic-ellipsis">NUM PEDIDO <p-sortIcon [field]="'numPedido'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'numPedido', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numDoc'">
                  <span class="text-overflow-dynamic-ellipsis">NUM DOC <p-sortIcon [field]="'numDoc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'numDoc', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codMod'">
                  <span class="text-overflow-dynamic-ellipsis">COD_MOD <p-sortIcon [field]="'codMod'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codMod', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codPart'">
                  <span class="text-overflow-dynamic-ellipsis">COD PART <p-sortIcon [field]="'codPart'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codPart', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
                  <span class="text-overflow-dynamic-ellipsis">NOME PART <p-sortIcon [field]="'razao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'razao', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codItem'">
                  <span class="text-overflow-dynamic-ellipsis">COD ITEM <p-sortIcon [field]="'codItem'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codItem', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtDoc'">
                  <span class="text-overflow-dynamic-ellipsis">DT DOC <p-sortIcon [field]="'dtDoc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'dtDoc', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtES'">
                  <span class="text-overflow-dynamic-ellipsis">DT E/S <p-sortIcon [field]="'dtES'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'dtES', 'contains')">
              </th>
              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codOper'">
                  <span class="text-overflow-dynamic-ellipsis">COD OPER <p-sortIcon [field]="'codOper'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codOper', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
                  <span class="text-overflow-dynamic-ellipsis">CFOP<p-sortIcon [field]="'cfop'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cfop', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                  <span class="text-overflow-dynamic-ellipsis">QTDE<p-sortIcon [field]="'qtde'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'qtde', 'contains')">
              </th>

              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlUnit'">
                  <span class="text-overflow-dynamic-ellipsis">VL UNIT<p-sortIcon [field]="'vlUnit'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlUnit', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlDesc'">
                  <span class="text-overflow-dynamic-ellipsis">VL DESC<p-sortIcon [field]="'vlDesc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlDesc', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlProd'">
                  <span class="text-overflow-dynamic-ellipsis">VL PROD<p-sortIcon [field]="'vlProd'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlProd', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlFrete'">
                  <span class="text-overflow-dynamic-ellipsis">VL FRETE<p-sortIcon [field]="'vlFrete'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlFrete', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlSeguro'">
                  <span class="text-overflow-dynamic-ellipsis">VL SEGURO<p-sortIcon [field]="'vlSeguro'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlSeguro', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstIcms'">
                  <span class="text-overflow-dynamic-ellipsis">CST ICMS<p-sortIcon [field]="'cstIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cstIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VL BC ICMS<p-sortIcon [field]="'vlBcIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIcms'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ ICMS<p-sortIcon [field]="'aliqIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqIcms', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VL ICMS<p-sortIcon [field]="'vlIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIcms', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIsentas'">
                  <span class="text-overflow-dynamic-ellipsis">VL ISENTAS<p-sortIcon [field]="'vlIsentas'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIsentas', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlOutras'">
                  <span class="text-overflow-dynamic-ellipsis">VL OUTRAS<p-sortIcon [field]="'vlOutras'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlOutras', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstIpi'">
                  <span class="text-overflow-dynamic-ellipsis">CST IPI<p-sortIcon [field]="'cstIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cstIpi', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIpi'">
                  <span class="text-overflow-dynamic-ellipsis">VL BC IPI<p-sortIcon [field]="'vlBcIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcIpi', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIpi'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ IPI<p-sortIcon [field]="'aliqIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqIpi', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIpi'">
                  <span class="text-overflow-dynamic-ellipsis">VL IPI<p-sortIcon [field]="'vlIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIpi', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VL BC ICMS ST<p-sortIcon [field]="'vlBcIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcIcmsSt', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ ICMS ST<p-sortIcon [field]="'aliqIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqIcmsSt', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VL ICMS ST<p-sortIcon [field]="'vlIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIcmsSt', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstPis'">
                  <span class="text-overflow-dynamic-ellipsis">CST PIS<p-sortIcon [field]="'cstPis'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cstPis', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcPis'">
                  <span class="text-overflow-dynamic-ellipsis">VL BC PIS<p-sortIcon [field]="'vlBcPis'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcPis', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqPis'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ PIS<p-sortIcon [field]="'aliqPis'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqPis', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlPis'">
                  <span class="text-overflow-dynamic-ellipsis">VL PIS<p-sortIcon [field]="'vlPis'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlPis', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstCofins'">
                  <span class="text-overflow-dynamic-ellipsis">CST COFINS<p-sortIcon [field]="'cstCofins'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cstCofins', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcCofins'">
                  <span class="text-overflow-dynamic-ellipsis">VL BC COFINS<p-sortIcon [field]="'vlBcCofins'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcCofins', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqCofins'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ COFINS<p-sortIcon [field]="'aliqCofins'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqCofins', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlCofins'">
                  <span class="text-overflow-dynamic-ellipsis">VL COFINS<p-sortIcon [field]="'vlCofins'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlCofins', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlTot'">
                  <span class="text-overflow-dynamic-ellipsis">VL TOT<p-sortIcon [field]="'vlTot'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlTot', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'chvNfe'">
                  <span class="text-overflow-dynamic-ellipsis">CHV NFE<p-sortIcon [field]="'chvNfe'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'chvNfe', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'chvNfe'">
                  <span class="text-overflow-dynamic-ellipsis">COD REND<p-sortIcon [field]="'codRend'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codRend', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center tableCheckbox">
                <p-tableCheckbox [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.operacao}}" >{{rowData.operacao }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj}}" >{{rowData.cnpj }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao}}" >{{rowData.razao }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numPedido}}" >{{rowData.numPedido }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numDoc}}" >{{rowData.numDoc }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codMod}}" >{{rowData.codMod }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codPart}}" >{{rowData.codPart }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao}}" >{{rowData.razao }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codItem}}" >{{rowData.codItem }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtDoc}}" >{{rowData.dtDoc }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtES}}" >{{rowData.dtES }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codOper}}" >{{rowData.codOper }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}" >{{rowData.cfop }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.qtde}}" >{{rowData.qtde }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlUnit | money}}" >{{rowData.vlUnit | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlDesc | money}}" >{{rowData.vlDesc | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlProd | money}}" >{{rowData.vlProd | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlFrete | money}}" >{{rowData.vlFrete | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlSeguro | money}}" >{{rowData.vlSeguro | money }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstIcms}}" >{{rowData.cstIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIcms | money}}" >{{rowData.vlBcIcms | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIcms | money}}" >{{rowData.aliqIcms | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIcms | money}}" >{{rowData.vlIcms | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIsentas | money}}" >{{rowData.vlIsentas | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlOutras | money}}" >{{rowData.vlOutras | money }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstIpi}}" >{{rowData.cstIpi }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIpi | money}}" >{{rowData.vlBcIpi | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIpi | money}}" >{{rowData.aliqIpi | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIpi | money}}" >{{rowData.vlIpi | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIcmsSt | money}}" >{{rowData.vlBcIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIcmsSt | money}}" >{{rowData.aliqIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIcmsSt | money}}" >{{rowData.vlIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstPis}}" >{{rowData.cstPis }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcPis | money}}" >{{rowData.vlBcPis | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqPis | money}}" >{{rowData.aliqPis | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlPis | money}}" >{{rowData.vlPis | money }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstCofins}}" >{{rowData.cstCofins }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcCofins | money}}" >{{rowData.vlBcCofins | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqCofins | money}}" >{{rowData.aliqCofins | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlCofins | money}}" >{{rowData.vlCofins | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlTot | money}}" >{{rowData.vlTot | money }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.chvNfe}}" >{{rowData.chvNfe }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codRend}}" >{{rowData.codRend }}</span>
                </span>
              </td>



            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
                <td [attr.colspan]="26" class="spaced-alert" style="width: 3021px">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 8px;">
            <div class="alert alertcustom" [ngClass]="'alert-success'" role="alert" style="cursor: text;">
              <strong>{{ pedidosSelecionados.length}}</strong> Itens Selecionados.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
</div>
</div>

</mat-card-content>
</mat-card>


<p-dialog header="EDITAR ESCRITURACAO" [(visible)]="exibirAjuste" styleClass="modal-scroll"  [responsive]="true" appendTo="body" [closable]="true">
  <div style="width: 900px;">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>CAMPO</label>
      <p-dropdown class="type-p-dropdown" [filter]="false" [options]="optionsAjustes"
        placeholder="Selecione o Status" [(ngModel)]="campoEditar" appendTo="body"></p-dropdown>
    </div>
  </div>

  <br>

  <label>Editar campo para:</label>
   <input class="form-control text-right" type="text" pInputText [(ngModel)]="valorEditar" />

  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirAjuste = false"><i class="fas fa-undo"></i>
      Cancelar </button>
    <button type="button" class="btn btn-primary" (click)="salvarAlteracoes()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>

</p-dialog>

