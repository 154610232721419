<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
  </mat-card-title>
  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> RELATÓRIOS GERENCIAIS</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="card-deck card-custom">
        <div class="card" *ngFor="let relat of listaRelat">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio(relat)"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio(relat)" matTooltip="{{relat.nome}}"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="existeRelatorioExterno">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorioExt('EXT')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorioExt('EXT')" matTooltip="RELATÓRIOS EXTERNOS"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatórios Externos</span>
            </h5>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- MODAL -->
  <p-dialog header="{{relatorio.nome}}" [modal]="true" appendTo="body" [style]="{width: '400px'}"
    styleClass="modal-initial" [(visible)]="modalRelatorio" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="!relatorio.is_composta">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030"  [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>

    <div class="row" *ngIf="relatorio.is_composta">
      <div class="form-group col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>DATA REFERÊNCIA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="dataRef" yearRange="2000:2030"  [(ngModel)]="dataRef"
              [disabled]="datasIndividuais">
            </p-calendar>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <p-checkbox [binary]="true" [(ngModel)]="datasIndividuais">Utilizar datas individuais</p-checkbox>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="form-row">
          <p-table class="table-system" [value]="dicRelatComposto[relatorio.id]"
            emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                  Data de referência</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                  *ngIf="datasIndividuais"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-config>
              <tr>
                <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                <td style="text-align: center">{{config.worksheet_title}}</td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                    [yearNavigator]="true" [defaultDate]="dataRef" yearRange="2000:2030"
                    [(ngModel)]="config['dataRef']">
                  </p-calendar>
                </td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                      (click)="carregarValores(config)"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <p-footer>  
      <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
        *ngIf="!relatorio.is_composta"><i class="fas fa-laptop"></i> Tela</button>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal EXT-->
  <p-dialog header="RELATÓRIOS EXTERNOS" [modal]="true" appendTo="body" width="700" styleClass="modal-initial"
    [(visible)]="statusRelExt" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col-sm-5">
        <label class="d-block">Relatório</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="relExterno" [options]="opcoesRelatoriosExternos"
          defaultLabel="Selecione" [filter]="true" (onChange)="relatorioExterno($event)"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirFilial">
        <label class="d-block">Filial</label>
        <input class="form-control text-right" type="text" [(ngModel)]="filialRelatorio" />
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirDtIni">
        <label class="d-block">Data inicial</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtIni" yearRange="2000:2030"  [(ngModel)]="dtIni"></p-calendar>
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirDtFin">
        <label class="d-block">Data final</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtFin" yearRange="2000:2030"  [(ngModel)]="dtFin"></p-calendar>
      </div>
    </div>


    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioExterno()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>
<div *ngIf="isLoadingInProgress" class="loading-layer">
  <h6>Processamento em andamento, favor aguardar... {{loadingProgress}}%</h6>
  <p-progressBar [value]="loadingProgress" style="width: 40% !important"></p-progressBar>
</div>
