<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>




  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsListaFormularioMotivoCancelamento"
          [value]="listaFormularioMotivoCancelamento" [rows]="rows" [paginator]="true"
          [totalRecords]="listaFormularioMotivoCancelamento.length" (onFilter)="onFilter($event)"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>

              <th class="text-left" style="width: 100%">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                  <span class="text-overflow-dynamic-ellipsis">MOTIVOS DE CANCELAMENTO <p-sortIcon
                      [field]="'descricao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'descricao', 'contains')">
              </th>


            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id , rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container" style="width: 100%">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="3" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>

      <!--Inicio Modal Formulario-->
      <p-dialog header="MOTIVO DO CANCELAMENTO" [(visible)]="modalFormularioMotivoCancelamento"
        [style]="{width: '800px'}" [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body"
        [closable]="true">



        <!-- modalFormularioMotivoCancelamento -->
        <div *ngIf="modalFormularioMotivoCancelamento">


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>DESCRIÇÃO<span class="obrigatorio">*</span> </label>
              <input [(ngModel)]="formularioMotivoCancelamento.descricao" class="form-control text-left" type="text"
                maxlength="200" />
            </div>
          </div>


          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>


        </div>


        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalFormularioMotivoCancelamento=false;"><i
              class="fas fa-undo"></i> Cancelar</button>
          <button *ngIf="!editFormulario" type="button" class="btn btn-primary"
            (click)="salvarFormularioMotivoCancelamento();" [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i>
            Salvar</button>
          <button *ngIf="editFormulario" type="button" class="btn btn-primary"
            (click)="editarFormularioMotivoCancelamento();" [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i>
            Salvar</button>
        </p-footer>
      </p-dialog>
      <!--Final Modal Formulario-->

    </mat-card-content>
  </div>

</mat-card>