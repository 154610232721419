<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsStaffList" [value]="staffList" [paginator]="true" [rows]="rows"
        [totalRecords]="staffList.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
        selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-left" style="width: 25%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nome', 'contains')">
            </th>

            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
            </th>


            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                <span class="text-overflow-dynamic-ellipsis">Fone <p-sortIcon [field]="'fone'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'fone', 'contains')">
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">Email <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cidade'">
                <span class="text-overflow-dynamic-ellipsis">Cidade <p-sortIcon [field]="'cidade'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cidade', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                <span class="text-overflow-dynamic-ellipsis">UF <p-sortIcon [field]="'uf'"></p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboUf" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'uf', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'staffSeminario'">
                <span class="text-overflow-dynamic-ellipsis">Já Staff Seminário <p-sortIcon [field]="'staffSeminario'">
                  </p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboStaff" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'staffSeminario', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'staffPalestra'">
                <span class="text-overflow-dynamic-ellipsis">Já Staff Palestra <p-sortIcon [field]="'staffPalestra'">
                  </p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboStaff" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'staffPalestra', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dispVizinha'">
                <span class="text-overflow-dynamic-ellipsis">Disponível Viagem <p-sortIcon [field]="'dispVizinha'">
                  </p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboDispVizinha" defaultLabel="Selecione"
                [filter]="false" (onChange)="dt2.filter($event.value, 'dispVizinha', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'kmDistancia'">
                <span class="text-overflow-dynamic-ellipsis">KM Distancia <p-sortIcon [field]="'kmDistancia'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'kmDistancia', 'contains')">
            </th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>



            <td class="text-left" style="width: 25%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf}}">{{rowData.cpf }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fone}}  "> {{rowData.fone}} </span>
              </span>
            </td>
            <td class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.email}}  ">{{rowData.email}} </span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cidade}}   ">{{rowData.cidade}}
                </span>
              </span>
            </td>

            <td class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.uf}} "> {{rowData.uf}} </span>
              </span>
            </td>

            <td class="text-center" style="width: 10%;">
              <p-checkbox class="mr-1" [(ngModel)]="rowData.staffSeminario" [binary]="true"
              inputId="staffSeminario" [disabled]="true"></p-checkbox>
            </td>

            <td class="text-center" style="width: 10%;">
              <p-checkbox class="mr-1" [(ngModel)]="rowData.staffPalestraEn" [binary]="true"
                      inputId="staffPalestraEn" [disabled]="true"></p-checkbox>
            </td>

            <td class="text-center" style="width: 10%;">
              <p-checkbox class="mr-1" [(ngModel)]="rowData.dispVizinha" [binary]="true"
              inputId="dispVizinha" [disabled]="true"></p-checkbox>
            </td>

            <td class="text-right" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.kmDistancia}} ">
                  {{rowData.kmDistancia}} </span>
              </span>
            </td>


          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="10" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </mat-card-content>
</mat-card>