<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12"></div>
    <div class="ui-g-12 text-right" style="font-size: 13px;padding-right: 30px;">
      <span style="font-weight: bold;">SALDO INICIAL: </span>
      <span *ngIf="saldoInicial > 0 "> {{ (saldoInicial * (saldoInicial < 0 ? -1 : 1)) | money }}</span>
          <span *ngIf="saldoInicial < 0 " style="color:darkred;">
            {{ (saldoInicial * (saldoInicial < 0 ? -1 : 1)) | money }} {{ saldoInicial < 0 ? 'D' : 'C' }}</span>
    </div>
  </div>

  <mat-card-content>
    <div class="shadow-box">
      <!--CRIAÇÃO DO TOOLBAR PARA EXPORTAÇÃO-->
        <div class="header-box">
          <div class="header-box-content p-3" style="padding-bottom: 16px; margin-bottom: 16px;">
            <div class="actions-card">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

      <div class="box-content">
        <p-table class="table-system" #dt [value]="lctos" [rows]="20" [paginator]="true" selectionMode="multiple"
          [pageLinks]="5" [rowsPerPageOptions]="[5,10,15,20,50]" [style]="{'width':'100%'}" immutable="false"
          (onRowSelect)="onRowSelect($event)">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataLcto'">
                  <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'dataLcto'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'dataLcto', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                  <span class="text-overflow-dynamic-ellipsis">NÚM. LANÇAMENTO <p-sortIcon [field]="'numero'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'numero', 'contains')">
              </th>
              <th class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'lote'">
                  <span class="text-overflow-dynamic-ellipsis">LOTE <p-sortIcon [field]="'lote'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'lote', 'contains')">
              </th>
              <th class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                  <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'historico', 'contains')">
              </th>
              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cc'">
                  <span class="text-overflow-dynamic-ellipsis">CC <p-sortIcon [field]="'cc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'cc', 'contains')">
              </th>
              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cp'">
                  <span class="text-overflow-dynamic-ellipsis">PROJ <p-sortIcon [field]="'cp'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'cp', 'contains')">
              </th>
              <th class="text-right" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'debito'">
                  <span class="text-overflow-dynamic-ellipsis">DÉBITO <p-sortIcon [field]="'debito'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'debito', 'contains')">
              </th>
              <th class="text-right" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'debito'">
                  <span class="text-overflow-dynamic-ellipsis">CRÉDITO <p-sortIcon [field]="'debito'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'debito', 'contains')">
              </th>
              <th class="text-right" style="width: 9%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'credito'">
                  <span class="text-overflow-dynamic-ellipsis">SALDO AMD <p-sortIcon [field]="'credito'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'credito', 'contains')">
              </th>
              <th class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'sldFin'">
                  <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'sldFin'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'sldFin', 'contains')">
              </th>

              <!--CASO FOR USAR FUTURAMENTE-->
              <!-- <p-column header="PDF" [style]="{ 'width': '20px', 'text-align':'right' }">
                      <ng-template let-vl="rowData" pTemplate="body">
                          <button mat-icon-button class="btn-color" (click)="exportarLcto(vl)">
                              <mat-icon class="md-48">picture_as_pdf</mat-icon>
                            </button>

                      </ng-template>
                  </p-column> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 6%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{ rowData.dataLcto }}">
                    {{ rowData.dataLcto }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numero}}">{{rowData.numero }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.lote}}">{{rowData.lote }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.historico}}">{{rowData.historico
                    }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cc}}">{{rowData.cc }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cp}}">{{rowData.cp}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 6px;">
                <span class="text-overflow-dynamic-container" *ngIf="rowData.debito>0">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.debito | money  }}">{{rowData.debito
                    | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 6%;">
                <div class="text-overflow-dynamic-container" *ngIf="rowData.credito>0">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.credito | money  }}">{{rowData.credito | money }}</span>
                </div>
              </td>
              <td class="text-right" style="width: 9%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.saldo | money  }}">{{rowData.saldo |
                    money }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.indSaldo}}">{{rowData.indSaldo}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="10" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p-dialog [(visible)]="modal" appendTo="body" header="Visualizar Lançamento" [style]="{width: '800px'}" [modal]="true"
      class="modal-linnks" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
      [closable]="true">
      <div class="counteudo">
        <div class="tabela-data-table">
          <p-table class="table-system" #dt1 [value]="lctosPartida" [rows]="10" [paginator]="false"
            selectionMode="single" [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''"
            [scrollable]="lctosPartida.length >= 11 ? true : false" scrollHeight="250px" [pageLinks]="5"
            [rowsPerPageOptions]="[5,10,15,20,50]" [style]="{'width':'100%'}" immutable="false"
            (onFilter)="onFilterlctosPartida($event)">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-left" style="width:25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                    <span class="text-overflow-dynamic-ellipsis">CONTA CONTÁBIL <p-sortIcon [field]="'codCta'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'codCta', 'contains')">
                </th>
                <th class="text-center" style="width: 5%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'indDc'">
                    <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDc'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'indDc', 'contains')">
                </th>
                <th class="text-right" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                    <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'valor', 'contains')">
                </th>
                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'hist'">
                    <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'hist'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'hist', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-left" style="width:25%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">{{ rowData.codCta
                      }}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 5%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indDc}}">{{rowData.indDc
                      }}</span>
                  </span>
                </td>
                <td class="text-right" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.valor | money  }}">{{rowData.valor | money }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.hist}}">{{rowData.hist }}</span>
                  </span>
                </td>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="4" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="modal = false;"><i class="fas fa-undo"></i>
          Fechar
        </button>
      </p-footer>
    </p-dialog>

  </mat-card-content>
</mat-card>
