<script src="ng-pattern-restrict.js"></script>
<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <mat-card-content>
    <div class="box-content " *ngIf="showLista">
      <div class="row">
        <div class="col-sm-12">

          <p-table class="table-system" #dt10 [value]="listaTabelaCrm" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
            [style]="{ width: '100%' }">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-left" style="width: 25%;">
                  <span [pSortableColumn]="'descricao'">
                    <span>DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt10.filter($event.target.value, 'descricao', 'contains')">
                </th>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-left" style="width: 25%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.descricao }}</span>
                  </span>
                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="1" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>


        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- EDIT | NEW -->
  <!-- ---------------------------------------------------------------------------------------- -->



  <div class="row">
    <div class="col-sm-12">
      <div class="box-content" *ngIf="showEdit || showNew">

        <div class="row">
          <div class="form-group col-sm-12">
            <label>CONFIGURAÇÃO API <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione uma configuração de api"
              [options]="opcaoConfiguracaoApi" [(ngModel)]="configuracaoApiId"
              (onChange)="getListaPlataformas(configuracaoApiId)"></p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-12">
            <label>PLATAFORMA <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione uma plataforma"
              [options]="opcaoPlataforma" [(ngModel)]="plataformaId"
              (onChange)="getListaComboPlataformaServicos(plataformaId)"></p-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-12">
            <label>SERVIÇO DA PLATAFORMA <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione um serviço da plataforma"
              [options]="opcaoPlataformaServico" [(ngModel)]="plataformaServicoId"></p-dropdown>
          </div>
        </div>



        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>DESCRIÇÃO<span class="obrigatorio">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="descricao" required="true" maxlength="150" />
          </div>
        </div>



        <div class="form-row">
          <div class="form-group col-sm-12">
            <button class="btn btn-secondary" (click)="loadComponent('lista', null)"><i class="fas fa-undo"></i>
              Cancelar
            </button>
            <button *ngIf="showEdit" class="btn btn-danger" (click)="deletarTabelaCrm()"><i class="fas fa-times"></i>
              Excluir
            </button>
            <button class="btn btn-primary" (click)="salvar(true)"><i class="fas fa-check"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>