<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content" style="padding: 1rem !important; margin-top: -25px;">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta">

            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registrosAuthUser" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="selectCheckbox($event)"
              (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left" style="width: 200px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'displayName'">
                      <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                        Nome <p-sortIcon [field]="'displayName'"></p-sortIcon>
                      </span>
                      <br>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($any($event.target).value, 'displayName', 'contains')">
                    </span>
                    </th>
  
                    <th class="text-left">
                      <br>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'fullName'">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                          Nome Completo <p-sortIcon [field]="'fullName'"></p-sortIcon>
                        </span>
                      </span>
                      <br>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($any($event.target).value, 'fullName', 'contains')">
                    </th>

                    <th class="text-left">
                      <br>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'mail'">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                          E-mail <p-sortIcon [field]="'mail'"></p-sortIcon>
                        </span>
                      </span>
                      <br>
                      <input class="form-control form-control-sm" type="text"  autocomplete="false"
                        (input)="dt.filter($any($event.target).value, 'mail', 'contains')">
                    </th>

                    <th class="text-left" style="width: 200px;">
                      <br>
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'active'">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                          Ativo <p-sortIcon [field]="'active'"></p-sortIcon>
                        </span>
                      </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesAtivo"
                      (onChange)="dt.filter($event.value, 'active', 'equals')"></p-dropdown>
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" [id]="'checkboxContainer'+rowData.id">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-left" style="width: 50px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cod}}">{{rowData.displayName}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.fullName}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.mail}}</span>
                    </span>
                  </td>

                  <td class="text-center" style="width: 10%;">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.active" [binary]="true" inputId="allowPut"
                      [disabled]="true"></p-checkbox>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Nome<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" minlength="3" pInputText [(ngModel)]="name">
              <p style="text-align: right; font-size: 9px;">NOME EXCLUSIVO PARA O GRUPO</p>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Nome Completo</label>
              <div>
                <input class="form-control" id="input" type="text" minlength="3" pInputText [(ngModel)]="nomeCompleto">
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>E-mail</label>
              <div>
                <input class="form-control" id="input" type="text" minlength="3" pInputText [(ngModel)]="email">
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Password</label>
              <div>
                <input class="form-control" id="input" type="password" minlength="3" pInputText [(ngModel)]="password">
              </div>
            </div>
          </div>

            <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="active">Ativo</mat-slide-toggle>
                </div>
            </div> 

            <div class="app-multi-select">
                <label class="select-label">Grupos</label>
                <br>
                <p-checkbox
                  [(ngModel)]="selectedGruposAll"
                  [ngModelOptions]="{standalone: true}"
                  class="select-all"
                  (click)="selectGruposAll()"
                  >
                </p-checkbox>

            <div class="clear"></div>

            <div class="clear multi-select-container">
              <div *ngFor="let grupo of grupos">
                <p-checkbox value={{grupo.id}} [(ngModel)]="selectedGrupos" [ngModelOptions]="{standalone: true}">
                </p-checkbox>
                <label for="{{grupo.name}}"> {{grupo.name}}</label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div></div>

  </mat-card-content>
</mat-card>