<app-data-table class="cad-aglut"
            mainTitle="Cadastro Aglutinação"
            singleTitle="Código de Aglutinação"
            routerPath="/cad-aglut"
            entityPath="/cadaglut"
            keyField="id"
            [entity]="cadAglut"
            [columns]="this['columns']"
            [controls]="this['controls']">
</app-data-table>
