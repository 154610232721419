<div class="box-content d-flex">

  <div class="col-sm-8 calendarContainer">

    <div class="calendarHeader">
      <div class="btn btn-outline-secondary" mwlCalendarPreviousView [view]="viewMonth" [(viewDate)]="viewDate">
        Anterior
      </div>
      <h3>
        {{ viewDate | calendarDate:(viewMonth + 'ViewTitle'):'pt' }}
      </h3>
      <div class="btn btn-outline-secondary" mwlCalendarNextView [view]="viewMonth" [(viewDate)]="viewDate">
        Próximo
      </div>
    </div>
    <div class="col-sm-12">
      <mwl-calendar-month-view [locale]="'pt'" [viewDate]="viewDate" [events]="eventList" [refresh]="refresh"
        [activeDayIsOpen]="false" (dayClicked)="onEventClick($event.day)">
      </mwl-calendar-month-view>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <span>
        <i class="material-icons" style="font-size: 10px; color: #488aff; padding: 0 10px;">brightness_1</i>
        Recebimento em aberto
      </span>
      <span>
        <i class="material-icons" style="font-size: 10px; color: #ff2222; padding: 0 10px;">brightness_1</i>
        Pagamento em aberto
      </span>
      <span>
        <i class="material-icons" style="font-size: 10px; color: rgb(72, 138, 153); padding: 0 10px;">brightness_1</i>
        Recebimento Baixado
      </span>
      <span>
        <i class="material-icons" style="font-size: 10px; color: #992222; padding: 0 10px;">brightness_1</i>Pagamento
        Baixado
      </span>
    </div>
  </div>

  <div *ngIf="showCalendar" class="col-sm-4 infoContainer">

    <h3>
      {{currentDate}} , {{currentDay | date: 'd'}}
    </h3>

    <div class="d-flex flex-column" style="height: 100%;">

      <p-panel *ngIf="currentInformation.length>0" header="Recebimento" [toggleable]="true">
        <div class="sideBox" *ngFor="let event of currentInformation">
          <i class="mark"
            [ngStyle]="event.status === 'EM ABERTO' ? {'background-color': '#488aff'} : {'background-color': 'rgb(72, 138, 153)'}">
          </i>
          <label class="description">
            {{event.title}}
          </label>
          <div class="d-flex" *ngIf="event.status === 'EM ABERTO'">
            <button (click)="openEdit(event)">
              <i class="material-icons">edit</i>
            </button>
            <button (click)="openBaixarEvento(event)">
              <i class="material-icons">flash_on</i>
            </button>
          </div>
        </div>
      </p-panel>

      <p-panel *ngIf="currentInformationPagamento.length>0" header="Pagamento" [toggleable]="true">
        <div class="sideBox" *ngFor="let event of currentInformationPagamento">
          <i class="mark"
            [ngStyle]="event.status === 'EM ABERTO' ? {'background-color': '#ff2222'} : {'background-color': '#992222'}">
          </i>
          <label class="description">
            {{event.title}}
          </label>
          <div class="d-flex" *ngIf="event.status === 'EM ABERTO'">
            <button (click)="openEdit(event)">
              <i class="material-icons">edit</i>
            </button>
            <button (click)="openBaixarEvento(event)">
              <i class="material-icons">flash_on</i>
            </button>
          </div>
        </div>
      </p-panel>

    </div>

    <table class="detalhes mt-1">
      <tr>
        <td>Recebimento</td>
        <td>Pagamento</td>
        <td>Movimentação</td>
      </tr>
      <tr>
        <td [style.color]="'#488aff'">{{formatRecebimento | number : '1.2-2' : 'pt-BR'}}</td>
        <td [style.color]="'#ff2222'">{{formatPagamento | number : '1.2-2' : 'pt-BR'}}</td>
        <td [style.color]="totalMovimentacao > 0 ? '#488aff': '#ff2222'">
          {{movimentacaoTotal | number :'1.2-2' : 'pt-BR' }}</td>
      </tr>
    </table>

    <!-- <div class="detalhes">
      <div>
        <span>

        </span>
        <span>

        </span>
        <span>

        </span>
      </div>
      <div>
        <span style=" color: #488aff;">

        </span>
        <span style="color: #ff2222; ">

        </span>
        <span >

        </span>
      </div>
    </div> -->

  </div>

</div>

<!-- Modal Editar -->
<p-dialog header="Editar Evento" [(visible)]="modalEdit" [style]="{width: '500px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Alterar</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="false" [options]="opcaoDataBanco"
        placeholder="Selecione uma opção" [(ngModel)]="selectedOption" (onChange)="onEditFieldChange($event.value)">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3" *ngIf="selectedOption ==='alteraData'">
      <label>Selecione a Data</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtvencimentoNew"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="currentDay">
      </p-calendar>
    </div>
    <div class="form-group col-sm-8" *ngIf="selectedOption ==='alteraConta'">
      <label>Conta</label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [options]="opcoesContaBancaria"
        placeholder="Selecione a conta" [(ngModel)]="contaSelecionada"></p-dropdown>
    </div>
  </div>

  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="atualizarEvento()"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Modal Baixar Evento -->
<p-dialog header="Baixar Evento" [(visible)]="modalBaixar" [style]="{width: '500px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Data da Baixa</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataBaixa"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="currentDay">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Tipo de Baixa</label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTiposBaixa"
        placeholder="Selecione o tipo de baixa" [(ngModel)]="tipoBaixa" (onChange)="checkTipoBaixa(tipoBaixa)">
      </p-dropdown>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalBaixar=false'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="prebaixa()" [disabled]="isTipoBaixa"><i
            class="fas fa-bolt"></i> Baixar
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
