<div *ngIf="isRender" class="box-content position-relative">
  <div class="layer" *ngIf="isLoadingForm">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    <h2>{{(messageLoading !== null) ? messageLoading : 'Carregando...'}}</h2>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-row">
        <div class="form-group col-sm-10">
          <label>Título <span class="obrigatorio">*</span></label>
          <input class="form-control" [(ngModel)]="requisition.titulo" type="text" />
        </div>
        <div class="form-group col-sm-2">
          <label>Data Entrega <span class="obrigatorio">*</span></label>
          <p-calendar appendTo="body" class="type-p-calendar datepicker-form-rms" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="requisition.dtEntrega" yearRange="2010:2030" [defaultDate]="defaultDate" dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Tipo Requisição <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optTypesRequisition" [(ngModel)]="requisition.tipo.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptTypesRequisition" [dropdownIcon]="loadingIconOptTypesRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeTypeRequisition()">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Objeto <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optObjectsRequisition" [(ngModel)]="requisition.objeto.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptObjectsRequisition"
          [dropdownIcon]="loadingIconOptObjectsRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeObjectRequisition()"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Categoria</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCategoriesRequisition" [(ngModel)]="requisition.categoria.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCategoriesRequisition" [dropdownIcon]="loadingIconOptCategoriesRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'">
          </p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Origem <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optOriginsRequisition" [(ngModel)]="requisition.origem.id"emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptOriginsRequisition" [dropdownIcon]="loadingIconOptOriginsRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeOriginRequisition()"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Termo de Confidencialidade (NDA)</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optNdasRequisition" [(ngModel)]="requisition.nda.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptNdasRequisition" [dropdownIcon]="loadingIconOptNdasRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Prioridade <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optPrioritiesRequisition" [(ngModel)]="requisition.prioridade.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptPrioritiesRequisition" [dropdownIcon]="loadingIconOptPrioritiesRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-2" *ngIf="allowsOtherCurrencies && foreignRequisition">
          <label>Moeda <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCurrenciesRequisition" [(ngModel)]="requisition.moeda.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCurrenciesRequisition" [dropdownIcon]="loadingIconOptCurrenciesRequisition ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="getCurrencyRate()"></p-dropdown>
        </div>
        <div class="form-group col-sm-2" *ngIf="allowsOtherCurrencies && foreignRequisition">
          <label>Taxa <span class="obrigatorio">*</span></label>
          <input *ngIf="!loadingRate" [disabled]="requisition.moeda.id === 1" class="form-control text-right" type="number" [(ngModel)]="requisition.txMoeda">
          <p-skeleton *ngIf="loadingRate" width="100%" height="32px"></p-skeleton>
        </div>
        <p-checkbox *ngIf="isGestor" class="ml-1 col-sm-4" [binary]="true" [(ngModel)]="requisition.expresso"label="Expresso"></p-checkbox>
      </div>
      <div class="form-row mt-4">
        <div class="form-group col-sm-12">
          <label>Descrição <span class="obrigatorio">*</span></label>
          <p-editor [(ngModel)]="requisition.descricao" [style]="{'min-height':'100px'}"></p-editor>
        </div>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="!isEditRMS">
      <p-fileUpload [customUpload]="true" (uploadHandler)="onUpload($event)" [multiple]="true" accept=".txt,.xlsx,.pdf,.csv,.xls,image/*"
      [maxFileSize]="10000000" [auto]="true" (onRemove)="onRemove($event)" #fileUploader styleClass="file-uploader mt-3"
      chooseLabel="Selecionar arquivo" chooseIcon="fas fa-paperclip"
      invalidFileSizeMessageSummary="Arquivo inválido" invalidFileSizeMessageDetail="O tamanho máximo de upload é {0}."
      invalidFileTypeMessageSummary="Arquivo inválido" invalidFileTypeMessageDetail="Os tipos de arquivos aceitos são: {0}."
      invalidFileLimitMessageSummary="Arquvivo inválido" invalidFileLimitMessageDetail="O limite é {0} no máximo.">
        <ng-template pTemplate="toolbar">
          <h3>Anexos ({{files.length}})</h3>
          <div class="btnCustom-default btn-closeHanled" (click)="closeUpload()">
            <span class="material-icons">{{uploaderState ? 'visibility' : 'visibility_off'}}</span>
            <div>{{uploaderState ? 'Exibir' : 'Ocultar'}}</div>
          </div>
        </ng-template>
        <ng-template let-file pTemplate="file">
          <div class="file">
            <p>{{file.name}} - {{file.size}}KB</p>
            <button matTooltip="Remover anexo" class="btn-remove" type="button" (click)="removeFile(file, fileUploader)">
              <span class="material-icons">delete_outline</span>
            </button>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="drag-text">
            <span class="material-icons">cloud_upload</span>
            <h2>Arraste e solte seus anexos aqui!</h2>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>
    <div class="col-sm-12" [ngClass]="{ mt4: isEditRMS }" *ngIf="!isFinancial">
      <div class="header-section-form">
        <h3>Itens da RMS <span class="obrigatorio">*</span></h3>
        <app-toolbar [value]="toolbarItems"></app-toolbar>
      </div>
      <p-table class="table-system" [value]="requisition.pedidoItem" selectionMode="single" [(selection)]="itemSelected"
      (onRowSelect)="onSelectItem($event)" (onRowUnselect)="onSelectItem($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox"></th>
            <th class="text-left" style="width:25%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Item
                </span>
              </span>
            </th>
            <th class="text-left" style="width:15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Operação
                </span>
              </span>
            </th>
            <th class="text-left" style="width:15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Part Number
                </span>
              </span>
            </th>
            <th class="text-left" style="width:10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  NCM
                </span>
              </span>
            </th>
            <th class="text-left" style="width:15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Fabricante
                </span>
              </span>
            </th>
            <th class="text-right" style="width:5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Qtd.
                </span>
              </span>
            </th>
            <th class="text-right" style="width:10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Valor Unit {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : '' }}
                </span>
              </span>
            </th>
            <th class="text-right" style="width:10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Valor Total {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : '' }}
                </span>
              </span>
            </th>
            <th *ngIf="allowsOtherCurrencies && foreignRequisition" class="text-right" style="width:10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Valor Total (R$)
                </span>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center tableCheckbox">
              <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
            </td>
            <td>
              <span *ngIf="!rowData.itemCadastrado" class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.item}}">
                  {{rowData.item}}
                </span>
              </span>
              <span *ngIf="rowData.itemCadastrado" class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.itemInt.descricao}}">
                  {{rowData.itemInt.codigo}}-{{rowData.itemInt.descricao}}
                </span>
              </span>
            </td>
            <td>
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.operacaoRegra.descricao}}">
                  {{rowData.operacaoRegra.cod ? rowData.operacaoRegra.cod + " - " + rowData.operacaoRegra.descricao : rowData.operacaoRegra.descricao }}
                </span>
              </span>
            </td>
            <td>
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.partNumber}}">
                  {{rowData.partNumber}}
                </span>
              </span>
            </td>
            <td>
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipi.ncm}}">
                  {{rowData.tipi.ncm}}
                </span>
              </span>
            </td>
            <td>
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fabricante}}">
                  {{rowData.fabricante}}
                </span>
              </span>
            </td>
            <td class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.quantidade}}">
                  {{rowData.quantidade}}
                </span>
              </span>
            </td>
            <td class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorUnit | money}}">
                  {{rowData.valorUnit | money}}
                </span>
              </span>
            </td>
            <td class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorTotal | money}}">
                  {{rowData.valorTotal | money}}
                </span>
              </span>
            </td>
            <td *ngIf="allowsOtherCurrencies && foreignRequisition" class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{(rowData.valorTotal * requisition.txMoeda)| money}}">
                  {{(rowData.valorTotal * requisition.txMoeda) | money}}
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer" let-rowData>
          <tr>
            <td [attr.colspan]="8" class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Total
                </span>
              </span>
            </td>
            <td class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{totalRequisitionValue | money}}">
                  {{totalRequisitionValue | money}}
                </span>
              </span>
            </td>
            <td class="text-right" *ngIf="allowsOtherCurrencies && foreignRequisition">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{totalRequisitionValue | money}}">
                  {{(totalRequisitionValue * requisition.txMoeda) | money}}
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="(allowsOtherCurrencies && foreignRequisition) ? 9 : 8" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Sem Itens Adicionados!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="containerCustom-informations">
      <div class="col-sm-12 mt-4" *ngIf="!isTransfer" [ngClass]="{ reverse: isFinancial }">
        <div class="header-section-form">
          <h3>Rateio <span *ngIf="isMandatoryApportionment" class="obrigatorio">*</span><span class="text-secondary" *ngIf="!isFinancial">{{itemSelected ? ': ' + requisitionItem.descricao : ''}}</span></h3>
          <app-toolbar [value]="toolbarApportionment"></app-toolbar>
        </div>
        <p-table *ngIf="isFinancial || (itemSelected &&  requisitionItem)" class="table-system" [value]="requisitionItem.requisicaoItemRateios" selectionMode="single"
        (onEditComplete)="editingApportionment()" [(selection)]="apportionmentSelected"
        (onRowSelect)="changeToolbarApportionment()" (onRowUnselect)="changeToolbarApportionment()">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox"></th>
              <th class="text-left" style="width: 45%">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    Centro de Custo
                  </span>
                </span>
              </th>
              <th class="text-left" style="width: 45%">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    Projeto
                  </span>
                </span>
              </th>
              <th class="text-left" style="width: 10%">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    Porcentagem
                  </span>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
              </td>
              <td pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [(ngModel)]="rowData.cadCcus.id" [options]="optCostCenter" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCostCenter" [dropdownIcon]="loadingIconOptCostCenter ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeCostCenter(rowData)"></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cadCcus.label}}">
                        {{rowData.cadCcus.label}}
                      </span>
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn>
                <p-cellEditor (click)="showAvailableProjects(rowData.cadCcus.id)">
                  <ng-template pTemplate="input">
                    <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [(ngModel)]="rowData.cadProj.id" [options]="optProjects" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptProjects" [dropdownIcon]="loadingIconOptProjects ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeProject(rowData)"></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cadProj.label }}">
                        {{ rowData.cadProj.label }}
                      </span>
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input type="number" class="text-right" style="max-width: 100%;" [(ngModel)]="rowData.porcentagem">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis text-right" matTooltip="{{ rowData.porcentagem }}%">
                        {{ rowData.porcentagem }}%
                      </span>
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td [attr.colspan]="3" class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    Total
                  </span>
                </span>
              </td>
              <td class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{totalRequesitionPercentageApportionment}}%">
                    {{totalRequesitionPercentageApportionment}}%
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Sem Rateio Adicionado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="!isFinancial && !itemSelected" class="alert alert-xs alert-primary d-inline" role="alert">
          <i class="fas fa-info-circle"></i> Selecione um item para ver seu rateio!
        </div>
      </div>
      <div class="col-sm-12 mt-4">
        <div class="header-section-form">
          <h3>Informações de pagamento <span *ngIf="isMandatoryBankInformation" class="obrigatorio">*</span></h3>
        </div>
        <div *ngIf="isFinancial && !isTransfer" class="form-row align-items-center">
          <div *ngIf="!isTransfer" class="form-group col-sm-5">
            <label>Operação <span *ngIf="isMandatoryOperation" class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optOperations" [(ngModel)]="financialRequisition.operacaoRegraContabil.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptOperations" [dropdownIcon]="loadingIconOptOperations ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
          </div>
          <div class="form-group {{(allowsOtherCurrencies && foreignRequisition) ? 'col-sm-3': 'col-sm-4'}}">
            <label>Histórico <span class="obrigatorio">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="financialRequisition.historico" />
          </div>
          <div class="form-group {{(allowsOtherCurrencies && foreignRequisition) ? 'col-sm-2': 'col-sm-3'}}">
            <label>Valor Moeda {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : ''}} <span class="obrigatorio">*</span></label>
            <input class="form-control" type="number" [(ngModel)]="financialRequisition.valor"/>
          </div>
          <div class="form-group col-sm-2" *ngIf="allowsOtherCurrencies && foreignRequisition && !isTransfer">
            <label>Valor (R$)</label>
            <input *ngIf="!loadingRate" [disabled]="true" class="form-control" type="number" [value]="(financialRequisition.valor * requisition.txMoeda)"/>
            <p-skeleton *ngIf="loadingRate" width="100%" height="32px"></p-skeleton>
          </div>
          <div class="form-group col-sm-2">
            <label>Data de vencimento <span class="obrigatorio">*</span></label>
            <p-calendar appendTo="body" class="type-p-calendar datepicker-form-rms" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="financialRequisition.dtVencimento" yearRange="2010:2030" [defaultDate]="defaultDate" dateFormat="dd/mm/yy"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Conta da empresa <span *ngIf="isMandatoryBankInformation" class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown " [filter]="true" placeholder="Selecione" [options]="optCompanyAccounts" [(ngModel)]="requisition.empresaConta.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts || !allowChangeAccount" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
          </div>
          <div class="form-group {{(allowsOtherCurrencies && foreignRequisition) ? 'col-sm-3': 'col-sm-4'}}">
            <label>Nº Documento </label>
            <input class="form-control" type="text" maxlength="10" [(ngModel)]="financialRequisition.nDocumento" />
          </div>
          <p-checkbox class="ml-1" [(ngModel)]="isTransfer" [binary]="true" (onChange)="changeTransfer()" label="Transferência"></p-checkbox>
          <p-checkbox class="ml-3" [(ngModel)]="isStarling" [binary]="true" (onChange)="changeStarling()" label="Estorno"></p-checkbox>
        </div>
        <div *ngIf="isFinancial && isTransfer" class="form-row align-items-center">
          <div class="form-group col-sm-2">
            <label>Data</label>
            <p-calendar appendTo="body" class="type-p-calendar datepicker-form-rms" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="financialRequisition.dtVencimento" yearRange="2010:2030" [defaultDate]="defaultDate" dateFormat="dd/mm/yy"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Conta origem <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCompanyAccounts" [(ngModel)]="financialRequisition.contaOrigem.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Conta destino <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCompanyAccounts" [(ngModel)]="financialRequisition.contaDestino.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Histórico <span class="obrigatorio">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="financialRequisition.historico"/>
          </div>
          <div class="form-group col-sm-1">
            <label>Valor <span class="obrigatorio">*</span></label>
            <input class="form-control" type="number" [(ngModel)]="financialRequisition.valor"/>
          </div>
          <p-checkbox class="ml-1" [(ngModel)]="isTransfer" [binary]="true" (onChange)="changeTransfer()" label="Transferência"></p-checkbox>
        </div>
        <div *ngIf="!isFinancial" class="form-row align-items-end">
          <div class="form-group col-sm-3">
            <label>Tipo de pagamento</label>
            <p-dropdown appendTo="body" class="type-p-dropdown" placeholder="Selecione" [options]="optPaymentTypes" [(ngModel)]="requisition.tipoPagamento" (onChange)="changePaymentType(false)"></p-dropdown>
          </div>
          <div class="form-group col-sm-2" *ngIf="requisition.tipoPagamento === 1">
            <label>Forma de pagamento <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" placeholder="Selecione a forma de pagamento" [filter]="true" [options]="optPaymentForms" [(ngModel)]="requisition.formaPagamento.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptPaymentForms" [dropdownIcon]="loadingIconOptPaymentForms ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changePaymentForm()">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-3" *ngIf="requisition.tipoPagamento === 1">
            <label>Conta <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCompanyAccounts" [(ngModel)]="requisition.empresaConta.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts || !isAllowsChangingBank" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
          </div>
          <div class="form-group col-sm-4 d-flex align-items-center">
            <button class="btnCustom-green" (click)="openingModalPaymentForm()">
              <span class="material-icons">add</span>
              <div>Adicionar</div>
            </button>
            <button class="btnCustom-default" (click)="updatePaymentMethod()" [disabled]="isFetchParcel">
              <span class="material-icons" [ngClass]="{ rotate: isFetchParcel }">refresh</span>
              <div>{{ isFetchParcel ? 'Carregando...' : 'Atualizar' }}</div>
            </button>
          </div>
          <p-table *ngIf="requisition.pedidoParcela.length > 0 && !isFinancial" class="table-system mb-3"  [value]="requisition.pedidoParcela">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 5%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Parcela
                    </span>
                  </span>
                </th>
                <th class="text-center" style="width: 10%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Vencimento
                    </span>
                  </span>
                </th>
                <th class="text-right" style="width: 10%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Valor {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : '' }}
                    </span>
                  </span>
                </th>
                <th *ngIf="allowsOtherCurrencies && foreignRequisition" class="text-right" style="width: 10%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Valor (R$)
                    </span>
                  </span>
                </th>
                <th class="text-left" style="width: 20%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Conta
                    </span>
                  </span>
                </th>
                <th class="text-left" style="width: 15%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Tipo
                    </span>
                  </span>
                </th>
                <th class="text-left" style="width: 30%">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">
                      Histórico
                    </span>
                  </span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.parcela }}">
                      {{ rowData.parcela }}
                    </span>
                  </span>
                </td>
                <td class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.dtVencimento | timezone | date: 'dd/MM/yyyy' }}">
                      {{ rowData.dtVencimento | timezone | date: 'dd/MM/yyyy' }}
                    </span>
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valor | money }}">
                      {{ rowData.valor | money }}
                    </span>
                  </span>
                </td>
                <td *ngIf="allowsOtherCurrencies && foreignRequisition" class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ (rowData.valor * requisition.txMoeda) | money }}">
                      {{ (rowData.valor * requisition.txMoeda) | money }}
                    </span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}">
                      {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                    </span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                      {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                    </span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.historico }}">
                      {{ rowData.historico }}
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline">
                    <i class="fas fa-info-circle"></i> Sem forma pagamento selecionada
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-4">
      <div class="header-section-form">
        <h3>Justificativa</h3>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Necessidade</label>
          <textarea class="form-control" style="resize: none;" [(ngModel)]="requisition.necessidade" value="requisition.necessidade" rows="5" type="text"></textarea>
        </div>
        <div class="form-group col-sm-4">
          <label>Resultados esperados</label>
          <textarea class="form-control" style="resize: none;" [(ngModel)]="requisition.resultadoEsperado" value="requisition.resultadoEsperado" rows="5" type="text"></textarea>
        </div>
        <div class="form-group col-sm-4">
          <label>Escolha do fornecedor (compra dirigida)</label>
          <textarea class="form-control" style="resize: none;" [(ngModel)]="requisition.compraDirigida" value="requisition.compraDirigida" rows="5" type="text"></textarea>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-3">
      <div class="header-section-form">
        <h3>{{(isProvider && !isStarling) ? "Fornecedores" : "Clientes"}} sugeridos <span class="obrigatorio">*</span></h3>
        <app-toolbar [value]="toolbarProvider"></app-toolbar>
      </div>
      <p-table class="table-system" [value]="requisition.sugestaoFornecedores" selectionMode="single" [(selection)]="providerSelected"
      (onRowSelect)="onSelectProvider($event)" (onRowUnselect)="onSelectProvider($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox"></th>
            <th class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Empresa
                </span>
              </span>
            </th>
            <th class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  E-mail
                </span>
              </span>
            </th>
            <th class="text-left" style="width: 10%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Link
                </span>
              </span>
            </th>
            <th class="text-left" style="width: 10%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Departamento
                </span>
              </span>
            </th>
            <th class="text-left" style="width: 10%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Telefone
                </span>
              </span>
            </th>
            <th class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Contato
                </span>
              </span>
            </th>
            <th class="text-left" style="width: 20%">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">
                  Observação
                </span>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center tableCheckbox">
              <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.empresa}}">
                  {{rowData.empresa}}
                </span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}">
                  {{rowData.email}}
                </span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.link}}">
                  {{rowData.link}}
                </span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.area}}">
                  {{rowData.area}}
                </span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.telefone}}">
                  {{rowData.telefone}}
                </span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                  {{rowData.nome}}
                </span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.observacao}}">
                  {{rowData.observacao}}
                </span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="8" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Sem {{(isProvider && !isStarling) ? "Fornecedor" : "Cliente"}} registrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-sm-12 mt-4" *ngIf="isShowApproval">
      <div class="header-section-form">
        <h3>Aprovação</h3>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <p-checkbox class="mr-1" [(ngModel)]="isSaveAndApprove" [binary]="true" inputId="approv" label="Solicitar Aprovação"></p-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="showModalItem" [style]="{width:'900px'}" styleClass="height-initial-item" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
  <p-header>
    <div class="modal-add-item-form-header">
      <label>Adicionar Item</label>
      <div class="d-flex align-items-center">
        <button *ngIf="!isEditItem" class="btnCustom-green" (click)="changeFormItem(!requisitionItem.itemCadastrado)">
          <span class="material-icons">{{requisitionItem.itemCadastrado ? 'add' : 'undo'}}</span>
          <div>{{requisitionItem.itemCadastrado ? 'Criar item' : 'Selecionar Item'}}</div>
        </button>
      </div>
    </div>
  </p-header>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Operação <span *ngIf="isMandatoryOperation" class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optOperations" [(ngModel)]="requisitionItem.operacaoId" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptOperations" [dropdownIcon]="loadingIconOptOperations ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeOperation()"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Item <span class="obrigatorio">*</span></label>
      <input class="form-control" placeholder="Insira o item" [(ngModel)]="requisitionItem.item" *ngIf="!requisitionItem.itemCadastrado">
      <div input-vertical="middle" *ngIf="requisitionItem.itemCadastrado">
        <p-dropdown appendTo="body" class="type-p-dropdown" panelStyleClass="panel-add-item-form" [filter]="true" [virtualScroll]="true" [itemSize]="30" [options]="optItems" placeholder="Selecione" [(ngModel)]="requisitionItem.itemInt.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptItems" [dropdownIcon]="loadingIconOptItems ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeItem($event)"></p-dropdown>
      </div>
    </div>
  </div>
  <div class="form-row {{(requisitionItem.itemCadastrado ? 'lks-form-grid-readOnly' : '')}}">
    <div class="form-group col-sm-12 lks-form-grid locked-element">
      <label>Descrição</label>
      <input *ngIf="!loadingItemInformations" class="form-control" placeholder="Descrição" [(ngModel)]="requisitionItem.descricao">
      <p-skeleton *ngIf="loadingItemInformations" width="100%" height="32px"></p-skeleton>
    </div>
    <div class="form-group col-sm-12 lks-form-grid locked-element">
      <label>Tipo Item</label>
      <p-dropdown *ngIf="!loadingItemInformations" appendTo="body" class="type-p-dropdown" [filter]="true" [options]="optTypesItem" placeholder="Selecione" [(ngModel)]="requisitionItem.itemTipo.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptTypesItem" [dropdownIcon]="loadingIconOptTypesItem ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
      <p-skeleton *ngIf="loadingItemInformations" width="100%" height="32px"></p-skeleton>
    </div>
    <div class="form-group col-sm-4 lks-form-grid locked-element">
      <label>Part Number</label>
      <input *ngIf="!loadingItemInformations" class="form-control" placeholder="Part Number" [(ngModel)]="requisitionItem.partNumber">
      <p-skeleton *ngIf="loadingItemInformations" width="100%" height="32px"></p-skeleton>
    </div>
    <div class="form-group col-sm-4 lks-form-grid locked-element">
      <label>Capítulo NCM</label>
      <p-dropdown *ngIf="!loadingItemInformations"  appendTo="body" class="type-p-dropdown" panelStyleClass="panel-add-item-form-ncm" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optTipis" [(ngModel)]="requisitionItem.tipiCap.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptTipis" [dropdownIcon]="loadingIconOptTipis ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="changeNCM(requisitionItem.tipiCap.id)"></p-dropdown>
      <p-skeleton *ngIf="loadingItemInformations" width="100%" height="32px"></p-skeleton>
    </div>
    <div class="form-group col-sm-4 lks-form-grid locked-element">
      <label>NCM</label>
      <p-dropdown *ngIf="!loadingItemInformations" appendTo="body" class="type-p-dropdown" panelStyleClass="panel-add-item-form-ncm" [filter]="true" placeholder="Selecione" [options]="optNCM" [(ngModel)]="requisitionItem.tipi.id" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptNCM" [dropdownIcon]="loadingIconOptNCM ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
      <p-skeleton *ngIf="loadingItemInformations" width="100%" height="32px"></p-skeleton>
    </div>
  </div>
  <div class="form-row" *ngIf="!requisitionItem.itemCadastrado">
    <div class="form-group col-sm-12 locked-element">
      <label>Descrição completa</label>
      <textarea class="form-control" rows="5" input-vertical="middle" style="resize: none;" placeholder="Descrição completa em português para registro da DI" [(ngModel)]="requisitionItem.descricaoBr"></textarea>
    </div>
    <div class="form-group col-sm-12 locked-element">
      <label>Fabricante</label>
      <input class="form-control" [(ngModel)]="requisitionItem.fabricante">
    </div>
    <div class="form-group col-sm-12 locked-element">
      <label>Link</label>
      <input class="form-control" [(ngModel)]="requisitionItem.link">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group locked-element {{(allowsOtherCurrencies && foreignRequisition) ? 'col-sm-3': 'col-sm-4'}}">
      <label>Quantidade <span class="obrigatorio">*</span></label>
      <input class="form-control text-right" type="number" [(ngModel)]="requisitionItem.quantidade" (ngModelChange)="calcTotalItemValue()">
    </div>
    <div class="form-group locked-element {{(allowsOtherCurrencies && foreignRequisition) ? 'col-sm-3': 'col-sm-4'}}">
      <label>Valor Unitário {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : '' }} <span class="obrigatorio">*</span></label>
      <input class="form-control text-right" type="number" [(ngModel)]="requisitionItem.valorUnit" (ngModelChange)="calcTotalItemValue()" />
    </div>
    <div class="form-group locked-element {{(allowsOtherCurrencies && foreignRequisition) ? 'col-sm-3': 'col-sm-4'}}">
      <label class="text-right">Valor Total {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : ''}}</label>
      <input class="form-control text-right" type="number" [disabled]="true" [ngModel]="requisitionItem.valorTotal" />
    </div>
    <div *ngIf="allowsOtherCurrencies && foreignRequisition" class="form-group locked-element col-sm-3">
      <label class="text-right">Valor Total (R$)</label>
      <input class="form-control text-right" type="number" [disabled]="true" [value]="(requisitionItem.valorTotal * requisition.txMoeda)"/>
    </div>
  </div>
  <p-footer>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <button class="btnCustom-default" (click)="openingModalItem(false)">
        <span class="material-icons">close</span>
        <div>Cancelar</div>
      </button>
      <button class="btnCustom-green" (click)="isEditItem ? editItemInRequisition() : addItemInRequisition()">
        <span class="material-icons">{{isEditItem ? 'edit': 'add'}}</span>
        <div>{{isEditItem ? 'Alterar' : 'Adicionar'}}</div>
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="showModalProvider" [style]="{width:'900px'}" styleClass="height-initial-provider" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
  <p-header>
    <div class="modal-add-item-form-header">
      <label>Adicionar {{(isProvider && !isStarling) ? "Fornecedor" : "Cliente"}}</label>
      <div class="d-flex align-items-center">
        <button *ngIf="!isEditProvider" class="btnCustom-green" (click)="changeFormProvider(!isSupplierAlreadyRegistered)">
          <span class="material-icons">{{isSupplierAlreadyRegistered ? 'add' : 'undo'}}</span>
          <div>{{(isSupplierAlreadyRegistered ? 'Criar' : 'Selecionar') + ((isProvider && !isStarling) ? ' fornecedor' : ' cliente')}}</div>
        </button>
      </div>
    </div>
  </p-header>
  <div class="form-row">
    <div class="form-group col-sm-12" *ngIf="isSupplierAlreadyRegistered">
      <label>{{(isProvider && !isStarling)?"Fornecedor":"Cliente"}} <span class="obrigatorio"> *</span></label>
      <p-dropdown [virtualScroll]="true" [itemSize]="30" appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optProviders" [(ngModel)]="suggestedSupplier.participanteEmpresaId" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptProviders" [dropdownIcon]="loadingIconOptProviders ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
    </div>
    <div class="form-group col-sm-12" *ngIf="!isSupplierAlreadyRegistered">
      <label>{{(isProvider && !isStarling) ? "Fornecedor" : "Cliente"}} <span class="obrigatorio"> *</span></label>
      <input class="form-control" [(ngModel)]="suggestedSupplier.empresa">
    </div>
  </div>
  <div class="form-row" *ngIf="!isSupplierAlreadyRegistered">
    <div class="form-group col-sm-2">
      <label>Telefone</label>
      <p-inputMask class="type-p-mask" matInput placeholder="(99) 99999-9999" mask="(99) 99999-9999" [(ngModel)]="suggestedSupplier.telefone"></p-inputMask>
    </div>
    <div class="form-group col-sm-5">
      <label>Contato</label>
      <input class="form-control" placeholder="Nome Contato" [(ngModel)]="suggestedSupplier.nome">
    </div>
    <div class="form-group col-sm-5">
      <label>E-mail</label><span class="obrigatorio"> *</span>
      <input class="form-control" [(ngModel)]="suggestedSupplier.email">
    </div>
    <div class="form-group col-sm-6">
      <label>Departamento</label>
      <input class="form-control" placeholder="" [(ngModel)]="suggestedSupplier.area">
    </div>
    <div class="form-group col-sm-6">
      <label>Link</label>
      <input class="form-control" placeholder="" [(ngModel)]="suggestedSupplier.link">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Observação</label>
      <input type="text" class="form-control" placeholder="Observação" [(ngModel)]="suggestedSupplier.observacao">
    </div>
  </div>
  <p-footer>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <button class="btnCustom-default" (click)="openingModalProvider(false)">
        <span class="material-icons">close</span>
        <div>Cancelar</div>
      </button>
      <button class="btnCustom-green" (click)="isEditProvider ? editProviderInRequisition() : addProviderInRequisition()">
        <span class="material-icons">{{isEditProvider ? 'edit': 'add'}}</span>
        <div>{{isEditProvider ? 'Alterar' : 'Adicionar'}}</div>
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="showModalPaymentForm" [style]="{width:'900px'}" styleClass="modal-scroll" [modal]="true" [responsive]="true" appendTo="body" [closable]="true" header="Parcelas">
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Quantidade de parcelas</label>
      <input class="form-control" type="number" [(ngModel)]="numberOfInstallments">
    </div>
    <div class="form-group col-sm-2">
      <label>Primeiro vencimento</label>
      <p-calendar appendTo="body" class="type-p-calendar datepicker-form-rms" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="firstSalary" yearRange="2010:2030" [defaultDate]="firstSalary" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="form-group col-sm-2 d-flex align-items-end">
      <button class="btnCustom-green" (click)="calcPaymentMethod(true)">
        <span class="material-icons">calculate</span>
        <div>Calcular</div>
      </button>
    </div>
  </div>

  <p-table *ngIf="requisition.pedidoParcela.length > 0 && !isFinancial" class="table-system mb-3"  [value]="requisition.pedidoParcela">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 5%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Parcela
            </span>
          </span>
        </th>
        <th class="text-center" style="width: 15%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Vencimento
            </span>
          </span>
        </th>
        <th class="text-right" style="width: 10%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Valor {{ (allowsOtherCurrencies && foreignRequisition) ? currencySelected() : '' }}
            </span>
          </span>
        </th>
        <th *ngIf="allowsOtherCurrencies && foreignRequisition" class="text-right" style="width: 10%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Valor (R$)
            </span>
          </span>
        </th>
        <th class="text-left" style="width: 20%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Conta
            </span>
          </span>
        </th>
        <th class="text-left" style="width: 15%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Tipo
            </span>
          </span>
        </th>
        <th class="text-left" style="width: 25%">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">
              Histórico
            </span>
          </span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.parcela }}">
              {{ rowData.parcela }}
            </span>
          </span>
        </td>
        <td pEditableColumn class="text-center">
          <div class="col-sm-12 p-0">
            <p-calendar appendTo="body" class="type-p-calendar datepicker-form-rms" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="rowData.dtVencimento" dateFormat="dd/mm/yy">
            </p-calendar>
          </div>
        </td>
        <td pEditableColumn class="text-right">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control" type="number" [(ngModel)]="rowData.valor" />
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valor | money }}">
                  {{ rowData.valor | money }}
                </span>
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td *ngIf="allowsOtherCurrencies && foreignRequisition" class="text-right">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ (rowData.valorBrl * requisition.txMoeda) | money }}">
              {{ (rowData.valorBrl * requisition.txMoeda) | money }}
            </span>
          </span>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCompanyAccounts" [(ngModel)]="rowData.empresaConta.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts || !isAllowsChangingBank" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="setInstallmentsInPaymentForm(rowData, 'companyAccounts', 'empresaConta', 'nome')"></p-dropdown>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}">
                  {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                </span>
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn class="text-left">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="optLowTypes" placeholder="Selecione" [(ngModel)]="rowData.tipoBaixa.id"  emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptLowTypes" [dropdownIcon]="loadingIconOptLowTypes ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="setInstallmentsInPaymentForm(rowData, 'lowTypes', 'tipoBaixa', 'descricao')"></p-dropdown>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                  {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                </span>
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="col-sm-12 p-0">
                <input class="form-control" type="text" [(ngModel)]="rowData.historico"/>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.historico }}">
                  {{ rowData.historico }}
                </span>
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td [attr.colspan]="2" style="width: 18%" class="text-right">
          TOTAL REQUISIÇÃO:
        </td>
        <td style="width: 18%" class="text-right">
          {{ totalRequisitionValue | money }}
        </td>
        <td *ngIf="(allowsOtherCurrencies && foreignRequisition)" style="width: 18%" class="text-right">
          {{ (totalRequisitionValue * requisition.txMoeda) | money }}
        </td>
        <td [attr.colspan]="(allowsOtherCurrencies && foreignRequisition) ? 4 : 3" style="width: 18%"></td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="8" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline">
            <i class="fas fa-info-circle"></i> Sem forma pagamento selecionada
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-footer>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <button class="btnCustom-default" (click)="openingModalPaymentForm()">
        <span class="material-icons">close</span>
        <div>Cancelar</div>
      </button>
      <button class="btnCustom-green" (click)="saveInstallments()">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>
    </div>
  </p-footer>
</p-dialog>