<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <!--Table Seleção Item-->
  <div class="row" *ngIf="!editar">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Selecione um item</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateSelectItem>
          <p-table class="table-system" [value]="lista" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 30%">CÓDIGO</th>
                <th style="width: 70%" class="text-left">DESCRIÇÃO</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr (click)="verGrupo(rowData)" [pSelectableRow]="rowData">
                <td>
                  {{ rowData.codigo }}
                </td>
                <td class="text-left">
                  {{ rowData.descricao }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <!--Item Selecionado-->
  <div class="row" *ngIf="editar">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Item Selecionado</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainItemS"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateItemSelecionado>
          <div class="form-row">
            <div class="form-group col-sm-2 mb-2">
              <label for="inputCodigo">CÓDIGO</label>
              <input class="form-control" [(ngModel)]="grupo.codigo" value="{{grupo.codigo}}" type="text"
                name="inputCodigo">
            </div>
            <div class="form-group col-sm-4 mb-2">
              <label for="inputDescricao">DESCRIÇÃO</label>
              <input class="form-control" [(ngModel)]="grupo.descricao" value="{{grupo.descricao}}" type="text"
                name="inputDescricao">
            </div>
            <div class="form-group col-sm-1 mb-2">
              <label for="inputCodigo">DATA INI</label>
              <input class="form-control" [(ngModel)]="grupo.dtInicial" value="{{grupo.dtInicial}}" type="text"
                name="inputCodigo">

            </div>
            <div class="form-group col-sm-1 mb-2">
              <label for="inputCodigo">DATA FIN</label>
              <input class="form-control" [(ngModel)]="grupo.dtFinal" value="{{grupo.dtFinal}}" type="text"
                name="inputCodigo">
            </div>
            <div class="form-group col-sm-4 mb-2">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Relacione Itens Disponíveis-->
  <div class="row" *ngIf="editar">
    <div class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Itens Disponíveis</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainItemD"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateItens>
          <!-- <h4 class="sub-title-page mb-3"><i class="fas fa-key" style="color: #0091ea;"></i> <strong>Itens</strong></h4> -->
          <p style="font-size: 13px; text-transform: initial;"><i class="fas fa-arrow-right"
              style="color: #0091ea;"></i> Relacione os itens disponíveis.</p>
          <div class="row">
            <div class="col-sm-12">
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Itens Disponíveis" targetHeader="Itens Relacionados"
                  [source]="itensRelacionar" [target]="itensRelacionados" [dragdrop]="true" [responsive]="true"
                  filterBy="desc" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-itm pTemplate="item">{{itm.desc}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Relacione Alíquotas Disponíveis-->
    <div class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Alíquotas Disponíveis</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainA"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateAliquota>
          <div class="row">
            <div class="col-sm-12">
              <!-- <h4 class="sub-title-page mb-3"><i class="fas fa-key" style="color: #0091ea;"></i> <strong>Alíquotas</strong></h4> -->
              <p style="font-size: 13px; text-transform: initial;"><i class="fas fa-arrow-right"
                  style="color: #0091ea;"></i> Relacione as alíquotas disponíveis.</p>
              <div class="row">
                <div class="col-sm-12">
                  <div class="conteudo m-0">
                    <p-pickList sourceHeader="Alíquotas Disponíveis" targetHeader="Alíquotas Relacionadas"
                      [source]="aliquotasRelacionar" [target]="aliquotasRelacionadas" [dragdrop]="true"
                      [responsive]="true" filterBy="desc" [showSourceControls]="false" [showTargetControls]="false">
                      <ng-template let-aliq pTemplate="item">{{aliq.desc}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="empresaVinculada" class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Perfis Fiscais Disponíveis</h3>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateAliquota>
          <div class="row">
            <div class="col-sm-12">
              <p style="font-size: 13px; text-transform: initial;"><i class="fas fa-arrow-right"
                  style="color: #0091ea;"></i> Vincule os perfis fiscais disponíveis.</p>
              <div class="row">
                <div class="col-sm-12">
                  <div class="conteudo m-0">
                    <p-pickList sourceHeader="Perfis Fiscais Disponíveis" targetHeader="Perfis Fiscais Relacionadas"
                      [source]="perfisFiscaisRelacionar" [target]="perfisFiscaisRelacionados" [dragdrop]="true"
                      [responsive]="true" filterBy="desc" [showSourceControls]="false" [showTargetControls]="false">
                      <ng-template let-perfil pTemplate="item">{{perfil.nome}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div class="ui-g">
  <div class="ui-g-12">

  </div>
</div>