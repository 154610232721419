<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        {{title}}
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
          <br>
          <small class="font-weight-normal">Período selecionado: {{convertTimestamp(dataIni)}} à {{convertTimestamp(dataFin)}}</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">{{ '&nbsp;' }}</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="dadosTabela" [style]="{width:'100%',height:'100%'}"
            [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [totalRecords]="totalLinhas" [(selection)]="movSelecionadas"  dataKey="id"
            [lazy]="true" (onLazyLoad)="receberMovimentacoes($event)" [lazyLoadOnInit]="false" paginatorDropdownAppendTo="body"
            (onRowSelect)="atualizarToolbar()" (onHeaderCheckboxToggle)="atualizarToolbar()" (onRowUnselect)="atualizarToolbar()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DATA <p-sortIcon [field]="'data'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'data', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'doc'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CÓDIGO <p-sortIcon [field]="'doc'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'doc', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'mi'">
                      <span class="text-overflow-dynamic-ellipsis">
                        MI <p-sortIcon [field]="'mi'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'mi', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'local'">
                      <span class="text-overflow-dynamic-ellipsis">
                        ALMOXARIFADO <p-sortIcon [field]="'local'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'local', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codItem'">
                      <span class="text-overflow-dynamic-ellipsis">
                        ITEM <p-sortIcon [field]="'codItem'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'codItem', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        HISTÓRICO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoEntradaSaida'">
                      <span class="text-overflow-dynamic-ellipsis">
                        E/S <p-sortIcon [field]="'tipoEntradaSaida'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="listaES" defaultLabel="" [filter]="false" (onChange)="dt.filter($event.value, 'tipoEntradaSaidaId', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                      <span class="text-overflow-dynamic-ellipsis">
                        QTDE <p-sortIcon [field]="'qtde'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'qtde', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">
                        VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'valor', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data }}">
                        {{rowData.data}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.doc}}">
                        {{rowData.doc}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.mi}}">
                        {{rowData.mi}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.local}}">
                        {{rowData.local}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codItem}}">
                        {{rowData.codItem}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">
                        {{rowData.descricao}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoEntradaSaida}}">
                        {{rowData.tipoEntradaSaida}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.qtde}}">
                        {{rowData.qtde}}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor | money}}">
                        {{rowData.valor | money}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="12" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Inclusão Movimentação Interna" [(visible)]="mostrarModalNovo" [style]="{width: '900px'}" [modal]="true"
 class="modal-scroll" appendTo="body" [closable]="false" *ngIf="mostrarModalNovo">
  <div class="form-row">
    <div class="form-group col-2">
      <label>Data</label>
      <p-calendar class="type-p-calendar" [(ngModel)]="movInterna.dtMov" appendTo="body" [monthNavigator]="true"
        [yearNavigator]="true" yearRange="2010:2030" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-4">
      <label>Cod.</label>
      <input class="form-control" class="form-control" type="text"
        placeholder="Código gerado automaticamente" readonly />
    </div>
    <div class="form-group col-sm-6">
      <label>Mi</label>
      <p-dropdown [options]="listaMI" [(ngModel)]="movInterna.mi" appendTo="body" [disabled]="requisicaoMiCarregando" [dropdownIcon]="requisicaoMiCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="alterarMI(movInterna.mi)"
        placeholder="selecione" [filter]="true" class="type-p-dropdown"></p-dropdown>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Histórico</label>
      <input class="form-control" [(ngModel)]="movInterna.historico" type="text" />
    </div>
  </div>

  <p-table class="table-system" [value]="movInterna.movInternaItem" [paginator]="false" [rows]="15"
    [resizableColumns]="true" [responsive]="true" [rowHover]="false" derableColumns="true" [scrollable]="true"
    [style]="{width:'100%'}" scrollHeight="280px" *ngIf="mostrarTabelaItens" >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 20%;">Item</th>
        <th style="width: 5%;" *ngIf="mostrarTransformacao">E/S</th>
        <th style="width: 10%;" *ngIf="mostrarTransfAlmoxarifado || movInterna.mi.tipo.id === 2 || mostrarTransfParticipante">Alm. Origem</th>
        <th style="width: 10%;" *ngIf="mostrarTransfAlmoxarifado || movInterna.mi.tipo.id === 1 || mostrarTransfParticipante">Alm. Destino</th>
        <th style="width: 10%;" class="text-right" *ngIf="mostrarQtde">Un.</th>
        <th style="width: 5%;" class="text-right" *ngIf="mostrarQtde">Qtd.</th>
        <th style="width: 5%;" class="text-right" *ngIf="mostrarValor">Valor</th>
        <th style="width: 10%;" *ngIf="mostrarTransfParticipante">Part. Origem</th>
        <th style="width: 10%;" *ngIf="mostrarTransfParticipante">Part. Destino</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td pEditableColumn style="width:20%">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaItem" [(ngModel)]="rowData.item.id" (onChange)="itemAlterado(rowData); dadosItem(rowData)"
                    [filter]="true" [disabled]="requisicaoItemCarregando" [dropdownIcon]="requisicaoItemCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" placeholder="Selecione" panelStyleClass="custom-width">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{ (rowData.item.codigo != undefined && rowData.item.descricao != undefined) ? (rowData.item.codigo + ' - ' + rowData.item.descricao) : '' }}" class="overflow-ellipse"
                style="cursor: pointer;">
                {{(rowData.item.codigo != undefined && rowData.item.descricao != undefined) ? (rowData.item.codigo + ' - ' + rowData.item.descricao) : ''}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:5%" *ngIf="mostrarTransformacao">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaNovoES"
                    [(ngModel)]="rowData.tipoEntradaSaida" [filter]="true" placeholder="Selecione"
                    panelStyleClass="custom-width">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{rowData.tipoEntradaSaida.name}}" class="overflow-ellipse"
                style="cursor: pointer;">
                {{rowData.tipoEntradaSaida.name}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:10%" *ngIf="mostrarTransfAlmoxarifado || movInterna.mi.tipo.id === 2 || mostrarTransfParticipante">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaAlmoxarifado"
                    [(ngModel)]="rowData.empresaAlmoxarifadoOrig.id" [filter]="true" [disabled]="requisicaoAlmoxCarregando" (onChange)="popularAlmox(rowData, 1)" [dropdownIcon]="requisicaoAlmoxCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" placeholder="Selecione"
                    panelStyleClass="custom-width">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{rowData.empresaAlmoxarifadoOrig.codigo}}" class="overflow-ellipse"
                style="cursor: pointer;" *ngIf="rowData.empresaAlmoxarifadoOrig">
                {{rowData.empresaAlmoxarifadoOrig.codigo}}
              </span>
              <span matTooltip="{{rowData.empresaAlmoxarifadoOrig.codigo}}" class="overflow-ellipse"
                style="cursor: pointer;" *ngIf="!rowData.empresaAlmoxarifadoOrig">
              </span>
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:10%" *ngIf="mostrarTransfAlmoxarifado || movInterna.mi.tipo.id === 1 || mostrarTransfParticipante">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaAlmoxarifado"
                    [(ngModel)]="rowData.empresaAlmoxarifadoDest.id" [filter]="true" [disabled]="requisicaoAlmoxCarregando" (onChange)="popularAlmox(rowData, 2)" [dropdownIcon]="requisicaoAlmoxCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" placeholder="Selecione"
                    panelStyleClass="custom-width">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{rowData.empresaAlmoxarifadoDest.codigo}}" class="overflow-ellipse"
                style="cursor: pointer;">
                {{rowData.empresaAlmoxarifadoDest.codigo}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>


        <td pEditableColumn style="width:10%" *ngIf="mostrarQtde">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="opcoesUnidadeMedida"
                    [(ngModel)]="rowData.unidadeMedida" [filter]="true"  placeholder="Selecione"
                    panelStyleClass="custom-width" (click)="dadosItem(rowData)">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{rowData.unidadeMedida.codigo}}" class="overflow-ellipse"
                style="cursor: pointer;">
                {{rowData.unidadeMedida.codigo}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:5%; text-align:right;" *ngIf="mostrarQtde">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <input class="form-control text-right" type="number" [(ngModel)]="rowData.qtde" />
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="overflow-ellipse text-center" style="cursor: pointer;"></span>
              {{rowData.qtde}}
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:5%; text-align:right;" *ngIf="mostrarValor">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <input class="form-control text-right" [(ngModel)]="rowData.valor" type="number" />
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="overflow-ellipse text-right" style="cursor: pointer;"></span>
              {{rowData.valor}}
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:10%" *ngIf="mostrarTransfParticipante">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaParticipante"
                    [(ngModel)]="rowData.participanteOrig.participanteEmpresaId" [filter]="true" [disabled]="requisicaoPartCarregando" (onChange)="popularPart(rowData, 1)" [dropdownIcon]="requisicaoPartCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" placeholder="Selecione"
                    panelStyleClass="custom-width">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{(rowData.participanteOrig.codigo != undefined) ? (rowData.participanteOrig.codigo + ' - ' + rowData.participanteOrig.fantasia) : ''}}"
                class="overflow-ellipse" style="cursor: pointer; text-align: left">
                {{(rowData.participanteOrig.codigo != undefined) ? (rowData.participanteOrig.codigo + ' - ' + rowData.participanteOrig.fantasia) : ''}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>

        <td pEditableColumn style="width:10%" *ngIf="mostrarTransfParticipante">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="form-row">
                <div class="form-group col-sm-12 mb-0">
                  <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaParticipante"
                    [(ngModel)]="rowData.participanteDest.participanteEmpresaId" [filter]="true" [disabled]="requisicaoPartCarregando" (onChange)="popularPart(rowData, 2)" [dropdownIcon]="requisicaoPartCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" placeholder="Selecione"
                    panelStyleClass="custom-width">
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <span matTooltip="{{(rowData.participanteDest.codigo != undefined) ? (rowData.participanteDest.codigo + ' - ' + rowData.participanteDest.fantasia) : ''}}"
                class="overflow-ellipse" style="cursor: pointer; text-align: left">
                {{(rowData.participanteDest.codigo != undefined) ? (rowData.participanteDest.codigo + ' - ' + rowData.participanteDest.fantasia) : ''}}
              </span>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='this.fecharModalNovo()'><i class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='this.criarMovInt()'><i class="fas fa-check"></i> Salvar</button>
  </p-footer>

</p-dialog>

<p-dialog header="Filtrar Período" [(visible)]="mostrarFiltro" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dataIni" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="dataFin" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
  </div>

  <p-footer>
    <div id="div-botoes">
      <div class="w-100 d-flex align-items-center justify-content-end">
        <button type="button" class="btnCustom-default mr-3" (click)='mostrarModalFiltro(false)'>
          <span class="material-icons">close</span>
          <div>Cancelar</div>
        </button>
        <button type="button" class="btnCustom-green" [disabled]='!dataIni || !dataFin || dataIni > dataFin' (click)='saveFilterModal()'>
          <span class="material-icons">filter_alt</span>
          <div>Filtrar</div>
        </button>
      </div>
    </div>
  </p-footer>

</p-dialog>
