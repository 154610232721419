<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <!-- TABELA VERSÕES -->
  <div class="row">
    <div class="col-sm-12 pb-4">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"><i class="fas fa-file-contract" style="font-size: 15px;"></i> Versões de Orçamento
            </h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel" style="margin-right:-5px;"></i>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHideCard()" (click)="changeState()">
                <span *ngIf="isCardVisible" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardVisible" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>

        <div class="box-content" [@changeDivSize]=currentState>
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" #dt1 [value]="listVersaoOrc" [rows]="rows" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{width:'100%'}" immutable="false"
                selectionMode="single">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="text-align: left; width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'versao'">
                        <span class="text-overflow-dynamic-ellipsis">VERSÃO <p-sortIcon [field]="'versao'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'versao', 'contains')">
                    </th>
                    <th style="text-align: left; width: 20%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'tipo', 'contains')">
                    </th>
                    <th style="text-align: center; width: 5%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE <p-sortIcon [field]="'qtde'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'qtde', 'contains')">
                    </th>
                    <th style="text-align: center; width: 7%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo'">
                        <span class="text-overflow-dynamic-ellipsis">PERÍODO <p-sortIcon [field]="'periodo'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'periodo', 'contains')">
                    </th>
                    <th style="text-align: center; width: 7%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                        <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'status', 'contains')">
                    </th>
                    <th style="text-align: center; width: 5%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">
                          AÇÃO
                        </span>
                      </span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-versao>
                  <tr [pSelectableRow]="versao">
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)="onSelectVersao(versao)">{{versao.versao}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)="onSelectVersao(versao)">{{versao.tipo}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 5%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)="onSelectVersao(versao)">{{versao.qtde}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)="onSelectVersao(versao)">{{versao.periodo}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)="onSelectVersao(versao)">{{versao.status}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 5%;">
                      <span class="edit" style="text-align: center;" matTooltip="Editar status"
                        (click)="openModalEditStatus(versao)">
                        <i class="fas fa-pencil-alt"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="6" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="versaoSelecionada != null">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"><i class="fas fa-project-diagram" style="font-size: 15px;"></i> Gestão de Orçamento
            </h3>
            <div class="actions-card">
              <!--botão de travar gestor, apagar após adc do método certo ou usar esse vazio criado no ts-->
              <button type="button" (click)="lockmanager()" class="btn btn-xs btn-link" matTooltipPosition="above"
                [disabled]="!hasGestorSelected || versaoSelecionada.status !== 'Aberto'" matTooltip="Travar Gestor">
                <span class="material-icons" style="font-size: 23px; margin-right: -7px;">lock</span>
              </button>
              <button type="button" (click)="collapseAll()" class="btn btn-xs btn-link" matTooltipPosition="above"
                [disabled]="btnOptions" matTooltip="Recolher todos">
                <span class="material-icons" style="font-size: 23px; margin-right: -7px;">arrow_upward</span>
              </button>
              <button type="button" (click)="expandAll()" class="btn btn-xs btn-link" matTooltipPosition="above"
                [disabled]="!btnOptions" matTooltip="Expandir todos">
                <span class="material-icons" style="font-size: 23px; margin-right: -7px;">arrow_downward</span>
              </button>
              <button type="button" (click)="removeTreeNode()" class="btn btn-xs btn-link"
                matTooltip="Deletar Registros" matTooltipPosition="above"
                [disabled]="detailSelecionados.length < 1 || versaoSelecionada.status !== 'Aberto'">
                <span class="material-icons" style="font-size: 23px; margin-right: -9px;">delete</span>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHideCardTree()"
                (click)="changeStateTree()">
                <span *ngIf="isTreeVisible" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isTreeVisible" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>

        <div class="box-content" [@changeDivSize]=currentStateTree>
          <div class="row">
            <div class="col-sm-12">
              <p-tree #expandingTree [value]="detailVersao" selectionMode="checkbox" [(selection)]="detailSelecionados"
                (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)"></p-tree>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <p-dialog header="Editar Status" [(visible)]="modalEditStatus" width="300" [modal]="true" styleClass="modal-initial"
    [responsive]="true" appendTo="body" [closable]="true" *ngIf="versaoEdit != null">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Status</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusOrcamento"
          [(ngModel)]="versaoEdit.status_id"></p-dropdown>
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalEditStatus=false; versaoEdit = null;'><i
              class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="editarRegistro();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <!-- FIM TABELA -->
</mat-card>