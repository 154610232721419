<mat-card class="card-linnks margin-linnks" style="margin-top: 15px !important;">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
   <div class="box-content pt-2">
      <div *ngIf="(detalhesPorItem)">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="exportarExcel()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p-table class="table-system" #dt1 [columns]="colsicmsIpisDetalhe" [value]="icmsIpi" [rows]="rows" [paginator]="true" selectionMode="single"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" (onFilter)="onFilter($event)" [scrollable]="true">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtDoc'">
                  <span class="text-overflow-dynamic-ellipsis">DT_DOC <p-sortIcon [field]="'dtDoc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'dtDoc', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtES'">
                  <span class="text-overflow-dynamic-ellipsis">DT_E_S <p-sortIcon [field]="'dtES'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'dtES', 'contains')">
              </th>
              <th class="text-left" style="width: 170px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codSit'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'codSit'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codSit', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codMod'">
                  <span class="text-overflow-dynamic-ellipsis">COD_MOD <p-sortIcon [field]="'codMod'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codMod', 'contains')">
              </th>
              <th class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numDoc'">
                  <span class="text-overflow-dynamic-ellipsis">NUM_DOC <p-sortIcon [field]="'numDoc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'numDoc', 'contains')">
              </th>
              <th class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpjCpf'">
                  <span class="text-overflow-dynamic-ellipsis">CNPJ_CPF <p-sortIcon [field]="'cnpjCpf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cnpjCpf', 'contains')">
              </th>
              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'ie'">
                  <span class="text-overflow-dynamic-ellipsis">IE <p-sortIcon [field]="'ie'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'ie', 'contains')">
              </th>
              <th class="text-left" style="width: 250px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'razao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'razao', 'contains')">
              </th>
              <th class="text-left" style="width: 200px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'regTrib'">
                  <span class="text-overflow-dynamic-ellipsis">REGIME TRIB <p-sortIcon [field]="'regTrib'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'regTrib', 'contains')">
              </th>
              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codItem'">
                  <span class="text-overflow-dynamic-ellipsis">COD_ITEM <p-sortIcon [field]="'codItem'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codItem', 'contains')">
              </th>
              <th class="text-left" style="width: 250px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descItem'">
                  <span class="text-overflow-dynamic-ellipsis">DESCR_ITEM <p-sortIcon [field]="'descItem'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'descItem', 'contains')">
              </th>
              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codNcm'">
                  <span class="text-overflow-dynamic-ellipsis">COD_NCM <p-sortIcon [field]="'codNcm'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'codNcm', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tpItem'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO_ITEM<p-sortIcon [field]="'tpItem'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'tpItem', 'contains')">
              </th>
              <th class="text-center" style="width: 80px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                  <span class="text-overflow-dynamic-ellipsis">UF<p-sortIcon [field]="'uf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'uf', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
                  <span class="text-overflow-dynamic-ellipsis">CFOP<p-sortIcon [field]="'cfop'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cfop', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlOper'">
                  <span class="text-overflow-dynamic-ellipsis">VL_OPR<p-sortIcon [field]="'vlOper'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlOper', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstIcms'">
                  <span class="text-overflow-dynamic-ellipsis">CST ICMS<p-sortIcon [field]="'cstIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cstIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VL_BC_ICMS<p-sortIcon [field]="'vlBcIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIcms'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ_ICMS<p-sortIcon [field]="'aliqIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VL_ICMS<p-sortIcon [field]="'vlIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VL_BC_ICMS_ST<p-sortIcon [field]="'vlBcIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlBcIcmsSt', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VL_ICMS_ST<p-sortIcon [field]="'vlIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIcmsSt', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstIpi'">
                  <span class="text-overflow-dynamic-ellipsis">CST IPI<p-sortIcon [field]="'cstIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'cstIpi', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIpi'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ_IPI<p-sortIcon [field]="'aliqIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'aliqIpi', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIpi'">
                  <span class="text-overflow-dynamic-ellipsis">VL_IPI<p-sortIcon [field]="'vlIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'vlIpi', 'contains')">
              </th>
              <th class="text-center" style="width: 320px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'chvNfe'">
                  <span class="text-overflow-dynamic-ellipsis">CHV_NFE<p-sortIcon [field]="'chvNfe'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'chvNfe', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtDoc}}" >{{rowData.dtDoc }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtES}}" >{{rowData.dtES }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 170px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codSit}}" >{{rowData.codSit }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codMod}}" >{{rowData.codMod }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numDoc}}" >{{rowData.numDoc }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpjCpf}}" >{{rowData.cnpjCpf }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ie}}" >{{rowData.ie }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 250px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao}}" >{{rowData.razao }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 200px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.regTrib}}" >{{rowData.regTrib }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codItem}}" >{{rowData.codItem }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 250px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descItem}}" >{{rowData.descItem }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codNcm}}" >{{rowData.codNcm }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tpItem}}" >{{rowData.tpItem }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 80px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}" >{{rowData.uf }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}" >{{rowData.cfop }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlOper}}" >{{rowData.vlOper }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstIcms}}" >{{rowData.cstIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIcms}}" >{{rowData.vlBcIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIcms}}" >{{rowData.aliqIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIcms | money}}" >{{rowData.vlIcms | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIcmsSt | money}}" >{{rowData.vlBcIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIcmsSt | money}}" >{{rowData.vlIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstIpi}}" >{{rowData.cstIpi}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIpi}}" >{{rowData.aliqIpi}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIpi}}" >{{rowData.vlIpi}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 320px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.chvNfe}}" >{{rowData.chvNfe}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
                <td [attr.colspan]="26" class="spaced-alert" style="width: 3021px">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div *ngIf="!(detalhesPorItem)">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="exportarExcel()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p-table class="table-system" #dt2 [columns]="colsicmsIpis" [value]="icmsIpi" [rows]="rows" [paginator]="true" selectionMode="single"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" (onFilter)="onFilter($event)" [scrollable]="true">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtDoc'">
                  <span class="text-overflow-dynamic-ellipsis">DT_DOC <p-sortIcon [field]="'dtDoc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'dtDoc', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtES'">
                  <span class="text-overflow-dynamic-ellipsis">DT_E_S <p-sortIcon [field]="'dtES'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'dtES', 'contains')">
              </th>
              <th class="text-left" style="width: 170px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codSit'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'codSit'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'codSit', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codMod'">
                  <span class="text-overflow-dynamic-ellipsis">COD_MOD <p-sortIcon [field]="'codMod'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'codMod', 'contains')">
              </th>
              <th class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numDoc'">
                  <span class="text-overflow-dynamic-ellipsis">NUM_DOC <p-sortIcon [field]="'numDoc'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'numDoc', 'contains')">
              </th>
              <th class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpjCpf'">
                  <span class="text-overflow-dynamic-ellipsis">CNPJ_CPF <p-sortIcon [field]="'cnpjCpf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'cnpjCpf', 'contains')">
              </th>
              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'ie'">
                  <span class="text-overflow-dynamic-ellipsis">IE <p-sortIcon [field]="'ie'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'ie', 'contains')">
              </th>
              <th class="text-left" style="width: 250px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'razao'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'razao', 'contains')">
              </th>
              <th class="text-left" style="width: 200px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'regTrib'">
                  <span class="text-overflow-dynamic-ellipsis">REGIME TRIB <p-sortIcon [field]="'regTrib'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'regTrib', 'contains')">
              </th>
              <th class="text-center" style="width: 80px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                  <span class="text-overflow-dynamic-ellipsis">UF<p-sortIcon [field]="'uf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'uf', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
                  <span class="text-overflow-dynamic-ellipsis">CFOP<p-sortIcon [field]="'cfop'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'cfop', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlOper'">
                  <span class="text-overflow-dynamic-ellipsis">VL_OPR<p-sortIcon [field]="'vlOper'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'vlOper', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstIcms'">
                  <span class="text-overflow-dynamic-ellipsis">CST ICMS<p-sortIcon [field]="'cstIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'cstIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VL_BC_ICMS<p-sortIcon [field]="'vlBcIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'vlBcIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIcms'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ_ICMS<p-sortIcon [field]="'aliqIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'aliqIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VL_ICMS<p-sortIcon [field]="'vlIcms'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'vlIcms', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlBcIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VL_BC_ICMS_ST<p-sortIcon [field]="'vlBcIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'vlBcIcmsSt', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VL_ICMS_ST<p-sortIcon [field]="'vlIcmsSt'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'vlIcmsSt', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cstIpi'">
                  <span class="text-overflow-dynamic-ellipsis">CST IPI<p-sortIcon [field]="'cstIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'cstIpi', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIpi'">
                  <span class="text-overflow-dynamic-ellipsis">ALIQ_IPI<p-sortIcon [field]="'aliqIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'aliqIpi', 'contains')">
              </th>
              <th class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIpi'">
                  <span class="text-overflow-dynamic-ellipsis">VL_IPI<p-sortIcon [field]="'vlIpi'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'vlIpi', 'contains')">
              </th>
              <th class="text-center" style="width: 320px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'chvNfe'">
                  <span class="text-overflow-dynamic-ellipsis">CHV_NFE<p-sortIcon [field]="'chvNfe'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'chvNfe', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtDoc}}" >{{rowData.dtDoc }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtES}}" >{{rowData.dtES }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 170px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codSit}}" >{{rowData.codSit }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codMod}}" >{{rowData.codMod }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numDoc}}" >{{rowData.numDoc }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 150px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpjCpf}}" >{{rowData.cnpjCpf }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ie}}" >{{rowData.ie }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 250px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao}}" >{{rowData.razao }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 200px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.regTrib}}" >{{rowData.regTrib }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 80px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}" >{{rowData.uf }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}" >{{rowData.cfop }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlOper}}" >{{rowData.vlOper }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstIcms}}" >{{rowData.cstIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIcms}}" >{{rowData.vlBcIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIcms}}" >{{rowData.aliqIcms }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIcms | money}}" >{{rowData.vlIcms | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlBcIcmsSt | money}}" >{{rowData.vlBcIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIcmsSt | money}}" >{{rowData.vlIcmsSt | money }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cstIpi}}" >{{rowData.cstIpi}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIpi}}" >{{rowData.aliqIpi}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlIpi}}" >{{rowData.vlIpi}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 320px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.chvNfe}}" >{{rowData.chvNfe}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
                <td [attr.colspan]="22" class="spaced-alert" style="width: 3021px">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
