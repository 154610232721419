<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Configurações Período</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStatePeriodo>
            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt1 [columns]="colsListaPeriodoAnos" [value]="listaPeriodoAnos" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false" selectionMode="single" (onRowSelect)="handleSelect()"  (onRowUnselect)="handleSelect()" [(selection)]="selectedPeriods">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ano'">
                      <span class="text-overflow-dynamic-ellipsis">ANO <p-sortIcon [field]="'ano'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'ano', 'contains')">
                  </th>
                  <th class="text-left" style="width: 49%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoCompleta'">
                      <span class="text-overflow-dynamic-ellipsis">PERÍODO <p-sortIcon [field]="'descricaoCompleta'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricaoCompleta', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        (click)="carregarOrcamentoPeriodoMeses(rowData.ano);">{{ rowData.ano}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 49%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        (click)="carregarOrcamentoPeriodoMeses(rowData.ano);">
                        {{rowData.descricaoCompleta}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box" *ngIf="boolExibiMeses">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Visualizações Período</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarView"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStateMeses>
            <!--Tela Meses do período-->
            <p-table class="table-system" #dt2 [columns]="colsListaMeses" [value]="listaPeriodoMeses" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false" selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ano_data'">
                      <span class="text-overflow-dynamic-ellipsis">ANO <p-sortIcon [field]="'ano_data'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'ano_data', 'contains')">
                  </th>
                  <th class="text-left" style="width: 49%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'mes_data'">
                      <span class="text-overflow-dynamic-ellipsis">MESES <p-sortIcon [field]="'mes_data'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'mes_data', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        {{ rowData.ano_data }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 49%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        {{ rowData.mes_data }}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="2" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>


  <!--Modal Período-->
  <p-dialog header="Período" [(visible)]="boolCriarPeriodo" styleClass="modal-initial" width="400" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true">
    <div class="form-row">
      <div class="form-group col-6">
        <label>MÊS INICIAL <span class="obrigatorio">*</span></label>
        <br/>
        <p-calendar [(ngModel)]="initialDate" view="month" dateFormat="mm/yy" [yearNavigator]="true"
          yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker"></p-calendar>
      </div>
      <div class="form-group col-6">
        <label>MÊS FINAL <span class="obrigatorio">*</span></label>
        <br/>
        <p-calendar [(ngModel)]="finalDate" view="month" dateFormat="mm/yy" [yearNavigator]="true" yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker"></p-calendar>
      </div>
    </div>
    <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
      <div class="ui-g-12">
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="cancelar()"><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="criarNovoPeriodo()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
</mat-card>