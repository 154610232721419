<script src="ng-pattern-restrict.js"></script>

<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <mat-card-content>
    <div class="box-content pt-0" *ngIf="showLista">
      <div class="row">

        <div class="col-sm-12">
          <div class="clearfix"></div>

          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarTipoPag"></app-toolbar>
              </div>
            </div>
          </div>
          <div style="margin-top: -11px;">
            <p-table class="table-system" #dt1 [value]="tiposLista" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{ width: '100%' }"
              [(selection)]="tiposPagamentosSelecionados" (onHeaderCheckboxToggle)="changeToolbar()"
              (onRowSelect)="selectRow($event)" (onRowUnselect)="unselectRow($event)">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">ID <p-sortIcon
                          [field]="'id'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'id', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">CÓDIGO <p-sortIcon
                          [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 45%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">DESCRIÇÃO <p-sortIcon
                          [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnab_descricao'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">FORMA BANCÁRIA <p-sortIcon
                          [field]="'cnab_descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'cnab_descricao', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.id }}">{{ rowData.id }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codigo }}">{{ rowData.codigo }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}">{{ rowData.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cnab_descricao }}">{{ rowData.cnab_descricao }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- EDIT | NEW -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <div class="row form-projeto" *ngIf="showEdit || showNew">
    <div class="box-content" style="width: 800px;">
      <div class="header-box">
        <div class="header-box-content p-3">
          <div class="actions-card">
            <app-toolbar [value]="toolbarTipoPag"></app-toolbar>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label>DESCRIÇÃO</label>
          <input class="form-control" type="text" [(ngModel)]="descricao" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label>CODIGO</label>
          <input maxlength="4" class="form-control" type="text" [(ngModel)]="codigo" mask="****" placeholder="COD1" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label>FORMA BANCÁRIA</label>
          <p-dropdown (onChange)="resetFields(false)" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
            [options]="formaCnabList" [(ngModel)]="formaCnab"></p-dropdown>
        </div>
      </div>
      <div class="form-row" *ngIf="formaCnab === 1">
        <div class="form-group col-12">
          <label>Conta Bancária</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesContaBancaria"
            placeholder="Selecione a conta Bancária" [(ngModel)]="contaSelecionadaId"
            (onChange)="selectInstituicaoFinanceira()">
          </p-dropdown>
        </div>
      </div>
      <div class="form-row" *ngIf="formaCnab === 1">
        <div class="form-group col-12">
          <label>CÓDIGO DA CARTEIRA</label>
          <input class="form-control" type="text" [(ngModel)]="codigoCarteira" />
        </div>
      </div>
      <div class="form-row" *ngIf="formaCnab === 1 && instituicaoFinanceira === 55">
        <div class="form-group col">
          <label>Variação Carteira</label>
          <input class="form-control" type="text" [(ngModel)]="variacaoCarteira" />
        </div>
      </div>
      <div class="form-row" *ngIf="formaCnab === 1">
        <div class="form-group col-12">
          <label>INSTRUÇÕES DO BOLETO</label>
          <textarea #descricaoCartao class="form-control" placeholder="EX: - Não protestar"
            [(ngModel)]="instrucaoBoleto">
            </textarea>
        </div>
      </div>
      <div class="form-row" *ngIf="formaCnab === 7 || formaCnab === 1">
        <div class="form-group col-12">
          <label>EXPIRAÇÃO PAGAMENTO (dias)</label>
          <input class="form-control" type="number" [(ngModel)]="diasExpiracao" *ngIf="!expirationDaysOptionsPreDefined"
            [maxlength]="maxExpirationdays" />
          <p-dropdown *ngIf="expirationDaysOptionsPreDefined" appendTo="body" class="type-p-dropdown" [filter]="true"
            [options]="expirationDaysOptions" placeholder="Selecione a quantidade de dias para expiração"
            [(ngModel)]="diasExpiracao">
          </p-dropdown>
        </div>
      </div>

      <div *ngIf="formaCnab === 1 || formaCnab === 7 || formaCnab === 9">
        <div class="header-form mt-3">
          <h6>Configuração de cobranças (OctaCash)</h6>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>Tipo juros </label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="optLevy" [(ngModel)]="tipo_juros" emptyMessage="Nenhum resultado encontrado!">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-6">
            <label>
              Valor
              <span style="line-height: 0;">{{tipo_juros === "PERCENTAGE" ? '(% ao mês)' : '(R$)'}} </span>
            </label>
            <input class="form-control" [(ngModel)]="valor_juros" type="number" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>Tipo multa </label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="optLevy" [(ngModel)]="tipo_multa" emptyMessage="Nenhum resultado encontrado!">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-6">
            <label>
              Valor
              <span style="line-height: 0;">{{tipo_multa === "PERCENTAGE" ? '(%)' : '(R$)'}} </span>
            </label>
            <input class="form-control" [(ngModel)]="valor_multa" type="number" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>
              Limite de dias antes do vencimento
            </label>
            <input class="form-control" [(ngModel)]="qtdeDiasDesconto" type="number" />
          </div>
          <div class="form-group col-sm-4">
            <label>Tipo desconto </label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="optLevy" [(ngModel)]="tipo_desconto" emptyMessage="Nenhum resultado encontrado!">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-4">
            <label>
              Valor
              <span style="line-height: 0;">{{tipo_desconto === "PERCENTAGE" ? '(%)' : '(R$)'}} </span>
            </label>
            <input class="form-control" [(ngModel)]="valor_desconto" type="number" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll" [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

    <div class="form-row" *ngIf="clonagemNaPropriaEmpresa">
      <div class="form-group col-sm-12">
        <label>Novo Código</label>
        <input class="form-control" type="text" [(ngModel)]="novoCodigo" maxlength="4">
      </div>

      <div class="form-group col-sm-12">
        <label>Nova Descrição</label>
        <input class="form-control" type="text" [(ngModel)]="novaDescricao">
      </div>
    </div>

    <div *ngIf="cadastroGrupoEmpresa && !clonagemNaPropriaEmpresa">
      <label >{{ descricaoModalGrupoEmpresas }}</label>
      <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
      </p-multiSelect>
    </div>

    <p-footer class="d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="salvarTipoPagamentoGrupoEmpresa()" *ngIf="!clonagem">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>

      <button class="btnCustom-green" (click)="clonarTipoPagamentoGrupoEmpresa()" *ngIf="clonagem">
        <span class="material-icons">save</span>
        <div>Clonar</div>
      </button>
    </p-footer>
  </p-dialog>
</mat-card>