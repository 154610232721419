<div style="padding: 1.5rem">
  <div class="row">
    <div class="col">
      <ul class="list-inline filter-menu mb-3">
        <li class="list-inline-item" [ngClass]="{active: statusTela1}"><a
            (click)="showTelaDashboard('TELA_1')">Saldos</a></li>
        <li class="list-inline-item" [ngClass]="{active: statusTela2}"><a
            (click)="showTelaDashboard('TELA_2')">Movimentações</a></li>
      </ul>
    </div>
  </div>

  <div class="row" *ngIf="statusTela1">

    <div class="headerOptions col">

      <div class="form-group ">
        <label>Período</label>
        <ul class="list-inline filter-menu">
          <li class="list-inline-item" [ngClass]="{active: statusLink1}"><a
              (click)="showTypeDashboard('LINK_1')">Dia</a>
          </li>
          <li class="list-inline-item" [ngClass]="{active: statusLink2}"><a
              (click)="showTypeDashboard('LINK_2')">Semana</a>
          </li>
          <li class="list-inline-item" [ngClass]="{active: statusLink3}"><a
              (click)="showTypeDashboard('LINK_3')">Mês</a>
          </li>
        </ul>
      </div>

      <div class="d-flex align-items-end ml-3">
        <div class="form-group ">
          <a (click)="dropdownDateEvent()" class="btn btn-outline-secondary dropdown-toggle p-2" href="#" role="button"
            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-calendar"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink"
            [ngStyle]="showDatas ? {'display': 'block'} : {'display': 'none'}">
            <div class="form-group col-sm-12 mb-1">
              <label>DATA INICIAL</label>
              <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="dtInicial1" yearRange="2000:2030" [(ngModel)]="dtInicial1">
              </p-calendar>
            </div>
            <div class="form-group col-sm-12 mb-2">
              <label>DATA FINAL</label>
              <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="dtFinal1" yearRange="2000:2030" [(ngModel)]="dtFinal1">
              </p-calendar>
            </div>
            <div class="form-group col-sm-12 mb-2">
              <button type="button" class="btn btn-info w-100" (click)='atualizarTudo(1);'><i
                  class="fas fa-refresh"></i>
                Atualizar</button>
            </div>
          </div>

        </div>

        <div class="form-group ml-2">
          <label style="margin-left: 1px;">Empresa</label>
          <p-multiSelect class="type-p-mult type-p-mult-clear" [options]="listEmpresas"
            defaultLabel="Selecione uma empresa" [filter]="true" (onChange)="atualizarTudo(3);"
            [(ngModel)]="empresasSelecionadas" [maxSelectedLabels]="1" placeholder="{0} empresas selecionadas"
            selectedItemsLabel="{0} empresas selecionadas">
          </p-multiSelect>
        </div>
      </div>

      <mat-slide-toggle class="slideThickLabel ml-3" [(ngModel)]="baixados" (change)="atualizarTudo(3)">Somente títulos
        baixados
      </mat-slide-toggle>


    </div>

    <div class="col pb-3">
      <div class="row">
        <div class="col-sm-4 pl-0 pr-1 text-center">
          <div class="div-card-total card-total-radius cardValues">
            <p class="titulo-card-total" *ngIf="statusTela1">SALDO INICIAL ANO</p>
            <p class="valor-card-total" *ngIf="statusTela1"
              [ngStyle]="saldoinicialano <= 0 ? {'color': 'darkred'} : {'': ''}">R${{saldoinicialano | number : '1.2-2'
              : 'pt-BR'}}</p>
          </div>
        </div>

        <div class="col-sm-4 pl-0 pr-1 text-center">
          <div class="div-card-total card-total-radius cardValues">
            <p class="titulo-card-total" *ngIf="statusTela1">SALDO FINAL ATUAL</p>
            <p class="valor-card-total" *ngIf="statusTela1"
              [ngStyle]="saldofinalano <= 0 ? {'color': 'darkred'} : {'': ''}">R${{saldofinalano | number : '1.2-2' :
              'pt-BR'}}</p>
          </div>
        </div>

        <div class="col-sm-4 pl-0 text-center">
          <div class="div-card-total card-total-radius cardValues">
            <p class="titulo-card-total" *ngIf="statusTela1">GERAÇÃO DE CAIXA</p>
            <p class="valor-card-total" *ngIf="statusTela1"
              [ngStyle]="saldomesanomov <= 0 ? {'color': 'darkred'} : {'': ''}">R${{saldomesanomov | number : '1.2-2' :
              'pt-BR'}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row" *ngIf="statusTela2">

    <div class="headerOptions col">

      <div class="d-flex align-items-end">

        <div class="form-group">
          <label>Modelo</label>
          <p-dropdown class="type-p-dropdown" [(ngModel)]="idMascara" (onChange)="getMovimentacoes()"
            [options]="listMascaras" [filter]="true" placeholder="Selecionar um modelo"></p-dropdown>
        </div>

        <div class="form-group ml-2">
          <label style="margin-left: 1px;">Empresa</label>
          <p-multiSelect class="type-p-mult type-p-mult-clear" [options]="listEmpresas"
            defaultLabel="Selecione uma empresa" [filter]="true" (onChange)="atualizarTudo(3);"
            [(ngModel)]="empresasSelecionadas" [maxSelectedLabels]="1" placeholder="{0} empresas selecionadas"
            selectedItemsLabel="{0} empresas selecionadas">
          </p-multiSelect>
        </div>

        <div class="form-group ml-2">
          <a (click)="dropdownDateEvent()" class="btn btn-outline-secondary dropdown-toggle p-2" href="#" role="button"
            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-calendar"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink"
            [ngStyle]="showDatas ? {'display': 'block'} : {'display': 'none'}">
            <div class="form-group col-sm-12 mb-1">
              <label>DATA INICIAL</label>
              <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="dtInicial2" yearRange="2000:2030" [(ngModel)]="dtInicial2">
              </p-calendar>
            </div>
            <div class="form-group col-sm-12 mb-2">
              <label>DATA FINAL</label>
              <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="dtFinal2" yearRange="2000:2030" [(ngModel)]="dtFinal2">
              </p-calendar>
            </div>
            <div class="form-group col-sm-12 mb-2">
              <button type="button" class="btn btn-info w-100" (click)='atualizarTudo(2);'><i
                  class="fas fa-refresh"></i>
                Atualizar</button>
            </div>
          </div>
        </div>

      </div>

      <mat-slide-toggle class="slideThickLabel ml-3" [(ngModel)]="baixados" (change)="atualizarTudo(3)">Somente títulos
        baixados
      </mat-slide-toggle>

    </div>

    <div class="col pb-3">
      <div class="row">
        <div class="col-sm-4 pl-0 pr-1 text-center">
          <div class="div-card-total card-total-radius cardValues">
            <p class="titulo-card-total">PAGAMENTO</p>
            <p class="valor-card-total" [ngStyle]="lisaldofinalcptotal <= 0 ? {'color': 'darkred'} : {'': ''}">
              R${{lisaldofinalcptotal | number : '1.2-2' : 'pt-BR'}}</p>
          </div>
        </div>

        <div class="col-sm-4 pl-0 pr-1 text-center">
          <div class="div-card-total card-total-radius cardValues">
            <p class="titulo-card-total">RECEBIMENTO</p>
            <p class="valor-card-total">R${{lisaldofinalcrtotal | number : '1.2-2' : 'pt-BR'}}</p>
          </div>
        </div>

        <div class="col-sm-4 pl-0 text-center">
          <div class="div-card-total card-total-radius cardValues">
            <p class="titulo-card-total">MOVIMENTAÇÃO</p>
            <p class="valor-card-total" [ngStyle]="lisaldomovimentacao <= 0 ? {'color': 'darkred'} : {'': ''}">
              R${{lisaldomovimentacao | number : '1.2-2' : 'pt-BR'}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela1'}">
    <div [ngStyle]="statusLink1 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusLink1'}">
      <div class="row">
        <div class="col-sm-12 pb-4">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content p-3">
                <h3 class="title-card">Saldo em conta diário <i class="lastUpdate">(Última atualização: {{ ultimaAtu |
                    date: 'dd/MM/yyy H:mm' }})</i></h3>
                <div class="actions-card">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
                </div>
              </div>
            </div>
            <div class="box-content" [@changeDivSize]=currentStateTable>
              <div class="table-scroll">
                <table class="main-table">
                  <thead>
                    <tr>
                      <th scope="col">Data</th>
                      <th scope="col" *ngFor="let dia of lidays">{{ dia }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="highlight-line"><strong>Saldo Inicial</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of lisaldoinicialdiatotal">
                        <strong [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}"> {{ total | number : '1.2-2' :
                          'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontasreduced">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of lisaldoinicialdias[diccontas[conta]]"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                    <tr>
                      <th class="highlight-line"><strong>Movimentações</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of limovimentacaodiatotal">
                        <strong [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">{{ total | number : '1.2-2' :
                          'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontasreduced">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of limovimentacaodias[diccontas[conta]]; index as i;"
                        [attr.data-index]="i" (click)="openDetalhes(conta, i, 'DIAS')" style="cursor:pointer"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                    <tr>
                      <th class="highlight-line"><strong>Saldo Final</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of lisaldofinaldiatotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">{{ total | number : '1.2-2' :
                          'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontasreduced">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of lisaldofinaldias[diccontas[conta]]"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngStyle]="statusLink2 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusLink2'}">
      <div class="row">
        <div class="col-sm-12 pb-4">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content p-3">
                <h3 class="title-card">Saldo em conta semanal <i class="lastUpdate">(Última atualização: {{ ultimaAtu |
                    date: 'dd/MM/yyy H:mm' }})</i></h3>
                <div class="actions-card">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
                </div>
              </div>
            </div>
            <div class="box-content" [@changeDivSize]=currentStateTable>
              <div class="table-scroll">
                <table class="main-table">
                  <thead>
                    <tr>
                      <th scope="col">Semana</th>
                      <th scope="col" *ngFor="let semana of liweeks">{{ semana }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="highlight-line"><strong>Saldo Inicial</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of lisaldoinicialsemanatotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong>{{ total | number : '1.2-2' : 'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontasreduced">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of lisaldoinicialsemana[diccontas[conta]]"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                    <tr>
                      <th class="highlight-line"><strong>Movimentações</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of limovimentacaosemanatotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong>{{ total | number : '1.2-2' : 'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontasreduced">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of limovimentacaosemana[diccontas[conta]]; index as i;"
                        [attr.data-index]="i" (click)="openDetalhes(conta, i, 'SEMANAS') " style="cursor:pointer"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                    <tr>
                      <th class="highlight-line"><strong>Saldo Final</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of lisaldofinalsemanatotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong>{{ total | number : '1.2-2' : 'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontasreduced">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of lisaldofinalsemana[diccontas[conta]]"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngStyle]="statusLink3 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusLink3'}">
      <div class="row">
        <div class="col-sm-12 pb-4">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content p-3">
                <h3 class="title-card">Saldo em conta mensal <i class="lastUpdate">(Última atualização: {{ ultimaAtu |
                    date: 'dd/MM/yyy H:mm' }})</i></h3>
                <div class="actions-card">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
                </div>
              </div>
            </div>
            <div class="box-content" [@changeDivSize]=currentStateTable>
              <div class="table-scroll">
                <table class="main-table">
                  <thead>
                    <tr>
                      <th scope="col">Mês/Ano</th>
                      <th scope="col" *ngFor="let mes of limonths">{{ mes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="highlight-line"><strong>Saldo Inicial</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of lisaldoinicialmestotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong>{{ total | number : '1.2-2' : 'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontas">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of lisaldoinicialmes[diccontas[conta]]"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                    <tr>
                      <th class="highlight-line"><strong>Movimentações</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of limovimentacaomestotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong>{{ total | number : '1.2-2' : 'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontas">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of limovimentacaomes[diccontas[conta]]; index as i;"
                        [attr.data-index]="i" (click)="openDetalhes(conta, i, 'MESES')" style="cursor:pointer"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                    <tr>
                      <th class="highlight-line"><strong>Saldo Final</strong></th>
                      <td class="highlight-line text-right" *ngFor="let total of lisaldofinalmestotal"
                        [ngStyle]="total < 0 ? {'color': 'darkred'} : {'': ''}">
                        <strong>{{ total | number : '1.2-2' : 'pt-BR' }}</strong>
                      </td>
                    </tr>
                    <tr *ngFor="let conta of licontas">
                      <th>{{ conta }}</th>
                      <td class="text-right" *ngFor="let val of lisaldofinalmes[diccontas[conta]]"
                        [ngStyle]="val < 0 ? {'color': 'darkred'} : {'': ''}">
                        {{ val | number : '1.2-2' : 'pt-BR' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullAcumulado ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">Saldos Acumulados</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarCharts"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateAcumulado>
            <figure class="highcharts-figure pt-4">
              <highcharts-chart id="saldo_acumulado_chart" style="width: 100%; height: 500px; display: block;">
              </highcharts-chart>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela2'}">
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">Movimentações <i class="lastUpdate">(Última atualização: {{ ultimaAtu | date:
                  'dd/MM/yyy H:mm' }})</i>
              </h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMov"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateMov>
            <p-table class="table-system" #dt [value]="liglobal" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" (onFilter)="onFilter($event)" [style]="
              {'width':'100%'}" selectionMode="single" [lazy]="true" (onLazyLoad)="getMovimentacoes($event)"
              [totalRecords]="totalMov">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 6%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_previsao'">
                      <span class="text-overflow-dynamic-ellipsis">PAGTO PREV. <p-sortIcon [field]="'dt_previsao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dt_previsao_str', 'contains')">
                  </th>
                  <th class="text-center" style="width: 6%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_baixa'">
                      <span class="text-overflow-dynamic-ellipsis">PAGTO EF. <p-sortIcon [field]="'dt_baixa'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dt_baixa_str', 'contains')">
                  </th>
                  <th class="text-center" style="width: 3%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'num_semana'">
                      <span class="text-overflow-dynamic-ellipsis">SEMANA <p-sortIcon [field]="'num_semana'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'num_semana', 'contains')">
                  </th>
                  <th class="text-center" style="width: 4%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_mov'">
                      <span class="text-overflow-dynamic-ellipsis">MOV <p-sortIcon [field]="'tipo_mov'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'tipo_mov', 'contains')">
                  </th>
                  <th class="text-center" style="width: 4%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresa_id'">
                      <span class="text-overflow-dynamic-ellipsis">EMP. ID <p-sortIcon [field]="'empresa_id'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'empresa_id', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'grupo'">
                      <span class="text-overflow-dynamic-ellipsis">GRUPO <p-sortIcon [field]="'grupo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'grupo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 11%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao_fin'">
                      <span class="text-overflow-dynamic-ellipsis">OPER. <p-sortIcon [field]="'operacao_fin'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'operacao_fin', 'contains')">

                  </th>
                  <th class="text-left" style="width: 11%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">FANTASIA <p-sortIcon [field]="'fantasia'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'fantasia', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'documento'">
                      <span class="text-overflow-dynamic-ellipsis">NUM. DOC. <p-sortIcon [field]="'documento'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'documento', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo_pedido'">
                      <span class="text-overflow-dynamic-ellipsis">COD. PEDIDO <p-sortIcon [field]="'codigo_pedido'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo_pedido', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo_controle'">
                      <span class="text-overflow-dynamic-ellipsis">COD. CONTROLE <p-sortIcon
                          [field]="'codigo_controle'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo_controle', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresa_conta'">
                      <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'empresa_conta'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'empresa_conta', 'contains')">
                  </th>
                  <th class="text-right" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor', 'contains')">
                  </th>
                  <th class="text-center" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'status', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'comentarios'">
                      <span class="text-overflow-dynamic-ellipsis">COMENTÁRIOS <p-sortIcon [field]="'comentarios'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'comentarios', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dt_previsao | date:'dd/MM/yyyy'}}">{{rowData.dt_previsao |
                        date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dt_baixa | date:'dd/MM/yyyy'}}">{{rowData.dt_baixa |
                        date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.num_semana}}">{{rowData.num_semana}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo_mov}}">{{rowData.tipo_mov
                        }}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.empresa_id}}">{{rowData.empresa_id }}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.grupo}}">{{rowData.grupo}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.operacao_fin}}">{{rowData.operacao_fin}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.documento}}">{{rowData.documento}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigo_pedido}}">{{rowData.codigo_pedido}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigo_controle}}">{{rowData.codigo_controle}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.empresa_conta}}">{{rowData.empresa_conta}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        [ngStyle]="rowData.valor < 0 ? {'color': 'darkred'} : {'': ''}"
                        matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">
                        <span *ngIf="rowData.comentarios === ''" class="add-text text-left overflow-ellipse"
                          style="max-width: 100%;"><button type="button" class="btn btn-xs btn-link"
                            style="padding: 0 !important; font-weight: bold; text-transform: none; font-size: 11px !important; line-height: 0;"
                            (click)="onRowSelect(rowData)">Adicionar comentário</button></span>
                        <span class="overflow-ellipse pull-left" style="max-width: 90%;"
                          [matTooltip]="getStringFromHtml(rowData.comentarios)"
                          [innerHtml]="rowData.comentarios | safeHtml"></span>
                        <span *ngIf="rowData.comentarios != ''" class="edit-line pull-left" style="max-width: 10%;"
                          matTooltip="Editar"><i class="fas fa-pencil-alt" style="padding-left: 3px;"
                            (click)="onRowSelect(rowData)"></i></span>
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td class="text-right" style="font-weight: bold;" colspan="12">Total:</td>
                  <td class="text-right" matTooltip="{{calcFooterTotal()  | number : '1.2-2' : 'pt-BR'}}"
                    style="font-weight: bold; text-align: right;">{{calcFooterTotal() | number : '1.2-2' :
                    'pt-BR'}}</td>
                  <td class="text-right" style="text-align: right;" colspan="3"></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="16" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- DETALHE MOVIMENTACOES-->

<p-dialog header="Detalhe Movimentações" [(visible)]="modalDetalheMov" [style]="{width: '1200px'}" [modal]="true"
  styleClass="modal-scroll modal-custom" [responsive]="true" [contentStyle]="{minHeight: '450px'}" appendTo="body"
  [closable]="true" [draggable]="false">
  <div class="row">
    <div class="pull-right align-down-table">
      <button type="button" class="btn btn-link" style="margin-left: 995px;" (click)="exportarDetalhe()"><i
          class="fas fa-file-excel excel"></i> Exportar para Excel</button>
    </div>
    <div class="col-sm-12">
      <p-table class="table-system" #mv [value]="movTable" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" selectionMode="single" [lazy]="true"
        (onLazyLoad)="onFlowCashSelect($event)" [totalRecords]="totalDetalhe">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vencimento'">
                <span class="text-overflow-dynamic-ellipsis">VENCTO/BAIXA <p-sortIcon [field]="'dt_vencimento'">
                  </p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'dt_vencimento_str', 'contains')">
            </th>
            <th class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_previsao'">
                <span class="text-overflow-dynamic-ellipsis">PREVISÃO <p-sortIcon [field]="'dt_previsao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'dt_previsao_str', 'contains')">
            </th>
            <th class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_emissao'">
                <span class="text-overflow-dynamic-ellipsis">EMISSÃO <p-sortIcon [field]="'dt_emissao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'dt_emissao_str', 'contains')">
            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao_fin'">
                <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao_fin'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'operacao_fin', 'contains')">
            </th>
            <th class="text-left" style="width: 16%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'historico', 'contains')">
            </th>
            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'documento'">
                <span class="text-overflow-dynamic-ellipsis">DOC <p-sortIcon [field]="'documento'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'documento', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                <span class="text-overflow-dynamic-ellipsis">PARC <p-sortIcon [field]="'parcela'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'parcela', 'contains')">
            </th>
            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'valor', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="mv.filter($event.target.value, 'status', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.dt_vencimento_str }}">{{
                  rowData.dt_vencimento_str }}</span>
              </span>
            </td>
            <td class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_previsao_str }}">{{rowData.dt_previsao_str
                  }}</span>
              </span>
            </td>
            <td class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_emissao_str }}">{{rowData.dt_emissao_str
                  }}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.nome }}">{{ rowData.nome }}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.operacao_fin }}">{{
                  rowData.operacao_fin
                  }}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.historico }}">{{ rowData.historico
                  }}</span>
              </span>
            </td>
            <td class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.documento }}">{{ rowData.documento
                  }}</span>
              </span>
            </td>
            <td class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.parcela }}">{{ rowData.parcela
                  }}</span>
              </span>
            </td>
            <td class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                  'pt-BR'}}</span>
              </span>
            </td>
            <td class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.status }}">{{ rowData.status
                  }}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="10" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalDetalheMov = false'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>

<!-- COMENTÁRIOS -->
<p-dialog header="Comentários" [(visible)]="modalComentarios" [style]="{width: '600px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-bars"></i> Comentário <button type="button"
          class="btn btn-xs btn-info pull-right" style="margin-top: -3px;" matTooltip="Adicionar comentário"
          matTooltipPosition="left" (click)="toggleAddComentario()"><i class="fas fa-plus"></i> Adicionar</button></h3>
      <div *ngIf="criacaoComentario || edicaoComentario">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <p-editor [(ngModel)]="comentario" [style]="{'min-height':'100px'}">
            </p-editor>
            <button type="button" class="btn btn-xs btn-primary" *ngIf="criacaoComentario"
              (click)="criarComentario()"><i class="fas fa-save"></i> Gravar</button>
            <button type="button" class="btn btn-xs btn-primary" *ngIf="edicaoComentario"
              (click)="editarComentario()"><i class="fas fa-check"></i> Salvar</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <ng-container>
            <div class="height-activity">
              <div *ngFor="let c of rowComments">
                <div class="spaced-activity">
                  <div class="by-date">
                    <span class="user"><i class="far fa-user"></i> {{ c.full_name }}</span><span class="date-time"><i
                        class="far fa-calendar-alt"></i> {{ c.change_timestamp | date: 'dd/MM/yyy H:mm' }} </span>
                    <div class="actions">
                      <span class="edit" matTooltip="Editar comentário" matTooltipPosition="above"><i
                          class="fas fa-pencil-alt" (click)="toggleEditComentario(c)"></i></span>
                      <span class="delete" matTooltip="Excluir comentário" matTooltipPosition="above"><i
                          class="far fa-trash-alt" (click)="deleteComment(c)"></i></span>
                    </div>
                  </div>
                  <div class='box-activity' style="min-height: 74px;">
                    <p class="description" [innerHtml]="c.descricao | safeHtml"></p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalComentarios = false;'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>