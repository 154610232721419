<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div class="box-content pt-2" *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content" style="padding: 1.5rem !important;">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registrosEmpresas" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false" (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)"
              selectionMode="multiple">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'razaoSocial'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Razão <p-sortIcon [field]="'razaoSocial'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'razaoSocial', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Fantasia <p-sortIcon [field]="'fantasia'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nomeFantasia', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpnj'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CNPJ<p-sortIcon [field]="'cpnj'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'cpnj', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                      <span class="text-overflow-dynamic-ellipsis">
                        UF <p-sortIcon [field]="'uf'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'ufSigla', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'municipio'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Município <p-sortIcon [field]="'municipio'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'municipioIbge.nome', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" [id]="'checkboxContainer'+rowData.id" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razaoSocial}}">
                        {{rowData.razaoSocial}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeFantasia}}">
                        {{rowData.nomeFantasia}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj}}">
                        {{rowData.cnpj}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="">
                        id: {{rowData.id}}ta
                        {{rowData.ufSigla}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.municipioIbge?.nome}}">
                        {{rowData.municipioIbge?.nome}}
                      </span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content" style="padding: 1.5rem !important;">
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content">
          <div class="form-row">

            <div class="form-group col-sm-12">
              <label>Razão<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="razao">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Fantasia<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="fantasia">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>CNPJ<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="cnpj">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Telefone</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="telefone">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>UF</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="ufs" [(ngModel)]="selectedUf"
                optionLabel="uf" (onChange)="loadMunicipio()" optionValue="id"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Município</label>
              <p-dropdown class="type-p-dropdown" [placeholder]="statusMunicipio" [options]="municipios"
                [(ngModel)]="selectedMunicipio" optionLabel="nome" optionValue="id"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="verificaConciliacao">Verifica Conciliação</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Número Inicial NF</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="numeroInicialNf">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Série NF</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="serieNf">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Número NFS</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="numeroNfs">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Série NFS</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="serieNfs">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>RPS</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="rps">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>CNAE</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="cnae">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Indicador da Incidência Tributária</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="indicadorIncidenciaTributaria"
                [(ngModel)]="selectedIndicadorIncidenciaTributaria" optionLabel="descricaoCompleta" optionValue="id">
              </p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Indicador de Método de Apropriação de Créditos</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="indicadorApropriacaoCredito"
                [(ngModel)]="selectedIndicadorApropriacaoCredito" optionLabel="descricaoCompleta" optionValue="id">
              </p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Código Indicador do Tipo de Contribuição Apurada do Período</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="codigoTipoContribuicaoApurada"
                [(ngModel)]="selectedCodigoTipoContribuicaoApurada" optionLabel="descricaoCompleta" optionValue="id">
              </p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Indicador de Regime Cumulativo</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="indicadorRegimeCumulativo"
                [(ngModel)]="selectedIndicadorRegimeCumulativo" optionLabel="descricaoCompleta" optionValue="id">
              </p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Mês Inicio</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="mes" [(ngModel)]="selectedMes"
                optionLabel="label" optionValue="value"></p-dropdown>
            </div>
          </div>

          <!-- <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Projeto para aprovações</label>
              <p-dropdown class="type-p-dropdown" placeholder="Selecione o projeto" [options]="listaTodoProjetos" [(ngModel)]="todoProjetoId"></p-dropdown>
            </div>
          </div> -->

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Destinatários Vinc. XML</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="destinatariosVincXml">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="permiteEstoqueNegativo">Permite Estoque Negativo</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="centroCustoObrigatorio">Centro de Custo Obrigatório</mat-slide-toggle>
            </div>
          </div>

          <!-- <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="centroCustoRMSObrigatorio">Centro de Custo Obrigatório no RMS
              </mat-slide-toggle>
            </div>
          </div> -->

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="vinculaItemOperacao">Vincula Item Com Operação</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="calendarioAutomatico">Calendário Automático</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <!-- <label>Grupo de Estoque Obrigatório</label> -->
              <mat-slide-toggle [(ngModel)]="grupoEstoqueObrigatorio">Grupo de Estoque Obrigatório</mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <!-- <label>Benefício Obrigatório na RMS</label> -->
              <mat-slide-toggle [(ngModel)]="beneficioObrigatorioRms">Benefício Obrigatório RMS</mat-slide-toggle>
              <!-- **fazer checkbox -->
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <!-- <label>Segmento Obrigatório</label> -->
              <mat-slide-toggle [(ngModel)]="segmentoObrigatorio">Segmento Obrigatório</mat-slide-toggle>
              <!-- **fazer checkbox -->
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <!-- <label>Segmento Obrigatório</label> -->
              <mat-slide-toggle [(ngModel)]="faturaProdutoAutomatico">Emite Pedido Produto Automático</mat-slide-toggle>
              <!-- **fazer checkbox -->
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <!-- <label>Segmento Obrigatório</label> -->
              <mat-slide-toggle [(ngModel)]="visualizaFiliais">Visualizar Filiais</mat-slide-toggle>
              <!-- **fazer checkbox -->
            </div>
          </div>


          <div class="form-row" *ngIf="editarConta">
            <div class="form-group col-sm-12">
              <mat-slide-toggle [(ngModel)]="cadastroGrupoEmpresa">Habilitar cadastro no grupo de empresa</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoProdutoServico">Permitir a modificação de produto e serviço para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoParticipante">Permitir a modificação de participante para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoContaContabil">Permitir a modificação de conta contábil para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoRegraContabil">Permitir a modificação de regra contábil para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoRegraFiscal">Permitir a modificação de regra fiscal para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoOperacaoRegra">Permitir a modificação de operação regra para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <mat-slide-toggle [(ngModel)]="permiteAlteracaoFormaPagamento">Permitir a modificação de forma de pagamento para as empresas do grupo</mat-slide-toggle>
            </div>

            <div class="form-group col-sm-12" *ngIf="editarConta && cadastroGrupoEmpresa">
              <label>Empresas do grupo</label>

              <p-multiSelect class="type-p-mult" appendTo="body"
                [filter]="true" [placeholder]="placeholderEmpresas" [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
              </p-multiSelect>
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>