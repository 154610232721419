<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-11">
    </div>
  </div>
  <mat-card-content>
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title mb-4"><i class="fas fa-file-download"> </i> Exportar ECD</h3>
        </div>
        <div class="toolbar">
          <app-toolbar [value]="toolbarMain"></app-toolbar>
        </div>
      </div>
      <p-table class="table-system" [value]="ecdList" [style]="{'width':'100%'}" immutable="false">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">DATA INICIAL</span>
              </span>
            </th>
            <th class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">DATA FINAL</span>
              </span>
            </th>
            <th *ngIf="ecdList && ecdList.length > 0" class="text-center" style="width: 110px; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">PROCESSANDO</span>
              </span>
            </th>
            <th *ngIf="ecdList && ecdList.length > 0" class="text-center" style="width: 110px; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">APURADO</span>
              </span>
              <p-checkbox #mdCheckAllApurado [disabled]="allApuracaoDisabled"
                (click)="onSelectAllStatus(mdCheckAllApurado.checked, 2)" [binary]="true"
                ></p-checkbox>
            </th>
            <th *ngIf="ecdList && ecdList.length > 0 && hasApurado" class="text-center"
              style="width: 110px; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">HOMOLOGADO</span>
              </span>
              <p-checkbox #mdCheckAllHomologado [disabled]="allHomologacaoDisabled"
                (click)="onSelectAllStatus(mdCheckAllHomologado.checked, 3)" [binary]="true"
                ></p-checkbox>
            </th>
            <th *ngIf="ecdList && ecdList.length > 0 && hasApurado" class="text-center"
              style="width: 110px; vertical-align: sub;">
              USUÁRIO
            </th>
            <th *ngIf="ecdList && ecdList.length > 0 && hasApurado" class="text-center"
              style="width: 110px; vertical-align: sub;">
              DATA ENCERRAMENTO
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{ rowData.dtIni | timezone | date:'dd/MM/yyyy' }}">{{ rowData.dtIni | timezone |
                  date:'dd/MM/yyyy' }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip=" {{ rowData.dtFin | timezone | date:'dd/MM/yyyy' }}">{{ rowData.dtFin | timezone |
                  date:'dd/MM/yyyy' }}</span>
              </span>
            </td>
            <td *ngIf="ecdList && ecdList.length > 0" class="text-center" style="width: 110px;">
              <p-checkbox class="mr-1"
                [disabled]="true"
                [(ngModel)]="rowData.processando"
                (click)="onClick(rowData, 4, rowData.homologado)"
                (keyup)="onClick(rowData, 4, rowData.homologado, $event)"
                [binary]="true"
                inputId="processando">
              </p-checkbox>
          </td>
            <td *ngIf="ecdList && ecdList.length > 0" class="text-center" style="width: 110px;">
                <p-checkbox class="mr-1"
                  [disabled]="rowData.homologado || rowData.processando"
                  [(ngModel)]="rowData.apurado"
                  (click)="onClick(rowData, 4, rowData.homologado)"
                  (keyup)="onClick(rowData, 4, rowData.homologado, $event)"
                  [binary]="true"
                  inputId="apurado">
                </p-checkbox>
            </td>

            <td *ngIf="ecdList && ecdList.length > 0 && hasApurado" class="text-center" style="width: 110px;">
                <p-checkbox class="mr-1"
                  [disabled]="!rowData.apurado"
                  [(ngModel)]="rowData.homologado"
                  (click)="onClick(rowData, 3, !rowData.apurado)"
                  (keyup)="onClick(rowData, 3, !rowData.apurado, $event)"
                  [binary]="true"
                  inputId="homologado">
                </p-checkbox>

            </td>
            <td *ngIf="ecdList && ecdList.length > 0 && hasApurado" class="text-center" style="width: 110px;">
              {{rowData?.apuracaoUser?.fullName || ''}}
            </td>
            <td class="text-center" style="width: 45%;" *ngIf="ecdList && ecdList.length > 0 && hasApurado">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{ rowData.apuracaoDatetime | timezone | date:'dd/MM/yyyy HH:mm' }}">{{ rowData.apuracaoDatetime | timezone |
                  date:'dd/MM/yyyy HH:mm' }}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="2" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum período registrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>

  <p-dialog header="Datas dos novos registros" [(visible)]="modalEcd" width="600" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label class="d-block">Mês Inicial</label>
            <p-dropdown class="type-p-dropdown" [(ngModel)]="mesIni" [options]="meses" defaultLabel="Selecione"
              [filter]="true"></p-dropdown>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block">Mês Final</label>
            <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
              [filter]="true"></p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalEcd = false;"><i
          class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="inserirNovoEcd()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>

    <div class="ui-g" style="position: relative; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12" style="text-transform: initial;">
        <p-messages life="5000" [value]="msgsModal" (click)="hideMessage()"></p-messages>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="modalLog" appendTo="body" width="600" styleClass="modal-initial" [responsive]="true" [closable]="true">
    <div class="row">
        <div class="col-sm-12">
          <ul class="list-group list-group-flush list-log">
            <li class="list-group-item" *ngFor="let log of logAdvertencia">
                <i class="fas fa-exclamation"></i> {{log.detail }}
            </li>
          </ul>
        </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalLog=false'><i class="fas fa-undo"></i> Fechar</button>
    </p-footer>
  </p-dialog>
</mat-card>
