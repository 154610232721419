<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px; z-index: 10;">
    <div class="ui-g-12">

    </div>
  </div>


  <div class="shadow-box">
    <div class="header-box">
      <div class="header-box-content p-3">
        <h3 class="title-card">Configurações</h3>
        <div class="actions-card">
          <app-toolbar [value]="toolbarMain"></app-toolbar>
        </div>
      </div>
    </div>
    <div class="box-content" [@changeDivSize]=currentStateDash>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label for="inputgerencial">GERENCIAL</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="planosGerenciais"
          placeholder="Selecione o Plano Gerencial" [(ngModel)]="gerencialId" name="inputgerencial"
          (onChange)="buscarContasGerencial(gerencialId)">
        </p-dropdown>
        </div>
        <div class="form-group col-sm-2">
          <label for="inputvisualizacao">VISUALIZAÇÃO</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposVisualizacao"
          placeholder="Selecione a visualização Padrão" [(ngModel)]="visualizacaoId" name="inputvisualizacao">
        </p-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-bars"></i> SELEÇÃO CONTAS GERENCIAIS</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-2">
          <label>NOME BOX1</label>
          <input class="form-control" type="text" pInputText [(ngModel)]="box1" maxlength="50" />
        </div>
        <div class="form-group col-sm-2">
          <label>NOME BOX2</label>
          <input class="form-control" type="text" pInputText [(ngModel)]="box2" maxlength="50" />
        </div>
        <div class="form-group col-sm-2">
          <label>NOME BOX3</label>
          <input class="form-control" type="text" pInputText [(ngModel)]="box3" maxlength="50" />
        </div>
        <div class="form-group col-sm-2">
          <label>NOME BOX4</label>
          <input class="form-control" type="text" pInputText [(ngModel)]="box4" maxlength="50" />
        </div>
        <div class="form-group col-sm-2">
          <label>NOME BOX5</label>
          <input class="form-control" type="text" pInputText [(ngModel)]="box5" maxlength="50" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-2">
          <label for="inputbox1">BOX1</label>
          <p-multiSelect class="type-p-mult" placeholder="{0} contas selecionadas" selectedItemsLabel="{0} contas selecionadas" [options]="opcoesListBox1" [(ngModel)]="listBox1" defaultLabel="Selecione" [filter]="true"></p-multiSelect>
          <mat-slide-toggle [(ngModel)]="box1ShowAbsolute">Valor absoluto </mat-slide-toggle>
        </div>
        <div class="form-group col-sm-2">
          <label for="inputbox2">BOX2</label>
          <p-multiSelect class="type-p-mult" placeholder="{0} contas selecionadas" selectedItemsLabel="{0} contas selecionadas" [options]="opcoesListBox2" [(ngModel)]="listBox2" defaultLabel="Selecione" [filter]="true"></p-multiSelect>
          <mat-slide-toggle class="mr-2" [(ngModel)]="box2ShowAbsolute">Valor absoluto </mat-slide-toggle>
        </div>
        <div class="form-group col-sm-2">
          <label for="inputbox3">BOX3</label>
          <p-multiSelect class="type-p-mult" placeholder="{0} contas selecionadas" selectedItemsLabel="{0} contas selecionadas" [options]="opcoesListBox3" [(ngModel)]="listBox3" defaultLabel="Selecione" [filter]="true"></p-multiSelect>
          <mat-slide-toggle class="mr-2" [(ngModel)]="box3ShowAbsolute">Valor absoluto </mat-slide-toggle>
        </div>
        <div class="form-group col-sm-2">
          <label for="inputbox4">BOX4</label>
          <p-multiSelect class="type-p-mult" placeholder="{0} contas selecionadas" selectedItemsLabel="{0} contas selecionadas" [options]="opcoesListBox4" [(ngModel)]="listBox4" defaultLabel="Selecione" [filter]="true"></p-multiSelect>
          <mat-slide-toggle class="mr-2" [(ngModel)]="box4ShowAbsolute">Valor absoluto </mat-slide-toggle>
        </div>
        <div class="form-group col-sm-2">
          <label for="inputbox5">BOX5</label>
          <p-multiSelect class="type-p-mult" placeholder="{0} contas selecionadas" selectedItemsLabel="{0} contas selecionadas" [options]="opcoesListBox5" [(ngModel)]="listBox5" defaultLabel="Selecione" [filter]="true"></p-multiSelect>
          <mat-slide-toggle class="mr-2" [(ngModel)]="box5ShowAbsolute">Valor absoluto </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 pt-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <ul class="list-inline filter-menu filter-card-header mt-0">
              <li class="list-inline-item" [ngClass]="{active: statusCambio}"><a (click)="showTelaCard('TELA_1')">Operações Câmbio</a></li>
              <li class="list-inline-item" [ngClass]="{active: statusFolha}"><a (click)="showTelaCard('TELA_2')">Operações Folha de Pagamento</a></li>
            </ul>
            <div class="actions-card">
              <app-toolbar [value]="toolbarDetails"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateOperacoes>
          <div [ngStyle]="statusCambio ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusCambio'}">
            <div class="row">
              <div class="col-sm-8">
                <div class="conteudo m-0">
                  <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Relacionados" [source]="listaOperacoes"
                    [target]=" listaOperacoesRelacionadas " [dragdrop]="true" [responsive]="true" filterBy="descricao"
                    [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-item pTemplate="item">{{ item.descricao }}</ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
          </div>
          <div [ngStyle]="statusFolha ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusFolha'}">
            <div class="row">
              <div class="col-sm-8">
                <div class="conteudo m-0">
                  <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Relacionados" [source]="listaOperacoes"
                    [target]=" listaOperacoesFolhaRelacionadas " [dragdrop]="true" [responsive]="true" filterBy="descricao"
                    [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-item pTemplate="item">{{ item.descricao }}</ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
