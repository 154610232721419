<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Taxa Moeda</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
                <!-- <button type="button" (click)="showModalNovaTaxa()" class="btn btn-xs btn-link"
                  matTooltip="Adicionar Taxa" matTooltipPosition="above" [disabled]="listaIdsSelecionados.length">
                  <span class="material-icons" style="font-size: 28px; margin-right: -5px;">add</span>
                </button>
                <button type="button" (click)="carregarTaxa()" class="btn btn-xs btn-link" matTooltip="Editar Taxa"
                  matTooltipPosition="above"
                  [disabled]="listaIdsSelecionados.length > 1 || listaIdsSelecionados.length <= 0">
                  <span class="material-icons" style="font-size: 24px; margin-right: -5px;">edit</span>
                </button>
                <button type="button" (click)="excluirTaxa()" class="btn btn-xs btn-link" matTooltip="Remover Taxa"
                  matTooltipPosition="above" [disabled]="!listaIdsSelecionados.length">
                  <span class="material-icons" style="font-size: 23px; margin-right: 0px;">delete</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideMoeda()"
                  (click)="changeStateMoeda()">
                  <span *ngIf="isCardMoeda" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardMoeda" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button> -->
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateMoeda>
            <div class="row">
              <div class="col-sm-12">

                <p-table [(selection)]="selectedRates" class="table-system" #dt1 [columns]="colslistalistaTaxas" [value]="listaTaxas" [rows]="rows"
                  [paginator]="true" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="single" [style]="{'width':'100%'}" [scrollable]="false" scrollHeight="20vh" (onRowSelect)="handleSelect()"  (onRowUnselect)="handleSelect()">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center tableHeaderCheckbox">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox (click)="selectTodos()"></p-tableHeaderCheckbox>
                        </span>
                      </th>
                      <th class="text-left" style="width: 33%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_moeda'">
                          <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'descricao_moeda'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'descricao_moeda', 'contains')">
                      </th>
                      <th class="text-left" style="width: 33%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'taxa'">
                          <span class="text-overflow-dynamic-ellipsis">TAXA <p-sortIcon [field]="'taxa'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'taxa', 'contains')">
                      </th>
                      <th class="text-left" style="width: 33%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ano'">
                          <span class="text-overflow-dynamic-ellipsis">ANO<p-sortIcon [field]="'ano'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'ano', 'contains')">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center tableCheckbox">
                        <!-- (click)="selectCheckbox(rowData.id, rowData)" -->
                        <p-tableCheckbox [value]="rowData">
                        </p-tableCheckbox>
                      </td>
                      <td class="text-left" style="width: 33%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{ rowData.simbolo }} - {{ rowData.descricao_moeda }}">
                            {{ rowData.simbolo }} - {{ rowData.descricao_moeda }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 33%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.taxa | money}}">{{rowData.taxa | money}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 33%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.periodo_mes }}" money>
                            {{ rowData.periodo_mes }}/{{ rowData.periodo_ano}} - ({{ rowData.ano }})
                          </span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>

  <!--Modal Taxa Moeda-->
  <p-dialog header="Taxa Moeda" [(visible)]="modalState" styleClass="modal-scroll"
    width="400" [modal]="true" [responsive]="true" appendTo="body" [closable]="true" (onHide)="closeDialog()">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>MOEDA <span class="obrigatorio" *ngIf="boolCriarPeriodoTaxa">*</span></label>
        <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
          [options]="OpcoesMoeda" [(ngModel)]="idMoeda">
        </p-dropdown>
      </div>

      <div class="form-group col-sm-6">
        <label for="inputDescricao">TAXA <span class="obrigatorio" *ngIf="boolCriarPeriodoTaxa">*</span></label>
        <input class="form-control text-left" value="{{ periodoTaxa.taxa }}" [(ngModel)]="periodoTaxa.taxa"
          name="inputTaxa" maxlength="11">
      </div>

      <div *ngIf="boolCriarPeriodoTaxa" class="col-sm-12">
        <p-multiSelect class="type-p-mult" [options]="meses" [(ngModel)]="selectedPeriodos"
          defaultLabel="Selecionar meses" [filter]="true" placeholder="{0} meses selecionados"
          selectedItemsLabel="{0} meses selecionados" appendTo="body" optionLabel="dataFormatada" optionDisabled="disabled"></p-multiSelect>
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="cancelar()"><i class="fas fa-undo"></i> Cancelar</button>
      <button *ngIf="boolCriarPeriodoTaxa && !boolEditarPeriodoTaxa" type="button" class="btn btn-primary"
        (click)="criarNovaTaxa()"><i class="fas fa-check"></i> Salvar</button>
      <button *ngIf="!boolCriarPeriodoTaxa && boolEditarPeriodoTaxa" type="button" class="btn btn-primary"
        (click)="editarTaxa()"><i class="fas fa-check"></i> Salvar</button>
    </p-footer>
  </p-dialog>
</mat-card>