<h3 *ngIf="!loading && !hasEmpresaSelected">Para prosseguir, selecione uma empresa no menu superior</h3>

<app-data-table mainTitle="Item Grupo Tributação" *ngIf="hasEmpresaSelected"
                singleTitle="Item Grupo Tributação"
                routerPath="/item-grupo-tributacao"
                entityPath="/itemgrupotributacao"
                keyField="id"
                [entity]="itemGrupoTributacao"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
