<p-menubar [model]="items"></p-menubar>
<mat-card class="principal">
        <mat-card>
                        <header class="clearfix">
                                        <div id="logo">

                                        </div>
                                        <h1>101020101 - CAIXA</h1>
                                        <div id="company" class="clearfix">
                                          <div>SANCHEZ CANO LTDA</div>
                                          <div>AVENIDA JOSE BENASSI,1003<br /> PARQUE INDUSTRIAL I - JUNDIAÍ - SP</div>
                                          <div>(11) 4589-6450</div>
                                        </div>
                                        <div id="project">
                                          <div><span>STATUS</span> Homologado</div>
                                          <div><span>FORMALIZADO</span> Suellen Daher - 30/01/2017</div>
                                          <div><span>E-MAIL</span>suellen.daher@finiguloseimas.com.br</div>
                                          <div><span>HOMOLOGADO</span>José Roberto - 31/01/2017</div>
                                          <div><span>E-MAIL</span>josé.roberto@dcompany.com.br</div>
                                        </div>
                                      </header>
                                      <main>
                                        <table>
                                          <thead>
                                            <tr>
                                              <th class="service">DATA</th>
                                              <th class="desc">DESCRIÇÃO</th>
                                              <th></th>
                                              <th></th>
                                              <th>TOTAL</th>
                                              <th class="unit">EDITAR</th>
                                              <th class="unit">EXCLUIR</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td class="service">29/01/2017</td>
                                              <td class="desc">Composição do Financeiro</td>
                                              <td class="unit"></td>
                                              <td class="qty"></td>
                                              <td class="total">30.123,50</td>
                                              <td class="unit"><button pButton type="button" class="ui-button-info" icon="fa-edit"></button></td>
                                              <td class="unit"><button pButton type="button" class="ui-button-danger" icon="fa-trash"></button></td>
                                            </tr>
                                            <tr>
                                              <td class="service">30/01/2017</td>
                                              <td class="desc">Título 1234 a ser regularizado</td>
                                              <td class="unit"></td>
                                              <td class="qty"></td>
                                              <td class="total">1.200,00</td>
                                              <td class="unit"><button pButton type="button" class="ui-button-info" icon="fa-edit"></button></td>
                                              <td class="unit"><button pButton type="button" class="ui-button-danger" icon="fa-trash"></button></td>
                                            </tr>
                                            <tr>
                                              <td colspan="4" class="total">TOTAL FINANCEIRO</td>
                                              <td class="total">31.323,50</td>
                                              <td></td>
                                              <td></td>

                                            </tr>
                                            <tr>
                                              <td colspan="4" class="total">VALOR CONTÁBIL</td>
                                              <td class="total">31.323,50</td>
                                              <td></td>
                                              <td></td>

                                            </tr>
                                            <tr>
                                              <td colspan="4" class="grand total">DIFERENÇA</td>
                                              <td class="grand total">0,00</td>
                                              <td></td>
                                              <td></td>

                                            </tr>
                                          </tbody>
                                        </table>
                                        <div id="notices">
                                          <div>ARQUIVOS SUPORTE:</div>
                                          <div class="notice">
                                                        Composição do Financeiro.xls
                                                        <button pButton type="button" class="ui-button-info" icon="fa-download"></button>
                                                        <button pButton type="button" class="ui-button-danger" icon="fa-trash"></button>
                                                        </div>
                                        </div>
                                      </main>
                                      <!--<footer>
                                        Invoice was created on a computer and is valid without the signature and seal.
                                      </footer>-->


        </mat-card>
</mat-card>

<!--<mat-card class="principal">
                <mat-card-header><h3>Conta 101010101 - CAIXA</h3>
                </mat-card-header>
<div class="ui-g">
        <div class="ui-g-12 ui-md-6 ui-lg-4">
               <mat-card class="fer">
                        <mat-card-header>
                                <h3>Valor Contábil: {{vlContabil |money}}</h3>
                        </mat-card-header>
                </mat-card>
        </div>
        <div class="ui-g-12 ui-md-6 ui-lg-4">
                <mat-card class="fer1">
                                 <mat-card-header>
                                         <h3>Valor Financeiro: {{vlFinanceiro |money}}</h3>
                                 </mat-card-header>
                </mat-card>
        </div>
        <div class="ui-g-12 ui-md-6 ui-lg-4">
                <mat-card class="fer2">
                        <mat-card-header>
                                <h3>Diferença: {{vlDiferenca |money}}</h3>
                        </mat-card-header>
                </mat-card>
        </div>
</div>

<div class="ui-g">

        <div class="ui-g-12 ui-md-6 ui-lg-6">
                <mat-card>
                        <mat-card-header>
                        <h3>Composição Financeiro</h3>
                </mat-card-header>
        <mat-card-content>
                <table class="table-teste">
                        <thead>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th></th>
                        </thead>
                        <tr >
                        <td>31/01/2017</td>
                        <td>Composição Financeiro</td>
                        <td>{{vlFinanceiro-10000 |money}}</td>
                        <td><button pButton type="button" class="ui-button-danger" icon="fa-trash"></button></td>
                        </tr>
                        <tr>
                        <td>29/01/2017</td>
                        <td>Título 1234 a ser regularizado</td>
                        <td>{{10000 |money}}</td>
                        <td><button pButton type="button" class="ui-button-danger" icon="fa-trash"></button></td>
                        </tr>

                </table>
        </mat-card-content>
                </mat-card>
        </div>
        <div class="ui-g-12 ui-md-6 ui-lg-6">
                        <mat-card>
                                        <mat-card-header>
                                        <h3>Arquivos Suporte</h3>
                                </mat-card-header>
                        <mat-card-content>
                                <table class="table-teste">
                                        <thead>
                                        <th>Nome</th>
                                        <th></th>
                                        <th></th>
                                        </thead>
                                        <tr>
                                        <td>Composição do Financeiro.xls</td>
                                        <td><button pButton type="button" class="ui-button-info" icon="fa-download"></button></td>
                                        <td><button pButton type="button" class="ui-button-danger" icon="fa-trash"></button></td>
                                        </tr>
                                        <tr>
                                        <td>Print Titulo 1234.jpg</td>
                                        <td><button pButton type="button" class="ui-button-info" icon="fa-download"></button></td>
                                        <td><button pButton type="button" class="ui-button-danger" icon="fa-trash"></button></td>
                                        </tr>

                                </table>
                        </mat-card-content>
                                </mat-card>
                </div>
</div>

<div class="ui-g">

                <div class="ui-g-12 ui-md-6 ui-lg-6">
                        <mat-card>
                                <mat-card-header><h3>Status</h3>
                                </mat-card-header>
                                <mat-card-content>
                                        <table class="table-teste">
                                        <tr>
                                                <td>
                                <b>Formalizado por:</b> Suellen Daher
                                                </td>
                                        </tr>
                                        <tr>
                                                        <td>
                                        <b>Homologado por:</b> José Roberto
                                                        </td>
                                                </tr>
                                        </table>
                                </mat-card-content>
                            </mat-card>
                </div>
                <div class="ui-g-12 ui-md-6 ui-lg-6">
                                <mat-card>
                                        <mat-card-header><h3>Exportar Formalização</h3>
                                        </mat-card-header>
                                        <mat-card-content>
                                                        <button pButton type="button" class="button-teste" icon="fa fa-file-pdf-o"></button>

                                        </mat-card-content>
                                    </mat-card>
                        </div>
        </div>
</mat-card>-->
