<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title mb-4"><i class="fas fa-file-download"> </i> Exportar ECF (PERÍODOS DISPONÍVEIS)</h3>
        </div>
      </div>
      <p-table class="table-system" [value]="ecfList" [style]="{'width':'100%'}" immutable="false">
        <ng-template pTemplate="header">
          <tr>
            <th *ngIf="canRemove" class="text-center" style="width: 40px; vertical-align: sub;">
              <span class="check-all p-0">
                <p-checkbox (change)="onSelectAll($event.checked)" [binary]="true">
                </p-checkbox>
              </span>
            </th>
            <th class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">DATA INICIAL</span>
              </span>
            </th>
            <th class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">DATA FINAL</span>
              </span>
            </th>
            <th *ngIf="ecfList && ecfList.length > 0 && (!isVirtual || hasApurado)" class="text-center"
              style="width: 110px; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">APURADO</span>
              </span>
              <p-checkbox #mdCheckAllApurado [disabled]="allApuracaoDisabled"
                (click)="onSelectAllStatus(mdCheckAllApurado.checked, 2)" [binary]="true"
                ></p-checkbox>
            </th>
            <th *ngIf="ecfList && ecfList.length > 0 && ((!isVirtual && hasApurado) || hasHomologado)"
              class="text-center" style="width: 110px; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">HOMOLOGADO</span>
              </span>
              <p-checkbox #mdCheckAllHomologado [disabled]="allHomologacaoDisabled"
                (click)="onSelectAllStatus(mdCheckAllHomologado.checked, 3)" [binary]="true"
                ></p-checkbox>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td *ngIf="canRemove" class="text-center" style="width: 40px;">
              <p-checkbox [(ngModel)]="rowData.selected" (change)="onSelect()"></p-checkbox>
            </td>
            <td class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{ rowData.dtIni | timezone | date:'dd/MM/yyyy' }}">{{ rowData.dtIni | timezone |
                  date:'dd/MM/yyyy' }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip=" {{ rowData.dtFin | timezone | date:'dd/MM/yyyy' }}">{{ rowData.dtFin | timezone |
                  date:'dd/MM/yyyy' }}</span>
              </span>
            </td>
            <td *ngIf="ecfList && ecfList.length > 0 && (!isVirtual || hasApurado)" class="text-center"
              style="width: 110px;">
                <p-checkbox class="mr-1"
                  [disabled]="isVirtual || rowData.homologado"
                  [(ngModel)]="rowData.apurado"
                  (click)="onClick(rowData, 2, rowData.homologado)"
                  (keyup)="onClick(rowData, 2, rowData.homologado, $event)"
                  [binary]="true"
                  inputId="apurado">
              </p-checkbox>
            </td>
            <td *ngIf="ecfList && ecfList.length > 0 && ((!isVirtual && hasApurado) || hasHomologado)"
              class="text-center" style="width: 110px;">
              <p-checkbox class="mr-1"
                [disabled]="isVirtual || !rowData.apurado"
                [(ngModel)]="rowData.homologado"
                (click)="onClick(rowData, 3, !rowData.apurado)"
                (keyup)="onClick(rowData, 3, !rowData.apurado, $event)"
                [binary]="true"
                inputId="homologado">
              </p-checkbox>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="2" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum período registrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>