<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <table width="100%">
    <tr>
      <td width="10%"></td>
      <td width="20%">
        <mat-card class="card-relatorio">POSIÇÃO CONTAS À RECEBER</mat-card>
      </td>
      <td width="10%"></td>
      <td width="20%">
        <mat-card class="card-relatorio">POSIÇÃO CONTAS À PAGAR</mat-card>
      </td>
      <td width="40%"></td>
    </tr>
  </table>

</mat-card>
