<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">PLANO DE AÇÃO</h3>
              <div class="actions-card">


                <button type="button" class="btn btn-xs btn-link" (click)="buscarAtas()">
                  <span class="material-icons full-icon" matTooltip="Voltar" matTooltipPosition="above" style="font-size: 22px; margin-right: -3px;">undo</span>
                </button>

                <button type="button" (click)="novaAta()" class="btn btn-xs btn-link" *ngIf="!visualizaAta"
                  matTooltip="Adicionar Plano de Ação" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 28px;">add</span>
                </button>

                <button type="button" class="btn btn-xs btn-link" *ngIf="visualizaAta" (click)="impressao()">
                  <span class="material-icons full-icon" matTooltip="Impressão"
                    matTooltipPosition="above">print</span>
                </button>

                <button type="button" class="btn btn-xs btn-link" *ngIf="visualizaAta" (click)="salvarAta('remove')">
                  <span class="material-icons full-icon" matTooltip="Excluir"
                    matTooltipPosition="above">delete</span>
                </button>

                <button type="button" (click)="salvarAta('salvar')" class="btn btn-xs btn-link" *ngIf="visualizaAta"
                  matTooltip="Salvar Plano" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 28px;">save</span>
                </button>

                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideControlOrca()"
                  (click)="changeStateControlOrca()">
                  <span *ngIf="isCardControlOrca" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardControlOrca" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStateControlOrca>
            <div class="row">
              <div class="col-sm-12">

                <p-table class="table-system" [value]="atas"
                [style]="{width:'100%'}" [paginator]="true"
                [pageLinks]="pageLinks" [rows]="rows" [rowsPerPageOptions]="rowsPerPage"
                (onFilter)="onFilter($event)"
                #dt1 *ngIf="!visualizaAta">

                <ng-template pTemplate="header">
                  <tr>


                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                        <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'data'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'data', 'contains')">
                    </th>

                  <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                        <span class="text-overflow-dynamic-ellipsis">TITULO <p-sortIcon [field]="'titulo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'titulo', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                        <span class="text-overflow-dynamic-ellipsis">RESPONSÁVEL <p-sortIcon [field]="'responsavel'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'responsavel', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'areaId'">
                        <span class="text-overflow-dynamic-ellipsis">area <p-sortIcon [field]="'areaId'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'areaId', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'classificacao'">
                        <span class="text-overflow-dynamic-ellipsis">CLASSIFICAÇÃO <p-sortIcon [field]="'classificacao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'classificacao', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'prazoForm'">
                        <span class="text-overflow-dynamic-ellipsis">PRAZO <p-sortIcon [field]="'prazoForm'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'prazoForm', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'valor', 'contains')">
                    </th>

                    <th class="text-center" style="width: 10%;">
                      <span [pSortableColumn]="'usuarioModificacao'">USUÁRIO
                        <p-sortIcon [field]="'usuarioModificacao'"></p-sortIcon></span>

                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'usuarioModificacao', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataModificacao'">
                        <span class="text-overflow-dynamic-ellipsis">ÚLTIMA MODIFICAÇÃO <p-sortIcon [field]="'dataModificacao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'dataModificacao', 'contains')">
                    </th>


                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData" (click)="visualizarAta(rowData)">

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data }}" >{{rowData.data | date:
                          'dd/MM/yyyy'}}</span>
                      </span>
                    </td>

                  <td class="text-left" style="width: 30%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.titulo }}" >{{rowData.titulo }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.responsavel }}" >{{rowData.responsavel }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.areaId }}" >{{rowData.areaId }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.classificacao }}" >{{rowData.classificacao }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.prazoForm }}" >{{rowData.prazoForm | date:
                          'dd/MM/yyyy'}}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor }}" >{{rowData.valor }}</span>
                      </span>
                    </td>


                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.usuarioModificacao }}" >{{rowData.usuarioModificacao }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">

                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataModificacao }}" >{{rowData.dataModificacao }}</span>
                      </span>
                    </td>

                   </tr>
                </ng-template>


                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="20" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>

              <div class="form-group col-sm-12" *ngIf="visualizaAta">


                <div class="form-row">
                  <div class="form-group col-sm-1">
                    <label>Data</label>
                    <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                      [defaultDate]="dataAta" [(ngModel)]="dataAta" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)"
                      [locale]="pt">

                    </p-calendar>
                  </div>

                  <div class="form-group col-sm-7">
                    <label>Título </label>
                    <input class="form-control" [(ngModel)]="tituloAta" type="text" />
                  </div>

                  <div class="form-group col-sm-2">
                    <label>Área </label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" [(options)]="opcoesArea"
                placeholder="Selecione a Área" [(ngModel)]="areaId"></p-dropdown>
              </div>

              <div class="form-group col-sm-2">
                <label>Classificação </label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [(options)]="optionsClassificacao"
            placeholder="Selecione a Classificacao" [(ngModel)]="classificacao"></p-dropdown>
          </div>
                </div>

                <div class="form-row">

                  <div class="form-group col-sm-1">
                    <label>Prazo</label>
                    <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                      [defaultDate]="dataAta" [(ngModel)]="prazoAta" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)"
                      [locale]="pt">

                    </p-calendar>
                  </div>

                  <div class="form-group col-sm-3">
                    <label>Responsável </label>
                    <input class="form-control" [(ngModel)]="responsavel" type="text" />
                  </div>

                  <div class="form-group col-sm-2">
                    <label>Status </label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" [(options)]="optionsStatus"
                placeholder="Selecione a Classificacao" [(ngModel)]="status"></p-dropdown>
              </div>

              <div class="form-group col-sm-2">
              <label>Valor</label>
                <input class="form-control text-right" type="text"
                  pInputText
                  [(ngModel)]="valor" />
                </div>




                </div>

                <label>Descricao do Problema </label>
                  <p-editor [(ngModel)]="infoAta" [style]="{'min-height':'100px'}"></p-editor>


                  <label>Ação </label>
                  <p-editor [(ngModel)]="acao" [style]="{'min-height':'100px'}"></p-editor>

                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

