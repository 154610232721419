<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>

  <!-- Alert -->
  <div class="ui-g">
    <div class="ui-g-12">
      <p-messages [value]="msgs" (click)="hideMessage()"></p-messages>
    </div>
  </div>


  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->
  <mat-card-content>
    <!-- <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-info-circle"></i> Produtos e Serviços</h3>
        </div>
    </div> -->
    <div class="box-content pt-2" *ngIf="showLista">
      <div class="row">
        <div class="col-sm-12">
          <div class="clearfix"></div>
          <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dt1.exportCSV()"><i class="fas fa-file-excel excel"></i>
              Exportar para Excel</button>
          </div>
          <div class="clearfix"></div>
          <p-table class="table-system" #dt1 [columns]="colsDeptoEmpresa" [value]="lista" [rows]="rows"
            [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
            [style]="{ width: '100%' }">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 150%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-left" style="width: 150%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}"
                      (click)="loadComponent('edit', rowData)">{{rowData.descricao}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="1" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- EDIT | NEW -->
  <!-- ---------------------------------------------------------------------------------------- -->
  <div class="row " *ngIf="showEdit || showNew">
    <div class="col-sm-12 col-md-12">
      <div class="box-content">


        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>DESCRIÇÃO</label>
            <input class="form-control" type="text" input-text="left" [(ngModel)]="deptoEmpresa.descricao"
              maxlength="200" />
          </div>
        </div>


        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-secondary" (click)="loadComponent('lista', null); "><i class="fas fa-undo"></i>
              Cancelar</button>
            <button class="btn btn-danger" *ngIf="showEdit" (click)="deletarRegistro()"><i class="fas fa-times"></i>
              Excluir</button>
            <button class="btn btn-primary" *ngIf="showEdit" (click)="editarRegistro()"><i class="fas fa-check"></i>
              Salvar</button>
            <button class="btn btn-primary" *ngIf="!showEdit" (click)="salvarNovoRegistro()"><i
                class="fas fa-check"></i> Salvar</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>