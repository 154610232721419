<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content" style="padding: 1rem !important;">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{active: statusExtratoOfx}">
                  <a style="padding: 10px 0 20px !important;" (click)="showTelaOfxCentralWeb('TELA_1')">Extrato OFX</a>
                </li>
                <li class="list-inline-item" [ngClass]="{active: statusCentralWeb}">
                  <a style="padding: 10px 0 20px !important;" (click)="showTelaOfxCentralWeb('TELA_2')">Central Web</a>
                </li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div *ngIf="cardOfx" class="box-content" [@changeDivSize]="currentStateCardOfx">
            <div [ngStyle]="statusExtratoOfx ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusExtratoOfx'}">
              <p-table class="table-system" #dt1 [value]="ofx" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [lazy]="true"
                (onLazyLoad)="checkBigVolume(false, $event)" [totalRecords]="totalTransactions"
                (onRowSelect)="onRowSelect()" (onRowUnselect)="onRowUnselect()" [(selection)]="selectedOfx" (onHeaderCheckboxToggle)="selectTodos()">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 5%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                        <span class="text-overflow-dynamic-ellipsis">Data
                          <p-sortIcon [field]="'data'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'data', 'contains')">
                    </th>
                    <th class="text-center" style="width: 5%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_banco'">
                        <span class="text-overflow-dynamic-ellipsis">Banco <p-sortIcon [field]="'cod_banco'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'cod_banco', 'contains')">
                    </th>
                    <th class="text-center" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_transacao'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD. TRANSAÇÃO <p-sortIcon
                            [field]="'cod_transacao'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'cod_transacao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 6%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dados_conta'">
                        <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'dados_conta'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'dados_conta', 'contains')">
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'regra_contabil.descricao'">
                        <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO REGRA <p-sortIcon
                            [field]="'regra_contabil.descricao'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'regra_contabil.descricao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_participante'">
                        <span class="text-overflow-dynamic-ellipsis">PARTICIPANTE <p-sortIcon
                            [field]="'desc_participante'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'desc_participante', 'contains')">
                    </th>
                    <th class="text-left" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_centro_custo'">
                        <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO <p-sortIcon
                            [field]="'desc_centro_custo'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'desc_centro_custo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_projeto'">
                        <span class="text-overflow-dynamic-ellipsis">PROJETO <p-sortIcon [field]="'desc_projeto'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'desc_projeto', 'contains')">
                    </th>
                    <th class="text-left" style="width: 9%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_tipo_baixa'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO DE BAIXA <p-sortIcon
                            [field]="'desc_tipo_baixa'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'desc_tipo_baixa', 'contains')">
                    </th>
                    <th class="text-right" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'valor', 'contains')">
                    </th>
                    <th class="text-center" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_status'">
                        <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'descr_status'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descr_status', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.data}}">{{rowData.data}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.cod_banco}}">{{rowData.cod_banco}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.cod_transacao}}">{{rowData.cod_transacao}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.dados_conta}}">{{rowData.dados_conta}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.regra_contabil.descricao}}">{{rowData.regra_contabil.descricao}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: pointer;display: flex;align-items: center;justify-content: center;"
                          (click)="openModalParticipante(rowData)" matTooltip="{{rowData.desc_participante}}">
                          <div class="overflow-ellipse" style="width: 90%;max-width: 90%;text-align: initial;">
                            {{rowData.desc_participante}}
                          </div>
                          <span class="material-icons" style="font-size: 14px;margin-left: 1rem;">edit</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 9%;">
                      <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
                        [options]="opcoesCentroCusto" [(ngModel)]="rowData.centro_custo_id"
                        placeholder="{{rowData.desc_centro_custo}}" optionLabel="label"
                        (onChange)="getProjetos(rowData); ">
                      </p-dropdown>
                    </td>
                    <td class="text-left" style="width: 9%;">
                      <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
                        [options]="rowData.lista_projetos" [(ngModel)]="rowData.projeto_id"
                        placeholder="{{rowData.desc_projeto}}" optionLabel="label"
                        (onChange)="incluirProjetoSelecionado(rowData); ">
                      </p-dropdown>
                    </td>
                    <td class="text-left" style="width: 9%;">
                      <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
                        [options]="opcoesTipoBaixa" [(ngModel)]="rowData.tipo_baixa_id"
                        placeholder="{{rowData.desc_tipo_baixa}}" optionLabel="label"
                        (onChange)="incluirTipoBaixaSelecionado(rowData);">
                      </p-dropdown>
                    </td>
                    <td class="text-right" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="{{rowData.valor}}"> {{ rowData.valor | money }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" matTooltip="{{rowData.status.descricao}}">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;">
                          <!-- <i *ngIf="rowData.descr_status===4" class="fas fa-circle" style="color: blue;"></i> -->
                          <i *ngIf="rowData.descr_status==='CONCILIADO'" class="fas fa-circle"
                            style="color: green;"></i>
                          <i *ngIf="rowData.descr_status==='VERIFICAR'" class="fas fa-circle"
                            style="color: yellow;"></i>
                          <i *ngIf="rowData.descr_status==='PENDENTE'" class="fas fa-circle green red"
                            style="color: darkred;"></i>
                          {{rowData.descr_status}}
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="11">TOTAL DO PERÍODO:</td>
                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="R$ {{ totalPeriodo | money}}">R$ {{ totalPeriodo | money}}</span>
                      </span>
                    </td>
                    <td class="text-right">
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusCentralWeb ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusCentralWeb'}">
              <p-table class="table-system" #dt2 [value]="centralWeb" selectionMode="single" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox (click)="selectTodosCw()"></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                        <span class="text-overflow-dynamic-ellipsis">DATA VENCIMENTO <p-sortIcon
                            [field]="'data_vencimento'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'data_vencimento', 'contains')">
                    </th>
                    <th class="text-center" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_fato_gerador'">
                        <span class="text-overflow-dynamic-ellipsis">DATA FATO GERADOR <p-sortIcon
                            [field]="'data_fato_gerador'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'data_fato_gerador', 'contains')">
                    </th>
                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'obrigacao'">
                        <span class="text-overflow-dynamic-ellipsis">OBRIGAÇÃO <p-sortIcon [field]="'obrigacao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'obrigacao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_tipo_arquivo'">
                        <span class="text-overflow-dynamic-ellipsis">TIPO ARQUIVO <p-sortIcon
                            [field]="'nome_tipo_arquivo'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'nome_tipo_arquivo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao_regra_descricao'">
                        <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO REGRA <p-sortIcon
                            [field]="'operacao_regra_descricao'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'operacao_regra_descricao', 'contains')">
                    </th>
                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_empresa_conta'">
                        <span class="text-overflow-dynamic-ellipsis">CONTA BANCÁRIA <p-sortIcon
                            [field]="'desc_empresa_conta'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'desc_empresa_conta', 'contains')">
                    </th>
                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_participante'">
                        <span class="text-overflow-dynamic-ellipsis">PARTICIPANTE <p-sortIcon
                            [field]="'desc_participante'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'desc_participante', 'contains')">
                    </th>
                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_centro_custo'">
                        <span class="text-overflow-dynamic-ellipsis">CENTRO CUSTO <p-sortIcon
                            [field]="'desc_centro_custo'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'desc_centro_custo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'desc_projeto'">
                        <span class="text-overflow-dynamic-ellipsis">PROJETO <p-sortIcon [field]="'desc_projeto'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'desc_projeto', 'contains')">
                    </th>
                    <th class="text-right" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'valor', 'contains')">
                    </th>
                    <th class="text-center" style="width: 8%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'status.descricao'">
                        <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status.descricao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'status.descricao', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox (click)="selectCw(rowData.envio_id, rowData)" [value]="rowData">
                      </p-tableCheckbox>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.data_fato_gerador}}">{{rowData.data_fato_gerador}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.obrigacao}}">{{rowData.obrigacao}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nome_tipo_arquivo}}">{{rowData.nome_tipo_arquivo}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.operacao_regra_descricao}}">{{rowData.operacao_regra_descricao}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
                        [options]="listaEmpresasConta" [(ngModel)]="rowData.empresa_conta_id"
                        placeholder="{{rowData.desc_empresa_conta}}" optionLabel="label"
                        (onChange)="incluirEmpresaContaSelecionadoCw(rowData)">
                      </p-dropdown>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          style="user-select: all; cursor: pointer;display: flex;align-items: center;justify-content: space-between;"
                          (click)="openModalParticipante(rowData)" matTooltip="{{rowData.desc_participante}}">
                          {{rowData.desc_participante}}
                          <span class="material-icons" style="font-size: 14px">edit</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-left">
                      <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
                        [options]="opcoesCentroCusto" [(ngModel)]="rowData.centro_custo_id"
                        placeholder="{{rowData.desc_centro_custo}}" optionLabel="label"
                        (onChange)="getProjetos(rowData)">
                      </p-dropdown>
                    </td>
                    <td class="text-left">
                      <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
                        [options]="rowData.lista_projetos" [(ngModel)]="rowData.projeto_id"
                        placeholder="{{rowData.desc_projeto}}" optionLabel="label"
                        (onChange)="incluirProjetoSelecionadoCw(rowData)">
                      </p-dropdown>
                    </td>
                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valor | money}}">{{rowData.valor | money }}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container" matTooltip="{{rowData.status.descricao}}">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;">
                          <i *ngIf="rowData.status.id===2" class="fas fa-circle" style="color: green;"></i>
                          <i *ngIf="rowData.status.id===1" class="fas fa-circle green red" style="color: darkred;"></i>
                          {{rowData.status.descricao}}
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td colspan="10" style="width:86%;">TOTAL DO PERÍODO:</td>
                    <td class="text-right" style="width:7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                          matTooltip="R$ {{ totalCentralWeb | money}}">R$ {{ totalCentralWeb | money}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width:7%;">
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="12" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-card-content *ngIf="this.dynamicTableVisible" style="margin-top: 20px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="shadow-box">
            <app-dynamic-table (contentTosave)="salvarTitulos($event)" (messages)="exibirMensagem($event)"
              (modalStatus)="openModal($event)" (visible)="fecharDynamicTable($event)" [value]="this.dynamicTable"
              [updateTableContent]="this.updateDynamicTable" [resetTableState]="this.resetDynamicTable">
            </app-dynamic-table>
          </div>
        </div>
      </div>
    </mat-card-content>
    <div [ngStyle]="statusExtratoOfx ? {'display': 'block'} : {'display': 'none'}"
      [ngClass]="{'fade-in': 'statusExtratoOfx'}">
      <div *ngIf="verificarTitulos" class="row mt-3">
        <div class="col-sm-12">
          <div class="shadow-box">
            <div class="header-box">
              <div class="header-box-content" style="padding: 1rem !important;">
                <ul class="list-inline filter-menu filter-card-header mt-0">
                  <li class="list-inline-item" [ngClass]="{active: statusNaoConciliado}">
                    <a style="padding: 10px 0 20px !important;" (click)="showTelaConciliacao('TELA_2')">
                      Não conciliados ({{ titulosFinanceiroNaoConc.length}})
                    </a>
                  </li>
                  <li class="list-inline-item" [ngClass]="{active: statusConciliado}">
                    <a style="padding: 10px 0 20px !important;" (click)="showTelaConciliacao('TELA_1')">
                      Conciliados({{ titulosFinanceiro.length }})
                    </a>
                  </li>
                </ul>
                <div class="actions-card" style="display: flex;align-items: center;justify-content: center">
                  <button *ngIf="statusConciliado" (click)="desfazerConciliacao()" type="button" class="btnCustom-red">
                    <span class="material-icons">delete</span>
                    Remover
                  </button>
                  <button *ngIf="statusNaoConciliado && liTitulosFin.length" (click)="verificarAntesConciliar()"
                    type="button" class="btnCustom-green">
                    <span class="material-icons">done_all</span>
                    Conciliar
                  </button>
                  <button type="button" class="btn btn-xs btn-link" (click)="verificarTitulos=false" matTooltip="Fechar"
                    matTooltipPosition="above">
                    <span class="material-icons" style="font-size: 22px">close</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="box-content">
              <div [ngStyle]="statusConciliado ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusConciliado'}">
                <p-table class="table-system" #dt4 [value]="titulosFinanceiro" [rows]="rows" [paginator]="true"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [selection]="liTitulosFinConc">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center tableHeaderCheckbox">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox (click)="selectTodos()"></p-tableHeaderCheckbox>
                        </span>
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                          <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'data'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'data', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo <p-sortIcon [field]="'tipo'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'tipo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem_titulo'">
                          <span class="text-overflow-dynamic-ellipsis">Origem <p-sortIcon [field]="'origem_titulo'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'origem_titulo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao_regra'">
                          <span class="text-overflow-dynamic-ellipsis">Operação Regra <p-sortIcon
                              [field]="'operacao_regra'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'operacao_regra', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'participante'">
                          <span class="text-overflow-dynamic-ellipsis">Participante <p-sortIcon
                              [field]="'participante'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'participante', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'banco'">
                          <span class="text-overflow-dynamic-ellipsis">Banco <p-sortIcon [field]="'banco'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'banco', 'contains')">
                      </th>
                      <th class="text-left" style="width: 11%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                          <span class="text-overflow-dynamic-ellipsis">Num. Doc <p-sortIcon [field]="'documento'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'documento', 'contains')">
                      </th>
                      <th class="text-left" style="width: 11%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                          <span class="text-overflow-dynamic-ellipsis">Histórico <p-sortIcon [field]="'historico'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'historico', 'contains')">
                      </th>
                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                          <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'valor', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                          <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt4.filter($event.target.value, 'status', 'contains')">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center tableCheckbox">
                        <p-tableCheckbox (click)="selectTitulo(rowData.id, rowData)" [value]="rowData">
                        </p-tableCheckbox>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.data}}">{{rowData.data}}</span>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.origem_titulo}}">{{rowData.origem_titulo}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.operacao_regra}}">{{rowData.operacao_regra}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.participante}}">{{rowData.participante}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.banco}}">{{rowData.banco}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.documento}}">{{rowData.documento}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                        </span>
                      </td>
                      <td class="text-right">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                            matTooltip="{{rowData.valor}}">{{ rowData.valor | money }}</span>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                            matTooltip="{{rowData.status}}">{{ rowData.status }}</span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="9" style="width:75%;">TOTAL: </td>
                      <td class="text-right" style="width:10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="R$ {{ totalFinanceiro | money}}">R$ {{ totalFinanceiro | money}}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width:10%;">
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="10" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <div [ngStyle]="statusNaoConciliado ? {'display': 'block'} : {'display': 'none'}"
                [ngClass]="{'fade-in': 'statusNaoConciliado'}">
                <p-table class="table-system" #dt6 [value]="titulosFinanceiroNaoConc" [rows]="rows" [paginator]="true"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}" [(selection)]="liTitulosFin">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center tableHeaderCheckbox">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </span>
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                          <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'data'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'data', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                          <span class="text-overflow-dynamic-ellipsis">Tipo <p-sortIcon [field]="'tipo'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'tipo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem_titulo'">
                          <span class="text-overflow-dynamic-ellipsis">Origem <p-sortIcon [field]="'origem_titulo'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'origem_titulo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao_regra'">
                          <span class="text-overflow-dynamic-ellipsis">Operação Regra <p-sortIcon
                              [field]="'operacao_regra'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'operacao_regra', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'participante'">
                          <span class="text-overflow-dynamic-ellipsis">Participante <p-sortIcon
                              [field]="'participante'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'participante', 'contains')">
                      </th>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'banco'">
                          <span class="text-overflow-dynamic-ellipsis">Banco <p-sortIcon [field]="'banco'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'banco', 'contains')">
                      </th>
                      <th class="text-left" style="width: 11%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'documento'">
                          <span class="text-overflow-dynamic-ellipsis">Num. doc <p-sortIcon [field]="'documento'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'documento', 'contains')">
                      </th>
                      <th class="text-left" style="width: 11%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                          <span class="text-overflow-dynamic-ellipsis">Histórico <p-sortIcon [field]="'historico'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'historico', 'contains')">
                      </th>
                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                          <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'">
                            </p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'valor', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                          <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt6.filter($event.target.value, 'status', 'contains')">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center tableCheckbox">
                        <p-tableCheckbox (click)="selectTituloNaoConc()" [value]="rowData">
                        </p-tableCheckbox>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.data}}">{{rowData.data}}</span>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.origem_titulo}}">{{rowData.origem_titulo}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.operacao_regra}}">{{rowData.operacao_regra}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.participante}}">{{rowData.participante}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.banco}}">{{rowData.banco}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.documento}}">{{rowData.documento}}</span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                        </span>
                      </td>
                      <td class="text-right">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                            matTooltip="{{rowData.valor}}">{{ rowData.valor | money }}</span>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                            matTooltip="{{rowData.status}}">{{ rowData.status }}</span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <tr>
                      <td colspan="9" style="width:75%;">TOTAL: </td>
                      <td class="text-right" style="width:10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                            matTooltip="R$ {{ totalFinanceiroNaoConc | money}}">R$ {{ totalFinanceiroNaoConc |
                            money}}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width:10%;">
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="10" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Modal Filtros-->
<p-dialog header="Filtrar" [(visible)]="modalFiltros" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecionar banco</label>
      <p-multiSelect class="type-p-mult" [options]="bancos" [(ngModel)]="banco_selecionados" defaultLabel="Selecione">
      </p-multiSelect>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalFiltros=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="fecharModalFiltros()"><i class="fas fa-pencil-alt"></i>
      Alterar</button>
  </p-footer>
</p-dialog>

<!-- Modal Alterar Período-->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" style="width: 400px" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy"></p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="atualizarPagina(); modalPeriodo=false;"><i
        class="fas fa-pencil-alt"></i>
      Alterar</button>
  </p-footer>
</p-dialog>

<!-- Modal Alterar Banco-->
<p-dialog header="Alternar Conta" [(visible)]="modalBanco" width="400" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecionar banco</label>
      <p-multiSelect class="type-p-mult" [options]="bancos" [(ngModel)]="banco_selecionados" defaultLabel="Selecione">
      </p-multiSelect>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalBanco=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="atualizarPagina(); modalBanco=false;"><i
        class="fas fa-pencil-alt"></i>
      Alterar</button>
  </p-footer>
</p-dialog>

<!-- Modal Participante-->
<app-modal-participante [(isVisible)]="modalParticipante" [isReplicable]="true"
  (onSelectParticipant)="salvarParticipante($event)">
</app-modal-participante>

<!-- Modal Rateio-->
<p-dialog header="Definir Rateio" [(visible)]="modalRateio" [modal]="true" [responsive]="true" appendTo="body"
  [closable]="true">
  <div *ngIf="modalRateio" [style]="{height: '100%'}">
    <div class="form-row" style="height: 100%;">

      <div class="form-group col-sm-4">
        <label>Centro de Custo <span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="this.opcoesCentroCusto"
          [(ngModel)]="this.cc"
          (onChange)="getProjetos({centro_custo_id: {value: this.cc.value, id: this.cc.value}}, true) "
          placeholder="Selecione um centro de custo" optionLabel="label">
        </p-dropdown>
      </div>

      <div class="form-group col-sm-4">
        <label>Projeto</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="this.opcoesProjeto"
          [(ngModel)]="proj" placeholder="Selecione um projeto" optionLabel="label">
        </p-dropdown>
      </div>

      <div class="form-group col-sm-2">
        <label>Porcentagem <span class="obrigatorio">*</span></label>
        <input style="height: 30px;" class="form-control text-right" value="{{this.porcentagemCc}}"
          [(ngModel)]="this.porcentagemCc" money>
      </div>

      <div class="form-group col-sm-2">
        <div class="locked-element">
          <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
          <button type="button" class="btnCustom-green" matTooltip="Adicionar" matTooltipPosition="above"
            (click)="incluirRateioCentroCusto()">
            <span class="material-icons">add</span>
            <div>Adicionar</div>
          </button>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 20px; height: 100%;">
      <div class="col-sm-12">
        <p-table class="table-system"
          [value]="this.dynamicTable.content[this.dynamicTable.index][this.dynamicTable.key]">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 33%;">CENTRO CUSTO</th>
              <th style="width: 34%;">PROJETO</th>
              <th style="width: 16%;">PORCENTAGEM</th>
              <th style="width: 16%;">AÇÃO</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width: 35%;">
                <span class="overflow-ellipse"
                  matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">{{
                  rowData.cadCcus.codCcus }}
                  - {{ rowData.cadCcus.descrCcus }} </span>
              </td>

              <td style="width: 35%;" *ngIf="rowData.cadProj">

                <span class="overflow-ellipse"
                  matTooltip="{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}">{{
                  rowData.cadProj.codProj }}
                  - {{ rowData.cadProj.descrProj }} </span>
              </td>
              <td *ngIf="!rowData.cadProj">
              </td>
              <td>
                {{ rowData.porcentagem | money }}%
              </td>
              <td class="text-center">
                <span class="delete" matTooltip="Excluir centro de custo" (click)="removerRateioCentroCusto(rowData)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr style="height:25px">
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Sem rateio!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="footer">
      <label class="mr-3" *ngIf="rateioCentroCusto !== 0 && rateioCentroCusto !== 100"><strong>Rateio
          Disponível:</strong>
        {{rateioCentroCusto}}%</label>
      <button type="button" class="btn btn-secondary" *ngIf="rateioCentroCusto === 0 || rateioCentroCusto === 100"
        (click)="fecharModalRateio()">
        <i class="fas fa-undo"></i> FECHAR
      </button>
      <button [disabled]="this.rateioCentroCusto < 0 || this.rateioCentroCusto > 0" type="button"
        class="btn btn-primary" (click)="adicionarRateioTodos()">
        <i class="fas fa-check"></i> Salvar
      </button>
      <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; height: 100%;">
        <p-checkbox class="mr-1" [(ngModel)]="aplicarTodosRateios" [binary]="true" inputId="rateio"></p-checkbox>
        <label for="rateio" class="ml-1">Aplicar para todas as linhas selecionadas</label>
      </div>
    </div>
  </p-footer>
</p-dialog>