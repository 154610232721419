<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 class="sub-title"> RELATÓRIOS CONTÁBEIS PARA VISUALIZAÇÃO NA TELA OU
        EXPORTAR PARA EXCEL</h3>
    </div>
  </div>
  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="empresa?.id">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('BALANCETE_MENSAL')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('BALANCETE_MENSAL')" matTooltip="Balancete Mensal"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Balancete Mensal </span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('RAZAO_CONTABIL')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('RAZAO_CONTABIL')" matTooltip="Razão Contábil"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Razão Contábil</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('EVOLUCAO_MENSAL')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('EVOLUCAO_MENSAL')" matTooltip="Evolução Mensal"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Evolução Mensal</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('PLANO_DE_CONTAS')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('PLANO_DE_CONTAS')" matTooltip="Plano de Contas"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Plano de Contas</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('BalancoPatrimonial')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('BalancoPatrimonial')"
              matTooltip="Balanço Patrimonial e DRE" style="width: 150px;"><span class="d-block">Balanço Patrimonial e
                DRE</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId === 158">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('REPORT_PL')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('REPORT_PL')" matTooltip="Report Gerencial"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Report Gerencial</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId === 1 || empresaId === 115">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('TIT_ABERTO')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('TIT_ABERTO')" matTooltip="Títulos Abertos"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Títulos Abertos</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('GERENCIAL')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('GERENCIAL')" matTooltip="Gerencial"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Gerencial</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('LEI_DO_BEM')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('LEI_DO_BEM')" matTooltip="Lei do Bem - Fornecedores"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Lei do Bem - Fornecedores</span>
            </h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId == 158 || empresaId == 152 || empresaId == 234">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('PADIS')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('PADIS')" matTooltip="PADIS" matTooltipPosition="above"
              style="width: 150px;"><span class="d-block">PADIS</span>
            </h5>
          </div>
        </div>
        <div class="card" *ngIf="existeRelatorioExterno">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('EXT')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('EXT')" matTooltip="RELATÓRIOS EXTERNOS"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatórios Externos</span>
            </h5>
          </div>
        </div>
        <div class="card" *ngFor="let relat of listaRelat">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio(relat)"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio(relat)" matTooltip="{{relat.nome}}"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal BALANCETE MENSAL (FEITO)-->
  <p-dialog header="BALANCETE MENSAL" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusBalancenteMensal" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label class="d-block">Data Inicial</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesIni" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>
      <div class="form-group col-sm-3">
        <label class="d-block">Data Final</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>
      <div class="form-group col-sm-3">
        <label class="d-block">Idioma</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="idioma" [options]="idiomas" defaultLabel="Selecione">
        </p-dropdown>

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="p-field-checkbox mb-3 ml-1">
        <p-checkbox class="mr-1" [(ngModel)]="antesEncerramento" [binary]="true" inputId="antesEncerramento">
        </p-checkbox>
        <label for="antesEncerramento" class="ml-1">Antes de Encerramento</label>
      </div>
    </div>

    <p-footer>
      <button [disabled]="((mesIni === 0 || mesFin === 0 || mesFin < mesIni)) || ((mesFin === 0))" type="button"
        class="btn btn-primary" (click)="reportPc('balanceteMensal')"><i class="fas fa-laptop"></i>
        Tela
      </button>
      <button [disabled]="((mesIni === 0 || mesFin === 0 || mesFin < mesIni)) || ((mesFin === 0))" type="button"
        class="btn btn-primary" (click)="reportXls('balanceteMensal')"><i class="far fa-file-excel"></i>
        Excel
      </button>
      <button [disabled]="((mesIni === 0 || mesFin === 0 || mesFin < mesIni)) || ((mesFin === 0))" class="btn btn-primary" style="padding: 0.375rem 0.9rem;" type="button" (click)="relatBalancetePdf()">
        <i class="far fa-file-pdf pdf"></i>
        PDF
      </button>
    </p-footer>
  </p-dialog>

  <!-- Modal EVOLUÇÃO MENSAL (FEITO)-->
  <p-dialog header="EVOLUÇÃO MENSAL" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusEvolucaoMensal" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label class="d-block">Mes de Corte</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesCorte" [options]="meses" defaultLabel="Selecione"
          [filter]="false"></p-dropdown>
      </div>
      <div class="form-group col-sm-3">
        <label class="d-block">Idioma</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="idioma" [options]="idiomas" defaultLabel="Selecione">
        </p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <!-- <div class="form-group col-sm-12"> -->
      <!-- <mat-checkbox [(ngModel)]="separaCentroCusto">Separar Centro de Custo</mat-checkbox> -->
      <div class="p-field-checkbox mb-3 ml-1">
        <p-checkbox class="mr-1" [(ngModel)]="separaCentroCusto" [binary]="true" inputId="separaCentroCusto">
        </p-checkbox>
        <label for="separaCentroCusto" class="ml-1">Separar Centro de Custo</label>
      </div>
      <!-- </div> -->
    </div>
    <p-footer>
      <button type="button" class="btn btn-primary" (click)="reportXls('evolucaoMensal')"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal PLANO DE CONTAS (FEITO)-->
  <p-dialog header="PLANO DE CONTAS" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusPlanoDeContas" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col" style="min-height: 50px;"></div>
    </div>
    <p-footer>
      <!-- <button type="button" class="btn btn-secondary" (click)='statusPlanoDeContas=false'><i class="fas fa-undo"></i>
          Cancelar</button> -->
      <button type="button" class="btn btn-primary" (click)="reportPc('planoContas')"><i class="fas fa-laptop"></i>
        Tela</button>
      <button type="button" class="btn btn-primary" (click)="reportXls('planoContas')"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal LALUR (FEITO)-->
  <p-dialog header="LALUR" [modal]="true" appendTo="body" [style]="{width: '700px'}" styleClass="modal-initial"
    [(visible)]="statusLalur" [responsive]="true" [closable]="false">
    <div class="form-row">
      <div class="form-group col">
        <p-checkbox [binary]="true" [(ngModel)]="visaoGerencial"></p-checkbox>
        <label style="padding: 0 5px;">Visão Geral</label>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-primary" [disabled]="true" (click)="reportPc('lalur')"><i
          class="fas fa-laptop"></i> Tela</button>
      <button type="button" class="btn btn-primary" (click)="reportXls('lalur')"><i class="far fa-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal RAZÃO CONTÁBIL (FEITO)-->
  <p-dialog header="RAZÃO CONTÁBIL" [modal]="true" appendTo="body" [style]="{width: '900px'}" styleClass="modal-initial"
    [(visible)]="statusRazaoContabil" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="planoContasSelecionados.length <= 5">
      <div class="form-group col-sm-2">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [locale]="pt"></p-calendar>
      </div>
      <div class="form-group col-sm-2">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [locale]="pt"></p-calendar>
      </div>
    </div>
    <div class="form-row" *ngIf="planoContasSelecionados.length > 5">
      <div class="form-group col-sm-3">
        <label class="d-block">Data Inicial</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesIni" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>
      <div class="form-group col-sm-3">
        <label class="d-block">Data Final</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Conta Contábil</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesPlanoContas" [(ngModel)]="planoContasSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" panelStyleClass="custom-width">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <p-footer>
      <div class="d-flex justify-content-between">
        <p-checkbox [(ngModel)]="visaoTabela" [binary]="true" inputId="visaoTabela" label="Visão Tabela"></p-checkbox>
        <button class="btn btn-primary" [disabled]="(dtFin < dtIni)" type="button" (click)="reportXls('razaoContabil')">
          <i class="far fa-file-excel"></i> Excel
        </button>
      </div>
    </p-footer>
  </p-dialog>

  <!-- Modal REPORT GERENCIAL (FEITO)-->
  <p-dialog header="REPORT GERENCIAL" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusPl" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Data Corte</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
          [filter]="false"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label>RELATÓRIO</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="relatorioEscolhido" [options]="opcoesProjetosRel"
          defaultLabel="Selecione" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="relatorioEscolhido === 2 || relatorioEscolhido === 3">
        <label>VISÃO</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="tipoUsGaap" [options]="opcoesTipoUsGaap"
          defaultLabel="Selecione" [filter]="false"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="relatorioEscolhido === 1">
        <div class="p-field-checkbox mb-3 mt-4" style="width: 100px;">
          <p-checkbox class="mr-1" [(ngModel)]="acumulado" [binary]="true" inputId="acumulado"></p-checkbox>
          <label for="acumulado" class="ml-1">Acumulado</label>
        </div>

      </div>
    </div>
    <p-footer>
      <button [disabled]="((mesFin === 0))" class="btn btn-primary" type="button" (click)="reportPl('reportPl')"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>


  </p-dialog>

  <!-- Modal TÍTULOS EM ABERTO (FEITO)-->
  <p-dialog header="TÍTULOS EM ABERTO" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusTitAberto" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Data Corte</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
          [filter]="false"></p-dropdown>
      </div>

      <div class="form-group col-sm-4">
        <label>Tipo Relatório</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="tipoRelatorio" [options]="tiposRelatorios"
          defaultLabel="Selecione" [filter]="false"></p-dropdown>
      </div>
    </div>
    <p-footer>
      <button [disabled]="true" class="btn btn-primary" type="button" (click)="reportPc('balanceteMensal')"><i
          class="fas fa-laptop"></i> Tela</button>
      <button [disabled]="((mesFin === 0))" class="btn btn-primary" type="button"
        (click)="reportTitAberto('reportPl')"><i class="far fa-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>


  <!-- Modal GERENCIAL-->
  <p-dialog header="GERENCIAL" [modal]="true" appendTo="body" [style]="{width: '900px'}" styleClass="modal-initial"
    [(visible)]="statusGerencial" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label class="d-block">Data</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesIni" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>

      <div class="form-group col-sm-3">
        <label class="d-block">GERENCIAL</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="gerencial" [options]="opcoesGerencial" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>

      <div class="form-group col-sm-3">
        <label class="d-block">VISÃO</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="visao" [options]="opcoesVisao" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>

    </div>

    <p-footer>
      <button class="btn btn-primary" [disabled]="((mesIni === 0))" type="button" (click)="reportPc('gerencial')"><i
          class="fas fa-laptop"></i> Tela</button>
    </p-footer>
  </p-dialog>

  <!-- Modal LEI DO BEM Fornecedores-->
  <p-dialog header="LEI DO BEM - FORNECEDORES" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusLdB" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [required]='true'></p-calendar>
      </div>
      <div class="form-group col-sm-3">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" [required]='true'></p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projeto Lei do Bem</label>
        <p-multiSelect class="type-p-mult" panelStyleClass="custom-width" [options]="projetosLdB"
          [(ngModel)]="leidobemId" defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarLdB()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal EVOLUÇÃO PATRIMONIAL (FEITO)-->
  <p-dialog header="Balanço Patrimonial e DRE" [modal]="true" appendTo="body" [style]="{width: '700px'}"
    styleClass="modal-initial" [(visible)]="statusBalancoPatrimonial" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label class="d-block">Mes de Corte</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesCorte" [options]="meses" defaultLabel="Selecione"
          [filter]="false"></p-dropdown>
      </div>
      <!-- <div class="form-group col-sm-3">
        <label class="d-block">Idioma</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="idioma" [options]="idiomas" defaultLabel="Selecione">
        </p-dropdown>
      </div> -->
      <div class="form-group col-sm-6">
        <label class="d-block">Relatório</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="relatorioDreBalanco" [options]="optDreBalanco" defaultLabel="Selecione">
        </p-dropdown>
      </div>
    </div>
    <p-footer>
      <button [disabled]="((mesIni === 0))" class="btn btn-primary" style="padding: 0.375rem 0.9rem;" type="button"
        (click)="relatpatrimonial()"><i class="far fa-file-pdf pdf"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL Gerencial -->
  <p-dialog header="{{relatorioGerSelecionado.nome}}" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalRelatorioGer" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="!relatorioGerSelecionado.is_composta">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>

    <div class="row" *ngIf="relatorioGerSelecionado.is_composta">
      <div class="form-group col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>DATA REFERÊNCIA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef" [disabled]="datasIndividuais">
            </p-calendar>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <p-checkbox [binary]="true" [(ngModel)]="datasIndividuais"></p-checkbox>
            <label style="border: 0 5px;">Utilizar datas individuais</label>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="form-row">
          <p-table class="table-system" [value]="dicRelatComposto[relatorioGerSelecionado.id]"
            emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                  Data de referência</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                  *ngIf="datasIndividuais"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-config>
              <tr>
                <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                <td style="text-align: center">{{config.worksheet_title}}</td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                    [yearNavigator]="true" [defaultDate]="dataRef" yearRange="2000:2030"
                    [(ngModel)]="config['dataRef']">
                  </p-calendar>
                </td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                      (click)="carregarValores(config)"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
        *ngIf="!relatorioGerSelecionado.is_composta"><i class="fas fa-laptop"></i> Tela</button>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal PADIS-->
  <p-dialog header="PADIS" [modal]="true" appendTo="body" [style]="{width: '700px'}" styleClass="modal-initial"
    [(visible)]="statusPadis" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label class="d-block">Data inicial</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtIni" yearRange="2000:2030" [(ngModel)]="dtIni"></p-calendar>
      </div>
      <div class="form-group col-sm-3">
        <label class="d-block">Data final</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtFin" yearRange="2000:2030" [(ngModel)]="dtFin"></p-calendar>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioPadis()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal EXT-->
  <p-dialog header="RELATÓRIOS EXTERNOS" [modal]="true" appendTo="body" width="700" styleClass="modal-initial"
    [(visible)]="statusRelExt" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col-sm-5">
        <label class="d-block">Relatório</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="relExterno" [options]="opcoesRelatoriosExternos"
          defaultLabel="Selecione" [filter]="true" (onChange)="relatorioExterno($event)"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirFilial">
        <label class="d-block">Filial</label>
        <input class="form-control text-right" type="text" [(ngModel)]="filialRelatorio" />
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirDtIni">
        <label class="d-block">Data inicial</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtIni" yearRange="2000:2030" [(ngModel)]="dtIni"></p-calendar>
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirDtFin">
        <label class="d-block">Data final</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtFin" yearRange="2000:2030" [(ngModel)]="dtFin"></p-calendar>
      </div>
    </div>


    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioExterno()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>
<div *ngIf="isLoadingInProgress" class="loading-layer">
  <h6>Processamento em andamento, favor aguardar... {{loadingProgress}}%</h6>
  <p-progressBar [value]="loadingProgress" style="width: 40% !important"></p-progressBar>
</div>
