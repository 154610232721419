<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>


  <div class="row">
    <div class="col">

    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> {{subtitle}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="empresaOk">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio()"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio()" matTooltip="Orçamento gerencial"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Orçamento gerencial</span></h5>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- MODAL -->
  <p-dialog header="Orçamento Gerencial" [modal]="true" appendTo="body" [style]="{width: '800px'}" styleClass="lks-modal"
    [(visible)]="modalOrca" [responsive]="true" [closable]="true">


    <div class="row" *ngIf="dblinkedSessionService.empresa.id === 1 || dblinkedSessionService.empresa.id === 115 || dblinkedSessionService.empresa.id === 133">
      <div class="col-sm-6">
        <div class="row">
          <div class="col">
            <h3 class="sub-title mt-3"><i class="fas fa-code-branch"></i> Versões</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Versão orçamento</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a versão" [options]="versaoList"
              [(ngModel)]="versaoId" appendTo="body"></p-dropdown>
          </div>
          <div class="form-group col-sm-12">
            <label>Modelo de relatório</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o modelo do relatório" [options]="opcoesModelo"
              [(ngModel)]="modeloSelect" appendTo="body"></p-dropdown>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col">
            <h3 class="sub-title mt-3"><i class="fas fa-filter"></i> Filtros</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Classificação</label>
            <p-multiSelect class="type-p-mult" [options]="opcoesClass" defaultLabel="Selecione classificação"
              [filter]="true" [(ngModel)]="classSelecionadas" appendTo="body"
              placeholder="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Área</label>
            <p-multiSelect class="type-p-mult" [options]="opcoesArea" defaultLabel="Selecione área" [filter]="true"
            [(ngModel)]="areaSelecionadas" placeholder="{0} itens selecionados"
              selectedItemsLabel="{0} itens selecionados" appendTo="body"></p-multiSelect>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Centro de custo</label>
            <p-multiSelect class="type-p-mult" [options]="opcoesCcus" defaultLabel="Selecione centro de custo"
              [filter]="true" [(ngModel)]="ccusSelecionados" placeholder="{0} itens selecionados"
              selectedItemsLabel="{0} itens selecionados" appendTo="body"></p-multiSelect>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Projeto</label>
            <p-multiSelect class="type-p-mult" [options]="opcoesProj" defaultLabel="Selecione projeto"
              [filter]="true" [(ngModel)]="projSelecionados" placeholder="{0} itens selecionados"
              selectedItemsLabel="{0} itens selecionados" appendTo="body"></p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="dblinkedSessionService.empresa.id !== 1 && dblinkedSessionService.empresa.id !== 115 && dblinkedSessionService.empresa.id !== 133">
      <div class="col-sm-12">
        <div class="row">
          <div class="col">
            <h3 class="sub-title mt-3"><i class="fas fa-code-branch"></i> Versões</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Selecionar Configuração</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a configuração" [options]="configList"
              [(ngModel)]="configId" appendTo="body"></p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Versão orçamento</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a versão" [options]="versaoList"
              [(ngModel)]="versaoId" appendTo="body"></p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Moeda</label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a moeda" [options]="opcoesMoeda"
              [(ngModel)]="moeda" appendTo="body"></p-dropdown>
          </div>
        </div>
    </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-primary" matTooltip="Exportar em Excel" matTooltipPosition="right"
        matTooltipPosition="right" [disabled]="((dblinkedSessionService.empresa.id !== 1 &&
                                                dblinkedSessionService.empresa.id !== 115 &&
                                                dblinkedSessionService.empresa.id !== 133) && (versaoId === null || configId === null || moeda === null)) ||
                                                ((dblinkedSessionService.empresa.id == 1 ||
                                                dblinkedSessionService.empresa.id == 115 ||
                                                dblinkedSessionService.empresa.id == 133) && (versaoId === null || modeloSelect === null))" (click)="baixarOrca()"><i class="fas fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>
