<mat-card class="card-linnks margin-linnks">
  <mat-card-content class="mat-card-linnks" [ngStyle]="{'width': larguraImpressao+'%'}">
    <div id="div-botoes">
      <div class="margin-botoes">
        <button type="button" (click)="print()" mat-raised-button pButton label="Imprimir" class="teste" ></button>
      </div>
    </div>

    <div id="container-impressao" *ngIf="pedidoIdioma === 'pt'">
      <table width="100%" cellspacing="10" cellpadding="0" class="padding-bottom20">
        <tr>
          <td width="50%" class="vertical-middle" style="text-align:center;">
            <!--<img *ngIf="dblinkedSessionService.empresa.id === 158" src="http://www.idea-ip.com/uploads/2/9/6/9/29690155/8226944.jpg" alt="" />-->
            <img src='data:image/jpg;base64,{{infosPedido.empLogo}}'/>
          </td>
          <td width="50%">
            <table cellspacing="0" cellpading="0" class="italic">
              <tr><td colspan="2" class="px16 bold uppercase">{{ infosPedido.razaoSocial }}</td></tr>
              <tr><td colspan="2" class="px12">{{ infosPedido.endereco }}, {{ infosPedido.endNumero }},  {{ infosPedido.endComplnto }}</td></tr>
              <tr><td colspan="2" class="px12">{{ infosPedido.endCidade }} - {{ infosPedido.endUf }} - CEP {{ infosPedido.endCep }} - {{ infosPedido.endPais }}</td></tr>
              <tr><td colspan="2" class="px12">CNPJ: {{ infosPedido.cnpj }}</td></tr>
              <tr><td colspan="2" class="px12 uppercase">INSCRIÇÃO ESTADUAL: {{ infosPedido.ie }}</td></tr>
              <tr>
                <td colspan="2" class="px12 bold" *ngIf="infosPedido.fornecedorTipo === 'F'">Comprador: {{ infosPedido.nomeComprador }}</td>
                <td colspan="2" class="px12 bold" *ngIf="infosPedido.fornecedorTipo === 'C'">Vendedor: {{ infosPedido.nomeComprador }}</td>
              </tr>
              <tr>
                <td width="50%" class="px12">Tel: {{ infosPedido.foneComprador }}</td>
                <td width="50%" class="px12">E-mail: {{ infosPedido.emailComprador }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="border-top border-bottom">
        <tr class="px13 vertical-top">
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'F'" class="padding5"><span class="px14 bold uppercase">Pedido de compra:</span> {{ infosPedido.pedidoNum }}</td>
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'C'" class="padding5"><span class="px14 bold uppercase">Pedido de venda:</span> {{ infosPedido.pedidoNum }}</td>
          <td width="30%" class="padding5"><span class="px14 bold uppercase">Data:</span> {{ infosPedido.dataPedido }}</td>
          <td width="20%" class="padding5"><span class="px14 bold uppercase">Status:</span> {{ infosPedido.status }}</td>
        </tr>
        <tr class="px13 vertical-top">
          <td class="padding5 border-top" *ngIf="dblinkedSessionService.empresa.id === 158"><span class="px14 bold uppercase">N° JIRA:</span> {{ infosPedido.codigoControle }}</td>
          <td class="padding5 border-top" *ngIf="dblinkedSessionService.empresa.id !== 158"><span class="px14 bold uppercase">CÓDIGO DE CONTROLE:</span> {{ infosPedido.codigoControle }}</td>
          <td class="padding5 border-top"><span class="px14 bold uppercase">Centro de Custo / Projeto:</span>{{infosPedido.ccProj}}</td>
          <td class="padding5 border-top"><span class="px14 bold uppercase"></span> </td>
        </tr>
      </table>

      <table width="100%" cellspacing="5" cellpadding="0" class="padding-top15 padding-bottom15">
        <tr class="px13">
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'F'"><span class="px14 bold">Fornecedor:</span> {{ infosPedido.razaoSocialPart }}</td>
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'C'"><span class="px14 bold">Cliente:</span> {{ infosPedido.razaoSocialPart }}</td>
          <td><span class="px14">CNPJ:</span> {{ infosPedido.cnpjPart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">Endereço:</span> {{ infosPedido.enderecoPart }}, {{ infosPedido.endNumeroPart }}, {{ infosPedido.endComplntoPart }} , {{ infosPedido.endBairroPart }}</td>
          <td><span class="px14">I.E:</span> {{ infosPedido.iePart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">Cidade/Estado:</span> {{ infosPedido.endCidadePart }}/{{ infosPedido.endUfPart }}</td>
          <td><span class="px14">Contato:</span> {{ infosPedido.contatoPart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">CEP:</span> {{ infosPedido.endCepPart }}</td>
          <td><span class="px14">Telefone:</span> {{ infosPedido.foneCompPart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">País:</span> {{ infosPedido.endPaisPart }}</td>
          <td><span class="px14">E-mail:</span> {{ infosPedido.emailCompPart }}</td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="border-top border-bottom">
        <tr class="px14 bold text-center">
          <td width="25%" class="padding3 border-right border-bottom ">Orçamento N°</td>
          <td width="25%" class="padding3 border-right border-bottom ">Método de Envio</td>
          <td width="25%" class="padding3 border-right border-bottom ">INCOTERMS</td>
          <td width="25%" class="padding3 border-bottom">Condição de Pagamento</td>
        </tr>
        <tr class="px13 text-center vertical-top">
          <td class="padding3 border-right">{{ infosPedido.numOrcamento }}</td>
          <td class="padding3 border-right">{{ infosPedido.metodoEnvio }}</td>
          <td class="padding3 border-right">{{ infosPedido.incoterms }}</td>
          <td class="padding3">{{ infosPedido.condPagmnto }}</td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="px11 padding-top20">
        <tr class="px12 bold text-center">
          <td width="80px" class="padding5 border1 border-top border-right border-bottom">Código</td>
          <td width="305px" class="padding5 border1 border-top border-right border-bottom">Descrição</td>
          <td width="35px" class="padding5 border1 border-top border-right border-bottom">UN</td>
          <td width="35px" class="padding5 border1 border-top border-right border-bottom">Qtd.</td>
          <td width="110px" class="padding5 border1 border-top border-right border-bottom">Data de Entrega</td>
          <td width="100px" class="padding5 border1 border-top border-right border-bottom">NCM</td>
          <td width="50px" class="padding5 border1 border-top border-right border-bottom">Moeda</td>
          <td width="100px" class="text-right padding5 border1 border-top border-right border-bottom">Preço Unit.</td>
          <td width="100px" class="text-right border1 border-top border-bottom">Preço Total</td>
        </tr>
        <ng-container *ngFor="let p of infosPedidoProd;let i = index">
          <tr class="vertical-top" *ngIf="i<30">
            <td class="padding5 border1 border-right border-bottom">{{p.codigo}}</td>
            <td class="padding5 border1 border-right border-bottom">{{p.descricao}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.unidade}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.quantidade}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.dtEntrega}}</td>
            <td class="text-left padding5 border1 border-right border-bottom">{{p.ncm}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.moeda}}</td>
            <td class="text-right padding5 border1 border-right border-bottom">{{ p.vlrUnitario | money }}</td>
            <td class="text-right padding5 border1 border-bottom">{{p.vlrItem | money}}</td>
          </tr>
        </ng-container>
        <ng-container *ngFor = "let n of qtdLinhas">
          <tr *ngIf="infosPedidoProd.length < 30">
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-bottom">&nbsp;</td>
          </tr>
        </ng-container>
        <tr class="px13 text-right">
          <td colspan="8" class="padding5 border1 border-right">Subtotal:</td>
          <td class="padding5 border1 border-bottom">{{ subTotal | money }}</td>
        </tr>
        <tr class="px13 text-right">
          <td colspan="8" class="padding5 border1 border-right">Desconto:</td>
          <td class="padding5 border1 border-bottom">{{ descTotal | money }}</td>
        </tr>
        <tr class="px13 text-right">
          <td colspan="8" class="padding5 border1 border-right">Outros (Taxas)</td>
          <td class="padding5 border1 border-bottom">{{ vlrOutros | money }}</td>
        </tr>
        <tr class="px14 bold text-right">
          <td colspan="8" class="padding5 border1 border-right">TOTAL</td>
          <td class="padding5 border1 border-bottom">{{ vlrTotal | money }}</td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="margin-top20 border1 border-top">
        <tr>
          <td width="50%" class="px12 bold uppercase padding5 border1 border-right">Local de entrega:</td>
          <td width="50%" class="px12 bold uppercase padding5 border1">Local de cobrança:</td>
        </tr>
        <tr>
          <td class="border1 border-right padding5 vertical-top">
            <table width="100%" cellspacing="0" cellpadding="0" class="px13">
              <tr><td class="bold uppercase">{{ infosPedido.enderecoEntrega }}</td></tr>
            </table>
          </td>
          <td class="border1 padding5 vertical-top">
            <table width="100%" cellspacing="0" cellpadding="0" class="px13">
              <tr><td class="bold uppercase">{{ infosPedido.enderecoCobranca }}</td></tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="padding-top20 padding-bottom20 border1 border-top">
        <tr>
          <td width="25px" class="px13 bold uppercase padding5 vertical-top">OBS:</td>
          <td width="100%" class="px12 padding5">
            {{ infosPedido.dadosAdicionais }}
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="padding-bottom20 border1 border-top">
        <tr>
          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomeUsuario !== null">
            <img *ngIf="infosPedido.assinatura !== null" src='data:image/jpg;base64,{{infosPedido.assinatura}}' width="100%" />

            <div *ngIf="infosPedido.assinatura !== null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:-38px 0 0;">{{infosPedido.nomeUsuario}}</div>
            <div *ngIf="infosPedido.assinatura === null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:60px 0 0;">{{infosPedido.nomeUsuario}}</div>
          </td>
          <td width="33.33%" class="text-center padding10 vertical-bottom"  *ngIf="infosPedido.nomeUsuario === null">
            <img src='' width="100%"/>
            <div class="" style="position:relative; z-index:5; margin:-38px 0 0;"></div>
          </td>

          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomePrimeira !== 'null'">
            <img *ngIf="infosPedido.assinaturaPrimeira !== null" src='data:image/jpg;base64,{{infosPedido.assinaturaPrimeira}}' width="100%" />
            <div *ngIf="infosPedido.assinaturaPrimeira !== null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:-40px 0 0;">{{infosPedido.nomePrimeira}}</div>
            <div *ngIf="infosPedido.assinaturaPrimeira === null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:60px 0 0;">{{infosPedido.nomePrimeira}}</div>
          </td>
          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomePrimeira === 'null'">
            <img src='' width="100%"/>
            <div class="" style="position:relative; z-index:5; margin:-40px 0 0;"></div>
          </td>

          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomeSegunda !== 'null'">
            <img *ngIf="infosPedido.assinaturaSegunda !== null" src='data:image/jpg;base64,{{infosPedido.assinaturaSegunda}}' width="100%" />
            <div *ngIf="infosPedido.assinaturaSegunda !== null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:-50px 0 0;">{{infosPedido.nomeSegunda}}</div>
            <div *ngIf="infosPedido.assinaturaSegunda === null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:60px 0 0;">{{infosPedido.nomeSegunda}}</div>
          </td>
          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomeSegunda === 'null'">
            <img src='' width="100%"/>
            <div class="" style="position:relative; z-index:5; margin:-50px 0 0;"></div>
          </td>
        </tr>
      </table>

    </div>

    <div id="container-impressao" *ngIf="pedidoIdioma === 'en'">
      <table width="100%" cellspacing="10" cellpadding="0" class="padding-bottom20">
        <tr>
          <td width="50%" class="vertical-middle" style="text-align:center;">
            <!--<img *ngIf="dblinkedSessionService.empresa.id === 158" src="http://www.idea-ip.com/uploads/2/9/6/9/29690155/8226944.jpg" alt="" />-->
            <img src='data:image/jpg;base64,{{infosPedido.empLogo}}'/>
          </td>
          <td width="50%">
            <table cellspacing="0" cellpading="0" class="italic">
              <tr><td colspan="2" class="px16 bold uppercase">{{ infosPedido.razaoSocial }}</td></tr>
              <tr><td colspan="2" class="px12">Address: {{ infosPedido.endereco }}, {{ infosPedido.endNumero }},  {{ infosPedido.endComplnto }}</td></tr>
              <tr><td colspan="2" class="px12">{{ infosPedido.endCidade }} - {{ infosPedido.endUf }} - ZIP-CODE {{ infosPedido.endCep }} - {{ infosPedido.endPais }}</td></tr>
              <tr><td colspan="2" class="px12">Tax Id: {{ infosPedido.cnpj }}</td></tr>
              <tr><td colspan="2" class="px12 uppercase">State Tax Number: {{ infosPedido.ie }}</td></tr>
              <tr>
                <td colspan="2" class="px12 bold" *ngIf="infosPedido.fornecedorTipo === 'F'">Buyer: {{ infosPedido.nomeComprador }}</td>
                <td colspan="2" class="px12 bold" *ngIf="infosPedido.fornecedorTipo === 'C'">Seller: {{ infosPedido.nomeComprador }}</td>
              </tr>
              <tr>
                <td width="50%" class="px12">Tel: {{ infosPedido.foneComprador }}</td>
                <td width="50%" class="px12">E-mail: {{ infosPedido.emailComprador }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="border-top border-bottom">
        <tr class="px13 vertical-top">
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'F'" class="padding5"><span class="px14 bold uppercase">Purchase order:</span> {{ infosPedido.pedidoNum }}</td>
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'C'" class="padding5"><span class="px14 bold uppercase">Sales order:</span> {{ infosPedido.pedidoNum }}</td>
          <td width="30%" class="padding5"><span class="px14 bold uppercase">Date:</span> {{ infosPedido.dataPedido }}</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Em negociação'"><span class="px14 bold uppercase">Status:</span>Under negotiation</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Aguardando Aprovação'"><span class="px14 bold uppercase">Status:</span>Waiting for approval</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Pré-Aprovado'"><span class="px14 bold uppercase">Status:</span>Pre approved</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Aprovado'"><span class="px14 bold uppercase">Status:</span>Approved</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Recusado'"><span class="px14 bold uppercase">Status:</span>Declined</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Compra'"><span class="px14 bold uppercase">Status:</span>Purchase</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Compra Direta'"><span class="px14 bold uppercase">Status:</span>Purchase</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Venda'"><span class="px14 bold uppercase">Status:</span>Sales</td>
          <td width="20%" class="padding5" *ngIf="infosPedido.status === 'Venda Direta'"><span class="px14 bold uppercase">Status:</span>Sales</td>
        </tr>
        <tr class="px13 vertical-top">
          <td class="padding5 border-top" *ngIf="dblinkedSessionService.empresa.id === 158"><span class="px14 bold uppercase">N° JIRA:</span> {{ infosPedido.codigoControle }}</td>
          <td class="padding5 border-top" *ngIf="dblinkedSessionService.empresa.id !== 158"><span class="px14 bold uppercase">CÓDIGO DE CONTROLE:</span> {{ infosPedido.codigoControle }}</td>
          <td class="padding5 border-top"><span class="px14 bold uppercase">Project/Application:</span> {{infosPedido.ccProj}}</td>
          <td class="padding5 border-top"><span class="px14 bold uppercase"></span> </td>
        </tr>
      </table>

      <table width="100%" cellspacing="5" cellpadding="0" class="padding-top15 padding-bottom15">
        <tr class="px13">
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'F'"><span class="px14 bold">Supplier:</span> {{ infosPedido.razaoSocialPart }}</td>
          <td width="50%" *ngIf="infosPedido.fornecedorTipo === 'C'"><span class="px14 bold">Client:</span> {{ infosPedido.razaoSocialPart }}</td>
          <td><span class="px14">TAX ID:</span> {{ infosPedido.cnpjPart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">Address:</span> {{ infosPedido.enderecoPart }}, {{ infosPedido.endNumeroPart }}, {{ infosPedido.endComplntoPart }} , {{ infosPedido.endBairroPart }}</td>
          <td></td>
        </tr>
        <tr class="px13">
          <td><span class="px14">City/State:</span> {{ infosPedido.endCidadePart }}/{{ infosPedido.endUfPart }}</td>
          <td><span class="px14">Contact:</span> {{ infosPedido.contatoPart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">Zip Code:</span> {{ infosPedido.endCepPart }}</td>
          <td><span class="px14">Telephone:</span> {{ infosPedido.foneCompPart }}</td>
        </tr>
        <tr class="px13">
          <td><span class="px14">Country:</span> {{ infosPedido.endPaisPart }}</td>
          <td><span class="px14">E-mail:</span> {{ infosPedido.emailCompPart }}</td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="border-top border-bottom">
        <tr class="px14 bold text-center">
          <td width="25%" class="padding3 border-right border-bottom ">Quotation</td>
          <td width="25%" class="padding3 border-right border-bottom ">Shipping Method</td>
          <td width="25%" class="padding3 border-right border-bottom ">INCOTERMS</td>
          <td width="25%" class="padding3 border-bottom">Payment Terms</td>
        </tr>
        <tr class="px13 text-center vertical-top">
          <td class="padding3 border-right">{{ infosPedido.numOrcamento }}</td>
          <td class="padding3 border-right">{{ infosPedido.metodoEnvio }}</td>
          <td class="padding3 border-right">{{ infosPedido.incoterms }}</td>
          <td class="padding3">{{ infosPedido.formaPagamentoEn }}</td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="px11 padding-top20">
        <tr class="px12 bold text-center">
          <td width="80px" class="padding5 border1 border-top border-right border-bottom">Part number</td>
          <td width="305px" class="padding5 border1 border-top border-right border-bottom">Description</td>
          <td width="35px" class="padding5 border1 border-top border-right border-bottom">UM</td>
          <td width="35px" class="padding5 border1 border-top border-right border-bottom">Qty.</td>
          <td width="110px" class="padding5 border1 border-top border-right border-bottom">Delivery</td>
          <td width="100px" class="padding5 border1 border-top border-right border-bottom">NCM</td>
          <td width="50px" class="padding5 border1 border-top border-right border-bottom">Currency</td>
          <td width="100px" class="text-right padding5 border1 border-top border-right border-bottom">Unit Price</td>
          <td width="100px" class="text-right border1 border-top border-bottom">Total Price</td>
        </tr>
        <ng-container *ngFor="let p of infosPedidoProd;let i = index">
          <tr class="vertical-top" *ngIf="i<30">
            <td class="padding5 border1 border-right border-bottom">{{p.itemFornecedor}}</td>
            <td class="padding5 border1 border-right border-bottom">{{p.descricaoEn}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.unidade}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.quantidade}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.dtEntrega}}</td>
            <td class="text-left padding5 border1 border-right border-bottom">{{p.ncm}}</td>
            <td class="text-center padding5 border1 border-right border-bottom">{{p.moeda}}</td>
            <td class="text-right padding5 border1 border-right border-bottom">{{ p.vlrUnitario | money }}</td>
            <td class="text-right padding5 border1 border-bottom">{{p.vlrItem | money}}</td>

          </tr>
        </ng-container>
        <ng-container *ngFor = "let n of qtdLinhas">
          <tr  *ngIf="infosPedidoProd.length < 30">
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-right border-bottom"></td>
            <td class="padding5 border1 border-bottom">&nbsp;</td>
          </tr>
        </ng-container>
        <tr class="px13 text-right">
          <td colspan="8" class="padding5 border1 border-right">Subtotal:</td>
          <td class="padding5 border1 border-bottom">{{ subTotal | money }}</td>
        </tr>
        <tr class="px13 text-right">
          <td colspan="8" class="padding5 border1 border-right">Discount:</td>
          <td class="padding5 border1 border-bottom">{{ descTotal | money }}</td>
        </tr>
        <tr class="px13 text-right">
          <td colspan="8" class="padding5 border1 border-right">Other/Tax</td>
          <td class="padding5 border1 border-bottom">{{ vlrOutros | money }}</td>
        </tr>
        <tr class="px14 bold text-right">
          <td colspan="8" class="padding5 border1 border-right">TOTAL</td>
          <td class="padding5 border1 border-bottom">{{ vlrTotal | money }}</td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="margin-top20 border1 border-top">
        <tr>
          <td width="50%" class="px12 bold uppercase padding5 border1 border-right">Ship To:</td>
          <td width="50%" class="px12 bold uppercase padding5 border1">Bill To:</td>
        </tr>
        <tr>
          <td class="border1 border-right padding5 vertical-top">
            <table width="100%" cellspacing="0" cellpadding="0" class="px13">
              <tr><td class="bold uppercase">{{ infosPedido.enderecoEntrega }}</td></tr>
            </table>
          </td>
          <td class="border1 padding5 vertical-top">
            <table width="100%" cellspacing="0" cellpadding="0" class="px13">
              <tr><td class="bold uppercase">{{ infosPedido.enderecoCobranca }}</td></tr>
            </table>
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="padding-top20 padding-bottom20 border1 border-top">
        <tr>
          <td width="25px" class="px13 bold uppercase padding5 vertical-top">OBS:</td>
          <td width="100%" class="px12 padding5">
            {{ infosPedido.dadosAdicionais }}
          </td>
        </tr>
      </table>

      <table width="100%" cellspacing="0" cellpadding="0" class="padding-bottom20 border1 border-top">
        <tr>
          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomeUsuario !== null">
            <img *ngIf="infosPedido.assinatura !== null" src='data:image/jpg;base64,{{infosPedido.assinatura}}' width="100%" />

            <div *ngIf="infosPedido.assinatura !== null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:-38px 0 0;">{{infosPedido.nomeUsuario}}</div>
            <div *ngIf="infosPedido.assinatura === null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:60px 0 0;">{{infosPedido.nomeUsuario}}</div>
          </td>
          <td width="33.33%" class="text-center padding10 vertical-bottom"  *ngIf="infosPedido.nomeUsuario === null">
            <img src='' width="100%"/>
            <div class="" style="position:relative; z-index:5; margin:-38px 0 0;"></div>
          </td>

          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomePrimeira !== 'null'">
            <img *ngIf="infosPedido.assinaturaPrimeira !== null" src='data:image/jpg;base64,{{infosPedido.assinaturaPrimeira}}' width="100%" />
            <div *ngIf="infosPedido.assinaturaPrimeira !== null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:-40px 0 0;">{{infosPedido.nomePrimeira}}</div>
            <div *ngIf="infosPedido.assinaturaPrimeira === null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:60px 0 0;">{{infosPedido.nomePrimeira}}</div>
          </td>
          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomePrimeira === 'null'">
            <img src='' width="100%"/>
            <div class="" style="position:relative; z-index:5; margin:-40px 0 0;"></div>
          </td>

          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomeSegunda !== 'null'">
            <img *ngIf="infosPedido.assinaturaSegunda !== null" src='data:image/jpg;base64,{{infosPedido.assinaturaSegunda}}' width="100%" />
            <div *ngIf="infosPedido.assinaturaSegunda !== null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:-50px 0 0;">{{infosPedido.nomeSegunda}}</div>
            <div *ngIf="infosPedido.assinaturaSegunda === null" class="px14 bold border1 border-top padding-top15" style="position:relative; z-index:5; margin:60px 0 0;">{{infosPedido.nomeSegunda}}</div>
          </td>
          <td width="33.33%" class="text-center padding10 vertical-bottom" *ngIf="infosPedido.nomeSegunda === 'null'">
            <img src='' width="100%"/>
            <div class="" style="position:relative; z-index:5; margin:-50px 0 0;"></div>
          </td>
        </tr>
      </table>

    </div>
  </mat-card-content>
</mat-card>
