<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>

  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> RELATÓRIOS ESTOQUE PARA EXPORTAÇÃO EM PDF</h3>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorioEstoque('KARDEX')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorioEstoque('KARDEX')" matTooltip="Kardex"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">KARDEX</span></h5>
            <!-- <p class="card-text"><small class="text-muted">Acessado última vez: <span class="date"> 4 dias</span></small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorioEstoque('POSICAO_ESTOQUE')"><i
                class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorioEstoque('POSICAO_ESTOQUE')"
              matTooltip="Posição de Estoque" matTooltipPosition="above" style="width: 150px;"><span
                class="d-block">POSIÇÃO
                ESTOQUE</span></h5>
            <!-- <p class="card-text"><small class="text-muted">Acessado última vez: <span class="date"> 4 dias</span></small></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- MODAL KARDEX -->
  <p-dialog header="KARDEX" [modal]="true" appendTo="body" [style]="{width: '900px'}" styleClass="modal-initial"
    [(visible)]="modalKardex" [responsive]="true" [closable]="true" *ngIf="modalKardex">
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" [readonlyInput]="true" dateFormat="dd/mm/yy" [(ngModel)]="dataIni"
          [monthNavigator]="true" appendTo="body" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>

      <div class="form-group col-sm-2">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" [readonlyInput]="true" dateFormat="dd/mm/yy" [(ngModel)]="dataFin"
          [monthNavigator]="true" appendTo="body" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>

      <div class="form-group col-sm-8" style="margin-top: 6px">
        <label class="d-block" style="visibility: hidden;">Do not Remove</label>
        <div class="p-field-checkbox mb-3 ml-1">
          <p-checkbox class="mr-1" [(ngModel)]="descenglish" [binary]="true" inputId="descenglish"></p-checkbox>
          <label for="descenglish" class="ml-1" style="position:relative; margin-top: 4px">Incluir Descrição em
            inglês</label>
        </div>
      </div>

    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Almoxarifado</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesAlmoxarifado" [(ngModel)]="almoxarifadoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" panelStyleClass="custom-width">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label>Item</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesItens" [(ngModel)]="itensSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" panelStyleClass="custom-width">
        </p-multiSelect>
      </div>
      <!--<div class="form-group col">
        <label>Grupo de Estoque</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesGruposEstoque" [(ngModel)]="gruposSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} grupos selecionados"></p-multiSelect>
      </div>-->
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="almoxarifadoEstoque()"><i class="fas fa-file-pdf"></i>
        PDF</button>
    </p-footer>
  </p-dialog>
  <!-- FIM DA MODAL KARDEX -->

  <!-- MODAL Relatório Posição de Estoque -->
  <p-dialog header="Relatório Posição de Estoque" [modal]="true" appendTo="body" [style]="{width: '900px'}"
    styleClass="modal-initial" [(visible)]="modalPosicaoEstoque" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Data de corte</label>
        <p-calendar class="type-p-calendar" [readonlyInput]="true" dateFormat="dd/mm/yy" [(ngModel)]="dataFin"
          [monthNavigator]="true" appendTo="body" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <!--<div class="form-group col">
        <label class="d-block">Data Final</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin"
          [options]="meses" defaultLabel="Selecione" [filter]="true"></p-dropdown>
      </div>-->

      <div class="form-group col-sm-10" style="margin-top: 6px">
        <label class="d-block" style="visibility: hidden;">Do not Remove</label>
        <div class="p-field-checkbox mb-3 ml-1">
          <p-checkbox class="mr-1" [(ngModel)]="descenglish" [binary]="true" inputId="descenglish"></p-checkbox>
          <label for="descenglish" class="ml-1" style="position:relative; margin-top: 4px;">Incluir Descrição em
            inglês</label>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Almoxarifado</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesAlmoxarifado" [(ngModel)]="almoxarifadoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" panelStyleClass="custom-width">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label>Item</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesItens" [(ngModel)]="itensSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" panelStyleClass="custom-width">
        </p-multiSelect>
      </div>
      <!--<div class="form-group col">
        <label>Grupo de Estoque</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesGruposEstoque" [(ngModel)]="gruposSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} grupos selecionados"></p-multiSelect>
      </div>-->
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="almoxarifadoEstoque()"><i class="fas fa-file-pdf"></i>
        PDF</button>
    </p-footer>
  </p-dialog>
  <!-- FIM DA MODAL Relatório Posição de Estoque -->
</mat-card>