<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content space">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-file"></i> Relatório</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="relatorios" style="width: 500px;"
                placeholder="Selecione o Relatório" [(ngModel)]="reportId" (onChange)="buscarUsuarios()"></p-dropdown>
            </div>
          </div>
            <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-users"></i> Usuários</h3>
            </div>
            </div>
            <div class="row">
            <div class="col-sm-6">
              <div class="conteudo m-0">
                <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Usuários Relacionados" [source]="listaUsuariosRelacionar"
                  [target]="listaUsuariosRelacionados" [dragdrop]="true" [responsive]="true" filterBy="mail"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-resp pTemplate="item">{{resp.nome}} - {{resp.mail}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
  </mat-card-content>

</mat-card>
