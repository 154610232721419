<mat-card class="card-linnks margin-linnks">

  <mat-card-title style="margin-left: 2px;">
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content style="width: 50vw;">
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box" style="padding: 10px;">

          <div class="actions-card"
            style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: flex-end;">
            <app-toolbar [value]="toolbarMain"></app-toolbar>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-user"></i> Operação contábil</h3>
            </div>
          </div>

          <div class="dropdows">
            <div class="option">
              <p style="font-weight: bold;">Operações de pagamento</p>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoPagamento" placeholder="Selecione"
                    [(ngModel)]="operacaoSelecionada" (onChange)="buscarOperacoesRelacionadas()">
              </p-dropdown>
            </div>

            <div class="option">
              <p style="font-weight: bold;">Operações de recebimento</p>

              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoRecebimento" placeholder="Selecione"
                    [(ngModel)]="operacaoSelecionada" (onChange)="buscarOperacoesRelacionadas()">
              </p-dropdown>

            </div>
          </div>

          <div class="row" style="margin-top: 10px;">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas  fa-hand-holding-usd"></i> Operações de origem externa</h3>
            </div>
          </div>

          <p-pickList [showSourceControls]="false" [showTargetControls]="false" sourceHeader="Disponíveis"
            targetHeader="Operações relacionadas" [source]="operacoesDisponiveis" [target]="operacoesVinculadas"
            [dragdrop]="true" [responsive]="true" filterBy="descricao">
            <ng-template let-item pTemplate="item">
              {{ item.idOrigem }} - {{ item.descricao }}
            </ng-template>
          </p-pickList>

        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>