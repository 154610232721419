<div class="containerCustom">

  <div class="approvs" *ngIf="!isLoading">
    <div *ngFor="let row of approves; index as i">
      <div class="section" (click)="selectApprov(row.id)" [ngClass]="{ selected: row.isSelected }">
        <div class="section-content">
          <small>Numero: {{row.codigo}} - Prioridade: {{row.prioridade}} - {{row.moeda.simbolo}}{{row.valor | money}}</small>
          <h2>{{row.titulo}}</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="tabs" *ngIf="!isLoading && !contentLoading">
    <div class="sidebar-header" *ngIf="itemSelected !== null">
      <ul class="list-inline filter-menu mb-3">
        <li class="list-inline-item" [ngClass]="{ active: showPreview }">
          <a (click)="changeTab(0)">Preview</a>
        </li>
        <li class="list-inline-item" [ngClass]="{ active: showComments }">
          <a (click)="changeTab(1)">Comentários</a>
        </li>
        <li class="list-inline-item" [ngClass]="{ active: showAttachments }">
          <a (click)="changeTab(2)">Anexos</a>
        </li>
        <li class="list-inline-item" [ngClass]="{ active: showActivity }">
          <a (click)="changeTab(3)">Atividades</a>
        </li>
        <li class="list-inline-item" [ngClass]="{ active: showJustification }">
          <a (click)="changeTab(4)">Justificativa de compra</a>
        </li>
      </ul>
      <div class="sidebar-header-filter">
        <div class="sidebar-filter" *ngIf="(showPreview && rowsSelected.length > 1) || showComments || showAttachments || showActivity">
          <div class="icon-append">
            <span class="material-icons">search</span>
          </div>
          <input class="input-filter" [placeholder]="filterPlaceholder" type="text" [(ngModel)]="contentFilter" (ngModelChange)="onFilter(tab)"/>
        </div>
        <button *ngIf="showActivity" type="button" matTooltip="{{onlyApprov ? 'Todas as atividades' : 'Apenas aprovações'}}" matTooltipPosition="above" class="btnCustom-default" (click)="onlyApprovs()" [ngClass]="{ btn_bg_green: onlyApprov }">
          <span class="material-icons">done_all</span>
        </button>
        <button [disabled]="onlyApprov" *ngIf="showActivity" type="button" matTooltip="{{withComments ? 'Ocultar comentarios' : 'Ver comentarios'}}" matTooltipPosition="above" class="btnCustom-default" (click)="showMessages()">
          <span *ngIf="withComments" class="material-icons">chat_bubble</span>
          <span *ngIf="!withComments" class="material-icons">hide_source</span>
        </button>
        <button *ngIf="showAttachments"  matTooltip="{{isCollapseAll ? 'Mostrar todos' : 'Ocultar todos'}}" type="button" class="btnCustom-default"(click)="collapseAll(tab)">
          <span *ngIf="!isCollapseAll" class="material-icons">expand_less</span>
          <span *ngIf="isCollapseAll" class="material-icons">expand_more</span>
        </button>
      </div>
    </div>

    <div *ngIf="showPreview && !contentLoading" class="sidebar-content">
      <div class="preview" *ngIf="itemSelected !== null">
        <div *ngFor="let row of approvSelected">
          <div class="preview-header">
            <small>{{row.codigo}}</small>
            <h2>{{row.titulo}}</h2>
          </div>
          <div class="preview-body">
            <div class="section-row">
              <div class="info-column" matTooltip="{{row.requisitante}}">
                <small>Requisitante:</small>
                <p>{{row.requisitante}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.labelAprovador}}">
                <small>Responsável:</small>
                <p>{{row.labelAprovador}}</p>
              </div>
              <div class="info-column" matTooltip="{{convertTimestamp(row.data)}}">
                <small>Data:</small>
                <p>{{convertTimestamp(row.data)}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.tipo}}">
                <small>Tipo de requisicao:</small>
                <p>{{row.tipo}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.objeto}}">
                <small>Objeto:</small>
                <p>{{row.objeto}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.categoria}}">
                <small>Categoria:</small>
                <p>{{row.categoria}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.origem}}">
                <small>Origem:</small>
                <p>{{row.origem}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.moeda.codigo + ' - ' + row.moeda.descricao}}">
                <small>Moeda:</small>
                <p>{{row.moeda.codigo + ' - ' + row.moeda.descricao}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.prioridade}}">
                <small>Prioridade:</small>
                <p>{{row.prioridade}}</p>
              </div>
              <div class="info-column" matTooltip="{{row.moeda.simbolo}}{{row.valor | money}}">
                <small>Valor total:</small>
                <p>{{row.moeda.simbolo}}{{row.valor | money}}</p>
              </div>
            </div>
            <div class="section-row single">
              <div class="info-column">
                <small>Descrição:</small>
                <p class="desc" [innerHtml]="row.descricao | safeHtml"></p>
              </div>
            </div>
            <div class="itens">
              <small>Itens ({{row.itens.length}}):</small>
              <div class="item-container">
                <div class="item" *ngFor="let item of row.itens; let i = index" >
                  <div *ngIf="i < showingOff" (click)="selectItemApprov(item)" [ngClass]="{ isSelected: item.isSelected }">
                    <span matTooltip="{{item.descricao}}">{{item.descricao}}</span>
                    <span matTooltip="{{item.qtd}}">qtd: ({{item.qtd}})</span>
                    <span matTooltip="{{row.moeda.simbolo}}{{item.valorUnit | money}}"> {{row.moeda.simbolo}} {{item.valorUnit | money}}</span>
                  </div>
                </div>
              </div>
              <div class="item-footer">
                <small *ngIf="showingOff !== 5" class="show-more" (click)="showLess()">
                  <span class="material-icons">remove_circle_outline</span>
                  Exibir menos
                </small>
                <small *ngIf="!(showingOff >= row.itens.length)" class="show-more" (click)="showMore()">
                  <span class="material-icons">add_circle_outline</span>
                  Exibir mais
                </small>
              </div>
            </div>
            <div class="itens">
              <small>Centro de custos(s):</small>
              <div class="item-container">
                <div class="item ccus" *ngFor="let item of itemApportionment">
                  <div>
                    <span matTooltip="{{item.ccus}}">{{item.ccus}}</span>
                    <span matTooltip="{{item.proj}}">{{item.proj}}</span>
                    <span matTooltip="{{item.procentagem}}">{{item.porcentagem}}%</span>
                  </div>
                </div>
                <div class="ccus" *ngIf="itemApportionment.length === 0">
                  <span class="empty">Selecione um item para ver seu centro de custo</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="preview-empty" *ngIf="itemSelected === null">
        <span class="material-icons"> report </span>
        <h2>Não conseguimos encontrar informações!</h2>
        <p>Selecione uma aprovação.</p>
      </div>
    </div>

    <div *ngIf="showComments" class="sidebar-content">
      <div #comments class="comments">
        <div *ngFor="let message of messages">
          <div class="message">
            <div class="user">
              <h2 matTooltip="{{message.userName}}">{{isUser(message.userName)}}</h2>
            </div>
            <div class="content">
              <div class="footer">
                <p>{{message.userName}}</p>
                <small>{{convertDate(message.time)}}</small>
                <span *ngIf="userId === message.userId" matTooltip="Editar" (click)="editMessage(message)">Editar</span>
                <span *ngIf="userId === message.userId" matTooltip="Remover" (click)="deleteComment(message)">Remover</span>
              </div>
              <div>
                <p [innerHtml]="applyHighlights(message.message) | safeHtml"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showAttachments" class="sidebar-content">
      <div class="attachments">
        <div *ngFor="let attachment of attachments; index as i">
          <div class="section">
            <div class="section-header">
              <h2>{{attachment.section}}</h2>
              <button matTooltip="{{attachmentState[i].label ? 'Ver menos' : 'Ver mais'}}" type="button"
                class="btnCustom-default" style="margin-right: 0;" (click)="cardAttachmentsState(i)">
                <span *ngIf="attachmentState[i].label" class="material-icons">expand_less</span>
                <span *ngIf="!attachmentState[i].label" class="material-icons">expand_more</span>
              </button>
            </div>
            <div class="section-body" [@changeDivSize]=currentAttachmentState[i].label>
              <div *ngIf="attachment.files.length === 0">
                <p class="empty">Nenhum arquivo inserido nessa seção!</p>
              </div>
              <div *ngFor="let archive of attachment.files">
                <div (click)="downloadAttachment(archive)">
                  <span class="material-icons">description</span>
                  <p>{{archive.name}}</p>
                </div>
                <button matTooltip="{{'Remover ' + archive.name}}" type="button" class="btnDelete" (click)="deleteFile(archive)">
                  <span class="material-icons">delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showActivity" class="sidebar-content">
      <div class="activities">
        <div class="section" *ngFor="let activity of activities">
          <div class="section-header">
            <p>{{activity.userName}}</p>
            <small>{{convertDate(activity.time)}}</small>
          </div>
          <p [innerHtml]="applyHighlights(activity.action) | safeHtml"></p>
        </div>
      </div>
    </div>

    <div *ngIf="showJustification" class="sidebar-content">
      <div class="justification" *ngFor="let row of approvSelected">
        <div class="section">
          <small>Necessidade: </small>
          <p [innerHtml]="row.necessidade || 'nenhuma necessidade inserido!' | safeHtml"></p>
        </div>
        <div class="section">
          <small>Resultado esperado: </small>
          <p [innerHtml]="row.resultadoEsperado || 'nenhum resultado esperado inserido!' | safeHtml"></p>
        </div>
        <div class="section">
          <small>Fornecedor escolhido: </small>
          <p [innerHtml]="row.compraDirigida || 'nenhum fornecedor escolhido inserido!' | safeHtml"></p>
        </div>
      </div>
    </div>

    <div class="sidebar-footer">
      <div *ngIf="showPreview && itemSelected !== null && possibleApprove()">
        <div class="preview-approv">
          <div class="actions">
            <button *ngIf="!btnLoading" [matTooltip]="tooltipRefuse" type="button" class="btn-refuse" (click)="approve(false)">
              <span class="material-icons">do_disturb_alt</span>
              <div>Recusar</div>
            </button>
            <button *ngIf="!btnLoading" [matTooltip]="tooltipApprove" type="button" class="btn-approve" (click)="approve(true)">
              <span class="material-icons">done_all</span>
              <div>Aprovar</div>
            </button>
            <button *ngIf="btnLoading" [disabled]="true" type="button" class="btnCustom-default">
              <span class="material-icons loading-icon">hourglass_empty</span>
              <div>Carregando ...</div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="showComments">
        <div class="write-message">
          <div class="write-body">
            <div>
              <div id="mentionOptions" class="hidden">
                <span *ngFor="let user of mentionOptions" (click)="onSelectMention(user.id)">{{user.name}}</span>
              </div>
              <div class="container">
                <div #backdrop class="backdrop">
                  <div #highlights class="highlights" [innerHTML]="highlightedText"></div>
                </div>
                <textarea placeholder="Envie seu comentário" (keyup)="verifyKey($event)" [(ngModel)]="comment" (scroll)="handleScroll()" #textarea>
                </textarea>
              </div>
            </div>
            <button matTooltip="{{isEditMessage ? 'Salvar edição' : 'Enviar comentário'}}" type="button"
              class="btnCustom-green mt-3" (click)="isEditMessage ? updateComment() : saveComment()" style="margin-left: auto; margin-right: 0;">
                <span class="material-icons">send</span>
                <div>{{isEditMessage ? 'Salvar edição' : 'Enviar comentário'}}</div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="showAttachments">
        <div class="attaching">
          <div class="attaching-body">
            <p-dropdown class="dropdown-attachments" [options]="origins" [(ngModel)]="originSelected" placeholder="Selecione uma seção" appendTo="body" [filter]="false"></p-dropdown>
            <div class="upload-container ">
              <input type="file" id="btn-upload" hidden #file (change)="fileChange()"/>
              <label class="upload-btn" for="btn-upload">Selecionar arquivo</label>
              <span class="upload-name" #fileChoosen></span>
            </div>
            <button [disabled]="btnLoading" type="button" class="btnCustom-green" matTooltip="Anexar arquivo" matTooltipPosition="above" (click)="saveFile()">
              <span *ngIf="btnLoading" class="material-icons loading-icon">hourglass_empty</span>
              <div *ngIf="btnLoading">Carregando ...</div>
              <span *ngIf="!btnLoading" class="material-icons">send</span>
              <div *ngIf="!btnLoading">Anexar arquivo</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="approvs" *ngIf="isLoading">
    <div *ngFor="let c of cardsSelected">
      <p-skeleton width="100%" height="4rem" styleClass="mb-3"></p-skeleton>
    </div>
  </div>

  <p-skeleton *ngIf="isLoading || contentLoading" width="100%" height="100%"></p-skeleton>

</div>
