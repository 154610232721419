<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}. Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above">
                  <i class="far fa-file-excel excel"></i>
                </button>

                <button type="button" class="btn btn-xs btn-link" (click)="changeCardState()">
                  <span *ngIf="iconState" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">
                    expand_less
                  </span>
                  <span *ngIf="!iconState" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">
                    expand_more
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentState">

            <div class="clearfix"></div>

            <p-table class="table-system" #dt2 [columns]="colsBoletosGerados" [value]="boletosGerados" [rows]="rows"
              [paginator]="true" [totalRecords]="boletosGerados.length" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'vencimento'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'vencimento', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'referencia'">
                      <span class="text-overflow-dynamic-ellipsis">REFERÊNCIA <p-sortIcon [field]="'referencia'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'referencia', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                      <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
                  </th>

                  <th class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'nome', 'contains')">
                  </th>

                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'valor', 'contains')">
                  </th>


                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon></span>
                    </span>
                    <p-multiSelect class="type-p-mult" [options]="opcoesStatus" defaultLabel="Selecione" [filter]="false"
                      (onChange)="dt2.filter($event.value, 'status', 'in')"></p-multiSelect>
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>

                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="visualizarDetalheBoleto(rowData)"
                        matTooltip="{{rowData.vencimento}}">{{rowData.vencimento }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="visualizarDetalheBoleto(rowData)"
                        matTooltip="{{rowData.referencia}}">{{rowData.referencia }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="visualizarDetalheBoleto(rowData)"
                        matTooltip="{{rowData.cpf}}">{{rowData.cpf}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="visualizarDetalheBoleto(rowData)"
                        matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="visualizarDetalheBoleto(rowData)"
                        matTooltip="{{rowData.valor}}">{{rowData.valor }}</span>
                    </span>
                  </td>

                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="visualizarDetalheBoleto(rowData)"
                        matTooltip="{{rowData.status}}">{{rowData.status }}</span>
                    </span>
                  </td>


                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <p-dialog header="Detalhes do Boleto" [(visible)]="visualizarDetalhe" [style]="{width: '700px'}" [modal]="true"
      styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
      <div *ngIf="visualizarDetalhe">
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">Nome:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.nome }}</small>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">CPF:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.cpf }}</small>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">CÓDIGO DE BARRA:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.bar_code }}</small>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">LINK:</label>
          <div class="col-sm-9 text-left">
            <a class="btn btn-xs btn-link" style="padding: 0px !important;" target="_blank"
              href="{{detalheBoleto.payment_link}}">Download do Boleto</a>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">CÓDIGO TRANSAÇÃO:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.code }}</small>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">VENCIMENTO:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.vencimento }}</small>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">VALOR:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.valor }}</small>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-sm-3 col-form-label pb-0">STATUS:</label>
          <div class="col-sm-9 text-left">
            <small>{{ detalheBoleto.status }}</small>
          </div>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="visualizarDetalhe = false;"><i class="fas fa-undo"></i>
          FECHAR</button>
        <button type="button" class="btn btn-danger" (click)="cancelarBoleto()"><i class="fas fa-barcode"></i> CANCELAR
          BOLETO</button>
      </p-footer>
    </p-dialog>
  </mat-card-content>

  <!-- Modal Alterar Período -->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
    class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="periodoChange();"><i class="fas fa-check"></i>
        Alterar</button>
    </p-footer>
  </p-dialog>
  <!-- Modal Alterar Período -->


</mat-card>