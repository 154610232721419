<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>


  <div>
    <mat-card-content>
      <div class="box-content pt-2">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsEduzzCarga" [value]="listEduzzCarga" [rows]="rows"
          [paginator]="true" [totalRecords]="listEduzzCarga.length" (onFilter)="onFilter($event)"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'saleId'">
                  <span class="text-overflow-dynamic-ellipsis">SALE ID <p-sortIcon [field]="'saleId'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'saleId', 'contains')">
              </th>
              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'clientName'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'clientName'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'clientName', 'contains')">
              </th>
              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'clientEmail'">
                  <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'clientEmail'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'clientEmail', 'contains')">
              </th>

              <th class="text-left" style="width: 13%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'clientDocument'">
                  <span class="text-overflow-dynamic-ellipsis">DOCUMENTO <p-sortIcon [field]="'clientDocument'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'clientDocument', 'contains')">
              </th>

              <th class="text-left" style="width: 12%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'clientCel'">
                  <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'clientCel'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'clientCel', 'contains')">
              </th>

              <th class="text-left" style="width: 13%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'salePaymentMethod'">
                  <span class="text-overflow-dynamic-ellipsis">MET. PAGAMENT. <p-sortIcon [field]="'salePaymentMethod'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'salePaymentMethod', 'contains')">
              </th>

              <th class="text-right" style="width: 9%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'saleTotal'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'saleTotal'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'saleTotal', 'contains')">
              </th>

              <th class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'contentId'">
                  <span class="text-overflow-dynamic-ellipsis">COD EDUZZ <p-sortIcon [field]="'contentId'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'contentId', 'contains')">
              </th>

              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'contentTitle'">
                  <span class="text-overflow-dynamic-ellipsis">EVT. NOME <p-sortIcon [field]="'contentTitle'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'contentTitle', 'contains')">
              </th>

              <th class="text-left" style="width: 13%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCriacaoEduzz'">
                  <span class="text-overflow-dynamic-ellipsis">DT CRIA EDUZZ <p-sortIcon [field]="'dataCriacaoEduzz'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'dataCriacaoEduzz', 'contains')">
              </th>

              <th class="text-left" style="width: 13%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataPagamenoEduzz'">
                  <span class="text-overflow-dynamic-ellipsis">DT PAGAMENTO <p-sortIcon [field]="'dataPagamenoEduzz'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'dataPagamenoEduzz', 'contains')">
              </th>

              <th class="text-center" style="width: 9%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'saleStatusName'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'saleStatusName'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'saleStatusName', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox [disabled]="rowData.desabilitaSelecao" (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.saleId}}">{{rowData.saleId
                    }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.clientName}}">{{rowData.clientName
                    }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.clientEmail}}">{{rowData.clientEmail}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.clientDocument}}">{{rowData.clientDocument}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.clientCel}}">{{rowData.clientCel
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.salePaymentMethod}}">{{rowData.salePaymentMethod }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.saleTotal}}">{{rowData.saleTotal
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.contentId}}">{{rowData.contentId
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.contentTitle}}">{{rowData.contentTitle }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.dataCriacaoEduzz}}">{{rowData.dataCriacaoEduzz }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.dataPagamenoEduzz}}">{{rowData.dataPagamenoEduzz }}</span>
                </span>
              </td>

              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.saleStatusName}}">{{rowData.saleStatusName }}</span>
                </span>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="13" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>

    </mat-card-content>
  </div>


  <!-- Modal Alterar Período -->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
    class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Data Inicial Criação Eduzz</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
        <div class="form-group col-sm-6">
          <label>Data Final Criação Eduzz</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="periodoChange();"><i class="fas fa-check"></i>
        Alterar</button>
    </p-footer>
  </p-dialog>
  <!-- Modal Alterar Período -->

  <!-- Modal para edicao do registro -->
  <p-dialog header="Alterar Registro" [(visible)]="boolModalEditar" [style]="{width: '800px'}" [modal]="true"
    class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
    <div *ngIf="boolModalEditar">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome</label>
          <input type="text" class="form-control text-left" value="{{eduzzCarga.clientName}}"
            [(ngModel)]="eduzzCarga.clientName" />
        </div>
      </div>



      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Email</label>
          <input type="text" class="form-control text-left" value="{{eduzzCarga.clientEmail}}"
            [(ngModel)]="eduzzCarga.clientEmail" maxlength="60" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Telefone</label>
          <input type="text" class="form-control text-left" value="{{eduzzCarga.clientCel}}"
            [(ngModel)]="eduzzCarga.clientCel" maxlength="15" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Documento CPF / CNPJ</label>
          <input type="text" class="form-control text-left" value="{{eduzzCarga.clientDocument}}"
            [(ngModel)]="eduzzCarga.clientDocument" />
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>CÓD INTEGRAÇÃO EDUZZ</label>
          <input type="text" class="form-control text-left" value="{{eduzzCarga.contentId}}"
            [(ngModel)]="eduzzCarga.contentId" />
        </div>
      </div>

    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='boolModalEditar=false;'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="salvarEduzzCarga();"><i class="fas fa-check"></i>
        Alterar</button>
    </p-footer>
  </p-dialog>
  <!-- Modal para edicao do registro -->


</mat-card>