<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content" style="padding: 1rem !important; margin-top: -25px;">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta">

            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroBeneficio" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple" [style]="{'width':'100%'}"
              immutable="false" (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                      <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                        Código <p-sortIcon [field]="'cod'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'cod', 'contains')">
                  </th>

                  <th class="text-left">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                        Nome <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'nome', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" [id]="'checkboxContainer'+rowData.id">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cod}}">{{rowData.cod}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Código</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="codigo">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Nome</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="nome">
            </div>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>

