<h3>Importar</h3>

<div class="principal">
  <div *ngIf="msgs">
    <h3>Importação de arquivo DBL</h3>

  </div>
  <div *ngIf="!msgs">
    <h4>Selecione um arquivo DBL para importar</h4>
    <input type="file" #file (change)="fileChange()" />
    <br>
    <br>
    <div id="div-botoes">
      <div class="margin-botoes">
        <button pButton type="button" [disabled]="importDisabled" (click)="importar()">Importar</button>
      </div>
    </div>
  </div>
</div>
