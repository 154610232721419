<div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
  <div class="ui-g-12">

  </div>
</div>

<!--PADDING INICIAL DE TODA -->
<div style="padding: 1.5rem" class="pt-3">
  <div class="row">
    <div class="col" style="margin-left: -10px;">
      <h4 class="font-weight-bold mt-3 mb-3 ml-2">Dashboard
        <div class="text-muted text-tiny mt-1"><small class="font-weight-normal"
            [innerHTML]="formatarDataExtenso()"></small></div>
      </h4>
    </div>
  </div>

  <div class="form-row mb-4 mt-2">
    <div class="col-sm-6">
      <label class="d-block" style="margin-top: 8px; margin-bottom: 12px; font-size: 12px;">Abas</label>
      <ul class="list-inline filter-menu mb-3">
        <li class="list-inline-item" [ngClass]="{active: statusTela2}"><a (click)="showTelaDashboard('TELA_2')">CAP EXPO
            COLIG</a></li>
        <li class="list-inline-item" [ngClass]="{active: statusTela1}"><a (click)="showTelaDashboard('TELA_1')">CAP
            EXPO</a></li>
        <li class="list-inline-item" [ngClass]="{active: statusTela3}"><a (click)="showTelaDashboard('TELA_3')">PRL
            REVENDA</a>
        <li class="list-inline-item" [ngClass]="{active: statusTela4}"><a
            (click)="showTelaDashboard('TELA_4')">IMOBILIZADO</a>
        <li class="list-inline-item" [ngClass]="{active: statusTela5}"><a (click)="showTelaDashboard('TELA_5')">PIC
            VENDA</a>
        <li class="list-inline-item" [ngClass]="{active: statusTela6}"><a (click)="showTelaDashboard('TELA_6')">PRL
            VENDA PROD</a>
        </li>
      </ul>
    </div>

    <div class="col-sm-6 d-flex align-items-center justify-content-end">
      <div class="form-group col-sm-2">
        <label>Ano</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedYear" [options]="opcoesAno" [filter]="false"
        placeholder="Selecione um ano"></p-dropdown>
      </div>
      <div class="form-group col-sm-4">
        <label>Mês Final</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedMonth" [options]="opcoesMeses" [filter]="false"
          placeholder="Selecione um ano"></p-dropdown>
      </div>
      <div class="col-sm-4">
        <div class="form-group col-sm-2">
          <label></label> <!-- Alinhamento -->
          <button class="btn btn-primary mt-1" type="button" (click)="getTpCalculo()"><i class="fas fa-spinner"></i>
            Carregar dashboard</button>
        </div>
      </div>
    </div>
  </div>

  <!--TELA 1-->
  <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela1'}">

    <div class="row">
      <!--INICÍO DO CARDDASH1-->
      <div class="col-sm-4 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash1 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por País</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash1()">
                  <span *ngIf="!fullDash1" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash1" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash1()"
                  (click)="changeStateDash1()">
                  <span *ngIf="isCardDash1" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash1" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash1>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash1 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash1'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_1" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH1-->

      <!--INICÍO DO CARDDASH2-->
      <div class="col-sm-4 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash2 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Organização</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash2()">
                  <span *ngIf="!fullDash2" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash2" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash2()"
                  (click)="changeStateDash2()">
                  <span *ngIf="isCardDash2" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash2" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash2>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash2 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash2'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_2" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH2-->

      <!--INICÍO DO CARDDASH3-->
      <div class="col-sm-4 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash3 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash3()">
                  <span *ngIf="!fullDash3" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash3" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash3()"
                  (click)="changeStateDash3()">
                  <span *ngIf="isCardDash3" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash3" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash3>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash3 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash3'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_3" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH3-->

      <!--INICÍO DO CARDDASH4-->
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">CAP EXPO | Exportação de Países com Tributação Favorecida</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportExcel()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i style="font-size: 19px; margin-right:-6px;"
                    class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash4()"
                  (click)="changeStateDash4()">
                  <span *ngIf="isCardDash4" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash4" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash4>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt1 [value]="CapExpo" [columns]="colsCapExpo" selectionMode="single"
                [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}" immutable="false" [scrollable]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_item'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD PROD <p-sortIcon [field]="'cod_item'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'cod_item', 'contains')">
                    </th>
                    <th class="text-center" style="width: 250px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
                        <span class="text-overflow-dynamic-ellipsis">PRODUTO <p-sortIcon [field]="'descr_item'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descr_item', 'contains')">
                    </th>
                    <th class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ncm'">
                        <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'ncm'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'ncm', 'contains')">
                    </th>
                    <th class="text-center" style="width: 250px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_part'">
                        <span class="text-overflow-dynamic-ellipsis">PARTICIPANTE <p-sortIcon [field]="'descr_part'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descr_part', 'contains')">
                    </th>
                    <th class="text-center" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_pais'">
                        <span class="text-overflow-dynamic-ellipsis">PAÍS <p-sortIcon [field]="'descr_pais'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'descr_pais', 'contains')">
                    </th>
                    <th class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'unid_inv'">
                        <span class="text-overflow-dynamic-ellipsis">UN <p-sortIcon [field]="'unid_inv'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'unid_inv', 'contains')">
                    </th>
                    <th class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTD <p-sortIcon [field]="'qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_venda'">
                        <span class="text-overflow-dynamic-ellipsis">VR VENDA <p-sortIcon [field]="'vr_venda'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'vr_venda', 'contains')">
                    </th>
                    <th class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_venda_unit'">
                        <span class="text-overflow-dynamic-ellipsis">VR VENDA UNIT <p-sortIcon
                            [field]="'vr_venda_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'vr_venda_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'fator1'">
                        <span class="text-overflow-dynamic-ellipsis">FATOR <p-sortIcon [field]="'fator1'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'fator1', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_total'">
                        <span class="text-overflow-dynamic-ellipsis">VR TOTAL <p-sortIcon [field]="'vr_total'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'vr_total', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'custo'">
                        <span class="text-overflow-dynamic-ellipsis">VL CUSTO <p-sortIcon [field]="'custo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'custo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'margem15'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM 15% <p-sortIcon [field]="'margem15'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'margem15', 'contains')">
                    </th>
                    <th class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'divergencia'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM DIVERG <p-sortIcon [field]="'divergencia'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'divergencia', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'preco'">
                        <span class="text-overflow-dynamic-ellipsis">PREÇO PARAM <p-sortIcon [field]="'preco'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'preco', 'contains')">
                    </th>
                    <th class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ajuste1'">
                        <span class="text-overflow-dynamic-ellipsis">VL AJUSTE 1 <p-sortIcon [field]="'ajuste1'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'ajuste1', 'contains')">
                    </th>
                    <th class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE VENDA LOCAL <p-sortIcon
                            [field]="'qtde_venda_local'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'qtde_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 128px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_total_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">VR TOTAL VENDA <p-sortIcon
                            [field]="'vr_total_venda_local'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'vr_total_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_unit_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">VR UNIT <p-sortIcon
                            [field]="'vr_unit_venda_local'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'vr_unit_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percent_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">%SOBRE <p-sortIcon [field]="'percent_venda_local'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'percent_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ajuste_final'">
                        <span class="text-overflow-dynamic-ellipsis">AJUSTE FINAL <p-sortIcon [field]="'ajuste_final'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'ajuste_final', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cod_item}}">{{
                          rowData.cod_item }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData. descr_item}}">{{rowData.
                          descr_item }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ncm}}">{{rowData.ncm
                          }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descr_part}}">{{rowData.descr_part }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descr_pais}}">{{rowData.descr_pais
                          }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.unid_inv}}">{{rowData.unid_inv
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.qtde}}">{{rowData.qtde
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_venda | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_venda | number :
                          '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_venda_unit | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_venda_unit |
                          number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fator1}}">{{rowData.fator1
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_total | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_total | number :
                          '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.custo | number : '1.2-2' :'pt-BR'}}">{{rowData.custo | number : '1.2-2'
                          :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.margem15 | number : '1.2-2' :'pt-BR'}}">{{rowData.margem15 | number :
                          '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.divergencia | number : '1.2-2' :'pt-BR'}}">{{rowData.divergencia |
                          number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.preco | number : '1.2-2' :'pt-BR'}}">{{rowData.preco | number : '1.2-2'
                          :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ajuste1 | number : '1.2-2' :'pt-BR'}}">{{rowData.ajuste1 | number :
                          '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.qtde_venda_local | number : '1.0-3' :'pt-BR'}}">{{rowData.qtde_venda_local|
                          number : '1.0-3' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 128px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_total_venda_local | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_total_venda_local
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_unit_venda_local | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_unit_venda_local
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percent_venda_local | number : '1.2-2' :'pt-BR'}}%">{{rowData.percent_venda_local
                          | number : '1.2-2' :'pt-BR' }}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ajuste_final | number : '1.2-2' :'pt-BR'}}">{{rowData.ajuste_final |
                          number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 925px;" colspan="6">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL:</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['qtde']  | number : '1.0-0' : 'pt-BR'}}">{{TotaisCapExpo['qtde'] |
                          number : '1.0-0' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['vr_venda']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['vr_venda']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 125px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['fator1']  | number : '1.0-0' : 'pt-BR'}}">{{TotaisCapExpo['fator1']
                          | number : '1.0-0' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['vr_total']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['vr_total']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['custo']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['custo']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['margem15']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['margem15']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['divergencia']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['divergencia']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['preco']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['preco']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['ajuste1']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['ajuste1']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['qtde_venda_local']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['qtde_venda_local']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 128px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['vr_total_venda_local']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['vr_total_venda_local']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 225px;" colspan="2">
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpo['ajuste_final']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpo['ajuste_final']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="21" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH4-->

    </div>

  </div>

  <!--TELA 2-->
  <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela2'}">

    <div class="row">
      <!--INICÍO DO CARDDASH5-->
      <div class="col-sm-4 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash5 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por País</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash5()">
                  <span *ngIf="!fullDash5" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash5" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash5()"
                  (click)="changeStateDash5()">
                  <span *ngIf="isCardDash5" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash5" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash5>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash1 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash1'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_5" style="width: 100%; height:400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH5-->

      <!--INICÍO DO CARDDASH6-->
      <div class="col-sm-4 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash6 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Organização</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash6()">
                  <span *ngIf="!fullDash6" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash6" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash6()"
                  (click)="changeStateDash6()">
                  <span *ngIf="isCardDash6" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash6" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash6>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash2 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash2'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_6" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH6-->

      <!--INICÍO DO CARDDASH7-->
      <div class="col-sm-4 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash7 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash7()">
                  <span *ngIf="!fullDash7" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash7" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash7()"
                  (click)="changeStateDash7()">
                  <span *ngIf="isCardDash7" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash7" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash7>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash3 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash3'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_7" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH7-->

      <!--INICÍO DO CARDDASH8-->
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">CAP EXPO COLIG | Exportação Pessoas Vínculadas</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportExcel()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i style="font-size: 19px; margin-right:-6px;"
                    class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash8()"
                  (click)="changeStateDash8()">
                  <span *ngIf="isCardDash8" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash8" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash8>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt2 [value]="CapExpoColig" [columns]="colsCapExpoColig"
                selectionMode="single" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" [scrollable]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_item'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD PROD <p-sortIcon [field]="'cod_item'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'cod_item', 'contains')">
                    </th>
                    <th class="text-center" style="width: 250px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
                        <span class="text-overflow-dynamic-ellipsis">PRODUTO <p-sortIcon [field]="'descr_item'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'descr_item', 'contains')">
                    </th>
                    <th class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ncm'">
                        <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'ncm'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'ncm', 'contains')">
                    </th>
                    <th class="text-center" style="width: 250px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_part'">
                        <span class="text-overflow-dynamic-ellipsis">PARTICIPANTE <p-sortIcon [field]="'descr_part'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'descr_part', 'contains')">
                    </th>
                    <th class="text-center" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_pais'">
                        <span class="text-overflow-dynamic-ellipsis">PAÍS <p-sortIcon [field]="'descr_pais'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'descr_pais', 'contains')">
                    </th>
                    <th class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'unid_inv'">
                        <span class="text-overflow-dynamic-ellipsis">UN <p-sortIcon [field]="'unid_inv'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'unid_inv', 'contains')">
                    </th>
                    <th class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTD <p-sortIcon [field]="'qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_venda'">
                        <span class="text-overflow-dynamic-ellipsis">VR VENDA <p-sortIcon [field]="'vr_venda'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'vr_venda', 'contains')">
                    </th>
                    <th class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_venda_unit'">
                        <span class="text-overflow-dynamic-ellipsis">VR VENDA UNIT <p-sortIcon
                            [field]="'vr_venda_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'vr_venda_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'fator1'">
                        <span class="text-overflow-dynamic-ellipsis">FATOR <p-sortIcon [field]="'fator1'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'fator1', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_total'">
                        <span class="text-overflow-dynamic-ellipsis">VR TOTAL <p-sortIcon [field]="'vr_total'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'vr_total', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'custo'">
                        <span class="text-overflow-dynamic-ellipsis">VL CUSTO <p-sortIcon [field]="'custo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'custo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'margem15'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM 15% <p-sortIcon [field]="'margem15'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'margem15', 'contains')">
                    </th>
                    <th class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'divergencia'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM DIVERG <p-sortIcon [field]="'divergencia'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'divergencia', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'preco'">
                        <span class="text-overflow-dynamic-ellipsis">PREÇO PARAM <p-sortIcon [field]="'preco'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'preco', 'contains')">
                    </th>
                    <th class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ajuste1'">
                        <span class="text-overflow-dynamic-ellipsis">VL AJUSTE 1 <p-sortIcon [field]="'ajuste1'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'ajuste1', 'contains')">
                    </th>
                    <th class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE VENDA LOCAL <p-sortIcon
                            [field]="'qtde_venda_local'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'qtde_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 128px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_total_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">VR TOTAL VENDA <p-sortIcon
                            [field]="'vr_total_venda_local'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'vr_total_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_unit_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">VR UNIT <p-sortIcon
                            [field]="'vr_unit_venda_local'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'vr_unit_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'percent_venda_local'">
                        <span class="text-overflow-dynamic-ellipsis">%SOBRE <p-sortIcon [field]="'percent_venda_local'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'percent_venda_local', 'contains')">
                    </th>
                    <th class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ajuste_final'" nGG>
                        <span class="text-overflow-dynamic-ellipsis">AJUSTE FINAL <p-sortIcon [field]="'ajuste_final'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'ajuste_final', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cod_item}}">{{
                          rowData.cod_item }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData. descr_item}}">{{rowData.
                          descr_item }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ncm}}">{{rowData.ncm
                          }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descr_part}}">{{rowData.descr_part }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descr_pais}}">{{rowData.descr_pais
                          }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.unid_inv}}">{{rowData.unid_inv
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.qtde}}">{{rowData.qtde
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_venda | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_venda | number :
                          '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_venda_unit | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_venda_unit |
                          number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fator1}}">{{rowData.fator1
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_total | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_total | number :
                          '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.custo | number : '1.2-2' :'pt-BR'}}">{{rowData.custo | number : '1.2-2'
                          :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.margem15 | number : '1.2-2' :'pt-BR'}}">{{rowData.margem15 | number :
                          '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.divergencia | number : '1.2-2' :'pt-BR'}}">{{rowData.divergencia |
                          number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.preco | number : '1.2-2' :'pt-BR'}}">{{rowData.preco | number : '1.2-2'
                          :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ajuste1 | number : '1.2-2' :'pt-BR'}}">{{rowData.ajuste1 | number :
                          '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.qtde_venda_local | number : '1.0-3' :'pt-BR'}}">{{rowData.qtde_venda_local|
                          number : '1.0-3' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 128px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_total_venda_local | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_total_venda_local
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_unit_venda_local | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_unit_venda_local
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.percent_venda_local | number : '1.2-2' :'pt-BR'}}%">{{rowData.percent_venda_local
                          | number : '1.2-2' :'pt-BR' }}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ajuste_final | number : '1.2-2' :'pt-BR'}}">{{rowData.ajuste_final |
                          number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 925px;" colspan="6">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL:</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['qtde']  | number : '1.0-0' : 'pt-BR'}}">{{TotaisCapExpoColig['qtde']
                          | number : '1.0-0' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['vr_venda']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['vr_venda']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['fator1']  | number : '1.0-0' : 'pt-BR'}}">{{TotaisCapExpoColig['fator1']
                          | number : '1.0-0' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['vr_total']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['vr_total']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['custo']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['custo']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['margem15']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['margem15']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['divergencia']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['divergencia']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['preco']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['preco']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['ajuste1']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['ajuste1']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['qtde_venda_local']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['qtde_venda_local']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 128px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['vr_total_venda_local']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['vr_total_venda_local']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 225px;" colspan="2">
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisCapExpoColig['ajuste_final']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisCapExpoColig['ajuste_final']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="21" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH8-->

    </div>

  </div>

  <!--TELA 3-->
  <div [ngStyle]="statusTela3 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela3'}">

    <div class="row">
      <!--INICÍO DO CARDDASH9-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash9 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash9()">
                  <span *ngIf="!fullDash9" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash9" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash9()"
                  (click)="changeStateDash9()">
                  <span *ngIf="isCardDash9" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash9" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash9>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash10 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash9'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_9" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH9-->

      <!--INICÍO DO CARDDASH10-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash10 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Relação Valor Venda x Compra CPV</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash10()">
                  <span *ngIf="!fullDash10" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash10" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash10()"
                  (click)="changeStateDash10()">
                  <span *ngIf="isCardDash10" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash10" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash10>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash10 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash10'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_10" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH10-->

      <!--INICÍO DO CARDDASH11-->
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">PRL REVENDA </h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportExcel()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i style="font-size: 19px; margin-right:-6px;"
                    class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash11()"
                  (click)="changeStateDash11()">
                  <span *ngIf="isCardDash11" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash11" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash11>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt3 [value]="PrlRev" [columns]="colsPrlRev" selectionMode="single"
                [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}" immutable="false" [scrollable]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-REV.cod_prod'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD ITEM <p-sortIcon [field]="'IMPO-REV.cod_prod'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'IMPO-REV.cod_prod', 'contains')">
                    </th>
                    <th class="text-center" style="width: 270px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-REV.descr_prod'">
                        <span class="text-overflow-dynamic-ellipsis">DESC ITEM <p-sortIcon
                            [field]="'IMPO-REV.descr_prod'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value,  'IMPO-REV.descr_prod', 'contains')">
                    </th>
                    <th class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Faturamento.unid_inv'">
                        <span class="text-overflow-dynamic-ellipsis">UNID INV <p-sortIcon
                            [field]="'Faturamento.unid_inv'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Faturamento.unid_inv', 'contains')">
                    </th>
                    <th class="text-center" style="width: 90px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Faturamento.ncm'">
                        <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'Faturamento.ncm'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Faturamento.ncm', 'contains')">
                    </th>
                    <th class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-REV.qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTD IMPO <p-sortIcon [field]="'IMPO-REV.qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'IMPO-REV.qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-REV.vl_cif_brl'">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL IMPO <p-sortIcon
                            [field]="'IMPO-REV.vl_cif_brl'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'IMPO-REV.vl_cif_brl', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.unit'">
                        <span class="text-overflow-dynamic-ellipsis">UNIT IMPO<p-sortIcon [field]="'Formula.unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.consumo_qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE CONS <p-sortIcon
                            [field]="'Formula.consumo_qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.consumo_qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.consumo_valor'">
                        <span class="text-overflow-dynamic-ellipsis">VALOR CONS <p-sortIcon
                            [field]="'Formula.consumo_valor'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.consumo_valor', 'contains')">
                    </th>
                    <th class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.consumo_praticado'">
                        <span class="text-overflow-dynamic-ellipsis">PRATICADO <p-sortIcon
                            [field]="'Formula.consumo_praticado'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.consumo_praticado', 'contains')">
                    </th>
                    <th class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.calc_prec_qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE VENDA<p-sortIcon
                            [field]="'Formula.calc_prec_qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.calc_prec_valor'">
                        <span class="text-overflow-dynamic-ellipsis">VL VENDA <p-sortIcon
                            [field]="'Formula.calc_prec_valor'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_valor', 'contains')">
                    </th>
                    <th class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.calc_prec_vl_unit'">
                        <span class="text-overflow-dynamic-ellipsis">VL VENDA UNIT <p-sortIcon
                            [field]="'Formula.calc_prec_vl_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_vl_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container"
                        [pSortableColumn]="'Formula.calc_prec_percent_mrcusto'">
                        <span class="text-overflow-dynamic-ellipsis">% MR/CUSTO <p-sortIcon
                            [field]="'Formula.calc_prec_percent_mrcusto'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_percent_mrcusto', 'contains')">
                    </th>
                    <th class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.calc_prec_rec_liq'">
                        <span class="text-overflow-dynamic-ellipsis">REC LÍQ AJUSTADA <p-sortIcon
                            [field]="'Formula.calc_prec_rec_liq'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_rec_liq', 'contains')">
                    </th>
                    <th class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.calc_prec_margem20'">
                        <span class="text-overflow-dynamic-ellipsis">VL MARG 20PERC <p-sortIcon
                            [field]="'Formula.calc_prec_margem20'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_margem20', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.calc_prec_prec_param'">
                        <span class="text-overflow-dynamic-ellipsis">PERCO PARAM <p-sortIcon
                            [field]="'Formula.calc_prec_prec_param'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.calc_prec_prec_param', 'contains')">
                    </th>
                    <th class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.margem_div'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM DIVERG <p-sortIcon
                            [field]="'Formula.margem_div'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.margem_div', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.ajuste_unit'">
                        <span class="text-overflow-dynamic-ellipsis">AJUSTE UNIT <p-sortIcon
                            [field]="'Formula.ajuste_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.ajuste_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 113px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.ajuste_total'">
                        <span class="text-overflow-dynamic-ellipsis">AJUSTE TOTAL <p-sortIcon
                            [field]="'Formula.ajuste_total'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt3.filter($event.target.value, 'Formula.ajuste_total', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-REV'].cod_prod}}">{{rowData['IMPO-REV'].cod_prod }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 270px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-REV'].descr_prod}}">{{rowData['IMPO-REV'].descr_prod }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Faturamento'].unid_inv}}">{{rowData['Faturamento'].unid_inv }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 90px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Faturamento'].ncm}}">{{rowData['Faturamento'].ncm }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-REV'].qtde | number : '1.2-2' :'pt-BR'}}">{{rowData['IMPO-REV'].qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-REV'].vl_cif_brl | number : '1.2-2' :'pt-BR'}}">{{rowData['IMPO-REV'].vl_cif_brl
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].unit
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].consumo_qtde | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].consumo_qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].consumo_valor | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].consumo_valor
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].consumo_praticado | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].consumo_praticado
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_qtde | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].calc_prec_qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_valor | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].calc_prec_valor
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_vl_unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].calc_prec_vl_unit
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_percent_mrcusto | number : '1.2-2' :'pt-BR'}}%">{{rowData['Formula'].calc_prec_percent_mrcusto
                          | number : '1.2-2' :'pt-BR' }}%</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_rec_liq | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].calc_prec_rec_liq
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_margem20 | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].calc_prec_margem20
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].calc_prec_prec_param | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].calc_prec_prec_param
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].margem_div | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].margem_div
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].ajuste_unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].ajuste_unit
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 113px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula'].ajuste_total | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula'].ajuste_total
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 570px;" colspan="5">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL:</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['IMPO-REV']?.qtde | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['IMPO-REV']?.qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['IMPO-REV']?.vl_cif_brl | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['IMPO-REV']?.vl_cif_brl
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.consumo_qtde | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.consumo_qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.consumo_valor | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.consumo_valor
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.consumo_praticado | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.consumo_praticado
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.calc_prec_qtde | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.calc_prec_qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.calc_prec_valor | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.calc_prec_valor
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 250px;" colspan="2">
                    </td>
                    <td class="text-right" style="width: 140px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.calc_prec_rec_liq | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.calc_prec_rec_liq
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.calc_prec_margem20 | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.calc_prec_margem20
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.calc_prec_prec_param | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.calc_prec_prec_param
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 130px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.margem_div | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.margem_div
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 113px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlRev['Formula']?.ajuste_total | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlRev['Formula']?.ajuste_total
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="20" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH11-->

    </div>

  </div>

  <!--TELA 4 NÃO MEXER-->
  <div [ngStyle]="statusTela4 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela4'}">

    <div class="row">
      <!--INICÍO DO CARDDASH12-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash12 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash12()">
                  <span *ngIf="!fullDash12" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash12" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash12()"
                  (click)="changeStateDash12()">
                  <span *ngIf="isCardDash12" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash12" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash12>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash12 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash12'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_12" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH12-->

      <!--INICÍO DO CARDDASH13-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash13 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Relação Valor Venda x Compra CPV</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash13()">
                  <span *ngIf="!fullDash13" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash13" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash13()"
                  (click)="changeStateDash13()">
                  <span *ngIf="isCardDash13" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash13" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash13>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash13 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash13'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_13" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH13-->

      <!--INICÍO DO CARDDASH14-->
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">IMOBILIZADO </h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportExcel()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i style="font-size: 19px; margin-right:-6px;"
                    class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash14()"
                  (click)="changeStateDash14()">
                  <span *ngIf="isCardDash14" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash14" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash14>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt4 [value]="Imobilizado" [columns]="colsImobilizado"
                selectionMode="single" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" [scrollable]="false">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_item'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD PROD <p-sortIcon [field]="'cod_item'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'cod_item', 'contains')">
                    </th>
                    <th class="text-left" style="width: 240px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descr_item'">
                        <span class="text-overflow-dynamic-ellipsis">DESCR PROD <p-sortIcon [field]="'descr_item'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'descr_item', 'contains')">
                    </th>
                    <th class="text-center" style="width: 75px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'unid_inv'">
                        <span class="text-overflow-dynamic-ellipsis">UN <p-sortIcon [field]="'unid_inv'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'unid_inv', 'contains')">
                    </th>
                    <th class="text-center" style="width: 75px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ncm'">
                        <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'ncm'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'ncm', 'contains')">
                    </th>
                    <th class="text-center" style="width: 75px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTD <p-sortIcon [field]="'qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'total'">
                        <span class="text-overflow-dynamic-ellipsis">VL TOTAL <p-sortIcon [field]="'total'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'total', 'contains')">
                    </th>
                    <th class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'unit'">
                        <span class="text-overflow-dynamic-ellipsis">VL UNIT <p-sortIcon [field]="'unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'vr_unit'">
                        <span class="text-overflow-dynamic-ellipsis">VR UNIT <p-sortIcon [field]="'vr_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'vr_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 111px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'margem20'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM 20% <p-sortIcon [field]="'margem20'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'margem20', 'contains')">
                    </th>
                    <th class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'param'">
                        <span class="text-overflow-dynamic-ellipsis">PARÂMETRO <p-sortIcon [field]="'param'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'param', 'contains')">
                    </th>
                    <th class="text-right" style="width: 107px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'margem_div5'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM DIV <p-sortIcon [field]="'margem_div5'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'margem_div5', 'contains')">
                    </th>
                    <th class="text-right" style="width: 91px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ajuste'">
                        <span class="text-overflow-dynamic-ellipsis">VL AJUSTE <p-sortIcon [field]="'ajuste'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt4.filter($event.target.value, 'ajuste', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cod_item}}">{{
                          rowData.cod_item }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 240px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData. descr_item}}">{{rowData.
                          descr_item}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.unid_inv}}">{{rowData.unid_inv
                          }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ncm}}">{{rowData.ncm
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.qtde | number : '1.2-2' :'pt-BR'}}">{{rowData.qtde | number : '1.2-2'
                          :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.total | number : '1.2-2' :'pt-BR'}}">{{rowData.total | number : '1.2-2'
                          :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.unit | number : '1.2-2' :'pt-BR'}}">{{rowData.unit | number : '1.2-2'
                          :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.vr_unit | number : '1.2-2' :'pt-BR'}}">{{rowData.vr_unit | number :
                          '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 111px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.margem20 | number : '1.2-2' :'pt-BR'}}">{{rowData.margem20 | number :
                          '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.param | number : '1.2-2' :'pt-BR'}}">{{rowData.param | number : '1.2-2'
                          :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 107px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.margem_div5 | number : '1.2-2' :'pt-BR'}}">{{rowData.margem_div5 |
                          number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 91px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ajuste | number : '1.2-2' :'pt-BR'}}">{{rowData.ajuste | number :
                          '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 500px;" colspan="4">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL:</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 75px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisImobilizado['qtde']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisImobilizado['qtde']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisImobilizado['total']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisImobilizado['total']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 90px;" colspan="1">
                    </td>
                    <td class="text-left" style="width: 90px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 111px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisImobilizado['margem20']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisImobilizado['margem20']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisImobilizado['param']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisImobilizado['param']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 107px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisImobilizado['margem_div5']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisImobilizado['margem_div5']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 91px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisImobilizado['ajuste']  | number : '1.2-2' : 'pt-BR'}}">{{TotaisImobilizado['ajuste']
                          | number : '1.2-2' : 'pt-BR'}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="12" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH14-->

    </div>

  </div>

  <!--TELA 5-->
  <div [ngStyle]="statusTela5 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela5'}">

    <div class="row">
      <!--INICÍO DO CARDDASH15-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash15 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash15()">
                  <span *ngIf="!fullDash15" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash15" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash15()"
                  (click)="changeStateDash15()">
                  <span *ngIf="isCardDash15" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash15" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash15>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash15 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash15'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_15" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH15-->

      <!--INICÍO DO CARDDASH16-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash16 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Relação Importação x Valor Local</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash16()">
                  <span *ngIf="!fullDash16" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash16" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash16()"
                  (click)="changeStateDash16()">
                  <span *ngIf="isCardDash16" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash16" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash16>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash16 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash16'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_16" style="width: 100%; height: 400px;display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH16-->

      <!--INICÍO DO CARDDASH17-->
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">PIC VENDA </h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportExcel()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i style="font-size: 19px; margin-right:-6px;"
                    class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash17()"
                  (click)="changeStateDash17()">
                  <span *ngIf="isCardDash17" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash17" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash17>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt5 [value]="PicRev" [columns]="colsPicRev" selectionMode="single"
                [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                [style]="{'width':'100%'}" immutable="false" [scrollable]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.cod_prod'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD PROD <p-sortIcon
                            [field]="'IMPO-VENDA.cod_prod'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'IMPO-VENDA.cod_prod', 'contains')">
                    </th>
                    <th class="text-center" style="width: 250px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.descr_prod'">
                        <span class="text-overflow-dynamic-ellipsis">DESC ITEM <p-sortIcon
                            [field]="'IMPO-VENDA.descr_prod'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'IMPO-VENDA.descr_prod', 'contains')">
                    </th>
                    <th class="text-center" style="width: 80px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Base Importações.unid_inv'">
                        <span class="text-overflow-dynamic-ellipsis">UN INV <p-sortIcon
                            [field]="'Base Importações.unid_inv'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Base Importações.unid_inv', 'contains')">
                    </th>
                    <th class="text-center" style="width: 85px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Base Importações.ncm'">
                        <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'Base Importações.ncm'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Base Importações.ncm', 'contains')">
                    </th>
                    <th class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE <p-sortIcon [field]="'IMPO-VENDA.qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'IMPO-VENDA.qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.vl_cif_brl'">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL<p-sortIcon [field]="'IMPO-VENDA.vl_cif_brl'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'IMPO-VENDA.vl_cif_brl', 'contains')">
                    </th>
                    <th class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.unit'">
                        <span class="text-overflow-dynamic-ellipsis">UNIT <p-sortIcon [field]="'Formula.unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.unidade'">
                        <span class="text-overflow-dynamic-ellipsis">UNID <p-sortIcon [field]="'Formula.unidade'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.unidade', 'contains')">
                    </th>
                    <th class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Base Compras locais.qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE TOTAL <p-sortIcon
                            [field]="'Base Compras locais.qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Base Compras locais.qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.percent_compra'">
                        <span class="text-overflow-dynamic-ellipsis">% COMPRA <p-sortIcon
                            [field]="'Formula.percent_compra'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.percent_compra', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container"
                        [pSortableColumn]="'Base Compras locais.vl_rec_liq'">
                        <span class="text-overflow-dynamic-ellipsis">VL TOTAL<p-sortIcon
                            [field]="'Base Compras locais.vl_rec_liq'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Base Compras locais.vl_rec_liq', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.unitario'">
                        <span class="text-overflow-dynamic-ellipsis">VL UNIT <p-sortIcon [field]="'Formula.unitario'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.unitario', 'contains')">
                    </th>
                    <th class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.preco_praticado'">
                        <span class="text-overflow-dynamic-ellipsis">PERÇO PRAT <p-sortIcon
                            [field]="'Formula.preco_praticado'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.preco_praticado', 'contains')">
                    </th>
                    <th class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.preco_parametro'">
                        <span class="text-overflow-dynamic-ellipsis">PERÇO PARAM <p-sortIcon
                            [field]="'Formula.preco_parametro'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.preco_parametro', 'contains')">
                    </th>
                    <th class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.margem_div'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM DIVER <p-sortIcon
                            [field]="'Formula.margem_div'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.margem_div', 'contains')">
                    </th>
                    <th class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.val_ajuste'">
                        <span class="text-overflow-dynamic-ellipsis">VL AJUSTE <p-sortIcon
                            [field]="'Formula.val_ajuste'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt5.filter($event.target.value, 'Formula.val_ajuste', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?.descr_prod}}">{{rowData['IMPO-VENDA']?.cod_prod
                          }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?.descr_prod}}">{{rowData['IMPO-VENDA']?.descr_prod }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 80px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Base Importações']?.unid_inv}}">{{rowData['Base Importações']?.unid_inv
                          }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 85px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Base Importações']?.ncm}}">{{rowData['Base Importações']?.ncm }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?.qtde | number : '1.2-2' :'pt-BR'}}">{{rowData['IMPO-VENDA']?.qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?.vl_cif_brl | number : '1.2-2' :'pt-BR'}}">{{rowData['IMPO-VENDA']?.vl_cif_brl
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.unit
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 90px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.unidade}}">{{rowData['Formula']?.unidade}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Base Compras locais']?.qtde | number : '1.2-2' :'pt-BR'}}">{{rowData['Base
                          Compras locais']?.qtde | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.percent_compra | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.percent_compra
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Base Compras locais']?.vl_rec_liq | number : '1.2-2' :'pt-BR'}}">{{rowData['Base
                          Compras locais']?.vl_rec_liq | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.unitario | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.unitario
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.preco_praticado | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.preco_praticado
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.preco_parametro | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.preco_parametro
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.margem_div | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.margem_div
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.val_ajuste | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.val_ajuste
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 525px;" colspan="4">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL:</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['IMPO-VENDA']?.qtde | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['IMPO-VENDA']?.qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 100px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['IMPO-VENDA']?.vl_cif_brl | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['IMPO-VENDA']?.vl_cif_brl
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Formula']?.unit | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Formula']?.unit
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 90px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 105px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Base Compras locais']?.qtde | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Base
                          Compras locais']?.qtde | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Formula']?.percent_compra | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Formula']?.percent_compra
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Base Compras locais']?.vl_rec_liq | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Base
                          Compras locais']?.vl_rec_liq | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;" colspan="1">
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Formula']?.preco_praticado | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Formula']?.preco_praticado
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Formula']?.preco_parametro | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Formula']?.preco_parametro
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Formula']?.margem_div | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Formula']?.margem_div
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 94px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPicRev['Formula']?.val_ajuste | number : '1.2-2' :'pt-BR'}}">{{TotaisPicRev['Formula']?.val_ajuste
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="16" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH17-->

    </div>

  </div>

  <!--TELA 6-->
  <div [ngStyle]="statusTela6 ? {'display': 'block'} : {'display': 'none'}" [ngClass]="{'fade-in': 'statusTela6'}">

    <div class="row">
      <!--INICÍO DO CARDDASH18-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash18 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Valor Ajuste por Item</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash18()">
                  <span *ngIf="!fullDash18" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash18" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash18()"
                  (click)="changeStateDash18()">
                  <span *ngIf="isCardDash18" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash18" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash18>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash18 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash18'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_18" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH18-->

      <!--INICÍO DO CARDDASH19-->
      <div class="col-sm-6 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;"
          [ngClass]="fullDash19 ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Qtde PA x Relação Qtde MPI</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
                  matTooltipPosition="above" (click)="fullScreenDash19()">
                  <span *ngIf="!fullDash13" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullDash13" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash19()"
                  (click)="changeStateDash19()">
                  <span *ngIf="isCardDash19" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash19" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash19>
            <div class="row">
              <div class="col">
                <div [ngStyle]="statusDash19 ? {'display': 'block'} : {'display': 'none'}"
                  [ngClass]="{'fade-in': 'statusDash19'}">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_19" style="width: 100%; height: 400px; display: block;">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH19-->

      <!--INICÍO DO CARDDASH20-->
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px;">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">PRL VENDA PROD </h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="exportExcel()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i style="font-size: 19px; margin-right:-6px;"
                    class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash20()"
                  (click)="changeStateDash20()">
                  <span *ngIf="isCardDash20" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardDash20" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDash20>
            <div style="min-height: 388px;">
              <p-table class="table-system" #dt6 [value]="PrlVendProd" [columns]="colsPrlVendProd"
                selectionMode="single" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" [scrollable]="true">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.cod_prod'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD PROD <p-sortIcon
                            [field]="'IMPO-VENDA.cod_prod'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'IMPO-VENDA.cod_prod', 'contains')">
                    </th>
                    <th class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.descr_prod'">
                        <span class="text-overflow-dynamic-ellipsis">DESCR PROD <p-sortIcon
                            [field]="'IMPO-VENDA.descr_prod'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'IMPO-VENDA.descr_prod', 'contains')">
                    </th>
                    <th class="text-center" style="width: 80px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Base Importações.unid_inv'">
                        <span class="text-overflow-dynamic-ellipsis">UN <p-sortIcon
                            [field]="'Base Importações.unid_inv'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Base Importações.unid_inv', 'contains')">
                    </th>
                    <th class="text-center" style="width: 85px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Base Importações.ncm'">
                        <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'Base Importações.ncm'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Base Importações.ncm', 'contains')">
                    </th>
                    <th class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.qtde'">
                        <span class="text-overflow-dynamic-ellipsis">QTD <p-sortIcon [field]="'IMPO-VENDA.qtde'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'IMPO-VENDA.qtde', 'contains')">
                    </th>
                    <th class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'IMPO-VENDA.vl_cif_brl'">
                        <span class="text-overflow-dynamic-ellipsis">VL TOTAL <p-sortIcon
                            [field]="'IMPO-VENDA.vl_cif_brl'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'IMPO-VENDA.vl_cif_brl', 'contains')">
                    </th>
                    <th class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.vl_unit'">
                        <span class="text-overflow-dynamic-ellipsis">VL UNIT <p-sortIcon [field]="'Formula.vl_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.vl_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.qtde_dados_consumo'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE CONSUMO <p-sortIcon
                            [field]="'Formula.qtde_dados_consumo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.qtde_dados_consumo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.valor_dados_consumo'">
                        <span class="text-overflow-dynamic-ellipsis">VL CONSUMO <p-sortIcon
                            [field]="'Formula.valor_dados_consumo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.valor_dados_consumo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container"
                        [pSortableColumn]="'Formula.preco_praticado_dados_consumo'">
                        <span class="text-overflow-dynamic-ellipsis">PREÇO PRAT <p-sortIcon
                            [field]="'Formula.preco_praticado_dados_consumo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.preco_praticado_dados_consumo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container"
                        [pSortableColumn]="'MAPA_PRL_VENDA.qtde_mp_vendida'">
                        <span class="text-overflow-dynamic-ellipsis">QTDE VENDA <p-sortIcon
                            [field]="'MAPA_PRL_VENDA.qtde_mp_vendida'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'MAPA_PRL_VENDA.qtde_mp_vendida', 'contains')">
                    </th>
                    <th class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'MAPA_PRL_VENDA.base_calculo'">
                        <span class="text-overflow-dynamic-ellipsis">REC LÍQ AJUSTADA <p-sortIcon
                            [field]="'MAPA_PRL_VENDA.base_calculo'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'MAPA_PRL_VENDA.base_calculo', 'contains')">
                    </th>
                    <th class="text-right" style="width: 112px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'MAPA_PRL_VENDA.margem_lucro'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM 20% <p-sortIcon
                            [field]="'MAPA_PRL_VENDA.margem_lucro'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'MAPA_PRL_VENDA.margem_lucro', 'contains')">
                    </th>
                    <th class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container"
                        [pSortableColumn]="'MAPA_PRL_VENDA.preco_parametro_total'">
                        <span class="text-overflow-dynamic-ellipsis">PRECO PARAM <p-sortIcon
                            [field]="'MAPA_PRL_VENDA.preco_parametro_total'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'MAPA_PRL_VENDA.preco_parametro_total', 'contains')">
                    </th>
                    <th class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.preco_param_unit'">
                        <span class="text-overflow-dynamic-ellipsis">PRECO PARAM UNIT <p-sortIcon
                            [field]="'Formula.preco_param_unit'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.preco_param_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.margem_div'">
                        <span class="text-overflow-dynamic-ellipsis">MARGEM DIV <p-sortIcon
                            [field]="'Formula.margem_div'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.margem_div', 'contains')">
                    </th>
                    <th class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.ajuste_unit'">
                        <span class="text-overflow-dynamic-ellipsis">VL AJUSTE UNIT<p-sortIcon
                            [field]="'Formula.ajuste_unit'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.ajuste_unit', 'contains')">
                    </th>
                    <th class="text-right" style="width: 119px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'Formula.ajuste_total'">
                        <span class="text-overflow-dynamic-ellipsis">VL AJUSTE <p-sortIcon
                            [field]="'Formula.ajuste_total'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt6.filter($event.target.value, 'Formula.ajuste_total', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{ rowData['IMPO-VENDA']?.cod_prod}}">{{
                          rowData['IMPO-VENDA']?.cod_prod }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 250px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?. descr_prod}}">{{rowData['IMPO-VENDA']?.
                          descr_prod}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 80px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Base Importações']?.unid_inv}}">{{rowData['Base Importações']?.unid_inv
                          }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 85px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Base Importações']?.ncm}}">{{rowData['Base Importações']?.ncm
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?.qtde | number : '1.2-2' :'pt-BR'}}">{{rowData['IMPO-VENDA']?.qtde
                          | number : '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['IMPO-VENDA']?.vl_cif_brl | number : '1.2-2' :'pt-BR'}}">{{rowData['IMPO-VENDA']?.vl_cif_brl
                          | number : '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.vl_unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.vl_unit
                          | number : '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.qtde_dados_consumo | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.qtde_dados_consumo
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.valor_dados_consumo | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.valor_dados_consumo
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.preco_praticado_dados_consumo | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.preco_praticado_dados_consumo
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['MAPA_PRL_VENDA']?.qtde_mp_vendida == null? '0,00' : rowData['MAPA_PRL_VENDA']?.qtde_mp_vendida | number : '1.2-2' :'pt-BR'}}">{{rowData['MAPA_PRL_VENDA']?.qtde_mp_vendida
                          == null? '0,00' : rowData['MAPA_PRL_VENDA']?.qtde_mp_vendida | number : '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['MAPA_PRL_VENDA']?.base_calculo == null? '0,00' : rowData['MAPA_PRL_VENDA']?.base_calculo | number : '1.2-2' :'pt-BR'}}">{{rowData['MAPA_PRL_VENDA']?.base_calculo
                          == null? '0,00' : rowData['MAPA_PRL_VENDA']?.base_calculo | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 112px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['MAPA_PRL_VENDA']?.margem_lucro == null? '0,00' : rowData['MAPA_PRL_VENDA']?.margem_lucro | number : '1.2-2' :'pt-BR'}}">{{rowData['MAPA_PRL_VENDA']?.margem_lucro
                          == null? '0,00' : rowData['MAPA_PRL_VENDA']?.margem_lucro | number : '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['MAPA_PRL_VENDA']?.preco_parametro_total == null? '0,00' : rowData['MAPA_PRL_VENDA']?.preco_parametro_total | number : '1.2-2' :'pt-BR'}}">{{rowData['MAPA_PRL_VENDA']?.preco_parametro_total
                          == null? '0,00' : rowData['MAPA_PRL_VENDA']?.preco_parametro_total | number : '1.2-2' :'pt-BR'
                          }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.preco_param_unit == null? '0,00' : rowData['Formula']?.preco_param_unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.preco_param_unit
                          == null? '0,00' : rowData['Formula']?.preco_param_unit | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.margem_div == null? '0,00' : rowData['Formula']?.margem_div | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.margem_div
                          == null? '0,00' : rowData['Formula']?.margem_div | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.ajuste_unit == null? '0,00' : rowData['Formula']?.ajuste_unit | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.ajuste_unit
                          == null? '0,00' : rowData['Formula']?.ajuste_unit | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 119px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData['Formula']?.ajuste_total == null? '0,00' : rowData['Formula']?.ajuste_total | number : '1.2-2' :'pt-BR'}}">{{rowData['Formula']?.ajuste_total
                          == null? '0,00' : rowData['Formula']?.ajuste_total | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 525px;" colspan="4">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL:</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['IMPO-VENDA']?.qtde | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['IMPO-VENDA']?.qtde
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['IMPO-VENDA']?.vl_cif_brl | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['IMPO-VENDA']?.vl_cif_brl
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 95px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.vl_unit | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.vl_unit
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 125px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.qtde_dados_consumo | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.qtde_dados_consumo
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.valor_dados_consumo | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.valor_dados_consumo
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 115px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.preco_praticado_dados_consumo | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.preco_praticado_dados_consumo
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.qtde_mp_vendida | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.qtde_mp_vendida
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.base_calculo | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.base_calculo
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 112px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.margem_lucro | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.margem_lucro
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 118px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.preco_parametro_total | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['MAPA_PRL_VENDA']?.preco_parametro_total
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 145px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.preco_param_unit | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.preco_param_unit
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 110px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.margem_div | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.margem_div
                          | number : '1.2-2' :'pt-BR'}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.ajuste_unit | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.ajuste_unit
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 120px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{TotaisPrlVendProd['Formula']?.ajuste_total | number : '1.2-2' :'pt-BR'}}">{{TotaisPrlVendProd['Formula']?.ajuste_total
                          | number : '1.2-2' :'pt-BR' }}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="10" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <!--FIM DO CARDDASH20-->

    </div>
  </div>

</div>