<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content p-4">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content p-3">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroFeriados" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple" [style]="{'width':'100%'}"
                immutable="false" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" [(selection)]="select">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 40px;">
                    </th>
                    <th class="text-left" style="width: 300px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtInicialFormatoBrasil'">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                            Data <p-sortIcon [field]="'data'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtInicialFormatoBrasil', 'contains')">
                    </th>
  
                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoFeriado.id'">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                          Tipo <p-sortIcon [field]="'tipoFeriado.tipo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'tipoFeriado.tipo', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-left: 20px;">
                          Descricao <p-sortIcon [field]="'descricao'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'descricao', 'contains')">
                    </th>
  
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                  <tr>
                    <td class="text-center" style="width: 40px;">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
  
                    <td class="text-left" style="width: 40px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.data}}">{{rowData.data  | timezone | date:'dd/MM/yyyy'}}</span>
                      </span>
                    </td>
  
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.tipoFeriado.tipo}}">{{rowData.tipoFeriado != null? rowData.tipoFeriado.tipo: ''}}</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
          </div>
        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content p-4">
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Data</label>
              <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
               yearRange="2010:2030" [(ngModel)]="data">
              </p-calendar>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Descrição <span>*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricao">
            </div>
          </div>

          
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Tipo Feriado</label>
            <p-dropdown class="type-p-dropdown" [options]="opcoesTipo" [(ngModel)]="tipo">
            </p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Empresa</label>
            <p-dropdown class="type-p-dropdown" [options]="opcoesEmpresa" [(ngModel)]="empresas">
            </p-dropdown>
          </div>
        </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

















