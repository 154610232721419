<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <div class="row">
    <div class="col-sm-8 col-xxl-6">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <ul class="list-inline filter-menu mb-3">
              <li class="list-inline-item" [ngClass]="{ active: statusLink1 }">
                <a (click)="showTypeVinc('LINK_1')">Centro de custo</a>
              </li>
              <li class="list-inline-item" [ngClass]="{ active: statusLink2 }">
                <a (click)="showTypeVinc('LINK_2')">Contas gerenciais</a>
              </li>
              <li class="list-inline-item" [ngClass]="{ active: statusLink3 }">
                <a (click)="showTypeVinc('LINK_3')">Áreas</a>
              </li>
            </ul>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="showHideCardCc()" (click)="changeStateCc()">
                <span *ngIf="isCardCc" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardCc" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]="currentStateCc">
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Usuário</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="usuarios" placeholder="Selecione o usuário"
                [(ngModel)]="usuarioId" (onChange)="buscarRelacsUsuario()"></p-dropdown>
            </div>
            <div class="form-group col-sm-6" *ngIf="usuarioId > 0 && statusLink2">
              <label>Plano gerencial</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="listPg"
                placeholder="Selecione o plano gerencial" [(ngModel)]="pgId" (onChange)="buscarCtaRelac()"></p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-4" *ngIf="statusLink1">
              <h3 class="sub-title"><i class="fas fa-hand-holding-usd"></i> Centros de custo</h3>
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Centros de custo disponíveis" targetHeader="Centros de custo relacionados"
                  [source]="listaCentroCustoDispo" [target]="listaCentroCustoRelacionados" [dragdrop]="true"
                  [responsive]="true" filterBy="descr_ccus" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-centroCusto pTemplate="item">
                    {{centroCusto.cod_ccus}} - {{centroCusto.descr_ccus}}
                  </ng-template>
                </p-pickList>
              </div>
              <button type="button" class="btn btn-primary mt-3" (click)="salvarRelacionamentoCcus()">
                <i class="fas fa-check"></i> Salvar
              </button>
            </div>

            <div class="col-sm-12 mt-4" *ngIf="statusLink2">
              <h3 class="sub-title"><i class="fas fa-hand-holding-usd"></i> Contas gerenciais</h3>
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Contas gerenciais disponíveis" targetHeader="Contas gerenciais relacionadas"
                  [source]="listaContasDispo" [target]="listaContasRelacionadas" [dragdrop]="true" [responsive]="true"
                  filterBy="descr_cta" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-cta pTemplate="item">
                    {{cta.cod_cta}} - {{cta.descr_cta}}
                  </ng-template>
                </p-pickList>
              </div>
              <button type="button" class="btn btn-primary mt-3" (click)="salvarRelacionamentoCta()">
                <i class="fas fa-check"></i> Salvar
              </button>
            </div>

            <div class="col-sm-12 mt-4" *ngIf="statusLink3">
              <h3 class="sub-title"><i class="fas fa-warehouse"></i> Áreas</h3>
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Áreas disponíveis" targetHeader="Áreas relacionadas"
                  [source]="listaAreasDisponiveis" [target]="listaAreasRelacionadas" [dragdrop]="true" [responsive]="true"
                  filterBy="descricao" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-area pTemplate="item">
                    {{area.codigo}} - {{area.descricao}}
                  </ng-template>
                </p-pickList>
              </div>
              <button type="button" class="btn btn-primary mt-3" (click)="salvarRelacionamentoArea()">
                <i class="fas fa-check"></i> Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>