<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>

    <div class="box-content">
      <div class="header-box" style="height: 10px">
        <div class="header-box-content p-3" style="margin-top: -25px">
          <ul class="list-inline filter-menu filter-card-header mt-0">
            <li class="list-inline-item"></li>
            <li class="list-inline-item"></li>
          </ul>
          <div class="actions-card">
            <div style="display: flex;">
              <button type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu" class="btnCustom-default"
                matTooltip="Mais opções" *ngIf="!formulario && !editar">
                <span class="material-icons" style="margin-top: -7px;">more_vert</span>
              </button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                  <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                    <p-checkbox class="mr-1" [(ngModel)]="showInativos" [binary]="true"
                    (onChange)="empresaChanged()">
                    </p-checkbox>
                    <span>Exibir Inativos</span>
                  </div>
                  <div class="menuOptions" mat-menu-item (click)="exportarExcel();">
                    <span>Exportar</span>
                  </div>
                </mat-menu>
                <app-toolbar [value]="toolbarRegraFiscal"></app-toolbar>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="formulario">

        <div class="form-row mt-4">
          <div class="form-group col-sm-2">
            <label for="inputCodigo">CÓDIGO</label>
            <input class="form-control" [(ngModel)]="operacaoRegraFiscal.codigo" value="{{operacaoRegraFiscal.codigo}}"
              type="text" name="inputCodigo">
          </div>
          <div class="form-group col-sm-10">
            <label for="inputTitulo">TÍTULO</label>
            <input class="form-control" [(ngModel)]="operacaoRegraFiscal.titulo" value="{{operacaoRegraFiscal.titulo}}"
              type="text" name="inputTitulo">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label for="inputDescricao">DESCRIÇÃO</label>
            <textarea class="form-control" rows="3" [(ngModel)]="operacaoRegraFiscal.descricao"
              value="{{operacaoRegraFiscal.descricao}}" name="inputDescricao">
            </textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label for="inputDadosAdicionais">DADOS ADICIONAIS</label>
            <textarea class="form-control" rows="3" [(ngModel)]="operacaoRegraFiscal.dadosAdicionais"
              value="{{operacaoRegraFiscal.dadosAdicionais}}" name="inputDadosAdicionais">
            </textarea>
          </div>
        </div>

        <div class="form-column" style="background-color: #fff; padding: 10px;">
          <div class="form-group">
            <div class="p-field-checkbox mb-3 ml-1">
              <p-checkbox class="mr-1" [(ngModel)]="operacaoRegraFiscal.ativo"
                (change)="operacaoRegraFiscal.ativo = !operacaoRegraFiscal.ativo" [binary]="true"
              inputId="operacaoRegraFiscal.ativo"></p-checkbox>
              <label for="operacaoRegraFiscal.ativo" class="ml-1" style="position: relative;top: 3px">Regra
              ativa</label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-arrows-alt-v"></i> BENEFÍCIO (TAG XML "CBENEF")</h3>
          </div>
        </div>


        <div class="form-row" *ngIf="operacaoRegraFiscal.codigosCbenef">
          <div class="form-group col-sm-2 mt-1">
            <label>CÓDIGO DE BENEFÍCIO - ESTADO (SC)</label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
              [options]="opcoesCodBenef" [(ngModel)]="operacaoRegraFiscal.codigosCbenef.id"></p-dropdown>
          </div>
        </div>

        <div class="row" *ngIf="operacaoRegraFiscal.tipoEntradaSaida">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-arrows-alt-v"></i> TIPO DE OPERAÇÃO</h3>
          </div>
        </div>


        <div class="form-row" *ngIf="operacaoRegraFiscal.tipoEntradaSaida">
          <div class="form-group col-sm-2 mt-1">
            <label>OPERAÇÃO</label>
            <p-dropdown appendTo="body" [disabled]="editar" class="type-p-dropdown" [filter]="true"
              [options]="opcoesOperacao" [(ngModel)]="operacaoRegraFiscal.tipoEntradaSaida.id" (ngModelChange)="loadCodigosEnquadramento()"></p-dropdown>
          </div>
        </div>
        <mat-tab-group dynamicHeight="true">

          <mat-tab label="ICMS" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-2 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcms.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcms.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcms.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcms.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mt-2 mb-1">
                <label>Mod. deter.do ICMS</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4 mb-2">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesModBcIcms"
                  [(ngModel)]="operacaoRegraFiscalDetalheIcms.modBcIcmsId" placeholder="Selecione"></p-dropdown>
              </div>
            </div>

            <div *ngIf="operacaoRegraFiscal.tipoEntradaSaida">
              <div *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id === 2">
                <div class="form-row">
                  <div class="form-group col-sm-12 mt-2 mb-2">
                    <label>VENDA PARA CONSUMIDOR FINAL</label>
                  </div>
                </div>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcms.somaIpiBc">SOMA IPI NA BASE DE CÁLCULO
                  </mat-slide-toggle>
                </label>
                <div class="form-group col-sm-12 mt-2 mb-4"
                  *ngIf="regimeTributario === 1 || regimeTributario === 2 || regimeTributario === 4">
                  <label>CSOSN</label>
                  <p-dropdown class="type-p-dropdown altura-csosn" [filter]="true" [options]="opcoesCsosn"
                    [(ngModel)]="operacaoRegraFiscalDetalheIcms.csosn" placeholder="Selecione"></p-dropdown>
                </div>
              </div>

              <div *ngIf="operacaoRegraFiscal.tipoEntradaSaida">
                <div *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id">

                  <div class="form-row">
                    <button type="button" class="btn btn-secondary" (click)="adicionarLinha()"><i
                        class="fas fa-plus"></i>
                      Adicionar</button>
                    <div class="form-group col-sm-12 mb-0">
                      <h3 class="sub-title"><i class="fas fa-file"></i> RELAÇÃO CFOP e CST</h3>
                    </div>
                  </div>
                  <p-table class="table-system"
                    [value]="operacaoRegraFiscalDetalheIcms.operacaoRegraFiscalDetalheCfopRelacList"
                    [style]="{'width':'100%'}">
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="text-center" style="width: 25%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">CFOP ORIGEM</span>
                          </span>
                        </th>
                        <th class="text-center" style="width: 25%;"
                          *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id === 2">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">CFOP</span>
                          </span>
                        </th>
                        <th class="text-center" style="width: 25%;"
                          *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id === 1">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">CFOP DESTINO</span>
                          </span>
                        </th>
                        <th class="text-center" style="width: 20%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">CST ICMS</span>
                          </span>
                        </th>
                        <th class="text-center" style="width: 5%;">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                          </span>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData">
                        <td class="text-left">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCfop"
                            [(ngModel)]="rowData.cfopOrig" appendTo="body">
                          </p-dropdown>
                        </td>
                        <td class="text-left" *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id === 2">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCfop"
                            [(ngModel)]="rowData.cfopOrig" appendTo="body">
                          </p-dropdown>
                        </td>
                        <td class="text-left" *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id === 1">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCfop"
                            [(ngModel)]="rowData.cfopDest" appendTo="body">
                          </p-dropdown>
                        </td>
                        <td class="text-left">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCstIcmsTabelaB"
                            [(ngModel)]="rowData.cstIcmsTabelaB" appendTo="body">
                          </p-dropdown>
                        </td>
                        <td class="text-center" style="width: 5%;">
                          <span class="text-overflow-dynamic-container">
                            <span (click)="removerLinhaCfop(rowData)" class="delete" style="text-align: center;">
                              <i class="fas fa-trash-alt" matTooltip="Remover"></i>
                            </span>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="5" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="IPI" appendTo="body">


            <div class="form-row">
              <div class="form-group col-sm-12 mt-2 mb-1">
                <label>CST</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4 mb-2">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesCstIpi"
                  [(ngModel)]="operacaoRegraFiscalDetalheIpi.cstIpi">
                </p-dropdown>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mt-2 mb-1">
                <label>Código de enquadramento</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4 mb-2">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesCodEnqIpi"
                  [(ngModel)]="operacaoRegraFiscalDetalheIpi.codEnqIpiId" placeholder="Selecione" [virtualScroll]="true" [itemSize]="38">
                </p-dropdown>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIpi.lucroReal"> LUCRO REAL</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIpi.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIpi.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIpi.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="ICMS-ST" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcmsSt.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcmsSt.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcmsSt.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIcmsSt.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="PIS" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>CST</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4 mb-2">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesCstPis"
                  [(ngModel)]="operacaoRegraFiscalDetalhePis.cstPisCofins">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePis.lucroReal"> LUCRO REAL</mat-slide-toggle>
                </label>
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePis.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePis.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePis.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>REGIME DE INCIDÊNCIA</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesRegimeIncidencia"
                  [(ngModel)]="operacaoRegraFiscalDetalhePis.regimeIncidencia">
                </p-dropdown>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="COFINS" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>CST</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-4 mb-2">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesCstCofins"
                  [(ngModel)]="operacaoRegraFiscalDetalheCofins.cstPisCofins">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCofins.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCofins.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCofins.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3 mb-0">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCofins.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>REGIME DE INCIDÊNCIA</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-4 mb-2">
                <p-dropdown class="type-p-dropdown" [filter]="true" appendTo="body" [options]="opcoesRegimeIncidencia"
                  [(ngModel)]="operacaoRegraFiscalDetalheCofins.regimeIncidencia">
                </p-dropdown>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="IRRF" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIrrf.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIrrf.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIrrf.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIrrf.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="PCC" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePcc.lucroReal"> LUCRO REAL</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePcc.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePcc.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalhePcc.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="ISSQN" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssqn.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssqn.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssqn.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssqn.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>

          </mat-tab>

          <mat-tab label="ISS RET" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssRet.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssRet.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssRet.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssRet.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
                <div *ngIf="operacaoRegraFiscal.tipoEntradaSaida" class="pull-left">
                  <div *ngIf="operacaoRegraFiscal.tipoEntradaSaida.id === 1">
                    <label class="mr-3">
                      <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIssRet.inscritoMunicipio">INSCRITO
                        MUNICÍPIO
                      </mat-slide-toggle>
                    </label>
                  </div>
                </div>

              </div>
            </div>
          </mat-tab>

          <mat-tab label="INSS" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheInss.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheInss.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheInss.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheInss.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="SIMPLES" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheSimples.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheSimples.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheSimples.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheSimples.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="II" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIi.lucroReal"> LUCRO REAL</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIi.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIi.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheIi.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="CIDE" appendTo="body">
            <div class="form-row">
              <div class="form-group col-sm-12 mt-1 mb-1">
                <label>GERA IMPOSTO QUANDO</label>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12 mb-2">
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCide.lucroReal"> LUCRO REAL
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCide.lucroPresumido">LUCRO PRESUMIDO
                  </mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCide.simples">SIMPLES</mat-slide-toggle>
                </label>
                <label class="mr-3">
                  <mat-slide-toggle [(ngModel)]="operacaoRegraFiscalDetalheCide.naoContribuinte">NÃO CONTRIBUINTE
                  </mat-slide-toggle>
                </label>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="form-row">
          <div class="form-group col-sm-12 mb-1 mt-3">
          </div>
        </div>
      </div>
    </div>

    <div class="box-content" *ngIf="!formulario">
       <div style="margin-top: -25px;">
        <p-table class="table-system" #dt1 [value]="regras" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" (onRowSelect)="onRowSelectRegra($event)"
          [style]="{'width':'100%'}" (onRowUnselect)="onRowUnselect($event)" [(selection)]="regrasFiscaisSelecionadas" (onHeaderCheckboxToggle)="selecionarTudo()">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox"style="width: 40px;">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-center" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th class="text-center" style="width: 65%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">TÍTULO <p-sortIcon [field]="'titulo'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'titulo', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtInicial'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">DATA INICIAL <p-sortIcon [field]="'dtInicial'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'dtInicial', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtFinal'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">DATA FINAL <p-sortIcon [field]="'dtFinal'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'dtFinal', 'contains')">
              </th>
              <th class="text-center" style="width: 33.33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS REGRA<p-sortIcon [field]="'ativo'">
                  </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]=" showInativos == true ? opcoesStatusRegraFiscalShowInativos : opcoesStatusRegraFiscal"
                  defaultLabel="Selecione" [filter]="false" (onChange)="dt1.filter($event.value, 'ativo', 'in')">
                </p-multiSelect>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData" [ngClass]="{ inativo: !rowData.ativo }">
              <td class="text-center" style="width: 40px;">
                <p-tableCheckbox [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 65%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.titulo}}">{{rowData.titulo}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.dtInicial | timezone |
                    date:'dd/MM/yyyy'}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.dtFinal | timezone | date:'dd/MM/yyyy'}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.ativo? 'ATIVO' : 'INATIVO'}}">{{rowData.ativo? 'ATIVO' : 'INATIVO'
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="6" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
      </p-table>
    </div>

    </div>
    <div class="ui-g">
      <div class="ui-g-12">

      </div>
    </div>

    <p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll" [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

      <div class="form-row" *ngIf="clonagemNaPropriaEmpresa">
        <div class="form-group col-sm-12">
          <label>Novo Código</label>
          <input class="form-control" type="text" [(ngModel)]="operacaoRegraClonagemNovoCodigo" maxlength="10">
        </div>

        <div class="form-group col-sm-12">
          <label>Novo Título</label>
          <input class="form-control" type="text" [(ngModel)]="operacaoRegraClonagemNovoTitulo">
        </div>
      </div>

      <div *ngIf="cadastroGrupoEmpresas && !clonagemNaPropriaEmpresa">
        <label >{{ descricaoModalGrupoEmpresas }}</label>
        <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
        </p-multiSelect>
      </div>

      <p-footer class="d-flex align-items-center justify-content-end">
        <button class="btnCustom-green" (click)="!editar && formulario ? salvarRegraGrupoEmpresa() : editarRegraGrupoEmpresa()" *ngIf="!clonagem">
          <span class="material-icons">save</span>
          <div>Salvar</div>
        </button>

        <button class="btnCustom-green" (click)="clonarRegraGrupoEmpresa()" *ngIf="clonagem">
          <span class="material-icons">save</span>
          <div>Clonar</div>
        </button>
      </p-footer>
    </p-dialog>
  </mat-card-content>