<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>




  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsListaMotivosRecusa" [value]="listaMotivosRecusa"
          [paginator]="true" [rows]="rows" [totalRecords]="listaMotivosRecusa.length" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 86%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'descricao', 'contains')">
              </th>



              <th class="text-center" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                  <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'ativoPt', 'in')"></p-multiSelect>
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataObrigatoria'">
                  <span class="text-overflow-dynamic-ellipsis">DT OBRIG <p-sortIcon [field]="'dataObrigatoria'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesDataObriga" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'dataObrigatoriaPt', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>

              <td class="text-left" style="width: 86%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
                    }}</span>
                </span>
              </td>


              <td class="text-center" style="width: 8%;">
                <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo" [binary]="true"
                inputId="ativo" [disabled]="true"></p-checkbox>
              </td>

              <td class="text-center" style="width: 8%;">
                <p-checkbox class="mr-1" [(ngModel)]="rowData.dataObrigatoria" [binary]="true"
                      inputId="dataObrigatoria" [disabled]="true"></p-checkbox>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="8" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>



      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>

      <p-dialog header="STATUS" [(visible)]="modalFormularioMotivos" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalConsultor -->
        <div *ngIf="modalFormularioMotivos">

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>DESCRICAO </label>
              <input [(ngModel)]="palestraClienteStatus.descricao" class="form-control text-left" type="text"
                maxlength="250" />
            </div>
          </div>


          <div class="form-row">
            <div class="col-sm-4">
              <mat-slide-toggle class="mr-2" [(ngModel)]="palestraClienteStatus.ativo"> Ativo </mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-4">
              <mat-slide-toggle class="mr-2" [(ngModel)]="palestraClienteStatus.dataObrigatoria"> DT OBRIG
              </mat-slide-toggle>
            </div>
          </div>


          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>

        <p-footer>

          <button type="button" class="btn btn-secondary" (click)="modalFormularioMotivos=false;"><i
              class="fas fa-undo"></i> Cancelar</button>
          <button *ngIf="!editFormulario" type="button" class="btn btn-primary" (click)="salvarFormulario();"
            [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>
          <button *ngIf="editFormulario" type="button" class="btn btn-primary" (click)="editarFormulario();"
            [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>

        </p-footer>
      </p-dialog>
      <!--Final Modal cadastro de Palestras-->

    </mat-card-content>
  </div>

</mat-card>