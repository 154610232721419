<div class="w-100 h-100" [ngStyle]="{'display': isLoading ? 'none' : 'block'}" [ngClass]="{ 'shadow-box p-3': showShadowBox }">
  <div class="table-card" *ngIf="data !== null && data !== undefined">
    <div class="header">
      <div class="title">
        <h2 *ngIf="data.title">{{data.title}}</h2>
        <small *ngIf="data.subtitle">{{data.subtitle}}</small>
      </div>
      <div *ngIf="hasExport">
        <button class="btnCustom-default" matTooltip="Exportar excel" [disabled]="isTableExportLoading" (click)="exportExcel()">
          <span *ngIf="!isTableExportLoading" class="material-icons">file_download</span>
          <span *ngIf="isTableExportLoading" class="material-icons loading-icon">refresh</span>
        </button>
      </div>
    </div>
    <app-skeleton [config]="skeletonConfig">
      <p-table #dt styleClass="dash-table" [value]="data.rows" [style]="{'width':'100%'}" immutable="false"
      [rows]="rows" [paginator]="hasPaginator" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
      [totalRecords]="data.totalRecords" paginatorDropdownAppendTo="body" [lazy]="isLazy"
      (onLazyLoad)="applyFilter($event)" [lazyLoadOnInit]="false" [scrollable]="isScrollable" scrollHeight="250px">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of data.cols" [class]="'text-'+col.align" [style]="'width:'+col.width+'%'">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="col.key">
                <span class="text-overflow-dynamic-ellipsis" style="user-select: none !important;">
                  {{col.title}} <p-sortIcon [field]="col.key"></p-sortIcon>
                </span>
              </span>
              <input *ngIf="hasFilter" class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, col.key, 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr class="line">
            <td [class]="'text-'+col.align" [style]="'width:'+col.width+'%'" *ngFor="let col of data.cols">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" [ngClass]="{ negative: item[col.key] < 0 }" matTooltip="{{ col.type === 'money' ? (item[col.key] | money): item[col.key] }}">
                  {{ col.type === 'money' ? (item[col.key] | money): item[col.key] }}
                </span>
              </span>
            </td>
          </tr>
        </ng-template>

        <ng-template *ngIf="hasFooter" pTemplate="footer">
          <tr>
            <td *ngFor="let col of data.cols" [class]="'text-'+col.align" [style]="'width:'+col.width+'%'">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" [ngClass]="{ negative: col?.footerValue < 0 }" matTooltip="{{ col.type === 'money' ? (col?.footerValue | money) : (col?.footerValue) }}">
                  {{ col.type === 'money' ? (col?.footerValue | money) : (col?.footerValue) }}
                </span>
              </span>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="data.cols.length" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </app-skeleton>
  </div>
</div>

<p-skeleton *ngIf="isLoading" width="100%" height="300px"></p-skeleton>