<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row p-3 w-100 d-flex align-items-center justify-content-between">
      <h4 class="font-weight-bold mt-3 mb-3">
        Monitoramento de API
      </h4>
      <div class="col-sm-12 col-md-8 col-lg-6 d-flex align-items-center justify-content-end" style="padding: 0 !important;">
        <button class="btnCustom-green" (click)="openingFilterModal(false)">
          <span class="material-icons">filter_alt</span>
          <div>Filtrar</div>
        </button>
        <div class="form-group col-6">
          <label>Família API</label>
          <p-dropdown styleClass="new-dropdown" panelStyleClass="new-dropdown-panel" [options]="familyOptions" [(ngModel)]="familySelected" placeholder="Selecione uma família" appendTo="body" [filter]="true" emptyMessage="Nenhuma família encontrado!" [disabled]="loadingFamilyOptions" [dropdownIcon]="loadingIconFamilyOptions ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="loadingInformations(true)"></p-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!familySelected">
    <div class="alert alert-xs alert-primary d-inline" role="alert">
      <i class="fas fa-info-circle"></i> Selecione uma família de API para prosseguir!
    </div>
  </div>
  <div *ngIf="familySelected" class="row pb-4">
    <div class="col-sm-12">
      <h6 class="font-weight-bold mb-3">API Status</h6>
      <div class="containerCard" *ngIf="!isLoading">
        <div [class]="'card-api status-'+item.codigo" *ngFor="let item of apisList">
          <div class="body">
            <h6>{{item.data}}</h6>
            <p>{{item.message}}</p>
          </div>
        </div>
      </div>
      <div class="containerCard" *ngIf="isLoading">
        <p-skeleton width="200px" height="100px"></p-skeleton>
      </div>
    </div>
  </div>
  <div *ngIf="familySelected" class="row pb-4">
    <div class="col-sm-12">
      <h6 class="font-weight-bold mb-3">Conciliação</h6>
      <div class="containerCard" *ngIf="!isLoading">
        <div [class]="'card-conciliation status-'+item.status" *ngFor="let item of conciliationList">
          <div class="body">
            <h6>{{item.origin}}</h6>
            <p>{{item.relationship}}</p>
            <h2>{{formatQuantity(item.data.valor)}}</h2>
            <p>{{item.message}}</p>
            <button (click)="openingConciliationLog(item)">
              <div>Ver log</div>
              <span class="material-icons">arrow_forward</span>
            </button>
          </div>
        </div>
      </div>
      <div class="emptyContainer" *ngIf="!isLoading && conciliationList.length === 0 && !isReqConciliationrHealthError">
        <span class="material-icons">warning</span>
        <small>Nenhuma conciliação registrada!</small>
      </div>
      <div class="errorContainer" *ngIf="!isLoading && isReqConciliationrHealthError">
        <span class="material-icons">warning</span>
        <small>Falha na comunicação com o servidor!</small>
      </div>
      <div class="containerCard" *ngIf="isLoading">
        <p-skeleton width="300px" height="250px"></p-skeleton>
        <p-skeleton width="300px" height="250px"></p-skeleton>
      </div>
    </div>
  </div>
  <div *ngIf="familySelected" class="row pb-4">
    <div class="col-sm-12">
      <h6 class="font-weight-bold mb-3">Timer Status</h6>
      <div class="containerCard" *ngIf="!isLoading">
        <div [class]="'card-timer status-'+item.status" *ngFor="let item of timerList">
          <div class="body">
            <h6>{{item.nome}}</h6>
            <p>{{item.msg}}</p>
            <h2>{{formatQuantity(item.qtde)}}</h2>
            <p>de registros em sua última execução!</p>
            <button (click)="openingTimerLog(item)">
              <div>Ver log</div>
              <span class="material-icons">arrow_forward</span>
            </button>
          </div>
        </div>
      </div>
      <div class="emptyContainer" *ngIf="!isLoading && timerList.length === 0 && !isReqTimerHealthError">
        <span class="material-icons">warning</span>
        <small>Nenhum timer registrado!</small>
      </div>
      <div class="errorContainer" *ngIf="!isLoading && isReqTimerHealthError">
        <span class="material-icons">warning</span>
        <small>Falha na comunicação com o servidor!</small>
      </div>
      <div class="containerCard" *ngIf="isLoading">
        <p-skeleton width="300px" height="250px"></p-skeleton>
        <p-skeleton width="300px" height="250px"></p-skeleton>
      </div>
    </div>
  </div>
</div>
<p-dialog [(visible)]="isLogModal" *ngIf="isLogModal" [style]="{width: '60vw'}" [modal]="true" styleClass="modal-custom"
  [responsive]="true" [closable]="true" appendTo="body" [header]="'Log do Timer: ' + timerSelected.nome"
  (onHide)="openingTimerLog()">
  <app-skeleton [config]="skeletonConfig">
    <p-table class="table-system" #logTable [value]="timerLogs" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
      [rowsPerPageOptions]="rowsPerPage" [totalRecords]="totalLogs" paginatorDropdownAppendTo="body" [lazy]="true"
      (onLazyLoad)="getTimerLog(timerSelected.codigo, $event)" [style]="{width:'100%',height:'100%'}">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" [width]="'20%'">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'executed_at_str'">
              <span class="text-overflow-dynamic-ellipsis">
                data de execução <p-sortIcon [field]="'executed_at_str'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="logTable.filter($event.target.value, 'executed_at_str', 'contains')">
          </th>
          <th class="text-left" [width]="'70%'">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'message'">
              <span class="text-overflow-dynamic-ellipsis">
                descrição <p-sortIcon [field]="'message'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="logTable.filter($event.target.value, 'message', 'contains')">
          </th>
          <th class="text-center" [width]="'10%'">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'status_code'">
              <span class="text-overflow-dynamic-ellipsis">
                status <p-sortIcon [field]="'status_code'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="logTable.filter($event.target.value, 'status_code', 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="text-center">
            <span class="text-overflow-dynamic-container" matTooltip="{{rowData.executed_at_str}}">
              <span class="text-overflow-dynamic-ellipsis">
                {{rowData.executed_at_str}}
              </span>
            </span>
          </td>
          <td class="text-left">
            <span class="text-overflow-dynamic-container" matTooltip="{{rowData.message}}">
              <span class="text-overflow-dynamic-ellipsis">
                {{rowData.message}}
              </span>
            </span>
          </td>
          <td class="text-center">
            <span class="text-overflow-dynamic-container" matTooltip="Status: {{rowData.status_code}}">
              <span class="text-overflow-dynamic-ellipsis">
                <span [class]="'material-icons table-status-'+ rowData.status_code">circle</span>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="3" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhuma atividade registrada!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </app-skeleton>
  <p-footer class="d-flex align-items-center justify-content-between">
    <div class="alert alert-xs alert-primary d-inline" role="alert">
      <i class="fas fa-info-circle"></i> O log é armazenado durante 60 dias após a emissão dele
    </div>
    <button type="button" class="btn btn-secondary" (click)="openingTimerLog()">
      <i class="fas fa-times"></i> Fechar
    </button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="isConciliationModal" *ngIf="isConciliationModal" [style]="{width: '60vw'}" [modal]="true"
  styleClass="modal-custom" [responsive]="true" [closable]="true" appendTo="body"
  [header]="'Log da Conciliação: ' + conciliationSelected.origin" (onHide)="openingConciliationLog()">
  <p-table class="table-system" #conciliationTable [value]="conciliationSelected.data.log" [rows]="rows"
    [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
    [totalRecords]="conciliationSelected.data.log.length" paginatorDropdownAppendTo="body"
    [style]="{width:'100%',height:'100%'}">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" [width]="'20%'">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
            <span class="text-overflow-dynamic-ellipsis">
              id <p-sortIcon [field]="'id'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="conciliationTable.filter($event.target.value, 'id', 'contains')">
        </th>
        <th class="text-left" [width]="'50%'">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'message'">
            <span class="text-overflow-dynamic-ellipsis">
              descrição <p-sortIcon [field]="'message'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="conciliationTable.filter($event.target.value, 'message', 'contains')">
        </th>
        <th class="text-right" [width]="'15%'">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
            <span class="text-overflow-dynamic-ellipsis">
              valor <p-sortIcon [field]="'valor'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="conciliationTable.filter($event.target.value, 'valor', 'contains')">
        </th>
        <th class="text-center" [width]="'15%'">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'source'">
            <span class="text-overflow-dynamic-ellipsis">
              empresa <p-sortIcon [field]="'source'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="conciliationTable.filter($event.target.value, 'source', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="text-center">
          <span class="text-overflow-dynamic-container" matTooltip="{{rowData.id}}">
            <span class="text-overflow-dynamic-ellipsis">
              {{rowData.id}}
            </span>
          </span>
        </td>
        <td class="text-left">
          <span class="text-overflow-dynamic-container" matTooltip="{{rowData.message}}">
            <span class="text-overflow-dynamic-ellipsis">
              {{rowData.message}}
            </span>
          </span>
        </td>
        <td class="text-right">
          <span class="text-overflow-dynamic-container" matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">
            <span class="text-overflow-dynamic-ellipsis">
              {{rowData.valor | number : '1.2-2' : 'pt-BR'}}
            </span>
          </span>
        </td>
        <td class="text-center">
          <span class="text-overflow-dynamic-container" matTooltip="{{rowData.source}}">
            <span class="text-overflow-dynamic-ellipsis">
              {{rowData.source}}
            </span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="4" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhuma atividade registrada!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button type="button" class="btn btn-primary" (click)="expExcel()">
      <i class="fas fa-file-download"></i> Exportar
    </button>
    <button type="button" class="btn btn-secondary" (click)="openingConciliationLog()">
      <i class="fas fa-times"></i> Fechar
    </button>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="isFilterModal" [style]="{width: '25vw'}" [modal]="true" styleClass="modal-custom" [responsive]="true" [closable]="true" appendTo="body" header="Filtros">
  <div class="form-row">
    <div class="form-group col-6">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="dateIni" yearRange="2010:2030" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
    </div>
    <div class="form-group col-6">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="dateFin" yearRange="2010:2030" dateFormat="dd/mm/yy" appendTo="body"></p-calendar>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="openingFilterModal(false)">
      <i class="fas fa-times"></i> Fechar
    </button>
    <button type="button" class="btn btn-primary" (click)="openingFilterModal(true)">
      <i class="fas fa-filter"></i> Filtrar
    </button>
  </p-footer>
</p-dialog>