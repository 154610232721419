<h3 *ngIf="!loading && !hasEmpresaSelected">Para prosseguir, selecione uma empresa no menu superior</h3>

<app-data-table mainTitle="Grupo Tributação" *ngIf="hasEmpresaSelected"
                singleTitle="Grupo Tributação"
                routerPath="/grupo-tributacao"
                entityPath="/grupotributacao"
                keyField="id"
                [entity]="grupoTributacao"
                [entityFilter]="entityFilter"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
