<mat-card class="card-linnks margin-linnks">
  <mat-card-title>

    {{ title }}
  </mat-card-title>
  <mat-card-subtitle>
    PESSOAS PRESENTES: {{qtdePresentes}} - PESSOAS AUSENTES: {{qtdeFaltantes}}
  </mat-card-subtitle>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesEvento"
          [(ngModel)]="eventoId" (onChange)="buscarParticipantesEvento()"></p-dropdown>
      </div>
    </div>

    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsCheckInList" [value]="checkInList" [rows]="rows"
        [totalRecords]="checkInList.length" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-center" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'presente'">
                <span class="text-overflow-dynamic-ellipsis">Check In <p-sortIcon [field]="'presente'"></p-sortIcon>
                  </span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboCheckin" defaultLabel="Selecione"
                [filter]="false" (onChange)="dt2.filter($event.value, 'presente', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 25%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
            </th>

            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'fone'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'fone', 'contains')">
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">email <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'ingresso'">
                <span class="text-overflow-dynamic-ellipsis">INGRESSO <p-sortIcon [field]="'ingresso'"></p-sortIcon>
                  </span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboTipoIngresso" defaultLabel="Selecione"
                [filter]="false" (onChange)="dt2.filter($event.value, 'ingresso', 'in')"></p-multiSelect>
            </th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>

            <td class="text-center" style="width: 12%;">

              <div *ngIf="!rowData.presente">
                <button type="button" class="btn btn-link" style="color: green;" (click)="fazerCheckIn(rowData)"><i
                    class="fas fa-check"></i> FAZER CHECK-IN</button>
              </div>
              <div *ngIf="rowData.presente">
                <button type="button" class="btn btn-link" style="color: red;" (click)="desfazerCheckIn(rowData)"><i
                    class="fas fa-times"></i> DESFAZER CHECK-IN</button>
              </div>


            </td>
            <td class="text-left" style="width: 25%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}"
                  (click)="showModalPalestraCliente(rowData)">{{rowData.nome }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf}}"
                  (click)="showModalPalestraCliente(rowData)">{{rowData.cpf}}</span>
              </span>
            </td>

            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fone}}"
                  (click)="showModalPalestraCliente(rowData)">{{rowData.fone}}</span>
              </span>
            </td>

            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}"
                  (click)="showModalPalestraCliente(rowData)">{{rowData.email}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ingresso}}"
                  (click)="showModalPalestraCliente(rowData)">{{rowData.ingresso }}</span>
              </span>
            </td>


          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="6" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


    </div>
  </mat-card-content>


  <!-- Inicio Modal Cadastra Palestra Cliente -->
  <p-dialog header="Cadastrar Cliente" [(visible)]="modalPalestraCliente" [style]="{width: '800px'}" [modal]="true"
    styleClass="lks-modal" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="true">


    <!-- modalFormulario -->
    <div *ngIf="modalPalestraCliente">

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>NOME COMPLETO</label>
          <input class="form-control" [(ngModel)]="palestraCliente.nomeCompleto" required="true" type="text"
            minlength="3" maxlength="255" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>EMAIL</label>
          <input class="form-control" [(ngModel)]="palestraCliente.email" required="true" type="email" minlength="3"
            maxlength="255" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DDD+TELEFONE</label>
          <p-inputMask class="type-p-mask" mask="(99)99999999?9" [(ngModel)]="palestraCliente.telefone"
            placeholder="(99)999999999"></p-inputMask>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>CPF</label>
          <input class="form-control" [(ngModel)]="palestraCliente.cpf" required="true" type="text" minlength="3"
            maxlength="20" />
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>EMPRESA</label>
          <input class="form-control" [(ngModel)]="palestraCliente.empresaNome" required="true" type="text"
            minlength="3" maxlength="255" />
        </div>
      </div>



      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">

        </div>
      </div>


    </div>


    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalPalestraCliente=false; "><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="salvarPalestraCliente()"><i class="fas fa-pencil-alt"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
  <!-- Final Modal Cadastra Palestra Cliente -->




</mat-card>