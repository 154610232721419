<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>


  <div>
    <mat-card-content>
      <div class="clearfix"></div>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt10.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt10 [columns]="colslistaClientes" [value]="listaClientes" [rows]="rows"
          [totalRecords]="listaClientes.length" [paginator]="true" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>

              <th class="text-left" style="width: 33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeCpfCnpj'">
                  <span class="text-overflow-dynamic-ellipsis">NOME/CPF/CNPJ<p-sortIcon [field]="'dataInicio'">
                    </p-sortIcon> </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt10.filter($event.target.value, 'nomeCpfCnpj', 'contains')">
              </th>
              <th class="text-left" style="width: 33%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                  <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'fone'"></p-sortIcon> </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt10.filter($event.target.value, 'fone', 'contains')">
              </th>
              <th class="text-left" style="width: 33%;" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon> </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt10.filter($event.target.value, 'email', 'contains')">
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-left" style="width: 33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeCpfCnpj}}"><span
                      style="user-select: all; cursor: text" (click)="showModalInfCliente(rowData)">
                      {{rowData.nomeCpfCnpj }} </span></span>
                </span>
              </td>

              <td class="text-left" style="width: 33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fone}}"> <span
                      style="user-select: all; cursor: text" (click)="showModalInfCliente(rowData)"> {{rowData.fone}}
                    </span></span>
                </span>
              </td>
              <td class="text-left" style="width: 33%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}"> <span
                      style="user-select: all; cursor: text" (click)="showModalInfCliente(rowData)"> {{rowData.email}}
                    </span></span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="3" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>



      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>

    </mat-card-content>
  </div>



  <!-- Inicio Modal Informacao cliente -->
  <p-dialog [(visible)]="modalInfCliente" [style]="{width: '1200px'}" [modal]="true" styleClass="modal-initial"
    [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true"
    styleClass="modal-scroll consulta-cliente-altura">
    <p-header>
      Consulta Clientes
      <div class="actions-modal">
        <div *ngIf="aba1"><button type="button" class="btn btn-xs btn-link" (click)="exportarCSV()"
            matTooltip="Exportar Excel" matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
        </div>
        <div *ngIf="aba2"><button type="button" class="btn btn-xs btn-link" (click)="dt11.exportCSV()"
            matTooltip="Exportar Excel" matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
        </div>
        <div *ngIf="aba3"><button type="button" class="btn btn-xs btn-link" (click)="dt12.exportCSV()"
            matTooltip="Exportar Excel" matTooltipPosition="left"><i class="far fa-file-excel excel"></i> </button>
        </div>
      </div>
    </p-header>


    <div class="row">
      <div class="col-sm-12">
        <ul class="list-inline filter-menu mt-0">
          <li class="list-inline-item" [ngClass]="{active: aba1}"><a (click)="showTela('1')">INF GERAIS</a></li>
          <li class="list-inline-item" [ngClass]="{active: aba2}"><a (click)="showTela('2')">EVENTOS</a></li>
          <li class="list-inline-item" [ngClass]="{active: aba3}"><a (click)="showTela('3')">PAGAMENTOS</a></li>
        </ul>
      </div>

    </div>

    <div class="row">
      <div class="col-sm-12">
        <!-- INICIO DA ABA 1 -->
        <div [ngStyle]="aba1 ? {'display': 'block'} : {'display': 'none' }" [ngClass]="{'fade-in': 'aba1'}">

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-user"> </i> Dados do cliente
                <div class="pull-right">
                  <button type="button" class="btn btn-link p-0" (click)="exportarCSV()"><i
                      class="fas fa-file-excel excel"></i> Exportar para Excel</button>
                </div>
              </h3>
            </div>

          </div>


          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>NOME</label>
              <input [(ngModel)]="nome" class="form-control text-left" type="text" readOnly="true" />
            </div>
            <div class="form-group col-sm-3">
              <label>CPF</label>
              <input [(ngModel)]="cpf" class="form-control text-left" type="text" readOnly="true" />
            </div>

            <div class="form-group col-sm-3">
              <label>CNPJ</label>
              <input [(ngModel)]="cnpj" class="form-control text-left" type="text" readOnly="true" />
            </div>

          </div>



          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>fone</label>
              <input [(ngModel)]="fone" class="form-control text-left" type="text" readOnly="true" />
            </div>
            <div class="form-group col-sm-6">
              <label>email</label>
              <input [(ngModel)]="email" class="form-control text-left" type="text" readOnly="true" />
            </div>


          </div>

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title mb-1"><i class="fas fa-map-marker-alt"></i> Endereço:</h3>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-2">
              <label>cep</label>
              <input [(ngModel)]="cep" class="form-control text-left" type="text" readOnly="true" />
            </div>
            <div class="form-group col-sm-5">
              <label>rua</label>
              <input [(ngModel)]="rua" class="form-control text-left" type="text" readOnly="true" />
            </div>
            <div class="form-group col-sm-2">
              <label>numero</label>
              <input [(ngModel)]="numero" class="form-control text-left" type="text" readOnly="true" />
            </div>

            <div class="form-group col-sm-3">
              <label>bairro</label>
              <input [(ngModel)]="bairro" class="form-control text-left" type="text" readOnly="true" />
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-sm-9">
              <label>cidade</label>
              <input [(ngModel)]="cidade" class="form-control text-left" type="text" readOnly="true" />
            </div>
            <div class="form-group col-sm-3">
              <label>uf</label>
              <input [(ngModel)]="uf" class="form-control text-left" type="text" readOnly="true" />
            </div>


          </div>

        </div>
        <!-- FINAL DA ABA 1 -->

        <!-- INICIO DA ABA 2 -->
        <div [ngStyle]="aba2 ? {'display': 'block'} : {'display': 'none' }" [ngClass]="{'fade-in': 'aba2'}">

          <p-table class="table-system" #dt11 [columns]="colslistaEventosParticipante"
            [value]="listaEventosParticipante" [totalRecords]="listaEventosParticipante.length" [paginator]="false"
            [pageLinks]="pageLinks" selectionMode="single" [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">DT INICIO EV. </span>
                  </span>

                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">LOCAL </span>
                  </span>

                </th>
                <th class="text-left" style="width: 9%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">COD. EV. </span>
                  </span>
                </th>

                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">EVENTO </span>
                  </span>
                </th>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">INGRESSO </span>
                  </span>
                </th>

                <th class="text-right" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">VALOR </span>
                  </span>
                </th>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">STATUS </span>
                  </span>
                </th>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">ORIGEM </span>
                  </span>
                </th>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">DEPTO </span>
                  </span>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>

                <td class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataInicio}}"><span
                        style="user-select: all; cursor: text"> {{rowData.dataInicio }} </span></span>
                  </span>
                </td>

                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.localCompleto}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.localCompleto}} </span></span>
                  </span>
                </td>
                <td class="text-left" style="width: 9%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigoEvento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.codigoEvento}} </span></span>
                  </span>
                </td>

                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.descricao}} </span></span>
                  </span>
                </td>

                <td class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigoIngresso}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.codigoIngresso}} </span></span>
                  </span>
                </td>

                <td class="text-right" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.valor}} </span></span>
                  </span>
                </td>


                <td class="text-right" style="width: 14%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.statusDescricao}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.statusDescricao}} </span></span>
                  </span>
                </td>

                <td class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.origem}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.origem}} </span></span>
                  </span>
                </td>

                <td class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.deptoDescricao}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.deptoDescricao}} </span></span>
                  </span>
                </td>

              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="9" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

        </div>
        <!-- FINAL DA ABA 2 -->


        <!-- INICIO DA ABA 3 -->
        <div [ngStyle]="aba3 ? {'display': 'block'} : {'display': 'none' }" [ngClass]="{'fade-in': 'aba3'}">

          <p-table class="table-system" #dt12 [paginator]="false"
             [columns]="colslistaEventosPagamento" [value]="listaEventosPagamento"
            selectionMode="single">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">COD. EV. </span>
                  </span>

                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">EVENTO </span>
                  </span>

                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">FORMA PAG. </span>
                  </span>
                </th>


                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">BANCO </span>
                  </span>
                </th>

                <th class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">AGÊNCIA </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">CONTA </span>
                  </span>
                </th>


                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">DT PRI. VENC. </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">QTD. PARC. </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">N. CARTÃO </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">AUTORIZAÇÃO </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">N. DOC </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">N. TERM. </span>
                  </span>
                </th>

                <th class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">N. EST. </span>
                  </span>
                </th>

                <th class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">VL PARC. </span>
                  </span>
                </th>

                <th class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">VL TOTAL. </span>
                  </span>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>

              <tr>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigoEvento}}"><span
                        style="user-select: all; cursor: text"> {{rowData.codigoEvento }} </span></span>
                  </span>

                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricaoEvento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.descricaoEvento}} </span></span>
                  </span>

                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricaoFormaPagamento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.descricaoFormaPagamento}} </span></span>
                  </span>
                </td>


                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeBanco}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.nomeBanco}} </span></span>
                  </span>
                </td>

                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.agencia}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.agencia}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.conta}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.conta}} </span></span>
                  </span>
                </td>


                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataPrimeiroVencimento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.dataPrimeiroVencimento}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.parcela}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.parcela}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numeroCartao}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.numeroCartao}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.autorizacao}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.autorizacao}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numeroDocumento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.numeroDocumento}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numeroTerminal}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.numeroTerminal}} </span></span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numeroEstabelecimento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.numeroEstabelecimento}} </span></span>
                  </span>
                </td>

                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorParcela}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.valorParcela}} </span></span>
                  </span>
                </td>

                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorPagamento}}"> <span
                        style="user-select: all; cursor: text"> {{rowData.valorPagamento}} </span></span>
                  </span>
                </td>

              </tr>

            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="15" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>





        </div>
        <!-- FINAL DA ABA 3 -->



      </div>
    </div>




    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalInfCliente=false; '><i class="fas fa-undo"></i>
        Fechar</button>
    </p-footer>

  </p-dialog>
  <!-- Final Modal  Informacao cliente -->


</mat-card>