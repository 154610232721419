<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="box-content full-width-container"
    [ngStyle]="{'width': ((regra.view && regra.nome && regra.descricao && regra.dtIni && regra.regra != null && ((regra.grauRisco != null && regra.regra == true) || regra.regra == false && regra.grauRisco == null)) ? '75%':'60%')}">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>View</label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesViews" (onChange)="onChangeValue()"
          [(ngModel)]="regra.view" [filter]="true"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Nome</label>
        <input class="form-control" id="input" type="text" [(ngModel)]="regra.nome">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Descrição</label>
        <textarea class="form-control" rows="3" [(ngModel)]="regra.descricao"></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [(ngModel)]="regra.dtIni" [yearNavigator]="true"
          yearRange="2000:2030" dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="form-group col-sm-2">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [(ngModel)]="regra.dtFin" [yearNavigator]="true"
          yearRange="2000:2030" dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="form-group pull-left pl-2 pr-2">
        <label class="d-block" style="margin-top: 7px; margin-bottom: 6px;">Tipo de Consulta</label>
        <mat-radio-group [(ngModel)]="regra.regra">
          <mat-radio-button class="mr-3" *ngFor="let rec of regraRelatorio" [value]="rec.value">
            {{rec.label}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="form-group pull-left">
        <label class="d-block" style="margin-top: 7px; margin-bottom: 6px;">Grau de Risco</label>
        <mat-radio-group [(ngModel)]="regra.grauRisco" [disabled]="regra.regra === false || !regra.regra">
          <mat-radio-button class="mr-3" *ngFor="let rec of grauRisco" [value]="rec.value">
            {{rec.label}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div
      *ngIf="regra.view && regra.nome && regra.descricao && regra.dtIni && regra.regra != null && ((regra.grauRisco != null && regra.regra == true) || regra.regra == false && regra.grauRisco == null) ">
      <p-accordion (onOpen)="onTabOpen($event)">
        <p-accordionTab header="Selecione os Campos">
          <div class="row">
            <div class="col-sm-10">
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Campos disponíveis" targetHeader="Selecionados" [source]="viewCampos"
                  [target]="camposSelecionados" [dragdrop]="true" [responsive]="true" [showSourceControls]="false"
                  [showTargetControls]="false">
                  <ng-template let-campo pTemplate="item">
                    <span matTooltip="{{campo.descricao}}">
                      {{campo.campo}}
                    </span>
                  </ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Adicionar Campo com Fórmula" [disabled]="camposSelecionados.length === 0">
          <div class="form-row">
            <div class="form-group col-sm-3">
              <p-dropdown class="type-p-dropdown" [options]="opcoesCamposFormula"
                (onChange)="onChangeCampoFormula($event)"></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresComparacaoFormula"
                (onChange)="onChangeCampoComp($event)"></p-dropdown>
            </div>
            <div class="form-group col">
              <table class="table-formula" [style]="{'padding':'10px', 'font-size': '12px', 'width':'100%'}">
                <tr>
                  <td>
                    <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                      (click)="abreParenteses()">(</button>
                  </td>
                  <td>
                    <button style="font-weight: bold;" class="btn btn-sm btn-info" type="button"
                      (click)="fechaParenteses()">)</button>
                  </td>
                  <td>
                    <button style="font-weight: bold;" class="btn btn-sm btn-info" (click)="incluirSoma()">+</button>
                  </td>
                  <td>
                    <button style="font-weight: bold;" class="btn btn-sm btn-info" (click)="incluirSub()">-</button>
                  </td>
                  <td>
                    <button style="padding: 8px 12px 0px; font-weight: bold;" class="btn btn-sm btn-info"
                      (click)="incluirMult()">*</button>
                  </td>
                  <td>
                    <button style="font-weight: bold;" class="btn btn-sm btn-info" (click)="incluirDiv()">/</button>
                  </td>
                  <td>
                    <button style="font-weight: bold;" class="btn btn-sm btn-danger" type="button"
                      (click)="limparCampoFormula()"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <p-checkbox [(ngModel)]="regra.exibirZero" [binary]="true">Não exibir Zero</p-checkbox>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <textarea rows="3" [(ngModel)]="campoFormula" class="form-control"></textarea>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Selecione a Condição" [disabled]="camposSelecionados.length === 0 || regra.sql===null ">
          <div class="form-row" id="tabela-select-condicao">
            <div class="form-group col-sm-2" *ngIf="regraGrupo.regraCondicao.length > 0">
              <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresLogicos"
                [(ngModel)]="regraCondicao.operadoresLogicos"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <p-dropdown class="type-p-dropdown" [options]="opcoesCampos" [(ngModel)]="regraCondicao.viewCamposA"
                (onChange)="onChangeCampos($event)"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresComparacao"
                [(ngModel)]="regraCondicao.operadoresComparacao"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <p-dropdown class="type-p-dropdown"
                [disabled]="regraCondicao.sqlLivre===null || regraCondicao.operadoresComparacao.name  === 'is null' || regraCondicao.operadoresComparacao.name  === 'is not null'"
                [options]="opcoesCampos" [(ngModel)]="regraCondicao.viewCamposB"
                [disabled]="regraCondicao.sqlLivre===null ">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <input class="form-control" id="input"
                [disabled]="regraCondicao.viewCamposB===null  || regraCondicao.operadoresComparacao.name  === 'is null' || regraCondicao.operadoresComparacao.name  === 'is not null'"
                type="text" size="10" [(ngModel)]="regraCondicao.sqlLivre">
            </div>
            <div class="form-group col">
              <button class="btn btn-sm btn-info"
                [disabled]="regraGrupo.regraCondicao.length > 0 && !regraCondicao.operadoresLogicos" type="button"
                (click)="incluirCondicaoOk()"><i class="fas fa-plus"></i> Incluir</button>
              <button class="btn btn-sm btn-info" type="button" (click)="incluirGrupoOk()"><i
                  class="fas fa-layer-group"></i> Add Grupo</button>
            </div>
          </div>
          <div class="row" *ngIf="regraGrupo.regraCondicao.length > 0">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Condições</h3>
            </div>
          </div>
          <div class="form-row" *ngFor="let cond of regraGrupo.regraCondicao">
            <div class="form-group col-sm-2" *ngIf="!cond.operadoresLogicos">
              <input class="form-control" value="Sem operador lógico" [disabled]="true" />
            </div>
            <div class="form-group col-sm-2" *ngIf="cond.operadoresLogicos">
              <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresLogicos"
                [(ngModel)]="cond.operadoresLogicos"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <p-dropdown class="type-p-dropdown" [options]="opcoesCampos" [(ngModel)]="cond.viewCamposA"
                (onChange)="onChangeCampos($event)"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresComparacaoCondicao"
                [(ngModel)]="cond.operadoresComparacao"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <p-dropdown class="type-p-dropdown"
                [disabled]="cond.sqlLivre===null  || cond.operadoresComparacao.name  === 'is null' || cond.operadoresComparacao.name  === 'is not null'"
                [options]="opcoesCampos" [(ngModel)]="cond.viewCamposB"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <input class="form-control" id="input"
                [disabled]="cond.viewCamposB===null  || cond.operadoresComparacao.name  === 'is null' || cond.operadoresComparacao.name  === 'is not null'"
                type="text" size="20" [(ngModel)]="cond.sqlLivre">
            </div>
            <div class="form-group col-sm-2">
              <span class="delete-item" (click)="excluirCondicao1(cond)" matTooltip="Excluir Condição"
                matTooltipPosition="right"><i class="fas fa-trash-alt"></i></span>
            </div>
          </div>

          <div class="row" *ngIf="regra.regraGrupo.length > 0">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"></i> Grupos de Condições</h3>
            </div>
          </div>
          <div *ngFor="let cond of regra.regraGrupo">
            <div class="form-row" *ngIf="cond.operadoresLogicos">
              <div class="form-group col-sm-2">
                <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresLogicosGrupo"
                  [(ngModel)]="cond.operadoresLogicos"></p-dropdown>
              </div>
            </div>
            <div class="form-row" *ngFor="let c of cond.regraCondicao">
              <div class="form-group col-sm-2" *ngIf="c.operadoresLogicos">
                <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresLogicos"
                  [(ngModel)]="c.operadoresLogicos"></p-dropdown>
              </div>
              <div class="form-group col-sm-2" *ngIf="!c.operadoresLogicos">
                <input class="form-control" value="Sem operador lógico" [disabled]="true" />
              </div>
              <div class="form-group col-sm-2">
                <p-dropdown class="type-p-dropdown" [options]="opcoesCampos" [(ngModel)]="c.viewCamposA"></p-dropdown>
              </div>
              <div class="form-group col-sm-2">
                <p-dropdown class="type-p-dropdown" [options]="opcoesOperadoresComparacaoCondicao"
                  [(ngModel)]="c.operadoresComparacao"></p-dropdown>
              </div>
              <div class="form-group col-sm-2">
                <p-dropdown class="type-p-dropdown"
                  [disabled]="c.sqlLivre===null || c.operadoresComparacao.name  === 'is null' || c.operadoresComparacao.name  === 'is not null'"
                  [options]="opcoesCampos" [(ngModel)]="c.viewCamposB"></p-dropdown>
              </div>
              <div class="form-group col-sm-2">
                <input class="form-control" id="input"
                  [disabled]="c.viewCamposB===null || c.operadoresComparacao.name  === 'is null' || c.operadoresComparacao.name  === 'is not null'"
                  type="text" size="20" [(ngModel)]="c.sqlLivre">
              </div>
              <div class="form-group col-sm-2">
                <span class="delete-item" (click)="excluirCondicao(c)" matTooltip="Excluir Condição"
                  matTooltipPosition="right"><i class="fas fa-trash-alt"></i></span>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Adicionar Condição com Fórmula" [disabled]="camposSelecionados.length === 0">
          <mark class="d-inline-block mb-1" style="font-size: 13px;">Não informar o <strong>WHERE</strong>, somente a
            condição.</mark>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <textarea rows="3" cols="100" [(ngModel)]="regra.sql" class="form-control"></textarea>
            </div>
          </div>
        </p-accordionTab>

        <p-accordionTab header="Selecione a Ordenação" [disabled]="regra.regraGrupo.length === 0 && !regra.sql">
          <div class="row">
            <div class="col-sm-10">
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Campos disponíveis" targetHeader="Ordenados" [source]="camposAgrupadosFinal"
                  [target]="camposOrdenados" [dragdrop]="true" [responsive]="true" [showSourceControls]="false"
                  [showTargetControls]="false">
                  <ng-template let-campo pTemplate="item">
                    <span matTooltip="{{campo.descricao}}">
                      Campo: {{campo.campo}} - Tipo: {{campo.tipo}}
                    </span>
                  </ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Visualizar Query" [disabled]="camposOrdenados.length === 0">
          <div class="form-row" *ngIf="!queryTestada">
            <div class="form-group col-sm-12">
              <button type="button" class="btn btn-primary" (click)="testarRegra()"><i class="fas fa-check"></i> Testar
                Regra</button>
            </div>
          </div>
          <div class="alert alert-danger p-2" style="font-size: 14px;" role="alert" *ngIf="erroQuery && queryTestada">
            <i class="fas fa-exclamation-triangle"></i> Query está com erro, por favor revise as condições!
          </div>
          <div *ngIf="!erroQuery && queryTestada">
            <div *ngIf="previewRegra.values.length === 0">
              <div class="alert alert-success p-2" style="font-size: 14px;" role="alert">
                <i class="fas fa-check"></i> Query está correta, porém <strong>não há informação</strong> para está
                condição neste periodo para a empresa selecionada.
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <button type="button" class="btn btn-primary" (click)="salvarRegra()"><i class="fas fa-check"></i>
                    Salvar Regra</button>
                </div>
              </div>
            </div>
            <div *ngIf="previewRegra.values.length > 0">
              <table class="table table-border-boot">
                <thead>
                  <tr>
                    <th *ngFor="let p of $any(previewRegra).columns">
                      {{p}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let v of $any(previewRegra).values">
                    <td *ngFor="let c of $any(previewRegra).columns; let i = index">
                      {{v[i]}}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="alert alert-primary p-2 mt-1" style="font-size: 14px;" role="alert">
                <i class="fas fa-info-circle"></i> Esta é uma pré-visualização do resultado com no <strong>máximo 10
                  linhas</strong>, não reflete o total da consulta.
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <button type="button" class="btn btn-primary" (click)="salvarRegra()"><i class="fas fa-check"></i>
                    Salvar Regra</button>
                </div>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div> <!-- /div accordion-->
  </div>
</mat-card>