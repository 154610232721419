<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="row">
    <div class="col">

    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> RELATÓRIOS GERENCIAIS</h3>
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col mt-1">
        <div class="card-deck card-custom">

          <div class="card mt-2">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('despesas')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('despesas')" matTooltip="Relatório Despesas"
                matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório Despesas</span></h5>
            </div>
          </div>

          <div class="card mt-2">
            <div class="card-body">
              <span class="icon-card" (click)="showDialogRelatorio()"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="showDialogRelatorio()" matTooltip="Orçamento Gerencial"
                matTooltipPosition="above" style="width: 150px;"><span class="d-block">Orçamento Gerencial</span></h5>
            </div>
          </div>

          <div class="card mt-2" *ngFor="let relat of listaRelat">
            <div class="card-body">
              <span class="icon-card" (click)="showDialogRelatorioGerencial(relat)"><i
                  class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="showDialogRelatorioGerencial(relat)" matTooltip="{{relat.nome}}"
                matTooltipPosition="above" style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
            </div>
          </div>

          <div class="card mt-2" *ngFor="let versao of listaVersoes">
            <div class="card-body">
              <span class="icon-card"><i class="fas fa-chart-bar"></i></span>
              <span class="info-card">
                <h5 class="card-title" (click)="showDialogDashOrca(versao)" matTooltip="{{ versao.versao }}"
                  matTooltipPosition="above" style="width: 150px">
                  <span class="overflow-ellipse">{{ versao.versao }}</span>
                </h5>
                <p class="card-text text-overflow-dynamic-container" style="width: 150px; margin-bottom: 2px">
                  <small class="text-muted text-overflow-dynamic-ellipsis">{{ versao.tipo }} - {{ versao.descricao
                    }}</small>
                </p>
                <p class="card-text overflow-ellipse" style="width: 150px">
                  <small class="text-muted">{{ versao.status }}</small>
                </p>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>

  <!-- MODAL -->
  <p-dialog header="{{relatorio.nome}}" [modal]="true" appendTo="body" width="500" styleClass="modal-initial"
    [(visible)]="modalRelatorio" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="!relatorio.is_composta">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>

    <div class="row" *ngIf="relatorio.is_composta">
      <div class="form-group col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>DATA REFERÊNCIA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef" [disabled]="datasIndividuais">
            </p-calendar>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <p-checkbox [binary]="true" [(ngModel)]="datasIndividuais">Utilizar datas individuais</p-checkbox>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="form-row">
          <p-table class="table-system" [value]="dicRelatComposto[relatorio.id]"
            emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                  Data de referência</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                  *ngIf="datasIndividuais"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-config>
              <tr>
                <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                <td style="text-align: center">{{config.worksheet_title}}</td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                    [yearNavigator]="true" [defaultDate]="dataRef" yearRange="2000:2030"
                    [(ngModel)]="config['dataRef']">
                  </p-calendar>
                </td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                      (click)="carregarValores(config)"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
        *ngIf="!relatorio.is_composta"><i class="fas fa-laptop"></i> Tela</button>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!--Modal RELATÓRIO DESPESAS-->
  <p-dialog header="Relatório Despesas" [(visible)]="modalDespesas" width="700" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label class="d-block">Data Corte</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
      </div>
    </div>
    <!--<div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo</label>
        <p-multiSelect class="type-p-mult" [options]="centroCustoOpcoes" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>-->

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Área</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesArea" defaultLabel="Selecione área" [filter]="true"
          [(ngModel)]="areaSelecionadas" placeholder="{0} itens selecionados"
          selectedItemsLabel="{0} itens selecionados" appendTo="body"></p-multiSelect>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <!-- <mat-checkbox [(ngModel)]="separarArquivo">Separar Arquivo por Área</mat-checkbox>  -->
        <p-checkbox class="mr-1" [(ngModel)]="separarArquivo" [binary]="true" inputId="separarArquivo"></p-checkbox>
        <label for="iss" style="position:relative; top: 2px">Separar Arquivo por Área</label>
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="fecharModalDespesas();"><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary" (click)="gerarRelatorioDespesas()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL RELATÓRIO GERENCIAL -->
  <p-dialog header="Modal Orçamento Gerencial" [modal]="true" appendTo="body" [(visible)]="modalOrca" [responsive]="true" [closable]="true" [style]="{width: '900px'}">
    <div class="row" *ngIf="empresaId === 1 || empresaId === 115 || empresaId === 133">
      <div class="col-sm-12">
        <h3 class="sub-title mt-2">Versões</h3>
      </div>
      <div class="form-row col-sm-12">
        <div class="form-group col-sm-6">
          <label>Versão orçamento</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a versão" [options]="versaoList" [(ngModel)]="versaoId" appendTo="body" selectedItemsLabel="{0} items selecionados"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <label>Modelo de relatório</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o modelo do relatório" [options]="opcoesModelo" [(ngModel)]="modeloSelect" appendTo="body" selectedItemsLabel="{0} items selecionados"></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="empresaId === 1 || empresaId === 115 || empresaId === 133">
      <div class="col-sm-12">
        <h3 class="sub-title mt-3">Filtros</h3>
      </div>
      <div class="form-row col-sm-12">
        <div class="form-group col-sm-6">
          <label>Classificação</label>
          <p-multiSelect class="type-p-mult" [options]="opcoesClass" defaultLabel="Selecione classificação" [filter]="true" [(ngModel)]="classSelecionadas" appendTo="body" placeholder="Selecione" selectedItemsLabel="{0} items selecionados"></p-multiSelect>
        </div>
        <div class="form-group col-sm-6">
          <label>Área</label>
          <p-multiSelect class="type-p-mult" [options]="opcoesArea" defaultLabel="Selecione área" [filter]="true" [(ngModel)]="areaSelecionadas" placeholder="Selecione" appendTo="body" selectedItemsLabel="{0} items selecionados"></p-multiSelect>
        </div>
        <div class="form-group col-sm-6">
          <label>Centro de custo</label>
          <p-multiSelect class="type-p-mult" [options]="opcoesCcus" defaultLabel="Selecione centro de custo" [filter]="true" [(ngModel)]="ccusSelecionados" placeholder="Selecione" appendTo="body" selectedItemsLabel="{0} items selecionados"></p-multiSelect>
        </div>
        <div class="form-group col-sm-6">
          <label>Projeto</label>
          <p-multiSelect class="type-p-mult" [options]="opcoesProj" defaultLabel="Selecione projeto" [filter]="true" [(ngModel)]="projSelecionados" placeholder="Selecione" appendTo="body" selectedItemsLabel="{0} items selecionados"></p-multiSelect>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="empresaId !== 1 && empresaId !== 115 && empresaId !== 133">
      <div class="col-sm-12">
        <h3 class="sub-title mt-2">Versões</h3>
      </div>
      <div class="form-row col-sm-12">
        <div class="form-group col-sm-4">
          <label>Selecionar Configuração</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a configuração" [options]="configList" [(ngModel)]="configId" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Versão orçamento</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a versão" [options]="versaoList" [(ngModel)]="versaoId" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Moeda</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a moeda" [options]="opcoesMoeda" [(ngModel)]="moeda" appendTo="body"></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-row col-sm-12">
        <div class="form-group col-sm-12">
          <p-checkbox [binary]="true" [(ngModel)]="separarArquivo" label="Separar arquivo por área"></p-checkbox>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="w-100 d-flex justify-content-end">
        <button type="button" class="btnCustom-green" matTooltip="Exportar em Excel" matTooltipPosition="right" [disabled]="((empresaId !== 1 &&  empresaId !== 115 && empresaId !== 133) && (versaoId === null || configId === null || moeda === null)) || ((empresaId == 1 || empresaId == 115 || empresaId == 133) && (versaoId === null || modeloSelect === null))" (click)="baixarOrca()">
          <span class="material-icons">upload_file</span>
          <div>Excel</div>
        </button>
      </div>
    </p-footer>
  </p-dialog>

  <!-- MODAL ORÇAMENTO DASH -->
  <p-dialog header="Orçamento Gerencial" [(visible)]="modalOrcaDash" width="700" [modal]="true"
    styleClass="modal-custom" [responsive]="true" appendTo="body" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-6" *ngIf="versao && versao.consolidar">
        <label>Empresas</label>
        <p-multiSelect class="type-p-mult" [options]="listEmpresas" [(ngModel)]="empresasSelecionadas"
          defaultLabel="Selecione empresas" selectedItemsLabel="{0} empresas selecionadas" [maxSelectedLabels]="1"
          appendTo="body">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Centros de custo</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione centro de custo" selectedItemsLabel="{0} centros de custo selecionados"
          (onChange)="getProjOrca()" [maxSelectedLabels]="1" appendTo="body">
        </p-multiSelect>
      </div>
      <div class="form-group col-sm-6">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione projetos" selectedItemsLabel="{0} projetos selecionados" [maxSelectedLabels]="1"
          appendTo="body">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4 mb-1">
        <label>Moeda</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione moeda" [options]="opcoesMoedas"
          [(ngModel)]="moedaDash" appendTo="body"></p-dropdown>
      </div>
      <div class="form-group col-sm-4 mb-1" *ngIf="moedaDash > 1">
        <p-checkbox class="mt-4" [(ngModel)]="utilizarTxDif" [binary]="true" label="Simular taxa?"></p-checkbox>
      </div>
      <div class="form-group col-sm-4 mb-1" *ngIf="moedaDash > 1 && utilizarTxDif">
        <label>Taxa</label>
        <input style="min-height: 34px" class="form-control text-right" type="number" [(ngModel)]="txMoeda" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Visão</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="visaoDash" [options]="visoesDash"
          defaultLabel="Selecione a visão" [filter]="true" appendTo="body"></p-dropdown>
      </div>
      <div class="form-group col-sm-6 mb-1">
        <p-checkbox class="mt-4" [(ngModel)]="hideLines" [binary]="true" label="Esconder contas nulas?"></p-checkbox>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-primary" matTooltip="Exportar" matTooltipPosition="right"
        (click)="baixarDashOrca()"><i class="fas fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>


