<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="far fa-money-bill-alt"
                  style="font-size: 18px; vertical-align: middle; margin-top: -3px;"></i>Orçamento</h3>
              <div class="actions-card">
                <button [disabled]="disabledEditBtn" type="button" class="btn btn-xs btn-link"
                  (click)="mostraModalRelacionamento()">
                  <span class="material-icons full-icon" style="margin-right: -4px;" matTooltip="Atribuir Orçamento"
                    matTooltipPosition="above">mode_edit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="alternarPeriodo()">
                  <span class="material-icons full-icon" style="font-size: 23px; margin-right: -4px;"
                    matTooltip="Alterar Período" matTooltipPosition="above">date_range</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="alternarCentroCusto()">
                  <span class="material-icons full-icon" matTooltip="Centro de Custo"
                    matTooltipPosition="above">search</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideControlOrca()"
                  (click)="changeStateControlOrca()">
                  <span *ngIf="isCardControlOrca" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardControlOrca" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateControlOrca>
            <div class="row">
              <div class="col-sm-12">
                <p-table class="table-system" #dt [value]="periodoOrcamentoEntrada" [rows]="rows" [paginator]="true"
                  [pageLinks]="pageLinks" emptyMessage="Nenhum Registro Encontrado" [rowsPerPageOptions]="rowsPerPage"
                  selectionMode="false" (onRowSelect)="selectCheckbox($event)"
                  (onRowUnselect)="unselectCheckbox($event)" [style]="{'width':'100%'}" (onFilter)="onFilter($event)">

                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center tableHeaderCheckbox">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox [disabled]="selecionarTodosDisabled" (click)="selectTodos($event)">
                          </p-tableHeaderCheckbox>
                        </span>
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="Conta">
                          <span class="text-overflow-dynamic-ellipsis">
                            CONTA CONTÁBIL <p-sortIcon field="Conta"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'Conta','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="CentroCusto">
                          <span class="text-overflow-dynamic-ellipsis">
                            CENTRO DE CUSTO <p-sortIcon field="CentroCusto"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'CentroCusto','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="DataTrans">
                          <span class="text-overflow-dynamic-ellipsis">
                            DATA <p-sortIcon field="DataTrans"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'DataTrans','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="Documento">
                          <span class="text-overflow-dynamic-ellipsis">
                            DOCUMENTO <p-sortIcon field="Documento"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'Documento','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="Emitente">
                          <span class="text-overflow-dynamic-ellipsis">
                            EMITENTE <p-sortIcon field="Emitente"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'Emitente','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="Narrativa">
                          <span class="text-overflow-dynamic-ellipsis">
                            NARRATIVA <p-sortIcon field="Narrativa"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'Narrativa','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="DescricaoItem">
                          <span class="text-overflow-dynamic-ellipsis">
                            DESCRIÇÃO <p-sortIcon field="DescricaoItem"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'DescricaoItem','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="NomeAbreviacao">
                          <span class="text-overflow-dynamic-ellipsis">
                            NOME ABREV. <p-sortIcon field="NomeAbreviacao"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'NomeAbreviacao','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="DebitoCredito">
                          <span class="text-overflow-dynamic-ellipsis">
                            VALOR <p-sortIcon field="DebitoCredito"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'DebitoCredito','contains')" />
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="linhaDescricao">
                          <span class="text-overflow-dynamic-ellipsis">
                            STATUS <p-sortIcon field="linhaDescricao"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value,'linhaDescricao','contains')" />
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center tableCheckbox">
                        <p-tableCheckbox [ disabled]="rowData.checkboxdisabled" [value]="rowData"></p-tableCheckbox>
                      </td>
                      <td class="text-left" field="Conta" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.Conta }}">
                            {{ rowData.Conta }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="CentroCusto" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.CentroCusto }}">
                            {{ rowData.CentroCusto }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="DataTrans" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.DataTrans }}">
                            {{ rowData.DataTrans }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="Documento" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.Documento }}">
                            {{ rowData.Documento }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="Emitente" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.Emitente }}">
                            {{ rowData.Emitente }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="Narrativa" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.Narrativa }}">
                            {{ rowData.Narrativa }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="DescricaoItem" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.DescricaoItem }}">
                            {{ rowData.DescricaoItem }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="NomeAbreviacao" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.NomeAbreviacao }}">
                            {{ rowData.NomeAbreviacao }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="DebitoCredito" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.DebitoCredito }}">
                            {{ rowData.DebitoCredito }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left" field="status" filterMatchMode="contains">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.linhaDescricao }}">
                            {{ rowData.linhaDescricao }}
                          </span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="11" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i>
                          Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" style="margin-top: 8px;">
        <div class="alert alertcustom" [ngClass]="contasNaoMapeadas.length <= 0 ? 'alert-success' : 'alert-warning'"
          role="alert" style="cursor: text;">
          Faltam <strong>{{ contasNaoMapeadas.length}}</strong> Contas a serem mapeadas.
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!---Modal RELACIONAMENTO-->
<p-dialog header="Relacionar Conta" [(visible)]="exibirRelacionamento" styleClass="modal-initial"
  [style]="{width: '600px'}" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Relacionar</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="idOrcamento" [options]="listaOrcamento" [filter]="true"
        placeholder="Selecione o orçamento"></p-dropdown>
    </div>
  </div>


  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirRelacionamento = false"><i class="fas fa-undo"></i>
      Cancelar </button>
    <button type="button" class="btn btn-primary" (click)="salvarSemPlano()"><i class="fas fa-check"></i> Sem
      Plano</button>
    <button type="button" class="btn btn-primary" (click)="salvarRelacionamentos()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal RELACIONAMENTO-->

<!---Modal OBSERVAÇÃO-->
<p-dialog header="Observação" [(visible)]="exibirAjustes" styleClass="modal-initial" [style]="{width: '800px'}"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
  <!-- <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-file-alt"></i> Informe o ocorrido</h3>
      </div>
    </div> -->
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Mensagem</label>
      <textarea class="form-control" rows="6"></textarea>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirAjustes = false"><i class="fas fa-undo"></i>
      Cancelar </button>
    <button type="button" class="btn btn-primary"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal OBSERVAÇÃO-->

<!-- Inicio Modal Alternar Centro de custo FEITO-->
<p-dialog header="Alternar Centro de custo" [(visible)]="modalAlternarCentroCusto" [style]="{width: '700px'}"
  [modal]="true" class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione um Centro de Custo</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesCentroCusto"
        placeholder="Selecione um Centro de Custo" [(ngModel)]="centroCustoSelecionado"
        (onChange)="alternarCentroCustoSelecionada($event)"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalAlternarCentroCusto=false'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>
<!--  Final Modal Alternar Centro de custo FEITO-->

<!-- Inicio Model Alternar periodo -->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)">

      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
    </div>
  </div>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary"
      (click)='modalPeriodo=false; dataIni = null; dataFin = null; isProcurarPorData= false'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="isProcurarPorData = true;change();"><i
        class="fas fa-pencil-alt"></i> Alterar</button>
  </p-footer>
</p-dialog>
<!-- Final model Alternar periodo -->