<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Parâmetros RMS
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4" *ngIf="!dblinkedSessionService.hasEmpresaSelected">
    <div class="alert alert-xs alert-primary d-inline" role="alert">
      <i class="fas fa-info-circle"></i> Selecione uma empresa para continuar!
    </div>
  </div>

  <div class="row pb-4" *ngIf="dblinkedSessionService.hasEmpresaSelected">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="headerBox">
          <div class="header-box">
            <div class="header-box-content pt-3">
              <h3 class="title-card">&nbsp;</h3>
              <div class="actions-card d-flex align-items-center justify-content-end">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
        </div>
        <div class="box-content">
          <div class="form-row col-12 p-3">
            <label class="col-12 mb-3">configurações gerais</label>
            <div class="form-group col-4">
              <mat-slide-toggle class="mr-2" [(ngModel)]="isUseFlow">Usar fluxo de aprovação</mat-slide-toggle>
            </div>
            <div class="form-group col-4">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="mandatoryCostCenter">Centro de custo obrigatório no RMS</mat-slide-toggle>
            </div>
            <div class="form-group col-4">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="mandatoryOperation">Operação obrigatória</mat-slide-toggle>
            </div>
            <div class="form-group col-4">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="mandatoryFinancialApproval">Exigir aprovação no financeiro</mat-slide-toggle>
            </div>
            <div class="form-group col-4">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="mandatoryOrderApproval">Exigir aprovação nos pedidos</mat-slide-toggle>
            </div>
            <div class="form-group col-4">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="mandatoryBankInformation">Informações bancárias obrigatórias</mat-slide-toggle>
            </div>
            <div class="form-group col-4">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="forbidItemEditOrder">Travar edição de item no pedido</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row col-12 p-3">
            <label class="col-12 mb-3">Disparo de e-mail</label>
            <div class="form-group col-12">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="isSendMention">Menções</mat-slide-toggle>
            </div>
            <div class="form-group col-12">
              <mat-slide-toggle [disabled]='!isUseFlow' class="mr-2" [(ngModel)]="isSendFlow">Fluxo de aprovação</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row col-12">
            <div class="form-group col-6">
              <label>Projeto de aprovação</label>
              <p-dropdown [disabled]='!isUseFlow' appendTo="body" class="type-p-dropdown" [filter]="true" [options]="optionsProject" placeholder="Selecione o projeto" emptyMessage="Nenhum resultado encontrado!" [(ngModel)]="selectedProjectId"></p-dropdown>
            </div>
            <div class="form-group col-6">
              <label>Gestores de aprovação</label>
              <p-multiSelect [disabled]='!isUseFlow' appendTo="body" class="type-p-mult" [filter]="true" [options]="optionsManager" placeholder="Selecione o(s) gestor(es)" emptyMessage="Nenhum resultado encontrado!" [(ngModel)]="selectedManagers"></p-multiSelect >
            </div>
          </div>
          <div class="form-row col-12">
            <div class="form-group col-6">
              <label>Conta padrão para RMS financeiro</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="optionsCompanyAccount" placeholder="Selecione uma conta" emptyMessage="Nenhum resultado encontrado!" [(ngModel)]="selectedDefaultCompanyAccountId"></p-dropdown>
            </div>
            <div *ngIf="selectedDefaultCompanyAccountId != null" class="form-group col-6 d-flex align-items-end">
              <mat-slide-toggle class="mr-2" [(ngModel)]="allowChangingFinancialRmsAccount">Permitir alterar</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row col-12">
            <div class="form-group col-6">
              <label>Data padrão para filtro</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="optionsDate" placeholder="Selecione um tipo de data" emptyMessage="Nenhum resultado encontrado!" [(ngModel)]="selectedDateFilter"></p-dropdown>
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> O filtro será aplicado no RMS e na Central de aprovação!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>