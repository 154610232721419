<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div *ngIf="!planoContas">
      <div class="box-content">
        <p-table class="table-system" #datatable [value]="planoContasListDistinct" [rows]="rows" [paginator]="true"
          selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
          immutable="false" (onRowSelect)="editPlanoContas($event)" [lazy]="true" [totalRecords]="planoContasCount"
          (onLazyLoad)="lazyLoadPlanosContas($event)">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO</span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="datatable.filter($event.target.value, 'codCta', 'contains')">
              </th>
              <th class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">TIPO</span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="datatable.filter($event.target.value, 'indCta', 'contains')">
              </th>
              <th class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">NÍVEL</span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="datatable.filter($event.target.value, 'nivel', 'contains')">
              </th>
              <th class="text-left" style="width: 70%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO</span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="datatable.filter($event.target.value, 'descrCta', 'contains')">
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">STATUS</span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="datatable.filter($event.target.value, 'status', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData" [ngClass]="rowData.indCta === 'S'  ? 'bold locked' : ''">
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">
                    {{ rowData.codCta }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indCta}}">{{rowData.indCta
                    }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nivel}}">{{rowData.nivel }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 70%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="5" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div *ngIf="planoContas">
      <!-- <hr class="quebralinha"> -->
      <mat-tab-group class="overflow-tabs" appendTo="body">
        <mat-tab label="REFERENCIAL">
          <div class="box-content">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Conta Contábil: {{ planoContas.codCta }} - {{
                  planoContas.descrCta }}</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Centro de Custo</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesCentroCusto" [(ngModel)]="planoContasRefRelac.cadCcus.id" [filter]="true"
                  [disabled]="editPlanoContasRefRelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Conta Contábil RFB</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body" [options]="opcoesRfb"
                  [(ngModel)]="planoContasRefRelac.planoContasRfb.id" [filter]="true"
                  [disabled]="editPlanoContasRefRelacDisabled">
                  <ng-template let-item pTemplate="item">
                    <span [class.bold]="item.data?.tipoCta === 'S'">{{ item.label }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <button [disabled]="cancelPlanoContasRefRelacDisabled" type="button" class="btn btn-sm btn-secondary"
                  matTooltip="Cancelar Alterações" (click)="cancel()"> Cancelar</button>
                <button *ngIf="planoContasRefRelac.id" [disabled]="updatePlanoContasRefRelacDisabled" type="button"
                  class="btn btn-sm btn-primary" matTooltip="Salvar alterações" (click)="updatePlanoContasRefRelac()"><i
                    class="fas fa-check"></i> Salvar</button>
                <button *ngIf="!planoContasRefRelac.id" [disabled]="addPlanoContasRefRelacDisabled" type="button"
                  class="btn btn-sm btn-info" matTooltip="Incluir novo relacionamento"
                  (click)="addPlanoContasRefRelac()"><i class="fas fa-plus"></i> Incluir</button>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-bezier-curve"> </i> Relacionamentos</h3>
              </div>
            </div>
            <p-table class="table-system" [value]="planoContasRefRelacList" [rows]="rows" [paginator]="false"
              selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="editPlanoContasRefRelac($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 60%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CONTA ADIÇÃO</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 90px; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width:35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.cadCcus"
                        matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">
                        {{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 60%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.planoContasRfb.codCta }} - {{ rowData.planoContasRfb.descrCta }}">{{
                        rowData.planoContasRfb.codCta }} - {{ rowData.planoContasRfb.descrCta }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 90px;">
                    <span class="delete" matTooltip="Excluir Relacionamento!">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!--  <div id="div-botoes">
                    <div class="margin-botoes">
                      <button mat-raised-button pButton type="button" (click)="back()" title="Visualizar Plano de Contas" style="padding-top: 8px;">Voltar</button>
                  </div>
                </div> -->
          </div>
        </mat-tab>

        <mat-tab label="IRPJ" *ngIf="tbIrpjM300Relac">
          <div class="box-content">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Conta Contábil: {{ planoContas.codCta }} - {{
                  planoContas.descrCta }}</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Centro de Custo</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesCentroCusto" [(ngModel)]="tbIrpjM300Relac.cadCcus.id" [filter]="true"
                  [disabled]="editTbIrpjM300RelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Adição</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesAdicaoIrpj" [(ngModel)]="tbIrpjM300Relac.tbIrpjM300Adicao.id" [filter]="true"
                  [disabled]="editTbIrpjM300RelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Exclusão</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesExclusaoIrpj" [(ngModel)]="tbIrpjM300Relac.tbIrpjM300Exclusao.id" [filter]="true"
                  [disabled]="editTbIrpjM300RelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <button type="button" [disabled]="cancelTbIrpjM300RelacDisabled" class="btn btn-sm btn-secondary"
                  matTooltip="Cancelar alterações" (click)="cancel()"> Cancelar</button>
                <button type="button" *ngIf="tbIrpjM300Relac.id" [disabled]="updateTbIrpjM300RelacDisabled"
                  class="btn btn-sm btn-primary" matTooltip="Salvar alterações" (click)="updateTbIrpjM300Relac()"><i
                    class="fas fa-check"></i> Salvar</button>
                <button type="button" *ngIf="!tbIrpjM300Relac.id" [disabled]="addTbIrpjM300RelacDisabled"
                  class="btn btn-sm btn-info" matTooltip="Incluir novo relacionamento" (click)="addTbIrpjM300Relac()"><i
                    class="fas fa-plus"></i> Incluir</button>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-bezier-curve"> </i> Relacionamentos</h3>
              </div>
            </div>
            <p-table class="table-system" [value]="tbIrpjM300RelacList" [rows]="rows" [paginator]="false"
              selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="editTbIrpjM300Relac($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 30%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CONTA ADIÇÃO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CONTA EXCLUSÃO</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 90px; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width:30%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.cadCcus"
                        matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">
                        {{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.tbIrpjM300Adicao.cod }} - {{ rowData.tbIrpjM300Adicao.descricao }}">{{
                        rowData.tbIrpjM300Adicao.cod }} - {{ rowData.tbIrpjM300Adicao.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.tbIrpjM300Exclusao.cod }} - {{ rowData.tbIrpjM300Exclusao.descricao }}">{{
                        rowData.tbIrpjM300Exclusao.cod }} - {{ rowData.tbIrpjM300Exclusao.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 90px;">
                    <span class="delete" matTooltip="Excluir Relacionamento!" (click)="deleteTbIrpjM300Relac(rowData)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- <div id="div-botoes">
                  <div class="margin-botoes">
                    <button mat-raised-button pButton type="button" (click)="back()" title="Visualizar Plano de Contas" style="padding-top: 8px;">Voltar</button>
                </div>
              </div> -->

          </div>
        </mat-tab>

        <mat-tab label="CSLL" *ngIf="tbCsllM350Relac">
          <div class="box-content">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Conta Contábil: {{ planoContas.codCta }} - {{
                  planoContas.descrCta }}</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Centro de Custo</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesCentroCusto" [(ngModel)]="tbCsllM350Relac.cadCcus.id" [filter]="true"
                  [disabled]="editTbCsllM350RelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Adição</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesAdicaoCsll" [(ngModel)]="tbCsllM350Relac.tbCsllM350Adicao.id" [filter]="true"
                  [disabled]="editTbCsllM350RelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Exclusão</label>
                <p-dropdown class="type-p-dropdown" panelStyleClass="custom-width" appendTo="body"
                  [options]="opcoesExclusaoCsll" [(ngModel)]="tbCsllM350Relac.tbCsllM350Exclusao.id" [filter]="true"
                  [disabled]="editTbCsllM350RelacDisabled"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-6">
                <button type="button" [disabled]="cancelTbCsllM350RelacDisabled" class="btn btn-sm btn-secondary"
                  matTooltip="Cancelar alterações" (click)="cancel()"> Cancelar</button>
                <button type="button" *ngIf="tbCsllM350Relac.id" [disabled]="updateTbCsllM350RelacDisabled"
                  class="btn btn-sm btn-primary" matTooltip="Salvar alterações" (click)="updateTbCsllM350Relac()"><i
                    class="fas fa-check"></i> Salvar</button>
                <button type="button" *ngIf="!tbCsllM350Relac.id" [disabled]="addTbCsllM350RelacDisabled"
                  class="btn btn-sm btn-info" matTooltip="Incluir novo relacionamento" (click)="addTbCsllM350Relac()"><i
                    class="fas fa-plus"></i> Incluir</button>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-bezier-curve"> </i> Relacionamentos</h3>
              </div>
            </div>
            <p-table class="table-system" [value]="tbCsllM350RelacList" [rows]="rows" [paginator]="false"
              selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="editTbCsllM350Relac($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 30%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CONTA ADIÇÃO</span>
                    </span>
                  </th>
                  <th class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">CONTA EXCLUSÃO</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 90px; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width:30%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" *ngIf="rowData.cadCcus"
                        matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">
                        {{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.tbCsllM350Adicao.cod }} - {{ rowData.tbCsllM350Adicao.descricao }}">{{
                        rowData.tbCsllM350Adicao.cod }} - {{ rowData.tbCsllM350Adicao.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.tbCsllM350Exclusao.cod }} - {{ rowData.tbCsllM350Exclusao.descricao }}">{{
                        rowData.tbCsllM350Exclusao.cod }} - {{ rowData.tbCsllM350Exclusao.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 90px;">
                    <span class="delete" matTooltip="Excluir Relacionamento!" (click)="deleteTbCsllM350Relac(rowData)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!--  <div id="div-botoes">
                  <div class="margin-botoes">
                    <button mat-raised-button pButton type="button" (click)="back()" title="Visualizar Plano de Contas" style="padding-top: 8px;">Voltar</button>
                </div>
              </div> -->
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div *ngIf="!planoContas">
      <div *ngIf="mapeamento" class="alert alert-warning mt-3" role="alert">
        <strong><i class="fas fa-exclamation-triangle"></i></strong> Faltam <strong>{{ mapeamento.pendentes }}</strong>
        Contas Contábeis Analíticas serem mapeadas de um total de <strong>{{ mapeamento.total }}</strong>
      </div>
    </div>
  </mat-card-content>
</mat-card>