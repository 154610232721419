<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>


  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsPalestras" [value]="palestras" [paginator]="true"
          [rows]="rows" [totalRecords]="palestras.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis">COD <p-sortIcon [field]="'codigo'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th class="text-left" style="width: 26%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                  <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'data'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'data', 'contains')">
              </th>
              <th class="text-left" style="width: 26%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeseminarista'">
                  <span class="text-overflow-dynamic-ellipsis">NOME PALESTRANTE <p-sortIcon [field]="'nomeseminarista'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nomeseminarista', 'contains')">
              </th>

              <th class="text-left" style="width: 26%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresa'">
                  <span class="text-overflow-dynamic-ellipsis">EMPRESA <p-sortIcon [field]="'empresa'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'empresa', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero_presentes'">
                  <span class="text-overflow-dynamic-ellipsis">Nº PRESENTES <p-sortIcon [field]="'numero_presentes'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'numero_presentes', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'palestra_aberta'">
                  <span class="text-overflow-dynamic-ellipsis">PALESTRA ABERTA <p-sortIcon [field]="'palestra_aberta'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesStatus" defaultLabel="Selecione" [filter]="false"
                  (onChange)="dt2.filter($event.value, 'palestra_aberta', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                    }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}  "> {{rowData.data}} </span>
                </span>
              </td>
              <td class="text-left" style="width: 26%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip=" {{rowData.nomeseminarista}}  ">{{rowData.nomeseminarista}} </span>
                </span>
              </td>

              <td class="text-left" style="width: 26%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.empresa}}   ">{{rowData.empresa}}
                  </span>
                </span>
              </td>

              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.numero_presentes}} ">
                    {{rowData.numero_presentes}} </span>
                </span>
              </td>


              <td class="text-center" style="width: 10%;">

                <p-checkbox class="mr-1" [(ngModel)]="rowData.palestra_aberta" [binary]="true"
                      inputId="palestra_aberta" [disabled]="true"></p-checkbox>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="8" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>


      <p-dialog header="Palestras" [(visible)]="modalPalestras" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">



        <!-- modalPalestras -->
        <div *ngIf="modalPalestras">
          <mat-tab-group (selectedTabChange)="tabChange($event)">
            <mat-tab label="Palestra">

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Seminarista <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                    [options]="opcoesPalestrante" [(ngModel)]="palestraObj.palestrante.id"></p-dropdown>
                </div>
              </div>

              <!-- [(ngModel)]="palestraObj.vendedor.id" -->

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Vendedor</label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesVendedor"
                    [(ngModel)]="palestraObj.vendedor.id"></p-dropdown>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Nome empresa </label>
                  <input type="text" [(ngModel)]="palestraObj.strempresa" class="form-control text-left"
                    maxlength="255">
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Número de participantes</label>
                  <input [(ngModel)]="palestraObj.numeroPresentes" type="number" class="form-control">
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>UF <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesUF"
                    [(ngModel)]="ufSelecionada" (onChange)="buscarUf(ufSelecionada)"></p-dropdown>
                </div>
                <div class="form-group col-sm-6">
                  <label>LOCAL <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                    [options]="opcoesMunicipio" [(ngModel)]="palestraObj.municipioibge.id"></p-dropdown>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>ENDEREÇO </label>
                  <input [(ngModel)]="palestraObj.endereco" class="form-control text-left" type="text" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Data</label>
                  <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true"
                    yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="palestraObj.data"
                    dateFormat="dd/mm/yy" >
                  </p-calendar>
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-4">
                  <mat-slide-toggle [(ngModel)]="palestraObj.palestraAberta">PALESTRA ABERTA</mat-slide-toggle>
                </div>
                <div class="form-group col-sm-4">
                  <mat-slide-toggle [(ngModel)]="palestraObj.agendadaPalestrante">AGENDADA PALESTRANTE
                  </mat-slide-toggle>
                </div>
                <div class="form-group col-sm-4">
                  <mat-slide-toggle [(ngModel)]="palestraObj.descontoFolha">DESCONTO EM FOLHA</mat-slide-toggle>
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>OBSERVAÇÃO</label>
                  <textarea [(ngModel)]="palestraObj.observacao" class="form-control text-left"
                    maxlength="255"></textarea>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Link Cadastro</label>
                  <input [(ngModel)]="palestraObj.captureLink" class="form-control text-left" type="text" readonly />
                </div>
              </div>

              <div class="form-row">
                <div class="col-sm-12 ">
                  <h3 class="sub-title"><i class="fas fa-ticket-alt"> </i> INGRESSOS</h3>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>QUANTIDADE </label>
                  <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
                  <input class="form-control text-right" type="number" [(ngModel)]="palestraIngresso.quantidade" />
                </div>
                <div class="form-group col-sm-3">
                  <label>TIPO </label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                    [options]="opcoesTipoIngresso" [(ngModel)]="palestraIngresso.tipoIngressoid"></p-dropdown>
                </div>
                <div class="form-group col-sm-3">
                  <label class="d-block" style="visibility: hidden;"> do not remove </label>
                  <button type="button" style="margin-top: 3px;" class="btn btn-sm btn-info"
                    (click)="adicionarIngresso()">
                    <i class="fas fa-{{btnTipoIngresso ? 'plus' : 'edit'}}"></i>
                    {{btnTipoIngresso ? 'Adicionar' : 'Atualizar'}}
                  </button>
                </div>
              </div>



              <!-- Inicio tabela quantidade de ingressos -->
              <div class="form-row">
                <div class="form-group col-sm-12">

                  <p-table class="table-system" selectionMode="single" [value]="palestraObj.palestraIngresso">

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width:70%" class="text-rigth">Tipo de Ingresso </th>
                        <th style="width:20%">Quantidade</th>
                        <th style="width:10%" class="text-center">AÇÃO</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData" style="height:25px">
                        <td>
                          <span class="overflow-ellipse text-rigth" matTooltip="{{ rowData.tipoIngressoDescricao  }}">
                            <span style="user-select: all; cursor: text"> {{ rowData.tipoIngressoDescricao }} </span>
                          </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse" matTooltip="{{ rowData.quantidade }}"> <span
                              style="user-select: all; cursor: text"> {{ rowData.quantidade }}</span> </span>
                        </td>

                        <td class="text-center overflow-ellipse">
                          <span class="edit" matTooltip="Editar Item" (click)="editarPalestraIngresso(rowData)"><i
                              class="far fa-edit"></i></span>
                          <span class="delete" matTooltip="Excluir Item" (click)="deletarPalestraIngresso(rowData)"><i
                              class="far fa-trash-alt"></i></span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>

                      <tr style="height:25px">
                        <td [attr.colspan]="4" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhum Ingresso adicionado
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
              </div>
              <!-- Final tabela quantidade de ingressos -->

              <!-- INICIO DA PORCENTAGEM DE CARTEIRA -->

              <div class="form-row">
                <div class="col-sm-12 ">
                  <h3 class="sub-title"><i class="fas fa-wallet"></i> BALANCEAR CARTEIRA</h3>
                </div>
              </div>


              <div class="form-row">

                <div class="form-group col-sm-5">
                  <label>CARTEIRA </label>
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesCarteira"
                    [(ngModel)]="palestraCarteiraPorcentagem.carteiraId"></p-dropdown>
                </div>

                <div class="form-group col-sm-3">
                  <label>PORCENTAGEM </label>
                  <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
                  <input class="form-control text-right" type="number"
                    [(ngModel)]="palestraCarteiraPorcentagem.porcentagem" />
                </div>

                <div class="form-group col-sm-3">
                  <label class="d-block" style="visibility: hidden;"> do not remove </label>
                  <button type="button" style="margin-top: 3px;" class="btn btn-sm btn-info"
                    (click)="adicionarCarteiraPorcentagem()">
                    <i class="fas fa-{{btnCarteira ? 'plus' : 'edit'}}"></i>
                    {{ btnCarteira ? 'Adicionar' : 'Atualizar' }}
                  </button>
                </div>
              </div>


              <!-- INICIO DA TABELA PARA CARTEIRA -->

              <div class="form-row">
                <div class="form-group col-sm-12">

                  <p-table class="table-system" selectionMode="single"
                    [value]="palestraObj.palestraCarteiraPorcentagem">

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width:70%" class="text-rigth">CARTEIRA </th>
                        <th style="width:20%">PORCENTAGEM %</th>
                        <th style="width:10%" class="text-center">AÇÃO</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData" style="height:25px">
                        <td>
                          <span class="overflow-ellipse text-rigth" matTooltip="{{ rowData.descricaoCarteira  }}">
                            <span style="user-select: all; cursor: text"> {{ rowData.descricaoCarteira }} </span>
                          </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse" matTooltip="{{ rowData.porcentagem }}"> <span
                              style="user-select: all; cursor: text"> {{ rowData.porcentagem }}</span> </span>
                        </td>

                        <td class="text-center overflow-ellipse">
                          <span class="edit" matTooltip="Editar Item" (click)="editarCarteiraPorcentagem(rowData)"><i
                              class="far fa-edit"></i></span>
                          <span class="delete" matTooltip="Excluir Item"
                            (click)="deletarCarteiraPorcentagem(rowData)"><i class="far fa-trash-alt"></i></span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>

                      <tr style="height:25px">
                        <td [attr.colspan]="4" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhuma Carteira adicionada
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
              </div>

              <!-- FINAL DA TABELA PARA CARTEIRA -->



              <!-- FINAL DA PORCENTAGEM DE CARTEIRA -->




            </mat-tab>
            <mat-tab label="Informações Adicionais">

              <!-- Inicio tabela Informacoes Adicionais -->
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Quantidade de ingressos</label>
                  <p-table class="table-system" selectionMode="single" [value]="informacoesAdicionais">

                    <ng-template pTemplate="header">
                      <tr>
                        <th style="width:70%" class="text-rigth">Descrição </th>
                        <th style="width:30%" class="text-right">Valor</th>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr [pSelectableRow]="rowData" style="height:25px">
                        <td>
                          <span class="overflow-ellipse text-rigth" matTooltip="{{ rowData.descricao  }}">
                            <span style="user-select: all; cursor: text"> {{ rowData.descricao }} </span> </span>
                        </td>
                        <td>
                          <span class="overflow-ellipse text-right" matTooltip="{{ rowData.quantidade }}"> <span
                              style="user-select: all; cursor: text"> {{ rowData.quantidade }}</span> </span>
                        </td>

                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>

                      <tr style="height:25px">
                        <td [attr.colspan]="4" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Nenhuma Informação adicional
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>


                </div>
              </div>
              <!-- Final tabela Informacoes adicionais -->




            </mat-tab>
          </mat-tab-group>




















        </div>
        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>



        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalPalestras=false"><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="salvarPalestra();" [disabled]="btnSalvarDisabled"><i
              class="fas fa-check"></i> Salvar</button>
        </p-footer>
      </p-dialog>
      <!--Final Modal cadastro de Palestras-->





    </mat-card-content>
  </div>

</mat-card>