<app-data-table mainTitle="EMPRESAS"
                singleTitle="EMPRESA"
                routerPath="/xml/company"
                application="auditor"
                entityPath="/company"
                gender="feminine"
                [entity]="company"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
