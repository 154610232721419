<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>

  <div class="row" *ngIf="mostraIcones">
    <div class="col-sm-12">
      <h3 class="sub-title mt-3"><i class="fas fa-bars"></i> TODAS AS DESPESAS DIRETAS</h3>
    </div>
  </div>
  <!-- SELEÇÃO DE MÓDULO-->
  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="mostraIcones">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showTipoIcone('1')"><i class="far fa-credit-card"></i></span>
            <h5 class="card-title" (click)="showTipoIcone('1')" matTooltip="Cartão de Crédito"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Cartão de Crédito</span></h5>
            <!-- <p class="card-text"><small class="text-muted">Acessado última vez: <span class="date"> 4 dias</span></small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showTipoIcone('3')"><i class="far fa-file-alt"></i></span>
            <h5 class="card-title" (click)="showTipoIcone('3')" matTooltip="Nota de Débito" matTooltipPosition="above"
              style="width: 150px;"><span class="d-block">Nota de Débito</span></h5>
            <!-- <p class="card-text"><small class="text-muted">Acessado última vez: <span class="date"> 4 dias</span></small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showTipoIcone('2')"><i class="fas fa-plane"></i></span>
            <h5 class="card-title" (click)="showTipoIcone('2')" matTooltip="Relat. Despesa Viagem"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relat. Despesa Viagem</span></h5>
            <!-- <p class="card-text"><small class="text-muted">Acessado última vez: <span class="date"> 4 dias</span></small></p> -->
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showTipoIcone('4')"><i class="fas fa-dollar-sign"></i></span>
            <h5 class="card-title" (click)="showTipoIcone('4')" matTooltip="Despesa Direta" matTooltipPosition="above"
              style="width: 150px;"><span class="d-block">Despesa Direta</span></h5>
            <!-- <p class="card-text"><small class="text-muted">Acessado última vez: <span class="date"> 4 dias</span></small></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TABELA INFO CADASTRADA-->
  <div *ngIf="!mostraIcones">
    <mat-card-content>

      <mat-tab-group (selectedIndexChange)="onTabChange($event)">
        <mat-tab label="Em Aberto ({{faturaAberta.length}})">
          <div class="box-content pt-0">
            <div class="clearfix"></div>
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"> &nbsp;</h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>

            <p-table class="table-system" #dt1 [columns]="colsFaturaAberta" [value]="faturaAberta" [rows]="rows"
              [paginator]="true" (onFilter)="onFilterFatura($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodosAberta($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_favorecido'">
                      <span class="text-overflow-dynamic-ellipsis">NOME/HISTÓRICO <p-sortIcon
                          [field]="'nome_favorecido'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'nome_favorecido', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon [field]="'numero'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'numero', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'data_vencimento'"
                          verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'data_vencimento', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_tipo_fatura'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO FATURA <p-sortIcon
                          [field]="'descricao_tipo_fatura'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricao_tipo_fatura', 'contains')">

                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                      <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'valorTotal'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'valorTotal', 'contains')">

                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome_favorecido}}">{{rowData.nome_favorecido}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numero }}">
                        {{rowData.numero }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao_tipo_fatura}}">{{rowData.descricao_tipo_fatura}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valorTotal | number : '1.2-2' : 'pt-BR'}}">{{rowData.valorTotal | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>

        <!-- Inicio tab aguardando aprovacao -->
        <mat-tab label="Aguardando Aprovação ({{faturaAguardandoAprovacao.length}})">
          <div class="box-content pt-0">
            <div class="clearfix"></div>
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"> &nbsp;</h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
            <p-table class="table-system" #dt2 [columns]="colsFaturaAguardandoAprovacao"
              [value]="faturaAguardandoAprovacao" [rows]="rows" [paginator]="true" (onFilter)="onFilterFatura($event)"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
              [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodosAberta($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_favorecido'">
                      <span class="text-overflow-dynamic-ellipsis">NOME/HISTÓRICO <p-sortIcon
                          [field]="'nome_favorecido'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'nome_favorecido', 'contains')">

                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon [field]="'numero'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'numero', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'data_vencimento'"
                          verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'data_vencimento', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_tipo_fatura'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO FATURA <p-sortIcon
                          [field]="'descricao_tipo_fatura'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'descricao_tipo_fatura', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                      <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'valorTotal'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'valorTotal', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome_favorecido}}">{{rowData.nome_favorecido}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.numero | number : '1.2-2' : 'pt-BR'}}">{{rowData.numero | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao_tipo_fatura}}">{{rowData.descricao_tipo_fatura}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valorTotal | number : '1.2-2' : 'pt-BR'}}">{{rowData.valorTotal |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
        <!-- final  tab aguardando aprovacao -->

        <!-- Inicio tab Aprovado -->
        <mat-tab label="Aprovado ({{faturaAprovado.length}})">
          <div class="box-content pt-0">
            <div class="clearfix"></div>
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"> &nbsp;</h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dt3.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
            <p-table class="table-system" #dt3 [columns]="colsFaturaAprovado" [value]="faturaAprovado" [rows]="rows"
              [paginator]="true" (onFilter)="onFilterFatura($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodosAberta($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_favorecido'">
                      <span class="text-overflow-dynamic-ellipsis">NOME/HISTÓRICO <p-sortIcon
                          [field]="'nome_favorecido'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'nome_favorecido', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon [field]="'numero'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'numero', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'data_vencimento'"
                          verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'data_vencimento', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_tipo_fatura'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO FATURA <p-sortIcon
                          [field]="'descricao_tipo_fatura'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'descricao_tipo_fatura', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                      <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'valorTotal'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'valorTotal', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt3.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome_favorecido}}">{{rowData.nome_favorecido}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.numero | number : '1.2-2' : 'pt-BR'}}">{{rowData.numero | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao_tipo_fatura}}">{{rowData.descricao_tipo_fatura}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valorTotal | number : '1.2-2' : 'pt-BR'}}">{{rowData.valorTotal |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
        <!-- Final tab aprovador -->

        <!-- Inicio tab reprovado -->
        <mat-tab label="Reprovado ({{faturaReprovado.length}})">
          <div class="box-content pt-0">
            <div class="clearfix"></div>
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"> &nbsp;</h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dt4.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="clearfix"></div>
            <p-table class="table-system" #dt4 [columns]="colsFaturaReprovado" [value]="faturaReprovado" [rows]="rows"
              [paginator]="true" (onFilter)="onFilterFatura($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodosAberta($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_favorecido'">
                      <span class="text-overflow-dynamic-ellipsis">NOME/HISTÓRICO <p-sortIcon
                          [field]="'nome_favorecido'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt4.filter($event.target.value, 'nome_favorecido', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon [field]="'numero'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt4.filter($event.target.value, 'numero', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'data_vencimento'"
                          verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt4.filter($event.target.value, 'data_vencimento', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_tipo_fatura'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO FATURA <p-sortIcon
                          [field]="'descricao_tipo_fatura'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt4.filter($event.target.value, 'descricao_tipo_fatura', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                      <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'valorTotal'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt4.filter($event.target.value, 'valorTotal', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt4.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome_favorecido}}">{{rowData.nome_favorecido}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.numero | number : '1.2-2' : 'pt-BR'}}">{{rowData.numero | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao_tipo_fatura}}">{{rowData.descricao_tipo_fatura}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valorTotal | number : '1.2-2' : 'pt-BR'}}">{{rowData.valorTotal |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
        <!-- Final  tab reprovado -->
        <mat-tab label="Contabilizado ({{faturaCont.length}})">
          <div class="box-content pt-0">
            <div class="clearfix"></div>
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"> &nbsp;</h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dt5.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <p-table class="table-system" #dt5 [columns]="colsFaturaCont" [value]="faturaCont" [rows]="rows"
              [paginator]="true" (onFilter)="onFilterFatura($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodosAberta($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_favorecido'">
                      <span class="text-overflow-dynamic-ellipsis">NOME/HISTÓRICO <p-sortIcon
                          [field]="'nome_favorecido'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt5.filter($event.target.value, 'nome_favorecido', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon [field]="'numero'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt5.filter($event.target.value, 'numero', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'data_vencimento'"
                          verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt5.filter($event.target.value, 'data_vencimento', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_tipo_fatura'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO FATURA <p-sortIcon
                          [field]="'descricao_tipo_fatura'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt5.filter($event.target.value, 'descricao_tipo_fatura', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                      <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'valorTotal'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt5.filter($event.target.value, 'valorTotal', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt5.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome_favorecido}}">{{rowData.nome_favorecido}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.numero | number : '1.2-2' : 'pt-BR'}}">{{rowData.numero | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao_tipo_fatura}}">{{rowData.descricao_tipo_fatura}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valorTotal | number : '1.2-2' : 'pt-BR'}}">{{rowData.valorTotal |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>

        <mat-tab label="Encerrado ({{faturaEnc.length}})">
          <div class="box-content pt-0">
            <div class="clearfix"></div>
            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"> &nbsp;</h3>
                <div class="actions-card">
                  <button type="button" class="btn btn-xs btn-link" (click)="dt6.exportCSV()"
                    matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <p-table class="table-system" #dt6 [columns]="colsFaturaEnc" [value]="faturaEnc" [rows]="rows"
              [paginator]="true" (onFilter)="onFilterFatura($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodosAberta($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_favorecido'">
                      <span class="text-overflow-dynamic-ellipsis">NOME/HISTÓRICO <p-sortIcon
                          [field]="'nome_favorecido'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt6.filter($event.target.value, 'nome_favorecido', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numero'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon [field]="'numero'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt6.filter($event.target.value, 'numero', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_vencimento'">
                      <span class="text-overflow-dynamic-ellipsis">VENCIMENTO <p-sortIcon [field]="'data_vencimento'"
                          verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt6.filter($event.target.value, 'data_vencimento', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_tipo_fatura'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO FATURA <p-sortIcon
                          [field]="'descricao_tipo_fatura'" verflow></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt6.filter($event.target.value, 'descricao_tipo_fatura', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                      <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'valorTotal'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt6.filter($event.target.value, 'valorTotal', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'" verflow>
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt6.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nome_favorecido}}">{{rowData.nome_favorecido}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.numero | number : '1.2-2' : 'pt-BR'}}">{{rowData.numero | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data_vencimento}}">{{rowData.data_vencimento}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao_tipo_fatura}}">{{rowData.descricao_tipo_fatura}}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valorTotal | number : '1.2-2' : 'pt-BR'}}">{{rowData.valorTotal |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </div>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <!--Modal cadastro de Faturas FEITO-->
  <p-dialog [(visible)]="modalFatura" [style]="{width: '800px'}" [modal]="true" styleClass="fatura-altura-inicial"
    [responsive]="true" appendTo="body" [closable]="true">

    <p-header *ngIf="!botaoNovaFatura">
      Detalhes {{labelCabecalhoFatura}} - Número: {{numeroCartao}}
    </p-header>

    <p-header *ngIf="botaoNovaFatura">
      Detalhes {{labelCabecalhoFatura}}
    </p-header>

    <div *ngIf="modalFatura">

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Nome <span class="obrigatorio">*</span></label>
          <input class="form-control" type="text" [(ngModel)]="nomeFavorecido" maxlength="255" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label *ngIf="iconesSelecionado ==='1'">Número Cartão <span class="obrigatorio">*</span></label>
          <label *ngIf="iconesSelecionado !=='1'">Número Controle <span class="obrigatorio">*</span></label>
          <input class="form-control text-left" type="text" [(ngModel)]="numeroCartao" maxlength="4" />
        </div>
        <div class="form-group col">
          <label>Id <span class="obrigatorio">*</span></label>
          <input class="form-control" type="text" [(ngModel)]="key" maxlength="3" />
        </div>
        <div class="form-group col" *ngIf="iconesSelecionado !=='1'">
          <label>Tipo fatura <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesTipoFatura" placeholder="Selecione"
            [(ngModel)]="tipoFaturaSelecionada" [disabled]="true"></p-dropdown>
        </div>
        <div class="form-group col" *ngIf="iconesSelecionado ==='1'">
          <label>Tipo fatura <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesTipoFatura" placeholder="Selecione"
            [(ngModel)]="tipoFaturaSelecionada" [disabled]="true"></p-dropdown>
        </div>
        <div class="form-group col">
          <label>Data Vencimento <span class="obrigatorio">*</span></label>
          <p-calendar appendTo="body" class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true"
            yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtVencimento" dateFormat="dd/mm/yy">
          </p-calendar>
        </div>
        <div class="form-group col" *ngIf="iconesSelecionado ==='1'">
          <label>Data Fechamento <span class="obrigatorio">*</span></label>
          <p-calendar appendTo="body" class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true"
            yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="dtFechamento" dateFormat="dd/mm/yy">
          </p-calendar>
        </div>
      </div>


      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Conta contábil <span class="obrigatorio">*</span></label><br>
          <button *ngIf="planoConta?.id" matTooltip="Alterar conta" class="btn btn-info" type="button"
            (click)="modalContasUnicas=true">
            {{ planoConta.codCta }} - {{ planoConta.descrCta }}
            <i class="fas fa-pen"></i>
          </button>
          <button *ngIf="!planoConta?.id" matTooltip="Alterar conta" class="btn btn-info" type="button"
            (click)="modalContasUnicas=true">
            Selecione...
            <i class="fas fa-pen"></i>
          </button>
          <!-- <label>Conta Contábil <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContas"
            placeholder="Selecione" [(ngModel)]="contaSelecionada"></p-dropdown> -->
        </div>
      </div>

      <!-- Inicio do combo box aprovador -->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Aprovador</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesAprovador"
            placeholder="Selecione" [(ngModel)]="aprovadorFatura"></p-dropdown>
        </div>
      </div>
      <!-- Final do combo box aprovador -->
      <!-- <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label> -->
      <div class="p-field-checkbox">
        <p-checkbox [(ngModel)]="financeiroPorItem" inputId="fpi"></p-checkbox>
        <label for="fpi" class="ml-2">Financeiro por item</label>
      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button class="btn btn-secondary" (click)="modalFatura=false"><i class="fas fa-undo"></i> Cancelar</button>
      <button class="btn btn-primary" (click)="salvarFatura();"><i class="fas fa-check"></i> Salvar</button>
    </p-footer>
  </p-dialog>
  <!--Final Modal cadastro de Faturas-->

  <!--Modal Incluir Item Faturas FEITO-->
  <p-dialog [(visible)]="modalFaturaItem" [style]="{width: '800px'}" [modal]="true"
    styleClass="fatura-item-altura-inicial" [responsive]="true" appendTo="body" [closable]="false">
    <p-header>
      {{labelTipoFatura}} - Número: {{numero}}
      <button type="button" class="btn btn-sm btn-info pull-right" (click)="displayDadosFaturaItemDialog();"><i
          class="fas fa-plus"></i> Adicionar</button>
    </p-header>


    <div class="row" *ngIf="modalFaturaItem">
      <div class="col-sm-12">
        <div class="clearfix"></div>
        <div class="pull-right align-down-table">
          <button type="button" class="btn btn-link" (click)="dt1.exportCSV()"><i class="fas fa-file-excel excel"></i>
            Exportar para Excel</button>
        </div>
        <div class="clearfix"></div>
        <p-table class="table-system" [paginator]="true" #dt1 [(value)]="this.faturaAtual.faturaItem" [rows]="rows"
          (onFilter)="onFilterFaturaItem($event)" selectionMode="single" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCompra'">
                  <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'dataCompra'"></p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-left" style="width: 40%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                  <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'"></p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtdeParcelas'">
                  <span class="text-overflow-dynamic-ellipsis">QTD. <p-sortIcon [field]="'qtdeParcelas'"></p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda.codigo'">
                  <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'moeda.codigo'"></p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-right" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorParcela'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valorParcela'"></p-sortIcon>
                  </span>
                </span>
              </th>
              <th style="width: 15%;" class="text-center">AÇÃO</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span (click)="itemOnRowSelect(rowData,0)"> {{ rowData.dataCompra | date:'dd/MM/yyyy' }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 40%;">
                <span class="text-overflow-dynamic-container">
                  <span (click)="itemOnRowSelect(rowData,0)" matTooltip="{{rowData.historico}}"> {{ rowData.historico
                    }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span (click)="itemOnRowSelect(rowData,0)"> {{ rowData.qtdeParcelas }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span (click)="itemOnRowSelect(rowData,0)"> {{ rowData.moeda.codigo }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 15%;">
                <span class="text-overflow-dynamic-container">
                  <span (click)="itemOnRowSelect(rowData,0)"> {{ rowData.valorParcela | money }}</span>
                </span>
              </td>
              <td class="text-center" style="width: 15%;">
                <span class="edit" matTooltip="Alterar" (click)="itemOnRowSelect(rowData,0)">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="delete" matTooltip="Excluir" (click)="exluirItemListaGrid(rowData)">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="attachment" matTooltip="Anexos" (click)="itemOnRowSelect(rowData, 1)"
                  *ngIf="rowData.contemArquivo > 0">
                  <i class="fas fa-paperclip"></i>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-sm-12">
        <table style="width: 100%">
          <tbody>
            <tr>
              <td style="width: 10%; padding: .50em .5em;"></td>
              <td style="width: 40%; padding: .50em .5em;"></td>
              <td style="width: 10%; padding: .50em .5em;"></td>
              <td style="width: 10%; padding: .50em .5em;"></td>
              <td
                style="width: 15%; text-align: right; font-size:12px; font-weight: 900; padding: .50em .5em; color: #555555;">
                <span>TOTAL: {{ valorTotalItem | money}}</span>
              </td>
              <td style="width: 15%; padding: .50em .5em;"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>

      <!--           <button mat-raised-button class="button-green" *ngIf="!isDepesaDireta && (status !== 3)" (click)='addTaxa(9)' style="width: 153px;float: left;margin-right: 10px">Taxa/Contabilizar</button>
          <button mat-raised-button class="button-green" *ngIf="isDepesaDireta && (status !== 4 && status !== 2 && status !== 3)" (click)='addTaxa(1)' style="width: 103px;float: left;margin-right: 10px">Contabilizar</button>
          <button mat-raised-button class="button-green" *ngIf="isDepesaDireta && (status === 4 || status === 2) && !itemComplemento" (click)='salvarContabilizar(2);' style="width: 103px;float: left;margin-right: 10px">Descontabilizar</button>
          <button mat-raised-button class="button-green" *ngIf="status > 1 && iconesSelecionado ==='1'" (click)="displayComplemento();" style="width: 103px;float: left;margin-right: 10px">Complemento</button>
 -->
      <button type="button" class="btn btn-secondary" (click)='modalFaturaItem=false'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary"
        *ngIf="!isDepesaDireta && (status !== 3 && status !== 5 && status !== 7)" (click)='addTaxa(9)'><i
          class="fas fa-calculator"></i> Taxa/Contabilizar</button>
      <button type="button" class="btn btn-primary"
        *ngIf="isDepesaDireta && (status !== 4 && status !== 2 && status !== 3 && status !== 5 && status !== 7)"
        (click)='addTaxa(1)'><i class="fas fa-calculator"></i> Contabilizar</button>
      <button type="button" class="btn btn-primary"
        *ngIf="isDepesaDireta && (status === 4 || status === 2) && !itemComplemento" (click)='salvarContabilizar(2);'><i
          class="fas fa-calculator"></i> Descontabilizar</button>
      <button type="button" class="btn btn-primary" *ngIf="status > 1 && iconesSelecionado ==='1'"
        (click)="displayComplemento();"><i class="fas fa-link"></i> Complemento</button>
      <button type="button" class="btn btn-primary"
        *ngIf="((status !== 2 && status !== 3) || (status === 2 && this.faturaAtual.faturaItemComplemento.length > 0))"
        (click)="salvarFaturaItem();"><i class="fas fa-check"></i> Salvar</button>
      <button type="button" class="btn btn-danger" *ngIf="status === 2" (click)="salvarContabilizar(5);"><i
          class="fas fa-times"></i> Encerrar</button>
      <button type="button" class="btn btn-primary" *ngIf="status === 3" (click)="salvarContabilizar(6);"><i
          class="fas fa-book-open"></i> Reabrir</button>

      <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
        <div class="ui-g-12">

        </div>
      </div>
    </p-footer>

  </p-dialog>

  <!--Modal Dados Fatura Item FEITO-->
  <p-dialog [(visible)]="modalDadosFaturaItem" [style]="{width: '800px'}" [modal]="true" top="0"
    styleClass="dados-fatura-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <p-header>
      Item {{labelTipoFatura}} - Número: {{numero}}
    </p-header>
    <div *ngIf="modalDadosFaturaItem">
      <mat-tab-group [selectedIndex]="indexTab" style="min-height:450px">
        <mat-tab label="Dados">
          <div class="form-row">
            <div class="form-group col-sm-12 mt-3">
              <label>Participante</label><br>
              <button *ngIf="participante?.id" matTooltip="Selecione o fornecedor" class="btn btn-info" type="button" (click)="modalParticipante=true">
                {{ participante.codigo }} - {{ participante.fantasia }} - {{ participante.cnpj }}
                <i class="fas fa-pen"></i>
              </button>
              <button *ngIf="!participante?.id" matTooltip="Selecione o fornecedor" class="btn btn-info" type="button" (click)="modalParticipante=true">
                Selecione...
                <i class="fas fa-pen"></i>
              </button>
              <!-- <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesParticipante"
                [(ngModel)]="participanteSelecionada"></p-dropdown> -->
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Conta Contábil <span class="obrigatorio">*</span></label><br>
              <button *ngIf="planoConta?.id" matTooltip="Alterar conta" class="btn btn-info" type="button"
                (click)="modalContasUnicas=true">
                {{ planoConta.codCta }} - {{ planoConta.descrCta }}
                <i class="fas fa-pen"></i>
              </button>
              <button *ngIf="!planoConta?.id" matTooltip="Alterar conta" class="btn btn-info" type="button"
                (click)="modalContasUnicas=true">
                Selecione...
                <i class="fas fa-pen"></i>
              </button>
              <!-- <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                [options]="opcoesPlanoContasItem" [(ngModel)]="contaSelecionadaItem"
                (onChange)="isContaResultado(contaSelecionadaItem)"></p-dropdown> -->
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Histórico <span class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="faturaItem.historico" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <label>Moeda <span class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                [options]="opcoesMoeda" [(ngModel)]="moedaSelecionadaItem"></p-dropdown>
            </div>
            <div class="form-group col">
              <label>Qtd. Parcelas <span class="obrigatorio">*</span></label>
              <input class="form-control" type="number" min="1" [(ngModel)]="faturaItem.qtdeParcelas" />
            </div>
            <div class="form-group col" *ngIf="isDepesaDireta">
              <label>Taxa</label>
              <input class="form-control text-right" type="text" [disabled]="moedaSelecionadaItem === 1"
                [(ngModel)]="faturaItem.taxa" />
            </div>
            <div class="form-group col">
              <label *ngIf="!isDepesaDireta">Valor parcela <span class="obrigatorio">*</span></label>
              <label *ngIf="isDepesaDireta">Valor <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="faturaItem.valorParcela" money />
            </div>
            <div class="form-group col">
              <label>Data <span class="obrigatorio">*</span></label>
              <p-calendar class="type-p-calendar" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="faturaItem.dataCompra"
                dateFormat="dd/mm/yy"></p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label class="d-block">Movimento <span class="obrigatorio">*</span></label>
              <mat-radio-group [(ngModel)]="creditoEstornoValue">
                <mat-radio-button class="mr-4" *ngFor="let rec of creditoEstorno" [value]="rec">
                  {{rec}}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button type="button" class="btn btn-sm btn-info pull-right" (click)="addItemRateio();"><i
                  class="fas fa-plus"></i> Adicionar Rateio</button>
            </div>
          </div>

          <div class="clearfix"></div>

          <div class="row">
            <div class="col-sm-12 mt-3">
              <p-table class="table-system" [(value)]="faturaItem.faturaRateio">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 35%;">Centro de Custo</th>
                    <th style="width: 35%;">Projeto</th>
                    <th style="width: 20%;" class="text-right">Porcentagem</th>
                    <th style="width: 10%;" class="text-center">Ação</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 35%;">
                      <span matTooltip='{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}'
                        class="overflow-ellipse">{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}</span>
                    </td>
                    <td style="width: 35%;">
                      <span matTooltip='{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}'
                        class="overflow-ellipse">{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}</span>
                    </td>
                    <td style="width: 20%;" class="text-right">
                      {{ rowData.porcentagem | money }}%
                    </td>
                    <td style="width: 10%;" class="text-center">
                      <span class="edit" matTooltip="Editar centro de custo" (click)="editarCentroCusto()">
                        <i class="fas fa-edit"></i>
                      </span>
                      <span class="delete" matTooltip="Excluir centro de custo"
                        (click)="removerRateioCentroCusto(rowData)">
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem rateio!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>

        </mat-tab>

        <mat-tab label="Anexos">
          <div class="form-row">
            <!--Input FILE Antigo -->
            <!-- <div class="form-group col-sm-6 mt-4">
              <input type="file" #file (change)="fileChange()" accept="application/pdf" />
            </div> -->
            <div class="form-group col-sm-12 mt-3 mb-1">
              <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                <span class="input-group-append">
                  <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                        Remover Arquivo
                      </span> -->
                  <span class="input-group-text btn-file">
                    <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                      Arquivo</span>
                    <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                      Arquivo</span> <!-- Antes era Trocar Arquivo-->
                    <input type="file" #file (change)="fileChange()" accept="application/pdf" />
                  </span>
                </span>
                <div class="form-control" data-trigger="fileinput">
                  <span class="fileinput-filename"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" #dt1 [value]="files" [rows]="rows" [paginator]="true"
                (onFilter)="onFilterFatura($event)" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
                [style]="{'width':'100%'}">

                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 90%;">ARQUIVO</th>
                    <th style="width: 10%;">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td style="width: 90%;" class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span matTooltip="{{ rowData.name }}" (click)="baixarAnexo(rowData)"> {{ rowData.name }} </span>
                      </span>
                    </td>
                    <td style="width: 10%;" class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="delete" matTooltip="Excluir Arquivo" (click)="excluirAnexo(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>

    <div class="clearfix"></div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalDadosFaturaItem=false'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-danger" *ngIf="alteraItem" (click)="exluirItemLista()"><i
          class="fas fa-times"></i> Excluir</button>
      <button type="button" class="btn btn-primary" *ngIf="alteraItem" (click)="alterarItemLista();"><i
          class="fas fa-pencil-alt"></i> Alterar</button>
      <button type="button" class="btn btn-primary" *ngIf="!alteraItem" (click)="addItemLista();"><i
          class="fas fa-check"></i> Adicionar</button>
    </p-footer>
  </p-dialog>

  <!--Modal Definir Rateio FEITO-->
  <p-dialog header="Definir Rateio" [(visible)]="modalCentroCusto" [style]="{width: '800px'}" [modal]="true"
    styleClass="rateio-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalCentroCusto">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Centro de Custo <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"
            [(ngModel)]="cc" (onChange)="controlCentroCusto(cc.id)" placeholder="Selecione um centro de custo"
            optionLabel="label"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Projeto</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesProjetos"
            [(ngModel)]="proj" placeholder="Selecione um projeto" optionLabel="label"></p-dropdown>
        </div>
        <div class="form-group col-sm-2">
          <label>Porcentagem <span class="obrigatorio">*</span></label>
          <input class="form-control text-right" value="{{porcentagemCc}}" [(ngModel)]="porcentagemCc" money>
        </div>
        <div class="form-group col-sm-2">
          <div class="locked-element">
            <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
            <button class="btn btn-sm btn-info d-block" style="margin-top: 8px" (click)="incluirRateioCentroCusto()"><i
                class="fas fa-plus"></i> Adicionar</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mt-2">
          <p-table class="table-system" [(value)]="faturaItem.faturaRateio">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 35%;">CENTRO CUSTO</th>
                <th style="width: 35%;">PROJETO</th>
                <th class="text-right" style="width: 20%;">PORCENTAGEM</th>
                <th class="text-center" style="width: 10%;">AÇÃO</th>
              </tr>
            </ng-template>6
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td style="width: 35%;">
                  <span class="overflow-ellipse"
                    matTooltip="{{ rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}">{{
                    rowData.cadCcus.codCcus }} - {{ rowData.cadCcus.descrCcus }}</span>
                </td>
                <td style="width: 35%;" *ngIf="rowData.cadProj">
                  <span class="overflow-ellipse"
                    matTooltip="{{ rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}">{{
                    rowData.cadProj.codProj }} - {{ rowData.cadProj.descrProj }}</span>
                </td>
                <td *ngIf="!rowData.cadProj">
                </td>
                <td class="text-right">
                  {{ rowData.porcentagem | money }}%
                </td>
                <td class="text-center">
                  <span class="delete" matTooltip="Excluir centro de custo" (click)="removerRateioCentroCusto(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr style="height:25px">
                <td [attr.colspan]="4" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Sem rateio!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <label class="mr-3" *ngIf="rateioCentroCusto !== 0 && rateioCentroCusto !== 100"><strong>Rateio
          Disponível:</strong> {{rateioCentroCusto}}%</label>
      <button type="button" class="btn btn-secondary" *ngIf="rateioCentroCusto === 0 || rateioCentroCusto === 100"
        (click)="fecharModalRateio()"><i class="fas fa-undo"></i> FECHAR</button>
    </p-footer>
  </p-dialog>

  <!-- Modal Alterar Período FEITO-->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
    styleClass="lks-modal" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="true">

    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="atualizarVoltar();"><i class="fas fa-pencil-alt"></i>
            Alterar</button>
        </div>
      </div>
    </p-footer>

  </p-dialog>

  <!-- Modal Taxas FEITO-->
  <p-dialog header="Taxas" [(visible)]="modalTaxas" [style]="{width: '800px'}" [modal]="true"
    styleClass="taxas-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">

    <div *ngIf="modalTaxas">
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>Data Contabilização</label>
          <p-calendar class="type-p-calendar" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
            yearRange="2010:2030" [(ngModel)]="dataContabilizacao" dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
      <div *ngFor="let moedas of moedasitens">
        <div class="form-row">
          <div class="form-group col-sm-1">
            <label>Moeda</label>
            <span style="font-size: 12px;height: 30px;vertical-align: middle;display: table-cell;">{{ moedas.codigo
              }}</span>
          </div>
          <div class="form-group col-sm-1">
            <label>Taxa</label>
            <input class="form-control text-right" type="text" id="valueTaxa_{{moedas.id}}" [(ngModel)]="moedas.taxa" />
          </div>
        </div>
        <hr class="mt-0 mb-2">
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalTaxas=false;"><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" *ngIf="status === 1 || status === 6"
        (click)="salvarContabilizar(1);"><i class="fas fa-calculator"></i> Contabilizar</button>
      <button type="button" class="btn btn-danger" *ngIf="((status === 4 || status === 2) && !itemComplemento)"
        (click)="salvarContabilizar(2);"><i class="fas fa-calculator"></i> Descontabilizar</button>
      <button type="button" class="btn btn-primary" *ngIf="status === 4 && itemComplemento"
        (click)="salvarContabilizar(3);"><i class="fas fa-calculator"></i> Contabilizar</button>
      <button type="button" class="btn btn-danger" *ngIf="status === 2 && itemComplemento"
        (click)="salvarContabilizar(4);"><i class="fas fa-calculator"></i> Descontabilizar</button>
    </p-footer>

  </p-dialog>

  <!--Modal Incluir Item Faturas FEITO-->
  <p-dialog [(visible)]="modalFaturaItemComplemento" [style]="{width: '800px'}" [modal]="true"
    styleClass="complemento-altura-inicial" [responsive]="true" appendTo="body" [closable]="false">
    <p-header>
      {{labelTipoFatura}} - Número: {{numero}}
      <div class="pull-right">
        <button class="btn btn-sm btn-info" (click)="displayDadosFaturaItemDialog();"><i class="fas fa-plus"></i>
          Adicionar</button>
      </div>
    </p-header>
    <div class="row" *ngIf="modalFaturaItemComplemento">
      <div class="col-sm-12">
        <p-table class="table-system" [paginator]="true" #dt1 [(value)]="this.faturaAtual.faturaItemComplemento"
          [rows]="rows" [paginator]="false" (onFilter)="onFilterFaturaItem($event)" selectionMode="single"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}">

          <ng-template pTemplate="header">
            <tr>
              <th class="clearfix">
                <button type="button" class="btn btn-link" (click)="dt1.exportCSV()"><i
                    class="fas fa-file-excel excel"></i> Exportar para Excel</button>
              </th>
            </tr>
            <tr>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCompra'">
                  <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'dataCompra'" verflow>
                    </p-sortIcon>
                  </span>
                </span>
              <th class="text-center" style="width: 40%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                  <span class="text-overflow-dynamic-ellipsis">Histórico <p-sortIcon [field]="'historico'" verflow>
                    </p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtdeParcelas'">
                  <span class="text-overflow-dynamic-ellipsis">Qtd. <p-sortIcon [field]="'qtdeParcelas'" verflow>
                    </p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda.codigo'">
                  <span class="text-overflow-dynamic-ellipsis">Moeda <p-sortIcon [field]="'moeda.codigo'" verflow>
                    </p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorParcela'">
                  <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valorParcela'" verflow>
                    </p-sortIcon>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 15%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">Ação <p-sortIcon [field]="'qtdeParcelas'" verflow>
                    </p-sortIcon>
                  </span>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width: 10%;" class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="itemOnRowSelect(rowData,0)">{{rowData.dataCompra
                    | timezone | date:'dd/MM/yyyy'}}</span>
                </span>
              </td>
              <td style="width: 40%;" class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    (click)="itemOnRowSelect(rowData,0)">{{rowData.historico}}</span>
                </span>
              </td>
              <td style="width: 10%;" class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    (click)="itemOnRowSelect(rowData,0)">{{rowData.qtdeParcelas}}</span>
                </span>
              </td>
              <td style="width: 10%;" class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    (click)="itemOnRowSelect(rowData,0)">{{rowData.moeda.codigo}}</span>
                </span>
              </td>
              <td style="width: 15%;" class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    (click)="itemOnRowSelect(rowData,0)">{{rowData.valorParcela | money}}</span>
                </span>
              </td>
              <td style="width: 15%;" class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="edit" matTooltip="Alterar" (click)="itemOnRowSelect(rowData,0)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="delete" matTooltip="Excluir" (click)="exluirItemListaGrid(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                  <span class="attachment" matTooltip="Anexos" (click)="itemOnRowSelect(rowData, 1)"
                    *ngIf="rowData.contemArquivo > 0">
                    <i class="fas fa-paperclip"></i>
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="col-sm-12">
        <table style="width: 100%">
          <tbody>
            <tr>
              <td style="width: 10%; padding: .50em .5em;"></td>
              <td style="width: 40%; padding: .50em .5em;"></td>
              <td style="width: 10%; padding: .50em .5em;"></td>
              <td style="width: 10%; padding: .50em .5em;"></td>
              <td
                style="width: 15%; text-align: right; font-size:12px; font-weight: 900; padding: .50em .5em; color: #555555;">
                <span>TOTAL: {{ valorTotalItemCompl | money}}</span>
              </td>
              <td style="width: 15%; padding: .50em .5em;"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p-footer>
      <!--         <button mat-raised-button class="button-default" *ngIf="!isDepesaDireta" (click)='addTaxa(9)' style="width: 153px;">Taxa/Contabilizar</button>
        <button mat-raised-button class="button-default" *ngIf="isDepesaDireta && (status !== 4 && status !== 2)" (click)='addTaxa(3)' style="width: 153px;">Contabilizar</button>
        <button mat-raised-button class="button-default" *ngIf="isDepesaDireta && (status === 2) && itemComplemento" (click)='salvarContabilizar(4)' style="width: 153px;">Descontabilizar</button>
 -->
      <button type="button" class="btn btn-secondary" (click)='cancelarModalComplemento();'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary"
        *ngIf="!isDepesaDireta && status !== 5 && status !== 6 && status !== 7" (click)='addTaxa(9)'><i
          class="fas fa-check"></i> Taxa/Contabilizar</button>
      <button type="button" class="btn btn-primary"
        *ngIf="isDepesaDireta && (status !== 4 && status !== 2 && status !== 5 && status !== 6 && status !== 7)"
        (click)='addTaxa(3)'><i class="fas fa-calculator"></i> Contabilizar</button>
      <button type="button" class="btn btn-danger" *ngIf="isDepesaDireta && (status === 2) && itemComplemento"
        (click)='salvarContabilizar(4)'><i class="fas fa-calculator"></i> Descontabilizar</button>


      <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
        <div class="ui-g-12">

        </div>
      </div>
    </p-footer>
  </p-dialog>

  <!-- Modal Aprovar-->
  <p-dialog header="Aprovar/Recusar em Lote" [(visible)]="modalAprovarLote" [style]="{width: '800px'}" [modal]="true"
    styleClass="lks-modal" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="true">

    <div *ngIf="modalAprovarLote">
      <div class="mt-3">
        <button type="button" class="btn btn-danger btn-sm mr-2" (click)="recusarFaturaLote();"><i
            class="fas fa-ban"></i> Recusar</button>
        <button type="button" class="btn btn-primary btn-sm" (click)="aprovarFaturaLote();"><i class="fas fa-check"></i>
          Aprovar</button>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
  </p-dialog>
  <!-- Fim Modal Aprovar-->
</mat-card>

<!-- Lazy Load Plano de contas -->
<app-modal-plano-contas [(isVisible)]="modalContasUnicas" (onSelectAccount)="selecionarContasUnica($event)">
</app-modal-plano-contas>

<!-- Lazy Load Participantes -->
<app-modal-participante [(isVisible)]="modalParticipante" (onSelectParticipant)="salvarParticipante($event)">
</app-modal-participante>