<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">
    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-8 col-xxl-6">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">Índices Financeiros</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateIndice>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title mt-0"><i class="fas fa-sliders-h"> </i> PARÂMETROS DE CONSULTA</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-1" style="min-width: 130px;">
                <label>DATA INICIAL</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  [defaultDate]="DefInitDate" yearRange="2000:2030"  [(ngModel)]="dtInicial">
                </p-calendar>
              </div>
              <div class="form-group col-sm-1" style="min-width: 130px;">
                <label>DATA FINAL</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  [defaultDate]="DefFinalDate" yearRange="2000:2030"  [(ngModel)]="dtFinal">
                </p-calendar>
              </div>
              <div class="form-group col-sm-2">
                <label>ÍNDICE</label>
                <p-dropdown class="type-p-dropdown" class="type-p-dropdown" [(ngModel)]="idIndices"
                  [options]="listIndices" [filter]="false" placeholder="Selecione um índice"></p-dropdown>
              </div>
              <div class="form-group col-sm-2">
                <label class="d-block" style="visibility: hidden; margin-top: 3px;">DO NOT REMOVE</label>
                <button type="button" class="btn btn-info" (click)='getIndices();'><i class="fas fa-refresh"></i>
                  Atualizar</button>
              </div>
            </div>

            <hr style="margin-top: 5px; border-color: #ccc;">
            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt2 [columns]="colsIndice" [value]="indicesLista" [rows]="15"
              [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indice'">
                      <span class="text-overflow-dynamic-ellipsis">INDICE <p-sortIcon [field]="'indice'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'indice', 'contains')">
                  </th>
                  <th class="text-left" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'mes'">
                      <span class="text-overflow-dynamic-ellipsis">MÊS <p-sortIcon [field]="'mes'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'mes', 'contains')">
                  </th>
                  <th class="text-center" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ano'">
                      <span class="text-overflow-dynamic-ellipsis">ANO <p-sortIcon [field]="'ano'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'ano', 'contains')">
                  </th>
                  <th class="text-right" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'taxa'">
                      <span class="text-overflow-dynamic-ellipsis">TAXA <p-sortIcon [field]="'taxa'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'taxa', 'contains')">
                  </th>
                  <th class="text-right" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'acum_ano'">
                      <span class="text-overflow-dynamic-ellipsis">ACUM. ANO <p-sortIcon [field]="'acum_ano'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'acum_ano', 'contains')">
                  </th>
                  <th class="text-right" style="width: 16%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'acum_12_meses'">
                      <span class="text-overflow-dynamic-ellipsis">ACUM. 12 MESES <p-sortIcon [field]="'acum_12_meses'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'acum_12_meses', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.indice}}">{{rowData.indice}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.mes}}">{{rowData.mes}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ano}}">{{rowData.ano}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.taxa}}%">{{rowData.taxa}}%</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.acum_ano}}%">{{rowData.acum_ano}}%</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 16%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.acum_12_meses}}%">{{rowData.acum_12_meses}}%</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>