<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="clearfix"></div>

  <mat-card-content *ngIf="showTabela">
    <div class="box-content pt-3 pb-3">

      <div [ngStyle]="exibirTabela ? {'display': 'inline'} : {'display': 'none'}"
        [ngClass]="{'fade-in': 'exibirTabela'}">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt10.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
      </div>


      <div class="form-row">
        <div class="form-group col-sm-12">
          <p-dropdown class="type-p-dropdown" placeholder="Selecione uma Tabela" [filter]="true"
            [options]="opcoesTabelaCrm" [(ngModel)]="tabelaCrmId" (onChange)="buscarColunasTabelaCrmCarga()">
          </p-dropdown>
        </div>
      </div>

      <div [ngStyle]="exibirTabela ? {'display': 'block'} : {'display': 'none'}"
        [ngClass]="{'fade-in': 'exibirTabela'}">
        <p-table class="table-system" [columns]="tColunas" #dt10 selectionMode="single" [value]="listTabelaCrmCarga"
          [paginator]="true" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          selectionMode="false" [style]="{'width':'100%'}" [scrollable]="true">

          <ng-template pTemplate="header" let-rowData let-columns>
            <tr>

              <th class="text-left" style="width: 250px;" *ngFor="let col of tColunas">
                <span pSortableColumn={{col.field}}>
                  <span> {{col.field}} <p-sortIcon field={{col.field}}></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt10.filter($event.target.value, col.field, 'contains')">
              </th>

              <th class="text-center" style="width: 150px;">
                <span pSortableColumn='ultimaAtualizacao'>
                  <span> ULTIMA ATUALIZAÇÃO <p-sortIcon field='ultimaAtualizacao'></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt10.filter($event.target.value, 'ultimaAtualizacao', 'contains')">
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">

              <td class="text-left" style="width: 250px;" *ngFor="let col of tColunas; let i = index;">
                <span class="overflow-ellipse" matTooltip="{{rowData[col.field]}}">
                  <span style="user-select: all; cursor: text">{{rowData[col.field] }}</span>
                </span>
              </td>

              <td class="text-center" style="width: 150px;">
                <span class="overflow-ellipse" matTooltip="{{rowData.ultimaAtualizacao}}">
                  <span style="user-select: all; cursor: text">{{rowData.ultimaAtualizacao }}</span>
                </span>
              </td>

            </tr>
          </ng-template>


          <ng-template pTemplate="emptymessage" let-columns>

            <tr>
              <td [attr.colspan]="tColunas?.length" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>


    </div>

    <div class="ui-g">
      <div class="ui-g-12">

      </div>
    </div>

  </mat-card-content>



</mat-card>

<!-- Inicio Modal Atualizar Tabela -->
<p-dialog header="Atualizar Tabela" [(visible)]="modalAtualizarTabelaPorPeriodo" [style]="{width: '400px'}" [modal]="true"
  class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div *ngIf="modalAtualizarTabelaPorPeriodo">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Data Inicial para Atualização</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
      </div>
      <div class="form-group col-sm-6">
        <label>Data Final para Atualização</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalAtualizarTabelaPorPeriodo=false;'><i
        class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="atuazliarPeriodo();"><i class="fas fa-check"></i>
      Alterar</button>
  </p-footer>
</p-dialog>
<!-- Final Modal Atualizar Tabela -->