<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content">
        <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-file-alt"></i> Cadastrar</h3>
            </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Insira a descrição</label>
            <input class="form-control" [(ngModel)]="gatilhoObrigacao.descricao" />
          </div>
          <div class="form-group col">
              <label class="d-block" style="visibility: hidden; margin-top: 3px;">Insira a descrição</label>
              <button class="btn btn-sm btn-primary" type="button" (click)="salvarGatilho()" [disabled]="!gatilhoObrigacao.descricao"><i class="fas fa-check"></i> Salvar</button>
          </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-pencil-alt"></i> Editar</h3>
            </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
              <label>Gatilho</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="gatilhos" [(ngModel)]="gatilhoEditar" (ngModelChange)="carregarGatilho()"></p-dropdown>
          </div>
          <div class="form-group pull-left ml-1 mr-1" *ngIf="(this.gatilhoDisparoList.length < 3 || gatilhoDisparo.dias === null) && modalEditarGatilho === true">
            <label>Incluir Disparo</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="dias" [(ngModel)]="gatilhoDisparo.dias"></p-dropdown>
          </div>
          <div class="form-group col" *ngIf="(this.gatilhoDisparoList.length < 3 || gatilhoDisparo.dias === null) && modalEditarGatilho === true">
            <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
            <button class="btn btn-sm btn-primary" type="button" (click)="adicionarGatilho()" [disabled]="this.gatilhoDisparoList.length === 3 || gatilhoDisparo.dias === null"><i class="fas fa-plus"></i> Incluir</button>
          </div>
        </div>

        <div class="row" *ngIf="this.gatilhoDisparoList.length > 0">
          <div class="col-sm-3 pr-0">
            <table class="table table-border-boot">
              <thead>
                <tr>
                  <th style="width: 90%">Disparos Configurados</th>
                  <th style="width: 10%;" class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let gatilho of this.gatilhoDisparoList">
                  <td *ngIf="!gatilho.dias">0</td>
                  <td *ngIf="gatilho.dias">{{gatilho.dias}}</td>
                  <td class="text-center">
                    <span class="delete" matTooltip='Excluir Disparo' (click)="removerDisparo(gatilho)">
                        <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </mat-card-content>
</mat-card>
