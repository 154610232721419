<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <!--Table Seleção Item-->
  <div class="row" *ngIf="!editar">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">Selecione um grupo</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateSelectItem>
          <p-table class="table-system" [value]="lista" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 30%">CÓDIGO</th>
                <th style="width: 70%" class="text-left">DESCRIÇÃO</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr (click)="verGrupo(rowData)" [pSelectableRow]="rowData">
                <td>
                  {{ rowData.codigo }}
                </td>
                <td class="text-left">
                  {{ rowData.descricao }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="editar">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">Item Selecionado</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarDetails"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateItemSelecionado>
          <div class="form-row">
            <div class="col-sm-2 mb-2">
              <label for="inputCodigo">CÓDIGO<span class="obrigatorio">*</span></label>
              <input class="form-control" [(ngModel)]="grupo.codigo" value="{{grupo.codigo}}" type="text"
                name="inputCodigo">
            </div>
            <div class="col-sm-2 mb-2">
              <label for="inputDescricao">DESCRIÇÃO</label>
              <input class="form-control" [(ngModel)]="grupo.descricao" value="{{grupo.descricao}}" type="text"
                name="inputDescricao">
            </div>
            <div class="col-sm-1 mb-2">
              <label for="inputPorcentagem">PORCENTAGEM</label>
              <p-inputNumber [min]="0" [max]="100" inputId="inputPorcentagemDesconto" [(ngModel)]="grupo.porcentagem" suffix="%"></p-inputNumber>
            </div>
            <div class="col-sm-2 mb-2">
              <label for="inputPorcentagemDesconto">PORCENTAGEM DESCONTO</label>
              <p-inputNumber [min]="0" [max]="100" inputId="inputPorcentagemDesconto" [(ngModel)]="grupo.porcentagemDesconto" suffix="%"></p-inputNumber>
            </div>
            <div class="col-sm-1 mb-2">
              <label class="mb-0">DATA INI<span class="obrigatorio mb-0">*</span></label>
              <p-calendar appendTo="body" [(ngModel)]="grupo.dtInicial" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="2000:2030"></p-calendar>
            </div>
            <div class="col-sm-1 mb-2">
              <label class="mb-0">DATA FIM<span class="obrigatorio mb-0">*</span></label>
              <p-calendar appendTo="body" [(ngModel)]="grupo.dtFinal" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="2000:2030"></p-calendar>
            </div>
            <div class="d-flex align-items-center justify-content-start col-sm-2">
              <mat-slide-toggle class="mr-2" [(ngModel)]="grupo.ativo"> ATIVO </mat-slide-toggle>
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-3 mb-2">
              <label>Gerente</label>
                <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [(ngModel)]="gerente" [options]="gerentOptions" placeholder="Selecione o Gerente">
              </p-dropdown>
            </div>

            <div class="col-sm-2 mb-2">
              <label for="inputPorcentagemGerente">PORCENTAGEM GERENTE</label>
              <p-inputNumber [min]="0" [max]="100" inputId="inputPorcentagemGerente" [(ngModel)]="grupo.porcentagemGerente" suffix="%"></p-inputNumber>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="editar">
    <div class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card" *ngIf="visaoTipoItem === false">Itens Disponíveis</h3>
            <h3 class="title-card" *ngIf="visaoTipoItem === true">Tipos de Itens Disponíveis</h3>
            <div class="actions-card d-flex align-items-center">
              <mat-slide-toggle class="mr-3" [(ngModel)]="visaoTipoItem"> TIPO ITEM </mat-slide-toggle>
              <app-toolbar [value]="toolbarItens"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateItens>
          <p *ngIf="visaoTipoItem === false" style="font-size: 13px; text-transform: initial;"><i
              class="fas fa-arrow-right" style="color: #0091ea;"></i> Relacione os itens disponíveis.</p>
          <p *ngIf="visaoTipoItem === true" style="font-size: 13px; text-transform: initial;"><i
              class="fas fa-arrow-right" style="color: #0091ea;"></i> Relacione os tipos de item disponíveis.</p>
          <div class="row">
            <div class="col-sm-12">
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Itens Disponíveis" targetHeader="Itens Relacionados"
                  [source]="itensRelacionar" [target]="itensRelacionados" [dragdrop]="true" [responsive]="true"
                  filterBy="desc" *ngIf="visaoTipoItem === false && picklistState" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-itm pTemplate="item">{{itm.desc}}</ng-template>
                </p-pickList>
                <p-pickList sourceHeader="Tipos Item Disponíveis" targetHeader="Tipos Item Relacionados"
                  [source]="tipoItemRelacionar" [target]="tipoItemRelacionadas" [dragdrop]="true" [responsive]="true"
                  filterBy="desc" *ngIf="visaoTipoItem === true && picklistState" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-itm pTemplate="item">{{itm.desc}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">Usuários Disponíveis</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarAliquota"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateAliquota>
          <div class="row">
            <div class="col-sm-12">
              <p style="font-size: 13px; text-transform: initial;"><i class="fas fa-arrow-right"
                  style="color: #0091ea;"></i> Relacione as alíquotas disponíveis.</p>
              <div class="row">
                <div class="col-sm-12">
                  <div class="conteudo m-0">
                    <p-pickList *ngIf="picklistState" sourceHeader="Usuários Disponíveis" targetHeader="Usuários Relacionados"
                      [source]="usuariosRelacionar" [target]="usuariosRelacionadas" [dragdrop]="true" [responsive]="true"
                      filterBy="desc" [showSourceControls]="false" [showTargetControls]="false">
                      <ng-template let-aliq pTemplate="item">{{aliq.desc}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div class="ui-g">
  <div class="ui-g-12">

  </div>
</div>