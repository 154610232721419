<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content col-sm-6">
      <div class="row">
        <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-file-upload"> </i> IMPORTAÇÃO</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>1) Selecione a empresa: <i *ngIf="empresa?.id" class="fas fa-check" style="color: green;"></i></label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label class="d-block">2) Indique o tipo de processo: <i *ngIf="fileType" class="fas fa-check" style="color: green;"></i></label>
          <mat-radio-group name="fileType" [(ngModel)]="fileType" (ngModelChange)="fileTypeChange()">
            <mat-radio-button class="d-block mr-3" *ngFor="let f of fileTypes" [disabled]="f.disabled || fileName" [value]="f">
              {{ f.caption }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="form-row" *ngIf="fileType && fileType?.tag !== 'siscoserv-retorno'">
        <div class="form-group col-sm-12">
          <label class="d-block">3) Selecione o tipo de arquivo: <i *ngIf="replace !== null" class="fas fa-check" style="color: green;"></i></label>
          <mat-radio-group name="replace" [(ngModel)]="replace">
            <mat-radio-button class="d-block" [disabled]="fileName" [value]="false">Novo</mat-radio-button>
            <mat-radio-button class="d-block" [disabled]="fileName || fileType?.tag === 'siscoserv-retorno'" [value]="true">Retificação e/ou aditivo</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-row" *ngIf="fileType && (replace !== null || fileType?.tag === 'siscoserv-retorno')">
        <div class="form-group col-sm-12 mb-1 mt-1">
          <label class="d-block">{{ fileType?.tag === 'siscoserv-retorno' ? 3 : 4 }}) Selecione o arquivo: <i *ngIf="files?.length === 1" class="fas fa-check" style="color: green;"></i></label>
          <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
              <span class="input-group-append">
                  <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                    Remover Arquivo
                  </span> -->
                  <span class="input-group-text btn-file">
                    <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar Arquivo</span>
                    <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar Arquivo</span> <!-- Antes era Trocar Arquivo-->
                    <input type="file" #file [disabled]="!empresa || !fileType || replace === null || fileName" (change)="fileChange()" />
                  </span>
              </span>
              <div class="form-control" data-trigger="fileinput">
                <span class="fileinput-filename"></span>
              </div>
          </div>
        </div>
      </div>

      <div *ngIf="message">
        <div class="alert alert-xs alert-danger d-block" role="alert" *ngIf="fileName && error">
          <i class="fas fa-info-circle"></i> Ocorreram erros ao processar o arquivo <strong>"{{ fileName }}":</strong>
          <br>
          {{ message }}
        </div>
      </div>

      <!-- alert decision user -->
      <div>
        <div class="alert alert-xs alert-warning d-block p-2" *ngIf="duplicated" role="alert" style="font-size: 12px !important;">
          <i class="fas fa-exclamation-triangle"></i> Dados a serem incluídos já existem no Banco.<br><strong class="d-block mb-1">Deseja sobrescrevê-los?</strong>
          <button type="button" class="btn btn-sm btn-danger" (click)="ignoreOverwriteFiles()"><i class="fas fa-times"></i> Não</button>
          <button type="button" class="btn btn-sm btn-primary" (click)="overwriteFiles()"><i class="fas fa-check"></i> Sim</button>
        </div>
      </div>
      <div class="row" *ngIf="errors">
        <div class="col-sm-12">
          <ul class="list-group list-group-flush list-log">
            <li class="list-group-item" *ngFor="let errorMessage of errors">
                <i class="fas fa-exclamation"></i> {{ errorMessage }}
            </li>
          </ul>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col">
            <button type="button" class="btn btn-primary" (click)="upload()" *ngIf="!fileName" [disabled]="!fileType || !files || files.length === 0 || (replace === null && fileType?.tag !== 'siscoserv-retorno')"><i class="fas fa-upload"></i> Enviar</button>
            <button type="button" class="btn btn-primary" (click)="newFile()" *ngIf="fileName"><i class="fas fa-upload"></i> Novo arquivo</button>
        </div>
      </div>

    </div>
  </mat-card-content>
</mat-card>

<p-dialog header="Log de Advertência" [(visible)]="modalLog" [style]="{width: '800px'}" [modal]="true" class="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
    <div class="row">
      <div class="col-sm-12">
        <ul class="list-group list-group-flush list-log">
          <li class="list-group-item" *ngFor="let log of logAdvertencia">
              <i class="fas fa-exclamation"></i> {{log.detail }}
          </li>
        </ul>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalLog=false;'><i class="fas fa-undo"></i> Fechar</button>
    </p-footer>
</p-dialog>
