<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="PERÍODO ({{dblinkedSessionService.periodo.year.value}})">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
              <p-table class="table-system" [value]="periodoFis" [rows]="rows" [paginator]="true"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 16%" class="text-left">MÊS</th>
                    <th style="width: 17%" class="text-center">STATUS</th>
                    <th style="width: 17%" class="text-center">DATA ENCERRAMENTO</th>
                    <th style="width: 17%" class="text-center">DATA FECHAMENTO</th>
                    <th style="width: 17%" class="text-left">USUARIO</th>
                    <th style="width: 16%" class="text-center">AÇÃO</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-left">{{ rowData.mes }}</td>
                    <td class="text-center">{{ rowData.status }}</td>
                    <td class="text-center">{{ rowData.dt_encerramento | date:'dd/MM/yyyy' : '+000' }}</td>
                    <td class="text-center" [pEditableColumn]="rowData">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="col-sm-12 p-0">
                            <p-calendar class="type-p-calendar" (onClose)="atualizarDataFechamento(rowData)"
                              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                              [(ngModel)]="rowData.dt_fechamento" dateFormat="dd/mm/yy">
                            </p-calendar>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ rowData.dt_fechamento | date:'dd/MM/yyyy' : '+000' }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td class="text-left">{{ rowData.usuario }}</td>
                    <td class="text-center">
                      <span class="edit" *ngIf="rowData.status_id === 1" matTooltip="Encerrar Período"
                        (click)="mudarStatusPeriodo(rowData)"><i class="fas fa-check"></i></span>

                      <span class="delete" *ngIf="rowData.status_id === 2" matTooltip="Abrir Período"
                        (click)="mudarStatusPeriodo(rowData)"><i class="far fa-folder-open"></i></span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height:25px">
                    <td [attr.colspan]="6" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Sem período!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>