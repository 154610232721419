<div class="containerCustom">
  <div *ngFor="let btn of value.buttons">
    <span *ngIf="!btn.dropdown">
      <button *ngIf="btn.visible" [disabled]="btn.disabled" type="button" class="btnCustom-{{btn.color}}"
        matTooltip="{{btn.tooltip}}" matTooltipPosition="above" (click)="btn.onClick()">
        <span class="material-icons">{{btn.icon}}</span>
        <div class="d-flex flex-column align-items-start">
          <div class="module" *ngIf="btn.title.length > 0">{{btn.title}}</div>
          <div *ngIf="btn.text.length > 0" [style]="{'font-weight':btn.title.length > 0 && 500}">
            {{btn.text}}
          </div>
        </div>
      </button>
    </span>
    <span *ngIf="btn.dropdown">
      <button *ngIf="btn.visible" [disabled]="btn.disabled" type="button" class="btnCustom-{{btn.color}}"
        matTooltip="{{btn.tooltip}}" matTooltipPosition="above" [matMenuTriggerFor]="beforeMenu">
        <span class="material-icons">{{btn.icon}}</span>
        <div *ngIf="btn.text.length > 0">{{btn.text}}</div>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="list-options">
        <ng-container *ngFor="let opt of btn.options">
          <div *ngIf="(opt.visible === undefined || opt.visible === null) ? true : opt.visible" class="menuOptions" mat-menu-item (click)="opt.callback();">
            {{opt.label}}
          </div>
        </ng-container>
      </mat-menu>
    </span>
  </div>
</div>