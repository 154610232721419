<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullBenef ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{active: statusTela1}">
                  <a (click)="showTelaBeneficios('TELA_1')">Lista</a>
                </li>
                <li class="list-inline-item" [ngClass]="{active: statusTela2}">
                  <a (click)="showTelaBeneficios('TELA_2')">Tabela</a>
                </li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
                <!-- <button type="button" *ngIf="statusTela1" class="btn btn-xs btn-link" (click)="openAddRateio()">
                  <span class="material-icons" matTooltip="Adicionar" matTooltipPosition="above"
                    style="font-size: 22px;">add_circle_outline</span>
                </button>
                <button type="button" *ngIf="statusTela2" (click)="salvarRateio()" class="btn btn-xs btn-link"
                  matTooltip="Salvar Alterações" matTooltipPosition="above">
                  <span class="material-icons full-icon" style="font-size: 22px;">save</span>
                </button> -->
                <!-- <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()"
                  matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                </button> -->
                <!--<button type="button" *ngIf="statusTela2" class="btn btn-xs btn-link" (click)="dt2.exportCSV()"
                  matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardBenef()"
                  (click)="changeStateBenef()">
                  <span *ngIf="isCardBenef" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardBenef" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button> -->
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateBenef>
            <div [ngStyle]="statusTela1 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela1'}">
              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [columns]="colsRateioLista" [value]="rateioLista" [rows]="20"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple"
                [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                        <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #codigo
                        (input)="dt.filter(codigo.value, 'codigo', 'contains')">
                    </th>
                    <th class="text-left" style="width: 32%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                        <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #nome
                        (input)="dt.filter(nome.value, 'nome', 'contains')">
                    </th>
                    <th class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'porcent'">
                        <span class="text-overflow-dynamic-ellipsis">PORCENTAGEM <p-sortIcon [field]="'porcent'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #porcent
                        (input)="dt.filter(porcent.value, 'porcent', 'contains')">
                    </th>
                    <th class="text-left" style="width: 22%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ccus'">
                        <span class="text-overflow-dynamic-ellipsis">CENTRO DE CUSTO <p-sortIcon [field]="'ccus'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #ccus
                        (input)="dt.filter(ccus.value, 'ccus', 'contains')">
                    </th>
                    <th class="text-left" style="width: 22%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'proj'">
                        <span class="text-overflow-dynamic-ellipsis">CÓD. PROJ. <p-sortIcon [field]="'proj'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #proj
                        (input)="dt.filter(proj.value, 'proj', 'contains')">
                    </th>
                    <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 32%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 12%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.porcent | number : '1.2-2' : 'pt-BR'}} %">{{rowData.porcent | number :
                          '1.2-2' : 'pt-BR'}} %</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 22%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.ccus}}">{{rowData.ccus}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 22%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.proj}}">{{rowData.proj}}</span>
                      </span>
                    </td>
                    <!-- <td class="text-center" style="width: 90px;">
                      <button type="button" style="padding: 0 !important;" (click)="onRowSelect(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 22px">edit</span>
                      </button>
                      <button type="button" style="padding: 0 !important;" (click)="deleteRateio(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 18px">delete</span>
                      </button>
                    </td> -->
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum rateio encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusTela2 ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusTela2'}">
              <!-- Table New P-TABLE -->
              <!-- <p-table class="table-system" #dt2 [value]="tabelaRateio" scrollable="true" scrollHeight="55vh" [paginator]="true" [rowsPerPageOptions]="rowsPerPage"
                editable="true" [style]="{ 'width': '100%'}" selectionMode="single"> -->
              <p-table class="table-system" #dt2 [value]="tabelaRateio" selectionMode="single" [rows]="rows"
                [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" immutable="false"
                [scrollable]="existemColunasDinamicas()" [style]="{'width':'100%'}" (onEditComplete)="calcularTotal()">

                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-left" style="width: 300px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'funcionario'">
                        <span class="text-overflow-dynamic-ellipsis">FUNCIONÁRIO <p-sortIcon [field]="'funcionario'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #funcionario
                        (input)="dt2.filter(funcionario.value, 'funcionario', 'contains')">
                    </th>
                    <th class="text-center" style="text-align: center; width: 80px;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'total'">
                        <span class="text-overflow-dynamic-ellipsis">TOTAL <p-sortIcon [field]="'total'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #total
                        (input)="dt2.filter(total.value, 'total', 'contains')">
                    </th>
                    <th *ngFor="let proj of liprojtabela" class="text-center" style="width: 125px;">
                      <span class="text-overflow-dynamic-container" pSortableColumn={{proj.field}}>
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{proj.name}}"
                          matTooltipPosition="above">{{ proj.name }} <p-sortIcon field={{proj.field}}></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text" #proj.field
                        (input)="dt2.filter(proj.field.value, proj.field, 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-left" style="width: 300px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.funcionario}}">{{rowData.funcionario}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 80px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.total | number : '1.2-2' : 'pt-BR'}} %">{{rowData.total | number :
                          '1.2-2' : 'pt-BR'}} %</span>
                      </span>
                    </td>
                    <td pEditableColumn *ngFor="let proj of liprojtabela" class="text-center" style="width: 125px;">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <div class="form-row m-0">
                            <div class="form-group col-sm-12 mb-0 p-0">
                              <input class="form-control form-control-sm" type="number"
                                [(ngModel)]="rowData[proj['field']]" />
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span class="text-overflow-dynamic-container">
                            <span class="text-overflow-dynamic-ellipsis" style="cursor: pointer;"
                              matTooltip=" {{rowData[proj['field']]}} %">
                              {{rowData[proj['field']] | number : '1.2-2' : 'pt-BR'}} %
                            </span>
                          </span>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td colspan="2" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum rateio encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVO RATEIO -->
<p-dialog header="Adicionar novo rateio" [(visible)]="modalNew" [style]="{width: '500px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="repetirPeriod">Repetir do periodo anterior?</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-12" *ngIf="repetirPeriod">
      <label>Funcionário</label>
      <p-multiSelect class="type-p-mult" [options]="funcList" [(ngModel)]="funcIdNew"
        defaultLabel="Selecionar funcionário(s)" [filter]="true" placeholder="{0} funcionário(s) selecionado(s)"
        selectedItemsLabel="{0} funcionário(s) selecionado(s)" appendTo="body"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12" *ngIf="!repetirPeriod">
      <label>Funcionário</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o funcionário" [options]="funcList"
        [(ngModel)]="funcIdNew" appendTo="body"></p-dropdown>
    </div>
    <div class="form-group col-sm-12" *ngIf="!repetirPeriod">
      <label>Centro de Custo</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o centro de custo" [options]="ccusList"
        [(ngModel)]="ccusIdNew" appendTo="body" (onChange)="getProj(ccusIdNew)"></p-dropdown>
    </div>
    <div class="form-group col-sm-12" *ngIf="projOk && !repetirPeriod">
      <label>Projeto</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o projeto" [options]="projList"
        [(ngModel)]="projIdNew" appendTo="body"></p-dropdown>
    </div>
    <div class="form-group col-sm-12" *ngIf="!repetirPeriod">
      <label>Porcentagem (%)</label>
      <input class="form-control text-right" [(ngModel)]="porcentagemNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addRateio();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT RATEIO -->
<p-dialog header="Editar rateio" [(visible)]="modalEdit" [style]="{width: '500px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Centro de Custo</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o centro de custo" [options]="ccusList"
        [(ngModel)]="ccusIdEdit" (onChange)="getProj(ccusIdEdit)" appendTo="body"></p-dropdown>
    </div>
    <div class="form-group col-sm-12" *ngIf="projOk">
      <label>Projeto</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o projeto" [options]="projList"
        [(ngModel)]="projIdEdit" appendTo="body"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Porcentagem (%)</label>
      <input class="form-control text-right" [(ngModel)]="porcentagemEdit">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editRateio();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>