<div *ngIf="loading || offline" [class.tests-theme]="tests">
  <div class="shadow" [class.offline]="offline"></div>
  <div class="loading" [class.message]="offline">
    <div>
      <div class="backdrop"></div>
      <img src="/assets/images/icon.png" alt="Loading..." class="icon animated-icon" style="margin-right:49px; width: initial !important;"/>
    </div>
    <span *ngIf="offline">Por favor, verifique sua conexão.</span>
  </div>
</div>

<p-toast position="bottom-right" key="messageLinnks"></p-toast>

<app-sidebar></app-sidebar>

<div class="full-container">
  <div *ngIf="isPublicationNotice" class="alert-publication" role="alert">
    <i class="fas fa-info-circle"></i> {{publicationNoticeMessage}}
  </div>
  <mat-sidenav-container #sidenavContainer>
    <mat-toolbar color="accent" *ngIf="showMenu && (!toolbarHidden || usuario)">
      <div class="headerWrapper">
        <div fxLayout class="toolbar-left-corner sidenav-width" [class.sidenav-padding]="sidenav ? sidenav.opened : ''">
          <img *ngIf="sidenavState" class="img_logo" src="/assets/images/icon.png" [alt]="applicationTitle"  [title]="applicationTitle" />
          <button mat-icon-button class="menu" (click)="handleToggle()" [disabled]="!authenticated">
            <mat-icon class="color-icon">menu</mat-icon>
          </button>
        </div>
        <div class="buttonsWrapper">
          <div class="dropdown" [class.navigation-disabled]="editing">
            <i class="material-icons md-18">business</i>
            <p-dropdown *ngIf="displayEmpresa" class="dropdown-empresa" [filter]="true" placeholder="Selecione a empresa" [disabled]="disableEmpresa" [options]="empresas" [(ngModel)]="dblinkedSessionService.empresa" (ngModelChange)="empresaChange()" [style]="{'background-color':'#F8FAFB'}">
            </p-dropdown>
            <i class="material-icons md-18" *ngIf="dblinkedSessionService.displayFiliais">business</i>
            <p-multiSelect class="type-p-mult" *ngIf="dblinkedSessionService.displayFiliais" [options]="filiais"
            defaultLabel="Selecione filiais" selectedItemsLabel="{0} filiais selecionadas" [(ngModel)]="dblinkedSessionService.filiaisSelecionadas" [maxSelectedLabels]="3" (ngModelChange)="filialChange()">
            </p-multiSelect>
            <i *ngIf="displayPeriodo" class="material-icons md-18">access_time</i>
            <p-dropdown *ngIf="displayPeriodo" class="dropdown-periodo" placeholder="Selecione o período" [disabled]="editing" [options]="dblinkedSessionService.periodos" [(ngModel)]="dblinkedSessionService.periodId" (ngModelChange)="periodoChange($event)" [style]="{'background-color':'#F8FAFB'}">
            </p-dropdown>
          </div>
          <div class="userName">
            <span class="material-icons">account_circle</span>
            <label *ngIf="usuario" matTooltip="{{usuario.nome}} {{usuario.sobrenome}}">
              {{usuario.nome}} {{usuario.sobrenome}}
            </label>
          </div>
          <app-notification></app-notification>
          <button mat-icon-button style="color:#A4A4A4;" (click)="manualLinnks()" matTooltip="Manual Linnks">
            <mat-icon class="color-icon">help_outline</mat-icon>
          </button>
          <button mat-icon-button class="fullscreen" matTooltip="Exibir em {{ isScreenFull ? 'janela' : 'tela cheia' }}" (click)="toggleFullScreen()">
            <mat-icon class="color-icon-text" style="font-size: 29px !important;">
              fullscreen{{ isScreenFull ? '_exit' :'' }}
            </mat-icon>
          </button>
          <button matTooltip="Encerrar sessão" mat-icon-button (click)="logout()" *ngIf="authenticated" [disabled]="!canLogout">
            <mat-icon class="color-icon-text">exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </mat-toolbar>

    <mat-sidenav *ngIf="showMenu" #mainSidenav mode="side" [ngClass]="configService.tests ? 'side-teste' : ''">
      <div class="app-logo"><img [src]="srcImg()" (error)="errorHandlerImg($event)" [alt]="applicationTitle" /></div>
      <div class="version">Versão: {{versionSystem}}</div>
      <mat-nav-list (click)="closeSidebar()">
        <div [class.navigation-disabled]="editing">
          <div *ngIf="displayBackMenu">
            <a mat-list-item class="back" (click)="menuBack()">
              <div class="d-flex align-items-center">
                <span class="material-icons menu-icon">keyboard_arrow_left</span>
                <span mat-line>{{ backMenuTitle }}</span>
              </div>
            </a>
            <a mat-list-item class="current">
              <div class="d-flex align-items-center">
                <span class="material-icons menu-icon">{{ currentMenu.icon }}</span>
                <span mat-line>{{ currentMenu.title }}</span>
              </div>
            </a>
          </div>
          <div *ngFor="let m of currentMenus">
            <a [ngClass]="((m.parent)!==undefined) && (m.menus?.length < 1) ? 'sub-menu' : 'sub-menu-int'" *ngIf="(m.tag !== 'restrict' || root) && m.link" mat-list-item (click)="(m.menus?.length || 0) > 0 ? menuCallback(m) : menuActivate(m)" [routerLink]="m.link" [routerLinkActive]="(m.menus?.length || 0) > 0 ? 'router-parent-link-active' : 'router-link-active'">
              <div *ngIf="(m.menus?.length > 0) && ((m.parent) !== undefined)">
                <span mat-line>{{ m.title }}</span>
                <span class="material-icons menu-icon drop-menu">keyboard_arrow_down</span>
              </div>
              <div *ngIf="(m.menus?.length > 0) && ((m.parent) === undefined)">
                <span mat-line>{{m.title}}</span>
              </div>
              <div class="d-flex align-items-center">
                <span *ngIf="(m.menus?.length < 1) && (m.id !== 1)" mat-line class="indent-line">{{m.title}}</span>
                <span *ngIf="(m.id === 1)" class="material-icons menu-icon">{{m.icon}}</span>
                <span *ngIf="(m.id === 1)" mat-line>{{m.title}}</span>
              </div>
            </a>
            <a class="sub-menu-int" *ngIf="(m.tag !== 'restrict' || root) && !m.link" mat-list-item [class.disabled]="(m.menus?.length || 0) === 0" (click)="menuCallback(m)">
              <div *ngIf="(m.parent!==undefined)">
                <span mat-line class="indent-line">{{m.title}}</span>
                <span class="material-icons menu-icon drop-menu">keyboard_arrow_down</span>
              </div>
              <div *ngIf="(m.parent===undefined)" class="d-flex align-items-center">
                <span class="material-icons menu-icon">{{m.icon}}</span>
                <span mat-line>{{m.title}}</span>
              </div>
            </a>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <div #mainContainer class="main-container" [class.authenticated]="authenticated" [ngClass]="{ fullscreen: !showMenu}">
      <!-- TOOLBAR ANTIGA -->
      <div *ngIf="!editing && !toolbarHidden && usuario" id="control-button" class="control-button">
        <ng-container *ngFor="let button of buttons">
          <button *ngIf="!button.child" [matTooltip]="button.label" [disabled]="button.disabled" class="action mat-elevation-z0 {{ button.icon }}" (click)="button.callback()">
            <mat-icon *ngIf="isButtonIcon(button)">{{ button.name }}</mat-icon>
            <span *ngIf="isButtonText(button)" style="font-size: 12px; letter-spacing: .5px;">{{ button.name }}</span>
          </button>
          <ng-container *ngIf="button.child">
            <button mat-icon-button [matMenuTriggerFor]="matMenu" [matTooltip]="button.label" [disabled]="button.disabled" class="action mat-elevation-z0 {{ button.icon }}" disableRipple>
              <mat-icon *ngIf="isButtonIcon(button)">{{ button.name }}</mat-icon>
              <span *ngIf="isButtonText(button)" style="font-size: 12px; letter-spacing: .5px;">{{ button.name }}</span>
            </button>
            <mat-menu #matMenu="matMenu" xPosition="before" [overlapTrigger]="false">
              <button mat-menu-item *ngFor="let menu of button.child" (click)="menu.callback()">
                <mat-icon *ngIf="menu.icon">{{ menu.icon }}</mat-icon>
                <span *ngIf="menu.label">{{ menu.label }}</span>
              </button>
            </mat-menu>
          </ng-container>
        </ng-container>
      </div>
      <router-outlet>
        <div *ngIf="waitForMFA" id="mfa-dialog">
          <div class="form-row">
            <div class="form-group col-sm-4 position-relative">
              <label>INSIRA O CÓDIGO MANDADO PARA O EMAIL</label>
              <input type="text" class="form-control" id="mfacode" [(ngModel)]="mfaCode" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4 position-relative">
              <button type="button" class="btn btn-primary" (click)='mfaVerification();'>
                <i class="fas fa-check"></i>Verificar
              </button>
            </div>
          </div>
        </div>
      </router-outlet>
    </div>
  </mat-sidenav-container>
</div>

<p-dialog header="Adicionar Nova Senha" [(visible)]="forceResetDialog" [style]="{width: '400px'}" [modal]="true" styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;"></div>
  <div class="form-row">
    <div class="form-group col-sm-12 position-relative">
      <label>SENHA NOVA</label>
      <input type="password" class="form-control" id="uePassowrd0" [(ngModel)]="novaSenha"/>
      <button class="btn btn-sm btn-link submit-eye" type="submit" [ngStyle]="novaSenha?.length > 0 ? {'display': 'block'} : {'display': 'none'}">
        <i id="ueEyePass0" class="fa fa-eye"></i>
      </button>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12 position-relative">
      <label>CONFIRME A SENHA</label>
      <input type="password" class="form-control" id="uePassowrd1" [(ngModel)]="novaSenha2" />
      <button class="btn btn-sm btn-link submit-eye" type="submit" [ngStyle]="novaSenha2?.length > 0 ? {'display': 'block'} : {'display': 'none'}">
        <i id="ueEyePass1" class="fa fa-eye"></i>
      </button>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='forceResetDialog=false;'>
      <i class="fas fa-undo"></i> Fechar
    </button>
    <button type="button" class="btn btn-primary" (click)='salvarNovaSenha();'>
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>
