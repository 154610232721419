<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>




  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt20.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt20 [columns]="colsListaFormularioRespostas" [value]="listaFormularioRespostas"
          [rows]="rows" [paginator]="true" [totalRecords]="listaFormularioRespostas.length"
          (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>

              <th class="text-left" style="width: 50%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'nome', 'contains')">
              </th>
              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                  <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'cpf', 'contains')">
              </th>
              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                  <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'fone'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'fone', 'contains')">
              </th>

              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCadastro'">
                  <span class="text-overflow-dynamic-ellipsis">DATA CAD. <p-sortIcon [field]="'dataCadastro'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'dataCadastro', 'contains')">
              </th>

              <th class="text-center" style="width: 9%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoStatus'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'descricaoStatus'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt20.filter($event.value, 'descricaoStatus', 'in')"></p-multiSelect>
              </th>


            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-left" style="width: 50%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalVisualizarFormulario(rowData)"
                    matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalVisualizarFormulario(rowData)"
                    matTooltip="{{rowData.cpf}}">{{rowData.cpf }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalVisualizarFormulario(rowData)"
                    matTooltip="{{rowData.fone}}">{{rowData.fone}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalVisualizarFormulario(rowData)"
                    matTooltip="{{rowData.dataCadastro}}">{{rowData.dataCadastro}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalVisualizarFormulario(rowData)"
                    matTooltip="{{rowData.descricaoStatus}}">{{rowData.descricaoStatus }}</span>
                </span>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="13" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>




      </div>

      <div class="ui-g">
        <div class="ui-g-12" style=" z-index: 100;">

        </div>
      </div>

      <!-- Inicio Modal SELECIONAR FORMULÁRIO-->
      <p-dialog header="SELECIONAR FORMULÁRIO" [(visible)]="modalSelecionarFormulario" [style]="{width: '700px'}"
        [modal]="true" class="lks-modal" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
        [closable]="true">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Selecione um Formulário</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="false" [options]="opcoesFormulario"
              placeholder="Selecione um Formulário" [(ngModel)]="formularioId" (onChange)="listarRespostas()">
            </p-dropdown>
          </div>
        </div>
        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalSelecionarFormulario=false'><i
              class="fas fa-undo"></i> Fechar</button>
        </p-footer>
      </p-dialog>
      <!--  Final Modal SELECIONAR FORMULÁRIO-->

      <!--Inicio Modal Formulario-->
      <p-dialog header="{{lblFormulario}}" [(visible)]="modalFormularioResposta" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">


        <!-- modalFormularioResposta -->
        <div *ngIf="modalFormularioResposta">

          <mat-tab-group id="tabFormularioVisuInformacoes" [(selectedIndex)]="selectedIndex"
            (selectedTabChange)="tabChange($event)">
            <mat-tab label="AGENDAMENTO">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label> NOME</label>
                  <input id="nomeResponsavel" [(ngModel)]="nome" class="form-control text-left" [disabled]="true"
                    type="text" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label> CPF</label>
                  <input id="cpfResponsavel" [(ngModel)]="cpf" class="form-control text-left" [disabled]="true"
                    type="text" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label> FONE</label>
                  <input id="foneResponsavel" [(ngModel)]="fone" class="form-control text-left" [disabled]="true"
                    type="text" />
                </div>
              </div>

              <div *ngFor="let linhas of listaPerguntas">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label> {{linhas.perguntaDescricao }} </label>
                    <input [(ngModel)]="linhas.resposta" class="form-control text-left" [disabled]="true" type="text" />

                  </div>
                </div>

              </div>
            </mat-tab>

            <mat-tab label="HISTÓRICO">

              <div class="pull-right align-down-table">
                <button type="button" class="btn btn-link" (click)="dt3.exportCSV()"><i
                    class="fas fa-file-excel excel"></i> Exportar para Excel</button>
              </div>
              <div class="clearfix"></div>

              <p-table class="table-system" #dt3 [columns]="colsListaHistorico" [value]="listaHistorico" [rows]="rows"
                [paginator]="false" [totalRecords]="listaHistorico.length" [pageLinks]="pageLinks"
                selectionMode="single" [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>

                    <th class="text-left" style="width: 18%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">DATA </span>
                      </span>
                    </th>
                    <th class="text-left" style="width: 18%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">DATA RE-AGEN. </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">STATUS </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">MOTIVO </span>
                      </span>
                    </th>

                    <th class="text-center" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">RESPONSÁVEL </span>
                      </span>
                    </th>


                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>

                    <td class="text-left" style="width: 18%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.data}}">{{rowData.data
                          }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 18%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.dataReagendamento}}">{{rowData.dataReagendamento }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricaoStatus}}">{{rowData.descricaoStatus}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricaoReagendamentoCancelamento}}">{{rowData.descricaoReagendamentoCancelamento}}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 20%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.nomeResponsavel}}">{{rowData.nomeResponsavel }}</span>
                      </span>
                    </td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>



            </mat-tab>
          </mat-tab-group>




          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalFormularioResposta=false;"><i
              class="fas fa-undo"></i> Fechar</button>
          <button type="button" [(disabled)]="!btnCancelar" class="btn btn-danger"
            (click)="showModalCancelarAgenda()"><i class="fas fa-times"></i> Cancelar</button>
          <button type="button" [(disabled)]="!btnReagendar" class="btn btn-warning" (click)="showModalReagendar()"><i
              class="fas fa-calendar"></i> Re-agendamento</button>
          <button type="button" [(disabled)]="!btnAgendar" class="btn btn-primary" (click)="showModalAgenda()"><i
              class="fas fa-check"></i> Agendar</button>

        </p-footer>
      </p-dialog>
      <!--Final Modal Formulario-->


      <!--Inicio Modal Agenda-->
      <p-dialog header="Agenda" [(visible)]="modalFormularioAgenda" [style]="{width: '1200px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalFormularioAgenda -->
        <div *ngIf="modalFormularioAgenda">

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>PALESTRANTE </label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesSeminarista"
                [(ngModel)]="seminaristaEmail" (onChange)="atualizarAgenda(seminaristaEmail)"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">

              <iframe [src]="urlSafe" style="border: 0" width="100%" height="400" frameborder="0"
                scrolling="no"></iframe>
            </div>
          </div>

          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>


        <p-footer>

          <button type="button" class="btn btn-secondary" (click)="modalFormularioAgenda=false;"><i
              class="fas fa-undo"></i> Fechar</button>
          <!-- <button  type="button" class="btn btn-primary"  (click)="showNovoAgendamento()" ><i class="fas fa-check"></i> Agendamento</button> -->
          <button type="button" class="btn btn-primary" (click)="showModalAgendamento()"><i class="fas fa-check"></i>
            Agendamento</button>

        </p-footer>
      </p-dialog>
      <!--Final Modal Agenda-->

      <!--Inicio Modal Agendamento-->
      <p-dialog header="Agendamento" [(visible)]="modalFormularioAgendamento" [style]="{width: '1000px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalFormularioAgendamento -->
        <div *ngIf="modalFormularioAgendamento">


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>PALESTRANTE </label>
              <p-dropdown class="type-p-dropdown" [disabled]="true" [filter]="true" placeholder="Selecione"
                [options]="opcoesSeminarista" [(ngModel)]="seminaristaEmail"></p-dropdown>
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>TIPO DE ATIVIDADE </label>
              <input [(ngModel)]="tipoAtividade" class="form-control text-left" type="text" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>DATA INICIO </label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [showTime]="true" [(ngModel)]="dataInicioEvento" hourFormat="12" (ngModelChange)="atualizaDataFinal()">
              </p-calendar>
            </div>

            <div class="form-group col-sm-6">
              <label>DATA FINAL </label>
              <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [showTime]="true" hourFormat="12" [(ngModel)]="dataFinalEvento">
              </p-calendar>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>DESCRIÇÃO </label>
              <!-- <textarea class="form-control" [froalaEditor]="optionsDescFroola" rows="10" [(ngModel)]="descricaoEvento"  value="{{descricaoEvento}}" name="descricaoEvento"> </textarea> -->
              <textarea [froalaEditor]="optionsDescFroola" class="field card-back-description"
                [(ngModel)]="descricaoEvento" value="{{descricaoEvento}}" name="descricaoEvento"></textarea>

            </div>
          </div>

          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px; z-index: 100;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>


        <p-footer>

          <button type="button" class="btn btn-secondary" (click)="modalFormularioAgendamento=false;"><i
              class="fas fa-undo"></i> Fechar</button>
          <button type="button" class="btn btn-primary" (click)="salvarAgendamento()"><i class="fas fa-check"></i> Fazer
            agendamento</button>
        </p-footer>
      </p-dialog>

      <!--Final Modal Agendamento-->

      <!--Inicio Modal Cancelamento Agendamento-->
      <p-dialog header="Cancelar Agendamento" [(visible)]="modalCancelarAgendamento" [style]="{width: '1000px'}"
        [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalConsultor -->
        <div *ngIf="modalCancelarAgendamento">

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Selecione um Motivo</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="false" [options]="opcoesMotivosCancelamento"
                placeholder="Selecione um Motivo" [(ngModel)]="motivoCancelamentoId"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Data do Contato</label>
              <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [(ngModel)]="dataCancelamento">
              </p-calendar>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>OBSERVAÇÃO </label>
              <!-- <textarea class="form-control" rows="10" [(ngModel)]="descricaoEvento"  value="{{descricaoEvento}}" name="observacao"> </textarea> -->
              <textarea [froalaEditor]="optionsDescFroola" class="field card-back-description"
                [(ngModel)]="descricaoEvento" value="{{descricaoEvento}}" name="descricaoEvento"></textarea>
            </div>
          </div>

          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalCancelarAgendamento=false;"><i
              class="fas fa-undo"></i> Fechar</button>
          <button type="button" class="btn btn-primary" (click)="salvarCancelamentoAgendado()"><i
              class="fas fa-check"></i> Cancelar Agendamento</button>
        </p-footer>
      </p-dialog>
      <!--Final Modal Cancelamento Agendamento-->

      <!-- Inicio modal de Re-agendamento -->
      <p-dialog header="Re-agendamento" [(visible)]="modalReagendar" [style]="{width: '1000px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalConsultor -->
        <div *ngIf="modalReagendar">

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>PALESTRANTE </label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesSeminarista"
                [(ngModel)]="seminaristaEmail"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Selecione um Motivo</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="false"
                [options]="opcoesMotivosReagendamento" placeholder="Selecione um motivo"
                [(ngModel)]="motivoReagendamentoId"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Data Inicial</label>
              <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [showTime]="true" hourFormat="12" [(ngModel)]="dataInicioEvento" (ngModelChange)="atualizaDataFinal()">
              </p-calendar>
            </div>

            <div class="form-group col-sm-6">
              <label>Data Final</label>
              <p-calendar appendTo="body" class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [showTime]="true" hourFormat="12" [(ngModel)]="dataFinalEvento">
              </p-calendar>
            </div>

          </div>


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>OBSERVAÇÃO </label>
              <textarea [froalaEditor]="optionsDescFroola" rows="5" class="field card-back-description"
                [(ngModel)]="descricaoEvento" value="{{descricaoEvento}}" name="descricaoEvento"></textarea>
              <!-- <textarea class="form-control" rows="10" [(ngModel)]="descricaoEvento"  value="{{descricaoEvento}}" name="descricaoEvento"> </textarea> -->
            </div>
          </div>

          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>


        <p-footer>

          <button type="button" class="btn btn-secondary" (click)="modalReagendar=false;"><i class="fas fa-undo"></i>
            Fechar</button>
          <button type="button" class="btn btn-primary" (click)="salvarReagendamento()"><i class="fas fa-check"></i>
            Re-agendamento</button>
        </p-footer>
      </p-dialog>
      <!-- Final modal de Re-agendamento -->



    </mat-card-content>
  </div>


</mat-card>