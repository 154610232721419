<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-6">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-cog"></i> Configuração Transfer Pricing</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeConfig()"
                  (click)="changeStateConfig()">
                  <span *ngIf="isCardConfig" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardConfig" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfig>
            <div class="form-group col-sm-12">

              <div class="form-row pt-3 pb-3">
                <div class="form-group col-sm-4">
                  <label>Código</label>
                  <span class="obrigatorio" *ngIf="[undefined, null, ''].indexOf(tpConfigRelat.codigo) >= 0">*</span>
                  <input class="form-control" type="text" placeholder="" [(ngModel)]="tpConfigRelat.codigo">
                </div>

                <div class="form-group col-sm-4">
                  <label>Nome da Configuração</label>
                  <span class="obrigatorio" *ngIf="[undefined, null, ''].indexOf(tpConfigRelat.nome) >= 0">*</span>
                  <input class="form-control" type="text" placeholder="" [(ngModel)]="tpConfigRelat.nome">
                </div>

                <div class="form-group col-sm-4">
                  <label>Adicionar filtro <span class="obrigatorio">*</span></label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione uma aba"
                    (onChange)="loadTableFields()" [options]="opcoesWorksheet" [(ngModel)]="selectedWorksheet">
                  </p-dropdown>
                </div>
              </div>

              <!--COLUNA VIEW-->
              <div class="row">
                <div class="col-sm-12">
                  <p-table class="table-system" #dt6 [value]="tableFields" [rows]="rows" [paginator]="true"
                    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%'}">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'campo'">
                            <span class="text-overflow-dynamic-ellipsis">CAMPO <p-sortIcon [field]="'campo'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'campo', 'contains')">
                        </th>
                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                            <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'descricao', 'contains')">
                        </th>
                        <th style="text-align: left; width: 34%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                            <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipo'"></p-sortIcon>
                            </span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'tipo', 'contains')">
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-viewCampo>
                      <tr [pSelectableRow]="viewCampo">
                        <td (click)="onSelectViewCampo(viewCampo.campo)">{{viewCampo.campo}}</td>
                        <td (click)="onSelectViewCampo(viewCampo.campo)">{{viewCampo.descricao}}</td>
                        <td (click)="onSelectViewCampo(viewCampo.campo)">{{viewCampo.tipo}}</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-rowData>
                      <tr>
                        <td [attr.colspan]="3" class="spaced-alert">
                          <div class="alert alert-xs alert-primary d-inline" role="alert">
                            <i class="fas fa-info-circle"></i> Selecione uma aba!
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mark class="d-inline-block mb-1 mt-4" style="font-size: 13px;">Não informar o
                    <strong>WHERE</strong>,
                    somente a condição.</mark>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <textarea rows="8" cols="100" class="form-control"
                        [(ngModel)]="worksheetSqlRelac[selectedWorksheet]"></textarea>
                    </div>
                  </div>
                </div>

                <div class="form-group col-sm-12">
                  <button type="button" *ngIf="isNewConfig" class="btn btn-primary" (click)="salvarRelatorio()"><i
                      class="fas fa-check"></i>
                    Salvar</button>

                  <button type="button" *ngIf="!isNewConfig" class="btn btn-primary" (click)="editarRelatorio()"><i
                      class="fas fa-check"></i>
                    Salvar</button>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>