<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content pt-2">
      <div class="header-box">
        <div class="header-box-content p-3">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            <!-- <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button> -->
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <div style="margin-top: 5px;">
      <p-table  class="table-system" #dt1  [value]="lista" [rows]="rows" selectionMode="multiple"  [paginator]="true"
      [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" immutable="false" [style]="{'width':'100%'}"
      (onRowUnselect)="unselectCheckbox($event)" (onRowSelect)="selectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 3%;">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="checkAll()"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container"  [pSortableColumn]="'data'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">DATA <p-sortIcon [field]="'data'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'data', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'requisicaoId'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">CÓDIGO <p-sortIcon [field]="'requisicaoId'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'requisicaoId', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoItem'">
                      <span class="text-overflow-dynamic-ellipsis"  style="height: 12px">ITEM <p-sortIcon [field]="'codigoItem'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'codigoItem', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                      <span class="text-overflow-dynamic-ellipsis"  style="height: 12px">HISTÓRICO <p-sortIcon [field]="'historico'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'historico', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'almoxarifado'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">ALMOXARIFADO <p-sortIcon [field]="'almoxarifado'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'almoxarifado', 'contains')">
                  </th>
                  <th class="text-center" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoEntradaSaida'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">TIPO <p-sortIcon [field]="'tipoEntradaSaida'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'tipoEntradaSaida', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">QTDE <p-sortIcon [field]="'qtde'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'qtde', 'contains')">
                  </th>
                  <!-- <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                      <span class="text-overflow-dynamic-ellipsis">USUÁRIO <p-sortIcon [field]="'usuario'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'usuario', 'contains')">
                  </th> -->
                  <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 3%;" [id]="'checkboxContainer'+rowData.requisicaoId">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="row(rowData)"
                       matTooltip="{{ rowData.data }}">
                        {{ rowData.data }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span *ngIf="rowData.tipoEntradaSaida === 'S'" class="text-overflow-dynamic-ellipsis" (click)="row(rowData)"matTooltip="R{{rowData.requisicaoId}}">R{{rowData.requisicaoId}}</span>
                      <span *ngIf="rowData.tipoEntradaSaida === 'E'" class="text-overflow-dynamic-ellipsis" (click)="row(rowData)" matTooltip="R{{rowData.requisicaoParentId}}E{{rowData.requisicaoId}}">R{{rowData.requisicaoParentId}}E{{rowData.requisicaoId}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="row(rowData)"  matTooltip="{{ rowData.codigoItem }}">
                        {{ rowData.codigoItem }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="row(rowData)"  matTooltip="{{ rowData.historico }}">
                        {{ rowData.historico }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="row(rowData)"  matTooltip="{{ rowData.descricaoAlmoxarifado }}">
                        {{ rowData.almoxarifado }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="row(rowData)" matTooltip="{{ rowData.tipoEntradaSaida }}">
                        {{ rowData.tipoEntradaSaida }}
                      </span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="row(rowData)" matTooltip="{{ rowData.qtde }}">
                        {{ rowData.qtde }}
                      </span>
                    </span>
                  </td>
                  <!-- <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.usuario }}">
                        {{ rowData.usuario }}
                      </span>
                    </span>
                  </td> -->
                  <!-- <td class="text-center" style="width: 90px;">
                    <button type="button" style="padding: 0 !important;" (click)="editarRequisicao(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">edit</span>
                    </button>
                    <button type="button" style="padding: 0 !important;" (click)="selectRequisicao(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Estornar" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">undo</span>
                    </button>
                    <button type="button" style="padding: 0 !important;" (click)="removerRequisicao(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 18px">delete</span>
                    </button>
                  </td> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
    </div>
  </mat-card-content>

</mat-card>

<!-- Modal Requisição -->
<p-dialog header="Requisição" [(visible)]="modal" [style]="{width: '800px'}" [modal]="true" [responsive]="true"
  class="modal-initial" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Data <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" [(ngModel)]="dtOperacao" appendTo="body" [monthNavigator]="true"
        [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-2" [hidden]="true">
      <!--Liberar para ver na edição-->
      <label>Código</label>
      <input class="form-control" type="text" disabled="true" />
    </div>
    <div class="form-group col">
      <label>Almoxarifado <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [(ngModel)]="local1Modal" placeholder="selecione"
        [filter]="true" [options]="listaLocal"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Item <span class="obrigatorio">*</span></label>
      <p-dropdown (onChange)="buscarSaldoItem($event)" placeholder="selecione" [(ngModel)]="item1Modal" appendTo="body"
        [filter]="true" [options]="listaItem" class="type-p-dropdown"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>QTDE. Disponível</label>
      <input class="form-control text-right" [(ngModel)]="qtdeDisponivelModal" type="text" readOnly />
    </div>
    <div class="form-group col-sm-6">
      <label>QTDE. <span class="obrigatorio">*</span></label>
      <input class="form-control text-right" [(ngModel)]="qtde1Modal" type="text" number />
    </div>
  </div>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='cancelar()'><i class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='salvar()'><i class="fas fa-check"></i> Salvar</button>
  </p-footer>

</p-dialog>


<!-- Modal Estorno Requisicao -->
<p-dialog header="Estorno de Requisição" [(visible)]="modalEstorno" [style]="{width: '800px'}" [modal]="true"
  [responsive]="true" class="modal-initial" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-10">
      Requisição feita por: {{requisicaoEdicao.usuario}}
    </div>
    <div class="form-group col-sm-2">
      Saldo: {{requisicaoEdicao.saldo}}
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Data <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" [(ngModel)]="dtEstorno" appendTo="body" [monthNavigator]="true"
        [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="defaultDate" dateFormat="dd/mm/yy">
      </p-calendar>
    </div>
    <div class="form-group col-sm-8">
      <label>HISTÓRICO</label>
      <input class="form-control text-left" [(ngModel)]="historicoEstorno" type="text" />
    </div>
    <div class="form-group col-sm-2">
      <label>QTDE<span class="obrigatorio"> *</span></label>
      <input class="form-control text-right" [(ngModel)]="qtdeEstorno" type="text" number />
    </div>
  </div>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalEstorno=false"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='salvarEstorno()'><i class="fas fa-check"></i>
      Estornar</button>
  </p-footer>

</p-dialog>

<!-- Modal Alterar Período FEITO-->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div *ngIf="modalPeriodo">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="getRequisicoes();"><i class="fas fa-pencil-alt"></i>
      Alterar</button>
  </p-footer>
</p-dialog>