<mat-card class="card-linnks margin-linnks" style="margin-top: 15px !important;">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content pt-2">
      <!-- <div class="pull-right align-down-table">
        <button type="button" class="btn btn-link" (click)="dt1.exportCSV()">
          <i class="fas fa-file-excel excel"></i> Exportar para Excel</button>
      </div> -->
      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="exportarExcel()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <p-table class="table-system" #dt1 [columns]="colsretidosFonte"
        [value]="retidosFonte" [rows]="rows" [paginator]="true" selectionMode="single" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false" (onFilter)="onFilter($event)"
        [scrollable]="true" (onRowSelect)="select($event)"  (onRowUnselect)="unselect($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 100px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEntrada'">
                <span class="text-overflow-dynamic-ellipsis">{{columnTitle}} <p-sortIcon [field]="'dtEntrada'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'dtEntrada', 'contains')">
            </th>
            <th class="text-center" style="width: 100px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEmissao'">
                <span class="text-overflow-dynamic-ellipsis">Data Emissão <p-sortIcon [field]="'dtEmissao'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'dtEmissao', 'contains')">
            </th>
            <th class="text-left" style="width: 170px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'cnpj', 'contains')">
            </th>
            <th class="text-left" style="width: 250px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
                <span class="text-overflow-dynamic-ellipsis">RAZÃO SOCIAL <p-sortIcon [field]="'razao'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'razao', 'contains')">
            </th>
            <th class="text-left" style="width: 200px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'mun'">
                <span class="text-overflow-dynamic-ellipsis">MUNICÍPIO <p-sortIcon [field]="'mun'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'mun', 'contains')">
            </th>
            <th class="text-center" style="width: 80px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                <span class="text-overflow-dynamic-ellipsis">UF<p-sortIcon [field]="'uf'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'uf', 'contains')">
            </th>
            <th class="text-left" style="width: 200px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'regTrib'">
                <span class="text-overflow-dynamic-ellipsis">REGIME TRIB <p-sortIcon [field]="'regTrib'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'regTrib', 'contains')">
            </th>
            <th class="text-left" style="width: 150px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'status_cnpj'">
                <span class="text-overflow-dynamic-ellipsis">STATUS CNPJ <p-sortIcon [field]="'status_cnpj'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'status_cnpj', 'contains')">
            </th>
            <th class="text-center" style="width: 135px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'atualizado'">
                <span class="text-overflow-dynamic-ellipsis">ATUALIZADO EM <p-sortIcon [field]="'atualizado'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'atualizado', 'contains')">
            </th>
            <th class="text-left" style="width: 150px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'numDoc'">
                <span class="text-overflow-dynamic-ellipsis">NUM DOC <p-sortIcon [field]="'numDoc'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'numDoc', 'contains')">
            </th>
            <th class="text-center" style="width: 150px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'status', 'contains')">
            </th>
            <th class="text-left" style="width: 100px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codList'">
                <span class="text-overflow-dynamic-ellipsis">COD LIST <p-sortIcon [field]="'codList'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'codList', 'contains')">
            </th>
            <th class="text-left" style="width: 100px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codItem'">
                <span class="text-overflow-dynamic-ellipsis">COD ITEM <p-sortIcon [field]="'codItem'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'codItem', 'contains')">
            </th>
            <th class="text-left" style="width: 250px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descItem'">
                <span class="text-overflow-dynamic-ellipsis">DESCR ITEM<p-sortIcon [field]="'descItem'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'descItem', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrOper'">
                <span class="text-overflow-dynamic-ellipsis">VALOR OPER<p-sortIcon [field]="'vlrOper'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'vlrOper', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'deducao'">
                <span class="text-overflow-dynamic-ellipsis">DEDUÇÃO<p-sortIcon [field]="'deducao'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'deducao', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrServico'">
                <span class="text-overflow-dynamic-ellipsis">VALOR SERVIÇO<p-sortIcon [field]="'vlrServico'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'vlrServico', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIssqn'">
                <span class="text-overflow-dynamic-ellipsis">ALIQ ISSQN<p-sortIcon [field]="'aliqIssqn'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'aliqIssqn', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrIssqn'">
                <span class="text-overflow-dynamic-ellipsis">VALOR ISSQN<p-sortIcon [field]="'vlrIssqn'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'vlrIssqn', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqIrrf'">
                <span class="text-overflow-dynamic-ellipsis">ALIQ IRRF<p-sortIcon [field]="'aliqIrrf'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'aliqIrrf', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlIrrf'">
                <span class="text-overflow-dynamic-ellipsis">VALOR IRRF<p-sortIcon [field]="'vlIrrf'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'vlIrrf', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqCsrf'">
                <span class="text-overflow-dynamic-ellipsis">ALIQ CSRF<p-sortIcon [field]="'aliqCsrf'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'aliqCsrf', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlCsrf'">
                <span class="text-overflow-dynamic-ellipsis">VALOR CSRF<p-sortIcon [field]="'vlCsrf'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'vlCsrf', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliqInss'">
                <span class="text-overflow-dynamic-ellipsis">ALIQ INSS<p-sortIcon [field]="'aliqInss'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'aliqInss', 'contains')">
            </th>
            <th class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrInss'">
                <span class="text-overflow-dynamic-ellipsis">VALOR INSS<p-sortIcon [field]="'vlrInss'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'vlrInss', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-center" style="width: 100px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dtEntrada}}">{{rowData.dtEntrada }}</span>
                <!-- <span class="text-overflow-dynamic-ellipsis" matTooltip="{{columnTitle}}" >{{columnTitle }}</span> -->
              </span>
            </td>
            <td class="text-center" style="width: 100px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dtEmissao}}">{{rowData.dtEmissao }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 170px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj}}">{{rowData.cnpj }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 250px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao}}">{{rowData.razao }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 200px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.mun}}">{{rowData.mun }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 80px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}">{{rowData.uf }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 200px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.regTrib}}">{{rowData.regTrib }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 150px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.status_cnpj}}">{{rowData.status_cnpj }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 135px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.atualizado}}">{{rowData.atualizado }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 150px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.numDoc}}">{{rowData.numDoc }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 150px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 100px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.codList}}">{{rowData.codList }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 100px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.codItem}}">{{rowData.codItem }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 250px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descItem}}">{{rowData.descItem }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrOper | money}}">{{rowData.vlrOper | money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.deducao | money}}">{{rowData.deducao | money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrServico | money}}">{{rowData.vlrServico | money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.aliqIssqn | money}}">{{rowData.aliqIssqn | money}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrIssqn | money}}">{{rowData.vlrIssqn | money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.aliqIrrf | money}}">{{rowData.aliqIrrf | money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlIrrf | money}}">{{rowData.vlIrrf | money }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.aliqCsrf | money}}">{{rowData.aliqCsrf | money}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlCsrf | money}}">{{rowData.vlCsrf | money}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.aliqInss | money}}">{{rowData.aliqInss | money}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 120px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlrInss | money}}">{{rowData.vlrInss | money}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="26" class="spaced-alert" style="width: 3501px">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>
