<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">
    </div>
  </div>

  <div class="row">
    <div class="col pb-3">
      <div *ngIf="!telaConfiguracao">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu mb-2 mt-0 d-inline-block">
                <li class="list-inline-item" [ngClass]="{active: true}"><a>CONEXÕES DE PLANO CONTAS</a></li>
              </ul>
              <div class="actions-card">
                <button class="btn btn-sm btn-info" type="button" (click)="addItem()"><i class="fas fa-plus"></i>
                  Adicionar conexão</button>
                <button type="button" class="btn btn-xs btn-link" (click)="showHideCardResultadosParametrosContabeis()"
                  (click)="changeStateResultadosParametrosContabeis()">
                  <span *ngIf="isCardResultadosParametrosContabeis" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardResultadosParametrosContabeis" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;"
            [@changeDivSize]=currentStateResultadosParametrosContabeis>
            <div class="row" style="padding: 14px;">
              <p-table class="table-system" #dt1 [value]="empresaParametroContabil" selectionMode="single"
                [paginator]="true" (onFilter)="onFilter()" [pageLinks]="pageLinks" [rows]="rows"
                [rowsPerPageOptions]="rowsPerPage">

                <ng-template pTemplate="header">
                  <tr>

                    <th class="text-center" style="width: 25%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'labelContaCliente'">
                        <span class="text-overflow-dynamic-ellipsis">Conta Contábil Cliente Nacional <p-sortIcon
                            [field]="'labelContaCliente'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'labelContaCliente', 'contains')">
                    </th>

                    <th class="text-center" style="width: 25%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'labelContaFornecedor'">
                        <span class="text-overflow-dynamic-ellipsis">Conta Contábil Fornecedor Nacional <p-sortIcon
                            [field]="'labelContaFornecedor'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'labelContaFornecedor', 'contains')">
                    </th>


                    <th class="text-center" style="width: 25%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'labelContaFinanceiro'">
                        <span class="text-overflow-dynamic-ellipsis">Conta Contábil Financeiro <p-sortIcon
                            [field]="'labelContaFinanceiro'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'labelContaFinanceiro', 'contains')">
                    </th>



                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'verificarConciliacaoStr'">
                        <span class="text-overflow-dynamic-ellipsis">Verifica Conciliação <p-sortIcon
                            [field]="'verificarConciliacaoStr'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'verificarConciliacaoStr', 'contains')">
                    </th>

                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">Ação</span>
                    </th>

                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td style="width: 25%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.labelContaCliente}}">{{rowData.labelContaCliente}}</span>
                      </span>
                    </td>
                    <td style="width: 25%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.labelContaFornecedor}}">{{rowData.labelContaFornecedor}}</span>
                      </span>
                    </td>
                    <td style="width: 25%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.labelContaFinanceiro}}">{{rowData.labelContaFinanceiro}}</span>
                      </span>
                    </td>
                    <td style="width: 15%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.verificarConciliacaoStr}}">{{rowData.verificarConciliacaoStr}}</span>
                      </span>
                    </td>

                    <td style="width: 10%;" class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <button
                          type="button" style="padding: 0 !important;"
                          (click)="editarParametroContabil(rowData)" class="btn btn-xs btn-link btn-full"
                          matTooltip="Editar" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px">edit</span>
                        </button>

                        <button
                          type="button" style="padding: 0 !important;"
                          (click)="removerParametroContabil(rowData)" class="btn btn-xs btn-link btn-full"
                          matTooltip="Excluir" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px">delete</span>
                        </button>

                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="telaConfiguracao">
    <div class="row">
      <div class="col pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content" style="padding: 0.4rem 1rem 0.4rem">
              <ul class="list-inline filter-menu mb-2 mt-1 d-inline-block">
                <li class="list-inline-item" [ngClass]="{active: true}"><a>Criar/Editar Parâmetro Contábil</a></li>
              </ul>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="voltarTelaInicialParametroContabil()">
                  <span class="material-icons full-icon" matTooltip="Voltar" matTooltipPosition="above">undo</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;">
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Conta Contábil Cliente Nacional <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContas"
                  [(ngModel)]="ccClienteNacional" (onChange)="checarContaAnalitica(ccClienteNacional, 1)"></p-dropdown>
              </div>
              <div class="form-group col-sm-4" style="margin-left: 15px">
                <label>Conta relacionada </label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContasRfb"
                  [(ngModel)]="ccClienteNacionalRelac"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Conta Contábil Cliente Internacional <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContas"
                  [(ngModel)]="ccClienteInternacional" (onChange)="checarContaAnalitica(ccClienteInternacional, 2)">
                </p-dropdown>
              </div>
              <div class="form-group col-sm-4" style="margin-left: 15px">
                <label>Conta relacionada </label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContasRfb"
                  [(ngModel)]="ccClienteInternacionalRelac"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Conta Contábil Fornecedor <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContas"
                  [(ngModel)]="ccFornecedor" (onChange)="checarContaAnalitica(ccFornecedor, 3)"></p-dropdown>
              </div>
              <div class="form-group col-sm-4" style="margin-left: 15px">
                <label>Conta relacionada </label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContasRfb"
                  [(ngModel)]="ccFornecedorRelac"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Conta Contábil Fornecedor Internacional <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContas"
                  [(ngModel)]="ccFornecedorInternacional"
                  (onChange)="checarContaAnalitica(ccFornecedorInternacional, 4)"></p-dropdown>
              </div>
              <div class="form-group col-sm-4" style="margin-left: 15px">
                <label>Conta Relacionada </label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContasRfb"
                  [(ngModel)]="ccFornecedorInternacionalRelac"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Conta Contábil Financeiro <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContas"
                  [(ngModel)]="ccFinanceiro" (onChange)="checarContaAnalitica(ccFinanceiro, 5)"></p-dropdown>
              </div>
              <div class="form-group col-sm-4" style="margin-left: 15px">
                <label>Conta relacionada </label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="opcoesPlanoContasRfb"
                  [(ngModel)]="ccFinanceiroRelac"></p-dropdown>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-2">
                <label>Nível Mínimo Conta Analítica</label>
                                <input class="form-control" type="number" [(ngModel)]="minimoAnalitica" />

              </div>
              <div class="form-group col-sm-1">
                <label>Dígitos Nivel 1 </label>
                   <input class="form-control" type="number" [(ngModel)]="digitosNivel1" />
              </div>
              <div class="form-group col-sm-1">
                <label>Dígitos Nivel 2 </label>
                   <input class="form-control" type="number" [(ngModel)]="digitosNivel2" />
              </div>
              <div class="form-group col-sm-1" style="margin-left: 15px">
                <label>Dígitos Nivel 3 </label>
                   <input class="form-control" type="number" [(ngModel)]="digitosNivel3" />
              </div>
              <div class="form-group col-sm-1" >
                <label>Dígitos Nivel 4 </label>
                   <input class="form-control" type="number" [(ngModel)]="digitosNivel4" />
              </div>
              <div class="form-group col-sm-1" >
                <label>Dígitos Nivel 5 </label>
                   <input class="form-control" type="number" [(ngModel)]="digitosNivel5" />
              </div>
              <div class="form-group col-sm-1" >
                <label>Dígitos Nivel 6 </label>
                <input class="form-control" type="number" [(ngModel)]="digitosNivel6" />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-1"  matTooltip="Após cada código separe com ;">
                <label>Ini Cód Ativo</label>
                   <input class="form-control" type="text" [(ngModel)]="iniCodNat1" />
              </div>
              <div class="form-group col-sm-1" matTooltip="Após cada código separe com ;">
                <label>Ini Cód Passivo </label>
                   <input class="form-control" type="text" [(ngModel)]="iniCodNat2" />
              </div>
              <div class="form-group col-sm-1" matTooltip="Após cada código separe com ;">
                <label>Ini Cód PL </label>
                   <input class="form-control" type="text" [(ngModel)]="iniCodNat3" />
              </div>
              <div class="form-group col-sm-1"  matTooltip="Após cada código separe com ;">
                <label>Ini Cód Resultado</label>
                   <input class="form-control" type="text" [(ngModel)]="iniCodNat4" />
              </div>

              <div class="form-group col-sm-4" style="margin-left: 15px">
                <label>Responsável Contábil (Para mais de um separar por ';')</label>
                   <input class="form-control" type="text" [(ngModel)]="respContabil" />
              </div>

            </div>

            <div class="form-row">
              <div class="form-group col-sm-3"  matTooltip="Após cada nível separe com ;">
                <label>Níveis de contas para o relatório DRE</label>
                <input class="form-control" type="text" [(ngModel)]="niveisContasDre" />
              </div>

              <div class="form-group col-sm-3"  matTooltip="Após cada nível separe com ;">
                <label>Níveis de contas para o Balanço Patrimonial</label>
                <input class="form-control" type="text" [(ngModel)]="niveisBalancoPatrimonial" />
              </div>

              <div class="form-group col-sm-3">
                <label>Selecionar Conta de Resultado</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesPlanoContasResultado" [(ngModel)]="contaEncerramentoResultadoSelecionada">
                </p-dropdown>
              </div>
            </div>


            <div class="form-row">
              <div class="col-sm-4" style="margin-top: 15px">
                <mat-slide-toggle class="mr-2" [(ngModel)]="verificarConciliacao" [checked]="verificarConciliacao">
                  VERIFICA CONCILIAÇÃO</mat-slide-toggle>
              </div>
            </div>

            <div class="form-row">
              <div class="col-sm-4" style="margin-top: 15px">
                <mat-slide-toggle class="mr-2" [(ngModel)]="codPlanoContasPadrao" [checked]="codPlanoContasPadrao">
                  PLANO CONTAS CÓDIGO PADRÃO</mat-slide-toggle>
              </div>
            </div>

            <div class="form-row">
              <div class="col-sm-4" style="margin-top: 15px">
                <mat-slide-toggle class="mr-2" [(ngModel)]="alterarRateio" [checked]="alterarRateio">PERMITE ALTERAR
                  RATEIO</mat-slide-toggle>
              </div>
            </div>

            <div class="form-row">
              <div class="col-sm-4" style="margin-top: 15px">
                <mat-slide-toggle class="mr-2" [(ngModel)]="visualizarContaAutomatica" [checked]="visualizarContaAutomatica">VISUALIZA CONTAS AUTOMÁTICAS</mat-slide-toggle>
              </div>
            </div>

            <p-footer>
              <button type="button" class="btn btn-primary" style="margin-top: 15px"
                (click)="salvarParametroContabil()"><i class="fas fa-check"></i>
                Salvar</button>
            </p-footer>
          </div>
        </div>
      </div>
    </div>
  </div>

</mat-card>