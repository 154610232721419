<mat-card class="card-linnks margin-linnks">

        <mat-card-title>
          {{titlePrincipal}}
          <mat-card-subtitle>
            {{subtitle}}
          </mat-card-subtitle>
        </mat-card-title>

        <div class="ui-g">
          <div class="ui-g-12">

          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 pb-3">
            <div class="shadow-box">

                <div class="header-box">
                  <div class="header-box-content">
                    <h3 class="title-card">{{tituloCard}}</h3>
                    <div class="actions-card">
                      <app-toolbar [value]="toolbarMain" *ngIf="!novoGrupo && !editaGrupo"></app-toolbar>
                    </div>
                  </div>
                </div>

          <div class="box-content" *ngIf="!novoGrupo && !editaGrupo">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-file"></i> Grupo</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                  <p-dropdown class="type-p-dropdown" [filter]="true" [options]="grupos"
                  placeholder="Selecione o grupo" [(ngModel)]="groupId" (onChange)="grupoSelecionado()"></p-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-users"></i> Menus</h3>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col-sm-6">
                <div class="conteudo m-0">
                  <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Relacionados" [source]="listaRelacionar"
                    [target]="listaRelacionados" [dragdrop]="true" [responsive]="true" filterBy="permissions_url"
                    [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-resp pTemplate="item">{{resp.permissions_url}}</ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="salvarRelacionamento()">
              <i class="fas fa-check"></i>
              Salvar
            </button>
          </div>

          <div class="box-content" *ngIf="novoGrupo || editaGrupo">
            <div class="row">
              <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-file"></i> Grupo</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Código <span class="obrigatorio">*</span></label>
                <input class="form-control" [(ngModel)]="codNomeGrupo" type="text" />
              </div>
              <div class="form-group col-sm-8">
                <label>Nome <span class="obrigatorio">*</span></label>
                <input class="form-control" [(ngModel)]="nomeGrupo" type="text" />
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="form-group col-sm-12">
                <label>Descrição </label>
                <input class="form-control" [(ngModel)]="descricaoGrupo" type="text" />
              </div>
            </div>
            <p-footer>
              <div class="w-100 d-flex align-items-center justify-content-end">
                <button class="btnCustom-default" (click)="fecharForm()">
                  <span class="material-icons">close</span>
                  <div>Cancelar</div>
                </button>
                <button class="btnCustom-green" (click)="salvarForm()">
                  <span class="material-icons">save</span>
                  <div>Salvar</div>
                </button>
              </div>
            </p-footer>
          </div>
        </div>
      </div>
    </div>

</mat-card>

