<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12"></div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Objeto</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentStateObjeto">
            <p-table class="table-system" #dt1 [value]="objRequisicao" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              [(selection)]="selectedRows" (onRowSelect)="checkToolBar()" (onRowUnselect)="checkToolBar()"
              (onHeaderCheckboxToggle)="checkToolBar()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'nome', 'contains')">
                  </th>
                  <th class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_relac'">
                      <span class="text-overflow-dynamic-ellipsis">RELAÇÃO <p-sortIcon [field]="'tipo_relac'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'tipo_relac', 'contains')">
                  </th>
                  <th class="text-left" style="width: 50%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoTipoRequisicao'">
                      <span class="text-overflow-dynamic-ellipsis">TIPO REQUISIÇÃO <p-sortIcon [field]="'descricaoTipoRequisicao'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricaoTipoRequisicao', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                        {{rowData.nome}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.tipo_relac}}">{{rowData.tipo_relac}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricaoTipoRequisicao}}">{{rowData.descricaoTipoRequisicao}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="4">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i>
                      Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<p-dialog header="Detalhe Objeto" [(visible)]="modalObjeto" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true" (onHide)="closeModal()">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="descricaoObjeto">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Relação</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a relação"
        [options]="listRelacaoObjeto" [(ngModel)]="relacaoId"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Tipos de Requisição</label>
      <p-multiSelect class="type-p-dropdown" [filter]="true" placeholder="Selecione os tipos"
        [options]="listTipoRequisicaoObjeto" [(ngModel)]="selectedsTipos"></p-multiSelect>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='closeModal()'>
          <i class="fas fa-undo"> </i> Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="edit ? atualizar() : adicionar()">
          <i class="fas fa-check"></i> Salvar
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>