<div>
  <div class="col-sm-12" style="padding: 0 !important" *ngIf="!loadingConfigPattern">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Refresh Token</label>
        <input type="text" class="form-control" [(ngModel)]="config.refreshToken" disabled/>
      </div>
      <div class="form-group col-sm-6">
        <label>Chave de acesso <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.chaveAcesso" (keyup)="validationConfig()"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Cliente padrão<span class="obrigatorio">*</span></label>
        <div class="participant-container">
          <p> {{ clientCode ? clientCode + ' - ' + clientFantasyName : 'NÃO INFORMADO' }} </p>
          <span class="material-icons" (click)="loadParticipant(1)" matTooltip="Alterar Cliente">edit</span>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label>Fornecedor padrão<span class="obrigatorio">*</span></label>
        <div class="participant-container">
          <p> {{ providerCode ? providerCode + ' - ' + providerFantasyName : 'NÃO INFORMADO' }} </p>
          <span class="material-icons" (click)="loadParticipant(2)" matTooltip="Alterar Cliente">edit</span>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Operação vinculação<span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optOperationAccountingRule" emptyMessage="Nenhuma operação encontrado!" [disabled]="loadingOptOperation" [dropdownIcon]="loadingIconOptOperation ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="operacaoVinculadaId" (onChange)="changeOperationAccountingRule()"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12 position-relative">
        <p-pickList sourceHeader="Operações Disponíveis" targetHeader="Operações Relacionadas"
          [source]="listOFXMemoToRelate" [target]="listOFXMemoRelated" [dragdrop]="true" [disabled]="loadingOFXMemo"
          [responsive]="true" filterBy="memo" [showSourceControls]="false" [showTargetControls]="false">
          <ng-template let-resp pTemplate="item">{{resp.memo}}</ng-template>
        </p-pickList>
        <div *ngIf="loadingOFXMemo" class="layer-loading-ofx-memo">
          <i class="pi pi-spin pi-spinner"></i>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-3 mb-0 d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="saveListOFXMemo()" matTooltip="Salvar vinculação">
        <span class="material-icons loading-icon">save</span>
        <div>Salvar vinculação</div>
      </button>
    </div>
  </div>
  <div class="col-sm-12 loading-content" style="padding: 0 !important" *ngIf="loadingConfigPattern">
    <p><span class="material-icons rotate">hourglass_empty</span> <b>Carregando informações da conta ...</b></p>
  </div>
</div>

<app-modal-participante [(isVisible)]="modalParticipant" (onSelectParticipant)="saveParticipant(participantTypeId, $event)"
  [participanteTipoId]="participantTypeId">
</app-modal-participante>