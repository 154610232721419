<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12"></div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">NDA</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentStateNda">
            <p-table class="table-system" #dt1 [value]="ndaRequisicao" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              [(selection)]="selectedRows" (onRowSelect)="checkToolBar()" (onRowUnselect)="checkToolBar()"
              (onHeaderCheckboxToggle)="checkToolBar()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left" style="width: 100%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'nome', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                        {{rowData.nome}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="2">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i>
                      Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<p-dialog header="Detalhe NDA" [(visible)]="modalNda" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="descricaoNda">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNda=false;edit=false;add=false'>
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="edit ? atualizar() : adicionar()">
          <i class="fas fa-check"></i> Salvar
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>