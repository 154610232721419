<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
  </mat-card-title>
  <div class="row">
    <div class="col">

    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> RELÁTORIOS RH PARA EXPORTAÇÃO EXCEL</h3>
    </div>
  </div>
  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="empresa?.id">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('DEM_RH')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('DEM_RH')"
              matTooltip="Report PADIS - Anexo III RH Interno" matTooltipPosition="above" style="width: 150px;"><span
                class="d-block">Report PADIS - Anexo III RH Interno</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId === 158 || empresaId === 152 || empresaId === 234">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('ORCA_RH')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('ORCA_RH')" matTooltip="Orçamento RH"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Orçamento RH</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId === 158 || empresaId === 152 || empresaId === 234">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('FOLHA_PGTO')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('FOLHA_PGTO')" matTooltip="Folha de pagamento"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Folha de pagamento</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId === 158 || empresaId === 152 || empresaId === 234">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('LEI_DO_BEM')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('LEI_DO_BEM')" matTooltip="Relatório Lei do Bem"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório Lei do Bem</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="empresaId === 158 || empresaId === 152 || empresaId === 234">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('DB_PAYROLL')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('DB_PAYROLL')" matTooltip="Relatório DB Payroll"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório DB Payroll</span></h5>
          </div>
        </div>
        <div class="card">
        <div class="card-body">
          <span class="icon-card" (click)="showDialogRelatorio('AFD')"><i class="fas fa-chart-bar"></i></span>
          <h5 class="card-title" (click)="showDialogRelatorio('AFD')" matTooltip="Relatório de apontamento"
            matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório de apontamento</span></h5>
        </div>
      </div>
        <div class="card" *ngFor="let relat of listaRelatGer">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio(relat)"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio(relat)" matTooltip="{{relat.nome}}"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL DEMONSTRATIVO RH-->
  <p-dialog header="Report PADIS - Anexo III RH Interno" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="statusDemonRh" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA INICIAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtIni">
        </p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>DATA FINAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefFinalDate" yearRange="2000:2030" [(ngModel)]="dtFin">
        </p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Funcionários</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesFuncionarios" [(ngModel)]="funcionariosSelecionados"
          defaultLabel="Selecione funcionário(s)" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" (onChange)="onChangeCcus()">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarDemonstrativoRh()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL RELATORIO LEI DO BEM-->
  <p-dialog header="Lei do Bem" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
    [(visible)]="statusleidobem" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA INICIAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtIni">
        </p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>DATA FINAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefFinalDate" yearRange="2000:2030" [(ngModel)]="dtFin">
        </p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Funcionários</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesFuncionarios" [(ngModel)]="funcionariosSelecionados"
          defaultLabel="Selecione funcionário(s)" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" (onChange)="onChangeCcus()">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <p-footer>
      <button [disabled]="(dtIni === undefined || dtIni === null || dtFin === undefined || dtFin === null )"
        class="btn btn-primary" type="button" (click)="gerarRelatLeiDoBem()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL RELATÓRIO RH-->
  <p-dialog header="Orçamento RH" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
    [(visible)]="statusOrcaRh" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Versão orçamento</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a versão"
          [(ngModel)]="versaoSelecionada" [options]="opcoesVersao">
        </p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Plano gerencial</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o plano gerencial"
          [(ngModel)]="pgelecionado" [options]="opcoesPg">
        </p-dropdown>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarOrcamentoRh()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL FOLHA DE PAGAMENTO -->
  <p-dialog header="Folha de pagamento" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="statusFolhaPgto" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA INICIAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtIni">
        </p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>DATA FINAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefFinalDate" yearRange="2000:2030" [(ngModel)]="dtFin">
        </p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Funcionários*</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesFuncionarios" [(ngModel)]="funcionariosSelecionados"
          defaultLabel="Selecione funcionário(s)" selectedItemsLabel="{0} itens selecionados" appendTo="body">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Centro de Custo*</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesCentroCustos" [(ngModel)]="centroCustoSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" (onChange)="onChangeCcus()"
          appendTo="body">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Projetos</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesProjetos" [(ngModel)]="projetosSelecionados"
          defaultLabel="Selecione" selectedItemsLabel="{0} itens selecionados" appendTo="body"></p-multiSelect>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarFolhaPgto()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL GERENCIAL-->
  <p-dialog header="{{relatGerSelecionado.nome}}" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalRelatorioGer" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="!relatGerSelecionado.is_composta">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>

    <div class="row" *ngIf="relatGerSelecionado.is_composta">
      <div class="form-group col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>DATA REFERÊNCIA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef" [disabled]="datasIndividuais">
            </p-calendar>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <p-checkbox [binary]="true" [(ngModel)]="datasIndividuais"></p-checkbox>
            <label style="padding: 0 5px;">Utilizar datas individuais</label>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="form-row">
          <p-table class="table-system" [value]="dicRelatComposto[relatGerSelecionado.id]"
            emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                  Data de referência</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                  *ngIf="datasIndividuais"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-config>
              <tr>
                <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                <td style="text-align: center">{{config.worksheet_title}}</td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                    [yearNavigator]="true" [defaultDate]="dataRef" yearRange="2000:2030"
                    [(ngModel)]="config['dataRef']">
                  </p-calendar>
                </td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                      (click)="carregarValores(config)"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <p-footer>
      <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
        *ngIf="!relatGerSelecionado.is_composta"><i class="fas fa-laptop"></i> Tela</button>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL DB PAYROLL-->
  <p-dialog header="DB Payroll" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
    [(visible)]="modalDbPayroll" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>VERSÃO</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a versão"
          [(ngModel)]="versaoSelecionada" [options]="opcoesVersao">
        </p-dropdown>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioDbPayroll()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL APONTAMENTO-->
  <p-dialog header="Apontamento" [modal]="true" appendTo="body" width="500" styleClass="modal-initial"
    [(visible)]="modalAfd" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA INICIAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtIni">
        </p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>DATA FINAL</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="DefFinalDate" yearRange="2000:2030" [(ngModel)]="dtFin">
        </p-calendar>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Contrato</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesContrato" [(ngModel)]="tipoCargoSelecionado"
          defaultLabel="Selecione contrato(s)" selectedItemsLabel="{0} itens selecionados" appendTo="body">
        </p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Funcionários</label>
        <p-multiSelect class="type-p-mult" [options]="opcoesFuncionarios" [(ngModel)]="funcionariosSelecionados"
          defaultLabel="Selecione funcionário(s)" selectedItemsLabel="{0} itens selecionados" appendTo="body">
        </p-multiSelect>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioAfd()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>
