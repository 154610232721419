<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="Movimentação Mês">
        <div class="box-content pt-2">


          <!-- <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dtLista.exportCSV()"><i
                class="fas fa-file-excel excel"></i> Exportar para Excel1</button>
          </div>
          <div class="clearfix"></div> -->

          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dtLista.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              </div>
            </div>
          </div>

          <p-table class="table-system" #dtLista [columns]="colslista" [value]="lista" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
            [style]="{'width':'100%'}" (onHeaderCheckboxToggle)="checkAll()" (onRowSelect)="selectCheckbox($event)"  (onRowUnselect)="unselectCheckbox($event)">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 45px;">
                  <span class="check-all p-0">
                    <!-- <mat-checkbox (click)="checkAll()"></mat-checkbox> -->
                    <p-tableHeaderCheckbox ></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'model'">
                    <span class="text-overflow-dynamic-ellipsis">MODELO <p-sortIcon [field]="'model'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'model', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'series'">
                    <span class="text-overflow-dynamic-ellipsis">SÉRIE <p-sortIcon [field]="'series'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'series', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'document'">
                    <span class="text-overflow-dynamic-ellipsis">NUM DOC <p-sortIcon [field]="'document'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'document', 'contains')">
                </th>

                <th class="text-left" style="width: 27%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'key'">
                    <span class="text-overflow-dynamic-ellipsis">CHAVE ELETRÔNICA <p-sortIcon [field]="'key'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'key', 'contains')">
                </th>

                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'emission'">
                    <span class="text-overflow-dynamic-ellipsis">DT EMISSÃO <p-sortIcon [field]="'emission'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'emission', 'contains')">
                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                    <span class="text-overflow-dynamic-ellipsis">CÓDIGO DO ITEM <p-sortIcon [field]="'codigo'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'codigo', 'contains')">
                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtLista.filter($event.target.value, 'status', 'contains')">
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center" style="width: 45px;">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.model}}">{{rowData.model
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.series}}">{{rowData.series
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.document}}">{{rowData.document}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 27%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.key}}">{{rowData.key}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.emission}}">{{rowData.emission
                      }}</span>
                  </span>
                </td>

                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                      }}</span>
                  </span>
                </td>

                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                      }}</span>
                  </span>
                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="8" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>



        </div>
      </mat-tab>
      <mat-tab label="Pendentes">
        <div class="box-content pt-2">


          <!-- <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dtListaAnt.exportCSV()"><i
                class="fas fa-file-excel excel"></i> Exportar para Excel</button>
          </div> -->
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dtListaAnt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <p-table class="table-system" #dtListaAnt [columns]="colslista" [value]="listaAnt" [rows]="rows"
            [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
            [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'model'">
                    <span class="text-overflow-dynamic-ellipsis">MODELO <p-sortIcon [field]="'model'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'model', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'series'">
                    <span class="text-overflow-dynamic-ellipsis">SÉRIE <p-sortIcon [field]="'series'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'series', 'contains')">
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'document'">
                    <span class="text-overflow-dynamic-ellipsis">NUM DOC <p-sortIcon [field]="'document'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'document', 'contains')">
                </th>

                <th class="text-left" style="width: 27%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'key'">
                    <span class="text-overflow-dynamic-ellipsis">CHAVE ELETRÔNICA <p-sortIcon [field]="'key'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'key', 'contains')">
                </th>

                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'emission'">
                    <span class="text-overflow-dynamic-ellipsis">DT EMISSÃO <p-sortIcon [field]="'emission'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'emission', 'contains')">
                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                    <span class="text-overflow-dynamic-ellipsis">CÓDIGO DO ITEM <p-sortIcon [field]="'codigo'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'codigo', 'contains')">
                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtListaAnt.filter($event.target.value, 'status', 'contains')">
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>

                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.model}}">{{rowData.model
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.series}}">{{rowData.series
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.document}}">{{rowData.document}}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 27%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.key}}">{{rowData.key}}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.emission}}">{{rowData.emission
                      }}</span>
                  </span>
                </td>

                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                      }}</span>
                  </span>
                </td>

                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                      }}</span>
                  </span>
                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>



        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

  <p-dialog header="REGISTRO 1100: REGISTRO DE INFORMAÇÕES SOBRE EXPORTAÇÃO" [(visible)]="modal" appendTo="body"
    [closable]="true" [modal]="true" [responsive]="true" styleClass="modal-scroll" [style]="{width: '700px'}">
    <div class="lks-modal-content">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label class="d-inline-block">CHAVE ELETRÔNICA:</label>
          <p class="d-inline-block">{{subChave}}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>tipo de documento</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [(ngModel)]="tipDoc" [options]="listaTipDoc"
            [filter]="true"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-9">
          <label>número da declaração</label>
          <input class="form-control" [(ngModel)]="numDec" />
        </div>
        <div class="form-group col-sm-3">
          <label>data declaração</label>
          <p-calendar class="type-p-calendar" [(ngModel)]="dtDec" appendTo="body" dateFormat="dd/mm/yy"
            [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>preencher com</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [(ngModel)]="exp" [options]="listaExp" [filter]="true">
          </p-dropdown>
        </div>
      </div>
      <div class="form-row align-items-end">
        <div class="form-group col-sm-9">
          <label>nº do registro de exportação</label>
          <input class="form-control" [(ngModel)]="numReg" />
        </div>
        <div class="form-group col-sm-3">
          <label>data exportação</label>
          <p-calendar class="type-p-calendar" [(ngModel)]="dtReg" appendTo="body" dateFormat="dd/mm/yy"
            [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
        </div>
      </div>
      <div class="form-row align-items-end">
        <div class="form-group col-sm-9">
          <label>nº do conhecimento de embarque</label>
          <input class="form-control" [(ngModel)]="numCon" />
        </div>
        <div class="form-group col-sm-3">
          <label>data embarque</label>
          <p-calendar class="type-p-calendar" [(ngModel)]="dtCon" appendTo="body" dateFormat="dd/mm/yy"
            [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>data da averbação</label>
          <p-calendar class="type-p-calendar" [(ngModel)]="dtAverb" appendTo="body" dateFormat="dd/mm/yy"
             [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
        </div>
        <div class="form-group col">
          <label>tipo de conhecimento</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [(ngModel)]="tipCon" [options]="listaTipCon"
            [filter]="true"></p-dropdown>
        </div>
        <div class="form-group col">
          <label>código do país</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [(ngModel)]="codPais" [options]="listaCodPais"
            [filter]="true"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>data expedição</label>
          <p-calendar class="type-p-calendar" [(ngModel)]="dtExpedicao" appendTo="body" dateFormat="dd/mm/yy"
             [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
        </div>
      </div>


    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='cancelar()'><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)='salvar()' *ngIf="!edita"><i class="fas fa-check"></i>
        Salvar</button>
      <button type="button" class="btn btn-primary" (click)='salvar()' *ngIf="edita"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>

</mat-card>

<!-- Modal Alterar Período FEITO-->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div *ngIf="modalPeriodo">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='closeModalPeriodo()'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="getNF(true)"><i class="fas fa-pencil-alt"></i>
          Alterar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>