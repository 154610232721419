<!-- MENSAGENS-->
<div class="ui-g" style="position: fixed; right: 25px; bottom: 30px; z-index: 10">
  <div class="ui-g-12">

  </div>
</div>
<!--FIM MENSAGENS-->

<!--PADDING INICIAL DE TODA -->
<div style="padding: 1.5rem" class="pt-3">
  <div class="row">
    <div class="col p-0">
      <h4 class="font-weight-bold mt-3 mb-3">
        Dashboard
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatarDataExtenso()"></small>
        </div>
      </h4>
    </div>
  </div>

  <!--INICIO DOS DOIS CARDS INICIAIS-->
  <div class="row">
    <!--INICÍO DO CARDDASH1-->
    <div class="col-sm-5 pb-4">
      <div class="shadow-box" style="border-radius: 10px; padding: 4px" [ngClass]="fullscreen ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">DashBoard 1</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash1()"
                (click)="changeStateDash1()">
                <span *ngIf="isCardDash1" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardDash1" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]="currentStateDash1">
          <div style="min-height: 414px">
            <h4 class="title-card" style="font-size: 0.9rem; margin: 0 0 0 -6px">
              Empresas
            </h4>
            <div class="row pb-4">
              <div class="col-sm-9 pl-2 pr-2">
                <div class="row card-primary align-items-center db4-white"
                  style="min-height: 50px !important; padding: 0">
                  <div class="col-sm-12" style="padding: 0">
                    <p-multiSelect class="type-p-mult" [options]="listEmpresas" defaultLabel="Selecione empresas"
                      [filter]="true" (onChange)="
                        atualizarDash2(1);
                        getBigNumbers();
                        getContabilNumbers();
                        buscarInfosChart();
                        buscarInfosWeekly('pend');
                        buscarInfosWeekly('diversos');
                        buscarInfosWeekly('operacaocambio');
                        buscarInfosWeekly('operacaofolha')
                      " [(ngModel)]="empresasSelecionadas" placeholder="{0} empresa(s) selecionada(s)"
                      selectedItemsLabel="{0} empresa(s) selecionada(s)"></p-multiSelect>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 pl-2 pr-2" style="
                  align-items: center;
                  display: flex;
                  justify-content: flex-end;
                ">
                <button class="btn btn-sm btn-secondary" (click)="openModalAnexos()" matTooltip="Baixar Documentos"
                  matTooltipPosition="above" style="font-weight: 600; min-height: 35px; margin-bottom: 5px">
                  <i class="fas fa-paperclip"></i> Documentos
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('saldoInitAnoAnteriores')">
                  <h6 class="title-card text-capitalize">Saldo inicial ano</h6>
                  <span class="percentenge">{{ saldoInitAno }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('cashRealizadoYTDAnteriores')">
                  <h6 class="title-card text-capitalize">Cash realizado YTD</h6>
                  <span class="percentenge"> {{ cashRealizadoYTD }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('saldoFinAnoAnteriores')">
                  <h6 class="title-card text-capitalize">Saldo final ano</h6>
                  <span class="percentenge">{{ saldoFinAno }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('cashRealizadoAnteriores')">
                  <h6 class="title-card text-capitalize">Cash realizado ano</h6>
                  <span class="percentenge">{{ cashRealizado }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('dolarIPGAnteriores')">
                  <h6 class="title-card text-capitalize">Dólar Company</h6>
                  <span class="percentenge">{{
                    dolarIPG | number: "1.4-4":"pt-BR"
                    }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('dolarInitAnoAnteriores')">
                  <h6 class="title-card text-capitalize">Dólar inicial ano</h6>
                  <span class="percentenge">{{
                    dolarInitAno | number: "1.4-4":"pt-BR"
                    }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('dolarHojeAnteriores')">
                  <h6 class="title-card text-capitalize">Dólar hoje</h6>
                  <span class="percentenge">{{
                    dolarHoje | number: "1.4-4":"pt-BR"
                    }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('dolarMedioAnteriores')">
                  <h6 class="title-card text-capitalize">Dólar médio YTD</h6>
                  <span class="percentenge">{{
                    dolarMedio | number: "1.4-4":"pt-BR"
                    }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('rendaRealYTDAnteriores')">
                  <h6 class="title-card text-capitalize">Real. Income YTD</h6>
                  <span class="percentenge">{{ rendaRealYTD }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('custoDespYTDAnteriores')">
                  <h6 class="title-card text-capitalize">
                    Real. cost + exp. YTD
                  </h6>
                  <span class="percentenge">{{ custoDespYTD }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('capexYTDAnteriores')">
                  <h6 class="title-card text-capitalize">CAPEX YTD</h6>
                  <span class="percentenge">{{ capexYTD }}</span>
                </div>
              </div>

              <div class="col-sm-3 pl-2 pr-2">
                <div class="card-outline-primary-colorgrey customCard"
                  (click)="openModalMiniCards('intangivelYTDAnteriores')">
                  <h6 class="title-card text-capitalize">Intang. YTD</h6>
                  <span class="percentenge">{{ intangivelYTD }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--FIM DO CARDDASH1-->

    <!--INICÍO DO CARDDASH2-->
    <div class="col-sm-7 pb-4">
      <div class="shadow-box" style="border-radius: 10px; padding: 4px" [ngClass]="fullDash2 ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">DashBoard 2</h3>
            <div class="actions-card">
              <div class="btn-group btn-group-sm btn-group-custom ml-2 mr-2" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-sm btn-sm btn-outline-primary" [ngClass]="{ active: visaoYtd }"
                  (click)="atualizarDash2(1)">
                  YTD
                </button>
                <button type="button" class="btn btn-sm btn-sm btn-outline-primary" [ngClass]="{ active: visao12m }"
                  (click)="atualizarDash2(2)">
                  ANO
                </button>
                <button type="button" class="btn btn-sm btn-sm btn-outline-primary" [ngClass]="{ active: visaoMes }"
                  (click)="atualizarDash2(3)">
                  12M
                </button>
              </div>
              <button type="button" class="btn btn-xs btn-link btn-full" style="margin-right: -8px"
                matTooltip="Fullscreen" matTooltipPosition="above" (click)="fullScreenDash2()">
                <span *ngIf="!fullDash2" class="material-icons full-icon">fullscreen</span>
                <span *ngIf="fullDash2" class="material-icons full-icon">fullscreen_exit</span>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardDash2()"
                (click)="changeStateDash2()">
                <span *ngIf="isCardDash2" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardDash2" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentStateDash2">
            <div class="row">
              <div class="col pl-2 pr-2">
                <div class="card-outline-primary" [ngClass]="setClassCard(box1Valor)"
                  (click)="showTelaMiniCard('TELA_8_1')">
                  <h6 class="title-card" style="min-height: 0.8rem !important">
                    {{ box1 }}
                  </h6>
                  <div class="row">
                    <div class="col text-center">
                      <span class="value" style="font-size: 1.5rem !important">{{ box1Valor | number: "1.0-0":"pt-BR"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col pl-2 pr-2">
                <div class="card-outline-primary" [ngClass]="setClassCard(box2Valor)"
                  (click)="showTelaMiniCard('TELA_8_2')">
                  <h6 class="title-card" style="min-height: 0.8rem !important">
                    {{ box2 }}
                  </h6>
                  <div class="row">
                    <div class="col text-center">
                      <span class="value" style="font-size: 1.5rem !important">{{ box2Valor | number: "1.0-0":"pt-BR"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col pl-2 pr-2">
                <div class="card-outline-primary" [ngClass]="setClassCard(box3Valor)"
                  (click)="showTelaMiniCard('TELA_8_3')">
                  <h6 class="title-card" style="min-height: 0.8rem !important">
                    {{ box3 }}
                  </h6>
                  <div class="row">
                    <div class="col text-center">
                      <span class="value" style="font-size: 1.5rem !important">{{ box3Valor | number: "1.0-0":"pt-BR"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col pl-2 pr-2">
                <div class="card-outline-primary" [ngClass]="setClassCard(box4Valor)"
                  (click)="showTelaMiniCard('TELA_8_4')">
                  <h6 class="title-card" style="min-height: 0.8rem !important">
                    {{ box4 }}
                  </h6>
                  <div class="row">
                    <div class="col text-center">
                      <span class="value" style="font-size: 1.5rem !important">{{ box4Valor | number: "1.0-0":"pt-BR"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col pl-2 pr-2">
                <div class="card-outline-primary" [ngClass]="setClassCard(box5Valor)"
                  (click)="showTelaMiniCard('TELA_8_5')">
                  <h6 class="title-card" style="min-height: 0.8rem !important">
                    {{ box5 }}
                  </h6>
                  <div class="row">
                    <div class="col text-center">
                      <span class="value" style="font-size: 1.5rem !important">{{ box5Valor | number: "1.0-0":"pt-BR"
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div [ngStyle]="
                    statusEntrada ? { display: 'block' } : { display: 'none' }
                  " [ngClass]="{ 'fade-in': 'statusEntrada' }">
                  <figure class="highcharts-figure mb-0">
                    <highcharts-chart id="chart_12" style="width: 100%; height: 300px; display: block">
                    </highcharts-chart>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--FIM DO CARDDASH2-->
  </div>
  <!--FIM DOS DOIS CARD INICIAIS-->

  <!-- INICIO ESCOLHA DO PERIODO PARA VISUALIZAÇÃO -->
  <div class="row">
    <div class="col">
      <div class="pull-right">
        <div *ngIf="isWeekVision">
          <p-dropdown class="type-p-dropdown drop-filter-periods" (onChange)="
              getFluxoCaixa();
              getFluxoCaixaHistorico();
              buscarInfosWeekly('pend');
              buscarInfosWeekly('diversos');
              buscarInfosWeekly('operacaocambio');
              buscarInfosWeekly('operacaofolha')
            " [options]="listSemanas" [filter]="true" placeholder="Selecione uma semana" [(ngModel)]="weekSelected">
          </p-dropdown>
        </div>
        <div *ngIf="isMonthVision">
          <p-dropdown class="type-p-dropdown drop-filter-periods" (onChange)="
              getFluxoCaixa();
              getFluxoCaixaHistorico();
              buscarInfosWeekly('pend');
              buscarInfosWeekly('diversos');
              buscarInfosWeekly('operacaocambio');
              buscarInfosWeekly('operacaofolha')
            " [options]="listMeses" [filter]="true" placeholder="Selecione um mês" [(ngModel)]="monthSelected">
          </p-dropdown>
        </div>
      </div>
      <div class="pull-right pr-2">
        <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
          <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="filter4('FILTER_1')"
            [ngClass]="{ active: isWeekVision }">
            Semana
          </button>
          <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="filter4('FILTER_2')"
            [ngClass]="{ active: isMonthVision }">
            Mês
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- FIM ESCOLHA DO PERIODO PARA VISUALIZAÇÃO -->

  <!--INICIO CABELAÇHO TABELA APPROVALS BANCÁRIAS-->
  <div class="row">
    <div class="col">
      <ul class="list-inline filter-menu filter-gray mb-2">
        <li class="list-inline-item pl-1"><a>Bank Approvals</a></li>
      </ul>
    </div>
  </div>
  <!--FIM CABELAÇHO TABELA APPROVALS BANCÁRIAS-->

  <!--INICIO CONTEÚDO TABELA APPROVALS BANCÁRIAS-->
  <div class="row">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box" style="border-radius: 13px; padding: 3px" [ngClass]="fullscreen ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card d-inline-block">Transações Pendentes</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above">
                <i class="far fa-file-excel excel"></i>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardTable1()"
                (click)="changeStateTable1()">
                <span *ngIf="isCardTable1" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardTable1" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateTable1>

          <!-- CONVERSÃO P-TABLE -->
          <p-table class="table-system" [columns]="colstransPendentes" #dt2 [value]="transPendentes" [rows]="rows"
            [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            selectionMode="single" [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-left" style="width: 42%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                    <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'operacao', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
                    <span class="text-overflow-dynamic-ellipsis">Nº OPERAÇÕES <p-sortIcon [field]="'quantidade'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'quantidade', 'contains')">
                </th>
                <th class="text-center" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'banco'">
                    <span class="text-overflow-dynamic-ellipsis">BANCO <p-sortIcon [field]="'banco'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'banco', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'status', 'contains')">
                </th>
                <th class="text-right" style="width: 13%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                    <span class="text-overflow-dynamic-ellipsis">VALOR(BRL) <p-sortIcon [field]="'valor'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'valor', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td style="width: 42%;">
                  <span class="text-overflow-dynamic-container"
                    (click)="onWeeklyBankApprovalsSelect(rowData.banco, rowData.operacao, rowData.status, true)">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container"
                    (click)="onWeeklyBankApprovalsSelect(rowData.banco, rowData.operacao, rowData.status, true)">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.quantidade}}">{{rowData.quantidade}}</span>
                  </span>
                </td>
                <td style="width: 20%;" class="text-center">
                  <span class="text-overflow-dynamic-container"
                    (click)="onWeeklyBankApprovalsSelect(rowData.banco, rowData.operacao, rowData.status, true)">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.banco}}">{{rowData.banco}}</span>
                  </span>
                </td>
                <td style="width: 15%;" class="text-center">
                  <div *ngIf="rowData.status === 'PENDENTE'">
                    <span class="text-overflow-dynamic-container"
                      (click)="onWeeklyBankApprovalsSelect(rowData.banco, rowData.operacao, rowData.status, true)">
                      <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: green"></i>
                        {{rowData.status}}</span>
                    </span>
                  </div>
                  <div *ngIf="rowData.status === 'VENCIDO'">
                    <span class="text-overflow-dynamic-container"
                      (click)="onWeeklyBankApprovalsSelect(rowData.banco, rowData.operacao, rowData.status, true)">
                      <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: yellow"></i>
                        {{rowData.status}}</span>
                    </span>
                  </div>
                </td>
                <td style="width: 13%;" class="text-right">
                  <span class="text-overflow-dynamic-container"
                    (click)="onWeeklyBankApprovalsSelect(rowData.banco, rowData.operacao, rowData.status, true)">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                      'pt-BR'}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer">
              <tr>
                <td colspan="4" style="width:87%;">TOTAL:</td>
                <td class="text-right" style="width:13%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                      matTooltip="R$ {{ somaTransPendentes | number : '1.2-2' : 'pt-BR'}}">R$ {{ somaTransPendentes |
                      number : '1.2-2' : 'pt-BR'}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <!--FIM P-TABLE TABELA APPROVALS BANCÁRIAS-->

  <!--INICIO CABELAÇHO TABELA FINANCIAL TRANSACTIONS-->
  <div class="row">
    <div class="col">
      <ul class="list-inline filter-menu filter-gray mb-2">
        <li class="list-inline-item pl-1"><a>Financial Transactions</a></li>
      </ul>
    </div>
  </div>
  <!--FIM CABELAÇHO TABELA FINANCIAL TRANSACTIONS-->

  <!--INICIO CONTEÚDO TABELA FINANCIAL TRANSACTIONS-->
  <div class="row">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box" style="border-radius: 13px; padding: 3px" [ngClass]="fullscreen ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <ul class="list-inline filter-menu filter-card-header mt-0">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: statusSummary }">
                  <a (click)="showTelabank('TELA_1')">Diversos</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusFolha }">
                  <a (click)="showTelabank('TELA_2')">Folha Pagamento</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusOperCambio }">
                  <a (click)="showTelabank('TELA_4')">Operação de Câmbio</a>
                </li>
              </ul>
            </ul>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dtSummary.exportCSV()"
                matTooltip="Exportar Excel" matTooltipPosition="above">
                <i class="far fa-file-excel excel" *ngIf="statusSummary"></i>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="dtFolha.exportCSV()"
                matTooltip="Exportar Excel" matTooltipPosition="above">
                <i class="far fa-file-excel excel" *ngIf="statusFolha"></i>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="dtCambio.exportCSV()"
                matTooltip="Exportar Excel" matTooltipPosition="above">
                <i class="far fa-file-excel excel" *ngIf="statusOperCambio"></i>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardTable2()"
                (click)="changeStateTable2()">
                <span *ngIf="isCardTable2" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardTable2" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateTable2>
          <div [ngStyle]="statusSummary ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusSummary'}">
            <p-table class="table-system" [columns]="colssummary" #dtSummary [value]="summary" [rows]="rows"
              [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                      <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtSummary.filter($event.target.value, 'operacao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'fantasia'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtSummary.filter($event.target.value, 'fantasia', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                      <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtSummary.filter($event.target.value, 'historico', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoControle'">
                      <span class="text-overflow-dynamic-ellipsis">COD CONTROLE <p-sortIcon [field]="'codigoControle'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtSummary.filter($event.target.value, 'codigoControle', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtSummary.filter($event.target.value, 'status', 'contains')">
                  </th>
                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR(BRL) <p-sortIcon [field]="'valor'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtSummary.filter($event.target.value, 'valor', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigoControle}}">{{rowData.codigoControle}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-center">
                    <div *ngIf="rowData.status === 'PENDENTE'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: green"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                    <div *ngIf="rowData.status === 'VENCIDO'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: yellow"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                    <div *ngIf="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: blue"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                  </td>
                  <td style="width: 10%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                        'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="5" style="width:85%;">TOTAL:</td>
                  <td class="text-right" style="width:15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ somaWeekly | number : '1.2-2' : 'pt-BR'}}">R$ {{ somaWeekly | number : '1.2-2'
                        : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div [ngStyle]="statusFolha ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusFolha'}">
            <p-table class="table-system" [columns]="colsoperacaoFolha" #dtFolha [value]="operacaoFolha" [rows]="rows"
              [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                      <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtFolha.filter($event.target.value, 'operacao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'fantasia'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtFolha.filter($event.target.value, 'fantasia', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                      <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtFolha.filter($event.target.value, 'historico', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoControle'">
                      <span class="text-overflow-dynamic-ellipsis">COD CONTROLE <p-sortIcon [field]="'codigoControle'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtFolha.filter($event.target.value, 'codigoControle', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtFolha.filter($event.target.value, 'status', 'contains')">
                  </th>
                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR(BRL) <p-sortIcon [field]="'valor'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtFolha.filter($event.target.value, 'valor', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigoControle}}">{{rowData.codigoControle}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-center">
                    <div *ngIf="rowData.status === 'PENDENTE'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: green"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                    <div *ngIf="rowData.status === 'VENCIDO'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: yellow"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                    <div *ngIf="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: blue"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                  </td>
                  <td style="width: 10%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                        'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="5" style="width:91%;">TOTAL:</td>
                  <td class="text-right" style="width:9%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ somaWeekly | number : '1.2-2' : 'pt-BR'}}">R$ {{ somaWeekly | number : '1.2-2'
                        : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div [ngStyle]="statusOperCambio ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusOperCambio'}">
            <p-table class="table-system" [columns]="colsoperacaoCambio" #dtCambio [value]="operacaoCambio"
              [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                      <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtCambio.filter($event.target.value, 'operacao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'fantasia'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtCambio.filter($event.target.value, 'fantasia', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                      <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtCambio.filter($event.target.value, 'historico', 'contains')">
                  </th>
                  <th class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoControle'">
                      <span class="text-overflow-dynamic-ellipsis">COD CONTROLE <p-sortIcon [field]="'codigoControle'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtCambio.filter($event.target.value, 'codigoControle', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtCambio.filter($event.target.value, 'status', 'contains')">
                  </th>
                  <th class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR(BRL) <p-sortIcon [field]="'valor'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dtCambio.filter($event.target.value, 'valor', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigoControle}}">{{rowData.codigoControle}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-center">
                    <div *ngIf="rowData.status === 'PENDENTE'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: green"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                    <div *ngIf="rowData.status === 'VENCIDO'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: yellow"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                    <div *ngIf="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL'">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"><i class="fas fa-circle" style="color: blue"></i>
                          {{rowData.status}}</span>
                      </span>
                    </div>
                  </td>
                  <td style="width: 10%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                        'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="5" style="width:85%;">TOTAL:</td>
                  <td class="text-right" style="width:15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ somaWeekly | number : '1.2-2' : 'pt-BR'}}">R$ {{ somaWeekly | number : '1.2-2'
                        : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="row">
            <div class="col-sm-12 pt-3">
              <figure class="highcharts-figure mb-0">
                <highcharts-chart id="chart_17" style="width: 100%; height: 300px; display: block">
                </highcharts-chart>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--FIM CONTEÚDO TABELA FINANCIAL TRANSACTIONS-->

  <!--INICIO CABELAÇHO TABELA CASHVIEW-->
  <div class="row">
    <div class="col">
      <ul class="list-inline filter-menu filter-gray mb-2">
        <li class="list-inline-item pl-1"><a>Cashview</a></li>
      </ul>
    </div>
  </div>
  <!--FIM CABELAÇHO TABELA CASHVIEW-->

  <!--INICIO CONTEÚDO TABELA CASHVIEW-->
  <div class="row">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box" style="border-radius: 13px; padding: 3px" [ngClass]="fullscreen ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <ul class="list-inline filter-menu filter-card-header mt-0">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: statusSaldoprevisto }">
                  <a (click)="showTelacashview('TELA_5')">Saldo Previsto (EOW)</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusFluxoHistorico }">
                  <a (click)="showTelacashview('TELA_7')">Histórico gravado</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusVariacaoanterior }">
                  <a (click)="showTelacashview('TELA_6')">Variação em Relação a Semana Anterior</a>
                </li>
              </ul>
            </ul>
            <div class="actions-card">
              <button type="button" (click)="checkFluxoHistorico()" class="btn btn-xs btn-link"
                matTooltip="Gravar Saldo" matTooltipPosition="above">
                <span class="material-icons" style="font-size: 22px">save</span>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="exportSaldosExcel()"
                matTooltip="Exportar Excel" matTooltipPosition="above">
                <i class="far fa-file-excel excel"></i>
              </button>
              <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardCashView()"
                (click)="changeStateCashView()">
                <span *ngIf="isCardCashView" class="material-icons" matTooltip="Ocultar Card"
                  matTooltipPosition="above">expand_less</span>
                <span *ngIf="!isCardCashView" class="material-icons" matTooltip="Ver Card"
                  matTooltipPosition="above">expand_more</span>
              </button>
            </div>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentStateCashView>
          <div [ngStyle]="statusSaldoprevisto ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusSaldoprevisto'}">
            <p class="text-info"><strong>Saldo Previsto após os pagamentos e recebimentos desta semana Idea!:
                {{ saldoFinalPeriodAtu | number : '1.2-2' : 'pt-BR' }}</strong></p>
            <p-table class="table-system" #dt [value]="fluxoCaixaAtual" [rows]="rows" [paginator]="true"
              (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" sortField="conta">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Pré-Pgtos Previsto</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Mov. Prev(-)</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Mov. Prev(+)</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Final Pós-Pgto</span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th class="text-center" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">{{ periodoInitAtu }}</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">{{ periodoFinAtu }}</span>
                    </span>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 35%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.conta}}">{{rowData.conta}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.pre_pgtos | number : '1.2-2' : 'pt-BR'}}">{{rowData.pre_pgtos | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="color:darkred; cursor: pointer;"
                        (click)="onFlowCashSelect(rowData.conta, 'CP', true)"
                        matTooltip="{{rowData.mov_prev_negativo | number : '1.2-2' : 'pt-BR'}}">{{rowData.mov_prev_negativo
                        | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="color:darkblue; cursor: pointer;"
                        (click)="onFlowCashSelect(rowData.conta, 'CR', true)"
                        matTooltip="{{rowData.mov_prev_positivo | number : '1.2-2' : 'pt-BR'}}">{{rowData.mov_prev_positivo
                        | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.final_pos_pgto | number : '1.2-2' : 'pt-BR'}}">{{rowData.final_pos_pgto |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="1" style="width:35%;">TOTAL:</td>
                  <td class="text-right" style="width:20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ saldoInitPeriodAtu | number : '1.2-2' : 'pt-BR'}}">R$ {{ saldoInitPeriodAtu |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td colspan="2" style="width:40%;"></td>
                  <td class="text-right" style="width:20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ saldoFinalPeriodAtu | number : '1.2-2' : 'pt-BR'}}">R$ {{ saldoFinalPeriodAtu
                        | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div [ngStyle]="
              statusFluxoHistorico ? { display: 'block' } : { display: 'none' }
            " [ngClass]="{ 'fade-in': 'statusFluxoHistorico' }">
            <div *ngIf="dt_historico != null || dt_historico != undefined">
              <p class="text-info">
                <strong>Data histórico:
                  {{ dt_historico | date: "short":"-0300":"pt-BR" }} por
                  {{ usr_historico }}</strong>
              </p>
            </div>
            <p-table class="table-system" #dt [value]="fluxoHistorico" [rows]="rows" [paginator]="true"
              (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" sortField="conta">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Pré-Pgtos Previsto</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Mov. Prev(-)</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Mov. Prev(+)</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Final Pós-Pgto</span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th class="text-center" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">{{ periodoInitAtu }}</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">{{ periodoFinAtu }}</span>
                    </span>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 35%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.conta}}">{{rowData.conta}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.pre_pgtos | number : '1.2-2' : 'pt-BR'}}">{{rowData.saldo_init | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="color:darkred; cursor: pointer;"
                        (click)="onFlowCashHistorySelect(rowData.conta, 'CP')"
                        matTooltip="{{rowData.mov_prev_negativo | number : '1.2-2' : 'pt-BR'}}">{{rowData.mov_neg |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="color:darkblue; cursor: pointer;"
                        (click)="onFlowCashHistorySelect(rowData.conta, 'CR')"
                        matTooltip="{{rowData.mov_prev_positivo | number : '1.2-2' : 'pt-BR'}}">{{rowData.mov_pos |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 20%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.final_pos_pgto | number : '1.2-2' : 'pt-BR'}}">{{rowData.saldo_final |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="1" style="width:35%;">TOTAL:</td>
                  <td class="text-right" style="width:20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ saldoInitPeriodHisto | number : '1.2-2' : 'pt-BR'}}">R$ {{
                        saldoInitPeriodHisto | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td colspan="2" style="width:40%;"></td>
                  <td class="text-right" style="width:20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ saldoFinalPeriodHisto | number : '1.2-2' : 'pt-BR'}}">R$ {{
                        saldoFinalPeriodHisto | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div [ngStyle]="statusVariacaoanterior ? {'display': 'block'} : {'display': 'none'}"
            [ngClass]="{'fade-in': 'statusVariacaoanterior'}">
            <p-table class="table-system" #dt [value]="fluxoCaixaDiff" [rows]="rows" [paginator]="true"
              (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" sortField="conta">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Pré-Pgtos Previsto</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Mov. Prev(-)</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Mov. Prev(+)</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Final Pós-Pgto</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Comentários</span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th class="text-center" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">{{ periodoInitAtu }}</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">{{ periodoFinAtu }}</span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 25%;" class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.conta}}">{{rowData.conta}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.pre_pgtos | number : '1.2-2' : 'pt-BR'}}">{{rowData.pre_pgtos | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="color:darkred; cursor: pointer;"
                        (click)="onFlowCashDiffSelect(rowData.conta, 'CP')"
                        matTooltip="{{rowData.mov_prev_negativo | number : '1.2-2' : 'pt-BR'}}">{{rowData.mov_prev_negativo
                        | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="color:darkblue; cursor: pointer;"
                        (click)="onFlowCashDiffSelect(rowData.conta, 'CR')"
                        matTooltip="{{rowData.mov_prev_positivo | number : '1.2-2' : 'pt-BR'}}">{{rowData.mov_prev_positivo
                        | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.final_pos_pgto | number : '1.2-2' : 'pt-BR'}}">{{rowData.final_pos_pgto |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td style="width: 15%;" class="text-center">
                    <span *ngIf="rowData.comentarios === ''" class="add-text text-left overflow-ellipse"
                      style="max-width: 100%;"><button type="button" class="btn btn-xs btn-link"
                        style="padding: 0 !important; font-weight: bold; text-transform: none; font-size: 11px !important; line-height: 0;"
                        (click)="onCommentSelect(rowData)">Adicionar comentário</button></span>
                    <span class="overflow-ellipse pull-left" style="max-width: 90%;"
                      [matTooltip]="getStringFromHtml(rowData.comentarios)"
                      [innerHtml]="rowData.comentarios | safeHtml"></span>
                    <span *ngIf="rowData.comentarios !=''" class="edit-line pull-left" style="max-width: 10%;"
                      matTooltip="Editar"><i class="fas fa-pencil-alt" style="padding-left: 3px;"
                        (click)="onCommentSelect(rowData)"></i></span>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="1" style="width:25%;">TOTAL:</td>
                  <td class="text-right" style="width:15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ saldoInitPeriodDiff | number : '1.2-2' : 'pt-BR'}}">R$ {{ saldoInitPeriodDiff
                        | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td colspan="2" style="width:30%;"></td>
                  <td class="text-right" style="width:15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                        matTooltip="R$ {{ saldoFinalPeriodDiff | number : '1.2-2' : 'pt-BR'}}">R$ {{
                        saldoFinalPeriodDiff | number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td colspan="1" style="width:15%;"></td>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--FIM CONTEÚDO TABELA CASHVIEW-->

</div>
<!--FIM PADDING INICIAL DE TODA A TELA-->

<!--INICIO MODAL CUSTOM-->
<p-dialog [(visible)]="modalMiniCards" width="600" [modal]="true" styleClass="modal-scroll modal-custom"
  [responsive]="true" [contentStyle]="{ minHeight: '450px' }" appendTo="body" [closable]="true" [draggable]="false">
  <p-header class="clearfix" style="width: 100%">
    <div class="row">
      <div class="col">
        <div class="pull-right">
          <!-- <ul class="list-inline filter-icons">
            <li
              class="list-inline-item active"
              [ngClass]="{ active: statusLink1 }"
            >
              <a (click)="showTelaModal('TELA_1')">
                <span class="material-icons"> view_agenda </span>
              </a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: statusLink2 }">
              <a (click)="showTelaModal('TELA_2')">
                <span class="material-icons"> bar_chart </span>
              </a>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
  </p-header>

  <!-- <div
    [ngStyle]="statusLink1 ? { display: 'block' } : { display: 'none' }"
    [ngClass]="{ 'fade-in': 'statusLink1' }"
  > -->
  <div class="pb-3">
    <div class="row">
      <div class="col">
        <h3 class="sub-title mt-0"><i class="fas fa-bars"></i> Ativos</h3>
      </div>
    </div>
    <p-table class="table-system" #dt [value]="miniCardsValues[selectedMiniCard]" [rows]="rows" [paginator]="false"
      (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
      [style]="{'width':'100%'}" selectionMode="single" [scrollable]="true" scrollHeight="350px">

      <ng-template pTemplate="header">
        <tr>
          <th class="text-left" style="width: 22.5%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'ano'">
              <span class="text-overflow-dynamic-ellipsis">ANO <p-sortIcon [field]="'ano'" verflow></p-sortIcon>
              </span>
            </span>
          </th>
          <th class="text-right" style="width: 22.5%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'value'">
              <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'value'"></p-sortIcon></span>
            </span>
          </th>
          <th class="text-center" style="width: 22.5%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'variacao'">
              <span class="text-overflow-dynamic-ellipsis">VARIAÇÃO <p-sortIcon [field]="'variacao'"></p-sortIcon>
              </span>
            </span>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td style="width: 22.5%;" class="text-left">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ano}}">{{rowData.ano}}</span>
            </span>
          </td>
          <td style="width: 22.5%;" class="text-right">
            <span class="text-overflow-dynamic-container" matTooltip="{{rowData.value | number : '1.2-2' : 'pt-BR'}}">
              <span class="text-overflow-dynamic-ellipsis">{{rowData.value | number : '1.2-2' : 'pt-BR'}}</span>
            </span>
          </td>
          <td style="width: 22.5%;" class="text-center">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.variacao | number: '1.0-0'}}">
                {{rowData.variacao | number: '1.0-0'}} {{rowData.variacao !== null? '%' : ''}}
                <span *ngIf="rowData.variacao < 0" class="material-icons"
                  style="color: darkred; line-height: 0; font-size: 14px; vertical-align: middle;">
                  arrow_downward
                </span>
                <span *ngIf="rowData.variacao > 0" class="material-icons"
                  style="color: rgb(12, 139, 0); line-height: 0; font-size: 14px; vertical-align: middle;">
                  arrow_upward
                </span>
              </span>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div>
    <figure class="highcharts-figure mb-0">
      <highcharts-chart id="chart_18" style="width: 100%; height: 420px; display: block">
      </highcharts-chart>
    </figure>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalMiniCards = false">
      <i class="fas fa-undo"></i> Fechar
    </button>
  </p-footer>
</p-dialog>
<!--FIM MODAL CUSTOM-->

<!--INICIO MODAL ANEXO-->
<p-dialog header="Adicionar Anexo" [(visible)]="modalAnexos" width="600" [modal]="true"
  styleClass="modal-scroll modal-custom" [responsive]="true" appendTo="body" [closable]="true" [draggable]="false">
  <!-- INICIO ESCOLHA DO PERIODO PARA VISUALIZAÇÃO -->
  <div class="row">
    <div class="col">
      <div class="pull-right">
        <div *ngIf="isWeekForPrint" class="pull-left">
          <p-dropdown class="type-p-dropdown drop-filter-periods" (onChange)="
              getFluxoCaixa();
              getFluxoCaixaHistorico();
              buscarInfosWeekly('pend');
              buscarInfosWeekly('diversos');
              buscarInfosWeekly('operacaocambio');
              buscarInfosWeekly('operacaofolha')
            " [options]="listSemanas" [filter]="true" placeholder="Selecione uma semana" [(ngModel)]="weekSelected">
          </p-dropdown>
        </div>
        <div *ngIf="isMonthForPrint" class="pull-left">
          <p-dropdown class="type-p-dropdown drop-filter-periods" (onChange)="
              getFluxoCaixa();
              getFluxoCaixaHistorico();
              buscarInfosWeekly('pend');
              buscarInfosWeekly('diversos');
              buscarInfosWeekly('operacaocambio');
              buscarInfosWeekly('operacaofolha')
            " [options]="listMeses" [filter]="true" placeholder="Selecione um mês" [(ngModel)]="monthSelected">
          </p-dropdown>
        </div>
      </div>
      <div class="pull-left pr-2">
        <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
          <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="filterForPrint('FILTER_1')"
            [ngClass]="{ active: isWeekForPrint }">
            Semana
          </button>
          <button type="button" class="btn btn-sm btn-sm btn-outline-primary" (click)="filterForPrint('FILTER_2')"
            [ngClass]="{ active: isMonthForPrint }">
            Mês
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- FIM ESCOLHA DO PERIODO PARA VISUALIZAÇÃO -->
  <div class="row" style="
      align-items: flex-end !important;
      justify-content: center !important;
      height: 240px;
    "></div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAnexos = false">
      <i class="fas fa-undo"></i> Fechar
    </button>
    <button type="button" class="btn btn-primary" matTooltip="Imprimir Arquivo" (click)="imprimirAnexo()">
      <i class="fas fa-print"></i> Imprimir
    </button>
  </p-footer>
</p-dialog>
<!--FIM MODAL ANEXO-->

<!--MODAL DETALHE TABELA FLUXO CAIXA DETALHE-->
<p-dialog header="Detalhe fluxo de caixa" [(visible)]="detalheFluxo" [style]="{width: '1200px'}" [modal]="true"
  styleClass="modal-scroll modal-custom" [responsive]="true" [contentStyle]="{minHeight: '450px'}" appendTo="body"
  [closable]="true" [draggable]="false">
  <div class="row">
    <div class="col-sm-12">
      <p-table class="table-system" #dt4 [value]="movTable" [rows]="rows" [paginator]="false" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
        (onFilter)="onFilterDetalhePendentes($event)" [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''"
        [scrollable]="movTable.length >= 15 ? true : false" scrollHeight="320px">

        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vcto'">
                <span class="text-overflow-dynamic-ellipsis">VENCTO/BAIXA <p-sortIcon [field]="'dt_vcto'" verflow>
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'dt_vcto', 'contains')">
            </th>
            <th class="text-center" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_previsao'">
                <span class="text-overflow-dynamic-ellipsis">PREVISÃO <p-sortIcon [field]="'dt_previsao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'dt_previsao', 'contains')">
            </th>
            <th class="text-center" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_emissao'">
                <span class="text-overflow-dynamic-ellipsis">EMISSÃO <p-sortIcon [field]="'dt_emissao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'dt_emissao', 'contains')">
            </th>
            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'operacao', 'contains')">
            </th>
            <th class="text-left" style="width: 9%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'contaBancaria'">
                <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'contaBancaria'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'contaBancaria', 'contains')">
            </th>
            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'historico', 'contains')">
            </th>
            <th class="text-left" style="width: 6%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nf'">
                <span class="text-overflow-dynamic-ellipsis">DOC <p-sortIcon [field]="'nf'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'nf', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                <span class="text-overflow-dynamic-ellipsis">PARC <p-sortIcon [field]="'parcela'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'parcela', 'contains')">
            </th>
            <th class="text-right" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'valor', 'contains')">
            </th>
            <th class="text-center" style="width: 7.5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt4.filter($event.target.value, 'status', 'contains')">
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td style="width: 7%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_vcto | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_vcto | date:'dd/MM/yyyy' :
                  '+000'}}</span>
              </span>
            </td>
            <td style="width: 7%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_previsao | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_previsao |
                  date:'dd/MM/yyyy' : '+000'}}</span>
              </span>
            </td>
            <td style="width: 7%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_emissao | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_emissao |
                  date:'dd/MM/yyyy' : '+000'}}</span>
              </span>
            </td>
            <td style="width: 12%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
              </span>
            </td>
            <td style="width: 10%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
              </span>
            </td>
            <td style="width: 9%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.contaBancaria}}">{{rowData.contaBancaria}}</span>
              </span>
            </td>
            <td style="width: 12%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
              </span>
            </td>
            <td style="width: 6%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nf}}">{{rowData.nf}}</span>
              </span>
            </td>
            <td style="width: 5%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.parcela}}">{{rowData.parcela}}</span>
              </span>
            </td>
            <td style="width: 7%;" class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                  'pt-BR'}}</span>
              </span>
            </td>
            <td style="width: 7.5%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="11" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="detalheFluxo = false">
      <i class="fas fa-undo"></i> Fechar
    </button>
  </p-footer>
</p-dialog>
<!-- FIM MODAL FLUXO DE CAIXA DETALHE-->

<!-- INICIO MODAL COMENTARIOS -->
<p-dialog header="Comentários" [(visible)]="modalCommentarios" width="600" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body" [closable]="true">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title">
        <i class="fas fa-bars"></i> Comentário
        <button *ngIf="edicaoComentario" type="button" class="btn btn-xs btn-info pull-right" style="margin-top: -3px"
          matTooltip="Adicionar comentário" matTooltipPosition="left" (click)="toggleAddComentario()">
          <i class="fas fa-plus"></i> Adicionar
        </button>
      </h3>
      <div *ngIf="criacaoComentario">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <textarea [froalaEditor]="optionsDescFroola" [(ngModel)]="comentario" id="textAreaTwoSize"
              autofocus></textarea>
            <button type="button" class="btn btn-xs btn-primary" *ngIf="criacaoComentario" (click)="criarComentario()">
              <i class="fas fa-save"></i> Gravar
            </button>
            <button type="button" class="btn btn-xs btn-primary" *ngIf="edicaoComentario" (click)="editarComentario()">
              <i class="fas fa-check"></i> Salvar
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <ng-container>
            <div class="height-activity">
              <div *ngFor="let c of rowComments">
                <div class="spaced-activity">
                  <div class="by-date">
                    <span class="user"><i class="far fa-user"></i> {{ c.user_id }}</span><span class="date-time"><i
                        class="far fa-calendar-alt"></i>
                      {{ c.dt_historico | date: "dd/MM/yyy H:mm" }}
                    </span>
                    <div class="actions">
                      <span class="edit" matTooltip="Editar comentário" matTooltipPosition="above"><i
                          class="fas fa-pencil-alt" (click)="toggleEditComentario(c)"></i></span>
                      <span class="delete" matTooltip="Excluir comentário" matTooltipPosition="above"><i
                          class="far fa-trash-alt" (click)="deleteComment(c)"></i></span>
                    </div>
                  </div>
                  <div class="box-activity" style="min-height: 74px">
                    <p class="description" [innerHtml]="c.descricao | safeHtml"></p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalCommentarios = false; calcFluxoDiff()">
      <i class="fas fa-undo"></i> Fechar
    </button>
  </p-footer>
</p-dialog>
<!-- FIM MODAL COMENTARIOS -->

<!--MODAL DETALHE TABELA BANK APPROVALS -->
<p-dialog header="Detalhe transações pendentes" [(visible)]="detalheBankApprovals" [style]="{width: '1200px'}"
  [modal]="true" styleClass="modal-scroll modal-custom" [responsive]="true" [contentStyle]="{minHeight: '450px'}"
  appendTo="body" [closable]="true" [draggable]="false">
  <div class="row">
    <div class="col-sm-12">
      <p-table class="table-system" #dt3 [value]="movTable" [rows]="rows" [paginator]="false" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
        (onFilter)="onFilterDetalhePendentes($event)" [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''"
        [scrollable]="movTable.length >= 15 ? true : false" scrollHeight="320px">

        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vcto'">
                <span class="text-overflow-dynamic-ellipsis">VENCTO/BAIXA <p-sortIcon [field]="'dt_vcto'" verflow>
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'dt_vcto', 'contains')">
            </th>
            <th class="text-center" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_previsao'">
                <span class="text-overflow-dynamic-ellipsis">PREVISÃO <p-sortIcon [field]="'dt_previsao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'dt_previsao', 'contains')">
            </th>
            <th class="text-center" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_emissao'">
                <span class="text-overflow-dynamic-ellipsis">EMISSÃO <p-sortIcon [field]="'dt_emissao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'dt_emissao', 'contains')">
            </th>
            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'operacao', 'contains')">
            </th>
            <th class="text-left" style="width: 9%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'contaBancaria'">
                <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'contaBancaria'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'contaBancaria', 'contains')">
            </th>
            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                <span class="text-overflow-dynamic-ellipsis">HISTÓRICO <p-sortIcon [field]="'historico'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'historico', 'contains')">
            </th>
            <th class="text-left" style="width: 6%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nf'">
                <span class="text-overflow-dynamic-ellipsis">DOC <p-sortIcon [field]="'nf'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'nf', 'contains')">
            </th>
            <th class="text-center" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                <span class="text-overflow-dynamic-ellipsis">PARC <p-sortIcon [field]="'parcela'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'parcela', 'contains')">
            </th>
            <th class="text-right" style="width: 7%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'valor', 'contains')">
            </th>
            <th class="text-center" style="width: 7.5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'status', 'contains')">
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td style="width: 7%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_vcto | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_vcto | date:'dd/MM/yyyy' :
                  '+000'}}</span>
              </span>
            </td>
            <td style="width: 7%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_previsao | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_previsao |
                  date:'dd/MM/yyyy' : '+000'}}</span>
              </span>
            </td>
            <td style="width: 7%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dt_emissao | date:'dd/MM/yyyy' : '+000'}}">{{rowData.dt_emissao |
                  date:'dd/MM/yyyy' : '+000'}}</span>
              </span>
            </td>
            <td style="width: 12%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
              </span>
            </td>
            <td style="width: 10%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.operacao}}">{{rowData.operacao}}</span>
              </span>
            </td>
            <td style="width: 9%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.contaBancaria}}">{{rowData.contaBancaria}}</span>
              </span>
            </td>
            <td style="width: 12%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
              </span>
            </td>
            <td style="width: 6%;" class="text-left">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nf}}">{{rowData.nf}}</span>
              </span>
            </td>
            <td style="width: 5%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.parcela}}">{{rowData.parcela}}</span>
              </span>
            </td>
            <td style="width: 7%;" class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.valor | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor | number : '1.2-2' :
                  'pt-BR'}}</span>
              </span>
            </td>
            <td style="width: 7.5%;" class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="11" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='detalheBankApprovals = false;'><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>
<!-- FIM MODAL FLUXO DE CAIXA DETALHE-->