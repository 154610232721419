<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content class="mat-card-linnks">
    <div class="tabela-data-table">

      <h3>Evoluçao Ativo/Passivo</h3>

      <p-table class="table-system" #dt2 [columns]="scrollableCols" [frozenColumns]="frozenCols" [value]="evoAtivo"
        [scrollable]="true" scrollHeight="300px" frozenWidth="400px" [rows]="10" [paginator]="true"
        selectionMode="single" [pageLinks]="5" [rowsPerPageOptions]="[5,10,15,20,50]">
        <ng-template pTemplate="frozencolgroup" let-columns>
          <colgroup>
            <col style="width:400px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">

          </colgroup>
        </ng-template>
        <ng-template pTemplate="frozenheader" let-columns>
          <tr>
            <th class="text-left" style="width: 400px;" frozen="true">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Código </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'codCta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">JAN </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'jan', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">FEV </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'fev', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">MAR </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'mar', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">ABR </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'abr', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">MAI </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'mai', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">JUN </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'jun', 'contains')">
            </th>


            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">JUL </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'jul', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">AGO </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'ago', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">SET </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'set', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">OUT </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'out', 'contains')">
            </th>


            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">NOV </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nov', 'contains')">
            </th>


            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">DEZ </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dez', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="frozenbody" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">

            <td class="text-left" style="width: 400px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codCta  }}">{{rowData.codCta
                  }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,1)"
                  matTooltip="{{rowData.jan  | money}}">{{rowData.jan | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,2)"
                  matTooltip="{{rowData.fev | money}}">{{rowData.fev | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,3)"
                  matTooltip="{{rowData.mar | money}}">{{rowData.mar | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,4)"
                  matTooltip="{{rowData.abr | money}}">{{rowData.abr | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,5)"
                  matTooltip="{{rowData.mai | money}}">{{rowData.mai | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,6)"
                  matTooltip="{{rowData.jun | money}}">{{rowData.jun | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,7)"
                  matTooltip="{{rowData.jul | money}}">{{rowData.jul | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,8)"
                  matTooltip="{{rowData.ago | money}}">{{rowData.ago | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,9)"
                  matTooltip="{{rowData.set | money}}">{{rowData.set | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,10)"
                  matTooltip="{{rowData.out | money}}">{{rowData.out | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,11)"
                  matTooltip="{{rowData.nov | money}}">{{rowData.nov | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,12)"
                  matTooltip="{{rowData.dez | money}}">{{rowData.dez | money}}</span>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,1)"
                  matTooltip="{{rowData.jan  | money}}">{{rowData.jan | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,2)"
                  matTooltip="{{rowData.fev | money}}">{{rowData.fev | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,3)"
                  matTooltip="{{rowData.mar | money}}">{{rowData.mar | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,4)"
                  matTooltip="{{rowData.abr | money}}">{{rowData.abr | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,5)"
                  matTooltip="{{rowData.mai | money}}">{{rowData.mai | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,6)"
                  matTooltip="{{rowData.jun | money}}">{{rowData.jun | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,7)"
                  matTooltip="{{rowData.jul | money}}">{{rowData.jul | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,8)"
                  matTooltip="{{rowData.ago | money}}">{{rowData.ago | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,9)"
                  matTooltip="{{rowData.set | money}}">{{rowData.set | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,10)"
                  matTooltip="{{rowData.out | money}}">{{rowData.out | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,11)"
                  matTooltip="{{rowData.nov | money}}">{{rowData.nov | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectA(rowData,12)"
                  matTooltip="{{rowData.dez | money}}">{{rowData.dez | money}}</span>
              </span>
            </td>

          </tr>
        </ng-template>
      </p-table>



      <h3>Evoluçao Resultado</h3>

      <p-table class="table-system" #dtevoResultado [columns]="scrollableCols" [frozenColumns]="frozenCols"
        [value]="evoResultado" [scrollable]="true" scrollHeight="300px" frozenWidth="400px" [rows]="10"
        [paginator]="true" selectionMode="single" [pageLinks]="5" [rowsPerPageOptions]="[5,10,15,20,50]">
        <ng-template pTemplate="frozencolgroup" let-columns>
          <colgroup>
            <col style="width:400px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">
            <col style="width:90px">

          </colgroup>
        </ng-template>
        <ng-template pTemplate="frozenheader" let-columns>
          <tr>
            <th class="text-left" style="width: 400px;" frozen="true">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Código </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'codCta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">JAN </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'jan', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">FEV </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'fev', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">MAR </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'mar', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">ABR </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'abr', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">MAI </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'mai', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">JUN </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'jun', 'contains')">
            </th>


            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">JUL </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'jul', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">AGO </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'ago', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">SET </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'set', 'contains')">
            </th>

            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">OUT </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'out', 'contains')">
            </th>


            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">NOV </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'nov', 'contains')">
            </th>


            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">DEZ </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtevoResultado.filter($event.target.value, 'dez', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="frozenbody" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">

            <td class="text-left" style="width: 400px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codCta  }}">{{rowData.codCta
                  }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,1)"
                  matTooltip="{{rowData.jan  | money}}">{{rowData.jan | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,2)"
                  matTooltip="{{rowData.fev | money}}">{{rowData.fev | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,3)"
                  matTooltip="{{rowData.mar | money}}">{{rowData.mar | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,4)"
                  matTooltip="{{rowData.abr | money}}">{{rowData.abr | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,5)"
                  matTooltip="{{rowData.mai | money}}">{{rowData.mai | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,6)"
                  matTooltip="{{rowData.jun | money}}">{{rowData.jun | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,7)"
                  matTooltip="{{rowData.jul | money}}">{{rowData.jul | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,8)"
                  matTooltip="{{rowData.ago | money}}">{{rowData.ago | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,9)"
                  matTooltip="{{rowData.set | money}}">{{rowData.set | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,10)"
                  matTooltip="{{rowData.out | money}}">{{rowData.out | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,11)"
                  matTooltip="{{rowData.nov | money}}">{{rowData.nov | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,12)"
                  matTooltip="{{rowData.dez | money}}">{{rowData.dez | money}}</span>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,1)"
                  matTooltip="{{rowData.jan  | money}}">{{rowData.jan | money }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,2)"
                  matTooltip="{{rowData.fev | money}}">{{rowData.fev | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,3)"
                  matTooltip="{{rowData.mar | money}}">{{rowData.mar | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,4)"
                  matTooltip="{{rowData.abr | money}}">{{rowData.abr | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,5)"
                  matTooltip="{{rowData.mai | money}}">{{rowData.mai | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,6)"
                  matTooltip="{{rowData.jun | money}}">{{rowData.jun | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,7)"
                  matTooltip="{{rowData.jul | money}}">{{rowData.jul | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,8)"
                  matTooltip="{{rowData.ago | money}}">{{rowData.ago | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,9)"
                  matTooltip="{{rowData.set | money}}">{{rowData.set | money}}</span>
              </span>
            </td>

            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,10)"
                  matTooltip="{{rowData.out | money}}">{{rowData.out | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,11)"
                  matTooltip="{{rowData.nov | money}}">{{rowData.nov | money}}</span>
              </span>
            </td>


            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectR(rowData,12)"
                  matTooltip="{{rowData.dez | money}}">{{rowData.dez | money}}</span>
              </span>
            </td>

          </tr>
        </ng-template>
      </p-table>


    </div>
  </mat-card-content>
</mat-card>