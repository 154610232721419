<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <mat-tab label="Eventos">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-6">
                <h3 class="sub-title"><i class="fas fa-calendar-alt"></i> Evento Old
                  <div class="pull-right">
                   <!-- <button type="button" class="btn btn-link p-0" (click)="exportarCSV()"><i
                        class="fas fa-file-excel excel"></i> Exportar para csv</button> -->
                  </div>
                </h3>
            </div>


            <div class="col-sm-6">
              <h3 class="sub-title"><i class="fas fa-calendar-alt"></i> Evento New
                <div class="pull-right">
                </div>
              </h3>
          </div>

          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
                  <p-dropdown class="type-p-dropdown" [style]="{'min-width': '384px'}" [filter]="true" [options]="opcoesComboEventoOld"
                placeholder="Selecione um Evento" [(ngModel)]="eventoIdOld" (onChange)="buscarComboEventosNew(eventoIdOld);carregarParticipantesEventoOld(eventoIdOld) "></p-dropdown>

            </div>

            <div class="form-group col-sm-6">
                <p-dropdown class="type-p-dropdown" [style]="{'min-width': '384px'}" [filter]="true" [options]="opcoesComboEventoNew"
              placeholder="Selecione um Evento" [(ngModel)]="eventoIdNew" (onChange)="carregarParticipantesEventoNew(eventoIdNew);checarIngressos(eventoIdOld,eventoIdNew)"></p-dropdown>

          </div>

          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-users"></i> Particpantes</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="conteudo m-0">
                <p-pickList styleClass="fixe-width-picklist" sourceHeader="Evento Old" targetHeader="Evento New" [source]="listaParticipantesOld"
                  [target]="listaParticipantesNew" [dragdrop]="true" [responsive]="true" filterBy="participanteNome"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-participante pTemplate="item">{{participante.participanteNome}} - CPF: {{participante.cpf}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>





