<div style="padding: 1.5rem !important;">
  <h4 class="font-weight-bold py-3 mb-4">
    Dashboard Central
    <div class="text-muted text-tiny mt-1"><small class="font-weight-normal"
        [innerHTML]="formatarDataExtenso()"></small></div>
  </h4>

  <div class="row">
    <div class="col-sm-6 col-xl-3">

      <div class="card mb-4">
        <div class="card-body">
          <div class="ml-5" style="text-align:end;cursor:pointer;margin-top:-15px;" (click)="detalharObrigacoes('1')">
            ...</div>
          <div class="d-flex align-items-center">
            <div class="display-4 text-success"><i class="ion ion-md-time"></i></div>
            <div class="ml-3">
              <div class="text-muted small">Vencendo Hoje</div>
              <div class="text-large">{{ totalHoje }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-6 col-xl-3">

      <div class="card mb-4">
        <div class="card-body">
          <div class="ml-5" style="text-align:end;cursor:pointer;margin-top:-15px;" (click)="detalharObrigacoes('2')">
            ...</div>
          <div class="d-flex align-items-center">
            <div class="display-4 text-primary"><i class="ion ion-md-done-all"></i></div>
            <div class="ml-3">
              <div class="text-muted small">Agendadas no Mês</div>
              <div class="text-large">{{ totalAgendaMes }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-6 col-xl-3">

      <div class="card mb-4">
        <div class="card-body">
          <div class="ml-5" style="text-align:end;cursor:pointer;margin-top:-15px;" (click)="detalharObrigacoes('3')">
            ...</div>
          <div class="d-flex align-items-center">
            <div class="display-4 text-success"><i class="ion ion-ios-send"></i></div>
            <div class="ml-3">
              <div class="text-muted small">Enviadas no Mês</div>
              <div class="text-large">{{ totalEnviaMes }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-6 col-xl-3">

      <div class="card mb-4">
        <div class="card-body">
          <div class="ml-5" style="text-align:end;cursor:pointer;margin-top:-15px;" (click)="detalharObrigacoes('4')">
            ...</div>
          <div class="d-flex align-items-center">
            <div class="display-4 text-danger"><i class="fas fa-thumbs-down"></i></div>
            <div class="ml-3">
              <div class="text-muted small">Vencidas</div>
              <div class="text-large">{{ totalVencidas }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>



  <div class="row">
    <div class="d-flex col-xl-6 align-items-stretch">


      <div class="card d-flex w-100 mb-4">
        <div class="row no-gutters row-bordered row-border-light h-100">
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">{{ cklstNaoIniciado }}</span> </span><br>
                <small class="text-muted">Não iniciado</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-hourglass display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">{{ cklstEmAndamento }} </span> </span><br>
                <small class="text-muted">Em andamento</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-checkmark-circle display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">{{ cklstConcluido }}</span></span><br>
                <small class="text-muted">Concluído</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-cross-circle display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">{{ cklstVencido }}</span></span><br>
                <small class="text-muted">Vencido</small>
              </span>
            </span>

          </div>
        </div>
      </div>


    </div>
    <div class="d-flex col-xl-6 align-items-stretch">


      <div class="card w-100 mb-4">
        <div class="card-body" style="padding:1.5rem 1rem 0 1.5rem;">
          <button type="button" class="btn btn-sm btn-outline-primary icon-btn float-right p-0"
            (click)="atualizaOne()"><i class="ion ion-md-sync"></i></button>
          <div class="card-header-title text-muted" style="font-size: 13px;">Evolução Valor dos Tributos</div>
        </div>
        <div class="px-2 mt-4">
          <div style="height: 200px">
            <figure class="highcharts-figure">
              <highcharts-chart id="chart_column" style="width: 100%; height: 180px; display: block">
              </highcharts-chart>
            </figure>
          </div>
        </div>
      </div>


    </div>
  </div>





  <div class="card mb-4 ">
    <h6 class="card-header with-elements">
      <div class="card-header-title col-9">Demonstrativo de Envio por Responsável </div>
      <div class="card-header-elements ml-auto col-3 pr-0">
        <div class="text m-0 p-0 col">
          <span class="text-light text-tiny font-weight-semibold align-middle"
            style="color:#ababab!important;">RESPONSÁVEL</span>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesResponsavel" [(ngModel)]="responsavelId"
            (onChange)="alternarResp()" placeholder="Selecione"></p-dropdown>
        </div>
      </div>
    </h6>

    <div class="row no-gutters row-bordered">
      <div class="col-md-8 col-lg-12 col-xl-8">
        <div class="card-body">
          <div style="height: 210px">
            <figure class="highcharts-figure">
              <highcharts-chart id="chart_area1" style="width: 100%; height: 190px; display: block">
              </highcharts-chart>
            </figure>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-12 col-xl-4">
        <div class="card-body">


          <div class="row">

            <div class="col-md-12 col-lg-12 col-xl-12">

              <div class="py-4 px-3" style="background-color:#fff;height:300px;">
                <figure class="highcharts-figure">
                  <highcharts-chart id="chart_pizza" style="width: 100%; height: 240px; display: block">
                  </highcharts-chart>
                </figure>
              </div>

            </div>

          </div>


        </div>
      </div>
    </div>
  </div>


  <div class="card mb-4 ">
    <h6 class="card-header with-elements">
      <div class="card-header-title col-9">Gráfico Evolução Diária</div>
      <div class="card-header-elements ml-auto col-3 pr-0">
        <label class="text m-0 p-0 col"> </label>
      </div>
    </h6>

    <div class="row no-gutters row-bordered">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card-body">
          <div style="height:210px;">
            <figure class="highcharts-figure">
              <highcharts-chart id="chart_area2" style="width: 100%; height: 190px; display: block">
              </highcharts-chart>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


<p-dialog header="{{ tituloModal }}" [(visible)]="modalObrigacoes" [style]="{width: '1000px', height: '550px'}"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true" (onHide)="resetModal()">

  <p-header>
    <div class="actions-modal">
      <button type="button" class="btn btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
        matTooltipPosition="right"><i class="fas fa-file-excel excel"></i></button>
    </div>
  </p-header>

  <p-table class="table-system" #dt [value]="relObrigacoes" [columns]="colsRelObrigacoes" [rows]="rows"
    [scrollable]="true" scrollHeight=300px [paginator]="false" [pageLinks]="pageLinks"
    [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left" style="width: 12%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Responsável </span>
          </span>
        </th>
        <th class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Obrigação </span>
          </span>
        </th>
        <th class="text-left" style="width: 20%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Tipo Arquivo </span>
          </span>
        </th>
        <th class="text-left" style="width: 20%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Empresa </span>
          </span>
        </th>
        <th class="text-center" style="width: 80px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Vencto </span>
          </span>
        </th>
        <th class="text-center" style="width: 80px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Envio </span>
          </span>
        </th>
        <th class="text-left" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis">Status </span>
          </span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="text-left" style="width: 12%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.resp}}">
              <span> {{ rowData.resp }} </span>
            </span>
          </span>
        </td>
        <td class="text-left" style="width: 15%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.obrigacao}}">
              <span> {{ rowData.obrigacao }} </span>
            </span>
          </span>
        </td>
        <td class="text-left" style="width: 20%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.arquivo}}">
              <span> {{ rowData.arquivo }} </span>
            </span>
          </span>
        </td>
        <td class="text-left" style="width: 20%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.empresa}}">
              <span> {{ rowData.empresa }} </span>
            </span>
          </span>
        </td>
        <td class="text-center" style="width: 80px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vcto}}">
              <span> {{ rowData.vcto }} </span>
            </span>
          </span>
        </td>
        <td class="text-center" style="width: 80px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.envio}}">
              <span> {{ rowData.envio }} </span>
            </span>
          </span>
        </td>
        <td class="text-left" style="width: 100px;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">
              <span> {{ rowData.descricao }} </span>
            </span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="6" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

</p-dialog>