<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}} Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}

    </mat-card-subtitle>
  </mat-card-title>


  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="pull-right align-down-table">
          <button type="button" class="btn btn-link" (click)="dt2.exportCSV()"><i class="fas fa-file-excel excel"></i>
            Exportar para Excel</button>
        </div>
        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsEventos" [value]="eventos" [rows]="rows"
          [totalRecords]="eventos.length" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                  <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'id', 'contains')">
              </th>
              <th class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'item'">
                  <span class="text-overflow-dynamic-ellipsis">ITEM <p-sortIcon [field]="'item'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'item', 'contains')">
              </th>
              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'municipio'">
                  <span class="text-overflow-dynamic-ellipsis">MUNICIPIO <p-sortIcon [field]="'municipio'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'municipio', 'contains')">
              </th>

              <th class="text-center" style="width: 9%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                  <span class="text-overflow-dynamic-ellipsis">UF <p-sortIcon [field]="'uf'"></p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesUf" defaultLabel="Selecione" [filter]="true"
                  (onChange)="dt2.filter($event.value, 'uf', 'in')"></p-multiSelect>
              </th>

              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataInicio'">
                  <span class="text-overflow-dynamic-ellipsis">INICIO <p-sortIcon [field]="'dataInicio'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'dataInicio', 'contains')">
              </th>

              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataTermino'">
                  <span class="text-overflow-dynamic-ellipsis">TÉRMINO <p-sortIcon [field]="'dataTermino'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'dataTermino', 'contains')">
              </th>

              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'chaveEduzz'">
                  <span class="text-overflow-dynamic-ellipsis">CHAVE EDUZZ <p-sortIcon [field]="'chaveEduzz'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'chaveEduzz', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'meioPagamentoDescricao'">
                  <span class="text-overflow-dynamic-ellipsis">GATEWAY PAGAMENTO <p-sortIcon
                      [field]="'meioPagamentoDescricao'"></p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboMeioPagamento" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'meioPagamentoDescricao', 'in')">
                </p-multiSelect>
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'statusEvento'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'statusEvento'"></p-sortIcon>
                  </span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesStatusCombo" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'statusEvento', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center" style="width: 45px;">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 3%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.id}}">{{rowData.id }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.item}}">{{rowData.item }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.municipio}}">{{rowData.municipio}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}">{{rowData.uf}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataInicio}}">{{rowData.dataInicio
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.dataTermino}}">{{rowData.dataTermino }}</span>
                </span>
              </td>


              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.chaveEduzz}}">{{rowData.chaveEduzz
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.meioPagamentoDescricao}}">{{rowData.meioPagamentoDescricao }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.statusEvento}}">{{rowData.statusEvento }}</span>
                </span>
              </td>



            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="13" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>


      <p-dialog header="Eventos" [(visible)]="modalEventos" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="false">

        <p-header>
          <div style="position: absolute; right: 15px; top: 15px;">

            <button class="btn btn-sm btn-info pull-right" (click)="showModalIngressos();"><i
                class="fas fa-ticket-alt"></i> Ingressos</button>
            <button class="btn btn-sm btn-info pull-right" (click)="showModalContrato();"><i class="fas fa-file"></i>
              Contrato</button>
            <button class="btn btn-sm btn-info pull-right" (click)="gerarCheckIn();"><i class="fas fa-check"></i> Check
              In</button>
            <button class="btn btn-sm btn-info pull-right" (click)="enviarMensagemParticipantes('email');"><i
                class="far fa-envelope"></i> E-mail</button>
            <button class="btn btn-sm btn-info pull-right" (click)="enviarMensagemParticipantes('whats');"><i
                class="fab fa-whatsapp"></i> WhatsApp</button>
            <button class="btn btn-sm btn-info pull-right" (click)="abrirModalWhatsInfo()"><i
                class="fab fa-whatsapp"></i> WhatsApp Info</button>
            <!--<button class="btn btn-sm btn-info pull-right" (click)="gerarLinkPagto()"><i class="fa fa-money"></i> Link Pagamento</button>-->

          </div>

        </p-header>




        <div *ngIf="modalEventos">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Seminarista <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesPalestrante"
                [(ngModel)]="eventosObj.seminarista.id"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Item <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesItens"
                [(ngModel)]="eventosObj.item.id"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>GETWAY DE PAGAMENTO <span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                [options]="opcoesMeioPagametno" [(ngModel)]="eventosObj.eventosMeioPagamento.id"></p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>UF </label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesUF"
                [(ngModel)]="ufSelecionada" (onChange)="buscarUf(ufSelecionada)"></p-dropdown>
            </div>
            <div class="form-group col-sm-6">
              <label>LOCAL </label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesMunicipio"
                [(ngModel)]="eventosObj.municipioIbge.id"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>ENDEREÇO </label>
              <input [(ngModel)]="eventosObj.endereco" class="form-control text-left" type="text" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>INFORMAÇÕES LOCAL</label>
              <input [(ngModel)]="eventosObj.informacoes" class="form-control text-left" type="text" />
            </div>
          </div>

          <!-- <div class="form-row">
            <div class="form-group col-sm-12">
              <label>LINK</label>
              <input [(ngModel)]="eventosObj.link" class="form-control text-left" type="text"/>
            </div>
          </div> -->

          <div class="form-row">


          </div>

          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Inicio</label>
              <p-calendar class="type-p-calendar" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="eventosObj.dataInicio"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>
            <div class="form-group col-sm-6">
              <label>Término</label>
              <p-calendar class="type-p-calendar" appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2010:2030" [defaultDate]="defaultDate" [(ngModel)]="eventosObj.dataTermino"
                dateFormat="dd/mm/yy">
              </p-calendar>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>CÓDIGO INTEGRAÇÃO EDUZZ</label>
              <input type="text" [(ngModel)]="eventosObj.chaveEduzz" class="form-control text-right" max="25" />
            </div>
          </div>

          <div class="form-row">

            <div class="form-group col-sm-4">
              <label>Tempo de acesso do pacote em meses</label>
              <input type="number" [disabled]="desabilitarTempoAcessoClubeVidaPlena==true"
                [(ngModel)]="eventosObj.tempoAcessoClubeVidaPlena" class="form-control text-right" max="240" />
            </div>

            <div class="col-sm-3 align">
              <div class="p-field-checkbox">
                <p-checkbox class="mr-1" [(ngModel)]="eventosObj.clubeVidaPlena"
                  (click)="habilitaDesabilitaTempoAcesso(eventosObj.clubeVidaPlena)" [binary]="true"
                  inputId="checkVidaPlena"></p-checkbox>
                <label for="checkVidaPlena">OFERECE CLUBE VIDA PLENA</label>
              </div>
            </div>



          </div>




          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Status</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
                [options]="statusEvento" [(ngModel)]="eventosObj.statusEvento.id" [disabled]="desabilitaStatus">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>Forma de Pagamento</label>
              <p-multiSelect class="type-p-mult" appendTo="body" [ngModelOptions]="{standalone: true}"
                defaultLabel="Selecione" [options]="formasPgto" [(ngModel)]="formasPgtoSelec" [filter]="true"
                placeholder="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados">
              </p-multiSelect>
            </div>

            <div class="form-group col-sm-4">
              <label>Conta Bancária</label>
              <p-multiSelect class="type-p-mult" appendTo="body" [ngModelOptions]="{standalone: true}"
                defaultLabel="Selecione" [options]="contasBancarias" [(ngModel)]="contasBancariasSelec" [filter]="true"
                placeholder="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados">
              </p-multiSelect>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div style="cursor: pointer;" (click)="editarCentroCusto()">
                <p-table class="table-system" [value]="eventosObj.eventosIngressos">
                  <ng-template pTemplate="header">
                    <tr>
                      <th width="60%">Código</th>
                      <th width="20%" class="text-right">Pacote</th>
                      <th width="20%" class="text-right">Promocional</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td>
                        {{ rowData.codigo }}
                      </td>
                      <td class="text-right">
                        {{ rowData.valorPacote | money }}
                      </td>
                      <td class="text-right">
                        {{ rowData.valorPromocional | money }}
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>

                    <tr style="height:25px">
                      <td [attr.colspan]="8" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Ingresso Adicionado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>





        </div>
        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalEventos=false"><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="salvarEvento();"><i class="fas fa-check"></i>
            Salvar</button>
        </p-footer>
      </p-dialog>
      <!--Final Modal cadastro de Faturas-->


      <p-dialog header="WhatsApp Info" [(visible)]="modalWhatsInfo" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="false">

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>MENSAGEM</label>
            <textarea class="form-control" rows="5" pInputTextarea value="msgWhatsInfo"
              [(ngModel)]="msgWhatsInfo"></textarea>
          </div>
        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalWhatsInfo=false; msgWhatsInfo = ''"><i
              class="fas fa-undo"></i> Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="enviarMsgWhatsInfo();"><i class="fas fa-check"></i>
            Salvar</button>
        </p-footer>

      </p-dialog>


      <p-dialog header="Ingressos" [(visible)]="modalIngressos" [style]="{width: '800px'}" [modal]="true"
        styleClass="altura-inicial-ingresso" [responsive]="true" appendTo="body" [closable]="true">

        <div *ngIf="modalIngressos">
          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-ticket-alt"> </i> Tipo Ingresso <span class="obrigatorio">*</span>
              </h3>
            </div>
            <div class="form-group col-sm-6">
              <div class="lks-form-grid">
                <!-- <label>Tipo Ingresso:</label> -->
                <div class="input-12 type-p-dropdown" input-vertical="middle">
                  <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="tpingresso"
                    [(ngModel)]="tpingressoValue" (onChange)="onChangeIngresso()"></p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"> </i> DESCRIÇÃO</h3>
            </div>
            <div class="form-group col-sm-12">
              <div class="lks-form-grid-readOnly">
                <!-- <label>Tipo Ingresso:</label> -->
                <div class="input-12" input-vertical="middle">
                  <textarea [froalaEditor]="optionsDescFroola" class="field card-back-description" id="textAreaTwoSize"
                    (click)="anexoChange(event)" autofocus [(ngModel)]="eventosIngressosObj.descricao"
                    [disabled]="true"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-bars"> </i> Descrição Complemento</h3>
            </div>
            <div class="form-group col-sm-12">
              <div class="lks-form-grid-readOnly">
                <div class="input-12" input-vertical="middle">
                  <textarea [froalaEditor]="optionsDescFroola" class="field card-back-description" id="textAreaTwoSize"
                    (click)="anexoChange(event)" autofocus [(ngModel)]="eventosIngressosObj.descricaoComplemento"
                    [disabled]="true"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-money-check-alt"> </i> Valor</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Valor Pacote <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="eventosIngressosObj.valorPacote" money />
            </div>
            <div class="form-group col-sm-6">
              <label>Valor Promocional <span class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" [(ngModel)]="eventosIngressosObj.valorPromocional"
                money />
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>LINK <span class="obrigatorio">*</span></label>
              <input class="form-control text-left" type="text" [(ngModel)]="eventosIngressosObj.link" text />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-ticket-alt"> </i> Seus Ingressos</h3>
            </div>
            <div class="col-sm-12">



              <p-table class="table-system" #dteventosIngressos [value]="this.eventosObj.eventosIngressos" [rows]="rows"
                [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true"
                scrollHeight="250px">
                <ng-template pTemplate="header">
                  <tr>



                    <th class="text-center" style="width: 84px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">Codigo </span>
                      </span>
                    </th>

                    <th class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">Pacote </span>
                      </span>

                    </th>


                    <th class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">Promocional </span>
                      </span>

                    </th>


                    <th class="text-center" style="width: 66px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                      </span>

                    </th>


                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">




                    <td class="text-right" style="width: 84px;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                          }}</span>
                      </span>
                    </td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valorPacote | money }}">{{rowData.valorPromocional | money }}</span>
                      </span>
                    </td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valorPromocional | money }}">{{rowData.valorPromocional | money
                          }}</span>
                      </span>
                    </td>


                    <td class="text-center" style="width: 66px;">
                      <span class="edit" (click)="itemOnRowSelectIngresso(rowData)" matTooltip='Alterar'>
                        <i class="fas fa-edit"></i>
                      </span>
                      <span class="delete" (click)="removerIngressosGrid(rowData)" matTooltip='Excluir'>
                        <i class="far fa-trash-alt"></i>
                      </span>

                    </td>


                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="4" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>


            </div>
          </div>
        </div> <!-- End Links Modal Content-->
        <div class="ui-g" style="position: fixed; right: 15px;  bottom: 5px; min-width: 500px; max-width: 500px;">
          <div class="ui-g-12">

          </div>
        </div>
        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalIngressos=false'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" *ngIf="editarEventoIngresso" (click)="alterarIngressos();"><i
              class="fas fa-pencil-alt"></i> Alterar</button>
          <button type="button" class="btn btn-primary" *ngIf="!editarEventoIngresso" (click)="addIngressos();"><i
              class="fas fa-check"></i> Adicionar</button>
        </p-footer>
      </p-dialog>


      <p-dialog [(visible)]="modalParticipantePorEvento" [style]="{width: '900px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="false">

        <p-header>
          Curso : {{nomeCurso}}
        </p-header>

        <div *ngIf="modalParticipantePorEvento">

          <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dtlstparticipantePorEventos.exportCSV()"><i
                class="fas fa-file-excel excel"></i> Exportar para Excel</button>
          </div>
          <div class="clearfix"></div>

          <p-table class="table-system" #dtlstparticipantePorEventos [columns]="colslstparticipantePorEventos"
            [value]="lstparticipantePorEventos" [paginator]="false" (onFilter)="onFilter($event)"
            [pageLinks]="pageLinks" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true"
            scrollHeight="250px">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                    <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtlstparticipantePorEventos.filter($event.target.value, 'nome', 'contains')">
                </th>

                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                    <span class="text-overflow-dynamic-ellipsis">Ingresso <p-sortIcon [field]="'codigo'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtlstparticipantePorEventos.filter($event.target.value, 'codigo', 'contains')">
                </th>


                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoPagto'">
                    <span class="text-overflow-dynamic-ellipsis">Tipo Pagto <p-sortIcon [field]="'tipoPagto'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtlstparticipantePorEventos.filter($event.target.value, 'tipoPagto', 'contains')">
                </th>


                <th class="text-rigth" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                    <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dtlstparticipantePorEventos.filter($event.target.value, 'valor', 'contains')">
                </th>

                <th class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                  </span>

                </th>


              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">




                <td class="text-left" style="width: 25%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo  }}">{{rowData.codigo
                      }}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoPagto }}">{{rowData.tipoPagto
                      }}</span>
                  </span>
                </td>


                <td class="text-right" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.valor  | money }}">{{rowData.valor | money }}</span>
                  </span>
                </td>

                <td class="text-center" style="width: 15%;">
                  <span class="edit" (click)="visualizarFicha(rowData)" matTooltip='Imprimir'>
                    <i class="fas fa-print"></i>
                  </span>
                  <!--<span class="edit" (click)="itemOnRowSelect(rowData)" matTooltip='Alterar'>
                  <i class="fas fa-edit"></i>
                </span>-->
                  <span class="delete" (click)="exluirItemListaGrid(rowData)" matTooltip='Excluir'>
                    <i class="far fa-trash-alt"></i>
                  </span>

                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>


        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalParticipantePorEvento=false'><i
              class="fas fa-undo"></i> Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="gerarPedidos();"><i class="fas fa-spinner"></i> GERAR
            PEDIDOS</button>
          <button type="button" class="btn btn-primary" (click)="validaParticipanteIngresso();"><i
              class="fas fa-check"></i> Ok</button>
        </p-footer>
      </p-dialog>

      <!-- Inicio Modal Alternar periodo -->
      <p-dialog header="Alterar Período" [(visible)]="modalSelecionarData" [style]="{width: '400px'}" [modal]="true"
        styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
        [closable]="true">

        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>Data Inicial</label>
            <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
              [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy">
            </p-calendar>
          </div>
          <div class="form-group col-sm-4">
            <label>Data Final</label>
            <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
              [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy"></p-calendar>
          </div>
        </div>
        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalSelecionarData=false'><i
              class="fas fa-undo"></i> Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="atualizarVoltar()"><i class="fas fa-pencil-alt"></i>
            Alterar</button>
        </p-footer>
      </p-dialog>
      <!-- Final modal Alternar periodo -->

    </mat-card-content>
  </div>

  <!-- Inicio do modal para Contrato -->
  <p-dialog header="Contrato" [(visible)]="modalContrato" [style]="{width: '800px'}" [modal]="true"
    styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true"
    [contentStyle]="{'min-height': '450px'}">

    <div *ngIf="modalContrato">


      <div class="form-row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-bars"> </i> DESCRIÇÃO</h3>
        </div>
        <div class="form-group col-sm-12">
          <div class="lks-form-grid">
            <!-- <label>Tipo Ingresso:</label> -->
            <div class="input-12" input-vertical="middle">
              <textarea [froalaEditor]="optionsDescFroola" class="field " id="textAreaTwoSize" autofocus
                [(ngModel)]="eventosObj.contrato" rows="6"></textarea>
            </div>
          </div>
        </div>
      </div>



    </div> <!-- End Links Modal Content-->
    <div class="ui-g" style="position: fixed; right: 15px;  bottom: 5px; min-width: 500px; max-width: 500px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalContrato=false'><i class="fas fa-undo"></i>
        Fechar</button>
    </p-footer>
  </p-dialog>
  <!-- Final do modal para Contrato -->

</mat-card>