<div [class.readonly]="readonly" [class.disabled]="disabled" class="app-multi-select">
  <mat-checkbox *ngIf="!readonly" #all
                class="select-all"
                (change)="toggleAll(all)" (click)="cancelWhenReadonly($event)"
                [checked]="value?.length > 0 && value?.length === options.values.length">
  </mat-checkbox>

  <label class="select-label">{{ label }} {{ value?.length > 0 ? '(' + value.length + ')' : '' }}</label>

  <div class="clear"></div>

  <div class="clear multi-select-container">
    <div *ngFor="let v of options?.values">
      <p-checkbox 
                    [binary]="true"
                    [class.read-only]="readonly"
                    (click)="cancelWhenReadonly($event)"
                    (keydown)="cancelWhenReadonly($event)"
                    (change)="changeCallback(v, $event)">{{ v[options.label] }}</p-checkbox>
    </div>
  </div>
</div>
