<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}. Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>




  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsListaGetnetPagamento" [value]="listaGetnetPagamento"
          [rows]="rows" [paginator]="true" [totalRecords]="listaGetnetPagamento.length" (onFilter)="onFilter($event)"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>

              <th class="text-left" style="width: 11%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nome', 'contains')">
              </th>

              <th class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                  <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
              </th>

              <th class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'telefone'">
                  <span class="text-overflow-dynamic-ellipsis">TELEFONE <p-sortIcon [field]="'telefone'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'telefone', 'contains')">
              </th>
              <th class="text-left" style="width: 12%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoEventoCompleto'">
                  <span class="text-overflow-dynamic-ellipsis">EVENTO <p-sortIcon [field]="'descricaoEventoCompleto'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'descricaoEventoCompleto', 'contains')">
              </th>

              <th class="text-left" style="width: 4%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
                  <span class="text-overflow-dynamic-ellipsis">QTD INGRESSO(S) <p-sortIcon [field]="'quantidade'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'quantidade', 'contains')">
              </th>

              <th class="text-left" style="width: 4%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcelaNum'">
                  <span class="text-overflow-dynamic-ellipsis">PARCELA(S) <p-sortIcon [field]="'parcelaNum'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'parcelaNum', 'contains')">
              </th>

              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                  <span class="text-overflow-dynamic-ellipsis">VL TOTAL <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'valorTotal', 'contains')">
              </th>

              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                  <span class="text-overflow-dynamic-ellipsis">VL PAGO <p-sortIcon [field]="'valor'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'valor', 'contains')">
              </th>

              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'pagarEm'">
                  <span class="text-overflow-dynamic-ellipsis">PAGAR EM<p-sortIcon [field]="'pagarEm'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'pagarEm', 'contains')">
              </th>

              <th class="text-center" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'recorrenciaPt'">
                  <span class="text-overflow-dynamic-ellipsis">RECORRÊNCIA <p-sortIcon [field]="'recorrenciaPt'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboRecorrencia" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'recorrenciaPt', 'in')"></p-multiSelect>
              </th>

              <th class="text-center" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'statusDescricaoPt'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'statusDescricaoPt'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'statusDescricaoPt', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-left" style="width: 11%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.cpf}}">{{rowData.cpf }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.telefone}}">{{rowData.telefone}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 12%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.descricaoEventoCompleto}}">{{rowData.descricaoEventoCompleto}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 6%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.quantidade}}">{{rowData.quantidade }}</span>
                </span>
              </td>


              <td class="text-right" style="width: 4%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.parcelaNum}}/{{rowData.parcelaTotal}}">{{rowData.parcelaNum
                    }}/{{rowData.parcelaTotal }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.valorTotal}}">{{rowData.valorTotal }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.valor}}">{{rowData.valor }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.pagarEm}}">{{rowData.pagarEm }}</span>
                </span>
              </td>

              <td class="text-center" style="width: 5%;">
                <div *ngIf="rowData.recorrencia">
                  <p-checkbox [binary]="true" (click)="showModalInformacoes(rowData)"></p-checkbox>
                </div>
                <div *ngIf="!rowData.recorrencia">
                  <p-checkbox [binary]="true" (click)="showModalInformacoes(rowData)">
                  </p-checkbox>
                </div>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.statusDescricaoPt}}">{{rowData.statusDescricaoPt }}</span>
                </span>
              </td>




            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="11" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>


      <!--Inicio Modal INFORMACOES-->
      <p-dialog header="INFORMAÇÕES" [(visible)]="modalInformacoes" [style]="{width: '850px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalInformacoes -->
        <div *ngIf="modalInformacoes">

          <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange($event)">
            <mat-tab label="INFORMAÇÕES">

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>ASSINATURA ID</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{subscriptionId}}"
                    [(ngModel)]="subscriptionId" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>PLANO ID</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getnetPlanId}}"
                    [(ngModel)]="getnetPlanId" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>PAGAMENTO ID</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamentoparcela.paymentId}}" [(ngModel)]="getNetPagamentoparcela.paymentId" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>NOME</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPagamento.nome}}"
                    [(ngModel)]="getNetPagamento.nome" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>CPF</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPagamento.cpf}}"
                    [(ngModel)]="getNetPagamento.cpf" />
                </div>

                <div class="form-group col-sm-6">
                  <label>TELEFONE</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPagamento.telefone}}"
                    [(ngModel)]="getNetPagamento.telefone" />
                </div>

              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>DESCRIÇÃO EVENTO</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamento.eventoDescricao}}" [(ngModel)]="getNetPagamento.eventoDescricao" />
                </div>
              </div>

              <div class="form-row">
                <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-credit-card"> </i> DADOS DO CARTÃO</h3>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>NOME</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamento.cartaoNome}}" [(ngModel)]="getNetPagamento.cartaoNome" />
                </div>

                <div class="form-group col-sm-6">
                  <label>4 ÚLTIMOS DÍGITOS</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamento.ultimoDigitoCartao}}" [(ngModel)]="getNetPagamento.ultimoDigitoCartao" />
                </div>

              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>QTD INGRESSO(S)</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamento.quantidade}}" [(ngModel)]="getNetPagamento.quantidade" />
                </div>

                <div class="form-group col-sm-6">
                  <label> PARCELA(S)</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{parcelaCompleto}}"
                    [(ngModel)]="parcelaCompleto" />
                </div>



              </div>

              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>COBRAR EM</label>
                  <p-calendar class="type-p-calendar" [disabled]="!isPodeAlterarData" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="getNetPagamentoparcela.pagarEm"
                    [(ngModel)]="getNetPagamentoparcela.pagarEm" dateFormat="dd/mm/yy" >
                  </p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>VALOR TOTAL</label>
                  <input type="text" class="form-control text-left" [readonly]="!isPodeAlterarData"
                    value="{{getNetPagamento.valorTotal}}" [(ngModel)]="getNetPagamento.valorTotal" money />
                </div>

                <div class="form-group col-sm-3">
                  <label>VALOR PAGO</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamento.valorPago}}" [(ngModel)]="getNetPagamento.valorPago" money />
                </div>

                <div class="form-group col-sm-3">
                  <label>VALOR EsTORNO</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamentoHistorico.valorEstorno}}"
                    [(ngModel)]="getNetPagamentoHistorico.valorEstorno" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>MENSAGEM</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPagamento.mensagem}}"
                    [(ngModel)]="getNetPagamento.mensagem" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>DETALHE DA DESCRIÇÃO</label>
                  <input type="text" class="form-control text-left" readonly="true"
                    value="{{getNetPagamentoHistorico.descriptionDetail}}"
                    [(ngModel)]="getNetPagamentoHistorico.descriptionDetail" />
                </div>
              </div>

            </mat-tab>
            <mat-tab label="HISTÓRICO">

              <div class="pull-right align-down-table">
                <button type="button" class="btn btn-link" (click)="dt3.exportCSV()"><i
                    class="fas fa-file-excel excel"></i> Exportar para Excel</button>
              </div>
              <div class="clearfix"></div>

              <p-table class="table-system" #dt3 [columns]="colsListaHistorico" [value]="listaHistorico" [rows]="rows"
                [paginator]="false" [totalRecords]="listaHistorico.length" selectionMode="single"
                [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">DT REQ. </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">PAGO EM </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">PAGAMENTO ID </span>
                      </span>
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">TRANSAÇÃO ID </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">VL PAGO </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">CANCELADO EM </span>
                      </span>
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">VL ESTORNO </span>
                      </span>

                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">JUST. ESTORNO </span>
                      </span>

                    </th>

                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">STATUS </span>
                      </span>
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.horaBrasil}}">{{rowData.horaBrasil }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.authorizedAt}}">{{rowData.authorizedAt }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.paymentId}}">{{rowData.paymentId}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.transactionId}}">{{rowData.transactionId}}</span>
                      </span>
                    </td>
                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}">{{rowData.valor
                          }}</span>
                      </span>
                    </td>


                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.cancelRequestAt}}">{{rowData.cancelRequestAt }}</span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.valorEstorno}}">{{rowData.valorEstorno }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.justificativaEstorno}}">{{rowData.justificativaEstorno }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.descricaoStatus}}">{{rowData.descricaoStatus }}</span>
                      </span>
                    </td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="9" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>


            </mat-tab>

          </mat-tab-group>



          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>


        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalInformacoes=false;"><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" [disabled]="!isPodeAlterarData"
            (click)="salvarEdicaoParcelaPagamento();"><i class="fas fa-pencil-alt"></i> Alterar</button>
          <button type="button" class="btn btn-primary" [disabled]="!isPodeEstornar" (click)="estornarPagamento();"><i
              class="fas fa-money-bill-alt"></i> Estornar</button>
        </p-footer>
      </p-dialog>
      <!--Final Modal INFORMACOES-->



      <!-- Inicio Modal Estorno -->
      <p-dialog header="Estorno" [(visible)]="modalEstorno" [style]="{width: '400px'}" [modal]="true"
        styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
        [closable]="true">


        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>VALOR A SER ESTORNADO</label>
            <input [(ngModel)]="valorEstorno" [disabled]="true" class="form-control text-left" type="text" money />
          </div>

        </div>



        <div class="form-row">
          <label>JUSTIFICATIVA</label>
          <textarea class="form-control" [(ngModel)]="justificativaEstorno" rows="2"></textarea>
        </div>


        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalEstorno=false'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="salvarEstorno()"><i class="fas fa-pencil-alt"></i>
            Salvar</button>
        </p-footer>
      </p-dialog>
      <!-- Final modal Estorno -->


      <!-- Modal Alterar Período -->
      <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
        class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
        [closable]="true">
        <div *ngIf="modalPeriodo">
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Data Inicial </label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
            </div>
            <div class="form-group col-sm-6">
              <label>Data Final </label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
            </div>
          </div>
        </div>
        <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
          <div class="ui-g-12">

          </div>
        </div>
        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="periodoChange();"><i class="fas fa-check"></i>
            Alterar</button>
        </p-footer>
      </p-dialog>
      <!-- Modal Alterar Período -->


    </mat-card-content>
  </div>

</mat-card>