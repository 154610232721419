<mat-card class="card-linnks margin-linnks">
  <mat-card-title class="mb-0">
    Calendário Financeiro
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-9 col-xxl-8">
        <div class="shadow-box" [ngClass]="fullCalendar ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Calendário</h3>
              <div class="actions-card">
                <a href="{{ calendarioUrl }}" class="btn btn-xs btn-link" matTooltip="Baixar Calendário" matTooltipPosition="above">
                  <span style="font-size: 15px;"><i class="fas fa-link"></i></span>
                </a>
                <input type="text" value="{{ calendarioUrl }}">
                <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen" matTooltipPosition="above" (click)="fullScreenCalendar()">
                  <span *ngIf="!fullCalendar" class="material-icons full-icon">fullscreen</span>
                  <span *ngIf="fullCalendar" class="material-icons full-icon">fullscreen_exit</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardCalendar()" (click)="changeState()">
                  <span *ngIf="isCardCalendar" class="material-icons" matTooltip="Ocultar Card" matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardCalendar" class="material-icons" matTooltip="Ver Card" matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentState>


            <div class="row">
              <div class="col-sm-12">
                <div class="row text-center">
                    <div class="col text-left">
                      <div class="btn-group btn-group-sm">
                        <div
                          style="font-weight: 600; line-height: 1.7;"
                          class="btn btn-outline-secondary"
                          mwlCalendarPreviousView
                          [view]="view"
                          [(viewDate)]="viewDate"
                          (viewDateChange)="viewDateChange.next(viewDate)">
                          Anterior
                        </div>
                        <div
                          style="font-weight: 600; line-height: 1.7;"
                          class="btn btn btn-outline-primary"
                          mwlCalendarToday
                          [(viewDate)]="viewDate"
                          (viewDateChange)="viewDateChange.next(viewDate)">
                          Hoje
                        </div>
                        <div
                          style="font-weight: 600; line-height: 1.7;"
                          class="btn btn-outline-secondary"
                          mwlCalendarNextView
                          [view]="view"
                          [(viewDate)]="viewDate"
                          (viewDateChange)="viewDateChange.next(viewDate)">
                          Próximo
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <h3 style="
                      font-size: 1rem;
                      font-weight: bold;
                      color: #666;
                      line-height: 40px;
                      ">
                      {{ viewDate | calendarDate:(view + 'ViewTitle'):locale }}
                      </h3>
                    </div>
                    <div class="col"></div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-sm-12">
              <mwl-calendar-month-view
                  [locale]="locale"
                  [viewDate]="viewDate"
                  [events]="listaEventos"
                  [refresh]="refresh"
                  [activeDayIsOpen]="activeDayIsOpen"
                  (dayClicked)="dayClicked($event.day)"
                  (eventClicked)="eventClicked($event)"
                  ></mwl-calendar-month-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
