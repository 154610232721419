<div class="w-100 h-100" [ngSwitch]="dashId">
  <app-dash-financial-summary *ngSwitchCase="1"></app-dash-financial-summary>
  <app-dash-purchases *ngSwitchCase="2"></app-dash-purchases>
  <app-dash-sales *ngSwitchCase="3"></app-dash-sales>
  <app-dash-rms *ngSwitchCase="4"></app-dash-rms>
  <app-dash-stock-list *ngSwitchCase="5"><app-dash-stock-list>

  <div class="empty" *ngSwitchDefault>
    <div class="empty-content">
      <span class="material-icons">push_pin</span>
      <h2>Defina uma dashboard como principal ou selecione uma!</h2>
    </div>
</div>