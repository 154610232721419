<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content class="mat-card-linnks">

    <div class="lks-table-expanded">
        <p-checkbox class="type-p-checkbox" [(ngModel)]="!exibirPedidos" (change)="exibirOcultaPedidos($event)"></p-checkbox>
        <label style="padding: 0 5px;">Ocultar Pedidos do Fluxo?</label>
      <p-table #data [columns]="cols" [value]="contents" dataKey="descricao" rowExpandMode="single" (onRowExpand)="onRowExpand($event)" [paginator]="true" [rows]="10">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">{{col.header}}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
          <tr>
            <td>
              <a href="#" [pRowToggler]="rowData" *ngIf="rowIndex > 0" field="ids">
                <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
              </a>
            </td>
            <td> {{ rowData.descricao }} </td>
            <td *ngIf="rowData.tipoDia1 === 'cr'" >{{ rowData.dia1 | money }}</td>
            <td *ngIf="rowData.tipoDia1 === 'cp'" style="color:darkred;">{{ rowData.dia1 | money }}</td>
            <td *ngIf="rowData.tipoDia2 === 'cr'">{{ rowData.dia2 | money }}</td>
            <td *ngIf="rowData.tipoDia2 === 'cp'" style="color:darkred;">{{ rowData.dia2 | money }}</td>
            <td *ngIf="rowData.tipoDia3 === 'cr'">	{{ rowData.dia3 | money }}</td>
            <td *ngIf="rowData.tipoDia3 === 'cp'" style="color:darkred;">	{{ rowData.dia3 | money }}</td>
            <td *ngIf="rowData.tipoDia4 === 'cr'">	{{ rowData.dia4 | money }}</td>
            <td *ngIf="rowData.tipoDia4 === 'cp'" style="color:darkred;">	{{ rowData.dia4 | money }}</td>
            <td *ngIf="rowData.tipoDia5 === 'cr'">	{{ rowData.dia5 | money }}</td>
            <td *ngIf="rowData.tipoDia5 === 'cp'" style="color:darkred;">	{{ rowData.dia5 | money }}</td>
            <td *ngIf="rowData.tipoDia6 === 'cr'">	{{ rowData.dia6 | money }}</td>
            <td *ngIf="rowData.tipoDia6 === 'cp'" style="color:darkred;">	{{ rowData.dia6 | money }}</td>
            <td *ngIf="rowData.tipoDia7 === 'cr'">	{{ rowData.dia7 | money }}</td>
            <td *ngIf="rowData.tipoDia7 === 'cp'" style="color:darkred;">	{{ rowData.dia7 | money }}</td>
            <td *ngIf="rowData.tipoDia8 === 'cr'">	{{ rowData.dia8 | money }}</td>
            <td *ngIf="rowData.tipoDia8 === 'cp'" style="color:darkred;">	{{ rowData.dia8 | money }}</td>
            <td *ngIf="rowData.tipoDia9 === 'cr'">	{{ rowData.dia9 | money }}</td>
            <td *ngIf="rowData.tipoDia9 === 'cp'" style="color:darkred;">	{{ rowData.dia9 | money }}</td>
            <td *ngIf="rowData.tipoDia10 === 'cr'">	{{ rowData.dia10 | money }}</td>
            <td *ngIf="rowData.tipoDia10 === 'cp'" style="color:darkred;">	{{ rowData.dia10 | money }}</td>
            <td *ngIf="rowData.tipoDia11 === 'cr'">	{{ rowData.dia11 | money }}</td>
            <td *ngIf="rowData.tipoDia11 === 'cp'" style="color:darkred;">	{{ rowData.dia11 | money }}</td>
            <td *ngIf="rowData.tipoDia12 === 'cr'">	{{ rowData.dia12 | money }}</td>
            <td *ngIf="rowData.tipoDia12 === 'cp'" style="color:darkred;">	{{ rowData.dia12 | money }}</td>
            <td *ngIf="rowData.tipoDia13 === 'cr'">	{{ rowData.dia13 | money }}</td>
            <td *ngIf="rowData.tipoDia13 === 'cp'" style="color:darkred;">	{{ rowData.dia13 | money }}</td>
            <td *ngIf="rowData.tipoDia14 === 'cr'">	{{ rowData.dia14 | money }}</td>
            <td *ngIf="rowData.tipoDia14 === 'cp'" style="color:darkred;">	{{ rowData.dia14 | money }}</td>
            <td *ngIf="rowData.tipoDia15 === 'cr'">	{{ rowData.dia15 | money }}</td>
            <td *ngIf="rowData.tipoDia15 === 'cp'" style="color:darkred;">	{{ rowData.dia15 | money }}</td>
            <td *ngIf="rowData.tipoDia16 === 'cr'">	{{ rowData.dia16 | money }}</td>
            <td *ngIf="rowData.tipoDia16 === 'cp'" style="color:darkred;">	{{ rowData.dia16 | money }}</td>
            <td *ngIf="rowData.tipoDia17 === 'cr'">	{{ rowData.dia17 | money }}</td>
            <td *ngIf="rowData.tipoDia17 === 'cp'" style="color:darkred;">	{{ rowData.dia17 | money }}</td>
            <td *ngIf="rowData.tipoDia18 === 'cr'">	{{ rowData.dia18 | money }}</td>
            <td *ngIf="rowData.tipoDia18 === 'cp'" style="color:darkred;">	{{ rowData.dia18 | money }}</td>
            <td *ngIf="rowData.tipoDia19 === 'cr'">	{{ rowData.dia19 | money }}</td>
            <td *ngIf="rowData.tipoDia19 === 'cp'" style="color:darkred;">	{{ rowData.dia19 | money }}</td>
            <td *ngIf="rowData.tipoDia20 === 'cr'">	{{ rowData.dia20 | money }}</td>
            <td *ngIf="rowData.tipoDia20 === 'cp'" style="color:darkred;">	{{ rowData.dia20 | money }}</td>
            <td *ngIf="rowData.tipoDia21 === 'cr'">	{{ rowData.dia21 | money }}</td>
            <td *ngIf="rowData.tipoDia21 === 'cp'" style="color:darkred;">	{{ rowData.dia21 | money }}</td>
            <td *ngIf="rowData.tipoDia22 === 'cr'">	{{ rowData.dia22 | money }}</td>
            <td *ngIf="rowData.tipoDia22 === 'cp'" style="color:darkred;">	{{ rowData.dia22 | money }}</td>
            <td *ngIf="rowData.tipoDia23 === 'cr'">	{{ rowData.dia23 | money }}</td>
            <td *ngIf="rowData.tipoDia23 === 'cp'" style="color:darkred;">{{ rowData.dia23 | money }}</td>
            <td *ngIf="rowData.tipoDia24 === 'cr'">	{{ rowData.dia24 | money }}</td>
            <td *ngIf="rowData.tipoDia24 === 'cp'" style="color:darkred;">	{{ rowData.dia24 | money }}</td>
            <td *ngIf="rowData.tipoDia25 === 'cr'">	{{ rowData.dia25 | money }}</td>
            <td *ngIf="rowData.tipoDia25 === 'cp'" style="color:darkred;"> {{ rowData.dia25 | money }}</td>
            <td *ngIf="rowData.tipoDia26 === 'cr'">	{{ rowData.dia26 | money }}</td>
            <td *ngIf="rowData.tipoDia26 === 'cp'" style="color:darkred;">	{{ rowData.dia26 | money }}</td>
            <td *ngIf="rowData.tipoDia27 === 'cr'">	{{ rowData.dia27 | money }}</td>
            <td *ngIf="rowData.tipoDia27 === 'cp'" style="color:darkred;"> {{ rowData.dia27 | money }}</td>
            <td *ngIf="rowData.tipoDia28 === 'cr'">	{{ rowData.dia28 | money }}</td>
            <td *ngIf="rowData.tipoDia28 === 'cp'" style="color:darkred;">	{{ rowData.dia28 | money }}</td>
            <td *ngIf="rowData.tipoDia29 === 'cr'">	{{ rowData.dia29 | money }}</td>
            <td *ngIf="rowData.tipoDia29 === 'cp'" style="color:darkred;">	{{ rowData.dia29 | money }}</td>
            <td *ngIf="rowData.tipoDia30 === 'cr'">	{{ rowData.dia30 | money }}</td>
            <td *ngIf="rowData.tipoDia30 === 'cp'" style="color:darkred;">	{{ rowData.dia30 | money }}</td>
            <td *ngIf="rowData.tipoDia31 === 'cr'">	{{ rowData.dia31 | money }}</td>
            <td *ngIf="rowData.tipoDia31 === 'cp'" style="color:darkred;">	{{ rowData.dia31 | money }}</td>
            <td *ngIf="rowData.tipoTotalDia === 'cr'"> {{rowData.totalDia | money}} </td>
            <td *ngIf="rowData.tipoTotalDia === 'cp'" style="color:darkred;">{{rowData.totalDia | money}}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
          <tr>
            <td [attr.colspan]="columns.length + 2">
              <table>
                <tr *ngFor="let col of expandValores">
                  <td>
                    <!-- <mat-checkbox *ngIf="col.nf" (click)="selectCheckbox(col.nfeId,col)" [(ngModel)]="col.selected" class="type-mat-checkbox"></mat-checkbox> -->
                  </td>
                  <td>{{ col.nome }} <span *ngIf="col.nf"> - {{ tipo }}  {{ col.nf }}  - Parcela {{ col.parcela }} </span></td>
                  <td *ngIf="col.tipo === 'cr'" >{{ col.dia1 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">{{ col.dia1 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">{{ col.dia2 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">{{ col.dia2 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia3 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia3 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia4 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia4 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia5 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia5 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia6 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia6 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia7 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia7 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia8 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia8 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia9 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia9 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia10 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia10 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia11 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia11 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia12 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia12 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia13 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia13 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia14 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia14 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia15 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia15 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia16 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia16 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia17 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia17 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia18 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia18 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia19 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia19 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia20 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia20 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia21 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia21 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia22 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia22 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia23 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">{{ col.dia23 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia24 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia24 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia25 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;"> {{ col.dia25 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia26 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia26 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia27 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;"> {{ col.dia27 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia28 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia28 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia29 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia29 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia30 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ col.dia30 | money }}</td>
                  <td *ngIf="col.tipo === 'cr'">	{{ col.dia31 | money }}</td>
                  <td *ngIf="col.tipo === 'cp'" style="color:darkred;">	{{ rowData.dia31 | money }}</td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer" let-rowData let-columns="columns">
          <tr *ngFor="let col of footerValores">
            <td>
            </td>
            <td> {{ col.descricao }} </td>
            <td *ngIf="col.tipoDia1 === 'cr'" >{{ col.dia1 | money }}</td>
            <td *ngIf="col.tipoDia1 === 'cp'" style="color:darkred;">{{ col.dia1 | money }}</td>
            <td *ngIf="col.tipoDia2 === 'cr'">{{ col.dia2 | money }}</td>
            <td *ngIf="col.tipoDia2 === 'cp'" style="color:darkred;">{{ col.dia2 | money }}</td>
            <td *ngIf="col.tipoDia3 === 'cr'">	{{ col.dia3 | money }}</td>
            <td *ngIf="col.tipoDia3 === 'cp'" style="color:darkred;">	{{ col.dia3 | money }}</td>
            <td *ngIf="col.tipoDia4 === 'cr'">	{{ col.dia4 | money }}</td>
            <td *ngIf="col.tipoDia4 === 'cp'" style="color:darkred;">	{{ col.dia4 | money }}</td>
            <td *ngIf="col.tipoDia5 === 'cr'">	{{ col.dia5 | money }}</td>
            <td *ngIf="col.tipoDia5 === 'cp'" style="color:darkred;">	{{ col.dia5 | money }}</td>
            <td *ngIf="col.tipoDia6 === 'cr'">	{{ col.dia6 | money }}</td>
            <td *ngIf="col.tipoDia6 === 'cp'" style="color:darkred;">	{{ col.dia6 | money }}</td>
            <td *ngIf="col.tipoDia7 === 'cr'">	{{ col.dia7 | money }}</td>
            <td *ngIf="col.tipoDia7 === 'cp'" style="color:darkred;">	{{ col.dia7 | money }}</td>
            <td *ngIf="col.tipoDia8 === 'cr'">	{{ col.dia8 | money }}</td>
            <td *ngIf="col.tipoDia8 === 'cp'" style="color:darkred;">	{{ col.dia8 | money }}</td>
            <td *ngIf="col.tipoDia9 === 'cr'">	{{ col.dia9 | money }}</td>
            <td *ngIf="col.tipoDia9 === 'cp'" style="color:darkred;">	{{ col.dia9 | money }}</td>
            <td *ngIf="col.tipoDia10 === 'cr'">	{{ col.dia10 | money }}</td>
            <td *ngIf="col.tipoDia10 === 'cp'" style="color:darkred;">	{{ col.dia10 | money }}</td>
            <td *ngIf="col.tipoDia11 === 'cr'">	{{ col.dia11 | money }}</td>
            <td *ngIf="col.tipoDia11 === 'cp'" style="color:darkred;">	{{ col.dia11 | money }}</td>
            <td *ngIf="col.tipoDia12 === 'cr'">	{{ col.dia12 | money }}</td>
            <td *ngIf="col.tipoDia12 === 'cp'" style="color:darkred;">	{{ col.dia12 | money }}</td>
            <td *ngIf="col.tipoDia13 === 'cr'">	{{ col.dia13 | money }}</td>
            <td *ngIf="col.tipoDia13 === 'cp'" style="color:darkred;">	{{ col.dia13 | money }}</td>
            <td *ngIf="col.tipoDia14 === 'cr'">	{{ col.dia14 | money }}</td>
            <td *ngIf="col.tipoDia14 === 'cp'" style="color:darkred;">	{{ col.dia14 | money }}</td>
            <td *ngIf="col.tipoDia15 === 'cr'">	{{ col.dia15 | money }}</td>
            <td *ngIf="col.tipoDia15 === 'cp'" style="color:darkred;">	{{ col.dia15 | money }}</td>
            <td *ngIf="col.tipoDia16 === 'cr'">	{{ col.dia16 | money }}</td>
            <td *ngIf="col.tipoDia16 === 'cp'" style="color:darkred;">	{{ col.dia16 | money }}</td>
            <td *ngIf="col.tipoDia17 === 'cr'">	{{ col.dia17 | money }}</td>
            <td *ngIf="col.tipoDia17 === 'cp'" style="color:darkred;">	{{ col.dia17 | money }}</td>
            <td *ngIf="col.tipoDia18 === 'cr'">	{{ col.dia18 | money }}</td>
            <td *ngIf="col.tipoDia18 === 'cp'" style="color:darkred;">	{{ col.dia18 | money }}</td>
            <td *ngIf="col.tipoDia19 === 'cr'">	{{ col.dia19 | money }}</td>
            <td *ngIf="col.tipoDia19 === 'cp'" style="color:darkred;">	{{ col.dia19 | money }}</td>
            <td *ngIf="col.tipoDia20 === 'cr'">	{{ col.dia20 | money }}</td>
            <td *ngIf="col.tipoDia20 === 'cp'" style="color:darkred;">	{{ col.dia20 | money }}</td>
            <td *ngIf="col.tipoDia21 === 'cr'">	{{ col.dia21 | money }}</td>
            <td *ngIf="col.tipoDia21 === 'cp'" style="color:darkred;">	{{ col.dia21 | money }}</td>
            <td *ngIf="col.tipoDia22 === 'cr'">	{{ col.dia22 | money }}</td>
            <td *ngIf="col.tipoDia22 === 'cp'" style="color:darkred;">	{{ col.dia22 | money }}</td>
            <td *ngIf="col.tipoDia23 === 'cr'">	{{ col.dia23 | money }}</td>
            <td *ngIf="col.tipoDia23 === 'cp'" style="color:darkred;">{{ col.dia23 | money }}</td>
            <td *ngIf="col.tipoDia24 === 'cr'">	{{ col.dia24 | money }}</td>
            <td *ngIf="col.tipoDia24 === 'cp'" style="color:darkred;">	{{ col.dia24 | money }}</td>
            <td *ngIf="col.tipoDia25 === 'cr'">	{{ col.dia25 | money }}</td>
            <td *ngIf="col.tipoDia25 === 'cp'" style="color:darkred;"> {{ col.dia25 | money }}</td>
            <td *ngIf="col.tipoDia26 === 'cr'">	{{ col.dia26 | money }}</td>
            <td *ngIf="col.tipoDia26 === 'cp'" style="color:darkred;">	{{ col.dia26 | money }}</td>
            <td *ngIf="col.tipoDia27 === 'cr'">	{{ col.dia27 | money }}</td>
            <td *ngIf="col.tipoDia27 === 'cp'" style="color:darkred;"> {{ col.dia27 | money }}</td>
            <td *ngIf="col.tipoDia28 === 'cr'">	{{ col.dia28 | money }}</td>
            <td *ngIf="col.tipoDia28 === 'cp'" style="color:darkred;">	{{ col.dia28 | money }}</td>
            <td *ngIf="col.tipoDia29 === 'cr'">	{{ col.dia29 | money }}</td>
            <td *ngIf="col.tipoDia29 === 'cp'" style="color:darkred;">	{{ col.dia29 | money }}</td>
            <td *ngIf="col.tipoDia30 === 'cr'">	{{ col.dia30 | money }}</td>
            <td *ngIf="col.tipoDia30 === 'cp'" style="color:darkred;">	{{ col.dia30 | money }}</td>
            <td *ngIf="col.tipoDia31 === 'cr'">	{{ col.dia31 | money }}</td>
            <td *ngIf="col.tipoDia31 === 'cp'" style="color:darkred;">	{{ col.dia31 | money }}</td>
            <td></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>
