<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>

    <mat-tab-group (selectedTabChange)="tabChange($event)">

      <mat-tab label="Não iniciado ({{ listaInicial.length }})">
        <div class="box-content pt-0">
          <div class="clearfix"></div>
          <!-- <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dt1.exportCSV()"><i class="fas fa-file-excel excel"></i>
              Exportar para Excel</button>
          </div> -->
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>

          <p-table class="table-system" #dt1 [columns]="colsChecklistNaoIniciado" [value]="listaInicial" [rows]="rows"
            [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            selectionMode="false" [style]="{'width':'100%'}">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCorte'">
                    <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'dataCorte'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'dataCorte', 'contains')">
                </th>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                    <span class="text-overflow-dynamic-ellipsis">Título <p-sortIcon [field]="'titulo'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'titulo', 'contains')">
                </th>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'andamento'">
                    <span class="text-overflow-dynamic-ellipsis">Andamento <p-sortIcon [field]="'andamento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'andamento', 'contains')">
                </th>
                <th class="text-center" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                    <span class="text-overflow-dynamic-ellipsis">Responsável <p-sortIcon [field]="'usuario'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'usuario', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'status', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox (click)="select(rowData.id,rowData)" [value]="rowData"></p-tableCheckbox>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dataCorte}}">{{rowData.dataCorte}}</span>
                  </span>
                </td>
                <td style="width: 25%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.titulo}}">{{rowData.titulo}}</span>
                  </span>
                </td>
                <td style="width: 25%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.andamento}}">{{rowData.andamento}}</span>
                  </span>
                </td>
                <td style="width: 20%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>

      <mat-tab label="Em andamento ({{ listaAndamento.length }})">
        <div class="box-content pt-0">
          <div class="clearfix"></div>
          <!-- <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dt2.exportCSV()"><i class="fas fa-file-excel excel"></i>
              Exportar para Excel</button>
          </div> -->
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <p-table class="table-system" #dt2 [columns]="colsChecklistEmAndamento" [value]="listaAndamento" [rows]="rows"
            [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            selectionMode="false" [style]="{'width':'100%'}">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCorte'">
                    <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'dataCorte'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'dataCorte', 'contains')">
                </th>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                    <span class="text-overflow-dynamic-ellipsis">Título <p-sortIcon [field]="'titulo'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'titulo', 'contains')">
                </th>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'andamento'">
                    <span class="text-overflow-dynamic-ellipsis">Andamento <p-sortIcon [field]="'andamento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'andamento', 'contains')">
                </th>
                <th class="text-center" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                    <span class="text-overflow-dynamic-ellipsis">Responsável <p-sortIcon [field]="'usuario'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'usuario', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt2.filter($event.target.value, 'status', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox (click)="select(rowData.id,rowData)" [value]="rowData"></p-tableCheckbox>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dataCorte}}">{{rowData.dataCorte}}</span>
                  </span>
                </td>
                <td style="width: 25%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.titulo}}">{{rowData.titulo}}</span>
                  </span>
                </td>
                <td style="width: 25%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.andamento}}">{{rowData.andamento}}</span>
                  </span>
                </td>
                <td style="width: 20%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>

      <mat-tab label="Concluído ({{ listaConcluido.length }})">
        <div class="box-content pt-0">
          <div class="clearfix"></div>
          <!-- <div class="pull-right align-down-table">
            <button type="button" class="btn btn-link" (click)="dt3.exportCSV()"><i class="fas fa-file-excel excel"></i>
              Exportar para Excel</button>
          </div> -->
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt3.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <p-table class="table-system" #dt3 [columns]="colsChecklistConcluido" [value]="listaConcluido" [rows]="rows"
            [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
            selectionMode="false" [style]="{'width':'100%'}">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataCorte'">
                    <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'dataCorte'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'dataCorte', 'contains')">
                </th>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulo'">
                    <span class="text-overflow-dynamic-ellipsis">Título <p-sortIcon [field]="'titulo'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'titulo', 'contains')">
                </th>
                <th class="text-center" style="width: 25%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'andamento'">
                    <span class="text-overflow-dynamic-ellipsis">Andamento <p-sortIcon [field]="'andamento'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'andamento', 'contains')">
                </th>
                <th class="text-center" style="width: 20%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                    <span class="text-overflow-dynamic-ellipsis">Responsável <p-sortIcon [field]="'usuario'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'usuario', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                    <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt3.filter($event.target.value, 'status', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox (click)="select(rowData.id,rowData)" [value]="rowData"></p-tableCheckbox>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dataCorte}}">{{rowData.dataCorte}}</span>
                  </span>
                </td>
                <td style="width: 25%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.titulo}}">{{rowData.titulo}}</span>
                  </span>
                </td>
                <td style="width: 25%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.andamento}}">{{rowData.andamento}}</span>
                  </span>
                </td>
                <td style="width: 20%;" class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.usuario}}">{{rowData.usuario}}</span>
                  </span>
                </td>
                <td style="width: 10%;" class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>

    </mat-tab-group>

    <p-dialog header="Check List de Obrigações" [modal]="true" appendTo="body" [style]="{width: '800px'}"
      styleClass="modal-scroll" [(visible)]="modalNovo" [responsive]="true" [closable]="true"
      (onHide)="modalNovoAction();">

      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Responsável</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" [options]="usuarios"
            placeholder="Selecione" [(ngModel)]="responsavel"></p-dropdown>
        </div>
        <div class="form-group col-sm-3" style="display: none;">
          <label>Conclusão</label>
          <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
            [(ngModel)]="checkList.dataCorte" [defaultDate]="defaultDate" [yearNavigator]="true" yearRange="2010:2030">
          </p-calendar>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Título <span class="obrigatorio">*</span></label>
          <input class="form-control" type='text' [(ngModel)]="checkList.titulo">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição</label>
          <textarea class="form-control" [(ngModel)]="checkList.descricao" rows="4"></textarea>
        </div>
      </div>

      <!-- START CHECK LIST-->
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-tasks"></i> Check List <span class="obrigatorio">*</span></h3>
        </div>
      </div>
      <div id="listaItens" style="display: none;" (click)="fecharIncluir()">
        <div class="row" id="itensAdicionados">
          <div class="col-sm-12">
            <label style="color:#8c8c8c;margin-bottom:11px;">Andamento: {{ porcentagem }}%</label>

            <div class='type-p-checkbox sombra-texto' *ngFor="let item of listarItens;let i = index">

              <div id="{{ 'itemListaId'+i }}" *ngIf="showItemId!==i" style="position: relative;">
                <p-checkbox *ngIf="showItemId!==i" (click)="concluirItem(item)" [(ngModel)]="item.concluido"
                  [binary]="true"></p-checkbox>
                <span class="{{ item.concluido ? 'item-concluido' : 'vazio' }}" (click)="alterarItemDiv(i,item)"
                  matTooltip='Editar item!'> {{ item.descricao }} </span>
                <span *ngIf="showItemId!==i" input-text="right" input-vertical="middle">
                  <div matTooltip='Excluir item da lista!' (click)="removeItem(item.id)" class="botao-excluir-item"><i
                      class="fas fa-trash-alt"></i></div>
                </span>
              </div>

            </div>

            <div id="inputListaId" class="lks-modal-button" *ngIf="inputItemId!==-1" style="
                  position: absolute;
                  border: 1px solid #bbbbbb;
                  background-color: rgb(255, 255, 255);
                  z-index: 10;
                  top: 42%;
                  width: 96%;
                  border-radius: 3px;
                  box-shadow: 0px 1px 7px 1px #cccc;
                  padding: 10px;">
              <input class="form-control" type='text' [(ngModel)]="conteudoItemEditar" (keyup.enter)="alterarItem()" />
              <button class="btn btn-xs btn-secondary mt-2" type="button" (click)="inputItemId=-1;showItemId=-1"><i
                  class="fas fa-times"></i> Fechar</button>
              <button class="btn btn-xs btn-primary mt-2" type="button" (click)="alterarItem()"><i
                  class="fas fa-check"></i> Salvar</button>
              <span (click)="inputItemId=-1;showItemId=-1"
                style="width:67%;display:inline-block;padding:8px 0px;height:25px;border-radius:3px;"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" id="textoItem" style="display: none;">
        <div class="col-sm-12">
          <input class="form-control mt-2" type='text' id="conteudoItem" placeholder="Adicionar item..."
            [(ngModel)]="conteudoItem" #item (keyup.enter)="onEnter(item.value)" />
          <button class="btn btn-xs btn-secondary mt-2" (click)="fecharIncluir()"><i class="fas fa-times"></i>
            Fechar</button>
          <button class="btn btn-xs btn-primary mt-2" type="button" (click)="adicionarItem()"><i
              class="fas fa-plus"></i> Adicionar Item</button>
          <!--<button class="button-default" type="button" pButton label="Fechar" (click)="fecharIncluir()" style="margin: 5px 0;"></button>-->
        </div>
      </div>

      <div class="row" id="addItem">
        <div class="col-sm-12">
          <label style="color:#8c8c8c;text-transform: initial;margin: 0 0 0 2px !important;cursor:pointer;"
            (click)="addItem()">Adicionar item...</label>
        </div>
      </div>

      <div class="lks-form-grid" (click)="fecharIncluir()"></div>
      <!-- END CHECK LIST-->

      <div class="" (click)="fecharIncluir()">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-paperclip"></i> Anexo</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12 mb-1">
            <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
              <span class="input-group-append">
                <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                        Remover Arquivo
                      </span> -->
                <span class="input-group-text btn-file">
                  <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                    Arquivo</span>
                  <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                    Arquivo</span> <!-- Antes era Trocar Arquivo-->
                  <input type="file" #file (change)="fileChange()" />
                </span>
              </span>
              <div class="form-control" data-trigger="fileinput">
                <span class="fileinput-filename"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="files?.length > 0">
          <div class="col-sm-12">
            <table class="table table-border-boot">
              <thead>
                <tr>
                  <th style="width: 80%;">Arquivo</th>
                  <th style="width: 10%;" class="text-center">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of files">
                  <td>
                    <span matTooltip='Baixar Arquivo!' (click)="baixarAnexo(file)"> {{ file.nome }} </span>
                  </td>
                  <td class="text-center">
                    <span class="delete" matTooltip='Excluir Arquivo' (click)="excluirAnexo(file)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-calendar-alt"></i> Vencimento <span class="obrigatorio">*</span></h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <!-- <label class="d-block">Recorrente <span class="obrigatorio">*</span></label> -->
          <mat-radio-group [(ngModel)]="checkList.recorrencia" (ngModelChange)="modalRecorrencia()">
            <mat-radio-button class="mr-3" *ngFor="let rec of recorrencias" [value]="rec">
              {{rec.descricao}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="lks-form-grid">
        <div class="input-12">

        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)='modalNovoAction();'><i class="fas fa-undo"></i>
          Fechar</button>
        <button type="button" class="btn btn-danger" (click)='removerCheckList()'><i class="fas fa-times"></i>
          Excluir</button>
        <button type="button" class="btn btn-primary" (click)="addCheckList()"><i class="fas fa-check"></i>
          Salvar</button>
      </p-footer>
    </p-dialog>

    <p-dialog [(visible)]="exbirModalRecorrencia" [style]="{width: '700px'}" [responsive]="true" [modal]="true"
      showEffect="fade" appendTo="body" styleClass="modal-initial" [closable]="false">
      <p-header>
        <div class="modal-title" *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 1 : false">Vencimento Único
        </div>
        <div class="modal-title" *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 2 : false">Vencimento
          Semanal</div>
        <div class="modal-title" *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 3 : false">Vencimento
          Mensal</div>
        <div class="modal-title" *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 4 : false">Vencimento Anual
        </div>
      </p-header>


      <div *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 1 : false">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Vencimento</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [defaultDate]="defaultDate" [yearNavigator]="true" yearRange="2010:2030">
            </p-calendar>
          </div>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
          <label for="antDNU">Antecipa Dia Não Útil</label>
        </div>
      </div>

      <div *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 2 : false">
        <div class="form-row">
          <div class="form-group col-sm-2">
            <label>Repete a cada</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
            <label>(semanas)</label>
          </div>
          <div class="ml-3 col">
            <label class="d-block" style="margin-top: 5px;">Repete</label>
            <div class="p-field-checkbox">
              <p-checkbox class="mr-1" [(ngModel)]="dom" [binary]="true" inputId="dom"></p-checkbox>
              <label for="dom" class="mr-3">D</label>
              <p-checkbox class="mr-1" [(ngModel)]="seg" [binary]="true" inputId="seg"></p-checkbox>
              <label for="seg" class="mr-3">S</label>
              <p-checkbox class="mr-1" [(ngModel)]="ter" [binary]="true" inputId="ter"></p-checkbox>
              <label for="ter" class="mr-3">T</label>
              <p-checkbox class="mr-1" [(ngModel)]="qua" [binary]="true" inputId="qua"></p-checkbox>
              <label for="qua" class="mr-3">Q</label>
              <p-checkbox class="mr-1" [(ngModel)]="qui" [binary]="true" inputId="qui"></p-checkbox>
              <label for="qui" class="mr-3">Q</label>
              <p-checkbox class="mr-1" [(ngModel)]="sex" [binary]="true" inputId="sex"></p-checkbox>
              <label for="sex" class="mr-3">S</label>
              <p-checkbox class="mr-1" [(ngModel)]="sab" [binary]="true" inputId="sab"></p-checkbox>
              <label for="sab" class="mr-3">S</label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Início Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"
              [defaultDate]="defaultDate"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"
              [defaultDate]="defaultDate"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Após</label>
            <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
            <label>ocorrências</label>
          </div>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
          <label for="antDNU">Antecipa Dia Não Útil</label>
        </div>
      </div>

      <div *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 3 : false">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Dia do mês</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="dias" [(ngModel)]="diaMes"></p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Repete a cada meses</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Início Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"
              [defaultDate]="defaultDate"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"
              [defaultDate]="defaultDate"></p-calendar>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Termina Após</label>
            <input type="text" class="form-control" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
            <label>ocorrências</label>
          </div>
          <div class="col">
            <label class="d-block" style="margin-top: 6px;">Período</label>
            <div class="p-field-checkbox">
              <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
              <label for="antDNU">Antecipa Dia Não Útil</label>
              <p-checkbox class="ml-3 mr-1" [(ngModel)]="diaUtil" [binary]="true" inputId="diaU"
                [disabled]="diaMes > 20"></p-checkbox>
              <label for="diaU">Dia Útil</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="checkList.recorrencia ? checkList.recorrencia.id === 4 : false">
        <div class="form-row">
          <div class="form-group col-sm-3">
            <label>Repete a cada</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
            <label>anos</label>
          </div>
          <div class="form-group col-sm-3">
            <label>Início Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"
              [defaultDate]="defaultDate"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Em</label>
            <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
              [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"
              [defaultDate]="defaultDate"></p-calendar>
          </div>
          <div class="form-group col-sm-3">
            <label>Termina Após</label>
            <input class="form-control" type="text" pInputText [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
            <label>ocorrências</label>
          </div>
        </div>
        <div class="p-field-checkbox">
          <p-checkbox class="mr-1" [(ngModel)]="antecipaDiaNaoUtil" [binary]="true" inputId="antDNU"></p-checkbox>
          <label for="antDNU">Antecipa Dia Não Útil</label>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)='exbirModalRecorrencia=false'><i class="fa fa-undo"></i>
          Fechar</button>
        <button type="button" class="btn btn-primary" (click)="criarChecklist()"><i class="fa fa-check"></i>
          Salvar</button>
      </p-footer>
    </p-dialog>

  </mat-card-content>

</mat-card>