<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        {{title}}
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">{{ '&nbsp;' }}</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="dadosTabela" [style]="{width:'100%',height:'100%'}"
            [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [rows]="rows" [(selection)]="maqSelecionadas" dataKey="id"
            (onRowSelect)="atualizarToolbar()" (onHeaderCheckboxToggle)="atualizarToolbar()" (onRowUnselect)="atualizarToolbar()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all">
                      <p-tableHeaderCheckbox [disabled]="checkboxDesativada"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'externo_id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        IDENTIFICAÇÃO EXTERNA <p-sortIcon [field]="'externo_id'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'externo_id', 'contains')">
                  </th>
                  <th class="text-center" style="width: 40%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'conta_nome'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CONTA BANCÁRIA RELACIONADA <p-sortIcon [field]="'conta_nome'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'conta_nome', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [value]="rowData" [disabled]="checkboxDesativada"></p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo }}">
                        {{rowData.codigo}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">
                        {{rowData.descricao}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.externo_id}}">
                        {{rowData.externo_id}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.conta_nome}}">
                        {{rowData.conta_nome}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="12" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header={{tituloModal}} [(visible)]="mostrarModal" [style]="{width: '900px'}" [modal]="true"
 class="modal-scroll" appendTo="body" [closable]="false" *ngIf="mostrarModal">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Código <span class="obrigatorio">*</span> </label>
      <input class="form-control" [(ngModel)]="maquinaCartao.codigo" type="text"/>
    </div>
    <div class="form-group col-sm-6">
      <label>Descrição <span class="obrigatorio">*</span> </label>
      <input class="form-control" [(ngModel)]="maquinaCartao.descricao" type="text"/>
    </div>
  </div>
  <div class="form-row" >
    <div class="form-group col-sm-6">
      <label>Identificação Externa <span class="obrigatorio">*</span> </label>
      <input class="form-control" [(ngModel)]="maquinaCartao.externoId" type="text"/>
    </div>
    <div class="form-group col-sm-6">
      <label>Conta bancária <span class="obrigatorio">*</span> </label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [options]="listaConta" [(ngModel)]="maquinaCartao.contaBancoId"
        [filter]="true" [disabled]="requisicaoContaCarregando" [dropdownIcon]="requisicaoContaCarregando ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" placeholder="Selecione" panelStyleClass="custom-width">
      </p-dropdown>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='this.fecharModal()'><i class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='this.concluirModal()'><i class="fas fa-check"></i> {{tituloBotaoModal}}</button>
  </p-footer>

</p-dialog>