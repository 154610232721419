<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-12 d-flex align-items-center justify-content-between" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Demonstrativo de Aprovações
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Informativo sobre os volumes</small>
        </div>
      </h4>
      <div class="d-flex align-itens-center justify-content-end">
        <p-multiSelect class="type-p-mult mr-3 mult-header" [filter]="true" [placeholder]="placeholderCompanies" [options]="availableCompanies" appendTo="body" [(ngModel)]="selectedCompanies" [disabled]="loadingOptCompanies" [dropdownIcon]="loadingOptIconCompanies ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="checkCompaniesList()"></p-multiSelect>
        <app-dash-changer [options]="periodOptions" format="chip" (tabSelected)="changePeriod($event)"></app-dash-changer>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="selectedCompanies.length > 0">
    <div class="row">
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="shadow-box w-100 p-3 h-100">
          <app-dash-changer class="mt-3" [options]="firstTab" format="text" (tabSelected)="changeTab($event, 1)"></app-dash-changer>
          <app-dash-graphics container="priority_chart" type="pie" [height]="300" [pieData]="priorityData" [isLegend]="false" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (firstTabSelected === 'priority') ? 'block' : 'none'}"></app-dash-graphics>
          <app-dash-graphics container="status_bar" type="bar" [height]="300" [isMoney]="false" [barData]="statusData" [isLegend]="false" [baseList]="statusLabel" [isLegend]="false" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (firstTabSelected === 'status') ? 'block' : 'none'}"></app-dash-graphics>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="shadow-box w-100 p-3 h-100">
          <app-dash-changer class="mt-3" [options]="secondTab" format="text" (tabSelected)="changeTab($event, 2)"></app-dash-changer>
          <app-dash-graphics container="type_bar" type="bar" [height]="300" [isMoney]="false" [barData]="typeData" [isLegend]="false" [baseList]="typeLabel" [isLegend]="false" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (secondTabSelected === 'type') ? 'block' : 'none'}"></app-dash-graphics>
          <app-dash-graphics container="company_chart" type="pie" [height]="300" [pieData]="companyData" [isLegend]="true" [isLoading]="isLoading" [showShadowBox]="false" [ngStyle]="{'display': (secondTabSelected === 'company') ? 'block' : 'none'}"></app-dash-graphics>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4">
        <div class="shadow-box w-100 p-3 h-100">
          <app-dash-changer class="mt-3" [options]="threeTab" format="text" (tabSelected)="changeTab($event, 3)"></app-dash-changer>
          <app-dash-table *ngIf="threeTabSelected === 'requester'" [data]="tableRequester" [isLoading]="isLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="false" [isScrollable]="true" [showShadowBox]="false"></app-dash-table>
          <app-dash-table *ngIf="threeTabSelected === 'approver'" [data]="tableApprover" [isLoading]="isLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="false" [isScrollable]="true" [showShadowBox]="false"></app-dash-table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top" *ngIf="selectedCompanies.length > 0">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <app-dash-table [data]="tableApprovalDetails" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="true" [hasFilter]="true" [hasPaginator]="true" [hasFooter]="true" [hasExport]="true" [customExport]="customExport" [isTableExportLoading]="isTableExportLoading"
        [isScrollable]="true" (appliedFilter)="changeFilterDetails($event)"></app-dash-table>
      </div>
    </div>
  </div>
  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-top" *ngIf="selectedCompanies.length > 0">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <app-dash-graphics container="status_ccus" title="Centro de Custo" type="bar" [height]="300" [isMoney]="false" [barData]="ccusData" [isLegend]="false" [baseList]="ccusLabel" [isLegend]="false" [isLoading]="isLoading"></app-dash-graphics>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top">
        <app-dash-graphics container="status_proj" title="Projeto" type="bar" [height]="300" [isMoney]="false" [barData]="projectData" [isLegend]="false" [baseList]="projectLabel" [isLegend]="false" [isLoading]="isLoading"></app-dash-graphics>
      </div>
    </div>
  </div>
  <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-top" *ngIf="selectedCompanies.length > 0">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <app-dash-graphics container="column_chart" title="Evolução de volume mensal" type="column" [height]="680" [columnData]="monthlyEvolutionData" [isMoney]="false" [baseList]="monthlyEvolutionBase" [isLegend]="true" [isLoading]="isLoading"></app-dash-graphics>
      </div>
    </div>
  </div>
</div>

<div class="empty" *ngIf="selectedCompanies.length <= 0">
  <div class="empty-content">
      <span class="material-icons">warning</span>
      <h2>Por favor, selecione pelo menos uma empresa</h2>
  </div>
</div>
