  <div class="row">

    <div class="col-sm-4">
      <label>Participante</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="part" />
    </div>
    <div class="col-sm-2">
      <label>Núm Doc</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="numDoc" />
    </div>
    <div class="col-sm-2">
      <label>Pedido</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="codPed" />
    </div>
    <div class="col-sm-2">
      <label>Cód Controle</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="codigoControle" />
    </div>


  </div>


<div class="row mt-2" *ngFor="let p of parcelas">

  <div class="row ml-2">
    <div class="col-sm-12 mt-3">
      <h3 class="sub-title"><i class="fas fa-info-circle"></i> Parcela {{p.parcela}}</h3>
      <button type="button" (click)="editar()"><i class="fas fa-edit"></i></button>
    </div>
  </div>

  <div class="row ml-2">
  <div class="col-sm-2">
    <label>Status</label>
    <input class="form-control text-left" [disabled]="true" type="text"
    [(ngModel)]="p.status" />
  </div>
    <div class="col-sm-3">
      <label>Dt Venc</label>
      <p-calendar class="type-p-calendar" [disabled]="!editPerm || p.status === 'BAIXADO' || p.status === 'BAIXA PARCIAL'" dateFormat="dd/mm/yy" [(ngModel)]="p.dtExtrato"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030">
      </p-calendar>
    </div>
    <div class="col-sm-7">

    </div>
    <div class="col-sm-2">
      <label>Valor</label>
      <input class="form-control text-right" [disabled]="true" type="text"
      [(ngModel)]="p.valor" money />
    </div>
    <div class="col-sm-6">
      <label>Cod Barras</label>
      <input class="form-control text-right" [disabled]="!editPerm || p.status === 'BAIXADO' || p.status === 'BAIXA PARCIAL'" type="text"
      [(ngModel)]="p.codigoBarra" />
    </div>


    <div class="col-sm-4">
      <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
      <button type="button" class="btn btn-danger" [disabled]="!editPerm || (p.status !== 'BAIXADO' && p.status !== 'BAIXA PARCIAL')" (click)="desfazerBaixa(p)"><i class="fas fa-trash"></i></button>
      <button type="button" class="btn btn-primary" [disabled]="!editPerm || p.status === 'BAIXADO' || p.status === 'BAIXA PARCIAL'" (click)="baixar(p)"><i class="fas fa-bolt"></i></button>
      <button type="button" class="btn btn-primary" [disabled]="!editPerm || p.status === 'BAIXADO' || p.status === 'BAIXA PARCIAL'" (click)="salvar(p)"><i class="fas fa-check"></i></button>
    </div>
  </div>

  </div>


  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
    <div class="ui-g-12">

    </div>
  </div>
