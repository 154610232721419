<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>
  <mat-card-content>

    <div class="row" *ngIf="msgs">
      <div class="col">

      </div>
    </div>

    <div class="box-content pt-2 col-sm-6" style="min-width: 600px;">
      <div class="header-box">
        <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-file-upload" style="font-size: 14.5px;"> </i> Importação</h3>
              <div class="actions-card" *ngIf="tipoImportacao == 2">
                <a href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_FINAL_MOVIMENTACOES.csv" matTooltip="Baixar Layout" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 23px; margin-right: -5px;color: #6c757d;">cloud_download</span>
                </a>
              </div>
          </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-12 mb-1 mt-1">
                <label>Tipo de arquivo</label>
                <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="opcoesImportacao"
                  [(ngModel)]="tipoImportacao" (onChange)="onChangeImportacao()"></p-dropdown>
            </div>
            <div class="form-group col-sm-12 mb-1 mt-1">
              <label class="d-block">Selecione um arquivo</label>
              <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                  <span class="input-group-append">
                      <span class="input-group-text btn-file">
                        <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar Arquivo</span>
                        <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar Arquivo</span>
                        <input type="file" #file (change)="fileChange()" />
                      </span>
                  </span>
                  <div class="form-control" data-trigger="fileinput">
                    <span class="fileinput-filename"></span>
                  </div>
              </div>
            </div>
        </div>
        <div class="form-row">
          <div class="form-group col mb-0">
              <button type="button" class="btn btn-primary" (click)="importar()"><i class="fas fa-upload"></i> Importar</button>
          </div>
        </div>
    </div>
  </mat-card-content>
