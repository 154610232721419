<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Autorização de Pagamentos
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
          <br>
          <small *ngIf="hasTimeCourse" class="font-weight-normal">Período selecionado: {{convertTimestamp(dateInitial)}} à {{convertTimestamp(dateFinal)}}</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="headerBox">
          <ul class="list-inline filter-menu mb-3">
            <li class="list-inline-item" [ngClass]="{ active: showPending }">
              <a (click)="changeScreen(0)">Pendentes ({{tableDataPending.length}})</a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: showRefused }">
              <a (click)="changeScreen(2)">Recusados ({{tableDataRefused.length}})</a>
            </li>
            <li class="list-inline-item" [ngClass]="{ active: showConcluded }">
              <a (click)="changeScreen(1)">Aprovados ({{tableDataApproved.length}})</a>
            </li>
          </ul>
          <div class="d-flex align-items-center justify-content-end">
            <app-toolbar [value]="toolbarMain"></app-toolbar>
          </div>
        </div>
        <div class="box-content" [@changeDivSize]=currentState>
          <p-table #dt class="table-system" [value]="tableData" [rows]="rows" [paginator]="true" *ngIf="hasTimeCourse"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" [(selection)]="clearArr"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="onSelectAll($event)"
          [loading]="tableLoading" [showLoader]="false">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-center">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEntrega'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Data Vencimento<p-sortIcon [field]="'dtEntrega'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="dataEntregaFilterTable" (input)="dt.filter($event.target.value, 'dtEntrega', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao_social'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Razão Social <p-sortIcon [field]="'razao_social'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="businessNameFilterTable" (input)="dt.filter($event.target.value, 'razao_social', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Histórico <p-sortIcon [field]="'historico'"></p-sortIcon>
                    </span>
                  </span>
                  <input pInputText class="form-control form-control-sm" type="text" [(ngModel)]="historicFilterTable" (input)="dt.filter($event.target.value, 'historico', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'n_documento'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Num. Documento <p-sortIcon [field]="'n_documento'"></p-sortIcon>
                    </span>
                  </span>
                  <input pInputText class="form-control form-control-sm" type="text" [(ngModel)]="numDocumentFilterTable" (input)="dt.filter($event.target.value, 'n_documento', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Parcela <p-sortIcon [field]="'parcela'"></p-sortIcon>
                    </span>
                  </span>
                  <input pInputText class="form-control form-control-sm" type="text" [(ngModel)]="parcelFilterTable" (input)="dt.filter($event.target.value, 'parcela', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'requisitante_full_name'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Requisitante <p-sortIcon [field]="'requisitante_full_name'"></p-sortIcon>
                    </span>
                  </span>
                  <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="applicantsTable" defaultLabel="Selecione" [filter]="false" [(ngModel)]="applicantsFilterTable" (onChange)="dt.filter($event.value, 'requisitante_full_name', 'in')"></p-multiSelect>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_pagamento'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Tipo pagamento <p-sortIcon [field]="'tipo_pagamento'"></p-sortIcon>
                    </span>
                  </span>
                  <input pInputText class="form-control form-control-sm" type="text" [(ngModel)]="paymentTypeFilterTable" (input)="dt.filter($event.target.value, 'tipo_pagamento', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_total'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Valor (R$) <p-sortIcon [field]="'valor_total'"></p-sortIcon>
                    </span>
                  </span>
                  <input class="form-control form-control-sm" type="text" [(ngModel)]="valueFilterTable" (input)="dt.filter($event.target.value, 'valor_total', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="text-center">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtEntrega}}">{{rowData.dtEntrega}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.razao_social}}">{{rowData.razao_social}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.historico}}">{{rowData.historico}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.n_documento}}">{{rowData.n_documento}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.parcela}}">{{rowData.parcela}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.requisitante_full_name}}">{{rowData.requisitante_full_name}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo_pagamento}}">{{rowData.tipo_pagamento}}</span>
                  </span>
                </td>
                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor_total | money}}">{{rowData.valor_total | money}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr *ngFor="let item of tableDataLoading">
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
                <td class="text-center">
                  <p-skeleton width="100%"></p-skeleton>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer">
              <tr>
                <td colspan="8" class="text-right">Valor total:</td>
                <td class="text-right">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="R$ {{totalValue | money}}">R$ {{totalValue | money}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="9" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div *ngIf="!hasTimeCourse">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Sem período selecionado!
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Verificar código de autorização" [(visible)]="modalPaymentGenerate" [style]="{width: '700px'}" [modal]="true"
  class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código de autorização</label>
      <input class="form-control" type="text" [(ngModel)]="insertedCode" />
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalPaymentGenerate=false'><i
        class="fas fa-undo"></i>Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="redirectFunction()"><i
        class="fas fa-check"></i>Verificar</button>
  </p-footer>
</p-dialog>