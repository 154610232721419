<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    Importar
    <mat-card-subtitle>
      Importar Planilha Movimentação Estoque
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content pt-4 col-sm-6" style="min-width: 600px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="cabecalho">
            <button type="button" class="btnCustom-default" matTooltip="Baixar Layout" matTooltipPosition="above"
              (click)="getLinkLayoutUpload()">
              <span class="material-icons">cloud_download</span>
              <div>
                Baixar Layout
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12 mb-1 mt-1">
          <div class="attaching-body">
            <div class="upload-container">
              <input type="file" id="btn-upload" hidden #file (change)="fileChange()" />
              <label class="upload-btn" for="btn-upload">Selecionar arquivo</label>
              <span class="upload-name" #fileChoosen></span>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btnCustom-green" (click)="importar()">
        <span class="material-icons">file_upload</span>
        <div>
          Importar
        </div>
      </button>
    </div>
  </mat-card-content>
</mat-card>