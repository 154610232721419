<app-data-table
class="regra-relac"
mainTitle="Regra Relacionamento"
singleTitle="Regra"
routerPath="/regra-relac"
entityPath="/regrarelac"
[entity]="regrarelac"
[entityFilter]="entityFilter"
[columns]="this['columns']"
[controls]="this['controls']">
</app-data-table>