<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullBenef ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content tableSpace">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateBenef>
            <p-table class="table-system" #dt [columns]="colsEncargos" [value]="encargoLista" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" (onRowUnselect)="onRowUnselect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">COD. CONTRATO <p-sortIcon [field]="'codigo'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 32%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_contrato'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO CONTRATO <p-sortIcon
                          [field]="'descricao_contrato'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao_contrato', 'contains')">
                  </th>
                  <th class="text-left" style="width: 32%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_encargo'">
                      <span class="text-overflow-dynamic-ellipsis">ENCARGO <p-sortIcon [field]="'descricao_encargo'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao_encargo', 'contains')">
                  </th>
                  <th class="text-right" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_porcent'">
                      <span class="text-overflow-dynamic-ellipsis">PORCENTAGEM <p-sortIcon [field]="'valor_porcent'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor_porcent', 'contains')">
                  </th>
                  <th class="text-right" style="width: 12%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR FIXO <p-sortIcon [field]="'valor'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 12%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 32%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.descricao_contrato}}">{{rowData.descricao_contrato}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 32%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.descricao_encargo}}">{{rowData.descricao_encargo}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 12%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.valor_porcent}}%"
                        *ngIf="rowData.is_porcent">{{rowData.valor_porcent}}%</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 12%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect(rowData)'
                        matTooltip="{{rowData.valor | number }}">{{rowData.valor | number }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum encargo encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVO ENCARGO -->
<p-dialog header="Adicionar novo encargo" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Contrato</label>
      <p-dropdown class="type-p-mult" [options]="tipoCargoList" [(ngModel)]="tipoCargoIdNew"
        defaultLabel="Selecionar benefício(s)" [filter]="true" placeholder="Selecione"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição do encargo" [(ngModel)]="descricaoNew">
    </div>
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="isPorcentNew">Porcentual?</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-12" *ngIf="isPorcentNew">
      <label>Valor porcentual (%)</label>
      <input class="form-control text-right" [(ngModel)]="valorPorcentNew">
    </div>
    <div class="form-group col-sm-12" *ngIf="!isPorcentNew">
      <label>Valor</label>
      <input class="form-control text-right" [(ngModel)]="valorNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Benefícios</label>
      <p-dropdown class="type-p-mult" [options]="benefList" [(ngModel)]="benefSelecionados"
        defaultLabel="Selecionar benefício(s)" [filter]="true" placeholder="{0} benefício(s) selecionado(s)"
        selectedItemsLabel="{0} benefício(s) selecionado(s)"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Salário/Rescisão</label>
      <p-dropdown class="type-p-mult" [options]="salList" [(ngModel)]="salSelecionados" defaultLabel="Selecionar item"
        [filter]="true" placeholder="{0} item(s) selecionado(s)" selectedItemsLabel="{0} item(s) selecionado(s)">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Provisões</label>
      <p-dropdown class="type-p-mult" [options]="provList" [(ngModel)]="provSelecionados"
        defaultLabel="Selecionar provisão(s)" [filter]="true" placeholder="{0} provisão(s) selecionada(s)"
        selectedItemsLabel="{0} provisão(s) selecionada(s)"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addEncargo();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT ENCARGO -->
<p-dialog header="Editar encargo" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição do encargo" [(ngModel)]="descricaoEdit">
    </div>
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="isPorcentEdit">Porcentual?</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-12" *ngIf="isPorcentEdit">
      <label>Valor porcentual (%)</label>
      <input class="form-control text-right" [(ngModel)]="valorPorcentEdit">
    </div>
    <div class="form-group col-sm-12" *ngIf="!isPorcentEdit">
      <label>Valor</label>
      <input class="form-control text-right" [(ngModel)]="valorEdit">
    </div>
    <div class="form-group col-sm-12">
      <label>Benefícios</label>
      <p-multiSelect class="type-p-mult" [options]="benefList" [(ngModel)]="benefSelecionados"
        defaultLabel="Selecionar benefício(s)" [filter]="true" placeholder="{0} benefício(s) selecionado(s)"
        selectedItemsLabel="{0} benefício(s) selecionado(s)"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Salário/Rescisão</label>
      <p-multiSelect class="type-p-mult" [options]="salList" [(ngModel)]="salSelecionados"
        defaultLabel="Selecionar item" [filter]="true" placeholder="{0} item(s) selecionado(s)"
        selectedItemsLabel="{0} item(s) selecionado(s)"></p-multiSelect>
    </div>
    <div class="form-group col-sm-12">
      <label>Provisões</label>
      <p-multiSelect class="type-p-mult" [options]="provList" [(ngModel)]="provSelecionados"
        defaultLabel="Selecionar provisão(s)" [filter]="true" placeholder="{0} provisão(s) selecionada(s)"
        selectedItemsLabel="{0} provisão(s) selecionada(s)"></p-multiSelect>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editEncargo();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>