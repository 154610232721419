<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>


  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->
  <mat-card-content>
    <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="box-content pt-2" *ngIf="showLista">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>


      <p-table class="table-system" #dt2 [columns]="colsLista" [value]="lista" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" emptyMessage="Nenhum Registro Encontrado" [rowsPerPageOptions]="rowsPerPage"
        selectionMode="false" [style]="{ width: '100%' }">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnae'">
                <span class="text-overflow-dynamic-ellipsis">Código Municipal de Serviço <p-sortIcon [field]="'cnae'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cnae', 'contains')">
            </th>

            <th class="text-left" style="width: 75%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'descricao', 'contains')">
            </th>


            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'retidoPrestador'">
                <span class="text-overflow-dynamic-ellipsis">ISS PRESTADOR <p-sortIcon [field]="'retidoPrestador'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'retidoPrestador', 'contains')">
            </th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)"
                  matTooltip="{{rowData.cnae}}">{{rowData.cnae }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 75%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="loadComponent('edit', rowData)"
                  matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
              </span>
            </td>

            <td class="text-center" style="width: 15%;">
              <span matTooltip="{{rowData.recorrenciaPt}}">
                <p-checkbox class="mr-1" [(ngModel)]="rowData.retidoPrestador" [binary]="true" inputId="retidoPrestador" [disabled]="true">
                </p-checkbox>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="13" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>



    </div>

    <div *ngIf="showEdit || showNew" class="box-content col-sm-6">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Código Municipal de Serviço</label>
          <input class="form-control" type="text" input-text="left" [(ngModel)]="empresaCnae.cnae" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DESCRIÇÃO</label>
          <input class="form-control" type="text" input-text="left" [(ngModel)]="empresaCnae.descricao" />
        </div>
      </div>
      <div class="p-field-checkbox mb-3">
        <p-checkbox class="mr-1" [(ngModel)]="empresaCnae.retidoPrestador" [binary]="true" inputId="iss"></p-checkbox>
        <label for="iss">ISS Prestador</label>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12 mb-0">
          <button type="button" class="btn btn-secondary" (click)="loadComponent('lista', null);"><i
              class="fas fa-undo"></i> Cancelar</button>
          <button *ngIf="showEdit" type="button" class="btn btn-danger" (click)="excluir()"><i class="fas fa-times"></i>
            Excluir</button>
          <button type="button" class="btn btn-primary" (click)="salvar()"><i class="fas fa-check"></i> Salvar</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>