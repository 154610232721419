<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <div class="clearfix mt-5"></div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="box-content pt-2">
          <div class="clearfix"></div>

          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarGrupoOp"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <div style="margin-top: 4px;">


            <p-table class="table-system" #dt [columns]="this['columns']" [value]="listaGruposOperacao" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="selectCheckbox($event)"
              (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 3%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox (click)="checkAll($event)"></p-tableHeaderCheckbox>
                      </span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">Código <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 40%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 35%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoConcatenado'">
                      <span class="text-overflow-dynamic-ellipsis">Item tipo <p-sortIcon [field]="'codigoConcatenado'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'codigoConcatenado', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 3%;">
                    <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codigoConcatenado }}"
                        *ngIf="rowData.vinculaItemTipo">{{ rowData.codigoConcatenado }}
                      </span>
                      <span matTooltip="Item tipo não vinculado" *ngIf="!rowData.vinculaItemTipo">Item tipo não
                        vinculado</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
        </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!--Modal Grupo Operação-->
  <p-dialog header="Grupo operação" [(visible)]="boolCriarGrupoOperacao || boolEditarGrupoOperacao"
    styleClass="modal-scroll" [style]="{width: '600px'}" [modal]="true" [responsive]="true" appendTo="body"
    [closable]="false">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label for="inputCodigo">Código</label>
        <input class="form-control text-left" value="{{ operacaoRegraGrupo.codigo }}"
          [disabled]="boolEditarGrupoOperacao" [(ngModel)]="operacaoRegraGrupo.codigo" name="inputCodigo" maxlength="3">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label for="inputDescricao">Descrição</label>
        <input class="form-control text-left" value="{{ operacaoRegraGrupo.descricao }}"
          [(ngModel)]="operacaoRegraGrupo.descricao" name="inputDescricao">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Item tipo</label>
        <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
          [options]="OpcoesItemTipo" [(ngModel)]="itemTipoId">
        </p-dropdown>
      </div>
    </div>
    <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="cancelar()"><i class="fas fa-undo"></i> Cancelar</button>
      <button *ngIf="boolCriarGrupoOperacao && !boolEditarGrupoOperacao" type="button" class="btn btn-primary"
        (click)="criarGrupoOperacao()"><i class="fas fa-check"></i> Salvar</button>
      <button *ngIf="!boolCriarGrupoOperacao && boolEditarGrupoOperacao" type="button" class="btn btn-primary"
        (click)="editarGrupoOperacao()"><i class="fas fa-check"></i> Salvar</button>
    </p-footer>
  </p-dialog>
</mat-card>