<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullBenef ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content tableSpace">
              <h3 class="title-card">Cargos</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateBenef>
            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt [columns]="colsCargos" [value]="cargoLista" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [(selection)]="selectedJobs"
              [style]="{'width':'100%'}" (onRowUnselect)="onRowUnselect($event)" (onRowSelect)="onRowSelect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox"style="width: 40px;">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 22%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 21%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                      <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nivel', 'contains')">
                  </th>
                  <th class="text-left" style="width: 22%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_cargo'">
                      <span class="text-overflow-dynamic-ellipsis">CONTRATO <p-sortIcon [field]="'tipo_cargo'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'tipo_cargo', 'contains')">
                  </th>
                  <th class="text-right" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_min'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR MIN <p-sortIcon [field]="'valor_min'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor_min', 'contains')">
                  </th>
                  <th class="text-right" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_max'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR MAX <p-sortIcon [field]="'valor_max'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor_max', 'contains')">
                  </th>
                  <th class="text-right" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor_mediano'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR MEDIANO <p-sortIcon [field]="'valor_mediano'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valor_mediano', 'contains')">
                  </th>
                  <th class="text-center" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS<p-sortIcon [field]="'ativo'">
                        </p-sortIcon></span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="statusOptions"
                      defaultLabel="Selecione" [filter]="false" (onChange)="dt.filter($event.value, 'ativo', 'in')">
                    </p-multiSelect>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-center" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 22%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 21%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 22%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.tipo_cargo}}">{{rowData.tipo_cargo}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor_min | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_min | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor_max | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_max | number :
                        '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor_mediano | number : '1.2-2' : 'pt-BR'}}">{{rowData.valor_mediano |
                        number : '1.2-2' : 'pt-BR'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.ativo? 'ATIVO' : 'INATIVO'}}">{{rowData.ativo? 'ATIVO' : 'INATIVO'}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum cargo encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL NOVO CARGO -->
<p-dialog header="Adicionar novo cargo" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do cargo" [(ngModel)]="codigoNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição do cargo" [(ngModel)]="descricaoNew">
    </div>
    <div class="form-group col-sm-12">
      <label>Nível</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o nível" [options]="nivelList"
        [(ngModel)]="nivelIdNew"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Contrato</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o contrato" [options]="tipoCargoList"
        [(ngModel)]="tipoCargoIdNew"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="cargoAtivo">Ativo</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="salarioMediano">Cadastrar por salário médio?</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-6" *ngIf="!salarioMediano">
      <label>Valor min</label>
      <input class="form-control text-right" [(ngModel)]="valorMinNew">
    </div>
    <div class="form-group col-sm-6" *ngIf="!salarioMediano">
      <label>Valor max</label>
      <input class="form-control text-right" [(ngModel)]="valorMaxNew">
    </div>
    <div class="form-group col-sm-6" *ngIf="salarioMediano">
      <label>Valor médio</label>
      <input class="form-control text-right" [(ngModel)]="valorMedioNew">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addCargo();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- MODAL EDIT CARGO -->
<p-dialog header="Editar cargo" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código do cargo" [(ngModel)]="codigoEdit">
    </div>
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição do cargo" [(ngModel)]="descricaoEdit">
    </div>
    <div class="form-group col-sm-12">
      <label>Nível</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o nível" [options]="nivelList"
        [(ngModel)]="nivelIdEdit"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Contrato</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o contrato" [options]="tipoCargoList"
        [(ngModel)]="tipoCargoIdEdit"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="cargoAtivo">Ativo</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-12">
      <mat-slide-toggle [(ngModel)]="salarioMediano">Cadastrar por salário médio?</mat-slide-toggle>
    </div>
    <div class="form-group col-sm-6" *ngIf="!salarioMediano">
      <label>Valor min</label>
      <input class="form-control text-right" [(ngModel)]="valorMinEdit">
    </div>
    <div class="form-group col-sm-6" *ngIf="!salarioMediano">
      <label>Valor max</label>
      <input class="form-control text-right" [(ngModel)]="valorMaxEdit">
    </div>
    <div class="form-group col-sm-6" *ngIf="salarioMediano">
      <label>Valor médio</label>
      <input class="form-control text-right" [(ngModel)]="valorMedioEdit">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editCargo();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>