<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Funcionários</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>

                  <!-- <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button> -->
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateFunc>
            <p-table class="table-system" #dt [columns]="colsFuncLista" [value]="funcLista" [rows]="rows"
              [paginator]="true" [scrollable]="true"  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              selectionMode="single" [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" (onRowUnselect)="onRowUnselectFunc()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 100px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO<p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 265px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nome', 'contains')">
                  </th>
                  <th class="text-left" style="width: 250px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'area'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">ÁREA <p-sortIcon [field]="'area'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'area', 'contains')">
                  </th>
                  <th class="text-left" style="width: 250px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'titulacao'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">TITULAÇÃO <p-sortIcon [field]="'titulacao'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'titulacao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 250px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cargo'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">CARGO <p-sortIcon [field]="'cargo'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'cargo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 150px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'telefone'">
                      <span class="text-overflow-dynamic-ellipsis">TELEFONE <p-sortIcon [field]="'telefone'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'telefone', 'contains')">
                  </th>
                  <th class="text-center" style="width: 160px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                      <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'cpf', 'contains')">
                  </th>
                  <th class="text-center" style="width: 160px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'rg'">
                      <span class="text-overflow-dynamic-ellipsis">RG <p-sortIcon [field]="'rg'"></p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'rg', 'contains')">
                  </th>
                  <th class="text-center" style="width: 150px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'admissao'">
                      <span class="text-overflow-dynamic-ellipsis">DATA ADMISSÃO <p-sortIcon [field]="'admissao'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'admissao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 150px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'demissao'">
                      <span class="text-overflow-dynamic-ellipsis">DATA DEMISSÃO <p-sortIcon [field]="'demissao'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'demissao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 150px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'experiencia'">
                      <span class="text-overflow-dynamic-ellipsis">DATA EXPERIÊNCIA <p-sortIcon [field]="'experiencia'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'experiencia', 'contains')">
                  </th>
                  <th class="text-center" style="width: 150px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nascimento'">
                      <span class="text-overflow-dynamic-ellipsis">DATA NASCIMENTO <p-sortIcon [field]="'nascimento'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nascimento', 'contains')">
                  </th>
                  <th class="text-center" style="width: 90px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'idade'">
                      <span class="text-overflow-dynamic-ellipsis">IDADE <p-sortIcon [field]="'idade'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'idade', 'contains')">
                  </th>
                  <th class="text-center" style="width: 135px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'banco'">
                      <span class="text-overflow-dynamic-ellipsis">BANCO <p-sortIcon [field]="'banco'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'banco', 'contains')">
                  </th>
                  <th class="text-center" style="width: 135px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'agencia'">
                      <span class="text-overflow-dynamic-ellipsis">AGÊNCIA <p-sortIcon [field]="'agencia'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'agencia', 'contains')">
                  </th>
                  <th class="text-center" style="width: 135px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'conta'">
                      <span class="text-overflow-dynamic-ellipsis">CONTA <p-sortIcon [field]="'conta'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'conta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 220px;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'email', 'contains')">
                  </th>
                  <!-- <th class="text-center" style="width: 95px; vertical-align: sub;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesAtivo" appendTo = "body"
                    (onChange)="dt.filter($event.value, 'ativo', 'contains')"></p-dropdown>
                  </th> -->
                  <!-- <th class="text-center" style="width: 95px; vertical-align: sub;">
                    <br>
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 20px;"
                [ngStyle]="rowData.ativo == true ? {'background-color': ''} : {'background-color': 'rgba(255, 0, 0, 0.3)'}">
                  <td class="text-center" style="width: 100px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 265px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 250px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.area}}">{{rowData.area}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 250px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.titulacao}}">{{rowData.titulacao}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 250px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.cargo}}">{{rowData.cargo}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 150px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.telefone}}">{{rowData.telefone}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 160px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.cpf}}">{{rowData.cpf}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 160px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.rg}}">{{rowData.rg}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 150px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.admissao | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.admissao | date :
                        'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 150px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.demissao | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.demissao | date :
                        'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 150px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.experiencia | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.experiencia | date
                        : 'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 150px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.nascimento | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.nascimento | date :
                        'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 90px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.idade | number}}">{{rowData.idade | number}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 135px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.banco}}">{{rowData.banco}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 135px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.agencia}}">{{rowData.agencia}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 135px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.conta}}">{{rowData.conta}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 220px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelectFunc(rowData)'
                        matTooltip="{{rowData.email}}">{{rowData.email}}</span>
                    </span>
                  </td>
                  <!-- <td class="text-center" style="width: 95px;">
                    <span matTooltip="{{ rowData.ativo }}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo" [binary]="true"
                      inputId="ativo" [disabled]="true"></p-checkbox>
                    </span>
                  </td> -->
                  <!-- <td class="text-center" style="width: 95px;">
                    <button type="button" style="padding: 0 !important;" (click)="openEditFuncionario(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 14px; margin-top: 5px;">edit</span>
                    </button>
                    <button type="button" style="padding: 0 !important;" (click)="deleteFuncionario(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 14px; margin-top: 5px;">delete</span>
                    </button>
                  </td> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td colspan="18" class="spaced-alert" style="width: 3090px;">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum funcionário encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="rowSelected">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Dependentes de {{nomeEdit}}</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMainDep"></app-toolbar>

                <!-- <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button> -->
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateDep>
            <p-table class="table-system" #dt1 [columns]="colsDependenteLista" [value]="dependenteLista" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" (onRowUnselect)="onRowUnselectDep()"
              selectionMode="single" [style]="{'width':'100%'}" sortField="codigo" [sortOrder]="1" >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 35%;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">NOME DEPENDENTE <p-sortIcon [field]="'nome'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'nome', 'contains')">
                  </th>
                  <th class="text-left" style="width: 35%;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                      <span class="text-overflow-dynamic-ellipsis"  style="margin-left: 20px;">TIPO<p-sortIcon [field]="'tipo'"></p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'tipo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nascimento'">
                      <span class="text-overflow-dynamic-ellipsis">DATA NASCIMENTO <p-sortIcon [field]="'nascimento'">
                        </p-sortIcon></span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'nascimento', 'contains')">
                  </th>
                  <th class="text-center" style="width: 8%;">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'idade'">
                      <span class="text-overflow-dynamic-ellipsis">IDADE <p-sortIcon [field]="'idade'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'idade', 'contains')">
                  </th>
                  <!-- <th class="text-center" style="width: 90px; vertical-align: sub;">
                    <br>
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                    </span>
                  </th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectDep(rowData)"
                        matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectDep(rowData)"
                        matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectDep(rowData)"
                        matTooltip="{{rowData.nascimento | date : 'dd/MM/yyyy' : '+000'}}">{{rowData.nascimento | date :
                        'dd/MM/yyyy' : '+000'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"  (click)="onRowSelectDep(rowData)"
                        matTooltip="{{rowData.idade | number}}">{{rowData.idade | number}}</span>
                    </span>
                  </td>
                  <!-- <td class="text-center" style="width: 90px;">
                    <button type="button" style="padding: 0 !important;" (click)="openEditDependente(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                      <span class="material-icons"  style="font-size: 14px">edit</span>
                    </button>
                    <button type="button" style="padding: 0 !important;" (click)="deleteDependente(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 14px">delete</span>
                    </button>
                  </td> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum dependente encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL FUNC -->
<p-dialog header="Funcionário" [(visible)]="modalFunc" [style]="{ width: '900px' }" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-2">
      <label>Código<span class="obrigatorio">*</span></label>
      <input class="form-control text-left" placeholder="Código do Funcionário" [(ngModel)]="funcionario.codigo">
    </div>
    <div class="form-group col-sm-8">
      <label>Nome<span class="obrigatorio">*</span></label>
      <input class="form-control text-left" placeholder="Nome do Funcionário" [(ngModel)]="funcionario.nome">
    </div>
    <div *ngIf="editFunc" class="form-group col-sm-2">
      <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
      <p-checkbox [binary]="true" [(ngModel)]="funcionario.ativo"></p-checkbox>
      <label style="padding: 0 5px;">Ativo</label>
    </div>
    <div class="form-group col-sm-6">
      <label>Área</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a área"
        [options]="areaList" [(ngModel)]="funcionario.area.id"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Cargo<span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o Cargo" [options]="cargoList"
        [(ngModel)]="funcionario.cargo.id"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Gerente</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o Gerente" [options]="gerenteList"
        [(ngModel)]="funcionario.gerente.id"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Titulação</label>
      <input class="form-control text-left" placeholder="Títulação do Funcionário" [(ngModel)]="funcionario.titulacao">
    </div>
    <div class="form-group col-sm-6">
      <label>1ª Graduação</label>
      <input class="form-control text-left" placeholder="1ª Graduação do Funcionário" [(ngModel)]="funcionario.graduacao">
    </div>
    <div class="form-group col-sm-6">
      <label>Especialização</label>
      <input class="form-control text-left" placeholder="Especialização do Funcionário" [(ngModel)]="funcionario.especializacao">
    </div>
    <div class="form-group col-sm-3">
      <label>País</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o País" [options]="paisList"
        (onChange)="changePais()" [(ngModel)]="funcionario.pais.id"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Cidade</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a Cidade" [disabled]="funcionario.pais.id && funcionario.pais.id != 105"
        [options]="cidadeList" [(ngModel)]="funcionario.cidade.id"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>CPF</label>
      <input class="form-control text-left" placeholder="000.000.000-00" [(ngModel)]="funcionario.cpf">
    </div>
    <div class="form-group col-sm-3">
      <label>RG</label>
      <input class="form-control text-left" placeholder="00.000.000-0" [(ngModel)]="funcionario.rg">
    </div>
    <div class="form-group col-sm-3">
      <label>Gênero</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o Gênero" [options]="sexoList"
        [(ngModel)]="funcionario.sexo"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Data de Nascimento</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="funcionario.nascimento"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-3">
      <label>Data Inicial da Carreira</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="funcionario.carreira"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="1950:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-3">
      <label>Data de Admissão</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="funcionario.admissao"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-3">
      <label>Data de demissão</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="funcionario.demissao"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-3">
      <label>Porcentagem de Atuação</label>
      <input class="form-control text-left" placeholder="0" [(ngModel)]="funcionario.atuacao" type="number">
    </div>
    <div class="form-group col-sm-3">
      <label>Email</label>
      <input class="form-control text-left" placeholder="you@example.com" [(ngModel)]="funcionario.email">
    </div>
    <div class="form-group col-sm-2">
      <label>Telefone</label>
      <input class="form-control text-left" placeholder="(00) 0000-0000" [(ngModel)]="funcionario.telefone">
    </div>
    <div class="form-group col-sm-1">
      <label>Banco</label>
      <input class="form-control text-left" placeholder="000" [(ngModel)]="funcionario.banco">
    </div>
    <div class="form-group col-sm-1">
      <label>Agência</label>
      <input class="form-control text-left" placeholder="0000" [(ngModel)]="funcionario.agencia">
    </div>
    <div class="form-group col-sm-3">
      <label>Conta</label>
      <input class="form-control text-left" placeholder="00000-0" [(ngModel)]="funcionario.conta">
    </div>
    </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalFunc=false'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button *ngIf="!editFunc" type="button" class="btn btn-primary" (click)="addFuncionario()"><i class="fas fa-check"></i>
          Salvar</button>
          <button *ngIf="editFunc" type="button" class="btn btn-primary" (click)="editFuncionario();"><i class="fas fa-check"></i>
            Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Modal dependente -->
<p-dialog header="Dependente" [(visible)]="modalDep" [style]="{width: '500px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Nome</label>
      <input class="form-control text-left" placeholder="Nome do dependente" [(ngModel)]="dependente.nome">
    </div>
    <div class="form-group col-sm-12">
      <label>Tipo</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o tipo" [options]="tipoDepList"
        [(ngModel)]="dependente.tipo"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Data de nascimento</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dependente.nascimento"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalDep=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button *ngIf="!editDep" type="button" class="btn btn-primary" (click)="addDependente();"><i class="fas fa-check"></i>
          Salvar</button>
          <button *ngIf="editDep" type="button" class="btn btn-primary" (click)="editDependente();"><i class="fas fa-check"></i>
            Salvar</button>

      </div>
    </div>
  </p-footer>
</p-dialog>