<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>




  <mat-card-content>
    <div class="box-content">
      <div class=" {{(exibirItemContabil) ? 'lks-table-scroller' : ''}}">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title">Contas</h3>
          </div>
        </div>
        <p-table class="table-system" #dt1 [value]="planoContasList" [rows]="rows" [paginator]="true"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
          (onRowSelect)="onRowSelect($event)" (onFilter)="onFilter($event)"
          [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 200px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'codCta', 'contains')">
              </th>
              <th class="text-center" style="width: 100%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'descrCta', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'nivel', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">TIPO <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'indCta', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-left" style="width: 200px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codCta}}">{{rowData.codCta}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
                </span>
              </td>
              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indCta}}">{{rowData.indCta}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div>
    </div>

    <div *ngIf="exibirItemContabil">
      <div class="box-content mt-4">

        <div class="form-row">
          <div class="form-group col-sm-12 mb-1 mt-1">
            <h3 class="sub-title">Contas</h3>
          </div>
        </div>
        <p-table class="table-system" #dt2 [value]="itensList" [rows]="rows" [paginator]="false" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" (onFilter)="onFilter($event)"
          [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''" [scrollable]="itensList.length >= 11 ? true : false"
          scrollHeight="250px">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-left" style="width: 40px;">
                <p-tableHeaderCheckbox (click)="selecionarTodos()"></p-tableHeaderCheckbox>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th class="text-center" style="width: 90%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                  <span class="text-overflow-dynamic-ellipsis" style="height: 12px;">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'descricao', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 40px;">
                <p-tableCheckbox (click)="check(rowData.id,rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 90%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="3" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12 mt-3">
          <button class="btn btn-sm btn-info" type="button" (click)="add()" matTooltip="Adicionar Contas"><i
              class="fas fa-angle-double-down"></i> Adicionar</button>
          <button class="btn btn-sm btn-info" type="button" (click)="remove()" matTooltip="Remover Contas"><i
              class="fas fa-angle-double-up"></i> Remover</button>
        </div>
      </div>
      <div class="box-content">
        <div class="form-row">
          <div class="form-group col-sm-12 mt-1 mb-1">
            <h3 class="sub-title">Contas Mapeadas</h3>
          </div>
        </div>

        <p-table class="table-system" #dt3 [value]="itensReferenciadosList" [rows]="rows" [paginator]="false"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" (onFilter)="onFilter($event)"
          [ngClass]="contadorLinha < 11 ? 'no-overflow-y' : ''"
          [scrollable]="itensReferenciadosList.length >= 11 ? true : false" scrollHeight="250px">

          <ng-template pTemplate="header">
            <tr>
              <th class="text-center" style="width: 40px;">
                <span class="text-overflow-dynamic-container">
                  <span class="check-all">
                       <p-tableHeaderCheckbox (click)="selecionarTodos()"></p-tableHeaderCheckbox>
                  </span>
                </span>
              </th>
              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th class="text-center" style="width: 90%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'descricao', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-center" style="width: 40px;">
                <span class="text-overflow-dynamic-container">
                  <span class="check-all">
                    <p-checkbox></p-checkbox>
                  </span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 90%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="3" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </mat-card-content>