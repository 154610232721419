<mat-card class="card-linnks margin-linnks">

  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
      <div class="row">
        <div class="col-sm-8 col-xxl-6">
          <div class="shadow-box">

            <div class="header-box">
              <div class="header-box-content">
                <h3 class="title-card"><i class="fas fa-file-upload" style="font-size: 14.5px;"></i> IMPORTAÇÃO DE ARQUIVOS</h3>
                <div class="actions-card">
                  <a href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_FINAL_ORCAMENTO.csv" matTooltip="Baixar Layout" matTooltipPosition="above">
                    <span class="material-icons" style="font-size: 23px; margin-right: -5px;color: #6c757d;">cloud_download</span>
                  </a>
                  <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardImport()"
                    (click)="changeStateImport()">
                    <span *ngIf="isCardImport" class="material-icons mb-3" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                    <span *ngIf="!isCardImport" class="material-icons mb-3" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                  </button>
                </div>
            </div>
          </div>

      <div class="box-content"  [@changeDivSize]=currentStateImport>
        <div *ngIf="empresaId != 1 && empresaId != 115 && empresaId != 133">
          <div class="form-row" *ngIf="empresaId === 277">
            <div class="form-group col-sm-12">
              <label>Selecione o tipo de arquivo</label>
              <p-dropdown class="type-p-dropdown" [(ngModel)]="idTipoArquivo"
                [options]="listTipoArquivo" [filter]="false" placeholder="Selecionar o tipo de arquivo"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Selecione a versão</label>
              <p-dropdown class="type-p-dropdown" [(ngModel)]="idVersaoSelecionada"
                [options]="listVersoesAberta" [filter]="false" placeholder="Selecionar a versão"
                (onChange)="verificarSeTemAVersaoSelecionada($event)"></p-dropdown>
            </div>
          </div>

        </div>

        <div *ngIf="empresaId === 1 || empresaId === 115 || empresaId == 133">

          <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Selecione o Tipo Arquivo</label>
            <p-dropdown class="type-p-dropdown" [options]="listTipoArquivoFini" [filter]="false"
              placeholder="Selecionar uma versão" [(ngModel)]="idTipoArquivoSelecionado"></p-dropdown>
          </div>
        </div>

          <div class="form-row" *ngIf="idTipoArquivoSelecionado === 'DESPESA' || idTipoArquivoSelecionado === 'VENDA' ">
            <div class="form-group col-sm-12">
              <label>Selecione a versão</label>
              <p-dropdown class="type-p-dropdown" [options]="listVersoesFini" [filter]="false"
                placeholder="Selecionar uma versão" [(ngModel)]="idVersaoSelecionada"></p-dropdown>
            </div>
          </div>

          <div class="form-row" *ngIf="idTipoArquivoSelecionado === 'DESPESA'">
            <div class="form-group col-sm-12">
              <label>Área</label>
              <p-multiSelect class="type-p-mult" [options]="opcoesArea" defaultLabel="Selecione área" [filter]="true"
              [(ngModel)]="areaSelecionadas" placeholder="{0} itens selecionados"
                selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12 mb-1 mt-1">
            <label class="d-block">Selecione um arquivo para importar</label>
            <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
              <span class="input-group-append">
                <span class="input-group-text btn-file">
                  <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                    Arquivo</span>
                  <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                    Arquivo</span>
                  <input type="file" #file (change)="fileChange()" />
                </span>
              </span>
              <div class="form-control" data-trigger="fileinput">
                <span class="fileinput-filename"></span>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="idTipoArquivo ===  3">
            <div class="form-group col-sm-12 mb-3 mt-1 ml-1">
          <p-checkbox [binary]="true" [(ngModel)]="boolSubsPedido">Substituir Pedidos</p-checkbox>
          <label style="padding: 0 5px;">Substituir Pedidos</label>
        </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col mb-0">
            <button type="button" class="btn btn-primary" [disabled]="importDisabled" (click)="importar()"><i
                class="fas fa-upload"></i> Importar</button>
          </div>
        </div>

      </div>

      </div>
    </div>
    </div>
  </mat-card-content>
</mat-card>
