<mat-card class="card-linnks margin-linnks"  *ngIf="tipo != 0">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="row">
    <div class="col pb-3">
      <div>
        <div class="shadow-box">
          <div class="header-box">

                <div class="header-box-content p-3">
                <h3 class="title-card">EFETUAR LANÇAMENTOS USGAAP</h3>

            </div>

          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStatePesquisaLancamentos>

            <div class="form-row">
              <!--<div class="form-group col-sm-1 div-checkbox-lancamento" style="min-width: 132px;">
                <p-checkbox class="mr-1" [(ngModel)]="planoLocal" [binary]="true" inputId="recorrencia"
                  label="Plano Local"></p-checkbox>
              </div>-->
              <div class="form-group col-sm-2">
                <label>PLANO GERENCIAL <span class="obrigatorio"></span></label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesGerenciais" [filter]="true"
                  placeholder="Selecione o Relatório Gerencial" [(ngModel)]="idReferencial"></p-dropdown>
                </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-1" style="min-width: 132px;">
                <label>Data <span class="obrigatorio">*</span></label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataLcto"
                  [monthNavigator]="true" [defaultDate]="defaultDate" [yearNavigator]="true" yearRange="2010:2030">
                </p-calendar>
              </div>

              <div class="form-group col-sm-2" style="margin-right: 10px; margin-left: 10px;">
                <label>DÉBITO <span class="obrigatorio">*</span></label>
                <p-dropdown [(ngModel)]="debitoPc" *ngIf="planoLocal" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContas"></p-dropdown>
                <p-dropdown [(ngModel)]="debitoPgc" *ngIf="!planoLocal" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContasGerencial"></p-dropdown>
              </div>
              <div class="form-group col-sm-2" style="margin-right: 10px; margin-left: 10px;">
                <label>CRÉDITO <span class="obrigatorio">*</span></label>
                <p-dropdown [(ngModel)]="creditoPc" *ngIf="planoLocal" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContas"></p-dropdown>
                <p-dropdown [(ngModel)]="creditoPgc" *ngIf="!planoLocal" class="type-p-dropdown" [filter]="true" [options]="opcoesPlanoContasGerencial"></p-dropdown>
              </div>
              <div class="form-group col-sm-2" style="margin-right: 10px; margin-left: 10px;" *ngIf="planoLocal">
                <label>CENTRO DE CUSTO</label>
                <p-dropdown [(ngModel)]="ccus" class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"></p-dropdown>
              </div>
              <div class="form-group col-sm-2" style="margin-right: 10px; margin-left: 10px;">
                <label>TIPO DE AJUSTE <span class="obrigatorio">*</span></label>
                <p-dropdown [(ngModel)]="tipoAjuste" class="type-p-dropdown" [filter]="true" [options]="opcoesTipoAjuste"></p-dropdown>
              </div>
              <div class="form-group col-sm-2" style="min-width: 132px;">
                <label>Valor <span class="obrigatorio">*</span></label>
                <input [(ngModel)]="valor" class="form-control text-right" id="input" type="text" money />
              </div>
              </div>
              <div class="row">
              <div class="col-sm-6" style="min-width: 132px;">
                <label>HISTÓRICO <span class="obrigatorio">*</span></label>
                <input [(ngModel)]="historico" class="form-control" id="input" type="text" [(ngModel)]="historico">
              </div>

              <div class="col-sm-2 mt-4">
                  <button type="button"  class="btn btn-primary" (click)="salvarLctoUsGaap()">
                  <i class="fas fa-save"></i>
                  Salvar
                </button>


              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col pb-3">
      <div>
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">LANÇAMENTOS DE USGAAP</h3>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStateResultadosLancamentos>
            <div class="row" style="padding: 14px;">


              <p-table class="table-system" #dt1 [value]="lctos" [paginator]="true"
                [pageLinks]="pageLinks" [rows]="rows" [rowsPerPageOptions]="rowsPerPage">

                <ng-template pTemplate="header">
                  <tr>


                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                        <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'data'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'data', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'debito'">
                        <span class="text-overflow-dynamic-ellipsis">DÉBITO <p-sortIcon [field]="'debito'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'debito', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'credito'">
                        <span class="text-overflow-dynamic-ellipsis">CRÉDITO <p-sortIcon [field]="'credito'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'credito', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ccus'">
                        <span class="text-overflow-dynamic-ellipsis">CENTRO CUSTO <p-sortIcon [field]="'ccus'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'ccus', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'historico'">
                        <span class="text-overflow-dynamic-ellipsis">Histórico <p-sortIcon [field]="'historico'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'historico', 'contains')">
                    </th>

                    <th class="text-left" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                        <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'valor', 'contains')">
                    </th>

                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">Ação</span>
                    </th>

                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">

                    <td style="width: 10%;">
                      <span class="overflow-ellipse text-center" matTooltip="{{rowData.data}}">
                        {{rowData.data}}
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.debito}}">
                        {{rowData.debito}}
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.credito}}">
                        {{rowData.credito}}
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.ccus}}">
                        {{rowData.ccus}}
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.historico}}">
                        {{rowData.historico}}
                      </span>
                    </td>
                    <td style="width: 15%;">
                      <span class="overflow-ellipse text-left" matTooltip="{{rowData.valor}}">
                        {{rowData.valor}}
                      </span>
                    </td>

                    <td style="width: 10%;" class="text-center">
                      <div class="actionColumn">
                        <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Clonar"
                        matTooltipPosition="above" (click)="modalClonar(rowData.id)">
                          <span class="material-icons">file_copy</span>
                        </button>
                        <button class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above" (click)="excluirLcto(rowData.id)">
                          <span class="material-icons">delete</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  </mat-card >


  <p-dialog header="Clonar Lançamento" [(visible)]="clonarVisible" styleClass="altura-inicial-relacionar"
    [style]="{width: '600px'}" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Data <span class="obrigatorio">*</span></label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataLcto"
          [monthNavigator]="true" [defaultDate]="defaultDate" [yearNavigator]="true" yearRange="2010:2030">
        </p-calendar>

      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="clonarVisible = false">Cancelar </button>
      <button type="button" class="btn btn-primary" (click)="clonarLcto()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>


  <mat-card class="card-linnks margin-linnks"  *ngIf="tipo == 0">
    <mat-card-title>
      {{titlePrincipal}}
      <mat-card-subtitle>
       PERÍODOS USGAAP
      </mat-card-subtitle>
    </mat-card-title>

  <div class="box-content">


    <div class="row">
      <div class="col-sm-12">
        <div class="pull-right">
        </div>
        <div class="clearfix"></div>
        <p-table class="table-system" #dt [value]="periodoUsGaap" [rows]="rows" [paginator]="false"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">MÊS </span>
                </span>
              </th>
              <th class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">STATUS </span>
                </span>
              </th>
              <th class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">DT ENCERRAMENTO </span>
                </span>
              </th>
              <th class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">USUÁRIO </span>
                </span>
              </th>
              <th class="text-center" style="width: 90px; vertical-align: sub;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    {{ rowData.mes }}
                  </span>
                </span>
              </td>
              <td class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    {{ rowData.status }}
                  </span>
                </span>
              </td>
              <td class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    {{ rowData.dataEncerramento }}
                  </span>
                </span>
              </td>
              <td class="text-left" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    {{ rowData.usuario }}
                  </span>
                </span>
              </td>
              <td class="text-center" style="width: 90px;">
                <button *ngIf="rowData.statusId === 1" type="button" style="padding: 0 !important;"
                  (click)="encerrarPeriodo(rowData)" class="btn btn-xs btn-link btn-full" matTooltip="Encerrar"
                  matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 22px">check</span>
                </button>
                <button *ngIf="rowData.statusId === 2" type="button" style="padding: 0 !important;"
                  (click)="encerrarPeriodo(rowData)" class="btn btn-xs btn-link btn-full" matTooltip="Abrir"
                  matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 22px">folder-open</span>
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="5" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</mat-card>
