<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>
  <div class="d-flex flex-column">
    <h3 class="sub-title mt-3">
      Relatório de pedidos para visualização na tela ou exportar para excel
    </h3>
    <div class="cardList card-deck card-custom">
      <div class="card" *ngIf="tipoPedido == 0">
        <div class="card-body">
          <span class="icon-card" (click)="showDialogRelatorio('Compra')"><i class="fas fa-chart-bar"></i></span>
          <h5 class="card-title" (click)="showDialogRelatorio('Compra')" matTooltip="Pedidos de Compra"
            matTooltipPosition="above" style="width: 150px;"><span class="d-block">Pedidos de Compra</span></h5>
        </div>
      </div>
      <div class="card" *ngFor="let relat of listaRelat">
        <div class="card-body">
          <span class="icon-card" (click)="showDialogRelatorio(relat)"><i class="fas fa-chart-bar"></i></span>
          <h5 class="card-title" (click)="showDialogRelatorio(relat)" matTooltip="{{relat.nome}}"
            matTooltipPosition="above" style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
        </div>
      </div>

      <div class="card" *ngIf="tipoPedido == 1">
        <div class="card-body">
          <span class="icon-card" (click)="exibirModal(false)"><i class="fas fa-chart-bar"></i></span>
          <h5 class="card-title" (click)="exibirModal(false)" matTooltip="Relatório de Vendas" matTooltipPosition="above"
            style="width: 150px;"><span class="d-block">Relatório de Vendas</span></h5>
        </div>
      </div>

      <div class="card" *ngIf="tipoPedido == 1">
        <div class="card-body">
          <span class="icon-card" (click)="exibirModal(true)"><i class="fas fa-chart-bar"></i></span>
          <h5 class="card-title" (click)="exibirModal(true)" matTooltip="Relatório de Vendas" matTooltipPosition="above"
            style="width: 150px;"><span class="d-block">Relatório de Comissão</span></h5>
        </div>
      </div>

    </div>
  </div>


  <p-dialog header="pedidos de compra" [modal]="true" appendTo="body" [style]="{width: '700px'}" [contentStyle]="{height: '423px'}"
    styleClass="modal-scroll" [(visible)]="modalPedidosCompra" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Mês Inicial</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesIni" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
        <!-- <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar> -->
      </div>

      <div class="form-group col-sm-3">
        <label>Mês Final</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="mesFin" [options]="meses" defaultLabel="Selecione"
          [filter]="true"></p-dropdown>
        <!-- <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar> -->
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Participante</label>
        <p-multiSelect appendTo="body" [(ngModel)]="fornecedor" [options]="listaFornecedor" [filter]="true"
          defaultLabel="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados"
          panelStyleClass="custom-width"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Pedido</label>
        <input class="form-control" [(ngModel)]="pedido" type="text" />
      </div>
      <div class="form-group col-sm-4">
        <label>Tipo</label>
        <p-dropdown class="type-p-dropdown" appendTo="body" [(ngModel)]="tipo" [options]="listaTipo" [filter]="true"
          placeholder="Selecione"></p-dropdown>
      </div>
      <div class="form-group col-sm-4">
        <label>Status</label>
        <p-multiSelect appendTo="body" [(ngModel)]="status" [options]="listaStatus" [filter]="true"
          defaultLabel="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Comprador</label>
        <p-multiSelect appendTo="body" [(ngModel)]="comprador" [options]="listaComprador" [filter]="true"
          defaultLabel="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados"
          panelStyleClass="custom-width"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <p-checkbox class="mr-1" [(ngModel)]="detalhePorItem" [binary]="true" inputId="detalhePorItem"></p-checkbox>
        <label for="detalhePorItem" class="ml-1" style="position:relative; margin-top: 1px">Detalhe por Item</label>
      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>

    <p-footer>
      <button class="btn btn-primary" [disabled]="((mesFin < mesIni))" type="button" (click)="validaModal()"><i
          class="fas fa-laptop"></i> Tela</button>
          <button class="btn btn-primary" [disabled]="((mesFin < mesIni))" type="button" (click)="validaModalExcel()"><i
            class="fas fa-laptop"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal relatório gerencial -->
  <p-dialog header="{{relatorioGerSelecionado.nome}}" [modal]="true" appendTo="body" width="500"
    styleClass="modal-initial" [(visible)]="modalRelatorio" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="!relatorioGerSelecionado.is_composta">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>

    <div class="row" *ngIf="relatorioGerSelecionado.is_composta">
      <div class="form-group col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>DATA REFERÊNCIA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef" [disabled]="datasIndividuais">
            </p-calendar>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <p-checkbox [(ngModel)]="datasIndividuais">Utilizar datas individuais</p-checkbox>
            <label style="padding: 0 5px;">utilizar datas individuais</label>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="form-row">
          <p-table class="table-system" [value]="dicRelatComposto[relatorioGerSelecionado.id]"
            emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                  Data de referência</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                  *ngIf="datasIndividuais"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-config>
              <tr>
                <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                <td style="text-align: center">{{config.worksheet_title}}</td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                    [yearNavigator]="true" [defaultDate]="dataRef" yearRange="2000:2030"
                    [(ngModel)]="config['dataRef']">
                  </p-calendar>
                </td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                      (click)="carregarValores(config)"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <p-footer>
      <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
        *ngIf="!relatorioGerSelecionado.is_composta"><i class="fas fa-laptop"></i> Tela</button>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!--Modal relatorio de vendas-->
  <p-dialog [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
    [(visible)]="modalRelatVendas" [responsive]="true" [closable]="true" onHide="onCloseModal()">

      <p-header> {{ modalTitle }} </p-header>

    <div class="form-row">

      <div class="form-group col-sm-4">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtiniMonth"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>

      <div class="form-group col-sm-4">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtmaxMonth"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>

    </div>

    <div *ngIf="isComissionRelat" class="form-row">

      <div class="form-group col-sm-12">
        <label>Vendedores</label>
        <p-multiSelect class="type-p-mult" [options]="listVendedor" [(ngModel)]="vendedorSelecionados"
        defaultLabel="Selecione Vendedor" selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>

      <div class="d-flex align-items-center justify-content-start form-group col-sm-4">
        <mat-slide-toggle [(ngModel)]="cashierView">Visão Caixa</mat-slide-toggle>
      </div>

    </div>

    <p-footer>
      <!-- <button *ngIf="isComissionRelat" [disabled]="((!dtIni|| !dtFin || dtFin < dtIni)) || (vendedorSelecionados && vendedorSelecionados.length == 0)"
        class="btn btn-primary" type="button" (click)="reportVendas(2)"><i class="fas fa-laptop"></i> Tela
      </button> -->

      <button *ngIf="isComissionRelat" class="btn btn-primary" type="button"
        (click)="reportVendas(1)"><i class="far fa-file-excel"></i> Excel
      </button>

      <button *ngIf="!isComissionRelat" class="btn btn-primary" type="button"
        (click)="exportDetails()"><i class="far fa-file-excel"></i> Excel
      </button>

    </p-footer>
  </p-dialog>

</mat-card>
