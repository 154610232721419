<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <mat-tab label="Carteira por cliente">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-wallet"></i> Carteira
                  <div class="pull-right">
                        <button type="button" class="btn btn-xs btn-link" (click)="exportarCSV()" matTooltip="Exportar Excel"
                          matTooltipPosition="above"><i class="far fa-file-excel excel" style="font-size: 18px; margin-top: -10px;"></i> </button>
                  </div>
                </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
                <!-- <p-dropdown class="type-p-dropdown" [filter]="false" [options]="aprovadoresTecnicos" -->
                    <!-- placeholder="Selecione o Usuário" [(ngModel)]="aprovId" (onChange)="buscarUsuariosAprovador()"></p-dropdown> -->
                  <p-dropdown class="type-p-dropdown" [style]="{'min-width': '384px'}" [filter]="true" [options]="carteiras"
                placeholder="Selecione a carteira" [(ngModel)]="carteiraId" (onChange)="buscarCarteiras();buscarNovoConsultor(carteiraId) "></p-dropdown>

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-user"></i> Cliente</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="conteudo m-0">
                <p-pickList styleClass="fixe-width-picklist" sourceHeader="Disponíveis" targetHeader="Clientes Relacionados" [source]="listaCarteiraSemRelacionamento" [target]="listaCarteiraRelacionadas" [dragdrop]="true" [responsive]="true" filterBy="nomeCliente"
                [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-carteiraCliente pTemplate="item">{{carteiraCliente.nomeCliente}} - {{carteiraCliente.emailCliente}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

  <!-- Inicio Model Alternar periodo -->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

    <div class="form-row">
      <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)"  >

        </p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
      </div>
    </div>


  <p-footer>
    <button  type="button" class="btn btn-secondary" (click)='modalPeriodo=false; dataIni = null; dataFin = null'><i class="fas fa-undo"></i> Cancelar</button>
    <button  type="button" class="btn btn-primary" (click)="buscarCarteiras();modalPeriodo=false;"><i class="fas fa-pencil-alt"></i> Alterar</button>
  </p-footer>
  </p-dialog>
  <!-- Final model Alternar periodo -->

  <!-- Inicio Model Transferir Carteira -->
  <p-dialog header="Transferir Carteira" [(visible)]="modalTransferirCarteira" [style]="{width: '880px'}" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

      <!--Títulos Status Anterior / Atual-->
      <div class="row">
        <div class="col-sm-6">
          <h3 class="sub-title"><i class="fas fa-bars"> </i> Status Anterior</h3>
        </div>
        <div class="col-sm-6">
          <h3 class="sub-title"><i class="fas fa-bars"> </i> Status Atual</h3>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-6">
              <p-dropdown appendTo="body" class="type-p-dropdown" panelStyleClass="custom-width" [filter]="true" [options]="carteiras" [disabled]="true"
            placeholder="Selecione a carteira" [(ngModel)]="carteiraId" ></p-dropdown>
        </div>

        <div class="form-group col-sm-6">
            <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="custom-width" [filter]="true" [options]="carteiraNewConsultor"
          placeholder="Selecione a carteira" [(ngModel)]="carteiraIdNew" (onChange)="buscarNovoCarteira()" ></p-dropdown>
        </div>

      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="conteudo m-0">
            <p-pickList styleClass="fixe-width-picklist" sourceHeader="Clientes Relacionados" targetHeader="Clientes Relacionados" [source]="listaCarteiraRelacionadasOld" [target]="listaCarteiraNewConsultor" [dragdrop]="true" [responsive]="true" filterBy="nomeCliente"
              [showSourceControls]="false" [showTargetControls]="false">
              <ng-template let-carteiraCliente pTemplate="item">{{carteiraCliente.nomeCliente}} - {{carteiraCliente.emailCliente}}</ng-template>
            </p-pickList>
          </div>
        </div>
      </div>

    <p-footer>
      <button  type="button" class="btn btn-secondary" (click)='modalTransferirCarteira=false; '><i class="fas fa-undo"></i> Cancelar</button>
      <button  type="button" class="btn btn-primary" (click)="salvarTransferirCarteira();modalTransferirCarteira=false;"><i class="fas fa-pencil-alt"></i> Alterar</button>
    </p-footer>
  </p-dialog>
  <!-- Final  Model Transferir Carteira -->

