<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsEventosPlano" [value]="listaEventosPlano" [rows]="rows"
          [totalRecords]="listaEventosPlano.length" [paginator]="true" (onFilter)="onFilter($event)"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 28%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoCompleta'">
                  <span class="text-overflow-dynamic-ellipsis">EVENTO <p-sortIcon [field]="'descricaoCompleta'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'dataInicioEvento', 'contains')">
              </th>
              <th class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataInicioEvento'">
                  <span class="text-overflow-dynamic-ellipsis">DT INI. EVENT. <p-sortIcon [field]="'dataInicioEvento'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'descricaoEvento', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                  <span class="text-overflow-dynamic-ellipsis">UF <p-sortIcon [field]="'uf'"></p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboUf" defaultLabel="Selecione" [filter]="false"
                  (onChange)="dt2.filter($event.value, 'uf', 'in')"></p-multiSelect>
              </th>

              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeCidade'">
                  <span class="text-overflow-dynamic-ellipsis">CIDADE <p-sortIcon [field]="'nomeCidade'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nomeCidade', 'contains')">
              </th>


              <th class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                  <span class="text-overflow-dynamic-ellipsis">PARCELA Nº <p-sortIcon [field]="'parcela'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'parcela', 'contains')">
              </th>

              <th class="text-right" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorUnit'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR UNIT <p-sortIcon [field]="'valorUnit'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'valorUnit', 'contains')">
              </th>

              <th class="text-right" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'valor', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'recorrenciaPt'">
                  <span class="text-overflow-dynamic-ellipsis">RECORRÊNCIA <p-sortIcon [field]="'recorrenciaPt'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'recorrenciaPt', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center" style="width: 45px;">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricaoCompleta}}"><span
                      style="user-select: all; cursor: text"> {{rowData.descricaoCompleta }} </span></span>
                </span>
              </td>

              <td class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataInicioEvento}}"> <span
                      style="user-select: all; cursor: text"> {{rowData.dataInicioEvento}} </span></span>
                </span>
              </td>
              <td class="text-center" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}"> <span
                      style="user-select: all; cursor: text"> {{rowData.uf}} </span></span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeCidade}}"> <span
                      style="user-select: all; cursor: text"> {{rowData.nomeCidade }} </span></span>
                </span>
              </td>

              <td class="text-right" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.parcela}}"> <span
                      style="user-select: all; cursor: text"> {{rowData.parcela }} </span></span>
                </span>
              </td>


              <td class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorUnit}}"> <span
                      style="user-select: all; cursor: text"> {{rowData.valorUnit }} </span></span>
                </span>
              </td>

              <td class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}"> <span
                      style="user-select: all; cursor: text"> {{rowData.valor }} </span></span>
                </span>
              </td>

              <td class="text-center" style="width: 5%;">

                <p-checkbox class="mr-1" [(ngModel)]="rowData.recorrencia" [binary]="true"
                inputId="recorrenciadisabled" [disabled]="true"></p-checkbox>

              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="13" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>


      <p-dialog header="PLANO" [(visible)]="modalEventoPlano" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">



        <!-- modalConsultor -->
        <div *ngIf="modalEventoPlano">


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>EVENTO</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
                [options]="opcoesEventoPlano" [(ngModel)]="eventoId"></p-dropdown>
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>PARCELA</label>
              <input type="number" class="form-control text-right" value="{{plano.parcela}}" [(ngModel)]="plano.parcela"
                max="12" />
            </div>
          </div>



          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Valor Unitário</label>
              <input type="text" class="form-control text-right" value="{{plano.valorUnit}}"
                [(ngModel)]="plano.valorUnit" money />
            </div>

            <div class="form-group col-sm-6">
              <label>Valor</label>
              <input class="form-control text-right" value="{{plano.valor}}" [(ngModel)]="plano.valor" money />
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-4">
              <mat-slide-toggle class="mr-2" [(ngModel)]="plano.recorrencia"> Recorrência </mat-slide-toggle>
            </div>
          </div>


          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>


        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalEventoPlano=false;"><i class="fas fa-undo"></i>
            Cancelar</button>
          <button *ngIf="!editEventoPlano" type="button" class="btn btn-primary" (click)="salvarEventoPlano();"
            [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>
          <button *ngIf="editEventoPlano" type="button" class="btn btn-primary" (click)="editarEventoPlano();"
            [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>
        </p-footer>
      </p-dialog>
      <!--Final Modal cadastro de Palestras-->

    </mat-card-content>
  </div>

</mat-card>