
<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content" style="padding: 1.5rem !important;">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registrosMunicipioIbge" [rows]="rows"
            [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
            immutable="false" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
            selectionMode="single"  [(selection)]="selectM">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 40px;">
                </th>
                <th class="text-left" style="width: 120px;">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'uf.id'">
                    Uf <p-sortIcon [field]="'uf.id'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'uf.id', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'nome'">
                    Município <p-sortIcon [field]="'nome'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'nome', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'cod'">
                    Código Ibge <p-sortIcon [field]="'cod'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'cod', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 40px;">
                  <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData">
                  </p-tableRadioButton>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{getUfNome(rowData.uf?.id)}}">
                      {{getUfNome(rowData.uf?.id)}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                      {{rowData.nome}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cod}}">
                      {{rowData.cod}}
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="4" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          </div>
        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content" style="padding: 1.5rem !important;">
            <div class="space">
            <div class="actions-card spaceCard">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
          </div>
        </div>

        <div class="box-content">
          <div class="form-size">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Uf<span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown"
                  placeholder="Selecione"
                  [options]="ufs"
                  [(ngModel)]="selectedUf"
                  optionLabel="uf"
                  optionValue="id">
                </p-dropdown>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Município<span class="obrigatorio">*</span></label>
                <input
                  class="form-control"
                  id="input"
                  type="text"
                  pInputText
                  name="municipio"
                  [(ngModel)]="municipio"
                  >
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Código Ibge<span class="obrigatorio">*</span></label>
                <input
                  class="form-control"
                  id="input"
                  type="text"
                  pInputText
                  name="codigoIbge"
                  [(ngModel)]="codigoIbge"
                  maxlength="7"
                  >
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>DtIni<span class="obrigatorio">*</span></label>
                <input
                  class="form-control"
                  id="input"
                  type="text"
                  pInputText
                  name="dtini"
                  [(ngModel)]="dtIni"
                  maxlength="8"
                  >
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>DtFin<span class="obrigatorio">*</span></label>
                <input
                  class="form-control"
                  id="input"
                  type="text"
                  pInputText
                  name="dtFin"
                  [(ngModel)]="dtFin"
                  maxlength="8"
                  >
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>