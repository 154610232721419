<app-data-table mainTitle="Status"
                singleTitle="Status"
                application="proof"
                routerPath="/status"
                entityPath="/status"
                orderColumns="name"
                [entity]="authStatus"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
