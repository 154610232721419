<app-data-table *ngIf="hasEmpresaSelected"
                class="planocontas"
                mainTitle="Planos de contas"
                singleTitle="Plano de contas"
                routerPath="/planocontas"
                entityPath="/planocontas"
                [entity]="planocontas"
                [entityFilter]="entityFilter"
                [columns]="this['columns']"
                [controls]="this['controls']"
                bulkeditable="codNaturezaConta.id,nivel,parent.id,ativo,permiteLancamentoManual"
                bulkdelete="true">
</app-data-table>

<div class="row ml-3 mr-3" *ngIf="!loading && !hasEmpresaSelected">
  <div class="col-sm-12">
    <div class="alert alert-warning p-2" role="alert" style="font-size: 14px;">
      <i class="fas fa-warning"></i> Para prosseguir, selecione uma empresa no menu superior.
    </div>
  </div>
</div>
