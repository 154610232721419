<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="row" *ngIf="!exibirPedido">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: statusNegociacao }">
                  <a (click)="showTelaPedidoServico('TELA_1')">
                    Em Negociação ({{ pedidosNegociacao.length }})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{ active: statusCompras }">
                  <a (click)="showTelaPedidoServico('TELA_2')">
                    {{ tipo == 0 ? "Compras" : "Vendas" }} ({{
                    pedidosCompraVenda.length
                    }})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{ active: statusRecusado }">
                  <a (click)="showTelaPedidoServico('TELA_3')">
                    Recusado ({{ pedidosRecusados.length }})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{ active: statusFinalizado }">
                  <a (click)="showTelaPedidoServico('TELA_4')">
                    Finalizado ({{ pedidosFinalizados.length }})
                  </a>
                </li>
              </ul>

              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]="currentStatePedidoServico">
            <div [ngStyle]="
                statusNegociacao ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusNegociacao' }">
              <p-table class="table-system" #dt10 [value]="pedidosNegociacao" [rows]="rows" [paginator]="true"
                [totalRecords]="pedidosNegociacao.length" (onFilter)="onFilterPedido($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{ width: '100%' }" [columns]="cols"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                [(selection)]="clearArray" (onHeaderCheckboxToggle)="onSelectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSAO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'dt_faturamento','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          CÓDIGO <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'codigo','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'codigoControle','contains')" />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'objetoRms', 'contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'valorPorItens','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'valorSaldo','contains')" />
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          Usuário <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'usuario', 'contains')">
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Criado por <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'usuarioCriacao', 'contains')">
                    </th>

                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'pedidoStatus','contains')" />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}
                        ">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          {{ rowData.valorTotalPorItensFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusCompras ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusCompras' }">
              <p-table class="table-system" #dt20 [value]="pedidosCompraVenda" [rows]="rows" [paginator]="true"
                [columns]="cols" [totalRecords]="pedidosCompraVenda.length" (onFilter)="onFilterPedido($event)"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                [(selection)]="clearArray" (onHeaderCheckboxToggle)="onSelectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSÃO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'dt_emissao', 'contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'dt_faturamento','contains')" />
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          Código <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'codigo','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'codigoControle','contains')" />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'objetoRms', 'contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorTotalPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorTotalPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'valorTotalPorItens','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'valorSaldo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          Usuário <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'usuario', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Criado por <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'usuarioCriacao', 'contains')">
                    </th>

                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'pedidoStatus','contains')" />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$ {{ rowData.valorTotalPorItensFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusRecusado ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusRecusado' }">
              <p-table class="table-system" #dt30 [value]="pedidosRecusados" [rows]="rows" [paginator]="true"
                [totalRecords]="pedidosRecusados.length" (onFilter)="onFilterPedido($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }" (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple" [(selection)]="clearArray"
                [columns]="cols" (onHeaderCheckboxToggle)="onSelectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSAO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'dt_faturamento','contains')" />
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          Código <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'codigo','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'codigoControle','contains')" />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'objetoRms', 'contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'valorPorItens','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'valorSaldo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          Usuário <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'usuario', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Criado por <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'usuarioCriacao', 'contains')">
                    </th>

                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'pedidoStatus','contains')" />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$
                          {{
                          rowData.valorTotalPorItensFormatado | money
                          }}</span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>


                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusFinalizado ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusFinalizado' }">
              <p-table class="table-system" #dt40 [value]="pedidosFinalizados" [rows]="rows" [paginator]="true"
                (onFilter)="onFilterPedido($event)" [pageLinks]="pageLinks" emptyMessage="Nenhum Registro Encontrado"
                [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }" (onRowSelect)="onRowSelect($event)"
                (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple" [(selection)]="clearArray"
                [columns]="cols" (onHeaderCheckboxToggle)="onSelectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSAO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'dt_faturamento','contains')" />
                    </th>
                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          Código <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'codigo','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'codigoControle','contains')" />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'objetoRms', 'contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorTotalPorItensFormatado">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorTotalPorItensFormatado"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'valorTotalPorItensFormatado','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'valorSaldo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          Usuário <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'usuario', 'contains')">
                    </th>

                    <th class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Criado por <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'usuarioCriacao', 'contains')">
                    </th>

                    <th class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'pedidoStatus','contains')" />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$ {{ rowData.valorTotalPorItensFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>


                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content {{ (isReadOnly ? 'lks-form-grid-readOnly' : '') }}"
      *ngIf="exibirPedido && infoParticipante">

      <!-- cabeçalho -->
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-info-circle"></i> Informações
            {{ tipo == 0 ? "Fornecedor" : "Cliente" }}
          </h3>
        </div>
      </div>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th style="width: 180px">
              <strong>PEDIDO DE {{ tipo == 0 ? "COMPRA" : "VENDA" }}: </strong>
            </th>
            <th>
              <strong> {{ pedido.codigo }} </strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{{ tipo == 0 ? "Fornecedor:" : "Cliente:" }} </strong>
            </td>
            <td>
              <div *ngIf="!(infoParticipante[0].cnpj === null)">
                {{infoParticipante[0].nome}} - CNPJ: {{ infoParticipante[0].cnpj | cnpj}}
                <span class="edit" matTooltip="Alterar Participante" (click)="openModal()">
                  <i class="fas fa-edit"></i>
                </span>
              </div>
              <div *ngIf="!(infoParticipante[0].cpf === null)">
                {{infoParticipante[0].nome}} - CPF: {{ infoParticipante[0].cpf | cpf}}
                <span class="edit" matTooltip="Alterar Participante" (click)="openModal()">
                  <i class="fas fa-edit"></i>
                </span>
              </div>
              <div *ngIf="
                  infoParticipante[0].cpf === null &&
                  infoParticipante[0].cnpj === null
                ">
                {{ infoParticipante[0].nome }} (Exterior)
                <span class="edit" matTooltip="Alterar Participante" (click)="openModal()">
                  <i class="fas fa-edit"></i>
                </span>
              </div>
            </td>

          </tr>
          <tr>
            <td>
              <strong>ENDEREÇO: </strong>
            </td>
            <td>
              {{ infoParticipante[0].logradouro }},
              {{ infoParticipante[0].numero }} -
              {{ infoParticipante[0].cidade }}/{{ infoParticipante[0].uf }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>CONTATO: </strong>
            </td>
            <td>
              {{ this.ExisteParticipanteContato(this.infoParticipante[0]) }}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- INFOS INICIAIS-->
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-info-circle"></i> Informações Iniciais
          </h3>

          <!-- 1a. Linha das Iniciais -->
          <div class="form-row align-items-end">
            <div class="form-group col-sm-3">
              <label>STATUS <span class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="false" [options]="opcoesStatusPedido"
                placeholder="Selecione o Status" [(ngModel)]="pedido.pedidoStatus.id"
                (ngModelChange)="verificaStatus($event)" appendTo></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>MODELO <span class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="false" [(ngModel)]="pedido.modNf.id"
                [options]="opcoesModelo" (onChange)="selecionarModelo()"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>NÚMERO
                <span *ngIf="
                    (pedido.pedidoStatus.id === 4 &&
                      pedido.pedidoTipo.id === 2) ||
                    (pedido.pedidoStatus.id === 12 &&
                      pedido.pedidoTipo.id === 2) ||
                    (pedido.pedidoStatus.id === 7 && pedido.pedidoTipo.id === 4)
                  " class="obrigatorio">*</span></label>
              <input class="form-control" [disabled]="
                  pedido.pedidoStatus.id === 4 &&
                  pedido.pedidoTipo.id === 2 &&
                  pedido.modNf.id === 25
                " placeholder="{{
                  pedido.pedidoStatus.id === 4 &&
                  pedido.pedidoTipo.id === 2 &&
                  pedido.modNf.id === 25
                    ? 'Gerado Automaticamente'
                    : ''
                }}" type="text" [(ngModel)]="pedido.numNf" maxlength="50" />
            </div>
            <div class="form-group col-sm-1">
              <label>SÉRIE</label>
              <input class="form-control" type="text" maxlength="3" [(ngModel)]="pedido.serie" />
            </div>
            <div class="form-group col-sm-1">
              <label>DATA ENTREGA</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtEntrega" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030">
              </p-calendar>
            </div>
            <div class="form-group col-sm-1">
              <label>DATA EMISSÃO<span class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtEmissao" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030">
              </p-calendar>
            </div>
            <div class="form-group col-sm-1">
              <label>DATA ENTRADA/SAÍDA<span class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030">

              </p-calendar>
            </div>
          </div>

          <!-- 2a. Linha das Iniciais -->
          <div class="form-row">
            <div class="form-group col-sm-1">
              <label *ngIf="dblinkedSessionService.empresa.id === 158">Nº JIRA</label>
              <label *ngIf="dblinkedSessionService.empresa.id !== 158">CÓD. CARD</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.codigoControle" />
            </div>
            <div class="form-group col-sm-1">
              <label>ORÇAMENTO</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.codigoOrcamento" maxlength="100" />
            </div>
            <div class="form-group col-sm-1">
              <label>MOEDA</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesMoeda"
                placeholder="Selecione a Moeda" [(ngModel)]="pedido.moeda.id"
                (ngModelChange)="retornaCodigoMoeda(pedido.moeda.id)">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-1">
              <label>TAXA</label>
              <input class="form-control" type="text" [disabled]="pedido.converterTaxa || pedido.moeda.id === 1"
                [(ngModel)]="pedido.txMoeda" />
            </div>
            <div class="form-group col-sm-2" *ngIf="pedido.pedidoTipo.id === 2 && pedidoExterior">
              <label>NÚMERO INVOICE</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.numeroInvoice" maxlength="20" />
            </div>
            <div class="form-group col-sm-1" *ngIf="pedido.pedidoTipo.id === 2 && pedidoExterior">
              <label>DATA INVOICE</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dataInvoice" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-1" *ngIf="pedidoExterior">
              <label>DT INÍCIO SERV</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dataInicial" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-1" *ngIf="pedidoExterior">
              <label>DT FIM SERV</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dataFinal" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="col-sm-2" *ngIf="pedido.moeda.id != 1">
              <label class="d-block" style="visibility: hidden; margin-top: 11px;">DO NOT REMOVE</label>
              <mat-slide-toggle *ngIf="!isReadOnly" [(ngModel)]="pedido.converterTaxa"
                (ngModelChange)="pagamentoForma(); atualizarSubtotal()" (click)="alterarValorUnitItens()">
                Converter Taxa
              </mat-slide-toggle>
            </div>
            <div class="form-group col-sm-1" *ngIf="pedido.pedidoTipo.id === 2">
              <label>LOCAL PRESTAÇÃO</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="false" [options]="opcoesEndereco"
                placeholder="Selecione o Local" [(ngModel)]="pedido.localPrestacao"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <!-- FIM INFOS INICIAIS-->

      <!-- ITENS DO PEDIDO-->
      <!-- 6a. linha itens do pedido -->
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-bars"></i> Itens do Pedido</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-2">
          <p-table class="table-system" [value]="pedido.pedidoItem" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 22%">ITEM</th>
                <th style="width: 16%">OPERAÇÃO</th>
                <th class="text-right" style="width: 11%">QTD</th>
                <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR UNIT ({{ codigoMoeda }})
                </th>
                <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR UNIT (BRL)
                </th>
                <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                  VALOR UNIT
                </th>
                <th class="text-right" style="width: 11%">TIPO DESC</th>
                <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR DESC ({{ codigoMoeda }})
                </th>
                <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR DESC (BRL)
                </th>
                <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                  VALOR DESC
                </th>
                <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR TOTAL ({{ codigoMoeda }})
                </th>
                <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR TOTAL (BRL)
                </th>
                <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                  VALOR TOTAL
                </th>
                <th style="width: 7%" class="text-center">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData" style="height: 25px">
                <td *ngIf="rowData.item.id" (click)="rateioItem(rowData)">
                  <span matTooltip="{{ rowData.item.codigo }} - {{
                      rowData.item.descricao
                    }}" class="overflow-ellipse">{{ rowData.item.codigo }} -
                    {{ rowData.item.descricao }}</span>
                </td>
                <!-- <td *ngIf="rowData.item.id && !pedidoExterior" (click)="rateioItem(rowData)">
                  <span matTooltip="{{rowData.item.codigo}} - {{rowData.item.descricao}}"
                    class="overflow-ellipse">{{rowData.item.codigo}} - {{rowData.item.descricao}}</span>
                </td>
                <td *ngIf="rowData.item.id && pedidoExterior" (click)="rateioItem(rowData)">
                  <span matTooltip="{{rowData.item.codigo}} - {{rowData.item.descricaoEn}}"
                    class="overflow-ellipse">{{rowData.item.codigo}} - {{rowData.item.descricaoEn}}</span>
                </td> -->
                <td (click)="rateioItem(rowData)">
                  <span matTooltip="{{ rowData.operacaoRegra.cod }} - {{
                      rowData.operacaoRegra.descricao
                    }}" class="overflow-ellipse">{{ rowData.operacaoRegra.cod }} -
                    {{ rowData.operacaoRegra.descricao }}</span>
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.quantidade }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.valorUnit | money }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.tipoDesconto.cod }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.valorDesconto | money }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.valorTotal | money }}
                </td>
                <td class="text-center">
                  <span class="edit" matTooltip="Editar Item Pedido" (click)="itemOnRowSelect(rowData)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="delete" matTooltip="Excluir Item Pedido" *ngIf="!isReadOnly"
                    (click)="excluirItemLista(rowData.id)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr style="height: 25px">
                <td [attr.colspan]="8" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline">
                    <i class="fas fa-info-circle"></i> Sem Itens Adicionados!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col-sm-12">
          <button class="btn btn-sm btn-info" (click)="adicionarItem()" [disabled]="isReadOnly">
            <i class="fas fa-plus"></i> Adicionar Item
          </button>
        </div>

        <div class="col-sm-12">
          <div class="lks-form-grid" *ngIf="pedido.pedidoTipo.id === 2">
            <div class="lks-form-grid" *ngIf="pedido.pedidoItem">
              <div class="lks-form-grid" *ngIf="pedido.pedidoItem.length > 1">
                <div class="input-5 type-p-dropdown" input-text="left" input-vertical="middle">
                  <label>ITEM NF</label>
                  <p-dropdown appendTo="body" [filter]="false" [(ngModel)]="idItemFaturamento" [options]="opcoesItens">
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Início Rateio Pedido-->
      <div class="column">
        <div class="col-sm-12" *ngIf="exibirRateio && pedidoItem.item">
          <h3 class="sub-title mt-4 mb-0">
            <i class="fas fa-bars"> </i> Rateio
            <span class="text-secondary">{{ pedidoItem.item.codigo }}</span>
          </h3>
        </div>

        <div class="row" *ngIf="exibirRateio && pedidoItem.pedidoItensRateio">
          <div class="col-sm-12">
            <p-table class="table-system mt-2 d-block" [value]="pedidoItem.pedidoItensRateio"
              [style]="{ width: '100%' }">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 40%" class="text-left">Centro de Custo</th>
                  <th style="width: 33%">Projeto</th>
                  <th style="width: 20%">Porcentagem</th>
                  <th style="width: 7%" class="text-center">Ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr (click)="retornaIdCentroDeCusto(rowData.cadCcus.id, null)">
                  <td pEditableColumn style="width: 40%">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div class="form-row">
                          <div class="form-group col-sm-12 mb-0">
                            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                              [options]="opcoesCentroCusto" [(ngModel)]="rowData.cadCcus"
                              placeholder="Selecione um centro de custo" (onChange)="
                                calculaRateioDisponivelCentroCusto();
                                retornaIdCentroDeCusto(
                                  rowData.cadCcus.id,
                                  rowData
                                )
                              " optionLabel="label"></p-dropdown>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span matTooltip=" {{ rowData.cadCcus.codCcus }} - {{
                            rowData.cadCcus.descrCcus
                          }}" class="overflow-ellipse" style="cursor: pointer" *ngIf="rowData.cadCcus">
                          {{ rowData.cadCcus.codCcus }} -
                          {{ rowData.cadCcus.descrCcus }}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn style="width: 33%">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div class="form-row">
                          <div class="form-group col-sm-12 mb-0">
                            <p-dropdown appendTo="body" ##dd class="type-p-dropdown" [filter]="true"
                              [options]="opcoesProjeto" [(ngModel)]="rowData.cadProj" placeholder="Selecione um projeto"
                              (onChange)="calculaRateioDisponivelCentroCusto()" optionLabel="label"></p-dropdown>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span matTooltip=" {{ rowData.cadProj.codProj }} - {{
                            rowData.cadProj.descrProj
                          }}" class="overflow-ellipse" style="cursor: pointer" *ngIf="rowData.cadProj">
                          {{ rowData.cadProj.codProj }} -
                          {{ rowData.cadProj.descrProj }}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn style="width: 20%; text-align: right">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div class="form-row">
                          <div class="form-group col-sm-12 mb-0">
                            <input class="form-control text-right" [(ngModel)]="rowData.porcentagem"
                              (keyup)="calculaRateioDisponivelCentroCusto()" type="number" />
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span matTooltip="{{ rowData.porcentagem | money }}" class="overflow-ellipse text-right"
                          style="cursor: pointer">{{ rowData.porcentagem | money }}</span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td class="text-center" style="width: 7%">
                    <span class="delete" matTooltip="Excluir" (click)="removerRateioCentroCusto(rowData.id)"><i
                        class="far fa-trash-alt"></i></span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="col" *ngIf="exibirRateio">
            <button class="btn btn-sm btn-info mt-2" *ngIf="exibirRateio" (click)="adicionarRateio()"
              [disabled]="isReadOnly">
              <i class="fas fa-plus"></i> Adicionar Rateio
            </button>

            <button class="btn btn-sm btn-info mt-2" *ngIf="exibirRateio && pedido.pedidoItem.length > 1"
              (click)="replicarRateio()" [disabled]="isReadOnly">
              <i class="fas fa-copy"></i> Replicar
            </button>
          </div>
        </div>
        <!-- Final Rateio Pedido-->
        <!-- FIM ITENS DO PEDIDO-->

        <!-- INFOS GERAIS-->
        <div class="row">
          <div class="col-sm-12 mt-3">
            <h3 class="sub-title">
              <i class="fas fa-info-circle"></i> Informações Gerais
            </h3>
          </div>
        </div>
        <!-- 1a. linhas das Gerais -->
        <div class="form-row align-items-end">
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR INSS (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR INSS ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR INSS</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorInss" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR IRRF (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR IRRF ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR IRRF</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIr" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR CSLL (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR CSLL ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR CSLL</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCsll" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR PIS (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR PIS ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR PIS</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorPis" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR COFINS (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR COFINS ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR COFINS</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCofins" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR ISS (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR ISS ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR ISS</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIssRetido" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">OUTRAS RETENÇÕES (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">OUTRAS RETENÇÕES ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">OUTRAS RETENÇÕES</label>
            <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorOutrasRetencoes" money
              (blur)="atualizarSubtotal()" (ngModelChange)="atualizarSubtotal()" />
          </div>
        </div>

        <!-- 2a. linhas das Gerais -->
        <div class="form-row align-items-end">
          <div class="form-group col"></div>
          <div class="form-group col"></div>
          <div class="form-group col"></div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">SUBTOTAL (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">SUBTOTAL ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">SUBTOTAL</label>
            <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
              (ngModelChange)="atualizarSubtotal()" [(ngModel)]="subTotalPedido" money />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR DESCONTO (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR DESCONTO ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR DESCONTO</label>
            <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
              (ngModelChange)="atualizarSubtotal()" [(ngModel)]="valorDescontoItens" money />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">IMPOSTOS RET. (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">IMPOSTOS RET. ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">IMPOSTOS RET.</label>
            <input class="form-control text-right" type="text" (blur)="atualizarSubtotal()"
              (ngModelChange)="atualizarSubtotal()" [(ngModel)]="valorImpostosRetidos" money />
          </div>
          <div class="form-group col">
            <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR TOTAL (BRL)</label>
            <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR TOTAL ({{ codigoMoeda }})</label>
            <label *ngIf="!pedidoExterior">VALOR TOTAL</label>
            <input class="form-control text-right" type="text" [(ngModel)]="valorTotalPedido" money />
          </div>
        </div>
        <!-- FIM INFOS GERAIS-->

        <!-- FORMA PAGAMENTO-->
        <div class="clearfix"></div>

        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title mt-0">
              <i class="fas fa-money-check-alt"></i> Informações de Pagamento
            </h3>
          </div>
        </div>

        <!-- 1a. linha pagamento -->
        <div class="form-row" style="align-items: center">
          <div class="form-group col-sm-3">
            <label>TIPO DE PAGAMENTO <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.tipoPagamento"
              [options]="opcoesTipoPagamento" (onChange)="pagamentoTipo()"></p-dropdown>
          </div>
          <div class="form-group col-sm-2" *ngIf="pedido.tipoPagamento === 1">
            <label>FORMA DE PAGAMENTO <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.formaPagamento.id"
              [options]="opcoesFormaPagamento" (onChange)="infosFormaPagto(pedido.formaPagamento.id)"></p-dropdown>
          </div>
          <div class="form-group col-sm-3" *ngIf="pedido.tipoPagamento === 1">
            <label>CONTA <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.empresaConta.id"
              [options]="opcoesContas" (onChange)="pagamentoForma()" [disabled]="!permiteAlterarConta"></p-dropdown>
          </div>
          <div class="form-group col-sm-4" *ngIf="
              pedido.tipoPagamento === 1 &&
              pedido.empresaConta.id &&
              pedido.formaPagamento.id &&
              !isReadOnly
            ">
            <label class="d-block" style="visibility: hidden">DO NOT REMOVE</label>
            <button type="button" class="btn btn-sm btn-info" (click)="modalFormaPgto = true">
              <i class="fas fa-plus"></i> Adicionar
            </button>
            <button type="button" class="btn btn-sm btn-info" (click)="atualizarSubtotal(); pagamentoForma()">
              <i class="fas fa-refresh"></i> Atualizar
            </button>
          </div>
        </div>

        <!-- 2a. linha pagamento -->
        <div class="row" *ngIf="pedido.tipoPagamento === 1">
          <div class="col-sm-12 mb-3" *ngIf="pedido.pedidoParcela">
            <div *ngIf="pedido.pedidoParcela.length > 0">
              <p-table class="table-system" [value]="pedido.pedidoParcela" selectionMode="single"
                (onRowSelect)="modalFormaPgto = true" [scrollable]="true" scrollHeight="330px">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 10%">Parcela</th>
                    <th class="text-center" style="width: 12%">Vencimento</th>
                    <th class="text-right" style="width: 12%" *ngIf="pedido.converterTaxa && pedidoExterior">
                      Valor (BRL)
                    </th>
                    <th class="text-right" style="width: 12%" *ngIf="!pedido.converterTaxa && pedidoExterior">
                      Valor ({{ codigoMoeda }})
                    </th>
                    <th class="text-right" style="width: 12%" *ngIf="!pedidoExterior">
                      Valor
                    </th>
                    <th class="text-center" style="width: 12%">Conta</th>
                    <th class="text-left" style="width: 12%">Tipo baixa</th>
                    <th class="text-left" style="width: 12%">Tipo Pagamento</th>
                    <th class="text-left" style="width: 30%">Histórico</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr *ngIf="!isReadOnly" [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 10%">
                      {{ rowData.parcela }}
                    </td>
                    <td class="text-center" style="width: 12%">
                      {{ rowData.dtVencimento | date: "dd/MM/yyyy" }}
                    </td>
                    <td class="text-right" style="width: 12%">
                      {{ rowData.valor | money }}
                    </td>
                    <td class="text-center" style="width: 12%">
                      {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                    </td>
                    <td class="text-left" style="width: 12%">
                      <span class="overflow-ellipse" matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                        {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 12%">
                      <span class="overflow-ellipse" matTooltip="{{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}">
                        {{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%">
                      {{ rowData.historico }}
                    </td>
                  </tr>
                  <tr *ngIf="isReadOnly">
                    <td class="text-center" style="width: 10%">
                      {{ rowData.parcela }}
                    </td>
                    <td class="text-center" style="width: 12%">
                      {{ rowData.dtVencimento | timezone | date: "dd/MM/yyyy" }}
                    </td>
                    <td class="text-right" style="width: 12%">
                      {{ rowData.valor | money }}
                    </td>
                    <td class="text-center" style="width: 12%">
                      {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                    </td>
                    <td class="text-left" style="width: 12%">
                      <span class="overflow-ellipse" matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                        {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 12%">
                      <span class="overflow-ellipse" matTooltip="{{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}">
                        {{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}
                      </span>
                    </td>
                    <td class="text-left" style="width: 30%">
                      {{ rowData.historico }}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height: 25px">
                    <td [attr.colspan]="6">
                      Sem forma de pagamento selecionada
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>


        <div *ngIf="infosAdto">
          <div class="row" *ngIf="infosAdto.data.length > 0">
            <div class="col-sm-12">
              <h3 class="sub-title mt-0"><i class="fas fa-money-check-alt"></i> Informações de Adiantamento</h3>
            </div>
          </div>

          <div class="row" *ngIf="infosAdto.data">

            <div class="col-sm-12 mb-3" *ngIf="infosAdto.data">
              <div *ngIf="infosAdto.data.length > 0">
                <p-table class="table-border" [value]="infosAdto.data" selectionMode="single" [scrollable]="true"
                  scrollHeight="330px">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width:15%">Data</th>
                      <th class="text-right" style="width:15%">Valor</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td class="text-center" style="width:15%">{{rowData.data}}</td>
                      <td class="text-right" style="width:15%">{{rowData.valor | money}}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                    <tr style="height:25px">
                      <td [attr.colspan]="6">Sem forma de pagamento selecionada</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>


        <div class="row" *ngIf="pedido.tipoPagamento === 2">
          <!-- *ngIf="pedido.pedidoParcela" -->
          <div class="col-sm-12 mb-3" *ngIf="adtosRelacionadosPedido">
            <div *ngIf="adtosRelacionadosPedido.length">
              <p-table class="table-system" [value]="adtosRelacionadosPedido" selectionMode="single"
                (onRowSelect)="adicionarCompensacao()">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 10%">Data</th>
                    <th class="text-left" style="width: 30%">Participante</th>
                    <th class="text-left" style="width: 30%">Histórico</th>
                    <th class="text-right" style="width: 10%">Valor</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center">{{ rowData.data }}</td>
                    <td class="text-left">{{ rowData.nomePart }}</td>
                    <td class="text-left">{{ rowData.descricao }}</td>
                    <td class="text-right">{{ rowData.compensar | money }}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                  <tr style="height: 25px">
                    <td [attr.colspan]="3">Sem forma pagamento selecionada</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>

        <!-- <div class="row" *ngIf="financeiroAntecipado && tipo == 1">
          <div class="col-sm-12">
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>Operação Recebimento Antecipado</label>
                <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoCont"
                  placeholder="Selecione a Operação" [(ngModel)]="operacaoRegraAntecipado"></p-dropdown>
              </div>
            </div>

          </div>
        </div> -->

        <!-- FIM FORMA PAGAMENTO-->

        <!-- OBSERVAÇÕES PEDIDO-->
        <div class="row">
          <div class="col-sm-12 mt-3">
            <h3 class="sub-title">
              <i class="fas fa-file-alt"></i> OBSERVAÇÕES
            </h3>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>ENDEREÇO DE COBRANÇA</label>
            <textarea class="form-control" rows="5" type="text" value="pedido.enderecoCobranca"
              [(ngModel)]="pedido.enderecoCobranca"></textarea>
          </div>
          <div class="form-group col-sm-6">
            <label>ENDEREÇO DE ENTREGA</label>
            <textarea class="form-control" rows="5" type="text" value="pedido.enderecoEntrega"
              [(ngModel)]="pedido.enderecoEntrega"></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <!-- 1a. linha dados adicionais -->
            <label>DADOS ADICIONAIS</label>
            <textarea class="form-control" rows="5" pInputTextarea value="pedido.dadosAdicionais"
              [(ngModel)]="pedido.dadosAdicionais"></textarea>
          </div>
        </div>
        <!--FIM OBSERVAÇÕES PEDIDO-->

        <!--VENDEDOR-->
        <div class="clearfix"></div>

        <div class="row" *ngIf="
            comissaoAtiva && tipo == 1">
          <div class="col-sm-12">
            <h3 class="sub-title mt-0">
              <i class="fas fa-user"></i> Comissão de Venda
            </h3>
          </div>
        </div>

        <div class="form-row" *ngIf="comissaoAtiva && tipo == 1">
          <div class="form-group col-sm-3">
            <label>VENDEDOR</label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [(ngModel)]="vendedorId"
              [options]="opcoesVendedor">
            </p-dropdown>
          </div>
        </div>
        <!--FIM VENDEDOR-->

        <!--BOTÕES FINAIS-->
        <div class="row mt-3  {{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}">
          <div class="col-sm-12 d-flex align-items-center justify-content-start">
            <button class="btn btn-secondary" (click)="inicializar()">
              <i class="fas fa-undo"></i> Cancelar
            </button>
            <!-- <button class="btn btn-primary" *ngIf="habilitarSolicitar && tipo == 0" (click)="solicitarAprovacao()">
              <i class="fas fa-envelope"></i> Solicitar
            </button> -->
            <button class="btn btn-primary" *ngIf="!pedido.id" (click)="salvarPedido(false)">
              <i class="fas fa-check"></i> Salvar
            </button>
            <button class="btn btn-primary" *ngIf="!pedido.id" (click)="salvarPedido(true)">
              <i class="fas fa-check"></i> Salvar e Criar novo
            </button>
            <button class="btn btn-primary" *ngIf="pedido.id && pedido.pedidoStatus.id !== 5"
              (click)="atualizarPedido();"><i class="fas fa-check"></i> Salvar</button>
            <!-- <button type="button" class="btn btn-primary" *ngIf="financeiroAntecipado && pedido.id > 0 && tipo == 1"
              (click)="gerarFinanceiroPedido();"><i class="fas fa-check"></i> GERAR FINANCEIRO</button> -->
            <div class="p-field-checkbox ml-3"
              *ngIf="showSolicitarAprovacao  && (pedido.pedidoStatus.id === 4 || pedido.pedidoStatus.id === 7)">
              <p-checkbox class="mr-1" [(ngModel)]="requestApproval" [binary]="true" inputId="sa"></p-checkbox>
              <label for="sa" style="margin: 0">Solicitar aprovação</label>
            </div>
          </div>
        </div>
        <!--FIM BOTÕES FINAIS-->

        <div class="row mt-3" *ngIf="!exibirPedido">
          <div class="col-sm-12">
            <!--Saldo Inicial-->
            <div class="pull-left mr-3">
              <div class="div-card-total">
                <p class="titulo-card-total">Valor Total</p>
                <p class="valor-card-total">
                  R$ {{ valorTotalPedidos | money }}
                </p>
              </div>
            </div>
            <!--Movimentação-->
            <div class="pull-left mr-3">
              <div class="div-card-total">
                <p class="titulo-card-total">Valor Selecionados</p>
                <p class="valor-card-total">
                  R$ {{ valorPedidosSelecionados | money }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!---Modal Item-->
<p-dialog header="Adicionar Item" [(visible)]="exibirItemPedido" [style]="{ width: '900px' }" [modal]="true"
  styleClass="adicionar-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="exibirItemPedido">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Operação <span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
          placeholder="Selecione uma Operacao" [(ngModel)]="pedidoItem.operacaoRegra.id"
          (onChange)="dadosOperacao(pedidoItem.operacaoRegra.id)"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Item <span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesItens"
          placeholder="Selecione o Item" [(ngModel)]="pedidoItem.item.id" (onChange)="dadosItem($event)"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Part Number</label>
        <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.itemFornecedor" />
      </div>
      <div class="form-group col-sm-2">
        <label>Quantidade <span class="obrigatorio">*</span></label>
        <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.quantidade"
          (blur)="calcularValorTotal()" />
      </div>
      <div class="form-group col-sm-2">
        <label>Valor Unitário <span class="obrigatorio">*</span></label>
        <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.valorUnit"
          (blur)="calcularValorTotal()" />
      </div>
      <div class="form-group col-sm-1">
        <label>TIPO</label>
        <p-dropdown [filter]="false" [options]="opcoesDesconto" [(ngModel)]="pedidoItem.tipoDesconto.id"
          (ngModelChange)="calcularValorTotal()" placeholder="Selecione" class="type-p-dropdown altura-ncm"
          appendTo="body">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>Valor Desconto</label>
        <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.valorDesconto"
          (blur)="calcularValorTotal()" />
      </div>
      <div class="form-group col-sm-2">
        <label>Valor Total <span class="obrigatorio">*</span></label>
        <!-- To do Fernando -->
        <input class="form-control text-right" type="text" input-text="right" [disabled]="true"
          [(ngModel)]="pedidoItem.valorTotal" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-3">
        <label>valor Saving</label>
        <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorSaving" money />
      </div>
      <div class="form-group col-sm-12">
        <label>Observações</label>
        <textarea class="form-control" rows="5" pInputTextarea value="pedidoItem.observacaoSaving"
          [(ngModel)]="pedidoItem.observacaoSaving"></textarea>
      </div>
    </div>
    <!-- *ngIf="pedido.pedidoTipo.id === 1 || pedido.pedidoTipo.id === 2"-->
    <!-- <div class="form-group col-sm-6">
    <label>Tipo de Desconto</label>
    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesDesconto"
      placeholder="Selecione forma desconto" [(ngModel)]="pedidoItem.tipoDesconto.id"></p-dropdown>
  </div> -->
  </div>
  <!-- Final do form do modal-->
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px">
    <div class="ui-g-12"></div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirItemPedido = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <div class="d-inline-block {{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}">
      <button type="button" class="btn btn-danger" *ngIf="botaoEditar && !isReadOnly"
        (click)="excluirItemLista(pedidoItem.id)">
        <i class="fas fa-times"></i> Excluir
      </button>
      <button type="button" class="btn btn-primary" *ngIf="!botaoEditar" (click)="salvarItemLista(null)">
        <i class="fas fa-check"></i> Salvar
      </button>
      <button type="button" class="btn btn-primary" *ngIf="botaoEditar && !isReadOnly"
        (click)="alterarItemLista(pedidoItem.id)">
        <i class="fas fa-pencil-alt"></i> Alterar
      </button>
    </div>
  </p-footer>
</p-dialog>
<!---final Modal Item-->

<!---Modal Escrituracao -->
<p-dialog header="Escrituração Contábil e Fiscal: {{ pedido.codigo }}" [(visible)]="visualizarContabil"
  [style]="{ width: '1000px' }" [modal]="true" styleClass="contabil-altura-inicial" [responsive]="true" appendTo="body"
  [closable]="true">
  <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="visualizarContabil">
    <mat-tab-group>
      <mat-tab label="Escrituração Contábil">
        <div class="mt-2">
          <p-table class="table-system" [value]="visualizacaoContabil">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-left" style="width: 45%">COD CTA</th>
                <th class="text-center" style="width: 5%">D/C</th>
                <th class="text-right" style="width: 10%">VALOR</th>
                <th class="text-left" style="width: 20%">CENTRO CUSTO</th>
                <th class="text-left" style="width: 20%">PROJETO</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr style="height: 25px">
                <td class="text-left">
                  <span class="overflow-ellipse" matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                </td>
                <td class="text-center">
                  {{ rowData.indDc }}
                </td>
                <td class="text-right">
                  {{ rowData.valor | money }}
                </td>
                <td class="text-left">
                  <span class="overflow-ellipse" matTooltip="{{ rowData.centroCusto }}">{{ rowData.centroCusto }}</span>
                </td>
                <td class="text-left">
                  <span class="overflow-ellipse" matTooltip="{{ rowData.cadProj }}">{{ rowData.cadProj }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>
      <mat-tab label="Escrituração Fiscal">
        <div class="mt-2">
          <p-table [value]="visualizacaoFiscal" class="table-system" [scrollable]="true">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-right" style="width: 10%">BASE SERVIÇO</th>
                <th class="text-center" style="width: 6%">ALIQ INSS</th>
                <th class="text-center" style="width: 6%">VLR INSS</th>
                <th class="text-center" style="width: 6%">ALIQ IR</th>
                <th class="text-center" style="width: 6%">VLR IR</th>
                <th class="text-center" style="width: 6%">ALIQ CSLL</th>
                <th class="text-center" style="width: 6%">VLR CSLL</th>
                <th class="text-center" style="width: 6%">ALIQ PIS</th>
                <th class="text-center" style="width: 6%">VLR PIS</th>
                <th class="text-center" style="width: 6%">ALIQ COFINS</th>
                <th class="text-center" style="width: 6%">VLR COFINS</th>
                <th class="text-center" style="width: 6%">ALIQ ISS</th>
                <th class="text-center" style="width: 6%">VLR ISS</th>
                <th class="text-center" style="width: 10%">ALIQ ISS RET</th>
                <th class="text-center" style="width: 6%">VLR ISS RET</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr style="height: 25px">
                <td class="text-right">
                  {{ rowData.valorBaseServico | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqInss | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorInss | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqIrrf | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorIrrf | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqCsll | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorCsll | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqPis | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorPis | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqCofins | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorCofins | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqIss | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorIss | money }}
                </td>
                <td class="text-right">
                  {{ rowData.aliqIssRet | money }} %
                </td>
                <td class="text-right">
                  {{ rowData.valorIssRet | money }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <p-footer>
    <div class="ui-g">
      <div class="ui-g-11">
        Última Atualização: {{ pedido.dataUltimaAtualizacao }}
      </div>
      <div class="ui-g-1">
        <button type="button" class="btn btn-secondary" (click)="visualizarContabil = false">
          <i class="fas fa-undo"></i> Fechar
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
<!---Fim Modal Escrituracao-->

<!---Modal Formas Pgtos-->
<p-dialog header="Parcelas" [(visible)]="modalFormaPgto" [modal]="true" styleClass="modal-scroll" [responsive]="true"
  appendTo="body" [closable]="true">
  <div class="form-row" *ngIf="modalFormaPgto" style="width: 900px;">
    <div class="form-group col-sm-3">
      <label>Qtde Parcelas</label>
      <input class="form-control text-left" type="number" pInputText [(ngModel)]="qtdParcelas" min="0"
        [disabled]="parcelasExatas" />
    </div>
    <div class="form-group col-sm-3 pull-left">
      <label>Primeiro Venc.</label>
      <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" appendTo="body" [monthNavigator]="true"
        [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="primeroVencimento" dateFormat="dd/mm/yy"
        [defaultDate]="defaultDate">
      </p-calendar>
    </div>
    <div class="form-group col-sm-6 d-flex align-items-end justify-content-end"
      *ngIf="pedido.empresaConta.id && pedido.formaPagamento.id">
      <div class="btnCustom-default" matTooltip="Calcular parcelas" (click)="formaPgtoCalcular(true)">
        <i class="material-icons">calculate</i>
        <div>Calcular</div>
      </div>
    </div>
    <div class="form-group col-sm-12">
      <div id="tabela_edit_VCTOS">
        <p-table [value]="pedido.pedidoParcela" class="table-system">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 10%" class="text-center">Parcela</th>
              <th style="width: 12%" class="text-center">Vencimento</th>
              <th style="width: 12%" class="text-right">Valor</th>
              <th style="width: 12%" class="text-center">Conta</th>
              <th style="width: 12%" class="text-left">Tipo</th>
              <th style="width: 12%" class="text-left">Tipo Pagamento</th>
              <th style="width: 30%">Histórico</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td pEditableColumn style="width: 10%" class="text-center">
                <p-cellEditor>
                  <ng-template pTemplate="output">{{ rowData.parcela }}</ng-template>
                  <ng-template pTemplate="input">{{ rowData.parcela }}</ng-template>
                </p-cellEditor>
              </td>
              <td class="text-center" style="width: 12%">
                <p-calendar *ngIf="rowData.calendarState" class="type-p-calendar" appendTo="body"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                  [(ngModel)]="rowData.dtVencimento" dateFormat="dd/mm/yy" (onClose)="rowCalendarState(rowData)">
                </p-calendar>

                <span class="text-overflow-dynamic-container" *ngIf="!rowData.calendarState"
                  (click)="rowCalendarState(rowData)">
                  <span class="text-overflow-dynamic-ellipsis">{{ rowData.dtVencimento | date:'dd/MM/yyyy' : '+000'
                    }}</span>
                </span>

                <span (click)="rowCalendarState(rowData)"></span>
              </td>
              <td pEditableColumn style="width: 12%" class="text-right">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="col-sm-12 p-0">
                      <input class="form-control text-right" type="text" [(ngModel)]="rowData.valor" money
                        (keyup)="calculaValorParcelas()" />
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output">{{ rowData.valor | money }}</ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn style="width: 12%">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="col-sm-12 p-0">
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
                        [disabled]="!permiteAlterarConta" placeholder="Selecione" [(ngModel)]="rowData.empresaConta.id"
                        (onChange)="setEmpresaConta(rowData)"></p-dropdown>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output" class="overflow-ellipse">
                    <span matTooltip="{{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}">
                      {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn style="width: 12%" class="text-left">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="col-sm-12 p-0">
                      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposBaixas"
                        placeholder="Selecione" [(ngModel)]="rowData.tipoBaixa.id"
                        (onChange)="setTipoBaixa(rowData)">
                        </p-dropdown>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span class="overflow-ellipse"
                        matTooltip="{{rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa}}">
                        {{rowData.tipoBaixa ? rowData.tipoBaixa.descricao : "Clique para Editar"}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 12%" class="text-left">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="col-sm-12 p-0">
                        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposPagamentos"
                          placeholder="Selecione" [(ngModel)]="rowData.tipoPagamentoId"
                          (onChange)="setTipoPagamento(rowData)">
                        </p-dropdown>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span class="overflow-ellipse"
                        matTooltip="{{rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null}}">
                        {{rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : "Clique para editar"}}
                      </span>
                    </ng-template>
                </p-cellEditor>
              </td>
              <td pEditableColumn style="width: 30%">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <div class="col-sm-12 p-0">
                      <input class="form-control" type="text" [(ngModel)]="rowData.historico" />
                    </div>
                  </ng-template>
                  <ng-template pTemplate="output">{{ rowData.historico ? rowData.historico : "Clique para Editar" }}
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td [attr.colspan]="2" class="text-right">TOTAL:</td>
              <td style="width: 12%;" class="text-right">{{ valorTotalParcelas | money }}</td>
                <td [attr.colspan]="4" style="width: 18%"></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px">
      <div class="ui-g-12"></div>
    </div>
  </div>
  <p-footer>
    <button class="btn btn-secondary"
      (click)="modalFormaPgto = false; qtdParcelas = null; primeroVencimento = null; msgsModal = []">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button class="btn btn-primary" (click)="formaPgtoSalvar()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>
<!---Fim Formas Pgtos-->

<!---Modal Status Lote Pedidos FEITO-->
<p-dialog header="Altera Status Lote Pedidos" [(visible)]="modalStatusPedidoLote" [style]="{ width: '600px' }"
  [modal]="true" styleClass="status-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div *ngIf="modalStatusPedidoLote">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>ALTERAÇÃO</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="alteracoesPossiveisPedido"
          placeholder="Selecione a Alteração" [(ngModel)]="atualizacaoPedido"></p-dropdown>
      </div>
    </div>
    <div class="form-row" *ngIf="atualizacaoPedido === 1">
      <div class="form-group col-sm-12">
        <label>STATUS</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesStatusAprovacao"
          placeholder="Selecione Status" [(ngModel)]="cloneStatusPedido"></p-dropdown>
      </div>
    </div>
    <div class="form-row" *ngIf="atualizacaoPedido === 2">
      <div class="form-group col-sm-12">
        <label>DATA DE EMISSÃO</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-dropdown" dateFormat="dd/mm/yy"
          [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"  [(ngModel)]="dtEmissaoNova"></p-calendar>
      </div>
    </div>
    <div class="form-row" *ngIf="atualizacaoPedido === 2">
      <div class="form-group col-sm-12">
        <label>
          <p-checkbox [(ngModel)]="faturaAutomaticoLote"></p-checkbox>
          <label class="ml-1">FATURA AUTOMÁTICO</label>
        </label>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalStatusPedidoLote = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="aprovarPedidosLote()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>
<!---Fim Status Lote Pedidos-->

<!---Modal Clone Pedido -->
<p-dialog header="Clonar Pedido" [(visible)]="modalClonePedido" [style]="{ width: '600px' }" [modal]="true"
  styleClass="clonar-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div *ngIf="modalClonePedido">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>STATUS</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesStatusPedido"
          placeholder="Selecione Status Clone" [(ngModel)]="cloneStatusPedido" appendTo="body"></p-dropdown>
      </div>
      <div class="form-group col-sm-3">
        <label>DATA ENTREGA</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
          [(ngModel)]="pedido.dtEntrega" [monthNavigator]="true" [(ngModel)]="pedido.dtEntrega" [yearNavigator]="true"
          [defaultDate]="defaultDate" yearRange="2010:2030" appendTo="body"></p-calendar>
      </div>
      <div class="form-group col-sm-3">
        <label>DATA EMISSÃO</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
          [(ngModel)]="pedido.dtEmissao" [monthNavigator]="true" [minDate]="pedido.dtFaturamentoAutomatico"
          [(ngModel)]="pedido.dtEmissao" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
          appendTo="body">
        </p-calendar>
      </div>
      <div class="form-group col-sm-3">
        <label>DATA ENTRADA/SAIDA</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
          [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true"
          [(ngModel)]="pedido.dtFaturamentoAutomatico" [yearNavigator]="true" [defaultDate]="defaultDate"
          yearRange="2010:2030" appendTo="body"></p-calendar>
      </div>
    </div>
    <div class="d-flex align-items-end">
      <div class="form-group">
        <label>PARTICIPANTE</label>
        <p>
          {{ pedido.participante.id ? pedido.participante.codigo +'-'+ pedido.participante.fantasia :
          'Não informado'}}
        </p>
      </div>
      <span class="form-group ml-2" matTooltip="Alterar Participante" (click)="openModal()">
        <span class="material-icons" style="color: #666; font-size: 18px;cursor: pointer;">edit</span>
      </span>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalClonePedido = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="clonarPedido()"><i class="fas fa-check"></i>
      Clonar</button>
  </p-footer>

</p-dialog>
<!---Fim Clone Pedido-->

<!---Modal Contrato-->
<p-dialog header="Adicionar Detalhes Contrato" [(visible)]="exibirModalContrato" [style]="{ width: '800px' }"
  [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>CONTRATO ATIVO:</label>
    </div>

    <div class="form-group col-sm-8">
      <p-checkbox [(ngModel)]="pedido.contrato"></p-checkbox>
      <label class="ml-1">CONTRATO</label>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>DATA TÉRMINO CONTRATO: </label>
    </div>

    <div class="form-group col-sm-8">
      <p-calendar (onInput)="onInputDate($event)" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [(ngModel)]="pedido.dtFinalContrato" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
    </div>
  </div>

  <p-footer>
    <div class="lks-modal-button">
      <button class="btn btn-secondary" (click)="exibirModalContrato = false">
        Cancelar
      </button>
      <button class="btn btn-primary" (click)="gerarContratos()">Salvar</button>
    </div>
  </p-footer>
</p-dialog>
<!---Fim Modal Contrato-->

<!-- Inicio Modal Detalhes Motivo Recusa -->
<p-dialog header="Motivos de recusa" [(visible)]="exibirModalRejeicao" [style]="{ width: '800px' }" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title">
        <i class="fas fa-info-circle"></i> Informações da Recusa
      </h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <textarea class="form-control" disabled="true" rows="8">{{
        descricaoMotivoRejeicao
      }}</textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="alert alert-warning alertcustom" role="alert">
        <i class="fas fa-warning"></i> Para tentar emitir a NF novamente faça um
        clone deste pedido.
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirModalRejeicao = false;"><i class="fas fa-undo"></i>
      Fechar</button>
    <button class="btn btn-primary" (click)='reenviarNf()'><i class="fas fa-refresh"></i> EMITIR NF</button>
  </p-footer>
</p-dialog>
<!-- Final Modal Detalhes Motivo Recusa -->

<!---Modal Clone Lote Pedidos FEITO-->
<p-dialog header="Clonar Lote Pedidos" [(visible)]="modalClonePedidoLote" width="600" [modal]="true"
  styleClass="lote-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div *ngIf="modalClonePedidoLote">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>STATUS</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusPedido"
          placeholder="Selecione Status Clone" [(ngModel)]="cloneStatusPedido" appendTo="body"></p-dropdown>
      </div>
      <div class="form-group col-sm-3">
        <label>DATA ENTREGA</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
          [(ngModel)]="pedido.dtEntrega" [monthNavigator]="true" [(ngModel)]="pedido.dtEntrega" [yearNavigator]="true"
          [defaultDate]="defaultDate" yearRange="2010:2030" appendTo="body"></p-calendar>
      </div>
      <div class="form-group col-sm-3">
        <label>DATA EMISSÃO</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
          [(ngModel)]="pedido.dtEmissao" [monthNavigator]="true" [minDate]="pedido.dtFaturamentoAutomatico"
          [(ngModel)]="pedido.dtEmissao" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
          appendTo="body">
        </p-calendar>
      </div>
      <div class="form-group col-sm-3">
        <label>DATA ENTRADA/SAIDA</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
          [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true"
          [(ngModel)]="pedido.dtFaturamentoAutomatico" [yearNavigator]="true" [defaultDate]="defaultDate"
          yearRange="2010:2030" appendTo="body"></p-calendar>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 25px; bottom: 30px">
      <div class="ui-g-12"></div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalClonePedidoLote = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="clonarPedidosLote()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>
<!---Fim Clone Lote Pedidos-->

<!-- Modal Alterar Período FEITO-->
<p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{ width: '400px' }" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body"
  [closable]="true">
  <div *ngIf="modalPeriodo">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>Data Inicial</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
          [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataIni" [(ngModel)]="dataIni"
          dateFormat="dd/mm/yy"></p-calendar>
      </div>
      <div class="form-group col-sm-4">
        <label>Data Final</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
          [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataFin" [(ngModel)]="dataFin"
          dateFormat="dd/mm/yy"></p-calendar>
      </div>
    </div>
  </div>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px">
    <div class="ui-g-12"></div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)="closeModalPeriodo()">
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button type="button" class="btn btn-primary" (click)="alterarPeriodo()">
          <i class="fas fa-pencil-alt"></i> Alterar
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!---Modal Impressão -->
<p-dialog header="Impressão Pedido" [(visible)]="modalImpressao" [style]="{ width: '600px' }" [modal]="true"
  styleClass="clonar-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div *ngIf="modalImpressao">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <p-checkbox class="mr-1" [(ngModel)]="impressaoFormaPagamento" [binary]="true" inputId="impressaoPedido">
        </p-checkbox>
        <label for="impressaoPedido">Exibir detalhes de pagamento</label>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalImpressao = false; impressaoFormaPagamento = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="realizarImpressao()">
      <i class="fas fa-print"></i> Imprimir
    </button>
  </p-footer>
</p-dialog>
<!---Fim Impressão-->

<!-- modal ediçãostatus pedido -->
<p-dialog header="Alterar status da entrega" [(visible)]="modalStatusEntrega" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Status da entrega</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusEntrega"
        placeholder="Selecione o status" [(ngModel)]="statusEntregaId"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalStatusEntrega=false'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="mudarStatusEntrega()"><i class="fas fa-pencil-alt"></i>
          Alterar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Modal Participante-->
<app-modal-participante [(isVisible)]="modalParticipante"
  (onSelectParticipant)="newRequest?newParticipante($event):salvarParticipante($event)"
  [participanteTipoId]="tipo == 0 ? 2 : 1" [saveButtonLabel]="newRequest?'Escolher':'Salvar'">
</app-modal-participante>
