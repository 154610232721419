<ng-content *ngIf="!config.isLoading"></ng-content>
<p-table *ngIf="config.isLoading" [class]="config.styleClass" [style]="{ width: '100%'}" [loading]="true" [showLoader]="false">

  <ng-template pTemplate="header">
    <tr>
      <th class="text-center tableHeaderCheckbox" *ngIf="config.hasSelection">
        <span class="check-all p-0"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></span>
      </th>
      <th *ngFor="let column of config.columnList" [style]="{width:column.size,'text-align':column.textAlign}">
        <span class="text-overflow-dynamic-container" [pSortableColumn]="'column'">
          <span class="text-overflow-dynamic-ellipsis">
            {{column.name}}
            <p-sortIcon *ngIf="config.hasSort" [field]="'column'"></p-sortIcon>
          </span>
        </span>
        <input *ngIf="config.hasFilter" class="form-control form-control-sm" type="text">
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="loadingbody">
    <tr *ngFor="let item of [1,2,3]">
      <td *ngIf="config.hasSelection" class="text-center">
        <p-tableCheckbox></p-tableCheckbox>
      </td>
      <td *ngFor="let column of config.columnList" class="text-center">
        <p-skeleton width="100%"></p-skeleton>
      </td>
    </tr>
  </ng-template>

</p-table>