<app-data-table
class="projeto-dbl-relac"
mainTitle="View Projeto Relac"
singleTitle="View Projeto Relac"
routerPath="/view-projeto-dbl-relac"
entityPath="/viewprojetodblrelac"
[entity]="viewprojetodblrelac"
[entityFilter]="entityFilter"
[columns]="this['columns']"
[controls]="this['controls']">
</app-data-table>