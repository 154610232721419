<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div class="box-content pt-2" *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarPlano"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="clearfix"></div>
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="planoContas"
              [columns]="colsplanoContas" [paginator]="true" [rows]="rows" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="selecionarTudo()"
              [(selection)]="selectPlano" [totalRecords]="totalRecords" [lazy]="true" (onLazyLoad)="loadPlanoContasCompleto($event)" [lazyLoadOnInit]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descrCta', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                      <span class="text-overflow-dynamic-ellipsis">INDICADOR <p-sortIcon [field]="'indCta'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'indCta', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                      <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nivel', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codNaturezaConta.descricao'">
                      <span class="text-overflow-dynamic-ellipsis">NATUREZA <p-sortIcon
                          [field]="'codNaturezaConta.descricao'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codNaturezaConta.descricao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtInicial'">
                      <span class="text-overflow-dynamic-ellipsis">DATA INICIAL <p-sortIcon [field]="'dtInicial'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dtInicial', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%; vertical-align: sub;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">ATIVA <p-sortIcon [field]="'ativo'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 5%;">
                    <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codCta }}">{{ rowData.codCta
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 35%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrCta}}">{{rowData.descrCta
                        }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="A - Analítica"
                        *ngIf="rowData.indCta === 'A'">A - Analítica</span>
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="S - Sintética"
                        *ngIf="rowData.indCta === 'S'">S - Sintética</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nivel}}">{{rowData.nivel
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codNaturezaConta.descricao}}">{{rowData.codNaturezaConta.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtInicial}}">{{rowData.dtInicial
                        | timezone | date:'dd/MM/yyyy' }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <p-checkbox [disabled]="true" [binary]="true" [(ngModel)]="rowData.ativo"></p-checkbox>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content space">
              <div class="actions-card">
                <app-toolbar [value]="toolbarPlano"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group col-sm-12">
              <label>Código<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText
                [(ngModel)]="codigo">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Descrição<span class="obrigatorio">*</span></label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricao">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Descrição Inglês</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricaoEn">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Descrição Espanhol</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricaoEs">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Natureza da Conta<span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesNatureza" tool
                placeholder="Selecione a Natureza da Conta" (onChange)="alterarContasMapeadas(naturezaConta)"
                [(ngModel)]="naturezaConta">
              </p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Indicador<span class="obrigatorio">*</span></label>
              <p-dropdown id="indCta" name="indCta" class="type-p-dropdown" [filter]="true" [options]="opcoesIndicador"
                placeholder="Selecione o Indicador" [(ngModel)]="indCta" (onChange)="alterarNatureza()"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Níveis<span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesNivel" tool
                placeholder="Selecione o nível" [(ngModel)]="nivel" (onChange)="alterarNatureza()"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Conta Superior</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="planoContasSup" tool
                placeholder="Selecione a Conta Superior" [(ngModel)]="ctaSup"></p-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="indCta == 'A'">
            <div class="form-group col-sm-12">
              <label>Conta Referencial RFB</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="planoContasMapeadas" tool
                placeholder="Selecione a Conta Superior" [(ngModel)]="ctaMapeada"></p-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="editarConta">
            <div class="form-group mx-sm-1">
              <mat-slide-toggle class="checkBox mr-1" [(ngModel)]="ativa">Ativa</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group mx-sm-1">
              <mat-slide-toggle class="checkBox mr-1" [(ngModel)]="permiteLancamentoManual">
                Permite Lançamento Manual
              </mat-slide-toggle>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group mx-sm-1">
              <mat-slide-toggle class="checkBox mr-1" [(ngModel)]="aceitaRateio">
                Aceita Rateio
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!--Modal Clone Plano Contas-->
  <p-dialog header="Código e descrição para clonagem do Plano de Contas" [(visible)]="modalClonarPlanoContas"
    [style]="{ width: '600px' }" [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body"
    [closable]="true">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-row" *ngIf="clonagemNaPropriaEmpresa">
          <div class="form-group col-sm-4">
            <label>Código <span class="obrigatorio">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="codigoClonagem" />
          </div>
          <div class="form-group col-sm-4">
            <label>Descrição <span class="obrigatorio">*</span></label>
            <input type="text" class="form-control" [(ngModel)]="descricaoClonagem" />
          </div>
          <div class="form-group col-sm-4">
            <label>Descrição Inglês </label>
            <input type="text" class="form-control" [(ngModel)]="descricaoInglesClonagem" />
          </div>
        </div>
        <div class="form-row" *ngIf="registerGroupCompanies && !clonagemNaPropriaEmpresa">
          <div class="form-group col-sm-12">
            <label>Deseja clonar em quais empresas do grupo?</label>
            <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="companiesGroup" [(ngModel)]="selectedCompaniesGroup"></p-multiSelect>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <p-checkbox [binary]="true" class="checkBox mr-1" [(ngModel)]="clonarRelacaoRfb"></p-checkbox>
            <label>Clonar relação com Conta Referencial RFB</label>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalClonarPlanoContas = false;"><i
          class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="determinarMetodoClonagem()">
        <i class="fas fa-check"></i>
        Salvar
      </button>
    </p-footer>
  </p-dialog>

  <p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll" [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

    <label>{{ descricaoModalGrupoEmpresas }}</label>

    <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="companiesGroup" [(ngModel)]="selectedCompaniesGroup">
    </p-multiSelect>

    <p-footer class="d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="this.novaConta ? salvarNovaContaEmGrupoEmpresas() : salvarAlteracaoContaEmGrupoEmpresas()">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>
    </p-footer>
  </p-dialog>
</mat-card>
