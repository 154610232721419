<mat-card class="card-linnks margin-linnks">

    <mat-card-title>
      {{titlePrincipal}}
      <mat-card-subtitle>
        {{subtitle}}
      </mat-card-subtitle>
    </mat-card-title>

    <div class="ui-g">
      <div class="ui-g-12">

      </div>
    </div>

    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="Eventos por Projeto">
          <div class="box-content">
            <div class="row">
              <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-filepowerpoint"></i> PROJETO e OPERAÇÃO</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                  <label>EVENTO</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" [options]="projetos"
                  placeholder="Selecione o Evento" [(ngModel)]="projId" (onChange)="buscarEventos()"></p-dropdown>
              </div>
              <div class="form-group col-sm-4">
                  <label>OPERAÇÃO EMISSÃO</label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoEmissao" placeholder="Selecione uma Operacao"
                [(ngModel)]="operacaoEmissaoId"></p-dropdown>
            </div>
            </div>
            <div class="form-row">
              <div class="col-sm-3">
                <label class="d-block" style="color: #fff; cursor: default;">DO NOT REMOVE</label>
                <div class="p-field-checkbox mb-2">
                  <p-checkbox class="mr-1" [(ngModel)]="adto" [binary]="true" inputId="adto"></p-checkbox>
                  <label for="adto">Adiantamento</label>
                </div>
              </div>
              <div class="form-group col-sm-4" *ngIf="adto">
                  <label>OPERAÇÃO FINANCEIRA</label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoFin" placeholder="Selecione uma Operacao"
                [(ngModel)]="operacaoFinId"></p-dropdown>
            </div>


            </div>
              <div class="row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-calendar-alt"></i> EVENTOS</h3>
              </div>
              </div>
              <div class="row">
              <div class="col-sm-6">
                <div class="conteudo m-0">
                  <p-pickList sourceHeader="Disponíveis" targetHeader="Eventos Relacionados" [source]="listaEventosRelacionar"
                    [target]="listaEventosRelacionados" [dragdrop]="true" [responsive]="true" filterBy="evt"
                    [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-evt pTemplate="item">{{evt.evt}}</ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>

  </mat-card>
