<!-- <app-data-table class="resp-cad-item"
            mainTitle="Responsáveis cadastro de item"
            singleTitle="Responsável"
            routerPath="/resp-cad-item"
            entityPath="/respcaditem"
            keyField="id"
            [entity]="respCadItem"
            [columns]="this['columns']"
            [controls]="this['controls']">
</app-data-table> -->

<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div class="box-content pt-2" *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content" style="padding: 1.5rem !important;">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system"
              #dt
              [columns]="this['columns']"
              [value]="registrosResponsaveis"
              [rows]="rows"
              [paginator]="true"
              [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}"
              immutable="false"
              (onRowSelect)="selectCheckbox($event)"
              (onRowUnselect)="unselectCheckbox($event)"
              selectionMode="multiple"
              >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'responsaveis'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Responsáveis <p-sortIcon [field]="'responsaveis'"></p-sortIcon>
                      </span>
                    </span>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      (input)="dt.filter($event.target.value, 'responsaveis', 'contains')"
                      >
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" [id]="'checkboxContainer'+rowData.id" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.responsaveis}}">{{rowData.responsaveis}}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="2" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">

        <div class="header-box">
          <div class="header-box-content" style="padding: 1.5rem !important;">
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Responsáveis<span class="obrigatorio">*</span></label>
              <input
                class="form-control"
                id="input"
                type="text"
                pInputText
                [(ngModel)]="responsaveis"
                >
            </div>
          </div>

        </div>
      </div>
    </div>


  </mat-card-content>
</mat-card>