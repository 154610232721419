<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}} Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>




  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt20.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt20 [columns]="colsPagamentosPagSeguro" [value]="listaPagamentosPagSeguro"
          [rows]="rows" [totalRecords]="listaPagamentosPagSeguro.length" [paginator]="true" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>

              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'nome', 'contains')">
              </th>
              <th class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                  <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'cpf', 'contains')">
              </th>

              <th class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'telefone'">
                  <span class="text-overflow-dynamic-ellipsis">TELEFONE <p-sortIcon [field]="'telefone'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'telefone', 'contains')">
              </th>

              <th class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoCompleta'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricaoCompleta'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'descricaoCompleta', 'contains')">
              </th>

              <th class="text-left" style="width: 4%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcelaCompleta'">
                  <span class="text-overflow-dynamic-ellipsis">PARCELA <p-sortIcon [field]="'parcelaCompleta'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'parcelaCompleta', 'contains')">
              </th>

              <th class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cobrarEm'">
                  <span class="text-overflow-dynamic-ellipsis">COBRAR EM <p-sortIcon [field]="'cobrarEm'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'cobrarEm', 'contains')">
              </th>

              <th class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'pagoEm'">
                  <span class="text-overflow-dynamic-ellipsis">PAGO EM <p-sortIcon [field]="'pagoEm'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'pagoEm', 'contains')">
              </th>


              <th class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                  <span class="text-overflow-dynamic-ellipsis">VL TOTAL <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'valorTotal', 'contains')">
              </th>

              <th class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorPago'">
                  <span class="text-overflow-dynamic-ellipsis">VL PAGO <p-sortIcon [field]="'valorPago'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'valorPago', 'contains')">
              </th>

              <th class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorReembolso'">
                  <span class="text-overflow-dynamic-ellipsis">VL REEMBOLSO <p-sortIcon [field]="'valorReembolso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt20.filter($event.target.value, 'valorReembolso', 'contains')">
              </th>

              <th class="text-center" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'statusPagSeguroPortugues'">
                  <span class="text-overflow-dynamic-ellipsis">ST PagSeguro <p-sortIcon
                      [field]="'statusPagSeguroPortugues'"></p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt20.filter($event.value, 'statusPagSeguroPortugues', 'in')">
                </p-multiSelect>
              </th>

              <th class="text-center" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcelaCongeladaPortugues'">
                  <span class="text-overflow-dynamic-ellipsis">CONGELADO <p-sortIcon
                      [field]="'parcelaCongeladaPortugues'"></p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboCongelado" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt20.filter($event.value, 'parcelaCongeladaPortugues', 'in')">
                </p-multiSelect>
              </th>

              <th class="text-center" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'recorrenciaPt'">
                  <span class="text-overflow-dynamic-ellipsis">RECORRÊNCIA <p-sortIcon [field]="'recorrenciaPt'">
                    </p-sortIcon></span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboRecorrencia" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt20.filter($event.value, 'recorrenciaPt', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.nome }} </span> </span>
                </span>
              </td>

              <td class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf}}"
                    (click)="showModalEditarPagamento(rowData)"><span style="user-select: all; cursor: text">
                      {{rowData.cpf}} </span></span>
                </span>
              </td>
              <td class="text-left" style="width: 7%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.telefone}}"
                    (click)="showModalEditarPagamento(rowData)"><span style="user-select: all; cursor: text">
                      {{rowData.telefone}} </span></span>
                </span>
              </td>
              <td class="text-left" style="width: 8%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricaoCompleta}}"
                    (click)="showModalEditarPagamento(rowData)"><span style="user-select: all; cursor: text">
                      {{rowData.descricaoCompleta }} </span> </span>
                </span>
              </td>

              <td class="text-left" style="width: 4%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.parcelaCompleta}}"
                    (click)="showModalEditarPagamento(rowData)"><span style="user-select: all; cursor: text">
                      {{rowData.parcelaCompleta }} </span></span>
                </span>
              </td>

              <td class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cobrarEm}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.cobrarEm }}</span></span>
                </span>
              </td>


              <td class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.pagoEm}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.pagoEm }}</span></span>
                </span>
              </td>

              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorTotal}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.valorTotal }}</span></span>
                </span>
              </td>

              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorPago}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.valorPago }}</span></span>
                </span>
              </td>

              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorReembolso}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.valorReembolso }}</span></span>
                </span>
              </td>

              <td class="text-left" style="width: 9%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.statusPagSeguroPortugues}}"
                    (click)="showModalEditarPagamento(rowData)"><span
                      style="user-select: all; cursor: text">{{rowData.statusPagSeguroPortugues }}</span></span>
                </span>
              </td>


              <td class="text-center" style="width: 4.8%;">
                <div *ngIf="rowData.parcelaCongelada"><span style="user-select: all; cursor: text"
                    (click)="showModalEditarPagamento(rowData)">
                    <p-checkbox [binary]="true"></p-checkbox>
                  </span></div>
                <div *ngIf="!rowData.parcelaCongelada"><span style="user-select: all; cursor: text"
                    (click)="showModalEditarPagamento(rowData)">
                    <p-checkbox [binary]="true"></p-checkbox>
                  </span> </div>
              </td>

              <td class="text-center" style="width: 4.8%;">
                <div *ngIf="rowData.recorrencia">
                  <p-checkbox [binary]="true" (click)="showModalEditarPagamento(rowData)">
                  </p-checkbox>
                </div>
                <div *ngIf="!rowData.recorrencia">
                  <p-checkbox [binary]="true" (click)="showModalEditarPagamento(rowData)">
                  </p-checkbox>
                </div>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="13" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>

      <!-- Inicio Modal Alternar periodo -->
      <p-dialog header="Alterar Período" [(visible)]="modalSelecionarData" [style]="{width: '400px'}" [modal]="true"
        styleClass="modal-initial" appendTo="body" [closable]="true">

        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>Data Inicial</label>
            <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
              [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)"
              >
            </p-calendar>
          </div>
          <div class="form-group col-sm-4">
            <label>Data Final</label>
            <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
              [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
          </div>
        </div>
        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalSelecionarData=false'><i
              class="fas fa-undo"></i> Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="atualizarVoltar()"><i class="fas fa-pencil-alt"></i>
            Alterar</button>
        </p-footer>
      </p-dialog>
      <!-- Final modal Alternar periodo -->


      <!-- Inicio Modal Editar Pagamento -->
      <p-dialog header="" [(visible)]="modalEditarPagamento" width="800" [modal]="true" styleClass="modal-initial"
        [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true"
        styleClass="modal-scroll participante-eventos-altura">
        <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange($event)">
          <mat-tab label="Editar Pagamento">
            <div class="box-content pt-2 pl-0 pr-0">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>NOME</label>
                  <input [(ngModel)]="nome" class="form-control text-left" type="text" readOnly="true" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>CPF</label>
                  <input [(ngModel)]="cpf" class="form-control text-left" type="text" readOnly="true" />
                </div>

                <div class="form-group col-sm-6">
                  <label>TELEFONE</label>
                  <input [(ngModel)]="telefone" class="form-control text-left" type="text" readOnly="true" />
                </div>

              </div>



              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>DESCRIÇÃO EVENTO</label>
                  <input [(ngModel)]="descricaoEvento" class="form-control text-left" type="text" readOnly="true" />
                </div>
              </div>

              <div class="form-row">
                <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-credit-card"> </i> DADOS DO CARTÃO</h3>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>NOME</label>
                  <input [(ngModel)]="cartaoNome" readOnly="true" class="form-control text-left" type="text" />
                </div>
                <div class="form-group col-sm-6">
                  <label>4 ÚLTIMOS DIGITOS</label>
                  <input [(ngModel)]="cartaoDigito" readOnly="true" class="form-control text-left" type="text" />
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>PARCELA</label>
                  <input [(ngModel)]="parcelaReferencia" readOnly="true" class="form-control text-left" type="text" />
                </div>
                <div class="form-group col-sm-3">
                  <label>COBRAR EM</label>
                  <p-calendar class="type-p-calendar" [disabled]="!isPodeEditar" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataCobrarEm" [(ngModel)]="dataCobrarEm"
                    dateFormat="dd/mm/yy" >
                  </p-calendar>
                </div>
                <div class="form-group col-sm-3">
                  <label>VALOR À PAGAR</label>
                  <input [(ngModel)]="valorPagamento" [disabled]="!isPodeEditar" class="form-control text-left"
                    type="text" money />
                </div>
                <div class="form-group col-sm-3">
                  <label>VALOR REEMBOLSSO</label>
                  <input [(ngModel)]="valorTotalReembolsado" [disabled]="true" class="form-control text-left"
                    type="text" money />
                </div>

              </div>

              <div class="form-row">
                <div class="col-sm-4">
                  <mat-slide-toggle class="mr-2" [disabled]="!isPodeEditar" [(ngModel)]="congelarParcela"> CONGELAR
                    PARCELA </mat-slide-toggle>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>MENSAGEM PAGSEGURO</label>
                  <input [(ngModel)]="mensagemPagSeguro" class="form-control text-left" type="text" readOnly="true" />
                </div>
              </div>

              <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
                <div class="ui-g-12">

                </div>
              </div>


              <p-footer>
                <button type="button" class="btn btn-secondary"
                  (click)='modalEditarPagamento=false; dataIni = null; dataFin = null'><i class="fas fa-undo"></i>
                  Cancelar</button>
                <button type="button" class="btn btn-primary" [disabled]="!isPodeEditar"
                  (click)="salvarEdicaoParcelaPagamento();"><i class="fas fa-pencil-alt"></i> Alterar</button>
                <button type="button" class="btn btn-primary" [disabled]="!isPodeEditar"
                  (click)="congelarParcelas();"><i class="fas fa-ban"></i> Congelar parcelas futuras</button>
                <button type="button" class="btn btn-primary" [disabled]="!isPodeEstornar"
                  (click)="estornarPagamento();"><i class="fas fa-money-bill-alt"></i> Estornar</button>
              </p-footer>
            </div>



          </mat-tab>
          <mat-tab label="Justificativas de Estorno">
            <div class="box-content pt-2 pl-0 pr-0">

              <p-table class="table-system" #dt3 [value]="listaJustificativaPagSeguro" [rows]="rows"
                [totalRecords]="listaJustificativaPagSeguro.length" [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
                <ng-template pTemplate="header">
                  <tr>

                    <th class="text-left" style="width: 16%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">DT SOLICITAÇÃO </span>
                      </span>

                    </th>
                    <th class="text-left" style="width: 54%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">JUSTIFICATIVA </span>
                      </span>

                    </th>

                    <th class="text-left" style="width: 30%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">USUÁRIO </span>
                      </span>

                    </th>


                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataSolicitacao}}"><span
                            style="user-select: all; cursor: text">{{rowData.dataSolicitacao }} </span> </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.justificativa}}"><span
                            style="user-select: all; cursor: text"> {{rowData.justificativa}} </span></span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 7%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeUsuario}}"><span
                            style="user-select: all; cursor: text"> {{rowData.nomeUsuario}} </span></span>
                      </span>
                    </td>





                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>


          </mat-tab>
        </mat-tab-group>


      </p-dialog>
      <!-- Final Modal Editar Pagamento -->



      <!-- Inicio Modal Estorno -->
      <p-dialog header="Estorno" [(visible)]="modalEstorno" [style]="{width: '400px'}" [modal]="true"
        styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
        [closable]="true">

        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>VALOR A SER ESTORNADO</label>
            <input [(ngModel)]="valorEstorno" (blur)="verificarValorEstornado()" class="form-control text-left"
              type="text" money />
          </div>

          <div class="form-group col-sm-6">
            <label>SALDO DISPONÍVEL R$</label>
            <input [(ngModel)]="saldoReembolso" [disabled]="true" class="form-control text-left" type="text" money />
          </div>

        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <mat-slide-toggle [(ngModel)]="boolCongelarParcela"> Congelar as parcelas Futuras </mat-slide-toggle>
          </div>

        </div>

        <div class="form-row">
          <label>JUSTIFICATIVA</label>
          <textarea class="form-control" [(ngModel)]="justificativaEstorno" rows="2"></textarea>
        </div>


        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
          <div class="ui-g-12">

          </div>
        </div>

        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalEstorno=false'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="salvarEstorno()"><i class="fas fa-pencil-alt"></i>
            Salvar</button>
        </p-footer>
      </p-dialog>
      <!-- Final modal Estorno -->


    </mat-card-content>
  </div>

</mat-card>