<!-- <app-data-table class="participante"
                mainTitle="Participantes"
                singleTitle="Participante"
                application="auditor"
                routerPath="/auditor/participante"
                entityPath="/participante"
                [entity]="participante"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table> -->

<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div class="box-content pt-2" *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">
          <div class="header-box">
            <div class="header-box-content" style="padding: 1.5rem !important;">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system"
              #dt
              [columns]="this['columns']"
              [value]="registrosParticipantes"
              [rows]="rows"
              [paginator]="true"
              [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}"
              immutable="false"
              (onRowSelect)="selectCheckbox($event)"
              (onRowUnselect)="unselectCheckbox($event)"
              selectionMode="multiple"
              >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon>
                      </span>
                    </span>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      (input)="dt.filter($event.target.value, 'cnpj', 'contains')"
                      >
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'razao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Razão <p-sortIcon [field]="'razao'"></p-sortIcon>
                      </span>
                    </span>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      (input)="dt.filter($event.target.value, 'razao', 'contains')"
                      >
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Fantasia<p-sortIcon [field]="'fantasia'"></p-sortIcon>
                      </span>
                    </span>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      (input)="dt.filter($event.target.value, 'fantasia', 'contains')"
                      >
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresa'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Empresa <p-sortIcon [field]="'empresa'"></p-sortIcon>
                      </span>
                    </span>
                    <input
                      class="form-control form-control-sm"
                      type="text"
                      (input)="dt.filter($event.target.value, 'empresa', 'contains')"
                      >
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" [id]="'checkboxContainer'+rowData.id">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span
                        class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.cnpj}}"
                        >
                        {{rowData.cnpj}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span
                        class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.razao}}"
                        >
                        {{rowData.razao}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span
                        class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.fantasia}}"
                        >
                        {{rowData.fantasia}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span
                        class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.empresa}}"
                        >
                        {{rowData.empresa}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>




    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content" style="padding: 1.5rem !important;">
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content">

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>CNPJ<span class="obrigatorio">*</span></label>
              <input
                class="form-control"
                id="input"
                type="text"
                pInputText
                [(ngModel)]="cnpj"
                >
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Código<span class="obrigatorio">*</span></label>
              <input
                class="form-control"
                id="input"
                type="text"
                pInputText
                [(ngModel)]="codigo"
                >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Razão<span class="obrigatorio">*</span></label>
              <input
                class="form-control"
                id="input"
                type="text"
                pInputText
                [(ngModel)]="razao"
                >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Fantasia</label>
              <input
                class="form-control"
                id="input"
                type="text"
                pInputText
                [(ngModel)]="fantasia"
                >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Empresa</label>
                <p-dropdown class="type-p-dropdown"
                  placeholder="Selecione"
                  [options]="empresas"
                  [(ngModel)]="selectedEmpresa"
                  [filter] = "true"
                  filterBy="razaoCnpj"
                  optionLabel="razaoCnpj"
                  optionValue="idempresa"></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>


  </mat-card-content>
</mat-card>