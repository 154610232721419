
<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"> &nbsp;</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registrosTipi" [rows]="rows"
            [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
            immutable="false" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
            selectionMode="single" [(selection)]="selectTipi">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 40px;">
                </th>
                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'ncm'">
                    Ncm <p-sortIcon [field]="'ncm'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'ncm', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'ex'">
                    Ex <p-sortIcon [field]="'ex'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'ex', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'descricao'">
                    Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'aliqIpi'">
                    Ipi <p-sortIcon [field]="'aliqIpi'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'aliqIpi', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'limitereintegra'">
                    Reintegra <p-sortIcon [field]="'limitereintegra'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'limitereintegra', 'contains')">
                </th>

                <th class="text-left">
                  <span class="overflow-ellipse text-left" [pSortableColumn]="'padis'">
                    Padis <p-sortIcon [field]="'padis'"></p-sortIcon>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'padis', 'contains')">
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 40px;">
                  <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData">
                  </p-tableRadioButton>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ncm}}">
                      {{rowData.ncm}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ex}}">
                      {{rowData.ex}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">
                      {{rowData.descricao}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.aliqIpi}}">
                      {{rowData.aliqIpi}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.limitereintegra}}">
                      {{rowData.limitereintegra}}
                    </span>
                  </span>
                </td>

                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.padis}}">
                      {{rowData.padis}}
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          </div>
        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content" style="padding: 1.5rem !important;">
            <div class="space">
            <div class="actions-card spaceCard">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
          </div>
        </div>

        <div class="box-content">
          <div class="form-size">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>NCM<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText name="ncm" 
                  maxlength="8" [(ngModel)]="ncm">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>EX</label>
                <input class="form-control" id="input" type="text" pInputText name="ex" 
                  maxlength="3" [(ngModel)]="ex">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText name="descricao"
                  [(ngModel)]="descricao">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>ALIQ. IPI</label>
                <input class="form-control" id="input" type="text" pInputText name="aliqIpi"
                maxlength="4"  [(ngModel)]="aliqIpi">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>CAPÍTULO<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText name="capitulo"
               [(ngModel)]="capitulo">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>LIMITE REINTEGRA</label>
                <input class="form-control" id="input" type="text" pInputText name="limite_reintegra"
                  maxlength="2" [(ngModel)]="limitereintegra">
              </div>
            </div>

            <div style="display: flex;">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Data Inicial<span class="obrigatorio">*</span></label><br>
                    <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/aaaa" [monthNavigator]="true" [yearNavigator]="true"
                    yearRange="2010:2030" [(ngModel)]="dtIni">
                  </p-calendar>
                </div>
              </div>

              <div class="form-row" style="padding: 0 60px;">
                <div class="form-group col-sm-12">
                  <label>Data Final<span class="obrigatorio">*</span></label><br>
                    <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" placeholder="dd/mm/aaaa" [monthNavigator]="true" [yearNavigator]="true"
                    yearRange="2010:2030" [(ngModel)]="dtFim">
                  </p-calendar>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Padis</label>
                <input class="form-control" id="input" type="text" pInputText name="padis" [(ngModel)]="padis">
              </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>