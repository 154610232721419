<mat-card class="card-linnks margin-linnks">
  <mat-card-content>
    <div class="box-content pt-2">
      <div class="row">
        <div class="col-sm-12">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card"> Cadastro de regra de movimentação interna de estoque</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- Table New P-TABLE -->
          <div style="margin-top: 5px;">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="listaMi" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="exibirModalEditRegistro($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'codigo', 'contains')">
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'descricao', 'contains')">
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo.descricao'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Tipo <p-sortIcon [field]="'tipo.descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <div style="margin-top: -2px;">
                      <p-multiSelect #cmp class="type-p-mult" [options]="opcoesTipo" defaultLabel="Selecione"
                        (onChange)="dt.filter($event.value, 'tipo.descricao', 'in')"></p-multiSelect>
                    </div>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Quantidade <p-sortIcon [field]="'quantidade'"></p-sortIcon>
                      </span>
                    </span>
                    <div style="margin-top: -2px;">
                      <p-multiSelect #cmp [filter]="false" class="type-p-mult" [options]="opcoesBool" defaultLabel="Selecione"
                        (onChange)="dt.filter($event.value, 'quantidade', 'in')"></p-multiSelect>
                    </div>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                    </span>
                    <div style="margin-top: -2px;">
                      <p-multiSelect #cmp [filter]="false" class="type-p-mult" [options]="opcoesBool" defaultLabel="Selecione"
                        (onChange)="dt.filter($event.value, 'valor', 'in')"></p-multiSelect>
                    </div>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'transfAlmoxarifado'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Transf.alm <p-sortIcon [field]="'transfAlmoxarifado'"></p-sortIcon>
                      </span>
                    </span>
                    <div style="margin-top: -2px;">
                      <p-multiSelect #cmp [filter]="false" class="type-p-mult" [options]="opcoesBool" defaultLabel="Selecione"
                        (onChange)="dt.filter($event.value, 'transfAlmoxarifado', 'in')"></p-multiSelect>
                    </div>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'transfParticipante'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Transf.part <p-sortIcon [field]="'transfParticipante'"></p-sortIcon>
                      </span>
                    </span>
                    <div style="margin-top: -2px;">
                      <p-multiSelect #cmp [filter]="false" class="type-p-mult" [options]="opcoesBool" defaultLabel="Selecione"
                        (onChange)="dt.filter($event.value, 'transfParticipante', 'in')"></p-multiSelect>
                    </div>
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'transformacao'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">
                        Transformação <p-sortIcon [field]="'transformacao'"></p-sortIcon>
                      </span>
                    </span>
                    <div style="margin-top: -2px;">
                      <p-multiSelect #cmp [filter]="false" class="type-p-mult" [options]="opcoesBool" defaultLabel="Selecione"
                        (onChange)="dt.filter($event.value, 'transformacao', 'in')"></p-multiSelect>
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo.descricao}}">
                        {{rowData.tipo.descricao}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;" matTooltip="{{rowData.quantidade ? 'Ativo' : 'Inativo'}}" >
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.quantidade" [binary]="true" inputId="quantidade" [disabled]="true"></p-checkbox>
                  </td>
                  <td class="text-center" style="width: 10%;" matTooltip="{{rowData.valor ? 'Ativo' : 'Inativo'}}">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.valor" [binary]="true" inputId="valor" [disabled]="true"></p-checkbox>
                  </td>
                  <td class="text-center" style="width: 10%;" matTooltip="{{rowData.transfAlmoxarifado ? 'Ativo' : 'Inativo'}}">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.transfAlmoxarifado" [binary]="true" inputId="transfAlmoxarifado" [disabled]="true"></p-checkbox>
                  </td>
                  <td class="text-center" style="width: 10%;" matTooltip="{{rowData.transfParticipante ? 'Ativo' : 'Inativo'}}">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.transfParticipante" [binary]="true" inputId="transfParticipante" [disabled]="true"></p-checkbox>
                  </td>
                  <td class="text-center" style="width: 10%;" matTooltip="{{rowData.transformacao ? 'Ativo' : 'Inativo'}}">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.transformacao" [binary]="true" inputId="transformacao" [disabled]="true"></p-checkbox>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!--Modal cadastro de MI-->
<p-dialog [header]="headerModal" [(visible)]="boolCriarStatus" styleClass="modal-custom" [modal]="true"
  [responsive]="true" appendTo="body" [closable]="true" [style]="{width: '600px'}" (onHide)="closeDialog()"
  [contentStyle]="{maxHeight: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código <span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{mi.codigo}}" [(ngModel)]="mi.codigo" name="inputCodigo" maxlength="200">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição <span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{mi.descricao}}" [(ngModel)]="mi.descricao" name="inputDescricao"
        maxlength="200">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Tipo Entrada/Saída <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione" [options]="opcoesTipoES"
        [(ngModel)]="mi.tipo.id" appendTo="body">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.quantidade">
        Informa quantidade
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.valor" (change)="verifRegraTransf(1)">
        Informa valor
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.transfAlmoxarifado" (change)="verifRegraTransf(2)">
        Transferência almoxarifado
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label class="d-block" style="margin-top: 7px"></label>
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.transfParticipante" (change)="verifRegraTransf(3)">
        Transferência participante
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.transformacao" (change)="verifRegraTransf(4)" [disabled]="true">
        Transformação
      </mat-slide-toggle>
    </div>
    <div class="alert alert-xs alert-primary d-inline" role="alert">
      <i class="fas fa-info-circle"></i> A funcionalidade de transformação foi desativada do sistema. Favor usar Ordem de Produção.
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="boolCriarStatus = false; boolEditStatus = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button *ngIf="boolEditStatus" type="button" class="btn btn-danger" (click)="ecluirRegistro()"><i
        class="fas fa-times"></i> Excluir</button>
    <button *ngIf="boolCriarStatus && !boolEditStatus" type="button" class="btn btn-primary"
      (click)="criarNovoRegistro()"><i class="fas fa-check"></i> Salvar</button>
    <button *ngIf="!boolCriarStatus && boolEditStatus" type="button" class="btn btn-primary"
      (click)="editarRegistro()"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
</p-dialog>


<p-dialog [header]="headerModal" [(visible)]="boolEditStatus" styleClass="modal-custom" [modal]="true"
  [responsive]="true" appendTo="body" [closable]="true" [style]="{width: '600px'}" (onHide)="closeDialog()"
  [contentStyle]="{maxHeight: '700px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Código <span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{mi.codigo}}" [(ngModel)]="mi.codigo" name="inputCodigo" maxlength="200">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição <span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{mi.descricao}}" [(ngModel)]="mi.descricao" name="inputDescricao"
        maxlength="200">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Tipo Entrada/Saída <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione" [options]="opcoesTipoES"
        [(ngModel)]="mi.tipo.id" appendTo="body">
      </p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.quantidade">
        Informa quantidade
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.valor" (change)="verifRegraTransf(1)">
        Informa valor
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.transfAlmoxarifado" (change)="verifRegraTransf(2)">
        Transferência almoxarifado
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.transfParticipante" (change)="verifRegraTransf(3)">
        Transferência participante
      </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 1px" [(ngModel)]="mi.transformacao" (change)="verifRegraTransf(4)" [disabled]="true">
        Transformação
      </mat-slide-toggle>
    </div>
    <div class="alert alert-xs alert-primary d-inline" role="alert">
      <i class="fas fa-info-circle"></i> A funcionalidade de transformação foi desativada do sistema. Favor usar Ordem de Produção.
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="boolCriarStatus = false; boolEditStatus = false;"><i
        class="fas fa-undo"></i> Cancelar</button>
    <button *ngIf="boolEditStatus" type="button" class="btn btn-danger" (click)="ecluirRegistro()"><i
        class="fas fa-times"></i> Excluir</button>
    <button *ngIf="boolCriarStatus && !boolEditStatus" type="button" class="btn btn-primary"
      (click)="criarNovoRegistro()"><i class="fas fa-check"></i> Salvar</button>
    <button *ngIf="!boolCriarStatus && boolEditStatus" type="button" class="btn btn-primary"
      (click)="editarRegistro()"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>
  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>
</p-dialog>