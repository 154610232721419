<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>



  <!-- Inicio da div pesquisa -->
  <div class="box-content" *ngIf="tipo.toString() === '1'">



    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title mt-0"><i class="fas fa-bars"> </i> Participantes Eventos
          <div class="pull-right align-down-table" style="top:-5px; right:-5px;">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel" style="font-size: 18px;"></i> </button>
          </div>
        </h3>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <p-dropdown panelStyleClass="custom-width" class="type-p-dropdown" placeholder="Selecione um Evento"
          appendTo="body" [filter]="true" [options]="opcoesEventosMes" [(ngModel)]="eventoIdPesquisa"
          (onChange)="buscarParticipantes()">
        </p-dropdown>
      </div>
    </div>

    <div class="header-box">
      <div class="header-box-content">
        <h3 class="title-card"> &nbsp;</h3>
        <div class="actions-card">
          <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
            matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <p-table class="table-system" #dt2 [columns]="colsOrigemParticipante" [value]="origemparticipante" [rows]="rows"
      [totalRecords]="origemparticipante.length" [paginator]="true" (onFilter)="onFilter($event)"
      [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center tableHeaderCheckbox">
            <span class="check-all p-0">
              <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
            </span>
          </th>
          <th class="text-left" style="width: 11%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataInicioEvento'">
              <span class="text-overflow-dynamic-ellipsis">Dt Ini Evento <p-sortIcon [field]="'dataInicioEvento'">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt2.filter($event.target.value, 'dataInicioEvento', 'contains')">
          </th>
          <th class="text-left" style="width: 22%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoEvento'">
              <span class="text-overflow-dynamic-ellipsis">Desc. Evento <p-sortIcon [field]="'descricaoEvento'">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt2.filter($event.target.value, 'descricaoEvento', 'contains')">
          </th>
          <th class="text-left" style="width: 22%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeparticipante'">
              <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nomeparticipante'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt2.filter($event.target.value, 'nomeparticipante', 'contains')">
          </th>

          <th class="text-left" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'participanteFone'">
              <span class="text-overflow-dynamic-ellipsis">Fone <p-sortIcon [field]="'participanteFone'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt2.filter($event.target.value, 'participanteFone', 'contains')">
          </th>


          <th class="text-left" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeSeminarista'">
              <span class="text-overflow-dynamic-ellipsis">Seminarista <p-sortIcon [field]="'nomeSeminarista'">
                </p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt2.filter($event.target.value, 'nomeSeminarista', 'contains')">
          </th>

          <th class="text-right" style="width: 8%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'somaParcelas'">
              <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'somaParcelas'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dt2.filter($event.target.value, 'somaParcelas', 'contains')">
          </th>

          <th class="text-center" style="width: 12%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoStatusFicha'">
              <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'descricaoStatusFicha'">
                </p-sortIcon></span>
            </span>
            <p-multiSelect class="type-p-mult" [options]="opcoesStatusCombo" defaultLabel="Selecione" [filter]="false"
              (onChange)="dt2.filter($event.value, 'descricaoStatusFicha', 'in')"></p-multiSelect>
          </th>

          <th class="text-center" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'deptoDescricao'">
              <span class="text-overflow-dynamic-ellipsis">DEPTO <p-sortIcon [field]="'deptoDescricao'"></p-sortIcon>
              </span>
            </span>
            <p-multiSelect class="type-p-mult" [options]="opcoesDeptoEmpresa" defaultLabel="Selecione" [filter]="false"
              (onChange)="dt2.filter($event.value, 'deptoDescricao', 'in')"></p-multiSelect>
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="text-center tableCheckbox">
            <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
          </td>
          <td class="text-left" style="width: 11%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.dataInicioEvento}}">{{rowData.dataInicioEvento }}</span>
            </span>
          </td>
          <td class="text-left" style="width: 22%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.descricaoEvento}}">{{rowData.descricaoEvento }}</span>
            </span>
          </td>
          <td class="text-left" style="width: 24%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.nomeparticipante}}">{{rowData.nomeparticipante}}</span>
            </span>
          </td>
          <td class="text-center" style="width: 10%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.participanteFone}}">{{rowData.participanteFone}}</span>
            </span>
          </td>
          <td class="text-left" style="width: 10%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.nomeSeminarista}}">{{rowData.nomeSeminarista }}</span>
            </span>
          </td>

          <td class="text-right" style="width: 8%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.somaParcelas}}">{{rowData.somaParcelas }}</span>
            </span>
          </td>


          <td class="text-left" style="width: 12%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.descricaoStatusFicha}}">{{rowData.descricaoStatusFicha }}</span>
            </span>
          </td>

          <td class="text-left" style="width: 12%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
                matTooltip="{{rowData.deptoDescricao}}">{{rowData.deptoDescricao }}</span>
            </span>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="13" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>

  <!-- Final da div pesquisa -->

  <!-- Modal Motivos Estornos -->
  <p-dialog header="Motivos do Estorno" [(visible)]="modalMotivosEstornos" [style]="{width: '1000px'}" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true" class="modal-scroll">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione o Motivo<span class="obrigatorio">*</span></label>
        <p-dropdown panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body" [filter]="true"
          [options]="opcoesMotivosEstornos" [(ngModel)]="motivoEstornoSelecionado">
        </p-dropdown>
      </div>

    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Observação</label>
        <input class="form-control text-left" [style]="{'border': '1px', 'border-style': 'solid'}" type="text"
          [(ngModel)]="observacaoEstorno" minlength="1" maxlength="500" />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Selecione a forma de pagamento<span class="obrigatorio">*</span></label>
        <p-dropdown panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body" [filter]="true"
          [options]="opcoesFormaPagamentoEstorno" [(ngModel)]="formaPagamentoEstornoSelecionado">
        </p-dropdown>
      </div>
    </div>

    <div *ngIf="formaPagamentoEstornoSelecionado === 2">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Selecione o Banco<span class="obrigatorio">*</span></label>
          <p-dropdown panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body" [filter]="true"
            [options]="opcoesInstituicoesBancarias" [(ngModel)]="instituicoesBancariaSelecionada">
          </p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Agência<span class="obrigatorio">*</span></label>
          <input class="form-control text-left" type="text" [(ngModel)]="agenciaBanco" maxlength="7" />
        </div>
        <div class="form-group col-sm-6">
          <label>Conta <span class="obrigatorio">*</span></label>
          <input class="form-control text-left" type="text" pattern="([0-9]{2,6})([-])?([0-9]|[Xx]{1})"
            [(ngModel)]="bancoConta" maxlength="20" />
        </div>
      </div>

    </div>


    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Valor<span class="obrigatorio">*</span></label>
        <input class="form-control text-right" type="text" [(ngModel)]="valorEstorno" money />
      </div>
    </div>


    <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer class="mb-0">
      <button type="button" class="btn btn-secondary" (click)='modalMotivosEstornos = false'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)='salvarMotivosEstornos();'><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>

  </p-dialog>


  <!-- Inicio Modal Transferencia participantes -->

  <p-dialog header="Transferência" [(visible)]="modalTransferencia" [style]="{width: '1000px'}" [modal]="true"
    [responsive]="true" appendTo="body" [closable]="true" class="modal-scroll">


    <mat-tab-group id="tabEventoParticipante" [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange($event)">

      <mat-tab label="Eventos">
        <div class="box-content pt-2">

          <!--Títulos Status Anterior / Atual-->
          <div class="row">
            <div class="col-sm-6">
              <h3 class="sub-title"><i class="fas fa-bars"> </i> Status Anterior</h3>
            </div>
            <div class="col-sm-6">
              <h3 class="sub-title"><i class="fas fa-bars"> </i> Status Atual</h3>
            </div>
          </div>

          <div class="row">
            <!--Início Status Anterior-->
            <div class="col-sm-6 status-evento">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Evento</label>
                  <p-dropdown [disabled]="true" panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body"
                    [filter]="true" [options]="opcoesEventosOld" [(ngModel)]="eventoSelecionadoOld">
                  </p-dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Ingresso</label>
                  <p-dropdown [disabled]="true" panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body"
                    [filter]="true" [options]="opcoesIngressosOld" [(ngModel)]="ingressoSelecionadoOld">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <!--Fim Status Anterior-->

            <!--Início Status Novo-->
            <div class="col-sm-6 status-evento">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Selecione o Evento</label>
                  <p-dropdown panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body" [filter]="true"
                    [options]="opcoesEventosNew" [(ngModel)]="eventoSelecionadoNew"
                    (onChange)="buscarIngressosNew(eventoSelecionadoNew)">
                  </p-dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Selecione o Ingresso</label>
                  <p-dropdown panelStyleClass="custom-width" class="type-p-dropdown" appendTo="body" [filter]="true"
                    [options]="opcoesIngressosNew" [(ngModel)]="ingressoSelecionadoNew">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <!--Fim Status Novo-->
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title mt-0"><i class="fas fa-bars"> </i> Taxa / Observação / Crédito</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Taxa</label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorTaxaTransferencia" money />
            </div>

            <div class="form-group col-sm-6">
              <label>Crédito</label>
              <input class="form-control text-right" type="text" [(ngModel)]="valorCreditoTransferencia" money />
            </div>

          </div>




          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Observação</label>
              <textarea rows="4" [(ngModel)]="observacaoTransferencia" class="form-control text-left"></textarea>
            </div>
          </div>

          <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px;">
            <div class="ui-g-12">

            </div>
          </div>
          <p-footer class="mb-0">
            <button type="button" class="btn btn-secondary" (click)='modalTransferencia = false'><i
                class="fas fa-undo"></i>
              Cancelar</button>
            <button type="button" class="btn btn-primary" (click)='salvarTransferirEvento();'><i
                class="fas fa-check"></i>
              Salvar</button>
          </p-footer>

        </div>

      </mat-tab>

      <mat-tab label="Participantes ">
        <div class="box-content pt-2">
          <!-- Inicio Old participante -->
          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-user"> </i> Dados do participante Old</h3>
            </div>
            <div class="form-group col-sm-4">
              <label>NOME </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input maxlength="500" [readOnly]="true" class="form-control text-left" type="text"
                placeholder="Nome completo" [(ngModel)]="oldParticipanteEventosFicha.nome" />
            </div>
            <div class="form-group col-sm-2">
              <label>CPF </label>
              <!--<p-inputMask matTooltip="Informe CPF correto para garantir a inscrição!" class="type-p-mask custom-cpf"
                    mask="999.999.999-99" [(ngModel)]="eventosFicha.cpf" placeholder="999-999-999-99"
                    (keyup.enter)="validarCpf(eventosFicha.cpf);" (keyup)="validarCpf(eventosFicha.cpf);"></p-inputMask>-->
              <input [(ngModel)]="oldParticipanteEventosFicha.cpf" [readOnly]="true" class="form-control text-left" />
            </div>
            <div class="form-group col-sm-4">
              <!-- <label>Seu melhor e-mail. <strong>Atenção.</strong> Iremos enviar os detalhes de seu ingresso neste e-mail.</label> -->
              <label>E-mail </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" maxlength="60" [readOnly]="true"
                placeholder="Iremos enviar os detalhes de seu ingresso neste e-mail"
                [(ngModel)]="oldParticipanteEventosFicha.email" />
            </div>
            <div class="form-group col-sm-2">
              <label>TELEFONE </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" [readOnly]="true" [(ngModel)]="oldParticipanteEventosFicha.telefone"
                maxlength="15" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title mb-1"><i class="fas fa-map-marker-alt"></i> Old Endereço:</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-1" style="min-width: 125px;">
              <label>CEP </label>
              <div class="input-group input-group-sm">
                <p-inputMask class="form-control custom-search" mask="99999-999" [disabled]="true"
                  [(ngModel)]="oldParticipanteEventosFicha.cep" placeholder="99.999-999"
                  (keyup.enter)="pesquisarCep();"></p-inputMask>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" [disabled]="true" (click)="pesquisarCep();"><i
                      class="fas fa-search"></i></button>
                </div>
              </div>
            </div>
            <div class="form-group col-sm-5">
              <label>RUA </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" [readOnly]="true"
                [(ngModel)]="oldParticipanteEventosFicha.logradouro" />
            </div>
            <div class="form-group col-sm-1">
              <label>NÚMERO </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control" type="text text-left" [readOnly]="true"
                [(ngModel)]="oldParticipanteEventosFicha.numero" />
            </div>
            <div class="form-group col">
              <label>BAIRRO </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" [readOnly]="true"
                [(ngModel)]="oldParticipanteEventosFicha.bairro" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-7">
              <label>COMPLEMENTO</label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" [readOnly]="true"
                [(ngModel)]="oldParticipanteEventosFicha.complemento" />
            </div>
            <div class="form-group col-sm-1">
              <label>UF </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <p-dropdown class="type-p-dropdown" [disabled]="true" [filter]="true" [options]="oldParticipanteOpcoesUF"
                [disabled]="true" [(ngModel)]="oldParticipanteEventosFicha.ufId"
                (onChange)="oldParticipanteBuscarUf(oldParticipanteEventosFicha.ufId)"></p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>MUNICÍPIO </label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <p-dropdown class="type-p-dropdown" [disabled]="true" [filter]="true" placeholder="Selecione"
                [options]="oldParticipanteopcoesMunicipio" [(ngModel)]="oldParticipanteEventosFicha.municipioId">
              </p-dropdown>
            </div>
          </div>

          <!-- Final Old participante -->

          <!-- Inicio New Participante -->

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-user"> </i> Dados do participante New</h3>
            </div>
            <div class="form-group col-sm-4">
              <label>NOME <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input maxlength="500" class="form-control text-left" type="text" placeholder="Nome completo"
                [(ngModel)]="newParticipanteEventosFicha.nome" />
            </div>
            <div class="form-group col-sm-2">
              <label>CPF <span class="obrigatorio">*</span></label>
              <!--<p-inputMask matTooltip="Informe CPF correto para garantir a inscrição!" class="type-p-mask custom-cpf"
                mask="999.999.999-99" [(ngModel)]="eventosFicha.cpf" placeholder="999-999-999-99"
                (keyup.enter)="validarCpf(eventosFicha.cpf);" (keyup)="validarCpf(eventosFicha.cpf);"></p-inputMask>-->
              <input [(ngModel)]="newParticipanteEventosFicha.cpf" class="form-control text-left" />
            </div>
            <div class="form-group col-sm-4">
              <!-- <label>Seu melhor e-mail. <strong>Atenção.</strong> Iremos enviar os detalhes de seu ingresso neste e-mail.</label> -->
              <label>E-mail <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" maxlength="60"
                placeholder="Iremos enviar os detalhes de seu ingresso neste e-mail"
                [(ngModel)]="newParticipanteEventosFicha.email" />
            </div>
            <div class="form-group col-sm-2">
              <label>TELEFONE <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" [(ngModel)]="newParticipanteEventosFicha.telefone" maxlength="15" />
            </div>
          </div>

          <div class="form-row">
            <div class="col-sm-12">
              <h3 class="sub-title mb-1"><i class="fas fa-map-marker-alt"></i> New Endereço:</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-1" style="min-width: 125px;">
              <label>CEP <span class="obrigatorio">*</span></label>
              <div class="input-group input-group-sm">
                <p-inputMask class="form-control custom-search" mask="99999-999"
                  [(ngModel)]="newParticipanteEventosFicha.cep" placeholder="99999-999"
                  (keyup.enter)="newParticipantepesquisarCep();"></p-inputMask>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary" (click)="newParticipantepesquisarCep();"><i
                      class="fas fa-search"></i></button>
                </div>
              </div>
            </div>
            <div class="form-group col-sm-5">
              <label>RUA <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" [(ngModel)]="newParticipanteEventosFicha.logradouro" />
            </div>
            <div class="form-group col-sm-1">
              <label>NÚMERO <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control" type="text text-left" [(ngModel)]="newParticipanteEventosFicha.numero"
                maxlength="50" />
            </div>
            <div class="form-group col">
              <label>BAIRRO <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" [(ngModel)]="newParticipanteEventosFicha.bairro"
                maxlength="300" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-7">
              <label>COMPLEMENTO</label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <input class="form-control text-left" type="text" [(ngModel)]="newParticipanteEventosFicha.complemento"
                maxlength="300" />
            </div>
            <div class="form-group col-sm-1">
              <label>UF <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="newParticipanteOpcoesUF"
                [(ngModel)]="newParticipanteEventosFicha.ufId"
                (onChange)="newParticipanteBuscarUf(newParticipanteEventosFicha.ufId)"></p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>MUNICÍPIO <span class="obrigatorio">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                [options]="newParticipanteopcoesMunicipio" [(ngModel)]="newParticipanteEventosFicha.municipioId">
              </p-dropdown>
            </div>
          </div>

          <!-- Final New Participante -->

          <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px;">
            <div class="ui-g-12">

            </div>
          </div>
          <p-footer class="mb-0">
            <button type="button" class="btn btn-secondary" (click)='modalTransferencia = false'><i
                class="fas fa-undo"></i>
              Cancelar</button>
            <button type="button" class="btn btn-primary" (click)='salvarTransferirParticipante();'><i
                class="fas fa-check"></i>
              Salvar</button>
          </p-footer>


        </div>



      </mat-tab>

    </mat-tab-group>



  </p-dialog>

  <!-- Final  Modal Transferencia participantes -->


  <!-- Inicio Modal Informacoes Transferencia -->
  <p-dialog header="Informações de transferências" [(visible)]="modalInformacaoTransferencia"
    [style]="{width: '1200px'}" [modal]="true" [responsive]="true" appendTo="body" [closable]="true"
    styleClass="modal-scroll participante-eventos-altura">

    <mat-tab-group id="tabEventoInformacoes" [(selectedIndex)]="selectedIndexInformacoes">

      <mat-tab label="Eventos">
        <div class="box-content pt-1">

          <!-- Inicio table Informacao Transferencia -->


          <p-table class="table-system" #dt11 [value]="listaTransferenciaInf" [paginator]="false"
            [totalRecords]="listaTransferenciaInf.length" [pageLinks]="pageLinks" selectionMode="single"
            [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Dt Solicitação </span>
                  </span>

                </th>
                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Evento Anterior </span>
                  </span>
                </th>
                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Ingresso Anterior </span>
                  </span>
                </th>

                <th class="text-left" style="width: 13%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Evento Novo </span>
                  </span>
                </th>

                <th class="text-left" style="width: 12%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Ingresso Novo </span>
                  </span>
                </th>

                <th class="text-left" style="width: 13%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Observação </span>
                  </span>
                </th>

                <th class="text-right" style="width: 9%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Taxa </span>
                  </span>
                </th>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Crédito </span>
                  </span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>

                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.DataSolicitacao}}">{{rowData.DataSolicitacao }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.oldDataInicioEvento}} - {{rowData.oldDescricaoEvento}} - {{rowData.oldCidade}} /  {{rowData.oldUf}}  ">
                      {{rowData.oldDataInicioEvento}} - {{rowData.oldDescricaoEvento}} - {{rowData.oldCidade}} /
                      {{rowData.oldUf}} </span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="Valor Pacote: {{rowData.oldValorPacote}} {{rowData.oldEventoIngressoCodigo}}  ">Valor
                      Pacote: {{rowData.oldValorPacote}} {{rowData.oldEventoIngressoCodigo}} </span>
                  </span>
                </td>

                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.newDataInicioEvento}} - {{rowData.newDescricaoEvento}} - {{rowData.newCidade}} / {{rowData.newUf}}  ">{{rowData.newDataInicioEvento}}
                      - {{rowData.newDescricaoEvento}} - {{rowData.newCidade}} / {{rowData.newUf}} </span>
                  </span>
                </td>

                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="Valor Pacote:  {{rowData.newValorPacote}} {{rowData.newEventoIngressoCodigo}}">Valor
                      Pacote: {{rowData.newValorPacote}} {{rowData.newEventoIngressoCodigo}}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.observacao}} ">
                      {{rowData.observacao}}</span>
                  </span>
                </td>

                <td class="text-right" style="width: 14%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.valorTaxaTransferencia}}">{{rowData.valorTaxaTransferencia }}</span>
                  </span>
                </td>


                <td class="text-right" style="width: 14%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.creditoTransferencia}}">{{rowData.creditoTransferencia }}</span>
                  </span>
                </td>


              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="8" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>




          <!-- Final table Informacao Transferencia -->

        </div>
      </mat-tab>
      <mat-tab label="Participantes">
        <div class="box-content pt-1">
          <!-- Inicio tabela de informacoes de troca de participantes -->


          <p-table class="table-system" #dt12 [value]="listaTransferenciaInfParticipante" [paginator]="false"
            [totalRecords]="listaTransferenciaInfParticipante.length" [pageLinks]="pageLinks" selectionMode="single"
            [style]="{'width':'100%'}">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left" style="width: 12%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">Dt Solicitação </span>
                  </span>

                </th>
                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">old participante Nome </span>
                  </span>
                </th>
                <th class="text-left" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">old CPF </span>
                  </span>
                </th>

                <th class="text-left" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">old Fone </span>
                  </span>
                </th>

                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">old Email </span>
                  </span>
                </th>

                <th class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">new Nome </span>
                  </span>
                </th>

                <th class="text-left" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">new CPF </span>
                  </span>
                </th>

                <th class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">new Fone </span>
                  </span>
                </th>

                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis">new Email</span>
                  </span>
                </th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>

                <td class="text-left" style="width: 12%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dataSolicitacao}}">{{rowData.dataSolicitacao }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.oldParticipanteNome}}  ">
                      {{rowData.oldParticipanteNome}} </span>
                  </span>
                </td>
                <td class="text-left" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.oldParticipanteCpf}}  ">
                      {{rowData.oldParticipanteCpf}} </span>
                  </span>
                </td>

                <td class="text-left" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.oldParticipanteFone}}  ">{{rowData.oldParticipanteFone}} </span>
                  </span>
                </td>

                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.oldParticipanteEmail}} ">
                      {{rowData.oldParticipanteEmail}} </span>
                  </span>
                </td>

                <td class="text-left" style="width: 20%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.newParticipanteNome}} ">
                      {{rowData.newParticipanteNome}}</span>
                  </span>
                </td>

                <td class="text-left" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.newParticipanteCpf}}">{{rowData.newParticipanteCpf }}</span>
                  </span>
                </td>

                <td class="text-right" style="width: 11%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.newParticipanteFone}}">{{rowData.newParticipanteFone }}</span>
                  </span>
                </td>

                <td class="text-right" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.newParticipanteEmail}}">{{rowData.newParticipanteEmail }}</span>
                  </span>
                </td>

              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="9" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>


          <!-- Final da tabela de informacoes de troca de participantes -->


        </div>
      </mat-tab>




    </mat-tab-group>

    <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer class="mb-0">
      <button type="button" class="btn btn-secondary" (click)='modalInformacaoTransferencia = false'><i
          class="fas fa-undo"></i>
        Fechar</button>
      <!-- <button type="button" class="btn btn-primary" (click)='salvarTransferirEvento();'><i class="fas fa-check"></i>
        Salvar</button> -->
    </p-footer>


  </p-dialog>
  <!-- Final Modal Informacoes Transferencia -->



  <!-- Inicio div cadastro evento ficha -->
  <div *ngIf="tipo.toString() === '0'">
    <mat-card-content>
      <div class="box-content">
        <div class="row">
          <div class="col">
            <h1 class="title-page">INSCRIÇÃO DE SEMINÁRIO</h1>
            <!-- <h3 class="sub-title-page">POLOZI - Seminário de Inteligência Emocional</h3> -->
          </div>
        </div>
        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-ticket-alt"> </i> Evento / Ingresso</h3>
          </div>
          <div class="form-group col">
            <label>EVENTOS <span class="obrigatorio">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown id="dropOpcoesEvento" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="opcoesEventos" [(ngModel)]="eventosFicha.eventoId"
              (onChange)="buscarIngressos(eventosFicha.eventoId)">
            </p-dropdown>
          </div>
          <div class="form-group col">
            <label>INGRESSOS <span class="obrigatorio">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesIngressos"
              [(ngModel)]="eventosFicha.ingressosId" (onChange)="selectIngresso(eventosFicha.ingressosId)">
            </p-dropdown>
          </div>
          <div class="form-group col">
            <label>CÓD. PALESTRA</label>
            <!--VERIFICAR SE É OBRIGATÓRIO-->
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesPalestra"
              [(ngModel)]="eventosFicha.palestraId" (onChange)="verificaPalestraAbertaFechada()">
            </p-dropdown>
          </div>




        </div>
        <div class="form-row">
          <!-- Inicio da atendente -->
          <div class="form-group col" *ngIf="!boolPalestraAberta">
            <label>ATENDENTE<span class="obrigatorio">*</span></label>
            <!--VERIFICAR SE É OBRIGATÓRIO-->
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" type="text" [(ngModel)]="eventosFicha.atendente" minlength="3"
              maxlength="150" />
          </div>

          <div class="form-group col" *ngIf="boolPalestraAberta">
            <label>Vendedor<span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesVendedor"
              [(ngModel)]="eventosFicha.vendedorId">
            </p-dropdown>
          </div>
          <!-- final da atendente -->

          <div class="form-group col-sm-2">
            <label>Data da Venda </label>
            <p-calendar type-p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2040"
              [defaultDate]="defaultDate" [(ngModel)]="eventosFicha.dataVenda" dateFormat="dd/mm/yy">
            </p-calendar>
          </div>


          <div class="form-group col">
            <label>STATUS <span class="obrigatorio">*</span> </label>
            <!--VERIFICAR SE É OBRIGATÓRIO-->
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesStatus"
              [(ngModel)]="eventosFicha.statusEventoId">
            </p-dropdown>
          </div>

          <div class="form-group col">
            <label>DEPTO <span class="obrigatorio">*</span> </label>
            <!--VERIFICAR SE É OBRIGATÓRIO-->
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="opcoesComboDeptoEmpresa" [(ngModel)]="eventosFicha.deptoEmpresaId">
            </p-dropdown>
          </div>

        </div>
        <!-- End Form Row--->

        <!-- Inicio form row -->

        <!-- Final form row -->
        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-user"> </i> Dados do participante</h3>
          </div>
          <div class="form-group col-sm-3">
            <label>NOME <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input maxlength="500" class="form-control text-left" type="text" placeholder="Nome completo"
              [(ngModel)]="eventosFicha.nome" />
          </div>
          <div class="form-group col-sm-2">
            <label>CPF <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!--<p-inputMask matTooltip="Informe CPF correto para garantir a inscrição!" class="type-p-mask custom-cpf"
              mask="999.999.999-99" [(ngModel)]="eventosFicha.cpf" placeholder="999-999-999-99"
              (keyup.enter)="validarCpf(eventosFicha.cpf);" (keyup)="validarCpf(eventosFicha.cpf);"></p-inputMask>-->
            <input [(ngModel)]="eventosFicha.cpf" (keyup.enter)="validarCpf(eventosFicha.cpf);"
              class="form-control text-left" (keyup)="validarCpf(eventosFicha.cpf);" maxlength="14" />
          </div>

          <div class="form-group col-sm-2">
            <label>CNPJ <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!--<p-inputMask matTooltip="Informe CPF correto para garantir a inscrição!" class="type-p-mask custom-cpf"
              mask="999.999.999-99" [(ngModel)]="eventosFicha.cpf" placeholder="999-999-999-99"
              (keyup.enter)="validarCpf(eventosFicha.cpf);" (keyup)="validarCpf(eventosFicha.cpf);"></p-inputMask>-->
            <input [(ngModel)]="eventosFicha.cnpj" (keyup.enter)="validarCnpj(eventosFicha.cnpj);"
              class="form-control text-left" (keyup)="validarCnpj(eventosFicha.cnpj);" maxlength="18" />
          </div>

          <div class="form-group col-sm-3">
            <!-- <label>Seu melhor e-mail. <strong>Atenção.</strong> Iremos enviar os detalhes de seu ingresso neste e-mail.</label> -->
            <label>E-mail <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" type="text" maxlength="60"
              placeholder="Iremos enviar os detalhes de seu ingresso neste e-mail" [(ngModel)]="eventosFicha.email"
              maxlength="60" />
          </div>
          <div class="form-group col-sm-2">
            <label>TELEFONE <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" [(ngModel)]="eventosFicha.telefone" maxlength="15" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>DADOS ADICIONAIS</label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))"F -->
            <input class="form-control text-left" placeholder="Inserir Informações!"
              [(ngModel)]="eventosFicha.eventoDadosAdicionais">
          </div>
        </div>
        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title mb-1"><i class="fas fa-map-marker-alt"></i> Endereço:</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-2">
            <label>CEP <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <div class="d-flex">

              <p-inputMask mask="99999-999" [(ngModel)]="eventosFicha.cep" placeholder="99.999-999"
                (keyup.enter)="pesquisarCep();"></p-inputMask>

              <div class="input-group-append">
                <button type="button" class="btn btn-primary" (click)="pesquisarCep();">
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group col-sm-5">
            <label>RUA <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" type="text" [(ngModel)]="eventosFicha.logradouro" maxlength="300" />
          </div>
          <div class="form-group col-sm-1">
            <label>NÚMERO <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control" type="text text-left" [(ngModel)]="eventosFicha.numero" maxlength="50" />
          </div>
          <div class="form-group col-sm-4">
            <label>BAIRRO <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" type="text" [(ngModel)]="eventosFicha.bairro" maxlength="300" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-7">
            <label>COMPLEMENTO</label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" type="text" [(ngModel)]="eventosFicha.complemento" maxlength="300" />
          </div>
          <div class="form-group col-sm-1">
            <label>UF <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesUF" [(ngModel)]="eventosFicha.ufId"
              (onChange)="buscarUf(eventosFicha.ufId)"></p-dropdown>
          </div>
          <div class="form-group col-sm-4">
            <label>MUNICÍPIO <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesMunicipio"
              [(ngModel)]="eventosFicha.municipioId"></p-dropdown>
          </div>
        </div>


        <!-- <div class="form-row">
          <div class="form-group col-sm-1">
            <label>Valor Total</label>
            <input class="form-control text-right" type="text" [(ngModel)]="eventosFicha.valorTotal" (blur)="preenchervalor()" money/>
          </div>

        </div> -->


        <!-- Inicio Formas de pagamento -->
        <div class="form-row align-items-end" *ngIf="!isBonus">
          <div class="col-sm-12 ">


            <h3 class="sub-title"><i class="fas fa-money-check-alt"> </i> FORMAS DE PAGAMENTO</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-1">
            <label>Valor Total</label>
            <input class="form-control text-right" type="text" [(ngModel)]="eventosFicha.valorTotal" maxlength="13"
              (keydown)="preenchervalor()" money />
          </div>

        </div>
        <div class="form-row">


          <div class="form-group col-sm-1">
            <label>VALOR <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-right" type="text" maxlength="13" (blur)="calcularValorParcela()"
              [(ngModel)]="fichaPagamento.valor" money />
          </div>
          <div class="form-group col-sm-3">
            <label>TIPO <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="opcoesFormaPagto"
              [(ngModel)]="fichaPagamento.formaPagamento.id"
              (onChange)="validaTipoPagamento(fichaPagamento.formaPagamento)">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-3" *ngIf="isDeposito">
            <label>CONTA BANCÁRIA<span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-dropdown *ngIf="isDeposito" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [options]="opcoesDepositoBancario" [(ngModel)]="fichaPagamento.empresaContaId">
            </p-dropdown>
          </div>

          <div class="form-group col-sm-2" *ngIf="isPagSeguro || isCreditoAVista">
            <div *ngIf="isPagSeguro"> <label>Data Primeiro Vencimento <span class="obrigatorio"
                  *ngIf="eventosFicha.statusEventoId === 1">*</span></label> </div>
            <div *ngIf="isCreditoAVista"> <label>Data Pagamento <span class="obrigatorio"
                  *ngIf="eventosFicha.statusEventoId === 1">*</span></label> </div>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <p-calendar type-p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2018:2040"
              [defaultDate]="defaultDate" [(ngModel)]="fichaPagamento.dtPrimeiroVencimento" dateFormat="dd/mm/yy">
            </p-calendar>
          </div>

          <div class="form-group col-sm-2" *ngIf="isCreditoAVista">
            <div *ngIf="isCreditoAVista">
              <label *ngIf="isCreditoAVista">Parcelado em <span class="obrigatorio"
                  *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
              <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))"  -->
              <input class="form-control text-left" *ngIf="isCreditoAVista" type="number" [min]="min"
                [(ngModel)]="fichaPagamento.parcela" [max]="max" (blur)="calcularNumeroParcela()" />
            </div>
          </div>

          <div class="form-group col-sm-3" *ngIf="isCreditoAVista || isCreditoParcelado">
            <label>Nº do cartão <strong>(4 últimos digitos)</strong> <span class="obrigatorio"
                *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-left" type="text" [(ngModel)]="fichaPagamento.numeroCartao" maxlength="5" />
          </div>

          <div class="form-group col-sm-2"
            *ngIf="(isCreditoAVista || isCreditoParcelado|| isCartaoDebito) && fichaPagamento.formaPagamento.id > 0">
            <label>CÓD. COMPRA <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control" matTooltip="Insira o código da compra" type="text"
              [(ngModel)]="fichaPagamento.ccv" maxlength="255" />
          </div>


          <div class="form-group col-sm-2"
            *ngIf="(isCreditoAVista || isCreditoParcelado|| isCartaoDebito) && fichaPagamento.formaPagamento.id > 0">
            <label>AUTORIZACAO <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control" matTooltip="Insira o código da autorização" type="text"
              [(ngModel)]="fichaPagamento.autorizacao" maxlength="6" />
          </div>

          <div class="form-group col-sm-1"
            *ngIf="(isCreditoAVista || isCreditoParcelado|| isCartaoDebito) && fichaPagamento.formaPagamento.id > 0">
            <label>Nº DOC <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control" matTooltip="Insira o código do nº documento" type="text"
              [(ngModel)]="fichaPagamento.numeroDocumento" maxlength="6" />
          </div>

          <div class="form-group col-sm-2"
            *ngIf="(isCreditoAVista || isCreditoParcelado|| isCartaoDebito) && fichaPagamento.formaPagamento.id > 0">
            <label>Nº TERMINAL <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control" matTooltip="Insira o código do nº terminal" type="text"
              [(ngModel)]="fichaPagamento.numeroTerminal" maxlength="8" />
          </div>

          <div class="form-group col-sm-2"
            *ngIf="(isCreditoAVista || isCreditoParcelado|| isCartaoDebito) && fichaPagamento.formaPagamento.id > 0">
            <label>Nº ESTABELECIMENTO <span class="obrigatorio"
                *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control" matTooltip="Insira o código do nº estabelecimento" type="text"
              [(ngModel)]="fichaPagamento.numeroEstabelecimento" maxlength="15" />
          </div>

          <div class="form-group col-sm-2" *ngIf="isPagSeguro">
            <label>QTDE PARCELAS <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-right" type="number" [min]="min" [max]="max" input-text="right"
              [(ngModel)]="fichaPagamento.parcela" (blur)="calcularNumeroParcela()" />
          </div>

          <div class="form-group col-sm-2" *ngIf="isCreditoParcelado ">
            <label>QTDE PARCELAS <span class="obrigatorio" *ngIf="eventosFicha.statusEventoId === 1">*</span></label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-right" type="number" [min]="min" [max]="max" input-text="right"
              [(ngModel)]="fichaPagamento.parcela" (blur)="calcularNumeroParcela()" />
          </div>


          <div class="form-group col-sm-2" *ngIf="isPagSeguro || isCreditoAVista">
            <label>VALOR PARCELA</label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-right" type="text" [disabled]="true" input-text="right"
              [(ngModel)]="fichaPagamento.valorParcela" money />
          </div>

          <!-- checa se pode subustituir o boleto anterior -->
          <div class="col-sm-2" *ngIf="isPagSeguro">
            <label class="d-block" style="visibility:hidden; margin-top: 5px;"> do not remove <span class="obrigatorio"
                *ngIf="eventosFicha.statusEventoId === 1">*</span> </label>

            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <div class="p-field-checkbox">
              <p-checkbox class="mr-1" [(ngModel)]="eventosFicha.substituirBoletoAnterior" [binary]="true"
                inputId="substBoleto"></p-checkbox>
              <label for="substBoleto">SUBST. BOLETO ANTERIOR</label>
            </div>
          </div>

          <div class="form-group col-sm-2" *ngIf="isCheque">
            <label>NÚMERO CHEQUE</label>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <input class="form-control text-right" type="text" input-text="left"
              [(ngModel)]="fichaPagamento.numCheque" />
          </div>
          <div class="form-group col-sm-2" style="margin-top: 25px;">
            <button type="button" *ngIf="!editFormaPagto" (click)="addPagto()" class="btn btn-sm btn-info"><i
                class="fas fa-plus"></i> Adicionar</button>
            <button type="button" *ngIf="editFormaPagto" (click)="atualizaPagto()" class="btn btn-sm btn-primary"
              [disabled]="(isPodeEditar === null? false:(isPodeEditar))"><i class="fas fa-pencil-alt"></i>
              Editar</button>
          </div>

        </div>
        <!-- Final formas de pagamento -->
        <div class="form-row" *ngIf="!isBonus">
          <div class="form-group col-sm-12 mt-2">

            <p-table class="table-system" [value]="eventosFicha.fichasPagamento" selectionMode="single"
              [paginator]="true" [rows]="5" [totalRecords]="totalFichaPagamento">

              <ng-template pTemplate="header">
                <tr>
                  <th style="width:10%" class="text-right">VALOR </th>
                  <th style="width:83%">FORMA DE PAGTO</th>
                  <th style="width:7%" class="text-center">AÇÃO</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height:25px">
                  <td>
                    <span class="overflow-ellipse text-right" matTooltip="{{ rowData.valor | money }}">
                      <span style="user-select: all; cursor: text"> {{ rowData.valor | money }}</span> </span>
                  </td>
                  <td>
                    <span class="overflow-ellipse" matTooltip="{{ rowData.formaPagamento.descricao }}"> <span
                        style="user-select: all; cursor: text"> {{ rowData.formaPagamento.descricao }}</span> </span>
                  </td>

                  <td class="text-center overflow-ellipse">
                    <span class="edit" matTooltip="Editar Item" (click)="selectTipoPagto(rowData)"><i
                        class="far fa-edit"></i></span>
                    <span class="delete" matTooltip="Excluir Item" (click)="removerPagto(rowData)"><i
                        class="far fa-trash-alt"></i></span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer" let-rowData>
                <tr style="height:25px">
                  <td class="text-right" style="font-size:12px;">
                    <strong> {{somaPagamentos | money}} </strong>
                  </td>
                  <td colspan="2" style="font-size:12px;">
                    <strong>Valor total </strong>
                  </td>
                </tr>
              </ng-template>>

              <ng-template pTemplate="emptymessage" let-columns>

                <tr style="height:25px">
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Sem Pagamento Adicionado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Soma dos pagamentos: {{somaPagamentos}}</label>

              </div>
            </div> -->
          </div>
        </div>

        <!-- Inicio Observacao -->
        <div class="form-row">
          <div class="form-group col-sm-12 mt-2">

            <label>OBSERVAÇÃO</label>
            <!--VERIFICAR SE É OBRIGATÓRIO-->
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <textarea [(ngModel)]="eventosFicha.observacao" class="form-control text-left"></textarea>
          </div>
        </div>
        <!-- Final Observacao -->

        <div class="row">
          <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-secondary" (click)="limpaTela()"><i class="fas fa-eraser"></i>
              LIMPAR</button>
            <!-- [disabled]="(isPodeEditar === null? false:(isPodeEditar === true? false: true ))" -->
            <button type="button" id="btnSalvar" class="btn btn-primary" (click)="salvaParticipante()"><i
                class="fas fa-check"></i> SALVAR</button>
          </div>
        </div>
      </div>

    </mat-card-content>
  </div>
  <!-- Final div cadastro evento ficha  -->


  <!-- Modal Pesquisar Nome -->
  <p-dialog header="Pesquisar Nome" [(visible)]="modalPesquisarNome" [style]="{width: '400px'}" [modal]="true"
    class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
    <div *ngIf="modalPesquisarNome">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Digite um nome (mínimo de 3 letras)</label>
          <input class="form-control text-left" [style]="{'border': '1px', 'border-style': 'solid'}" type="text"
            [(ngModel)]="nomeSearch" maxlength="500" />
        </div>

      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalPesquisarNome=false; filtrar(" ")'><i
          class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="filtrar(nomeSearch);"><i class="fas fa-check"></i>
        Pesquisar</button>
    </p-footer>
  </p-dialog>
  <!-- Modal Pesquisar nome -->

</mat-card>