<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>

  <!-- Alert -->
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- LISTA -->
  <mat-card-content>

    <div class="box-content pt-2" *ngIf="!edit && !new">
      <div class="row">
        <div class="col-sm-12">
          <div class="header-box">
            <div class="header-box-content p-3">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <!-- Table New P-TABLE -->
          <div class="mt-4">
            <p-table class="table-system" #dt1 [columns]="colsLista" [value]="lista" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              selectionMode="single" [style]="{'width':'100%'}" [totalRecords]="totalRecords">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">CÓDIGO <p-sortIcon
                          [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">DESCRIÇÃO <p-sortIcon
                          [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 29%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoBom'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">DADOS ADICIONAIS <p-sortIcon
                          [field]="'descricaoBom'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricaoBom', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtdeItens'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">QTDE ITENS <p-sortIcon
                          [field]="'qtdeItens'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'qtdeItens', 'contains')">
                  </th>
                  <th class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtIni'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">DATA INI <p-sortIcon
                          [field]="'dtIni'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dtIni', 'contains')">
                  </th>
                  <th class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtFin'">
                      <span class="text-overflow-dynamic-ellipsis" style="height: 12px">DATA FIN <p-sortIcon
                          [field]="'dtFin'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dtFin', 'contains')">
                  </th>
                  <th class="text-center" style="width: 11%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                      </span>
                    </span>
                    <div>
                      <div style="margin-top: -2px;">
                        <p-multiSelect #cmp class="type-p-mult" defaultLabel="Selecione" [options]="opcoesAtivo"
                          (onChange)="dt1.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                      </div>
                    </div>
                    <!-- <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'ativo', 'contains')"> -->
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.codigo }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao | uppercase}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.descricao }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 29%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricaoBom | uppercase}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.descricaoBom }}
                      </span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.qtdeItens}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.qtdeItens }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtIni}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.dtIni }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtFin}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.dtFin }}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ativo}}"
                        (click)="loadArvore('edit', rowData)">
                        {{ rowData.ativo }}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content" *ngIf="edit || new ">
      <div class="header-box">
        <div class="header-box-content space">
          <div class="actions-card">
            <app-toolbar [value]="toolbarMain"></app-toolbar>
          </div>
        </div>
      </div>
      <div class="row mt-3">

        <div class="form-group col-sm-12" *ngIf="new && !edit">
          <label>ITEM</label>
          <p-dropdown panelStyleClass="minWidthOverride" class="type-p-dropdown" [filter]="true" [options]="opcoesItens"
            [(ngModel)]="itemPai" placeholder="Selecione um item" optionLabel="label"></p-dropdown>
        </div>

        <div class="form-group col-sm-12" *ngIf="!new && edit">
          <label>ITEM</label>
          <p class="item">{{itemEditado}}</p>
        </div>
      </div>

      <!--<table class="table table-borderless" *ngIf="edit && !new">
        <thead>
          <tr>
            <th style="width: 10px;">
              <strong>ITEM: </strong>
            </th>
            <th>
              {{itemEditado}}
            </th>
          </tr>
        </thead>
      </table>-->
      <div class="row">

        <div class="form-group col-sm-2">
          <label>DATA INICIO</label>
          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dataInicio" [monthNavigator]="true"
            [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
        </div>

        <div class="form-group col-sm-2">
          <label>DATA FINAL</label>
          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dataFinal" [monthNavigator]="true"
            [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
        </div>

        <div class="form-group col-sm-8 d-flex align-items-end">
          <div class="p-field-checkbox">
            <p-checkbox class="mr-1" [(ngModel)]="ativo" [binary]="true" inputId="ativo"></p-checkbox>
            <label for="ativo" class="ml-1">Ativo</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DADOS ADICIONAIS </label>
          <textarea class="form-control" rows="5" pInputTextarea value="descricao" [(ngModel)]="descricao"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <p-table class="table-system" [value]="listaItensArvore" [style]="{width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:60%" class="text-left">Item</th>
                <th style="width:20%" class="text-left">Almoxarifado</th>
                <th style="width:5%">UN</th>
                <th style="width:10%" class="text-center">Quantidade</th>
                <th style="width:5%" class="text-center"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td pEditableColumn style="width:60%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesItens"
                            [(ngModel)]="rowData.item" placeholder="Selecione um item" optionLabel="label"
                            (onChange)="carregarUnItem(rowData); verificarQtdeItens();"></p-dropdown>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip=" {{rowData.item.codigo}} - {{rowData.item.descricao}}" class="overflow-ellipse"
                        style="cursor: pointer;" *ngIf="rowData.item">
                        {{rowData.item.codigo}} - {{rowData.item.descricao}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width:20%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesAlmoxarifados"
                            [(ngModel)]="rowData.almoxarifado" placeholder="Selecione um almoxarifado"
                            optionLabel="label" (onChange)="verificarQtdeItens();"></p-dropdown>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip=" {{rowData.almoxarifado.codigo}} - {{rowData.almoxarifado.descricao}}"
                        class="overflow-ellipse" style="cursor: pointer;" *ngIf="rowData.almoxarifado">
                        {{rowData.almoxarifado.codigo}} - {{rowData.almoxarifado.descricao}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>



                <td pEditableColumn style="width:20%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesUnidadeMedida"
                            [(ngModel)]="rowData.unidadeMedida" placeholder="Selecione um item" optionLabel="label"
                            (onChange)="verificarQtdeItens();" (click)="carregarUnItem(rowData)"></p-dropdown>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip=" {{rowData.unidadeMedida.codigo}}" class="overflow-ellipse"
                        style="cursor: pointer;" *ngIf="rowData.unidadeMedida">
                        {{rowData.unidadeMedida.codigo}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <!--<td style="width:5%; text-align:left;">
                  <span matTooltip="{{rowData.un.descricao}}" class="overflow-ellipse text-left"
                    style="cursor: pointer;">{{rowData.un.descricao}}</span>
                </td>-->
                <td pEditableColumn style="width:10%; text-align:right;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <input class="form-control text-right" [(ngModel)]="rowData.quantidade" type="text" />
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip="{{rowData.quantidade}}" class="overflow-ellipse text-right"
                        style="cursor: pointer;">{{rowData.quantidade}}</span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td style="width:5%; text-align:center;">
                  <button type="button" style="padding: 0 !important;" (click)="excluirItemLista(rowData)"
                    class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                    <span class="material-icons" style="font-size: 16px;">delete</span>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class="row">
            <div class="col-sm-12 mt-3">
               <button class="btn btn-sm btn-info" (click)="showModalImportarItens();"><i class="fas fa-file-upload"></i>
                Upload</button>
              <button class="btn btn-sm btn-info" *ngIf="edit" (click)="clonarItem();"><i class="fas fa-copy"></i>
                Duplicar</button>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12">
              <!-- <button class="btn btn-secondary" (click)="inicializar()"><i class="fas fa-undo"></i> Cancelar</button>
              <button *ngIf="edit" type="button" class="btn btn-danger" (click)="excluirItem()"><i
                  class="fas fa-times"></i>
                Excluir</button>
              <button class="btn btn-primary" (click)="salvarItem();"><i class="fas fa-check"></i> Salvar</button> -->
            </div>
          </div>
        </div>
      </div>

      <p-dialog header="Clonar Arvore Produto" [(visible)]="modalClonar" [style]="{width: '400px'}" [responsive]="true"
        styleClass="modal-initial" appendTo="body" [closable]="true">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>ITEM</label>
            <p-dropdown panelStyleClass="minWidthOverrideClonar" [options]="opcoesItens" [(ngModel)]="itemPai"></p-dropdown>
          </div>
        </div>
        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalClonar=false"><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)='salvarClone()'><i class="fas fa-check"></i>
            Salvar</button>
        </p-footer>
      </p-dialog>
    </div>
  </mat-card-content>
</mat-card>

<!-- Modal importar itens -->
<p-dialog header="Importar Itens Árvore" [(visible)]="exibirModalImportarItens" styleClass="modal-scroll" [style]="{ width: '400px' }" [modal]="true"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-9 mb-1 mt-1">
      <label class="d-block">Selecione um arquivo csv</label>
      <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
        <span class="input-group-append">
          <span class="input-group-text btn-file">
            <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span>
            <input type="file" #file (change)="fileChange()" />
          </span>
        </span>
        <div class="form-control" data-trigger="fileinput">
          <span class="fileinput-filename" id="file-name"></span>
        </div>
      </div>
    </div>
    <div class="form-group col-sm-3  d-block mt-4">
      <button type="button" class="btn btn-primary button" (click)="importar()"><i class="fas fa-upload title"></i>
        Importar</button>
    </div>
  </div>
  <p-footer>
    <div class="row mt-3">
      <div class="col-sm-12">
        <!-- <button type="button" class="btn btn-secondary" (click)="exibirModalImportarItens = false"><i
            class="fas fa-undo"></i>
          Fechar</button> -->
      </div>
    </div>
  </p-footer>
</p-dialog>
