<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="box-content">
      <p-table class="table-system" #dt [value]="balancete" [rows]="rows" [paginator]="true" selectionMode="single"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 80px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-left" style="width: 200px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'descrCta', 'contains')">
            </th>
            <th class="text-center" style="width: 50px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">IND CTA <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'indCta', 'contains')">
            </th>
            <th class="text-center" style="width: 50px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-center" style="width: 50px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'natConta'">
                <span class="text-overflow-dynamic-ellipsis">NATUREZA <p-sortIcon [field]="'natConta'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'natConta', 'contains')">
            </th>
            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'sldIni'">
                <span class="text-overflow-dynamic-ellipsis">SALDO INI <p-sortIcon [field]="'sldIni'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'sldIni', 'contains')">
            </th>
            <th class="text-center" style="width: 40px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcIni'">
                <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcIni'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'indDcIni', 'contains')">
            </th>
            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'debito'">
                <span class="text-overflow-dynamic-ellipsis">DÉBITO <p-sortIcon [field]="'debito'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'debito', 'contains')">
            </th>
            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'credito'">
                <span class="text-overflow-dynamic-ellipsis">CRÉDITO <p-sortIcon [field]="'credito'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'credito', 'contains')">
            </th>
            <th class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'sldFin'">
                <span class="text-overflow-dynamic-ellipsis">SALDO FIN <p-sortIcon [field]="'sldFin'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'sldFin', 'contains')">
            </th>
            <th class="text-center" style="width: 40px;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'sldFin'">
                <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'sldFin'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt.filter($event.target.value, 'sldFin', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData" [ngClass]="rowData.indCta === 'S'  ? 'bold ' : ''">
            <td class="text-left" style="width: 80px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                  matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 200px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrCta}}">{{rowData.descrCta
                  }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 50px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indCta}}">{{rowData.indCta }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 50px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nivel}}">{{rowData.nivel }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 50px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.natConta}}">{{rowData.natConta
                  }}</span>
              </span>
            </td>
            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.sldIni  | money}}">{{rowData.sldIni |
                  money}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 40px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.indDcIni}}">{{rowData.indDcIni}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.debito | money}}">{{rowData.debito |
                  money}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.credito | money}}">{{rowData.credito
                  | money}}</span>
              </span>
            </td>
            <td class="text-right" style="width: 90px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.sldFin | money}}">{{rowData.sldFin |
                  money}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 40px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.indDcFin}}">{{rowData.indDcFin}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="11" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>
</mat-card>
<div *ngIf="isLoadingInProgress" class="loading-layer">
  <h6>Processamento em andamento, favor aguardar... {{loadingProgress}}%</h6>
  <p-progressBar [value]="loadingProgress" style="width: 40% !important"></p-progressBar>
</div>