<mat-card class="card-linnks margin-linnks">

  <mat-card-title *ngIf="!modalXmlCte">
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="row" *ngIf="!modalXmlCte">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="empresa?.id">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('XML_CTE')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('XML_CTE')" matTooltip="RELATÓRIO XML/CTE"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">RELATÓRIO XML/CTE </span></h5>
          </div>
        </div>
        <!--<div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="exibirModal('ICMS_IPI')"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="exibirModal('ICMS_IPI')" matTooltip="INFORMAÇÕES POR ITEM" matTooltipPosition="above" style="width: 150px;"><span class="d-block">INFORMAÇÕES POR ITEM </span></h5>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <span class="icon-card" (click)="buscarXmlNaoEncontrados()"><i class="fas fa-chart-bar"></i></span>
              <h5 class="card-title" (click)="buscarXmlNaoEncontrados()" matTooltip="NOTAS NÃO ESCRITURADAS SPED" matTooltipPosition="above" style="width: 150px;"><span class="d-block">NOTAS NÃO ESCRITURADAS SPED </span></h5>
            </div>
          </div>-->
      </div>
    </div>
  </div>

  <p-dialog header="INFORMAÇÕES POR ITEM" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalIcmsIpi" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Tipo</label>
        <p-dropdown class="type-p-dropdown" [options]="tipos" [(ngModel)]="entradaSaida"></p-dropdown>
      </div>
    </div>

    <p-footer>
      <button class="btn btn-primary" type="button" (click)="reportPc('item')"><i class="fas fa-laptop"></i>
        Tela</button>
    </p-footer>
  </p-dialog>


  <div class="row" *ngIf="modalXmlCte">
    <div class="col-sm-12">
      <!--Arquivos Processados-->
      <div [ngStyle]="{'display': 'block'}">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Relatório XML <span>(Período: {{this.dataIniSearch}} à
                  {{this.dataFinSearch}})</span></h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt20.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="impressaoXml()" matTooltip="Impressão XML"
                  matTooltipPosition="above">
                  <span class="material-icons full-icon" style="font-size: 22px;">file_download</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="impressaoDanfe()"
                  matTooltip="Impressão Danfe" matTooltipPosition="above">
                  <span class="material-icons full-icon" style="font-size: 22px;">print</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStateProcessados>
            <div class="d-flex mt-2">
              <!--Filter Tipo Arquivo-->
              <div class="form-group col-sm-3 col-xxl-2">
                <label>Tipo</label>
                <p-multiSelect #cmp class="type-p-mult" [options]="tipoArquivo" [(ngModel)]="filtroTipo"
                  defaultLabel="Filtre por tipo"></p-multiSelect>
              </div>
              <div class="form-group  mb-1" style="padding-right: 15px;">
                <!--style="min-width: 130px;"-->
                <label>DATA INICIAL</label>
                <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                  [yearNavigator]="true" [defaultDate]="DefInitDate" yearRange="2000:2030" [(ngModel)]="dtInicial">
                </p-calendar>
              </div>
              <div class="form-group  mb-2">
                <label>DATA FINAL</label>
                <p-calendar class="type-p-calendar calendar-small" dateFormat="dd/mm/yy" [monthNavigator]="true"
                  [yearNavigator]="true" [defaultDate]="DefFinalDate" yearRange="2000:2030" [(ngModel)]="dtFinal">
                </p-calendar>
              </div>
              <div class="form-group col-sm-2 mb-2" style="padding-top: 27px;">
                <!-- <label class="d-block" style="visibility: hidden; margin-top: 6px;">DO NOT REMOVE</label> -->
                <button type="button" class="btn btn-info w-100" (click)='atualizarTudo();'><i
                    class="fas fa-refresh"></i> Atualizar</button>
              </div>
            </div>

            <hr class="mt-0">

              <p-table class="table-system" #dt20 [columns]="colsXmlPendente" (onFilter)="onFilter($event)"
              [value]="xmlPendente" [totalRecords]="xmlPendente.length" [rows]="rows" [pageLinks]="pageLinks"
              appendTo="body" [paginator]="true" selectionMode="single" [style]="{ width: '100%' }" [rows]="rows"
              [pageLinks]="pageLinks" (onRowSelect)="onTableRowSelect($event)"
              (onRowUnselect)="onTableRowUnselect($event)" (onHeaderCheckboxToggle)="onTableselectAll($event)">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 3.2%;">
                    <span class="text-overflow-dynamic-container">
                    </span>
                  </th>
                  <th class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'emissao'">
                      <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'emissao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'emissao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'emitente'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'emitente'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'emitente', 'contains')">
                  </th>
                  <th class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                      <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'cnpj', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numNf'">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO DOC <p-sortIcon [field]="'numNf'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'numNf', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'model'">
                      <span class="text-overflow-dynamic-ellipsis">MODELO <p-sortIcon [field]="'model'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'model', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                      <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'valor', 'contains')">
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'chave'">
                      <span class="text-overflow-dynamic-ellipsis">CHAVE <p-sortIcon [field]="'chave'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'chave', 'contains')">
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'finnfe'">
                      <span class="text-overflow-dynamic-ellipsis">FINALIDADE <p-sortIcon [field]="'finnfe'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'finnfe', 'contains')">
                  </th>
                  <th class="text-center" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'status', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center" style="width: 3.2%;">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.emissao}}">{{rowData.emissao}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.emitente}}">{{rowData.emitente}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj}}">{{rowData.cnpj}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.natoper}}">{{rowData.numNf}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.model}}">{{rowData.model}}</span>
                    </span>
                  </td>
                  <td class="text-right" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.valor}}">{{rowData.valor}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.chave}}">{{rowData.chave}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.finnfe}}">{{rowData.finnfe}}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="10" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>