<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content">
        <div class="row">
            <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-coins"></i> Tributos</h3>
            </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-3" style="max-width: 348px;">
            <label>Responsável pelo Pagamento</label>
            <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" (onChange)="buscarObrigacoes()" [options]="opcoesusuarios" [(ngModel)]="usuarioResponsavel.id"></p-dropdown>
            <!-- <mat-select placeholder="Selecione o Responsável" class="example-full-width" [(ngModel)]="usuarioResponsavel.id">
                <mat-option *ngFor="let user of usuarios" [value]="user.id">
                  {{ user.nome }} - {{ user.mail }}
                </mat-option>
              </mat-select> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p-pickList sourceHeader="Disponíveis" targetHeader="Selecionados" [source]="codigosObrigacoes" [target]="codigosObrigacoesSelecionados" [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false">
              <ng-template let-c pTemplate="item">{{c.cod}} - {{c.descricao}}</ng-template>
            </p-pickList>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col mt-3">
              <button class="btn btn-primary" type="button" (click)="atualizarRelacao()" [disabled]="!usuarioResponsavel.id"><i class="fas fa-check"></i> Salvar</button>
          </div>
        </div>
    </div>
  </mat-card-content>
</mat-card>
