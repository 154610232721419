<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row" *ngIf="botaoNovo">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Informações Cadastrais</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarRegister"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <div class="form-group col-sm-12">
              <label>CÓDIGO</label>
              <input class="form-control" [(ngModel)]="vendedor.codigo" type="text" maxlength="255" />
            </div>

            <div class="form-group col-sm-12">
              <label>NOME<span class="obrigatorio">*</span></label>
              <input class="form-control" [(ngModel)]="vendedor.nome" required="true" type="text" minlength="3"
                maxlength="255" />
            </div>

            <div class="form-group col-sm-12">
              <label>CPF</label>
              <input class="form-control" [(ngModel)]="vendedor.cpf" type="text" minlength="10"
                maxlength="20" />
            </div>

            <div class="form-group col-sm-12">
              <label>E-MAIL</label>
              <input class="form-control" [(ngModel)]="vendedor.email" type="text" maxlength="50" />
            </div>

            <div class="form-group col-sm-12">
              <label>TELEFONE</label>
              <input class="form-control" [(ngModel)]="vendedor.telefone" type="text" maxlength="20" />
            </div>

            <div class="form-group col-sm-12 row">

              <div class="d-flex flex-column inlineSharedDiv">
                <label for="dtAdmissaoInput">DATA DE ADMISSÃO</label>
                <p-calendar [(ngModel)]="vendedor.dtAdmissao" dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="2000:2030" inputId="dtAdmissaoInput"></p-calendar>
              </div>

              <div class="d-flex flex-column inlineSharedDiv">
                <label class="">VALOR FIXO</label>
                <p-inputNumber inputStyleClass="form-control" styleClass="" [(ngModel)]="vendedor.valorFixo" mode="currency" currency="BRL"></p-inputNumber>
              </div>

            </div>

            <div class="form-row col-sm-12">
              <div class="form-group col-sm-6 type-mat-slide-toggle" input-vertical="middle">
                <mat-slide-toggle class="mr-2" [(ngModel)]="vendedor.visualizaOutrosVendedores"> VISUALIZA PEDIDO DE OUTROS VENDEDORES </mat-slide-toggle>
              </div>
              <div class="form-group col-sm-6 type-mat-slide-toggle" input-vertical="middle">
                <mat-slide-toggle class="mr-2" [(ngModel)]="vendedor.ativo"> ATIVO </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!botaoNovo">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Vendedor</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content">
            <div class="row">
              <div class="col-sm-12">

                <p-table class="table-system" #dt1 [value]="vendedoresLista" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" emptyMessage="Nenhum Registro Encontrado" [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}" (onRowSelect)="handleSelect()" (onRowUnselect)="handleSelect()" [(selection)]="selectedSellers">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="tableHeaderCheckbox"></th>
                      <th class="text-left" style="width: 10%">
                        <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                          <span class="text-overflow-dynamic-ellipsis">
                            CÓDIGO <p-sortIcon field="codigo"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'codigo','contains')">
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="nome">
                          <span class="text-overflow-dynamic-ellipsis">
                            NOME <p-sortIcon field="nome"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'nome','contains')">
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="cpf">
                          <span class="text-overflow-dynamic-ellipsis">
                            CPF <p-sortIcon field="cpf"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'cpf','contains')">
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="email">
                          <span class="text-overflow-dynamic-ellipsis">
                            E-MAIL <p-sortIcon field="email"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'email','contains')">
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="telefone">
                          <span class="text-overflow-dynamic-ellipsis">
                            TELEFONE <p-sortIcon field="telefone"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'telefone','contains')">
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="dtAdmissao">
                          <span class="text-overflow-dynamic-ellipsis">
                            DATA ADMISSÃO <p-sortIcon field="dtAdmissao"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'dtAdmissao','contains')">
                      </th>
                      <th class="text-left">
                        <span class="text-overflow-dynamic-container" pSortableColumn="valorFixo">
                          <span class="text-overflow-dynamic-ellipsis">
                            VALOR FIXO <p-sortIcon field="valorFixo"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'valorFixo','contains')">
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowValue>
                    <tr>
                      <td class="text-center tableCheckbox">
                        <p-tableCheckbox [value]="rowValue"></p-tableCheckbox>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.codigo }}"> {{ rowValue.codigo }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.nome }}"> {{ rowValue.nome }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.cpf }}"> {{ rowValue.cpf }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.email }}"> {{ rowValue.email }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.telefone }}"> {{
                            rowValue.telefone }} </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.dtAdmissao }}"> {{
                            rowValue.dtAdmissao }} </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.valorFixo}}"> {{
                            rowValue.valorFixo | currency: 'BRL'}} </span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="8" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

</mat-card>