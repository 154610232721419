<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="tabela-data-table" *ngIf="recebimentos">
    <p-table #dt1 [(value)]="recebimentos" [rows]="rows" [paginator]="true" (onRowSelect)="onRowSelect($event)" (onFilter)="onFilter($event)"
      [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">


          <ng-template pTemplate="header">
            <tr>
              <th class="text-center">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                  <span class="text-overflow-dynamic-ellipsis">DATA BAIXA <p-sortIcon [field]="'data'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'data', 'contains')">
              </th>
              <th class="text-center">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'nome', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nf'">
                  <span class="text-overflow-dynamic-ellipsis">NF <p-sortIcon [field]="'nf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'nf', 'contains')">
              </th>
              <th class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                  <span class="text-overflow-dynamic-ellipsis">Parcela <p-sortIcon [field]="'parcela'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'parcela', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                  <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'valor', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldo'">
                  <span class="text-overflow-dynamic-ellipsis">Saldo <p-sortIcon [field]="'saldo'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'saldo', 'contains')">
              </th>
              <th class="text-center" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                  <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text" (input)="dt1.filter($event.target.value, 'status', 'contains')">
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.data}}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.nome}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.nf}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.parcela}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.valor | money}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.saldo | money}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">{{rowData.status}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="7" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
    </p-table>

  </div>
  <table width="800px" cellspacing="0" style="margin-top: 5px;">
    <tr>
      <td width="">
        <div class="div-card-total">
          <p class="titulo-card-total">Total em Aberto</p>
          <p class="valor-card-total">R$ {{valorVencimento | money}}</p>
        </div>
      </td>
    </tr>
  </table>
</mat-card>


<p-dialog header="Detalhes da Baixa" [(visible)]="modalEfetuarBaixa" [style]="{width: '800px'}"
  class="conteudo_modal" [responsive]="true" [contentStyle]="{'overflow':'visible'}" [closable]="true">
  <div class="conteudo conteudo_modal">
    <div class="ui-g">
          <!--<div class="ui-g-7" *ngIf="criarBaixaFinanceira || editarBaixa">
        <div class="ui-g">
          <table width="100%" cellpadding="0" cellspacing="10">
            <tr>
              <td>
            <span style="font-weight: 500">Cliente:</span> {{nomeTitulo}}
            </td>
            </tr>
            <tr>
            <td>
            <span style="font-weight: 500">Núm NF:</span> {{nfTitulo}}<span style="font-weight: 500"> Parcela:</span> {{parcelaTitulo}}
          </td>
          </tr>
          </table>
          <div class="ui-g-8 label-modal">
            Valor Recebido:
          </div>
          <div class="ui-g-4">
            <input type="text" (blur)="calcularValorBaixa()" pInputText [style]="{'text-align':'right'}" [(ngModel)]="valorRecebidoBaixa" money/>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-8 label-modal">
            Valor Multa:
          </div>
          <div class="ui-g-4">
            <input type="text" (blur)="calcularValorBaixa()" pInputText [style]="{'text-align':'right'}" [(ngModel)]="valorMultaBaixa" money/>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-8 label-modal">
            Valor Juros:
          </div>
          <div class="ui-g-4">
            <input type="text" (blur)="calcularValorBaixa()" pInputText [style]="{'text-align':'right'}" [(ngModel)]="valorJurosBaixa" money/>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-8 label-modal">
            Valor Desconto:
          </div>
          <div class="ui-g-4">
            <input type="text" (blur)="calcularValorBaixa()" pInputText [style]="{'text-align':'right'}" [(ngModel)]="valorDescontoBaixa" money/>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-8 label-modal">
            Total da Baixa:
          </div>
          <div class="ui-g-4">
            <input type="text" readOnly="true" pInputText [style]="{'text-align':'right'}" [(ngModel)]="valorTotalBaixa" money/>
          </div>
        </div>
        <div class="ui-g">
            <div class="ui-g-8 label-modal">
              Data da Baixa:
            </div>
            <div class="ui-g-4">
              <p-calendar appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataVencimento" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
          </div>
          <div class="ui-g">
              <div class="ui-g-8 label-modal">
                  Conta Bancária:
                </div>
                <div class="ui-g-4">
            <mat-form-field>
              <mat-select [(ngModel)]="conta">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let c of contasBancarias" [value]="c">
                    {{c.nome}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            </div>
            </div>
          <div class="ui-g">
            <div class="ui-g-8 label-modal">
                Tipo de Baixa:
              </div>
              <div class="ui-g-4">
          <mat-form-field>
            <mat-select [(ngModel)]="tipoBaixa">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let t of tiposBaixa" [value]="t">
                {{t.descricao}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          </div>
        <div id="div-botoes">
          <div class="margin-botoes-modal">
            <button mat-raised-button class="mat-elevation-z0" (click)="cancelar()">Cancelar</button>
            <button type="button" *ngIf="editarBaixa && !criarBaixaFinanceira" [disabled]="false" pButton label="Excluir" class="botao-excluir" (click)="excluirBaixa()"></button>
            <button *ngIf="criarBaixaFinanceira" type="button" pButton label="Baixar" (click)="baixar()"></button>
            <button *ngIf="editarBaixa" type="button" pButton label="Salvar" (click)="alterarBaixa()"></button>
          </div>
        </div>
      </div>-->
      <div class="ui-g-12" *ngIf="criarHistoricoCobranca || editarHistorico">
          <div class="ui-g">
            <table width="100%" cellpadding="0" cellspacing="10">
              <tr>
                <td>
              <span style="font-weight: 500">Cliente:</span> {{nomeTitulo}}
              </td>
              </tr>
              <tr>
              <td>
              <span style="font-weight: 500">Núm NF:</span> {{nfTitulo}}<span style="font-weight: 500"> Parcela:</span> {{parcelaTitulo}}
            </td>
            </tr>
            <span style="font-weight: 700">Histórico:</span>
            <tr>
              <td>
            <textarea rows="5" cols="30" value="{{historico}}" [(ngModel)]="historico" pInputTextarea class="not-resize"></textarea>
          </td>
          </tr>
            <div class="ui-g">
                <div class="ui-g-6 label-modal">
                  Data do Histórico
                </div>
                <div class="ui-g-6">
                  <p-calendar appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataHistorico" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
                </div>
              </div>

            </table>
          </div>
          <div id="div-botoes">
            <div class="margin-botoes-modal">
              <button mat-raised-button class="mat-elevation-z0" (click)='modalEfetuarBaixa=false'>Cancelar</button>
              <button type="button" *ngIf="editarHistorico && !criarHistoricoCobranca" [disabled]="false" pButton label="Excluir" class="botao-excluir" (click)="excluirHistorico()"></button>
              <button *ngIf="criarHistoricoCobranca" type="button" pButton label="Gravar" (click)="gravarHistorico()"></button>
              <button *ngIf="editarHistorico" type="button" pButton label="Salvar" (click)="alterarHistorico()"></button>
            </div>
          </div>
        </div>
      <div class="ui-g-12" style="border-left: 1px solid #ededed;">
        <div class="ui-g" style="border:1px solid #ededed; border-radius: 3px;">
          <table width="100%">
            <tr>
              <td width="90%">
                  <span style="font-weight: 600">Histórico de Baixa</span>
              </td>
              <td width="10%">
                  <span style="font-weight: 700; font-size: 18px"></span>
              </td>
        </tr>
        </table>
          <div class="ui-g-12" style="border-top:1px solid #ededed; height: 79px !important;overflow-x: auto">
            <table width="100%" cellspacing="1">
              <ng-container *ngFor="let b of baixas">
                <tr>
                  <td>
                      <!--<span style="font-weight: 500">{{b.data_baixa}}</span>-->
                      <span style="font-weight: 500; text-transform: initial">{{b.usuario}}</span>
                      <span> - {{b.data_baixa}}:</span>
                  </td>
                </tr>
                <tr>
                  <td>
                      <span style="text-transform: initial">Valor da Baixa:</span> {{b.valor + b.multa +
                        b.juros-b.desconto| money}}
                  </td>
                </tr>
                <tr>
                    <tr>
                    <td style="border-bottom:1px solid #ededed;padding-bottom:5px;">
                        <span style="text-transform: initial">Tipo de Baixa:</span> {{b.tipoBaixa.descricao}} -
                        <span style="text-transform: initial">Conta: </span> {{b.empresaConta.nome}}
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
        <div class="ui-g" style="border:1px solid #ededed; border-radius: 3px;margin-top: 10px;">
            <table width="100%">
                <tr>
                  <td width="90%">
                      <span style="font-weight: 600">Histórico de Cobrança</span>
                  </td>
                  <td width="10%">
                      <span style="font-weight: 700; font-size: 18px"></span>
                  </td>
            </tr>
            </table>
          <div class="ui-g-12" style="border-top:1px solid #ededed; height: 79px !important;overflow-x: auto">
            <table width="100%" cellspacing="1">
              <ng-container *ngFor="let h of historicos">
                <tr style="cursor: pointer;" (click)="exibirHistorico(h)">
                  <td style="border-bottom:1px solid #ededed;padding-bottom:5px;">
                    <span style="font-weight: 500; text-transform: initial">{{h.usuario}}</span>
                    <span> - {{h.data}}:</span>
                    <p style="margin: 0px; text-transform: none">{{h.descricao}}</p>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
    <p-footer>
    </p-footer>
  </div>
</p-dialog>
