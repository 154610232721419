<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>

    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsFichas" [value]="fichas" [paginator]="true" [rows]="rows"
        [totalRecords]="fichas.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
        [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dataEvento'">
                <span class="text-overflow-dynamic-ellipsis">Data Evento <p-sortIcon [field]="'dataEvento'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dataEvento', 'contains')">
            </th>

            <th class="text-left" style="width: 40%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'evento'">
                <span class="text-overflow-dynamic-ellipsis">Evento <p-sortIcon [field]="'evento'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'evento', 'contains')">
            </th>


            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cidade'">
                <span class="text-overflow-dynamic-ellipsis">Cidade <p-sortIcon [field]="'cidade'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cidade', 'contains')">
            </th>

            <th class="text-left" style="width: 6%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                <span class="text-overflow-dynamic-ellipsis">UF <p-sortIcon [field]="'uf'"></p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboUf" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'uf', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_ingresso'">
                <span class="text-overflow-dynamic-ellipsis">Tipo Ingresso <p-sortIcon [field]="'tipo_ingresso'">
                  </p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboIngresso" defaultLabel="Selecione"
                [filter]="false" (onChange)="dt2.filter($event.value, 'tipo_ingresso', 'in')"></p-multiSelect>
            </th>

            <th class="text-center" style="width: 6%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'qtde'">
                <span class="text-overflow-dynamic-ellipsis">QTDE <p-sortIcon [field]="'qtde'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'qtde', 'contains')">
            </th>


            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'origem'">
                <span class="text-overflow-dynamic-ellipsis">Origem <p-sortIcon [field]="'origem'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dataCadastro', 'contains')">
            </th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>



            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dataEvento}}">{{rowData.dataEvento
                  }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 40%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.evento}}">{{rowData.evento }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cidade}}  "> {{rowData.cidade}}
                </span>
              </span>
            </td>
            <td class="text-center" style="width: 6%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.uf}}  ">{{rowData.uf}} </span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.tipo_ingresso}}   ">{{rowData.tipo_ingresso}} </span>
              </span>
            </td>

            <td class="text-center" style="width: 6%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.qtde}} "> {{rowData.qtde}} </span>
              </span>
            </td>


            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{rowData.origem}} "> {{rowData.origem}}
                </span>
              </span>
            </td>


          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="7" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


    </div>
  </mat-card-content>
</mat-card>