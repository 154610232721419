<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        LOG
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Consultar o LOG de operações do sistema</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content d-flex align-items-center justify-content-between p-3">
            <h3 class="title-card">&nbsp;</h3>
            <app-toolbar [value]="toolbar"></app-toolbar>
          </div>
        </div>
        <div class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="logRows" selectionMode="single" [(selection)]="selectedLog" (onRowSelect)="changeToolbar()" (onRowUnselect)="changeToolbar()" [paginator]="true" [rows]="rows" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 15%">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_execucao'">
                      <span class="text-overflow-dynamic-ellipsis">Data <p-sortIcon [field]="'data_execucao'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'data_execucao', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'mensagem'">
                      <span class="text-overflow-dynamic-ellipsis">Mensagem <p-sortIcon [field]="'mensagem'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'mensagem', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome_completo'">
                      <span class="text-overflow-dynamic-ellipsis">Usuário <p-sortIcon [field]="'nome_completo'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'nome_completo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'registro_id'">
                      <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'registro_id'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'registro_id', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'registro_codigo'">
                      <span class="text-overflow-dynamic-ellipsis">código <p-sortIcon [field]="'registro_codigo'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'registro_codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 20%">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'informacao_extra'">
                      <span class="text-overflow-dynamic-ellipsis">Informação Extra <p-sortIcon [field]="'informacao_extra'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" (input)="dt.filter($event.target.value, 'informacao_extra', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" >
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.data_execucao | date: 'dd/MM/yyyy HH:mm:ss':'+0000' }}">{{ rowData.data_execucao | date: 'dd/MM/yyyy HH:mm:ss':'+0000' }}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.mensagem }}">{{ rowData.mensagem }}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.nome_completo }}">{{ rowData.nome_completo }}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.registro_id }}">{{ rowData.registro_id }}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.registro_codigo }}">{{ rowData.registro_codigo }}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.informacao_extra }}">{{ rowData.informacao_extra }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!</div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog header="Buscar log" [(visible)]="showSearchLogModal" [style]="{width: '600px'}" [modal]="true" styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Módulo</label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione o módulo" [(ngModel)]="selectedModule" [options]="optionsModule" (onChange)="changeOptionsScreen()"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Tela</label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione a tela" [(ngModel)]="selectedScreen" [options]="optionsScreen"></p-dropdown>
    </div>
    <div class="form-group col-sm-12">
      <label>Ação</label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione a ação" [(ngModel)]="selectedAction" [options]="optionsAction"></p-dropdown>
    </div>
    <!-- <div class="form-group col-sm-12">
      <label>Data de execução</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" dateFormat="dd/mm/yy"></p-calendar>
    </div> -->
  </div>
  <p-footer>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="getLog()" [disabled]="isLoadingNewLogRows">
        <span class="material-icons">check</span>
        <div>Confirmar</div>
      </button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="Detalhes" [(visible)]="showDetailsLogModal" [style]="{width: '600px'}" [modal]="true" styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="pre-container" style="max-height: 600px; overflow-x: auto;">
    <pre *ngIf="showDetailsLogModal" style="overflow: hidden; white-space: break-spaces;" [innerHTML]="selectedLog.informacao_extra"></pre>
  </div>
  <!-- <code *ngIf="showDetailsLogModal" [innerHTML]="selectedLog.informacao_extra"></code>

  <pre *ngIf="showDetailsLogModal" [innerText]="selectedLog.informacao_extra"></pre>
  <code *ngIf="showDetailsLogModal" [innerText]="selectedLog.informacao_extra"></code>'

  <pre *ngIf="showDetailsLogModal">{{selectedLog.informacao_extra}}</pre>
  <code *ngIf="showDetailsLogModal">{{selectedLog.informacao_extra}}</code>


  <textarea *ngIf="showDetailsLogModal" cols="30" rows="10">{{selectedLog.informacao_extra}}</textarea>
  <textarea *ngIf="showDetailsLogModal" cols="30" rows="10" [innerHTML]="selectedLog.informacao_extra"></textarea>

  <textarea *ngIf="showDetailsLogModal" cols="30" rows="10" [innerText]="selectedLog.informacao_extra"></textarea> -->

  <p-footer>
    <div class="w-100 d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="null">
        <span class="material-icons">check</span>
        <div>Confirmar</div>
      </button>
    </div>
  </p-footer>
</p-dialog>