<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-file-alt" style="font-size: 15px;"></i> Tipo Orçamento</h3>
              <div class="actions-card">
                <button type="button" (click)="showModalNovoTipoOrcamento()" class="btn btn-xs btn-link"
                  matTooltip="Adicionar Tipo Orçamento" matTooltipPosition="above"
                  [disabled]="listaIdsSelecionados.length">
                  <span class="material-icons" style="font-size: 28px; margin-right: -5px;">add</span>
                </button>
                <button type="button" (click)="carregarInformacaoRegistro(this.listaIdsSelecionados[0] )"
                  class="btn btn-xs btn-link" matTooltip="Editar Tipo Orçamento" matTooltipPosition="above"
                  [disabled]="listaIdsSelecionados.length > 1 || listaIdsSelecionados.length <= 0">
                  <span class="material-icons" style="font-size: 24px; margin-right: -5px;">edit</span>
                </button>
                <button type="button" (click)="excluirRegistro()" class="btn btn-xs btn-link"
                  matTooltip="Remover Tipo Orçamento" matTooltipPosition="above"
                  [disabled]="!listaIdsSelecionados.length">
                  <span class="material-icons" style="font-size: 23px; margin-right: 0px;">delete</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideTipoOrca()"
                  (click)="changeStateTipoOrca()">
                  <span *ngIf="isCardTipoOrca" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardTipoOrca" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateTipoOrca>
            <div class="row">
              <div class="col-sm-12">
                <p-table class="table-system" #dt [columns]="colsListaTipoOrcamento" [value]="listaTipoOrcamento"
                  [rows]="rows" [paginator]="true" [paginator]="true" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                  [scrollable]="false" scrollHeight="20vh">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center tableHeaderCheckbox">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                        </span>
                      </th>
                      <th class="text-left" style="width: 30%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                      </th>
                      <th class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                          <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value, 'ativo', 'contains')">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center tableCheckbox">
                        <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                        </p-tableCheckbox>
                      </td>
                      <td class="text-left" style="width: 30%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codigo }}">{{
                            rowData.codigo }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 6%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.ativo }}">
                            <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo" [binary]="true"
                      inputId="ativo" [disabled]="true"></p-checkbox>
                          </span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>

  <p-dialog header="Orçamento Versão" [(visible)]="boolCriarTipoOrcamento || boolEditTipoOrcamento"
    styleClass="modal-initial" width="400" [modal]="true" [responsive]="true" appendTo="body" [closable]="true"
    (onHide)="closeDialog()">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>CÓDIGO <span class="obrigatorio" *ngIf="boolCriarTipoOrcamento">*</span></label>
        <input class="form-control" value="{{tipoOrcamento.codigo}}" [(ngModel)]="tipoOrcamento.codigo"
          name="inputCodigo" maxlength="50">
      </div>

      <div class="form-group col-sm-9">
        <label for="inputDescricao">DESCRIÇÃO <span class="obrigatorio" *ngIf="boolCriarTipoOrcamento">*</span></label>
        <input class="form-control" value="{{tipoOrcamento.descricao}}" [(ngModel)]="tipoOrcamento.descricao"
          name="inputDescricao" maxlength="100">
      </div>
    </div>

    <div class="form-row">
      <div class="col-sm-6">
        <mat-slide-toggle [checked]="tipoOrcamento.ativo" class="mr-2" [(ngModel)]="tipoOrcamento.ativo"> Ativo
        </mat-slide-toggle>
      </div>
    </div>

    <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="cancelar()"><i class="fas fa-undo"></i> Cancelar</button>
      <button *ngIf="boolCriarTipoOrcamento && !boolEditTipoOrcamento" type="button" class="btn btn-primary"
        (click)="criarNovoRegistro()"><i class="fas fa-check"></i> Salvar</button>
      <button *ngIf="!boolCriarTipoOrcamento && boolEditTipoOrcamento" type="button" class="btn btn-primary"
        (click)="editarRegistro()"><i class="fas fa-check"></i> Salvar</button>
    </p-footer>
  </p-dialog>
</mat-card>