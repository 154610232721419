<h3 *ngIf="!loading && !hasEmpresaSelected">Para prosseguir, selecione uma empresa no menu superior</h3>

<app-data-table mainTitle="Item Grupo" *ngIf="hasEmpresaSelected"
                singleTitle="Item Grupo"
                routerPath="/item-grupo"
                entityPath="/itemgrupo"
                keyField="id"
                [entity]="itemGrupo"
                [entityFilter]="entityFilter"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
