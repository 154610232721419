<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-12 d-flex align-items-center justify-content-between" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Demonstrativo de estoque
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Informativo da quantidade de estoque</small>
          <br>
        </div>
      </h4>
    </div>
  </div>

  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top">
    <div class="shadow-box w-100 p-3 h-100">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <app-dash-changer class="mt-3" [options]="tabOptionsTable" format="text" (tabSelected)="changeTabTable($event)"></app-dash-changer>
        <p-multiSelect *ngIf="availableWarehouses.length > 0" class="type-p-mult mr-3 mult-header" [filter]="true" [placeholder]="placeholderWarehouse" [options]="availableWarehouses" appendTo="body" [(ngModel)]="selectedWarehouses" [disabled]="loadingOptWarehouses" [dropdownIcon]="loadingOptIconWarehouses ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="checkWarehousesList()"></p-multiSelect>
      </div>
      <app-dash-table *ngIf="selectedWarehouses.length > 0" [data]="stockControlTable" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="true" [hasFilter]="true" [hasPaginator]="true" [hasFooter]="false" [hasExport]="false" [showShadowBox]="false" [isScrollable]="true" (appliedFilter)="changeFilterDetails($event)" [ngStyle]="{'display': (tabStock === 'control') ? 'block' : 'none'}"></app-dash-table>
      <app-dash-table *ngIf="selectedWarehouses.length > 0" [data]="stockBuyListTable" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="true" [hasFilter]="true" [hasPaginator]="true" [hasFooter]="false" [hasExport]="false" [showShadowBox]="false" [isScrollable]="true"  (appliedFilter)="changeFilterDetails($event)" [ngStyle]="{'display': (tabStock === 'buyList') ? 'block' : 'none'}"></app-dash-table>
    </div>
  </div>
</div>

<div class="empty" *ngIf="selectedWarehouses.length <= 0">
  <div class="empty-content">
      <span class="material-icons">warning</span>
      <h2>Por favor, selecione pelo menos um almoxarifado</h2>
  </div>
</div>