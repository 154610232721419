<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <mat-tab-group>

      <mat-tab label="Projetos para Centro de Custo">
        <div class="box-content">
          <div class="d-flex justify-content-end">
            <app-toolbar [value]="toolbarMain"></app-toolbar>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title">Centro de Custo</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="centrosDeCustos"
                placeholder="Selecione o Centro de Custo" [(ngModel)]="centroDeCustoId" (onChange)="buscarProjetos()">
              </p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title">Projetos</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="conteudo m-0">
                <div style="margin-left: 250px;" [style]="{ width: '100%' }">
                  <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Projetos relacionados"
                    [source]="listaProjetosAMapear" [target]="listaProjetosMapeados" [dragdrop]="true"
                    [responsive]="true" filterBy="codProj" [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-projeto pTemplate="item">{{ projeto.codProj }} - {{ projeto.descrProj }}
                    </ng-template>
                  </p-pickList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

</mat-card>