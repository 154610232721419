<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">

      <mat-tab label="Relacionamento Itens e Grupo">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-user"></i> Grupo operação</h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="grupos"
                placeholder="Selecione o Grupo" [(ngModel)]="grupoId" (onChange)="buscarItens()"></p-dropdown>
            </div>
          </div>
            <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas  fa-hand-holding-usd"></i> Itens </h3>
            </div>
            </div>
            <div class="row">
            <div class="col-sm-8">
              <div class="conteudo m-0">
                <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Itens relacionados" [source]="listaItensAMapear"
                  [target]="listaItensMapeados" [dragdrop]="true" [responsive]="true" filterBy="codItem"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-item pTemplate="item">{{ item.codItem }} - {{ item.descrItem }}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

</mat-card>