<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{title}}
  </mat-card-title>
  <div class="row">
    <div class="col">

    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 class="sub-title"><i class="fas fa-chart-bar"></i> Livros fiscais para visualização na tela ou exportar para
        excel</h3>
    </div>
  </div>

  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom" *ngIf="empresa?.id">
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('ICMS_IPI')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('ICMS_IPI')" matTooltip="ICMS / IPI" matTooltipPosition="above"
              style="width: 150px;"><span class="d-block">ICMS / IPI </span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('RETIDOS_NA_FONTE')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('RETIDOS_NA_FONTE')" matTooltip="RETIDOS NA FONTE"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">RETIDOS NA FONTE </span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('DEMAIS_DOCUMENTOS')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('DEMAIS_DOCUMENTOS')" matTooltip="DEMAIS DOCUMENTOS"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">DEMAIS DOCUMENTOS </span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('ICMS_IPI_HTML')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('ICMS_IPI_HTML')" matTooltip="ICMS/IPI (PDF)"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">ICMS/IPI (PDF) </span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('PIS_COFINS')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('PIS_COFINS')" matTooltip="APURAÇÃO PIS/COFINS"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">APURAÇÃO PIS/COFINS </span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('DIFAL')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('DIFAL')" matTooltip="DIFAL"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">DIFAL</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('DIFAL_NAO_CONTRIB')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('DIFAL_NAO_CONTRIB')" matTooltip="DIFAL NÃO CONTRIBUINTE"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">DIFAL NÃO CONTRIBUINTE</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('ICMS_ST')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('ICMS_ST')" matTooltip="ICMS ST"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">ICMS ST</span></h5>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('LIVRO_FISCAL')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('LIVRO_FISCAL')" matTooltip="LIVRO FISCAL"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">LIVRO FISCAL</span></h5>
          </div>

        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal('CSRF')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal('CSRF')" matTooltip="APURAÇÃO CSRF"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">APURAÇÃO CSRF</span></h5>
          </div>
        </div>
        <div class="card" *ngIf="existeRelatorioExterno">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('EXT')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('EXT')" matTooltip="RELATÓRIOS EXTERNOS"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatórios Externos</span>
            </h5>
          </div>
        </div>
        <div class="card" *ngFor="let relat of listaRelatGer">
          <div class="card-body">
            <span class="icon-card" (click)="exibirModal(relat)"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="exibirModal(relat)" matTooltip="{{relat.nome}}" matTooltipPosition="above"
              style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-dialog header="ICMS / IPI" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
    [(visible)]="modalIcmsIpi" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Tipo</label>
        <p-dropdown class="type-p-dropdown" [options]="tipos" [(ngModel)]="entradaSaida"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <!-- <mat-checkbox [(ngModel)]="detalhePorItem">Detalhe por Item</mat-checkbox> -->
        <div class="p-field-checkbox mb-3 ml-1">
          <p-checkbox class="mr-1" [(ngModel)]="detalhePorItem" [binary]="true" inputId="detalhePorItem"></p-checkbox>
          <label for="detalhePorItem" class="ml-1">Detalhe por Item</label>
        </div>
      </div>
    </div>
    <p-footer>
      <button [disabled]="((!dtIni|| !dtFin || dtFin < dtIni || entradaSaida == '999'))" class="btn btn-primary" type="button"
        (click)="reportPc('icmsIpi')"><i class="fas fa-laptop"></i> Tela</button>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni || entradaSaida == '999'))" class="btn btn-primary" type="button"
        (click)="gerarRelatorioIcmsIpiRetidoFonte('icmsIpi')"><i class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="RETIDOS NA FONTE" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalRetidos" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Tipo</label>
        <p-dropdown class="type-p-dropdown" [options]="tiposRetido" [(ngModel)]="servico"></p-dropdown>
      </div>
    </div>
    <p-footer>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni || servico == '999'))" class="btn btn-primary" type="button"
        (click)="reportPc('retidosFonte')"><i class="fas fa-laptop"></i> Tela</button>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni || servico == '999'))" class="btn btn-primary" type="button"
        (click)="gerarRelatorioIcmsIpiRetidoFonte('retidosFonte')"><i class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="DEMAIS DOCUMENTOS" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalDemaisDocs" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Tipo</label>
        <p-dropdown class="type-p-dropdown" [options]="tipos" [(ngModel)]="entradaSaida"></p-dropdown>
      </div>
    </div>
    <p-footer>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni))" class="btn btn-primary" type="button"
        (click)="reportPcOld('demaisDocs')"><i class="fas fa-laptop"></i> Tela</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="ICMS/IPI (PDF)" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
    [(visible)]="modalIcmsIpiHtml" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col">
        <label>Tipo do Relatório</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="relatorioSelecionado" [options]="listaRelatFiscal"
          defaultLabel="Selecione" [filter]="true"></p-dropdown>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" style="padding: 0.375rem 0.9rem;" type="button" (click)="baixarIcmsIpi()"><i
          class="far fa-file-pdf pdf"></i> Exportar</button>
    </p-footer>
  </p-dialog>

  <!-- MODAL GERENCIAL-->
  <p-dialog header="{{relatGerSelecionado.nome}}" [modal]="true" appendTo="body" width="500" styleClass="modal-initial"
    [(visible)]="modalRelatorioGer" [responsive]="true" [closable]="true">
    <div class="form-row" *ngIf="!relatGerSelecionado.is_composta">
      <div class="form-group col-sm-4">
        <label>DATA REFERÊNCIA</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef">
        </p-calendar>
      </div>
    </div>

    <div class="row" *ngIf="relatGerSelecionado.is_composta">
      <div class="form-group col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>DATA REFERÊNCIA</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
              [defaultDate]="dataRef" yearRange="2000:2030" [(ngModel)]="dataRef" [disabled]="datasIndividuais">
            </p-calendar>
          </div>
          <div class="form-group col-sm-6">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <p-checkbox [binary]="true" [(ngModel)]="datasIndividuais">Utilizar datas individuais</p-checkbox>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div class="form-row">
          <p-table class="table-system" [value]="dicRelatComposto[relatGerSelecionado.id]"
            emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                  Data de referência</th>
                <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                  *ngIf="datasIndividuais"></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-config>
              <tr>
                <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                <td style="text-align: center">{{config.worksheet_title}}</td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
                    [yearNavigator]="true" [defaultDate]="dataRef" yearRange="2000:2030"
                    [(ngModel)]="config['dataRef']">
                  </p-calendar>
                </td>
                <td style="text-align: center" *ngIf="datasIndividuais">
                  <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                      (click)="carregarValores(config)"></i></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <p-footer>
      <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
        *ngIf="!relatGerSelecionado.is_composta"><i class="fas fa-laptop"></i> Tela</button>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- PIS/COFINS -->
  <p-dialog header="Apuração PIS/COFINS" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalPisCofins" [responsive]="true" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label class="d-block">Periodo</label>
        <p-calendar class="type-p-calendar" dateFormat="MM yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="periodo" yearRange="2000:2030" [(ngModel)]="periodo"></p-calendar>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioPisCofins()"><i
          class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <!-- Modal EXT-->
  <p-dialog header="RELATÓRIOS EXTERNOS" [modal]="true" appendTo="body" width="700" styleClass="modal-initial"
    [(visible)]="statusRelExt" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col-sm-5">
        <label class="d-block">Relatório</label>
        <p-dropdown class="type-p-dropdown" [(ngModel)]="relExterno" [options]="opcoesRelatoriosExternos"
          defaultLabel="Selecione" [filter]="true" (onChange)="relatorioExterno($event)"></p-dropdown>
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirFilial">
        <label class="d-block">Filial</label>
        <input class="form-control text-right" type="text" [(ngModel)]="filialRelatorio" />
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirDtIni">
        <label class="d-block">Data inicial</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtIni" yearRange="2000:2030" [(ngModel)]="dtIni"></p-calendar>
      </div>
      <div class="form-group col-sm-2" *ngIf="exibirDtFin">
        <label class="d-block">Data final</label>
        <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
          [defaultDate]="dtFin" yearRange="2000:2030" [(ngModel)]="dtFin"></p-calendar>
      </div>
    </div>


    <p-footer>
      <button class="btn btn-primary" type="button" (click)="gerarRelatorioExterno()"><i class="far fa-file-excel"></i>
        Excel</button>
    </p-footer>
  </p-dialog>
</mat-card>

<p-dialog header="DIFAL" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalDifal" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
       </div>
    <p-footer>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni))" class="btn btn-primary" type="button"
      (click)="reportPc('difal')"><i class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="DIFAL NÃO CONTRIBUINTE" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalDifalNaoContrib" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
       </div>
    <p-footer>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni))" class="btn btn-primary" type="button"
      (click)="reportPc('difalNaoContrib')"><i class="far fa-file-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="ICMS ST" [modal]="true" appendTo="body" [style]="{width: '500px'}"
    styleClass="modal-initial" [(visible)]="modalIcmsSt" [responsive]="true" [closable]="true">

    <div class="form-row">
      <div class="form-group col">
        <label>Data Inicial</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
      <div class="form-group col">
        <label>Data Final</label>
        <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
          [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
       </div>
    <p-footer>
      <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni))" class="btn btn-primary" type="button"
      (click)="reportPc('icmsSt')"><i class="fas fa-excel"></i> Excel</button>
    </p-footer>
  </p-dialog>

  <p-dialog header="LIVRO FISCAL" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
  [(visible)]="modalLivroFiscal" [responsive]="true" [closable]="true">

  <div class="form-row">
    <div class="form-group col">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
    </div>
    <div class="form-group col">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
    </div>
  </div>

  <p-footer>
    <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni))" class="btn btn-primary" type="button"
      (click)="reportPc('livrofiscal')"><i class="far fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="APURAÇÃO CSRF" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
[(visible)]="modalPcc" [responsive]="true" [closable]="true">

<div class="form-row">
  <div class="form-group col">
    <label>Data Inicial</label>
    <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
      [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
  </div>
  <div class="form-group col">
    <label>Data Final</label>
    <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
      [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
  </div>
</div>

<p-footer>
  <button [disabled]="((!dtIni || !dtFin || dtFin < dtIni))" class="btn btn-primary" type="button"
    (click)="reportPc('livroCsrf')"><i class="far fa-file-excel"></i> Excel</button>
</p-footer>
</p-dialog>


