<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12"></div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Tipos de Anexo</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
                <button type="button" class="btn btn-xs btn-link" (click)="changeStateTpAnexo()" (click)="showHideTpAnexo()">
                  <span *ngIf="isCardTpAnexo" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above"> expand_less </span>
                  <span *ngIf="!isCardTpAnexo" class="material-icons" matTooltip="Ver Card" matTooltipPosition="above">
                    expand_more </span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]="currentStateTpAnexo">
            <p-table class="table-system" #dt1 [value]="tipoAnexoRequisicao"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodos()"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left" style="width: 100%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">
                        {{rowData.descricao}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="2">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i>
                      Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<p-dialog header="Detalhe Tipo Anexo" [(visible)]="modalTpAnexo" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="descricaoTpAnexo">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalTpAnexo=false;edit=false;add=false'><i
            class="fas fa-undo"></i>
          Cancelar</button>
        <button *ngIf="add" type="button" class="btn btn-primary" (click)="adicionar()"><i class="fas fa-check"></i>
          Salvar</button>
        <button *ngIf="edit" type="button" class="btn btn-primary" (click)="atualizar()"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>