<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-tab-group *ngIf="empresa" [(selectedIndex)]="tabIndex" (selectedTabChange)="tabChange()" dynamicHeight="true">
    <mat-tab label="Destinadas">
      <br>
      <div class="container-formulario">
        <app-period-select *ngIf="tabIndex === 0" [(ngModel)]="period" (change)="periodChange()" [months]="12"
          displayMonths="true" prompt="" filter="contains">
        </app-period-select>
      </div>
    </mat-tab>
    <mat-tab label="Emitidas">
      <br>
      <div class="container-formulario">
        <app-period-select *ngIf="tabIndex === 0" [(ngModel)]="period" (change)="periodChange()" [months]="12"
          displayMonths="true" prompt="" filter="contains">
        </app-period-select>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>