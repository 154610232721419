<div class="pt-3 customPadding">
  <div class="row d-flex align-items-center" style="padding-left: 1rem;">
    <div class="col-6" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Configuração de integração
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatDateExtensive()"></small>
        </div>
      </h4>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button class="btnCustom-green" matTooltip="Criar nova integração" (click)="addNewIntegration()">
        <span class="material-icons">add</span>
        <div>Nova integração</div>
      </button>
    </div>
  </div>
  <div class="row pt-4">
    <div class="col-sm-12" *ngIf="!isLoadingIntegrationsList && integrationsList.length > 0">
      <div class="card-integration" *ngFor="let item of integrationsList">
        <div class="header">
          <div [class]="'image ' + setCssClass(item.integrationCode)">
            <img [src]="setLogoImage(item.integrationCode)"/>
          </div>
          <div class="title">
            <h3>{{ item.name }}</h3>
            <div class="infos">
              <small>API: {{ setIntegrationLabel(item.integrationCode) }}</small>
              <small *ngIf="setShowRelatedAccount(item.integrationCode)">-</small>
              <small *ngIf="setShowRelatedAccount(item.integrationCode)">Conta bancária: {{ setCompanyAccountLabel(item.companyAccount) }}</small>
            </div>
          </div>
          <div class="btn-actions">
            <button *ngIf="!item.isLoading && item.isOpen" class="btnCustom-green" matTooltip="Salvar alterações" (click)="saveIntegration(item)">
              <span class="material-icons">save</span>
              <div>Salvar</div>
            </button>
            <button *ngIf="item.isLoading" class="btnCustom-green" [disabled]="true">
              <span class="material-icons loading-icon">hourglass_empty</span>
              <div>Carregando...</div>
            </button>
            <button class="btnCustom-default" [matTooltip]="item.isOpen ? 'Ver menos' : 'Ver mais'" (click)="openCardIntegration(item.lineId)">
              <span class="material-icons">{{ item.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
            </button>
          </div>
        </div>
        <div class="body" [ngClass]="{ 'is-open': item.isOpen }">
          <div class="form">
            <div class="header-form">
              <h6>Informações Iniciais</h6>
            </div>
            <div class="body-form col-sm-12">
              <div class="form-row">
                <div [class]="setColumnSize(item.integrationCode)">
                  <label>API de referência <span class="obrigatorio">*</span></label>
                  <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="setAPIsAvailable(item.lineId)" emptyMessage="Nenhum resultado encontrado!" [disabled]="(item.id !== null) || loadingOptAPIS" [dropdownIcon]="loadingIconOptAPIS ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="item.integrationCode"></p-dropdown>
                </div>
                <div [class]="setColumnSize(item.integrationCode)">
                  <label>Nome da configuração <span class="obrigatorio">*</span></label>
                  <input class="form-control" type="text" [(ngModel)]="item.name"/>
                </div>
                <div *ngIf="setShowRelatedAccount(item.integrationCode)" [class]="setColumnSize(item.integrationCode)">
                  <label>Conta referência <span class="obrigatorio">*</span></label>
                  <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optCompanyAccounts" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="item.companyAccount"></p-dropdown>
                </div>
                <div *ngIf="setShowRelatedStartDate(item.integrationCode)" [class]="setColumnSize(item.integrationCode)">
                  <label>Data inicial <span class="obrigatorio">*</span></label>
                  <p-calendar appendTo="body" class="type-p-calendar datepicker-integration" [monthNavigator]="true" [yearNavigator]="true" [(ngModel)]="item.startDate" yearRange="2010:2030" [defaultDate]="defaultDate" dateFormat="dd/mm/yy"></p-calendar>
                </div>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="header-form">
              <h6>Configurações</h6>
            </div>
            <div class="body-form col-sm-12" [ngSwitch]="item.integrationCode">
              <app-cfg-foxbit *ngSwitchCase="'FOX'"></app-cfg-foxbit>
              <app-cfg-matera *ngSwitchCase="'MAT'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)" (isFinishedSave)="setSaveIntegration(item.lineId, !$event)"></app-cfg-matera>
              <app-cfg-eye *ngSwitchCase="'EYE'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)"></app-cfg-eye>
              <app-cfg-conciliadora *ngSwitchCase="'CON'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)"></app-cfg-conciliadora>
              <app-cfg-bs2 *ngSwitchCase="'BS2'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)"></app-cfg-bs2>
              <app-cfg-paylivre *ngSwitchCase="'PLVRE'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)"></app-cfg-paylivre>
              <app-cfg-asaas *ngSwitchCase="'ASAAS'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)"></app-cfg-asaas>
              <app-cfg-tray *ngSwitchCase="'TRAY'" [isRender]="item.isOpen" [isSave]="item.isSave" [isConfigExists]="item.id !== null" (isValid)="setValidForm(item.lineId, $event)"></app-cfg-tray>
              <div class="row pb-4" *ngSwitchDefault>
                <div class="col-12">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Selecione uma API de referência para configurá-la!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12" *ngIf="!isLoadingIntegrationsList && integrationsList.length === 0">
      <div class="alert alert-xs alert-primary d-inline" role="alert">
        <i class="fas fa-info-circle"></i> Nenhuma integração cadastrada!
      </div>
    </div>
    <div class="col-sm-12" *ngIf="isLoadingIntegrationsList">
      <p-skeleton class="col-sm-12" width="100%" height="70px"></p-skeleton>
      <p-skeleton class="col-sm-12" width="100%" height="70px"></p-skeleton>
      <p-skeleton class="col-sm-12" width="100%" height="70px"></p-skeleton>
    </div>
  </div>
</div>
