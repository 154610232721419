<div class="h-100 d-flex align-items-end" *ngIf="format === 'chip'">
  <p-chip
    [label]="item.label"
    [styleClass]="'ml-2 custom-chip ' + ( item.active ? 'active' : '')"
    (click)="selectTab(item.value)"
    *ngFor="let item of options"
  ></p-chip>
</div>

<div class="custom-tab-change" *ngIf="format === 'text'">
  <h6 [ngClass]="{'active':  item.active}" (click)="selectTab(item.value)" *ngFor="let item of options">
    {{item.label}}
  </h6>
</div>