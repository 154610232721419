<div style="padding: 1.5rem !important;">
  <h4 class="font-weight-bold py-3 mb-4">
    Dashboard Compras
    <div class="text-muted text-tiny mt-1"><small class="font-weight-normal"
        [innerHTML]="formatarDataExtenso()"></small></div>
  </h4>
  <div class="row">
    <div class="d-flex col-xl-6 align-items-stretch">

      <div class="card d-flex w-100 mb-4">
        <div class="row no-gutters row-bordered row-border-light h-100">
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-chart-bars display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualNegocia | money}} </span> ({{
                  pedidosNegociacao.length }})</span><br>
                <small class="text-muted">Em Negociação</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-hourglass display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualCompra | money}} </span> ({{
                  pedidosCompra.length }})</span><br>
                <small class="text-muted">Compras</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-checkmark-circle display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualFinaliza | money}} </span> ({{
                  pedidosFinalizados.length }})</span><br>
                <small class="text-muted">Finalizado</small>
              </span>
            </span>

          </div>
          <div class="d-flex col-sm-6 col-md-4 col-lg-6 align-items-center">

            <span style="cursor: pointer" class="card-body media align-items-center text-dark" (click)="abrirPagina()">
              <i class="lnr lnr-cross-circle display-4 d-block text-primary"></i>
              <span class="media-body d-block ml-3">
                <span class="text-big"><span class="font-weight-bolder">R$ {{valorAtualRecusa| money}}</span> ({{
                  pedidosRecusados.length }})</span><br>
                <small class="text-muted">Recusado</small>
              </span>
            </span>

          </div>
        </div>
      </div>
    </div>
    <div class="d-flex col-xl-6 align-items-stretch">

      <div class="card w-100 mb-4">
        <div class="card-body" style="padding:1.5rem 1rem 0 1.5rem;">
          <button type="button" class="btn btn-sm btn-outline-primary icon-btn float-right p-0"
            (click)="atualizaOne()"><i class="ion ion-md-sync"></i></button>
          <div class="card-header-title text-muted" style="font-size: 13px;">Gráfico de Compras</div>
        </div>
        <div class="px-2 mt-4">
          <figure class="highcharts-figure">
            <highcharts-chart id="chart_3" style="width: 100%; height: 240px; display: block">
            </highcharts-chart>
          </figure>
        </div>
      </div>

    </div>
  </div>


  <div class="row" style="border: none;">

    <div class="col-md-7 col-lg-12 col-xl-3">
      <h6 class="card-header with-elements" style="background-color:#fff;">
        <div class="card-header-title" style="color: #a3a4a6 !important;">Compras Produtos no Mês</div>
        <div class="card-header-elements ml-auto">
          <button type="button" class="btn btn-sm btn-outline-primary icon-btn float-right p-0" (click)="atualizaTwo()">
            <i class="ion ion-md-sync"></i>
          </button>
        </div>
      </h6>
      <div class="py-4 px-3" style="background-color:#fff;height:300px;">
        <figure class="highcharts-figure">
          <highcharts-chart id="chart_1" style="width: 100%; height: 240px; display: block">
          </highcharts-chart>
        </figure>
      </div>
    </div>
    <div class="col-md-7 col-lg-12 col-xl-3">
      <h6 class="card-header with-elements" style="background-color:#fff;">
        <div class="card-header-title" style="color: #a3a4a6 !important;">Compras Serviços no Mês</div>
        <div class="card-header-elements ml-auto">
          <button type="button" class="btn btn-sm btn-outline-primary icon-btn float-right p-0" (click)="atualizaTwo()">
            <i class="ion ion-md-sync"></i>
          </button>
        </div>
      </h6>
      <div class="py-4 px-3" style="background-color:#fff;height:300px;">
        <figure class="highcharts-figure">
          <highcharts-chart id="chart_2" style="width: 100%; height: 240px; display: block">
          </highcharts-chart>
        </figure>
      </div>
    </div>

    <div class="col-md-5 col-lg-12 col-xl-6">



      <mat-tab-group class="overflow-tabs">
        <mat-tab label="Itens">
          <div class="card mb-4" style="border: none;">
            <table class="table card-table table-striped">
              <thead>
                <tr>
                  <th><strong>Itens + comprados</strong></th>
                  <th class="text-right"><strong>Qtde.</strong></th>
                  <th class="text-right"><strong>Vlr. Comprado</strong></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let it of itensTopFive">
                  <tr>
                    <td>{{ it.codItem }} - {{ it.descrItem }}</td>
                    <td class="text-right">{{ it.quantidade }}</td>
                    <td class="text-right">{{ it.valorTotal | money }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <span style="cursor: pointer; text-transform: capitalize;"
              class="card-footer d-block text-center text-dark small font-weight-semibold"
              (click)="detalharTopFiveItem()">Exibir +</span>
          </div>
        </mat-tab>

        <mat-tab label="Fornecedores">
          <div class="card mb-4" style="border: none;">
            <table class="table card-table table-striped">
              <thead>
                <tr>
                  <th><strong>Principais Fornecedores</strong></th>
                  <th class="text-right"><strong>Qtde.</strong></th>
                  <th class="text-right"><strong>Valor</strong></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let emp of empresasTopFive">
                  <tr>
                    <td>{{ emp.codEmpresa }} - {{ emp.nomeEmpresa }}</td>
                    <td class="text-right">{{ emp.quantidade }}</td>
                    <td class="text-right">{{ emp.valorTotal | money }}</td>
                  </tr>
                </ng-container>

              </tbody>
            </table>
            <span style="cursor: pointer; text-transform: capitalize;"
              class="card-footer d-block text-center text-dark small font-weight-semibold"
              (click)="detalharTopFiveEmp()">Exibir +</span>
          </div>
        </mat-tab>
      </mat-tab-group>



    </div>

  </div>
</div>
<p-dialog header="{{ tituloModal }}" [(visible)]="modalMaisInfo" [style]="{width: '1000px', height: '550px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true"
  (onHide)="resetModal()">
  <div *ngIf="modalMaisInfo">

    <div class="pull-right align-down-table">
      <button type="button" class="btn btn-link" (click)="dtItem1.exportCSV()"><i class="fas fa-file-excel excel"></i>
        Exportar para Excel</button>
    </div>
    <div class="clearfix"></div>

    <p-table styleClass="p-datatable-sm p-datatable-striped" #dtItem1 [columns]="colsItensTopFiveDetal"
      [value]="itensTopFiveDetal" [rows]="rows" [paginator]="false" [totalRecords]="itensTopFiveDetal.length"
      [pageLinks]="pageLinks" selectionMode="false" [scrollable]="itensTopFiveDetal.length > 15 ? true : false"
      scrollHeight="355px" [style]="{'width':'100%'}" (onFilter)="onFilterItem($event)"
      [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''">
      <ng-template pTemplate="header">
        <tr>

          <th class="text-center" style="width: 90px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'codItem'">
              <span class="text-overflow-dynamic-ellipsis">Cód. Item<p-sortIcon [field]="'codItem'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtItem1.filter($event.target.value, 'codItem', 'contains')">
          </th>
          <th class="text-left" style="width: 35%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrItem'">
              <span class="text-overflow-dynamic-ellipsis">Desc. Item <p-sortIcon [field]="'descrItem'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtItem1.filter($event.target.value, 'descrItem', 'contains')">
          </th>
          <th class="text-right" style="width: 82px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
              <span class="text-overflow-dynamic-ellipsis">Quantidade <p-sortIcon [field]="'quantidade'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtItem1.filter($event.target.value, 'quantidade', 'contains')">
          </th>

          <th class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
              <span class="text-overflow-dynamic-ellipsis">Valor Total <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtItem1.filter($event.target.value, 'valorTotal', 'contains')">
          </th>

          <th class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'porcentagem'">
              <span class="text-overflow-dynamic-ellipsis">Porc. (%) <p-sortIcon [field]="'porcentagem'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtItem1.filter($event.target.value, 'porcentagem', 'contains')">
          </th>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>

          <td class="text-center" style="width: 90px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codItem}}">{{rowData.codItem }}</span>
            </span>
          </td>
          <td class="text-left" style="width: 35%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descrItem}}">{{rowData.descrItem
                }}</span>
            </span>
          </td>
          <td class="text-right" style="width: 82px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis"
                matTooltip="{{rowData.quantidade}}">{{rowData.quantidade}}</span>
            </span>
          </td>
          <td class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis"
                matTooltip="{{rowData.valorTotal | money}}">{{rowData.valorTotal | money}}</span>
            </span>
          </td>
          <td class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.porcentagem}}">{{rowData.porcentagem
                }}</span>
            </span>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="5" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>


  </div>
</p-dialog>

<p-dialog header="{{ tituloModalEmp }}" [(visible)]="modalMaisInfoEmp" [style]="{width: '100px', height: '550px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true"
  (onHide)="resetModal()">
  <div *ngIf="modalMaisInfoEmp">

    <div class="pull-right align-down-table">
      <button type="button" class="btn btn-link" (click)="dtEmp.exportCSV()"><i class="fas fa-file-excel excel"></i>
        Exportar para Excel</button>
    </div>
    <div class="clearfix"></div>

    <p-table styleClass="p-datatable-sm p-datatable-striped" #dtEmp [columns]="colsEmpTopFiveDetal"
      [value]="empTopFiveDetal" [rows]="rows" [paginator]="false" [totalRecords]="empTopFiveDetal.length"
      [pageLinks]="pageLinks" selectionMode="false" [scrollable]="empTopFiveDetal.length > 15 ? true : false"
      scrollHeight="355px" [style]="{'width':'100%'}" (onFilter)="onFilterEmp($event)"
      [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''">
      <ng-template pTemplate="header">
        <tr>

          <th class="text-center" style="width: 90px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'codEmpresa'">
              <span class="text-overflow-dynamic-ellipsis">Cód. Fornec.<p-sortIcon [field]="'codEmpresa'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtEmp.filter($event.target.value, 'codEmpresa', 'contains')">
          </th>
          <th class="text-left" style="width: 35%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeEmpresa'">
              <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nomeEmpresa'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtEmp.filter($event.target.value, 'nomeEmpresa', 'contains')">
          </th>
          <th class="text-right" style="width: 82px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
              <span class="text-overflow-dynamic-ellipsis">Quantidade <p-sortIcon [field]="'quantidade'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtEmp.filter($event.target.value, 'quantidade', 'contains')">
          </th>

          <th class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
              <span class="text-overflow-dynamic-ellipsis">Valor Total <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtEmp.filter($event.target.value, 'valorTotal', 'contains')">
          </th>

          <th class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'porcentagem'">
              <span class="text-overflow-dynamic-ellipsis">Porc. (%) <p-sortIcon [field]="'porcentagem'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtEmp.filter($event.target.value, 'porcentagem', 'contains')">
          </th>


        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>

          <td class="text-center" style="width: 90px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codEmpresa}}">{{rowData.codEmpresa
                }}</span>
            </span>
          </td>
          <td class="text-left" style="width: 35%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeEmpresa}}">{{rowData.nomeEmpresa
                }}</span>
            </span>
          </td>
          <td class="text-right" style="width: 82px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis"
                matTooltip="{{rowData.quantidade}}">{{rowData.quantidade}}</span>
            </span>
          </td>
          <td class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis"
                matTooltip="{{rowData.valorTotal | money}}">{{rowData.valorTotal | money}}</span>
            </span>
          </td>
          <td class="text-right" style="width: 10%;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.porcentagem}}">{{rowData.porcentagem
                }}</span>
            </span>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="5" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>


  </div>
</p-dialog>