<script src="ng-pattern-restrict.js"></script>
<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <mat-card-content *ngIf='tCadastroMenu.length > 0'>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Cadastro</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="dt.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHideCardCadastroMenu()"
                  (click)="changeStateCadastroMenu()">
                  <span *ngIf="isCardCadastroMenu" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardCadastroMenu" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateCadastroMenu>
            <!--Table Cadastro Menu-->
            <p-table class="table-system" #dt [value]="tCadastroMenu" [rows]="rows" [paginator]="true"
              (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}">

              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 90px;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                      <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'id', 'contains')">
                  </th>
                  <th class="text-left" style="width: 90px;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                      <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'data'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'data', 'contains')">
                  </th>
                  <th class="text-left" style="width: 70%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'url'">
                      <span class="text-overflow-dynamic-ellipsis">URL <p-sortIcon [field]="'url'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'url', 'contains')">
                  </th>
                  <th class="text-left" style="width: 90px">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                        </span>
                    </span>
                    <p-multiSelect class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                      optionLabel="label" optionValue="value" [selectionLimit]="1" [filter]="false"
                      (onChange)="dt.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                  </th>
                  <th class="text-center" style="width: 90px; vertical-align: sub; padding-top: 8px">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis">Ação </span>
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-left" style="width: 90px">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.id }}"
                        style="user-select: all; cursor: text">{{ rowData.id }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 90px">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.data }}"
                        style="user-select: all; cursor: text">{{ rowData.data }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 70%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.url }}"
                        style="user-select: all; cursor: text">{{ rowData.url }}</span>
                    </span>
                  </td>
                  <td class="text-center">
                    <div>
                      <span matTooltip="{{rowData.ativo ? 'Sim' : 'Não'}}">
                        <p-checkbox [binary]="true" [disabled]="true" [(ngModel)]="rowData.ativo"></p-checkbox>
                      </span>
                    </div>
                  </td>
                  <td class="text-center" style="width: 90px">
                    <button type="button" style="padding: 0 !important;" (click)="openEditCadMenu(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 22px">edit</span>
                    </button>
                    <button type="button" style="padding: 0 !important;" (click)="deleteCadMenu(rowData)"
                      class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                      <span class="material-icons" style="font-size: 22px">delete</span>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL EDIT -->
<p-dialog header="Editar" [(visible)]="modalEdit" [style]="{width: '400px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <!-- <div class="form-group col-sm-12">
      <label>ID</label>
      <input class="form-control text-left" [(ngModel)]="idEdit" [disabled]="true">
    </div>
    <div class="form-group col-sm-12">
      <label>DATA</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dataEdit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"  [disabled]="true">
      </p-calendar>
    </div> -->
    <div class="form-group col-sm-12">
      <label>URL</label>
      <input class="form-control text-left" placeholder="Edite a url" [(ngModel)]="urlEdit">
    </div>
    <div class="p-field-checkbox">
      <mat-slide-toggle class="mr-1" [(ngModel)]="ativoEdit"  inputId="ativo">ATIVO</mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="editCadMenu();"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!-- MODAL NEW -->
<p-dialog header="Adicionar" [(visible)]="modalNew" [style]="{width: '400px'}" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>
  <div class="form-row">
    <!-- <div class="form-group col-sm-12">
      <label>ID</label>
      <input type="text" class="form-control text-left" placeholder="Insira o ID">
    </div>
    <div class="form-group col-sm-12">
      <label>DATA</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" >
      </p-calendar>
    </div> -->
    <div class="form-group col-sm-12">
      <label>URL</label>
      <input type="text" class="form-control text-left" placeholder="Insira a url" [(ngModel)]="urlNew">
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='createCadMenu();'><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>