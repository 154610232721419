<mat-card class="card-linnks margin-linnks">
	<mat-card-title>
		{{titlePrincipal}}
		<mat-card-subtitle>
			{{subtitle}}
		</mat-card-subtitle>
	</mat-card-title>
	<mat-card-content>
		<div class="box-content" *ngIf="!selected">
			<div class="row">
				<div class="col-sm-12">
					<h3 class="sub-title"><i class="fas fa-file-alt"> </i> Informações Conta</h3>
				</div>
			</div>
			<div class="form-row" *ngIf="movParteB">
				<div class="form-group col-sm-1">
					<label>Código</label>
					<input class="form-control" id="in" type="text" [(ngModel)]="movParteB.cod" />
				</div>
				<div class="form-group col-sm-5">
					<label>Descrição</label>
					<input id="in" class="form-control" type="text" [(ngModel)]="movParteB.descricao" />
				</div>
				<div class="form-group col-sm-1">
					<label>Saldo Inicial</label>
					<input id="in" class="form-control text-right" type="text" [(ngModel)]="movParteB.saldoInicial" money />
				</div>
				<div class="form-group col-sm-1">
					<label>Natureza</label>
					<p-dropdown class="type-p-dropdown" [options]="opcoes" [(ngModel)]="movParteB.natureza"></p-dropdown>
				</div>
				<div class="form-group col-sm-1">
					<label>Data Criação</label>
					<p-calendar class="type-p-calendar" [defaultDate]="maxDate" [readonlyInput]="true"
						[(ngModel)]="movParteB.data" dateFormat="dd/mm/yy"></p-calendar>
				</div>
				<div class="form-group col">
					<label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
					<button
						[disabled]="!movParteB.cod || !movParteB.descricao || !movParteB.saldoInicial || !movParteB.natureza || !movParteB.data"
						type="button" (click)="salvarConta()" class="btn btn-sm btn-info"><i class="fas fa-plus"></i>
						Adicionar</button>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12">
					<h3 class="sub-title"><i class="fas fa-bars"> </i> Contas Criadas</h3>
				</div>
			</div>
			<p-table class="table-system" #dt1 [value]="movParteBList" selectionMode="single" [rows]="rows"
				[paginator]="false" [scrollable]="true" scrollHeight="320px" [pageLinks]="pageLinks"
				[rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false">
				<ng-template pTemplate="header">
					<tr>
						<th class="text-center" style="width: 10%;">
							CÓDIGO
						</th>
						<th class="text-center" style="width: 10%;">
							DT. CRIAÇÃO
						</th>
						<th class="text-left" style="width: 50%;">
							DESCRIÇÃO
						</th>
						<th class="text-center" style="width: 10%;">
							NATUREZA
						</th>
						<th class="text-right" style="width: 10%;">
							SALDO INICIAL
						</th>
						<th class="text-center" style="width: 90px; vertical-align: sub;">
							AÇÃO
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr [pSelectableRow]="rowData">
						<td class="text-center" style="width: 10%;">
							<span class="text-overflow-dynamic-container">
								<span class="text-overflow-dynamic-ellipsis" (click)="editar(rowData)"
									matTooltip="{{rowData.cod}}">{{rowData.cod }}</span>
							</span>
						</td>
						<td class="text-center" style="width: 10%;">
							<span class="text-overflow-dynamic-container">
								<span class="text-overflow-dynamic-ellipsis" (click)="editar(rowData)"
									matTooltip="{{ rowData.data | timezone | date:'dd/MM/yyyy' }}">{{ rowData.data | timezone |
									date:'dd/MM/yyyy' }}</span>
							</span>
						</td>
						<td class="text-left" style="width: 50%;">
							<span class="text-overflow-dynamic-container">
								<span class="text-overflow-dynamic-ellipsis" (click)="editar(rowData)"
									matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
							</span>
						</td>
						<td class="text-center" style="width: 10%;">
							<span class="text-overflow-dynamic-container">
								<span class="text-overflow-dynamic-ellipsis" (click)="editar(rowData)"
									matTooltip="{{rowData.natureza}}">{{rowData.natureza }}</span>
							</span>
						</td>
						<td class="text-right" style="width: 10%;">
							<span class="text-overflow-dynamic-container">
								<span class="text-overflow-dynamic-ellipsis" (click)="editar(rowData)"
									matTooltip="{{rowData.saldoInicial | money}}">{{rowData.saldoInicial | money }}</span>
							</span>
						</td>
						<td class="text-center" style="width: 90px;">
							<span class="delete" matTooltip="Excluir!" (click)="excluirParteB(rowData)">
								<i class="fas fa-trash-alt"></i>
							</span>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-rowData>
					<tr>
						<td [attr.colspan]="6" class="spaced-alert">
							<div class="alert alert-xs alert-primary d-inline" role="alert">
								<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
							</div>
						</td>
					</tr>
				</ng-template>
			</p-table>
		</div>
		<div class="box-content" *ngIf="selected">
			<div class="row">
				<div class="col-sm-12">
					<button (click)="success()" type="button" class="btn btn-xs btn-primary pull-left d-inline-block mr-2 mt-2"
						matTooltip="Selecionar Contas"><i class="fas fa-arrow-left"></i></button>
					<h3 class="sub-title"><i class="fas fa-sitemap"> </i> Relacionamento Selecionado: <span
							style="color: #6b808c;">{{movParteB.cod}}</span></h3>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group col">
					<label>Descrição</label>
					<p>{{movParteB.descricao}}</p>
				</div>
			</div>
			<div class="form-row">
				<div class="form-group pull-left pl-1 pr-3">
					<label>Saldo Inicial</label>
					<p>{{movParteB.saldoInicial| money }}</p>
				</div>
				<div class="form-group pull-left">
					<label>Natureza</label>
					<p>{{movParteB.natureza}}</p>
				</div>
			</div>
			<p-accordion>
				<p-accordionTab header="Lançamentos Manuais">
					<p-table class="table-system" #dt2 [value]="movParteBMesesList" [rows]="rows" [paginator]="false"
						[scrollable]="true" scrollHeight="250px" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
						[style]="{'width':'100%'}" immutable="false"
						(onEditComplete)="atualizarSaldoUtilizadoParteB(movParteBMesesList)">
						<ng-template pTemplate="header">
							<tr>
								<th class="text-center" style="width: 10%;">
									DATA
								</th>
								<th class="text-left" style="width: 60%;">
									DESCRIÇÃO
								</th>
								<th class="text-center" style="width: 10%;">
									IND LCTO
								</th>
								<th class="text-right" style="width: 10%;">
									VALOR
								</th>
								<th class="text-center" style="width: 90px; vertical-align: sub;">
									AÇÃO
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-rowData>
							<tr>
								<td class="text-center" style="width: 10%;">
									<span class="text-overflow-dynamic-container">
										<span class="text-overflow-dynamic-ellipsis"
											matTooltip="{{rowData.data | timezone | date:'dd/MM/yyyy'}}">{{rowData.data | timezone |
											date:'dd/MM/yyyy'}}</span>
									</span>
								</td>
								<td class="text-left" style="width: 60%;">
									<span class="text-overflow-dynamic-container">
										<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}">{{
											rowData.descricao }}</span>
									</span>
								</td>
								<td pEditableColumn class="text-center" style="width:10%">
									<p-cellEditor>
										<ng-template pTemplate="input">
											<div class="form-row m-0">
												<div class="form-group col-sm-12 mb-0 p-0">
													<input class="form-control form-control-sm" type="text" [(ngModel)]="rowData.indLcto">
												</div>
											</div>
										</ng-template>

										<ng-template pTemplate="output">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="{{rowData.indLcto}}">{{rowData.indLcto}}</span>
											</span>
										</ng-template>
									</p-cellEditor>
								</td>
								<td pEditableColumn class="text-right" style="width:10%">
									<p-cellEditor>
										<ng-template pTemplate="input">
											<div class="form-row m-0">
												<div class="form-group col-sm-12 mb-0 p-0">
													<input class="form-control form-control-sm text-right" type="text"
														[(ngModel)]="rowData.valor">
												</div>
											</div>
										</ng-template>

										<ng-template pTemplate="output">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="{{rowData.valor | money}}">{{rowData.valor | money}}</span>
											</span>
										</ng-template>
									</p-cellEditor>
								</td>
								<td class="text-center" style="width: 90px;">
									<span class="delete" matTooltip="Excluir!" (click)="excluirParteBLcto(rowData)">
										<i class="fas fa-trash-alt"></i>
									</span>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="emptymessage" let-rowData>
							<tr>
								<td [attr.colspan]="5" class="spaced-alert">
									<div class="alert alert-xs alert-primary d-inline" role="alert">
										<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
									</div>
								</td>
							</tr>
						</ng-template>
					</p-table>
					<div class="form-row">
						<div class="form-group col mt-2">
							<button type="button" (click)="exibirLctoManual()" class="btn btn-sm btn-info"
								matTooltip="Adicionar Lançamento" matTooltipPosition="right"><i class="fas fa-plus"></i>
								Adicionar</button>
						</div>
					</div>


				</p-accordionTab>

				<p-accordionTab header="Demonstrativo de Saldos">
					<mat-tab-group (selectedTabChange)="onTabChange($event)">
						<mat-tab label="Anual">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt3 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Janeiro">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt4 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Fevereiro">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt5 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Março">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt6 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Abril">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt7 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Maio">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt8 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Junho">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt9 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Julho">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt10 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Agosto">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt11 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Setembro">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt12 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Outubro">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt13 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Novembro">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt14 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
						<mat-tab label="Dezembro">
							<p-table class="p-datatable-sm p-datatable-striped no-filter" #dt15 [value]="movParteBDemonstrativo"
								[rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
								[style]="{'width':'100%'}" immutable="false">
								<ng-template pTemplate="header">
									<tr>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO INICIAL"
													matTooltipPosition="above">SALDO INICIAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE A"
													matTooltipPosition="above">LANÇAMENTOS PARTE A</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="LANÇAMENTOS PARTE B"
													matTooltipPosition="above">LANÇAMENTOS PARTE B</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
										<th class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="SALDO FINAL"
													matTooltipPosition="above">SALDO FINAL</span>
											</span>
										</th>
										<th class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="NATUREZA"
													matTooltipPosition="above">NATUREZA</span>
											</span>
										</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-rowData>
									<tr>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_inicial | money  }}">{{rowData.saldo_inicial | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza }}">{{
													rowData.natureza }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_parte_a | money  }}">{{rowData.valor_parte_a | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_parte_a }}">{{
													rowData.natureza_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.valor_sem_parte_a | money  }}">{{rowData.valor_sem_parte_a | money
													}}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_sem_parte_a }}">{{
													rowData.natureza_sem_parte_a }}</span>
											</span>
										</td>
										<td class="text-right" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis"
													matTooltip="	{{rowData.saldo_final | money  }}">{{rowData.saldo_final | money }}</span>
											</span>
										</td>
										<td class="text-center" style="width: 10%;">
											<span class="text-overflow-dynamic-container">
												<span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.natureza_saldo_final }}">{{
													rowData.natureza_saldo_final }}</span>
											</span>
										</td>
									</tr>
								</ng-template>
								<ng-template pTemplate="emptymessage" let-rowData>
									<tr>
										<td [attr.colspan]="8" class="spaced-alert">
											<div class="alert alert-xs alert-primary d-inline" role="alert">
												<i class="fas fa-info-circle"></i> Nenhum registro encontrado!
											</div>
										</td>
									</tr>
								</ng-template>
							</p-table>
						</mat-tab>
					</mat-tab-group>
				</p-accordionTab>
			</p-accordion>
			<div class="form-row">
				<div class="form-group col">
					<button type="button" class="btn btn-primary" (click)="atualizarMapeamento()"
						matTooltip="Atualizar Mapeamento" matTooltipPosition="right"><i class="fas fa-refresh"></i>
						Atualizar</button>
				</div>
			</div>
		</div>
	</mat-card-content>

	<!--MODAL LANÇAMENTO MANUAL-->
	<p-dialog *ngIf="movParteBLcto" header="Incluir Lançamento Manual" [(visible)]="modalLctoManual"
		styleClass="modal-initial" [style]="{width: '800px'}" [modal]="true" appendTo="body" [closable]="true">
		<div class="form-row">
			<div class="form-group col-sm-3">
				<label>Mês do Lançamento</label>
				<p-dropdown class="type-p-dropdown" [options]="meses" [(ngModel)]="mesSelecionado"></p-dropdown>
			</div>
			<div class="form-group col-sm-3">
				<label>Valor do Lançamento</label>
				<input id="in" class="form-control text-right" type="text" [(ngModel)]="movParteBLcto.valor" money />
			</div>
			<div class="form-group col-sm-6">
				<label>Indicador do Lançamento</label>
				<p-dropdown class="type-p-dropdown" [options]="indicadorLcto" [(ngModel)]="movParteBLcto.indLcto"></p-dropdown>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col">
				<label>Histórico</label>
				<input id="in" class="form-control" type="text" [(ngModel)]="movParteBLcto.descricao" />
			</div>
		</div>
		<p-footer>
			<button type="button" class="btn btn-secondary" (click)="modalLctoManual=false"><i class="fas fa-undo"></i>
				Cancelar</button>
			<button type="button" class="btn btn-primary" (click)="salvarLancamentoManual()"><i class="fas fa-check"></i>
				Adicionar</button>
		</p-footer>
	</p-dialog>
</mat-card>