<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="ui-g" style="position: fixed; right: 15px;  bottom: 5px; max-width: 500px;">
      <div class="ui-g-12">
        <p-messages [value]="msgs" (click)="hideMessage()"></p-messages>
      </div>
    </div>

    <div class="clearfix"></div>

    <!--Alert no project -->
    <div class="row" *ngIf="projetoFavList.length === 0 && projetoList.length === 0">
      <div class="col">
        <div class="alert alert-sm alert-primary" role="alert">
          <i class="fas fa-info-circle"></i> Nenhum Projeto Inserido!
        </div>
      </div>
    </div>

    <!--Projetos / Favoritos -->
    <div class="row mt-3" *ngIf="projetoFavList.length !== 0 || projetoList.length !== 0">
      <div class="col-sm-10">
        <span class="primary-title" style="margin-left: 0px;">PROJETOS</span>
      </div>
      <div class="col-sm-2 text-right">
          <span class="primary-title">FAVORITOS</span>
      </div>
    </div>

    <!--Favoritos NEW -->
    <div class="row">
      <div class="col">
        <div class="panel panel-default panel-work" *ngFor="let proj of projetoFavList" [ngStyle]="{'border-left':'40px solid' + proj.corBg + ''}">
          <div class="panel-body">
            <div class="col-sm-11">
              <span class="titulo" (click)="buscarProjeto(false, proj.id)">{{ proj.nome }}</span>
            </div>
            <div class="col-sm-1">
              <span class="add-favoritos" (click)="favoritarProjeto(proj.id)">
                <i class="material-icons" style="color: yellow;" matTooltip='Remover Favorito!' matTooltipPosition="left">star</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Não Favoritos NEW -->
    <div class="row">
        <div class="col">
          <div class="panel panel-default panel-work" *ngFor="let proj of projetoList" [ngStyle]="{'border-left':'40px solid' + proj.corBg + ''}">
            <div class="panel-body">
              <div class="col-sm-11">
                <span class="titulo" (click)="buscarProjeto(false, proj.id)" matTooltip='{{ proj.nome }}'>{{ proj.nome }}</span>
              </div>
              <div class="col-sm-1">
                <span class="add-favoritos" (click)="favoritarProjeto(proj.id)">
                  <i class="material-icons"  style="color: #d0d0d0;" matTooltip='Adicionar aos Favoritos!' matTooltipPosition="left">star</i>
                </span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </mat-card-content>
</mat-card>

<!--Modal Criar Projeto -->
<p-dialog
  header="{{mcpTituloModal}}"
  [(visible)]="modalCriarProjeto"
  appendTo="body"
  [closable]="true"
  [modal]="true"
  [responsive]="true"
  styleClass="modal-initial"
  [style]="{width: '700px'}">
  <div *ngIf="modalCriarProjeto">
    <div class="form-row">
      <div class="form-group col-sm-10">
        <label>Nome</label>
        <input class="form-control" [(ngModel)]="mcpNome" />
      </div>
      <div class="form-group col-sm-2">
        <label>Key</label>
        <input class="form-control" [(ngModel)]="mcpKey" maxlength="4" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-10">
        <label>Departamento</label>
        <p-dropdown
        class="type-p-dropdown"
        [(ngModel)]="mcpDepartamento"
        [options]="mcpDepartamentoList"
        [filter]="true"
        ></p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>Cor</label>
        <input class="form-control" style="height: 33px; cursor: pointer;" matTooltip="Selecione a cor!" type="color" name="favcolor" [(ngModel)]="mcpCor" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Participantes</label>
        <p-multiSelect class="type-p-mult" [(ngModel)]="mcpUsuarios"
        (ngModelChange)="loadAdms()"
        [options]="mcpUsuariosList"
        defaultLabel="Selecione"
        [filter]="true"
        placeholder="{0} itens selecionados"
        selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Administradores</label>
        <p-multiSelect
          class="type-p-mult"
          [(ngModel)]="mcpAdms"
          [options]="mcpAdmsList"
          defaultLabel="Selecione"
          [filter]="true"
          placeholder="{0} itens selecionados"
          selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <mat-slide-toggle matTooltip="Inserir participantes do projeto em novos cartões"
        [(ngModel)]="addParticipantes">Inserir participantes</mat-slide-toggle>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 15px; bottom: 5px; max-width: 500px;">
      <div class="ui-g-12">
        <p-messages [value]="msgsModal" (click)="hideMessage()"></p-messages>
      </div>
    </div>
  </div>
  <p-footer>
      <button type="button" class="btn btn-secondary" (click)="refreshModal()"><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="criarProjeto()"><i class="fas fa-check"></i> Criar</button>
  </p-footer>
</p-dialog>

<!--Modal Buscar Projeto -->
<p-dialog
  header="buscar projeto"
  [(visible)]="modalBuscarProjeto"
  appendTo="body"
  [closable]="true"
  [modal]="true"
  [responsive]="true"
  styleClass="modal-initial"
  [style]="{width: '400px'}">
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione o Projeto</label>
      <p-dropdown
      class="type-p-dropdown"
      defaultLabel="Selecione"
      [(ngModel)]="mbpProjeto"
      [options]="mbpProjetoList"
      [filter]="true"
      ></p-dropdown>
    </div>
  </div>

  <div class="ui-g" style="position: fixed; right: 15px; bottom: 5px; max-width: 500px;">
    <div class="ui-g-12">
      <p-messages [value]="msgsModal" (click)="hideMessage()"></p-messages>
    </div>
  </div>

  <p-footer>
      <button type="button" class="btn btn-secondary" (click)="refreshModal()"><i class="fas fa-undo"></i> Cancelar</button>
      <button type="button" *ngIf="mbpProjeto!==null" class="btn btn-primary" (click)="buscarProjeto(true, mbpProjeto)"><i class="far fa-folder-open"></i> Abrir</button>
  </p-footer>
</p-dialog>

<!--Modal Desarquivar Projeto -->
<p-dialog
header="projetos arquivados"
[(visible)]="modalDesarquivar"
appendTo="body"
[closable]="true"
[modal]="true"
[responsive]="true"
styleClass="modal-initial"
[style]="{width: '400px'}">
<div class="form-row">
  <div class="form-group col-sm-12">
    <label>Selecione o projeto</label>
    <p-multiSelect
    class="type-p-mult"
    [(ngModel)]="mdOption"
    [options]="mdOptionList"
    defaultLabel="Selecione"
    [filter]="true"
    placeholder="{0} itens selecionados"
    selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
  </div>
</div>

<div class="ui-g" style="position: fixed; right: 15px; bottom: 5px; max-width: 500px;">
  <div class="ui-g-12">
    <p-messages [value]="msgsModal" (click)="hideMessage()"></p-messages>
  </div>
</div>

<p-footer>
    <button type="button" class="btn btn-secondary" (click)="refreshModal()"><i class="fas fa-undo"></i> Cancelar</button>
    <button type="button" class="btn btn-primary" *ngIf="mdOption.length>0" (click)="desarquivarProjetos()"><i class="far fa-folder-open"></i> Desarquivar</button>
</p-footer>
</p-dialog>