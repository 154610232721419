<p-dialog [(visible)]="isVisible" [style]="{width: '60vw'}" (onShow)="verifyInitial();getAccounts()"
  (onHide)="setVisibleState(false);resetValues()" [modal]="true" styleClass="modal-custom" [responsive]="true"
  [closable]="true" appendTo="body">

  <p-header>
    <label class="title">{{title}}</label>
  </p-header>

  <p-table class="table-system" #tableAccount dataKey="id" [value]="accountList" [rows]="15" [paginator]="true"
    [selectionMode]="isMultiple?'multiple':'single'" [lazy]="true" (onLazyLoad)="getAccounts($event)"
    [totalRecords]="totalAccount" [(selection)]="selectedAccount" appendTo="body"
    [style]="{width:'100%',height:'100%'}">

    <ng-template pTemplate="header">
      <tr>
        <th *ngIf="isMultiple" class="text-center" style="width: 40px;">
          <span class="check-all p-0">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </span>
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
            <span class="text-overflow-dynamic-ellipsis">Código <p-sortIcon [field]="'codCta'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableAccount.filter($event.target.value, 'codCta', 'contains')">
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
            <span class="text-overflow-dynamic-ellipsis">Descrição <p-sortIcon [field]="'descrCta'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableAccount.filter($event.target.value, 'descrCta', 'contains')">
        </th>
        <th class="text-center" style="width: 30%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'codNaturezaConta.descricao'">
            <span class="text-overflow-dynamic-ellipsis">Cód. natureza <p-sortIcon
                [field]="'codNaturezaConta.descricao'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableAccount.filter($event.target.value, 'codNaturezaConta.descricao', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
            <span class="text-overflow-dynamic-ellipsis">Nível<p-sortIcon [field]="'nivel'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableAccount.filter($event.target.value, 'nivel', 'contains')">
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="isMultiple?null:rowData" [class.tableRow]="!isMultiple">
        <td *ngIf="isMultiple" class="text-center">
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td class="text-center">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.codCta}}">{{rowData.codCta}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 6%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
          </span>
        </td>
        <td class="text-left" style="width: 7%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.codNaturezaConta.descricao}}">{{rowData.codNaturezaConta.descricao}}</span>
          </span>
        </td>
        <td class="text-right" style="width: 7%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text;"
              matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
          </span>
        </td>
      </tr>
    </ng-template>

  </p-table>

  <p-footer>
    <div class="footer">
      <div class="child">
        <ng-content></ng-content>
      </div>
      <div class="d-flex">
        <button type="button" class="btn btn-secondary" (click)="setVisibleState(false)">
          <i class="fas fa-undo"></i> Fechar
        </button>
        <button type="button" class="btn btn-primary" (click)="onSave()">
          <i class="fas fa-check"></i> {{saveButtonLabel}}
        </button>
      </div>
    </div>
  </p-footer>

</p-dialog>