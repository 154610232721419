<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{active: statusRemessas}"><a
                    (click)="showTelaRemRetor('TELA_1')">Remessas</a></li>
                <li class="list-inline-item" [ngClass]="{active: statusRetornos}"><a
                    (click)="showTelaRemRetor('TELA_2')">Retornos</a></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateRemRetor>
            <div [ngStyle]="statusRemessas ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusRemessas'}">

              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt [columns]="colsRemessa" [value]="remessasLista" [rows]="rows"
                [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="onSelectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-left" style="width: 10%;">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'id'">
                        CÓDIGO <p-sortIcon [field]="'id'"></p-sortIcon>
                      </span>
                      <input pInputText class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'id', 'contains')">
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'data_criacao'">
                        DATA CRIAÇÃO <p-sortIcon [field]="'data_criacao'"></p-sortIcon>
                      </span>
                      <input pInputText class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'data_criacao', 'contains')">
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'data_criacao'">
                        HORÁRIO <p-sortIcon [field]="'data_criacao'"></p-sortIcon>
                      </span>
                      <input pInputText class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'data_criacao', 'contains')">
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'qtd_pagamentos'">
                        QTD. PAGAMENTOS <p-sortIcon [field]="'qtd_pagamentos'"></p-sortIcon>
                      </span>
                      <input pInputText class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'qtd_pagamentos', 'contains')">
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'total_pago'">
                        TOTAL PAGO <p-sortIcon [field]="'total_pago'"></p-sortIcon>
                      </span>
                      <input pInputText class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'total_pago', 'contains')">
                    </th>
                    <th class="text-left">
                      <span class="overflow-ellipse text-center" [pSortableColumn]="'estatus'">
                        STATUS <p-sortIcon [field]="'estatus'"></p-sortIcon>
                      </span>
                      <input pInputText class="form-control form-control-sm" type="text" (input)="dt2.filter($event.target.value, 'estatus', 'contains')">
                    </th>
                    <th class="text-left" style="width: 50px;">
                      <span class="overflow-ellipse text-center">
                        AÇÃO
                      </span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect0(rowData)'
                          matTooltip="{{rowData.id}}">{{rowData.id}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)='onRowSelect0(rowData)' matTooltip="{{rowData.data_criacao | date:'dd/MM/yyyy' : '+000'}}">{{rowData.data_criacao | date:'dd/MM/yyyy' : '+000'}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)='onRowSelect0(rowData)' matTooltip="{{rowData.data_criacao | date:'HH:mm:ss' : '+000'}}">{{rowData.data_criacao | date:'HH:mm:ss' : '+000'}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect0(rowData)'
                          matTooltip="{{rowData.qtd_pagamentos}}">{{rowData.qtd_pagamentos}}</span>
                      </span>
                    </td>
                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect0(rowData)'
                          matTooltip="{{rowData.total_pago}}">{{rowData.total_pago | number : '1.2-2' : 'pt'}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect0(rowData)'
                          matTooltip="{{rowData.estatus}}">{{rowData.estatus}}</span>
                      </span>
                    </td>
                    <td class="text-center">
                      <span matTooltip="Baixar Remessa!" class="edit" (click)="baixar(rowData.id)">
                        <i class="fas fa-download"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div [ngStyle]="statusRetornos ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusRetornos'}">
              <!--START IMPORT FILE-->
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-file-upload"> </i> Importar Arquivo</h3>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-4 mb-1 mt-1" style="min-width: 525px;">
                  <label class="d-block">Selecione um arquivo</label>
                  <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                    <span class="input-group-append">
                      <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                              Remover Arquivo
                            </span> -->
                      <span class="input-group-text btn-file">
                        <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                          Arquivo</span>
                        <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i>
                          Selecionar Arquivo</span> <!-- Antes era Trocar Arquivo-->
                        <input type="file" #file (change)="fileChange()" multiple="multiple" />
                      </span>
                    </span>
                    <div class="form-control" data-trigger="fileinput">
                      <span class="fileinput-filename"></span>
                    </div>
                  </div>
                </div>
                <div class="form-group col-sm-2 mb-1 mt-1">
                  <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
                  <button type="button" class="btn btn-info" [disabled]="importDisabled" (click)="importar()"><i
                      class="fas fa-upload"></i> Importar</button>
                </div>
              </div>
              <!--END IMPORT FILE-->

              <!--TITLE RETORNOS-->
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title mt-3"><i class="fas fa-bars"> </i> Retornos</h3>
                </div>
              </div>

              <!-- Table New P-TABLE -->
              <p-table class="table-system" #dt2 [value]="retornosLista" [rows]="rows" [paginator]="true"
                (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                selectionMode="single" [style]="{'width':'100%'}"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" (onHeaderCheckboxToggle)="onSelectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>
                    <th class="text-center" style="width: 45%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_criacao'">
                        <span class="text-overflow-dynamic-ellipsis">Data Criação <p-sortIcon [field]="'data_criacao'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'data_criacao', 'contains')">
                    </th>
                    <th class="text-center" style="width: 45%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                        <span class="text-overflow-dynamic-ellipsis">Identificador <p-sortIcon [field]="'id'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt2.filter($event.target.value, 'id', 'contains')">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td class="text-center" style="width: 45%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          (click)='onRowSelect1(rowData)'>{{rowData.data_criacao | date:'dd/MM/yyyy' : '+000'}}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 45%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)='onRowSelect1(rowData)'
                          matTooltip="{{rowData.id}}">{{rowData.id}}</span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="3" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>


<!-- MODAL DOS PAGAMENTOS @octavio 07/04/2020 -->
<p-dialog header="Relação de Pagamentos" [(visible)]="modalRelacaoPagamentos" [style]="{width: '900px'}" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

  <!-- Table New P-TABLE -->
  <p-table class="table-system" #dt2 [value]="pagamentosLista" [rows]="rows" [paginator]="false"
    (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
    selectionMode="single" [scrollable]="true" scrollHeight="300px">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'pagamento_id'">
            <span class="text-overflow-dynamic-ellipsis">Pagamento ID <p-sortIcon [field]="'pagamento_id'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'pagamento_id', 'contains')">
        </th>
        <th style="width: 20%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
            <span class="text-overflow-dynamic-ellipsis">Fornecedor <p-sortIcon [field]="'fantasia'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'fantasia', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'n_documento'">
            <span class="text-overflow-dynamic-ellipsis">Nº DOCUMENTO <p-sortIcon [field]="'n_documento'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'n_documento', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vencimento'">
            <span class="text-overflow-dynamic-ellipsis">Data Vencimento <p-sortIcon [field]="'dt_vencimento'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'dt_vencimento', 'contains')">
        </th>
        <th class="text-right" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
            <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'valor', 'contains')">
        </th>
        <th *ngIf="retorno" class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'ocorrencia'">
            <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'ocorrencia'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt2.filter($event.target.value, 'ocorrencia', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.pagamento_id}}">{{rowData.pagamento_id}}</span>
          </span>
        </td>
        <td style="width: 20%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.n_documento}}">{{rowData.n_documento}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dt_vencimento}}">{{rowData.dt_vencimento
              | date : 'dd/MM/yyyy' : '+000'}}</span>
          </span>
        </td>
        <td class="text-right" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}">{{rowData.valor | number :
              '1.2-2' : 'pt'}}</span>
          </span>
        </td>
        <td *ngIf="retorno" class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.ocorrencia}}">{{rowData.ocorrencia}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="rowData?.length" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <button class="btn btn-secondary" (click)="modalRelacaoPagamentos = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>


<!-- MODAL DOS RECEBIMENTOS @octavio 01/03/2021 -->
<p-dialog header="Relação de Recebimentos" [(visible)]="modalRelacaoRecebimentos" [style]="{width: '900px'}"
  [modal]="true" styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

  <!-- migrado p-table anderson -->
  <p-table class="table-system" #dt3 [value]="pagamentosLista" [rows]="rows" [paginator]="false"
    (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
    selectionMode="single" [scrollable]="true" scrollHeight="300px">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'pagamento_id'">
            <span class="text-overflow-dynamic-ellipsis">Pagamento ID <p-sortIcon [field]="'pagamento_id'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'pagamento_id', 'contains')">
        </th>
        <th style="width: 20%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
            <span class="text-overflow-dynamic-ellipsis">Fornecedor <p-sortIcon [field]="'fantasia'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'fantasia', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'n_documento'">
            <span class="text-overflow-dynamic-ellipsis">Nº DOCUMENTO <p-sortIcon [field]="'n_documento'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'n_documento', 'contains')">
        </th>
        <th class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dt_vencimento'">
            <span class="text-overflow-dynamic-ellipsis">Data Vencimento <p-sortIcon [field]="'dt_vencimento'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'dt_vencimento', 'contains')">
        </th>
        <th class="text-right" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
            <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'valor', 'contains')">
        </th>
        <th *ngIf="retorno" class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'ocorrencia'">
            <span class="text-overflow-dynamic-ellipsis">OCORRÊNCIA <p-sortIcon [field]="'ocorrencia'"></p-sortIcon>
              </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="dt3.filter($event.target.value, 'ocorrencia', 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData">
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.pagamento_id}}">{{rowData.pagamento_id}}</span>
          </span>
        </td>
        <td style="width: 20%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.n_documento}}">{{rowData.n_documento}}</span>
          </span>
        </td>
        <td class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dt_vencimento}}">{{rowData.dt_vencimento
              | date : 'dd/MM/yyyy' : '+000'}}</span>
          </span>
        </td>
        <td class="text-right" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}">{{rowData.valor | number :
              '1.2-2' : 'pt'}}</span>
          </span>
        </td>
        <td *ngIf="retorno" class="text-center" style="width: 10%;">
          <span class="text-overflow-dynamic-container">
            <span class="text-overflow-dynamic-ellipsis"
              matTooltip="{{rowData.ocorrencia}}">{{rowData.ocorrencia}}</span>
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="rowData?.length" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-footer>
    <button class="btn btn-secondary" (click)="modalRelacaoRecebimentos = false;"><i class="fas fa-undo"></i>
      Fechar</button>
  </p-footer>
</p-dialog>