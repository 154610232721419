<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">

      <mat-tab label="Campanha por carteira">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-address-card"></i> Campanha
                </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
                <!-- <p-dropdown class="type-p-dropdown" [filter]="false" [options]="aprovadoresTecnicos" -->
                    <!-- placeholder="Selecione o Usuário" [(ngModel)]="aprovId" (onChange)="buscarUsuariosAprovador()"></p-dropdown> -->
                  <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCampanhas"
                placeholder="Selecione a carteira" [(ngModel)]="campanhaId" (onChange)="buscarCarteiras()"></p-dropdown>

            </div>
          </div>
            <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-wallet"></i>Carteira</h3>
            </div>
            </div>
            <div class="row">
            <div class="col-sm-10">
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Disponíveis" targetHeader="Carteiras Relacionados" [source]="listaCarteirasSemRelacionamento"
                  [target]="listaCarteirasRelacionadas" [dragdrop]="true" [responsive]="true" filterBy="carteiraNome"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-campanhaCarteira pTemplate="item">{{campanhaCarteira.carteiraNome}} </ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>


  <!--VERIFICAR COM MARCELO!-->
    <!-- Inicio Model Alternar periodo
    <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true" styleClass="lks-modal"
    [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">

      <div class="form-row">
        <div class="form-group col-sm-4">
            <label>Data Inicial</label>
            <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" (onSelect)="changeDataIni($event)"  >

          </p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
          [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
      </div>


    <p-footer>
      <button  type="button" class="btn btn-secondary" (click)='modalPeriodo=false; dataIni = null; dataFin = null'><i class="fas fa-undo"></i> Cancelar</button>
      <button  type="button" class="btn btn-primary" (click)="buscarCarteiras();modalPeriodo=false;"><i class="fas fa-pencil-alt"></i> Alterar</button>
    </p-footer>
  </p-dialog>
  Final model Alternar periodo -->

</mat-card>
