<div>
  <div class="header-box">
    <div class="header-box-content" style="padding: 1rem !important;">
      <ul class="list-inline filter-menu filter-card-header mt-0">
        <li class="list-inline-item">
          <a style="padding: 10px 0 20px !important;">{{this.name}}</a>
        </li>
      </ul>

      <div class="actions-card">
        <div class="containerCustom">
          <button class="btnCustom-green" *ngFor="let button of this.buttons" (click)="button.action(button.key)">
            <span class="material-icons">{{button.icon}}</span>
            <div>{{button.label}}</div>
          </button>

          <span *ngIf="closable" (click)="closeDynamicTable()" matTooltip="Fechar" class="material-icons"
            style="color: #cccccc !important; cursor: pointer;">close
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="dynamic-table">
    <p-table class="table-border" #dt3 [value]="this.content" [(selection)]="selectedRows" dataKey="index"
      [scrollable]="true" scrollHeight="1000px" [paginator]="this.lazy" [rowsPerPageOptions]="rowsPerPage" [rows]="rows"
      [pageLinks]="pageLinks" [lazy]="this.lazy" (onLazyLoad)="emitLazyEvent($event)"
      [totalRecords]="this.totalRecords">
      <!-- [frozenColumns]="this.lockedColumns"> -->
      <!-- [style]="column.style.widthInPixels ? 'width: ' + column.style.widthInPixels + 'px;' : 'width: 10%;'" -->
      <ng-template pTemplate="header">
        <tr>
          <th *ngIf="selectable" class="text-center tableHeaderCheckbox">
            <span class="check-all p-0">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </span>
          </th>
          <th [class]="'text-' + column.style.align" *ngFor="let column of this.columns">

            <span class="text-overflow-dynamic-container" [pSortableColumn]="column.key">
              <span class="text-overflow-dynamic-ellipsis"> {{column.name}}
                <p-sortIcon [field]="column.key"></p-sortIcon>
              </span>
            </span>
            <!-- <button class="button-lock" type="button" [disabled]="lockedColumns.length>= 2"
                (click)="toggleLock(column,false,index)">
                <i class="material-icons">{{lockedColumns.length >= 2 ? 'lock': 'lock_open'}}</i>
              </button> -->

            <input autocomplete="off" class="form-control form-control-sm" type="text"
              (input)="dt3.filter($event.target.value, column.targetKey, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8"><i class="fas fa-info-circle"></i> Nenhum registro encontrado.</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td *ngIf="selectable" class="text-center tableCheckbox">
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>

          <td [class]="'text-' + column.style.align" pEditableColumn *ngFor="let column of this.columns;">

            <span matTooltip="Este valor não pode ser alterado" *ngIf="column.type.readOnly.active">{{item[column.key]}}
            </span>

            <p-dropdown *ngIf="column.type.selectItem.active"
              [disabled]="(item[column.key] === undefined && column.type.selectItem.disableOnUndefined == true) || column.type.selectItem.readOnly? true: false"
              class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
              [options]="column.type.selectItem.options" [(ngModel)]="item[column.key]"
              placeholder="Selecione uma opção">
            </p-dropdown>


            <p-dropdown *ngIf="column.type.multiOptions.active"
              [disabled]="(item[column.key] === undefined && column.type.selectItem.disableOnUndefined == true) || column.type.selectItem.readOnly? true: false"
              class="type-p-dropdown" appendTo="body" panelStyleClass="list-table" [filter]="true"
              [options]="column.type.multiOptions.options[item[column.type.multiOptions.key]].options"
              [(ngModel)]="item[column.key]" placeholder="Selecione uma opção">
            </p-dropdown>

            <!-- <span (click)="reverse(item.index, column.key)"
                *ngIf="column.type.selectItem.invert.active && item[column.key] !== undefined"
                [matTooltip]="column.type.selectItem.invert.tooltipLabel" matTooltipPosition="above"
                class="material-icons reverse-button">
                swap_horizontal_circle
              </span> -->

            <div *ngIf="column.type.modal.active" class="modal-option">
              <button class="btnCustom-table" (click)="setEditableState(item.index, column.key)">
                <span class="material-icons">edit</span>

                <div
                  *ngIf="!isString(this.ObjectState[item.index][column.key]) && !item[column.type.modal.labelByValue]">
                  {{column.type.modal.label}}
                </div>

                <div *ngIf="isString(this.ObjectState[item.index][column.key])">
                  {{this.ObjectState[item.index][column.key]}}
                </div>

                <div
                  *ngIf="column.type.modal.labelByValue && item[column.type.modal.labelByValue] && !isString(this.ObjectState[item.index][column.key])">
                  {{item[column.type.modal.labelByValue]}}
                </div>

              </button>

              <span *ngIf="column.obrigatory && !this.ObjectState[item.index][column.key]" matTooltip="Não configurado"
                matTooltipPosition="above" style="color: #fabc02;" class="material-icons">error
              </span>

              <span *ngIf="this.ObjectState[item.index][column.key]" matTooltip="Configurado" matTooltipPosition="above"
                style="color: #00BFA5;" class="material-icons">
                check_circle
              </span>

              <span *ngIf="!column.obrigatory && !this.ObjectState[item.index][column.key]"
                matTooltip="Configuração opcional" matTooltipPosition="above" style="color: #888888;"
                class="material-icons">
                do_disturb_on
              </span>
            </div>

            <p-checkbox *ngIf="column.type.checkbox.active" [(ngModel)]="item[column.key]" value="true">
              {{column.type.checkbox.label}}
            </p-checkbox>

            <p-cellEditor *ngIf="column.type.input.active">

              <ng-template pTemplate="input">
                <input [align]="column.style.align || 'center'" autocomplete="off"
                  *ngIf="this.isNumber(item[column.key])" type="number" [(ngModel)]="item[column.key]">
                <input [align]="column.style.align || 'center'" autocomplete="off"
                  *ngIf="!this.isNumber(item[column.key])" type="text" [(ngModel)]="item[column.key]">
              </ng-template>

              <ng-template pTemplate="output">
                {{item[column.key] != null && item[column.key] != undefined ? item[column.key] : 'Clique para editar'}}
              </ng-template>

            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- Replica -->
<p-dialog header="Replicar valores" [(visible)]="modalReplic" [style]="{width: '40vw', heigth: '40vh'}" [modal]="true"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="containerCustom" style="margin-bottom: 20px;">
    <div class="buttons">
      <button class="btnCustom-green" (click)="this.addEditableColumn()">
        <span class="material-icons">add</span>
        <div>Adicionar</div>
      </button>
    </div>
  </div>
  <p-table class="table-border" #dt2 [value]="this.selectedColumns" dataKey="index">
    <!-- [frozenColumns]="this.lockedColumns"> -->

    <ng-template pTemplate="header">
      <tr class="row-replic" style="height: 30px;">
        <th style="padding: 10px !important;" class="text-left"
          *ngFor="let label of this.modalReplicTHLabels; let i = index" class="column-replic text-left" [id]="i">
          {{label}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-i="i">
      <tr class="row-replic">
        <td>
          <span class="overflow-ellipse text-left">
            <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="list-table"
              [options]="this.availableReplicColumns" optionLabel="name" [(ngModel)]="item.column"
              placeholder="Selecione">
            </p-dropdown>
          </span>
        </td>

        <td>
          <span class="overflow-ellipse text-left" *ngIf="item.column">
            <input autocomplete="off" *ngIf="item.column.type.input.active" class="form-control"
              [(ngModel)]="item.columnValue">

            <p-dropdown *ngIf="item.column.type.selectItem.active" class="type-p-dropdown" appendTo="body"
              panelStyleClass="list-table" [options]="item.column.type.selectItem.options" placeholder="Selecione"
              [filter]="true" [(ngModel)]="item.columnValue">
            </p-dropdown>

            <p-dropdown *ngIf="item.column.type.multiOptions.active" class="type-p-dropdown" appendTo="body"
              panelStyleClass="list-table" [options]="item.column.type.multiOptions.replicOptions" [group]="true"
              [filter]="true" placeholder="Selecione" [(ngModel)]="item.columnValue">
              <ng-template let-group pTemplate="group">
                <span>{{group.label}}</span>
              </ng-template>
            </p-dropdown>

            <p-checkbox *ngIf="item.column.type.checkbox.active" value="true" [(ngModel)]="item.columnValue">
              {{item.column.type.checkbox.label}}
            </p-checkbox>
          </span>
          <div style="width: 100%;" *ngIf="!item.column">
            <span>Nenhuma coluna selecionada</span>
          </div>
        </td>

        <td>
          <button (click)="removeEditableColumn(item)" class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha"
            matTooltipPosition="above">
            <span class="material-icons" style="font-size: 18px">delete</span>
          </button>
        </td>

      </tr>
    </ng-template>
  </p-table>

  <div class="containerCustom" style="margin-top: 20px;">
    <div class="buttons">
      <button class="btnCustom-green" (click)="this.applyValueInAllRows()">
        <span class="material-icons">done_all</span>
        <div>Aplicar</div>
      </button>
    </div>
  </div>
</p-dialog>

<!-- Filtros -->
<p-dialog *ngIf="lazy" header="Filtrar" [(visible)]="modalFilters" [modal]="true" [responsive]="true" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="initialDate" [(ngModel)]="initialDate" dateFormat="dd/mm/yy" [locale]="pt_BR"></p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
        [defaultDate]="finalDate" [(ngModel)]="finalDate" dateFormat="dd/mm/yy" [locale]="pt_BR"></p-calendar>
    </div>
  </div>
  <p-footer class="containerCustom">
    <div class="buttons">
      <button class="btnCustom-green" (click)="this.applyFilters()">
        <span class="material-icons">done_all</span>
        <div>Aplicar</div>
      </button>
    </div>
  </p-footer>
</p-dialog>
