<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      PERÍODO SELECIONADO {{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à
      {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="row" *ngIf="!exibirPedido">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item" [ngClass]="{ active: statusNegociacao }">
                  <a (click)="showTelaPedidoCompra('TELA_1')">
                    Em Negociação ({{ pedidosNegociacao.length }})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{ active: statusCompras }">
                  <a (click)="showTelaPedidoCompra('TELA_2')">
                    {{ tipo == 0 ? "Compras" : "Vendas" }} ({{
                    pedidosCompraVenda.length
                    }})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{ active: statusRecusado }">
                  <a (click)="showTelaPedidoCompra('TELA_3')">
                    Recusado ({{ pedidosRecusados.length }})
                  </a>
                </li>

                <li class="list-inline-item" [ngClass]="{ active: statusFinalizado }">
                  <a (click)="showTelaPedidoCompra('TELA_4')">
                    Finalizado ({{ pedidosFinalizados.length }})
                  </a>
                </li>
              </ul>

              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]="currentStatePedidoProduto">
            <div [ngStyle]="
                statusNegociacao ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusNegociacao' }">
              <p-table class="table-system" #dt10 [value]="pedidosNegociacao" [columns]="cols" [rows]="rows"
                [paginator]="true" [totalRecords]="pedidosNegociacao.length" (onFilter)="onFilterPedido($event)"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                [(selection)]="clearArray" (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)=" dt10.filter($event.target.value,'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSAO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'dt_faturamento','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          CÓDIGO <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'codigo', 'contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'codigoControle','contains')" />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'pedidoTipo', 'contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value, 'objetoRms', 'contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'valorTotalPorItensFormatado','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'valorSaldoFormatado','contains') " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO CRIAÇÃO
                          <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'usuarioCriacao','contains') " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO
                          <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'usuario','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS
                          <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'pedidoStatus','contains')" />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt10.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>
                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}
                        ">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$ {{ rowData.valorTotalPorItensFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusCompras ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusCompras' }">
              <p-table class="table-system" #dt20 [value]="pedidosCompraVenda" [columns]="cols" [rows]="rows"
                [paginator]="true" [totalRecords]="pedidosCompraVenda.length" (onFilter)="onFilterPedido($event)"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                [(selection)]="clearArray" (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)=" dt20.filter($event.target.value,'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSÃO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'dt_faturamento','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          CÓDIGO <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value, 'codigo', 'contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'codigoControle','contains') " />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'objetoRms','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorTotalPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorTotalPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'valorTotalPorItensFormatado','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'valorSaldoFormatado','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO
                          <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'usuario','contains') " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO CRIAÇÃO
                          <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'usuarioCriacao','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS
                          <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'pedidoStatus','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt20.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$ {{ rowData.valorTotalPorItensFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>


                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>


                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro
                        Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusRecusado ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusRecusado' }">
              <p-table class="table-system" #dt30 [value]="pedidosRecusados" [columns]="cols" [rows]="rows"
                [paginator]="true" [totalRecords]="pedidosRecusados.length" (onFilter)="onFilterPedido($event)"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                [(selection)]="clearArray" (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)=" dt30.filter($event.target.value,'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSÃO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'dt_faturamento','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          CÓDIGO <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value, 'codigo', 'contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'codigoControle','contains') " />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'objetoRms','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorTotalPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorTotalPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'valorTotalPorItensFormatado','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'valorSaldoFormatado','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO
                          <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'usuario','contains') " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO CRIAÇÃO
                          <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'usuarioCriacao','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS
                          <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'pedidoStatus','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt30.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$
                          {{
                          rowData.valorTotalPorItensFormatado | money
                          }}</span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 15 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro
                        Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>

            <div [ngStyle]="
                statusFinalizado ? { display: 'block' } : { display: 'none' }
              " [ngClass]="{ 'fade-in': 'statusFinalizado' }">
              <p-table class="table-system" #dt40 [value]="pedidosFinalizados" [columns]="cols" [rows]="rows"
                [paginator]="true" [totalRecords]="pedidosFinalizados.length" (onFilter)="onFilterPedido($event)"
                [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%' }"
                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
                [(selection)]="clearArray" (onHeaderCheckboxToggle)="selectAll($event)">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center tableHeaderCheckbox">
                      <span class="check-all p-0">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_entrega_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA ENTREGA
                          <p-sortIcon field="dt_entrega_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)=" dt40.filter($event.target.value,'dt_entrega','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_emissao_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          DATA EMISSÃO
                          <p-sortIcon field="dt_emissao_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'dt_emissao','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="dt_faturamento_timestamp">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{ tipo == 0 ? "DATA ENTRADA" : "DATA SAÍDA" }}
                          <p-sortIcon field="dt_faturamento_timestamp"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'dt_faturamento','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigo">
                        <span class="text-overflow-dynamic-ellipsis">
                          CÓDIGO <p-sortIcon field="codigo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value, 'codigo', 'contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="codigoControle">
                        <span class="text-overflow-dynamic-ellipsis">
                          {{
                          dblinkedSessionService?.empresa?.id === 158
                          ? "JIRA"
                          : "CONTROLE"
                          }}
                          <p-sortIcon field="codigoControle"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'codigoControle','contains') " />
                    </th>

                    <th class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="participante">
                        <span class="text-overflow-dynamic-ellipsis">
                          NOME
                          <p-sortIcon field="participante"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'participante','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoTipo">
                        <span class="text-overflow-dynamic-ellipsis">
                          TIPO <p-sortIcon field="pedidoTipo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'pedidoTipo','contains')" />
                    </th>

                    <th class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="objetoRms">
                        <span class="text-overflow-dynamic-ellipsis">
                          Objeto <p-sortIcon field="objetoRms"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'objetoRms','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorTotalPorItens">
                        <span class="text-overflow-dynamic-ellipsis">
                          VALOR
                          <p-sortIcon field="valorTotalPorItens"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'valorTotalPorItensFormatado','contains')" />
                    </th>

                    <th class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="valorSaldo">
                        <span class="text-overflow-dynamic-ellipsis">
                          SALDO <p-sortIcon field="valorSaldo"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'valorSaldoFormatado','contains')" />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuario">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO
                          <p-sortIcon field="usuario"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'usuario','contains') " />
                    </th>

                    <th class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="usuarioCriacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          USUÁRIO CRIAÇÃO
                          <p-sortIcon field="usuarioCriacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'usuarioCriacao','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%">
                      <span class="text-overflow-dynamic-container" pSortableColumn="pedidoStatus">
                        <span class="text-overflow-dynamic-ellipsis">
                          STATUS
                          <p-sortIcon field="pedidoStatus"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'pedidoStatus','contains') " />
                    </th>

                    <th class="text-center" style="width: 15%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container" pSortableColumn="statusAprovacao">
                        <span class="text-overflow-dynamic-ellipsis">
                          Status aprovação <p-sortIcon field="statusAprovacao"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt40.filter($event.target.value,'statusAprovacao','contains')" />
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_entrega }}">
                          {{ rowData.dt_entrega }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_emissao }}">
                          {{ rowData.dt_emissao }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.dt_faturamento }}">
                          {{ rowData.dt_faturamento }}
                        </span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigo }}">
                          {{ rowData.codigo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.codigoControle }}">
                          {{ rowData.codigoControle }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 20%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.participante }}">
                          {{ rowData.participante }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoTipo }}">
                          {{ rowData.pedidoTipo }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.objetoRms }}">
                          {{ rowData.objetoRms }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{
                            rowData.valorTotalPorItensFormatado | money
                          }}">
                          R$ {{ rowData.valorTotalPorItensFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-right" style="width: 10%">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.valorSaldoFormatado | money }}">
                          R$ {{ rowData.valorSaldoFormatado | money }}
                        </span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuario}}">{{rowData.usuario }}</span>
                      </span>
                    </td>

                    <td class="text-left" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{rowData.usuarioCriacao}}">{{rowData.usuarioCriacao }}</span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.pedidoStatus }}">
                          {{ rowData.pedidoStatus }}
                        </span>
                      </span>
                    </td>

                    <td class="text-center" style="width: 15%;" *ngIf="flowUses">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" (click)="visualizarPedido(rowData, true)"
                          matTooltip="{{ rowData.statusAprovacao }}">
                          {{ rowData.statusAprovacao }}
                        </span>
                        <span class="action-fade" matTooltip="Vizualizar Escrituração" (click)="previewPedido(rowData)">
                          <i class="fas fa-search"></i>
                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="footer">
                  <tr>
                    <td [attr.colspan]="flowUses ? 9 : 8">TOTAL DO PERÍODO:</td>

                    <td class="text-right">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text"
                          matTooltip="R$ {{ totalValue | money }}">
                          R$ {{ totalValue | money }}
                        </span>
                      </span>
                    </td>
                    <td [attr.colspan]="flowUses ? 6 : 4"></td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="flowUses ? 16 : 13" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i>
                        Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- vir -->
    <div class="box-content {{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="exibirPedido && infoParticipante">
      <!-- cabeçalho -->
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-info-circle"></i> Informações
            {{ tipo == 0 ? "Fornecedor" : "Cliente" }}
          </h3>
        </div>
      </div>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th style="width: 180px;">
              <strong>PEDIDO DE {{tipo == 0 ? 'COMPRA' : 'VENDA' }}: </strong>
            </th>
            <th>
              <strong> {{pedido.codigo}} </strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>{{tipo == 0 ? 'Fornecedor:' : 'Cliente:' }} </strong>
            </td>
            <td>
              <div *ngIf="!(infoParticipante[0].cnpj === null)">
                {{infoParticipante[0].nome}} - CNPJ: {{ infoParticipante[0].cnpj | cnpj}}
                <span class="edit" matTooltip="Alterar Participante" (click)="openModal()">
                  <i class="fas fa-edit"></i>
                </span>
              </div>
              <div *ngIf="!(infoParticipante[0].cpf === null)">
                {{infoParticipante[0].nome}} - CPF: {{ infoParticipante[0].cpf | cpf}}
                <span class="edit" matTooltip="Alterar Participante" (click)="openModal()">
                  <i class="fas fa-edit"></i>
                </span>
              </div>
              <div *ngIf="(infoParticipante[0].cpf === null) && (infoParticipante[0].cnpj === null)">
                {{infoParticipante[0].nome}} (Exterior)
                <span class="edit" matTooltip="Alterar Participante" (click)="openModal()">
                  <i class="fas fa-edit"></i>
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <strong>ENDEREÇO: </strong>
            </td>
            <td>
              {{infoParticipante[0].logradouro}}, {{infoParticipante[0].numero}} -
              {{infoParticipante[0].cidade}}/{{infoParticipante[0].uf}}
            </td>
          </tr>
          <tr>
            <td>
              <strong>CONTATO: </strong>
            </td>
            <td>
              {{this.ExisteParticipanteContato(this.infoParticipante[0])}}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-info-circle"></i> Informações Iniciais
          </h3>
          <!-- 1a. linha das Iniciais -->
          <div class="form-row align-items-end font-comeco">
            <div class="form-group col-sm-3">
              <label>STATUS<span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="false" [options]="opcoesStatusPedido"
                placeholder="Selecione o Status" [(ngModel)]="pedido.pedidoStatus.id" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>MODELO<span class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.modNf.id" [options]="opcoesModelo"
                (onChange)="selecionarModelo()" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>NÚMERO<span *ngIf="
                    pedido.pedidoStatus.id !== 1 ||
                    (pedido.pedidoStatus.id === 1 && pedido.modNf.id === 22)
                  " class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [(ngModel)]="pedido.numNf" [disabled]="
                  pedido.pedidoStatus.id !== 12 && pedido.modNf.id === 22
                " placeholder="{{
                  pedido.pedidoStatus.id !== 12 && pedido.modNf.id === 22
                    ? 'Gerado Automaticamente'
                    : ''
                }}" />
            </div>
            <div class="form-group col-sm-1">
              <label>SÉRIE<span *ngIf="pedido.pedidoStatus.id !== 1" class="obrigatorio">*</span></label>
              <input class="form-control" type="text" maxlength="3" [(ngModel)]="pedido.serie" />
            </div>
            <div class="form-group col-sm-1">
              <label>DATA ENTREGA<span style="color: #fff">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtEntrega" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030" (ngModelChange)="pagamentoForma()">
              </p-calendar>
            </div>
            <div class="form-group col-sm-1">
              <label>DATA EMISSÃO<span class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtEmissao" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030" (ngModelChange)="pagamentoForma()">
              </p-calendar>
            </div>
            <div class="form-group col-sm-1" *ngIf="pedido.pedidoTipo.id === 3">
              <label>DATA ENTRADA<span *ngIf="
                    pedido.pedidoTipo.id === 3 && pedido.pedidoStatus.id !== 1
                  " class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030" (ngModelChange)="pagamentoForma()">
              </p-calendar>
            </div>
            <div class="form-group col-sm-1" *ngIf="pedido.pedidoTipo.id === 1">
              <label>DATA SAÍDA<span *ngIf="
                    pedido.pedidoTipo.id === 1 && pedido.pedidoStatus.id !== 1
                  " class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030" (ngModelChange)="pagamentoForma()">
              </p-calendar>
            </div>
          </div>
          <!-- 2a. linha das Iniciais -->
          <div class="form-row">
            <div class="form-group col-sm-1">
              <label *ngIf="dblinkedSessionService.empresa.id === 158">Nº JIRA</label>
              <label *ngIf="dblinkedSessionService.empresa.id !== 158">CÓD. CARD</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.codigoControle" />
            </div>
            <div class="form-group col-sm-1">
              <label>ORÇAMENTO</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.codigoOrcamento" maxlength="150" />
            </div>
            <div class="form-group col-sm-1">
              <label>MOEDA</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesMoeda"
                placeholder="Selecione a Moeda" [(ngModel)]="pedido.moeda.id"
                (ngModelChange)="retornaCodigoMoeda(pedido.moeda.id)" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-1">
              <label>TAXA</label>
              <input class="form-control" type="text" [disabled]="pedido.converterTaxa || pedido.moeda.id === 1"
                [(ngModel)]="pedido.txMoeda" />
            </div>
            <div class="form-group col-sm-1">
              <label>INCOTERM</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesIncoterm"
                placeholder="Selecione o Incoterm" [(ngModel)]="pedido.tipoIncoterm.id" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>MÉTODO ENVIO</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.metodoEnvio" maxlength="150" />
            </div>
            <!-- [disabled]="pedido.pedidoStatus.id !== 12 && pedido.modNf.id === 22" -->
            <div class="form-group col-sm-4">
              <label>CHAVE ELETRÔNICA
                <span *ngIf="
                    ((this.pedido.pedidoStatus.id === 12 ||
                      this.pedido.pedidoStatus.id === 7 ||
                      this.pedido.pedidoStatus.id === 4) &&
                      (pedido.modNf.id === 22 || pedido.modNf.id === 23)) ||
                    (this.pedido.pedidoStatus.id === 1 &&
                      pedido.modNf.id === 22)
                  " class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.chvNfe" maxlength="44" [disabled]="
                  (!pedidoExterior &&
                    pedido.pedidoTipo.id === 3 &&
                    (pedido.modNf.id === 12 ||
                      pedido.modNf.id === 13 ||
                      pedido.modNf.id === 15 ||
                      pedido.modNf.id === 17)) ||
                  (!pedidoExterior &&
                    pedido.pedidoTipo.id === 3 &&
                    pedido.pedidoStatus.id !== 12 &&
                    pedido.modNf.id === 22)
                " placeholder="{{
                  pedido.pedidoStatus.id !== 12 && pedido.modNf.id === 22
                    ? 'Gerado Automaticamente'
                    : ''
                }}" />
            </div>
            <div class="form-group col-sm-1" *ngIf="!isReadOnly && (pedido.nfeFinalidade.id === 1 || pedido.nfeFinalidade.id === 4 ||
         pedido.nfeFinalidade.id === 5 || pedido.nfeFinalidade.id === 2)">
              <button class="btn btn-sm btn-info" style="margin-top: 26px;" (click)="referenciarCupom()"><i
                  class="fas fa-file-invoice"></i>
                Ref. Cupom</button>
            </div>
          </div>
          <!-- 4a. linha das Iniciais -->
          <div class="form-row" *ngIf="pedido.pedidoTipo.id === 1 || pedido.pedidoTipo.id === 3">
            <div class="form-group col-sm-1">
              <label>CSM.FINAL</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesConsumidorFinal"
                placeholder="Selecione " [(ngModel)]="pedido.consumidorFinal" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>VENDA PRESENCIAL</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesNfePresencial"
                placeholder="Selecione" [(ngModel)]="pedido.nfePresencial.id" appendTo="body">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>FINALIDADE</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesNfeFinalidade"
                placeholder="Selecione finalidade" [(ngModel)]="pedido.nfeFinalidade.id" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-4 largura-nfe" *ngIf="
                pedido.nfeFinalidade.id === 4 ||
                pedido.nfeFinalidade.id === 5 ||
                pedido.nfeFinalidade.id === 2 ||
                pedido.nfeFinalidade.id === 1
              ">
              <label>NFE's referenciadas<span *ngIf="
                    pedido.nfeFinalidade.id === 4 ||
                    pedido.nfeFinalidade.id === 5
                  " class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [disabled]="pedido.nfeFinalidade.id === 1"
                [(ngModel)]="pedido.nfeReferenciada" />
            </div>
            <div class="form-group col-sm-1" *ngIf="
                !isReadOnly &&
                (pedido.nfeFinalidade.id === 1 ||
                  pedido.nfeFinalidade.id === 4 ||
                  pedido.nfeFinalidade.id === 5 ||
                  pedido.nfeFinalidade.id === 2)
              ">
              <button class="btn btn-sm btn-info" style="margin-top: 26px" (click)="referenciarNfe()">
                <i class="fas fa-file-invoice"></i> Ref. NFe
              </button>
            </div>

            <div class="form-group col-sm-2" *ngIf="pedidoExterior">
              <label>NÚMERO INVOICE</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.numeroInvoice" maxlength="20" />
            </div>
            <div class="form-group col-sm-1" *ngIf="pedidoExterior">
              <label>DATA INVOICE</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dataInvoice" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-2" *ngIf="pedidoExterior">
              <label>NÚMERO AWB</label>
              <input class="form-control" type="text" [(ngModel)]="pedido.awb" maxlength="20" />
            </div>
            <div class="form-group col-sm-1" *ngIf="pedidoExterior">
              <label>DATA AWB</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
                [(ngModel)]="pedido.dataAwb" [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate"
                yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-2" *ngIf="
                tipo == 1 &&
                dblinkedSessionService.empresa.id == 302 &&
                (pedido.id != null || pedido.id != undefined)
              ">
              <label class="d-block" style="visibility: hidden; margin-top: 7px">DO NOT REMOVE</label>
              <button (click)="getCupomFiscal()" type="button" class="btn btn-sm btn-info">
                Vincular Cupom Fiscal
              </button>
            </div>
            <!-- 6a. linha toggle para conversão da taxa -->
            <div class="col-sm-3" *ngIf="pedido.moeda.id != 1">
              <label class="d-block" style="visibility: hidden; margin-top: 11px;">DO NOT REMOVE</label>
              <mat-slide-toggle *ngIf="!isReadOnly" [(ngModel)]="pedido.converterTaxa"
                (ngModelChange)="calculaValorPedido(); pagamentoForma(true);" (click)="alterarValorUnitItens()">
                Converter Taxa
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>

      <!-- 7a. linha itens do pedido -->
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-bars"></i> Itens do Pedido
            <span class="obrigatorio">*</span>
          </h3>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-2">
          <p-table class="table-system" [value]="pedido.pedidoItem" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 20%">ITEM</th>
                <th style="width: 10%">OPERAÇÃO</th>
                <th style="width: 10%">CFOP</th>
                <th class="text-right" style="width: 10%">QTD</th>
                <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR UNIT ({{ codigoMoeda }})
                </th>
                <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR UNIT (BRL)
                </th>
                <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                  VALOR UNIT
                </th>
                <th class="text-right" style="width: 10%">TIPO DESC</th>
                <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR DESC ({{ codigoMoeda }})
                </th>
                <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR DESC (BRL)
                </th>
                <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                  VALOR DESC
                </th>
                <th *ngIf="!pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR TOTAL ({{ codigoMoeda }})
                </th>
                <th *ngIf="pedido.converterTaxa && pedidoExterior" class="text-right" style="width: 11%">
                  VALOR TOTAL (BRL)
                </th>
                <th *ngIf="!pedidoExterior" class="text-right" style="width: 11%">
                  VALOR TOTAL
                </th>
                <th style="width: 7%" class="text-center">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData" style="height: 25px">
                <td *ngIf="rowData.item.id" (click)="rateioItem(rowData)">
                  <span matTooltip="{{ rowData.item.codigo }} - {{
                      rowData.item.descricao
                    }}" class="overflow-ellipse">{{ rowData.item.codigo }} -
                    {{ rowData.item.descricao }}</span>
                </td>
                <!-- <td *ngIf="rowData.item.id && pedidoExterior" (click)="rateioItem(rowData)">
                  <span matTooltip="{{rowData.item.codigo}} - {{rowData.item.descricaoEn}}"
                    class="overflow-ellipse">{{rowData.item.codigo}} - {{rowData.item.descricaoEn}}</span>
                </td>
                <td *ngIf="rowData.item.id && !pedidoExterior" (click)="rateioItem(rowData)">
                  <span matTooltip="{{rowData.item.codigo}} - {{rowData.item.descricao}}"
                    class="overflow-ellipse">{{rowData.item.codigo}} - {{rowData.item.descricao}}</span>
                </td> -->
                <td (click)="rateioItem(rowData)">
                  <span matTooltip="{{ rowData.operacaoRegra.cod }} - {{
                      rowData.operacaoRegra.descricao
                    }}" class="overflow-ellipse">{{ rowData.operacaoRegra.cod }} -
                    {{ rowData.operacaoRegra.descricao }}</span>
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.cfop }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.quantidade }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.valorUnit | money }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.tipoDesconto.cod }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.valorDesconto | money }}
                </td>
                <td class="text-right" (click)="rateioItem(rowData)">
                  {{ rowData.valorTotal | money }}
                </td>
                <td class="text-center">
                  <span class="edit" matTooltip="Editar Item Pedido" (click)="itemOnRowSelect(rowData)">
                    <i class="fas fa-edit"></i>
                  </span>
                  <span class="delete" matTooltip="Excluir Item Pedido" *ngIf="!isReadOnly"
                    (click)="excluirLinhaPedido(rowData.id)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr style="height: 25px">
                <td [attr.colspan]="11" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Sem Item Adicionado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col" *ngIf="!isReadOnly">
          <button class="btn btn-sm btn-info" (click)="adicionarItem()">
            <i class="fas fa-plus"></i> Adicionar Item
          </button>
          <button class="btn btn-sm btn-info" (click)="showModalImportarItens()">
            <i class="fas fa-file-upload"></i> Upload Itens
          </button>
        </div>
      </div>

      <!-- Início Rateio Pedido-->
      <div class="row">
        <div class="col-sm-12" *ngIf="exibirRateio && pedidoItem.item">
          <h3 class="sub-title mt-4 mb-0">
            <i class="fas fa-bars"> </i> Rateio
            <span class="text-secondary">{{ pedidoItem.item.codigo }}</span>
          </h3>
        </div>
      </div>
      <div class="row" *ngIf="exibirRateio && pedidoItem.pedidoItensRateio">
        <div class="col-sm-12">
          <p-table class="table-system" [value]="pedidoItem.pedidoItensRateio" [style]="{ width: '100%' }">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 40%" class="text-left">Centro de Custo</th>
                <th style="width: 33%">Projeto</th>
                <th style="width: 20%">Porcentagem</th>
                <th style="width: 7%" class="text-center">Ação</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr (click)="retornaIdCentroDeCusto(rowData.cadCcus.id, null)">
                <td pEditableColumn style="width: 40%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCentroCusto"
                            [(ngModel)]="rowData.cadCcus" placeholder="Selecione um centro de custo" (onChange)="
                              calculaRateioDisponivelCentroCusto();
                              retornaIdCentroDeCusto(
                                rowData.cadCcus.id,
                                rowData
                              )
                            " optionLabel="label" appendTo="body"></p-dropdown>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip=" {{ rowData.cadCcus.codCcus }} - {{
                          rowData.cadCcus.descrCcus
                        }}" class="overflow-ellipse" style="cursor: pointer" *ngIf="rowData.cadCcus">
                        {{ rowData.cadCcus.codCcus }} -
                        {{ rowData.cadCcus.descrCcus }}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 33%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesProjeto"
                            [(ngModel)]="rowData.cadProj" placeholder="Selecione um projeto"
                            (onChange)="calculaRateioDisponivelCentroCusto()" optionLabel="label" appendTo="body">
                          </p-dropdown>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip=" {{ rowData.cadProj.codProj }} - {{
                          rowData.cadProj.descrProj
                        }}" class="overflow-ellipse" style="cursor: pointer" *ngIf="rowData.cadProj">
                        {{ rowData.cadProj.codProj }} -
                        {{ rowData.cadProj.descrProj }}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 20%; text-align: right">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-0">
                          <input class="form-control text-right" [(ngModel)]="rowData.porcentagem"
                            (keyup)="calculaRateioDisponivelCentroCusto()" type="number" />
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span matTooltip="{{ rowData.porcentagem | money }}" class="overflow-ellipse text-right"
                        style="cursor: pointer">{{ rowData.porcentagem | money }}</span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="text-center" style="width: 7%">
                  <span class="delete" matTooltip="Excluir" (click)="removerRateioCentroCusto(rowData.id)"><i
                      class="far fa-trash-alt"></i></span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col" *ngIf="exibirRateio">
          <button class="btn btn-sm btn-info mt-2" *ngIf="exibirRateio" (click)="adicionarRateio()"
            [disabled]="isReadOnly">
            <i class="fas fa-plus"></i> Adicionar Rateio
          </button>

          <button class="btn btn-sm btn-info mt-2" *ngIf="exibirRateio && pedido.pedidoItem.length > 1"
            (click)="replicarRateio()" [disabled]="isReadOnly">
            <i class="fas fa-copy"></i> Replicar
          </button>
        </div>
      </div>
      <!-- Final Rateio Pedido-->

      <div class="row">
        <div class="col-sm-12 mt-3">
          <h3 class="sub-title">
            <i class="fas fa-info-circle"></i> Informações Gerais
          </h3>
        </div>
      </div>
      <div class="form-row align-items-end">
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">BASE CALC ICMS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">BASE CALC ICMS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">BASE CALC ICMS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorBaseCalculoIcms" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR ICMS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR ICMS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR ICMS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIcms" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">BASE CALC ICMS ST (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">BASE CALC ICMS ST ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">BASE CALC ICMS ST</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIcmsSt" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR ICMS ST (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR ICMS ST ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR ICMS ST</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIcmsSubst" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">ICMS UF REMETENTE (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">ICMS UF REMETENTE ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">ICMS UF REMETENTE</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIcmsUfRemetente" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">ICMS UF DESTINO (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">ICMS UF DESTINO ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">ICMS UF DESTINO</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIcmsUfDestino" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR FCP (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR FCP ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR FCP</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorFcp" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR IPI (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR IPI ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR IPI</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorIpi" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR PRODUTOS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR PRODUTOS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR PRODUTOS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="subTotalPedido" />
        </div>
      </div>
      <div class="form-row align-items-end">
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR FRETE (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR FRETE ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR FRETE</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorFrete" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR SEGURO (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR SEGURO ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR SEGURO</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorSeguro" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR DESCONTO (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR DESCONTO ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR DESCONTO</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorDesconto" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">OUTRAS DESPESAS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">OUTRAS DESPESAS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">OUTRAS DESPESAS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorOutrasDespesas" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">IMP. IMPORTAÇÃO (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">IMP. IMPORTAÇÃO ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">IMP. IMPORTAÇÃO</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorImpostoImportacao" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR TRIBUTOS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR TRIBUTOS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR TRIBUTOS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorTotalTributos" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR COFINS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR COFINS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR COFINS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorCofins" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR PIS (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR PIS ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR PIS</label>
          <input class="form-control text-right" type="text" [(ngModel)]="pedido.valorPis" money />
        </div>
        <div class="form-group col">
          <label *ngIf="pedido.converterTaxa && pedidoExterior">VALOR DOCUMENTO (BRL)</label>
          <label *ngIf="!pedido.converterTaxa && pedidoExterior">VALOR DOCUMENTO ({{ codigoMoeda }})</label>
          <label *ngIf="!pedidoExterior">VALOR DOCUMENTO</label>
          <input class="form-control text-right" type="text" [(ngModel)]="valorTotalPedido" money />
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title mt-3">
            <i class="fas fa-money-check-alt"></i> Informações de Pagamento
          </h3>
        </div>
      </div>

      <!-- 1a. linha pagamento -->
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>TIPO DE PAGAMENTO <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.tipoPagamento"
            [options]="opcoesTipoPagamento" (onChange)="pagamentoTipo()" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-2" *ngIf="pedido.tipoPagamento === 1">
          <label>FORMA DE PAGAMENTO <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.formaPagamento.id"
            [options]="opcoesFormaPagamento" (onChange)="infosFormaPagto(pedido.formaPagamento.id)" appendTo="body">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3" *ngIf="pedido.tipoPagamento === 1">
          <label>CONTA <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="pedido.empresaConta.id"
            [options]="opcoesContas" (onChange)="pagamentoForma()" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-4" *ngIf="pedido.tipoPagamento === 1">
          <label class="d-block" style="visibility: hidden">DO NOT REMOVE</label>
          <button type="button" class="btn btn-sm btn-info" *ngIf="
              pedido.empresaConta.id && pedido.formaPagamento.id && !isReadOnly
            " (click)="modalFormaPgto = true">
            <i class="fas fa-plus"></i> Adicionar
          </button>
          <button type="button" class="btn btn-sm btn-info" (click)="pagamentoForma(true)">
            <i class="fas fa-refresh"></i> Atualizar
          </button>
        </div>
      </div>

      <!-- 2a. linha pagamento -->
      <div class="row" *ngIf="pedido.tipoPagamento === 1">
        <div class="col-sm-12 mb-3" *ngIf="pedido.pedidoParcela">
          <div *ngIf="pedido.pedidoParcela.length > 0">
            <p-table class="table-system" [value]="pedido.pedidoParcela" selectionMode="single"
              (onRowSelect)="modalFormaPgto = true" [scrollable]="true" scrollHeight="330px">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 10%">Parcela</th>
                  <th class="text-center" style="width: 12%">Vencimento</th>
                  <th class="text-right" style="width: 12%" *ngIf="pedido.converterTaxa && pedidoExterior">
                    Valor (BRL)
                  </th>
                  <th class="text-right" style="width: 12%" *ngIf="!pedido.converterTaxa && pedidoExterior">
                    Valor ({{ codigoMoeda }})
                  </th>
                  <th class="text-right" style="width: 12%" *ngIf="!pedidoExterior">
                    Valor
                  </th>
                  <th class="text-center" style="width: 12%">Conta</th>
                  <th class="text-left" style="width: 12%">Tipo baixa</th>
                  <th class="text-left" style="width: 12%">Tipo Pagamento</th>
                  <th class="text-left" style="width: 30%">Histórico</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr *ngIf="!isReadOnly" [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 10%">
                    {{ rowData.parcela }}
                  </td>
                  <td class="text-center" style="width: 12%">
                    {{ rowData.dtVencimento | date: "dd/MM/yyyy" }}
                  </td>
                  <td class="text-right" style="width: 12%">
                    {{ rowData.valor | money }}
                  </td>
                  <td class="text-center" style="width: 12%">
                    {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                  </td>
                  <td class="text-left" style="width: 12%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                      {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                    </span>
                  </td>
                  <td class="text-left" style="width: 12%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}">
                      {{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}
                    </span>
                  </td>
                  <td class="text-left" style="width: 30%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.historico }}">{{ rowData.historico }}</span>
                  </td>
                </tr>
                <tr *ngIf="isReadOnly">
                  <td class="text-center" style="width: 10%">
                    {{ rowData.parcela }}
                  </td>
                  <td class="text-center" style="width: 12%">
                    {{ rowData.dtVencimento | timezone | date: "dd/MM/yyyy" }}
                  </td>
                  <td class="text-right" style="width: 12%">
                    {{ rowData.valor | money }}
                  </td>
                  <td class="text-center" style="width: 12%">
                    {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                  </td>
                  <td class="text-left" style="width: 12%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}">
                      {{ rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa }}
                    </span>
                  </td>
                  <td class="text-left" style="width: 12%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}">
                      {{ rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null }}
                    </span>
                  </td>
                  <td class="text-left" style="width: 30%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.historico }}">{{ rowData.historico }}</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr style="height: 25px">
                  <td [attr.colspan]="7" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline">
                      <i class="fas fa-info-circle"></i> Sem forma pagamento selecionada
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="pedido.tipoPagamento === 2">
        <div class="col-sm-12 mb-3" *ngIf="adtosRelacionadosPedido">
          <div *ngIf="adtosRelacionadosPedido.length">
            <p-table class="table-system" [value]="adtosRelacionadosPedido" selectionMode="single"
              (onRowSelect)="adicionarCompensacao()" [scrollable]="true" scrollHeight="320px">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 10%">Data</th>
                  <th class="text-left" style="width: 30%">Participante</th>
                  <th class="text-left" style="width: 30%">Histórico</th>
                  <th class="text-right" style="width: 10%">Valor</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 10%">
                    {{ rowData.data }}
                  </td>
                  <td class="text-left" style="width: 30%">
                    {{ rowData.nomePart }}
                  </td>
                  <td class="text-left" style="width: 30%">
                    {{ rowData.descricao }}
                  </td>
                  <td class="text-right" style="width: 10%">
                    {{ rowData.compensar | money }}
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr style="height: 25px">
                  <td [attr.colspan]="3">Sem forma pagamento selecionada</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>

      <!-- <div class="row" *ngIf="financeiroAntecipado && tipo == 1">
        <div class="col-sm-12">
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Operação Recebimento Antecipado</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesOperacaoCont"
                placeholder="Selecione a Operação" [(ngModel)]="operacaoRegraAntecipado"></p-dropdown>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Transportador -->
      <div class="row" *ngIf="pedido.modNf.id !== 15">
        <div class="col-sm-12">
          <h3 *ngIf="
              pedido.pedidoTipo.id === 3 ||
              (pedido.pedidoTipo.id === 1 && !pedidoExterior)
            " class="sub-title">
            <i class="fas fa-truck-moving"></i> Transportador
          </h3>
          <h3 *ngIf="pedido.pedidoTipo.id === 1 && pedidoExterior" class="sub-title">
            <i class="fas fa-warehouse"></i> Local Exportação
          </h3>

         <p-table class="table-system" [value]="(pedido.tipoFrete.id === 999) ? [] : [{}]">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 50%">Transportador</th>
                <th class="text-center" style="width: 10%">Volumes</th>
                <th class="text-right" style="width: 10%">Peso Liq.</th>
                <th class="text-right" style="width: 10%">Peso Bruto</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body">
              <tr style="height: 25px; cursor: pointer" *ngIf="
                  pedido.tipoFrete.id !== 6 &&
                  pedido.tipoFrete.id !== 5 &&
                  pedido.tipoFrete.id !== 4 &&
                  pedido.tipoFrete.id !== 999 &&
                  !isReadOnly
                " (click)="exbirModalTransportador = true">
                <td>{{ pedido.transportador.nome }}</td>
                <td class="text-center">{{ pedido.qVol }}</td>
                <td class="text-right">{{ pedido.pesoL | money }}</td>
                <td class="text-right">{{ pedido.pesoB | money }}</td>
              </tr>
              <tr style="height: 25px; cursor: pointer" *ngIf="
                  pedido.tipoFrete.id !== 6 &&
                  pedido.tipoFrete.id !== 5 &&
                  pedido.tipoFrete.id !== 4 &&
                  pedido.tipoFrete.id !== 999 &&
                  isReadOnly
                ">
                <td>{{ pedido.transportador.nome }}</td>
                <td class="text-center">{{ pedido.qVol }}</td>
                <td class="text-right">{{ pedido.pesoL | money }}</td>
                <td class="text-right">{{ pedido.pesoB | money }}</td>
              </tr>
              <tr style="height: 25px; cursor: pointer" *ngIf="pedido.tipoFrete.id === 4 || pedido.tipoFrete.id === 5"
                (click)="exbirModalTransportador = true">
                <td>Pedido sem ocorrência de transportador.</td>
                <td class="text-center">{{ pedido.qVol }}</td>
                <td class="text-right">{{ pedido.pesoL | money }}</td>
                <td class="text-right">{{ pedido.pesoB | money }}</td>
              </tr>
              <tr style="height: 25px; cursor: pointer" *ngIf="pedido.tipoFrete.id === 6"
                (click)="exbirModalTransportador = true">
                <td colspan="4">Pedido sem ocorrência de frete.</td>
              </tr>
              <tr style="height: 25px" *ngIf="pedido.tipoFrete.id === 999" class="spaced-alert">
                <td colspan="4">
                  <div class="alert alert-xs alert-primary d-inline">
                    <i class="fas fa-info-circle"></i> Sem frete selecionado.
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="4" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline">
                    <i class="fas fa-info-circle"></i> Sem frete selecionado.
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- <table class="table-system" *ngIf="
              pedido.pedidoTipo.id === 3 ||
              (pedido.pedidoTipo.id === 1 && !pedidoExterior)
            ">
            <thead>
              <tr>
                <th style="width: 50%">Transportador</th>
                <th class="text-center" style="width: 10%">Volumes</th>
                <th class="text-right" style="width: 10%">Peso Liq.</th>
                <th class="text-right" style="width: 10%">Peso Bruto</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 25px; cursor: pointer" *ngIf="
                  pedido.tipoFrete.id !== 6 &&
                  pedido.tipoFrete.id !== 5 &&
                  pedido.tipoFrete.id !== 4 &&
                  pedido.tipoFrete.id !== 999 &&
                  !isReadOnly
                " (click)="exbirModalTransportador = true">
                <td>{{ pedido.transportador.nome }}</td>
                <td class="text-center">{{ pedido.qVol }}</td>
                <td class="text-right">{{ pedido.pesoL | money }}</td>
                <td class="text-right">{{ pedido.pesoB | money }}</td>
              </tr>
              <tr style="height: 25px; cursor: pointer" *ngIf="
                  pedido.tipoFrete.id !== 6 &&
                  pedido.tipoFrete.id !== 5 &&
                  pedido.tipoFrete.id !== 4 &&
                  pedido.tipoFrete.id !== 999 &&
                  isReadOnly
                ">
                <td>{{ pedido.transportador.nome }}</td>
                <td class="text-center">{{ pedido.qVol }}</td>
                <td class="text-right">{{ pedido.pesoL | money }}</td>
                <td class="text-right">{{ pedido.pesoB | money }}</td>
              </tr>
              <tr style="height: 25px; cursor: pointer" *ngIf="pedido.tipoFrete.id === 4 || pedido.tipoFrete.id === 5"
                (click)="exbirModalTransportador = true">
                <td>Pedido sem ocorrência de transportador.</td>
                <td class="text-center">{{ pedido.qVol }}</td>
                <td class="text-right">{{ pedido.pesoL | money }}</td>
                <td class="text-right">{{ pedido.pesoB | money }}</td>
              </tr>
              <tr style="height: 25px; cursor: pointer" *ngIf="pedido.tipoFrete.id === 6"
                (click)="exbirModalTransportador = true">
                <td colspan="4">Pedido sem ocorrência de frete.</td>
              </tr>
              <tr style="height: 25px" *ngIf="pedido.tipoFrete.id === 999" class="spaced-alert">
                <td colspan="4">
                  <div class="alert alert-xs alert-primary d-inline">
                    <i class="fas fa-info-circle"></i> Sem frete selecionado.
                  </div>
                </td>
              </tr>
            </tbody>
          </table> -->

          <table class="table table-striped table-system-boot mb-2"
            *ngIf="pedido.pedidoTipo.id === 1 && pedidoExterior">
            <thead>
              <tr>
                <th class="text-center" style="width: 10%">UF</th>
                <th class="text-left" style="width: 40%">Local Embarque</th>
                <th class="text-left" style="width: 40%">Local Despacho</th>
              </tr>
            </thead>
            <tbody>
              <tr style="height: 25px; cursor: pointer" *ngIf="pedido.embarqueUf.id !== 999"
                (click)="exbirModalTransportador = true">
                <td class="text-center">{{ pedido.embarqueUf.id }}</td>
                <td class="text-left">{{ pedido.embarqueLocal }}</td>
                <td class="text-left">{{ pedido.embarqueDescricao }}</td>
              </tr>
              <tr style="height: 25px" *ngIf="pedido.embarqueUf.id === 999" class="spaced-alert">
                <td colspan="3">
                  <div class="alert alert-xs alert-primary d-inline">
                    <i class="fas fa-info-circle"></i> Sem Local Exportação
                    selecionado!
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-sm btn-info mt-3" *ngIf="!isReadOnly" (click)="exbirModalTransportador = true">
            <i class="fas fa-plus"></i> Adicionar
          </button>
        </div>
      </div>

      <!-- Agencia -->
      <div class="row" *ngIf="pedido.modNf.id === 15">
        <div class="col-sm-12">
          <h3 *ngIf="pedido.pedidoTipo.id === 1" class="sub-title">
            <i class="fas fa-user"></i> Agência
          </h3>

          <div *ngIf="exbirModalAgencia">
            <div class="form-row">
              <div class="form-group col-sm-4">
                <label>AGENCIA</label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesAgencia"
                  placeholder="Selecione a Agência" appendTo="body" [(ngModel)]="agenciaId"></p-dropdown>
              </div>
              <div class="form-group col-sm-4">
                <label>PORCENTAGEM COMISSÃO</label>
                <input class="form-control" type="text" [(ngModel)]="valorComissao"
                  (blur)="formataPorcentagemComissao()" />
              </div>
              <div class="form-group col-sm-4">
                <label>PAGAMENTO COMISSÃO</label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesPagamentoComissao"
                  placeholder="Selecione a opção" appendTo="body" [(ngModel)]="pagamentoComissao"></p-dropdown>
              </div>
            </div>
          </div>


          <button class="btn btn-sm btn-info" *ngIf="!isReadOnly && !exbirModalAgencia"
            (click)="exbirModalAgencia = true">
            <i class="fas fa-plus"></i> Adicionar
          </button>
          <button class="btn btn-sm btn-info" *ngIf="!isReadOnly && exbirModalAgencia" (click)="removerAgencia()">
            <i class="fas fa-trash"></i> Remover
          </button>
        </div>
      </div>

      <!-- Observações -->
      <div class="row">
        <div class="col-sm-12 mt-3">
          <h3 class="sub-title"><i class="fas fa-file-alt"></i> OBSERVAÇÕES</h3>
        </div>
      </div>
      <!-- 1a. linha -->
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>ENDEREÇO DE COBRANÇA</label>
          <textarea class="form-control" rows="5" type="text" value="pedido.enderecoCobranca"
            [(ngModel)]="pedido.enderecoCobranca"></textarea>
        </div>
        <div class="form-group col-sm-6">
          <label>ENDEREÇO DE ENTREGA</label>
          <textarea class="form-control" rows="5" type="text" value="pedido.enderecoEntrega"
            [(ngModel)]="pedido.enderecoEntrega"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <!-- 1a. linha dados adicionais -->
          <label>DADOS ADICIONAIS</label>
          <textarea class="form-control" rows="5" pInputTextarea value="pedido.dadosAdicionais"
            [(ngModel)]="pedido.dadosAdicionais"></textarea>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="row" *ngIf="comissaoAtiva && tipo == 1">
        <div class="col-sm-12">
          <h3 class="sub-title mt-0">
            <i class="fas fa-user"></i> Comissão de Venda
          </h3>
        </div>
        <div class="col-sm-3">
          <label>VENDEDOR</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [(ngModel)]="vendedorId" [options]="opcoesVendedor"
            appendTo="body">
          </p-dropdown>
        </div>
      </div>

      <div class="row mt-3  {{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}">
        <div class="col-sm-12 d-flex align-items-center justify-content-start">
          <button type="button" class="btn btn-secondary" (click)="inicializar()">
            <i class="fas fa-undo"></i> Cancelar
          </button>
          <!-- <button class="btn btn-primary" *ngIf="habilitarSolicitar" (click)="solicitarAprovacao()">
            <i class="fas fa-envelope"></i> Solicitar
          </button> -->
          <button type="button" class="btn btn-primary" *ngIf="!pedido.id" (click)="salvarPedido(false)">
            <i class="fas fa-check"></i> Salvar
          </button>
          <button type="button" class="btn btn-primary" *ngIf="!pedido.id" (click)="salvarPedido(true)">
            <i class="fas fa-check"></i> Salvar e Criar novo
          </button>
          <button type="button" class="btn btn-primary" *ngIf="pedido.id && pedido.pedidoStatus.id !== 5"
            (click)="atualizarPedido(false)">
            <i class="fas fa-check"></i> Salvar
          </button>
          <!-- <button type="button" class="btn btn-primary" *ngIf="financeiroAntecipado && pedido.id > 0 && tipo == 1"
            (click)="gerarFinanceiroPedido()">
            <i class="fas fa-check"></i> GERAR FINANCEIRO
          </button> -->
          <div class="p-field-checkbox ml-3"
            *ngIf="showSolicitarAprovacao && (pedido.pedidoStatus.id === 4 || pedido.pedidoStatus.id === 7)">
            <p-checkbox class="mr-1" [(ngModel)]="requestApproval" [binary]="true" inputId="sa"></p-checkbox>
            <label for="sa" style="margin: 0">Solicitar aprovação</label>
          </div>
        </div>
      </div>
    </div>
    <!---final formulário-->

    <div class="row mt-3" *ngIf="!exibirPedido">
      <div class="col-sm-12">
        <!--Saldo Inicial-->
        <div class="pull-left mr-3">
          <div class="div-card-total">
            <p class="titulo-card-total">Valor Total</p>
            <p class="valor-card-total">R$ {{ valorTotalPedidos | money }}</p>
          </div>
        </div>
        <!--Movimentação-->
        <div class="pull-left mr-3">
          <div class="div-card-total">
            <p class="titulo-card-total">Valor Selecionados</p>
            <p class="valor-card-total">
              R$ {{ valorPedidosSelecionados | money }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- Modal importar itens -->
  <p-dialog header="Importar Itens Pedido" [(visible)]="exibirModalImportarItens" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-file-upload"> </i> Importar Arquivo
          </h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6 mb-1 mt-1">
          <label class="d-block">Selecione um arquivo csv</label>
          <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
            <span class="input-group-append">
              <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                      Remover Arquivo
                    </span> -->
              <span class="input-group-text btn-file">
                <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea"></i>
                  Selecionar Arquivo</span>
                <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea"></i>
                  Selecionar Arquivo</span>
                <!-- Antes era Trocar Arquivo-->
                <input type="file" #file (change)="fileChange()" />
              </span>
            </span>
            <div class="form-control" data-trigger="fileinput">
              <span class="fileinput-filename"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-g" style="position: fixed; right: 25px; bottom: 30px">
        <div class="ui-g-12"></div>
      </div>
      <div class="form-row" *ngIf="1">
        <div class="form-group col-sm-12">
          <label class="d-block"> Selecione a opção: </label>
          <mat-radio-group name="replace" [(ngModel)]="replace">
            <mat-radio-button class="d-block" [value]="false">Incluir</mat-radio-button>
            <mat-radio-button class="d-block" [value]="true">Sobrescrever</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <button type="button" class="btn btn-primary" [disabled]="importDisabled" (click)="importar()">
            <i class="fas fa-upload"></i> Importar
          </button>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="row mt-3">
        <div class="col-sm-12">
          <button type="button" class="btn btn-secondary" (click)="closeModalImportarItens()">
            <i class="fas fa-undo"></i> Fechar
          </button>
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <!---Modal Item-->

  <p-dialog header="Adicionar Item do Pedido" [(visible)]="exibirItemPedido" [modal]="true"
    styleClass="item-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="exibirItemPedido && !pedidoItem.isServico"
      style="width: 900px;">
      <mat-tab-group>

        <mat-tab label="Dados">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title"><i class="fas fa-file-alt"></i> Dados</h3>
            </div>
          </div>
          <div id="conteudo-dados" style="width: calc(100%- 10px)">
            <div class="form-row">
              <div class="form-group col-sm-6">
                <label>Operação <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                  placeholder="Selecione a Operacao" [(ngModel)]="pedidoItem.operacaoRegra.id"
                  (onChange)="dadosOperacao(pedidoItem.operacaoRegra.id)" appendTo="body"></p-dropdown>
              </div>
              <div class="form-group col-sm-6">
                <label>Item <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesItens"
                  placeholder="Selecione o Item" [(ngModel)]="pedidoItem.item.id"
                  (onChange)="dadosItem(pedidoItem.item.id, true)" appendTo="body">
                </p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>Part Number</label>
                <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.itemFornecedor" />
              </div>
              <div class="form-group col-sm-3">
                <label>CFOP
                  <span *ngIf="
                      (this.pedido.pedidoTipo.id === 3 &&
                        this.pedido.pedidoStatus.id !== 1 &&
                        this.pedidoExterior) ||
                      (this.pedido.pedidoTipo.id === 1 &&
                        this.pedido.pedidoStatus.id === 4)
                    " class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown altura-cfop" [filter]="true" placeholder="Selecione CFOP"
                  [options]="opcoesCfop" [(ngModel)]="pedidoItem.cfop" appendTo="body" (onChange)="verificarCfop()"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>NCM <span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown altura-ncm" [filter]="true" placeholder="Selecione"
                  [(ngModel)]="pedidoItem.ncm" [options]="opcoesCapituloTipi" appendTo="body"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>CEST</label>
                <input class="form-control" type="number" [(ngModel)]="pedidoItem.cest" maxlength="10" [disabled]="
                    pedido.modNf.id !== 22 && pedido.pedidoTipo.id === 3
                  " />
              </div>
              <div class="form-group col-sm-3">
                <label>Unidade</label>
                <p-dropdown appendTo="body" class="type-p-dropdown altura-comercial" [filter]="true"
                  [options]="opcoesUnidadeMedida" placeholder="Selecione a Unidade"
                  [(ngModel)]="pedidoItem.unidadeMedida.id" (onChange)="dadosUnidade(pedidoItem.unidadeMedida.id)"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>Quantidade <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [disabled]="!pedidoItem.item"
                  (blur)="calculaValorTotalItem()" [(ngModel)]="pedidoItem.quantidade" />
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Unit. <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text"
                  (blur)="calculaValorUnitImport(); calculaValorTotalItem();" [disabled]="!pedidoItem.item" pInputText
                  [(ngModel)]="pedidoItem.valorUnit" />
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Total <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" (blur)="calculaValorTotalItem()"
                  [disabled]="!pedidoItem.item" pInputText [(ngModel)]="pedidoItem.valorTotalProduto" money />
              </div>
              <div class="form-group col-sm-3">
                <label>Unidade Tributável <span class="obrigatorio">*</span></label>

                <p-dropdown appendTo="body" class="type-p-dropdown altura-tributavel" [filter]="true"
                  [options]="opcoesUnidadeMedida" placeholder="Selecione a Unidade"
                  [(ngModel)]="pedidoItem.unidadeMedidaTributavel.id"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>Quantidade Tributável
                  <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [disabled]="
                    pedidoItem.unidadeMedidaTributavel.id ===
                    pedidoItem.unidadeMedida.id
                  " pInputText [(ngModel)]="pedidoItem.quantidadeTributavel" />
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Unit. Tributável
                  <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [disabled]="
                    pedidoItem.unidadeMedidaTributavel.id ===
                    pedidoItem.unidadeMedida.id
                  " pInputText [(ngModel)]="pedidoItem.valorUnitTributavel" />
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Total Tributável
                  <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" pInputText [(ngModel)]="valorItemTributavel"
                  [disabled]="
                    pedidoItem.unidadeMedidaTributavel.id ===
                    pedidoItem.unidadeMedida.id
                  " />
              </div>
              <div class="form-group col-sm-2">
                <label>Valor Frete</label>
                <input class="form-control text-right" type="text" (blur)="
                    calculaValorTotalItem();
                    acionaCalcValorUnitImport();
                    atualizarValorUnit()
                  " pInputText [(ngModel)]="pedidoItem.valorFrete" money />
              </div>
              <div class="form-group col-sm-2">
                <label>Valor Seguro</label>
                <input class="form-control text-right" type="text" (blur)="
                    calculaValorTotalItem();
                    acionaCalcValorUnitImport();
                    atualizarValorUnit()
                  " pInputText [(ngModel)]="pedidoItem.valorSeguro" money />
              </div>

              <div class="form-group col-sm-1">
                <label>TIPO</label>
                <p-dropdown [filter]="false" [options]="opcoesDesconto" [(ngModel)]="pedidoItem.tipoDesconto.id"
                  (ngModelChange)="calculaValorTotalItem()" placeholder="Selecione" class="type-p-dropdown altura-ncm"
                  appendTo="body">
                </p-dropdown>
              </div>
              <div class="form-group col-sm-2">
                <label>Valor Desconto</label>
                <input class="form-control text-right" type="text" [disabled]="!pedidoItem.tipoDesconto.id"
                  (blur)="calculaValorTotalItem()" [(ngModel)]="pedidoItem.valorDesconto" money />
              </div>
              <div class="form-group col-sm-2">
                <label>Outro</label>
                <input class="form-control text-right" type="text" (blur)="calculaValorTotalItem()"
                  [(ngModel)]="pedidoItem.valorOutro" money />
              </div>
              <div class="form-group col-sm-3">
                <label>Valor Total <span class="obrigatorio">*</span></label>
                <input class="form-control text-right" type="text" [(ngModel)]="pedidoItem.valorTotal" money
                  (onChange)="getTaxBaseAmounts()" />
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Tributos" *ngIf="pedido.pedidoTipo.id !== 2">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title">
                <i class="fas fa-file-alt"></i> Tributos
              </h3>
            </div>
          </div>
          <div id="conteudo-tributos" style="width: calc(100%- 10px)">
            <mat-tab-group>
              <mat-tab label="ICMS">
                <div class="form-row mt-3">
                  <div class="form-group col-sm-6">
                    <label>Situação Tributária
                      <span *ngIf="
                          pedidoItem.csosn === null ||
                          pedidoItem.csosn === undefined
                        " class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesCstIcms"
                      [(ngModel)]="pedidoItem.cstIcmsTabelaB.id" placeholder="Selecione" appendTo="body"></p-dropdown>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Origem <span class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOrigemCstIcms"
                      [(ngModel)]="pedidoItem.cstIcmsTabelaA.id" placeholder="Selecione" appendTo="body"></p-dropdown>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Mod. deter.do ICMS
                      <span *ngIf="
                          (!this.pedidoItem.modBcIcms ||
                            this.pedidoItem.modBcIcms === undefined ||
                            this.pedidoItem.modBcIcms === null) &&
                          (this.pedidoItem.cstIcmsTabelaB.id === 2 ||
                            this.pedidoItem.cstIcmsTabelaB.id === 3 ||
                            this.pedidoItem.cstIcmsTabelaB.id === 1) &&
                          ((this.pedido.pedidoTipo.id === 3 &&
                            this.pedidoExterior) ||
                            (this.pedido.pedidoTipo.id === 1 &&
                              !this.pedidoExterior))
                        " class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesModBcIcms"
                      [(ngModel)]="pedidoItem.modBcIcms" placeholder="Selecione" appendTo="body"></p-dropdown>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>CSOSN
                      <span *ngIf="
                          pedidoItem.cstIcmsTabelaB.id === null ||
                          pedidoItem.cstIcmsTabelaB.id === undefined
                        " class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown altura-csosn" [filter]="true" [options]="opcoesCsosn"
                      [(ngModel)]="pedidoItem.csosn" placeholder="Selecione" appendTo="body"></p-dropdown>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mt-0">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> ICMS
                    </h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-3">
                    <label>Valor base cálc. ICMS</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorIcms()" pInputText
                      [(ngModel)]="pedidoItem.valorBcIcms" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Perc. red. BC ICMS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.percRedBcIcms"
                      money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíquota ICMS</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorIcms()" pInputText
                      [(ngModel)]="pedidoItem.aliquotaIcms" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor ICMS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorIcms"
                      money />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 mt-0">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> ICMS ST
                    </h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-3">
                    <label>Mod. deter. ICMS ST </label>
                    <p-dropdown appendTo="body" appendTo="body" class="type-p-dropdown altura-mod-icms" [filter]="false"
                      [options]="opcoesModBcIcmsSt" [(ngModel)]="pedidoItem.modBcIcmsSt" placeholder="Selecione"
                      (onChange)="dadosMva($event)">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor base cálc. ICMS ST</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorIcmsSt()" pInputText
                      [(ngModel)]="pedidoItem.valorBcIcmsSt" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíquota ICMS ST</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorIcmsSt()" pInputText
                      [(ngModel)]="pedidoItem.aliquotaIcmsSt" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor ICMS ST</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorIcmsSt"
                      money />
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="IPI">
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> IPI
                    </h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label>Código de enquadramento<span class="obrigatorio">*</span></label>
                    <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCodEnqIpi"
                      [(ngModel)]="pedidoItem.codigoIpi" placeholder="Selecione"></p-dropdown>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Classe de enquadramento</label>
                    <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.classeIpi" />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Situação tributária<span class="obrigatorio">*</span></label>
                    <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCstIpi"
                      [(ngModel)]="pedidoItem.cstIpi.id" placeholder="Selecione"></p-dropdown>
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor base cálc.</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorIpi()" pInputText
                      [(ngModel)]="pedidoItem.valorBcIpi" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíquota</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorIpi(); getTaxBaseAmounts()"
                      pInputText [(ngModel)]="pedidoItem.aliquotaIpi" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor IPI</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorIpi"
                      money />
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="PIS / COFINS">
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> PIS
                    </h3>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-sm-3">
                    <label>Situação tributária<span class="obrigatorio">*</span></label>
                    <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCstPis"
                      [(ngModel)]="pedidoItem.cstPis.id" placeholder="Selecione" panelStyleClass="custom-width">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor base cálc.</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorPis()" pInputText
                      [(ngModel)]="pedidoItem.valorBcPis" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíquota %</label>
                    <input class="form-control text-right" type="text" input-text="right" (blur)="calculaValorPis()"
                      pInputText [(ngModel)]="pedidoItem.perAliquotaPis" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor PIS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorPis"
                      money />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> COFINS
                    </h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-3">
                    <label>Situação tributária<span class="obrigatorio">*</span></label>
                    <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesCstCofins"
                      [(ngModel)]="pedidoItem.cstCofins.id" placeholder="Selecione" panelStyleClass="custom-width">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor base cálc.</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorCofins()" pInputText
                      [(ngModel)]="pedidoItem.valorBcCofins" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíquota %</label>
                    <input class="form-control text-right" type="text" (blur)="calculaValorCofins()" pInputText
                      [(ngModel)]="pedidoItem.perAliquotaCofins" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor COFINS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorCofins"
                      money />
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Imposto de Importação" *ngIf="pedido.pedidoTipo.id === 3">
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> Imposto de Importação
                    </h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-3">
                    <label>Valor base cálc.</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorBcImport"
                      money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor despesas aduaneiras</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorDespImport" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor IOF</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorIofImport" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Valor imposto importação</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorImpostoImport" money />
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Operaçao Inter.">
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> Operaçao Interestadual
                    </h3>
                  </div>
                </div>
                <div class="form-row align-items-end">
                  <div class="form-group col-sm-3">
                    <label>Vl. Base Cálculo ICMS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorBcIcms"
                      money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Vl. Base Cálc. FCP UF Destino</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorBCIcmsFcpUfDest" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Perc. ICMS FCP UF Destino</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.percBaseCalculoIcmsFcpUfDest" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíq. Interna UF Térm.</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.aliquotaInternaUfTermino" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíq. Interestadual</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.aliquotaInterestadual" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Perc. Partilha UF Térm.</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.percentualPartilhaUfTermino" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Vl. ICMS Partilha UF Iníc.</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorIcmsPartilhaUfInicio" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Vl. ICMS Partilha UF Térm.</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorIcmsPartilhaUfTermino" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíq. FCP da UF Térm.</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.aliquotaFcpUfTermino" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Vl. FCP da UF Térm.</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorFcpUfTermino" money />
                  </div>
                </div>
              </mat-tab>



              <mat-tab label="Imposto Devolv.">
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> Imposto Devolvido
                    </h3>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-3">
                    <label>Perc. mercadoria devolvida</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.percentualMercadoriaDevolvida" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Vl. IPI devolvido</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.valorIpiDevolvido" money />
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="DIFAL">
                <div class="row">
                  <div class="col-sm-12 mt-3">
                    <h3 class="sub-title">
                      <i class="fas fa-file-alt"></i> DIFAL
                    </h3>
                  </div>
                </div>
                <div class="form-row align-items-end">
                  <div class="form-group col-sm-3">
                    <label>Vl. Oper. Sem ICMS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorOperSemIcmsDifal"
                    (blur)="calcularValorDifal()" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíq. Interna</label>
                    <input class="form-control text-right" type="text" pInputText
                    (blur)="calcularValorDifal()" [(ngModel)]="pedidoItem.aliqInternaDifal" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Alíq. Interestadual</label>
                    <input class="form-control text-right" type="text" pInputText
                    (blur)="calcularValorDifal()" [(ngModel)]="pedidoItem.aliqInterestadualDifal" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Vl. Oper. Com ICMS</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorOperComIcmsDifal"
                      money />
                  </div>
                </div>

                <div class="form-row align-items-end">
                  <div class="form-group col-sm-3">

                  </div>
                  <div class="form-group col-sm-3">
                    <label>Icms Origem (Crédito)</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.icmsOrigemCreditoDifal" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Icms Destino (Débito)</label>
                    <input class="form-control text-right" type="text" pInputText
                      [(ngModel)]="pedidoItem.icmsDestinoDebitoDifal" money />
                  </div>
                  <div class="form-group col-sm-3">
                    <label>Difal Recolher</label>
                    <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorRecolherDifal"
                      money />
                  </div>
                </div>

              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-tab>

        <mat-tab label="Estoque" [disabled]="!pedidoItem.operacaoRegra.controlaEstoque">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title"><i class="fas fa-file-alt"></i> Estoque</h3>
            </div>
          </div>
          <div id="conteudo-dados" style="width: calc(100%- 10px)">
            <div class="form-row">
              <div class="form-group col-sm-6" *ngIf="!pedidoItem.operacaoRegra.transfEstoque">
                <label>ALMOXARIFADO</label>

                <p-dropdown class="type-p-dropdown altura-cfop" [filter]="true" placeholder="Selecione"
                  [options]="opcoesAlmoxarifado" [(ngModel)]="pedidoItem.empresaAlmoxarifado.id" appendTo="body"
                  [disabled]="!pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado"></p-dropdown>
              </div>
              <div class="form-group col-sm-3" *ngIf="pedidoItem.operacaoRegra.transfEstoque">
                <label>ALMOXARIFADO ORIGEM</label>

                <p-dropdown class="type-p-dropdown altura-cfop" [filter]="true" placeholder="Selecione"
                  [options]="opcoesAlmoxarifado" [(ngModel)]="pedidoItem.empresaAlmoxarifado.id" [disabled]="
                    !pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado
                  " appendTo="body"></p-dropdown>
              </div>
              <div class="form-group col-sm-3" *ngIf="pedidoItem.operacaoRegra.transfEstoque">
                <label>ALMOXARIFADO DESTINO</label>

                <p-dropdown class="type-p-dropdown altura-cfop" [filter]="true" placeholder="Selecione"
                  [options]="opcoesAlmoxarifado" [(ngModel)]="pedidoItem.almoxarifadoDestino.id" [disabled]="
                    !pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado
                  " appendTo="body"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>LOTE</label>
                <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.lote" />
              </div>
              <div class="form-group col-sm-3">
                <label>VALIDADE</label>
                <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" appendTo="body"
                  dateFormat="dd/mm/yy" [(ngModel)]="pedidoItem.dtValidadeEstoque" [monthNavigator]="true"
                  [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>HISTÓRICO</label>
                <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.historicoEstoque" />
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Informações Adicionais" *ngIf="pedido.pedidoTipo.id !== 2">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title">
                <i class="fas fa-file-alt"></i> Informações Adicionais
              </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <textarea class="form-control" rows="5" pInputTextarea value="pedidoItem.dadosAdicionais"
                [(ngModel)]="pedidoItem.dadosAdicionais"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title"><i class="fas fa-file-alt"></i> Saving</h3>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-3">
              <label>valor Saving</label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorSaving"
                money />
            </div>
            <div class="form-group col-sm-12">
              <label>Observações</label>
              <textarea class="form-control" rows="5" pInputTextarea value="pedidoItem.observacaoSaving"
                [(ngModel)]="pedidoItem.observacaoSaving"></textarea>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title"><i class="fas fa-file-alt"></i> INFOS ADICIONAIS ITEM</h3>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-3">
              <label>Pedido</label>
              <input class="form-control text-left" type="text" pInputText [(ngModel)]="pedidoItem.codigoPedidoPart" />
            </div>
            <div class="form-group col-sm-3">
              <label>Código Item</label>
              <input class="form-control text-left" type="text" pInputText [(ngModel)]="pedidoItem.codigoProdPart" />
            </div>
            <div class="form-group col-sm-6">
            </div>
          </div>


        </mat-tab>

        <mat-tab label="Declaração de Importação" *ngIf="pedido.pedidoTipo.id === 3">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title">
                <i class="fas fa-file-alt"></i> Declaração de importação
              </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-5">
              <label>Número DI/DSI/DA/DRI-E<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.numeroDi" />
            </div>
            <div class="form-group col-sm-5">
              <label>Cod. exportador<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.codigoExportador" />
            </div>
            <div class="form-group col-sm-2">
              <label>Data registro<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy"
                [(ngModel)]="pedidoItem.dataRegistroImport" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title mt-0">
                <i class="fas fa-file-alt"></i> Desembaraço aduaneiro
              </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>Uf<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesUf"
                [(ngModel)]="pedidoItem.ufDesembaraco.id" placeholder="Selecione"></p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>Local<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.localDesembaraco" />
            </div>
            <div class="form-group col-sm-3">
              <label>Via Transp.<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesViaTransp"
                [(ngModel)]="pedidoItem.transporteTipo.id" placeholder="Selecione" appendTo="body"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>Data<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy"
                [(ngModel)]="pedidoItem.dataDesembaraco" [monthNavigator]="true" [yearNavigator]="true"
                [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>valor AFRMM</label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedidoItem.valorAFRMM" money />
            </div>
            <div class="form-group col-sm-3">
              <label>Forma de Intermediação<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTipoIntermediacao"
                [(ngModel)]="pedidoItem.tipoIntermediacao.id" placeholder="Selecione"></p-dropdown>
            </div>
            <div class="form-group col-sm-3">
              <label>CNPJ Adquirente</label>
              <input class="form-control text-right" type="text" id="cnpjAdquirente" pInputText
                [(ngModel)]="pedidoItem.cnpjAdquirente" OnlyNumber="true" maxlength="14" />
            </div>
            <div class="form-group col-sm-3">
              <label>Uf adquirente</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesUf"
                [(ngModel)]="this.pedidoItem.ufAdquirente.id" placeholder="Selecione"></p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title mt-0">
                <i class="fas fa-file-alt"></i> Adição
              </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Número<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" (blur)="
                  validaCampoNumero(importacaoAdicao.numero, 'Número da Adição')
                " pInputText [(ngModel)]="importacaoAdicao.numero" />
            </div>
            <div class="form-group col-sm-4">
              <label>Cód. Fabricante<span *ngIf="this.pedidoExterior && this.pedido.pedidoTipo.id === 3"
                  class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="importacaoAdicao.codigoFabricante" />
            </div>
            <div class="form-group col-sm-2">
              <label>Desconto</label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="importacaoAdicao.desconto"
                money />
            </div>
            <div class="form-group col-sm-2">
              <label class="d-block" style="visibility: hidden; margin-top: 6px">DO NOT REMOVE</label>
              <button type="button" class="btn btn-sm btn-info pull-left" (click)="incluirAdicaoImportacao()">
                <i class="fas fa-plus"></i> Adicionar
              </button>
            </div>
          </div>
          <div class="mt-3" *ngIf="pedidoItem.pedidoItensImportAdicao.length > 0">
            <p-table class="table-system" [value]="pedidoItem.pedidoItensImportAdicao" selectionMode="single"
              (onRowSelect)="onRowSelectAdicaoImportacao($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 30%">Número</th>
                  <th style="width: 30%">Código Fabricante</th>
                  <th class="text-right" style="width: 30%">Desconto</th>
                  <th class="text-center" style="width: 10%">Ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 30%">
                    <span matTooltip="{{ rowData.numero }}" class="overflow-ellipse">{{ rowData.numero }}</span>
                  </td>
                  <td style="width: 30%">
                    <span matTooltip="{{ rowData.codigoFabricante }}" class="overflow-ellipse">{{
                      rowData.codigoFabricante }}</span>
                  </td>
                  <td class="text-right">{{ rowData.desconto }}</td>
                  <td class="text-center">
                    <span class="delete" (click)="excluirAdicaoImportacao(rowData.id)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>

        <mat-tab label="Exportação" *ngIf="pedido.pedidoTipo.id === 1">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title mt-3">
                <i class="fas fa-file-alt"></i> Exportação
              </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>N° Ato Concessório</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedidoExportacao.numeroAto" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title mt-0">
                <i class="fas fa-file-alt"></i> Exportação Indireta
              </h3>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-3">
              <label>N° Registro Exportação</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedidoExportacao.numeroRegistro" />
            </div>
            <div class="form-group col-sm-3">
              <label>Qtde. Item Exportado</label>
              <input class="form-control text-left" type="text" pInputText [(ngModel)]="pedidoExportacao.qtdItemExport"
                money />
            </div>
            <div class="form-group col-sm-4">
              <label>Chave Acesso NF-e Recebida</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedidoExportacao.chaveAcessoNfe" />
            </div>
            <div class="form-group col-sm-2">
              <label style="visibility: hidden">NO REMOVE</label>
              <button type="button" class="btn btn-sm btn-info" (click)="validacaoExportacao()">
                <i class="fas fa-plus"></i> Adicionar
              </button>
            </div>
          </div>

          <div class="mt-3" *ngIf="pedidoItem.pedidoItensExportacao.length > 0">
            <p-table class="table-system" [value]="pedidoItem.pedidoItensExportacao" selectionMode="single"
              (onRowSelect)="onRowSelectPedidoExportacao($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 20%">Núm. Ato Concessório</th>
                  <th style="width: 25%">Núm. Reg. de Exportação</th>
                  <th style="width: 25%">Chave de Acesso da NF-e</th>
                  <th class="text-right" style="width: 20%">Qtde. Exportada</th>
                  <th class="text-center" style="width: 10%">Ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 20%">
                    <span matTooltip="{{ rowData.numeroAto }}" class="overflow-ellipse">{{ rowData.numeroAto }}</span>
                  </td>
                  <td style="width: 25%">
                    <span matTooltip="{{ rowData.numeroRegistro }}" class="overflow-ellipse">{{ rowData.numeroRegistro
                      }}</span>
                  </td>
                  <td style="width: 25%">
                    <span matTooltip="{{ rowData.chaveAcessoNfe }}" class="overflow-ellipse">{{ rowData.chaveAcessoNfe
                      }}</span>
                  </td>
                  <td class="text-right">{{ rowData.qtdItemExport }}</td>
                  <td class="text-center">
                    <span class="delete" (click)="excluirItemExportacao(rowData.id)">
                      <i class="fas fa-trash-alt"></i>
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px">
        <div class="ui-g-12"></div>
      </div>
    </div>

    <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="exibirItemPedido && pedidoItem.isServico">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Operação <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
            placeholder="Selecione uma Operacao" [(ngModel)]="pedidoItem.operacaoRegra.id" (onChange)="
              dadosOperacao($event);
              retornaIdOperacaoRegra(
                pedidoItem.operacaoRegra.id,
                pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil
              )
            " appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-12">
          <label>Item <span class="obrigatorio">*</span></label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesItens" placeholder="Selecione o Item"
            [(ngModel)]="pedidoItem.item.id" (onChange)="dadosItem($event, true)" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>Part Number</label>
          <input class="form-control" type="text" pInputText [(ngModel)]="pedidoItem.itemFornecedor" />
        </div>
        <div class="form-group col-sm-2">
          <label>Quantidade <span class="obrigatorio">*</span></label>
          <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.quantidade"
            (blur)="calcularValorTotal(); getTaxBaseAmounts()" />
        </div>
        <div class="form-group col-sm-2">
          <label>Valor Unitário <span class="obrigatorio">*</span></label>
          <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.valorUnit"
            (blur)="calcularValorTotal(); getTaxBaseAmounts();" />
        </div>
        <div class="form-group col-sm-1">
          <label>TIPO</label>
          <p-dropdown [filter]="false" [options]="opcoesDesconto" [(ngModel)]="pedidoItem.tipoDesconto.id"
            (ngModelChange)="calcularValorTotal()" placeholder="Selecione" class="type-p-dropdown altura-ncm"
            appendTo="body">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-2">
          <label>Valor Desconto</label>
          <input class="form-control text-right" type="text" input-text="right" [(ngModel)]="pedidoItem.valorDesconto"
            (blur)="calcularValorTotal()" />
        </div>
        <div class="form-group col-sm-2">
          <label>Valor Total <span class="obrigatorio">*</span></label>
          <input class="form-control text-right" type="text" input-text="right" [disabled]="true"
            [(ngModel)]="pedidoItem.valorTotal" (onChange)="getTaxBaseAmounts()" />
        </div>
      </div>

    </div>

    <p-footer>
      <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}">
        <button type="button" class="btn btn-secondary" (click)="cancelarEdicaoItem()">
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button type="button" class="btn btn-danger" *ngIf="!isReadOnly" (click)="excluirLinhaPedido(pedidoItem.id)">
          <i class="fas fa-times"></i> Excluir
        </button>
        <button type="button" class="btn btn-primary" *ngIf="!botaoEditar" (click)="salvarItemLista(null, false)">
          <i class="fas fa-check"></i> Salvar
        </button>
        <button type="button" class="btn btn-primary" *ngIf="botaoEditar && !isReadOnly" F (click)="alterarItemLista()">
          <i class="fas fa-pencil-alt"></i> Alterar
        </button>
      </div>
    </p-footer>
  </p-dialog>
  <!---Fim Modal Item-->

  <!---Modal Transportador-->
  <p-dialog header="Editar Transportador" [(visible)]="exbirModalTransportador" [modal]="true" styleClass="modal-scroll"
    [responsive]="true" appendTo="body" [closable]="true">
    <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="exbirModalTransportador" style="width: 900px;">
      <mat-tab-group>
        <mat-tab label="Transportador">
          <div class="form-row mt-2">
            <div class="form-group col-sm-6">
              <label>Modalidade do Frete
                <span *ngIf="
                    (pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                    (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                    (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)
                  " class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTipoFrete"
                [(ngModel)]="pedido.tipoFrete.id"></p-dropdown>
            </div>
            <div class="form-group col-sm-6">
              <label>Transportador
                <span *ngIf="
                    ((pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) &&
                    (this.pedido.tipoFrete.id === 1 ||
                      this.pedido.tipoFrete.id === 2 ||
                      this.pedido.tipoFrete.id === 3)
                  " class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesTransportador"
                [(ngModel)]="pedido.transportador.id" [disabled]="
                  pedido.tipoFrete.id === 6 ||
                  this.pedido.tipoFrete.id === 5 ||
                  this.pedido.tipoFrete.id === 4
                ">
              </p-dropdown>
            </div>
          </div>
          <!--<div class="lks-form-grid">

        <div class="input-3" input-vertical="middle"><label>Identificação do Vagão</label></div>
        <div class="input-3 type-p-dropdown" input-vertical="middle">
            <input type="text" input-text="left" pInputText [(ngModel)]="pedidoItemEditar.vlrAliquotaCofins"/>
        </div>

        <div class="input-1"></div>

        <div class="input-2" input-vertical="middle"><label>Identificação do Balsa</label></div>
        <div class="input-3" input-vertical="middle">
          <input type="text" input-text="left" pInputText [(ngModel)]="pedidoItemEditar.vlrAliquotaCofins"/>
        </div>

      </div>-->
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Código ANTT</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.codigoAntt"
                [disabled]="pedido.tipoFrete.id === 6" />
            </div>
            <div class="form-group col-sm-4">
              <label>Placa Veículo</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.placaVeiculo"
                [disabled]="pedido.tipoFrete.id === 6" />
            </div>
            <div class="form-group col-sm-4">
              <label>UF</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesUf"
                [disabled]="pedido.tipoFrete.id === 6" [(ngModel)]="pedido.ufPlaca.id"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Quantidade
                <span *ngIf="
                    ((pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) &&
                    this.pedido.tipoFrete.id != 6
                  " class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedido.qVol"
                [disabled]="pedido.tipoFrete.id === 6" />
            </div>
            <div class="form-group col-sm-4">
              <label>Espécie
                <span *ngIf="
                    ((pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) &&
                    this.pedido.tipoFrete.id != 6
                  " class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.especieVol"
                [disabled]="pedido.tipoFrete.id === 6" />
            </div>
            <div class="form-group col-sm-4">
              <label>Marca</label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.marcaVol"
                [disabled]="pedido.tipoFrete.id === 6" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>Peso Bruto
                <span *ngIf="
                    ((pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) &&
                    this.pedido.tipoFrete.id != 6
                  " class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedido.pesoB"
                [disabled]="pedido.tipoFrete.id === 6" weight />
            </div>
            <div class="form-group col-sm-4">
              <label>Peso Líquido
                <span *ngIf="
                    ((pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) &&
                    this.pedido.tipoFrete.id != 6
                  " class="obrigatorio">*</span></label>
              <input class="form-control text-right" type="text" pInputText [(ngModel)]="pedido.pesoL"
                [disabled]="pedido.tipoFrete.id === 6" weight />
            </div>
            <div class="form-group col-sm-4">
              <label>Numeração
                <span *ngIf="
                    ((pedido.pedidoTipo.id === 3 && !this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 3 && this.pedidoExterior) ||
                      (pedido.pedidoTipo.id === 1 && !this.pedidoExterior)) &&
                    this.pedido.tipoFrete.id != 6
                  " class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.numeracaoVol"
                [disabled]="pedido.tipoFrete.id === 6" />
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Local Exportação">
          <div class="row">
            <div class="col-sm-12 mt-2">
              <h3 class="sub-title">
                <i class="fas fa-warehouse"></i> Local Exportação
              </h3>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Uf Embarque
                <span *ngIf="pedido.pedidoTipo.id === 1 && this.pedidoExterior" class="obrigatorio">*</span></label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesUf"
                [(ngModel)]="pedido.embarqueUf.id"></p-dropdown>
            </div>
            <div class="form-group col-sm-6">
              <label>Local Embarque
                <span *ngIf="pedido.pedidoTipo.id === 1 && this.pedidoExterior" class="obrigatorio">*</span></label>
              <input class="form-control" type="text" pInputText [(ngModel)]="pedido.embarqueLocal"
                placeholder="Local embarque. Ex: Campinas" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Local Despacho
                <span *ngIf="pedido.pedidoTipo.id === 1 && this.pedidoExterior" class="obrigatorio">*</span></label>
              <input class="form-control" type="text" [(ngModel)]="pedido.embarqueDescricao"
                placeholder="Local despacho. Ex: Aeroporto ViraCapos" />
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px">
        <div class="ui-g-12"></div>
      </div>
    </div>
    <p-footer>
      <div class="lks-modal-button {{
          isReadOnly ? 'lks-form-grid-readOnly' : ''
        }}">
        <button type="button" class="btn btn-secondary" (click)="exbirModalTransportador = false; pedido.tipoFrete.id = 999">
          <i class="fas fa-undo"></i> Cancelar
        </button>
        <button type="button" class="btn btn-primary" *ngIf="!isReadOnly" (click)="atualizarTranspPedido()">
          <i class="fas fa-check"></i> Salvar
        </button>
      </div>
    </p-footer>
  </p-dialog>
  <!---Fim Modal Transportador-->


  <!---Modal Compensar-->
  <p-dialog header="Compensar Adiantamentos" [(visible)]="modalCompensacao" [modal]="true"
    styleClass="adiantamento-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="modalCompensacao" style="width: 900px;">
      <mat-tab-group (selectedTabChange)="tabChange($event)">
        <mat-tab label="Próprio">
          <!--Adiantamentos para este participante-->

          <div class="mt-3" id="tabela_edit_participantes">
            <p-table [scrollable]="true" scrollHeight="350px" class="table-system" [value]="adtosPart"
              selectionMode="false">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 10%">Data</th>
                  <th style="width: 14%">Fornecedor</th>
                  <th style="width: 14%">Histórico</th>
                  <th class="text-right" style="width: 14%">Valor</th>
                  <th class="text-right" style="width: 14%">Saldo</th>
                  <th class="text-right" style="width: 20%">
                    Valor a compensar
                  </th>
                  <th class="text-right" style="width: 14%">Saldo Final</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px">
                  <td style="width: 10%">{{ rowData.data }}</td>
                  <td style="width: 14%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.nomePart }}">{{ rowData.codPart }}-{{
                      rowData.nomePart }}</span>
                  </td>

                  <td style="width: 14%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.descricao }}">{{ rowData.descricao }}</span>
                  </td>
                  <td style="width: 14%" class="text-right">
                    {{ rowData.valor | money }}
                  </td>
                  <td style="width: 14%" class="text-right">
                    {{ rowData.saldo | money }}
                  </td>
                  <td style="width: 20%" class="text-right" pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div class="form-group col-sm-12 p-0">
                          <input class="form-control text-right" type="text" [(ngModel)]="rowData.compensar"
                            [disabled]="isReadOnly" money />
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ rowData.compensar | money }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="width: 14%" class="text-right">
                    {{ rowData.saldoFinal | money }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
        <mat-tab label="Outros">
          <!--Adiantamentos para outros participantes-->

          <div class="mt-3" id="tabela_edit_outros">
            <p-table [scrollable]="true" scrollHeight="350px" class="table-system" [value]="adtosOutrosPart"
              selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 10%">Data</th>
                  <th style="width: 14%">Fornecedor</th>
                  <th style="width: 14%">Histórico</th>
                  <th class="text-right" style="width: 14%">Valor</th>
                  <th class="text-right" style="width: 14%">Saldo</th>
                  <th class="text-right" style="width: 20%">
                    Valor a compensar
                  </th>
                  <th class="text-right" style="width: 14%">Saldo Final</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px">
                  <td style="width: 10%">{{ rowData.data }}</td>
                  <td style="width: 14%">
                    <span matTooltip="{{ rowData.codPart }}-{{ rowData.nomePart }}" class="overflow-ellipse">{{
                      rowData.codPart }}-{{ rowData.nomePart }}</span>
                  </td>
                  <td style="width: 14%">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.descricao }}">{{ rowData.descricao }}</span>
                  </td>
                  <td style="width: 14%" class="text-right">
                    {{ rowData.valor | money }}
                  </td>
                  <td style="width: 14%" class="text-right">
                    {{ rowData.saldo | money }}
                  </td>
                  <td style="width: 20%" class="text-right" pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <div class="form-group col-sm-12 p-0">
                          <input class="form-control text-right" type="text" [(ngModel)]="rowData.compensar" money />
                        </div>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ rowData.compensar | money }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="width: 14%" class="text-right">
                    {{ rowData.saldoFinal | money }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="ui-g">
      <div class="ui-g-12"></div>
    </div>
    <p-footer>
      <label class="d-block mb-3"><strong>Valor total a compensar:</strong>
        {{ valorTotalCompensar | money }}</label>
      <button type="button" class="btn btn-secondary" (click)="salvarCompensacao()">
        <i class="fas fa-undo"></i> Fechar
      </button>
    </p-footer>
  </p-dialog>
  <!---Fim Modal Compensar-->

  <!---Modal Formas Pgtos-->
  <p-dialog header="Parcelas" [(visible)]="modalFormaPgto" [modal]="true" styleClass="modal-scroll" [responsive]="true"
    appendTo="body" [closable]="true">
    <div class="form-row" *ngIf="modalFormaPgto" style="width: 900px;">
      <div class="form-group col-sm-3">
        <label>Qtde Parcelas</label>
        <input class="form-control text-left" type="number" pInputText [(ngModel)]="qtdParcelas" min="0"
          [disabled]="parcelasExatas" />
      </div>
      <div class="form-group col-sm-3 pull-left">
        <label>Primeiro Venc.</label>
        <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" appendTo="body" [monthNavigator]="true"
          [yearNavigator]="true" yearRange="2010:2030" [(ngModel)]="primeroVencimento" dateFormat="dd/mm/yy"
          [defaultDate]="defaultDate">
        </p-calendar>
      </div>
      <div class="form-group col-sm-6 d-flex align-items-end justify-content-end"
        *ngIf="pedido.empresaConta.id && pedido.formaPagamento.id">
        <div class="btnCustom-default" matTooltip="Calcular parcelas" (click)="formaPgtoCalcular(true)">
          <i class="material-icons">calculate</i>
          <div>Calcular</div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <div id="tabela_edit_VCTOS">
          <p-table [value]="pedido.pedidoParcela" class="table-system">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 10%" class="text-center">Parcela</th>
                <th style="width: 12%" class="text-center">Vencimento</th>
                <th style="width: 12%" class="text-right">Valor</th>
                <th style="width: 12%" class="text-center">Conta</th>
                <th style="width: 12%" class="text-left">Tipo</th>
                <th style="width: 12%" class="text-left">Tipo Pagamento</th>
                <th style="width: 30%">Histórico</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td pEditableColumn style="width: 10%" class="text-center">
                  <p-cellEditor>
                    <ng-template pTemplate="output">{{ rowData.parcela }}</ng-template>
                    <ng-template pTemplate="input">{{ rowData.parcela }}</ng-template>
                  </p-cellEditor>
                </td>
                <td class="text-center" style="width: 12%">
                  <p-calendar *ngIf="rowData.calendarState" class="type-p-calendar" appendTo="body"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                    [(ngModel)]="rowData.dtVencimento" dateFormat="dd/mm/yy" (onClose)="rowCalendarState(rowData)">
                  </p-calendar>

                  <span class="text-overflow-dynamic-container" *ngIf="!rowData.calendarState"
                    (click)="rowCalendarState(rowData)">
                    <span class="text-overflow-dynamic-ellipsis">{{ rowData.dtVencimento | date:'dd/MM/yyyy' : '+000'
                      }}</span>
                  </span>
                </td>
                <td pEditableColumn style="width: 12%" class="text-right">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="col-sm-12 p-0">
                        <input class="form-control text-right" type="text" [(ngModel)]="rowData.valor" money
                          (keyup)="calculaValorParcelas()" />
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">{{ rowData.valor | money }}</ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 12%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="col-sm-12 p-0">
                        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
                          [disabled]="!permiteAlterarConta" placeholder="Selecione"
                          [(ngModel)]="rowData.empresaConta.id" (onChange)="setEmpresaConta(rowData)"></p-dropdown>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output" class="overflow-ellipse">
                      <span matTooltip="{{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}">
                        {{ rowData.empresaConta ? rowData.empresaConta.nome : rowData.empresaConta }}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 12%" class="text-left">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="col-sm-12 p-0">
                        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposBaixas"
                          placeholder="Selecione" [(ngModel)]="rowData.tipoBaixa.id"
                          (onChange)="setTipoBaixa(rowData)">
                        </p-dropdown>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span class="overflow-ellipse"
                        matTooltip="{{rowData.tipoBaixa ? rowData.tipoBaixa.descricao : rowData.tipoBaixa}}">
                        {{rowData.tipoBaixa ? rowData.tipoBaixa.descricao : "Clique para Editar"}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 12%" class="text-left">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="col-sm-12 p-0">
                        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposPagamentos"
                          placeholder="Selecione" [(ngModel)]="rowData.tipoPagamentoId"
                          (onChange)="setTipoPagamento(rowData)">
                        </p-dropdown>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span class="overflow-ellipse"
                        matTooltip="{{rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : null}}">
                        {{rowData.tipoPagamentoId ? rowData.tipoPagamentoDesc : "Clique para editar"}}
                      </span>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="width: 30%">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="col-sm-12 p-0">
                        <input class="form-control" type="text" [(ngModel)]="rowData.historico" />
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">{{ rowData.historico ? rowData.historico : "Clique para Editar" }}
                    </ng-template>
                  </p-cellEditor>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="footer">
              <tr>
                <td [attr.colspan]="2" class="text-right">TOTAL:</td>
                <td style="width: 12%;" class="text-right">{{ valorTotalParcelas | money }}</td>
                <td [attr.colspan]="4" style="width: 18%"></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px">
        <div class="ui-g-12"></div>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-secondary" (click)="
          modalFormaPgto = false; qtdParcelas = null; primeroVencimento = null
        ">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button class="btn btn-primary" (click)="formaPgtoSalvar()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
  </p-dialog>
  <!---Fim Formas Pgtos-->

  <!---Modal Escrituração Pgtos-->
  <p-dialog header="Escrituração Contábil e Fiscal: {{ pedido.codigo }}" [(visible)]="visualizarContabil" [modal]="true"
    styleClass="contabil-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="{{ isReadOnly ? 'lks-form-grid-readOnly' : '' }}" *ngIf="visualizarContabil"
      [style]="{ width: '1000px' }">
      <mat-tab-group>
        <mat-tab label="Escrituração Contábil">
          <div class="mt-2">
            <p-table [value]="visualizacaoContabil" class="table-system" [rows]="rows" [paginator]="false"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 45%">COD CTA</th>
                  <th style="width: 5%" class="text-center">D/C</th>
                  <th style="width: 10%" class="text-right">VALOR</th>
                  <th style="width: 20%">CENTRO CUSTO</th>
                  <th style="width: 20%">PROJETO</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr style="height: 25px">
                  <td class="text-left">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                  </td>
                  <td class="text-center">
                    {{ rowData.indDc }}
                  </td>
                  <td class="text-right">
                    {{ rowData.valor | money }}
                  </td>
                  <td class="text-left">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.centroCusto }}">{{ rowData.centroCusto
                      }}</span>
                  </td>
                  <td class="text-left">
                    <span class="overflow-ellipse" matTooltip="{{ rowData.cadProj }}">{{ rowData.cadProj }}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
        <mat-tab label="Escrituração Fiscal">
          <div class="mt-2">
            <p-table [value]="visualizacaoFiscal" class="table-system" [scrollable]="true">
              <ng-template pTemplate="header">
                <tr>
                  <th>CFOP</th>
                  <th>CST ICMS</th>
                  <th>BASE ICMS</th>
                  <th>ALIQ ICMS</th>
                  <th>VLR ICMS</th>
                  <th>CST IPI</th>
                  <th>BASE IPI</th>
                  <th>ALIQ IPI</th>
                  <th>VLR IPI</th>
                  <th>CST PIS</th>
                  <th>BASE PIS</th>
                  <th>ALIQ PIS</th>
                  <th>VLR PIS</th>
                  <th>CST COF</th>
                  <th>BASE COF</th>
                  <th>ALIQ COF</th>
                  <th>VLR COF</th>
                  <th>BASE ICMS ST</th>
                  <th>ALIQ ICMS ST</th>
                  <th>VLR ICMS ST</th>
                  <th>VLR ISS</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr style="height: 25px">
                  <td style="text-align: right">
                    {{ rowData.cfop }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.cstIcms }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorBcIcms | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.aliquotaIcms | money }} %
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorIcms | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.cstIpi }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorBcIpi | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.aliquotaIpi | money }} %
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorIpi | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.cstPis }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorBcPis | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.perAliquotaPis | money }} %
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorPis | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.cstCofins }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorBcCofins | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.perAliquotaCofins | money }} %
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorCofins | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorBcIcmsSt | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.aliquotaIcmsSt | money }} %
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorIcmsSt | money }}
                  </td>
                  <td style="text-align: right">
                    {{ rowData.valorIss | money }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-11">
          Última Atualização: {{ pedido.dataUltimaAtualizacao }}
        </div>
        <div class="ui-g-1">
          <button type="button" class="btn btn-secondary" (click)="visualizarContabil = false">
            <i class="fas fa-undo"></i> Fechar
          </button>
        </div>
      </div>
    </p-footer>
  </p-dialog>
  <!---Fim Modal Escrituração Pgtos-->

  <!---Modal Clone Pedido-->
  <p-dialog header="Clonar Pedido" [(visible)]="modalClonePedido" [modal]="true" styleClass="clonar-altura-inicial"
    [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalClonePedido">
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>STATUS</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusPedido"
            placeholder="Selecione Status Clone" [(ngModel)]="cloneStatusPedido" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>DATA ENTREGA</label>
          <p-calendar appendTo="body" (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="pedido.dtEntrega" [monthNavigator]="true" [(ngModel)]="pedido.dtEntrega" [yearNavigator]="true"
            [defaultDate]="defaultDate" yearRange="2010:2030"></p-calendar>
        </div>
        <div class="form-group col-sm-3">
          <label>DATA EMISSÃO</label>
          <p-calendar appendTo="body" (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="pedido.dtEmissao" [monthNavigator]="true" [minDate]="pedido.dtFaturamentoAutomatico"
            [(ngModel)]="pedido.dtEmissao" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030">
          </p-calendar>
        </div>
        <div class="form-group col-sm-3">
          <label>DATA ENTRADA/SAIDA</label>
          <p-calendar appendTo="body" (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true"
            [(ngModel)]="pedido.dtFaturamentoAutomatico" [yearNavigator]="true" [defaultDate]="defaultDate"
            yearRange="2010:2030"></p-calendar>
        </div>
      </div>
      <div class="d-flex align-items-end">
        <div class="form-group">
          <label>PARTICIPANTE</label>
          <p>
            {{ pedido.participante.id ? pedido.participante.codigo +'-'+ pedido.participante.fantasia :
            'Não informado'}}
          </p>
        </div>
        <span class="form-group ml-2" matTooltip="Alterar Participante" (click)="openModal()">
          <span class="material-icons" style="color: #666; font-size: 18px;cursor: pointer;">edit</span>
        </span>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalClonePedido = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="clonarPedido()">
        <i class="fas fa-check"></i> Clonar
      </button>
    </p-footer>
  </p-dialog>
  <!---Fim Clone Pedido-->



  <!---Modal Clone Lote Pedidos-->
  <p-dialog header="Clonar Lote Pedidos" [(visible)]="modalClonePedidoLote" [modal]="true"
    styleClass="lote-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalClonePedidoLote" style="width: 900px;">
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>STATUS</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusPedido"
            placeholder="Selecione Status Clone" [(ngModel)]="cloneStatusPedido" appendTo="body"></p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>DATA ENTREGA</label>
          <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="pedido.dtEntrega" [monthNavigator]="true" [(ngModel)]="pedido.dtEntrega" [yearNavigator]="true"
            [defaultDate]="defaultDate" yearRange="2010:2030" appendTo="body"></p-calendar>
        </div>
        <div class="form-group col-sm-3">
          <label>DATA EMISSÃO</label>
          <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="pedido.dtEmissao" [monthNavigator]="true" [minDate]="pedido.dtFaturamentoAutomatico"
            [(ngModel)]="pedido.dtEmissao" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
            appendTo="body">
          </p-calendar>
        </div>
        <div class="form-group col-sm-3">
          <label>DATA ENTRADA/SAIDA</label>
          <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" dateFormat="dd/mm/yy"
            [(ngModel)]="pedido.dtFaturamentoAutomatico" [monthNavigator]="true"
            [(ngModel)]="pedido.dtFaturamentoAutomatico" [yearNavigator]="true" [defaultDate]="defaultDate"
            yearRange="2010:2030" appendTo="body"></p-calendar>
        </div>
      </div>
      <div class="ui-g" style="position: fixed; right: 25px; bottom: 30px">
        <div class="ui-g-12"></div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalClonePedidoLote = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="clonarPedidosLote()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
  </p-dialog>
  <!---Fim Clone Lote Pedidos-->

  <!-- Modal Alterar Período FEITO-->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [modal]="true" styleClass="modal-initial"
    [responsive]="true" [contentStyle]="{ overflow: 'visible' }" appendTo="body" [closable]="true">
    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataIni" [(ngModel)]="dataIni"
            dateFormat="dd/mm/yy"></p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar" [monthNavigator]="true"
            [yearNavigator]="true" yearRange="2010:2030" [defaultDate]="dataFin" [(ngModel)]="dataFin"
            dateFormat="dd/mm/yy"></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px">
      <div class="ui-g-12"></div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)="closeModalPeriodo()">
            <i class="fas fa-undo"></i> Cancelar
          </button>
          <button type="button" class="btn btn-primary" (click)="alterarPeriodo()">
            <i class="fas fa-pencil-alt"></i> Alterar
          </button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <!---Modal Status Lote Pedidos-->
  <p-dialog header="Altera Status Lote Pedidos" [(visible)]="modalStatusPedidoLote" [modal]="true"
    styleClass="status-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalStatusPedidoLote" style="width: 900px;">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>STATUS</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusAprovacao"
            placeholder="Selecione Status" [(ngModel)]="cloneStatusPedido" appendTo="body"></p-dropdown>
        </div>
      </div>
    </div>
    <p-footer>
      <button class="btn btn-secondary" (click)="modalStatusPedidoLote = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button class="btn btn-primary" (click)="aprovarPedidosLote()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
  </p-dialog>
  <!---Fim Status Lote Pedidos-->

  <!---Modal Referenciar Nfe FEITO-->
  <p-dialog header="Referenciar NFe" [(visible)]="modalReferenciarNfe" [modal]="true"
    styleClass="referenciar-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalReferenciarNfe" style="width: 900px;">
      <mat-tab-group (selectedTabChange)="onLinkClick($event)">
        <mat-tab label="Notas Fiscais">
          <div class="mt-1">
            <div class="pull-right align-down-table">
              <button type="button" class="btn btn-link" (click)="dt11.exportCSV()">
                <i class="fas fa-file-excel excel"></i> Exportar para Excel
              </button>
            </div>
            <div class="clearfix"></div>

            <p-table class="table-system" #dt11 [columns]="nfsParticipantes" [value]="nfsParticipantes" [rows]="rows"
              [paginator]="true" [totalRecords]="nfsParticipantes.length" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{ width: '100%' }"
              (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple"
              (onHeaderCheckboxToggle)="selectAll($event)">
              <ng-template pTemplate="header" let-rowData>
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="width: 10%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="data">
                      <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon field="data"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueData5" (input)="
                        dt11.filter(filterValueData5, 'data', 'contains')
                      " />
                  </th>
                  <th class="text-left" style="width: 10%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="cnpjEmit">
                      <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon field="cnpjEmit"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCnpj5" (input)="
                        dt11.filter(filterValueCnpj5, 'cnpjEmit', 'contains')
                      " />
                  </th>

                  <th class="text-left" style="width: 25%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="nomePart">
                      <span class="text-overflow-dynamic-ellipsis">PARTICIPANTE <p-sortIcon field="nomePart">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueParticipant5"
                      (input)="
                        dt11.filter(
                          filterValueParticipant5,
                          'nomePart',
                          'contains'
                        )
                      " />
                  </th>

                  <th class="text-left" style="width: 10%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="numDoc">
                      <span class="text-overflow-dynamic-ellipsis">NÚMERO <p-sortIcon field="numDoc"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNumber5" (input)="
                        dt11.filter(filterValueNumber5, 'numDoc', 'contains')
                      " />
                  </th>

                  <th class="text-left" style="width: 20%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="chvNfe">
                      <span class="text-overflow-dynamic-ellipsis">CHAVE ELETRÔNICA
                        <p-sortIcon field="chvNfe"> </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueChaveEletronica5"
                      (input)="
                        dt11.filter(
                          filterValueChaveEletronica5,
                          'chvNfe',
                          'contains'
                        )
                      " />
                  </th>

                  <th class="text-center" style="width: 10%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="cfop">
                      <span class="text-overflow-dynamic-ellipsis">CFOP <p-sortIcon field="cfop"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCFOP5" (input)="
                        dt11.filter(filterValueCFOP5, 'cfop', 'contains')
                      " />
                  </th>

                  <th class="text-center" style="width: 10%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="valorNfe">
                      <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon field="valorNfe"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValor5" (input)="
                        dt11.filter(filterValueValor5, 'valorNfe', 'contains')
                      " />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="text-center" style="width: 10%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.data }}">{{ rowData.data
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cnpjEmit }}">{{
                        rowData.cnpjEmit }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 25%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.nomePart }}">{{
                        rowData.nomePart }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.numDoc }}">{{ rowData.numDoc
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 20%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.chvNfe }}">{{ rowData.chvNfe
                        }}</span>
                    </span>
                  </td>

                  <td class="text-center" style="width: 10%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cfopDesc }}">{{
                        rowData.cfopDesc }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 10%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valorNfe | money }}">{{
                        rowData.valorNfe | money }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="8" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro
                      Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
        <mat-tab label="Itens">
          <div class="mt-3">
            <p-table class="table-system" #dt12 [value]="itensNfsParticipantes" [rows]="rows" [paginator]="true"
              [totalRecords]="itensNfsParticipantes.length" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              selectionMode="single" [style]="{ width: '100%' }">
              <ng-template pTemplate="header" let-rowData>
                <tr>
                  <th class="text-left" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="document">
                      <span class="text-overflow-dynamic-ellipsis">NF <p-sortIcon field="document"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueNf6" (input)="
                        dt12.filter(filterValueNf6, 'document', 'contains')
                      " />
                  </th>
                  <th class="text-left" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="emissao">
                      <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon field="emissao"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueData6" (input)="
                        dt12.filter(filterValueData6, 'emissao', 'contains')
                      " />
                  </th>

                  <th class="text-left" style="width: 10%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="item">
                      <span class="text-overflow-dynamic-ellipsis">ITEM <p-sortIcon field="item"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueItem6" (input)="
                        dt12.filter(filterValueItem6, 'nomePart', 'contains')
                      " />
                  </th>

                  <th *ngIf="pedido.nfeFinalidade.id === 1" class="text-left" style="width: 8%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="exibirRelacItem">
                      <span class="text-overflow-dynamic-ellipsis">ITEM RELACIONADO
                        <p-sortIcon field="exibirRelacItem"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueItemRelacionado6"
                      (input)="
                        dt12.filter(
                          filterValueItemRelacionado6,
                          'exibirRelacItem',
                          'contains'
                        )
                      " />
                  </th>

                  <th class="text-left" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="operacao">
                      <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon field="operacao"> </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueOperacao6" (input)="
                        dt12.filter(
                          filterValueOperacao6,
                          'operacao',
                          'contains'
                        )
                      " />
                  </th>

                  <th class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="cfop">
                      <span class="text-overflow-dynamic-ellipsis">CFOP <p-sortIcon field="cfop"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueCFOP6" (input)="
                        dt12.filter(filterValueCFOP6, 'cfop', 'contains')
                      " />
                  </th>

                  <th class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="quantidade">
                      <span class="text-overflow-dynamic-ellipsis">QUANTIDADE <p-sortIcon field="quantidade">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueQuantidade6"
                      (input)="
                        dt12.filter(
                          filterValueQuantidade6,
                          'quantidade',
                          'contains'
                        )
                      " />
                  </th>

                  <th *ngIf="!(pedido.nfeFinalidade.id === 1)" class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="saldo">
                      <span class="text-overflow-dynamic-ellipsis">SALDO <p-sortIcon field="saldo"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueSaldo6" (input)="
                        dt12.filter(filterValueSaldo6, 'saldo', 'contains')
                      " />
                  </th>

                  <th *ngIf="!(pedido.nfeFinalidade.id === 1)" class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="utilizar">
                      <span class="text-overflow-dynamic-ellipsis">UTILIZAR <p-sortIcon field="utilizar"> </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueUtilizar6" (input)="
                        dt12.filter(
                          filterValueUtilizar6,
                          'utilizar',
                          'contains'
                        )
                      " />
                  </th>

                  <th *ngIf="!(pedido.nfeFinalidade.id === 1)" class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="saldoFinal">
                      <span class="text-overflow-dynamic-ellipsis">SALDO FINAL
                        <p-sortIcon field="saldoFinal"> </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueSaldoFinal6"
                      (input)="
                        dt12.filter(
                          filterValueSaldoFinal6,
                          'saldoFinal',
                          'contains'
                        )
                      " />
                  </th>

                  <th class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="valorUnit">
                      <span class="text-overflow-dynamic-ellipsis">VL UNIT <p-sortIcon field="valorUnit"> </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValorUnit6" (input)="
                        dt12.filter(
                          filterValueValorUnit6,
                          'valorUnit',
                          'contains'
                        )
                      " />
                  </th>

                  <th class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container" pSortableColumn="valorTotal">
                      <span class="text-overflow-dynamic-ellipsis">VL TOTAL <p-sortIcon field="valorTotal">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text" [(ngModel)]="filterValueValorTotal6"
                      (input)="
                        dt12.filter(
                          filterValueValorTotal6,
                          'valorTotal',
                          'contains'
                        )
                      " />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-left" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.document }}">{{
                        rowData.document }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.emissao }}">{{ rowData.emissao
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.item }}">{{ rowData.item
                        }}</span>
                    </span>
                  </td>
                  <td *ngIf="pedido.nfeFinalidade.id === 1" class="text-left" style="width: 8%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.exibirRelacItem }}">{{
                        rowData.exibirRelacItem }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 5%">
                    <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                      placeholder="Selecione a Operacao" [(ngModel)]="rowData.operacao" (onChange)="
                        dadosOperacaoItem(rowData);
                        replicaOperacaoRegra(rowData)
                      " appendTo="body">
                    </p-dropdown>
                  </td>

                  <td class="text-center" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.cfop }}">{{ rowData.cfop
                        }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.quantidade }}">{{
                        rowData.quantidade }}</span>
                    </span>
                  </td>

                  <td *ngIf="!(pedido.nfeFinalidade.id === 1)" class="text-right" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.saldo }}">{{ rowData.saldo
                        }}</span>
                    </span>
                  </td>

                  <td *ngIf="!(pedido.nfeFinalidade.id === 1)" class="text-right" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.utilizar }}">{{
                        rowData.utilizar }}</span>
                    </span>
                  </td>

                  <td *ngIf="!(pedido.nfeFinalidade.id === 1)" class="text-right" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.saldoFinal }}">{{
                        rowData.saldoFinal }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valorUnit | money }}">{{
                        rowData.valorUnit | money }}</span>
                    </span>
                  </td>

                  <td class="text-right" style="width: 5%">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valorTotal | money }}">{{
                        rowData.valorTotal | money }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro
                      Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalReferenciarNfe = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="salvarReferenciaNfe()">
        <i class="fas fa-check"></i> Salvar
      </button>
    </p-footer>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>DATA TÉRMINO CONTRATO: </label>
          </div>
          <div class="form-group col-sm-8">
            <p-calendar (onInput)="onInputDate($event)" dateFormat="dd/mm/yy" [monthNavigator]="true"
              [(ngModel)]="pedido.dtFinalContrato" [yearNavigator]="true" yearRange="2010:2030">
            </p-calendar>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
  <!--- Fim Modal Referenciar Nfe-->

  <!---Modal Contrato-->
  <p-dialog header="Adicionar Detalhes Contrato" [(visible)]="exibirModalContrato" [modal]="true"
    styleClass="pagamento-altura-inicial" [responsive]="true" appendTo="body" [closable]="true" style="width: 900px;">
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>CONTRATO ATIVO:</label>
      </div>
      <div class="form-group col-sm-8">
        <p-checkbox [(ngModel)]="pedido.contrato"></p-checkbox>
        <label class="ml-1">CONTRATO</label>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-sm-4">
        <label>DATA TÉRMINO CONTRATO: </label>
      </div>
      <div class="form-group col-sm-8">
        <p-calendar dateFormat="dd/mm/yy" [monthNavigator]="true" [(ngModel)]="pedido.dtFinalContrato"
          [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
      </div>
    </div>

    <p-footer>
      <div class="lks-modal-button">
        <button class="btn btn-secondary" (click)="exibirModalContrato = false">
          Cancelar
        </button>
        <button class="btn btn-primary" (click)="gerarContratos()">
          Salvar
        </button>
      </div>
    </p-footer>
  </p-dialog>
  <!---Fim Modal Contrato-->

  <!---Modal Impressão -->
  <p-dialog header="Impressão Pedido" [(visible)]="modalImpressao" [modal]="true" styleClass="clonar-altura-inicial"
    [responsive]="true" appendTo="body" [closable]="true">
    <div *ngIf="modalImpressao">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <p-checkbox class="mr-1" [(ngModel)]="impressaoFormaPagamento" [binary]="true" inputId="impressaoPedido">
          </p-checkbox>
          <label for="impressaoPedido">Exibir detalhes de pagamento</label>
        </div>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalImpressao = false; impressaoFormaPagamento = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="realizarImpressao()">
        <i class="fas fa-print"></i> Imprimir
      </button>
    </p-footer>
  </p-dialog>
  <!---Fim Impressão-->

  <!-- Modal Cupom Fiscal -->
  <p-dialog header="Cupom Fiscal" [(visible)]="modalCupomFiscal" [modal]="true" styleClass="clonar-altura-inicial"
    [responsive]="true" appendTo="body" [closable]="true">
    <div class="form-group col-sm-12" style="width: 900px;">
      <p-table class="table-system" #cupomTable [value]="listaCupons" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)" selectionMode="multiple" (onHeaderCheckboxToggle)="selectAll($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox">
              <span class="check-all p-0">
                <p-tableHeaderCheckbox (click)="selectTodos($event)">
                </p-tableHeaderCheckbox>
              </span>
            </th>
            <th class="text-left" style="width: 14%">
              <span pSortableColumn="dt_hora_emissao">
                <span>DT EMISSÃO <p-sortIcon field="dt_hora_emissao"></p-sortIcon></span>
              </span>
              <input type="text" [(ngModel)]="filterValueHoraEmissao7" (input)="
                  cupomTable.filter(
                    filterValueHoraEmissao7,
                    'dt_hora_emissao',
                    'contains'
                  )
                " />
            </th>
            <th class="text-right" style="width: 10%">
              <span pSortableColumn="valor">
                <span>VALOR <p-sortIcon field="valor"></p-sortIcon></span>
              </span>
              <input type="text" [(ngModel)]="filterValueValor7" (input)="
                  cupomTable.filter(filterValueValor7, 'valor', 'contains')
                " />
            </th>
            <th class="text-left" style="width: 16%">
              <span pSortableColumn="cpf_cnpj">
                <span>CNPJ/CPF <p-sortIcon field="cpf_cnpj"></p-sortIcon></span>
              </span>
              <input type="text" [(ngModel)]="filterValueCpfCnpj7" (input)="
                  cupomTable.filter(filterValueCpfCnpj7, 'cpf_cnpj', 'contains')
                " />
            </th>
            <th class="text-left" style="width: 32%">
              <span pSortableColumn="nome">
                <span>NOME <p-sortIcon field="nome"></p-sortIcon></span>
              </span>
              <input type="text" [(ngModel)]="filterValueNome7" (input)="
                  cupomTable.filter(filterValueNome7, 'nome', 'contains')
                " />
            </th>
            <th class="text-center" style="width: 11%">
              <span pSortableColumn="codigo_ccf">
                <span>CÓD. CCF <p-sortIcon field="codigo_ccf"></p-sortIcon></span>
              </span>
              <input type="text" [(ngModel)]="filterValueCodigoCcf7" (input)="
                  cupomTable.filter(
                    filterValueCodigoCcf7,
                    'codigo_ccf',
                    'contains'
                  )
                " />
            </th>
            <th class="text-center" style="width: 11%">
              <span pSortableColumn="codigo_coo">
                <span>CÓD. COO <p-sortIcon field="codigo_coo"></p-sortIcon></span>
              </span>
              <input type="text" [(ngModel)]="filterValueCodigoCoo7" (input)="
                  cupomTable.filter(
                    filterValueCodigoCoo7,
                    'codigo_coo',
                    'contains'
                  )
                " />
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center tableCheckbox">
              <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left" style="width: 14%">
              <span matTooltip="{{ rowData.itens }}" matTooltipClass="multiline-tooltip">
                {{ rowData.dt_hora_emissao | date: "dd/MM/yyyy HH:mm" }}
              </span>
            </td>
            <td class="text-right" style="width: 10%">
              <span matTooltip="{{ rowData.itens }}" matTooltipClass="multiline-tooltip">
                {{ rowData.valor | money }}
              </span>
            </td>
            <td class="text-left" style="width: 16%">
              <span matTooltip="{{ rowData.itens }}" matTooltipClass="multiline-tooltip">
                {{ rowData.cpf_cnpj }}
              </span>
            </td>
            <td class="text-left" style="width: 32%">
              <span matTooltip="{{ rowData.nome }}" matTooltipClass="multiline-tooltip">
                {{ rowData.nome }}
              </span>
            </td>
            <td class="text-center" style="width: 11%">
              <span matTooltip="{{ rowData.itens }}" matTooltipClass="multiline-tooltip">
                {{ rowData.codigo_ccf }}
              </span>
            </td>
            <td class="text-center" style="width: 11%">
              <span matTooltip="{{ rowData.itens }}" matTooltipClass="multiline-tooltip">
                {{ rowData.codigo_coo }}
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="2" style="font-size: 11px"><b>Total: </b></td>
            <td class="text-right">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" style="user-select: all; cursor: text; font-size: 11px"
                  matTooltip="{{ valorTotalCupons | money }}"><b>{{ valorTotalCupons | money }}</b></span>
              </span>
            </td>
            <td colspan="4"></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Cupom Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <p-footer>
      <button class="btn btn-secondary" (click)="modalCupomFiscal = false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button class="btn btn-primary" (click)="atualizarVincCupom()">
        <i class="fas fa-refresh"></i> Vincular
      </button>
    </p-footer>
  </p-dialog>
  <!-- Fim Modal Cupom Fiscal -->
</mat-card>
<!-- Modal Referenciar Cupom-->
<p-dialog header="Referenciar Cupom" [(visible)]="modalRefCupom" [modal]="true" styleClass="modal-initial"
  [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
  <div class="form-row" style="width: 900px;">
    <div class="form-group col-sm-4">
      <label>MOD DOC</label>
      <input class="form-control" type="text" [(ngModel)]="pedido.codModDoc" maxlength="150" />
    </div>
    <div class="form-group col-sm-4">
      <label>NÚM ORDEM ECF</label>
      <input class="form-control" type="text" [(ngModel)]="pedido.numSeqEcf" maxlength="150" />
    </div>
    <div class="form-group col-sm-4">
      <label>NÚM CONT ORDEM</label>
      <input class="form-control" type="text" [(ngModel)]="pedido.numContOrdem" maxlength="150" />
    </div>

  </div>

  <p-footer>a
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)="modalRefCupom = false"><i class="fas fa-close"></i>
          FECHAR</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Inicio Modal Detalhes Motivo Recusa -->
<p-dialog header="Motivos de recusa" [(visible)]="exibirModalRejeicao" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="row" style="width: 900px;">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-info-circle"></i> Informações da Recusa</h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <textarea class="form-control" disabled="true" rows="8">{{descricaoMotivoRejeicao}}</textarea>
    </div>
  </div>

  <!--<div class="row">
    <div class="col-sm-12">
      <div class="alert alert-warning alertcustom" role="alert">
        <i class="fas fa-warning"></i> Para tentar emitir a NF novamente faça um clone deste pedido.
      </div>
    </div>
  </div>-->
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="exibirModalRejeicao = false;"><i class="fas fa-undo"></i>
      Fechar</button>
    <button class="btn btn-primary" (click)='reenviarNf()'><i class="fas fa-refresh"></i> EMITIR NF</button>
  </p-footer>

</p-dialog>
<!-- Final Modal Detalhes Motivo Recusa -->

<!-- modal edição data de entrega item -->
<p-dialog [header]="modalDeadlineTitle" [(visible)]="modalDeadline" [modal]="true" [responsive]="true" appendTo="body"
  [closable]="true" [draggable]="true">

  <div *ngIf="!isBatch" class="container-deadline">
    <div class="header-deadline">
      <p><b>Participante:</b> {{requestProvider || 'Indisponível'}}</p>
      <p><b>CNPJ:</b> {{requestCNPJ || 'Indisponível'}} <b>Cógido de controle:</b> {{requestCodControl ||
        'Indisponível'}}</p>
    </div>
    <div class="body-deadline">
      <div class="row my-3">
        <div class="col-sm-12">
          <h3 class="sub-title">
            <i class="fas fa-bars"></i> Itens do Pedido
          </h3>
          <p-table class="table-system" #dt [value]="requestItens" [rows]="rows" [rowsPerPageOptions]="rowsPerPage"
            [style]="{'width':'100%'}" selectionMode="multiple" [(selection)]="itensClearArray"
            (onRowSelect)="onItemSelect($event)" (onRowUnselect)="onItemUnselect($event)"
            (onHeaderCheckboxToggle)="onItemAllSelect($event)">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'item.dataEntrega'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Data de entrega <p-sortIcon [field]="'item.dataEntrega'"></p-sortIcon>
                    </span>
                  </span>
                  <input #op class="form-control form-control-sm" type="text"
                    (input)="dt.filter(op.value, 'operacaoRegra.descricao', 'contains')">
                </th>
                <th class="text-left" style="width: 40%">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'item.descricao'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Item <p-sortIcon [field]="'item.descricao'"></p-sortIcon>
                    </span>
                  </span>
                  <input #item class="form-control form-control-sm" type="text"
                    (input)="dt.filter(item.value, 'item.descricao', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacaoRegra.descricao'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Operação <p-sortIcon [field]="'operacaoRegra.descricao'"></p-sortIcon>
                    </span>
                  </span>
                  <input #op class="form-control form-control-sm" type="text"
                    (input)="dt.filter(op.value, 'operacaoRegra.descricao', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'cfop'">
                    <span class="text-overflow-dynamic-ellipsis">
                      CFPOP <p-sortIcon [field]="'cfop'"></p-sortIcon>
                    </span>
                  </span>
                  <input #cfop class="form-control form-control-sm" type="text"
                    (input)="dt.filter(cfop.value, 'cfop', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
                    <span class="text-overflow-dynamic-ellipsis">
                      QTD. <p-sortIcon [field]="'quantidade'"></p-sortIcon>
                    </span>
                  </span>
                  <input #qtd class="form-control form-control-sm" type="text"
                    (input)="dt.filter(qtd.value, 'quantidade', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorUnitario'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Valor unitário <p-sortIcon [field]="'valorUnitario'"></p-sortIcon>
                    </span>
                  </span>
                  <input #vlunt class="form-control form-control-sm" type="text"
                    (input)="dt.filter(vlunt.value, 'valorUnitario', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorTotal'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Valor Total <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
                    </span>
                  </span>
                  <input #vltl class="form-control form-control-sm" type="text"
                    (input)="dt.filter(vltl.value, 'valorTotal', 'contains')">
                </th>
                <th class="text-left">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'observacao'">
                    <span class="text-overflow-dynamic-ellipsis">
                      Osbervação <p-sortIcon [field]="'observacao'"></p-sortIcon>
                    </span>
                  </span>
                  <input #obs class="form-control form-control-sm" type="text"
                    (input)="dt.filter(obs.value, 'observacao', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.dataEntrega}}">{{rowData.dataEntrega}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.item.descricao}}">{{rowData.item.descricao}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.operacaoRegra.cod}} - {{rowData.operacaoRegra.descricao}}">{{rowData.operacaoRegra.cod}}
                      - {{rowData.operacaoRegra.descricao}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cfop}}">{{rowData.cfop}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.quantidade}}">{{rowData.quantidade}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorUnit}}">R$
                      {{rowData.valorUnit}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valorTotal}}">R$
                      {{rowData.valorTotal}}</span>
                  </span>
                </td>
                <td class="text-left">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis"
                      matTooltip="{{rowData.observacao}}">{{rowData.observacao}}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="9" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Estem pedido não possui nenhum item!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-sm-12">
      <div class="row">
        <div class="form-group col-sm-2">
          <label>DATA ENTREGA <span class="obrigatorio">*</span></label>
          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
            yearRange="2010:2030" appendTo="body" [(ngModel)]="itemDate">
          </p-calendar>
        </div>
        <div class="form-group col-sm-10">
          <label>OBSERVAÇÃO<span *ngIf="isBatch" class="obrigatorio">*</span></label>
          <input class="form-control" type="text" pInputText placeholder="Observação" [(ngModel)]="itemObs" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBatch" class="container-deadline">
    <div class="body-deadline">
      <div class="col-sm-12" style="padding: 0 !important">
        <div class="alert alert-xs alert-primary d-inline" role="alert">
          <i class="fas fa-info-circle"></i> Todos os itens dos pedidos selecionados serão afetados por esta alteração!
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalDeadline = false"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="saveRequest(typeReq)"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!-- modal ediçãostatus pedido -->
<p-dialog header="Alterar status da entrega" [(visible)]="modalStatusEntrega" [style]="{width: '400px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Status da entrega</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesStatusEntrega"
        placeholder="Selecione o status" [(ngModel)]="statusEntregaId"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalStatusEntrega=false'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="mudarStatusEntrega()"><i class="fas fa-pencil-alt"></i>
          Alterar</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<!-- Modal Participante-->
<app-modal-participante [(isVisible)]="modalParticipante"
  (onSelectParticipant)="newRequest?newParticipante($event):salvarParticipante($event)"
  [participanteTipoId]="tipo == 0 ? 2 : 1" [saveButtonLabel]="newRequest?'Escolher':'Salvar'">
</app-modal-participante>
