<app-data-table class="cad-aglut-relac"
            mainTitle="Relacionar Aglutinação"
            singleTitle="Relacionar de Aglutinação"
            routerPath="/cad-aglut-relac"
            entityPath="/cadaglutrelac"
            keyField="id"
            [entity]="cadAglutRelac"
            [columns]="this['columns']"
            [controls]="this['controls']">
</app-data-table>
