  <div class="row">

    <div class="col-sm-2">
      <label>Pedido</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="codPed" />
    </div>
    <div class="col-sm-4">
      <label>Participante</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="part" />
    </div>
    <div class="col-sm-2">
      <label>Status</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="status" />
    </div>
    <div class="col-sm-2">
      <label>Cód Controle</label>
      <input class="form-control text-left" [readOnly]="true" type="text"
      [(ngModel)]="codigoControle" />
    </div>




  </div>
