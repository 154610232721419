<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content style="margin-left: -16px;">
    <div class="col-sm-12 col-xxl-12 pt-6">
      <div class="shadow-box" [ngClass]="fullPagRec ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> RELACIONAMENTO DE ITENS XML</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content pb-0" styleClass="modal-initial" style="overflow: hidden;"
          [@changeDivSize]=currentStatePagRec>
          <div class="form-row">
            <div class="form-group col-sm-6" style="padding-left: 0px;">
              <label>SELECIONE O ITEM</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
                appendTo="body" [(ngModel)]="item.id" [options]="opcoesItensEmpresa"
                (onChange)="buscarItensParticipanteEmpresa()" panelStyleClass="custom-width"></p-dropdown>
            </div>
            <div class="form-group col-sm-6">
              <label>SELECIONE O FORNECEDOR</label><br>
              <button *ngIf="participante?.id" matTooltip="Selecione o fornecedor" class="btn btn-info" type="button" (click)="modalFornecedor=true">
                {{ participante.codigo }} - {{ participante.fantasia }} - {{ participante.cnpj }}
                <i class="fas fa-pen"></i>
              </button>
              <button *ngIf="!participante?.id" matTooltip="Selecione o fornecedor" class="btn btn-info" type="button" (click)="modalFornecedor=true">
                Selecione...
                <i class="fas fa-pen"></i>
              </button>
            </div>
            <!-- <div class="form-group col-sm-6">
              <label>SELECIONE O FORNECEDOR</label>
              <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
                appendTo="body" [options]="opcoesParticipante" [(ngModel)]="participante.id"
                (onChange)="buscarItensParticipante()" panelStyleClass="custom-width"></p-dropdown>
            </div> -->
          </div>
          <div class="form-row">

            <p-table class="table-system" #dt0 [columns]="colsItensParticipante" [value]="itensParticipante"
              [rows]="rows" [paginator]="false" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              emptyMessage="Nenhum Registro Encontrado" [scrollable]="contadorItensParticipante >= 20 ? true : false"
              scrollHeight="320px" (onFilter)="onFilterItensPart($event)" (onRowSelect)="selectCheckbox($event)"  (onRowUnselect)="unselectCheckbox($event)" (onHeaderCheckboxToggle)="selectTodos($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 45px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO<p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt0.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 87%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt0.filter($event.target.value, 'descricao', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center" style="width: 45px;">
                    <p-tableCheckbox [value]="rowData">
                  </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 87%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                    </span>
                  </td>


                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <div class="pull-left pb-4"></div>
          </div>
        </div>
      </div>
    </div>



    <div class="form-group col mt-3 pl-4">
      <button type="button" class="btn btn-sm btn-info" (click)="add()" [disabled]="idItens.length === 0"
        matTooltip="Adicionar Item" matTooltipPosition="right"><i class="fas fa-angle-double-down"></i>
        Adicionar</button>
      <button type="button" class="btn btn-sm btn-info" (click)="remove()" [disabled]="removeDisabled"
        matTooltip="Remover Item" matTooltipPosition="right"><i class="fas fa-angle-double-up"></i> Remover</button>
    </div>

    <div class="col-sm-12 col-xxl-12 pt-2" *ngIf="itensRelacionado">
      <div class="shadow-box" [ngClass]="fullPagRec ? 'box-fullscreen' : ''">
        <div class="header-box">
          <div class="header-box-content">
            <ul class="list-inline filter-menu filter-card-header mt-0">
              <li class="list-inline-item" [ngClass]="{active: aba1}"><a (click)="showTela('1')">ITENS RELACIONADOS</a>
              </li>
              <li class="list-inline-item" [ngClass]="{active: aba2}"><a (click)="showTela('2')">OUTROS ITENS</a></li>
            </ul>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainRel"></app-toolbar>

              <!-- <button *ngIf="aba1" type="button" class="btn btn-xs btn-link" (click)="dt20.exportCSV()"
                matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
              <button *ngIf="aba2" type="button" class="btn btn-xs btn-link" (click)="dt30.exportCSV()"
                matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button> -->
            </div>
          </div>
        </div>
        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStatePagRec>

          <div [ngStyle]="aba1 ? {'display': 'block'} : {'display': 'none'}" class="pull-left pr-2">

            <p-table class="table-system" #dt20 [columns]="colsItensVinculadosParticipante"
              [value]="itensVinculadosParticipante" [rows]="20" [paginator]="false"
              emptyMessage="Nenhum Registro Encontrado"
              [scrollable]="contadorItensVinculadosParticipante >= 20 ? true : false" scrollHeight="45vh"
              (onFilter)="onFilterItensVincPart($event)"
              (onHeaderCheckboxToggle)="selectTodosVinculados($event)" (onRowSelect)="selectCheckboxVinculados($event)"  (onRowUnselect)="unselectCheckboxVinculados($event)"
              >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 45px;">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO<p-sortIcon [field]="'codigo'"></p-sortIcon>
                        </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 87%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt20.filter($event.target.value, 'descricao', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center" style="width: 45px;">
                      <p-tableCheckbox [value]="rowData">
                      </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 87%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                    </span>
                  </td>


                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>


            <div class="pull-left pb-4"></div>



          </div>

          <div [ngStyle]="aba2 ? {'display': 'block'} : {'display': 'none'}" class="pull-left pr-2">

            <p-table class="table-system" [columns]="colsItensVinculadosOutros" #dt30 [value]="itensVinculadosOutros"
              emptyMessage="Nenhum Registro Encontrado" scrollHeight="45vh"
              [scrollable]="contadorItensOutros >= 20 ? true : false" (onFilter)="onFilterItensVincOutros($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 45px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoForn'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO<p-sortIcon [field]="'codigoForn'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt30.filter($event.target.value, 'codigoForn', 'contains')">
                  </th>
                  <th class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descriForn'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descriForn'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt30.filter($event.target.value, 'descriForn', 'contains')">
                  </th>

                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigoInt'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigoInt'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt30.filter($event.target.value, 'codigoInt', 'contains')">
                  </th>


                  <th class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descriInt'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descriInt'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt30.filter($event.target.value, 'descriInt', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <th class="text-center" style="width: 45px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>

                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigoForn}}">{{rowData.codigoForn }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descriForn}}">{{rowData.descriForn }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.codigoInt}}">{{rowData.codigoInt }}</span>
                    </span>
                  </td>

                  <td class="text-left" style="width: 40%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descriInt}}">{{rowData.descriInt }}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>



            <div class="pull-left pb-4"></div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Modal Participante-->
<app-modal-participante [(isVisible)]="modalFornecedor" (onSelectParticipant)="salvarParticipante($event)" [participanteTipoId]="2">
</app-modal-participante>