<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content p-4">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content p-3">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registrosTipoArquivo" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              immutable="false" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
              selectionMode="multiple" [(selection)]="select">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left">
                    <span class="overflow-ellipse text-left" [pSortableColumn]="'nome'">
                      Nome <p-sortIcon [field]="'nome'"></p-sortIcon>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'nome', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="overflow-ellipse text-left" [pSortableColumn]="'descricao'">
                      Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                        {{rowData.nome}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">
                        {{rowData.descricao}}
                      </span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content p-4">
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content">
          <form [formGroup]="formulario">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Nome<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText name="nome" formControlName="nome"
                  maxlength="255">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText name="descricao"
                  formControlName="descricao" maxlength="255">
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>