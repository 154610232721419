<script src="ng-pattern-restrict.js"></script>
<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <mat-card-content>
    <div class="box-content " *ngIf="showLista">
      <div class="row">
        <div class="col-sm-12">

          <p-table class="table-system" #dt10 [value]="tiposLista" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
            [style]="{ width: '100%' }">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-center" style="width: 6%;">
                  <span [pSortableColumn]="'codigo'">
                    <span>CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt10.filter($event.target.value, 'codigo', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span [pSortableColumn]="'descricao'">
                    <span>DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt10.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span [pSortableColumn]="'plataformaNome'">
                    <span>PLATAFORMA <p-sortIcon [field]="'plataformaNome'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt10.filter($event.target.value, 'plataformaNome', 'contains')">
                </th>
                <th class="text-center" style="width: 15%;">
                  <span [pSortableColumn]="'chaveAPI'">
                    <span>CHAVE <p-sortIcon [field]="'chaveAPI'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt10.filter($event.target.value, 'chaveAPI', 'contains')">
                </th>
                <th class="text-center" style="width: 6%;">
                  <span [pSortableColumn]="'dataCriacao'">
                    <span>DT CRIAÇÃO <p-sortIcon [field]="'dataCriacao'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt10.filter($event.target.value, 'dataCriacao', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-center" style="width: 6%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codigo }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.codigo }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.descricao }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.plataformaNome }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.plataformaNome }}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.chaveAPI }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.chaveAPI }}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 6%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.dataCriacao }}"
                      (click)="loadComponent('edit', rowData)">{{ rowData.dataCriacao }}</span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="5" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>


        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- EDIT | NEW -->
  <!-- ---------------------------------------------------------------------------------------- -->
  <div class="row">
    <div class="col-sm-12">
      <div class="box-content" *ngIf="showEdit || showNew">

        <div class="form-row">
          <!-- PLATAFORMA -->
          <div class="form-group col-sm-6">
            <label>PLATAFORMA <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione a plataforma"
              [options]="plataformaList" [(ngModel)]="plataformaId" (onChange)="checarCamposValidacao(plataformaId)">
            </p-dropdown>
          </div>

          <!-- CODIGO -->
          <div class="form-group col-sm-6">
            <label>CÓDIGO<span class="obrigatorio">*</span></label>
            <p-inputMask [style]="{'text-transform': 'uppercase'}" matTooltip="Quatro caracteres alfanuméricos."
              [(ngModel)]="codigo" mask="****" placeholder="COD1">
            </p-inputMask>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>CHAVE API<span class="obrigatorio" *ngIf="isRequeridoChave === true">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="chaveAPI" maxlength="200" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>DESCRIÇÃO<span class="obrigatorio">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="descricao" maxlength="200" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-4">
            <label>E-MAIL<span class="obrigatorio" *ngIf="isRequeridoEmail === true">*</span></label>
            <input class="form-control" type="email" [(ngModel)]="email" required="isRequeridoEmail" maxlength="200" />
          </div>
          <div class="form-group col-sm-4">
            <label>USUÁRIO<span class="obrigatorio" *ngIf="isRequeridoUsuario === true">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="usuario" required="isRequeridoUsuario"
              maxlength="200" />
          </div>
          <div class="form-group col-sm-4">
            <label>SENHA<span class="obrigatorio" *ngIf="isRequeridoSenha === true">*</span></label>
            <input class="form-control" type="password" [(ngModel)]="senha" required="isRequeridoSenha"
              maxlength="200" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>MÁXIMO PARCELA</label>
            <input class="form-control" type="number" [(ngModel)]="maxParcela">
          </div>
          <div class="form-group col-sm-6">
            <label>CONTA BANCÁRIA</label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [options]="opcoesContaBancaria"
              placeholder="Selecione a conta Bancária" [(ngModel)]="contaSelecionadaId">
            </p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-sliders-h"> </i> CONFIGURAÇÕES GERAIS</h3>
          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="split"> SPLIT PAGAMENTO </mat-slide-toggle>
          </div>
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="marketplace"> MARKETPLACE </mat-slide-toggle>
          </div>
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="ativo"> ATIVO </mat-slide-toggle>
          </div>


        </div>



        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-sliders-h"> </i> FORMAS DE PAGAMENTO</h3>
          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="boleto"> BOLETO </mat-slide-toggle>
          </div>
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="cartaoCredito"> CARTÃO DE CRÉDITO </mat-slide-toggle>
          </div>
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="assinatura"> ASSINATURA </mat-slide-toggle>
          </div>


        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <button class="btn btn-secondary" (click)="loadComponent('lista', null)"><i class="fas fa-undo"></i>
              Cancelar
            </button>
            <button *ngIf="showEdit" class="btn btn-danger" (click)="salvar(false)"><i class="fas fa-times"></i>
              Excluir
            </button>
            <button class="btn btn-primary" (click)="salvar(true)"><i class="fas fa-check"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>