<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}. Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>




  <div>
    <mat-card-content>
      <div class="box-content pt-2">

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>

        <p-table class="table-system" #dt2 [columns]="colsListaGetnetPlanos" [value]="listaGetnetPlanos" [rows]="rows"
          [paginator]="true" [totalRecords]="listaGetnetPlanos.length" (onFilter)="onFilter($event)"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>

              <th class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'getNetPlanoId'">
                  <span class="text-overflow-dynamic-ellipsis">PLANO ID <p-sortIcon [field]="'getNetPlanoId'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'getNetPlanoId', 'contains')">
              </th>

              <th class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'description'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'description'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'description', 'contains')">
              </th>

              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'installment'">
                  <span class="text-overflow-dynamic-ellipsis">PARCELA <p-sortIcon [field]="'installment'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'installment', 'contains')">
              </th>
              <th class="text-left" style="width: 5%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'amountPt'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'amountPt'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'amountPt', 'contains')">
              </th>

              <th class="text-left" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'createDatePt'">
                  <span class="text-overflow-dynamic-ellipsis">DT. CRIAÇÃO <p-sortIcon [field]="'createDatePt'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'createDatePt', 'contains')">
              </th>

              <th class="text-center" style="width: 6%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'statusPt'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'statusPt'"></p-sortIcon>
                    </span>
                </span>
                <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione"
                  [filter]="false" (onChange)="dt2.filter($event.value, 'statusPt', 'in')"></p-multiSelect>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-left" style="width: 15%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.getNetPlanoId}}">{{rowData.getNetPlanoId }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 20%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.description}}">{{rowData.description }}</span>
                </span>
              </td>
              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.installment}}">{{rowData.installment}}</span>
                </span>
              </td>
              <td class="text-right" style="width: 5%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.amountPt}}">{{rowData.amountPt}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 6%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.createDatePt}}">{{rowData.createDatePt }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" (click)="showModalInformacoes(rowData)"
                    matTooltip="{{rowData.statusPt}}">{{rowData.statusPt }}</span>
                </span>
              </td>




            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="6" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>


      <!--Inicio Modal INFORMACOES-->
      <p-dialog header="INFORMAÇÕES" [(visible)]="modalInformacoes" [style]="{width: '850px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

        <!-- modalConsultor -->
        <div *ngIf="modalInformacoes">

          <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="tabChange($event)">
            <mat-tab label="INFORMAÇÕES">

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>PLANO ID</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.getnetPlanId}}"
                    [(ngModel)]="getNetPlano.getnetPlanId" />
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>SELLER Id</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.sellerId}}"
                    [(ngModel)]="getNetPlano.sellerId" />
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>DESCRIÇÃO</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.description}}"
                    [(ngModel)]="getNetPlano.description" />
                </div>
              </div>


              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label>PARCELAS</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.installment}}"
                    [(ngModel)]="getNetPlano.installment" />
                </div>

                <div class="form-group col-sm-6">
                  <label>VALOR PARCELAS</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.amount}}"
                    [(ngModel)]="getNetPlano.amount" />
                </div>

              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>STATUS</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.status}}"
                    [(ngModel)]="getNetPlano.status" />
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>DATA E HORA DA CRIAÇÃO</label>
                  <input type="text" class="form-control text-left" readonly="true" value="{{getNetPlano.createDatePt}}"
                    [(ngModel)]="getNetPlano.createDatePt" />
                </div>
              </div>



            </mat-tab>


          </mat-tab-group>



          <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
            <div class="ui-g-12">

            </div>
          </div>

        </div>


        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalInformacoes=false;"><i class="fas fa-undo"></i>
            Cancelar</button>

        </p-footer>
      </p-dialog>
      <!--Final Modal INFORMACOES-->




      <!-- Modal Alterar Período -->
      <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
        class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
        [closable]="true">
        <div *ngIf="modalPeriodo">
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>Data Inicial </label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
            </div>
            <div class="form-group col-sm-6">
              <label>Data Final </label>
              <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
                [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
            </div>
          </div>
        </div>
        <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
          <div class="ui-g-12">

          </div>
        </div>
        <p-footer>
          <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="periodoChange();"><i class="fas fa-check"></i>
            Alterar</button>
        </p-footer>
      </p-dialog>
      <!-- Modal Alterar Período -->


    </mat-card-content>
  </div>

</mat-card>