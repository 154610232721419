<div>
  <div class="col-sm-12 status-content" style="padding: 0 !important" *ngIf="!loadingConfigPattern && contaCriada">
    <p><b>ID da conta: </b>{{config.usuario}}</p>
    <p><b>Chave API: </b>{{config.chaveApi}}</p>
    <p><b>Carteira: </b>{{config.walletId}}</p>
    <p><b>Status da conta: </b>{{config.accountStatus}}</p>
    <p><b>Url onboarding: </b><a target="_blank" href="{{config.onboardingUrl}}">{{config.onboardingUrl}}</a></p>
  </div>
  <div class="col-sm-12" style="padding: 0 !important" *ngIf="!loadingConfigPattern && !contaCriada">
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Razão Social <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.razao" (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-3">
        <label>CNPJ (somente números)<span class="obrigatorio">*</span></label>
        <input type="text" maxlength="14" class="form-control" [(ngModel)]="config.cnpj" (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-2">
        <label>Celular <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.telefone" placeholder="(DDD) 999999999"
          (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-4">
        <label>Email <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.email" (keyup)="validationConfig()" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>CEP <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" maxlength="8" [(ngModel)]="config.cep"
          (keyup.enter)="getCompleteAddress()" (blur)="getCompleteAddress()" (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-3">
        <label>Logradouro <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.logradouro" (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-2">
        <label>Complemento</label>
        <input type="text" class="form-control" maxlength="50" [(ngModel)]="config.complemento" />
      </div>
      <div class="form-group col-sm-1">
        <label>Número <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.numero" (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-2">
        <label>Bairro <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.bairro" (keyup)="validationConfig()" />
      </div>
      <div class="form-group col-sm-2">
        <label>Faturamento mensal<span class="obrigatorio">*</span></label>
        <input type="number" class="form-control" [(ngModel)]="faturamento_mensal" (keyup)="validationConfig()"/>
      </div>
    </div>
    <div class="alert alert-xs alert-primary d-inline" role="alert">
      <i class="fas fa-info-circle"></i> As informações entradas aqui devem ser iguais ao cartão CNPJ da Receita
      Federal!
    </div>
  </div>
  <div class="form-row" *ngIf="!loadingConfigPattern && !this.contaCriada">
    <div class="header-form">
      <h6>Termos de uso</h6>
    </div>
    <div class="col-sm-12">
      <div class="form-row">
        <div class="d-flex align-items-center">
          <p-checkbox [(ngModel)]="isAcceptedTerms" (onChange)="emitValidatedSteps()" [binary]="true" inputId="terms" class="mr-2"></p-checkbox>
          <label for="terms">Li e concordo com os <a class="external-link" href="https://aws-website-downloads-e72og.s3.amazonaws.com/TERMOS_DE_USO/Termos+de+Uso+da+Plataforma+Octacash.pdf" target="_blank">Termos e Condições</a></label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row" *ngIf="!loadingConfigPattern">
    <div class="header-form">
      <h6>Split de pagamento</h6><br>
      <mat-slide-toggle class="m-2" [(ngModel)]="splitFormaPag"> Usar o split na forma de pagamento </mat-slide-toggle>
    </div>
    <div class="col-sm-12">
      <div class="actions-card d-flex justify-content-end mb-2">
        <app-toolbar [value]="toolbarMain"></app-toolbar>
      </div>
      <app-skeleton [config]="skeletonConfigAsaas">
        <p-table #dt class="table-system" [value]="splitPagamento" [rows]="rows" [paginator]="true"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
          (onRowSelect)="changeToolbar()" (onRowUnselect)="changeToolbar()" [(selection)]="splitSelected">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox"></th>
              <th class="text-left" style="width: 30%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">
                    Nome <p-sortIcon [field]="'nome'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'nome', 'contains')">
              </th>
              <th class="text-left" style="width: 35%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'carteira'">
                  <span class="text-overflow-dynamic-ellipsis">
                    Carteira <p-sortIcon [field]="'carteira'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'carteira', 'contains')">
              </th>
              <th class="text-right" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                  <span class="text-overflow-dynamic-ellipsis">
                    Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'valor', 'contains')">
              </th>
              <th class="text-center" style="width: 25%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis">
                    Status
                  </span>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">
                    {{rowData.nome}}
                  </span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.carteira}}">
                    {{rowData.carteira}}
                  </span>
                </span>
              </td>
              <td class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{setIsPercentualValue(rowData.tipo_cobranca_id) ? rowData.valor  + '%': 'R$' + rowData.valor}}">
                    {{setIsPercentualValue(rowData.tipo_cobranca_id) ? rowData.valor + '%': 'R$ ' + rowData.valor}}
                  </span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.status_descr}}">
                    <i *ngIf="!rowData.status" class="fas fa-circle" style="color: yellow;"></i>
                    <i *ngIf="rowData.status" class="fas fa-circle" style="color: green;"></i>
                    {{rowData.status_descr}}
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="5" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </app-skeleton>
    </div>
  </div>
  <div class="form-row" *ngIf="!loadingConfigPattern">
    <div class="header-form">
      <h6>Operações e cashout</h6>
    </div>
    <div class="col-sm-12">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Operação para crédito de split <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
            [(ngModel)]="operacao_regra_contabil_id_entrada" [virtualScroll]="true" [itemSize]="30"
            [options]="optCreditOperations" emptyMessage="Nenhum resultado encontrado!" [disabled]="optOperationLoading"
            [dropdownIcon]="optIconOperationLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
        </div>
        <div class="form-group col-sm-6">
          <label>Operação para débito de split <span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
            [(ngModel)]="operacao_regra_contabil_id_saida" [virtualScroll]="true" [itemSize]="30"
            [options]="optDebitOperations" emptyMessage="Nenhum resultado encontrado!" [disabled]="optOperationLoading"
            [dropdownIcon]="optIconOperationLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"></p-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Conta para cashout<span class="obrigatorio">*</span></label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optCompanyAccounts" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="empresa_conta_id"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>Tipo de chave PIX da conta de cashout</label>
          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optPixKeys" [(ngModel)]="tipo_chave_pix"></p-dropdown>
        </div>
        <div class="form-group col-sm-4">
          <label>E-mail para avisos</label>
          <input class="form-control" [(ngModel)]="email_contato" type="text" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group d-flex align-items-center col-sm-12">
          <mat-slide-toggle [(ngModel)]="cashoutAutomatico">Cashout Automático</mat-slide-toggle>
        </div>
      </div>
      <div class="form-row">
        <ng-container>
          <div class="form-group col-sm-3">
            <label>Operação para taxas <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [(ngModel)]="operacao_regra_contabil_id_taxas" [virtualScroll]="true" [itemSize]="30"
              [options]="optDebitOperations" emptyMessage="Nenhum resultado encontrado!" [disabled]="optOperationLoading"
              [dropdownIcon]="optIconOperationLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'">
            </p-dropdown>
          </div>
          <div class="form-group col-sm-3">
            <label>Participante para taxas <span class="obrigatorio">*</span></label>
            <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione"
              [(ngModel)]="providerId" [virtualScroll]="true" [itemSize]="30"
              [options]="optProviders" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptProviders"
              [dropdownIcon]="loadingIconOptProviders ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'">
            </p-dropdown>
          </div>
          <div *ngIf="!cashoutAutomatico" class="form-group col-sm-3">
            <label>Data prevista<span class="obrigatorio">*</span></label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true"
              [(ngModel)]="dataCashout" [yearNavigator]="true" [defaultDate]="dataPadrao"
              yearRange="1900:2030" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}">
            </p-calendar>
          </div>
          <div *ngIf="!cashoutAutomatico" class="form-group col-sm-3">
            <label>Valor a retirar<span class="obrigatorio">*</span></label>
            <input class="form-control" [(ngModel)]="valorCashout" type="number" />
          </div>
        </ng-container>
      </div>
      <div class="form-row d-flex flex-column">
        <div class="alert alert-xs alert-primary d-block" style="max-width: max-content;">
          <i class="fas fa-info-circle"></i> O cashout será realizado na data selecionada às 23:50.
        </div>
        <div class="alert alert-xs alert-primary d-block" style="max-width: max-content;">
          <i class="fas fa-info-circle"></i> Após 30 transferências por mês, é cobrada uma taxa de R$2,00 por operação.
        </div>
        <div *ngIf="contadorTransferMensal<30" class="alert alert-xs alert-primary d-block" style="max-width: max-content;">
          <i class="fas fa-info-circle"></i> Você tem {{30-contadorTransferMensal}} cashouts disponíveis antes de ser cobrada a taxa.
        </div>
        <div *ngIf="contadorTransferMensal>29" class="alert alert-xs alert-primary d-block" style="max-width: max-content;">
          <i class="fas fa-info-circle"></i> Foi ultrapassado o número de cashouts disponíveis e será cobrada a taxa informada.
        </div>
      </div>
    </div>
  </div>
  <div class="form-row" *ngIf="!loadingConfigPattern">
    <div class="header-form">
      <h6>Aviso por e-mail</h6>
      <mat-slide-toggle class="m-2" [(ngModel)]="avisoEmail"> Configurar aviso por e-mail </mat-slide-toggle>
    </div>
    <div class="col-sm-12">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Dias de aviso antes do vencimento (separados por ;)</label>
          <input [disabled]="!avisoEmail" type="text" class="form-control" [(ngModel)]="dias_aviso_antes" />
        </div>
        <div class="form-group col-sm-6">
          <label>Intervalo de dias após o vencimento</label>
          <input [disabled]="!avisoEmail" type="number" class="form-control" [(ngModel)]="dias_aviso_apos" />
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 loading-content" style="padding: 0 !important" *ngIf="loadingConfigPattern">
    <p><span class="material-icons rotate">hourglass_empty</span> <b>Carregando informações da conta ...</b></p>
  </div>
</div>

<!-- Modal Split -->
<p-dialog header="Split de pagamento" [(visible)]="showModalSplit" [style]="{width: '900px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Nome <span class="obrigatorio">*</span></label>
      <input class="form-control" [(ngModel)]="splitSelected.nome" type="text" />
    </div>
    <div class="form-group col-sm-6">
      <label>Carteira <span class="obrigatorio">*</span></label>
      <input class="form-control" [(ngModel)]="splitSelected.carteira" type="text" />
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Cobrança <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="optLevy"
        [(ngModel)]="splitSelected.tipo_cobranca_id" emptyMessage="Nenhum resultado encontrado!">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>
        Valor
        <span style="line-height: 0;">{{setIsPercentualValue(splitSelected.tipo_cobranca_id) ? '(%)' : '(R$)'}} </span><span class="obrigatorio">*</span>
      </label>
      <input class="form-control" [(ngModel)]="splitSelected.valor" type="number" />
    </div>
  </div>
  <p-footer>
    <div class="w-100 d-flex justify-content-end">
      <button *ngIf="!isSaveLoading" class="btnCustom-red" matTooltip="Cancelar" (click)="openingModalSplit(false)">
        <span class="material-icons">close</span>
        <div>Cancelar</div>
      </button>
      <button *ngIf="!isSaveLoading" class="btnCustom-green" matTooltip="Salvar" (click)="saveSplitAsaas()">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>
      <button *ngIf="isSaveLoading" class="btnCustom-default" [disabled]="true">
        <span class="material-icons rotate">hourglass_full</span>
        <div>Carregando...</div>
      </button>
    </div>
  </p-footer>
</p-dialog>