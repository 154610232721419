<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <div class="actions-card d-flex align-items-center">
                <mat-slide-toggle *ngIf="statusTela === 'listagem'" class="slide-toggle-normal mr-3"
                  [(ngModel)]="regraNova" (change)="regraNovaChanged()">Nova Regra</mat-slide-toggle>

                <app-toolbar [value]="toolbarFormPag"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content" style="margin-top: 17px;" [@changeDivSize]=currentStateFormPag>
            <!-- lISTAGEM -->
            <div class="row" *ngIf="statusTela === 'listagem'">
              <div class="col-sm-12">
                <p-table class="table-system" [value]="formaPagamentoList" [rows]="rows" [paginator]="true"
                  emptyMessage="Nenhuma forma de pagamento encontrada" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect()" [(selection)]="formasPagamentosSelecionadas"
                  (onHeaderCheckboxToggle)="changeToolbar()" #dt1>

                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center tableHeaderCheckbox"style="width: 3%;">
                        <span class="check-all p-0">
                          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </span>
                      </th>
                      <th class="text-center" style="width: 10%">
                        <span class="text-overflow-dynamic-container" pSortableColumn="id">
                          <span class="text-overflow-dynamic-ellipsis">
                            ID <p-sortIcon field="id"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'id','contains')">
                      </th>
                      <th class="text-left" style="width: 77%">
                        <span class="text-overflow-dynamic-container" pSortableColumn="descricao">
                          <span class="text-overflow-dynamic-ellipsis">
                            DESCRIÇÃO <p-sortIcon field="descricao"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value,'descricao','contains')">
                      </th>
                      <th class="text-center" style="width: 10%">
                        <span class="text-overflow-dynamic-container" pSortableColumn="filtro">
                          <span class="text-overflow-dynamic-ellipsis">
                            ATIVO <p-sortIcon field="filtro"></p-sortIcon>
                          </span>
                        </span>
                        <p-multiSelect #cmp class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                          (onChange)="dt1.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                      </th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowValue>
                    <tr [pSelectableRow]="rowValue">
                      <td class="text-center" style="width: 3%;">
                        <p-tableCheckbox [pSelectableRow]="rowValue" [value]="rowValue">
                        </p-tableCheckbox>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.id }}"> {{ rowValue.id }}
                          </span>
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.descricao }}"> {{
                            rowValue.descricao | uppercase }} </span>
                        </span>
                      </td>
                      <td class="text-center">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowValue.filtro}}"> {{
                            rowValue.filtro }} </span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> NENHUMA FORMA DE PAGAMENTO ENCONTRADA
                        </div>
                      </td>
                    </tr>
                  </ng-template>

                </p-table>
              </div>
            </div>
            <!-- FORMULÁRIO REGRA ANTIGA -->
            <div class="row" *ngIf="statusTela === 'form' && !regraNova">
              <div class="col-sm-12">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label for="inputDescricao">DESCRIÇÃO<span class="obrigatorio">*</span></label>
                    <input class="form-control" value="{{formaPagamento.descricao}}"
                      [(ngModel)]="formaPagamento.descricao" name="inputDescricao" maxlength="50">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label for="inputDescricaoEn">DESCRIÇÃO EN</label>
                    <input class="form-control" value="{{formaPagamento.descricaoEn}}"
                      [(ngModel)]="formaPagamento.descricaoEn" name="inputDescricaoEn" maxlength="50">
                  </div>
                </div>

                <!-- Inicio tipo Pagamento -->
                <div class="form-row">
                  <div class="form-group col-sm-2">
                    <label for="maxParcelas">MAX PARCELA</label>
                    <input class="form-control" type="number" min="1" value="{{formaPagamento.maxParcelas}}"
                      [(ngModel)]="formaPagamento.maxParcelas" name="inputMaxParcelas">
                  </div>
                  <div class="form-group col">
                    <label>TIPO PAGAMENTO<span class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="tiposBaixas"
                      [(ngModel)]="formaPagamento.tipoBaixaId">
                    </p-dropdown>
                  </div>

                  <div class="form-group col">
                    <label>GATEWAY PAGMENTO</label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                      [options]="opcoesgateways" [(ngModel)]="formaPagamento.configuracoesApisId">
                    </p-dropdown>
                  </div>

                  <div class="form-group col" *ngIf="formaPagamento.tipoBaixaId === 12 || formaPagamento.tipoBaixaId === 13
                    || formaPagamento.tipoBaixaId === 3 || formaPagamento.tipoBaixaId === 4">
                    <label>CONTA BANCÁRIA<span class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                      [options]="contasBancarias" [(ngModel)]="formaPagamento.empresaContaId">
                    </p-dropdown>
                  </div>
                </div>
                <!-- Final tipo Pagamento -->

                <div class="row mb-3">
                  <div class="col-sm-1 d-flex align-items-center">
                    <div class="p-field-checkbox">
                      <p-checkbox class="mr-1" [(ngModel)]="formaPagamento.ativo" [binary]="true" inputId="atv">
                      </p-checkbox>
                      <label for="atv">Ativo</label>
                    </div>
                  </div>
                  <div class="col-sm-6 d-flex align-items-center">
                    <div class="p-field-checkbox">
                      <p-checkbox class="mr-1" [(ngModel)]="formaPagamento.boolDataEmissaoPedido" [binary]="true"
                        inputId="dep"></p-checkbox>
                      <label for="dep">Utilizar Data da Emissão do Pedido</label>
                    </div>
                  </div>
                </div>

                <div class="row" *ngIf="detalheList && (formaPagamento.tipoBaixaId !== 12 && formaPagamento.tipoBaixaId !== 13
                && formaPagamento.tipoBaixaId !== 3 && formaPagamento.tipoBaixaId !== 4)">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-divide"></i> Parcelas</h3>
                  </div>
                </div>
                <div class="row" *ngIf="detalheList && (formaPagamento.tipoBaixaId !== 12 && formaPagamento.tipoBaixaId !== 13
                && formaPagamento.tipoBaixaId !== 3 && formaPagamento.tipoBaixaId !== 4)">
                  <div class="col-sm-12">
                    <p-table class="table-system" #dt2 [value]="detalheList" [rows]="rows" [paginator]="true"
                      [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single">

                      <ng-template pTemplate="header">
                        <tr>
                          <th [style]="{'width' : '20%' }">
                            <span [style]="{'display': 'block' }">
                              <span>ACRESCENTA MÊS</span>
                            </span>
                            <input class="form-control form-control-sm" type="text"
                              (input)="dt2.filter($event.target.value, 'acrescentaMes', 'contains')">
                          </th>
                          <th [style]="{'width' : '35%' }">
                            <span [style]="{'display': 'block' }">
                              <span>DIAS</span>
                            </span>
                            <input class="form-control form-control-sm" type="text"
                              (input)="dt2.filter($event.target.value, 'dias', 'contains')">
                          </th>
                          <th [style]="{'width' : '35%' }">
                            <span [style]="{'display': 'block' }">
                              <span>PORCENTAGEM</span>
                            </span>
                            <input class="form-control form-control-sm" type="text"
                              (input)="dt2.filter($event.target.value, 'porcentagem', 'contains')">
                          </th>
                          <th [style]="{'text-align': 'center', 'width' : '10%' }">
                            <span [style]="{'display': 'block' }">
                              <span>AÇÃO</span>
                            </span>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowValue>
                        <tr [pSelectableRow]="rowValue">
                          <td class="text-center">
                            {{rowValue.acrescentaMes}}
                          </td>
                          <td class="text-center">
                            {{rowValue.dias}}
                          </td>
                          <td class="text-center">
                            {{rowValue.porcentagem}}
                          </td>
                          <td class="text-center" (click)="removerLinha(rowValue)">
                            <span class="delete" matTooltip="Remover!" (click)="removerLinha(rowValue)">
                              <i class="far fa-trash-alt"></i>
                            </span>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
                <div class="row" id="div-botoes" *ngIf="porcentagemTotal === 100 || criar || editar">
                </div>
              </div>
            </div>

            <!-- FORMULÁRIO REGRA NOVA  -->
            <div class="form-group col-sm-12" *ngIf="statusTela === 'form' && regraNova">
              <div class="form-row pt-3 pb-3" style="align-items: flex-end;">
                <div class="form-group col-sm-5">
                  <label>Descrição</label>
                  <span class="obrigatorio"
                    *ngIf="!formaPagamento.descricao || !formaPagamento.descricao.length">*</span>
                  <input class="form-control" type="text" placeholder="" [(ngModel)]="formaPagamento.descricao">
                </div>
                <div class="form-group col-sm-7 d-flex align-items-center">
                  <div class="p-field-checkbox d-flex align-items-center mr-3">
                    <p-checkbox class="mr-1" [(ngModel)]="formaPagamento.ativo" [binary]="true" inputId="atv">
                    </p-checkbox>
                    <label for="atv">Ativo</label>
                  </div>
                  <div class="p-field-checkbox d-flex align-items-center mr-3">
                    <p-checkbox class="mr-1" [(ngModel)]="formaPagamento.isRecebimento" [binary]="true" inputId="rec">
                    </p-checkbox>
                    <label for="rec">Recebimento</label>
                  </div>
                  <div class="p-field-checkbox d-flex align-items-center">
                    <p-checkbox class="mr-1" [(ngModel)]="formaPagamento.isPagamento" [binary]="true" inputId="pag">
                    </p-checkbox>
                    <label for="pag">Pagamento</label>
                  </div>

                </div>
              </div>

              <hr>

              <div class="form-row pt-3 pb-3">
                <div class="form-group col-6">
                  <label>Tipo</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione um tipo"
                    [options]="tiposBaixas" [(ngModel)]="formaPagamento.tipoBaixaId">
                  </p-dropdown>
                </div>
                <div class="form-group col-6">
                  <label>Tipo de Pagamento</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione um tipo"
                    [options]="tiposPagamentos" [(ngModel)]="formaPagamento.tipoPagamentoId">
                  </p-dropdown>
                </div>
              </div>
              <div class="form-row pt-3 pb-3">
                <div class="form-group col-sm-2" *ngIf="[3, 4].includes(formaPagamento.tipoBaixaId)">
                  <label>Adiministradora</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione uma administradora"
                    [options]="opcoesAdm" [(ngModel)]="formaPagamento.administradoraId">
                  </p-dropdown>
                </div>

                <div class="form-group col-sm-2" *ngIf="[3, 4].includes(formaPagamento.tipoBaixaId)">
                  <label>Bandeira</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione uma administradora"
                    [options]="opcoesBandeira" [(ngModel)]="formaPagamento.bandeiraId">
                  </p-dropdown>
                </div>

                <div class="form-group col-sm-3" *ngIf="[3, 4].includes(formaPagamento.tipoBaixaId)">
                  <label>Mensalidade</label>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.mensalidade">
                </div>

                <div class="form-group col-sm-3" *ngIf="[3, 4].includes(formaPagamento.tipoBaixaId)">
                  <label>Vencimento dia</label>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.diaVencimento"
                    pKeyFilter="int">
                </div>
              </div>

              <hr>

              <div class="form-row pt-3 pb-3">
                <div class="form-group col-sm-2">
                  <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="formaPagamento.parcelasExatas">
                    Parcelas exatas
                  </mat-slide-toggle>
                </div>

                <div class="form-group col-sm-2">
                  <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="formaPagamento.permiteAlterarBanco">
                    Permitir contas diferentes nas parcelas
                  </mat-slide-toggle>
                </div>

                <div class="form-group col-sm-2">
                  <mat-slide-toggle class="slide-toggle-normal" [(ngModel)]="formaPagamento.programacaoSemanal"
                    (change)="tirarOpcaoDiaFixo()">
                    Programação Semanal
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="form-row pt-3 pb-3">
                <!-- <div class="form-group col-2" *ngIf="formaPagamento.programacaoSemanal">
                  <label>Semana</label>
                  <span class="obrigatorio" *ngIf="!formaPagamento.paymentWeek">*</span>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.paymentWeek" pKeyFilter="int">
                </div> -->

                <div class="form-group col-2" *ngIf="formaPagamento.programacaoSemanal">
                  <label>Intervalo mínimo de dias</label>
                  <span class="obrigatorio" *ngIf="!formaPagamento.minDaysInterval">*</span>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.minDaysInterval"
                    pKeyFilter="int">
                </div>

                <div class="form-group col-2" *ngIf="formaPagamento.programacaoSemanal">
                  <span class="obrigatorio" *ngIf="!formaPagamento.dayOfWeek">*</span>
                  <label>Dia da semana</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione um dia"
                    [options]="opcoesDaysOfWeek" [(ngModel)]="formaPagamento.dayOfWeek">
                  </p-dropdown>
                </div>
              </div>

              <div class="form-row pt-3 pb-3">
                <div class="form-group col-6"
                  *ngIf="!formaPagamento.parcelasExatas && !formaPagamento.programacaoSemanal">
                  <label>N° máximo parcelas</label>
                  <span class="obrigatorio" *ngIf="!formaPagamento.maxParcelas">*</span>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.maxParcelas"
                    (change)="maxParcelasChanged()" pKeyFilter="int">
                </div>

                <div class="form-group col-6"
                  *ngIf="formaPagamento.parcelasExatas || formaPagamento.programacaoSemanal">
                  <label>N° parcelas</label>
                  <span class="obrigatorio"
                    *ngIf="!formaPagamento.maxParcelas && !formaPagamento.programacaoSemanal">*</span>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.maxParcelas"
                    (change)="maxParcelasChanged()" pKeyFilter="int" [disabled]="formaPagamento.programacaoSemanal">
                </div>

                <div class="form-group col-6" *ngIf="formaPagamento.dataReferencia != 'dia_fixo'">
                  <label>Intervalo de dias entre as parcelas</label>
                  <span class="obrigatorio"
                    *ngIf="!formaPagamento.intervaloDias && !formaPagamento.programacaoSemanal">*</span>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.intervaloDias"
                    (change)="intervaloDiasChanged(0, formaPagamento.intervaloDias)" pKeyFilter="int"
                    [disabled]="formaPagamento.programacaoSemanal">
                </div>

                <div class="form-group col-6" *ngIf="formaPagamento.dataReferencia == 'dia_fixo'">
                  <label>Dia do mês</label>
                  <span class="obrigatorio" *ngIf="!formaPagamento.paydayDefault">*</span>
                  <input class="form-control" type="number" placeholder="" [(ngModel)]="formaPagamento.paydayDefault"
                    (blur)="payDayChanged()" (change)="checkMaxDay()" pKeyFilter="int">
                </div>

                <div class="form-group col-6">
                  <label>Data de referência para a parcela</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione uma data"
                    [options]="opcoesDataReferencia" [(ngModel)]="formaPagamento.dataReferencia">
                  </p-dropdown>
                </div>

                <div class="form-group col-6" *ngIf="[3, 4].includes(formaPagamento.tipoBaixaId)">
                  <label>Regra contabil para taxa de administração</label>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione uma regra"
                    [options]="opcoesOperacaoRegra" [(ngModel)]="formaPagamento.regraContabilId">
                  </p-dropdown>
                </div>

                <div class="form-group col-6">
                  <label>Conta padrão</label>
                  <span class="obrigatorio">*</span>
                  <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione um tipo"
                    [options]="contasBancarias" [(ngModel)]="formaPagamento.empresaContaId">
                  </p-dropdown>
                </div>
              </div>

              <hr *ngIf="!formaPagamento.programacaoSemanal">

              <div class="row" style="justify-content: flex-end;" *ngIf="!formaPagamento.programacaoSemanal">
                <label (click)="adicionarLinha()" class="btnAdd" matTooltip="Adicionar Linha">
                  Nova Linha
                  <span class="material-icons">add</span>
                </label>

                <div class="col-sm-12">
                  <p-table class="table-system" #dt6 [value]="detalheList" [rows]="rows" [paginator]="true"
                    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'parcela'">
                            <span class="text-overflow-dynamic-ellipsis">N° de Parcelas <p-sortIcon [field]="'parcela'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'parcela', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;" *ngIf="formaPagamento.dataReferencia == 'dia_fixo'">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'acrescentaMes'">
                            <span class="text-overflow-dynamic-ellipsis">Acrescenta mês <p-sortIcon
                                [field]="'acrescentaMes'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'acrescentaMes', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;" *ngIf="formaPagamento.dataReferencia != 'dia_fixo'">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'dias'">
                            <span class="text-overflow-dynamic-ellipsis">{{"N° de dias"}} <p-sortIcon [field]="'dias'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'dias', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;" *ngIf="formaPagamento.dataReferencia == 'dia_fixo'">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'payday'">
                            <span class="text-overflow-dynamic-ellipsis">{{"Dia do mês"}} <p-sortIcon
                                [field]="'payday'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'payday', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'txAdmVista'">
                            <span class="text-overflow-dynamic-ellipsis">Taxa ADM a Vista <p-sortIcon
                                [field]="'txAdmVista'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'txAdmVista', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'txAdmParcelado'">
                            <span class="text-overflow-dynamic-ellipsis">Taxa ADM Parcelado <p-sortIcon
                                [field]="'txAdmParcelado'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'txAdmParcelado', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'antecipacaoVista'">
                            <span class="text-overflow-dynamic-ellipsis">Antecipação A vista <p-sortIcon
                                [field]="'antecipacaoVista'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'antecipacaoVista', 'contains')">
                        </th>

                        <th style="text-align: left; width: 33%;">
                          <span class="text-overflow-dynamic-container" [pSortableColumn]="'antecipacaoParcelado'">
                            <span class="text-overflow-dynamic-ellipsis">Antecipação Parcelado <p-sortIcon
                                [field]="'antecipacaoParcelado'">
                              </p-sortIcon></span>
                          </span>
                          <input class="form-control form-control-sm" type="text"
                            (input)="dt6.filter($event.target.value, 'antecipacaoParcelado', 'contains')">
                        </th>
                        <th style="text-align: center; width: 14%;">Ações</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-detalhe>
                      <tr>
                        <td> {{detalhe.parcela}} </td>
                        <td *ngIf="formaPagamento.dataReferencia == 'dia_fixo'">
                          <input class="form-control" type="number" placeholder="" [(ngModel)]="detalhe.acrescentaMes"
                            pKeyFilter="int">
                        </td>
                        <td *ngIf="formaPagamento.dataReferencia != 'dia_fixo'">
                          <input class="form-control" type="number" placeholder=""
                            (change)="validaDias(detalhe.parcela)" [(ngModel)]="detalhe.dias" pKeyFilter="int">
                        </td>
                        <td *ngIf="formaPagamento.dataReferencia == 'dia_fixo'">
                          <input class="form-control" type="number" placeholder="" [(ngModel)]="detalhe.payday"
                            pKeyFilter="int">
                        </td>
                        <td>
                          <input class="form-control" type="number" placeholder="" [(ngModel)]="detalhe.txAdmVista"
                            [disabled]="![3, 4].includes(formaPagamento.tipoBaixaId)">
                        </td>
                        <td>
                          <input class="form-control" type="number" placeholder="" [(ngModel)]="detalhe.txAdmParcelado"
                            [disabled]="![3, 4].includes(formaPagamento.tipoBaixaId)">
                        </td>
                        <td>
                          <input class="form-control" type="number" placeholder=""
                            [(ngModel)]="detalhe.antecipacaoVista"
                            [disabled]="![3, 4].includes(formaPagamento.tipoBaixaId)">
                        </td>
                        <td>
                          <input class="form-control" type="number" placeholder=""
                            [(ngModel)]="detalhe.antecipacaoParcelado"
                            [disabled]="![3, 4].includes(formaPagamento.tipoBaixaId)">
                        </td>
                        <td>
                          <span style="display: flex;align-items: center;justify-content: space-evenly;">
                            <button type="button" style="padding: 0 !important;" (click)="removerLinha(detalhe.parcela)"
                              class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha"
                              matTooltipPosition="above">
                              <span class="material-icons" style="font-size: 18px">delete</span>
                            </button>
                            <button type="button" style="padding: 0 !important;"
                              (click)="replicarValores(detalhe.parcela)" class="btn btn-xs btn-link btn-full"
                              matTooltip="Replicar Linha" matTooltipPosition="above">
                              <span class="material-icons" style="font-size: 18px">cached</span>
                            </button>
                            <button *ngIf="usaSplitFormaPag" type="button" style="padding: 0 !important;"
                              (click)="showModalSplit(detalhe)" class="btn btn-xs btn-link btn-full"
                              matTooltip="Configurar split" matTooltipPosition="above">
                              <span class="material-icons" style="font-size: 18px">share</span>
                            </button>
                          </span>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- MODAL SPLIT PAGAMENTO -->
<p-dialog header="Configuração split de pagamento" [(visible)]="modalSplitPagamento" [modal]="true"
  [style]="{ width: '900px' }" [responsive]="true" appendTo="body" [closable]="true">
  <div class="row">
    <div class="col-sm-12">
      <p-table class="table-system mt-2 d-block" [value]="splitPagamento" [style]="{ width: '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 25%" class="text-left">Nome</th>
            <th style="width: 25%" class="text-left">Carteira</th>
            <th style="width: 10%" class="text-center">Tipo cobrança</th>
            <th style="width: 10%" class="text-right">Valor</th>
            <th style="width: 20%" class="text-center">Status</th>
            <th style="width: 10%" class="text-center">Ação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td pEditableColumn class="text-left">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input class="form-control" [(ngModel)]="rowData.nome" type="text" />
                </ng-template>
                <ng-template pTemplate="output">
                  <span matTooltip="{{rowData.nome}}" class="overflow-ellipse text-left"
                    style="cursor: pointer">{{rowData.nome}}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="text-left">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input class="form-control" [(ngModel)]="rowData.carteira" type="text" />
                </ng-template>
                <ng-template pTemplate="output">
                  <span matTooltip="{{rowData.carteira}}" class="overflow-ellipse text-left"
                    style="cursor: pointer">{{rowData.carteira}}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="text-center">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown class="type-p-dropdown" [filter]="false" [options]="opcoesTipoCobranca"
                    [(ngModel)]="rowData.tipo_cobranca_id" placeholder="Selecione um tipo de cobrança"
                    appendTo="body"></p-dropdown>
                </ng-template>
                <ng-template pTemplate="output" matTooltip="{{rowData.tipo_cobranca_id === 1 ? 'R$': '%'}}">
                  {{rowData.tipo_cobranca_id === 1 ? 'R$': '%'}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="text-right">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input class="form-control" [(ngModel)]="rowData.valor" type="number" />
                </ng-template>
                <ng-template pTemplate="output" matTooltip="{{ rowData.valor }}">
                  {{ rowData.valor }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td class="text-center">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status_descr}}">
                  <i *ngIf="!rowData.status" class="fas fa-circle" style="color: yellow;"></i>
                  <i *ngIf="rowData.status" class="fas fa-circle" style="color: green;"></i>
                  {{rowData.status_descr}}
                </span>
              </span>
            </td>
            <td class="text-center">
              <button type="button" style="padding: 0 !important;" (click)="buscarCarteira(rowData)"
                class="btn btn-xs btn-link btn-full" matTooltip="Verificar carteira" matTooltipPosition="above">
                <span class="material-icons" style="font-size: 18px">verified</span>
              </button>
              <button type="button" style="padding: 0 !important;" (click)="removerSplit(rowData)"
                class="btn btn-xs btn-link btn-full" matTooltip="Excluir linha" matTooltipPosition="above">
                <span class="material-icons" style="font-size: 18px">delete</span>
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="6" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col">
      <button class="btn btn-sm btn-info mt-2" (click)="adicionarLinhaSplit()">
        <i class="fas fa-plus"></i> Adicionar linha
      </button>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalSplitPagamento = false">
      <i class="fas fa-undo"></i> Cancelar
    </button>
    <button type="button" class="btn btn-primary" (click)="salvarSplit()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>
<!-- FIM MODAL SPLIT PAGAMENTO -->

<p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll" [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

  <label style="margin-top: 100px;">{{ descricaoModalGrupoEmpresas }}</label>
  <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
  </p-multiSelect>

  <p-footer class="d-flex align-items-center justify-content-end">
    <button class="btnCustom-green" (click)="this.criar ? criarFormaPagamentoGrupoEmpresa() : editarFormaPagamentoGrupoEmpresa()" *ngIf="!clonagemGrupoEmpresas">
      <span class="material-icons">save</span>
      <div>Salvar</div>
    </button>

    <button class="btnCustom-green" (click)="clonarFormaPagamentoGrupoEmpresa()" *ngIf="clonagemGrupoEmpresas">
      <span class="material-icons">save</span>
      <div>Clonar</div>
    </button>
  </p-footer>
</p-dialog>