<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <!--Table Seleção Item-->
  <div class="row" *ngIf="!editar">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Selecione um MVA</h3>
            <div class="actions-card" style="display: flex;">
              <button *ngIf="mostrarMaisOpcoes" type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu"
                class="btnCustom-default" matTooltip="Mais opções">
                <span class="material-icons" style="margin-top: -7px;">more_vert</span>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                  <p-checkbox class="mr-1" [(ngModel)]="mostrarInativos" [binary]="true" (onChange)="getMva()"></p-checkbox>
                  <span>Exibir inativos</span>
                </div>
              </mat-menu>
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateSelectItem>
          <p-table class="table-system" [value]="lista" selectionMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 34%">NOME</th>
                <th style="width: 33%" class="text-left">VALOR</th>
                <th style="width: 33%" class="text-left">ATIVO</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr (click)="verMva(rowData)" [pSelectableRow]="rowData">
                <td>
                  {{ rowData.nome }}
                </td>
                <td class="text-left">
                  {{ rowData.valor }}
                </td>
                <td class="text-left">
                  <p-checkbox [ngModel]="rowData.ativo" [binary]="true" [disabled]="true"></p-checkbox>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <!--Item Selecionado-->
  <div class="row" *ngIf="editar">
    <div class="col-sm-12 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">MVA Selecionado</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainItemS"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateItemSelecionado>
          <div class="form-row">
            <div class="form-group col-sm-2 mb-2">
              <label for="inputCodigo">NOME</label>
              <input class="form-control" [(ngModel)]="mva.nome" value="{{mva.nome}}" type="text"
                name="inputCodigo">
            </div>
            <div class="form-group col-sm-4 mb-2">
              <label for="inputDescricao">VALOR</label>
              <input class="form-control" [(ngModel)]="mva.valor" value="{{mva.valor}}" type="text"
                name="inputDescricao">
            </div>
            <div class="form-group col-sm-4 d-flex align-items-end">
              <p-checkbox label="MVA Ativo" class="mr-1" [(ngModel)]="mva.ativo" (change)="mva.ativo = !mva.ativo" [binary]="true" inputId="operacaoRegra.ativo"></p-checkbox>
            </div>
            <div class="form-group col-sm-4 mb-2">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Relacione Itens Disponíveis-->
  <div class="row" *ngIf="editar">
    <div class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Ncms Disponíveis</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainItemD"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateItens>
          <div class="row">
            <div class="col-sm-6">
              <label>Selecine um capítulo para relacionar os NCMs</label>
              <p-dropdown placeholder="Selecione um capítulo" [(ngModel)]="tipiCapituloSelecionado" [disabled]="loadingTipis" [dropdownIcon]="loadingTipis ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" class="type-p-mult" appendTo="body" [options]="tipisOptions" defaultLabel="Selecione" [filter]="true" [virtualScroll]="true" [itemSize]="38" (onChange)="filtrarNcms()">
              </p-dropdown>
            </div>

            <div class="col-sm-12 mt-4">
              <div class="conteudo">
                <p-pickList sourceHeader="Ncms Disponíveis" targetHeader="Ncms Relacionados"
                  [source]="ncmsRelacionar" [target]="ncmsRelacionados" [dragdrop]="true" [responsive]="true"
                  filterBy="desc" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-itm pTemplate="item">{{itm.desc}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Relacione Alíquotas Disponíveis-->
    <div class="col-sm-12 col-xxl-6 pb-3">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">Ufs Disponíveis</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMainA"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateAliquota>
          <div class="row">
            <div class="col-sm-12">
              <!-- <h4 class="sub-title-page mb-3"><i class="fas fa-key" style="color: #0091ea;"></i> <strong>Alíquotas</strong></h4> -->
              <p style="font-size: 13px; text-transform: initial;"><i class="fas fa-arrow-right"
                  style="color: #0091ea;"></i> Relacione as ufs disponíveis.</p>
              <div class="row">
                <div class="col-sm-12">
                  <div class="conteudo m-0">
                    <p-pickList sourceHeader="Ufs Disponíveis" targetHeader="Ufs Relacionadas"
                      [source]="ufsRelacionar" [target]="ufsRelacionadas" [dragdrop]="true"
                      [responsive]="true" filterBy="desc" [showSourceControls]="false" [showTargetControls]="false">
                      <ng-template let-aliq pTemplate="item">{{aliq.desc}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div class="ui-g">
  <div class="ui-g-12">

  </div>
</div>