<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="PERÍODO ({{dblinkedSessionService.periodo.year.value}})">
        <div class="box-content">
          <div class="row">
            <div class="col-sm-12">
              <div class="pull-right">
              </div>
              <div class="clearfix"></div>
              <p-table class="table-system" #dt [value]="periodoEstoque" [pageLinks]="pageLinks"
                [style]="{'width':'100%'}" immutable="false">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">MÊS</span>
                      </span>
                    </th>
                    <th class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">STATUS</span>
                      </span>
                    </th>
                    <th class="text-center" style="width:25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">DT. ENCERRAMENTO</span>
                      </span>
                    </th>
                    <th class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">USUÁRIO</span>
                      </span>
                    </th>
                    <th class="text-center" style="width: 90px; vertical-align: sub;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                      </span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.mes }}">
                          {{ rowData.mes }}
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.status }}">{{
                          rowData.status }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.dataEncerramento }}">{{
                          rowData.dataEncerramento }}</span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 25%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip=" {{ rowData.usuario }}">{{
                          rowData.usuario }}</span>
                      </span>
                    </td>
                    <td class="text-center" style="width: 90px">
                      <button
                        *ngIf="rowData.statusId === 1"
                        type="button" style="padding: 0 !important;"
                        (click)="transferirSaldo(rowData)" class="btn btn-xs btn-link btn-full"
                        matTooltip="Encerrar Período" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px;">check</span>
                      </button>
                      <button
                        *ngIf="rowData.statusId === 2"
                        type="button" style="padding: 0 !important;"
                        (click)="abrirPeriodo(rowData)" class="btn btn-xs btn-link btn-full"
                        matTooltip="Abrir Período" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px;">create_new_folder</span>
                      </button>
                      <button
                        *ngIf="rowData.statusId === 1"
                        type="button" style="padding: 0 !important;"
                        (click)="confirmRecalcularCusto(rowData)" class="btn btn-xs btn-link btn-full"
                        matTooltip="Recalcular Custo" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px;">access_time</span>
                      </button>
                      <button
                        *ngIf="rowData.statusId === 2"
                        type="button" style="padding: 0 !important;"
                        (click)="confirmRecalcularCusto(rowData)" class="btn btn-xs btn-link btn-full"
                        matTooltip="Recalcular Custo" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 15px;">access_time</span>
                      </button>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="listaProcessosUsuario.length > 0">
          <div class="col mt-12">
            <div class="card">
              <div class="card-body">
                <button class="btn btn-sm btn-info" type="button" (click)="atualizarProcessos()"><i
                    class="fas fa-refresh"></i>
                  Atualizar</button>
                <br>
                <ul>
                  <li [style]="{width: '100%', display: 'flex', gap: '4px'}" *ngFor="let proc of listaProcessosUsuario">Progressão do reprocessamento do estoque: iniciou às {{proc.inicio}} - Status: <p-progressBar [style]="{width: '200px', height: '20px'}" [value]="proc.status.replace('%', '')"></p-progressBar></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>