<div class="shadow-box w-100 p-3 h-100" *ngIf="type === 'line' && lineData !== undefined" [ngStyle]="{'display': isLoading ? 'none' : 'block'}">
  <div class="big-number-card">
    <div class="header">
      <div class="title">
        <h2>{{lineData.title}}</h2>
        <small>{{lineData.subtitle}}</small>
      </div>
      <button class="btnCustom-default" (click)="sortData()">
        <span class="material-icons">{{(sortIcon === null) ? 'sort' : sortIcon}}</span>
      </button>
    </div>
    <div class="content">
      <span class="line-container" *ngFor="let item of lineData.lines">
        <div class="line" [ngClass]="{ isHeader: item.title === 'Todas as contas' }">
          <h3>{{item.title}}</h3>
          <p class="value" [ngClass]="{ negative: item.value < 0 }">{{item.value | money}}</p>
        </div>
      </span>
    </div>
  </div>
</div>

<p-skeleton *ngIf="isLoading" width="100%" height="300px"></p-skeleton>
