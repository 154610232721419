<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-8 col-xxl-6">
        <div class="shadow-box">
          
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Informações de Pagamento</h3>
              <div class="actions-card">
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeFormPag()"
                  (click)="changeStateFormPag()">
                  <span *ngIf="isCardFormPag" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardFormPag" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateFormPag>
            <div class="row" *ngIf="!criar && !editar">
              <div class="col-sm-12">
                <!-- Table New P-TABLE -->
                <p-table class="table-system" #dt [value]="formaPagamentoList" [rows]="rows" [paginator]="true"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  (onRowSelect)="onRowSelectFormaPagamento($event)" >
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                          <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon>
                          </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value, 'id', 'contains')">
                      </th>
                      <th class="text-left" style="width: 90%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                      <td class="text-left" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.id}}">{{rowData.id}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 90%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.descricao | uppercase}}">{{rowData.descricao | uppercase}}</span>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="2" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
            <div class="row" *ngIf="criar || editar" [style]="{ width: '100%' }">
              <div class="col-sm-12">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label for="inputDescricao">DESCRIÇÃO<span class="obrigatorio">*</span></label>
                    <input class="form-control" value="{{formaPagamento.descricao}}"
                      [(ngModel)]="formaPagamento.descricao" name="inputDescricao" maxlength="50">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label for="inputDescricaoEn">DESCRIÇÃO EN</label>
                    <input class="form-control" value="{{formaPagamento.descricaoEn}}"
                      [(ngModel)]="formaPagamento.descricaoEn" name="inputDescricaoEn" maxlength="50">
                  </div>
                </div>

                <!-- Inicio tipo Pagamento -->
                <div class="form-row">
                  <div class="form-group col-sm-2">
                    <label for="maxParcelas">MAX PARCELA</label>
                    <input class="form-control" type="number" min="1" value="{{formaPagamento.maxParcelas}}"
                      [(ngModel)]="formaPagamento.maxParcelas" name="inputMaxParcelas">
                  </div>
                  <div class="form-group col">
                    <label>TIPO PAGAMENTO<span class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione" [options]="tiposBaixas"
                      [(ngModel)]="formaPagamento.tipoBaixaId">
                    </p-dropdown>
                  </div>

                  <div class="form-group col">
                    <label>GATEWAY PAGMENTO</label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                      [options]="opcoesgateways" [(ngModel)]="formaPagamento.configuracoesApisId">
                    </p-dropdown>
                  </div>

                  <div class="form-group col" *ngIf="formaPagamento.tipoBaixaId === 12 || formaPagamento.tipoBaixaId === 13
                    || formaPagamento.tipoBaixaId === 3 || formaPagamento.tipoBaixaId === 4">
                    <label>CONTA BANCÁRIA<span class="obrigatorio">*</span></label>
                    <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                      [options]="contasBancarias" [(ngModel)]="formaPagamento.empresaContaId">
                    </p-dropdown>
                  </div>
                </div>
                <!-- Final tipo Pagamento -->


                <div class="form-row">
                  <div class="form-group col-sm-2">
                    <p-checkbox [(ngModel)]="formaPagamento.ativo" label="ATIVO"></p-checkbox>
                  </div>
                  <div class="form-group col">
                    <p-checkbox [(ngModel)]="formaPagamento.boolDataEmissaoPedido"
                      label="Utilizar Data da Emissão do Pedido">
                    </p-checkbox>
                  </div>
                </div>


                <div class="row" *ngIf="detalheList && (formaPagamento.tipoBaixaId !== 12 && formaPagamento.tipoBaixaId !== 13
                && formaPagamento.tipoBaixaId !== 3 && formaPagamento.tipoBaixaId !== 4)">
                  <div class="col-sm-12">
                    <h3 class="sub-title"><i class="fas fa-divide"></i> Parcelas</h3>
                  </div>
                </div>
                <div class="row" *ngIf="detalheList && (formaPagamento.tipoBaixaId !== 12 && formaPagamento.tipoBaixaId !== 13
                && formaPagamento.tipoBaixaId !== 3 && formaPagamento.tipoBaixaId !== 4)">




                  <div class="col-sm-12">
                    <!-- Table New P-TABLE -->
                    <p-table class="table-system" [value]="detalheList">
                      <ng-template pTemplate="header">
                        <tr>
                          <th class="text-right" style="width: 20%;">
                            <span class="text-overflow-dynamic-container">
                              <span class="text-overflow-dynamic-ellipsis">ACRESCENTA MÊS </span>
                            </span>
                          </th>
                          <th class="text-right" style="width: 35%;">
                            <span class="text-overflow-dynamic-container">
                              <span class="text-overflow-dynamic-ellipsis">DIAS </span>
                            </span>
                          </th>
                          <th class="text-right" style="width: 35%;">
                            <span class="text-overflow-dynamic-container">
                              <span class="text-overflow-dynamic-ellipsis">PORCENTAGEM </span>
                            </span>
                          </th>
                          <th class="text-center" style="width: 10%;">
                            <span class="text-overflow-dynamic-container">
                              <span class="text-overflow-dynamic-ellipsis">AÇÃO </span>
                            </span>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                        <tr>
                          <td class="text-right" style="width: 20%;">
                            <input class="form-control form-control-sm" type="number" value="{{rowData.acrescentaMes}}"
                              [(ngModel)]="rowData.acrescentaMes">
                          </td>
                          <td class="text-right" style="width: 35%;">
                            <input class="form-control form-control-sm" type="number" value="{{rowData.dias}}"
                              (blur)="verificarParcelas(rowData)" [(ngModel)]="rowData.dias">
                          </td>
                          <td class="text-right" style="width: 35%;">
                            <input class="form-control form-control-sm" type="number" value="{{rowData.porcentagem}}"
                              (blur)="verificarParcelas(rowData)" [(ngModel)]="rowData.porcentagem">
                          </td>
                          <td class="text-center" style="width: 10%;" (click)="removerLinha(rowData)">
                            <span class="delete" matTooltip="Remover!" (click)="removerLinha(rowData)"><i
                                class="far fa-trash-alt"></i></span>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage" let-rowData>
                        <tr>
                          <td [attr.colspan]="4" class="spaced-alert">
                            <div class="alert alert-xs alert-primary d-inline" role="alert">
                              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
                <div class="row" id="div-botoes" *ngIf="porcentagemTotal === 100 || criar || editar">
                  <div class="col-sm-12 mt-4">
                    <button class="btn btn-secondary" type="button" (click)="cancelar()"><i class="fas fa-undo"></i>
                      Cancelar</button>

                    <button class="btn btn-primary" type="button" *ngIf="criar"
                      [disabled]="(porcentagemTotal !== 100 && (formaPagamento.tipoBaixaId !== 12 && formaPagamento.tipoBaixaId !== 13
                    && formaPagamento.tipoBaixaId !== 3  && formaPagamento.tipoBaixaId !== 4))
                    || (((formaPagamento.tipoBaixaId === 12 || formaPagamento.tipoBaixaId === 13 || formaPagamento.tipoBaixaId === 3 || formaPagamento.tipoBaixaId === 4) && formaPagamento.empresaContaId === 0))"
                      (click)="salvarFormaPagamento()"><i class="fas fa-check"></i> Salvar</button>

                    <button class="btn btn-primary" type="button" *ngIf="editar"
                      [disabled]="((porcentagemTotal !== 100 && formaPagamento.tipoBaixaId !== 12 && formaPagamento.tipoBaixaId !== 13 && formaPagamento.tipoBaixaId !== 3  && formaPagamento.tipoBaixaId !== 4))
                    || (((formaPagamento.tipoBaixaId === 12 || formaPagamento.tipoBaixaId === 13 || formaPagamento.tipoBaixaId === 3 || formaPagamento.tipoBaixaId === 4) && formaPagamento.empresaContaId === 0))"
                      (click)="atualizarFormaPagamento()"><i class="fas fa-check"></i> Salvar </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>