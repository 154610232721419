<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-11">


    </div>
    <div class="ui-g-1 pr-0">
      <div *ngIf="existeLog">
        <button type="button" (click)="abreModalLog()" matTooltip="Log de Advertência" matTooltipPosition="left"
          class="btn btn-sm btn-warning pull-right"><i class="fas fa-exclamation"></i></button>
      </div>
    </div>
  </div>
  <div class="box-content pt-0">

    <div class="pull-right align-down-table">
      <button type="button" class="btn btn-link" (click)="dtformalizacoes.exportCSV()"><i
          class="fas fa-file-excel excel"></i> Exportar para Excel</button>
    </div>
    <div class="clearfix"></div>

    <p-table class="p-datatable-sm p-datatable-striped yes-overflow-x" #dtformalizacoes [columns]="colsformalizacoes"
      [value]="formalizacoes" [rows]="rows" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
      [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" style="width: 45px;">
            <span class="check-all p-0">
              <p-checkbox [ngModel]="todasSelecionadas" (change)="onSelectAll($event)"></p-checkbox>
            </span>
          </th>
          <th class="text-left" style="width: 150px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
              <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'codCta', 'contains')">
          </th>
          <th class="text-left" style="width: 150px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
              <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'descrCta', 'contains')">
          </th>
          <th class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
              <span class="text-overflow-dynamic-ellipsis">IND <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'indCta', 'contains')">
          </th>

          <th class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
              <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'nivel', 'contains')">
          </th>

          <th class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'natConta'">
              <span class="text-overflow-dynamic-ellipsis">NAT <p-sortIcon [field]="'natConta'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'natConta', 'contains')">
          </th>

          <th class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldoIni'">
              <span class="text-overflow-dynamic-ellipsis">SALDO INI <p-sortIcon [field]="'saldoIni'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'saldoIni', 'contains')">
          </th>

          <th class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcIni'">
              <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcIni'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'indDcIni', 'contains')">
          </th>

          <th class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'debito'">
              <span class="text-overflow-dynamic-ellipsis">DÉBITO <p-sortIcon [field]="'debito'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'debito', 'contains')">
          </th>

          <th class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'credito'">
              <span class="text-overflow-dynamic-ellipsis">CRÉDITO <p-sortIcon [field]="'credito'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'credito', 'contains')">
          </th>

          <th class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'saldoFin'">
              <span class="text-overflow-dynamic-ellipsis">SALDO FIN <p-sortIcon [field]="'saldoFin'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'saldoFin', 'contains')">
          </th>

          <th class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'indDcFin'">
              <span class="text-overflow-dynamic-ellipsis">D/C <p-sortIcon [field]="'indDcFin'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'indDcFin', 'contains')">
          </th>

          <th class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'diferenca'">
              <span class="text-overflow-dynamic-ellipsis">DIFERENÇA <p-sortIcon [field]="'diferenca'"></p-sortIcon>
              </span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'diferenca', 'contains')">
          </th>

          <th class="text-center" style="width: 90px;">
            <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
              <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon></span>
            </span>
            <input class="form-control form-control-sm" type="text"
              (input)="dtformalizacoes.filter($event.target.value, 'status', 'contains')">
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td class="text-center" style="width: 45px;">
            <p-checkbox [disabled]="rowData.indCta == 'S'" (ngModelChange)="check(rowData)"
              [(ngModel)]="rowData.selected"></p-checkbox>
          </td>
          <td class="text-left" style="width: 150px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.codCta}}">{{rowData.codCta }}</span>
            </span>
          </td>

          <td class="text-left" style="width: 150px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.descrCta}}">{{rowData.descrCta }}</span>
            </span>
          </td>

          <td class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.indCta}}">{{rowData.indCta }}</span>
            </span>
          </td>
          <td class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.nivel}}">{{rowData.nivel}}</span>
            </span>
          </td>
          <td class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.natConta}}">{{rowData.natConta}}</span>
            </span>
          </td>
          <td class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.saldoIni | money}}">{{rowData.saldoIni | money }}</span>
            </span>
          </td>

          <td class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.indDcIni}}">{{rowData.indDcIni }}</span>
            </span>
          </td>

          <td class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.debito | money}}">{{rowData.debito | money }}</span>
            </span>
          </td>

          <td class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.credito | money}}">{{rowData.credito | money }}</span>
            </span>
          </td>

          <td class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.saldoFin | money}}">{{rowData.saldoFin | money }}</span>
            </span>
          </td>

          <td class="text-center" style="width: 75px;">
            <span class="text-overflow-dynamic-container">
              <span class="text-overflow-dynamic-ellipsis" (click)="onRowFinanceiro(rowData)"
                matTooltip="{{rowData.indDcFin}}">{{rowData.indDcFin }}</span>
            </span>
          </td>

          <td class="text-right" style="width: 100px;">
            <span class="text-overflow-dynamic-container">
              <span *ngIf="rowData.indCta == 'A'" (click)="onRowFinanceiro(rowData)">{{rowData.diferenca |
                money}}</span>
            </span>
          </td>

          <td class="text-center" style="width: 90px;">
            <span *ngIf="rowData.indCta == 'A'" matTooltip="Informações Composição"><button type="button"
                class="btn btn-sm btn-link" (click)="onRowFinanceiro(rowData)">{{rowData.status}}</button></span>
          </td>


        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-rowData>
        <tr>
          <td [attr.colspan]="14" class="spaced-alert">
            <div class="alert alert-xs alert-primary d-inline" role="alert">
              <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>



  </div>
</mat-card>

<!-- Modal Composição Feito -->
<p-dialog [(visible)]="contaSelecionada" [style]="{width: '800px'}" [modal]="true"
  styleClass="composicao-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <p-header class="title-modal">
    Informações Composição
  </p-header>
  <mat-tab-group style="min-height:450px" (selectedTabChange)="tabChange($event)">
    <mat-tab label="Composição">
      <table class="table table-borderless mt-2">
        <tbody>
          <tr>
            <td style="width: 20%;"><strong>CONTA CONTÁBIL:</strong></td>
            <td> {{formalizacao.movSaldosContabeis.planoContas.codCta}} -
              {{formalizacao.movSaldosContabeis.planoContas.descrCta}}</td>
          </tr>
          <tr>
            <td><strong>MÊS DE REFERÊNCIA:</strong></td>
            <td>{{maxDate | date:'MM/yyyy'}}</td>
          </tr>
          <tr>
            <td><strong>FORMALIZAÇÃO:</strong></td>
            <td *ngIf="formalizacao.formalizado">{{formalizacao.usuarioFormalizado.nome}}
              {{formalizacao.usuarioFormalizado.sobrenome}}</td>
            <td *ngIf="!formalizacao.formalizado">PENDENTE</td>
          </tr>
          <tr>
            <td><strong>HOMOLOGAÇÃO:</strong></td>
            <td *ngIf="formalizacao.homologado">{{formalizacao.usuarioHomologado.nome}}
              {{formalizacao.usuarioHomologado.sobrenome}}</td>
            <td *ngIf="!formalizacao.homologado">PENDENTE</td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col-sm-12">
          <div style="max-height: 300px; overflow-y: auto;">
            <table class="table table-border-boot">
              <thead>
                <th style="width: 10%;" class="text-center">DATA</th>
                <!--<th>DOCUMENTO</th>
                    <th>OBSERVAÇÃO</th>-->
                <th style="width: 60%;">DESCRIÇÃO</th>
                <th style="width: 5%;" class="text-center">D/C</th>
                <th style="width: 15%;" class="text-right">TOTAL</th>
                <th style="width: 10%;" class="text-center">AÇÃO</th>
              </thead>
              <tbody>
                <tr *ngFor="let lcto of lancamentosFormalizacao">
                  <td class="text-center" style="width: 10%;">{{lcto.dataLcto}}</td>
                  <!--<td>{{lcto.documento}}</td>
                      <td>{{lcto.obs}}</td>-->
                  <td style="width: 60%;" matTooltip="{{lcto.descricao}}"><span class="overflow-ellipse d-block"
                      style="max-width: 435px;">{{lcto.descricao}}</span></td>
                  <td class="text-center" style="width: 5%;">{{lcto.natureza.cod}}</td>
                  <td class="text-right" style="width: 15%;">{{lcto.valor | money}}</td>
                  <td class="text-center" style="width: 10%;">
                    <button type="button" class="btn btn-sm btn-link d-inline-block edit" style="padding: 0 !important;"
                      matTooltip="Editar Item" (click)="editarLctoComposicao(lcto)"
                      [disabled]="formalizacao.formalizado || formalizacao.homologado"><i
                        class="far fa-edit"></i></button>
                    <button type="button" class="btn btn-sm btn-link d-inline-block delete"
                      style="padding: 0 !important;" matTooltip="Excluir Item" (click)="deletarLctoComposicao(lcto)"
                      [disabled]="formalizacao.formalizado || formalizacao.homologado"><i
                        class="far fa-trash-alt"></i></button>
                  </td>
                </tr>
                <tr>
                  <td class="text-right" colspan="3"><strong>TOTAL CONTA</strong></td>
                  <td class="text-right">{{lancamentosFinanceiro + vlRelTitAberto + vlEstoque + vlTerceiro| money}}</td>
                </tr>
                <tr>
                  <td class="text-right" colspan="3"><strong>VALOR CONTÁBIL</strong></td>
                  <td class="text-right">{{vlContabil | money}}</td>
                </tr>
                <tr>
                  <td class="text-right" colspan="3"><strong>DIFERENÇA</strong></td>
                  <td class="text-right">{{lancamentosFinanceiro + vlRelTitAberto + vlEstoque + vlTerceiro - vlContabil
                    -lancamentosContabil | money}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--<table width="100%">
                  <thead>
                    <th class="service">DATA</th>
                    <th class="doc">DOCUMENTO</th>
                    <th class="obs">OBSERVAÇÃO</th>
                    <th class="desc">DESCRIÇÃO</th>
                    <th class="qty">D/C</th>
                    <th class="qty"></th>
                    <th class="total">TOTAL</th>
                    <th class="unit"></th>
                    <th class="unit"></th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let lcto of lancamentosFormalizacao">
                      <td class="service">{{lcto.dataLcto}}</td>
                      <td class="doc">{{lcto.documento}}</td>
                      <td class="obs">{{lcto.obs}}</td>
                      <td class="desc">{{lcto.descricao}}</td>
                      <td class="qty">{{lcto.codigoNatureza}}</td>
                      <td class="qty"></td>
                      <td class="total">{{lcto.valor | money}}</td>
                      <td class="unit">
                        <button mat-icon-button (click)="editarLctoComposicao(lcto)" [disabled]="formalizacao.formalizado || formalizacao.homologado">
                          <mat-icon>mode_edit</mat-icon>
                        </button>
                      </td>
                      <td class="unit">
                        <button mat-icon-button (click)="deletarLctoComposicao(lcto)" [disabled]="formalizacao.formalizado || formalizacao.homologado">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6" class="total">TOTAL CONTA</td>
                      <td class="total">{{lancamentosFinanceiro + vlRelTitAberto | money}}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="6" class="total">VALOR CONTÁBIL</td>
                      <td class="total">{{vlContabil | money}}</td>
                      <td class="unit">
                      <td class="unit">
                    </tr>
                    <tr>
                      <td colspan="6" class="grand total">DIFERENÇA</td>
                      <td class="grand total">{{lancamentosFinanceiro + vlRelTitAberto - vlContabil -lancamentosContabil | money}}
                      </td>
                      <td class="grand total">
                      <td class="grand total">
                    </tr>
                  </tbody>
                </table>-->
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Anexo">
      <div class="row" *ngIf="!this.formalizacao.formalizado && !this.formalizacao.homologado">
        <div class="col-sm-12">
          <div class="form-row">
            <div class="form-group col-sm-12 mt-3 mb-0">
              <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
                <span class="input-group-append">
                  <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                                  Remover Arquivo
                                </span> -->
                  <span class="input-group-text btn-file">
                    <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                      Arquivo</span>
                    <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                      Arquivo</span> <!-- Antes era Trocar Arquivo-->
                    <input type="file" #file (change)="fileChange()" />
                  </span>
                </span>
                <div class="form-control" data-trigger="fileinput">
                  <span class="fileinput-filename"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-2">
          <table class="table table-border-boot">
            <thead>
              <tr>
                <th style="width: 90%;">Arquivo</th>
                <th style="width: 10%;" class="text-center">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let arquivo of arquivosFormalizacoes">
                <td matTooltip="Baixar Arquivo!" (click)="downloadArquivo(arquivo)">{{arquivo}}</td>
                <!-- <td class="fer">
                      <button mat-icon-button class="btn-color" (click)="downloadArquivo(arquivo)">
                        <mat-icon class="md-48">file_download</mat-icon>
                      </button>
                    </td> -->
                <td class="text-center">
                  <button type="button" class="btn btn-sm btn-link delete" style="padding: 0 !important;"
                    matTooltip="Excluir Arquivo" (click)="excluirArquivo(arquivo)"
                    [disabled]="this.formalizacao.formalizado || this.formalizacao.homologado">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
              <tr *ngIf="arquivosFormalizacoes.length === 0">
                <td colspan="2" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum arquivo
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Lançamento" *ngIf="(!formalizacao.formalizado && !formalizacao.homologado)">
      <div class="form-row">
        <div class="form-group col-sm-3">
          <label>Documento</label>
          <input type="text" class="form-control" [(ngModel)]="newLctoFormalizacao.documento">
        </div>
        <div class="form-group col-sm-3">
          <label>Natureza</label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesNatureza"
            [(ngModel)]="newLctoFormalizacao.natureza"></p-dropdown>
        </div>
        <div class="form-group col-sm-3">
          <label>Valor</label>
          <input id="in" class="form-control text-right" type="text" [(ngModel)]="newLctoFormalizacao.valor" />
        </div>
        <div class="form-group col-sm-3">
          <label>Data</label>
          <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy"
            [(ngModel)]="newLctoFormalizacao.dataLcto" [monthNavigator]="true" [yearNavigator]="true"
            yearRange="2015:2030" [defaultDate]="maxDate"></p-calendar>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Descrição</label>
          <input type="text" class="form-control" [(ngModel)]="newLctoFormalizacao.descricao">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Observação</label>
          <input type="text" class="form-control" [(ngModel)]="newLctoFormalizacao.obs">
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>

  <!-- <div class="form-row">
      <div class="form-group col-sm-12">
          Anexos
          <button mat-icon-button class="btn-color" (click)="incluirAnexo()" [disabled]="this.formalizacao.formalizado || this.formalizacao.homologado">
            <mat-icon class="md-48">cloud_upload</mat-icon>
          </button>
      </div>
    </div> -->
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="contaSelecionada = '';"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-danger" (click)="abrir();"
      *ngIf="tabIndex === 0 && (formalizacao.homologado || formalizacao.formalizado)"><i class="fas fa-times"></i>
      Desfazer</button>
    <button type="button" class="btn btn-primary" (click)="formalizar();"
      *ngIf="tabIndex === 0 && !formalizacao.homologado && !formalizacao.formalizado"><i class="fas fa-check"></i>
      Formalizar</button>
    <button type="button" class="btn btn-primary" (click)="homologar();"
      *ngIf="tabIndex === 0 && !formalizacao.homologado && formalizacao.formalizado"><i class="fas fa-check"></i>
      Homologar</button>
    <button type="button" class="btn btn-primary"
      [disabled]="!newLctoFormalizacao.dataLcto || !newLctoFormalizacao.descricao || !newLctoFormalizacao.valor || !newLctoFormalizacao.natureza"
      (click)="salvarLancamentoComposicao();"
      *ngIf="tabIndex === 2 && (!formalizacao.formalizado && !formalizacao.homologado)"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<!-- <p-dialog [(visible)]="modalAnexo" width="800" [modal]="true" styleClass="lks-modal" [responsive]="true" appendTo="body" [closable]="false">
  <p-header>
    Incluir Anexo
  </p-header>
  <div class="lks-modal-content">
    <tr>
      <td>
        <input type="file" #file (change)="fileChange()" />
        <br>
      </td>
    </tr>
          <p-footer>
              <div class="lks-modal-button">
            <button mat-raised-button class="button-default" (click)='modalAnexo=false'>Fechar</button>
          </div>
      </p-footer>
  </div>
</p-dialog> -->

<!-- Modal Composição Feito -->
<p-dialog [(visible)]="modalComposicao" [style]="{width: '800px'}" [modal]="true" styleClass="lks-modal"
  [responsive]="true" appendTo="body" [closable]="false">
  <p-header>
    <div *ngIf="!editarLctoComp">Incluir Lançamento de Composição</div>
    <div *ngIf="editarLctoComp">Editar Lançamento de Composição</div>
  </p-header>
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Documento</label>
      <input type="text" class="form-control" [(ngModel)]="newLctoFormalizacao.documento">
    </div>
    <div class="form-group col-sm-3">
      <label>Natureza</label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesNatureza"
        [(ngModel)]="newLctoFormalizacao.natureza"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Valor</label>
      <input id="in" class="form-control text-right" type="text" [(ngModel)]="newLctoFormalizacao.valor" />
    </div>
    <div class="form-group col-sm-3">
      <label>Data</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy"
        [(ngModel)]="newLctoFormalizacao.dataLcto" [monthNavigator]="true" [yearNavigator]="true" yearRange="2015:2030"
        [defaultDate]="maxDate"></p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input type="text" class="form-control" [(ngModel)]="newLctoFormalizacao.descricao">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Observação</label>
      <input type="text" class="form-control" [(ngModel)]="newLctoFormalizacao.obs">
    </div>
  </div>
  <p-footer>
    <div *ngIf="!editarLctoComp">
      <button type="button" class="btn btn-secondary" (click)='modalComposicao=false'><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary"
        [disabled]="!newLctoFormalizacao.dataLcto || !newLctoFormalizacao.descricao || !newLctoFormalizacao.valor || !newLctoFormalizacao.natureza"
        (click)="salvarLancamentoComposicao();"><i class="fas fa-check"></i> Salvar</button>
    </div>
    <div *ngIf="editarLctoComp">
      <button type="button" class="btn btn-secondary" (click)='modalComposicao=false'><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary"
        [disabled]="!newLctoFormalizacao.dataLcto || !newLctoFormalizacao.descricao || !newLctoFormalizacao.valor || !newLctoFormalizacao.natureza"
        (click)="atualizarLancamentoComposicao();"><i class="fas fa-check"></i> Salvar</button>
    </div>
  </p-footer>
</p-dialog>

<!-- Modal Alterar Status FEITO -->
<p-dialog header="Alterar status das contas selecionadas" [(visible)]="modalEditarLote" [style]="{width: '600px'}"
  [modal]="true" class="modal-linnks" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Selecionar Ação</label>
      <!-- To do Fernando -->
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="acoes" [(ngModel)]="statusAlterar"
        placeholder="Selecione"></p-dropdown>
      <!--<mat-form-field>
                <mat-select class="example-full-width" [(ngModel)]="statusAlterar" style="z-index: 9999;" appendTo="body">
                  <mat-option *ngFor="let a of acoes" [value]="a.value">
                    {{ a.viewValue }}
                  </mat-option>
                </mat-select>
            </mat-form-field>-->
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalEditarLote=false'><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" [disabled]="disabledAlteracaoLote()" (click)="salvarAlteracao()"><i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>

<!-- Modal Alterar Status FEITO -->
<p-dialog [(visible)]="modalLog" [modal]="true" appendTo="body" [style]="{width: '800px'}" styleClass="lks-modal"
  [responsive]="true" [closable]="true">
  <p-header>
    Log de Advertência
  </p-header>
  <div class="row">
    <div class="col-sm-12">
      <ul class="list-group list-group-flush list-log">
        <li class="list-group-item" *ngFor="let log of logAdvertencia">
          <i class="fas fa-exclamation"></i> {{log.detail }}
        </li>
      </ul>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)='modalLog=false'><i class="fas fa-undo"></i> Fechar</button>
  </p-footer>
</p-dialog>