<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content p-3" style="margin-top: -25px">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMov"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta" class="size">

            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroMovTermoAe" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="onRowSelect($event)" [(selection)]="selectMov"
              (onRowUnselect)="onRowUnselect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 3%;">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dnrcAbert'">
                      <span class="text-overflow-dynamic-ellipsis">
                        TERMO. FIXO ABERTURA <p-sortIcon [field]="'dnrcAbert'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dnrcAbert', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'numOrd'">
                      <span class="text-overflow-dynamic-ellipsis">
                        N° ORDEM <p-sortIcon [field]="'numOrd'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'numOrd', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'natLivr'">
                      <span class="text-overflow-dynamic-ellipsis">
                        NATUREZA DO LIVRO <p-sortIcon [field]="'natLivr'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'natLivr', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nire'">
                      <span class="text-overflow-dynamic-ellipsis">
                        NIRE <p-sortIcon [field]="'nire'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'nire', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtArquivo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DATA.ARQ. <p-sortIcon [field]="'dtArquivo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtArquivo', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtArquivoConvert'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DT.ARQ.CONVERSÃO <p-sortIcon [field]="'dtArquivoConvert'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtArquivoConvert', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEnceSocial'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DATA ENCE.EX.SOCIAL <p-sortIcon [field]="'dtEnceSocial'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtEnceSocial', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeAuditor'">
                      <span class="text-overflow-dynamic-ellipsis">
                        NOME AUDITOR <p-sortIcon [field]="'nomeAuditor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'nomeAuditor', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCvmAuditor'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CVM AUDITOR <p-sortIcon [field]="'codCvmAuditor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'codCvmAuditor', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dnrcEncer'">
                      <span class="text-overflow-dynamic-ellipsis">
                        TERM. FIXO ENCE. <p-sortIcon [field]="'dnrcEncer'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dnrcEncer', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadEcd.id'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CAD ECD <p-sortIcon [field]="'cadEcd.id'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'cadEcd.id', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 3%;">
                    <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData">
                    </p-tableRadioButton>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.dnrcAbert}}">{{rowData.dnrcAbert}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.numOrd}}">{{rowData.numOrd}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.natLivr}}">
                        {{rowData.natLivr}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nire}}">
                        {{rowData.nire}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtArq}}">
                        {{rowData.dtArq | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtArqConv}}">
                        {{rowData.dtArqConv | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtExSocial}}">
                        {{rowData.dtExSocial | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeAuditor}}">
                        {{rowData.nomeAuditor}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codCvmAuditor}}">
                        {{rowData.codCvmAuditor}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dnrcEncer}}">
                        {{rowData.dnrcEncer}}
                      </span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cadEcd}}">{{rowData.cadEcd
                        != null? rowData.cadEcd.id: ''}}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="12" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div class="form-size">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>TTERM. FIXO ABERTURA<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="termFixoAber">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>NUMERO DE ORDEM<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="numOrdem">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>NATUREZA DO LIVRO<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="natLivro">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>NIRE<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="nire">
              </div>
            </div>

            <div class="form-row">

              <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
                <label>DATA ARQUIVAMENTO</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="dtArquiv">
                </p-calendar>
              </div>

              <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
                <label>DT. ARQ. CONVERSÃO</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="dtArqConv">
                </p-calendar>
              </div>

              <div class="form-group" style="flex: 1;padding-right: 0.25rem;padding-left: 1rem;">
                <label>DATA ENCER. EX. SOCIAL</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="dtExSocial">
                </p-calendar>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>NOME AUDITOR</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="nomeAuditor">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>CVM AUDITOR</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="cvmAuditor">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>TERM. FIXO ENCER.<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="termFixoEncer">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>CAD ECD<span class="obrigatorio">*</span></label>
                <div *ngIf="editarConta">
                  <input class="form-control" id="input" type="text" disabled pInputText [(ngModel)]="cad_ecd">
                </div>
                <div *ngIf="novaConta">
                  <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="opcoesTipo"
                    [(ngModel)]="cad_ecd">
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>