<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content class="mat-card-linnks">
    <div class="tabela-data-table">

      <p-table class="table-system" #dtenvios [value]="envios" [rows]="rows" [paginator]="true"
        (onRowSelect)="onRowSelectEnvio($event)" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-left" style="width: 35%; ">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Obrigação </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtenvios.filter($event.target.value, 'obrigacao', 'contains')">
            </th>
            <th class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Envio </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtenvios.filter($event.target.value, 'dtEnvio', 'contains')">
            </th>
            <th class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Vencimento </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtenvios.filter($event.target.value, 'dtVencimento', 'contains')">
            </th>

            <th class="text-left" style="width: 13%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Retificado </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtenvios.filter($event.target.value, 'retificado', 'contains')">
            </th>

            <th class="text-left" style="width: 12%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Responsável </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtenvios.filter($event.target.value, 'usuario', 'contains')">
            </th>

            <th class="text-right" style="width: 80px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Valor </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtenvios.filter($event.target.value, 'vlSaldoRecolher', 'contains')">
            </th>

            <th class="text-center" style="width: 9%; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Confirmação </span>
              </span>
            </th>

            <th class="text-center" style="width: 8%; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">Download </span>
              </span>

            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">

            <td class="text-left" style="width: 35%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.obrigacao}}">{{rowData.obrigacao
                  }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtEnvio}}">{{rowData.dtEnvio
                  }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dtVencimento}}">{{rowData.dtVencimento}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.retificado}}">{{rowData.retificado}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.usuario}}">{{rowData.usuario
                  }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 80px;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.vlSaldoRecolher | money}}">{{rowData.vlSaldoRecolher | money }}</span>
              </span>
            </td>

            <td class="text-center" style="width: 10%;">

              <!-- Inicio config -->

              <span *ngIf="(rowData.nconf > 0 && rowData.conf === 0) && (rowData.nconf !== 0 || rowData.conf !== 0)">

                <span>
                  <i class="material-icons-done">done</i>
                </span>
                <span *ngIf="rowData.gera > 0 && rowData.comprovante === 0">
                  <i class="material-icons-done-all-concluido-dolar">attach_money</i>
                </span>
                <span *ngIf="rowData.gera > 0 && rowData.comprovante > 0">
                  <i class="material-icons-done-all-concluido-dolar-concluido">attach_money</i>
                </span>

              </span>

              <span *ngIf="rowData.nconf > 0 && rowData.conf > 0 && (rowData.nconf !== 0 || rowData.conf !== 0)">

                <span>
                  <i class="material-icons-done-all">done_all</i>
                </span>
                <span *ngIf="rowData.gera > 0 && rowData.comprovante === 0">
                  <i class="material-icons-done-all-concluido-dolar">attach_money</i>
                </span>
                <span *ngIf="rowData.gera > 0 && rowData.comprovante > 0">
                  <i class="material-icons-done-all-concluido-dolar-concluido">attach_money</i>
                </span>

              </span>

              <span *ngIf="rowData.nconf === 0 && (rowData.nconf !== 0 || rowData.conf !== 0)">

                <span>
                  <i class="material-icons-done-all-concluido">done_all</i>
                </span>
                <span *ngIf="rowData.gera > 0 && rowData.comprovante === 0">
                  <i class="material-icons-done-all-concluido-dolar">attach_money</i>
                </span>
                <span *ngIf="rowData.gera > 0 && rowData.comprovante > 0">
                  <i class="material-icons-done-all-concluido-dolar-concluido">attach_money</i>
                </span>

              </span>

              <span *ngIf="rowData.nconf === 0 && rowData.conf === 0">

                <span>
                  <i class="material-icons-done">done</i>
                </span>

              </span>

              <!-- Final config -->


            </td>

            <td class="text-center" style="width: 10%;">

              <span class="material-icons" (click)="export(rowData)">
                file_download
              </span>

            </td>



          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="13" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>



    </div>

    <div *ngIf="exibirModalHistoricoEnvio">
      <p-dialog [(visible)]="exibirModalHistoricoEnvio" [style]="{width: '800px'}" [modal]="true" class="modal-linnks"
        [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="false">
        <p-header>
          Histórico de Envio
        </p-header>
        <div class="conteudo">
          <p-accordion>
            <p-accordionTab header="Informações">
              <div class="ui-g">
                <div class="ui-g-4 label-modal">
                  Enviado por:
                </div>
                <div class="ui-g-8">
                  {{envioExibir.usuario.nome}} {{envioExibir.usuario.sobrenome}} em: {{ envioExibir.dtEnvio |
                  date:'dd/MM/yyyy HH:mm' }}
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4 label-modal">
                  Enviado para:
                </div>
                <div class="ui-g-8">
                  <textarea [rows]="3" [cols]="50" [disabled]="true" pInputTextarea
                    autoResize="false">{{envioExibir.outrosDestinatarios }}</textarea>

                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4 label-modal">
                  Tipo de Documento:
                </div>
                <div class="ui-g-8">
                  {{envioExibir.tipoDocumento.cod}}
                </div>
              </div>
              <div class="ui-g" *ngIf="envioExibir.justificativa">
                <div class="ui-g-4 label-modal">
                  Justificativa:
                </div>
                <div class="ui-g-8">
                  {{envioExibir.justificativa.descricao}}
                </div>
              </div>
              <div class="ui-g" *ngIf="envioExibir.detalhesJustificativa">
                <div class="ui-g-4 label-modal">
                  Detalhes Justificativa:
                </div>
                <div class="ui-g-8">
                  {{envioExibir.detalhesJustificativa}}
                </div>
              </div>
              <div class="ui-g" *ngIf="envioExibir.mensagem">
                <div class="ui-g-4 label-modal">
                  Mensagem enviada ao cliente:
                </div>
                <div class="ui-g-8">
                  {{envioExibir.mensagem}}
                </div>
              </div>

            </p-accordionTab>
            <p-accordionTab header="Valores" [disabled]="envioExibir.vlPrincipal.valueOf() === 0.00 &&
            envioExibir.vlMulta.valueOf() === 0.00 && envioExibir.vlJuros.valueOf() === 0.00 && envioExibir.vlSaldoRecolher.valueOf() === 0.00
            && envioExibir.vlImposto1.valueOf() === 0.00 && envioExibir.vlImposto2.valueOf() === 0.00 && envioExibir.vlImposto3.valueOf() === 0.00
            && envioExibir.vlImposto4.valueOf() === 0.00">

              <div
                *ngIf="(envioExibir.obrigacao.codigosObrigacoes.cod !== 'SPED Fiscal' && envioExibir.obrigacao.codigosObrigacoes.cod !== 'EFD Contribuições'
              && envioExibir.obrigacao.codigosObrigacoes.cod !== 'SISCOSERV') && (envioExibir.obrigacao.tipoArquivo.id !== 5 && envioExibir.obrigacao.tipoArquivo.id !== 7)">
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor Principal:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlPrincipal | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor Multa:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlMulta | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor Juros:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlJuros | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Saldo Recolher:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlSaldoRecolher | money }}</div>
                </div>
              </div>
              <div
                *ngIf="envioExibir.obrigacao.codigosObrigacoes.cod === 'SPED Fiscal' && envioExibir.tipoDocumento.id === 5">
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor ICMS:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto1 | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor IPI:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto2 | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor ICMS-ST:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto3 | money }}</div>
                </div>
              </div>
              <div *ngIf="envioExibir.obrigacao.codigosObrigacoes.cod === 'GIA' && envioExibir.tipoDocumento.id === 5">
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor ICMS:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto1 | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    Valor ICMS-ST:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto2 | money }}</div>
                </div>
              </div>
              <div
                *ngIf="envioExibir.obrigacao.codigosObrigacoes.cod === 'EFD Contribuições' && envioExibir.tipoDocumento.id === 5">
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    PIS:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto1 | money }}</div>
                </div>
                <div class="ui-g">
                  <div class="ui-g-4 label-modal">
                    COFINS:
                  </div>
                  <div class="ui-g-8">{{envioExibir.vlImposto2 | money }}</div>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab header="Confirmação de Recebimento" [disabled]="envioDestinatariosList.length === 0">
              <table>
                <thead>
                  <td width="400px">
                    E-mail
                  </td>
                  <td width="100px">
                    Confirmação
                  </td>
                </thead>
                <tr *ngFor="let e of envioDestinatariosList">
                  <td width="400px">
                    {{e.mail}}
                  </td>
                  <td width="100px">
                    {{e.dtHrConfirmacao | date:'dd/MM/yyyy HH:mm'}}
                  </td>
                </tr>
              </table>
            </p-accordionTab>
            <p-accordionTab header="Confirmação de Pagamento" [disabled]="verificaPagamento">
              <tr>
                <td width="50%">
                  Valor Principal
                </td>
                <td>

                  <input type="text" size="30" pInputText [(ngModel)]="vlPrincipal" money (keyup)="calcSaldoRecolher()">
              </tr>
              <tr>
                <td width="50%">
                  Valor Multa
                </td>
                <td>
                  <input type="text" size="30" pInputText [(ngModel)]="vlMulta" money (keyup)="calcSaldoRecolher()" />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  Valor Juros
                </td>
                <td>
                  <input type="text" size="30" pInputText [(ngModel)]="vlJuros" money (keyup)="calcSaldoRecolher()" />
                </td>
              </tr>
              <tr>
                <td width="50%">
                  Valor Saldo Recolher
                </td>
                <td>
                  <input type="text" size="30" [disabled]="true" pInputText [(ngModel)]="vlSaldoRecolher" />
                </td>
              </tr>
              <tr>
                <td>
                  <input type="file" #file (change)="fileChange()" />
                </td>
                <td>
                  <button mat-icon-button (click)="importar()">
                    <mat-icon class="md-24">attach_file</mat-icon>
                  </button>
                </td>
              </tr>
              <tr>
                <div *ngIf="files.length > 0">
                  <table>
                    <tr>
                      <th>
                        Anexos
                      </th>

                      <th>

                      </th>
                      <th>

                      </th>


                    </tr>

                    <tr *ngFor="let file of files">
                      <td>
                        {{file.nome}}
                      </td>
                      <td>
                        <button mat-icon-button>
                          <mat-icon class="md-24">file_download</mat-icon>
                        </button>
                      </td>
                      <td>
                        <button mat-icon-button>
                          <mat-icon class="md-24">delete</mat-icon>
                        </button>
                      </td>

                    </tr>
                  </table>
                </div>
              </tr>
              <div class="ui-g" id="div-botoes">
                <div class="margin-botoes-modal">
                  <div class="ui-g-12" *ngIf="comprovantePagamento === undefined">
                    <button class="btn-color" (click)="salvarPagamento()"
                      [disabled]="vlSaldoRecolher === 0 || files.length === 0"
                      [style]="{'border':'none', 'margin-right': '20px'}" class="mat-elevation-z0">Salvar</button>
                  </div>
                  <div class="ui-g-6" *ngIf="comprovantePagamento !== undefined">
                    <button class="btn-color" (click)="atualizarPagamento()"
                      [disabled]="vlSaldoRecolher === 0 || files.length === 0">
                      Atualizar</button>
                  </div>
                  <div class="ui-g-6" *ngIf="comprovantePagamento !== undefined">
                    <button class="botao-excluir" (click)="excluirComprovantePagamento()" class="mat-elevation-z0">
                      Excluir</button>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div class="ui-g">
          <div class="ui-g-12" id="div-botoes">
            <div class="margin-botoes-modal">
              <button mat-raised-button (click)='exibirModalHistoricoEnvio=false'
                [style]="{'border':'none', 'height':'40px', 'margin-right': '20px'}"
                class="mat-elevation-z0">Fechar</button>
            </div>
          </div>
        </div>
      </p-dialog>
    </div>
  </mat-card-content>
</mat-card>