<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <!-- <h2 *ngIf="!contaSelecionada" font-family="Lato">Selecione uma conta analítica do referencial da Receita Federal para ser mapeada</h2> -->

  <div *ngIf="!contaSelecionada">
    <div class="box-content">
      <p-table class="table-system" #dt1 [value]="planoContasRfbList" (onRowSelect)="editPlanoContasRfb($event)"
        selectionMode="single" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoCta'">
                <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipoCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'tipoCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivelCta'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivelCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'nivelCta', 'contains')">
            </th>
            <th class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'descrCta', 'contains')">
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData" [ngClass]="rowData.tipoCta !== 'A'  ? 'bold locked' : ''">
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.codCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.tipoCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.nivelCta}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="4" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="mapeamento">
      <div *ngIf="mapeamento" class="alert alert-warning mt-3" role="alert">
        <strong><i class="fas fa-exclamation-triangle"></i></strong> Faltam <strong>{{ mapeamento.pendentes }}</strong>
        Contas Contábeis Analíticas serem mapeadas de um total de <strong>{{ mapeamento.total }}</strong>
      </div>
    </div>
  </div>

  <div *ngIf="contaSelecionada">
    <!-- <table>
        <tr>
          <td>
            <button pButton type="button" icon="fa-arrow-left" iconPos="left" class="ui-button-secondary" (click)="back()" title="Voltar"
              style="font-size: 16px"></button>
          </td>
          <td>

          </td>
        </tr>
      </table> -->
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Conta Contábil: {{planoContasRfb.codCta}} -
            {{planoContasRfb.descrCta}}</h3>
        </div>
      </div>
      <p-table class="table-system" #dt2 [value]="planoContasList" [style]="{'width':'100%'}" immutable="false"
        (onRowSelect)="check($event)" (onRowUnselect)="uncheck($event)" (onFilter)="onFilterplanoContasList($event)"
        [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''" [scrollable]="planoContasList.length >= 15 ? true : false"
        scrollHeight="320px">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 40px;"></th>
            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'descrCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'indCta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [ngClass]="rowData.indCta === 'S'  ? 'bold ' : ''">
            <td class="text-center" style="width: 40px;">
              <p-tableCheckbox [disabled]="planoContasDisabled(rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.codCta}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.nivel}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.indCta}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="form-row">
      <div class="form-group col mt-4">
        <button type="button" class="btn btn-sm btn-info" (click)="add()" [disabled]="addDisabled"
          matTooltip="Adicionar Contas" matTooltipPosition="right"><i class="fas fa-angle-double-down"></i>
          Adicionar</button>
        <button type="button" class="btn btn-sm btn-info" (click)="remove()" [disabled]="removeDisabled"
          matTooltip="Remover Contas" matTooltipPosition="right"><i class="fas fa-angle-double-up"></i> Remover</button>
      </div>
    </div>

    <div class="box-content mt-2">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Contas Adicionadas</h3>
        </div>
      </div>
      <p-table class="table-system" #dt3 [value]="planoContasSelectedList" [style]="{'width':'100%'}" immutable="false"
        (onFilter)="onFilterplanoContasSelectedList($event)"
        [ngClass]="contadorLinhaSelected < 15 ? 'no-overflow-y' : ''"
        [scrollable]="planoContasSelectedList.length >= 15 ? true : false" scrollHeight="320px">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 40px;"></th>
            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'descrCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'indCta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center" style="width: 40px;">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.codCta}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.nivel}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.indCta}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</mat-card>