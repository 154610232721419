<mat-card class="card-linnks margin-linnks">
  <!-- <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title> -->

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pt-2">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content mt-1">
              <h3 class="title-card"> PARAMÊTROS VENDAS</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigFluxComissao>
            <div class="form-row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-list"> </i> Paramêtros vendedor</h3>
              </div>
              <div class="col-sm-6 mb-4">
                <mat-slide-toggle class="mr-2" [(ngModel)]="vendedorObrigatorio">
                  Vendedor Obrigatório
                </mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-list"> </i> Paramêtros relatório de vendas</h3>
              </div>
              <div class="col-sm-3 mb-4">
                <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                  [(ngModel)]="filtroDataRelatorioVendas" [options]="opcoesFiltroRelatorioVendas" appendTo="body">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="col-sm-12">
                <h3 class="sub-title"><i class="fas fa-list"> </i> Paramêtros tela de pedidos</h3>
              </div>
              <div class="col-sm-3 mb-4">
                <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
                  [(ngModel)]="filtroDataPedido" [options]="opcoesFiltroPedido" appendTo="body"></p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

</mat-card>