<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">

      <mat-tab label="Gateways">
        <div class="box-content">

            <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas  fa-hand-holding-usd"></i> Gateways</h3>
            </div>
            </div>
            <div class="row">
            <div class="col-sm-8">
              <div class="conteudo m-0">
                <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Relacionados" [source]="listaGateways"
                  [target]=" listaGatewaysAtribuidos " [dragdrop]="true" [responsive]="true" filterBy="codProj"
                  [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-item pTemplate="item">{{ item.codigo }} - {{ item.descricao }}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>

</mat-card>