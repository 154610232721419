<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Espaço por cliente
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Consultar o espaço BDD e EC2 dos clientes</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">Lista por cliente (atualização {{dt_atualizacao | date: 'dd/MM/yyyy'}})</h3>
          </div>
        </div>
        <div class="box-content">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="lista" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                      <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'id', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
                      <span class="text-overflow-dynamic-ellipsis">FANTASIA <p-sortIcon
                          [field]="'fantasia'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'fantasia', 'contains')">
                  </th>
                  <th class="text-right">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'espaco_bdd'">
                      <span class="text-overflow-dynamic-ellipsis">ESPAÇO BDD (MB) <p-sortIcon
                          [field]="'espaco_bdd'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'espaco_bdd', 'contains')">
                  </th>
                  <th class="text-right">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'espaco_bdd_porcent'">
                      <span class="text-overflow-dynamic-ellipsis">ESPAÇO BDD (%) <p-sortIcon
                          [field]="'espaco_bdd_porcent'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'espaco_bdd_porcent', 'contains')">
                  </th>
                  <th class="text-right">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'espaco_ec2'">
                      <span class="text-overflow-dynamic-ellipsis">ESPAÇO EC2 (MB) <p-sortIcon
                          [field]="'espaco_ec2'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'espaco_ec2', 'contains')">
                  </th>
                  <th class="text-right">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'espaco_ec2_porcent'">
                      <span class="text-overflow-dynamic-ellipsis">ESPAÇO EC2 (%) <p-sortIcon
                          [field]="'espaco_ec2_porcent'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'espaco_ec2_porcent', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.id }}">{{ rowData.id }}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.fantasia }}">{{
                        rowData.fantasia }}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.espaco_bdd | number : '1.0-0' : 'pt-BR' }}">{{
                        rowData.espaco_bdd | number : '1.0-0' : 'pt-BR' }}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.espaco_bdd_porcent }}">{{
                        rowData.espaco_bdd_porcent }}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.espaco_ec2 | number : '1.0-0' : 'pt-BR' }}">{{
                        rowData.espaco_ec2 | number : '1.0-0' : 'pt-BR' }}</span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.espaco_ec2_porcent }}">{{
                        rowData.espaco_ec2_porcent }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert"><i class="fas fa-info-circle"></i>
                      Nenhum Registro Encontrado!</div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>