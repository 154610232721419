<mat-card class="card-linnks margin-linnks">
		<mat-card-title>
        {{titlePrincipal}}
        <mat-card-subtitle>
            {{subtitle}}
        </mat-card-subtitle>
    </mat-card-title>

<div class="container">
	<div class="fixed-th-table-wrapper">
		<table>

			<tr>
				<th class="th-cab" colspan=2>DESCRIÇÃO</th>
				<td class="td-cab text-right"><span>JAN</span></td>
				<td class="td-cab text-right"><span>FEV</span></td>
				<td class="td-cab text-right"><span>MAR</span></td>
				<td class="td-cab text-right"><span>ABR</span></td>
				<td class="td-cab text-right"><span>MAI</span></td>
				<td class="td-cab text-right"><span>JUN</span></td>
				<td class="td-cab text-right"><span>JUL</span></td>
				<td class="td-cab text-right"><span>AGO</span></td>
				<td class="td-cab text-right"><span>SET</span></td>
				<td class="td-cab text-right"><span>OUT</span></td>
				<td class="td-cab text-right"><span>NOV</span></td>
				<td class="td-cab text-right"><span>DEZ</span></td>
			</tr>
			<tr *ngFor="let somaResultado of listaListSomaResultado">
				<th colspan="2"><span matTooltip="O valor é a soma dos saldos das contas contábeis de natureza 4">Lucro (Prejuízo) Acumulado</span></th>
				<td class="currency"><span>{{ somaResultado.jan | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.fev | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.mar | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.abr | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.mai | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.jun | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.jul | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.ago | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.set | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.out | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.nov | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.dez | money }}</span></td>
			</tr>
			<tr *ngIf="listSomaCsll?.length === 0">
				<th align="left" colspan="2"><span matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ e CSLL</span></th>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
			</tr>
			<tr *ngFor="let somaCsll of listSomaCsll">
				<th align="left" colspan="2"><span matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ e CSLL</span></th>
				<td class="currency"><span>{{ somaCsll.jan | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.fev | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.mar | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.abr | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.mai | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.jun | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.jul | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.ago | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.set | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.out | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.nov | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.dez | money }}</span></td>
			</tr>
			<tr *ngFor="let somaResultadoAntes of listaSomaResultadoAntesIrpj">
				<th align="left" colspan=2><span matTooltip="Valor do Lucro(Prejuízo)Acumulado + IRPJ e CSLL">Lucro (Prejuízo) Acumulado antes do IRPJ e CSLL</span></th>
				<td class="currency"><span>{{ somaResultadoAntes.jan | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.fev | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.mar | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.abr | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.mai | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.jun | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.jul | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.ago | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.set | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.out | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.nov | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.dez | money }}</span></td>
			</tr>

		<tr class="dtc" *ngIf="listaListSoma.length === 0">
				<th class="th-subtotal" colspan="2">ADIÇÕES</th>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
			</tr>

		<tr class="dtc" *ngFor="let tbCsll of listaListSoma">
				<th class="th-subtotal" colspan="2">ADIÇÕES</th>
				<td class="td-subtotal"><span>{{ tbCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbCsll of listaList">
				<th colspan="2"><span matTooltip="{{tbCsll.descricao}}">{{tbCsll.cod}} - {{tbCsll.desc_mini}}</span> </th>
				<td class="currency"><span>{{ tbCsll.jan | money }}</span></td>
				<td class="currency"><span>{{ tbCsll.fev | money }}</span></td>
				<td class="currency"><span>{{ tbCsll.mar | money }}</span></td>
				<td class="currency"><span>{{tbCsll.abr | money }}</span></td>
				<td class="currency"><span>{{tbCsll.mai | money }}</span></td>
				<td class="currency"><span>{{tbCsll.jun | money }}</span></td>
				<td class="currency"><span>{{tbCsll.jul | money }}</span></td>
				<td class="currency"><span>{{tbCsll.ago | money }}</span></td>
				<td class="currency"><span>{{tbCsll.set | money }}</span></td>
				<td class="currency"><span>{{tbCsll.out | money }}</span></td>
				<td class="currency"><span>{{tbCsll.nov | money }}</span></td>
				<td class="currency"><span>{{tbCsll.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngIf="listaListSomaExc.length === 0">
				<th class="th-subtotal" colspan="2">EXCLUSÕES</th>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbCsll of listaListSomaExc">
				<th class="th-subtotal" colspan="2">EXCLUSÕES</th>
				<td class="td-subtotal"><span>{{ tbCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbExc of listaListExc">
				<th colspan="2"><span matTooltip="{{tbExc.descricao}}">{{tbExc.cod}} - {{tbExc.desc_mini}}</span> </th>
				<td class="currency"><span>{{ tbExc.jan | money }}</span></td>
				<td class="currency"><span>{{ tbExc.fev | money }}</span></td>
				<td class="currency"><span>{{ tbExc.mar | money }}</span></td>
				<td class="currency"><span>{{tbExc.abr | money }}</span></td>
				<td class="currency"><span>{{tbExc.mai | money }}</span></td>
				<td class="currency"><span>{{tbExc.jun | money }}</span></td>
				<td class="currency"><span>{{tbExc.jul | money }}</span></td>
				<td class="currency"><span>{{tbExc.ago | money }}</span></td>
				<td class="currency"><span>{{tbExc.set | money }}</span></td>
				<td class="currency"><span>{{tbExc.out | money }}</span></td>
				<td class="currency"><span>{{tbExc.nov | money }}</span></td>
				<td class="currency"><span>{{tbExc.dez | money }}</span></td>
			</tr>
			<tr *ngFor="let somaLucroCompensacao of listaSomaLucroAntesCompensacao">
				<th class="th-subtotal" colspan="2"><span matTooltip="Lucro(Prejuízo) Acumulado + ADIÇÕES - EXCLUSÕES">LUCRO ANTES DA COMPENSAÇÃO PREJ. FISCAL</span></th>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.dez | money }}</span></td>
			</tr>
			<tr>
				<th colspan="2">PREJUÍZO FISCAL A COMPENSAR</th>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
			</tr>
			<tr *ngFor="let prejFiscalCompensado of listaPrejFiscalCompensado">
				<th class="left" colspan="2"><span matTooltip="30% do Lucro Antes da Compensação">PREJUÍZO FISCAL COMPENSADO</span></th>
				<td class="currency"><span>{{ prejFiscalCompensado.jan | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.fev | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.mar | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.abr | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.mai | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.jun | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.jul | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.ago | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.set | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.out | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.nov | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let compensacaoPrejPeriodoAnterior of listaCompensacaoPrejPeriodoAnterior">
				<th class="left" colspan="2">COMPENSAÇÃO PREJUÍZOS PERÍODOS ANTERIORES</th>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jan | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.fev | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mar | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.abr | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mai | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jun | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jul | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.ago | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.set | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.out | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.nov | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let saldoPrejFiscalCompensar of listaSaldoPrejFiscalCompensar">
				<th class="left" colspan="2"><span matTooltip="Prejuizo Fiscal a Compensar - Prejuizo Fiscal Compensado">SALDO DE PREJUÍZO FISCAL A COMPENSAR</span></th>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.jan | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.fev | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.mar | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.abr | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.mai | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.jun | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.jul | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.ago | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.set | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.out | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.nov | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let lucroAjustado of listaLucroAjustado">
				<th class="th-subtotal" colspan="2"><span matTooltip="Lucro antes da Compensação - Compensação Prejuízo de Períodos Anteriores">LUCRO AJUSTADO</span></th>
				<td class="td-subtotal"><span>{{ lucroAjustado.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.dez | money }}</span></td>
			</tr>
			<tr *ngFor="let impCsll of listaImpCsll">
				<th class="left" colspan="2"><span matTooltip="9% do LUCRO AJUSTADO">Contribuição Social (9%)</span></th>
				<td class="currency"><span>{{ impCsll.jan | money }}</span></td>
				<td class="currency"><span>{{ impCsll.fev | money }}</span></td>
				<td class="currency"><span>{{ impCsll.mar | money }}</span></td>
				<td class="currency"><span>{{ impCsll.abr | money }}</span></td>
				<td class="currency"><span>{{ impCsll.mai | money }}</span></td>
				<td class="currency"><span>{{ impCsll.jun | money }}</span></td>
				<td class="currency"><span>{{ impCsll.jul | money }}</span></td>
				<td class="currency"><span>{{ impCsll.ago | money }}</span></td>
				<td class="currency"><span>{{ impCsll.set | money }}</span></td>
				<td class="currency"><span>{{ impCsll.out | money }}</span></td>
				<td class="currency"><span>{{ impCsll.nov | money }}</span></td>
				<td class="currency"><span>{{ impCsll.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let totalCsll of listaTotalCsll">
				<th class="th-subtotal" colspan="2"><span>TOTAL CSLL A RECOLHER</span></th>
				<td class="td-subtotal"><span>{{ totalCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let totalCsll of listaTotalAntecCsll">
				<th class="th-subtotal" colspan="2"><span matTooltip="I">ANTECIPAÇÕES DE CSLL</span></th>
				<td class="td-subtotal"><span>{{ totalCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.dez | money }}</span></td>
			</tr>


			<tr class="dtc" *ngFor="let tbSomaDeducoes of listaListSomaDeducoes">
				<th class="th-subtotal" colspan="2">DEDUÇÕES</th>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbDed of listaListDed">

				<th colspan="2"><span matTooltip="{{tbDed.descricao}}">{{tbDed.cod}} - {{tbDed.desc_mini}}</span> </th>
				<td class="currency"><span>{{ tbDed.jan | money }}</span></td>
				<td class="currency"><span>{{ tbDed.fev | money }}</span></td>
				<td class="currency"><span>{{ tbDed.mar | money }}</span></td>
				<td class="currency"><span>{{tbDed.abr | money }}</span></td>
				<td class="currency"><span>{{tbDed.mai | money }}</span></td>
				<td class="currency"><span>{{tbDed.jun | money }}</span></td>
				<td class="currency"><span>{{tbDed.jul | money }}</span></td>
				<td class="currency"><span>{{tbDed.ago | money }}</span></td>
				<td class="currency"><span>{{tbDed.set | money }}</span></td>
				<td class="currency"><span>{{tbDed.out | money }}</span></td>
				<td class="currency"><span>{{tbDed.nov | money }}</span></td>
				<td class="currency"><span>{{tbDed.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbSomaValorLiq of listaValorLiqRecolher">
				<th class="th-subtotal" colspan="2">VALOR LÍQUIDO A RECOLHER</th>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.dez | money }}</span></td>
			</tr>
		</table>
	</div>

	<div class="scrolled-td-table-wrapper">
		<table>

			<tr>
				<th class="th-cab" colspan=2>DESCRIÇÃO</th>
				<td class="td-cab text-right"><span>JAN</span></td>
				<td class="td-cab text-right"><span>FEV</span></td>
				<td class="td-cab text-right"><span>MAR</span></td>
				<td class="td-cab text-right"><span>ABR</span></td>
				<td class="td-cab text-right"><span>MAI</span></td>
				<td class="td-cab text-right"><span>JUN</span></td>
				<td class="td-cab text-right"><span>JUL</span></td>
				<td class="td-cab text-right"><span>AGO</span></td>
				<td class="td-cab text-right"><span>SET</span></td>
				<td class="td-cab text-right"><span>OUT</span></td>
				<td class="td-cab text-right"><span>NOV</span></td>
				<td class="td-cab text-right"><span>DEZ</span></td>
			</tr>
			<tr *ngFor="let somaResultado of listaListSomaResultado">
				<th colspan="2"><span matTooltip="O valor é a soma dos saldos das contas contábeis de natureza 4">Lucro (Prejuízo) Acumulado</span></th>
				<td class="currency"><span>{{ somaResultado.jan | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.fev | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.mar | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.abr | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.mai | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.jun | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.jul | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.ago | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.set | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.out | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.nov | money }}</span></td>
				<td class="currency"><span>{{ somaResultado.dez | money }}</span></td>
			</tr>
			<tr *ngIf="listSomaCsll?.length === 0">
				<th align="left" colspan="2"><span matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ e CSLL</span></th>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
				<td class="currency"><span>{{ 0 | money }}</span></td>
			</tr>
			<tr *ngFor="let somaCsll of listSomaCsll">
				<th align="left" colspan="2"><span matTooltip="O valor é a soma dos saldos contábeis das contas relacionadas com as contas referenciais da RFB: 3.02.01.01.01.01 e 3.02.01.01.01.02">IRPJ e CSLL</span></th>
				<td class="currency"><span>{{ somaCsll.jan | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.fev | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.mar | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.abr | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.mai | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.jun | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.jul | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.ago | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.set | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.out | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.nov | money }}</span></td>
				<td class="currency"><span>{{ somaCsll.dez | money }}</span></td>
			</tr>
			<tr *ngFor="let somaResultadoAntes of listaSomaResultadoAntesIrpj">
				<th align="left" colspan=2><span matTooltip="Valor do Lucro(Prejuízo)Acumulado + IRPJ e CSLL">Lucro (Prejuízo) Acumulado antes do IRPJ e CSLL</span></th>
				<td class="currency"><span>{{ somaResultadoAntes.jan | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.fev | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.mar | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.abr | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.mai | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.jun | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.jul | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.ago | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.set | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.out | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.nov | money }}</span></td>
				<td class="currency"><span>{{ somaResultadoAntes.dez | money }}</span></td>
			</tr>

		<tr class="dtc" *ngIf="listaListSoma?.length === 0">
				<th class="th-subtotal" colspan="2">ADIÇÕES</th>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
			</tr>

		<tr class="dtc" *ngFor="let tbCsll of listaListSoma">
				<th class="th-subtotal" colspan="2">ADIÇÕES</th>
				<td class="td-subtotal"><span>{{ tbCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbCsll of listaList">
				<th colspan="2"><span matTooltip="{{tbCsll.descricao}}">{{tbCsll.cod}} - {{tbCsll.desc_mini}}</span> </th>
				<td class="currency"><span>{{ tbCsll.jan | money }}</span></td>
				<td class="currency"><span>{{ tbCsll.fev | money }}</span></td>
				<td class="currency"><span>{{ tbCsll.mar | money }}</span></td>
				<td class="currency"><span>{{tbCsll.abr | money }}</span></td>
				<td class="currency"><span>{{tbCsll.mai | money }}</span></td>
				<td class="currency"><span>{{tbCsll.jun | money }}</span></td>
				<td class="currency"><span>{{tbCsll.jul | money }}</span></td>
				<td class="currency"><span>{{tbCsll.ago | money }}</span></td>
				<td class="currency"><span>{{tbCsll.set | money }}</span></td>
				<td class="currency"><span>{{tbCsll.out | money }}</span></td>
				<td class="currency"><span>{{tbCsll.nov | money }}</span></td>
				<td class="currency"><span>{{tbCsll.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngIf="listaListSomaExc?.length === 0">
				<th class="th-subtotal" colspan="2">EXCLUSÕES</th>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
				<td class="td-subtotal"><span>{{ 0 | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbCsll of listaListSomaExc">
				<th class="th-subtotal" colspan="2">EXCLUSÕES</th>
				<td class="td-subtotal"><span>{{ tbCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbCsll.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbExc of listaListExc">
				<th colspan="2"><span matTooltip="{{tbExc.descricao}}">{{tbExc.cod}} - {{tbExc.desc_mini}}</span> </th>
				<td class="currency"><span>{{ tbExc.jan | money }}</span></td>
				<td class="currency"><span>{{ tbExc.fev | money }}</span></td>
				<td class="currency"><span>{{ tbExc.mar | money }}</span></td>
				<td class="currency"><span>{{tbExc.abr | money }}</span></td>
				<td class="currency"><span>{{tbExc.mai | money }}</span></td>
				<td class="currency"><span>{{tbExc.jun | money }}</span></td>
				<td class="currency"><span>{{tbExc.jul | money }}</span></td>
				<td class="currency"><span>{{tbExc.ago | money }}</span></td>
				<td class="currency"><span>{{tbExc.set | money }}</span></td>
				<td class="currency"><span>{{tbExc.out | money }}</span></td>
				<td class="currency"><span>{{tbExc.nov | money }}</span></td>
				<td class="currency"><span>{{tbExc.dez | money }}</span></td>
			</tr>
			<tr *ngFor="let somaLucroCompensacao of listaSomaLucroAntesCompensacao">
				<th class="th-subtotal" colspan="2"><span matTooltip="Lucro(Prejuízo) Acumulado + ADIÇÕES - EXCLUSÕES">LUCRO ANTES DA COMPENSAÇÃO PREJ. FISCAL</span></th>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ somaLucroCompensacao.dez | money }}</span></td>
			</tr>
			<tr>
				<th colspan="2">PREJUÍZO FISCAL A COMPENSAR</th>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
				<td class="currency"><span>{{ valorPrejuizo | money }}</span></td>
			</tr>
			<tr *ngFor="let prejFiscalCompensado of listaPrejFiscalCompensado">
				<th class="left" colspan="2"><span matTooltip="30% do Lucro Antes da Compensação">PREJUÍZO FISCAL COMPENSADO</span></th>
				<td class="currency"><span>{{ prejFiscalCompensado.jan | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.fev | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.mar | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.abr | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.mai | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.jun | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.jul | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.ago | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.set | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.out | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.nov | money }}</span></td>
				<td class="currency"><span>{{ prejFiscalCompensado.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let compensacaoPrejPeriodoAnterior of listaCompensacaoPrejPeriodoAnterior">
				<th class="left" colspan="2">COMPENSAÇÃO PREJUÍZOS PERÍODOS ANTERIORES</th>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jan | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.fev | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mar | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.abr | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.mai | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jun | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.jul | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.ago | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.set | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.out | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.nov | money }}</span></td>
				<td class="currency"><span>{{ compensacaoPrejPeriodoAnterior.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let saldoPrejFiscalCompensar of listaSaldoPrejFiscalCompensar">
				<th class="left" colspan="2"><span matTooltip="Prejuizo Fiscal a Compensar - Prejuizo Fiscal Compensado">SALDO DE PREJUÍZO FISCAL A COMPENSAR</span></th>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.jan | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.fev | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.mar | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.abr | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.mai | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.jun | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.jul | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.ago | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.set | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.out | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.nov | money }}</span></td>
				<td class="currency"><span>{{ saldoPrejFiscalCompensar.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let lucroAjustado of listaLucroAjustado">
				<th class="th-subtotal" colspan="2"><span matTooltip="Lucro antes da Compensação - Compensação Prejuízo de Períodos Anteriores">LUCRO AJUSTADO</span></th>
				<td class="td-subtotal"><span>{{ lucroAjustado.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ lucroAjustado.dez | money }}</span></td>
			</tr>
			<tr *ngFor="let impCsll of listaImpCsll">
				<th class="left" colspan="2"><span matTooltip="9% do LUCRO AJUSTADO">Contribuição Social (9%)</span></th>
				<td class="currency"><span>{{ impCsll.jan | money }}</span></td>
				<td class="currency"><span>{{ impCsll.fev | money }}</span></td>
				<td class="currency"><span>{{ impCsll.mar | money }}</span></td>
				<td class="currency"><span>{{ impCsll.abr | money }}</span></td>
				<td class="currency"><span>{{ impCsll.mai | money }}</span></td>
				<td class="currency"><span>{{ impCsll.jun | money }}</span></td>
				<td class="currency"><span>{{ impCsll.jul | money }}</span></td>
				<td class="currency"><span>{{ impCsll.ago | money }}</span></td>
				<td class="currency"><span>{{ impCsll.set | money }}</span></td>
				<td class="currency"><span>{{ impCsll.out | money }}</span></td>
				<td class="currency"><span>{{ impCsll.nov | money }}</span></td>
				<td class="currency"><span>{{ impCsll.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let totalCsll of listaTotalCsll">
				<th class="th-subtotal" colspan="2"><span>TOTAL CSLL A RECOLHER</span></th>
				<td class="td-subtotal"><span>{{ totalCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.dez | money }}</span></td>
			</tr>

			<tr *ngFor="let totalCsll of listaTotalAntecCsll">
				<th class="th-subtotal" colspan="2"><span matTooltip="I">ANTECIPAÇÕES DE CSLL</span></th>
				<td class="td-subtotal"><span>{{ totalCsll.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ totalCsll.dez | money }}</span></td>
			</tr>


			<tr class="dtc" *ngFor="let tbSomaDeducoes of listaListSomaDeducoes">
				<th class="th-subtotal" colspan="2">DEDUÇÕES</th>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaDeducoes.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbDed of listaListDed">

				<th colspan="2"><span matTooltip="{{tbDed.descricao}}">{{tbDed.cod}} - {{tbDed.desc_mini}}</span> </th>
				<td class="currency"><span>{{ tbDed.jan | money }}</span></td>
				<td class="currency"><span>{{ tbDed.fev | money }}</span></td>
				<td class="currency"><span>{{ tbDed.mar | money }}</span></td>
				<td class="currency"><span>{{tbDed.abr | money }}</span></td>
				<td class="currency"><span>{{tbDed.mai | money }}</span></td>
				<td class="currency"><span>{{tbDed.jun | money }}</span></td>
				<td class="currency"><span>{{tbDed.jul | money }}</span></td>
				<td class="currency"><span>{{tbDed.ago | money }}</span></td>
				<td class="currency"><span>{{tbDed.set | money }}</span></td>
				<td class="currency"><span>{{tbDed.out | money }}</span></td>
				<td class="currency"><span>{{tbDed.nov | money }}</span></td>
				<td class="currency"><span>{{tbDed.dez | money }}</span></td>
			</tr>
			<tr class="dtc" *ngFor="let tbSomaValorLiq of listaValorLiqRecolher">
				<th class="th-subtotal" colspan="2">VALOR LÍQUIDO A RECOLHER</th>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.jan | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.fev | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.mar | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.abr | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.mai | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.jun | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.jul | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.ago | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.set | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.out | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.nov | money }}</span></td>
				<td class="td-subtotal"><span>{{ tbSomaValorLiq.dez | money }}</span></td>
			</tr>
		</table>
	</div>
</div>
</mat-card>