<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content p-3" style="margin-top: -25px">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarSig"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta" class="size">

            <p-table class="table-system" #dt [value]="registrosSignatarios" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="onRowSelect($event)"
              [(selection)]="selectSig" (onRowUnselect)="onRowUnselect($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'identNom'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Nome Signatário <p-sortIcon [field]="'identNom'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'identNom', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'identCpf'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CPF/CNPJ <p-sortIcon [field]="'identCpf'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'identCpf', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Email <p-sortIcon [field]="'email'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'email', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCrc'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Núm do CRC <p-sortIcon [field]="'indCrc'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'indCrc', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ufCrc'">
                      <span class="text-overflow-dynamic-ellipsis">
                        UF CRC <p-sortIcon [field]="'ufCrc'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'ufCrc', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtCrc'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Data Val CRC <p-sortIcon [field]="'dtCrc'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'dtCrc', 'contains')">
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indRespLegal'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Ident Resp Legal <p-sortIcon [field]="'indRespLegal'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoes" defaultLabel="Selecione"
                    (onChange)="dt.filter($event.value, 'indRespLegal', 'contains')"></p-multiSelect>
                  </th>

                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ecfIdentQualif.descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Ecf Ident Qualif <p-sortIcon [field]="'ecfIdentQualif.descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'ecfIdentQualif.descricao', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;">
                    <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData">
                    </p-tableRadioButton>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.identNom}}">
                        {{rowData.identNom}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.identCpf}}">
                        {{rowData.identCpf}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}">
                        {{rowData.email}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.indCrc}}">
                        {{rowData.indCrc}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ufCrc}}">
                        {{rowData.ufCrc}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtCrc}}">
                        {{rowData.dtCrc | timezone | date: 'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>

                  <td class="text-center">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.indRespLegal" [binary]="true" inputId="ativo"
                      [disabled]="true"></p-checkbox>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ecfIdentQualif}}">
                        {{rowData.ecfIdentQualif != null? rowData.ecfIdentQualif.descricao: ''}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div class="form-size">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Nome do Signatário<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="nomeSignatario">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>CPF/CNPJ<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="cpfCnpj">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Email<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="email">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Fone<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="fone">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Número do CRC</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="numeroDoCrc">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>UF CRC</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="ufCrc">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Número Sequencial Crc</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="numeroSequencialCrc">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Data Validade CRC</label><br>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                yearRange="2010:2030" [(ngModel)]="dataValCrc">
                </p-calendar>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="identRespLegal">Ident Resp Legal</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Ecf Ident Qualif<span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" placeholder="Selecione" [options]="ecfIdentQualifOp" [(ngModel)]="selectedEcfIdentQualif">
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>