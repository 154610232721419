<script src="ng-pattern-restrict.js"></script>
<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
    </div>
  </div>

  <!-- LISTA -->
  <!-- ---------------------------------------------------------------------------------------- -->

  <mat-card-content>
    <div class="box-content " *ngIf="showLista">
      <div class="row">
        <div class="col-sm-12">

          <!-- inicio tabela em p-table -->
          <p-table class="table-system" #dt1 [value]="tiposLista" [rows]="rows" [paginator]="true"
            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="false"
            [style]="{ width: '100%' }">

            <ng-template pTemplate="header">
              <tr>
                <th class="text-left" style="width: 50%">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th class="text-center" style="width: 10%;">
                  <span [pSortableColumn]="'chave'">
                    <span>CHAVE <p-sortIcon [field]="'chave'"></p-sortIcon></span>
                  </span>
                  <p-multiSelect class="type-p-mult" [options]="opcoesChave" defaultLabel="Selecione" [filter]="false"
                    (onChange)="dt1.filter($event.value, 'chave', 'in')"></p-multiSelect>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span [pSortableColumn]="'email'">
                    <span>EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
                  </span>
                  <p-multiSelect class="type-p-mult" [options]="opcoesEmail" defaultLabel="Selecione" [filter]="false"
                    (onChange)="dt1.filter($event.value, 'email', 'in')"></p-multiSelect>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span [pSortableColumn]="'usuario'">
                    <span>USUÁRIO <p-sortIcon [field]="'usuario'"></p-sortIcon></span>
                  </span>
                  <p-multiSelect class="type-p-mult" [options]="opcoesUsuario" defaultLabel="Selecione" [filter]="false"
                    (onChange)="dt1.filter($event.value, 'usuario', 'in')"></p-multiSelect>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span [pSortableColumn]="'senha'">
                    <span>SENHA <p-sortIcon [field]="'senha'"></p-sortIcon></span>
                  </span>
                  <p-multiSelect class="type-p-mult" [options]="opcoesSenha" defaultLabel="Selecione" [filter]="false"
                    (onChange)="dt1.filter($event.value, 'senha', 'in')"></p-multiSelect>
                </th>
                <th class="text-center" style="width: 10%;">
                  <span [pSortableColumn]="'ativo'">
                    <span>ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon></span>
                  </span>
                  <p-multiSelect class="type-p-mult" [options]="opcoesAtivos" defaultLabel="Selecione" [filter]="false"
                    (onChange)="dt1.filter($event.value, 'ativo', 'in')"></p-multiSelect>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-left" style="width: 50%;">
                  <span>
                    <span matTooltip="{{ rowData.descricao }}" (click)="loadComponent('edit', rowData)">{{
                      rowData.descricao }}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 10%;">
                  <p-checkbox class="mr-1" [(ngModel)]="rowData.chave" [binary]="true"
                      inputId="chave" [disabled]="true"></p-checkbox>
                </td>
                <td class="text-center" style="width: 10%;">
                  <p-checkbox class="mr-1" [(ngModel)]="rowData.email" [binary]="true"
                      inputId="email" [disabled]="true"></p-checkbox>
                </td>
                <td class="text-center" style="width: 10%;">
                  <p-checkbox class="mr-1" [(ngModel)]="rowData.usuario" [binary]="true"
                      inputId="usuario" [disabled]="true"></p-checkbox>
                </td>
                <td class="text-center" style="width: 10%;">
                  <p-checkbox class="mr-1" [(ngModel)]="rowData.senha" [binary]="true"
                      inputId="senha" [disabled]="true"></p-checkbox>
                </td>
                <td class="text-center" style="width: 10%;">
                  <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo" [binary]="true"
                  inputId="ativo" [disabled]="true"></p-checkbox>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="6" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>

          </p-table>
          <!-- final tabela em p-table -->





        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- EDIT | NEW -->
  <!-- ---------------------------------------------------------------------------------------- -->



  <div class="row">
    <div class="col-sm-12">
      <div class="box-content" *ngIf="showEdit || showNew">

        <div class="row">
          <div class="form-group col-sm-12">
            <label>PLATAFORMA <span class="obrigatorio">*</span></label>
            <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione um tipo plataforma"
              [options]="opcaoPlataformaTipo" [(ngModel)]="plataformaPagamentos.plataformaTipoId"></p-dropdown>
          </div>
        </div>


        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>DESCRIÇÃO<span class="obrigatorio">*</span></label>
            <input class="form-control" type="text" [(ngModel)]="plataformaPagamentos.descricao" required="true"
              maxlength="150" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>URL BASE </label>
            <input class="form-control" type="text" [(ngModel)]="plataformaPagamentos.urlBase" />
          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-4">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentos.ativo"> ATIVO </mat-slide-toggle>
          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title mt-4"><i class="fas fa-sliders-h"> </i> CONFIGURAÇÕES GERAIS - VALIDAÇÕES DE CAMPOS
            </h3>
          </div>
        </div>

        <div class="form-row">
          <div class="col-sm-3">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentos.chave"> CHAVE </mat-slide-toggle>
          </div>

          <div class="col-sm-3">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentos.email"> EMAIL </mat-slide-toggle>
          </div>

          <div class="col-sm-3">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentos.usuario"> USUÁRIO </mat-slide-toggle>
          </div>

          <div class="col-sm-3">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentos.senha"> SENHA </mat-slide-toggle>
          </div>

        </div>

        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="sub-title mt-4"><i class="fas fa-sliders-h"> </i> CONFIGURAÇÕES GERAIS - SERVIÇOS DA PLATAFORMA
            </h3>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-5">
            <label>SUFIXO <span class="obrigatorio">*</span></label>
            <input class="form-control text-left" [(ngModel)]="plataformaPagamentosServicos.sufixo" type="text"
              maxlength="400" />
          </div>

          <div class="form-group col-sm-5">
            <label>DESCRIÇÃO <span class="obrigatorio">*</span></label>
            <input class="form-control text-left" [(ngModel)]="plataformaPagamentosServicos.descricao" type="text"
              maxlength="400" />
          </div>

          <div class="form-group col-sm-2">
            <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
            <button type="button" *ngIf="!editServico" (click)="addServico()" class="btn btn-sm btn-info"><i
                class="fas fa-plus"></i> Adicionar</button>
            <button type="button" *ngIf="editServico" (click)="atualizaServico()" class="btn btn-sm btn-info"><i
                class="fas fa-pencil-alt"></i>
              Editar</button>
          </div>


        </div>



        <div class="form-row">


          <div class="form-group col-sm-3">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentosServicos.ativo"> ATIVO </mat-slide-toggle>
          </div>

          <div class="form-group col-sm-3">
            <mat-slide-toggle class="mr-2" [(ngModel)]="plataformaPagamentosServicos.temPaginacao"> PAGINAÇÃO
            </mat-slide-toggle>
          </div>





        </div>


        <!-- Inicio tabela Servicos -->
        <div class="form-row">
          <div class="form-group col-sm-12 mt-2">

            <p-table class="table-system" selectionMode="single"
              [value]="plataformaPagamentos.plataformaPagamentosServicos" [paginator]="true" [rows]="5"
              [totalRecords]="plataformaPagamentos.plataformaPagamentosServicos.length">

              <ng-template pTemplate="header">
                <tr>
                  <th style="width:35%" class="text-left">SUFIXO </th>
                  <th style="width:35%">DESCRIÇÃO</th>
                  <th style="width:10%" class="text-center">PAGINAÇÃO</th>
                  <th style="width:10%" class="text-center">ATIVO</th>
                  <th style="width:10%" class="text-center">AÇÃO</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height:25px">
                  <td>
                    <span class="overflow-ellipse text-left" matTooltip="{{ rowData.sufixo  }}">
                      <span style="user-select: all; cursor: text"> {{ rowData.sufixo }}</span>
                    </span>
                  </td>
                  <td>
                    <span class="overflow-ellipse" matTooltip="{{ rowData.descricao }}"> <span
                        style="user-select: all; cursor: text"> {{ rowData.descricao }}</span> </span>
                  </td>

                  <td class="text-center">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.temPaginacao" [binary]="true"
                      inputId="temPaginacao" [disabled]="true"></p-checkbox>
                  </td>

                  <td class="text-center">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo" [binary]="true"
                    inputId="ativo" [disabled]="true"></p-checkbox>
                  </td>

                  <td class="text-center ">
                    <span class="edit" matTooltip="Editar Item" (click)="selectServico(rowData)"><i
                        class="far fa-edit"></i></span>
                    <span class="delete" matTooltip="Excluir Item" (click)="removerServico(rowData)"><i
                        class="far fa-trash-alt"></i></span>
                  </td>
                </tr>
              </ng-template>


              <ng-template pTemplate="emptymessage" let-columns>

                <tr style="height:25px">
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Sem Serviço Adicionado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </div>
        <!-- Final da tabela de Servicos -->


        <div class="form-row">
          <div class="form-group col-sm-12">
            <button class="btn btn-secondary" (click)="loadComponent('lista', null)"><i class="fas fa-undo"></i>
              Cancelar
            </button>
            <button *ngIf="showEdit" class="btn btn-danger" (click)="salvar(false)"><i class="fas fa-times"></i>
              Excluir
            </button>
            <button class="btn btn-primary" (click)="salvar(true)"><i class="fas fa-check"></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>