<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-12 d-flex align-items-center justify-content-between" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Saldo em conta
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Posição consolidada das suas contas bancárias</small>
          <br>
        </div>
      </h4>
      <div class="d-flex align-itens-center justify-content-end">
        <p-multiSelect class="type-p-mult mr-3 mult-header" [filter]="true" [placeholder]="placeholderCompanies" [options]="availableCompanies" appendTo="body" [(ngModel)]="selectedCompanies" [disabled]="loadingOptCompanies" [dropdownIcon]="loadingOptIconCompanies ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" (onChange)="checkCompaniesList()"></p-multiSelect>
      </div>
    </div>
  </div>
  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngIf="selectedCompanies.length > 0">
    <app-dash-big-number type="line" [lineData]="checkingAccountData" [isLoading]="isLoading"></app-dash-big-number>
  </div>
  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngIf="selectedCompanies.length > 0">
    <app-dash-big-number type="line" [lineData]="investmentsData" [isLoading]="isLoading"></app-dash-big-number>
  </div>
  <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4" *ngIf="selectedCompanies.length > 0">
    <!-- <app-dash-graphics container="bar" title="Contas" type="bar" [height]="300" [isMoney]="true" [barData]="barData" [isLegend]="false" [baseList]="barLabel" [isLegend]="true" [isLoading]="isLoading"></app-dash-graphics> -->
    <app-dash-graphics container="column_account" title="Contas" type="column" [height]="300" [isMoney]="true" [columnData]="columnAccountData" [isLegend]="false" [baseList]="columnAccountLabel" [isLegend]="true" [isLoading]="isLoading"></app-dash-graphics>
  </div>
  <div class="col-sm-12 col-lg-12 col-xl-12 mt-top" *ngIf="selectedCompanies.length > 0">
    <app-dash-graphics container="column_chart" title="Evolução mensal" type="column" [height]="400" [columnData]="monthlyEvolutionData" [isMoney]="true" [baseList]="monthlyEvolutionBase" [isLegend]="true" [isLoading]="isLoading" [isStack]="true"></app-dash-graphics>
  </div>
</div>

<div class="row mt-top" *ngIf="selectedCompanies.length > 0">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-6" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Movimentações
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Títulos a vencer ou vencidos</small>
          <br>
        </div>
      </h4>
    </div>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
    <app-dash-table [data]="releasesExpire" [isLoading]="isLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="true" [isScrollable]="false"></app-dash-table>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
    <app-dash-table [data]="releasesWillExpire" [isLoading]="isLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="true" [isScrollable]="false"></app-dash-table>
  </div>
</div>

<div class="row mt-top" *ngIf="selectedCompanies.length > 0">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-12 d-flex align-itens-center justify-content-between" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Registro de movimentações
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">São exibidos os 10 maiores valores</small>
          <br>
        </div>
      </h4>
      <app-dash-changer class="mt-3 mb-3" [options]="periodOptions" format="chip" (tabSelected)="changePeriod($event)"></app-dash-changer>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <app-dash-table [data]="nextPayments" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="true" [isScrollable]="false"></app-dash-table>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-top">
    <app-dash-table [data]="nextReceipts" [isLoading]="isLoading" [isTableLoading]="isTableLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="true" [isScrollable]="false"></app-dash-table>
  </div>
</div>

<div class="row mt-top" *ngIf="selectedCompanies.length > 0">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center" style="padding-bottom: 1rem;">
    <div class="col-6" style="padding-left: 0 !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Aguardando aprovação
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Títulos vencidos ou a vencer aguardando aprovação</small>
          <br>
        </div>
      </h4>
    </div>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
    <app-dash-table [data]="approvReleasesExpire" [isLoading]="isLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="true" [isScrollable]="false"></app-dash-table>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
    <app-dash-table [data]="approvReleasesWillExpire" [isLoading]="isLoading" [isLazy]="false" [hasFilter]="false" [hasPaginator]="false" [hasFooter]="true" [hasExport]="true" [isScrollable]="false"></app-dash-table>
  </div>
</div>

<div class="empty" *ngIf="selectedCompanies.length <= 0">
  <div class="empty-content">
      <span class="material-icons">warning</span>
      <h2>Por favor, selecione pelo menos uma empresa</h2>
  </div>
</div>