    <!--<td>
            Grupo:
        </td>
        <td>
            <input id="input" type="text" size="100" pInputText>
        </td>
<h3>Selecionar Empresas</h3>
<p-pickList [source]="empresas" [target]="empresasSelecionadas" dragdrop="true" [sourceStyle]="{'width':'500px', 'font-size':'14px'}"
                            [targetStyle]="{'width':'500px', 'font-size':'14px'}">
                            <ng-template let-emp pTemplate="item">

                  {{emp.cnpj}} - {{emp.nomeFantasia}}

                            </ng-template>
                        </p-pickList>
<h3>Selecionar Regras</h3>
<p-pickList [source]="regras" [target]="regrasSelecionadas" dragdrop="true" [sourceStyle]="{'width':'500px', 'font-size':'14px'}"
                            [targetStyle]="{'width':'500px', 'font-size':'14px'}">
                            <ng-template let-reg pTemplate="item">
                             <span matTooltip="{{reg.descricao}}">
                 {{reg.cod}} - {{reg.nome}}
                             </span>
                            </ng-template>
                        </p-pickList>-->

<app-data-table class="grupo-relac"
  mainTitle="Manutenção de Regras e Relatórios"
  singleTitle="Regra"
  routerPath="/regra"
  entityPath="/regra"
  [entity]="regra"
  [entityFilter]="entityFilter"
  [columns]="this['columns']"
  [controls]="this['controls']"
  bulkeditable="nome,descricao,ativa,homologada,grauRisco,ordem">
</app-data-table>

