<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>



  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="far fa-lightbulb" style="font-size: 15px;"></i>FORMA DE CONHECIMENTO</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateFormaConhecimento>
            <div class="row">
              <div class="col-sm-12">
                <p-table class="table-system" #dt1 [columns]="colslistaFormaConhecimento"
                  [value]="listaFormaConhecimento" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                  [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}" immutable="false">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo_formaconhecimento'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon
                              [field]="'codigo_formaconhecimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'codigo_formaconhecimento', 'contains')">
                      </th>
                      <th class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_formaconhecimento'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon
                              [field]="'descricao_formaconhecimento'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'descricao_formaconhecimento', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo_formaconhecimento'">
                          <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon
                              [field]="'ativo_formaconhecimento'"></p-sortIcon></span>
                        </span>
                        <p-dropdown #cmp class="type-p-mult" [options]="opcoesAtivo"
                        (onChange)="dt1.filter($event.value, 'ativo_formaconhecimento', 'contains')"></p-dropdown>
                        <!-- <input class="form-control form-control-sm" type="text"
                          (input)="dt1.filter($event.target.value, 'ativo_formaconhecimento', 'contains')"> -->
                      </th>
                      <th class="text-center" style="width: 95px; vertical-align: sub;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                        </span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">
                            {{ rowData.codigo_formaconhecimento}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">{{rowData.descricao_formaconhecimento}}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span>
                          <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo_formaconhecimento" [binary]="true"
                          inputId="ativo_formaconhecimento" [disabled]="true"></p-checkbox>
                        </span>
                      </td>
                      <td class="text-center" style="width: 95px;">
                        <button type="button" style="padding: 0 !important;"
                          (click)="openEditFormaConhecimento(rowData)" class="btn btn-xs btn-link btn-full"
                          matTooltip="Editar" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px; color: #cccccc;">edit</span>
                        </button>
                        <button type="button" style="padding: 0 !important;" (click)="excluirFormaConhecimento(rowData)"
                          class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px; color: #cccccc;">delete</span>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-neuter" style="font-size: 15px;"></i>GÊNERO</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMainGenero"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateGenero>
            <div class="row">
              <div class="col-sm-12">
                <p-table class="table-system" #dt2 [columns]="colslistaGenero" [value]="listaGenero" [rows]="rows"
                  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  [style]="{'width':'100%'}" immutable="false">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo_genero'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo_genero'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'codigo_genero', 'contains')">
                      </th>
                      <th class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_genero'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon
                              [field]="'descricao_genero'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'descricao_genero', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo_genero'">
                          <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo_genero'">
                            </p-sortIcon></span>
                        </span>
                        <p-dropdown #cmp class="type-p-mult" [options]="opcoesAtivo"
                      (onChange)="dt2.filter($event.value, 'ativo_genero', 'contains')"></p-dropdown>
                        <!-- <input class="form-control form-control-sm" type="text"
                          (input)="dt2.filter($event.target.value, 'ativo_genero', 'contains')"> -->
                      </th>
                      <th class="text-center" style="width: 95px; vertical-align: sub;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                        </span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">{{rowData.codigo_genero }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">{{rowData.descricao_genero }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span>
                          <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo_genero" [binary]="true"
                          inputId="ativo_genero" [disabled]="true"></p-checkbox>
                        </span>
                      </td>
                      <td class="text-center" style="width: 95px;">
                        <button type="button" style="padding: 0 !important;" (click)="openEditGenero(rowData)"
                          class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px; color: #cccccc;">edit</span>
                        </button>
                        <button type="button" style="padding: 0 !important;" (click)="excluirGenero(rowData)"
                          class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px; color: #cccccc;">delete</span>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-user-tie" style="font-size: 15px;"></i>PROFISSÃO</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMainProfissao"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" [@changeDivSize]=currentStateProfissao>
            <div class="row">
              <div class="col-sm-12">
                <p-table class="table-system" #dt3 [columns]="colslistaProfissao" [value]="listaProfissao" [rows]="rows"
                  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                  [style]="{'width':'100%'}" immutable="false">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo_profissao'">
                          <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo_profissao'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt3.filter($event.target.value, 'codigo_profissao', 'contains')">
                      </th>
                      <th class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao_profissao'">
                          <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon
                              [field]="'descricao_profissao'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt3.filter($event.target.value, 'descricao_profissao', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo_profissao'">
                          <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo_profissao'">
                            </p-sortIcon></span>
                        </span>
                        <p-dropdown #cmp class="type-p-mult" [options]="opcoesAtivo"
                        (onChange)="dt3.filter($event.value, 'ativo_profissao', 'contains')"></p-dropdown>
                        <!-- <input class="form-control form-control-sm" type="text"
                          (input)="dt3.filter($event.target.value, 'ativo_profissao', 'contains')"> -->
                      </th>
                      <th class="text-center" style="width: 95px; vertical-align: sub;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
                        </span>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-left" style="width: 20%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">{{rowData.codigo_profissao }}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 60%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis">{{rowData.descricao_profissao }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span>
                          <p-checkbox class="mr-1" [(ngModel)]="rowData.ativo_profissao" [binary]="true"
                          inputId="ativo_profissao" [disabled]="true"></p-checkbox>
                        </span>
                      </td>
                      <td class="text-center" style="width: 95px;">
                        <button type="button" style="padding: 0 !important;" (click)="openEditProfissao(rowData)"
                          class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px; color: #cccccc;">edit</span>
                        </button>
                        <button type="button" style="padding: 0 !important;" (click)="excluirProfissao(rowData)"
                          class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                          <span class="material-icons" style="font-size: 22px; color: #cccccc;">delete</span>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="4" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <p-dialog header="Nova Forma de Conhecimento" [(visible)]="modalFormaConhecimentoNew" [style]="{width: '500px'}"
    [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Código</label>
        <input class="form-control text-left" placeholder="Insira o Código" [(ngModel)]="codigoFormaConhecimentoNew">
      </div>
      <div class="form-group col-sm-12">
        <label>Descrição</label>
        <input class="form-control text-left" placeholder="Insira a Descrição"
          [(ngModel)]="descricaoFormadeConhecimentoNew">
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalFormaConhecimentoNew=false;'><i
              class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="addFormaConhecimento();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Novo Gênero" [(visible)]="modalGeneroNew" [style]="{width: '500px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Código</label>
        <input class="form-control text-left" placeholder="Insira o Código" [(ngModel)]="codigoGeneroNew">
      </div>
      <div class="form-group col-sm-12">
        <label>Descrição</label>
        <input class="form-control text-left" placeholder="Insira o Código" [(ngModel)]="descricaoGeneroNew">
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalGeneroNew=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="addGenero();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Nova Profissão" [(visible)]="modalProfissaoNew" [style]="{width: '500px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Código</label>
        <input class="form-control text-left" placeholder="Insira o Código" [(ngModel)]="codigoProfissaoNew">
      </div>
      <div class="form-group col-sm-12">
        <label>Descrição</label>
        <input class="form-control text-left" placeholder="Insira o Código" [(ngModel)]="descricaoProfissaoNew">
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalProfissaoNew=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="addProfissao();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Editar Forma de Conhecimento" [(visible)]="modalEditFormaConhecimento" [style]="{width: '500px'}"
    [modal]="true" styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Código</label>
        <input class="form-control text-left" placeholder="Código" [(ngModel)]="codigoEditFormaConhecimento">
      </div>
      <div class="form-group col-sm-9">
        <label>Nome</label>
        <input class="form-control text-left" placeholder="Nome do Funcionário"
          [(ngModel)]="descricaoEditFormaConhecimento">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label class="d-block" style="visibility: hidden; margin-top: -17px;">DO NOT REMOVE</label>
        <mat-slide-toggle [(ngModel)]="FormaConhecimentoAtivo" ></mat-slide-toggle>
        <label style="padding: 0 5px;">Ativo</label>
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalEditFormaConhecimento=false;'><i
              class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="editFormaConhecimento();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Editar Gênero" [(visible)]="modalEditGenero" [style]="{width: '500px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Código</label>
        <input class="form-control text-left" placeholder="Código" [(ngModel)]="codigoEditGenero">
      </div>
      <div class="form-group col-sm-9">
        <label>Nome</label>
        <input class="form-control text-left" placeholder="Nome do Funcionário" [(ngModel)]="descricaoEditGenero">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <mat-slide-toggle [(ngModel)]="GeneroAtivo">Ativo</mat-slide-toggle>
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalEditGenero=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="editGenero();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  <p-dialog header="Editar Profissão" [(visible)]="modalEditProfissao" [style]="{width: '500px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>Código</label>
        <input class="form-control text-left" placeholder="Código" [(ngModel)]="codigoEditProfissao">
      </div>
      <div class="form-group col-sm-9">
        <label>Nome</label>
        <input class="form-control text-left" placeholder="Nome do Funcionário" [(ngModel)]="descricaoEditProfissao">
      </div>
      <div class="form-group col-sm-12">
        <mat-slide-toggle [(ngModel)]="ProfissaoAtivo">Ativo</mat-slide-toggle>
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalEditProfissao=false;'><i
              class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="editProfissao();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

</mat-card>