<script src="ng-pattern-restrict.js"></script>
<mat-card class="card-linnks margin-linnks" *ngIf="!wait">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
    <div class="ui-g-12">

    </div>
  </div>

  <!-- <button class="btn btn-sm btn-info mb-2 pull-right" style="height: 28px;" (click)="addNovoUsuario()" *ngIf="!novoUsuario"><i class="fas fa-user"></i> ADICIONAR USUÁRIO</button> -->
  <div class="clearfix">
  </div>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12 pb-3">
        <div class="shadow-box">

          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card" *ngIf="novoUsuario"><i class="fas fa-user"></i> Adicionar um usuário</h3>
              <ul class="list-inline filter-menu filter-card-header mt-0" *ngIf="!novoUsuario">
                <li class="list-inline-item" [ngClass]="{active: statusUsuario}"><a
                    (click)="showTelaCadastroUser('TELA_1')">USUÁRIOS ({{usuarios.length}})</a></li>
                <li class="list-inline-item" [ngClass]="{active: statusUsuarioGrupo}"><a
                    (click)="showTelaCadastroUser('TELA_2')">USUÁRIOS POR GRUPOS</a></li>
              </ul>







              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
                <button type="button" class="btn btn-xs btn-link" (click)="this.exportExcelAll(this.usuarios, 'usuarios', this.exportMov)" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardCadastroUser()"
                  (click)="changeStateCadastroMenu()">
                  <span *ngIf="isCardCadastroUser" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardCadastroUser" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content" *ngIf="!novoUsuario" [@changeDivSize]=currentStateCadastroMenu>

            <!--Table Cadastro Menu-->

            <div [ngStyle]="statusUsuario ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusUsuario'}">

              <p-table class="table-system" #dt [value]="usuarios" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}">

                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                        <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'id', 'contains')">
                    </th>
                    <th class="text-left" style="width: 35%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeCompleto'">
                        <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nomeCompleto'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'nomeCompleto', 'contains')">
                    </th>
                    <th class="text-left" style="width: 35%;">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                        <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt.filter($event.target.value, 'email', 'contains')">
                    </th>
                    <th class="text-left" style="width: 10%">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                        <span class="text-overflow-dynamic-ellipsis">ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                        </span>
                      </span>
                      <p-multiSelect class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                        optionLabel="label" optionValue="value" [selectionLimit]="1" [filter]="false"
                        (onChange)="dt.filter($event.value, 'ativo', 'contains')"></p-multiSelect>
                    </th>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis">Ação</span>
                      </span>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.id}}">
                          <span style="user-select: all; cursor: text">{{rowData.id}}</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 35%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nomeCompleto}}">
                          <span style="user-select: all; cursor: text">{{rowData.nomeCompleto}}</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-left" style="width: 35%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}">
                          <span style="user-select: all; cursor: text">{{rowData.email}}</span>
                        </span>
                      </span>
                    </td>
                    <td class="text-center">
                      <div>
                        <span matTooltip="{{rowData.ativo ? 'Sim' : 'Não'}}">
                          <mat-checkbox [disabled]="true" [(ngModel)]="rowData.ativo"></mat-checkbox>
                        </span>
                      </div>
                    </td>
                    <td class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis" style="margin-top: -3px;">
                          <span class="action-btn material-icons" (click)="openEditCadMenu(rowData)">edit</span>
                          <span class="action-btn material-icons" (click)="usuarioExcluir(rowData)">delete</span>
                          <span class="action-btn material-icons" (click)="openResetSenha(rowData)">lock</span>

                        </span>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="5" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>

            <!--Tela 2-->

            <div [ngStyle]="statusUsuarioGrupo ? {'display': 'block'} : {'display': 'none'}"
              [ngClass]="{'fade-in': 'statusUsuarioGrupo'}">
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-file"></i> Grupo</h3>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-sm-3">
                  <p-dropdown class="type-p-dropdown" [filter]="true" [options]="grupos" placeholder="Selecione o grupo"
                    [(ngModel)]="groupId" (onChange)="buscarUsuarios()"></p-dropdown>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <h3 class="sub-title"><i class="fas fa-users"></i> Usuários</h3>
                </div>
              </div>
              <div class="row pb-3">
                <div class="col-sm-6">
                  <div class="conteudo m-0">
                    <p-pickList sourceHeader="Disponíveis" targetHeader="Relacionados" [source]="listaRelacionar"
                      [target]="listaRelacionados" [dragdrop]="true" [responsive]="true" filterBy="login">
                      <ng-template let-resp pTemplate="item">{{resp.login}}</ng-template>
                    </p-pickList>
                  </div>
                </div>
              </div>

              <!-- <button type="button" class="btn btn-primary" (click)="salvarRelacionamento()">
                    <i class="fas fa-check"></i> Salvar
                  </button> -->
            </div>
          </div>


          <div class="box-content pt-0 pb-0" *ngIf="novoUsuario" [@changeDivSize]=currentStateCadastroMenu>
            <div class="row">
              <div class="col-sm-12">
                <div class="my-4">
                  <p-steps class="steps-block" [model]="items" [(activeIndex)]="activeIndex" [readonly]="false">
                  </p-steps>
                </div>
              </div>
              <div class="col-sm-12" style="border-left: 1px solid #ccc;">
                <div class="my-5">

                  <div class="row" *ngIf="activeIndex === 0">
                    <div class="col-sm-7">
                      <h4 class="sub-title-page mb-2"><i class="fas fa-user-cog" style="color: #0091ea;"></i>
                        <strong>Configurar as informações básicas</strong>
                      </h4>
                      <p style="font-size: 14px; text-transform: initial;"><i class="fas fa-arrow-right"
                          style="color: #0091ea;"></i> Para começar, preencha algumas informações básicas sobre quem
                        você está adicionando como usuário.</p>
                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label>NOME</label> <span class="obrigatorio">*</span>
                          <input class="form-control" type="text" [(ngModel)]="nome" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label>SOBRENOME</label> <span class="obrigatorio">*</span>
                          <input class="form-control" type="text" [(ngModel)]="sobrenome" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label>E-MAIL</label> <span class="obrigatorio">*</span>
                          <input class="form-control" type="text" [(ngModel)]="email" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label>CONFIRMAÇÃO E-MAIL</label> <span class="obrigatorio">*</span>
                          <input class="form-control" type="text" [(ngModel)]="confEmail" />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-sm-6">
                          <label>TELEFONE</label>
                          <!-- <input class="form-control" type="text" [(ngModel)]="fone" /> -->
                          <p-inputMask class="type-p-mask custom-cep" mask="(99) 9999-9999" [(ngModel)]="fone"
                            placeholder="(99) 9999-9999"></p-inputMask>
                        </div>
                        <div class="form-group col-sm-6">
                          <label>CELULAR</label> <span class="obrigatorio">*</span>
                          <!-- <input class="form-control" type="text" (keydown.Tab)="activeIndex = 1;" [(ngModel)]="celular" /> -->
                          <p-inputMask class="type-p-mask custom-cep" mask="(99) 99999-9999"
                            (keydown.Tab)="activeIndex = 1;" [(ngModel)]="celular" placeholder="(99) 99999-9999">
                          </p-inputMask>
                        </div>
                      </div>
                      <div class="form-row" *ngIf="editUsuario">
                        <div class="form-group col-sm-6">
                          <mat-checkbox [(ngModel)]="ativo">USUÁRIO ATIVO</mat-checkbox>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-sm-12">
                          <div class="form-group col-sm-12 mt-3">
                            <button type="button" class="btn btn-secondary" style="margin-left: -15px; width: 106px;"
                              (click)="cancelarEdicao()">
                              <i class="fas fa-undo"></i>
                              Sair
                            </button>
                            <button type="button" class="btn btn-primary" (click)="avancarEdicao(activeIndex)">
                              <i class="fa fa-arrow-right"></i>
                              Avançar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" *ngIf="activeIndex === 1">
                    <div class="col-sm-12">
                      <h4 class="sub-title-page mb-3"><i class="far fa-building" style="color: #0091ea;"></i>
                        <strong>Configurar as informações da empresa</strong>
                      </h4>
                      <p style="font-size: 14px; text-transform: initial;"><i class="fas fa-arrow-right"
                          style="color: #0091ea;"></i> Relacione as empresas disponíveis.</p>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="conteudo m-0">
                            <p-pickList sourceHeader="Empresas Disponíveis" targetHeader="Empresas Relacionadas"
                              [source]="listaEmpresasRelacionar" [target]="listaEmpresasRelacionados" [dragdrop]="true"
                              [responsive]="true" filterBy="nome">
                              <ng-template let-emp pTemplate="item">{{emp.cnpj}} - {{emp.nome}}</ng-template>
                            </p-pickList>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="form-group col-sm-12 mt-3">
                      <button type="button" class="btn btn-secondary" style="width: 106px;"
                        (click)="voltarEdicao(activeIndex)">
                        <i class="fas fa-undo"></i>
                        Voltar
                      </button>
                      <button type="button" class="btn btn-primary" (click)="avancarEdicao(activeIndex)">
                        <i class="fa fa-arrow-right"></i>
                        Avançar
                      </button>
                    </div>

                  </div>

                  <div class="row" *ngIf="activeIndex === 2">


                    <div class="col-sm-12 mt-0">
                      <h4 class="sub-title-page mb-3"><i class="far fa-building" style="color: #0091ea;"></i>
                        <strong>EMPRESA(S) SELECIONADA(S)</strong>
                      </h4>
                      <p style="font-size: 14px; text-transform: initial;"><i class="fas fa-arrow-right"
                          style="color: #0091ea;"></i> Selecione a empresa que deseja relacionar aos grupos disponíveis.
                      </p>
                      <p-table class="p-datatable-sm p-datatable-striped table-mov" [value]="listaEmpresasRelacionados"
                        [paginator]="false" [rows]="5" [resizableColumns]="true" [responsive]="true" [rowHover]="false"
                        derableColumns="true" [scrollable]="true" [style]="{width:'100%'}" scrollHeight="280px">

                        <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th class="text-left" style="width: 80%">
                              <span class="text-overflow-dynamic-container">
                                <span class="text-overflow-dynamic-ellipsis">Empresa</span>
                              </span>
                            </th>
                            <th class="text-left" style="width: 20%">
                              <span class="text-overflow-dynamic-container">
                                <span class="text-overflow-dynamic-ellipsis">Relacionadas</span>
                              </span>
                            </th>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-rowData>
                          <tr>
                            <td style="width:80%">
                              <span (click)="changeGroup(rowData.id)" matTooltip="{{rowData.cnpj}} - {{rowData.nome}}"
                                class="overflow-ellipse" style="cursor: pointer; text-align: left">
                                {{rowData.cnpj}} - {{rowData.nome}}
                              </span>
                            </td>

                            <td style="width:20%" class="text-center">

                              <span *ngIf="rowData.selecionada"><i class="fas fa-check-double"
                                  style="color: #00bfa5"></i></span>

                            </td>

                          </tr>
                        </ng-template>

                      </p-table>
                    </div>


                    <div class="col-sm-12">
                      <h4 class="sub-title-page mb-3"><i class="fas fa-key"
                          style="color: #0091ea; margin-top: 40px;"></i> <strong>Configurar as informações do
                          acesso</strong></h4>
                      <p style="font-size: 14px; text-transform: initial;"><i class="fas fa-arrow-right"
                          style="color: #0091ea;"></i> Relacione e grave os grupos disponíveis a(s) empresa(s)
                        selecionada(s).</p>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="conteudo m-0">
                            <p-pickList sourceHeader="Disponíveis" targetHeader="Relacionados"
                              [source]="listaGruposRelacionar" [target]="listaGruposRelacionados" [dragdrop]="true"
                              [responsive]="true" filterBy="group_name">
                              <ng-template let-group pTemplate="item">{{group.group_name}}</ng-template>
                            </p-pickList>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-sm-12 mt-3">
                      <button type="button" class="btn btn-secondary" style="width: 106px;"
                        (click)="voltarEdicao(activeIndex)">
                        <i class="fas fa-undo"></i>
                        Voltar
                      </button>
                      <button type="button" class="btn btn-info" style="width: 106px;"
                        [disabled]="listaGruposRelacionados.length===0" (click)="salvarRelacao()">
                        <i class="fas fa-check"></i>
                        Gravar
                      </button>
                      <button type="button" class="btn btn-primary" style="width: 106px;"
                        (click)="avancarEdicao(activeIndex)">
                        <i class="fa fa-arrow-right"></i>
                        Avançar
                      </button>
                    </div>
                  </div>

                  <div class="row" *ngIf="activeIndex === 3">
                    <div class="col-sm-12">
                      <h4 class="sub-title-page mb-3" *ngIf="!editUsuario"><i class="far fa-eye"
                          style="color: #0091ea;"></i> <strong>Examinar e concluir</strong></h4>
                      <h4 class="sub-title-page mb-3" *ngIf="editUsuario"><i class="far fa-eye"
                          style="color: #0091ea;"></i> <strong>Concluir edição de usuário</strong></h4>
                      <p style="font-size: 14px; text-transform: initial;" *ngIf="!editUsuario"><i
                          class="fas fa-arrow-right" style="color: #0091ea;"></i> Revise todas as informações e
                        configurações deste usuário antes de terminar de adicioná-lo.</p>
                      <p style="font-size: 14px; text-transform: initial;" *ngIf="editUsuario"><i
                          class="fas fa-arrow-right" style="color: #0091ea;"></i> Revise todas as informações e
                        configurações antes de terminar está edição.</p>

                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label class="d-block">Nome:</label>
                          <p *ngIf="nome">{{nome}}</p>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert" *ngIf="!nome"
                            style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> Informação Obrigatória não foi informada!
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label class="d-block">Sobrenome:</label>
                          <p *ngIf="sobrenome">{{sobrenome}}</p>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert" *ngIf="!sobrenome"
                            style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> Informação Obrigatória não foi informada!
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label class="d-block">E-mail:</label>
                          <p *ngIf="email">{{email}}</p>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert" *ngIf="!email"
                            style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> Informação Obrigatória não foi informada!
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label class="d-block">Fone:</label>
                          <p *ngIf="fone">{{fone}}</p>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert" *ngIf="!fone"
                            style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> Nenhum telefone adicionado!
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12">
                          <label class="d-block">Celular:</label>
                          <p *ngIf="celular">{{celular}}</p>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert" *ngIf="!celular"
                            style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> Informação Obrigatória não foi informada!
                          </div>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-6">
                          <label class="d-block">Empresas Selecionadas:</label>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert"
                            *ngIf="listaEmpresasRelacionados.length === 0" style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> É necessário selecionar empresa!
                          </div>
                          <ul class="list-group list-group-flush list-log" style="max-height: 150px; overflow-y: auto;"
                            *ngIf="listaEmpresasRelacionados.length > 0">
                            <li class="list-group-item" *ngFor="let emp of listaEmpresasRelacionados"
                              style="font-size: 11px; font-weight: normal; cursor: pointer;"
                              (click)="changeGroup2(emp)">
                              {{emp.cnpj }} - {{emp.nome }}
                            </li>
                          </ul>
                        </div>

                        <div class="form-group col-sm-6">
                          <label class="d-block">Grupos Selecionados:</label>
                          <div class="alert alert-xs alert-warning d-inline-block mb-0" role="alert"
                            *ngIf="!empresaGrupoRelacionados[oldEmpresaId2] && ativo" style="font-size: 12px;">
                            <i class="fas fa-exclamation"></i> É necessário selecionar grupo!
                          </div>
                          <ul class="list-group list-group-flush list-log" style="max-height: 150px; overflow-y: auto;"
                            *ngIf="empresaGrupoRelacionados[oldEmpresaId2]">
                            <li class="list-group-item" *ngFor="let grp of empresaGrupoRelacionados[oldEmpresaId2]"
                              style="font-size: 11px; font-weight: normal;">
                              {{grp.group_name }}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-sm-12 mb-4">
                          <button type="button" class="btn btn-secondary" (click)="voltarEdicao(activeIndex)">
                            <i class="fas fa-undo"></i>
                            VOLTAR
                          </button>
                          <button type="button" class="btn btn-primary" (click)="cadastrarUsuario()">
                            <i class="fas fa-check"></i>
                            SALVAR
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </mat-card-content>


  <!-- MODAL EDIT -->
  <p-dialog header="Relacionar Grupos" [(visible)]="modalEdit" [style]="{width: '638px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="false">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">

      </div>
    </div>

    <div class="form-row" style="display: flow-root;">
      <div class="row">
        <div class="col-sm-12">
          <div class="conteudo m-0">
            <p-pickList *ngIf="picklistState" #pickList sourceHeader="Disponíveis" targetHeader="Relacionados"
              [source]="listaGrupoRelacionar" [target]="listaGrupoRelacionados" [dragdrop]="true" [responsive]="true"
              filterBy="group_name" [showSourceControls]="false" [showTargetControls]="false">
              <ng-template let-group pTemplate="item">{{group.group_name}}</ng-template>
            </p-pickList>
          </div>
        </div>
      </div>

      <div class="p-field-checkbox">
        <p-checkbox class="mr-1" [(ngModel)]="ativoEdit" [binary]="true" inputId="ativo"></p-checkbox>
        <label for="ativo">ATIVO</label>
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalEdit=false; resetarFiltros();'><i
          class="fas fa-undo"></i>
        Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="salvarRelacionamentoGrupo()"
        [disabled]="!(listaGrupoRelacionados.length > 0)"><i class="fas fa-check"></i>
        Salvar
      </button>
    </p-footer>
  </p-dialog>


  <!-- MODAL CONCEDER ACESSO -->
  <p-dialog header="Conceder acesso" [(visible)]="modalConcederAcesso" [style]="{width: '638px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="true" (onHide)="listUsuarios()">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">
        <p-messages [value]="msgsModal" (click)="hideMessage()"></p-messages>
      </div>
    </div>

    <div class="form-row" style="display: flow-root; margin-bottom: 5px;">
      <div class="row">
        <div class="col-sm-12">
          <div class="conteudo m-0">
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="usuariosSemAcesso"
              placeholder="Selecione o usuário" [(ngModel)]="usuarioSemAcessoId" (onChange)="buscarUsuarios()">
            </p-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="conteudo m-0">
          <p-pickList sourceHeader="Disponíveis" targetHeader="Relacionados" [source]="listaGruposRelacionar"
            [target]="listaGruposRelacionados" [dragdrop]="true" [responsive]="true" filterBy="group_name">
            <ng-template let-group pTemplate="item">{{group.group_name}}</ng-template>
          </p-pickList>
        </div>
      </div>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalConcederAcesso=false;'><i class="fas fa-undo"></i>
        Cancelar
      </button>
      <button type="button" [disabled]="!(listaGruposRelacionados.length > 0 && usuarioSemAcessoId > 0)"
        class="btn btn-primary" (click)="concederAcessoUsuario()"><i class="fas fa-check"></i>
        Conceder Acesso
      </button>
    </p-footer>
  </p-dialog>

  <!-- MODAL REDEFINIR SENHA -->
  <p-dialog header="Redefinir Senha" [(visible)]="modalReset" [style]="{width: '400px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
    [closable]="true">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">
        <p-messages [value]="msgsModal" (click)="hideMessage()"></p-messages>
      </div>
    </div>

    <!-- <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Insira uma nova senha:</label>
      <input class="form-control" type="password" [(ngModel)]="novaSenha">
    </div>
  </div> -->

    <div class="form-row">
      <div class="form-group col-sm-12 position-relative">
        <label>Insira uma nova senha:</label>
        <input type="password" class="form-control" id="uePassowrd2" [(ngModel)]="novaSenha" />
        <button class="btn btn-sm btn-link submit-eye" type="submit"
          [ngStyle]="novaSenha?.length > 0 ? {'display': 'block'} : {'display': 'none'}">
          <i id="ueEyePass2" class="fa fa-eye"></i>
        </button>
      </div>
    </div>

    <!-- <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Digite a senha novamente para confirmá-la:</label>
      <input class="form-control" type="password" [(ngModel)]="novaSenha2">
    </div>
  </div> -->

    <div class="form-row">
      <div class="form-group col-sm-12  position-relative">
        <label>Digite a senha novamente para confirmá-la:</label>
        <input type="password" class="form-control" id="uePassowrd3" [(ngModel)]="novaSenha2" />
        <button class="btn btn-sm btn-link submit-eye" type="submit"
          [ngStyle]="novaSenha2?.length > 0 ? {'display': 'block'} : {'display': 'none'}">
          <i id="ueEyePass3" class="fa fa-eye"></i>
        </button>
      </div>
    </div>
    <div class="p-field-checkbox">
      <p-checkbox class="mr-1" [(ngModel)]="forcePasswordReset" [binary]="true" inputId="forcePR"></p-checkbox>
      <label for="forcePR">Forçar nova senha ao logar?</label>
    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalReset=false;'><i class="fas fa-undo"></i>
        Cancelar
      </button>
      <button type="button" class="btn btn-primary" (click)="salvarReset()"><i class="fas fa-check"></i>
        Salvar
      </button>
    </p-footer>

  </p-dialog>
  <!-- EDIT USER PAGE -->

</mat-card>
