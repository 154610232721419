<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}. Período
      selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à
      {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="clearfix"></div>
    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsFichas" [value]="fichas" [rows]="rows"
        [totalRecords]="fichas.length" [paginator]="true" (onFilter)="onFilter($event)" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox">
              <span class="check-all p-0">
                <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
              </span>
            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'full_name'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'full_name'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'full_name', 'contains')">
            </th>
            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">E-mail <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'training_type_description'">
                <span class="text-overflow-dynamic-ellipsis">CURSO <p-sortIcon [field]="'training_type_description'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'training_type_description', 'contains')">
            </th>

            <th class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'ingresso'">
                <span class="text-overflow-dynamic-ellipsis">INGRESSO <p-sortIcon [field]="'ingresso'"></p-sortIcon>
                  </span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboTipoIngresso" defaultLabel="Selecione"
                [filter]="false" (onChange)="dt2.filter($event.value, 'ingresso', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'start_date'">
                <span class="text-overflow-dynamic-ellipsis">DATA <p-sortIcon [field]="'start_date'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'start_date', 'contains')">
            </th>


            <th class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboStatus" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'status', 'in')"></p-multiSelect>
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'motivo_erro'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'motivo_erro'"></p-sortIcon>
                  </span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboErro" defaultLabel="Selecione" [filter]="false"
                (onChange)="dt2.filter($event.value, 'motivo_erro', 'in')"></p-multiSelect>
            </th>

            <th class="text-right" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'valor', 'contains')">
            </th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [ngClass]="(rowData.status === 2 || rowData.status === 6) ? 'negrito' : '' ">
            <td class="text-center tableCheckbox">
              <p-tableCheckbox (click)="selectFicha(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.full_name}}"><span
                    style="user-select: all; cursor: text"> {{rowData.full_name }} </span></span>
              </span>
            </td>

            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}"> <span
                    style="user-select: all; cursor: text"> {{rowData.email}} </span></span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf}}"> <span
                    style="user-select: all; cursor: text"> {{rowData.cpf}} </span></span>
              </span>
            </td>
            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.training_type_description}}"> <span
                    style="user-select: all; cursor: text"> {{rowData.training_type_description }} </span></span>
              </span>
            </td>

            <td class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ingresso}}"> <span
                    style="user-select: all; cursor: text"> {{rowData.ingresso }} </span></span>
              </span>
            </td>


            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.start_date}}"> <span
                    style="user-select: all; cursor: text"> {{rowData.start_date }} </span></span>
              </span>
            </td>

            <td class="text-center" style="width: 5%;">

              <i *ngIf="rowData.status === 1" style="color: green;" class="fas fa-receipt"
                matTooltip="{{rowData.status_descricao}}"></i>
              <i *ngIf="rowData.status === 2 || rowData.status === 6" style="color: darkred;"
                class="fas fa-exclamation-triangle" matTooltip="{{rowData.motivo_erro}}"></i>
              <i *ngIf="rowData.status === 3" style="color: green;" class="fas fa-file-invoice-dollar"
                matTooltip="{{rowData.status_descricao}}"></i>
              <i *ngIf="rowData.status === 4" style="color: green;" class="fas fa-check"
                matTooltip="{{rowData.status_descricao}}"></i>
              <i *ngIf="rowData.status === 5" style="color: green;" class="fas fa-check-double"
                matTooltip="{{rowData.status_descricao}}"></i>
              <i *ngIf="rowData.status === 7" style="color: darkred;" class="fas fa-trash"
                matTooltip="{{rowData.status_descricao}}"></i>
              <i *ngIf="rowData.status === 8" style="color: darkblue;" class="fas fa-history"
                matTooltip="{{rowData.status_descricao}}"></i>

            </td>


            <td class="text-center" style="width: 10%;">

              <span *ngIf="rowData.motivo_erro.length === 0" matTooltip="{{rowData.status_descricao}}"> <span
                  style="user-select: all; cursor: text"> {{rowData.status_descricao}}</span> </span>
              <span *ngIf="rowData.motivo_erro.length > 0" matTooltip="{{rowData.motivo_erro}}"> <span
                  style="user-select: all; cursor: text"> {{rowData.status_descricao}}: {{rowData.motivo_erro}}</span>
              </span>

            </td>


            <td class="text-right" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}"> <span
                    style="user-select: all; cursor: text"> {{rowData.valor }} </span></span>
              </span>
            </td>



          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="13" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>



    </div>
  </mat-card-content>
</mat-card>