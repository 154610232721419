<p-dialog [(visible)]="isVisible" [style]="{width: '60vw'}" (onShow)="getParticipants()"
  (onHide)="setVisibleState(false);resetValues()" [modal]="true" styleClass="modal-custom" [responsive]="true"
  [closable]="true" appendTo="body">

  <p-header>
    <label class="title">Escolher participante</label>
  </p-header>

  <p-table class="table-system" #tableParticipant [value]="listParticipant" [rows]="25" [paginator]="true"
    [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [selectionMode]="isMultiple?'multiple':'single'"
    [lazy]="true" (onLazyLoad)="getParticipants($event)" [totalRecords]="totalParticipant"
    [(selection)]="selectedParticipant" appendTo="body" [style]="{width:'100%',height:'100%'}">

    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" style="width: 33%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod_part'">
            <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod_part'">
              </p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableParticipant.filter($event.target.value, 'cod_part', 'contains')">
        </th>
        <th class="text-center" style="width: 33%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf_cnpj'">
            <span class="text-overflow-dynamic-ellipsis">CPF/CNPJ <p-sortIcon [field]="'cpf_cnpj'"></p-sortIcon>
            </span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableParticipant.filter($event.target.value, 'cpf_cnpj', 'contains')">
        </th>
        <th class="text-center" style="width: 34%;">
          <span class="text-overflow-dynamic-container" [pSortableColumn]="'fantasia'">
            <span class="text-overflow-dynamic-ellipsis">FANTASIA <p-sortIcon [field]="'fantasia'"></p-sortIcon></span>
          </span>
          <input class="form-control form-control-sm" type="text"
            (input)="tableParticipant.filter($event.target.value, 'fantasia', 'contains')">
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" class="tableRow">
        <td class="text-center">
          <span class="overflow-ellipsis" matTooltip="{{rowData.cod_part}}">{{rowData.cod_part}}</span>
        </td>
        <td class="text-center" style="width: 6%;">
          <span class="overflow-ellipsis" matTooltip="{{rowData.cpf_cnpj}}">{{rowData.cpf_cnpj}}</span>
        </td>
        <td class="text-left" style="width: 7%;">
          <span class="overflow-ellipsis" matTooltip="{{rowData.fantasia}}">{{rowData.fantasia}}</span>
        </td>
      </tr>
    </ng-template>

  </p-table>

  <p-footer>
    <div class="footer">
      <div class="child">
        <ng-content></ng-content>
      </div>
      <div class="d-flex">
        <button type="button" class="btn btn-secondary" (click)="setVisibleState(false)">
          <i class="fas fa-undo"></i> Fechar
        </button>
        <button type="button" [disabled]="selectedParticipant.length==0" class="btn btn-primary" (click)="onSave()">
          <i class="fas fa-check"></i> {{saveButtonLabel}}
        </button>
        <p-checkbox *ngIf="isReplicable" class="mr-1" [(ngModel)]="applyAll" [binary]="true" inputId="participante"
          label="Aplicar para todas as linhas selecionadas">
        </p-checkbox>
      </div>
    </div>
  </p-footer>

</p-dialog>