<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content p-3" style="margin-top: -25px">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
            <div class="actions-card">
              <div style="display: flex;">
                <button type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu" class="btnCustom-default"
                  matTooltip="Mais opções" *ngIf="!editarConta && !novaConta">
                  <span class="material-icons" style="margin-top: -7px;">more_vert</span>
                </button>
                  <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                      <p-checkbox class="mr-1" [(ngModel)]="showInativos" [binary]="true"
                      (onChange)="carregar()">
                      </p-checkbox>
                      <span>Exibir Inativos</span>
                    </div>
                    <div class="menuOptions" mat-menu-item (click)="exportarExcel();">
                      <span>Exportar</span>
                    </div>
                  </mat-menu>
                  <app-toolbar [value]="toolbarOperacaoRegra"></app-toolbar>
              </div>
            </div>
          </div>
        </div>
          <div *ngIf="!novaConta && !editarConta" style="margin-top: -6px;">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroRegra" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="selectCheckbox($event)" [(selection)]="clearArray"
              (onRowUnselect)="unselectCheckbox($event)" (onHeaderCheckboxToggle)="selecionarTudo()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox"style="height: 40px;">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-center" style="height: 20px;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'cod', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'descricao', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoEntradaSaida.descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Tipo <p-sortIcon [field]="'tipoEntradaSaida.descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesEntrada" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'tipoEntradaSaida.descricao', 'contains')"></p-multiSelect>
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'geraFinanceiro'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Gera Financeiro <p-sortIcon [field]="'geraFinanceiro'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'geraFinanceiro', 'contains')"></p-multiSelect>
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'controlaEstoque'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Controla Estoque <p-sortIcon [field]="'controlaEstoque'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect #cmp class="type-p-mult" [options]="opcoesAtivo" defaultLabel="Selecione"
                      (onChange)="dt.filter($event.value, 'controlaEstoque', 'contains')"></p-multiSelect>
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtInicial'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Data Inicial <p-sortIcon [field]="'dtInicial'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtInicial', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtFinal'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Data Final <p-sortIcon [field]="'dtFinal'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'dtFinalFormatoBrasil', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">STATUS REGRA<p-sortIcon [field]="'ativo'">
                      </p-sortIcon></span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]=" showInativos == true ? opcoesOperacaoRegraShowInativos : opcoesOperacaoRegra"
                      defaultLabel="Selecione" [filter]="false" (onChange)="dt.filter($event.value, 'ativo', 'in')">
                    </p-multiSelect>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" [ngClass]="{ inativo: !rowData.ativo }">
                  <td class="text-center" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cod}}">{{rowData.cod}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.tipoEntradaSaida}}">{{rowData.tipoEntradaSaida != null?
                        rowData.tipoEntradaSaida.descricao: ''}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.geraFinanceiro" [binary]="true"
                      inputId="geraFinanceiro" [disabled]="true"></p-checkbox>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.controlaEstoque" [binary]="true"
                      inputId="controlaEstoque" [disabled]="true"></p-checkbox>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtInicial}}">
                        {{rowData.dtInicial | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtFinal}}">
                        {{rowData.dtFinal | timezone | date:'dd/MM/yyyy'}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.ativo? 'ATIVO' : 'INATIVO'}}">{{rowData.ativo? 'ATIVO' : 'INATIVO'
                        }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div style="margin-top: -64px;">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Tipo<span class="obrigatorio">*</span></label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesParent" (click)="true"
                  [(ngModel)]="operacaoRegra.tipoEntradaSaida.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Código<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="operacaoRegra.cod">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição<span class="obrigatorio">*</span></label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="operacaoRegra.descricao">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Regra Contábil</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesRegraContabil" [filter]="true"
                  [(ngModel)]="operacaoRegra.operacaoRegraContabil.id"></p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Regra Fiscal</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesRegraFiscal" [filter]="true"
                  [(ngModel)]="operacaoRegra.operacaoRegraFiscal.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Ind Nat Frt (Informar caso operação seja Frete)</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesIndNatFrt" [filter]="true"
                  [(ngModel)]="operacaoRegra.indNatFrt.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Base Cálculo Crédito</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesBcCalculoCredito" [filter]="true"
                  [(ngModel)]="operacaoRegra.bcCalculoCredito.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.geraFinanceiro">Gera Financeiro</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Data Inicial</label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="operacaoRegra.dtInicial">
                </p-calendar>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Data Final </label>
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  yearRange="2010:2030" [(ngModel)]="operacaoRegra.dtFinal">
                </p-calendar>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.adto">Adiantamento</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.controlaEstoque">Controle de Estoque</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Conta Contábil Estoque </label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesContaContabil" [filter]="true"
                  [(ngModel)]="operacaoRegra.contaContabilEstoque.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.transfEstoque">Transferência de Estoque</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.permiteAlteracaoAlmoxarifado">Permite Altereção
                  Almoxarifado</mat-slide-toggle>
              </div>
            </div>
            <div *ngIf="operacaoRegra.controlaEstoque">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Almoxarifado Origem</label>
                  <p-dropdown class="type-p-dropdown" [options]="opcoesAlmox"
                    [(ngModel)]="operacaoRegra.almoxarifadoOrigem.id">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div *ngIf="operacaoRegra.controlaEstoque && operacaoRegra.transfEstoque">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Almoxarifado Destino</label>
                  <p-dropdown class="type-p-dropdown" [options]="opcoesAlmox"
                    [(ngModel)]="operacaoRegra.almoxarifadoDestino.id">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div *ngIf="operacaoRegra.tipoEntradaSaida.id == 2" class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.criacaoOp">Criação Ordem de Produção</mat-slide-toggle>
              </div>
            </div>
            <div *ngIf="!operacaoRegra.transfEstoque && operacaoRegra.tipoEntradaSaida.id == 2" class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.utilizaReserva">Utiliza Almoxarifado Reserva
                </mat-slide-toggle>
              </div>
            </div>
            <div *ngIf="!operacaoRegra.transfEstoque && operacaoRegra.tipoEntradaSaida.id == 2">
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>Almoxarifado Reserva</label>
                  <p-dropdown class="type-p-dropdown" [options]="opcoesAlmox"
                    [(ngModel)]="operacaoRegra.almoxarifadoReserva.id">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.alteraCusto">Altera Custo</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.utilizaCustoMedio">Utiliza Custo Médio</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.utilizaPrecoVenda">Utiliza Preço Venda</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.transfFilial">Transf. Filial</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row" *ngIf="operacaoRegra.transfFilial">
              <div class="form-group col-sm-12">
                <label>Regra Fiscal Entrada</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesRegraFiscal"
                  [(ngModel)]="operacaoRegra.operacaoRegraFiscalEntrada.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row" *ngIf="operacaoRegra.transfFilial">
              <div class="form-group col-sm-12">
                <label>Regra Contábil Entrada</label>
                <p-dropdown class="type-p-dropdown" [options]="opcoesRegraContabil"
                  [(ngModel)]="operacaoRegra.operacaoRegraContabilEntrada.id">
                </p-dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.bloqueiaFiscal">Bloqueia Fiscal</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row" *ngIf="operacaoRegra.tipoEntradaSaida.id == 1">
              <div class="form-group col-sm-12">
                <mat-slide-toggle [(ngModel)]="operacaoRegra.utilizaBasePcXml">Considerar Base Pis e Cofins do Fornecedor</mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Dados Adicionais</label>
                <div></div>
                <div>
                  <textarea style="width: 100%; height: 200px;" rows="5" cols="30" pInputTextarea
                    [(ngModel)]="operacaoRegra.dadosAdicionais"></textarea>
                </div>
              </div>
            </div>
            <div class="form-column" style="background-color: #fff; padding: 10px;">
              <div class="form-group">
                <div class="p-field-checkbox mb-3 ml-1">
                  <p-checkbox class="mr-1" [(ngModel)]="operacaoRegra.ativo"
                    (change)="operacaoRegra.ativo = !operacaoRegra.ativo" [binary]="true"
                  inputId="operacaoRegra.ativo"></p-checkbox>
                  <label for="operacaoRegra.ativo" class="ml-1" style="position: relative;top: 3px">Regra
                  ativa</label>
                </div>
              </div>
            </div>
            <div *ngIf="usesEyeMobile && editarConta" class="col-sm-12">
              <p style="font-size: 13px; text-transform: initial;"><i class="fas fa-arrow-right"
                  style="color: #0091ea;"></i> Vincule as lojas disponíveis.</p>
              <div class="conteudo m-0">
                <p-pickList sourceHeader="Lojas Disponíveis" targetHeader="Lojas Relacionadas"
                  [source]="lojasRelacionar" [target]="lojasRelacionadas" [dragdrop]="true"
                  [responsive]="true" filterBy="nome" [showSourceControls]="false" [showTargetControls]="false">
                  <ng-template let-loja pTemplate="item">{{loja.nome}}</ng-template>
                </p-pickList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <p-dialog header="Clonagem de regra de operação" [(visible)]="modalCloneOperacao" [style]="{width: '900px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true" (onHide)="resetarVariaveis()">
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; z-index: 100;">
      <div class="ui-g-12">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Novo Código</label>
        <input type="text" class="form-control text-left" placeholder="Insira o novo Código" [(ngModel)]="codigoClone">
      </div>
      <div class="form-group col-sm-12">
        <label>Descrição</label>
        <input type="text" class="form-control text-left" placeholder="Insira a Descrição"
          [(ngModel)]="descricaoClone">
      </div>
    </div>
    <p-footer>
      <div id="div-botoes">
        <div class="margin-botoes-modal">
          <button type="button" class="btn btn-secondary" (click)='modalCloneOperacao=false;'><i class="fas fa-undo"></i>
            Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="clonarRegistro();"><i class="fas fa-check"></i>
            Salvar</button>
        </div>
      </div>
    </p-footer>
  </p-dialog>

  </mat-card-content>

  <p-dialog [header]="tituloModalGrupoEmpresas" [(visible)]="modalGrupoEmpresas" styleClass="modal-scroll" [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true" (onHide)="resetarVariaveis()">

    <label style="margin-top: 100px;">{{ descricaoModalGrupoEmpresas }}</label>
    <p-multiSelect defaultLabel="Selecione as empresas do grupo" class="type-p-mult" appendTo="body" [filter]="true" [options]="empresasGrupo" [(ngModel)]="empresasGrupoSelecionadas">
    </p-multiSelect>

    <p-footer class="d-flex align-items-center justify-content-end">
      <button class="btnCustom-green" (click)="this.novaConta ? criarOperacaoRegraGrupoEmpresa() : editarOperacaoRegraGrupoEmpresa()" *ngIf="!clonagemGrupoEmpresas">
        <span class="material-icons">save</span>
        <div>Salvar</div>
      </button>

      <button class="btnCustom-green" (click)="clonarRegraGrupoEmpresa()" *ngIf="clonagemGrupoEmpresas">
        <span class="material-icons">save</span>
        <div>Clonar</div>
      </button>
    </p-footer>
  </p-dialog>
</mat-card>