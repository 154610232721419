<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
  </mat-card-title>

  <div class="shadow-box" style="border-radius: 13px; padding: 3px; margin-top: 20px;"> 
    <div class="header-box" style="display: flex; justify-content: space-between">
      <mat-card-title style="display: flex; justify-content: center; align-items: center; margin: 0 8px;">
        {{subtitle}}
      </mat-card-title>
      <div>
        <button type="button" *ngIf="showCardBool" (click)="ngOnInit()" class="btn btn-xs btn-link">
          <i class="fas fa-undo-alt" matTooltip="Voltar" matTooltipPosition="above"
          style="font-size: 14px;"></i>
        </button>
        <button type="button" *ngIf="showCardBool" (click)="change()" class="btn btn-xs btn-link">
            <i class="fas fa-sync" matTooltip="Atualizar RMS" matTooltipPosition="above"
            style="font-size: 14px;"></i>
        </button>
        <button type="button" *ngIf="showCardBool" (click)="ngOnInit()" class="btn btn-xs btn-link">
            <i class="fas fa-trash" matTooltip="Excluir" matTooltipPosition="above"
            style="font-size: 14px;"></i>
        </button>
        <button type="button" *ngIf="showCardBool" (click)="ngOnInit()" class="btn btn-xs btn-link">
            <i class="fas fa-check" matTooltip="Salvar requisição" matTooltipPosition="above"
            style="font-size: 14px;"></i>
        </button>
        <button type="button" *ngIf="showCardBool" (click)="ngOnInit()" class="btn btn-xs btn-link">
            <i class="fas fa-pen" matTooltip="Editar requisição" matTooltipPosition="above"
            style="font-size: 14px;"></i>
        </button>
        <button type="button" *ngIf="showCardBool" (click)="salvarRelacionamento()" class="btn btn-xs btn-link">
          <i class="fas fa-save" matTooltip="Salvar Aprovadores" matTooltipPosition="above"
          style="font-size: 14px;"></i>
      </button>
        <button type="button" class="btn btn-xs btn-link" style="padding-left: 0 !important;" (click)="showCard()">
          <span *ngIf="showCardBool" class="material-icons" style="font-size: 30px; cursor: pointer;" matTooltip="Ocultar Card"
            matTooltipPosition="above">expand_less</span>
          <span *ngIf="!showCardBool" class="material-icons" style="font-size: 30px; cursor: pointer;" matTooltip="Ver Card"
            matTooltipPosition="above">expand_more</span>
        </button>
      </div>
    </div>
    <div class="box-content" [@changeDivSize]=currentStateMov>
      <div class="row">
        <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-user"></i> Grupo operação</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="grupos"
            placeholder="Selecione o Grupo" [(ngModel)]="grupoId" (onChange)="buscarOperacaoRegras()"></p-dropdown>
        </div>
      </div>
        <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas  fa-hand-holding-usd"></i> Operação Regras </h3>
        </div>
        </div>
        <div class="row">
        <div class="col-sm-8">
          <div class="conteudo m-0">
            <div style="margin-left: 250px; width: 100%;">
            <p-pickList *ngIf="picklistState" sourceHeader="Disponíveis" targetHeader="Operação Regras relacionadas" [source]="listaOperacaoRegrasAMapear"
              [target]="listaOperacaoRegrasMapeadas" [dragdrop]="true" [responsive]="true" filterBy="codOperacaoRegra"
              [showSourceControls]="false" [showTargetControls]="false">
              <ng-template let-operacaoRegra pTemplate="item">{{ operacaoRegra.codOperacaoRegra }} - {{ operacaoRegra.descrOperacaoRegra }}</ng-template>
            </p-pickList>
            </div>
          </div>
        </div>
        </div>
    </div>
  </div>


</mat-card>