<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g" style="position: fixed; right: 15px;  bottom: 5px; min-width: 500px; max-width: 500px;">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-tab-group>

    <!-- Aba 'RAS' -->
    <!--========================================================================================-->
    <mat-tab label="RAS ({{ rasResults.length }})">
      <div class="box-content pt-2">
        <div id="findme" style="display: none;">RAS</div>

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p-table class="table-system" #dt1 [columns]="colsrasResults" [value]="rasResults" [rows]="rows"
          [paginator]="true" selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          [style]="{'width':'100%'}" immutable="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="checkAll($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasNumProcesso'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO PROCESSO <p-sortIcon [field]="'rasNumProcesso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasNumProcesso', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numOperacaoEmpresa'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO OPERAÇÃO <p-sortIcon
                      [field]="'numOperacaoEmpresa'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'numOperacaoEmpresa', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasDtInicio'">
                  <span class="text-overflow-dynamic-ellipsis">DATA INÍCIO <p-sortIcon [field]="'rasDtInicio'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasDtInicio', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasDtConclusao'">
                  <span class="text-overflow-dynamic-ellipsis">DATA CONCLUSÃO <p-sortIcon [field]="'rasDtConclusao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasDtConclusao', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasModPrestacao'">
                  <span class="text-overflow-dynamic-ellipsis">MODO PRESTAÇÃO <p-sortIcon [field]="'rasModPrestacao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasModPrestacao', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasCodFornecedor'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO FORNECEDOR <p-sortIcon
                      [field]="'rasCodFornecedor'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasCodFornecedor', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasNomeFornecedor'">
                  <span class="text-overflow-dynamic-ellipsis">NOME FORNECEDOR <p-sortIcon
                      [field]="'rasNomeFornecedor'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasNomeFornecedor', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasEndereco'">
                  <span class="text-overflow-dynamic-ellipsis">ENDEREÇO <p-sortIcon [field]="'rasEndereco'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasEndereco', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasNif'">
                  <span class="text-overflow-dynamic-ellipsis">NIF <p-sortIcon [field]="'rasNif'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasNif', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasTipVinculacao'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO VINCULAÇÃO <p-sortIcon [field]="'rasTipVinculacao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasTipVinculacao', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasCodImportador'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO IMPORTADOR <p-sortIcon
                      [field]="'rasCodImportador'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasCodImportador', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasMoeda'">
                  <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'rasMoeda'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasMoeda', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasDescServico'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO SERVIÇO<p-sortIcon [field]="'rasDescServico'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasDescServico', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasCodNbs'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO NBS<p-sortIcon [field]="'rasCodNbs'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasCodNbs', 'contains')">
              </th>
              <th class="text-right">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rasValor'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR<p-sortIcon [field]="'rasValor'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt1.filter($event.target.value, 'rasValor', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="check(rowData.id, rowData)" [value]="rowData"
                  [disabled]="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL' || rowData.tipo === 'pp' || rowData.tipo === 'pr'">
                </p-tableCheckbox>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasNumProcesso}}">{{rowData.rasNumProcesso }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.numOperacaoEmpresa}}">{{rowData.numOperacaoEmpresa }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasDtInicio}}">{{rowData.rasDtInicio }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasDtConclusao}}">{{rowData.rasDtConclusao }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasModPrestacao}}">{{rowData.rasModPrestacao }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasCodFornecedor}}">{{rowData.rasCodFornecedor }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasNomeFornecedor}}">{{rowData.rasNomeFornecedor }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasEndereco}}">{{rowData.rasEndereco }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rasNif}}">{{rowData.rasNif
                    }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasTipVinculacao}}">{{rowData.rasTipVinculacao }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasCodImportador}}">{{rowData.rasCodImportador }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rasMoeda}}">{{rowData.rasMoeda
                    }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rasDescServico}}">{{rowData.rasDescServico }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rasCodNbs}}">{{rowData.rasCodNbs
                    }}</span>
                </span>
              </td>
              <td class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rasValor}}">{{rowData.rasValor
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="16" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </mat-tab>

    <!-- Aba 'RVS' -->
    <!--========================================-->
    <mat-tab label="RVS ({{ rvsResults.length }})">
      <div class="box-content pt-2">
        <div id="findme" style="display: none;">RVS</div>

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p-table class="table-system" #dt2 [columns]="colsrvsResults" [value]="rvsResults" [rows]="rows"
          [paginator]="true" selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          [style]="{'width':'100%'}" immutable="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="checkAll($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsNumProcesso'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO PROCESSO <p-sortIcon [field]="'rvsNumProcesso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsNumProcesso', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numOperacaoEmpresa'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO OPERAÇÃO <p-sortIcon
                      [field]="'numOperacaoEmpresa'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'numOperacaoEmpresa', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsDtInicio'">
                  <span class="text-overflow-dynamic-ellipsis">DATA INÍCIO <p-sortIcon [field]="'rvsDtInicio'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsDtInicio', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsDtConclusao'">
                  <span class="text-overflow-dynamic-ellipsis">DATA CONCLUSÃO <p-sortIcon [field]="'rvsDtConclusao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsDtConclusao', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsModPrestacao'">
                  <span class="text-overflow-dynamic-ellipsis">MODO PRESTAÇÃO <p-sortIcon [field]="'rvsModPrestacao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsModPrestacao', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsCodCliente'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO CLIENTE <p-sortIcon [field]="'rvsCodCliente'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsCodCliente', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsNomeCliente'">
                  <span class="text-overflow-dynamic-ellipsis">NOME CLIENTE <p-sortIcon [field]="'rvsNomeCliente'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsNomeCliente', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsEndereco'">
                  <span class="text-overflow-dynamic-ellipsis">ENDEREÇO <p-sortIcon [field]="'rvsEndereco'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsEndereco', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsNif'">
                  <span class="text-overflow-dynamic-ellipsis">NIF <p-sortIcon [field]="'rvsNif'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsNif', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsTipVinculacao'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO VINCULAÇÃO <p-sortIcon [field]="'rvsTipVinculacao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsTipVinculacao', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsCodExportador'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO EXPORTADOR <p-sortIcon
                      [field]="'rvsCodExportador'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsCodExportador', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsMoeda'">
                  <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'rvsMoeda'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsMoeda', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsDescServico'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO SERVIÇO<p-sortIcon [field]="'rvsDescServico'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsDescServico', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsCodNbs'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO NBS<p-sortIcon [field]="'rvsCodNbs'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsCodNbs', 'contains')">
              </th>
              <th class="text-right">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rvsValor'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR<p-sortIcon [field]="'rvsValor'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'rvsValor', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center">
                <p-tableCheckbox (click)="check(rowData.id, rowData)" [value]="rowData"
                  [disabled]="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL' || rowData.tipo === 'pp' || rowData.tipo === 'pr'">
                </p-tableCheckbox>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsNumProcesso}}">{{rowData.rvsNumProcesso }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.numOperacaoEmpresa}}">{{rowData.numOperacaoEmpresa }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsDtInicio}}">{{rowData.rvsDtInicio }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsDtConclusao}}">{{rowData.rvsDtConclusao }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsModPrestacao}}">{{rowData.rvsModPrestacao }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsCodCliente}}">{{rowData.rvsCodCliente }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsNomeCliente}}">{{rowData.rvsNomeCliente }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsEndereco}}">{{rowData.rvsEndereco }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rvsNif}}">{{rowData.rvsNif
                    }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsTipVinculacao}}">{{rowData.rvsTipVinculacao }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsCodExportador}}">{{rowData.rvsCodExportador }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rvsMoeda}}">{{rowData.rvsMoeda
                    }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rvsDescServico}}">{{rowData.rvsDescServico }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rvsCodNbs}}">{{rowData.rvsCodNbs
                    }}</span>
                </span>
              </td>
              <td class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rvsValor}}">{{rowData.rvsValor
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="16" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </mat-tab>

    <!-- Aba 'RP' -->
    <!--========================================================================================-->
    <mat-tab label="RP ({{ rpResults.length }})">
      <div class="box-content pt-2">
        <div id="findme" style="display: none;">RP</div>

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt3.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p-table class="table-system" #dt3 [columns]="colsrpResults" [value]="rpResults" [rows]="rows"
          [paginator]="true" selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          [style]="{'width':'100%'}" immutable="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="checkAll($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rpNumProcesso'">
                  <span class="text-overflow-dynamic-ellipsis">RAS SELECIONADO <p-sortIcon [field]="'rpNumProcesso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'rpNumProcesso', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rpCodProcesso'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO PROCESSO RP <p-sortIcon [field]="'rpCodProcesso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'rpCodProcesso', 'contains')">
              </th>
              <th class="text-center">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numOperacaoEmpresa'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO OPERAÇÃO <p-sortIcon
                      [field]="'numOperacaoEmpresa'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'numOperacaoEmpresa', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rpNumDocumento'">
                  <span class="text-overflow-dynamic-ellipsis">NUM DOCUMENTO <p-sortIcon [field]="'rpNumDocumento'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'rpNumDocumento', 'contains')">
              </th>
              <th class="text-center">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rpDtPagto'">
                  <span class="text-overflow-dynamic-ellipsis">DATA PAGTO <p-sortIcon [field]="'rpDtPagto'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'rpDtPagto', 'contains')">
              </th>
              <th class="text-right">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rpVlrPago'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR PAGO <p-sortIcon [field]="'rpVlrPago'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'rpVlrPago', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="check(rowData.id, rowData)" [value]="rowData"
                  [disabled]="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL' || rowData.tipo === 'pp' || rowData.tipo === 'pr'">
                </p-tableCheckbox>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rpNumProcesso}}">{{rowData.rpNumProcesso }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rpCodProcesso}}">{{rowData.rpCodProcesso }}</span>
                </span>
              </td>
              <td class="text-center">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.numOperacaoEmpresa}}">{{rowData.numOperacaoEmpresa }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rpNumDocumento}}">{{rowData.rpNumDocumento }}</span>
                </span>
              </td>
              <td class="text-center">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rpDtPagto}}">{{rowData.rpDtPagto
                    }}</span>
                </span>
              </td>
              <td class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rpVlrPago}}">{{rowData.rpVlrPago
                    }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="7" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </mat-tab>

    <!-- Aba 'RF' -->
    <!--========================================================================================-->
    <mat-tab label="RF ({{ rfResults.length }})">
      <div class="box-content pt-2">
        <div id="findme" style="display: none;">RF</div>

        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt4.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <p-table class="table-system" #dt4 [columns]="colsrfResults" [value]="rfResults" [rows]="rows"
          [paginator]="true" selectionMode="single" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
          [style]="{'width':'100%'}" immutable="false">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="checkAll($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rfNumProcesso'">
                  <span class="text-overflow-dynamic-ellipsis">RVS SELECIONADO <p-sortIcon [field]="'rfNumProcesso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt4.filter($event.target.value, 'rfNumProcesso', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rfCodProcesso'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO PROCESSO RF <p-sortIcon [field]="'rfCodProcesso'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt4.filter($event.target.value, 'rfCodProcesso', 'contains')">
              </th>
              <th class="text-center">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numOperacaoEmpresa'">
                  <span class="text-overflow-dynamic-ellipsis">NÚMERO OPERAÇÃO <p-sortIcon
                      [field]="'numOperacaoEmpresa'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt4.filter($event.target.value, 'numOperacaoEmpresa', 'contains')">
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rfNumNf'">
                  <span class="text-overflow-dynamic-ellipsis">NUM NF <p-sortIcon [field]="'rfNumNf'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt4.filter($event.target.value, 'rfNumNf', 'contains')">
              </th>
              <th class="text-center">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rfDtFaturamento'">
                  <span class="text-overflow-dynamic-ellipsis">DATA FAT <p-sortIcon [field]="'rfDtFaturamento'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt4.filter($event.target.value, 'rfDtFaturamento', 'contains')">
              </th>
              <th class="text-right">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rfVlrFaturado'">
                  <span class="text-overflow-dynamic-ellipsis">VALOR FATURADO <p-sortIcon [field]="'rfVlrFaturado'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt4.filter($event.target.value, 'rfVlrFaturado', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="check(rowData.id, rowData)" [value]="rowData"
                  [disabled]="rowData.status === 'BAIXADO' || rowData.status === 'BAIXA PARCIAL' || rowData.tipo === 'pp' || rowData.tipo === 'pr'">
                </p-tableCheckbox>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rfNumProcesso}}">{{rowData.rfNumProcesso }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rfCodProcesso}}">{{rowData.rfCodProcesso }}</span>
                </span>
              </td>
              <td class="text-center">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.numOperacaoEmpresa}}">{{rowData.numOperacaoEmpresa }}</span>
                </span>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rfNumNf}}">{{rowData.rfNumNf
                    }}</span>
                </span>
              </td>
              <td class="text-center">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rfDtFaturamento}}">{{rowData.rfDtFaturamento }}</span>
                </span>
              </td>
              <td class="text-right">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.rfVlrFaturado}}">{{rowData.rfVlrFaturado }}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="7" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </mat-tab>

  </mat-tab-group>

  <!--Inicio Modal modalRasRvs-->
  <p-dialog header="Retificar dados de RAS/RVS" [(visible)]="modalRasRvs" [style]="{width: '1000px'}" [modal]="true"
    styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

    <!-- modalRasRvs -->
    <div *ngIf="modalRasRvs">


      <!-- seus campos e controles vao aqui -->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DATA INICIO </label>
          <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
            [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030" [(ngModel)]="dataInicioEvento">
          </p-calendar>

        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DATA CONCLUSÃO </label>
          <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
            [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030" [(ngModel)]="dataConclusaoEvento">
          </p-calendar>

        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>MOEDA </label>
          <p-dropdown class="type-p-dropdown" appendTo="body" [filter]="true" placeholder="Selecione"
            [options]="opcoesMoeda" [(ngModel)]="moedaId"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>CÓDIGO NBS </label>
          <p-dropdown class="type-p-dropdown" appendTo="body" panelStyleClass="custom-width" [filter]="true"
            placeholder="Selecione" [options]="opcoesNbs" [(ngModel)]="nbsId"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>VALOR </label>
          <input class="form-control text-right" type="text" [(ngModel)]="valorRasRvs" />
        </div>
      </div>

      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">

        </div>
      </div>


    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalRasRvs=false;"><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary" (click)="rectifyRasRvsData()"><i class="fas fa-check"></i>
        Confirmar</button>
    </p-footer>
  </p-dialog>
  <!--Final Modal modalRasRvs-->

  <!--Inicio Modal modalRp-->
  <p-dialog header="Retificar dados de RP" [(visible)]="modalRp" [style]="{width: '1000px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">


    <!-- modalRp -->
    <div *ngIf="modalRp">


      <!-- seus campos e controles vao aqui -->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DATA PAGAMENTO </label>
          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
            [defaultDate]="defaultDate" yearRange="2010:2030" [(ngModel)]="dataPagamento">
          </p-calendar>

        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>VALOR </label>
          <input class="form-control text-right" type="text" [(ngModel)]="valorRp" />
        </div>
      </div>

      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">

        </div>
      </div>


    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalRp=false;"><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary" (click)="rectifyRpData()"><i class="fas fa-check"></i>
        Confirmar</button>
    </p-footer>
  </p-dialog>
  <!--Final Modal modalRp-->


  <!--Inicio Modal modalRf-->
  <p-dialog header="Retificar dados de RF" [(visible)]="modalRf" [style]="{width: '1000px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">


    <!-- modalRf -->
    <div *ngIf="modalRf">


      <!-- seus campos e controles vao aqui -->
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DATA FATURA </label>
          <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
            [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030" [(ngModel)]="dataFatura">
          </p-calendar>

        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>VALOR </label>
          <input class="form-control text-right" type="text" [(ngModel)]="valorRf" />
        </div>
      </div>

      <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
        <div class="ui-g-12">

        </div>
      </div>


    </div>

    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalRf=false;"><i class="fas fa-undo"></i>
        Fechar</button>
      <button type="button" class="btn btn-primary" (click)="rectifyRfData()"><i class="fas fa-check"></i>
        Confirmar</button>
    </p-footer>
  </p-dialog>
  <!--Final Modal modalRf-->

</mat-card>