<!-- MENSAGENS-->
<div class="ui-g" style="position: fixed; right: 25px; bottom: 30px; z-index: 3000">
  <div class="ui-g-12">
  </div>
</div>
<!--FIM MENSAGENS-->

<!--PADDING INICIAL DE TODA -->
<div style="padding: 1.5rem" class="pt-3">
  <div class="row">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Dashboard Orçamento
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal" [innerHTML]="formatarDataExtenso()"></small>
        </div>
      </h4>
    </div>
  </div>
  <!---Seleção do modelo de dashboard-->
  <div class="row" *ngIf="exibirOpcoesRelatorio">
    <div class="col">
      <div class="card-deck card-custom">
        <div class="card" *ngFor="let versao of listaVersoes">
          <div class="card-body">
            <span class="icon-card"><i class="fas fa-chart-bar"></i></span>
            <span class="info-card">
              <h5 class="card-title" (click)="getGerencial(versao, true); getMoeda()" matTooltip="{{ versao.versao }}"
                matTooltipPosition="above" style="width: 150px">
                <span class="overflow-ellipse">{{ versao.versao }}</span>
              </h5>
              <p class="card-text overflow-ellipse" matTooltip="{{ versao.tipo }} - {{ versao.descricao }}"
                matTooltipPosition="above" style="max-width: 150px; margin-bottom: 2px; display: inline-block;">
                <small class="text-muted">{{ versao.tipo }} - {{ versao.descricao }}</small>
              </p>
              <p class="card-text overflow-ellipse" style="width: 150px">
                <small class="text-muted">{{ versao.status }}</small>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Exibição do dashboard escolhido -->
  <div *ngIf="!exibirOpcoesRelatorio">
    <div class="row">
      <div class="col"></div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2" *ngIf="versao.consolidar">
        <label>Empresas</label>
        <p-multiSelect class="type-p-mult" [options]="listEmpresas" [(ngModel)]="empresasSelecionadas"
          defaultLabel="Selecione empresas" selectedItemsLabel="{0} empresas selecionadas" [maxSelectedLabels]="1"
          (onChange)="getOrcaOptions(); changeEmpresaSelecionada()">
        </p-multiSelect>
      </div>
      <div class="form-group col-sm-2">
        <label>{{levels['1'][0]}}</label>
        <p-multiSelect class="type-p-mult" [options]="optionsOrcaKey1" [(ngModel)]="selectedKey1"
          defaultLabel="Selecione {{levels['1'][0]}}" selectedItemsLabel="{0} {{levels['1'][0]}}"
          (onChange)="getOrcaOptions(keyLevel!==1&&levels['2'][1],2)" [maxSelectedLabels]="1">
        </p-multiSelect>
      </div>
      <div *ngIf="keyLevel!==1" class="form-group col-sm-2">
        <label>{{levels['2'][0]}}</label>
        <p-multiSelect class="type-p-mult" [options]="optionsOrcaKey2" [(ngModel)]="selectedKey2"
          defaultLabel="Selecione {{levels['2'][0]}}" selectedItemsLabel="{0} {{levels['2'][0]}}"
          (onChange)="getOrcaOptions(keyLevel===3&&levels['3'][1],3)" [maxSelectedLabels]="1">
        </p-multiSelect>
      </div>
      <div *ngIf="keyLevel===3" class="form-group col-sm-2">
        <label>{{levels['3'][0]}}</label>
        <p-multiSelect class="type-p-mult" [options]="optionsOrcaKey3" [(ngModel)]="selectedKey3"
          defaultLabel="Selecione {{levels['3'][0]}}" selectedItemsLabel="{0} {{levels['3'][0]}}"
          [maxSelectedLabels]="1">
        </p-multiSelect>
      </div>
      <div class="form-group col-sm-1 mb-1">
        <label>Moeda</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione moeda" [options]="opcoesMoedas"
          [(ngModel)]="moeda"></p-dropdown>
      </div>
      <div class="form-group col-sm-1 mb-1" *ngIf="moeda > 1">
        <label class="d-block mt-2" style="visibility: hidden">DO NOT REMOVE</label>
        <p-checkbox [binary]="true" [(ngModel)]="utilizarTxDif"></p-checkbox>
        <label style="padding: 0 5px;">Simular Taxa?</label>
      </div>
      <div class="form-group col-sm-1 mb-1" *ngIf="moeda > 1 && utilizarTxDif">
        <label>Taxa</label>
        <input style="min-height: 34px" class="form-control text-right" type="number" [(ngModel)]="txMoeda" />
      </div>
      <div class="form-group col-sm-1" style="min-width: 125px">
        <label class="d-block" style="visibility: hidden; margin-top: 6px">DO NOT REMOVE</label>
        <button type="button" class="btn btn-info" (click)="getGerencial(versao, false)">
          <i class="fas fa-refresh"></i> Atualizar
        </button>
      </div>
    </div>
    <!-- Dashboard Principal -->
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box" [ngClass]="fullMainTable ? 'box-fullscreen' : ''">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu mb-3">
                <li class="list-inline-item" [ngClass]="{ active: statusLink1 }">
                  <a (click)="showTypeDashboard('LINK_1');table.reset()">{{opcaoSelecionada}}</a>
                </li>
                <li class="list-inline-item" [ngClass]="{ active: statusLink2 }">
                  <a (click)="showTypeDashboard('LINK_2');table.reset()">Variação</a>
                </li>
              </ul>
              <div class="actions-card">

                <!-- <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(false, 'orcado')" [ngClass]="{ active: !isRealizadoVisao }">
                    Orçado
                  </button>
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(true, 'orcado')" [ngClass]="{ active: isRealizadoVisao }">
                    Realizado
                  </button>
                </div> -->
                <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(false, 'concise')" [ngClass]="{ active: !isConciseVision }">
                    Detalhado
                  </button>
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(true, 'concise')" [ngClass]="{ active: isConciseVision }">
                    Resumido
                  </button>
                </div>

                <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(false, 'trim')" [ngClass]="{ active: !visaoTrim }">
                    Mês
                  </button>
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(true, 'trim')" [ngClass]="{ active: visaoTrim }">
                    Trimestre
                  </button>
                </div>

                <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(false, 'accumulated')" [ngClass]="{ active: !isAccumulatedVision }">
                    ANO
                  </button>
                  <button type="button" class="btn btn-sm btn-sm btn-outline-primary"
                    (click)="changeVision(true, 'accumulated')" [ngClass]="{ active: isAccumulatedVision }">
                    YTD
                  </button>
                </div>
                <div class="btn-group btn-group-sm btn-group-custom mr-2" role="group">
                  <app-toolbar [value]="toolbarMain"></app-toolbar>
                  <!--<button *ngIf="!fullMainTable" type="button" (click)="backToOptions()" class="btnCustom-default"
                    matTooltip="Voltar para opções" matTooltipPosition="above">
                    <span class="material-icons">arrow_back_ios_new</span>
                    <div>Voltar</div>
                  </button>
                  <button type="button" class="btnCustom-default" (click)="export()" matTooltip="Exportar Excel"
                    matTooltipPosition="above">
                    <span class="material-icons">upload_file</span>
                    <div>Exportar</div>
                  </button>
                  <button type="button" (click)="salvarOrcamento()" class="btnCustom-green"
                    matTooltip="Gravar Orçamento" matTooltipPosition="above">
                    <span class="material-icons">save</span>
                    <div>Salvar</div>
                  </button>-->
                  <!-- vir -->
                  <button type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu"
                    class="btnCustom-default btn-trigger" matTooltip="Mais opções">
                    <span class="material-icons">more_vert</span>
                  </button>
                  <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">

                        <p-checkbox class="mr-1" [(ngModel)]="!hideLines" [binary]="true"
                          (click)="hideLines=!hideLines; esconderLinhas()"  (change)="changeLinesState()" inputId="hdln"></p-checkbox>
                        <label for="hdln">Exibir contas nulas</label>

                    </div>
                  </mat-menu>
                  <button type="button" class="btnCustom-default" matTooltip="Fullscreen" matTooltipPosition="above"
                    (click)="fullScreen('MainTable')">
                    <span *ngIf="!fullMainTable" class="material-icons full-icon">fullscreen</span>
                    <span *ngIf="fullMainTable" class="material-icons full-icon">fullscreen_exit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Dashboard orçamento -->
          <div class="box-content" [ngStyle]="statusLink1 ? { display: 'block',height:'100%' } : { display: 'none' }"
            [ngClass]="{ 'fade-in': 'statusLink1' }">
            <!-- Visão por mês -->
            <p-table *ngIf="!visaoTrim" #dt class="table-system" [value]="tableValuesOrca" [columns]="colOrca"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="[5, 10, 15, 20, 50, 100]"
              selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%">
                    {{ moedaSelecionada() }}
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'descr_cta','contains')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-center" *ngFor="let mes of colmeses" style="width: 6%; padding: 6px 6px">
                    <span class="text-overflow-dynamic-container"
                      matTooltip="{{ mes[1] }} {{ isRealizadoVisao ? '(R)' : mes[3] ? '(' + mes[3] + ')' : ''}}">
                      <span class="text-overflow-dynamic-ellipsis">
                        {{ mes[1] }} {{ isRealizadoVisao ? '(R)' : mes[3] ? '(' + mes[3] + ')' : ''}}
                      </span>
                    </span>
                    <span id="txtWheight">{{ moedaVisu != 1 ? 'tx: ' + mes[2] : ''}}</span>
                    <input pInputText type="text" (input)="dt.filter($event.target.value,mes[0] + 'Total','startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-center" style="width: 10%">
                    TOTAL
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'total', 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td style="padding: 0px 10px !important;" [ngClass]="chooseNivelClass(rowData)">
                    <span matTooltip="{{ rowData.descr_cta }}" class="overflow-ellipse text-left"
                      style="align-items: center;justify-content: space-between;display: flex;">
                      <div *ngIf="rowData.ind_cta === 'A'" (click)="openDetalhe(rowData.cod_cta, rowData.descr_cta)"
                        style="display: block;max-width: 90%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: left;">
                        {{ rowData.descr_cta }}
                      </div>
                      <button *ngIf="rowData.ind_cta === 'A'" type="button"
                        (click)="openDetalhe(rowData.cod_cta, rowData.descr_cta,true)" style="padding: 0 !important;"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 14px">search</span>
                      </button>
                      <div *ngIf="rowData.ind_cta != 'A'" class="strong">
                        {{ rowData.descr_cta }}
                      </div>
                    </span>
                  </td>
                  <td *ngFor="let mes of colmeses" [ngClass]="{ 'highlight-line': rowData.ind_cta != 'A' }"
                    style="padding: 0 !important;">
                    <span
                      matTooltip="{{ formatValue(rowData, rowData[mes[0] + 'Total']) | number: '1.2-2':'pt-BR' }}{{rowData.ind_cta == 'T' && rowData.is_porcentagem? '%' : ''}}"
                      class="overflow-ellipse text-right">
                      <div *ngIf="rowData.ind_cta === 'A'" (click)="openModalAjuste(rowData.cod_cta,mes[0])"
                        class="valueWrapper">
                        <span *ngIf="checkEdition(rowData.cod_cta, mes[0])"
                          class="material-icons referenceIcon">signal_cellular_4_bar</span>
                        {{ (rowData.inverter_sinal || (rowData.ind_rd === 'D' && rowData.ind_cta !== 'A') ?
                        -rowData[mes[0] + "Total"] : rowData[mes[0] + "Total"]) | number:
                        "1.2-2":"pt-BR" }}
                      </div>
                      <div *ngIf="rowData.ind_cta != 'A'" class="strong valueWrapper">
                        {{ formatValue(rowData, rowData[mes[0] + "Total"]) | number: "1.2-2":"pt-BR" }}{{rowData.ind_cta
                        == 'T' && rowData.is_porcentagem? "%" : ''}}
                      </div>
                    </span>
                  </td>
                  <td class="strong" [ngClass]="{ 'highlight-line': rowData.ind_cta != 'A' }">
                    <span
                      matTooltip="{{ formatValue(rowData, rowData.total) | number: '1.2-2':'pt-BR' }}{{rowData.ind_cta == 'T' && rowData.is_porcentagem? '%' : ''}}"
                      class="overflow-ellipse text-right">
                      {{ formatValue(rowData, rowData.total) | number: "1.2-2":"pt-BR" }}{{rowData.ind_cta == 'T' &&
                      rowData.is_porcentagem? "%" : ''}}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- Visão por trimestre -->
            <p-table *ngIf="visaoTrim" #dt class="table-system" [value]="tableValuesOrca" [columns]="colOrcaTrim"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="[5, 10, 15, 20, 50, 100]"
              selectionMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" style="width: 20%">
                    {{ moedaSelecionada() }}
                    <input pInputText type="text" (input)="dt.filter($event.target.value,'descr_cta','contains')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-center" *ngFor="let trim of coltrimestre" style="width: 16%">
                    {{ trim[1] }}
                    <input pInputText type="text"
                      (input)="dt.filter($event.target.value, trim[0] + 'Total', 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                  <th class="text-center" style="width: 16%">
                    TOTAL
                    <input pInputText type="text" (input)="dt.filter($event.target.value, 'total', 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td style="padding: 0px 10px !important;" [ngClass]="chooseNivelClass(rowData)">
                    <span matTooltip="{{ rowData.descr_cta }}" class="overflow-ellipse text-left"
                      style="align-items: center;justify-content: space-between;display: flex;">
                      <div *ngIf="rowData.ind_cta === 'A'" (click)="openDetalhe(rowData.cod_cta, rowData.descr_cta)"
                        style="display: block;max-width: 90%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: left;">
                        {{ rowData.descr_cta }}
                      </div>

                      <button *ngIf="rowData.ind_cta === 'A'" type="button"
                        (click)="openDetalhe(rowData.cod_cta, rowData.descr_cta,true)" style="padding: 0 !important;"
                        class="btn btn-xs btn-link btn-full" matTooltip="Visualizar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 10px">search</span>
                      </button>

                      <div *ngIf="rowData.ind_cta != 'A'" class="strong">
                        {{ rowData.descr_cta }}
                      </div>
                    </span>
                  </td>
                  <td *ngFor="let trim of coltrimestre" [ngClass]="{ 'highlight-line': rowData.ind_cta != 'A' }">
                    <span
                      matTooltip="{{ formatValue(rowData, rowData[trim[0] + 'Total']) | number: '1.2-2':'pt-BR' }}{{rowData.ind_cta == 'T' && rowData.is_porcentagem? '%' : ''}}"
                      class="overflow-ellipse text-right" [ngClass]="{ strong: rowData.ind_cta != 'A' }">
                      {{ formatValue(rowData, rowData[trim[0] + 'Total']) | number: "1.2-2":"pt-BR" }}{{rowData.ind_cta
                      == 'T' && rowData.is_porcentagem? '%' : ''}}
                    </span>
                  </td>
                  <td [ngClass]="{ 'highlight-line': rowData.ind_cta != 'A' }">
                    <span
                      matTooltip="{{ formatValue(rowData, rowData.total) | number: '1.2-2':'pt-BR' }}{{rowData.ind_cta == 'T' && rowData.is_porcentagem? '%' : ''}}"
                      class="overflow-ellipse text-right strong">
                      {{ formatValue(rowData, rowData.total) | number: "1.2-2":"pt-BR" }}{{rowData.ind_cta == 'T' &&
                      rowData.is_porcentagem? '%' : ''}}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <!-- Dashboard variação -->
          <div class="box-content" [ngStyle]="statusLink2 ? { display: 'block',height:'100%' } : { display: 'none' }"
            [ngClass]="{ 'fade-in': 'statusLink2' }">
            <!-- Visão por mês -->
            <p-table *ngIf="!visaoTrim" #dtt class="table-system" [value]="tableValuesVar" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="[5, 10, 15, 20, 50, 100]"
              selectionMode="single" [scrollable]="true" [style]="{ width: '100%' }" scrollWidth="300px"
              frozenWidth="300px" [frozenColumns]="frozenCols">
              <!-- vir -->
              <ng-template pTemplate="frozenheader">
                <tr>
                  <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab;color:#efefef">&nbsp;
                  </th>
                </tr>
                <tr>
                  <th class="text-left" [style]="'width: 300px; border-right: 0.3rem solid #ababab; ' + ((moedaVisu !== 1) ? 'height: 65px;' : '')">
                    {{ moedaSelecionada() }}
                    <input pInputText type="text" (input)="dtt.filter($event.target.value,'descr_cta','contains')"
                      class="form-control form-control-sm" />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenbody" let-rowData>
                <tr>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td style="width: 300px; padding: 0px 10px !important;border-right: 0.2rem solid #ababab"
                    [ngClass]="chooseNivelClass(rowData)">
                    <span matTooltip="{{ rowData.descr_cta }}" class="overflow-ellipse text-left"
                      style="align-items: center;justify-content: space-between;display: flex;">
                      <div *ngIf="rowData.ind_cta === 'A'"
                        (click)="openDetalheVisaoReal(rowData.cod_cta, rowData.descr_cta)"
                        style="display: block;max-width: 90%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: left;">
                        {{ rowData[frozenCols.field] }}
                      </div>
                      <button *ngIf="rowData.ind_cta === 'A'" type="button"
                        (click)="openDetalheVisaoReal(rowData.cod_cta, rowData.descr_cta,true)"
                        style="padding: 0 !important;" class="btn btn-xs btn-link btn-full" matTooltip="Visualizar"
                        matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 10px">search</span>
                      </button>
                      <div *ngIf="rowData.ind_cta != 'A'" class="strong">
                        {{ rowData.descr_cta }}
                      </div>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" *ngFor="let mes of colmeses" style="width: 400px"
                    [ngStyle]="{'border-left':!mes[0].includes('Jan') ?  '0.2rem solid #ababab':''}" colspan="4">
                    {{ mes[1] }}
                  </th>
                  <th class="text-center" style="width: 400px; border-left: 0.2rem solid #ababab" colspan="4">
                    TOTAL
                  </th>
                </tr>
                <tr>
                  <th class="text-center" *ngFor="let col of colsvar" [style]="'width: 100px; ' + ((moedaVisu !== 1) ? 'height: 65px;' : '')"
                    [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('Jan') ?  '0.2rem solid #ababab':''}">
                    <span [innerHTML]="varLabels(col)"></span>
                    <input pInputText type="text" (input)="dtt.filter($event.target.value, col[0], 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td *ngFor="let col of colsvar" style="width: 100px;padding: 0 !important;"
                    (click)="openModalComentarios(rowData, col)"
                    [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('Jan')?  '0.2rem solid #ababab':''}"
                    [ngClass]="{'highlight-line strong': rowData.ind_cta != 'A'}">
                    <span
                      matTooltip="{{ rowData.ind_cta == 'N'?'':col[1].includes('%') || (rowData.ind_cta === 'T' && rowData.is_porcentagem) ? inverterSinal(col, rowData) : inverterSinal(col, rowData) | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right valueWrapper" [ngClass]="checkNegative(rowData, col)">
                      <span *ngIf="col[1]=='Var.' && checkComments(rowData)" class="material-icons referenceIcon"
                        style="font-size: 10px"> signal_cellular_4_bar</span>
                      {{ rowData.ind_cta == 'N'?'':col[1].includes('%') || (rowData.ind_cta === 'T' &&
                      rowData.is_porcentagem)? inverterSinal(col, rowData) : inverterSinal(col, rowData) | number:
                      '1.2-2':'pt-BR' }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            <!-- Visão trimestral -->
            <p-table *ngIf="visaoTrim" #dtt class="table-system" [value]="tableValuesVar" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="[5, 10, 15, 20, 50, 100]"
              selectionMode="single" [scrollable]="true" [style]="{ width: '100%' }" scrollWidth="300px"
              frozenWidth="300px" [frozenColumns]="frozenCols">
              <ng-template pTemplate="frozenheader">
                <tr>
                  <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab;color:#efefef">&nbsp;
                  </th>
                </tr>
                <tr>
                  <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab">
                    {{ moedaSelecionada() }}
                    <input pInputText type="text" (input)="dtt.filter($event.target.value,'descr_cta','contains')"
                      class="form-control form-control-sm" />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenbody" let-rowData>
                <tr>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td style="width: 300px; padding: 0px 10px !important;border-right: 0.2rem solid #ababab"
                    [ngClass]="chooseNivelClass(rowData)">
                    <span matTooltip="{{ rowData.descr_cta }}" class="overflow-ellipse text-left"
                      style="align-items: center;justify-content: space-between;display: flex;">
                      <div *ngIf="rowData.ind_cta === 'A'"
                        (click)="openDetalheVisaoReal(rowData.cod_cta, rowData.descr_cta)"
                        style="display: block;max-width: 90%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;text-align: left;">
                        {{ rowData[frozenCols.field] }}
                      </div>
                      <button *ngIf="rowData.ind_cta === 'A'" type="button"
                        (click)="openDetalheVisaoReal(rowData.cod_cta, rowData.descr_cta,true)"
                        style="padding: 0 !important;" class="btn btn-xs btn-link btn-full" matTooltip="Visualizar"
                        matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 10px">search</span>
                      </button>
                      <div *ngIf="rowData.ind_cta != 'A'" class="strong">
                        {{ rowData.descr_cta }}
                      </div>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" *ngFor="let trim of coltrimestre" style="width: 400px;"
                    [ngStyle]="{'border-left':!trim[0].includes('q1')? '0.2rem solid #ababab':''}" colspan="4">
                    {{ trim[1] }}
                  </th>
                  <th class="text-center" style="width: 400px; border-left: 0.2rem solid #ababab" colspan="4">
                    TOTAL
                  </th>
                </tr>
                <tr>
                  <th class="text-center" *ngFor="let col of colsvartrim" style="width: 100px"
                    [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('q1')? '0.2rem solid #ababab':''}">
                    {{ varLabels(col,true) }}
                    <input pInputText type="text"
                      (input)="dtt.filter($event.target.value, col[0] + 'Total', 'startsWith')"
                      class="form-control form-control-sm" />
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td *ngFor="let col of colsvartrim" style="width: 100px;padding: 0 !important"
                    [ngClass]="{'highlight-line strong': rowData.ind_cta != 'A'}"
                    [ngStyle]="{'border-left':col[1].includes('Orc')&& !col[0].includes('q1') ?  '0.2rem solid #ababab':''}">
                    <span
                      matTooltip="{{ rowData.ind_cta == 'N'?'':col[1].includes('%') || (rowData.ind_cta === 'T' && rowData.is_porcentagem) ? inverterSinal(col, rowData) : inverterSinal(col, rowData) | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right valueWrapper" [ngClass]="checkNegative(rowData,col)">
                      {{ rowData.ind_cta == 'N'?'':col[1].includes('%') || (rowData.ind_cta === 'T' &&
                      rowData.is_porcentagem)? inverterSinal(col, rowData) : inverterSinal(col, rowData) | number:
                      '1.2-2':'pt-BR' }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Card de detalhes -->
    <div class="row" *ngIf="(edicaoCta && !statusLink2) || (visaoReal && statusLink2)">
      <div class="col-sm-12" style="margin-top: 10px">
        <div class="shadow-box" style="border-radius: 10px; padding: 4px">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">Detalhe da conta {{ ctaSelecionada }}</h3>
              <ul *ngIf="editOptions.length > 1" class="list-inline filter-menu mb-3">
                <li *ngFor="let company of editOptions" class="list-inline-item"
                  [ngClass]="{ active : selectedCompany === company }">
                  <a (click)="changeSelectedCompany(company)">{{ setDescription(company,'comp') }}</a>
                </li>
              </ul>
              <div class="actions-card" style="display: flex;padding-right: 5px;">
                <app-toolbar [value]="toolbarDetails"></app-toolbar>
                <!--<button *ngIf="edicaoCta && !statusLink2 && !visaoTrim && !isAccumulatedVision && !isRealizadoVisao"
                  type="button" (click)="changeEditionState()" class="btnCustom-default" matTooltip="Editar"
                  matTooltipPosition="above"
                  [disabled]="!editavel || versao.status !== 'Aberto' || !isSelectedCtaEditavel">
                  <span class="material-icons">edit</span>
                  <div>Editar</div>
                </button>
                <button *ngIf="edicaoCta && !statusLink2 && !visaoTrim && !isAccumulatedVision && !isRealizadoVisao"
                  type="button" (click)="addNewRow()" class="btnCustom-default" matTooltip="Nova linha"
                  matTooltipPosition="above"
                  [disabled]="!editavel || versao.status !== 'Aberto' || !isSelectedCtaEditavel">
                  <span class="material-icons">add</span>
                  <div>Adicionar</div>
                </button>
                <button *ngIf="!statusLink2 && !visaoTrim && !isAccumulatedVision" type="button"
                  (click)="salvarEdicao()" class="btnCustom-green" matTooltip="Salvar edição" matTooltipPosition="above"
                  [disabled]="!editavel || versao.status !== 'Aberto' || !isSelectedCtaEditavel">
                  <span class="material-icons">save</span>
                  <div>Salvar</div>
                </button>
                <button type="button" class="btnCustom-default" (click)="closeAjustes()" matTooltip="Ocultar Card"
                  matTooltipPosition="above">
                  <span class="material-icons">close</span>
                </button>-->
              </div>
            </div>
          </div>
          <!-- Detalhe principal mensal-->
          <div *ngIf="edicaoCta && !statusLink2 && !visaoTrim" class="box-content"
            [@changeDivSize]="currentStateEdicao">
            <p-table class="table-system" [value]="tableEdicao" selectionMode="single"
              (onEditComplete)="calcularTotalLinhaDetalhe($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">{{levels['1'][1]}}</th>
                  <th *ngIf="keyLevel!==1" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
                    {{levels['2'][1]}}
                  </th>
                  <th *ngIf="keyLevel===3" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
                    {{levels['3'][1]}}
                  </th>
                  <th class="text-center" *ngFor="let mes of colmeses" style="width: 6%">
                    <span class="text-overflow-dynamic-container"
                      matTooltip="{{ mes[1] }} {{ isRealizadoVisao ? '(R)' : mes[3] ? '(' + mes[3] + ')' : ''}}">
                      <span class="text-overflow-dynamic-ellipsis">
                        {{ mes[1] }} {{ isRealizadoVisao ? '(R)' : mes[3] ? '(' + mes[3] + ')' : ''}}
                      </span>
                    </span>
                  </th>
                  <th class="text-center" style="width: 6%">TOTAL</th>
                  <th *ngIf="!isAccumulatedVision" class="text-center" style="width: 4%">ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="rowData.fromAjuste || rowData.fromMov || !editionState || isForecast"
                      matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key1 }}
                    </span>
                    <p-dropdown *ngIf="!rowData.fromAjuste && !rowData.fromMov && editionState && !isForecast"
                      class="type-p-dropdown" [filter]="true" placeholder="Selecione ccus"
                      [options]="optionsKey1[selectedCompany]" [(ngModel)]="rowData.key1_id"
                      (onChange)="updateEditValue(rowData.line_id);getKeyOptions(levels['2'][1],rowData.descr_key1,rowData.line_id,keyLevel==1||isMain(2))">
                    </p-dropdown>
                  </td>
                  <td *ngIf="keyLevel!==1"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="rowData.fromAjuste || rowData.fromMov || !editionState || isForecast"
                      matTooltip="{{ rowData.descr_key2 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key2 }}
                    </span>
                    <p-dropdown *ngIf="!rowData.fromAjuste && !rowData.fromMov && editionState && !isForecast "
                      class="type-p-dropdown" [filter]="true" placeholder="Selecione proj"
                      [options]="isMain(2)?optionsKey2[selectedCompany]:rowData.options" [(ngModel)]="rowData.key2_id"
                      (onChange)="updateEditValue(rowData.line_id);getKeyOptions(levels['3'][1],rowData.descr_key2,rowData.line_id,keyLevel==2||isMain(3))">
                    </p-dropdown>
                  </td>
                  <td *ngIf="keyLevel===3"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="rowData.fromAjuste || rowData.fromMov || !editionState || isForecast"
                      matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key3}}
                    </span>
                    <p-dropdown *ngIf="!rowData.fromAjuste && !rowData.fromMov && editionState && !isForecast"
                      class="type-p-dropdown" [filter]="true" placeholder="Selecione proj"
                      [options]="isMain(3)?optionsKey3[selectedCompany]:rowData.options" [(ngModel)]="rowData.key3_id"
                      (onChange)="updateEditValue(rowData.line_id)">
                    </p-dropdown>
                  </td>
                  <td *ngFor="let mes of colmeses" pEditableColumn="{{ tableEdicao.indexOf(rowData) }}"
                    class="text-right" style="padding: 0 !important;"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <div class="valueWrapper">
                      <span *ngIf="checkDetEdition(rowData.line_id, mes[0])"
                        class="material-icons referenceIconDet">signal_cellular_4_bar</span>
                      <p-cellEditor
                        *ngIf="!rowData.fromAjuste && !rowData.fromMov && !isAccumulatedVision && editionState && !checkForecast(mes[0])">
                        <ng-template pTemplate="input" class="text-right">
                          <input type="number" style="width: 70px;" [(ngModel)]="rowData[mes[0]]" (focus)="checkInput($event)" />
                        </ng-template>
                        <ng-template pTemplate="output" class="text-right">
                          {{ rowData[mes[0]] | number: "1.2-2":"pt-BR" }}
                        </ng-template>
                      </p-cellEditor>
                      <span
                        *ngIf="rowData.fromAjuste || rowData.fromMov || isAccumulatedVision || !editionState || checkForecast(mes[0])"
                        matTooltip="{{ rowData[mes[0]] | number: '1.2-2':'pt-BR' }}"
                        class="overflow-ellipse text-right">
                        {{ rowData[mes[0]] | number: "1.2-2":"pt-BR" }}
                      </span>
                    </div>
                  </td>
                  <td class="text-right strong"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.total | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right">
                      {{ rowData.total | number: "1.2-2":"pt-BR" }}
                    </span>
                  </td>
                  <td *ngIf="!isAccumulatedVision"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
                    style="width: 50px;">
                    <div style="display: flex;justify-content: center;">
                      <button
                        *ngIf="edicaoCta && !statusLink2 && !visaoTrim && !rowData.fromAjuste && !isAccumulatedVision && !rowData.isDestiny"
                        type="button" style="padding: 0 !important;"
                        (click)="openMovModal(rowData.line_id,rowData.fromMov)" class="btn btn-xs btn-link btn-full"
                        matTooltip="Movimentar" matTooltipPosition="above"
                        [disabled]="!editavel || versao.status !== 'Aberto' || (!rowData.fromAjuste && isRealizadoVisao) || rowData.blockActions || !isSelectedCtaEditavel">
                        <span class="material-icons" style="font-size: 22px">swap_vert</span>
                      </button>
                      <button *ngIf="!statusLink2 && !rowData.isDestiny && !isAccumulatedVision" type="button"
                        style="padding: 0 !important;"
                        (click)="rowData.fromAjuste?deletarAjuste(rowData,true):deletSelectedDet(rowData.line_id,rowData.fromMov)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha" matTooltipPosition="above"
                        [disabled]="!editavel || versao.status !== 'Aberto' || (!rowData.fromAjuste && isRealizadoVisao) || rowData.blockActions || !isSelectedCtaEditavel">
                        <span class="material-icons" style="font-size: 18px">delete</span>
                      </button>
                      <button *ngIf="rowData.isDestiny" type="button" style="padding: 0 !important;"
                        (click)="openMovModal(rowData.line_id,rowData.fromMov,rowData.isDestiny)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Detalhes" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 19px">info</span>
                      </button>
                    </div>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td *ngIf="keyLevel!==1" class="text-center"></td>
                  <td *ngIf="keyLevel===3" class="text-center"></td>
                  <td class="text-center">TOTAL:</td>
                  <td class="text-right" *ngFor="let mes of colmeses">
                    {{ calcTotalEdit(tableEdicao, mes[0]) | number: "1.2-2":"pt-BR" }}
                  </td>
                  <td class="text-right">
                    {{ calcTotalEdit(tableEdicao, "total") | number: "1.2-2":"pt-BR" }}
                  </td>
                  <td *ngIf="!isAccumulatedVision"></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <!-- Detalhe principal trimestral -->
          <div *ngIf="edicaoCta && !statusLink2 && visaoTrim" class="box-content" [@changeDivSize]="currentStateEdicao">
            <p-table class="table-system" [value]="tableEdicao" selectionMode="single"
              (onEditComplete)="calcularTotalLinhaDetalhe($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">{{levels['1'][1]}}</th>
                  <th *ngIf="keyLevel!==1" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
                    {{levels['2'][1]}}
                  </th>
                  <th *ngIf="keyLevel===3" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
                    {{levels['3'][1]}}
                  </th>
                  <th class="text-center" *ngFor="let trim of coltrimestre" style="width: 16%">
                    {{ trim[1] }}
                  </th>
                  <th class="text-center" style="width: 16%">TOTAL</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
                  <td [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="rowData.descr_key1 !== ''" matTooltip="{{ rowData.descr_key1 }}"
                      class="overflow-ellipse text-left">
                      {{ rowData.descr_key1 }}
                    </span>
                  </td>
                  <td *ngIf="keyLevel!==1"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="rowData.descr_key2 !== ''" matTooltip="{{ rowData.descr_key2 }}"
                      class="overflow-ellipse text-left">
                      {{ rowData.descr_key2 }}
                    </span>
                  </td>
                  <td *ngIf="keyLevel===3"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="rowData.descr_key3 !== ''" matTooltip="{{ rowData.descr_key3 }}"
                      class="overflow-ellipse text-left">
                      {{ rowData.descr_key3 }}
                    </span>
                  </td>
                  <td *ngFor="let trim of coltrimestre" pEditableColumn="{{ tableEdicao.indexOf(rowData) }}"
                    class="text-right"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span *ngIf="!rowData.fromAjuste" matTooltip="{{ rowData[trim[0]] | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right">
                      {{ rowData[trim[0]] | number: "1.2-2":"pt-BR" }}
                    </span>
                    <span *ngIf="rowData.fromAjuste" matTooltip="{{ rowData[trim[0]] | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right">
                      {{ rowData[trim[0]] | number: "1.2-2":"pt-BR" }}
                    </span>
                  </td>
                  <td class="text-right strong"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.total | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right">
                      {{ rowData.total | number: "1.2-2":"pt-BR" }}
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td *ngIf="keyLevel!==1" class="text-center"></td>
                  <td *ngIf="keyLevel===3" class="text-center"></td>
                  <td class="text-center">TOTAL:</td>
                  <td class="text-right" *ngFor="let trim of coltrimestre">
                    {{ calcTotalEdit(tableEdicao, trim[0]) | number: "1.2-2":"pt-BR" }}
                  </td>
                  <td class="text-right">
                    {{ calcTotalEdit(tableEdicao, "total") | number: "1.2-2":"pt-BR" }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <!-- Detalhe variação mensal-->
          <div *ngIf="visaoReal && statusLink2 && !visaoTrim" class="box-content" [@changeDivSize]="currentStateEdicao">
            <p-table class="table-system" [value]="tableVisaoReal" selectionMode="single" [scrollable]="true"
              [style]="{ width: '100%' }" scrollWidth="300px" frozenWidth="300px" [frozenColumns]="frozenColsDet">
              <ng-template pTemplate="frozenheader">
                <!-- vir -->
                <tr>
                  <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab;color:#efefef"
                    [attr.colspan]="keyLevel">&nbsp;</th>
                </tr>
                <tr>
                  <th class="text-left" [ngStyle]="keyLevel==1 && {'border-right':  '0.3rem solid #ababab'}">
                    {{levels['1'][1]}}
                  </th>
                  <th *ngIf="keyLevel!==1" class="text-left"
                    [ngStyle]="keyLevel==2 && {'border-right':  '0.3rem solid #ababab'}">
                    {{levels['2'][1]}}
                  </th>
                  <th *ngIf="keyLevel===3" class="text-left"
                    [ngStyle]="keyLevel==3 && {'border-right':  '0.3rem solid #ababab'}">
                    {{levels['3'][1]}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenbody" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;">
                  <td class="text-left"
                    [ngStyle]="{'border-right': keyLevel==1 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key1 }}
                    </span>
                  </td>
                  <td *ngIf="keyLevel!==1" class="text-left"
                    [ngStyle]="{'border-right': keyLevel==2 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key2 }}
                    </span>
                  </td>
                  <td *ngIf="keyLevel==3" class="text-left"
                    [ngStyle]="{'border-right': keyLevel==3 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key3 }}
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenfooter">
                <tr>
                  <td class="text-center" style="width: 300px;border-right:0.3rem solid #ababab">TOTAL:</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" *ngFor="let mes of colmeses" style="width: 400px"
                    [ngStyle]="{'border-left':!mes[0].includes('Jan') ? '0.2rem solid #ababab':''}" colspan="4">
                    {{ mes[1] }}</th>
                  <th class="text-center" style="width: 400px; border-left: 0.2rem solid #ababab" colspan="4">TOTAL</th>
                </tr>
                <tr>

                  <th class="text-center" *ngFor="let col of colsvar" style="width: 100px"
                    [ngStyle]="{'border-left':col[1].includes('Orc') &&!col[0].includes('Jan')?  '0.2rem solid #ababab':''}">
                    {{ varLabels(col) }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px;">

                  <td *ngFor="let col of colsvar" style="width: 100px"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
                    [ngStyle]="{'border-left':col[1].includes('Orc') &&!col[0].includes('Jan')?'0.2rem solid #ababab':''}">
                    <span
                      matTooltip="{{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right" [ngClass]="checkNegative(rowData,col)">
                      {{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR'
                      }}
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td style="width: 100px" class="text-right" *ngFor="let col of colsvar"
                    [ngStyle]="{'border-left':col[1].includes('Orc')&&!col[0].includes('Jan') ?  '0.2rem solid #ababab':''}">
                    {{col[1].includes('%')?calcTotalEdit(tableVisaoReal, col[0],true):calcTotalEdit(tableVisaoReal,
                    col[0]) | number: "1.2-2":"pt-BR"}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <!-- Detalhe variação trimestral-->
          <!-- vir -->
          <div *ngIf="visaoReal && statusLink2 && visaoTrim" class="box-content" [@changeDivSize]="currentStateEdicao">
            <p-table class="table-system" [value]="tableVisaoReal" selectionMode="single" [scrollable]="true"
              [style]="{ width: '100%' }" scrollWidth="300px" frozenWidth="300px" [frozenColumns]="frozenColsDet">
              <ng-template pTemplate="frozenheader">
                <tr>
                  <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab;color:#efefef"
                    [attr.colspan]="keyLevel">
                    &nbsp;
                  </th>
                </tr>
                <tr>
                  <th class="text-left" [ngStyle]="keyLevel==1 && {'border-right':  '0.3rem solid #ababab'}">
                    {{levels['1'][1]}}
                  </th>
                  <th *ngIf="keyLevel!==1" class="text-left"
                    [ngStyle]="keyLevel==2 && {'border-right':  '0.3rem solid #ababab'}">
                    {{levels['2'][1]}}
                  </th>
                  <th *ngIf="keyLevel===3" class="text-left"
                    [ngStyle]="keyLevel==3 && {'border-right':  '0.3rem solid #ababab'}">
                    {{levels['3'][1]}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenbody" let-rowData>
                <tr>
                <tr [pSelectableRow]="rowData" style="height: 25px;">
                  <td class="text-left" [ngStyle]="keyLevel==1 && {'border-right':  '0.3rem solid #ababab'}"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key1 }}
                    </span>
                  </td>
                  <td *ngIf="keyLevel!==1" class="text-left"
                    [ngStyle]="keyLevel==2 && {'border-right':  '0.3rem solid #ababab'}"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key2 }}
                    </span>
                  </td>
                  <td *ngIf="keyLevel==3" class="text-left"
                    [ngStyle]="keyLevel==3 && {'border-right':  '0.3rem solid #ababab'}"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
                    <span matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
                      {{ rowData.descr_key3 }}
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="frozenfooter">
                <tr>
                  <td class="text-center" style="width: 300px;border-right:0.3rem solid #ababab">TOTAL:</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" *ngFor="let trim of coltrimestre" style="width: 400px; "
                    [ngStyle]="{'border-left':!trim[0].includes('q1') ? '0.2rem solid #ababab':''}" colspan="4">
                    {{ trim[1] }}</th>
                  <th class="text-center" style="width: 400px; border-left: 0.2rem solid #ababab" colspan="4">TOTAL</th>
                </tr>
                <tr>
                  <th class="text-center" *ngFor="let col of colsvartrim" style="width: 100px"
                    [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('q1') ?  '0.2rem solid #ababab':''}">
                    {{ varLabels(col,true) }}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px;">
                  <td *ngFor="let col of colsvartrim" style="width: 100px"
                    [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
                    [ngStyle]=" {'border-left':col[1].includes('Orc') && !col[0].includes('q1') ? '0.2rem solid #ababab':''}">
                    <span
                      matTooltip="{{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR' }}"
                      class="overflow-ellipse text-right" [ngClass]="checkNegative(rowData,col)">
                      {{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number:
                      '1.2-2':'pt-BR'}}
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td style="width: 100px" class="text-right" *ngFor="let col of colsvartrim"
                    [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('q1') ?  '0.2rem solid #ababab':''}">
                    {{col[1].includes('%')?calcTotalEdit(tableVisaoReal, col[0],true):calcTotalEdit(tableVisaoReal,
                    col[0]) | number: "1.2-2":"pt-BR"}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="footer-box">
            <div class="card-legend"><span></span>Ajuste<span></span>Movimentação</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal de ajuste -->
<p-dialog [(visible)]="modalAjustes" width="600" [modal]="true" styleClass="modal-custom" [responsive]="true"
  appendTo="body" [closable]="true">
  <p-header style="display: flex;justify-content: space-between;width: 100%;">
    <ul *ngIf="editOptions.length>1" class="list-inline filter-menu mb-3">
      <li *ngFor="let company of editOptions" class="list-inline-item" [ngClass]="{ active:selectedCompany===company }">
        <a (click)="changeSelectedCompany(company,true)">{{setDescription(company,'comp')}}</a>
      </li>
    </ul>
    <label *ngIf="editOptions.length===1" style="font-size: 0.85rem;font-weight: 600;">{{ modalTitulo }}</label>
  </p-header>
  <div class="row" *ngIf="tableAjustes.length > 0">
    <div class="col-sm-12">
      <p-table class="table-system" [value]="tableAjustes" selectionMode="single" [style]="{ width: '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 35%">{{levels['1'][1]}}</th>
            <th *ngIf="keyLevel!==1" class="text-center" style="width: 35%">{{levels['2'][1]}}</th>
            <th *ngIf="keyLevel===3" class="text-center" style="width: 35%">{{levels['3'][1]}}</th>
            <th class="text-right" style="width: 12%">Valor</th>
            <th class="text-right" style="width: 10%">Ação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center" style="width: 35%">
              <span matTooltip="{{ rowData.key1 }}" class="overflow-ellipse" (click)="openAjustes(rowData)">
                {{ rowData.key1 }}
              </span>
            </td>
            <td *ngIf="keyLevel!==1" class="text-center" style="width: 35%">
              <span matTooltip="{{ rowData.key2 }}" class="overflow-ellipse" (click)="openAjustes(rowData)">
                {{ rowData.key2 }}
              </span>
            </td>
            <td *ngIf="keyLevel===3" class="text-center" style="width: 35%">
              <span matTooltip="{{ rowData.key3 }}" class="overflow-ellipse" (click)="openAjustes(rowData)">
                {{ rowData.key3 }}
              </span>
            </td>
            <td class="text-right" style="width: 12%">
              <span matTooltip="{{ rowData.valor }}" class="overflow-ellipse text-right" (click)="openAjustes(rowData)">
                {{ rowData.valor }}
              </span>
            </td>
            <td style="width: 10%">
              <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha"
                matTooltipPosition="above" (click)="deletarAjuste(rowData)">
                <span class="material-icons" style="font-size: 18px">delete</span>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group " style="flex:1;margin-right: 0.4rem;">
      <label>{{levels['1'][0]}}</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione {{levels['1'][0]}}"
        [options]="optionsProjectKey1" [(ngModel)]="key1Ajuste" (onChange)="getAjusteOptions(2); getAjustesCcus()">
      </p-dropdown>
    </div>
    <div *ngIf="keyLevel!==1" class="form-group " style="flex:1;margin-left: 0.4rem;">
      <label>{{levels['2'][0]}}</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione {{levels['2'][0]}}"
        [options]="optionsProjectKey2" [(ngModel)]="key2Ajuste" (onChange)="getAjusteOptions(3); getAjustesProj()">
      </p-dropdown>
    </div>
    <div *ngIf="keyLevel===3" class="form-group " style="flex:1;margin-left: 0.4rem;">
      <label>{{levels['3'][0]}}</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione {{levels['3'][0]}}"
        [options]="optionsProjectKey3" [(ngModel)]="key3Ajuste"></p-dropdown>
    </div>
  </div>
  <div class="form-row" *ngFor="let aj of ajustes">
    <div class="form-group col-sm-3">
      <label *ngIf="aj.ref === 1">Janeiro</label>
      <label *ngIf="aj.ref === 2">Fevereiro</label>
      <label *ngIf="aj.ref === 3">Março</label>
      <label *ngIf="aj.ref === 4">Abril</label>
      <label *ngIf="aj.ref === 5">Maio</label>
      <label *ngIf="aj.ref === 6">Junho</label>
      <label *ngIf="aj.ref === 7">Julho</label>
      <label *ngIf="aj.ref === 8">Agosto</label>
      <label *ngIf="aj.ref === 9">Setembro</label>
      <label *ngIf="aj.ref === 10">Outubro</label>
      <label *ngIf="aj.ref === 11">Novembro</label>
      <label *ngIf="aj.ref === 12">Dezembro</label>
    </div>
    <div class="form-group col-sm-3">
      <input class="form-control form-control-sm text-right" type="number" [(ngModel)]="aj.valor"
        [disabled]="(limesreal.includes(movMonths[aj.ref-1]) && !isRealizadoVisao) || (ccusObrigatorio && !key1Ajuste)" />
    </div>

    <div class="form-group col-sm-6" style="justify-content: space-around; display: flex">
      <input class="form-control form-control-sm text-left" type="text" placeholder="Comentário"
        [(ngModel)]="aj.comentario" style="margin-right: 5px"
        [disabled]="(limesreal.includes(movMonths[aj.ref-1]) && !isRealizadoVisao) || (ccusObrigatorio && !key1Ajuste)" />
      <button type="button" class="btn btn-sm btn-info" (click)="carregarValores(aj.ref)"
        [disabled]="(limesreal.includes(movMonths[aj.ref-1]) && !isRealizadoVisao) || (ccusObrigatorio && !key1Ajuste)">
        <i class="fas fa-refresh"></i>
      </button>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="hideModalAjustes()">
      <i class="fas fa-undo"></i> Fechar
    </button>
    <button type="button" class="btn btn-primary" (click)="salvarAjustes()">
      <i class="fas fa-check"></i> Salvar
    </button>
  </p-footer>
</p-dialog>

<!-- Modal de edicao -->
<p-dialog id="modalDetail" [(visible)]="modalEdicao"  [style]="{ width: '900px' }" [modal]="true" styleClass="modal-custom"
  [responsive]="true" appendTo="body" [closable]="true">
  <p-header style="display: flex;justify-content: space-between;width: 100%;">
    <ul *ngIf="editOptions.length>1" class="list-inline filter-menu mb-3">
      <li *ngFor="let company of editOptions" class="list-inline-item" [ngClass]="{ active:selectedCompany===company }">
        <a (click)="changeSelectedCompany(company)">{{setDescription(company,'comp')}}</a>
      </li>
    </ul>
    <label *ngIf="editOptions.length===1" style="font-size: 1rem;font-weight: 600;">Detalhe da conta
      {{ ctaSelecionada }}</label>
    <div style="margin-left: 200px;">
      <app-toolbar [value]="toolbarEdit"></app-toolbar>
    </div>
  </p-header>

  <p-table *ngIf="!statusLink2 && !visaoTrim" class="table-system" [value]="tableEdicao" selectionMode="single"
    scrollHeight="300px" [scrollable]="true" (onEditComplete)="calcularTotalLinhaDetalhe($event)"
    [style]="{ width: '100%' }">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">{{levels['1'][1]}}</th>
        <th *ngIf="keyLevel!==1" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          {{levels['2'][1]}}
        </th>
        <th *ngIf="keyLevel===3" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          {{levels['3'][1]}}
        </th>
        <th class="text-center" *ngFor="let mes of colmeses" style="width: 100px">
          {{ mes[1] }}
        </th>
        <th class="text-center" style="width: 100px">TOTAL</th>
        <th *ngIf="!isAccumulatedVision" class="text-center" style="width: 50px">ação</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px;">
        <td [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          <span *ngIf="rowData.fromAjuste || rowData.fromMov || !editionState || isForecast"
            matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key1 }}
          </span>
          <p-dropdown appendTo="body" *ngIf="!rowData.fromAjuste && !rowData.fromMov && editionState && !isForecast"
            class="type-p-dropdown" [filter]="true" placeholder="Selecione {{levels['1'][1]}}"
            [options]="optionsKey1[selectedCompany]" [(ngModel)]="rowData.key1_id"
            (onChange)="updateEditValue(rowData.line_id);getKeyOptions(levels['2'][1],rowData.descr_key1,rowData.line_id,keyLevel==1||isMain(2))">
          </p-dropdown>
        </td>
        <td *ngIf="keyLevel!==1"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          <span *ngIf="rowData.fromAjuste || rowData.fromMov || !editionState || isForecast"
            matTooltip="{{ rowData.descr_key2 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key2 }}
          </span>
          <p-dropdown appendTo="body" *ngIf="!rowData.fromAjuste && !rowData.fromMov && editionState && !isForecast "
            class="type-p-dropdown" [filter]="true" placeholder="Selecione {{levels['2'][1]}}"
            [options]="isMain(2)?optionsKey2[selectedCompany]:rowData.options" [(ngModel)]="rowData.key2_id"
            (onChange)="updateEditValue(rowData.line_id);getKeyOptions(levels['3'][1],rowData.descr_key2,rowData.line_id,keyLevel==2||isMain(3))">
          </p-dropdown>
        </td>
        <td *ngIf="keyLevel===3" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span *ngIf="rowData.fromAjuste || rowData.fromMov || !editionState || isForecast"
            matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key3}}
          </span>
          <p-dropdown appendTo="body" *ngIf="!rowData.fromAjuste && !rowData.fromMov && editionState && !isForecast"
            class="type-p-dropdown" [filter]="true" placeholder="Selecione {{levels['3'][1]}}"
            [options]="isMain(3)?optionsKey3[selectedCompany]:rowData.options" [(ngModel)]="rowData.key3_id"
            (onChange)="updateEditValue(rowData.line_id)">
          </p-dropdown>
        </td>
        <td *ngFor="let mes of colmeses" pEditableColumn="{{ tableEdicao.indexOf(rowData) }}" class="text-right"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          style="width: 100px">
          <p-cellEditor *ngIf="!rowData.fromAjuste && !rowData.fromMov && !isAccumulatedVision && editionState">
            <ng-template pTemplate="input" class="text-right">
              <input type="number" style="width: 70px;" [(ngModel)]="rowData[mes[0]]" (focus)="checkInput($event)" />
            </ng-template>
            <ng-template pTemplate="output" class="text-right">
              {{ rowData[mes[0]] | number: "1.2-2":"pt-BR" }}
            </ng-template>
          </p-cellEditor>
          <span *ngIf="rowData.fromAjuste || rowData.fromMov || isAccumulatedVision || !editionState"
            matTooltip="{{rowData[mes[0]] | number: '1.2-2':'pt-BR'}}" class="overflow-ellipse text-right">
            {{ rowData[mes[0]] | number: "1.2-2":"pt-BR" }}
          </span>
        </td>
        <td class="text-right strong"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          style="width: 100px">
          <span matTooltip="{{ rowData.total | number: '1.2-2':'pt-BR' }}" class="overflow-ellipse text-right">
            {{ rowData.total | number: "1.2-2":"pt-BR" }}
          </span>
        </td>
        <td *ngIf="!isAccumulatedVision"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          style="width: 50px;">
          <div style="display: flex;justify-content: center;">
            <button
              *ngIf="!statusLink2 && !visaoTrim && !rowData.fromAjuste && !isAccumulatedVision && !rowData.isDestiny"
              type="button" style="padding: 0 !important;" (click)="openMovModal(rowData.line_id,rowData.fromMov)"
              class="btn btn-xs btn-link btn-full" matTooltip="Movimentar" matTooltipPosition="above"
              [disabled]="!editavel || versao.status !== 'Aberto'|| (!rowData.fromAjuste && isRealizadoVisao) || rowData.blockActions || !isSelectedCtaEditavel">
              <span class="material-icons" style="font-size: 22px">swap_vert</span>
            </button>
            <button *ngIf="!statusLink2 && !rowData.isDestiny && !isAccumulatedVision" type="button"
              style="padding: 0 !important;"
              (click)="rowData.fromAjuste?deletarAjuste(rowData,true):deletSelectedDet(rowData.line_id,rowData.fromMov)"
              class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha" matTooltipPosition="above"
              [disabled]="!editavel || versao.status !== 'Aberto' || (!rowData.fromAjuste && isRealizadoVisao) || rowData.blockActions || !isSelectedCtaEditavel">
              <span class="material-icons" style="font-size: 18px">delete</span>
            </button>
            <button *ngIf="rowData.isDestiny" type="button" style="padding: 0 !important;"
              (click)="openMovModal(rowData.line_id,rowData.fromMov,rowData.isDestiny)"
              class="btn btn-xs btn-link btn-full" matTooltip="Detalhes" matTooltipPosition="above">
              <span class="material-icons" style="font-size: 19px">info</span>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td *ngIf="keyLevel!==1" class="text-center" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}"></td>
        <td *ngIf="keyLevel===3" class="text-center" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}"></td>
        <td class="text-center" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">TOTAL:</td>
        <td class="text-right" *ngFor="let mes of colmeses" style="width: 100px">
          {{calcTotalEdit(tableEdicao, mes[0]) | number: "1.2-2":"pt-BR"}}
        </td>
        <td class="text-right" style="width: 100px">
          {{calcTotalEdit(tableEdicao, "total") | number: "1.2-2":"pt-BR"}}
        </td>
        <td *ngIf="!isAccumulatedVision" style="width: 50px"></td>
      </tr>
    </ng-template>
  </p-table>
  <p-table *ngIf=" !statusLink2 && visaoTrim" class="table-system" [value]="tableEdicao" selectionMode="single"
    scrollHeight="300px" [scrollable]="true" (onEditComplete)="calcularTotalLinhaDetalhe($event)">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          {{levels['1'][1]}}
        </th>
        <th *ngIf="keyLevel!==1" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          {{levels['2'][1]}}
        </th>
        <th *ngIf="keyLevel===3" class="text-left" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          {{levels['3'][1]}}
        </th>
        <th class="text-center" *ngFor="let trim of coltrimestre" style="width: 100px">
          {{ trim[1] }}
        </th>
        <th class="text-center" style="width: 100px">TOTAL</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px;">
        <td [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          <span *ngIf="rowData.descr_key1 !== ''" matTooltip="{{ rowData.descr_key1 }}"
            class="overflow-ellipse text-left">
            {{ rowData.descr_key1 }}
          </span>

        </td>
        <td *ngIf="keyLevel!==1"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">
          <span *ngIf="rowData.descr_key2 !== ''" matTooltip="{{ rowData.descr_key2 }}"
            class="overflow-ellipse text-left">
            {{ rowData.descr_key2 }}
          </span>

        </td>
        <td *ngIf="keyLevel===3" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span *ngIf="rowData.descr_key3 !== ''" matTooltip="{{ rowData.descr_key3 }}"
            class="overflow-ellipse text-left">
            {{ rowData.descr_key3 }}
          </span>
        </td>
        <td *ngFor="let trim of coltrimestre" pEditableColumn="{{ tableEdicao.indexOf(rowData) }}" class="text-right"
          style="width: 100px"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span *ngIf="!rowData.fromAjuste" matTooltip="{{ rowData[trim[0]] | number: '1.2-2':'pt-BR' }}"
            class="overflow-ellipse text-right">
            {{ rowData[trim[0]] | number: "1.2-2":"pt-BR" }}
          </span>
          <span *ngIf="rowData.fromAjuste" matTooltip="{{ rowData[trim[0]] | number: '1.2-2':'pt-BR' }}"
            class="overflow-ellipse text-right">
            {{ rowData[trim[0]] | number: "1.2-2":"pt-BR" }}
          </span>
        </td>
        <td class="text-right strong"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          style="width: 100px">
          <span matTooltip="{{ rowData.total | number: '1.2-2':'pt-BR' }}" class="overflow-ellipse text-right">
            {{ rowData.total | number: "1.2-2":"pt-BR" }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td *ngIf="keyLevel!==1" class="text-center" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}"></td>
        <td *ngIf="keyLevel===3" class="text-center" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}"></td>
        <td class="text-center" [ngStyle]="{'width':keyLevel===3? '100px':'150px'}">TOTAL:</td>
        <td class="text-right" *ngFor="let trim of coltrimestre" style="width: 100px">
          {{ calcTotalEdit(tableEdicao, trim[0]) | number: "1.2-2":"pt-BR" }}
        </td>
        <td class="text-right" style="width: 100px">
          {{ calcTotalEdit(tableEdicao, "total") | number: "1.2-2":"pt-BR" }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-table *ngIf="statusLink2 && !visaoTrim" class="table-system" [value]="tableVisaoReal" selectionMode="single"
    [scrollable]="true" scrollHeight="300px" [style]="{ width: '100%' }" scrollWidth="300px" frozenWidth="300px"
    [frozenColumns]="frozenColsDet">
    <ng-template pTemplate="frozenheader">
      <tr>
        <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab;color:#efefef"
          [attr.colspan]="keyLevel">
          &nbsp;
        </th>
      </tr>
      <tr>
        <th class="text-left" [ngStyle]="keyLevel==1 && {'border-right':  '0.3rem solid #ababab'}">
          {{levels['1'][1]}}
        </th>
        <th *ngIf="keyLevel!==1" class="text-left" [ngStyle]="keyLevel==2 && {'border-right':  '0.3rem solid #ababab'}">
          {{levels['2'][1]}}
        </th>
        <th *ngIf="keyLevel===3" class="text-left" [ngStyle]="keyLevel==3 && {'border-right':  '0.3rem solid #ababab'}">
          {{levels['3'][1]}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="frozenbody" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;">
        <td class="text-left" style="border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngStyle]="{'border-right': keyLevel==1 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key1 }}
          </span>
        </td>
        <td class="text-left" *ngIf="keyLevel!==1"
          [ngStyle]="{'border-right': keyLevel==2 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
          style="border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span matTooltip="{{ rowData.descr_key2 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key2 }}
          </span>
        </td>
        <td class="text-left" *ngIf="keyLevel===3"
          [ngStyle]=" {'border-right':keyLevel==3 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
          style="border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key3 }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="frozenfooter">
      <tr>
        <td class="text-center" style="width: 300px;border-right:0.3rem solid #ababab">TOTAL:</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" *ngFor="let mes of colmeses" style="width: 400px"
          [ngStyle]="{'border-left':!mes[0].includes('Jan') ?  '0.2rem solid #ababab':''}" colspan="4">
          {{ mes[1] }}</th>
        <th class="text-center" style="width: 400px; border-left: 0.2rem solid #ababab" colspan="4">TOTAL</th>
      </tr>
      <tr>
        <th class="text-center" *ngFor="let col of colsvar" style="width: 100px"
          [ngStyle]="{'border-left':col[1].includes('Orc') &&!col[0].includes('Jan')?  '0.2rem solid #ababab':''}">
          {{ varLabels(col) }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px;">
        <td *ngFor="let col of colsvar" style="width: 100px;border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          [ngStyle]="{'border-left':col[1].includes('Orc') &&!col[0].includes('Jan')?  '0.2rem solid #ababab':''}">
          <span
            matTooltip="{{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR' }}"
            class="overflow-ellipse text-right" [ngClass]="checkNegative(rowData,col)">
            {{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR' }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td style="width: 100px" class="text-right" *ngFor="let col of colsvar"
          [ngStyle]="{'border-left':col[1].includes('Orc')&&!col[0].includes('Jan') ?  '0.2rem solid #ababab':''}">
          {{col[1].includes('%')?calcTotalEdit(tableVisaoReal, col[0],true):calcTotalEdit(tableVisaoReal,
          col[0]) | number: "1.2-2":"pt-BR"}}
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-table *ngIf="statusLink2 && visaoTrim" class="table-system" [value]="tableVisaoReal" selectionMode="single"
    scrollHeight="300px" [scrollable]="true" [style]="{ width: '100%' }" scrollWidth="300px" frozenWidth="300px"
    [frozenColumns]="frozenColsDet">
    <ng-template pTemplate="frozenheader">
      <tr>
        <th class="text-left" style="width: 300px;border-right: 0.3rem solid #ababab;color:#efefef"
          [attr.colspan]="keyLevel">
          &nbsp;
        </th>
      </tr>
      <tr>
        <th class="text-left" [ngStyle]="keyLevel==1 && {'border-right':  '0.3rem solid #ababab'}">
          {{levels['1'][1]}}
        </th>
        <th *ngIf="keyLevel!==1" class="text-left" [ngStyle]="keyLevel==2 && {'border-right':  '0.3rem solid #ababab'}">
          {{levels['2'][1]}}
        </th>
        <th *ngIf="keyLevel===3" class="text-left" [ngStyle]="keyLevel==3 && {'border-right':  '0.3rem solid #ababab'}">
          {{levels['3'][1]}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="frozenbody" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;">
        <td class="text-left" style="border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngStyle]="{'border-right': keyLevel==1 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key1 }}
          </span>
        </td>
        <td class="text-left" *ngIf="keyLevel!==1"
          [ngStyle]="{'border-right': keyLevel==2 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
          style="border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span matTooltip="{{ rowData.descr_key2 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key2 }}
          </span>
        </td>
        <td class="text-left" *ngIf="keyLevel===3"
          [ngStyle]=" {'border-right':keyLevel==3 && '0.3rem solid #ababab','width':keyLevel===3? '100px':'150px'}"
          style="border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'">
          <span matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key3 }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="frozenfooter">
      <tr>
        <td class="text-center" style="width: 300px;border-right:0.3rem solid #ababab">TOTAL:</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center" *ngFor="let trim of coltrimestre" style="width: 400px; "
          [ngStyle]="{'border-left':!trim[0].includes('q1') ?  '0.2rem solid #ababab':''}" colspan="4">
          {{ trim[1] }}</th>
        <th class="text-center" style="width: 400px; border-left: 0.2rem solid #ababab" colspan="4">TOTAL</th>
      </tr>
      <tr>
        <th class="text-center" *ngFor="let col of colsvartrim" style="width: 100px"
          [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('q1') ?  '0.2rem solid #ababab':''}">
          {{ varLabels(col,true) }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px;">
        <td *ngFor="let col of colsvartrim" style="width: 100px;border-top: 0px;height: 1.75rem;max-height: 1.75rem;"
          [ngClass]="rowData.fromAjuste ? 'highlight-line-ajuste':rowData.fromMov && 'highlight-line-mov'"
          [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('q1') ?  '0.2rem solid #ababab':''}">
          <span
            matTooltip="{{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR' }}"
            class="overflow-ellipse text-right" [ngClass]="checkNegative(rowData,col)">
            {{ col[1].includes('%')?toPercent(rowData[col[0]]) + '%':rowData[col[0]] | number: '1.2-2':'pt-BR' }}
          </span>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td style="width: 100px" class="text-right" *ngFor="let col of colsvartrim"
          [ngStyle]="{'border-left':col[1].includes('Orc') && !col[0].includes('q1') ?  '0.2rem solid #ababab':''}">
          {{col[1].includes('%')?calcTotalEdit(tableVisaoReal, col[0],true):calcTotalEdit(tableVisaoReal,
          col[0]) | number: "1.2-2":"pt-BR"}}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-footer style="display: flex;justify-content: space-between;align-items: center;">
    <div class="card-legend" style="margin-left: 10px;">
      <span></span>Ajuste
      <span></span>Movimentação
    </div>
    <button type="button" class="btn btn-secondary" (click)="changeModalEdicao()">
      <i class="fas fa-undo"></i> Fechar
    </button>
  </p-footer>
</p-dialog>

<!-- Modal de movimentação -->
<p-dialog [(visible)]="modalMov" width="900" [modal]="true" styleClass="modal-custom" [responsive]="true"
  appendTo="body" [closable]="true">
  <p-header style="display: flex;justify-content: space-between;width: 100%;">
    <label style="font-size: 0.85rem;font-weight: 600;">Movimentação entre Contas</label>
    <!-- <div style="margin-right: -90px;" *ngIf="!movVisionMode"> -->
    <div *ngIf="!movVisionMode">
      <app-toolbar *ngIf="!statusLink2 && !visaoTrim" [value]="toolbarMov"></app-toolbar>
      <!--<button *ngIf="!statusLink2 && !visaoTrim" type="button" (click)="salvarMov()" class="btnCustom-default"
        matTooltip="Salvar edição" matTooltipPosition="above">
        <span class="material-icons">save</span>
      </button>-->
    </div>

  </p-header>
  <p>Conta Origem</p>
  <p-table class="table-system" [value]="tableOriginMov" selectionMode="single" [scrollable]="true"
    (onEditComplete)="calcularTotalLinhaDetalhe($event)">
    <ng-template pTemplate="header">
      <tr>
        <!-- <th *ngIf="editOptions.length>1" class="text-left" style="width: 150px">EMPRESA</th> -->
        <th class="text-left" style="width: 150px">CTA</th>
        <th class="text-left" style="width: 150px">{{levels['1'][1]}}</th>
        <th *ngIf="keyLevel!==1" class="text-left" style="width: 150px">{{levels['2'][1]}}</th>
        <th *ngIf="keyLevel===3" class="text-left" style="width: 150px">{{levels['3'][1]}}</th>
        <th class="text-center" *ngFor="let mes of colmeses" style="width: 100px">
          {{ mes[1] }}
        </th>
        <th class="text-center" style="width: 100px">TOTAL</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">

        <td style="width: 150px">
          <span matTooltip="{{ rowData.descr_cta }}" class="overflow-ellipse text-left">
            {{ rowData.descr_cta }}
          </span>
        </td>
        <td style="width: 150px">
          <span matTooltip="{{ rowData.descr_key1 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key1 }}
          </span>
        </td>
        <td *ngIf="keyLevel!==1" style="width: 150px">
          <span matTooltip="{{ rowData.descr_key2 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key2 }}
          </span>
        </td>
        <td *ngIf="keyLevel===3" style="width: 150px">
          <span matTooltip="{{ rowData.descr_key3 }}" class="overflow-ellipse text-left">
            {{ rowData.descr_key3 }}
          </span>
        </td>
        <td *ngFor="let mes of colmeses" pEditableColumn="{{ tableEdicao.indexOf(rowData) }}" class="text-right"
          style="width: 100px">
          <span matTooltip="{{
            rowData[mes[0]] | number: '1.2-2':'pt-BR'
          }}" class="overflow-ellipse text-right">
            {{ rowData[mes[0]] | number: "1.2-2":"pt-BR" }}
          </span>
        </td>
        <td class="text-right strong" style="width: 100px">
          <span matTooltip="{{ rowData.total | number: '1.2-2':'pt-BR' }}" class="overflow-ellipse text-right">
            {{ rowData.total | number: "1.2-2":"pt-BR" }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="row" style="margin-top: 20px;">
    <div
      style="padding: 0 1rem !important;width: 100%;display: flex;justify-content: space-between;align-items: center;">
      <p>Conta Destino</p>
      <app-toolbar *ngIf="!statusLink2 && !visaoTrim && !movVisionMode" [value]="toolbarMov2"></app-toolbar>
      <!--<button *ngIf="!statusLink2 && !visaoTrim && !movVisionMode" type="button" (click)="addMovRow()"
        class="btnCustom-default" Tooltip="Nova linha" matTooltipPosition="above">
        <span class="material-icons">add</span>
        <div>Adicionar</div>
      </button>-->
    </div>

    <div class="col-sm-12">
      <p-table class="table-system" [value]="tableMov" selectionMode="single" [scrollable]="true"
        (onEditComplete)="calcularTotalMov($event)" [style]="{ width: '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th *ngIf="editOptions.length>1" class="text-left" style="width: 150px">EMPRESA</th>
            <th class="text-left" style="width: 150px">CTA</th>
            <th class="text-left" style="width: 150px">{{levels['1'][1]}}</th>
            <th *ngIf="keyLevel!==1" class="text-left" style="width: 150px">{{levels['2'][1]}}</th>
            <th *ngIf="keyLevel===3" class="text-left" style="width: 150px">{{levels['3'][1]}}</th>
            <th class="text-center" *ngFor="let mes of colmeses" style="width: 100px">
              {{ mes[1] }}
            </th>
            <th class="text-center" style="width: 100px">TOTAL</th>
            <th *ngIf="isCommentVisible" class="text-center" style="width: 150px">COMENTÁRIO</th>
            <th *ngIf="!movVisionMode" class="text-center" style="width: 50px">Acao</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData" style="height: 25px;max-height: 25px">
            <td *ngIf="editOptions.length > 1 && versao.movimentar" style="width: 150px">
              <span *ngIf="movVisionMode" matTooltip="{{ setDescription(rowData.company_destiny,'comp')  }}"
                class="overflow-ellipse text-left">
                {{setDescription(rowData.company_destiny,'comp') }}
              </span>
              <p-dropdown *ngIf="!movVisionMode" appendTo="body" class="type-p-dropdown" [filter]="true"
                placeholder="Selecione empresa" [options]="listEmpresas" [(ngModel)]="rowData.company_destiny"
                (onChange)="getCcus();">
              </p-dropdown>
            </td>
            <td style="width: 150px">
              <span *ngIf="movVisionMode" matTooltip="{{ rowData.cta_id }}" class="overflow-ellipse text-left">
                {{setDescription(rowData.cta_id,'cta') }}
              </span>
              <p-dropdown *ngIf="!movVisionMode" appendTo="body" class="type-p-dropdown" [filter]="true"
                placeholder="Selecione cta" [options]="opcoesCta" [(ngModel)]="rowData.cta_id">
              </p-dropdown>
            </td>
            <td style="width: 150px">
              <span *ngIf="movVisionMode" matTooltip="{{ rowData.key1_id }}" class="overflow-ellipse text-left">
                {{ setDescription(rowData.key1_id,levels['1'][1])}}
              </span>
              <p-dropdown *ngIf="!movVisionMode" appendTo="body" class="type-p-dropdown" [filter]="true"
                placeholder="Selecione {{levels['1'][1]}}" [options]="optionsKey1[selectedCompany]"
                [(ngModel)]="rowData.key1_id"
                (onChange)="updateEditValue(rowData.line_id,true);getKeyOptions(levels['2'][1],rowData.descr_key1,rowData.line_id,keyLevel==1||isMain(2),true)">
              </p-dropdown>
            </td>
            <td style="width: 150px" *ngIf="keyLevel!==1">
              <span *ngIf="movVisionMode" matTooltip="{{ rowData.key2_id }}" class="overflow-ellipse text-left">
                {{ setDescription(rowData.key2_id,levels['2'][1]) }}
              </span>
              <p-dropdown appendTo="body" *ngIf="!movVisionMode" class="type-p-dropdown" [filter]="true"
                placeholder="Selecione {{levels['2'][1]}}"
                [options]="isMain(2)?optionsKey2[selectedCompany]:rowData.options" [(ngModel)]="rowData.key2_id"
                (onChange)="updateEditValue(rowData.line_id,true);getKeyOptions(levels['3'][1],rowData.descr_key2,rowData.line_id,keyLevel==2||isMain(3),true)">
              </p-dropdown>
            </td>
            <td style="width: 150px" *ngIf="keyLevel===3">
              <span *ngIf="movVisionMode" matTooltip="{{ rowData.key3_id }}" class="overflow-ellipse text-left">
                {{ setDescription(rowData.key3_id,levels['3'][1]) }}
              </span>
              <p-dropdown appendTo="body" *ngIf="!movVisionMode" class="type-p-dropdown" [filter]="true"
                placeholder="Selecione {{levels['3'][1]}}"
                [options]="isMain(3)?optionsKey3[selectedCompany]:rowData.options" [(ngModel)]="rowData.key3_id"
                (onChange)="updateEditValue(rowData.line_id,true)">
              </p-dropdown>
            </td>
            <td *ngFor="let mes of colmeses" pEditableColumn="{{ tableMov.indexOf(rowData) }}" class="text-right"
              style="width: 100px">
              <p-cellEditor>
                <ng-template *ngIf="!movVisionMode" pTemplate="input" class="text-right">
                  <input type="number" style="width: 70px;" [(ngModel)]="rowData[mes[0]]" (focus)="checkInput($event)"
                    [disabled]="limesreal.includes(mes[0]) && !isRealizadoVisao" />
                </ng-template>
                <ng-template pTemplate="output" class="text-right">
                  {{ rowData[mes[0]] | number: "1.2-2":"pt-BR" }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td class="text-right strong" style="width: 100px">
              <span matTooltip="{{ rowData.total | number: '1.2-2':'pt-BR' }}" class="overflow-ellipse text-right">
                {{ rowData.total | number: "1.2-2":"pt-BR" }}
              </span>
            </td>
            <td *ngIf="isCommentVisible" pEditableColumn="{{ tableMov.indexOf(rowData) }}" class="text-right"
              style="width: 150px">
              <p-cellEditor>
                <ng-template *ngIf="!movVisionMode" pTemplate="input" class="text-left">
                  <input type="text" style="width: 70px;" [(ngModel)]="rowData.comentario" />
                </ng-template>
                <ng-template pTemplate="output" class="text-left">
                  {{ rowData.comentario }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td style="width: 50px" *ngIf="!movVisionMode">
              <div style="display: flex;justify-content: center;">
                <button type="button" style="padding: 0 !important;" (click)="showComments()"
                  class="btn btn-xs btn-link btn-full" matTooltip="Mostrar comentários" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 17px">comment</span>
                </button>
                <button type="button" style="padding: 0 !important;" (click)="deletSelectedMov(rowData.line_id)"
                  class="btn btn-xs btn-link btn-full" matTooltip="Deletar Linha" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 18px">delete</span>
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td *ngIf="editOptions.length>1" class="text-center" style="width: 150px"></td>
            <td class="text-center" [ngStyle]="{'width': keyLevel*150+'px'}"></td>
            <td class="text-center" style="width: 150px">SOBRANDO:</td>
            <td class="text-right" *ngFor="let mes of colmeses" style="width: 100px">
              {{calcMovRest(mes[0]) | number: "1.2-2":"pt-BR"}}
            </td>
            <td class="text-right" style="width: 100px">{{calcMovRest('total') | number: "1.2-2":"pt-BR"}}</td>
            <td *ngIf="isCommentVisible" style="width: 150px"></td>
            <td *ngIf="!movVisionMode" style="width: 50px"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalMov=false;">
      <i class="fas fa-undo"></i> Cancelar
    </button>
  </p-footer>
</p-dialog>

<!-- Modal comentários -->
<p-dialog header="Comentário" [(visible)]="modalComentario" width="800" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row" style="display: flex; align-items: center; justify-content: space-between;margin-left: 1px;">
    <div class="form-group" *ngIf="versao?.consolidar" style="width: 49%;">
      <label>Empresas</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione uma empresa"
        [options]="listEmpresasSelecionadas" [(ngModel)]="empresaSelecionada" (onChange)="carregarComentarios()">
      </p-dropdown>
    </div>
    <p-checkbox (change)="changeComentarioGeral()" [(ngModel)]="comentarioGeral">Comentário geral</p-checkbox>
  </div>
  <div class="form-row" *ngIf="!comentarioGeral">
    <div class="form-group col-sm-6">
      <label>Centro de custo</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione centro de custo"
        [options]="opcoesCentroCustosVar" [(ngModel)]="key1Ajuste"
        (onChange)="carregarComentarios(); getProjetosVar();">
      </p-dropdown>
    </div>
    <div *ngIf="keyLevel!==1" class="form-group col-sm-6">
      <label>Projeto</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione projeto" [options]="opcoesProjetosVar"
        [(ngModel)]="key2Ajuste" (onChange)="carregarComentarios()"></p-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <h3 class="sub-title">
        <i class="far fa-comment-dots"></i> Comentários
        <button type="button" class="btn btn-xs btn-primary pull-right" style="visibility: hidden">
          <i class="fas fa-check"></i> DO NOT REMOVE
        </button>
      </h3>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <textarea [froalaEditor]="optionsDescFroola" [(ngModel)]="comentarioNew" id="textAreaTwoSize"
        autofocus></textarea>
      <button type="button" (click)="salvarComentario()" class="btn btn-xs btn-primary">
        <i class="fas fa-check"></i> Salvar
      </button>
      <button type="button" class="btn btn-xs btn-link btn-close-times" (click)="cancelarComentario()">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="spaced-activity" *ngFor="let c of comentarios">
        <div class="by-date">
          <span class="user"><i class="far fa-user"></i> {{ c.user_fullname }}</span><span class="date-time"><i
              class="far fa-calendar-alt"></i>
            {{ c.dt_comentario | date: "short":"+0000":"pt-BR" }}</span>
          <div class="actions">
            <span class="edit" matTooltip="Editar Comentário" matTooltipPosition="above"
              *ngIf="c.user_id == loggedUserId">
              <i class="fas fa-pencil-alt" (click)="editarComentario(c)"></i>
            </span>
            <span class="delete" matTooltip="Excluir Comentário" matTooltipPosition="above"
              *ngIf="c.user_id == loggedUserId">
              <i class="far fa-trash-alt" (click)="excluirComentario(c.id)"></i>
            </span>
          </div>
        </div>
        <div class="box-activity" [innerHtml]="c.comentario | safeHtml"></div>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalComentario = false">
      <i class="fas fa-undo"></i> Fechar
    </button>
  </p-footer>
</p-dialog>
<div *ngIf="isLoadingInProgress" class="loading-layer">
  <h6>Processamento em andamento, favor aguardar... {{loadingProgress}}%</h6>
  <p-progressBar [value]="loadingProgress" style="width: 40% !important"></p-progressBar>
</div>