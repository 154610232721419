<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>
  <mat-card-content>
    <div class="box-content pt-2">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsUsuarios" [value]="usuarios" [rows]="rows" [paginator]="true"
        (onFilter)="onFilter($event)" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
        [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'usuario'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'usuario', 'contains')">
            </th>

            <th class="text-left" style="width: 19%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>

            <th class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtRegistro'">
                <span class="text-overflow-dynamic-ellipsis">DT REGISTRO <p-sortIcon [field]="'dtRegistro'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dtRegistro', 'contains')">
            </th>

            <th class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'pagtoConfirmadoPt'">
                <span class="text-overflow-dynamic-ellipsis">PAGO <p-sortIcon [field]="'pagtoConfirmadoPt'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'pagtoConfirmadoPt', 'contains')">
            </th>

            <th class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuarioGeradoPt'">
                <span class="text-overflow-dynamic-ellipsis">GERADO <p-sortIcon [field]="'usuarioGeradoPt'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'usuarioGeradoPt', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'plano'">
                <span class="text-overflow-dynamic-ellipsis">PLANO <p-sortIcon [field]="'plano'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'plano', 'contains')">
            </th>

            <th class="text-right" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'duracao'">
                <span class="text-overflow-dynamic-ellipsis">DURAÇÃO <p-sortIcon [field]="'duracao'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'duracao', 'contains')">
            </th>

            <th class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtExpira'">
                <span class="text-overflow-dynamic-ellipsis">DT EXPIRA <p-sortIcon [field]="'dtExpira'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'dtExpira', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuarioPai'">
                <span class="text-overflow-dynamic-ellipsis">USUÁRIO PAI <p-sortIcon [field]="'usuarioPai'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'usuarioPai', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'formaPagto'">
                <span class="text-overflow-dynamic-ellipsis">FORMA PAGTO <p-sortIcon [field]="'formaPagto'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'formaPagto', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoPagto'">
                <span class="text-overflow-dynamic-ellipsis">TIPO PAGTO <p-sortIcon [field]="'tipoPagto'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'tipoPagto', 'contains')">
            </th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>

            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.usuario}}">{{rowData.usuario
                  }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 19%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}">{{rowData.email }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.dtRegistro}}">{{rowData.dtRegistro}}</span>
              </span>
            </td>
            <td class="text-sim" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.pagtoConfirmadoPt}}">{{rowData.pagtoConfirmadoPt}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.usuarioGeradoPt}}">{{rowData.usuarioGeradoPt }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.plano}}">{{rowData.plano }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.duracao}}">{{rowData.duracao
                  }}</span>
              </span>
            </td>

            <td class="text-center" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtExpira}}">{{rowData.dtExpira
                  }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.usuarioPai}}">{{rowData.usuarioPai
                  }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.formaPagto}}">{{rowData.formaPagto
                  }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoPagto}}">{{rowData.tipoPagto
                  }}</span>
              </span>
            </td>


          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="13" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>


    </div>
  </mat-card-content>