<div style="padding: 1.5rem" class="pt-3">
  <div class="row" style="padding-left: 1rem;">
    <div class="row" style="padding-left: 1rem !important;">
      <h4 class="font-weight-bold mt-3 mb-3">
        Produtos e Serviços
        <div class="text-muted text-tiny mt-1">
          <small class="font-weight-normal">Cadastre, edite e exclua seus itens</small>
        </div>
      </h4>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">&nbsp;</h3>
            <div class="actions-card" style="display: flex;">
              <button *ngIf="showMoreOptions" type="button" mat-icon-button [matMenuTriggerFor]="beforeMenu"
                class="btnCustom-default" matTooltip="Mais opções">
                <span class="material-icons" style="margin-top: -7px;">more_vert</span>
              </button>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <div class="menuOptions" mat-menu-item (click)="$event.stopPropagation();">
                  <p-checkbox class="mr-1" [(ngModel)]="showInactives" [binary]="true" (onChange)="loadItens()">
                  </p-checkbox>
                  <span>Exibir inativos</span>
                </div>
              </mat-menu>
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content" *ngIf="showLista">
          <app-skeleton [config]="skeletonConfig">
            <p-table class="table-system" #dt [value]="lista" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
              [rowsPerPageOptions]="rowsPerPage" [totalRecords]="totalRecords" [lazy]="true"
              (onLazyLoad)="loadItens($event)" [lazyLoadOnInit]="false" (onRowSelect)="changeToolbar()"
              (onRowUnselect)="changeToolbar()" (onHeaderCheckboxToggle)="changeToolbar()"
              [(selection)]="selectedItens">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center tableHeaderCheckbox">
                    <span class="check-all p-0">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </span>
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CODIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'itemFornecedor'">
                      <span class="text-overflow-dynamic-ellipsis">
                        PART NUMBER <p-sortIcon [field]="'itemFornecedor'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'itemFornecedor', 'contains')">
                  </th>
                  <th style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoEn'">
                      <span class="text-overflow-dynamic-ellipsis">
                        DESCRIÇÃO INGLÊS <p-sortIcon [field]="'descricaoEn'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricaoEn', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoUni'">
                      <span class="text-overflow-dynamic-ellipsis">
                        UN <p-sortIcon [field]="'descricaoUni'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'descricaoUni', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ncm'">
                      <span class="text-overflow-dynamic-ellipsis">
                        NCM <p-sortIcon [field]="'ncm'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'ncm', 'contains')">
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ativo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        ATIVO <p-sortIcon [field]="'ativo'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="activeOptions" defaultLabel="Selecione" [filter]="false"
                      (onChange)="dt.filter($event.value, 'ativo', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cadastrado'">
                      <span class="text-overflow-dynamic-ellipsis">
                        PRÉ CAD <p-sortIcon [field]="'cadastrado'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="preCadTable" defaultLabel="Selecione" [filter]="false"
                      (onChange)="dt.filter($event.value, 'cadastrado', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'verifica_estoque'">
                      <span class="text-overflow-dynamic-ellipsis">
                        MOV ESTOQUE <p-sortIcon [field]="'verifica_estoque'"></p-sortIcon>
                      </span>
                    </span>
                    <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table"
                      [options]="olhaEstoqueTable" defaultLabel="Selecione" [filter]="false"
                      (onChange)="dt.filter($event.value, 'verifica_estoque', 'in')"></p-multiSelect>
                  </th>
                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'estoqueMin'">
                      <span class="text-overflow-dynamic-ellipsis">
                        ESTOQUE MINÍMO <p-sortIcon [field]="'estoqueMin'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'estoqueMin', 'contains')">
                  </th>
                  <th class="text-right">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorCusto'">
                      <span class="text-overflow-dynamic-ellipsis">
                        VALOR CUSTO (R$) <p-sortIcon [field]="'valorCusto'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valorCusto', 'contains')">
                  </th>
                  <th class="text-right">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'valorVenda'">
                      <span class="text-overflow-dynamic-ellipsis">
                        VALOR VENDA (R$) <p-sortIcon [field]="'valorVenda'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'valorVenda', 'contains')">
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox [pSelectableRow]="rowData" [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.codigo }}">
                        {{ rowData.codigo }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.itemFornecedor }}">
                        {{ rowData.itemFornecedor }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricao }}">
                        {{ rowData.descricao }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricaoEn }}">
                        {{ rowData.descricaoEn }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.descricaoUni }}">
                        {{ rowData.descricaoUni }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.ncm }}">
                        {{ rowData.ncm }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.ativo }}">
                        {{ rowData.ativo ? 'ATIVO' : 'INATIVO'}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.cadastrado ? '' : 'PENDENTE' }}">
                        {{ rowData.cadastrado ? '' : 'PENDENTE' }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{ rowData.verificaEstoque ? 'SIM' : 'NÃO' }}">
                        {{ rowData.verificaEstoque ? 'SIM' : 'NÃO' }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.estoqueMin }}">
                        {{ rowData.estoqueMin }}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valorCusto | money }}">
                        {{ rowData.valorCusto | money }}
                      </span>
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.valorVenda | money }}">
                        {{ rowData.valorVenda | money }}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="13" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </app-skeleton>
        </div>

        <div class="box-content" *ngIf="showEdit || showNew">
          <div class="form-row mt-3">
            <div class="form-group col-sm-6">
              <label>CÓDIGO</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.codigo"
                placeholder="Código gerado automaticamente pelo sistema" disabled="disabled" />
            </div>
            <div class="form-group col-sm-6">
              <label>TIPO</label>
              <p-dropdown class="type-p-dropdown" [disabled]="showEdit === true && item.preCadastro !== 'PENDENTE'"
                [filter]="true" placeholder="Selecione" [options]="tipoList" [(ngModel)]="tipo"
                (ngModelChange)="setControllers(true)"></p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>DESCRIÇÃO</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.descricao" />
            </div>
            <div class="form-group col-sm-12">
              <label>DESCRIÇÃO EN</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.descricaoEn" />
            </div>
            <div class="form-group col-sm-12">
              <label>DESCRIÇÃO COMPLETA DI</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.descricaoCompleta" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>UN</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="unList" [(ngModel)]="un" [disabled]="ctrUn"></p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>PESO LÍQUIDO</label>
              <input class="form-control" type="number" input-text="right" [(ngModel)]="item.pesoL" />
            </div>
            <div class="form-group col-sm-4">
              <label>PESO BRUTO</label>
              <input class="form-control" type="number" input-text="right" [(ngModel)]="item.pesoB" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>CAPÍTULO TIPI</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="tipiList" [(ngModel)]="tipi" (ngModelChange)="loadNCM()"
                [disabled]="ctrTipi">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>NCM</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="ncmList" [(ngModel)]="ncm" [disabled]="ctrNcm"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>DATA INICIAL</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar datepicker-form" dateFormat="dd/mm/yy"
                [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [(ngModel)]="item.dtInicial">
              </p-calendar>
            </div>
            <div class="form-group col-sm-2">
              <label>DATA FINAL</label>
              <p-calendar (onInput)="onInputDate($event)" class="type-p-calendar datepicker-form" dateFormat="dd/mm/yy"
                [monthNavigator]="true" [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030"
                [(ngModel)]="item.dtFinal">
              </p-calendar>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>NVE</label>
              <input class="form-control" type="text" input-text="right" maxlength="20" [(ngModel)]="item.nve" />
            </div>
            <div class="form-group col-sm-4">
              <label>PART NUMBER</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.itemFornecedor" />
            </div>
            <div class="form-group col-sm-4">
              <label>ALIQ. ICMS</label>
              <input class="form-control" type="number" input-text="left" [(ngModel)]="item.aliquotaIcms"
                [disabled]="ctrAliquotaIcms" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>CÓD. BARRAS EAN-13</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.ean13"
                [disabled]="ctrEan13" />
            </div>
            <div class="form-group col-sm-4">
              <label>R$ VALOR CUSTO</label>
              <input class="form-control" type="number" input-text="left" [(ngModel)]="item.valorCusto" />
            </div>
            <div class="form-group col-sm-4">
              <label>R$ VALOR VENDA</label>
              <input class="form-control" type="number" input-text="left" [(ngModel)]="item.valorVenda" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>COD. CEST</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="codCestList" [(ngModel)]="codCest" [disabled]="ctrCest"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>OPERAÇÃO</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="operacaoList" [(ngModel)]="operacao" [disabled]="ctrOperacao">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>ORIGEM MATERIAL</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="origemList" [(ngModel)]="origem">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>INFORMAÇÕES ADICIONAIS</label>
              <input class="form-control" type="text" input-text="left" [(ngModel)]="item.informacoesAdicionais" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>COD. LST</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="lstList" [(ngModel)]="lst" [disabled]="ctrLst"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>CNAE SERVIÇO</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="cnaeList" [(ngModel)]="cnae" [disabled]="ctrCnae"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>COD. NBS</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="nbsList" [(ngModel)]="nbs" [disabled]="ctrNbs"></p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>COD. RENDIMENTO (REINF)</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="codigoRendimentoList" [(ngModel)]="codigoRendimento">
              </p-dropdown>
            </div>
            <div class="form-group col-sm-2">
              <label>CATEGORIA</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="categoriaList" [(ngModel)]="categoria">
              </p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-2" input-vertical="middle">
              <p-checkbox class="mr-2" [(ngModel)]="validarNcm" [binary]="true"
                (click)="validacaoNcm(item.id)"></p-checkbox>
              <label *ngIf="item.ncmValid">Revalidar NCM</label>
              <label *ngIf="!item.ncmValid">Validar NCM</label>
            </div>
            <div *ngIf="item.ncmValid" class="form-group col-sm-10">
              <label>Esta NCM foi validada {{item.ncmDtValid | date: 'dd/MM/yyyy'}} por
                {{item.ncmUserValid.fullName}}</label>
              <span class="delete" matTooltip="Excluir Validação" (click)="removerValidacao(item.id)"><i
                  class="far fa-trash-alt"></i></span>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3" input-vertical="middle">
              <mat-slide-toggle [(ngModel)]="item.verificaEstoque" [disabled]="ctrVerificaEstoque">Controla
                Estoque</mat-slide-toggle>
            </div>
            <div class="form-group col-sm-3" input-vertical="middle">
              <mat-slide-toggle [(ngModel)]="item.itemBom" [disabled]="ctrVerificaEstoque">BOM</mat-slide-toggle>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label>GRUPO ESTOQUE</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="grupoEstoqueList" [(ngModel)]="grupoEstoque"
                [disabled]="!item.verificaEstoque"></p-dropdown>
            </div>
            <div class="form-group col-sm-4">
              <label>ESTOQUE MIN</label>
              <input class="form-control" type="number" input-text="left" [(ngModel)]="estoqueMin"
                [disabled]="!item.verificaEstoque" />
            </div>
            <div class="form-group col-sm-4">
              <label>ESTOQUE MAX</label>
              <input class="form-control" type="number" input-text="left" [(ngModel)]="estoqueMax"
                [disabled]="!item.verificaEstoque" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>FATOR CONVERSÃO</label>
              <div class="col-sm-12">
                <p-table class="table-system" selectionMode="single" [value]="fatoresConversao">
                  <ng-template pTemplate="header">
                    <tr>
                      <th *ngIf="eyeMobileUser && (tipo === 1 || tipo === 5)" class="text-left" style="width: 40%">
                        ITEM VINCULADO
                      </th>
                      <th class="text-right" style="width: 40%">
                        UNIDADE MEDIDA
                      </th>
                      <th class="text-right" style="width: 40%">
                        FATOR
                      </th>
                      <th style="width: 20%" class="text-center">Ação</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData" style="height: 25px">
                      <td *ngIf="eyeMobileUser && (tipo === 1 || tipo === 5)" class="text-left" style="width: 40%">
                        {{ rowData.itemEye }}
                      </td>
                      <td class="text-right">
                        {{ rowData.codigoUnidadeMedida }}
                      </td>
                      <td class="text-right">
                        {{ rowData.fatorConversao }}
                      </td>
                      <td class="text-center">
                        <span class="edit" matTooltip="Editar Fator Conversão" (click)="editarFatorConv(rowData)">
                          <i class="fas fa-edit"></i>
                        </span>
                        <span class="delete" matTooltip="Excluir Fator Conversão" (click)="excluirFatorConv(rowData)">
                          <i class="fas fa-trash-alt"></i>
                        </span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                    <tr style="height: 25px">
                      <td [attr.colspan]="eyeMobileUser && (tipo === 1 || tipo === 5) ? 4 : 3" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline">
                          <i class="fas fa-info-circle"></i> Sem Unidades Adicionadas!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <div class="col-sm-12">
                <button class="btn btn-sm btn-info" (click)="adicionarConversao()">
                  <i class="fas fa-plus"></i> Adicionar Medida
                </button>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-6">
              <label>TIPO UTILIZAÇÃO (Utilizado para NF de Comunicação 21)</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="tipoUtilizacaoList" [(ngModel)]="tipoUtilizacao"
                [disabled]="operacao == 0"></p-dropdown>
            </div>
            <div class="form-group col-sm-6">
              <label>CÓD. SERVIÇO COMUNICAÇÃO (Utilizado para NF de Comunicação 21)</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30"
                placeholder="Selecione" [options]="codServicoTelecomunicacaoList"
                [(ngModel)]="codServicoTelecomunicacao" [disabled]="operacao == 0"></p-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="eyeMobileUser">
            <div class="form-group col-sm-6">
              <label>CATÁLOGOS (EYEMOBILE)</label>
              <p-multiSelect class="type-p-mult" [filter]="true" placeholder="Selecione" [options]="catalogosList"
                selectedItemsLabel="{0} catálogos selecionadas" [(ngModel)]="catalogosId" [maxSelectedLabels]="2"
                (onChange)="loadGrupoProdutos()"></p-multiSelect>
            </div>
            <div class="form-group col-sm-6">
              <label>GRUPO DE PRODUTO (EYEMOBILE)</label>
              <p-multiSelect class="type-p-mult" [filter]="true" [disabled]="catalogosId.length === 0"
                placeholder="Selecione" [options]="grupoProdutoList" selectedItemsLabel="{0} produtos selecionados"
                [(ngModel)]="grupoProdutosId" [maxSelectedLabels]="2"></p-multiSelect>
            </div>
          </div>
          <div class="form-row" *ngIf="showEdit">
            <div class="form-group col-sm-2" input-vertical="middle">
              <p-checkbox class="mr-2" [(ngModel)]="item.ativo" (change)="item.ativo" [binary]="true" inputId="ativo">
              </p-checkbox>
              <label>ATIVO</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- EDIT | NEW -->
<!-- ---------------------------------------------------------------------------------------- -->

<p-dialog header="Importar Itens" [(visible)]="modalUploadItens" styleClass="modal-scroll" [style]="{ width: '700px' }"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true">
  <p-header class="headerContainer">
    <div *ngIf="tipoLayoutImportacao===0" class="d-flex">
      <a type="button" class="btnCustom-default"
        href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LEGENDAS_UPLOAD_ITEM.pdf"
        matTooltip="Baixar Legendas">
        <span class="material-icons">file_download</span>
      </a>
      <a type="button" class="btnCustom-default"
        href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_CADASTRO_ITEM_NEW.csv"
        matTooltip="Baixar Layout">
        <span class="material-icons">cloud_upload</span>
      </a>
    </div>
    <div *ngIf="tipoLayoutImportacao===1">
      <a type="button" class="btnCustom-default"
        href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_FATOR_CONVERSAO_EYEMOBILE.xlsx"
        matTooltip="Baixar Layout">
        <span class="material-icons">cloud_upload</span>
      </a>
    </div>
  </p-header>
  <div class="form-row">
    <div class="col-sm-12">
      <label>Selecione o tipo de Arquivo</label>
      <p-dropdown class="type-p-dropdown" [(ngModel)]="tipoLayoutImportacao" [options]="listLayout" [filter]="false"
        placeholder="Selecionar um tipo de arquivo"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-9 mb-1 mt-1">
      <label class="d-block">Selecione um arquivo</label>
      <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
        <span class="input-group-append">
          <span class="input-group-text btn-file">
            <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span>
            <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
              Arquivo</span>
            <input type="file" #file (change)="fileChange()" />
          </span>
        </span>
        <div class="form-control" data-trigger="fileinput">
          <span class="fileinput-filename" id="file-name"></span>
        </div>
      </div>
    </div>
    <div class="form-group col mb-0">
      <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
      <button type="button" class="btn btn-primary" [disabled]="importDisabled" (click)="importar()"><i
          class="fas fa-upload"></i> Importar</button>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalUploadItens = false;"><i class="fas fa-undo"></i>
      Cancelar
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Editar itens em lote" [(visible)]="modalBatchEdit" styleClass="modal-scroll"
  [style]="{ width: '700px' }" [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

  <div class="modal-batch-header">
    <h2>Preencher APENAS os campos que você deseja modificar.</h2>
  </div>

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Valor de custo</label>
      <input class="form-control" type="number" [(ngModel)]="lotCostValue" />
    </div>
    <div class="form-group col-sm-4">
      <label>Valor de venda</label>
      <input class="form-control" type="number" [(ngModel)]="lotSaleValue" />
    </div>
    <div class="form-group col-sm-4">
      <label>Estoque minímo</label>
      <input class="form-control" type="number" [(ngModel)]="minimumLotStock" />
    </div>

    <div class="form-group col-sm-12" *ngIf="registerGroupCompanies">
      <label>Deseja clonar o produto para quais empresas do grupo?</label>

      <p-multiSelect [placeholder]="placeholderSelectedCompanies" class="type-p-mult" appendTo="body" [filter]="true"
        [options]="companiesGroup" [(ngModel)]="selectedCompaniesGroup">
      </p-multiSelect>
    </div>

    <div class="col-sm-12">
      <div class="alert alert-xs alert-primary d-inline" role="alert">
        <i class="fas fa-info-circle"></i> Os valores inseridos serão replicados para os {{selectedItens.length}} itens
        selecionados!
      </div>
    </div>
  </div>

  <p-footer class="d-flex align-items-center justify-content-end">
    <button class="btnCustom-default" (click)="openingModalBatchEdit()">
      <span class="material-icons">close</span>
      <div>Cancelar</div>
    </button>
    <button class="btnCustom-green" (click)="batchEdit()">
      <span class="material-icons">save</span>
      <div>Salvar</div>
    </button>
  </p-footer>
</p-dialog>

<p-dialog header="Clonar Item" [(visible)]="modalClone" [modal]="true" [responsive]="true" [style]="{width: '400px'}"
  appendTo="body" [closable]="true">

  <div class="form-row"*ngIf="this.cloneInOwnCompany">
    <div class="form-group col-sm-12">
      <label>Descrição</label>
      <input class="form-control" type="text" [(ngModel)]="itemDescription">
    </div>
  </div>

  <div class="form-row" *ngIf="this.cloneInOwnCompany">
    <div class="form-group col-sm-12">
      <label>Part Number</label>
      <input class="form-control" type="text" maxlength="100" [(ngModel)]="itemPartNumber">
    </div>
  </div>

  <div class="form-row" *ngIf="registerGroupCompanies && !this.cloneInOwnCompany">
    <div class="form-group col-sm-12">
      <label>Deseja clonar o produto para quais empresas do grupo?</label>

      <p-multiSelect [placeholder]="placeholderSelectedCompanies" class="type-p-mult" appendTo="body" [filter]="true"
        [options]="companiesGroup" [(ngModel)]="selectedCompaniesGroup">
      </p-multiSelect>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="setModalClone(false, false)"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)='saveClone()'><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>

<p-dialog [header]="modalGroupDataTitle" [(visible)]="modalGroup" styleClass="modal-scroll" [style]="{ width: '700px' }"
  [modal]="true" [responsive]="true" appendTo="body" [closable]="true">

  <label>{{ modalGroupDataLabel }}</label>

  <p-multiSelect [placeholder]="placeholderSelectedCompanies" class="type-p-mult" appendTo="body" [filter]="true"
    [options]="companiesGroup" [(ngModel)]="selectedCompaniesGroup">
  </p-multiSelect>

  <p-footer class="d-flex align-items-center justify-content-end">
    <button class="btnCustom-green" (click)="salvar(informationValid)">
      <span class="material-icons">save</span>
      <div>Salvar</div>
    </button>
  </p-footer>
</p-dialog>

<!---Modal Conversão -->
<p-dialog header="Fator Conversão" [(visible)]="modalConversao" [style]="{ width: '500px' }" [modal]="true"
  styleClass="contabil-altura-inicial" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div *ngIf="eyeMobileUser && (tipo === 1 || tipo === 5)" class="form-group col-sm-4">
      <label>Item <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown altura-comercial" [(ngModel)]="rowFator.itemEyeId" [filter]="true"
        [virtualScroll]="true" [itemSize]="30" [options]="optItens" placeholder="Selecione o item"></p-dropdown>
    </div>
    <div class="form-group" [ngClass]="eyeMobileUser && (tipo === 1 || tipo === 5) ? 'col-sm-4' : 'col-sm-6'">
      <label>Unidade <span class="obrigatorio">*</span></label>
      <p-dropdown appendTo="body" class="type-p-dropdown altura-comercial" [(ngModel)]="rowFator.unidadeMedida"
        [filter]="true" [options]="unList" placeholder="Selecione a Unidade" (onChange)="dadosUnidade()"></p-dropdown>
    </div>
    <div class="form-group" [ngClass]="eyeMobileUser && (tipo === 1 || tipo === 5) ? 'col-sm-4' : 'col-sm-6'">
      <label>Fator Conversão <span class="obrigatorio">*</span></label>
      <input class="form-control text-right" type="text" pInputText [(ngModel)]="rowFator.fatorConversao" />
    </div>
  </div>
  <p-footer>
    <div>
      <button type="button" class="btn btn-secondary"
        (click)="modalConversao=false">
        <i class="fas fa-undo"></i> Cancelar
      </button>
      <button type="button" class="btn btn-primary" *ngIf="!editConversao" (click)="incluirFatorConv()">
        <i class="fas fa-check"></i> Salvar
      </button>
      <button type="button" class="btn btn-primary" *ngIf="editConversao" (click)="editSaveFatorConv()">
        <i class="fas fa-pencil-alt"></i> Alterar
      </button>
    </div>
  </p-footer>
</p-dialog>
<!---Fim Modal Conversao-->