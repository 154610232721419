<mat-card class="card-linnks margin-linnks">

  <mat-card-title style="margin-left: 2px;">
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content *ngIf="this.dynamicTable">
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <app-dynamic-table class="table-system" (contentTosave)="salvarAlteracoes($event)" (contentToDelete)="deletarOperacoes($event)" (messages)="catchMessage($event)"
                [value]="this.dynamicTable">
              </app-dynamic-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>