<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <div class="clearfix"></div>

  <mat-card-content *ngIf="showTabela">
    <div class="box-content pt-3">

      <div class="form-row">
        <div class="form-group col-sm-12">
          <p-dropdown class="type-p-dropdown" placeholder="Selecione uma Tabela" [filter]="true"
            [options]="opcoesTabelaCrm" [(ngModel)]="tabelaCrmId" (onChange)="buscarTabelaCrmDetalhe()">
          </p-dropdown>
        </div>
      </div>


      <p-table class="table-system" #dt2 [value]="listTabelaCrmDetalhe" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" (onHeaderCheckboxToggle)="selectTodos($event)" (onRowSelect)="selectCheckbox($event)"  (onRowUnselect)="unselectCheckbox($event)" >
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 45px;">
              <span class="check-all p-0">
                <p-tableHeaderCheckbox ></p-tableHeaderCheckbox>
              </span>
            </th>

            <th class="text-left" style="width: 80%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'campo'">
                <span class="text-overflow-dynamic-ellipsis">CAMPO <p-sortIcon [field]="'campo'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'campo', 'contains')">
            </th>

            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'posicao'">
                <span class="text-overflow-dynamic-ellipsis">POSIÇÃO <p-sortIcon [field]="'posicao'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'posicao', 'contains')">
            </th>



          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center" style="width: 45px;">
              <p-tableCheckbox [value]="rowData" [(ngModel)]="rowData.selected"></p-tableCheckbox>
            </td>

            <td class="text-left" style="width: 80%;">
              <span>
                <span matTooltip="{{rowData.campo}}">{{rowData.campo }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.posicao}}">{{rowData.posicao
                  }}</span>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="3" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>



    </div>



  </mat-card-content>




  <!-- Modal para edicao do registro -->
  <div class="row">
    <div class="col-sm-12">
      <div class="box-content" *ngIf="boolModalEditar || boolModalNew">

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Campo</label>
            <input type="text" class="form-control text-left" value="{{tabelaCrmDetalhe.campo}}"
              [(ngModel)]="tabelaCrmDetalhe.campo" maxlength="300" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Posição</label>
            <input type="number" class="form-control text-left" value="{{tabelaCrmDetalhe.posicao}}"
              [(ngModel)]="tabelaCrmDetalhe.posicao" min="1" max="40" />
          </div>
        </div>



        <div class="form-row">
          <div class="form-group col-sm-12">
            <button class="btn btn-secondary" (click)="boolModalEditar=false; boolModalNew=false; showTabela=true"><i
                class="fas fa-undo"></i>
              Cancelar
            </button>
            <button *ngIf="boolModalNew" type="button" class="btn btn-primary" (click)="salvarTabelaCrmDetalhe();"><i
                class="fas fa-check"></i> Salvar</button>
            <button *ngIf="boolModalEditar" type="button" class="btn btn-primary" (click)="editarTabelaCrmDetalhe();"><i
                class="fas fa-check"></i> Editar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal para edicao do registro -->


</mat-card>