<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">
      <div class="ui-g-12">

      </div>
    </div>
  </div>

  <mat-card-content>
    <div *ngIf="!novaConta && !editarConta">
      <div class="row">
        <div class="col-sm-12">

          <div class="header-box">
            <div class="header-box-content" style="padding: 1.5rem !important;">
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>

          <div class="box-content">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroPisconfins" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
              [style]="{'width':'100%'}" immutable="false" (onRowSelect)="onRowSelect($event)"
              (onRowUnselect)="onRowUnselect($event)" [(selection)]="selectPIS">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-left" style="width: 300px;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'cst'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CST <p-sortIcon [field]="'cst'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'cst', 'contains')">
                  </th>

                  <th class="text-left">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'descricao', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;">
                    <p-tableRadioButton [pSelectableRow]="rowData" [value]="rowData">
                    </p-tableRadioButton>
                  </td>

                  <td class="text-left" style="width: 40px;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cst}}">{{rowData.cst}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>


    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div class="col-sm-6" style="min-width: 818px;">
        <div class="header-box">
          <div class="header-box-content" style="padding: 1.5rem !important;">
            <div class="space">
              <div class="actions-card spaceCard">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
        </div>

        <div class="box-content">
          <div class="form-size">
            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>CST</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="cst">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <label>Descrição</label>
                <input class="form-control" id="input" type="text" pInputText [(ngModel)]="descricao">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

