<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"></i> Tipo de Relatório</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-7">
          <label>Relatório</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o Relatório"
            [options]="opcoesRelatorio" [(ngModel)]="relatorioSelecionado">
          </p-dropdown>
        </div>
      </div>
      <div *ngIf="relatorioSelecionado">
        <div *ngIf="!relatorioSelecionado.params.startsWith('nenhum')">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="sub-title"><i class="fas fa-sliders-h"></i> Parâmetros</h3>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-1" *ngIf="verificaParametroDtIni(relatorioSelecionado)">
              <label>Data Inicial</label>
              <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dataIni" [monthNavigator]="true"
                appendTo="body" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-1" *ngIf="verificaParametroDtFin(relatorioSelecionado)">
              <label>Data Final</label>
              <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="dataFin" [monthNavigator]="true"
                appendTo="body" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
            </div>
            <div class="form-group col-sm-5" *ngIf="verificaParametroUsuarioId(relatorioSelecionado)">
              <label>Usuário</label>
              <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o Usuário"
                [options]="opcoesUsuario" [(ngModel)]="usuarioSelecionado">
              </p-dropdown>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <button class="btn btn-primary" type="button" (click)="gerarRelatorio()"
                [disabled]="(dataIni > dataFin) || (verificaParametroUsuarioId(relatorioSelecionado) && !usuarioSelecionado)"><i
                  class="fas fa-sync-alt"></i> Gerar</button>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="relatorioSelecionado.params.startsWith('nenhum')">
          <div class="form-group col">
            <button class="btn btn-primary" type="button" (click)="gerarRelatorio()"
              [disabled]="(dataIni > dataFin) || (verificaParametroUsuarioId(relatorioSelecionado) && !usuarioSelecionado)"><i
                class="fas fa-sync-alt"></i> Gerar</button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>