<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="box-content col-sm-6" style="min-width: 803px;">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-user"></i> DADOS DO CLIENTE</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>CPF/CNPJ</label>
          <div class="input-group input-group-sm">
            <input type="text" class="form-control" [(ngModel)]="cpf" aria-describedby="basic-addon2" style="height: 26px;">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" (click)="pesquisarCpf(); getPesquisarBoletoAntigo()"><i class="fas fa-search"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-9">
          <label>Nome</label>
          <input type="text" class="form-control" [(ngModel)]="nome" />
        </div>
        <div class="form-group col-sm-1">
          <label>DDD</label>
          <input class="form-control" type="text" [(ngModel)]="ddd" />
        </div>
        <div class="form-group col-sm-2">
            <label>TELEFONE</label>
            <input class="form-control" type="text" [(ngModel)]="fone" />
        </div>
      </div>
      <div class="form-row">
          <div class="form-group col-sm-12">
            <label>E-mail</label>
            <input type="text" class="form-control" [(ngModel)]="email" />
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-map-marker-alt"></i> Endereço</h3>
          </div>
      </div>
      <div class="form-row">
          <div class="form-group col-sm-4" style="min-width: 140px;">
            <label>CEP</label>
            <div class="input-group input-group-sm">
              <p-inputMask type="text" [(ngModel)]="cep" mask="99999-999" placeholder="99999-999" (keyup.enter)="pesquisarCep();" aria-describedby="basic-addon2"></p-inputMask>
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="pesquisarCep()"><i class="fas fa-search"></i></button>
              </div>
            </div>
          </div>
      </div>
      <div class="form-row">
          <div class="form-group col-sm-10">
            <label>Rua</label>
            <input type="text" class="form-control" [(ngModel)]="rua" />
          </div>
          <div class="form-group col-sm-2">
              <label>Número</label>
              <input type="text" class="form-control" [(ngModel)]="numero" />
          </div>
      </div>
      <div class="form-row">
          <div class="form-group col-sm-6">
              <label>Bairro</label>
              <input type="text" class="form-control"  [(ngModel)]="bairro" />
          </div>
          <div class="form-group col-sm-6">
              <label>Complemento</label>
              <input type="text" class="form-control"  [(ngModel)]="complemento" />
          </div>
      </div>
      <div class="form-row">
          <div class="form-group col-sm-2">
            <label>UF</label>
            <p-dropdown
              class="type-p-dropdown"
              [filter]="true"
              placeholder="Selecione"
              [options]="opcoesUf"
              [(ngModel)]="uf" (onChange)="comboMunicipio()"
            ></p-dropdown>
          </div>
          <div class="form-group col-sm-10">
              <label>MUNICÍPIO</label>
              <p-dropdown
                class="type-p-dropdown"
                [filter]="true"
                placeholder="Selecione"
                [options]="opcoesMunicipio"
                [(ngModel)]="municipio"
              ></p-dropdown>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-money-check"></i> Informações do Boleto</h3>
          </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
            <label>DATA PRIMEIRO VENCTO</label>
            <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [(ngModel)]="vencimento" [monthNavigator]="true"
            [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2010:2030" ></p-calendar>
        </div>
        <div class="form-group col-sm-6">
            <label>Nº PARCELAS</label>
            <input type="text" class="form-control text-right" placeholder="Nº de Parcelas" [(ngModel)]="numeroParcelas" />
        </div>
        <div class="form-group col-sm-6">
            <label>R$ VALOR DA PARCELA</label>
            <input type="number" class="form-control" [(ngModel)]="valor"/>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>DESCRIÇÃO</label>
          <input type="text" class="form-control" [(ngModel)]="descricao" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>INSTRUÇÕES</label>
          <input type="text" class="form-control" [(ngModel)]="instrucoes" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-money-check"></i> Informar o boleto antigo para o cancelamento</h3>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>BOLETO ANTIGO</label>
          <p-dropdown
            class="type-p-dropdown"
            [filter]="true"
            placeholder="Selecione"
            [options]="listaBoletoAntigo"
            [(ngModel)]="boletoAntigoId"
          ></p-dropdown>
        </div>
      </div>



      <div class="row">
        <div class="col-sm-12">
            <button type="button" class="btn btn-primary" (click)="gerarBoleto()"><i class="fas fa-check"></i> GERAR</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
