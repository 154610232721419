<app-data-table mainTitle="Crowds"
                singleTitle="Crowd"
                application="proof"
                routerPath="/crowd"
                entityPath="/authcrowd"
                orderColumns="name"
                [entity]="authCrowd"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>
