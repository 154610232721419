<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <div class="box-content">

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"></i> Tipo de Relatório</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Relatório</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione o Relatório" appendTo="body"
            [options]="opcoesRelatorio" [(ngModel)]="relatorioSelecionado">
          </p-dropdown>
        </div>
      </div>

      <div *ngIf="relatorioSelecionado">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-users"></i> Destinatários</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-6">
            <label>E-mail</label>
            <input class="form-control" placeholder="Insira os destinatarios, separe cada e-mail com ;"
              [(ngModel)]="relatorioAgendado.destinatarios" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-building"></i> Selecionar Empresas</h3>
          </div>
          <div class="col-sm-6">
            <p-pickList sourceHeader="Disponíveis" targetHeader="Selecionadas" [source]="empresas"
              [target]="empresasSelecionadas" [dragdrop]="true" [responsive]="true" [showSourceControls]="false"
              [showTargetControls]="false">
              <ng-template let-emp pTemplate="item">{{emp.cnpj | cnpj}} - {{emp.nomeFantasia}}</ng-template>
            </p-pickList>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title mt-4"><i class="fas fa-calendar-alt"></i> Vencimento</h3>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12">
            <!-- <p-selectButton [options]="recorrencias" [(ngModel)]="relatorioAgendado.recorrencia" (click)="modalRecorrencia()"></p-selectButton> -->
            <mat-radio-group [(ngModel)]="relatorioAgendado.recorrencia" (ngModelChange)="modalRecorrencia()">
              <mat-radio-button class="mr-3" *ngFor="let rec of recorrencias" [value]="rec">
                {{rec.descricao}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <button class="btn btn-primary" type="button" (click)="agendarRelatorio()"
              [disabled]="(!relatorioAgendado.destinatarios || empresasSelecionadas.length === 0 || !relatorioAgendado.recorrencia.id)"><i
                class="fas fa-check"></i> Salvar</button>
          </div>
        </div>

        <!-- Modal Recorrencia -->
        <p-dialog [(visible)]="exbirModalRecorrencia" [style]="{width: '800px'}" [responsive]="true" [modal]="true"
          showEffect="fade" appendTo="body" styleClass="modal-scroll custom" [closable]="false">
          <p-header>
            <div *ngIf="relatorioAgendado.recorrencia.id === 1">Vencimento Único</div>
            <div *ngIf="relatorioAgendado.recorrencia.id === 2">Vencimento Semanal</div>
            <div *ngIf="relatorioAgendado.recorrencia.id === 3">Vencimento Mensal</div>
            <div *ngIf="relatorioAgendado.recorrencia.id === 4">Vencimento Anual</div>
          </p-header>


          <div *ngIf="relatorioAgendado.recorrencia.id === 1">
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Vencimento</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col">
                <p-checkbox ng-value="true" [ngModel]="antecipaDiaNaoUtil" [binary]="true">Antecipa Dia Não Útil</p-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="relatorioAgendado.recorrencia.id === 2">
            <div class="form-row">
              <div class="form-group col-sm-2">
                <label>Repete a cada</label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
                <label>(semanas)</label>
              </div>
              <div class="form-group ml-3 col">
                <label class="d-block" style="margin-top: 5px;">Repete</label>
                <p-checkbox class="mr-3" value=true label="D" [(ngModel)]="dom" [binary]="true">D</p-checkbox>
                <p-checkbox class="mr-3" value=true label="S" [(ngModel)]="seg" [binary]="true">S</p-checkbox>
                <p-checkbox class="mr-3" value=true label="T" [(ngModel)]="ter" [binary]="true">T</p-checkbox>
                <p-checkbox class="mr-3" value=true label="Q" [(ngModel)]="qua" [binary]="true">Q</p-checkbox>
                <p-checkbox class="mr-3" value=true label="Q" [(ngModel)]="qui" [binary]="true">Q</p-checkbox>
                <p-checkbox class="mr-3" value=true label="S" [(ngModel)]="sex" [binary]="true">S</p-checkbox>
                <p-checkbox class="mr-3" value=true label="S" [(ngModel)]="sab" [binary]="true">S</p-checkbox>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Início Em</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" appendTo="body"></p-calendar>
              </div>
              <div class="form-group col-sm-3">
                <label>Termina Em</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
              <div class="form-group col-sm-3">
                <label>Termina Após</label>
                <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
                <label>ocorrências</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-grouo col">
                <p-checkbox ng-value="true" [ngModel]="antecipaDiaNaoUtil" [binary]="true">Antecipa Dia Não Útil</p-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="relatorioAgendado.recorrencia.id === 3">
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Dia do mês</label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [options]="dias" [(ngModel)]="diaMes"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>Repete a cada meses</label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
              </div>
              <div class="form-group col-sm-3">
                <label>Início Em</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
              <div class="form-group col-sm-3">
                <label>Termina Em</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Termina Após</label>
                <input type="text" class="form-control" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
                <label>ocorrências</label>
              </div>
              <div class="form-group col">
                <label class="d-block" style="margin-top: 6px;">Período</label>
                <p-checkbox ng-value="true" [ngModel]="antecipaDiaNaoUtil" [binary]="true">Antecipa Dia Não Útil</p-checkbox>
                <p-checkbox class="ml-3" value=true [binary]="true" [(ngModel)]="diaUtil" [disabled]="diaMes  > 20">Dia útil
                </p-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="relatorioAgendado.recorrencia.id === 4">
            <div class="form-row">
              <div class="form-group col-sm-3">
                <label>Repete a cada</label>
                <p-dropdown class="type-p-dropdown" appendTo="body" [options]="qtdes" [(ngModel)]="aCada"></p-dropdown>
                <label>anos</label>
              </div>
              <div class="form-group col-sm-3">
                <label>Início Em</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtIni"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
              <div class="form-group col-sm-3">
                <label>Termina Em</label>
                <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"></p-calendar>
              </div>
              <div class="form-group col-sm-3">
                <label>Termina Após</label>
                <input class="form-control" type="text" [(ngModel)]="qtdeOcorrencia" [disabled]="dtFin===null" />
                <label>ocorrências</label>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <label class="d-block" style="visibility: hidden; margin-top: 6px;">DO NOT REMOVE</label>
                <p-checkbox ng-value="true" [ngModel]="antecipaDiaNaoUtil" [binary]="true">Antecipa Dia Não Útil</p-checkbox>
              </div>
            </div>
          </div>
          <p-footer>
            <button type="button" class="btn btn-secondary" (click)='exbirModalRecorrencia=false'><i
                class="fa fa-undo"></i> Fechar</button>
          </p-footer>
        </p-dialog>
      </div>
    </div>
  </mat-card-content>
</mat-card>