<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <mat-card-content>
    <div class="box-content pt-0">

      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"> &nbsp;</h3>
          <div class="actions-card">
            <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
              matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <p-table class="table-system" #dt2 [columns]="colsMotivoEstorno" [value]="opcoesMotivoEstorno" [rows]="rows"
        [totalRecords]="opcoesMotivoEstorno.length" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox">
              <span class="check-all p-0">
                <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
              </span>
            </th>
            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'cpf', 'contains')">
            </th>



            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'email', 'contains')">
            </th>


            <th class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                <span class="text-overflow-dynamic-ellipsis">FONE <p-sortIcon [field]="'fone'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'fone', 'contains')">
            </th>

            <th class="text-left" style="width: 30%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'motivo'">
                <span class="text-overflow-dynamic-ellipsis">MOTIVO <p-sortIcon [field]="'motivo'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'motivo', 'contains')">
            </th>

            <th class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'observacao'">
                <span class="text-overflow-dynamic-ellipsis">OBSERVAÇÃO <p-sortIcon [field]="'observacao'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'observacao', 'contains')">
            </th>

            <th class="text-right" style="width: 11%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                <span class="text-overflow-dynamic-ellipsis">VALOR <p-sortIcon [field]="'valor'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'valor', 'contains')">
            </th>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoStatus'">
                <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'descricaoStatus'">
                  </p-sortIcon></span>
              </span>
              <p-multiSelect class="type-p-mult" [options]="opcoesComboMotivoEstorno" defaultLabel="Selecione"
                [filter]="false" (onChange)="dt2.filter($event.value, 'descricaoStatus', 'in')"></p-multiSelect>
            </th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center tableCheckbox">
              <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}"><span
                    style="user-select: all; cursor: text">{{rowData.nome }} </span> </span>
              </span>
            </td>

            <td class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf}}"><span
                    style="user-select: all; cursor: text"> {{rowData.cpf}} </span></span>
              </span>
            </td>
            <td class="text-center" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}"><span
                    style="user-select: all; cursor: text"> {{rowData.email}} </span></span>
              </span>
            </td>
            <td class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fone}}"><span
                    style="user-select: all; cursor: text"> {{rowData.fone }} </span> </span>
              </span>
            </td>

            <td class="text-left" style="width: 8%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.motivo}}"><span
                    style="user-select: all; cursor: text"> {{rowData.motivo }} </span></span>
              </span>
            </td>

            <td class="text-left" style="width: 15%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.observacao}}"><span
                    style="user-select: all; cursor: text">{{rowData.observacao }}</span></span>
              </span>
            </td>


            <td class="text-right" style="width: 11%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}"><span
                    style="user-select: all; cursor: text">{{rowData.valor }}</span></span>
              </span>
            </td>

            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricaoStatus}}"><span
                    style="user-select: all; cursor: text">{{rowData.descricaoStatus }}</span></span>
              </span>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="13" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </mat-card-content>
</mat-card>

<!--MODAL EDITAR ESTORNO -->
<div *ngIf="motivosEstornosEventos.length > 0">
  <p-dialog header="Detalhes Estorno: {{motivosEstornosEventos[0].nome}}" [(visible)]="modalEditEstorno"
    styleClass="modal-scroll" [style]="{'font-size': '12px'}" [style]="{width: '800px'}" [modal]="true" appendTo="body"
    [closable]="true">

    <div class="row">
      <div class="form-group col-sm-9">
        <strong>STATUS:</strong> {{ motivosEstornosEventos[0].descricaoStatus }}
      </div>
      <div class="form-group col-sm-3">
        <strong>SOLICITADO EM:</strong> {{ motivosEstornosEventos[0].dtHrSolicitacao }}
      </div>
    </div>


    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-envelope-open-text"></i> INFORMAÇÕES CADASTRAIS</h3>
      </div>
    </div>


    <div class="form-group col-sm-12">
      <strong>MOTIVO:</strong> {{ motivosEstornosEventos[0].motivo }}
    </div>



    <div class="form-group col-sm-12">
      <strong>OBSERVAÇÃO:</strong> {{ motivosEstornosEventos[0].observacao }}
    </div>



    <div class="form-group col-sm-12">
      <strong>FORMA PAGAMENTO:</strong> {{ motivosEstornosEventos[0].descricaoPagamento }}
    </div>



    <div *ngIf="motivosEstornosEventos[0].estornoFormaPagamentoId === 2">

      <div class="form-group col-sm-12">
        <strong>BANCO:</strong> {{ motivosEstornosEventos[0].codigoBancario }} - {{ motivosEstornosEventos[0].bancoNome
        }}
      </div>



      <div class="form-group col-sm-12">
        <span><strong>AGÊNCIA:</strong> {{ motivosEstornosEventos[0].agenciaBanco }} </span> <span class="ml-3">
          <strong>CONTA:</strong> {{ motivosEstornosEventos[0].contaCorrente }} </span>
      </div>
    </div>






    <div class="form-group col-sm-12">
      <strong>VALOR:</strong> {{ motivosEstornosEventos[0].valor }}
    </div>


    <div *ngIf="motivosEstornosEventos[0].status === 'RECUSADO' || motivosEstornosEventos[0].status === 'APROVADO'">

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-user"></i> APROVADOR</h3>
        </div>
      </div>

      <div class="form-group col-sm-12" *ngIf="motivosEstornosEventos[0].dtHrAprov">
        <strong>DATA APROVAÇÃO:</strong> {{ motivosEstornosEventos[0].dtHrAprov }} - {{
        motivosEstornosEventos[0].usuarioAprov }}
      </div>
      <div class="form-group col-sm-12" *ngIf="motivosEstornosEventos[0].dtHrRec">
        <strong>DATA RECUSA:</strong> {{ motivosEstornosEventos[0].dtHrRec }} - {{
        motivosEstornosEventos[0].usuarioAprov }}
      </div>

      <div *ngIf="motivosEstornosEventos[0].dtHrAprov">

        <div class="row">
          <div class="col-sm-12">
            <h3 class="sub-title"><i class="far fa-file-pdf"></i> ANEXO</h3>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-6 mb-1 mt-1">
            <label class="d-block">Selecione um arquivo</label>
            <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
              <span class="input-group-append">
                <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                          Remover Arquivo
                        </span> -->
                <span class="input-group-text btn-file">
                  <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                    Arquivo</span>
                  <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                    Arquivo</span> <!-- Antes era Trocar Arquivo-->
                  <input type="file" #file (change)="fileChange()" />
                </span>
              </span>
              <div class="form-control" data-trigger="fileinput">
                <span class="fileinput-filename" id="file-name"></span>
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 mb-1 mt-1">
            <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
            <button type="button" class="btn btn-primary" [disabled]="importDisabled" (click)="importar()"><i
                class="fas fa-upload"></i> Importar</button>
          </div>
        </div>

      </div>

    </div>


    <div *ngIf="motivosEstornosEventos[0].statusEstornoId === 1">

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-user"></i> APROVADOR</h3>
        </div>
      </div>

      <div class="form-group col-sm-12" *ngIf="motivosEstornosEventos[0].dtHrAprov">
        <strong>DATA APROVAÇÃO:</strong> {{ motivosEstornosEventos[0].dtHrAprov }} - {{
        motivosEstornosEventos[0].usuarioAprov }}
      </div>
      <div class="form-group col-sm-12" *ngIf="motivosEstornosEventos[0].dtHrRec">
        <strong>DATA RECUSA:</strong> {{ motivosEstornosEventos[0].dtHrRec }} - {{
        motivosEstornosEventos[0].usuarioAprov }}
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-envelope-open-text"></i> COMPROVANTE</h3>
        </div>
      </div>

      <div class="form-group col-sm-12">
        <strong>DATA ENVIO:</strong> {{ motivosEstornosEventos[0].dtHrComp }} - {{ motivosEstornosEventos[0].usuarioComp
        }}
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="far fa-file-pdf"></i> ANEXO</h3>
          <div *ngFor="let a of motivosEstornosEventos[0].anexos" (click)="baixarAnexo()">
            <div class="form-group col-sm-10" style="cursor:pointer">
              {{ a }}
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="ui-g" style="position: fixed; right: 0px; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>

    <p-footer>
      <div *ngIf="motivosEstornosEventos[0].statusEstornoId === 5">
        <div class="row">
          <div class="col-sm-12">
            <button type="button" class="btn btn-primary" (click)="reverterEstorno()"><i class="fas fa-undo"></i>
              Reverter Estorno</button>
            <button type="button" class="btn btn-primary" (click)="solicitarAprovacao()"><i class="far fa-envelope"></i>
              Solicitar Aprovação</button>
          </div>
        </div>
      </div>
    </p-footer>
  </p-dialog>
</div>