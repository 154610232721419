<div class="main-container">
  <div class="layer-background"></div>
  <div class="header">
    <img src="assets/images/logo-octalink-white.png" alt="Octalink" title="Octalink">
  </div>
  <div class="content">
    <div class="box-login" *ngIf="!forgetPassword && !resetting">
      <div class="box-header">
        <p>Seja bem-vindo!</p>
        <h2>Acesse nossa plataforma</h2>
      </div>
      <div class="box-content">
        <div class="error" *ngIf="errorMessage">
          <p>{{ errorMessage }}</p>
        </div>
        <form [formGroup]="form" (ngSubmit)="doLogin()" autocomplete="off">
          <div class="input-group" [ngClass]="applyError('email')">
            <label><span class="material-icons">email</span> E-mail:</label>
            <input type="text" class="input" formControlName="email" #fieldEmail [(ngModel)]="email">
            <span class="error-span" *ngIf="inputValidation('email')">
              <span class="material-icons">info</span> Campo inválido
            </span>
          </div>
          <div class="input-group" [ngClass]="applyError('password')">
            <label><span class="material-icons">lock</span> Senha:</label>
            <div class="password">
              <input [type]="viewPassword ? 'text' : 'password'" class="input" formControlName="password" #fieldPassword [(ngModel)]="password">
              <button type="button" class="show-btn" (click)="showPassword()">
                <span *ngIf="viewPassword" class="material-icons">visibility</span>
                <span *ngIf="!viewPassword" class="material-icons">visibility_off</span>
              </button>
            </div>
            <span class="error-span" *ngIf="inputValidation('password')">
              <span class="material-icons">info</span> Campo inválido
            </span>
            <!-- <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('required')">
              <span class="material-icons">info</span> Campo inválido
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('length')">
              <span class="material-icons">info</span> A senha deve conter entre 6 a 15 caracteres
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('lowercase')">
              <span class="material-icons">info</span> A senha deve conter pelo menos uma letra MINÚSCULA
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('uppercase')">
              <span class="material-icons">info</span> A senha deve conter pelo menos uma letra MAIÚSCULA
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('special')">
              <span class="material-icons">info</span> A senha deve conter pelo menos um caractere especial
            </span> -->
          </div>
          <!-- <div class="checkbox">
            <p-checkbox id="connected"></p-checkbox>
            <label for="connected">Mantenha-me conectado!</label>
          </div> -->
          <div class="send">
            <button class="btn" type="submit">Entrar</button>
          </div>
          <div class="forgot-password">
            <p (click)="onForgetPassword()">Esqueceu sua senha?</p>
          </div>
        </form>
      </div>
    </div>
    <div class="box-login" *ngIf="forgetPassword && !resetting && !resetState">
      <div class="box-header">
        <div class="back" (click)="onForgetPassword()">
          <span class="material-icons">arrow_back</span> Voltar
        </div>
        <p>Preencha seu e-mail para receber instruções</p>
        <h2>Redefinir senha!</h2>
      </div>
      <div class="box-content">
        <form [formGroup]="form" (ngSubmit)="reset()" autocomplete="off">
          <div class="input-group" [ngClass]="applyError('email')">
            <label><span class="material-icons">email</span> E-mail:</label>
            <input type="text" class="input" formControlName="email" #fieldEmail [(ngModel)]="email">
            <span class="error-span" *ngIf="inputValidation('email')">
              <span class="material-icons">info</span> Campo inválido
            </span>
          </div>
          <div class="send">
            <button [disabled]="resetFormValid()" class="btn" type="submit">Solicitar</button>
          </div>
        </form>
      </div>
    </div>
    <div class="box-response-mail" *ngIf="forgetPassword && !resetting && resetState" [ngClass]="{ hasError: resetErrorMessage }">

      <div class="box-content" *ngIf="!resetErrorMessage">
        <span class="material-icons">check_circle</span>
        <h2>Tudo certo!</h2>
        <p>Cheque seu e-mail para prosseguir com a redefinição de senha!</p>
      </div>
      <div class="box-content" *ngIf="resetErrorMessage">
        <span class="material-icons">report</span>
        <h2>Oops!</h2>
        <p>O e-mail {{email}} não consta ativado em nosso sistema!</p>
        <small (click)="tryAgain()">Tentar novamente</small>
      </div>
    </div>
    <div class="box-login" *ngIf="resetting">
      <div class="box-header">
        <p>Preencha as informações</p>
        <h2>Redefinição de senha!</h2>
      </div>
      <div class="box-content">
        <form [formGroup]="form"(ngSubmit)="doLogin()" autocomplete="off">
          <div class="input-group" [ngClass]="applyError('email')">
            <label><span class="material-icons">email</span> E-mail:</label>
            <input type="text" class="input" formControlName="email" #fieldEmail [(ngModel)]="email">
            <span class="error-span" *ngIf="inputValidation('email')">
              <span class="material-icons">info</span> Campo inválido
            </span>
          </div>
          <div class="input-group" [ngClass]="applyError('password')">
            <label><span class="material-icons">lock</span> Nova senha:</label>
            <div class="password">
              <input [type]="viewPassword ? 'text' : 'password'" class="input" formControlName="password" #fieldPassword [(ngModel)]="password">
              <button type="button" class="show-btn" (click)="showPassword()">
                <span *ngIf="viewPassword" class="material-icons">visibility</span>
                <span *ngIf="!viewPassword" class="material-icons">visibility_off</span>
              </button>
            </div>
            <span class="error-span" *ngIf="inputValidation('password')">
              <span class="material-icons">info</span> Campo inválido
            </span>
            <!-- <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('required')">
              <span class="material-icons">info</span> Campo inválido
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('length')">
              <span class="material-icons">info</span> A senha deve conter entre 6 a 15 caracteres
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('lowercase')">
              <span class="material-icons">info</span> A senha deve conter pelo menos uma letra MINÚSCULA
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('uppercase')">
              <span class="material-icons">info</span> A senha deve conter pelo menos uma letra MAIÚSCULA
            </span>
            <span class="error-span" *ngIf="inputValidation('password') && form.get('password').hasError('special')">
              <span class="material-icons">info</span> A senha deve conter pelo menos um caractere especial
            </span> -->
          </div>
          <div class="input-group" [ngClass]="{ hasError : form.get('passwordConfirm').hasError('equalsTo')}">
            <label><span class="material-icons">lock</span> Confirme sua senha:</label>
            <div class="password">
              <input [type]="viewPasswordConfirm ? 'text' : 'password'" class="input" formControlName="passwordConfirm" [(ngModel)]="passwordConfirm">
              <button type="button" class="show-btn" (click)="showPasswordConfirm()">
                <span *ngIf="viewPasswordConfirm" class="material-icons">visibility</span>
                <span *ngIf="!viewPasswordConfirm" class="material-icons">visibility_off</span>
              </button>
            </div>
            <span class="error-span" *ngIf="form.get('passwordConfirm').hasError('equalsTo')">
              <span class="material-icons">info</span> As senhas não coincidem
            </span>
          </div>
          <div class="send">
            <button [disabled]="!form.valid" class="btn" type="submit">Entrar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="footer">
    <a href="mailto:suporte@octalink.com.br?Subject=Olá!&body=Preciso de ajuda.">
      Precisando de ajuda? Contate nosso suporte!
    </a>
  </div>
</div>