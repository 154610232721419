<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>



    <div class="row">
      <div class="col-md-6" style="min-width: 818px;">
        <form *ngIf="loaded" [formGroup]="formGroup" novalidate autocomplete="off">
          <mat-tab-group class="overflow-tabs" dynamicHeight="true" [(selectedIndex)]="tabIndex"
            (selectedTabChange)="tabChange()">

            <mat-tab label="Geral" appendTo="body">
              <div class="box-content pt-3">
                <div #formContainer>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <label>Razão Social</label>
                      <input class="form-control" type="text" formControlName="razaoSocial" minlength="3"
                        maxlength="90">
                    </div>
                    <div class="form-group col-sm-12">
                      <label>Nome Fantasia</label>
                      <input class="form-control" type="text" formControlName="nomeFantasia" minlength="3"
                        maxlength="90">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <label>CNPJ</label>
                      <input class="form-control" type="text" formControlName="cnpj" minlength="14" maxlength="14">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-6">
                      <label>Inscrição Municipal</label>
                      <input class="form-control" type="text" formControlName="im" placeholder="Inserir só números">
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Inscrição Estadual</label>
                      <input class="form-control" type="text" formControlName="ie" placeholder="Inserir só números">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-10">
                      <label>E-mail</label>
                      <input class="form-control" type="email" formControlName="email" maxlength="115">
                    </div>
                    <div class="form-group col-sm-2">
                      <label>Telefone</label>
                      <input class="form-control" type="text" formControlName="telefone">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-10">
                      <label>Endereço</label>
                      <input class="form-control" type="text" formControlName="endereco" maxlength="150">
                    </div>
                    <div class="form-group col-sm-2">
                      <label class="texto">Número</label>
                      <input class="form-control" type="text" formControlName="numero" maxlength="6">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-6">
                      <label>Bairro</label>
                      <input class="form-control" type="text" formControlName="bairro" maxlength="50">
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Complemento</label>
                      <input class="form-control" type="text" formControlName="complemento" maxlength="50">
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-3">
                      <label>CEP</label>
                      <input class="form-control" formControlName="cep">
                    </div>
                    <div class="form-group col-sm-3">
                      <label>UF</label>
                      <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="uf" [options]="ufs" defaultLabel="Selecione"
                            [filter]="false"></p-dropdown> -->
                      <mat-select formControlName="uf.id">
                        <mat-option disabled>Selecione</mat-option>
                        <mat-option *ngFor="let uf of ufs" [value]="uf.id">{{ uf.uf }}</mat-option>
                      </mat-select>
                    </div>
                    <div class="form-group col-sm-6">
                      <label class="texto">Município</label>
                      <p-dropdown class="type-p-dropdown" [options]="municipios"
                          defaultLabel="Selecione" [filter]="true" appendTo="body" optionLabel="nome" optionValue="id" formControlName="municipioIbge.id"></p-dropdown>
                      <!-- <mat-select formControlName="municipioIbge.id">
                        <mat-option>Selecione</mat-option>
                        <mat-option *ngFor="let municipio of municipios" [value]="municipio.id">{{ municipio.nome }}
                        </mat-option>
                      </mat-select> -->
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <label class="texto">NTZ. Jurídica (DIRF)</label>
                      <p-dropdown class="type-p-dropdown" [options]="naturezaJuridicaList"
                          defaultLabel="Selecione" appendTo="body" [filter]="true" optionLabel="descricao" optionValue="id" formControlName="naturezaJuridica.id">
                        </p-dropdown>
                      <!-- <mat-select formControlName="naturezaJuridica.id">
                        <mat-option>Selecione</mat-option>
                        <mat-option *ngFor="let n of naturezaJuridicaList" [value]="n.id">{{ n.id }} - {{ n.descricao }}
                        </mat-option>
                      </mat-select> -->
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <label class="texto">NTZ. Jurídica (ECF)</label>
                      <p-dropdown class="type-p-dropdown" appendTo="body"
                          [options]="naturezaJuridicaEcfList" defaultLabel="Selecione" [filter]="true" optionLabel="descricao" optionValue="id" formControlName="naturezaJuridicaEcf.id"></p-dropdown>
                      <!-- <mat-select formControlName="naturezaJuridicaEcf.id">
                        <mat-option>Selecione</mat-option>
                        <mat-option *ngFor="let natureza of naturezaJuridicaEcfList" [value]="$any(natureza).id">{{
                          $any(natureza).codigo }} -
                          {{ $any(natureza).descricao }}</mat-option>
                      </mat-select> -->
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <label class="texto">Regime Tributário</label>
                      <p-dropdown class="type-p-dropdown" [options]="regimeTributarioList"
                          defaultLabel="Selecione" appendTo="body" [filter]="true" optionLabel="descricao" optionValue="id" formControlName="regimeTributario.id"></p-dropdown>
                      <!-- <mat-select formControlName="regimeTributario.id">
                        <mat-option>Selecione</mat-option>
                        <mat-option *ngFor="let rt of regimeTributarioList" [value]="rt.id">{{ rt.descricao }}
                        </mat-option>
                      </mat-select> -->
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-sm-12">
                      <label>CNAE-Fiscal <span class="icon-info" matTooltipPosition="right"
                          matTooltip="Se houver mais de uma atividade, indicar a de maior faturamento!">
                          <i class="fas fa-question-circle"></i>
                        </span></label>
                      <input class="form-control" type="text" formControlName="cnae" maxlength="7">
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col">
                    <!-- <button mat-raised-button class="mat-elevation-z0">Cancelar</button>
                    <button class="botao-excluir" type="button" pButton label="Excluir"></button> -->
                    <button type="button" class="btn btn-primary" (click)="save()"><i class="fas fa-check"></i>
                      Salvar</button>
                  </div>
                </div>
              </div>
            </mat-tab>


            <mat-tab *ngFor="let year of years" [label]="year.toString()">
              <div class="box-content" *ngIf="tabReady && tabIndex > 0 && year === years[tabIndex - 1]">
                <p-accordion class="custom-tab">
                  <p-accordionTab matTooltip="Registro 0000 - Abertura" header="Registro 0000 - Abertura">
                    <div class="form-row">
                      <div class="form-group col">
                        <label>Tipo da ECD</label>

                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="tipoEcds"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]tipoEcd.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of tipoEcds" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <!-- {{tipoEcds | json}} -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="tipoEcds"
                          formControlName="empresaPeriodos[periodo={{year}}]tipoEcd.id" emptyMessage="Selecione"
                          [filter]="false" optionLabel="descricao" optionValue="id">
                        </p-dropdown>
                      </div>

                      <div class="form-group col">
                        <label>Tipo da ECF</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="tipoEcfs"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->
                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]tipoEcf.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of tipoEcfs" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedTipoEcfs" [options]="tipoEcfs"
                          formControlName="empresaPeriodos[periodo={{year}}]tipoEcf.id" emptyMessage="Selecione"
                          [filter]="false" optionLabel="descricao" optionValue="id">
                        </p-dropdown>
                      </div>

                      <div class="form-group col" *ngIf="displayCodScp">
                        <label>CNPJ da SCP</label>
                        <input class="form-control" placeholder="" type="text"
                          formControlName="empresaPeriodos[periodo={{year}}]codScp" minlength="14" maxlength="14">
                      </div>
                    </div>
                  </p-accordionTab>
                  <!--
              <br>
              TODO:<br>
              * Indicador de Situac¸a~o Especial e Outros Eventos SIT_ESPECIAL<br>
              * Data da Situac¸a~o Especial ou Evento DT_SIT_ESP<br>
              * Escriturac¸a~o retificadora ou escriturac¸a~o com mudanc¸a de forma de tributac¸a~o RETIFICADORA<br>
              <br>
              -->
                  <p-accordionTab matTooltip="Registro 0010 - Parâmetros de Tributação"
                    header="Registro 0010 - Parâmetros de Tributação">
                    <div class="form-row">
                      <div class="form-group col">
                        <label class="texto">Hash ECD Anterior</label>
                        <input class="form-control" type="text"
                          formControlName="empresaPeriodos[periodo={{year}}]hashEcfAnterior" minlength="4"
                          maxlength="4">
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle class="mr-2" formControlName="empresaPeriodos[periodo={{year}}]optRefis">
                          Optante pelo Refis
                        </mat-slide-toggle>
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]optPaes">Optante pelo Paes
                        </mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <label>Forma de tributação</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="formaTribs"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]formaTrib.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of formaTribs" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedFormTribs" [options]="formaTribs"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]formaTrib.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>

                      <div class="form-group col">
                        <label>Período de Apuração do IRPJ e da CSLL</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="formaApurs"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]formaApur.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of formaApurs" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->

                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedFormaApurs" [options]="formaApurs"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]formaApur.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <label>Qualificação da Pessoa Jurídica</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="codQualifPjs"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]codQualifPj.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of codQualifPjs" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedCodQualifPjs" [options]="codQualifPjs"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]codQualifPj.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>

                      <div class="form-group col">
                        <label>Tipo da Escrituração</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="tipEscPres"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]tipEscPre.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of tipEscPres" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedTipEscPres" [options]="tipEscPres"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]tipEscPre.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <label>Tipo de Pessoa Jurídica Imune ou Isenta</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="tipEnts" defaultLabel="Selecione"
                            [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]tipEnt.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of tipEnts" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedTipEnts" [options]="tipEnts"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]tipEnt.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>

                      <div class="form-group col">
                        <label>Apuração do IRPJ para Imunes ou Isentas</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="formaApurIs"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]formaApurI.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of formaApurIs" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedFormaApurIs" [options]="formaApurIs"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]formaApurI.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <label>Apuração da CSLL para Imunes ou Isentas</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="apurCslls"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]apurCsll.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of apurCslls" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->

                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedApurCslls" [options]="apurCslls"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]apurCsll.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>

                      <div class="form-group col">
                        <label>Critério de reconhecimento empresas</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="indRecReceitas"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]indRecReceita.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of indRecReceitas" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->

                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedIndRecReceitas"
                          [options]="indRecReceitas" optionLabel="descricao"
                          formControlName="empresaPeriodos[periodo={{year}}]indRecReceita.id" optionValue="id">
                        </p-dropdown>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-file-alt"></i> Formas de Tributação no Período</h3>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-sm-6" *ngFor="let q of quarters; let index = index;">
                        <label>{{ q }}º trimestre</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="formaTribPers"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder=""
                          formControlName="empresaPeriodos[periodo={{year}}]formaTribPerRelacs[periodo={{q}}]formaTribPer.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of formaTribPers" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [options]="formaTribPers" optionLabel="descricao"
                          formControlName="empresaPeriodos[periodo={{year}}]formaTribPerRelacs[periodo={{q}}]formaTribPer.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-12">
                        <h3 class="sub-title"><i class="fas fa-file-alt"></i> Formas de Apuração da Estimativa Mensal
                        </h3>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-6" *ngFor="let m of months; let index = index">
                        <label>{{ m.description }}</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="mesBalReds"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder=""
                          formControlName="empresaPeriodos[periodo={{year}}]mesBalRedRelacs[periodo={{m.id}}]mesBalRed.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of mesBalReds" [value]="f.id"
                            [disabled]="mesBalRedDisabled(f.id, year, m.id)">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [options]="mesBalReds" optionLabel="descricao"
                          formControlName="empresaPeriodos[periodo={{year}}]mesBalRedRelacs[periodo={{m.id}}]mesBalRed.id"
                          optionValue="id">
                          <ng-template let-option pTemplate="item">
                            <div>
                              <div (click)="mesBalRedDisabled(option.id, year, m.id) "
                                [ngStyle]="{'color':mesBalRedDisabled(option.id, year, m.id) ? '#ccc':'', 'cursor': 'auto'}">
                                {{option.descricao}}
                              </div>
                            </div>
                          </ng-template>
                        </p-dropdown>
                      </div>
                    </div>
                  </p-accordionTab>

                  <p-accordionTab matTooltip="Registro 0020 - Parâmetros Complementares"
                    header="Registro 0020 - Parâmetros Complementares">
                    <div class="row align-items-start">
                      <div class="form-group col-md-6 pr-0">
                        <label>PJ sujeita à alíquota da CSLL de 9%, 17% ou 20% em 31/12/2015</label>
                        <!-- <p-dropdown class="type-p-dropdown" [(ngModel)]="f" [options]="indAliqCslls"
                            defaultLabel="Selecione" [filter]="false"></p-dropdown> -->

                        <!-- <mat-select placeholder="" formControlName="empresaPeriodos[periodo={{year}}]indAliqCsll.id">
                          <mat-option disabled>Selecione</mat-option>
                          <mat-option *ngFor="let f of indAliqCslls" [value]="f.id">{{ f.cod }} - {{ f.descricao }}
                          </mat-option>
                        </mat-select> -->
                        <p-dropdown class="type-p-dropdown" [(ngModel)]="selectedIndAliqCslls" [options]="indAliqCslls"
                          optionLabel="descricao" formControlName="empresaPeriodos[periodo={{year}}]indAliqCsll.id"
                          optionValue="id">
                        </p-dropdown>
                      </div>
                      <div class="form-group col-md-6">
                        <label>Quantidade total de SCP do Sócio Ostensivo de SCP</label>
                        <input class="form-control" placeholder="" type="number"
                          formControlName="empresaPeriodos[periodo={{year}}]indQteScp">
                      </div>

                      <div class="form-row">
                        <div class="form-group col">

                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indAdmFunClu">Administradora
                          de Fundos e Clubes de Investimento</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indAdmFunClu">Administradora
                          de Fundos e Clubes de Investimento</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPartCons">Participações
                          em Consórcios de Empresas</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">

                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indOpExt">Operações com o
                          Exterior</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indOpVinc">Operações com
                          Pessoa Vinculada/Interposta Pessoa/País com Tributação Favorecida</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPjEnquad">PJ Enquadrada
                          nos artigos 48 ou 49 da Instrução Normativa RFB no 1.312/2012</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPartExt">Participações no
                          Exterior: A pessoa jurídica deve assinalar este campo, caso tenha participações no exterior
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indAtivRural">Atividade
                          Rural: A pessoa jurídica deve assinalar este campo, caso explore atividade rural
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indLucExp">Existência de
                          Lucro da Exploração</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRedIsen">Isenção e
                          Redução do Imposto para Lucro Presumido</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indFin">Indicativo da
                          Existe^ncia de FINOR/FINAM</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indDoaEleit">Doações a
                          Campanhas Eleitorais</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPartColig">Participação
                          Avaliada pelo Método de Equivalência Patrimonial</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indVendExp">PJ Efetuou
                          Vendas a Empresa Comercial Exportadora com Fim Específico de Exportação</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRecExt">Rendimentos do
                          Exterior ou de Não Residentes</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indAtivExt">Ativos no
                          Exterior
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indComExp">PJ Comercial
                          Exportadora</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPagtoExt">Pagamentos ao
                          Exterior ou a Não Residentes</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indEcomTi">Comércio
                          Eletrônico e Tecnologia da Informação</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRoyRec">Royalties
                          Recebidos do Brasil e do Exterior</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRoyPag">Royalties Pagos a
                          Beneficiários do Brasil e do Exterior</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle> Relativos a Servios, Juros e Dividendos Recebidos do Brasil e do
                          Exterior
                        </mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPagtoRem">Pagamentos ou
                          Remessas a Título de Serviços, Juros e Dividendos a Beneficiários do Brasil e do
                          Exterior</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indInovTec">Inovação
                          Tecnológica e Desenvolvimento Tecnológico</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indCapInf">Capacitação de
                          Informática e Inclusão Digital</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPjHab">PJ Habilitada no
                          Repes, Recap, Padis, PATVD, Reidi, Repenec, Reicomp, Retaero, Recine, Resíduos
                          Sólidos, Recopa, Copa do Mundo, Retid, REPNBL-Redes, Reif e Olimpi´adas
                        </mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPoloAm">Pólo Industrial
                          de Manaus e Amazônia Ocidental</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indZonExp">Zonas de
                          Processamento de Exportação</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indAreaCom">Áreas de Livre
                          Comércio</mat-slide-toggle>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPaisPais">Pessoa jurídica
                          é entidade integrante de grupo multinacional, nos termos da Instrução Normativa RFB no
                          1.681/2016</mat-slide-toggle>
                      </div>
                    </div>
                  </p-accordionTab>

                  <p-accordionTab matTooltip="Registro 0021 - Parâmetros de Identificação dos Tipos de Programa"
                    header="Registro 0021 - Parâmetros de Identificação dos Tipos de Programa">
                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRepes">Regime Especial de
                          Tributação para a Plataforma de Exportação de Serviços de Tecnologiada Informação (Repes)
                        </mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRecap">Regime Especial de
                          Aquisição de Bens de Capital para Empresas Exportadoras (Recap)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPadis">Programa de Apoio
                          ao
                          Desenvolvimento Tecnológico da Indústria de Semicondutores (Padis)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indPatvd">Programa de Apoio
                          ao Desenvolvimento Tecnológico da Indústria de Equipamentos para TV Digital (PA TVD)
                        </mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indReidi">Regime Especial de
                          Incentivos para o Desenvolvimento da Infraestrutura (Reidi)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRepenec">Regime Especial
                          de
                          Incentivos para o Desenvolvimento da Infraestrutura da Indústria Petrolífera das
                          Regiões NO, NE e CO (Repenec)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indReicomp">Regime Especial
                          de Incentivo a Computadores para Uso Educacional (Reicomp)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRetaero">Regime Especial
                          para a Indústria Aeronáutica Brasileira (Retaero)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRecine">Regime Especial
                          de Tributaão para Desenvolvimento da Atividade de Exibição Cinematográfica (Recine)
                        </mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indResiduosSolid">
                          Estabelecimentos industriais façam jus a crédito presumido do IPI na aquisição de
                          resíduos sólidos (Lei nº12.375, de 2010)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRecopa">Regime Especial
                          de Tributac¸a~o para construção, ampliação, reforma ou modernização de estádios
                          de futebol (Recopa)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indCopaDoMund">Benefícios
                          fiscais não abrangidos na alínea anterior, relativos á realização da Copa das
                          Confederações 2013 e da Copa do Mundo 2014</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRetid">Regime Especial
                          Tributário para a Indústria de Defesa (Retid)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indRepnblRedes">Regime
                          Especial de Tributação do Programa Nacional de Banda Larga para Implantação de Redes
                          de Telecomunicações (REPNBL-Redes)</mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indReif">Regime Especial de
                          Incentivo ao Desenvolvimento da Infraestrutura da Indústria de Fertilizantes (REIF)
                        </mat-slide-toggle>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col">
                        <mat-slide-toggle formControlName="empresaPeriodos[periodo={{year}}]indOlimpiadas">Habilitada
                          para fins de fruição dos benefícios fiscais relativos á realização dos Jogos
                          Olímpicos 2016 e dos Jogos Paraolímpicos 2016</mat-slide-toggle>
                      </div>
                    </div>

                  </p-accordionTab>
                </p-accordion>
              </div>
            </mat-tab>
          </mat-tab-group>
        </form>
      </div>
    </div>
  </mat-card-content>

  <mat-card-content>
    <div class="row">
      <div class="col-md-6" style="min-width: 818px;">

        <div class="form-row">
          <div class="form-group col">
            <label class="texto">Ind Centralizada</label>
            <input type="text" class="form-control" [(ngModel)]="indCentralizada" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label class="texto">Ind. Mudança PC</label>
            <input type="text" class="form-control" [(ngModel)]="indMudancaPc" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label class="texto">Cód. Plan. Ref</label>
            <input type="text" class="form-control" [(ngModel)]="codPlanRef" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label class="texto">Tipo ECD Id</label>
            <input type="text" class="form-control" [(ngModel)]="tipoEcdId" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <label class="texto">Tipo ECF Id</label>
            <input type="text" class="form-control" [(ngModel)]="tipoEcfId" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col">
            <button type="button" class="btn btn-primary" (click)="save()"><i class="fas fa-check"></i>
              Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>