<div fxLayout="row" class="boxList" *ngIf="!DragDrop">
  <div class="list" *ngFor="let file of files | slice:0:2;">
    <div class="box">
      <span>{{file.name}}</span><span>{{dot}}</span>
    </div>
  </div>

  <div *ngIf="!DragDrop && multiple">
    <div *ngFor="let file of files | slice:0:1;">
      <span class="item material-icons" (click)="removeFile(file, fileUploader)">delete_outline</span>
    </div>
  </div>
</div>

<p-fileUpload [class.hideDrag]="!DragDrop" accept="{{acceptFiles}}" [customUpload]="true"
  (uploadHandler)="onUpload($event)" [multiple]="multiple ? true : false" [maxFileSize]="10000000" [auto]="true"
  (onRemove)="onRemove($event)" #fileUploader styleClass="file-uploader mt-3" [chooseLabel]=labelButton
  chooseIcon="fas fa-paperclip" invalidFileSizeMessageSummary="Não foi possível anexar este arquivo!"
  invalidFileSizeMessageDetail="O tamanho máximo por arquivo é {0}."
  invalidFileTypeMessageSummary="{0} é um arquivo inválido!"
  invalidFileTypeMessageDetail="Os tipos de arquivos aceitos são: {0}."
  invalidFileLimitMessageSummary="Não foi possível anexar este arquivo!"
  invalidFileLimitMessageDetail="O limite máximo de anexos é {0}.">
  <ng-template let-file pTemplate="file">
    <div class="file" *ngIf="DragDrop">
      <p>{{file.name}} - {{file.size}}KB</p>
      <button matTooltip="Remover anexo" class="btn-remove" type="button" (click)="removeFile(file, fileUploader)">
        <span class="material-icons">delete_outline</span>
      </button>
    </div>
  </ng-template>
  <ng-template pTemplate="content" *ngIf="DragDrop">
    <div class="drag-text">
      <span class="material-icons">cloud_upload</span>
      <h2>Arraste e solte seus anexos aqui!</h2>
    </div>
  </ng-template>
</p-fileUpload>