<button mat-icon-button class="notifyButton" [matMenuTriggerFor]="beforeMenu" matTooltip="Notificações" [ngClass]="{ news: notificationsCount() }">
  <span class="material-icons">notifications_none</span>
</button>
<mat-menu #beforeMenu="matMenu" xPosition="before" class="matMenu-notification">
  <div class="notifyHeader" (click)="$event.stopPropagation();"  *ngIf="notificationList.length > 0">
    <div class="filter">
      <div class="icon-append">
        <span class="material-icons">search</span>
      </div>
      <input class="input-filter" placeholder="Pesquise" type="text" [(ngModel)]="contentFilter" (ngModelChange)="onFilter()" />
    </div>

    <button type="button" matTooltip="Visualizar todas" matTooltipPosition="above" class="btnCustom-green" (click)="updateNotifications(notificationList)">
      <span class="material-icons">done_all</span>
    </button>
    <button type="button" matTooltip="Remover todas" matTooltipPosition="above" class="btnCustom-red" (click)="deleteNotifications(notificationList)">
      <span class="material-icons">delete</span>
    </button>
  </div>
  <div class="notifyBody" [ngClass]="{ 'has-Notify': (notificationList.length > 0) }">
    <div *ngFor="let item of notificationList" class="notifyCard"  (click)="updateNotification(item)">
      <div class="user">
        <h2>{{isUser(item.sender_name)}}</h2>
      </div>
      <div class="content">
        <div class="infos">
          <p>{{item.sender_name}}</p>
          <small>{{convertDate(item.time)}}</small>
          <span *ngIf="!item.read" class="notRead">Novo!</span>
        </div>
        <div class="text">
          <span [innerHTML]="item.message | safeHtml"></span>
        </div>
        <div class="footer">
          <span>{{item.code}} - {{item.title}}</span>
        </div>
      </div>
    </div>
    <div class="more-container" *ngIf="(notificationList.length !== 0) && showMoreEnable" (click)="$event.stopPropagation();">
      <small  class="show-more" (click)="showMore()">
        <span class="material-icons">add_circle_outline</span>
        Exibir mais
      </small>
    </div>
    <div class="empty" *ngIf="notificationList.length === 0">
      <small>Nenhuma notificação encontrada!</small>
    </div>
  </div>
</mat-menu>