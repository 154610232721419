<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle> {{ subtitle }} </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="box-content pt-2">


      <div class="pull-right align-down-table">
        <button type="button" class="btn btn-link" (click)="dtlista.exportCSV()"><i class="fas fa-file-excel excel"></i>
          Exportar para Excel</button>
      </div>
      <div class="clearfix"></div>

      <p-table class="table-system" #dtlista [columns]="colslista" [value]="lista" [rows]="rows" [paginator]="true"
        [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
        <ng-template pTemplate="header">
          <tr>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo'">
                <span class="text-overflow-dynamic-ellipsis">PERÍODO <p-sortIcon [field]="'periodo'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'periodo', 'contains')">
            </th>
            <th class="text-left" style="width: 25%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'prestadorNome'">
                <span class="text-overflow-dynamic-ellipsis">PRESTADOR NOME <p-sortIcon [field]="'prestadorNome'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'prestadorNome', 'contains')">
            </th>
            <th class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'serie'">
                <span class="text-overflow-dynamic-ellipsis">SERIE <p-sortIcon [field]="'serie'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'serie', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'numDocto'">
                <span class="text-overflow-dynamic-ellipsis">NUM DOCTO <p-sortIcon [field]="'numDocto'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'numDocto', 'contains')">
            </th>

            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEmissaoNf'">
                <span class="text-overflow-dynamic-ellipsis">DT EMISSÃO NF <p-sortIcon [field]="'dtEmissaoNf'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'dtEmissaoNf', 'contains')">
            </th>

            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoServico'">
                <span class="text-overflow-dynamic-ellipsis">TIPO SERVIÇO <p-sortIcon [field]="'tipoServico'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'tipoServico', 'contains')">
            </th>

            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrBruto'">
                <span class="text-overflow-dynamic-ellipsis">VLR BRUTO <p-sortIcon [field]="'vlrBruto'"></p-sortIcon>
                  </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'vlrBruto', 'contains')">
            </th>

            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrBaseRet'">
                <span class="text-overflow-dynamic-ellipsis">VLR BASE RET <p-sortIcon [field]="'vlrBaseRet'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'vlrBaseRet', 'contains')">
            </th>

            <th class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrRetencao'">
                <span class="text-overflow-dynamic-ellipsis">VLR RETENÇÃO <p-sortIcon [field]="'vlrRetencao'">
                  </p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dtlista.filter($event.target.value, 'vlrRetencao', 'contains')">
            </th>


          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>

            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.periodo}}">{{rowData.periodo
                  }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 25%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.prestadorNome}}">{{rowData.prestadorNome }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 5%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.serie}}">{{rowData.serie}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.numDocto}}">{{rowData.numDocto}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtEmissaoNf}}">{{rowData.dtEmissaoNf
                  }}</span>
              </span>
            </td>

            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoServico}}">{{rowData.tipoServico
                  }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrBruto}}">{{rowData.vlrBruto
                  }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrBaseRet}}">{{rowData.vlrBaseRet
                  }}</span>
              </span>
            </td>

            <td class="text-right" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrRetencao}}">{{rowData.vlrRetencao
                  }}</span>
              </span>
            </td>


          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="9" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>



    </div>
  </mat-card-content>
</mat-card>