<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <mat-card-content>
    <!--TELA 1 - PLANO GERENCIAL - CADASTRE E CONTROLE O PLANO GERENCIAL E SUAS CONTAS-->
    <div class="row" [ngClass]="{'fade-in': 'isCardMov'}">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card">PLANO GERENCIAL E SUAS CONTAS</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>

                <!-- <button type="button" (click)="voltarEdit()" class="btn btn-xs btn-link" matTooltip="Voltar"
                  matTooltipPosition="above" *ngIf="editGerenciais">
                  <span class="material-icons" style="font-size: 34px;">navigate_before</span>
                </button> -->

                <!-- Botão para Adicionar um novo Plano Gerencial -->
                <!-- <button type="button" (click)="addPg()" class="btn btn-xs btn-link" matTooltip="Adicionar Plano"
                  matTooltipPosition="above" *ngIf="!editGerenciais">
                  <span class="material-icons" style="font-size: 28px;">add</span>
                </button> -->

                <!-- Botão para Adicionar uma nova Conta Gerencial -->
                <!-- <button type="button" (click)="addConta()" class="btn btn-xs btn-link" matTooltip="Adicionar Conta"
                  matTooltipPosition="above" *ngIf="editGerenciais">
                  <span class="material-icons" style="font-size: 28px;">add</span>
                </button> -->

                <!-- <button type="button" (click)="showModalUploadGerencial()" class="btn btn-xs btn-link"
                  matTooltip="Upload plano Gerencial" matTooltipPosition="above" *ngIf="editGerenciais">
                  <span class="material-icons" style="font-size: 24px;">backup</span>
                </button> -->
<!--
                <button type="button" (click)="showModalclonarPlano()" class="btn btn-xs btn-link"
                  matTooltip="Clonar Plano Gerencial" matTooltipPosition="above" *ngIf="!editGerenciais"
                  [disabled]="listaGerencialId.length === 0 || listaGerencialId.length > 1">
                  <i class="far fa-copy" style="font-size: 21px;"></i>
                </button> -->

                <!-- <button type="button" (click)="editPg()" class="btn btn-xs btn-link" matTooltip="Editar"
                  matTooltipPosition="above" *ngIf="!editGerenciais" [disabled]="listaGerencialId.length <= 0">
                  <span class="material-icons" style="font-size: 24px;">edit</span>
                </button> -->

                <!-- <button type="button" (click)="editarContaPlanoGerencial()" class="btn btn-xs btn-link"
                  matTooltip="Editar" matTooltipPosition="above" *ngIf="editGerenciais && idContaSelecionado.length > 0"
                  [disabled]="contaGerencialId.length > 1">
                  <span class="material-icons" style="font-size: 24px;">edit</span>
                </button> -->

                <!-- Botão para Remover um plano Gerencial -->
                <!-- <button type="button" (click)="removePg()" class="btn btn-xs btn-link" matTooltip="Remover Plano"
                  matTooltipPosition="above" *ngIf="!editGerenciais" [disabled]="listaGerencialId.length === 0">
                  <span class="material-icons" style="font-size: 23px; margin-left: -4px;">delete</span>
                </button> -->

                <!-- <button type="button" (click)="removePc()" class="btn btn-xs btn-link" matTooltip="Remover Conta"
                  matTooltipPosition="above" *ngIf="editGerenciais" [disabled]="contaGerencialId.length === 0">
                  <span class="material-icons" style="font-size: 23px; margin-left: -4px;">delete</span>
                </button> -->

                <!-- Botão excel -->
                <!-- <button type="button" class="btn btn-xs btn-link" matTooltip="Exportar Excel" matTooltipPosition="above"
                  [ngStyle]="!editGerenciais ? {'display': 'inline'} : {'display': 'none'}"><i
                    class="far fa-file-excel excel"></i> </button> -->
                <!-- <button type="button" class="btn btn-xs btn-link" (click)="exportPgc()" matTooltip="Exportar Excel"
                  matTooltipPosition="above" [ngStyle]="editGerenciais ? {'display': 'inline'} : {'display': 'none'}"><i
                    class="far fa-file-excel excel"></i> </button> -->

                <!-- Botão collapse -->
                <!-- <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeMov()" (click)="changeStateMov()">
                  <span *ngIf="isCardMov" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardMov" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button> -->
              </div>
            </div>
          </div>


          <div class="box-content" [@changeDivSize]=currentStateMov>
            <p-table *ngIf="!viewCpg && !editGerenciais" class="table-system" #dt [value]="planosGerenciais"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" selectionMode="multiSelect"
              [rowsPerPageOptions]="rowsPerPage" [style]="{'width':'100%'}"
              (onHeaderCheckboxToggle)="selectTodos($event)" (onRowSelect)="selectCheckbox($event)"
              (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                    <p-tableHeaderCheckbox [ngModel]="checkedAll" (change)="selectTodos($event)">
                    </p-tableHeaderCheckbox>
                  </th>
                  <th class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'id'">
                      <span class="text-overflow-dynamic-ellipsis">ID <p-sortIcon [field]="'id'"></p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'id', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 75%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                      <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'nome', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda.codigo'">
                      <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'moeda.codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'moeda.codigo', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 5%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                        matTooltip="{{ rowData.id }}">{{ rowData.id }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                        matTooltip="{{ rowData.codigo }}">{{ rowData.codigo }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 75%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                        matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(rowData)"
                        matTooltip="{{rowData.moeda.codigo}}">{{rowData.moeda.codigo }}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="5" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <!--FIM TELA 1 - PLANO GERENCIAL - CADASTRE E CONTROLE O PLANO GERENCIAL E SUAS CONTAS-->

            <!--TELA 2 - PLANO GERENCIAL - EDITANDO PLANO GERENCIAL: 200 - REPORT BUDGET-->

            <p-table *ngIf="editGerenciais" class="table-system" #dt8 [value]="contasGerenciais" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              (onHeaderCheckboxToggle)="selectTodosConta($event)" selectionMode="multiSelect" [scrollable]="false"
              scrollHeight="320px">
              [rowStyleClass]="planoContasListStyleClass"
              >
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                    <p-tableHeaderCheckbox  (change)="selectTodosConta($event)">
                    </p-tableHeaderCheckbox>
                  </th>
                  <th class="text-left" style="width: 7%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'codCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 30%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'descrCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 17%;">
                    <span class="text-overflow-dynamic-container"
                      [pSortableColumn]="'codNaturezaConta.codDescNatureza'">
                      <span class="text-overflow-dynamic-ellipsis">NATUREZA <p-sortIcon
                          [field]="'codNaturezaConta.codDescNatureza'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'codNaturezaConta.codDescNatureza', 'contains')">
                  </th>
                  <th class="text-left" style="width: 21%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ctaSup.codDescCtaSup'">
                      <span class="text-overflow-dynamic-ellipsis">CTA SUPERIOR <p-sortIcon
                          [field]="'ctaSup.codDescCtaSup'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'ctaSup.codDescCtaSup', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                      <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'nivel', 'contains')">
                  </th>
                  <th class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'ordem'">
                      <span class="text-overflow-dynamic-ellipsis">ORDEM <p-sortIcon [field]="'ordem'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'ordem', 'contains')">
                  </th>
                  <th class="text-left" style="width: 9%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                      <span class="text-overflow-dynamic-ellipsis">INDICADOR <p-sortIcon [field]="'indCta'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'indCta', 'contains')">
                  </th>
                  <th class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'indRd'">
                      <span class="text-overflow-dynamic-ellipsis">RECEB/DÉBITO <p-sortIcon [field]="'indRd'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt8.filter($any($event.target).value, 'indRd', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxConta(rowData.id,rowData)">
                    </p-tableCheckbox>
                  </td>
                  <td class="text-left" style="width: 7%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                        matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 30%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                        matTooltip="{{rowData.descrCta}}">{{rowData.descrCta }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 17%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)" matTooltip="{{rowData.codNaturezaConta != null ?
                          rowData.codNaturezaConta.codDescNatureza : '' }}">
                        {{rowData.codNaturezaConta != null ?
                        rowData.codNaturezaConta.codDescNatureza : "" }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 21%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)" matTooltip="{{rowData.ctaSup != null ? rowData.ctaSup.codDescCtaSup :
                          ''}}">
                        {{rowData.ctaSup != null ? rowData.ctaSup.codDescCtaSup :
                        "" }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                        matTooltip="{{rowData.nivel}}">
                        {{rowData.nivel }}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 8%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                        matTooltip="{{rowData.ordem}}">
                        {{rowData.ordem}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 9%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                        matTooltip="{{rowData.indCta}}">
                        {{rowData.indCta}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                        matTooltip="{{rowData.indRd}}">
                        {{rowData.indRd}}
                      </span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="9" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>

        <!--FIM TELA 2 - PLANO GERENCIAL - EDITANDO PLANO GERENCIAL: 200 - REPORT BUDGET-->

        <!--TELA 2.1 - PLANO GERENCIAL - VINCULAÇÃO E CONDIÇÃO-->
        <!--TELA 2.1 - PLANO GERENCIAL - VINCULAÇÃO-->

        <div class="row" [ngClass]="{'fade-in': 'isCardMov'}" *ngIf="viewCpg && editPc">
          <div class="col-sm-12 pt-4">
            <div class="shadow-box">
              <div class="actions-card" style="justify-content: space-between;display: flex;padding: 20px 20px;">
                <mat-slide-toggle style="margin-top: 10px;" [(ngModel)]="isVinculacaoPg">Vinculação por plano gerencial
                </mat-slide-toggle>
                <button type="button" class="btn btn-xs btn-link" (click)="savePgcVinculacao()" *ngIf="isVinculacaoPg">
                  <span class="material-icons" matTooltip="Salvar vinculações" matTooltipPosition="above" style="color: #cccccc!important;">save</span>
                </button>
              </div>
              <!-- Vinculação tradicional -->
              <div *ngIf="!isVinculacaoPg">
                <div class="header-box">
                  <div class="header-box-content">

                    <ul class="list-inline filter-menu filter-card-header mt-0">
                      <li class="list-inline-item" [ngClass]="{active: aba1}" *ngIf="showvizualizacao"><a
                          (click)="showTela('VINCULACAO')">VINCULAÇÃO</a></li>
                      <li class="list-inline-item" [ngClass]="{active: aba3}"><a
                          (click)="showTela('TELA_3')">CONDIÇÃO</a>
                      </li>
                    </ul>

                    <div class="actions-card">

                      <button *ngIf="aba1 && !aba3" type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()"
                        matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel" style="
                          margin-left: 7px; color: #cccccc!important;"></i>
                      </button>
                      <button *ngIf="aba2 && !aba3" type="button" class="btn btn-xs btn-link" (click)="dt3.exportCSV()"
                        matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                      </button>
                      <button type="button" (click)="voltarCpg()" class="btn btn-xs btn-link" matTooltip="Fechar"
                        matTooltipPosition="above" *ngIf="viewCpg">
                        <span class="material-icons" style="font-size: 28px; margin-right: -6px;color: #cccccc!important;">close</span>
                      </button>
                      <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeVin()"
                        (click)="changeStateVin()">
                        <span *ngIf="isCardVin" class="material-icons" matTooltip="Ocultar Card"
                          matTooltipPosition="above" style="color: #cccccc!important;">expand_less</span>
                        <span *ngIf="!isCardVin" class="material-icons" matTooltip="Ver Card"
                          matTooltipPosition="above" style="color: #cccccc!important;">expand_more</span>
                      </button>
                    </div>

                  </div>
                </div>

                <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateVin>
                  <div [ngStyle]="vinculacao ? {'display': 'block'} : {'display': 'none'}" class="pull-left">

                    <div class="pull-left pb-4"></div>

                    <div class="row">
                      <div class="col-sm-12 mt-1">
                        <p-table class="table-system" #dt2 [value]="planoContasList" [rows]="rows" [paginator]="true"
                          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [(selection)]="selectedContasNaoVinc"
                          (onHeaderCheckboxToggle)="selectTodosRelacionar($event)" (onRowSelect)="selectCta($event)"
                          (onRowUnselect)="selectCta($event)" (onFilter)="onFilterRelacionar($event)">
                          <ng-template pTemplate="header">
                            <tr>
                              <th class="text-center" style="width: 40px;">
                                <p-tableHeaderCheckbox [(ngModel)]="planoContasListCheckAll"></p-tableHeaderCheckbox>
                              </th>
                              <th class="text-left" style="width: 10%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                                  <span class="text-overflow-dynamic-ellipsis">COD <p-sortIcon [field]="'codCta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'codCta', 'contains')">
                              </th>
                              <th class="text-left" style="width: 12%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCtaSup'">
                                  <span class="text-overflow-dynamic-ellipsis">CTA SUP <p-sortIcon
                                      [field]="'codCtaSup'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'codCtaSup', 'contains')">
                              </th>
                              <th class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                                  <span class="text-overflow-dynamic-ellipsis">IND CTA <p-sortIcon [field]="'indCta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'indCta', 'contains')">
                              </th>
                              <th class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                                  <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'nivel', 'contains')">
                              </th>
                              <th class="text-left" style="width: 35%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                                  <span class="text-overflow-dynamic-ellipsis">DESC CONTA <p-sortIcon
                                      [field]="'descrCta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'descrCta', 'contains')">
                              </th>
                              <th class="text-left" style="width: 9%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'mapeado'">
                                  <span class="text-overflow-dynamic-ellipsis">MAPEADA <p-sortIcon [field]="'mapeado'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'mapeado', 'contains')">
                              </th>
                              <th class="text-left" style="width: 15%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'natConta'">
                                  <span class="text-overflow-dynamic-ellipsis">NATUREZA <p-sortIcon
                                      [field]="'natConta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt2.filter($any($event.target).value, 'natConta', 'contains')">
                              </th>
                            </tr>
                          </ng-template>

                          <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData">
                              <td class="text-center" style="width: 40px;">
                                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                              </td>
                              <td class="text-left" style="width: 10%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 12%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{rowData.codCtaSup}}">{{rowData.codCtaSup }}</span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{rowData.indCta}}">
                                    {{rowData.indCta }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{rowData.nivel}}">
                                    {{rowData.nivel}}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 35%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{rowData.descrCta}}">
                                    {{rowData.descrCta }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 9%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{rowData.mapeado}}">
                                    {{rowData.mapeado}}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 15%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis"
                                    matTooltip="{{rowData.natConta}}">
                                    {{rowData.natConta}}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage" let-rowData>
                            <tr>
                              <td [attr.colspan]="8" class="spaced-alert">
                                <div class="alert alert-xs alert-primary d-inline" role="alert">
                                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                                </div>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="col-sm-2">
                        <label>Tipo Ccus</label>
                        <p-dropdown class="type-p-dropdown" [options]="opcoesTipoCcus" [(ngModel)]="vincTipoCcusId"
                          [filter]="true"></p-dropdown>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col mb-4 mt-3">
                        <button type="button" class="btn btn-sm btn-info" (click)="add()"
                          matTooltip="Adicionar Contas"><i class="fas fa-angle-double-down"></i> Adicionar</button>
                        <button type="button" class="btn btn-sm btn-info" (click)="remove()"
                          matTooltip="Remover Contas"><i class="fas fa-angle-double-up"></i> Remover</button>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <p-table class="table-system" #dt3 [value]="planoContasVincList" [rows]="rows"
                          [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
                          (onHeaderCheckboxToggle)="selectTodosRelacionados($event)" [(selection)]="selectedContasVinc"
                          (onRowSelect)="selectCtaRelacionada($event)" (onRowUnselect)="selectCtaRelacionada($event)"
                          (onFilter)="onFilterRelacionadas($event)">
                          <ng-template pTemplate="header">
                            <tr>
                              <th class="text-center" style="width: 3%">
                                <p-tableHeaderCheckbox [(ngModel)]="planoContasListRelacionadosCheckAll">
                                </p-tableHeaderCheckbox>
                              </th>
                              <th class="text-left" style="width: 10%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                                  <span class="text-overflow-dynamic-ellipsis">COD <p-sortIcon [field]="'codCta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt3.filter($any($event.target).value, 'codCta', 'contains')">
                              </th>
                              <th class="text-left" style="width: 12%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCtaSup'">
                                  <span class="text-overflow-dynamic-ellipsis">CTA SUP <p-sortIcon
                                      [field]="'codCtaSup'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt3.filter($any($event.target).value, 'codCtaSup', 'contains')">
                              </th>
                              <th class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                                  <span class="text-overflow-dynamic-ellipsis">IND CTA <p-sortIcon [field]="'indCta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt3.filter($any($event.target).value, 'indCta', 'contains')">
                              </th>
                              <th class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                                  <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt3.filter($any($event.target).value, 'nivel', 'contains')">
                              </th>
                              <th class="text-left" style="width: 35%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                                  <span class="text-overflow-dynamic-ellipsis">DESC CONTA <p-sortIcon
                                      [field]="'descrCta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt3.filter($any($event.target).value, 'descrCta', 'contains')">
                              </th>
                              <th class="text-left" style="width: 15%;">
                                <span class="text-overflow-dynamic-container" [pSortableColumn]="'natConta'">
                                  <span class="text-overflow-dynamic-ellipsis">NATUREZA <p-sortIcon
                                      [field]="'natConta'">
                                    </p-sortIcon>
                                  </span>
                                </span>
                                <input class="form-control form-control-sm" type="text"
                                  (input)="dt3.filter($any($event.target).value, 'natConta', 'contains')">
                              </th>
                            </tr>
                          </ng-template>

                          <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData">
                              <td class="text-center" style="width: 3%;">
                                <p-tableCheckbox [value]="rowData">
                                </p-tableCheckbox>
                              </td>
                              <td class="text-left" style="width: 10%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                                    matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 12%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                                    matTooltip="{{rowData.codCtaSup}}">{{rowData.codCtaSup }}</span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                                    matTooltip="{{rowData.indCta}}">
                                    {{rowData.indCta }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 8%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                                    matTooltip="{{rowData.nivel}}">
                                    {{rowData.nivel}}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 35%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                                    matTooltip="{{rowData.descrCta}}">
                                    {{rowData.descrCta }}
                                  </span>
                                </span>
                              </td>
                              <td class="text-left" style="width: 15%;">
                                <span class="text-overflow-dynamic-container">
                                  <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelectConta(rowData)"
                                    matTooltip="{{rowData.natConta}}">
                                    {{rowData.natConta}}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage" let-rowData>
                            <tr>
                              <td [attr.colspan]="7" class="spaced-alert">
                                <div class="alert alert-xs alert-primary d-inline" role="alert">
                                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                                </div>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </div>
                  <!--FIM TELA 2.1 - PLANO GERENCIAL - VINCULAÇÃO-->

                  <!--TELA 2.1 - PLANO GERENCIAL - CONDIÇÃO-->

                  <div [ngStyle]="aba2 ? {'display': 'block'} : {'display': 'none'}" class="pull-left">
                    <div class="pull-left pb-4"></div>
                  </div>

                  <div [ngStyle]="aba3 ? {'display': 'block'} : {'display': 'none'}" class="pull-left">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="header-box">
                          <div class="header-box-content">
                            <ul class="list-inline filter-menu mt-0 mb-2" style="margin-left: -15px;">
                              <li class="list-inline-item" *ngIf="showvizualizacao" [ngClass]="{active: cc}  "><a
                                  (click)="showTela('CC')">CENTRO DE
                                  CUSTO</a></li>
                              <li class="list-inline-item" *ngIf="showvizualizacao" [ngClass]="{active: cp}"><a
                                  (click)="showTela('CP')">PROJETO</a>
                              </li>
                              <li *ngIf="planogerencialcontas.indCta !== 'T'" class="list-inline-item"
                                [ngClass]="{active: view}"><a (click)="showTela('VW')">VIEW</a>
                              </li>
                              <li *ngIf="planogerencialcontas.indCta === 'T'" class="list-inline-item"
                                [ngClass]="{active: viewContaGer}"><a (click)="showTela('GERENCIAL')">CONTA
                                  GERENCIAL</a>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div [ngStyle]="cc ? {'display': 'block'} : {'display': 'none'}" class="pull-left pr-2"
                          [ngClass]="{'fade-in': 'cc'}">

                          <p-table class="table-system" #dt4 [value]="centrosCusto" [rows]="rows" [paginator]="true"
                            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                            [scrollable]="false" scrollHeight="200px">
                            <ng-template pTemplate="header">
                              <tr>
                                <th class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCcus'">
                                    <span class="text-overflow-dynamic-ellipsis">codCcus <p-sortIcon
                                        [field]="'codCcus'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt4.filter($any($event.target).value, 'codCcus', 'contains')">
                                </th>
                                <th class="text-left" style="width: 80%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCcus'">
                                    <span class="text-overflow-dynamic-ellipsis">descrCcus <p-sortIcon
                                        [field]="'descrCcus'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt4.filter($any($event.target).value, 'descrCcus', 'contains')">
                                </th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData>
                              <tr [pSelectableRow]="rowData">
                                <td class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectCc(rowData)"
                                      matTooltip="{{ rowData.codCcus }}">{{ rowData.codCcus }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 80%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectCc(rowData)"
                                      matTooltip="{{ rowData.descrCcus }}">{{ rowData.descrCcus }}</span>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-rowData>
                              <tr>
                                <td [attr.colspan]="2" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>

                        </div>

                        <div [ngStyle]="cp ? {'display': 'block'} : {'display': 'none'}" class="pull-left pr-2">

                          <p-table class="table-system" #dt5 [value]="projetos" [rows]="rows" [paginator]="true"
                            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                            [scrollable]="false" scrollHeight="200px">
                            <ng-template pTemplate="header">
                              <tr>
                                <th class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codProj'">
                                    <span class="text-overflow-dynamic-ellipsis">codProj <p-sortIcon
                                        [field]="'codProj'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt5.filter($any($event.target).value, 'codProj', 'contains')">
                                </th>
                                <th class="text-left" style="width: 80%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrProj'">
                                    <span class="text-overflow-dynamic-ellipsis">descrProj <p-sortIcon
                                        [field]="'descrProj'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt5.filter($any($event.target).value, 'descrProj', 'contains')">
                                </th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData>
                              <tr [pSelectableRow]="rowData">
                                <td class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectCp(rowData)"
                                      matTooltip="{{ rowData.codProj }}">{{ rowData.codProj }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 80%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectCp(rowData)"
                                      matTooltip="{{ rowData.descrProj }}">{{ rowData.descrProj }}</span>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-rowData>
                              <tr>
                                <td [attr.colspan]="2" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>

                        <div [ngStyle]="view ? {'display': 'block'} : {'display': 'none'}" class="pull-left"
                          *ngIf="planogerencialcontas.indCta !== 'T'">

                          <p-table class="table-system" #dt6 [value]="viewCampos" [rows]="rows" [paginator]="true"
                            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                            [scrollable]="false" scrollHeight="200px">
                            <ng-template pTemplate="header">
                              <tr>
                                <th class="text-left" style="width: 30%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'campo'">
                                    <span class="text-overflow-dynamic-ellipsis">campo <p-sortIcon [field]="'campo'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt6.filter($any($event.target).value, 'campo', 'contains')">
                                </th>
                                <th class="text-left" style="width: 60%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                                    <span class="text-overflow-dynamic-ellipsis">descricao <p-sortIcon
                                        [field]="'descricao'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt6.filter($any($event.target).value, 'descricao', 'contains')">
                                </th>
                                <th class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                                    <span class="text-overflow-dynamic-ellipsis">tipo <p-sortIcon [field]="'tipo'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt6.filter($any($event.target).value, 'tipo', 'contains')">
                                </th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData>
                              <tr [pSelectableRow]="rowData">
                                <td class="text-left" style="width: 30%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectViewCampo(rowData)"
                                      matTooltip="{{ rowData.campo }}">{{ rowData.campo }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 60%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectViewCampo(rowData)"
                                      matTooltip="{{ rowData.descricao }}">{{ rowData.descricao }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectViewCampo(rowData)"
                                      matTooltip="{{ rowData.tipo }}">{{ rowData.tipo }}</span>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-rowData>
                              <tr>
                                <td [attr.colspan]="3" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>

                        <div [ngStyle]="viewContaGer ? {'display': 'block'} : {'display': 'none'}"
                          class="pull-left pr-2" *ngIf="planogerencialcontas.indCta === 'T'">

                          <p-table class="table-system" #dt7 [value]="contasGerenciais" [rows]="rows" [paginator]="true"
                            [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                            emptyMessage="Nenhum Registro Encontrado" [scrollable]="false" scrollHeight="200px"
                            *ngIf="editGerenciais">
                            <ng-template pTemplate="header">
                              <tr>
                                <th class="text-left" style="width: 30%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                                    <span class="text-overflow-dynamic-ellipsis">código <p-sortIcon [field]="'codCta'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt7.filter($any($event.target).value, 'codCta', 'contains')">
                                </th>
                                <th class="text-left" style="width: 60%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                                    <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon
                                        [field]="'descricao'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt7.filter($any($event.target).value, 'descricao', 'contains')">
                                </th>
                                <th class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                                    <span class="text-overflow-dynamic-ellipsis">nivel <p-sortIcon [field]="'nivel'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt7.filter($any($event.target).value, 'nivel', 'contains')">
                                </th>
                                <th class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                                    <span class="text-overflow-dynamic-ellipsis">indicador <p-sortIcon
                                        [field]="'indCta'">
                                      </p-sortIcon>
                                    </span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text"
                                    (input)="dt7.filter($any($event.target).value, 'indCta', 'contains')">
                                </th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-rowData>
                              <tr [pSelectableRow]="rowData">
                                <td class="text-left" style="width: 30%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectView(rowData)"
                                      matTooltip="{{ rowData.codCta }}">{{ rowData.codCta }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 60%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectView(rowData)"
                                      matTooltip="{{ rowData.descrCta }}">{{ rowData.descrCta }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectView(rowData)"
                                      matTooltip="{{ rowData.nivel }}">{{ rowData.nivel }}</span>
                                  </span>
                                </td>
                                <td class="text-left" style="width: 20%;">
                                  <span class="text-overflow-dynamic-container">
                                    <span class="text-overflow-dynamic-ellipsis" (click)="onSelectView(rowData)"
                                      matTooltip="{{ rowData.indCta }}">{{ rowData.indCta }}</span>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage" let-rowData>
                              <tr>
                                <td [attr.colspan]="4" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                      <div class="custom-row">
                        <div class="row-item" *ngIf="planogerencialcontas.indCta === 'T'">
                          <mat-slide-toggle [(ngModel)]="planogerencialcontas.condTotal"
                            (change)="toggleCondicaoTotal()" class="slideThickLabel">Condição de resultado
                          </mat-slide-toggle>
                        </div>
                        <div class="col-sm-4" *ngIf="planogerencialcontas.condTotal">
                          <p style="margin-top: 15px;" class="mat-slide-toggle-content row-item">quando resultado for
                          </p>
                        </div>
                        <div class="col-sm-2" *ngIf="planogerencialcontas.condTotal">
                          <p-dropdown class="type-p-dropdown" [options]="opcoesCondicaoTotal"
                            [(ngModel)]="planogerencialcontas.comparadorCondicaoTotal" [filter]="true">
                          </p-dropdown>
                        </div>
                        <div class="col-sm-1" *ngIf="planogerencialcontas.condTotal">
                          <p style="margin-top: 15px;" class="mat-slide-toggle-content">a</p>
                        </div>
                        <div class="row-item" *ngIf="planogerencialcontas.condTotal">
                          <input class="row-item" type="number" placeholder="número"
                            [(ngModel)]="planogerencialcontas.numeroCondicaoTotal">
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <mark class="d-inline-block mb-1 mt-4" style="font-size: 13px;">Não informar o
                          <strong>WHERE</strong>,
                          somente a condição.</mark>
                        <div class="form-row">
                          <div class="form-group col-sm-12">
                            <textarea rows="8" cols="100" [(ngModel)]="planogerencialcontas.sql"
                              class="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-sm-12">
                        <button type="button" class="btn btn-primary" (click)="salvarConta()">
                          <i class="fas fa-check"></i>
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--FIM TELA 2.1 - PLANO GERENCIAL - CONDIÇÃO-->
                </div>
              </div>

              <!-- Vinculação através de outro plano gerencial -->
              <div *ngIf="isVinculacaoPg">
                <div class="box-content pb-0" style="overflow: hidden;">
                  <div style="display: flex; flex-direction: column; margin-left: 1rem;">
                    <div style="margin-top: 2px;">
                      <div class="col-sm-4" style="padding: 10px 10px;">
                        <p-multiSelect style="margin-top: 0px; width: 10px;" class="type-p-mult type-p-mult-clear"
                          [options]="opcoesPgVinculacao" defaultLabel="Selecione" [filter]="true"
                          [(ngModel)]="selectedPgVinculacao" appendTo="body" [maxSelectedLabels]="1"
                          (onChange)="changePgVinculacao()"></p-multiSelect>
                      </div>
                      <p-table class="table-system" #dt8 [value]="listPgcVinculacao" [rows]="rows" [paginator]="true"
                        #dt (onRowSelect)="onRowSelect($event)" [pageLinks]="pageLinks"
                        [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%'}" [scrollable]="true"
                        scrollHeight="270px">
                        <ng-template pTemplate="header">
                          <tr>
                            <th class="text-center" style="width: 1%">
                              <p-tableHeaderCheckbox [(ngModel)]="planoContasListCheckAll"></p-tableHeaderCheckbox>
                            </th>

                            <th class="text-left"
                              style="text-align: left; width: 16%; background-color: #efefef; border-color: #cccccc;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                                <span class="text-overflow-dynamic-ellipsis">Código <p-sortIcon [field]="'codCta'">
                                  </p-sortIcon>
                                </span>
                              </span>
                              <input class="form-control form-control-sm" type="text"
                                (input)="dt.filter($any($event.target).value, 'codCta', 'contains')">
                            </th>

                            <th class="text-left"
                              style="text-align: left; width: 16%; background-color: #efefef; border-color: #cccccc;">
                              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                                <span class="text-overflow-dynamic-ellipsis">Descrição<p-sortIcon [field]="'descrCta'">
                                  </p-sortIcon>
                                </span>
                              </span>
                              <input class="form-control form-control-sm" type="text"
                                (input)="dt.filter($any($event.target).value, 'descrCta', 'contains')">
                            </th>

                          </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-conta>
                          <tr [pSelectableRow]="conta">
                            <td class="text-center" style="width:1%;">
                              <p-tableCheckbox [value]="conta"></p-tableCheckbox>
                            </td>
                            <td style="width: 16%;">
                              <span class="text-overflow-dynamic-container">
                                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(conta)"
                                  matTooltip="{{ conta.codCta }}">{{ conta.codCta }}</span>
                              </span>
                            </td>
                            <td style="width: 16%;">
                              <span class="text-overflow-dynamic-container">
                                <span class="text-overflow-dynamic-ellipsis" (click)="onRowSelect(conta)"
                                  matTooltip="{{ conta.codCta }}">{{ conta.descrCta }}</span>
                              </span>
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" *ngIf="listPgcVinculacao.length < 1">
                          <tr>
                            <td [attr.colspan]="2" class="spaced-alert">
                              <div class="alert alert-xs alert-primary d-inline" role="alert">
                                <i class="fas fa-info-circle"></i> {{this.listPgcVinculacao.length <
                                  1? 'Selecione um plano gerencial!' : 'O plano gerencial não possuí contas analiticas.'
                                  }} </div>
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <p-dialog header="Plano Gerencial" [(visible)]="modalPlanoGerencial" [style]="{'width':'800px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">

    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>CÓDIGO</label>
        <input class="form-control" type="text" [(ngModel)]="planoGerencialCodigo" />
      </div>
      <div class="form-group col">
        <label>NOME</label>
        <input class="form-control" type="text" [(ngModel)]="planoGerencialNome" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>NÍVEIS</label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesNivel" [(ngModel)]="planoGerencialNivel" [filter]="true">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>MOEDA</label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesMoeda" [(ngModel)]="planoGerencialMoeda" [filter]="true">
        </p-dropdown>
      </div>
      <div class="form-group col-sm-4">
        <label>VIEW</label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesView" [(ngModel)]="planoGerencialView" [filter]="true">
        </p-dropdown>
      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalPlanoGerencial = false"><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" *ngIf="editPc || newPc" (click)="atualizarPlanoGerencial()"><i
          class="fas fa-check"></i> Salvar</button>
    </p-footer>
  </p-dialog>

  <!-- Modal Conta Gerencial -->
  <p-dialog header="Conta Gerencial" [(visible)]="modalContaPlanoGerencial" [style]="{'width':'800px'}" [modal]="true"
    styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>CÓDIGO</label>
        <input class="form-control" type="text" [(ngModel)]="planogerencialcontas.codCta" />
      </div>
      <div class="form-group col">
        <label>DESCRIÇÃO</label>
        <input class="form-control" type="text" [(ngModel)]="planogerencialcontas.descrCta" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <label>NATUREZA</label>
        <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesNatureza"
          (onChange)="procurarContaSuperior()" placeholder="Selecione a Natureza"
          [(ngModel)]="planogerencialcontas.codNaturezaConta.id"></p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>NÍVEL</label>
        <p-dropdown class="type-p-dropdown" [options]="opcoesNivel" (onChange)="procurarContaSuperior()"
          [(ngModel)]="planogerencialcontas.nivel" [filter]="true"></p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <label>INDICADOR</label>
        <p-dropdown id="indCta" name="indCta" class="type-p-dropdown" [filter]="true" [options]="opcoesIndicador"
          placeholder="Selecione o Indicador" [(ngModel)]="planogerencialcontas.indCta"
          (onChange)="procurarContaSuperior()"></p-dropdown>
      </div>
      <div class="form-group col-sm-4">
        <label>CONTA SUPERIOR</label>
        <p-dropdown id="ctaSup" name="ctaSup" class="type-p-dropdown" [filter]="true" [options]="opcoesContas"
          placeholder="Selecione a Conta Superior" [(ngModel)]="planogerencialcontas.ctaSup.id"></p-dropdown>
      </div>
      <div class="form-group col-sm-1">
        <label>ORDEM</label>
        <input class="form-control" type="number" [(ngModel)]="planogerencialcontas.ordem" />
      </div>
      <div style="display: flex; flex-direction: row; width: 100%; align-items: center; justify-content: flex-start;">
        <div class="form-group col-sm-2" style="padding-left: 4px;">
          <label>Recebimento/Débito</label>
          <p-dropdown id="indRd" name="indRd" class="type-p-dropdown" [filter]="true" [options]="opcoesRecbDeb"
            placeholder="Selecione o tipo da conta" [(ngModel)]="planogerencialcontas.indRd"></p-dropdown>
        </div>
        <div style="display: flex; height: 100%; width: 200px; align-items: center; justify-content: flex-start;">
          <p-checkbox [(ngModel)]="planogerencialcontas.inverterSinal" [binary]="true">
          </p-checkbox>
          <label class="ml-1">Inverter o resultado</label>
        </div>
        <div style="display: flex; height: 100%; width: 200px; align-items: center; justify-content: flex-start;"
          *ngIf="planogerencialcontas.indCta === 'T'">
          <p-checkbox [(ngModel)]="planogerencialcontas.isPorcentagem" [binary]="true">
          </p-checkbox>
          <label class="ml-1">Porcentagem</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h3 class="sub-title"><i class="fas fa-sliders-h"></i> Configurações para relatórios</h3>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <p-checkbox [(ngModel)]="confLineAbove" [binary]="true"></p-checkbox>
        <label class="ml-1">Pular uma linha acima</label>
      </div>
      <div class="form-group col-sm-4">
        <p-checkbox [(ngModel)]="confLineBelow" [binary]="true"></p-checkbox>
        <label class="ml-1">Pular uma linha abaixo</label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
        <p-checkbox [(ngModel)]="isLinhaCor" [binary]="true" (click)="checkCorLinha()"></p-checkbox>
        <label class="ml-1">Linha colorida?</label>
      </div>
      <div class="form-group col-sm-4">
        <label>Cor da linha</label>
        <input type="color" class="form-control" [(ngModel)]="confLineColor" style="height: 34px;"
          [disabled]="!isLinhaCor" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
        <p-checkbox [(ngModel)]="isFonteCor" [binary]="true" (click)="checkCorFonte()"> </p-checkbox>
        <label class="ml-1">Cor de fonte?</label>
      </div>
      <div class="form-group col-sm-4">
        <label>Cor da fonte</label>
        <input type="color" class="form-control" [(ngModel)]="confFontColor" style="height: 34px;"
          [disabled]="!isFonteCor" />
      </div>
      <div class="form-group col-sm-4">
        <label class="d-block" style="visibility: hidden;">DO NOT REMOVE</label>
        <p-checkbox [(ngModel)]="confBold" [binary]="true"></p-checkbox>
        <label class="ml-1">Negrito</label>
      </div>
    </div>

    <!--Modal Upload plano gerencial-->
    <p-dialog [(visible)]="modalUploadGerencial" styleClass="modal-scroll" [style]="{'width':'700px'}" [modal]="true"
      [responsive]="true" appendTo="body" [closable]="true">
      <p-header>
        <div class="cabecalho">
          <label>Importar Plano de Contas Gerenciais</label>
          <a href="https://aws-website-downloads-e72og.s3.amazonaws.com/LAYOUT_UPLOAD/LAYOUT_FINAL_PG.csv"
            matTooltip="Baixar Layout" matTooltipPosition="above">
            <span class="material-icons"
              style="font-size: 24px; margin-left: 18px; margin-top: -5px; color: #666666;">cloud_download</span>
          </a>
        </div>
      </p-header>
      <div class="form-row">
        <div class="form-group col-sm-9 mb-1 mt-1">
          <label class="d-block">Selecione um arquivo</label>
          <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
            <span class="input-group-append">
              <span class="input-group-text btn-file">
                <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                  Arquivo</span>
                <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar
                  Arquivo</span>
                <input type="file" #file (change)="fileChange()" />
              </span>
            </span>
            <div class="form-control" data-trigger="fileinput">
              <span class="fileinput-filename" id="file-name"></span>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-3 mb-0">
          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
          <button type="button" class="btn btn-primary" (click)="importar()"><i class="fas fa-upload"></i>
            Importar</button>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="modalUploadGerencial = false;"><i
            class="fas fa-undo"></i> Cancelar</button>
      </p-footer>
    </p-dialog>
    <!--Fim do Modal-->

    <!---Modal Clone Plano Gerencial -->
    <p-dialog header="Clonar plano gerencial" [(visible)]="modalClonePG" [style]="{'width':'700px'}" [modal]="true"
      styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
      <div *ngIf="modalClonePG">
        <div class="form-row">
          <div class="form-group col-sm-12">
            <label>Selecione a(s) empresa(s) de destino </label>
            <p-multiSelect class="type-p-mult type-p-mult-clear" [options]="listEmpresas"
              defaultLabel="Selecione uma empresa" [filter]="true" [(ngModel)]="empresasSelecionadas" appendTo="body"
              [maxSelectedLabels]="1" placeholder="{0} empresas selecionadas"
              selectedItemsLabel="{0} empresas selecionadas">
            </p-multiSelect>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-2">
            <label>CÓDIGO</label>
            <input class="form-control" type="text" [(ngModel)]="planoGerencialCloneCodigo" />
          </div>
          <div class="form-group col">
            <label>DESCRIÇÃO</label>
            <input class="form-control" type="text" [(ngModel)]="planoGerencialNomeNew" />
          </div>
          <div class="form-group col-sm-12" style="margin-bottom: 0px;">
            <mat-slide-toggle class="slideThickLabel" style="margin-top: 3px;" [(ngModel)]="colSQL">Obter Coluna SQL</mat-slide-toggle>
          </div>
        </div>
      </div>
      <p-footer>
        <button type="button" class="btn btn-secondary" (click)="modalClonePG = false;"><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="clonarPlano()"><i class="fas fa-check"></i>
          Clonar</button>
      </p-footer>
    </p-dialog>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)="modalContaPlanoGerencial = false;"><i
          class="fas fa-undo"></i> Cancelar</button>
      <button type="button" class="btn btn-primary" *ngIf="!editCpg" (click)="save()"><i class="fas fa-check"></i>
        Salvar</button>
      <button type="button" class="btn btn-primary" *ngIf="editCpg" (click)="editarConta()"><i class="fas fa-check"></i>
        Salvar</button>
    </p-footer>
  </p-dialog>
  <!--Fim modal plano gerencial-->
</mat-card>