<div>
  <div class="col-sm-12" style="padding: 0 !important" *ngIf="!loadingConfigPattern">
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Chave de acesso <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.chaveAcesso" (keyup)="validationConfig()"/>
      </div>
      <div class="form-group col-sm-6">
        <label>Contas Bancárias <span class="obrigatorio">*</span></label>
        <p-multiSelect class="type-p-mult" appendTo="body" panelStyleClass="mult-table" [options]="optCompanyAccounts" placeholder="Selecione" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyAccounts" [dropdownIcon]="loadingIconOptCompanyAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="selectedAccountCompanies" (onChange)="validationConfig()"></p-multiSelect>
      </div>
      <!-- <div class="form-group col-sm-6">
        <label>Cliente padrão<span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optCustomers" emptyMessage="Nenhum cliente encontrado!" [disabled]="loadingIconOptCustomer" [dropdownIcon]="loadingIconOptCustomer ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="config.clientePadraoId"></p-dropdown>
      </div> -->
    </div>
  </div>
  <div class="col-sm-12 loading-content" style="padding: 0 !important" *ngIf="loadingConfigPattern">
    <p><span class="material-icons rotate">hourglass_empty</span> <b>Carregando informações da conta ...</b></p>
  </div>
</div>
