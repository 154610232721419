<mat-card class="card-linnks margin-linnks">
  <!--<mat-card-title>
    IMPORTAR LEADS
  </mat-card-title>-->
  <mat-card-content>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px;">
      <div class="ui-g-12">

      </div>
    </div>

    <div class="box-content pt-2 col-sm-6" style="min-width: 600px;">
      <div class="row">
        <div class="col-sm-12">
            <h3 class="sub-title"><i class="fas fa-file-upload"> </i> Importação de leads</h3>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
            <label>Selecione o Workshop</label>
            <p-dropdown class="type-p-dropdown" [(ngModel)]="idWorkshop" [options]="opcoesWorkshop"  [filter]="false"
            placeholder="Selecione um workshop"></p-dropdown>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-sm-12">
            <label>Selecione a Carteira</label>
            <p-dropdown class="type-p-dropdown" [(ngModel)]="idCarteira" [options]="opcoesCarteiras"  [filter]="false"
            placeholder="Selecione uma carteira"></p-dropdown>
        </div>
      </div>




      <div class="form-row">
        <div class="form-group col-sm-12 mb-1 mt-1">
          <label class="d-block">Selecione um arquivo para importar</label>
          <div class="fileinput fileinput-new input-group input-group-sm" data-provides="fileinput">
              <span class="input-group-append">
                  <!-- <span class="input-group-text fileinput-exists" data-dismiss="fileinput">
                    Remover Arquivo
                  </span> -->
                  <span class="input-group-text btn-file">
                    <span class="fileinput-new"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar Arquivo</span>
                    <span class="fileinput-exists"><i class="fas fa-paperclip" style="color: #0091ea;"></i> Selecionar Arquivo</span> <!-- Antes era Trocar Arquivo-->
                    <input type="file" #file (change)="fileChange()" />
                  </span>
              </span>
              <div class="form-control" data-trigger="fileinput">
                <span class="fileinput-filename"></span>
              </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col mb-0">
            <button type="button" class="btn btn-primary" [disabled]="importDisabled" (click)="importar()"><i class="fas fa-upload"></i> Importar</button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
