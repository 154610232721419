<mat-card class="card-linnks margin-linnks" style="margin-top: 15px !important; margin-bottom: 10px; overflow: hidden;">
  <mat-card-title class="mt-3 mb-3">
    {{title}}
  </mat-card-title>
  <mat-card-content>
    <div class="shadow-box" style="box-shadow: none;" [ngClass]="fullTable ? 'box-fullscreen' : ''">
      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card"><i class="fas fa-table"></i>{{configName}} | Data ref.:
            {{dataRefToDate(dataRef) | date: 'MM/yyyy'}}</h3>
          <div class="actions-card">
            <button type="button" (click)="atualizar()" class="btn btn-xs btn-link" matTooltip="Atualizar valores"
              matTooltipPosition="above">
              <span class="material-icons" style="font-size: 23px; margin-right: 0px;">refresh</span>
            </button>
            <button type="button" class="btn btn-xs btn-link" (click)="gerarRelatorioGerencialExcel()"
              matTooltip="Exportar Excel" matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
            </button>
            <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Fullscreen"
              matTooltipPosition="above" (click)="fullScreenTable()">
              <span *ngIf="!fullTable" class="material-icons full-icon">fullscreen</span>
              <span *ngIf="fullTable" class="material-icons full-icon">fullscreen_exit</span>
            </button>
            <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeTableView()"
              (click)="changeStateTableView()">
              <span *ngIf="isCardTableView" class="material-icons" matTooltip="Ocultar Card"
                matTooltipPosition="above">expand_less</span>
              <span *ngIf="!isCardTableView" class="material-icons" matTooltip="Ver Card"
                matTooltipPosition="above">expand_more</span>
            </button>
          </div>
        </div>
      </div>
      <div class="box-content" style="box-shadow: none;" [@changeDivSize]=currentStateTableView>
        <div class="shadown-table" style="box-shadow: none;">

          <div class="p-table-new-scroll">

            <p-table class="table-system table-preview" [columns]="colunas" [value]="contasGerenciais" [rows]="rows">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of colunas" style="border-left: solid 1px #ccc !important"
                    [ngClass]="col.tipo === 2 ? 'separador' : ''" [ngStyle]="getStyle('header', col, null)">
                    <span class="{{col.indice === 0 ? 'overflow-ellipse text-left' : 'overflow-ellipse text-center'}}"
                      matTooltip="{{col.label}}" matTooltipPosition="above">
                      {{ col.label }}
                    </span>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-conta>
                <tr *ngIf="conta['linha_acima'] && (!hideNoValueContas || !isHidden(conta))">
                  <td class="separador-linha" style="border-left: solid 1px #ccc;" *ngFor="let col of colunas"
                    [ngClass]="col.tipo === 2 ? 'separador' : ''"
                    [ngStyle]="{'background-color':col.iscorfundocoluna ? col.corfundo : '', 'width': col.indice === 0 ? '270px' : '50px'}">
                  </td>
                </tr>

                <tr *ngIf="!hideNoValueContas || !isHidden(conta)" (click)="openModalDetalhe(conta)">
                  <td *ngFor="let col of colunas" style="border-left: solid 1px #ccc;" [ngClass]="col.indice === 0? conta['nivel'] === 1? 'nivel1' : conta['nivel'] === 2? 'nivel2' : conta['nivel'] === 3? 'nivel3' : conta['nivel'] === 4? 'nivel4' : 'nivel5'
                                    : col.tipo === 2 ? 'separador' : ''" [ngStyle]="getStyle('body', col, conta)">
                    <span class="{{col.indice !== 0 ? 'overflow-ellipse text-right' : 'overflow-ellipse'}}"
                      [ngStyle]="{'text-transform': col.indice !== 0? 'lowercase' : 'uppercase'}">
                      {{ col.tipo === 3 && col.isvalor && conta.is_porcentagem || conta[alfabeto(col.indice)] == '-'?
                      "-" :
                      col.tipo === 2 ? "" :
                      col.indice === 0? conta[alfabeto(col.indice)] :
                      toPercent(conta, col.ispercent, conta[alfabeto(col.indice)]) | number: '1.' + col.qtddecimais +
                      '-' + col.qtddecimais:'pt-BR'}}{{
                      (col.tipo === 3 && col.isvalor && conta.is_porcentagem) || conta[alfabeto(col.indice)] == '-'? ""
                      :
                      col.tipo == 3 && conta.is_porcentagem? "p.p." :
                      col.ispercent || (conta.is_porcentagem && col.tipo == 1 && col.indice != 0) ||
                      (conta[alfabeto(col.indice)+"-pgc"] != null && conta[alfabeto(col.indice)+"-pgc"]['isPercent'])?
                      "%" : ""}}
                    </span>
                  </td>
                </tr>

                <tr *ngIf="conta['linha_abaixo'] && (!hideNoValueContas || !isHidden(conta))">
                  <td class="separador-linha" *ngFor="let col of colunas" [ngClass]="col.tipo === 2 ? 'separador' : ''"
                    [ngStyle]="{'background-color':col.iscorfundocoluna ? col.corfundo : '', 'width': col.indice === 0 ? '270px' : '50px'}">
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </div>

          <p-dialog header="{{contaselecionada}}" [(visible)]="modalDetalhe" width="1600" [modal]="true"
            styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">
            <div class="p-table-new-scroll">
              <p-table class="table-system table-preview" #dt [columns]="colunasDetalhe" [value]="tableDetalhe"
                [rows]="rows">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngIf="niveis?.length == 0" style="border-left: solid 1px #ccc; width: 100px;">Níveis não
                      informados</th>
                    <th *ngFor="let nivel of niveis" style="border-left: solid 1px #ccc; width: 200px;">{{nivel.label}}
                    </th>
                    <th *ngFor="let col of colunasDetalhe" style="border-left: solid 1px #ccc !important"
                      [ngClass]="col.tipo === 2 ? 'separador' : ''" [ngStyle]="getStyle('header', col, null)">
                      <span class="overflow-ellipse text-center" matTooltip="{{col.label}}" matTooltipPosition="above">
                        {{ col.label }}
                      </span>
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-detalhe>
                  <tr>
                    <td *ngIf="niveis?.length == 0" style="border-left: solid 1px #ccc; width: 100px;"></td>
                    <td *ngFor="let nivel of niveis" style="border-left: solid 1px #ccc; width: 200px;">
                      {{detalhe[nivel.tipo]}}</td>
                    <td *ngFor="let col of colunasDetalhe" style="border-left: solid 1px #ccc;"
                      [ngClass]="col.tipo === 2 ? 'separador' : ''" [ngStyle]="getStyle('body', col, detalhe)">
                      <span class="overflow-ellipse text-center" matTooltip="{{detalhe[alfabeto(col.indice)]}}"
                        matTooltipPosition="above">
                        {{ col.tipo === 2 ? "" : detalhe[alfabeto(col.indice)] == null? '0,00' :
                        detalhe[alfabeto(col.indice)] | number: '1.' +
                        col.qtddecimais + '-' + col.qtddecimais:'pt-BR'}}{{col.ispercent ? "%" : "" }}
                      </span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-dialog>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>