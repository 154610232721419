<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="clearfix"></div>
    <div class="row">
      <div [ngStyle]="{ 'min-width' : novaConta && editarConta?  '818px' : '818px' }">
        <div class="box-content">
          <div class="header-box" style="margin-top: -25px !important;">
            <div class="header-box-content" style="padding: 1rem !important; margin-left: -25px;">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                 <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>


          <div *ngIf="!novaConta && !editarConta">

            <p-table class="table-system" #dt [columns]="this['columns']" [value]="registroView" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple" [style]="{'width':'100%'}"
              immutable="false" (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'campo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Campo <p-sortIcon [field]="'campo'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'campo', 'contains')">
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'aliasCampo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Alias <p-sortIcon [field]="'aliasCampo'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'aliasCampo', 'contains')">
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Descrição <p-sortIcon [field]="'descricao'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'descricao', 'contains')">
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                      <span class="text-overflow-dynamic-ellipsis">
                        Tipo <p-sortIcon [field]="'tipo'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($any($event.target).value, 'tipo', 'contains')">
                  </th>

                  <th class="text-center">
                    <br>
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'view.id'">
                      <span class="text-overflow-dynamic-ellipsis">
                       View <p-sortIcon [field]="'view.id'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="opcoesM" 
                    (onChange)="dt.filter($event.value, 'view.id', 'equals')"></p-dropdown>
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData  [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" [id]="'checkboxContainer'+rowData.id">
                    <p-tableCheckbox [value]="rowData" (click)="selectCheckboxContainer(rowData)">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.campo}}">{{rowData.campo}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.aliasCampo}}">{{rowData.aliasCampo}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.tipo}}">{{rowData.tipo}}</span>
                    </span>
                  </td>

                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.viewId}}">{{rowData.view != null? rowData.view.nome: ''}}</span>
                    </span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <div class="row form-projeto" *ngIf="novaConta || editarConta">
      <div style="min-width: 818px;">
        <div class="box-content">
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Campo</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="viewCampo">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Alias</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="viewAlias">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Descrição</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="viewDescricao">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>Tipo</label>
              <input class="form-control" id="input" type="text" pInputText [(ngModel)]="viewTipo">
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>VIEW</label>
              <p-dropdown class="type-p-dropdown" [options]="opcoesViewCampos" [(ngModel)]="viewView">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
</mat-card>