<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
    <mat-card-subtitle>
      {{ subtitle }}. Período selecionado{{' '+
      this.dataIniSearch.substring(8,10)+'/'+this.dataIniSearch.substring(5,7)+'/'+this.dataIniSearch.substring(0,4)}}
      à {{' '+ this.dataFinSearch.substring(8,10)
      +'/'+this.dataFinSearch.substring(5,7)+'/'+this.dataFinSearch.substring(0,4)}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <mat-tab-group (selectedTabChange)="tabChange($event)">
      <!--boletos ativos (selectedTabChange)="tabChanged($event)"> -->
      <mat-tab label="Ativos ({{ boletosAtivos.length }})">
        <div class="row">
          <div class="col-sm-12">
            <div class="shadow-box">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card"> &nbsp;</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link" (click)="dt10.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above">
                      <i class="far fa-file-excel excel"></i>
                    </button>

                    <button type="button" class="btn btn-xs btn-link" (click)="changeCardState()">
                      <span *ngIf="iconState" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">
                        expand_less
                      </span>
                      <span *ngIf="!iconState" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">
                        expand_more
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="box-content" [@changeDivSize]="currentState">

                <div class="clearfix"></div>

                <p-table class="table-system" #dt10 [columns]="colsBoletosAtivos" [value]="boletosAtivos" [rows]="rows"
                  [paginator]="true" [totalRecords]="boletosAtivos.length" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 45px;">

                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'reference'">
                          <span class="text-overflow-dynamic-ellipsis">Ref. <p-sortIcon [field]="'reference'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'reference', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtDueDate'">
                          <span class="text-overflow-dynamic-ellipsis">Vencto <p-sortIcon [field]="'dtDueDate'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'dtDueDate', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtCreate'">
                          <span class="text-overflow-dynamic-ellipsis">Emissão <p-sortIcon [field]="'dtCreate'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'dtCreate', 'contains')">
                      </th>

                      <th class="text-left" style="width: 23%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                          <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'nome', 'contains')">
                      </th>

                      <th class="text-left" style="width: 12%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                          <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'cpf', 'contains')">
                      </th>

                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                          <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'valor', 'contains')">
                      </th>


                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                          <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                            </span>
                        </span>
                        <p-multiSelect class="type-p-mult" [options]="opcoesComboAtivoStatus" defaultLabel="Selecione"
                          [filter]="false" (onChange)="dt10.filter($event.value, 'status', 'in')"></p-multiSelect>
                      </th>

                      <th class="text-center" style="width: 15%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtLastEvent'">
                          <span class="text-overflow-dynamic-ellipsis">Última Atualização <p-sortIcon [field]="'dtLastEvent'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt10.filter($event.target.value, 'dtLastEvent', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center" style="width: 45px;">
                        <p-checkbox (click)="selectBoleto(rowData.id, rowData)" [(ngModel)]="rowData.selected">
                        </p-checkbox>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reference}}">{{rowData.reference
                            }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtDueDate}}">{{rowData.dtDueDate
                            }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.dtCreate}}">{{rowData.dtCreate}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 23%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 12%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf | cpf}}">{{rowData.cpf | cpf
                            }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}">{{rowData.valor
                            }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                            }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 15%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.dtLastEvent}}">{{rowData.dtLastEvent }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="13" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!--boletos cancelados-->
      <mat-tab label="Cancelados ({{ boletosCancelados.length }})">
        <div class="row">
          <div class="col-sm-12">
            <div class="shadow-box">
              <div class="header-box">
                <div class="header-box-content">
                  <h3 class="title-card"> &nbsp;</h3>
                  <div class="actions-card">
                    <button type="button" class="btn btn-xs btn-link" (click)="dt20.exportCSV()" matTooltip="Exportar Excel" matTooltipPosition="above">
                      <i class="far fa-file-excel excel"></i>
                    </button>

                    <button type="button" class="btn btn-xs btn-link" (click)="changeCardState()">
                      <span *ngIf="iconState" class="material-icons" matTooltip="Ocultar Card"
                        matTooltipPosition="above">
                        expand_less
                      </span>
                      <span *ngIf="!iconState" class="material-icons" matTooltip="Ver Card"
                        matTooltipPosition="above">
                        expand_more
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="box-content" [@changeDivSize]="currentState">
                <div class="clearfix"></div>

                <p-table class="table-system" #dt20 [columns]="colsBoletosAtivos" [value]="boletosCancelados" [rows]="rows"
                  [paginator]="true" [totalRecords]="boletosCancelados.length" (onFilter)="onFilter($event)"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
                  [style]="{'width':'100%'}">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="text-center" style="width: 45px;">

                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'reference'">
                          <span class="text-overflow-dynamic-ellipsis">Ref. <p-sortIcon [field]="'reference'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'reference', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtDueDate'">
                          <span class="text-overflow-dynamic-ellipsis">Vencto <p-sortIcon [field]="'dtDueDate'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'dtDueDate', 'contains')">
                      </th>
                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtCreate'">
                          <span class="text-overflow-dynamic-ellipsis">Emissão <p-sortIcon [field]="'dtCreate'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'dtCreate', 'contains')">
                      </th>

                      <th class="text-left" style="width: 23%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                          <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'nome', 'contains')">
                      </th>

                      <th class="text-left" style="width: 12%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'cpf'">
                          <span class="text-overflow-dynamic-ellipsis">CPF <p-sortIcon [field]="'cpf'"></p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'cpf', 'contains')">
                      </th>

                      <th class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'valor'">
                          <span class="text-overflow-dynamic-ellipsis">Valor <p-sortIcon [field]="'valor'"></p-sortIcon>
                            </span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'valor', 'contains')">
                      </th>


                      <th class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                          <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                            </span>
                        </span>
                        <p-multiSelect class="type-p-mult" [options]="opcoesComboCanceladoStatus" defaultLabel="Selecione"
                          [filter]="false" (onChange)="dt20.filter($event.value, 'status', 'in')"></p-multiSelect>
                      </th>

                      <th class="text-center" style="width: 15%;">
                        <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtLastEvent'">
                          <span class="text-overflow-dynamic-ellipsis">Última Atualização <p-sortIcon [field]="'dtLastEvent'">
                            </p-sortIcon></span>
                        </span>
                        <input class="form-control form-control-sm" type="text"
                          (input)="dt20.filter($event.target.value, 'dtLastEvent', 'contains')">
                      </th>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData>
                    <tr>
                      <td class="text-center" style="width: 45px;">
                        <p-checkbox (click)="selectBoleto(rowData.id, rowData)" [(ngModel)]="rowData.selected">
                        </p-checkbox>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.reference}}">{{rowData.reference
                            }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtDueDate}}">{{rowData.dtDueDate
                            }}</span>
                        </span>
                      </td>
                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.dtCreate}}">{{rowData.dtCreate}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 23%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
                        </span>
                      </td>
                      <td class="text-left" style="width: 12%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cpf | cpf}}">{{rowData.cpf | cpf
                            }}</span>
                        </span>
                      </td>

                      <td class="text-right" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.valor}}">{{rowData.valor
                            }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 10%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                            }}</span>
                        </span>
                      </td>

                      <td class="text-center" style="width: 15%;">
                        <span class="text-overflow-dynamic-container">
                          <span class="text-overflow-dynamic-ellipsis"
                            matTooltip="{{rowData.dtLastEvent}}">{{rowData.dtLastEvent }}</span>
                        </span>
                      </td>

                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-rowData>
                    <tr>
                      <td [attr.colspan]="13" class="spaced-alert">
                        <div class="alert alert-xs alert-primary d-inline" role="alert">
                          <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="row mt-3">
      <div class="col-sm-12">
        <!--Total Período-->
        <div class="pull-left mr-3">
          <div class="div-card-total">
            <p class="titulo-card-total">Total Período</p>
            <p class="valor-card-total" *ngIf="saldoAtual >= 0">R$ {{ saldoAtual | money}}</p>
            <p class="valor-card-total" *ngIf="saldoAtual < 0" style="color:darkred;">R$ {{ saldoAtual | money}}</p>
          </div>
        </div>
        <!--Total Filtro-->
        <div class="pull-left mr-3" *ngIf="valorFiltro > 0">
          <div class="div-card-total">
            <p class="titulo-card-total">Total Filtro</p>
            <p class="valor-card-total" *ngIf="valorFiltro >= 0">R$ {{ valorFiltro | money}}</p>
            <p class="valor-card-total" *ngIf="valorFiltro < 0" style="color:darkred;">R$ {{ valorFiltro | money}}</p>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- Modal Alterar Período -->
  <p-dialog header="Alterar Período" [(visible)]="modalPeriodo" [style]="{width: '400px'}" [modal]="true"
    class="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true">
    <div *ngIf="modalPeriodo">
      <div class="form-row">
        <div class="form-group col-sm-4">
          <label>Data Inicial</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataIni" [(ngModel)]="dataIni" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
        <div class="form-group col-sm-4">
          <label>Data Final</label>
          <p-calendar class="type-p-calendar" [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030"
            [defaultDate]="dataFin" [(ngModel)]="dataFin" dateFormat="dd/mm/yy" ></p-calendar>
        </div>
      </div>
    </div>
    <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
      <div class="ui-g-12">

      </div>
    </div>
    <p-footer>
      <button type="button" class="btn btn-secondary" (click)='modalPeriodo=false;'><i class="fas fa-undo"></i>
        Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="periodoChange();"><i class="fas fa-check"></i>
        Alterar</button>
    </p-footer>
  </p-dialog>
  <!-- Modal Alterar Período -->
</mat-card>