<app-data-table class="item-tipo"
            mainTitle="TIPO ITEM"
            singleTitle="TIPO ITEM"
            routerPath="/item-tipo"
            entityPath="/itemtipo"
            keyField="id"
            [entity]="itemTipo"
            [columns]="this['columns']"
            [controls]="this['controls']">
</app-data-table>
