<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
  </mat-card-title>
  <mat-card-content>
    <div class="row">
      <div class="shadow-box">
        <div class="box-content">
          <div class="header-box">
            <div class="header-box-content p-3">
              <ul class="list-inline filter-menu filter-card-header mt-0">
                <li class="list-inline-item"></li>
                <li class="list-inline-item"></li>
              </ul>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div *ngIf="!novaConta && !editarConta">
            <p-table class="table-system" #dt [columns]="this['columns']" [value]="listaParametros" [rows]="rows"
              [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="multiple"
              [style]="{'width':'100%'}" immutable="false" [(selection)]="selectedRows" (onRowSelect)="changeToolbar()"
              (onRowUnselect)="changeToolbar()">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 40px;">
                  </th>
                  <th class="text-center" style="height: 20px;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'respLimiteCompraPriAlcada.nome'">
                      <span class="text-overflow-dynamic-ellipsis">
                        RESPONSÁVEL PRIMEIRA ALÇADA <p-sortIcon [field]="'respLimiteCompraPriAlcada.nome'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($event.target.value, 'respLimiteCompraPriAlcada.nome', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'respLimiteCredito.nome'">
                      <span class="text-overflow-dynamic-ellipsis">RESPONSÁVEL PELO LIMITE DE CRÉDITO <p-sortIcon
                          [field]="'respLimiteCredito.nome'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'respLimiteCredito.nome', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresaContaPagamento.nome'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CONTA PAGAMENTO <p-sortIcon [field]="'empresaContaPagamento.nome'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'empresaContaPagamento.nome', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'empresaContaRecebimento.nome'">
                      <span class="text-overflow-dynamic-ellipsis">
                        CONTA RECEBIMENTO <p-sortIcon [field]="'empresaContaRecebimento.nom'"></p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt.filter($any($event.target).value, 'empresaContaRecebimento.nome', 'contains')">
                  </th>
                  <th class="text-center">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'permiteAlteracaoPrecoVenda'">
                      <span class="text-overflow-dynamic-ellipsis">
                        PERMITE ALTERAÇÃO PREÇO DE VENDA <p-sortIcon [field]="'permiteAlteracaoPrecoVenda'">
                        </p-sortIcon>
                      </span>
                    </span>
                    <br>
                    <p-dropdown #cmp class="type-p-mult" [options]="optAlteracaoPrecoVenda"
                      (onChange)="dt.filter($event.value, 'permiteAlteracaoPrecoVenda', 'contains')"></p-dropdown>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData [paginator]="true" [pageLinks]="pageLinks"
                [rowsPerPageOptions]="rowsPerPage">
                <tr [pSelectableRow]="rowData">
                  <td class="text-center" style="width: 40px;" [id]="'checkboxContainer'+rowData.id">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.respLimiteCompraPriAlcada ? rowData.respLimiteCompraPriAlcada.nome : null}}">
                        {{rowData.respLimiteCompraPriAlcada ? rowData.respLimiteCompraPriAlcada.nome : null}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.respLimiteCredito ? rowData.respLimiteCredito.nome : null}}">
                        {{rowData.respLimiteCredito ? rowData.respLimiteCredito.nome : null}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.empresaContaPagamento ? rowData.empresaContaPagamento.nome : null}}">
                        {{rowData.empresaContaPagamento ? rowData.empresaContaPagamento.nome : null}}
                      </span>
                    </span>
                  </td>
                  <td class="text-left">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.empresaContaRecebimento ? rowData.empresaContaRecebimento.nome : null}}">
                        {{rowData.empresaContaRecebimento ? rowData.empresaContaRecebimento.nome : null}}
                      </span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 10%;">
                    <p-checkbox class="mr-1" [(ngModel)]="rowData.permiteAlteracaoPrecoVenda" [binary]="true"
                      inputId="permiteAlteracaoPrecoVenda" [disabled]="true"></p-checkbox>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="6" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="row form-projeto" *ngIf="novaConta || editarConta">
            <div style="min-width: 818px;">
              <div class="box-content">
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>CONTA BANCÁRIA PADRÃO PAGAMENTO</label>
                    <p-dropdown class="type-p-dropdown" [options]="optEmpresaConta"
                      [(ngModel)]="contaBancariaPagamento">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>CONTA BANCÁRIA PADRÃO RECEBIMENTO</label>
                    <p-dropdown class="type-p-dropdown" [options]="optEmpresaConta"
                      [(ngModel)]="contaBancariaRecebimento">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>RESPONSÁVEL LIMITE DE COMPRA (PRIMEIRA ALÇADA)</label>
                    <p-dropdown class="type-p-dropdown" [options]="optResponsaveis" [(ngModel)]="respPrimeiraAlcada">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>VALOR PRIMEIRA ALÇADA</label>
                    <input class="form-control" id="input" type="number" [(ngModel)]="valorPrimeiraAlcada">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>RESPONSÁVEL LIMITE DE COMPRA (SEGUNDA ALÇADA)</label>
                    <p-dropdown class="type-p-dropdown" [options]="optResponsaveis" [(ngModel)]="respSegundaAlcada">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>VALOR SEGUNDA ALÇADA</label>
                    <input class="form-control" id="input" type="number" [(ngModel)]="valorSegAlcada">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>RESPONSÁVEL LIMITE DE COMPRA (TERCEIRA ALÇADA)</label>
                    <p-dropdown class="type-p-dropdown" [options]="optResponsaveis" [(ngModel)]="respTerceiraAlcada">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-sm-12">
                    <label>VALOR TERCEIRA ALÇADA</label>
                    <input class="form-control" id="input" type="number" [(ngModel)]="valorTerAlcada">
                  </div>
                </div>
                <div class="form-row">
                <div class="form-group col-sm-12">
                  <label>RESPONSÁVEL PELO LIMITE DE CRÉDITO</label>
                  <p-dropdown class="type-p-dropdown" [options]="optResponsaveis" [(ngModel)]="respCredito">
                  </p-dropdown>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="pagamentoSomenteContaPadrao">PAGAMENTO SOMENTE PELA CONTA PADRÃO
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="recebimentoSomenteContaPadrao">RECEBIMENTO SOMENTE PELA CONTA PADRÃO
                  </mat-slide-toggle>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="permiteAlteracaoVenda">PERMITE ALTERAÇÃO PREÇO DE VENDA</mat-slide-toggle>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="verificaLimiteCompra">VERIFICA LIMITE DE COMPRA</mat-slide-toggle>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="verificaLimiteCredito">VERIFICA LIMITE DE CREDITO</mat-slide-toggle>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-sm-12">
                  <mat-slide-toggle [(ngModel)]="geraFinanceiro">PEDIDO GERA FINANCEIRO</mat-slide-toggle>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>