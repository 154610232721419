<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div>
    <mat-card-content>
      <div class="clearfix"></div>
      <div class="box-content pt-2">

        <!-- <div class="pull-right align-down-table">
          <button type="button" class="btn btn-link" (click)="dt2.exportCSV()"><i class="fas fa-file-excel excel"></i>
            Exportar para Excel</button>
        </div> -->
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card"> &nbsp;</h3>
            <div class="actions-card">
              <button type="button" class="btn btn-xs btn-link" (click)="dt2.exportCSV()" matTooltip="Exportar Excel"
                matTooltipPosition="above"><i class="far fa-file-excel excel"></i> </button>
            </div>
          </div>
        </div>

        <div class="clearfix"></div>


        <p-table class="table-system" #dt2 [columns]="colslistaConsultores" [value]="listaConsultores" [rows]="rows"
          [totalRecords]="listaConsultores.length" [paginator]="true" [pageLinks]="pageLinks"
          [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME PALESTRANTE <p-sortIcon [field]="'nome'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'nome', 'contains')">
              </th>

              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                  <span class="text-overflow-dynamic-ellipsis">EMAIL <p-sortIcon [field]="'email'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'email', 'contains')">
              </th>

              <th class="text-left">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'fone'">
                  <span class="text-overflow-dynamic-ellipsis">TELEFONE <p-sortIcon [field]="'fone'"></p-sortIcon>
                    </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt2.filter($event.target.value, 'fone', 'contains')">
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox (click)="selectCheckbox(rowData.id, rowData)" [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}"><span
                      style="user-select: all; cursor: text"> {{rowData.nome }} </span></span>
                </span>
              </td>

              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}"><span
                      style="user-select: all; cursor: text"> {{rowData.email }} </span></span>
                </span>
              </td>

              <td class="text-left">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.fone}}"><span
                      style="user-select: all; cursor: text"> {{rowData.fone }} </span></span>
                </span>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="4" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>



      </div>

      <div class="ui-g">
        <div class="ui-g-12">

        </div>
      </div>


      <p-dialog header="Consultor" [(visible)]="modalConsultor" [style]="{width: '800px'}" [modal]="true"
        styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">



        <!-- modalConsultor -->
        <div *ngIf="modalConsultor">


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>NOME </label>
              <input [(ngModel)]="consultor.nome" class="form-control text-left" type="text" maxlength="300" />
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>EMAIL </label>
              <input [(ngModel)]="consultor.email" class="form-control text-left" type="text" maxlength="150" />
            </div>
          </div>



          <div class="form-row">
            <div class="form-group col-sm-12">
              <label>TELEFONE </label>

              <p-inputMask class="type-p-mask" mask="(99)99999999?9" [(ngModel)]="consultor.fone"
                placeholder="(99)999999999"></p-inputMask>
            </div>
          </div>



        </div>
        <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">

        </div>



        <p-footer>
          <button type="button" class="btn btn-secondary" (click)="modalConsultor=false"><i class="fas fa-undo"></i>
            Cancelar</button>
          <button *ngIf="!editConsultor" type="button" class="btn btn-primary" (click)="salvarConsultor();"
            [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>
          <button *ngIf="editConsultor" type="button" class="btn btn-primary" (click)="editarConsultor();"
            [disabled]="btnSalvarDisabled"><i class="fas fa-check"></i> Salvar</button>

        </p-footer>
      </p-dialog>
      <!--Final Modal cadastro de Palestras-->





    </mat-card-content>
  </div>

</mat-card>