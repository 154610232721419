<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <!-- <div *ngIf="!tbCsllM350Relac?.tbCsllM350Adicao && !tbCsllM350Relac?.tbCsllM350Exclusao">
    <h2>Selecione uma conta de Adição e uma de Exclusão para serem mapeadas</h2>
  </div> -->

  <div *ngIf="tbCsllM350Relac?.tbCsllM350Adicao || tbCsllM350Relac?.tbCsllM350Exclusao">
    <div class="box-content pb-0">
      <div class="row"
        *ngIf="(tbCsllM350Relac?.tbCsllM350Adicao && !tbCsllM350Relac?.tbCsllM350Exclusao) || (!tbCsllM350Relac?.tbCsllM350Adicao && tbCsllM350Relac?.tbCsllM350Exclusao)">
        <div class="col"></div>
      </div>
      <div class="row" *ngIf="tbCsllM350Relac?.tbCsllM350Adicao && tbCsllM350Relac?.tbCsllM350Exclusao">
        <div class="col-sm-12">
          <button *ngIf="tbCsllM350Relac?.tbCsllM350Adicao && tbCsllM350Relac?.tbCsllM350Exclusao" (click)="back()"
            type="button" class="btn btn-xs btn-primary pull-left d-inline-block mr-2 mt-2"
            matTooltip="Selecionar Contas"><i class="fas fa-arrow-left"></i></button>
          <h3 class="sub-title"><i class="fas fa-sitemap"> </i> Mapeamento IRPJ: Plano de Contas Empresa</h3>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label>Adição Selecionada</label>
          <p>{{ tbCsllM350Relac?.tbCsllM350Adicao ? tbCsllM350Relac.tbCsllM350Adicao.cod + ' - ' +
            tbCsllM350Relac.tbCsllM350Adicao.descricao
            : 'Pendente' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label>Exclusão Selecionada</label>
          <p>{{ tbCsllM350Relac?.tbCsllM350Exclusao ? tbCsllM350Relac.tbCsllM350Exclusao.cod + ' - ' +
            tbCsllM350Relac.tbCsllM350Exclusao.descricao
            : 'Pendente' }}</p>
        </div>
      </div>
      <div class="form-row" *ngIf="tbCsllM350Relac?.tbCsllM350Adicao && tbCsllM350Relac?.tbCsllM350Exclusao">
        <div class="form-group col-sm-3">
          <label>Centro de Custo</label>
          <p-dropdown class="type-p-dropdown" [(ngModel)]="cadCcusId" [options]="opcoesCentroCusto" [filter]="true"
            (ngModelChange)="update()"></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tbCsllM350Relac?.tbCsllM350Adicao && tbCsllM350Relac?.tbCsllM350Exclusao">
    <div class="box-content pt-0">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Contas</h3>
        </div>
      </div>
      <p-table class="table-system" #dt2 [value]="planoContasList" [style]="{'width':'100%'}" immutable="false"
        (onFilter)="onFilterplanoContasList($event)" [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''"
        [scrollable]="planoContasList.length >= 15 ? true : false" scrollHeight="320px">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 40px;"></th>
            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'indCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt2.filter($event.target.value, 'descrCta', 'contains')">
            </th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [ngClass]="rowData.indCta === 'S'  ? 'bold ' : ''">
            <td class="text-center" style="width: 40px;">
              <p-checkbox [disabled]="planoContasDisabled(rowData)"
                [ngModel]="rowData.selected || planoContasDisabled(rowData)" (ngModelChange)="check(rowData, $event)">
              </p-checkbox>
            </td>
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.codCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.indCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.nivel}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <br>

    <div class="form-row">
      <div class="form-group col">
        <button type="button" class="btn btn-sm btn-info" (click)="add()" [disabled]="addDisabled"
          matTooltip="Adicionar Contas"><i class="fas fa-angle-double-down"></i> Adicionar</button>
        <button type="button" class="btn btn-sm btn-info" (click)="remove()" [disabled]="removeDisabled"
          matTooltip="Remover Contas"><i class="fas fa-angle-double-up"></i> Remover</button>
      </div>
    </div>

    <div class="box-content mt-2">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Contas Mapeadas</h3>
        </div>
      </div>
      <p-table class="table-system" #dt3 [value]="planoContasSelectedList" [style]="{'width':'100%'}" immutable="false"
        (onFilter)="onFilterplanoContasSelectedList($event)"
        [ngClass]="contadorLinhaSelected < 15 ? 'no-overflow-y' : ''"
        [scrollable]="planoContasSelectedList.length >= 15 ? true : false" scrollHeight="320px">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center" style="width: 40px;"></th>
            <th class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'codCta'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'codCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'indCta'">
                <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'indCta'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'indCta', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nivel'">
                <span class="text-overflow-dynamic-ellipsis">NÍVEL <p-sortIcon [field]="'nivel'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'nivel', 'contains')">
            </th>
            <th class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descrCta'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descrCta'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt3.filter($event.target.value, 'descrCta', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td class="text-center" style="width: 40px;">
              <p-checkbox [binary]="true" [(ngModel)]="rowData.selected"></p-checkbox>
            </td>
            <td class="text-left" style="width: 20%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.codCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.indCta}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.nivel}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 50%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descrCta}}">{{rowData.descrCta}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="5" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div *ngIf="!tbCsllM350Relac?.tbCsllM350Adicao || !tbCsllM350Relac?.tbCsllM350Exclusao">
    <div
      class="box-content {{ (tbCsllM350Relac?.tbCsllM350Adicao || tbCsllM350Relac?.tbCsllM350Exclusao ? 'pt-0' : '') }}">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Contas</h3>
        </div>
      </div>
      <p-table class="table-system" #dt1 [value]="tbCsllM350List" [rows]="rows" [paginator]="true"
        (onFilter)="onFiltertbCsllM350List($event)" (onRowSelect)="selectTbCsllM350($event)" [pageLinks]="pageLinks"
        emptyMessage="Nenhum Registro Encontrado" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
        [style]="{'width':'100%'}">

        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'cod'">
                <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'cod'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'cod', 'contains')">
            </th>
            <th class="text-left" style="width: 80%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                </span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
            </th>
            <th class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoLanc'">
                <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipoLanc'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'tipoLanc', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData"
            [ngClass]="rowData.tipo !== 'E'  ? (rowData.used ? 'bold locked line-through locked ': ' bold locked '  ) : (rowData.used ? 'line-through locked ' : '')">
            <td class="text-left" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.cod}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 80%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{rowData.descricao}}">{{rowData.descricao}}</span>
              </span>
            </td>
            <td class="text-center" style="width: 10%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">{{rowData.tipoLanc}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="3" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="box-content mt-4">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="sub-title"><i class="fas fa-file-alt"> </i> Contas Mapeadas</h3>
        </div>
      </div>
      <p-table class="table-system" #dt4 [value]="tbCsllM350RelacListDistinct" [style]="{'width':'100%'}"
        immutable="false" selectionMode="single" (onFilter)="onFiltertbCsllM350RelacListDistinct($event)"
        [ngClass]="contadorLinha < 15 ? 'no-overflow-y' : ''"
        [scrollable]="tbCsllM350RelacListDistinct.length >= 15 ? true : false" scrollHeight="320px"
        (onRowSelect)="editTbCsllM350Relac($event)">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-left" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">ADIÇÃO</span>
              </span>
            </th>
            <th class="text-left" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">EXCLUSÃO</span>
              </span>
            </th>
            <th class="text-center" style="width: 90px; vertical-align: sub;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis">AÇÃO</span>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-left" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{ rowData.tbCsllM350Adicao.cod + ' - ' + rowData.tbCsllM350Adicao.descricao }}">{{
                  rowData.tbCsllM350Adicao.cod + ' - ' + rowData.tbCsllM350Adicao.descricao }}</span>
              </span>
            </td>
            <td class="text-left" style="width: 45%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis"
                  matTooltip="{{ rowData.tbCsllM350Exclusao.cod + ' - ' + rowData.tbCsllM350Exclusao.descricao }}">{{
                  rowData.tbCsllM350Exclusao.cod + ' - ' + rowData.tbCsllM350Exclusao.descricao }}</span>
              </span>
            </td>
            <td class="text-center" style="width: 90px;">
              <span class="delete" matTooltip="Excluir!" (click)="remove(rowData)">
                <i class="fas fa-trash-alt"></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="3" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum registro encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>

</mat-card>