<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content p-3">
              <h3 class="title-card">CONFIGURAÇÃO FLUXO DE CAIXA</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
              </div>
            </div>
          </div>
          <div class="box-content" style="overflow: hidden;" [@changeDivSize]=currentStateConfigFlux>
            <!-- Table New P-TABLE -->
            <p-table class="table-system" #dt2 [value]="listaCtrl" [rows]="rows" dataKey="id"
              (onRowExpand)="onRowSelect($event)" rowExpandMode="single">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 45px;"></th>
                  <th class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeFantasia'">
                      <span class="text-overflow-dynamic-ellipsis">EMPRESA <p-sortIcon [field]="'nomeFantasia'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'nomeFantasia', 'contains')">
                  </th>
                  <th class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'nomeFluxo'">
                      <span class="text-overflow-dynamic-ellipsis">NOME FLUXO <p-sortIcon [field]="'nomeFluxo'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'nomeFluxo', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'createTimestamp'">
                      <span class="text-overflow-dynamic-ellipsis">CRIADO EM <p-sortIcon [field]="'createTimestamp'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'createTimestamp', 'contains')">
                  </th>
                  <th class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'userNome'">
                      <span class="text-overflow-dynamic-ellipsis">USUÁRIO <p-sortIcon [field]="'userNome'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'userNome', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'changeTimestamp'">
                      <span class="text-overflow-dynamic-ellipsis">ALTERADO EM <p-sortIcon [field]="'changeTimestamp'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'changeTimestamp', 'contains')">
                  </th>
                  <th class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'favorito'">
                      <span class="text-overflow-dynamic-ellipsis">FAVORITO <p-sortIcon [field]="'favorito'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt2.filter($event.target.value, 'favorito', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData let-expanded="expanded">
                <tr [class.highlight]="expanded">
                  <td class="text-center tableCheckbox">
                    <p-tableCheckbox (click)="selectCheckbox(rowData.id,rowData)" [value]="rowData">
                    </p-tableCheckbox>
                  </td>
                  <td [pRowToggler]="rowData" class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nomeFantasia}}">{{rowData.nomeFantasia}}</span>
                    </span>
                  </td>
                  <td [pRowToggler]="rowData" class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.nomeFluxo}}">{{rowData.nomeFluxo}}</span>
                    </span>
                  </td>
                  <td [pRowToggler]="rowData" class="text-center" style="width: 10%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.createTimestamp  | timezone | date:'dd/MM/yyyy'}}">{{rowData.createTimestamp
                        | timezone | date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>
                  <td [pRowToggler]="rowData" class="text-left" style="width: 25%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.userNome}}">{{rowData.userNome}}</span>
                    </span>
                  </td>
                  <td [pRowToggler]="rowData" class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.changeTimestamp  | timezone | date:'dd/MM/yyyy'}}">{{rowData.changeTimestamp
                        | timezone | date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 15%;">
                    <mat-radio-button [checked]="rowData.favorito" value="rowData.id"
                      (change)="changeFluxoFavorito(rowData.id)"></mat-radio-button>
                  </td>
                </tr>
              </ng-template>

              <!-- expansao grupo fluxo de caixa -->
              <ng-template pTemplate="rowexpansion" let-rowData>
                <tr class="expandContainer">
                  <td colspan="7">
                    <div class="p-3">

                      <div class="form-row">
                        <div class="form-group col-sm-2">
                          <label>Linha</label>
                          <input class="form-control" [(ngModel)]="grupoFluxoCaixa.codigoGrupo">
                        </div>
                        <div class="form-group col-sm-4">
                          <label>Grupo</label>
                          <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true"
                            [options]="tiposFluxoCaixa" placeholder="Selecione"
                            [(ngModel)]="grupoFluxoCaixa.tipoGrupoFluxo.id"></p-dropdown>
                        </div>
                        <div class="form-group col-sm-6">
                          <label>Descrição</label>
                          <input class="form-control" [(ngModel)]="grupoFluxoCaixa.descricao">
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-sm-12">
                          <div class="pull-right">
                            <button type="button" class="btn btn-sm btn-secondary"
                              *ngIf="grupoFluxoCaixa.id!==undefined" (click)="cancelarEdicaoGrupo()">Cancelar</button>
                            <button type="button" class="btn btn-sm btn-info"
                              (click)="grupoFluxoCaixaAdd()">{{((grupoFluxoCaixa.id===undefined)?' Adicionar':'
                              Alterar')}}
                            </button>
                          </div>
                        </div>
                      </div>

                      <app-skeleton [config]="skeleton">
                        <p-table class="table-system" [value]="fluxoCaixa.grupoFluxoCaixa" dataKey="id"
                          (onRowExpand)="criarAliasGrupo($event)" rowExpandMode="single">
                          <ng-template pTemplate="header">
                <tr>
                  <th style="width:10%;" class="text-center">Linha</th>
                  <th style="width:57%;">Descrição</th>
                  <th style="width:17%;">Tipo</th>
                  <th style="width:15%;" class="text-center">Ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-expanded="expanded">
                <tr [class.highlight]="expanded">
                  <td [pRowToggler]="rowData" [pRowTogglerDisabled]="rowData.tipoGrupoFluxo.id!=2" style="width:10%;"
                    class="text-center">
                    {{ rowData.codigoGrupo}}
                  </td>
                  <td [pRowToggler]="rowData" [pRowTogglerDisabled]="rowData.tipoGrupoFluxo.id!=2" style="width:60%;">
                    {{ rowData.descricao }}
                  </td>
                  <td [pRowToggler]="rowData" [pRowTogglerDisabled]="rowData.tipoGrupoFluxo.id!=2" style="width:15%;">
                    {{ rowData.tipoGrupoFluxo.descricao }}
                  </td>

                  <td style="width:15%;" class="text-center">
                    <div class="actionColumn">
                      <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Editar Fluxo"
                        matTooltipPosition="above" (click)="editarGrupoFluxoCaixa(rowData)">
                        <span class="material-icons">edit</span>
                      </button>
                      <button *ngIf="rowData.tipoGrupoFluxo.id===2" class="btn btn-xs btn-link btn-full"
                        matTooltip="Compor conteúdo do grupo" matTooltipPosition="above" [pRowToggler]="rowData">
                        <span class="material-icons">share</span>
                      </button>
                      <button *ngIf="rowData.tipoGrupoFluxo.id===3" class="btn btn-xs btn-link btn-full"
                        matTooltip="Adicionar Formula" matTooltipPosition="above"
                        (click)="openModalFormulaDetal(rowData)">
                        <span class="material-icons">functions</span>
                      </button>
                      <button *ngIf="rowData.tipoGrupoFluxo.id===1 || rowData.tipoGrupoFluxo.id===4"
                        class="btn btn-xs btn-link btn-full" matTooltip="Saldo Inical" matTooltipPosition="above">
                        <span class="material-icons">attach_money</span>
                      </button>
                      <button class="btn btn-xs btn-link btn-full" matTooltip="Excluir Fluxo" matTooltipPosition="above"
                        (click)="removerGrupoFluxoCaixa(rowData)">
                        <span class="material-icons">delete</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <!-- expansao do alias -->
              <ng-template pTemplate="rowexpansion" let-rowData>
                <tr class="expandContainer">
                  <td colspan="4">
                    <div class="p-3">
                      <div>
                        <div class="d-flex align-items-center">
                          <div class="form-group col-sm-6">
                            <label>Nome</label>
                            <input class="form-control" [(ngModel)]="aliasGrupo.nomeAlias">
                          </div>
                          <div class="form-group col-sm-2">
                            <label>Codigo</label>
                            <input class="form-control" [(ngModel)]="aliasGrupo.codigoAlias" type="number">
                          </div>
                          <button type="button" class="btn btn-sm btn-secondary" *ngIf="aliasGrupo.id!==undefined"
                            (click)="cancelarEdicaoAlias()">Cancelar</button>
                          <button type="button" class="btn btn-sm btn-info"
                            (click)="aliasGruposFluxoCaixaAdd()">{{((aliasGrupo.id===undefined)?'Adicionar':'Alterar')}}</button>
                        </div>

                        <div class="row">
                          <div class="col-sm-12">
                            <app-skeleton [config]="skeletonAlias">
                              <p-table class="table-system" [value]="grupoFluxoCaixa.aliasGrupoList"
                                selectionMode="single">
                                <ng-template pTemplate="header">
                <tr>
                  <th style="width:10%;">Código</th>
                  <th style="width:75%;">Nome</th>
                  <th style="width:15%;" class="text-center">Ação</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td style="width:10%;" (click)="editarAliasGrupo(rowData)">
                    {{ rowData.codigoAlias}}
                  </td>
                  <td style="width:75%;" (click)="editarAliasGrupo(rowData)">
                    {{ rowData.nomeAlias }}
                  </td>
                  <td style="width:15%;" class="text-center">
                    <div class="actionColumn">
                      <button type="button" class="btn btn-xs btn-link btn-full" matTooltip="Editar Grupo"
                        matTooltipPosition="above" (click)="editarAliasGrupo(rowData)">
                        <span class="material-icons">edit</span>
                      </button>
                      <button class="btn btn-xs btn-link btn-full" matTooltip="Relacionar Grupo e Operações"
                        matTooltipPosition="above" (click)="vincularGrupoOperacao(rowData, true)">
                        <span class="material-icons">share</span>
                      </button>
                      <button class="btn btn-xs btn-link btn-full" matTooltip="Excluir Grupo" matTooltipPosition="above"
                        (click)="removerAliasGrupo(rowData)">
                        <span class="material-icons">delete</span>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
            </app-skeleton>
          </div>
        </div>


      </div>
    </div>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="4" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
    </p-table>
    </app-skeleton>
    </div>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-rowData>
      <tr>
        <td [attr.colspan]="7" class="spaced-alert">
          <div class="alert alert-xs alert-primary d-inline" role="alert">
            <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
          </div>
        </td>
      </tr>
    </ng-template>
    </p-table>
    </div>
    </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">CONFIGURAÇÃO BI FLUXO DE CAIXA</h3>
            <div class="actions-card" style="display: flex;align-items: center;justify-content: center">
              <app-toolbar [value]="toolbarFluxoBI"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigFluxBI>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tiposBi"
                placeholder="Selecione o BI" [(ngModel)]="relPbi">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">CONFIGURAÇÕES DE TAXAS</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarTaxa"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateParametroTaxa>

          <div class="form-row">
            <label>CONFIGURAÇÃO MOEDA FINANCEIRO</label>
            <div class="form-group col-sm-12">
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesConfigTaxa"
                placeholder="Selecione a opção" [(ngModel)]="confTaxa">
              </p-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <mat-slide-toggle class="mr-2" [(ngModel)]="permiteMultiMoeda">
                Permite Multi Moeda
              </mat-slide-toggle>
              <mat-slide-toggle class="mr-2" [(ngModel)]="permiteAlterarMoeda">
                Permite Alterar Moeda
              </mat-slide-toggle>

            </div>
          </div>


          <div class="form-row">
            <label>CONFIGURAÇÃO MOEDA PEDIDO</label>
            <div class="form-group col-sm-12">
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesConfigTaxa"
                placeholder="Selecione a opção" [(ngModel)]="confTaxaPedido">
              </p-dropdown>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">CONTAS BANCÁRIAS PADRÃO</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarContas"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content pb-0" [@changeDivSize]=currentStateConfigContasPadrao>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <p-multiSelect class="type-p-mult" [filter]="true" [options]="opcoesTipoConta"
                placeholder="Selecione a(s) conta(s)" [(ngModel)]="contasSelecionadas"
                defaultLabel="Selecione a(s) conta(s)">
              </p-multiSelect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card">CONFIGURAÇÃO PARAMETRO DE BOLETO</h3>
            <div class="actions-card" style="display: flex;align-items: center;justify-content: center">
              <app-toolbar [value]="toolbarParametroBoleto"></app-toolbar>
            </div>
          </div>
        </div>

        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigParametroBoleto>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="tipoBoleto"
                placeholder="Selecione o tipo de boleto" [(ngModel)]="tipoBoletoId">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card"> CONSIDERAR PROVISÕES NO FLUXO DE CAIXA</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarFluxoProv"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigFluxProv>
          <div class="form-row">
            <div class="form-group col">
              <mat-slide-toggle class="mr-2" [(ngModel)]="pedidoFluxoFin">
                Pedidos
              </mat-slide-toggle>
              <mat-slide-toggle [(ngModel)]="provFlxuoFin">Provisões
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card"> PEDIDOS MISTO / RECEBIMENTO ANTECIPADO</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarFluxoMisto"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigFluxPedidoMisto>
          <div class="form-row">
            <div class="form-group col">
              <mat-slide-toggle class="mr-2" [(ngModel)]="pedidoMisto">
                Pedido Misto
              </mat-slide-toggle>
              <mat-slide-toggle [(ngModel)]="financeiroAntecipado">
                Financeiro Antecipado
              </mat-slide-toggle>
            </div>
          </div>
          <div class="form-row">

            <div class="form-group col-sm-12">
              <label>OPERAÇÃO CONTÁBIL RECEBIMENTO</label>
              <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesOperacao"
                placeholder="Selecione a Operação" [(ngModel)]="operacaoId">
              </p-dropdown>
            </div>
          </div>

            <div class="form-row">
              <div class="form-group col-sm-12">
                <mat-slide-toggle class="mr-2" [(ngModel)]="faturaAutomatico">
                  Fatura após recebimento
                </mat-slide-toggle>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-sm-1">
                <label>DIAS PARA FATURAR</label>
              <input class="form-control" type="number" [(ngModel)]="diasFaturar" maxlength="2" />
              </div>
              </div>





        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content p-3">
            <h3 class="title-card"> GRUPO DE COMISSÃO</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarComissao"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigFluxComissao>
          <div class="form-row">
            <div class="form-group col">
              <mat-slide-toggle class="mr-2" [(ngModel)]="comissaoAtivo">
                Habilitar Comissão
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pt-2">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content" style="padding: 1rem !important;">
            <h3 class="title-card"> TÍTULOS FINANCEIRO</h3>
            <div class="actions-card" style="display: flex;align-items: center;justify-content: center">
              <app-toolbar [value]="toolbarParticipante"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content pb-0" style="overflow: hidden;" [@changeDivSize]=currentStateConfigParticipante>
          <div class="form-row">
            <div class="form-group col">
              <mat-slide-toggle class="mr-2" [(ngModel)]="participanteObrigatorio">
                Participante Obrigatório na inclusão de titulos
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  </mat-card-content>

</mat-card>
<!---Modal Fluxo de caixa FEITO-->
<p-dialog header="Controle Fluxo de Caixa" [(visible)]="modalFluxoCaixa" [style]="{'width': '600px'}" [modal]="true"
  styleClass="modal-initial" [responsive]="true" appendTo="body" [closable]="true">

  <div *ngIf="modalFluxoCaixa">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Nome do fluxo</label>
        <input class="form-control" [(ngModel)]="fluxoCaixa.nomeFluxo">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Tipo de Conta Padrão</label>
        <!--(ngModelChange)="loadAdms()"-->
        <p-multiSelect class="type-p-mult" [(ngModel)]="fluxoCaixa.empresaContas" [options]="opcoesEmpresaConta"
          defaultLabel="Adicionar / remover tipos de conta" [filter]="true" placeholder="{0} itens selecionados"
          selectedItemsLabel="{0} itens selecionados"></p-multiSelect>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalFluxoCaixa = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="fluxoCaixaSalvar((fluxoCaixa.id>0))"><i
        class="fas fa-check"></i> Salvar</button>
  </p-footer>
</p-dialog>
<!---Fim Modal Fluxo de caixa-->

<!--Modal vinculação operação-->
<p-dialog header="VINCULAR GRUPO OPERAÇÃO" [style]="{'width': '80vw'}" [(visible)]="vinculaGrupoOperacao" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">

  <p-header>
    <div class="headerButtons">
      <app-toolbar [value]="toolbarGrupoOper"></app-toolbar>
    </div>
  </p-header>

  <div class="col-sm-12">
    <div id="composicao" class="mb-3">
      <div class="form-row">
        <div class="form-group col-sm-3 mb-0">
          <label>Grupo</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesGrupoFluxoCaixa" placeholder="Selecione"
            [(ngModel)]="grupoFluxoCaixa.id" (ngModelChange)="comboAliasFluxoCaixa()">
          </p-dropdown>
        </div>
        <div class="form-group col-sm-3 mb-0">
          <label>Linha</label>
          <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesAliasFluxoCaixa" placeholder="Selecione"
            [(ngModel)]="aliasGrupo.id" (ngModelChange)="vincularGrupoOperacao($event, false)"></p-dropdown>
        </div>
      </div>
    </div>

    <p-accordion [multiple]="true">
      <p-accordionTab header="Operações" [selected]="true">
        <p-table class="table-system" #dt3 [value]="listaOperacaoRegras" [rows]="rows" [paginator]="true"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single"
          (onFilter)="onFilterOrigem($event)" [(selection)]="operacaoSelecionada" scrollHeight="300px"
          (onRowSelect)="updateLinkOper()" (onRowUnSelect)="updateLinkOper()"
          (onHeaderCheckboxToggle)="updateLinkOper()">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox [disabled]="(filterOrigem.length === 0)">
                  </p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th class="text-left" style="width: 60%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoRegra'">
                  <span class="text-overflow-dynamic-ellipsis">REGRA <p-sortIcon [field]="'descricaoRegra'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'descricaoRegra', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoRegra'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO REGRA <p-sortIcon [field]="'tipoRegra'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'tipoRegra', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoES'">
                  <span class="text-overflow-dynamic-ellipsis">ENT/SAIDA <p-sortIcon [field]="'tipoES'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'tipoES', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoOperacao'">
                  <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'tipoOperacao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt3.filter($event.target.value, 'tipoOperacao', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 60%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.descricaoRegra}}">{{rowData.descricaoRegra}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.tipoRegra}}">{{rowData.tipoRegra}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoES}}">{{rowData.tipoES}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.tipoOperacao}}">{{rowData.tipoOperacao}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="6" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado, selecione um grupo!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>

      <p-accordionTab header="Composição">
        <p-table class="table-system" #dt [value]="listaOperacaoRegrasSelecionadas" [rows]="rows" [paginator]="true"
          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" selectionMode="single" immutable="false"
          (onFilter)="onFilterDestino($event)" [(selection)]="operacaoAdicionada" scrollHeight="300px"
          (onRowSelect)="updateLinkOper()" (onRowUnSelect)="updateLinkOper()"
          (onHeaderCheckboxToggle)="updateLinkOper()">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center tableHeaderCheckbox">
                <span class="check-all p-0">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </span>
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'codigo', 'contains')">
              </th>
              <th class="text-left" style="width: 60%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricaoRegra'">
                  <span class="text-overflow-dynamic-ellipsis">REGRA <p-sortIcon [field]="'descricaoRegra'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'descricaoRegra', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoRegra'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO REGRA <p-sortIcon [field]="'tipoRegra'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'tipoRegra', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoES'">
                  <span class="text-overflow-dynamic-ellipsis">ENT/SAIDA <p-sortIcon [field]="'tipoES'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'tipoES', 'contains')">
              </th>
              <th class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoOperacao'">
                  <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'tipoOperacao'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'tipoOperacao', 'contains')">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="text-center tableCheckbox">
                <p-tableCheckbox [value]="rowData">
                </p-tableCheckbox>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 60%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.descricaoRegra}}">{{rowData.descricaoRegra}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.tipoRegra}}">{{rowData.tipoRegra}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipoES}}">{{rowData.tipoES}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 10%;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.tipoOperacao}}">{{rowData.tipoOperacao}}</span>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="5" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum registro encontrado, selecione uma linha!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>
  </div>
  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="vinculaGrupoOperacao = false;"><i class="fas fa-undo"></i>
      Cancelar
    </button>
  </p-footer>
</p-dialog>
<!---Fim Modal vinculação operação-->

<!---Modal Alias linhas Fluxo de caixa FEITO-->
<p-dialog header="Composição: {{ grupoFluxoCaixa.descricao }} " [(visible)]="modalAliasGrupo"
  [style]="{'width': '700px'}" [contentStyle]="{'min-height': '450px'}" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" appendTo="body" [closable]="true">

  <div *ngIf="modalAliasGrupo">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Nome</label>
        <input class="form-control" [(ngModel)]="aliasGrupo.nomeAlias">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Codigo</label>
        <input class="form-control" [(ngModel)]="aliasGrupo.codigoAlias" type="number">
      </div>
      <div class="form-group col-sm-4">
        <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
        <button type="button" class="btn btn-sm btn-secondary" *ngIf="aliasGrupo.id!==undefined"
          (click)="cancelarEdicaoAlias()">Cancelar</button>
        <button type="button" class="btn btn-sm btn-info"
          (click)="aliasGruposFluxoCaixaAdd()">{{((aliasGrupo.id===undefined)?'Adicionar':'Alterar')}}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p-table class="table-system" [value]="grupoFluxoCaixa.aliasGrupoList" selectionMode="single">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:10%;">Código</th>
              <th style="width:75%;">Nome</th>
              <th style="width:15%;" class="text-center">Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width:10%;" (click)="editarAliasGrupo(rowData)">
                {{ rowData.codigoAlias}}
              </td>
              <td style="width:75%;" (click)="editarAliasGrupo(rowData)">
                {{ rowData.nomeAlias }}
              </td>
              <td style="width:15%;" class="text-center">
                <span style="min-width: 20px;" class="edit d-inline-block" matTooltip="Editar Grupo"
                  (click)="editarAliasGrupo(rowData)">
                  <i class="fas fa-edit"></i>
                </span>

                <span style="min-width: 20px;" class="shared d-inline-block" matTooltip="Relacionar Grupo e Operações"
                  (click)="vincularGrupoOperacao(rowData, true)">
                  <i class="fas fa-share-alt"></i>
                </span>

                <span style="min-width: 20px;" class="delete d-inline-block" matTooltip="Excluir Grupo"
                  (click)="removerAliasGrupo(rowData)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>


  </div>

  <div class="ui-g" style="position: fixed; right: 0; bottom: 2px; max-width: 555px;">
    <div class="ui-g-12">
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalAliasGrupo = false;"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="salvarAliasGrupo()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>
<!---Modal Alias linhas Fluxo de caixa-->

<!---Modal Formula Detalhes Fluxo de caixa FEITO-->
<p-dialog header="Detalhar Fórmula: {{grupoFluxoCaixa.descricao}}" [(visible)]="modalFormulaDetal"
  [style]="{'width': '700px'}" [contentStyle]="{'min-height': '450px'}" [modal]="true" styleClass="modal-scroll"
  [responsive]="true" appendTo="body" [closable]="true">

  <div *ngIf="modalFormulaDetal">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Grupo</label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [options]="opcoesGrupoFluxoCaixa"
          placeholder="Selecione" [(ngModel)]="formulaDetalhe.grupoFluxoCaixa.id"></p-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <label>Operador</label>
        <input class="form-control" [(ngModel)]="formulaDetalhe.operador" maxlength="1">
      </div>
      <div class="form-group col">
        <label class="d-block" style="visibility: hidden; margin-top: 5px;">DO NOT REMOVE</label>
        <button type="button" class="btn btn-sm btn-secondary" *ngIf="formulaDetalhe.id!==undefined"
          (click)="cancelarFormulaDetalhe()">Cancelar</button>
        <button type="button" class="btn btn-sm btn-info"
          (click)="formulaDetalheAdd()">{{((formulaDetalhe.id===undefined)?'Adicionar':'Alterar')}}</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p-table class="table-system" [value]="formulaDetalList" selectionMode="single">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:45%">Grupo</th>
              <th style="width:45%">Operador</th>
              <th style="width:10%;" class="text-center">Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width:45%" (click)="editarFormulaDetalhe(rowData)">
                {{ rowData.grupoFluxoCaixa.descricao }}
              </td>
              <td style="width:45%" (click)="editarFormulaDetalhe(rowData)">
                {{ ((rowData.operador === null || rowData.operador === undefined) ? '' : (rowData.operador.indexOf('-')
                !== -1)?'Subtração(-)':(rowData.operador.indexOf('+') !== -1)?'Adição(+)':'') }}
              </td>
              <td style="width:10%;" class="text-center">
                <span class="edit" matTooltip="Editar" (click)="editarFormulaDetalhe(rowData)">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="delete" matTooltip="Excluir" (click)="removerFormulaDetalhe(rowData)">
                  <i class="fas fa-trash-alt"></i>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="salvarFormulaDetalhe();"><i class="fas fa-undo"></i>
      Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="salvarFormulaDetalhe();"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>
