<div class="ui-g">
  <div class="ui-g-12">

  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="shadow-box">
      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card">ORIGEM DE INFORMAÇÕES</h3>
          <div class="actions-card">

            <button type="button" (click)="salvarFluxoAprov()" class="btn btn-xs btn-link btn-full"
              matTooltip="Salvar Alterações" matTooltipPosition="above">
              <span class="material-icons full-icon" style="font-size: 22px;">save</span>
            </button>

          </div>
        </div>
      </div>

      <div class="box-content" [@changeDivSize]=currentStateVinculacao>

        <div class="form-row">
          <div class="form-group col-sm-4">
            <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesOrigem"
              placeholder="Selecione a Origem" [(ngModel)]="origemSelecionada" (onChange)="buscarCamposView()">
            </p-dropdown>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-sm-8">
            <p-table class="table-system" #dt6 [value]="viewCampos" [rows]="rows" [paginator]="true"
              [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%'}">
              <ng-template pTemplate="header">
                <tr>
                  <th style="text-align: left; width: 33%; background-color: #efefef; border-color: #cccccc;">
                    <span [pSortableColumn]="'campo'">
                      <span>CAMPO <p-sortIcon [field]="'campo'"></p-sortIcon></span>
                    </span>
                    <input type="text" (input)="dt6.filter($event.target.value, 'campo', 'contains')">
                  </th>
                  <th style="text-align: left; width: 33%; background-color: #efefef; border-color: #cccccc;">
                    <span [pSortableColumn]="'descricao'">
                      <span>DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon></span>
                    </span>
                    <input type="text" (input)="dt6.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th style="text-align: left; width: 34%; background-color: #efefef; border-color: #cccccc;">
                    <span [pSortableColumn]="'tipo'">
                      <span>TIPO <p-sortIcon [field]="'tipo'"></p-sortIcon></span>
                    </span>
                    <input type="text" (input)="dt6.filter($event.target.value, 'tipo', 'contains')">
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-viewCampo>
                <tr [pSelectableRow]="viewCampo">
                  <td (click)="onSelectViewCampo(viewCampo.campo)">{{viewCampo.campo}}</td>
                  <td (click)="onSelectViewCampo(viewCampo.campo)">{{viewCampo.descricao}}</td>
                  <td (click)="onSelectViewCampo(viewCampo.campo)">{{viewCampo.tipo}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="3" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Selecione um plano gerencial!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="form-group col-sm-4">
            <mark class="d-inline-block mb-1 mt-1" style="font-size: 13px;">Não informar o
              <strong>WHERE</strong>,
              somente a condição.</mark>
            <div class="form-row">
              <div class="form-group col-sm-12">
                <textarea rows="8" cols="100" [(ngModel)]="sql" class="form-control"></textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">

                <p-checkbox [binary]="true" [(ngModel)]="addPart">ADD TODOS PARTICIPANTES</p-checkbox>
                <p-checkbox [binary]="true" [(ngModel)]="addAdm">ADD ADMINISTRADORES</p-checkbox>

              </div>
            </div>
            <div class="row">
            </div>
            <div class="row">
              <div class="col-sm-12 mt-2">

                <p-multiSelect class="type-p-mult" [disabled]="addPart" [(ngModel)]="idsUsuario"
                  [options]="opcoesParticipantes" defaultLabel="Selecionar Participantes" [filter]="true"
                  placeholder="{0} itens selecionados" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>

              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 mt-2">

                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesParticipantes"
                  placeholder="Selecione o Responsável" [(ngModel)]="responsavelId"></p-dropdown>

              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 mt-2">

                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesPaineis"
                  placeholder="Selecione o Painel" [(ngModel)]="painelId"></p-dropdown>

              </div>
            </div>



            <div class="row">
              <div class="col-sm-12 mt-2">

                <p-multiSelect class="type-p-mult" [(ngModel)]="idsMarcador" [options]="opcoesMarcadores"
                  defaultLabel="Selecionar Marcadores" [filter]="true" placeholder="{0} marcadores selecionados"
                  selectedItemsLabel="{0} marcadores selecionados">
                </p-multiSelect>

              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 mt-2">

                <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesData"
                  placeholder="Selecione o Campo Data" [(ngModel)]="campoData"></p-dropdown>

              </div>
            </div>

          </div>
        </div>

        <div class="form-row">

          <div class="row" *ngIf="editandoLinha">
            <div class="col mt-2">
              <button class="btn btn-sm btn-info" type="button" (click)="salvarLinha()"><i class="fas fa-plus"></i>
                Salvar Linha</button>
            </div>
          </div>

          <div class="row" *ngIf="!editandoLinha">
            <div class="col mt-2">
              <button class="btn btn-sm btn-info" type="button" (click)="addLinha()"><i class="fas fa-plus"></i>
                Adicionar Linha</button>
            </div>
          </div>


        </div>



        <div class="mt-2">

          <p-table class="table-system" #dt2 [value]="detalhes" [rows]="rows" [paginator]="false">
            <ng-template pTemplate="header">
              <tr>

                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                    <span class="text-overflow-dynamic-ellipsis">ORIGEM <p-sortIcon [field]="'nome'">
                      </p-sortIcon></span>
                  </span>
                </th>
                <th class="text-left" style="width: 65%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'sql'">
                    <span class="text-overflow-dynamic-ellipsis">CONDIÇÃO <p-sortIcon [field]="'sql'">
                      </p-sortIcon></span>
                  </span>
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'addPart'">
                    <span class="text-overflow-dynamic-ellipsis">ADD PART <p-sortIcon [field]="'addPart'">
                      </p-sortIcon></span>
                  </span>
                </th>
                <th class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'addAdm'">
                    <span class="text-overflow-dynamic-ellipsis">ADD ADM<p-sortIcon [field]="'addAdm'">
                      </p-sortIcon></span>
                  </span>
                </th>
                <th style="width:10%" class="text-center">AÇÃO</th>

              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr [pSelectableRow]="rowData">
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.origem }}">{{ rowData.origem
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 65%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.sql }}">{{ rowData.sql }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.addPart ? 'SIM' : 'NÃO' }}">{{
                      rowData.addPart ? 'SIM' : 'NÃO' }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 10%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.addAdm ? 'SIM' : 'NÃO' }}">{{
                      rowData.addAdm ? 'SIM' : 'NÃO' }}</span>
                  </span>
                </td>

                <td class="text-center" style="width: 10%;">
                  <span class="edit" matTooltip="Editar Fluxo" (click)="editarFluxo(rowData)">
                    <i class="far fa-edit"></i>
                  </span>
                  <span class="delete" matTooltip="Excluir Fluxo" (click)="removerFluxo(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </span>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="7" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>

          </p-table>
        </div>







      </div>
    </div>
  </div>

</div>