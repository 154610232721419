<mat-card class="card-linnks margin-linnks">
  <mat-card-title style="margin-left: 2px;">
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>


  <div class="row">
    <div class="col-sm-12">
      <div class="shadow-box">
        <div class="header-box">
          <div class="header-box-content">
            <h3 class="title-card">CONFIGURAÇÕES IMPORTAÇÃO</h3>
            <div class="actions-card">
              <app-toolbar [value]="toolbarMain"></app-toolbar>
            </div>
          </div>
        </div>
        <div class="box-content">

          <p-table class="table-system" #dt [columns]="colslistaImportacao" [value]="listaImportacaConfiguracao"
            [rows]="rows" [paginator]="true" [paginator]="true" [pageLinks]="pageLinks"
            [rowsPerPageOptions]="rowsPerPage" selectionMode="single" [style]="{'width':'100%'}" [scrollable]="false"
            scrollHeight="20vh" (onRowSelect)="handleSelect()" (onRowUnselect)="handleSelect()" [(selection)]="selectedConfigurations">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center tableHeaderCheckbox">
                  <span class="check-all p-0">
                    <p-tableHeaderCheckbox (click)="selectTodos($event)"></p-tableHeaderCheckbox>
                  </span>
                </th>
                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                    <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'descricao', 'contains')">
                </th>
                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipoOrcamento.descricao'">
                    <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipoOrcamento.descricao'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'tipoOrcamento.descricao', 'contains')">
                </th>
                <th class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'planoGerencial.nome'">
                    <span class="text-overflow-dynamic-ellipsis">PL. GERENCIAL <p-sortIcon
                        [field]="'planoGerencial.nome'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'planoGerencial.nome', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'selecionarProjeto'">
                    <span class="text-overflow-dynamic-ellipsis">PROJETO <p-sortIcon [field]="'selecionarProjeto'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'selecionarProjeto', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'selecionarCentroCusto'">
                    <span class="text-overflow-dynamic-ellipsis">C. CUSTO <p-sortIcon [field]="'selecionarCentroCusto'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'selecionarCentroCusto', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'selecionarContabil'">
                    <span class="text-overflow-dynamic-ellipsis">CONTABIL <p-sortIcon [field]="'selecionarContabil'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'selecionarContabil', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'selecionarGerencial'">
                    <span class="text-overflow-dynamic-ellipsis">GERENCIAL <p-sortIcon [field]="'selecionarGerencial'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'selecionarGerencial', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'selecionarArea'">
                    <span class="text-overflow-dynamic-ellipsis">ÁREA <p-sortIcon [field]="'selecionarArea'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'selecionarArea', 'contains')">
                </th>
                <th class="text-center" style="width: 9%;">
                  <span class="text-overflow-dynamic-container" [pSortableColumn]="'selecionarSubarea'">
                    <span class="text-overflow-dynamic-ellipsis">SUBÁREA <p-sortIcon [field]="'selecionarSubarea'">
                      </p-sortIcon></span>
                  </span>
                  <input class="form-control form-control-sm" type="text"
                    (input)="dt.filter($event.target.value, 'selecionarSubarea', 'contains')">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td class="text-center tableCheckbox">
                  <p-tableCheckbox [value]="rowData">
                  </p-tableCheckbox>
                </td>
                <td class="text-left" style="width: 15%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
                      }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 8%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.tipoOrcamento?.descricao }}">{{
                      rowData.tipoOrcamento?.codigo }} - {{
                      rowData.tipoOrcamento?.descricao }}</span>
                  </span>
                </td>
                <td class="text-left" style="width: 12%;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{ rowData.planoGerencial?.nome }}">{{
                      rowData.planoGerencial?.codigo }} - {{ rowData.planoGerencial?.nome }}</span>
                  </span>
                </td>
                <td class="text-center" style="width: 7%; padding-bottom: 5px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.projeto}}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.projeto" [binary]="true"
                      inputId="projeto" [disabled]="true"></p-checkbox>
                    </span>
                  </span>
                </td>
                <td class="text-center" style="width: 7%; padding-bottom: 5px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.selecionarCentroCusto}}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.selecionarCentroCusto" [binary]="true"
                      inputId="selecionarCentroCusto" [disabled]="true"></p-checkbox>
                    </span>
                  </span>
                </td>
                <td class="text-center" style="width: 7%; padding-bottom: 5px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.projeto}}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.projeto" [binary]="true"
                      inputId="projeto" [disabled]="true"></p-checkbox>
                    </span>
                  </span>
                </td>
                <td class="text-center" style="width: 7%; padding-bottom: 5px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.selecionarGerencial}}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.selecionarGerencial" [binary]="true"
                      inputId="selecionarGerencial" [disabled]="true"></p-checkbox>
                    </span>
                  </span>
                </td>
                <td class="text-center" style="width: 7%; padding-bottom: 5px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.selecionarArea}}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.selecionarArea" [binary]="true"
                      inputId="selecionarArea" [disabled]="true"></p-checkbox>
                    </span>
                  </span>
                </td>
                <td class="text-center" style="width: 7%; padding-bottom: 5px;">
                  <span class="text-overflow-dynamic-container">
                    <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.selecionarSubarea}}">
                      <p-checkbox class="mr-1" [(ngModel)]="rowData.selecionarSubarea" [binary]="true"
                      inputId="selecionarSubarea" [disabled]="true"></p-checkbox>
                    </span>
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-rowData>
              <tr>
                <td [attr.colspan]="10" class="spaced-alert">
                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                    <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<p-dialog header="Configurações Importação"
  [(visible)]="modalState" styleClass="modal-scroll" width="900"
  [modal]="true" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body" [closable]="true"
  (onHide)="closeDialog()" [baseZIndex]="10">
  <div class="form-row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-list"> </i> Configurações Iniciais <span class="obrigatorio">*</span></h3>
    </div>
    <div class="col-sm-6">
      <label for="inputDescricao">TIPO<span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
        [(ngModel)]="configuracaoImportacao.tipoOrcamento.id" [options]="opcoesTipoOrcamento"></p-dropdown>
    </div>
    <div class="col-sm-6">
      <label for="inputDescricao">DESCRIÇÃO<span class="obrigatorio">*</span></label>
      <input class="form-control" value="{{configuracaoImportacao.descricao}}"
        [(ngModel)]="configuracaoImportacao.descricao" name="inputDescricao" maxlength="100">
    </div>
  </div>
  <div class="form-row mt-3">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-cog"> </i> Configurações Gerais</h3>
    </div>
    <div class="col-sm-3 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.selecionarProjeto"> Projeto </mat-slide-toggle>
    </div>
    <div class="col-sm-4 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.selecionarCentroCusto"> Centro de custo
      </mat-slide-toggle>
    </div>
    <div class="col-sm-3 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.selecionarArea"> Área </mat-slide-toggle>
    </div>
    <div class="col-sm-2 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.selecionarSubarea"> Subárea </mat-slide-toggle>
    </div>
  </div>

  <div class="form-row mt-3">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-info"> </i> Informações Reais</h3>
    </div>

    <div class="col-sm-5 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.dadosReaisErp"> Buscar Dados Reais do ERP
      </mat-slide-toggle>
    </div>
    <div class="col-sm-5 mt-1">
      <p-dropdown class="type-p-dropdown" *ngIf="configuracaoImportacao.versaoReal && !configuracaoImportacao.dadosReaisErp" [filter]="true"
        placeholder="Selecione a versão do real" [(ngModel)]="configuracaoImportacao.versaoReal.id" [options]="opcoesVersoes"></p-dropdown>
    </div>
  </div>

  <div class="form-row mt-3">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-file-invoice"> </i> Plano de contas <span class="obrigatorio">*</span></h3>
    </div>

    <div class="col-sm-5 mt-1">
      <mat-slide-toggle class="mr-2" id="boolContabil" [(ngModel)]="configuracaoImportacao.selecionarContabil"
        (change)="mudarSelecaoPlano(0)"> Contabil </mat-slide-toggle>
    </div>

    <div class="col-sm-4 mt-1">
      <mat-slide-toggle class="mr-2" id="boolGerencial" [(ngModel)]="configuracaoImportacao.selecionarGerencial"
        (change)=" mudarSelecaoPlano(1)"> Gerencial </mat-slide-toggle>
    </div>

    <div class="col-sm-3 mt-1">
      <p-dropdown class="type-p-dropdown" *ngIf="configuracaoImportacao.selecionarGerencial" [filter]="true"
        placeholder="Selecione" [(ngModel)]="configuracaoImportacao.planoGerencial.id" [options]="opcoesPlanoGerencial">
      </p-dropdown>
    </div>
  </div>

  <div class="form-row mt-3">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-coins"> </i> Tipo de taxa </h3>
    </div>

    <div class="col-sm-5 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.taxaCorporacao"
        (change)="configuracaoImportacao.ptax=false;"> Taxa de corporação </mat-slide-toggle>
    </div>

    <div class="col-sm-5 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.ptax"
        (change)="configuracaoImportacao.taxaCorporacao=false;"> PTAX </mat-slide-toggle>
    </div>
  </div>

  <div class="form-row mt-3">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-chart-bar"> </i> Grupo de empresa </h3>
    </div>

    <div class="col-sm-5 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.podeConsolidar"> Aceita consolidação
      </mat-slide-toggle>
    </div>

    <div class="col-sm-5 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.podeMovimentar"> Aceita movimentação
      </mat-slide-toggle>
    </div>
  </div>

  <div class="form-row mt-3">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-chart-bar"> </i> Relatório BI</h3>
    </div>

    <div class="col-sm-6 mt-1">
      <p-dropdown class="type-p-dropdown" [filter]="true" placeholder="Selecione"
        [(ngModel)]="configuracaoImportacao.relatorioPbi" [options]="relatorios"></p-dropdown>
    </div>
  </div>

  <div class="form-row">
    <div class="col-sm-12 mt-3" style="margin-bottom: -16px;">
      <h3 class="sub-title mb-4"><i class="fas fa-level-up-alt"> </i> Niveis de Visualização <span
          class="obrigatorio">*</span></h3>

      <p-pickList  sourceHeader="Níveis Disponíveis" targetHeader="Níveis Vinculados"
        [source]="opcoesTipoVisao" [target]="listTipoVisao" [dragdrop]="true" [responsive]="true" filterBy="id"
        [showSourceControls]="false" [showTargetControls]="false">
        <ng-template let-tipo pTemplate="item">
          {{ tipo.label }}
        </ng-template>
      </p-pickList>
    </div>
  </div>

  <div class="form-row">
    <div class="col-sm-12">
      <h3 class="sub-title"><i class="fas fa-eye"></i> Opções de visualização </h3>
    </div>
    <div class="col-sm-3 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.visaoDetalhada"> Detalhado </mat-slide-toggle>
    </div>
    <div class="col-sm-3 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.visaoMensal"> Mensal </mat-slide-toggle>
    </div>
    <div class="col-sm-3 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.visaoYtd"> YearToDate </mat-slide-toggle>
    </div>
    <div class="col-sm-3 mt-1">
      <mat-slide-toggle class="mr-2" [(ngModel)]="configuracaoImportacao.esconderLinhas"> Esconder linhas nulas
      </mat-slide-toggle>
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="cancelar()"><i class="fas fa-undo"></i> Cancelar</button>
    <button *ngIf="boolCriarconfiguracaoImportacao && !boolEditconfiguracaoImportacao" type="button"
      class="btn btn-primary" (click)="criarNovoRegistro()"><i class="fas fa-check"></i> Salvar</button>
    <button *ngIf="!boolCriarconfiguracaoImportacao && boolEditconfiguracaoImportacao" type="button"
      class="btn btn-primary" (click)="editarRegistro()"><i class="fas fa-check"></i> Salvar</button>
  </p-footer>

</p-dialog>