<div>
  <div class="col-sm-12" style="padding: 0 !important" *ngIf="!loadingConfigPattern">
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Chave da API <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.api_key" (keyup)="validationConfig()"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-6">
        <label>Id da empresa na Conciliadora <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.empresa_conciliadora_id" (keyup)="validationConfig()"/>
      </div>
      <div class="form-group col-sm-6">
        <label>Senha da empresa na Conciliadora <span class="obrigatorio">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="config.senha" (keyup)="validationConfig()"/>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-12">
        <label>Conta padrão de Cartão de Crédito<span class="obrigatorio">*</span></label>
        <p-dropdown appendTo="body" class="type-p-dropdown" [filter]="true" [virtualScroll]="true" [itemSize]="30" placeholder="Selecione" [options]="optCompanyCredAccounts" emptyMessage="Nenhum resultado encontrado!" [disabled]="loadingOptCompanyCredAccounts" [dropdownIcon]="loadingIconOptCompanyCredAccounts ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'" [(ngModel)]="config.conta_empresa_cred_id" (onChange)="validationConfig()"></p-dropdown>
      </div>
    </div>
  </div>
  <div class="col-sm-12 loading-content" style="padding: 0 !important" *ngIf="loadingConfigPattern">
    <p><span class="material-icons rotate">hourglass_empty</span> <b>Carregando informações da conta ...</b></p>
  </div>
</div>
