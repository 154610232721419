<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{titlePrincipal}}
  </mat-card-title>

  <div class="row">
    <div class="col">
      <h3 class="sub-title mt-3"><i class="fas fa-chart-bar"></i> RELATÓRIOS FINANCEIROS GERENCIAIS PARA EXPORTAR EM
        EXCEL</h3>
    </div>
  </div>
  <div class="row">
    <div class="col mt-1">
      <div class="card-deck card-custom">
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('CP')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('CP')" matTooltip="Posição contas à pagar"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Posição contas à
                pagar</span></h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('CR')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('CR')" matTooltip="Posição contas à receber"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Posição contas à
                receber</span></h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('ADC')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('ADC')" matTooltip="Adiantamentos à clientes"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Adiantamentos à
                clientes</span></h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('ADF')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('ADF')" matTooltip="Adiantamentos à fornecedores"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Adiantamentos à
                fornecedores</span></h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('RR')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('RR')" matTooltip="Relatório de recebimentos"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório de recebimentos</span>
            </h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('RP')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('RP')" matTooltip="Relatório de pagamentos"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório de pagamentos</span></h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('BO')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('BO')" matTooltip="Relatório borderô"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Relatório borderô</span></h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('MOV')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('MOV')" matTooltip="Movimentações bancárias"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Movimentações bancárias</span>
            </h5>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('MOV_RAT')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('MOV_RAT')" matTooltip="Movimentações por rateio"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Movimentações por rateio</span>
            </h5>
          </div>
        </div>
        <div class="card" *ngFor="let relat of listaRelat">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio(relat)"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio(relat)" matTooltip="{{relat.nome}}"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">{{relat.nome}}</span></h5>
          </div>
        </div>
        <div class="card mt-2" *ngIf="empresaId === 160">
          <div class="card-body">
            <span class="icon-card" (click)="showDialogRelatorio('ASAAS')"><i class="fas fa-chart-bar"></i></span>
            <h5 class="card-title" (click)="showDialogRelatorio('ASAAS')" matTooltip="Cobranças ASAAS"
              matTooltipPosition="above" style="width: 150px;"><span class="d-block">Cobranças ASAAS</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<p-dialog header="POSIÇÃO CONTAS A PAGAR" [modal]="true" appendTo="body" [style]="{width: '400px'}"
  styleClass="lks-modal" [(visible)]="statusCp" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data de Corte</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtCorte"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="consolidaFilial"> Consolidar filiais</mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar Relatório em Excel!" matTooltipPosition="right"
      matTooltipPosition="right" [disabled]="!dtCorte" (click)="baixarTitRel('CP')"><i class="fas fa-file-excel"></i>
      Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="POSIÇÃO CONTAS A RECEBER" [modal]="true" appendTo="body" [style]="{width: '400px'}"
  styleClass="lks-modal" [(visible)]="statusCr" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data de Corte</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtCorte"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="consolidaFilial"> Consolidar filiais</mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar Relatório em Excel!" matTooltipPosition="right"
      matTooltipPosition="right" [disabled]="!dtCorte" (click)="baixarTitRel('CR')"><i class="fas fa-file-excel"></i>
      Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="ADIANTAMENTOS À FORNECEDORES" [modal]="true" appendTo="body" [style]="{width: '400px'}"
  styleClass="lks-modal" [(visible)]="statusAdf" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data de Corte</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtCorte"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar Relatório em Excel!" matTooltipPosition="right"
      matTooltipPosition="right" [disabled]="!dtCorte" (click)="baixarAdiantamentos('CP')"><i
        class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="ADIANTAMENTOS À CLIENTES" [modal]="true" appendTo="body" [style]="{width: '400px'}"
  styleClass="lks-modal" [(visible)]="statusAdc" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data de Corte</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtCorte"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar Relatório em Excel!" matTooltipPosition="right"
      matTooltipPosition="right" [disabled]="!dtCorte" (click)="baixarAdiantamentos('CR')"><i
        class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="RELATÓRIO DE RECEBIMENTOS" [modal]="true" appendTo="body" [style]="{width: '500px'}"
  styleClass="modal-initial" [(visible)]="statusRR" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Participantes</label>
      <div *ngIf="liparticipantes.length">
        <p>{{ liparticipantes.length }} cliente(s) selecionado(s)
          <span matTooltip="Alterar cliente" (click)="loadParticipantes(1)">
            <span class="material-icons" style="font-size: 15px; cursor: pointer;">edit</span>
          </span>
        </p>
      </div>
      <div *ngIf="!liparticipantes.length">
        <p>Todos os clientes
          <span matTooltip="Alterar cliente" (click)="loadParticipantes(1)">
            <span class="material-icons" style="font-size: 15px; cursor: pointer;">edit</span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="mr-2" [(ngModel)]="titulosAberto"> Exibir títulos em aberto </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="consolidaFilial"> Consolidar filiais</mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar Relatório em Excel!" matTooltipPosition="right"
      matTooltipPosition="right" [disabled]="!dtInit || !dtFin || !liparticipantes" (click)="baixarRelatorio(1)"><i
        class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="RELATÓRIO DE PAGAMENTOS" [modal]="true" appendTo="body" [style]="{width: '500px'}"
  styleClass="modal-initial" [(visible)]="statusRP" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Participantes</label>
      <div *ngIf="liparticipantes.length">
        <p>{{ liparticipantes.length }} fornecedore(s) selecionado(s)
          <span matTooltip="Alterar fornecedor" (click)="loadParticipantes(2)">
            <span class="material-icons" style="font-size: 15px; cursor: pointer;">edit</span>
          </span>
        </p>
      </div>
      <div *ngIf="!liparticipantes.length">
        <p>Todos os fornecedores
          <span matTooltip="Alterar fornecedor" (click)="loadParticipantes(2)">
            <span class="material-icons" style="font-size: 15px; cursor: pointer;">edit</span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <mat-slide-toggle class="mr-2" [(ngModel)]="titulosAberto"> Exibir títulos em aberto </mat-slide-toggle>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="consolidaFilial"> Consolidar filiais</mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar Relatório em Excel!" matTooltipPosition="right"
      matTooltipPosition="right" [disabled]="!dtInit || !dtFin || !liparticipantes" (click)="baixarRelatorio(0)"><i
        class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="Relatório Borderô" [modal]="true" appendTo="body" [style]="{width: '400px'}"
  styleClass="modal-initial" [responsive]="true" [(visible)]="statusBo" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-5">
      <label>Data da Operação</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtCorte"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12" style="margin-bottom: 0px;">
      <mat-slide-toggle class="slideThickLabel" style="margin-top: 3px;" [(ngModel)]="titulosAberto">Somente títulos
        Pendentes</mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button [disabled]="!dtCorte" class="btn btn-primary" style="padding: 0.375rem 0.9rem;" type="button"
      (click)="baixarRelatorioBo()"><i class="far fa-file-pdf pdf"></i>
      Exportar</button>
  </p-footer>
</p-dialog>

<p-dialog header="MOVIMENTAÇÕES BANCÁRIAS" [modal]="true" appendTo="body" [style]="{width: '500px'}"
  styleClass="modal-initial" [(visible)]="statusMov" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione a conta</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesContas" placeholder="Selecione a Conta"
        [(ngModel)]="contaSelecionada"></p-dropdown>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar elatório em excel"
      [disabled]="!dtInit || !dtFin || !contaSelecionada" (click)="baixarRelatorioMov()"><i
        class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="{{relatorioGerSelecionado.nome}}" [modal]="true" appendTo="body" width="500"
  styleClass="modal-initial" [(visible)]="modalRelatorio" [responsive]="true" [closable]="true">
  <div class="form-row" *ngIf="!relatorioGerSelecionado.is_composta">
    <div class="form-group col-sm-4">
      <label>DATA REFERÊNCIA</label>
      <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
        [defaultDate]="dtCorte" yearRange="2000:2030" [(ngModel)]="dtCorte">
      </p-calendar>
    </div>
  </div>

  <div class="row" *ngIf="relatorioGerSelecionado.is_composta">
    <div class="form-group col-sm-12">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>DATA REFERÊNCIA</label>
          <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
            [defaultDate]="dtCorte" yearRange="2000:2030" [(ngModel)]="dtCorte" [disabled]="datasIndividuais">
          </p-calendar>
        </div>
        <div class="form-group col-sm-6">
          <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
          <p-checkbox [(ngModel)]="datasIndividuais"></p-checkbox>
          <label class="ml-1">Utilizar datas individuais</label>
        </div>
      </div>
    </div>
    <div class="form-group col-sm-12">
      <div class="form-row">
        <p-table class="table-system" [value]="dicRelatComposto[relatorioGerSelecionado.id]"
          emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
              <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba</th>
              <th style="text-align: left; background-color: #efefef; border-color: #cccccc" *ngIf="datasIndividuais">
                Data de referência</th>
              <th style="text-align: left; background-color: #efefef; border-color: #cccccc; width: 10%;"
                *ngIf="datasIndividuais"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-config>
            <tr>
              <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
              <td style="text-align: center">{{config.worksheet_title}}</td>
              <td style="text-align: center" *ngIf="datasIndividuais">
                <p-calendar class="type-p-calendar" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                  [defaultDate]="dtCorte" yearRange="2000:2030" [(ngModel)]="config['dataRef']">
                </p-calendar>
              </td>
              <td style="text-align: center" *ngIf="datasIndividuais">
                <button type="button" class="btn btn-sm btn-info"><i class="fas fa-refresh"
                    (click)="carregarValores(config)"></i></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <p-footer>
    <button class="btn btn-primary" type="button" (click)="renderizarRelatorioGerencial()"
      *ngIf="!relatorioGerSelecionado.is_composta"><i class="fas fa-laptop"></i> Tela</button>
    <button class="btn btn-primary" type="button" (click)="gerarRelatorioGerencialExcel()"><i
        class="far fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="COBRANÇAS ASAAS" [modal]="true" appendTo="body" [style]="{width: '500px'}" styleClass="modal-initial"
  [(visible)]="statusAsaas" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar elatório em excel" [disabled]="!dtInit || !dtFin"
      (click)="baixarRelatorioAsaas()"><i class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<p-dialog header="MOVIMENTAÇÕES POR RATEIO" [modal]="true" appendTo="body" [style]="{width: '500px'}"
  styleClass="modal-initial" [(visible)]="statusMovRat" [responsive]="true" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Data Inicial <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtInit"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
    <div class="form-group col-sm-4">
      <label>Data Final <span class="obrigatorio">*</span></label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [(ngModel)]="dtFin"
        [monthNavigator]="true" [yearNavigator]="true" yearRange="2010:2030" input-text="right">
      </p-calendar>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione as empresas <span class="obrigatorio">*</span></label>
      <p-multiSelect class="type-p-mult type-p-mult-clear" [options]="opcoesEmpresas" defaultLabel="Selecione empresas"
        [filter]="true" (onChange)="getContasBancariasMov();getFluxoCaixa()" [(ngModel)]="empresasSelecionadas"
        [maxSelectedLabels]="1" placeholder="{0} empresas selecionadas" selectedItemsLabel="{0} empresas selecionadas">
      </p-multiSelect>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione as contas <span class="obrigatorio">*</span></label>
      <p-multiSelect class="type-p-mult type-p-mult-clear" [options]="opcoesContas" defaultLabel="Selecione contas"
        [filter]="true" [(ngModel)]="contasSelecionadas" [maxSelectedLabels]="1" placeholder="{0} contas selecionadas"
        selectedItemsLabel="{0} contas selecionadas">
      </p-multiSelect>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-12">
      <label>Selecione o modelo de fluxo de caixa</label>
      <p-dropdown class="type-p-dropdown" [filter]="true" [options]="opcoesFluxoCaixa"
        placeholder="Selecione o fluxo de caixa" [(ngModel)]="fluxoSelecionado"></p-dropdown>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="baixados"> Somente baixados </mat-slide-toggle>
    </div>
    <div class="form-group col-sm-6">
      <mat-slide-toggle class="mr-2" [(ngModel)]="visuProjetos"> Detalhar por projeto </mat-slide-toggle>
    </div>
  </div>
  <p-footer>
    <button type="button" class="btn btn-primary" matTooltip="Exportar elatório em excel"
      (click)="baixarRelatorioMovRateio()"><i class="fas fa-file-excel"></i> Excel</button>
  </p-footer>
</p-dialog>

<app-modal-participante [(isVisible)]="modalParticipante" [isMultiple]="true"
  (onSelectParticipant)="salvarParticipante($event)">
  <p-checkbox [(ngModel)]="semParticipante" [binary]="true" inputId="participante"
    label="Incluir títulos sem participante">
  </p-checkbox>
</app-modal-participante>