<mat-select [class.floating]="floating" [placeholder]="placeholder" [required]="required" [(ngModel)]="value" (opened)="onOpen()">
  <mat-option *ngIf="prompt" disabled>
    {{ prompt }}
  </mat-option>

  <div class="filter" *ngIf="filter">
    <mat-form-field>
      <input #input matInput [(ngModel)]="filterValue" autocomplete="off" />
      <i matPrefix class="material-icons">search</i>
    </mat-form-field>
  </div>

  <mat-option *ngIf="displayAll" [value]="null" (onSelect)="onSelect()">
    Todos
  </mat-option>

  <div *ngFor="let p of search()">
    <mat-option [value]="p" (onSelect)="onSelect(p)">
      {{ label(p) }}
    </mat-option>
  </div>
</mat-select>
