<app-data-table class="empresa-conta"
                mainTitle="Empresa Conta"
                singleTitle="Empresa Conta"
                routerPath="/empresa-conta"
                entityPath="/empresaconta"
                [entityFilter]="entityFilter"
                keyField="id"
                [entity]="empresaConta"
                [columns]="this['columns']"
                [controls]="this['controls']">
</app-data-table>