<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>
  <div [ngStyle]="{'display': 'block'}" [ngClass]="{'fade-in': true}">

    <div class="row">
      <div class="col pb-3">
        <div class="shadow-box">
          <div class="header-box">
            <div class="header-box-content">
              <ul class="list-inline filter-menu mb-2 mt-0 d-inline-block" style="margin-right: 3px; margin-left: 3px;">
                <li class="list-inline-item" [ngClass]="{active: true}"><a>SCRIPTS PARA INSERÇÃO DE DADOS DBL</a></li>
              </ul>
              <div class="actions-card">
                <span class="delete" matTooltip="Excluir" style="color: #a52632; font-size: 0.900rem;"
                  (click)="removerSelecionados()">
                  <i class="fas fa-trash-alt"></i></span>
                <button type="button" class="btn btn-xs btn-link" (click)="showHideCardScriptsDbl()"
                  (click)="changeStateScriptsDbl()">
                  <span *ngIf="isCardScriptsDbl" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardScriptsDbl" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button>
              </div>
            </div>
          </div>

          <div class="box-content pt-0" style="overflow: initial;" [@changeDivSize]=currentStateScriptsDbl>
            <div class="row" style="padding: 14px;">
              <p-table class="table-system" #dt1 [value]="scripts_importacao_dbl" selectionMode="single"
                (onFilter)="onFilter($event)" [paginator]="true" [pageLinks]="pageLinks" [rows]="rows"
                [rowsPerPageOptions]="rowsPerPage" (onHeaderCheckboxToggle)="checkAll($event)"
                (onRowSelect)="selectCheckbox($event)" (onRowUnselect)="unselectCheckbox($event)">

                <ng-template pTemplate="header">
                  <tr>
                    <th class="text-center" style="width: 10%;">
                      <span class="text-overflow-dynamic-container">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </span>
                    </th>

                    <th class="text-center">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo_arquivo'">
                        <span class="text-overflow-dynamic-ellipsis">Tipo Arquivo <p-sortIcon [field]="'tipo_arquivo'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'tipo_arquivo', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'recorrente'">
                        <span class="text-overflow-dynamic-ellipsis">Recorrente <p-sortIcon [field]="'recorrente'">
                          </p-sortIcon></span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'recorrente', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodicidade'">
                        <span class="text-overflow-dynamic-ellipsis">Periodicidade <p-sortIcon [field]="'periodiciade'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'periodicidade', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'periodo'">
                        <span class="text-overflow-dynamic-ellipsis">Período <p-sortIcon [field]="'periodo'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'periodo', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                        <span class="text-overflow-dynamic-ellipsis">Status <p-sortIcon [field]="'status'">
                          </p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'status', 'contains')">
                    </th>

                    <th class="text-left">
                      <span class="text-overflow-dynamic-container" [pSortableColumn]="'data_modificacao'">
                        <span class="text-overflow-dynamic-ellipsis">Data Última Modificação <p-sortIcon
                            [field]="'lote'"></p-sortIcon>
                        </span>
                      </span>
                      <input class="form-control form-control-sm" type="text"
                        (input)="dt1.filter($event.target.value, 'data_modificacao', 'contains')">
                    </th>

                    <th class="text-center">
                      <span class="text-overflow-dynamic-container">Ação</span>
                    </th>

                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                  <tr>
                    <td class="text-center tableCheckbox">
                      <span class="text-overflow-dynamic-container">
                        <p-tableCheckbox [value]="rowData" [(ngModel)]="rowData.selected"></p-tableCheckbox>
                      </span>
                    </td>

                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.tipo_arquivo}}">{{rowData.tipo_arquivo}}</span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.recorrente}}">{{rowData.recorrente}}</span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.periodicidade}}">{{rowData.periodicidade}}</span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.periodo}}">{{rowData.periodo}}</span>
                      </span>
                    </td>

                    <td class="text-center">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.status}}">{{rowData.status}}</span>
                      </span>
                    </td>

                    <td class="text-left">
                      <span class="text-overflow-dynamic-container">
                        <span class="text-overflow-dynamic-ellipsis"
                          matTooltip="{{rowData.data_modificacao}}">{{rowData.data_modificacao}}</span>
                      </span>
                    </td>

                    <td style="width: 10%;" class="text-center">
                      <button type="button" style="padding: 0 !important;" (click)="editarScriptDbl(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Editar" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 22px">edit</span>
                      </button>
                      <button type="button" style="padding: 0 !important;" (click)="removerScriptDbl(rowData)"
                        class="btn btn-xs btn-link btn-full" matTooltip="Excluir" matTooltipPosition="above">
                        <span class="material-icons" style="font-size: 22px">delete</span>
                      </button>
                    </td>
                  </tr>
                </ng-template>

                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                    <td [attr.colspan]="8" class="spaced-alert">
                      <div class="alert alert-xs alert-primary d-inline" role="alert">
                        <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                      </div>
                    </td>
                  </tr>
                </ng-template>

              </p-table>
            </div>

            <div class="row">
              <div class="col mt-2">
                <button class="btn btn-sm btn-info" type="button" (click)="adicionarScriptDbl()"><i
                    class="fas fa-plus"></i>
                  Adicionar Script</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<!--Modal Novo Script DBL-->
<p-dialog header="Adicionar Script DBL" [(visible)]="modalCriacaoScriptDbl" width="1000" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label>Tipo de Arquivo <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesTipoArquivo" [filter]="true"
        [(ngModel)]="opcoesTipoArquivoEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Recorrente <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesRecorrente" [filter]="true"
        [(ngModel)]="opcoesRecorrenteEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Periodicidade <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesPeriodicidade" [filter]="true"
        [(ngModel)]="opcoesPeriodicidadeEscolhido"></p-dropdown>
    </div>
    <div class="form-group col-sm-6">
      <label>Período Obtenção de Dados</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2000:2030"
        [(ngModel)]="periodoSelecionado">
      </p-calendar>
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalCriacaoScriptDbl = false;"><i class="fas fa-undo"></i>
      Fechar</button>
    <button type="button" class="btn btn-primary" (click)="salvarScriptDbl()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>


<!--Modal Editar Script DBL-->
<p-dialog header="Editar Script DBL" [(visible)]="modalEdicaoScriptDbl" width="1000" [modal]="true"
  styleClass="modal-scroll" [responsive]="true" appendTo="body" [closable]="true">
  <div class="form-row">
    <div class="form-group col-sm-3">
      <label>Tipo de Arquivo <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesTipoArquivo" [filter]="true"
        [(ngModel)]="currentTipoArquivo"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Recorrente <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesRecorrente" [filter]="true"
        [(ngModel)]="currentRecorrente">
      </p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Periodicidade <span class="obrigatorio">*</span></label>
      <p-dropdown class="type-p-dropdown" appendTo="body" [options]="opcoesPeriodicidade" [filter]="true"
        [(ngModel)]="currentPeriodicidade"></p-dropdown>
    </div>
    <div class="form-group col-sm-3">
      <label>Período Obtenção de Dados</label>
      <p-calendar class="type-p-calendar" appendTo="body" dateFormat="dd/mm/yy" [monthNavigator]="true"
        [yearNavigator]="true" [defaultDate]="defaultDate" yearRange="2000:2030"
        [(ngModel)]="periodoObtencaoDados">
      </p-calendar>
    </div>
  </div>

  <div class="ui-g" style="position:fixed; right: 25px; bottom: 30px;">
    <div class="ui-g-12">

    </div>
  </div>

  <p-footer>
    <button type="button" class="btn btn-secondary" (click)="modalEdicaoScriptDbl = false;"><i class="fas fa-undo"></i>
      Fechar</button>
    <button type="button" class="btn btn-primary" (click)="salvarScriptDblEditado()"><i class="fas fa-check"></i>
      Salvar</button>
  </p-footer>
</p-dialog>