<mat-card class="card-linnks margin-linnks" style="margin-top: 15px !important; overflow-x: hidden;">
  <mat-card-title>
    {{title}}
    <mat-card-subtitle>
      <b>de: </b>{{subDtIni}}<b> até: </b>{{subDtFin}}<b> fornecedor: </b>{{subFornecedor}}<b> pedido:
      </b>{{subPedido}}<b>
        tipo: </b>{{subTipo}}<b>
        status: </b> {{subStatus}}
    </mat-card-subtitle>
  </mat-card-title>

  <mat-card-content>
    <div class="box-content pt-2">
      <div class="lks-table {{detalhe ?'lks-table-true':'lks-table-false'}}">
        <p-header class="clearfix">
          <div class="pull-right align-down-table" style="position: fixed; top: 60px; right: 26px;">
            <button type="button" class="btn btn-link" (click)="dt.exportCSV()"><i class="fas fa-file-excel excel"></i>
              Exportar para Excel</button>
          </div>
        </p-header>
        <p-table class="table-system" #dt [columns]="colsLista" [value]="lista" [rows]="rows" [paginator]="false"
          [pageLinks]="pageLinks" emptyMessage="Nenhum Registro Encontrado" [rowsPerPageOptions]="rowsPerPage"
          selectionMode="false" [scrollable]="true" scrollHeight="60vh" [style]="{'width':'100%'}">
          <ng-template pTemplate="header">
            <tr>

              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'dtEmissao'">
                  <span class="text-overflow-dynamic-ellipsis">DT EMISSÃO <p-sortIcon [field]="'dtEmissao'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'dtEmissao', 'contains')">
              </th>
              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codPedido'">
                  <span class="text-overflow-dynamic-ellipsis">COD PEDIDO <p-sortIcon [field]="'codPedido'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'codPedido', 'contains')">
              </th>
              <th class="text-left" style="width: 80px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'incoterm'">
                  <span class="text-overflow-dynamic-ellipsis">INCOTERM <p-sortIcon [field]="'incoterm'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'incoterm', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'numJira'">
                  <span class="text-overflow-dynamic-ellipsis">COD CONTROLE <p-sortIcon [field]="'numJira'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'numJira', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'moeda'">
                  <span class="text-overflow-dynamic-ellipsis">MOEDA <p-sortIcon [field]="'moeda'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'moeda', 'contains')">
              </th>

              <th class="text-left" style="width: 90px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'orcamento'">
                  <span class="text-overflow-dynamic-ellipsis">ORÇAMENTO <p-sortIcon [field]="'orcamento'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'orcamento', 'contains')">
              </th>

              <th class="text-left" style="width: 110px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'mtdEnvio'">
                  <span class="text-overflow-dynamic-ellipsis">MÉTODO ENVIO <p-sortIcon [field]="'mtdEnvio'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'mtdEnvio', 'contains')">
              </th>

              <th class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'cnpj'">
                  <span class="text-overflow-dynamic-ellipsis">CNPJ <p-sortIcon [field]="'cnpj'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'cnpj', 'contains')">
              </th>

              <th class="text-left" style="width: 300px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                  <span class="text-overflow-dynamic-ellipsis">NOME <p-sortIcon [field]="'nome'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'nome', 'contains')">
              </th>

              <th class="text-left" style="width: 50px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'uf'">
                  <span class="text-overflow-dynamic-ellipsis">UF <p-sortIcon [field]="'uf'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'uf', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                  <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'codigo', 'contains')">
              </th>

              <th class="text-left" style="width: 300px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                  <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'descricao', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'pnForn'">
                  <span class="text-overflow-dynamic-ellipsis">PN FORNECEDOR <p-sortIcon [field]="'pnForn'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'pnForn', 'contains')">
              </th>

              <th class="text-left" style="width: 300px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'operacao'">
                  <span class="text-overflow-dynamic-ellipsis">OPERAÇÃO <p-sortIcon [field]="'operacao'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'operacao', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'detalhe'">
                  <span class="text-overflow-dynamic-ellipsis">FORMA PAGTO <p-sortIcon [field]="'detalhe'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'detalhe', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'ncm'">
                  <span class="text-overflow-dynamic-ellipsis">NCM <p-sortIcon [field]="'ncm'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'ncm', 'contains')">
              </th>

              <th class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'quantidade'">
                  <span class="text-overflow-dynamic-ellipsis">QTDE <p-sortIcon [field]="'quantidade'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'quantidade', 'contains')">
              </th>

              <th class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrUnit'">
                  <span class="text-overflow-dynamic-ellipsis">VLR UNIT <p-sortIcon [field]="'vlrUnit'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrUnit', 'contains')">
              </th>

              <th class="text-right" style="width: 90px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrTotal'">
                  <span class="text-overflow-dynamic-ellipsis">VLR TOTAL <p-sortIcon [field]="'vlrTotal'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrTotal', 'contains')">
              </th>

              <th class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrIcms'">
                  <span class="text-overflow-dynamic-ellipsis">VLR ICMS <p-sortIcon [field]="'vlrIcms'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrIcms', 'contains')">
              </th>

              <th class="text-right" style="width: 100px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrIcmsSt'">
                  <span class="text-overflow-dynamic-ellipsis">VLR ICMS ST <p-sortIcon [field]="'vlrIcmsSt'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrIcmsSt', 'contains')">
              </th>

              <th class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrIpi'">
                  <span class="text-overflow-dynamic-ellipsis">VLR IPI <p-sortIcon [field]="'vlrIpi'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrIpi', 'contains')">
              </th>

              <th class="text-right" style="width: 125px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrImpImport'">
                  <span class="text-overflow-dynamic-ellipsis">VLR IMP IMPORT <p-sortIcon [field]="'vlrImpImport'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrImpImport', 'contains')">
              </th>

              <th class="text-left" style="width: 120px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrDesconto'">
                  <span class="text-overflow-dynamic-ellipsis">VLR DESCONTO <p-sortIcon [field]="'vlrDesconto'">
                    </p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrDesconto', 'contains')">
              </th>

              <th class="text-right" style="width: 90px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrFrete'">
                  <span class="text-overflow-dynamic-ellipsis">VLR FRETE <p-sortIcon [field]="'vlrFrete'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrFrete', 'contains')">
              </th>

              <th class="text-right" style="width: 100px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrSeguro'">
                  <span class="text-overflow-dynamic-ellipsis">VLR SEGURO <p-sortIcon [field]="'vlrSeguro'">
                    </p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrSeguro', 'contains')">
              </th>

              <th class="text-right" style="width: 100px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'vlrOutro'">
                  <span class="text-overflow-dynamic-ellipsis">VLR OUTRO <p-sortIcon [field]="'vlrOutro'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'vlrOutro', 'contains')">
              </th>

              <th class="text-left" style="width: 130px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'tipo'">
                  <span class="text-overflow-dynamic-ellipsis">TIPO <p-sortIcon [field]="'tipo'"></p-sortIcon></span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'tipo', 'contains')">
              </th>

              <th class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'rateio'">
                  <span class="text-overflow-dynamic-ellipsis">RATEIO <p-sortIcon [field]="'rateio'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'rateio', 'contains')">
              </th>

              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'usuario'">
                  <span class="text-overflow-dynamic-ellipsis">USUARIO <p-sortIcon [field]="'usuario'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'usuario', 'contains')">
              </th>

              <th class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container" [pSortableColumn]="'status'">
                  <span class="text-overflow-dynamic-ellipsis">STATUS <p-sortIcon [field]="'status'"></p-sortIcon>
                  </span>
                </span>
                <input class="form-control form-control-sm" type="text"
                  (input)="dt.filter($event.target.value, 'status', 'contains')">
              </th>


            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>

              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.dtEmissao}}">{{rowData.dtEmissao
                    }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codPedido}}">{{rowData.codPedido
                    }}</span>
                </span>
              </td>
              <td class="text-left" style="width: 80px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.incoterm}}">{{rowData.incoterm}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 80px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.numJira}}">{{rowData.numJira}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 80px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.moeda}}">{{rowData.moeda}}</span>
                </span>
              </td>
              <td class="text-left" style="width: 90px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.orcamento}}">{{rowData.orcamento
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 110px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.mtdEnvio}}">{{rowData.mtdEnvio
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 120px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.cnpj}}">{{rowData.cnpj }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 300px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 50px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.uf}}">{{rowData.uf }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 300px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.descricao}}">{{rowData.descricao
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.pnForn}}">{{rowData.pnForn
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 300px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.operacao}}">{{rowData.operacao
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.formaPagamento}}">{{rowData.formaPagamento }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.ncm}}">{{rowData.ncm }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.quantidade}}">{{rowData.quantidade
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrUnit}}">{{rowData.vlrUnit
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 90px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrTotal}}">{{rowData.vlrTotal
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrIcms}}">{{rowData.vlrIcms
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrIcmsSt}}">{{rowData.vlrIcmsSt
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 100px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrIpi}}">{{rowData.vlrIpi
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 125px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.vlrImpImport}}">{{rowData.vlrImpImport }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 120px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis"
                    matTooltip="{{rowData.vlrDesconto}}">{{rowData.vlrDesconto
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 90px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrFrete}}">{{rowData.vlrFrete
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 100px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrSeguro}}">{{rowData.vlrSeguro
                    }}</span>
                </span>
              </td>

              <td class="text-right" style="width: 100px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.vlrOutro}}">{{rowData.vlrOutro
                    }}</span>
                </span>
              </td>

              <td class="text-left" style="width: 130px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.tipo}}">{{rowData.tipo }}</span>
                </span>
              </td>

              <td class="text-center" style="width: 80px;" *ngIf="detalhe">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.rateio}}">{{rowData.rateio
                    }}</span>
                </span>
              </td>

              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.usuario}}">{{rowData.usuario
                    }}</span>
                </span>
              </td>


              <td class="text-center" style="width: 100px;">
                <span class="text-overflow-dynamic-container">
                  <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.status}}">{{rowData.status
                    }}</span>
                </span>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-rowData>
            <tr>
              <td [attr.colspan]="13" class="spaced-alert">
                <div class="alert alert-xs alert-primary d-inline" role="alert">
                  <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
