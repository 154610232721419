<mat-card class="card-linnks margin-linnks">

  <mat-card-title>
    {{mainTitle}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>

  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>

  <mat-card-content>
    <div class="box-content" *ngIf="!novaRequisicao">

      <p-table #dt1 [value]="mapDestinatarios" [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
        [rowsPerPageOptions]="rowsPerPage" selectionMode="false" [style]="{'width':'100%'}" class="table-system"
        (onHeaderCheckboxToggle)="selectTodos($event)" (onRowSelect)="selectDestinatario($event)"  (onRowUnselect)="unselectDestinatario($event)">

        <ng-template pTemplate="header">
          <tr>
            <th class="text-center tableHeaderCheckbox">
              <span class="check-all p-0">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </span>
            </th>
            <th class="text-left" style="width: 60%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'nome'">
                <span class="text-overflow-dynamic-ellipsis">Nome <p-sortIcon [field]="'nome'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'nome', 'contains')">
            </th>
            <th class="text-left" style="width: 35%;">
              <span class="text-overflow-dynamic-container" [pSortableColumn]="'email'">
                <span class="text-overflow-dynamic-ellipsis">Email <p-sortIcon [field]="'email'"></p-sortIcon></span>
              </span>
              <input class="form-control form-control-sm" type="text"
                (input)="dt1.filter($event.target.value, 'email', 'contains')">
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td class="text-center tableCheckbox">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td class="text-left" style="width: 60%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.nome}}">{{rowData.nome}}</span>
              </span>
            </td>
            <td class="text-left" style="width: 37%;">
              <span class="text-overflow-dynamic-container">
                <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.email}}">{{rowData.email}}</span>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-rowData>
          <tr>
            <td [attr.colspan]="3" class="spaced-alert">
              <div class="alert alert-xs alert-primary d-inline" role="alert">
                <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </mat-card-content>

  <div class="box-content" *ngIf="novaRequisicao">
    <div class="form-row">
      <div class="form-group col-sm-5">
        <label> Nome </label>
        <input class="form-control" type="text" input-text="left" [(ngModel)]="destinatario.nome" />
      </div>
      <div class="form-group col-sm-4">
        <label> Email </label>
        <input class="form-control" type="text" input-text="left" [(ngModel)]="destinatario.mail" />
      </div>
      <div class="form-group col-sm-3">
        <label>Tipo de arquivo</label>
        <p-multiSelect class="type-p-mult" [options]="mapTipoArquivos" [(ngModel)]="vetTipoArquivo"
          defaultLabel="Selecionar Tipo de Arquivo" [filter]="true" placeholder="{0} tipos selecionados"
          selectedItemsLabel="{0} tipos selecionados"></p-multiSelect>
      </div>
    </div>

  </div>
</mat-card>