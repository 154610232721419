<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{ title }}
  </mat-card-title>
  <mat-card-content>
    <div class="shadow-box">
      <div class="header-box">
        <div class="header-box-content">
          <h3 class="title-card">CONFIGURAÇÃO DE RELATÓRIO GERENCIAL</h3>

          <ul class="list-inline filter-menu filter-card-header mt-4">
            <li class="list-inline-item" [ngClass]="{active: oneSheetConfig}" *ngIf="!(isAdding || isEditing)"><a
                (click)="showTela('normalConfig')">NOVA CONFIGURAÇÃO</a></li>
            <li class="list-inline-item" [ngClass]="{active: composeConfig}" *ngIf="!(isAdding || isEditing)"><a
                (click)="showTela('configCompose')">CONFIGURAÇÃO COMPOSTA</a>
            </li>
          </ul>

          <div class="actions-card d-flex">
            <app-toolbar [value]="toolbarMain"></app-toolbar>
          </div>
        </div>
      </div>
      <div class="box-content pt-2">
        <div [ngStyle]="oneSheetConfig ? {'display': 'block'} : {'display': 'none'}">
          <!--Listagem-->
          <div class="row" *ngIf="!isAdding && !isEditing">
            <div class="col-sm-12">
              <app-skeleton [config]="skeleton">
                <p-table class="table-system" #dt6 [value]="listConfigs" [rows]="rows" [paginator]="true"
                  [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" emptyMessage="Nenhum Registro Encontrado"
                  (onRowSelect)="checkConfig($event)" (onRowUnselect)="uncheckConfig($event)"
                  [style]="{ width: '100%'}">

                  <ng-template pTemplate="header">
                    <tr>
                      <th style="text-align: left; width: 5%;"></th>
                      <th style="text-align: left; width: 17%;">Código</th>
                      <th style="text-align: left; width: 78%;">Nome</th>
                    </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-config>
                    <tr>
                      <td style="text-align: center; width: 5%">
                        <p-tableCheckbox [value]="config"></p-tableCheckbox>
                      </td>
                      <td style="text-align: left; width: 17%">
                        {{config.codigo != null? config.codigo : 'Configuração sem código'}}
                      </td>
                      <td style="text-align: left; width: 78%">
                        {{config.nome != null? config.nome : 'Configuração sem nome'}}
                      </td>
                    </tr>
                  </ng-template>

                </p-table>
              </app-skeleton>
            </div>
          </div>

          <!--Relatório-->
          <div *ngIf="oneSheetConfig && (isAdding || isEditing)">
            <div class="row">
              <div class="form-group col-sm-12">
                <div class="form-row" style="flex-direction: column;">

                  <div>
                    <div class="flex-style">
                      <div class="form-group col-sm-6">
                        <label>Código</label>
                        <span class="obrigatorio"
                          *ngIf="configRelat.codigo == null || configRelat.codigo === ''">*</span>
                        <input class="form-control" type="text" placeholder="" [(ngModel)]="configRelat.codigo">
                      </div>

                      <div class="form-group col-sm-6">
                        <label>Nome da Configuração</label>
                        <span class="obrigatorio" *ngIf="configRelat.nome == null || configRelat.nome === ''">*</span>
                        <input class="form-control" type="text" placeholder="" [(ngModel)]="configRelat.nome">
                      </div>
                    </div>

                    <div class="flex-style">
                      <div class="form-group col-sm-6">
                        <label>Plano gerencial</label>
                        <span class="obrigatorio"
                          *ngIf="[null, undefined].includes(configRelat.planoGerencialId)">*</span>
                        <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione o plano gerencial"
                          [options]="opcoesPlanoGerencial" [(ngModel)]="configRelat.planoGerencialId"
                          (onChange)="buscarInfosView()"></p-dropdown>
                      </div>

                      <div class="form-group col-sm-6">
                        <label>MÓDULOS</label>
                        <p-multiSelect class="type-p-mult" [options]="opcoesModulos" defaultLabel="Selecione módulo"
                          [filter]="true" [(ngModel)]="planoGerencialModulos"></p-multiSelect>
                      </div>
                    </div>

                    <div class="flex-style">
                      <div class="form-group col-sm-12">
                        <p-checkbox class="ml-1" [binary]="true" [(ngModel)]="configRelat.hideNoValueContas" label="Exibir contas sem valor"></p-checkbox>
                        <p-checkbox class="ml-4" [binary]="true" [(ngModel)]="configRelat.showFormulas" label="Exibir formulas no relatório Excel" (onChange)="changeFormulas()"></p-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <p-accordion [multiple]="true">

                  <p-accordionTab header="Configurações" [selected]="isEditing ? true : false">

                    <div class="form-row">
                      <div class="form-group col-sm-2">
                        <label>Tipo</label>
                        <span class="obrigatorio" *ngIf="[null, undefined].includes(addColuna.tipo)">*</span>
                        <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione o Tipo"
                          (onChange)="changeTipo(addColuna.tipo)" [options]="opcoesTipo" [(ngModel)]="addColuna.tipo">
                        </p-dropdown>
                      </div>
                      <div class="form-group col-sm-2 pl-3" *ngIf="addColuna.tipo !== 2">
                        <label>N° casas decimais
                          <span class="obrigatorio"
                            *ngIf="[null, undefined].includes(addColuna.qtdDecimais)">*</span></label>
                        <input class="form-control text-right" type="text" placeholder=""
                          [(ngModel)]="addColuna.qtdDecimais" [pKeyFilter]="'int'">
                      </div>

                      <div class="form-group col-sm-6 text-left" *ngIf="addColuna.tipo === 2 && isAdding">
                        <label class="d-block" style="visibility: hidden; margin-top: 7px;">DO NOT REMOVE</label>
                        <button type="button" class="btn btn-sm btn-primary" (click)="adicionarColuna()"><i
                            class="fas fa-check"></i> ADICIONAR COLUNA</button>
                      </div>

                      <div class="form-group col-sm-3 pl-3" *ngIf="addColuna.tipo !== 2">
                        <label>Label</label>
                        <span class="obrigatorio" *ngIf="addColuna.label == null || addColuna.label === ''">*</span>
                        <input class="form-control" [(ngModel)]="addColuna.label" type="text" />
                      </div>

                      <div class="form-group col-sm-3 pl-3" *ngIf="isEditing">
                        <label>Índice</label>
                        <span class="obrigatorio" *ngIf="addColuna.label == null || addColuna.label === ''">*</span>
                        <input class="form-control text-right" type="text" placeholder="" [(ngModel)]="addColuna.indice"
                          [pKeyFilter]="'int'" />
                      </div>

                      <div class="form-group col-sm-2 mt-1" *ngIf="addColuna.tipo !== 2">
                        <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                        <p-checkbox [binary]="true" [(ngModel)]="addColuna.isColunaNegrito"></p-checkbox>
                        <label style="padding: 0 5px;">Coluna Negrito</label>
                      </div>
                    </div>

                    <div class="form-row align-items-center" *ngIf="addColuna.tipo !== 2">
                      <div class="form-group col-sm-2 mt-1">
                        <label>Cor do Fundo</label>
                        <input type="color" class="form-control" [(ngModel)]="addColuna.corFundo"
                          [disabled]="!addColuna.isCorFundoCabecalho && !addColuna.isCorFundoColuna"
                          style="height: 34px;" />
                      </div>
                      <div class="form-group col">
                        <div class="pull-left">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('fundoH')" [(ngModel)]="addColuna.isCorFundoCabecalho">
                          </p-checkbox>
                          <label style="padding: 0 15px;">Cabeçalho</label>
                        </div>
                        <div class="pull-left pl-4">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('fundoC')" [(ngModel)]="addColuna.isCorFundoColuna">
                          </p-checkbox>
                          <label style="padding: 0 5px;">Coluna</label>
                        </div>
                      </div>

                      <div class="form-group col-sm-2 mt-1">
                        <label>Cor do texto </label>
                        <input type="color" class="form-control" [(ngModel)]="addColuna.corTexto"
                          [disabled]="!addColuna.isCorTextoCabecalho && !addColuna.isCorTextoColuna"
                          style="height: 34px;" />
                      </div>
                      <div class="form-group col">
                        <div class="pull-left">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('textoH')" [(ngModel)]="addColuna.isCorTextoCabecalho">
                          </p-checkbox>
                          <label style="padding: 0 5px;">Cabeçalho</label>
                        </div>
                        <div class="pull-left pl-4">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('textoC')" [(ngModel)]="addColuna.isCorTextoColuna">
                          </p-checkbox>
                          <label style="padding: 0 5px;">Coluna</label>
                        </div>
                      </div>
                    </div>

                    <div class="form-row align-items-center" *ngIf="addColuna.tipo === 3">
                      <div class="form-group col-sm-3" *ngIf="!addColuna.isFormulaPgc">
                        <label>Fórmula</label>
                        <span class="obrigatorio" *ngIf="addColuna.formula == null || addColuna.formula === ''">*</span>
                        <input class="form-control" [(ngModel)]="addColuna.formula" type="text"
                          [disabled]="addColuna.isFormulaPgc" />
                      </div>

                      <div class="form-group col">
                        <div class="pull-left pr-1" *ngIf="!addColuna.isFormulaPgc">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('valor')" [(ngModel)]="addColuna.isValor"
                            [disabled]="addColuna.isFormulaPgc"></p-checkbox>
                          <label style="padding: 0 5px;">Valor</label>
                        </div>
                        <div class="pull-left pl-4" *ngIf="!addColuna.isFormulaPgc">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('percent')" [(ngModel)]="addColuna.isPercent"
                            [disabled]="addColuna.isFormulaPgc">%</p-checkbox>
                          <label style="padding: 0 5px;">%</label>
                        </div>
                        <div class="pull-left">
                          <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT REMOVE</label>
                          <p-checkbox [binary]="true" (click)="check('pgcFormula')" [(ngModel)]="addColuna.isFormulaPgc">Análise
                          </p-checkbox>
                          <label style="padding: 0 5px;">Vertical</label>
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <p-table class="table-system" #dt6 [value]="opcoesFiltro" [style]="{ width: '100%'}">
                          <ng-template pTemplate="header">
                            <tr>
                              <th style="text-align: left; background-color: #efefef; border-color: #cccccc;">
                                Data para label</th>
                            </tr>
                          </ng-template>

                          <ng-template pTemplate="body" let-filtro>
                            <tr>
                              <td (click)="onSelectLabelCampo(filtro.value)" [pSelectableRow]="filtro">
                                {{filtro.label}}
                              </td>
                            </tr>
                          </ng-template>

                          <ng-template pTemplate="emptymessage" *ngIf="configRelat.planoGerencialId == null">
                            <tr>
                              <td [attr.colspan]="1" class="spaced-alert">
                                <div class="alert alert-xs alert-primary d-inline" role="alert">
                                  <i class="fas fa-info-circle"></i> Selecione um plano gerencial!
                                </div>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>

                    <h3 class="sub-title" *ngIf="addColuna.tipo !== 1 && addColuna.tipo !== 2 && addColuna.tipo !== 3">
                      Visualizar
                    </h3>
                    <h3 class="sub-title" *ngIf="addColuna.tipo === 1">View</h3>
                    <h3 class="sub-title" *ngIf="addColuna.tipo === 3 && !addColuna.isFormulaPgc">Fórmula</h3>
                    <h3 class="sub-title" *ngIf="addColuna.tipo === 3 && addColuna.isFormulaPgc">Adicionar formula -
                      Coluna {{alfabeto(addColuna.indice)}}</h3>


                    <!--Formula-->
                    <div *ngIf="addColuna.tipo === 3 && addColuna.isFormulaPgc">
                      <div class="row">
                        <div class="col-sm-6">
                          <p-table class="table-system" #dt6 [rows]="rows" [paginator]="true" [pageLinks]="pageLinks"
                            [rowsPerPageOptions]="rowsPerPage" [value]="columnFormulasAnalise"
                            [style]="{ width: '100%'}">
                            <ng-template pTemplate="header">
                              <tr>
                                <th
                                  style="text-align: left; width: 50%; background-color: #efefef; border-color: #cccccc;">
                                  <span [pSortableColumn]="'pgcDescr'">
                                    <span>Conta <p-sortIcon [field]="'conta'"></p-sortIcon></span>
                                  </span>
                                  <input class="form-control form-control-sm" type="text" (input)="dt6.filter($event.target.value, 'pgcDescr', 'contains')">
                                </th>
                                <th
                                  style="text-align: left; width: 50%; background-color: #efefef; border-color: #cccccc;">
                                  <span [pSortableColumn]="'formula'">
                                    <span>Formula <p-sortIcon [field]="'formula'"></p-sortIcon></span>
                                  </span>
                                  <br>
                                  <input class="form-control form-control-sm" type="text" (input)="dt6.filter($event.target.value, 'formula', 'contains')">
                                </th>
                                <th
                                  style="text-align: center; width: 90px; vertical-align: sub; padding-top: 8px; background-color: #efefef; border-color: #cccccc;">
                                  Ação
                                </th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-formula>
                              <tr>
                                <td [pSelectableRow]="formula" style="width: 50%;">
                                  {{formula.pgcDescr}}
                                </td>
                                <td [pSelectableRow]="formula" style="width: 50%;">
                                  {{formula.formula}}
                                </td>
                                <td style="text-align: center; width: 90px; vertical-align: sub; padding-top: 8px">
                                  <span class="edit" matTooltip="Editar" (click)="editFormulaPgc(formula)">
                                    <i class="fas fa-pencil-alt"></i>
                                  </span>
                                  <span class="delete" matTooltip="Remover" (click)="deleteFormulaPgc(formula)">
                                    <i class="fas fa-trash-alt"></i>
                                  </span>
                                </td>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage" *ngIf="columnFormulasAnalise.length === 0">
                              <tr>
                                <td [attr.colspan]="3" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Nenhuma formula adicionada.
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>

                        <div class="col-sm-6">
                          <div class="form-row">
                            <div class="form-group col-sm-3">
                              <label>Coluna</label>
                              <span class="obrigatorio">*</span>
                              <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Selecione"
                                [options]="opcoesColunas" [(ngModel)]="addFormulaAnalise.columnReferenciaIndice">
                              </p-dropdown>
                            </div>
                            <div class="form-group col">
                              <label>Conta</label>
                              <span class="obrigatorio" *ngIf="contasSelecionadas.length === 0">*</span>
                              <p-multiSelect class="type-p-multi" [options]="opcoesContas"
                                [(ngModel)]="contasSelecionadas" (onChange)="onChangeContas($event.value)"
                                defaultLabel="Selecione uma conta para aplicar a formula" [filter]="true"
                                placeholder="{0} contas selecionadas" selectedItemsLabel="{0} contas selecionadas">
                              </p-multiSelect>
                            </div>
                          </div>

                          <div class="form-row align-items-center"
                            *ngIf="contasSelecionadas.length > 0 && addFormulaAnalise.columnReferenciaIndice != null">
                            <div class="form-group col-sm-3">
                              <label>Fórmula</label>
                              <span class="obrigatorio"
                                *ngIf="addFormulaAnalise.formula == null || addFormulaAnalise.formula == ''">*</span>
                              <input class="form-control text-right" type="text" placeholder="B / C1"
                                [(ngModel)]="addFormulaAnalise.formula">
                            </div>
                            <div class="form-group col">
                              <div class="pull-left">
                                <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT
                                  REMOVE</label>
                                <p-checkbox [binary]="true" (click)="check('pgcValor')" [(ngModel)]="addFormulaAnalise.isValor">
                                </p-checkbox>
                                <label style="padding: 0 5px;">Valor</label>
                              </div>
                              <div class="pull-left">
                                <label class="d-block" style="visibility: hidden; margin-top: 4px;">DO NOT
                                  REMOVE</label>
                                <p-checkbox [binary]="true" (click)="check('pgcPercent')" [(ngModel)]="addFormulaAnalise.isPercent">%
                                </p-checkbox>
                                <label style="padding: 0 5px;">%</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-row"
                            *ngIf="contasSelecionadas.length > 0 && addFormulaAnalise.columnReferenciaIndice != null">
                            <div class="form-group col-sm-12">
                              <button type="button" class="btn btn-sm btn-composeConfigary pull-left"
                                (click)="voltar()"><i class="fas fa-undo"></i>
                                Voltar</button>

                              <button type="button" class="btn btn-sm btn-primary pull-left ml-2"
                                (click)="adicionarFormulaPgc()"><i class="fas fa-check"></i> ADICIONAR FORMULA</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!--COLUNA VIEW-->
                    <div class="form-row" *ngIf="addColuna.tipo === 1">
                      <div class="form-group col-sm-2">
                        <label>Campo de valor</label>
                        <span class="obrigatorio"
                          *ngIf="[null, undefined, ''].indexOf(addColuna.viewCampoValor) >= 0">*</span>
                        <p-dropdown class="type-p-dropdown" [filter]="false" placeholder="Escolha um campo para o valor"
                          [options]="opcoesCampoValor" [(ngModel)]="addColuna.viewCampoValor"></p-dropdown>
                      </div>
                    </div>

                    <div style="display: flex;" *ngIf="addColuna.tipo === 1">
                      <div style="margin-right: 1rem;">
                        <p-table class="table-system" #dt6 [value]="viewCampos" [rows]="rows" [paginator]="true"
                          [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage" [style]="{ width: '100%'}"
                          [scrollable]="true" scrollHeight="270px">
                          <ng-template pTemplate="header">
                            <tr>
                              <th class="text-left">
                                <span class="overflow-ellipse text-center" [pSortableColumn]="'campo'">
                                  CAMPO <p-sortIcon [field]="'campo'"></p-sortIcon>
                                </span>
                                <input pInputText type="text" class="form-control form-control-sm"
                                  (input)="dt6.filter($event.target.value, 'campo', 'contains')">
                              </th>
                              <th class="text-left">
                                <span class="overflow-ellipse text-center" [pSortableColumn]="'descricao'">
                                  DESCRIÇÃO <p-sortIcon [field]="'descricao'"></p-sortIcon>
                                </span>
                                <input pInputText type="text" class="form-control form-control-sm"
                                  (input)="dt6.filter($event.target.value, 'descricao', 'contains')">
                              </th>
                              <th class="text-left">
                                <span class="overflow-ellipse text-center" [pSortableColumn]="'tipo'">
                                  TIPO <p-sortIcon [field]="'tipo'"></p-sortIcon>
                                </span>
                                <input pInputText type="text" class="form-control form-control-sm"
                                  (input)="dt6.filter($event.target.value, 'tipo', 'contains')">
                              </th>
                            </tr>
                          </ng-template>

                          <ng-template pTemplate="body" let-viewCampo>
                            <tr [pSelectableRow]="viewCampo">
                              <td (click)="onSelectViewCampo('vw.' + viewCampo.campo)">{{viewCampo.campo}}</td>
                              <td (click)="onSelectViewCampo('vw.' + viewCampo.campo)">{{viewCampo.descricao}}</td>
                              <td (click)="onSelectViewCampo('vw.' + viewCampo.campo)">{{viewCampo.tipo}}</td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage" let-rowData>
                            <tr>
                              <td [attr.colspan]="3" class="spaced-alert">
                                <div class="alert alert-xs alert-primary d-inline" role="alert">
                                  <i class="fas fa-info-circle"></i> Selecione um plano gerencial!
                                </div>
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>

                      <div style="display: flex; flex-direction: column; margin-left: 1rem;">
                        <div style="margin-top: 1rem;">
                          <p-table class="table-system" #dt7 [value]="opcoesFiltro" [style]="{ width: '100%'}">
                            <ng-template pTemplate="header">
                              <tr>
                                <th
                                  style="text-align: left; width: 16%; background-color: #efefef; border-color: #cccccc;">
                                  Data para filtro</th>
                                <th
                                  style="text-align: left; width: 16%; background-color: #efefef; border-color: #cccccc;">
                                  Data para label</th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-filtro>
                              <tr>
                                <td (click)="onSelectViewCampo(filtro.value)" [pSelectableRow]="filtro">
                                  {{filtro.label}}
                                </td>
                                <td (click)="onSelectLabelCampo(filtro.value)" [pSelectableRow]="filtro">
                                  {{filtro.label}}
                                </td>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage" *ngIf="configRelat.planoGerencialId == null">
                              <tr>
                                <td [attr.colspan]="1" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Selecione um plano gerencial!
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>

                        <div>
                          <p-table class="table-system" #dt7 [value]="listGrupoEmpresa" [style]="{ width: '100%'}">
                            <ng-template pTemplate="caption" let-title>Grupo de empresas</ng-template>
                            <ng-template pTemplate="header">
                              <tr>
                                <th
                                  style="text-align: left; width: 12%; background-color: #efefef; border-color: #cccccc;">
                                  Check</th>
                                <th
                                  style="text-align: left; width: 16%; background-color: #efefef; border-color: #cccccc;">
                                  ID</th>
                                <th style="text-align: left; background-color: #efefef; border-color: #cccccc;">
                                  Empresa</th>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-empresa>
                              <tr>
                                <td>
                                  <p-checkbox (onChange)="selectCheckbox(empresa.id, $event)" [binary]="true" [value]="empresasSelecionadas.includes(empresa.id)"></p-checkbox>
                                </td>
                                <td>
                                  {{empresa.id}}
                                </td>
                                <td>
                                  {{empresa.nome_fantasia}}
                                </td>
                              </tr>
                            </ng-template>

                            <ng-template pTemplate="emptymessage" *ngIf="listGrupoEmpresa.length == 0">
                              <tr>
                                <td [attr.colspan]="3" class="spaced-alert">
                                  <div class="alert alert-xs alert-primary d-inline" role="alert">
                                    <i class="fas fa-info-circle"></i> Agrupamento de empresas não cadastrado!
                                  </div>
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                    </div>



                    <!--SELECIONE UM TIPO AO LADO-->
                    <div *ngIf="addColuna.tipo !== 1 && addColuna.tipo !== 2 && addColuna.tipo !== 3">
                      <div class="alert alert-xs alert-info d-inline-block mb-0" role="alert">
                        <i class="fas fa-info-circle"></i> Configure um tipo para visualização!
                      </div>
                    </div>

                    <!--Preencha os campos obrigatórios-->
                    <div *ngIf="addColuna.tipo === 3 && !addColuna.isFormulaPgc">
                      <div class="alert alert-xs alert-info d-inline-block mb-0" role="alert">
                        <i class="fas fa-info-circle"></i> Preencha os campos obrigatórios acima!
                      </div>
                    </div>

                    <div class="form-row" *ngIf="addColuna.tipo === 1">
                      <div class="form-group col-sm-12">
                        <mark class="d-inline-block mb-1 mt-4" style="font-size: 13px;">Não informar o
                          <strong>WHERE</strong>,
                          somente a condição.</mark>
                        <div class="form-row">
                          <div class="form-group col-sm-12">
                            <textarea rows="8" cols="100" [(ngModel)]="addColuna.sql" class="form-control"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row"
                      *ngIf="!((addColuna.isFormulaPgc && addColuna.tipo === 3) && (contasSelecionadas.length > 0 && addFormulaAnalise.columnReferenciaIndice != null))">
                      <div class="form-group col-sm-12 mt-2 text-left">
                        <button type="button" *ngIf="isEditing" class="btn btn-sm btn-composeConfigary"
                          (click)="cancelEdit()"><i class="fas fa-undo"></i> CANCELAR</button>
                        <button type="button" *ngIf="isEditing" class="btn btn-sm btn-primary"
                          (click)="editarColuna()"><i class="fas fa-check"></i> SALVAR</button>
                        <button type="button" *ngIf="isAdding && addColuna.tipo === 3" class="btn btn-sm btn-primary"
                          (click)="adicionarColuna()"><i class="fas fa-check"></i> ADICIONAR COLUNA</button>
                        <button type="button" *ngIf="isAdding && addColuna.tipo === 1" style="margin-top: -38px;"
                          class="btn btn-sm btn-primary" (click)="adicionarColuna()"><i class="fas fa-check"></i>
                          ADICIONAR COLUNA</button>
                      </div>
                    </div>
                  </p-accordionTab>

                  <p-accordionTab header="Níveis de visualiazação" [selected]="false">
                    <div>
                      <div class="col-sm-12">
                        <div>
                          <div style="width: 100%; margin: 0 auto; margin-top: 20px;">
                            <p-pickList [showSourceControls]="false" [showTargetControls]="false"
                              sourceHeader="Níveis Disponíveis" targetHeader="Níveis Vinculados"
                              [source]="opcoesTipoVisao" [target]="listTipoVisao" [dragdrop]="true" [responsive]="true"
                              filterBy="id">
                              <ng-template let-tipo pTemplate="item">
                                {{ tipo.label }}
                              </ng-template>
                            </p-pickList>
                          </div>

                        </div>
                      </div>
                    </div>
                  </p-accordionTab>

                  <p-accordionTab header="Preview" [selected]="true">
                    <div class="shadown-table">
                      <p-table class="table-system table-preview no-margin" [columns]="configRelat.colunas"
                        [value]="contasGerenciais" [rows]="rows" [scrollable]="true" scrollHeight="360px">
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                            <th class="text-center" *ngFor="let col of columns" (mouseover)="columnOver = col.indice;"
                              (mouseleave)="columnOver = null;" [ngClass]="col.tipo === 2 ? 'separador' : ''"
                              [ngStyle]="col.indice === 0 ? {'width': '360px'} : {'width': '150px'}"
                              (click)="onSelectColumn(col)">
                              {{ alfabeto(col.indice) }}

                              <div *ngIf="col.indice != 0 && columnOver == col.indice">
                                <span style="margin: 0px 5px; cursor: pointer;" (click)="moveColumn(-1, col.indice)"
                                  *ngIf="col.indice != 1">
                                  <i class="fas fa-arrow-left"></i>
                                </span>
                                <span style="margin: 0px 5px; cursor: pointer;" (click)="moveColumn(1, col.indice)">
                                  <i class="fas fa-arrow-right"></i> </span>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th *ngFor="let col of configRelat.colunas" [ngClass]="col.tipo === 2 ? 'separador' : ''"
                              [ngStyle]="{'background-color':col.isCorFundoCabecalho ? col.corFundo : '', 'color':col.isCorTextoCabecalho ? col.corTexto : '', 'width': col.indice === 0 ? '360px' : '150px'}">
                              <div style="display: flex; justify-content: center;">
                                <span style="display: flow-root;"
                                  matTooltip="{{ col.indice === 0? col.label : formatarLabel(col.label) }}"
                                  matTooltipPosition="above"
                                  class="{{col.indice === 0 ? 'overflow-ellipse text-left' : 'overflow-ellipse text-center'}}">
                                  {{ col.indice === 0? col.label : formatarLabel(col.label) }}
                                </span>
                                <span style="display: inline-flex;">
                                  <span *ngIf="col.indice !== 0" class="edit" style="margin: 0px 5px; cursor: pointer;"
                                    matTooltip="Editar" matTooltipPosition="above" (click)="enableEdit(col)">
                                    <i class="fas fa-pencil-alt"></i>
                                  </span>
                                  <span *ngIf="col.indice !== 0" class="delete" style="cursor: pointer;"
                                    matTooltip="Excluir" matTooltipPosition="above" (click)="deleteColumn(col)">
                                    <i class="fas fa-trash-alt"></i>
                                  </span>
                                </span>
                              </div>
                            </th>
                          </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-conta>
                          <tr *ngIf="conta['linha_acima'] && !(configRelat.hideNoValueContas || conta['isHidden'])">
                            <td class="separador-linha" *ngFor="let col of configRelat.colunas"
                              [ngStyle]="{'background-color':col.isCorFundoColuna ? col.corFundo : '', 'color':col.isCorTextoColuna ? col.corTexto : '', 'font-weight':col.isColunaNegrito ? 'bold' : '', 'width': col.indice === 0 ? '360px' : '150px'}">
                            </td>
                          </tr>

                          <tr *ngIf="!configRelat.hideNoValueContas || !conta['isHidden']">
                            <td *ngFor="let col of configRelat.colunas" (click)="onSelectConta(conta, col.indice)"
                              [ngClass]="getClass(col, conta)"
                              [ngStyle]="{'background-color':col.isCorFundoColuna ? col.corFundo : '', 'color':col.isCorTextoColuna ? col.corTexto : '', 'font-weight':col.isColunaNegrito ? 'bold' : '', 'width': col.indice === 0 ? '360px' : '150px'}">
                              <span class="{{col.indice !== 0 ? 'overflow-ellipse text-right' : 'overflow-ellipse'}}">
                                {{ col.tipo === 2? "" : col.indice === 0? conta['cod_cta'] + " - " +
                                conta[alfabeto(col.indice)] :
                                conta[alfabeto(col.indice)] | number: '1.' + col.qtdDecimais + '-' +
                                col.qtdDecimais:'pt-BR'}}{{col.isPercent || (conta[alfabeto(col.indice)+"-pgc"] != null
                                && conta[alfabeto(col.indice)+"-pgc"]['isPercent'])? "%" : ""}}
                              </span>
                            </td>
                          </tr>

                          <tr *ngIf="conta['linha_abaixo'] && !(configRelat.hideNoValueContas || conta['isHidden'])">
                            <td class="separador-linha" *ngFor="let col of configRelat.colunas"
                              [ngStyle]="{'background-color':col.isCorFundoColuna ? col.corFundo : '', 'color':col.isCorTextoColuna ? col.corTexto : '', 'font-weight':col.isColunaNegrito ? 'bold' : '', 'width': col.indice === 0 ? '360px' : '150px'}">
                            </td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>

                    <!--Preencha os campos obrigatórios-->
                    <div *ngIf="configRelat.colunas.length <= 0">
                      <div class="alert alert-xs alert-info d-inline-block mb-0" role="alert">
                        <i class="fas fa-info-circle"></i> Selecione um plano gerencial!
                      </div>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
        </div>

        <!-- Segunda aba -->
        <div [ngStyle]="composeConfig ? {'display': 'block'} : {'display': 'none'}">
          <div class="row">
            <div class="form-group col-sm-12">
              <div class="form-row">
                <div class="form-group col-sm-3">
                  <label>Código</label>
                  <span class="obrigatorio" *ngIf="configRelat.codigo == null || configRelat.codigo === ''">*</span>
                  <input class="form-control" type="text" placeholder="" [(ngModel)]="configRelat.codigo">
                </div>

                <div class="form-group col-sm-3">
                  <label>Nome da Configuração</label>
                  <span class="obrigatorio" *ngIf="configRelat.nome == null || configRelat.nome === ''">*</span>
                  <input class="form-control" type="text" placeholder="" [(ngModel)]="configRelat.nome">
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-12 mt-2 text-left">
              <div class="form-row">
                <div class="form-group col-sm-6">
                  <p-pickList sourceHeader="Disponíveis" targetHeader="Vinculadas" [source]="opcoesConfig"
                    [target]="listConfigRelatComposta" [dragdrop]="true" [responsive]="true" filterBy="nome"
                    [showSourceControls]="false" [showTargetControls]="false">
                    <ng-template let-config pTemplate="item">
                      {{ config.codigo? config.codigo + " - " : "" }} {{ config.nome }}
                    </ng-template>
                  </p-pickList>
                </div>
                <div class="form-group col-sm-6">
                  <p-table class="table-system" [value]="listConfigRelatComposta"
                    emptyMessage="Nenhum Registro Encontrado" [style]="{ width: '100%'}">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Configuração</th>
                        <th style="text-align: left; background-color: #efefef; border-color: #cccccc">Título da aba
                        </th>
                      </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-config>
                      <tr>
                        <td style="text-align: center">{{config.codigo}} - {{config.nome}}</td>
                        <td style="text-align: center">
                          <input class="form-control" type="text" placeholder="" [(ngModel)]="config.worksheet_title">
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
