<mat-card class="card-linnks margin-linnks" style="margin-top: 15px !important;">
  <mat-card-title>
    GERENCIAL
    <mat-card-subtitle>
      RELATÓRIO GERENCIAL
    </mat-card-subtitle>
  </mat-card-title>
    <mat-card-content>
    <div class="box-content">

      <app-linnks-table
[cols]="cols"
[value]="value"
[exportable]="true"
[paginator]="false"
[globalFilter]="false"
[titulo]="titulo"
[onlyTable]="false">
</app-linnks-table>



    </div>
  </mat-card-content>
</mat-card>
