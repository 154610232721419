<mat-card class="card-linnks margin-linnks">
  <mat-card-title>
    {{titlePrincipal}}
    <mat-card-subtitle>
      {{subtitle}}
    </mat-card-subtitle>
  </mat-card-title>
  <div class="ui-g">
    <div class="ui-g-12">

    </div>
  </div>


  <div [ngStyle]="statusTelaClass ? {'display': 'block'} : {'display': 'none'}"
    [ngClass]="{'fade-in': 'statusTelaClass'}">
    <div class="row">
      <div class="col-sm-12 pb-4">
        <div class="shadow-box">

          <div class="header-box">
            <div class="header-box-content">
              <h3 class="title-card"><i class="fas fa-sliders-h" style="font-size: 15px;"></i> CLASSIFICAÇÕES</h3>
              <div class="actions-card">
                <app-toolbar [value]="toolbarMain"></app-toolbar>
                <!-- <button type="button" (click)="openNewModal()" class="btn btn-xs btn-link"
                  matTooltip="Adicionar classificação" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 28px; margin-right: -5px;">add</span>
                </button>
                <button type="button" (click)="openEditModal()" [disabled]="editTool" class="btn btn-xs btn-link"
                  matTooltip="Editar classificação" matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 24px; margin-right: -5px;">edit</span>
                </button>
                <button type="button" (click)="delete()" class="btn btn-xs btn-link" matTooltip="Remover classificação"
                  matTooltipPosition="above">
                  <span class="material-icons" style="font-size: 23px; margin-right: 0px;">delete</span>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="dt1.exportCSV()" matTooltip="Exportar Excel"
                  matTooltipPosition="above"><i class="far fa-file-excel excel"></i>
                </button>
                <button type="button" class="btn btn-xs btn-link" (click)="ShowHIdeCardTable()"
                  (click)="changeStateTable()">
                  <span *ngIf="isCardTable" class="material-icons" matTooltip="Ocultar Card"
                    matTooltipPosition="above">expand_less</span>
                  <span *ngIf="!isCardTable" class="material-icons" matTooltip="Ver Card"
                    matTooltipPosition="above">expand_more</span>
                </button> -->
              </div>
            </div>
          </div>

          <div class="box-content" [@changeDivSize]=currentStateTable>
            <p-table class="table-system" #dt1 [columns]="colslistaClassificacoes" [value]="listaClassificacoes"
              [rows]="rows" [paginator]="true" [pageLinks]="pageLinks" [rowsPerPageOptions]="rowsPerPage"
              [style]="{'width':'100%'}" (onHeaderCheckboxToggle)="selectTodos($event)" (onRowSelect)="selectCheckbox($event)"  (onRowUnselect)="unselectCheckbox($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center" style="width: 60px;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'codigo'">
                      <span class="text-overflow-dynamic-ellipsis">CÓDIGO <p-sortIcon [field]="'codigo'"></p-sortIcon>
                      </span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'codigo', 'contains')">
                  </th>
                  <th class="text-left" style="width: 65%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'descricao'">
                      <span class="text-overflow-dynamic-ellipsis">DESCRIÇÃO <p-sortIcon [field]="'descricao'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'descricao', 'contains')">
                  </th>
                  <th class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container" [pSortableColumn]="'data'">
                      <span class="text-overflow-dynamic-ellipsis">ÚLTIMA EDIÇÃO <p-sortIcon [field]="'data'">
                        </p-sortIcon></span>
                    </span>
                    <input class="form-control form-control-sm" type="text"
                      (input)="dt1.filter($event.target.value, 'dataFormatada', 'contains')">
                  </th>

                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">

                  <td class="text-center" style="width: 60px;">
                    <p-tableCheckbox  [value]="rowData">
                    </p-tableCheckbox>
                  </td>

                  <td class="text-left" style="width: 20%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis" matTooltip="{{rowData.codigo}}">{{rowData.codigo
                        }}</span>
                    </span>
                  </td>
                  <td class="text-left" style="width: 65%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.descricao}}">{{rowData.descricao }}</span>
                    </span>
                  </td>
                  <td class="text-center" style="width: 15%;">
                    <span class="text-overflow-dynamic-container">
                      <span class="text-overflow-dynamic-ellipsis"
                        matTooltip="{{rowData.data | date:'dd/MM/yyyy'}}">{{rowData.data | date:'dd/MM/yyyy'}}</span>
                    </span>
                  </td>

                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-rowData>
                <tr>
                  <td [attr.colspan]="4" class="spaced-alert">
                    <div class="alert alert-xs alert-primary d-inline" role="alert">
                      <i class="fas fa-info-circle"></i> Nenhum Registro Encontrado!
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

        </div>
        
      </div>
    </div>
  </div>
</mat-card>

<!-- MODAL NOVA CLASSIFICAÇÃO -->
<p-dialog header="{{statusTelaClass ? 'Adicionar Classificação': 'Adicionar Subárea'}}" [(visible)]="modalNew"
 [style]="{ width: '400px' }" [modal]="true" styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}"
  appendTo="body" [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Código <span class="obrigatorio">*</span></label>
      <input class="form-control text-left" placeholder="Código" [(ngModel)]="newCodigo">
    </div>
    <div class="form-group col-sm-8">
      <label>Descrição <span class="obrigatorio">*</span></label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="newDescricao">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalNew=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="addClassificacao();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>

<!-- MODAL EDIT CLASSIFICAÇÃO -->
<p-dialog header="{{statusTelaClass ? 'Editar Classificação': ''}}" [(visible)]="modalEdit"  [style]="{ width: '400px' }" [modal]="true"
  styleClass="modal-initial" [responsive]="true" [contentStyle]="{'overflow':'visible'}" appendTo="body"
  [closable]="true">

  <div class="form-row">
    <div class="form-group col-sm-4">
      <label>Código</label>
      <input class="form-control text-left" placeholder="Código" [(ngModel)]="editCodigo">
    </div>
    <div class="form-group col-sm-8">
      <label>Descrição</label>
      <input class="form-control text-left" placeholder="Descrição" [(ngModel)]="editDescricao">
    </div>
  </div>
  <p-footer>
    <div id="div-botoes">
      <div class="margin-botoes-modal">
        <button type="button" class="btn btn-secondary" (click)='modalEdit=false;'><i class="fas fa-undo"></i>
          Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editClassificacao();"><i class="fas fa-check"></i>
          Salvar</button>
      </div>
    </div>
  </p-footer>

</p-dialog>